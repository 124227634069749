import { canAccessLocation } from "../../../utils/commonUtils";
import { IContactProfile } from "../../RightSideContainer/TeamInbox/Conversations/interfaces";

export const checkLocationAccess = (props: {
  locationUuids: string[],
  isMultiTenancyEnabled: boolean,
  allowedLocationUuids: string[]
}) => {
  const { locationUuids, isMultiTenancyEnabled, allowedLocationUuids } = props;
  const haveAccessToLocation = isMultiTenancyEnabled ? canAccessLocation(locationUuids, allowedLocationUuids) : true;
  return haveAccessToLocation;
}

export const getActivePatientProfile = (profiles: IContactProfile[]) => {
  return profiles?.find(profile => profile.isActive) || null;
};