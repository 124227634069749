import {ModalActionAntSelect} from '../ModalActionCommonComponent/ModalActionAntSelect';
import {HStack, Text} from 'native-base';
import {Colors} from '../../../styles';
import {IProps, useTimeZoneSelect} from './useTimeZoneSelect';

export const TimezoneSelect = (props: IProps) => {
  const {
    showLabel,
    isRequired,
    memorizeTimezone,
    showErrors,
    showSelectBorder,
    selectColor,
    fontColor,
    width,
    label,
    popupClassName,
  } = props;
  const {
    componentState,
    setComponentState,
    getTimezonesQuery,
    getLastSavedTimezone,
    updateMemorizeTimezone,
    initializeSelectedTimezone,
  } = useTimeZoneSelect(props);

  return (
    <>
      {!props.isDisabled && (
        <ModalActionAntSelect
          toShowSelectDropdownArrowSvg={props?.toShowSelectDropdownArrowSvg}
          disabled={props.isDisabled}
          value={componentState.selectedTimezone || undefined}
          data={componentState.timezoneList}
          loading={componentState.isLoading}
          showSearch={true}
          allowClear={false}
          onChange={(value: string) => {
            const timezone = componentState.timezoneList.find(
              (timezone) => timezone.uuid === value
            );
            setComponentState((prev) => ({
              ...prev,
              selectedTimezone: timezone,
            }));
            updateMemorizeTimezone(timezone);
          }}
          filterOption={(input: string, option: any) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          optionProps={{
            key: 'uuid',
            label: 'label',
            value: 'uuid',
          }}
          label={showLabel && (label || 'selectTimezone')}
          labelInValue={false}
          isInvalid={isRequired && !componentState.selectedTimezone}
          isRequired={isRequired}
          placeholder="Select timezone"
          errors={
            props.showErrors && isRequired && !componentState.selectedTimezone
          }
          errorText={'Please select timezone'}
          extraStyle={{
            flex: 1,
            backgroundColor: props.selectColor,
            fontColor: props?.fontColor,
            fontSize: props?.labelFontSize,
          }}
          style={{width: props?.width}}
          customStyle={{...props.customStyle, backgroundColor: props.selectColor}}
          bordered={props.showSelectBorder}
          className={props.className}
          popupClassName={popupClassName}
          hideCustomIcon={componentState.isLoading ? false : true}
        />
      )}

      {props.isDisabled && componentState.selectedTimezone?.displayValue && (
        <HStack alignItems={'center'} height={'full'} marginTop={2}>
          <Text underline={true} color={Colors.secondary[700]}>
            {componentState.selectedTimezone?.displayValue}
          </Text>
        </HStack>
      )}
    </>
  );
};
