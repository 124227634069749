import {IKeyOperation} from '../../../../../../../Interfaces';
import {IAddOrUpdateConditionParams, ICodeableParam, ICondition, IConditionComponentValue} from '../interfaces';
import { ConditionFields } from '../../CustomComponentUtils';
import { ComponentType } from '../../Diagnosis/interfaces';
import { IFormatReconciliationMetaData, IReconciliationDisplay, IReconciliationConflictField, ReconciliationSource } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { Condition } from 'fhir/r4';
import { getDateStrFromFormat, getFormattedDate, getMomentObj } from '../../../../../../../utils/DateUtils';
import { getFhirCodeBySystem, getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { FHIRConflictFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants/StringConst';

const extension_url_type = 'type'
export const getRecordListFormatted = (list: any[],fields?:IKeyOperation) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries, fields);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[],fields?:IKeyOperation): ICondition[] => {
  const tempFormattedList: ICondition[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const status = item?.clinicalStatus?.coding?.length
        ? item.clinicalStatus?.coding[0]
        : {code: item.clinicalStatus?.text};
      const severity =  item?.severity?.coding?.length
      ? item.severity?.coding[0]
      : {code: item.severity?.text};
      let note = '';
      if (item?.note?.length) {
        note = item.note[0].text || '';
      }
      const conditionType = item?.extension?.length && fields?.type?.extensionUrl ? item.extension.find((ext: {url:string,valueString:string}) => ext.url == fields?.type?.extensionUrl)?.valueString : null;
      const type = item?.extension?.length ? item.extension.filter((ext: {url:string,valueString:string}) => ext.url.includes(extension_url_type))?.[0]?.valueString : null;
      const tempObj: ICondition = {
        id: item?.id,
        onSetDateTime: item?.onsetDateTime,
        condition: item.code,
        name: getConditionName(item.code),
        clinicalStatus: status,
        note,
        uniqueId: item?.id,
        isFreeTextRecord: !item.code?.coding?.length,
        type,
        showNote: note.length > 0 || fields?.note?.isRequired,
        conditionType: {code: conditionType},
        fieldType: item?.category?.[0]?.code || ComponentType.Condition, // if no category then set it to Condition for backward compatibility
        extension: item.extension,
        meta: item?.meta,
        severity: severity
      };

      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (type?:ComponentType): IConditionComponentValue => {
  return {
    conditions: [
      {
        id: '34234324',
        onSetDateTime: '2020-12-24',
        condition: {
          text: `Sample ${
            type === ComponentType.Condition ? 'Problem' : 'Diagnosis'
          }`,
          coding: [],
        },
        name: `Sample ${
          type === ComponentType.Condition ? 'Problem' : 'Diagnosis'
        }`,
        clinicalStatus: {code: 'active', display: 'Active'},
        note: 'Test notes',
        uniqueId: '1234',
        isFreeTextRecord: false,
        severity:  {
          "code": "Severe",
          "display": "Severe"
        }
      },
    ],
  };
};

export const sortItems = (records: ICondition[]) => {
  return records.sort((a: ICondition, b: ICondition) => {
    const statusA = a.clinicalStatus?.code || '';
    const statusB = b.clinicalStatus?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(
        b.recordedDate || b.onSetDateTime || new Date()
      ).getTime() -
      new Date(
        a.recordedDate || a.onSetDateTime || new Date()
      ).getTime()
    );
  })
};

export const getConditionName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: ConditionFields,
  conditionData: ICondition,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== ConditionFields.code && conditionData.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
    return false;
  }
  switch (field) {
    case ConditionFields.onSetDateTime:
      if ((fields?.onsetDateTime?.isRequired || false) && submitTriggered) {
        return !conditionData.onSetDateTime;
      }
      return false;

    case ConditionFields.clinicalStatus:
      if ((fields?.clinicalStatus?.isRequired || false) && submitTriggered) {
        return (
          !conditionData.clinicalStatus || !conditionData.clinicalStatus.code
        );
      }
      return false;
    case ConditionFields.severity:
      if ((fields?.severity?.isRequired || false) && submitTriggered) {
        return !conditionData.severity || !conditionData.severity.code;
      }
      return false;

    case ConditionFields.note:
      if ((fields?.note?.isRequired || false) && submitTriggered) {
        return !conditionData.note;
      }
      return false;

    case ConditionFields.conditionType:
      if ((fields?.type?.isRequired || false) && submitTriggered) {
        return (
          !conditionData.conditionType || !conditionData.conditionType.code
        );
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateConditionParams) => {
  return {
    resourceType: 'Condition',
    ...(params.id && {id: params.id}),
    clinicalStatus: {
      coding: [
        {
          code: params.clinicalStatus?.code,
          display: params.clinicalStatus?.display,
        },
      ],
    },
    severity: {
      text: params.severity?.display,
      coding: [
        {
          code: params.severity?.code,
          display: params.severity?.display,
        },
      ],
    },
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.note && {
      note: [
        {
          text: params.note,
        },
      ],
    }),
    ...((params.extension || []).length > 0 && {extension: params.extension}),
    onsetDateTime: params.onSetDateTime,
    code: params.condition,
    category: [
      {
        coding: [
          {
            code: params?.componentType,
          },
        ],
      },
    ],
    ...(params?.meta && { meta: params?.meta })
  };
};



const getFieldCapabilities = (capabilities: any): IKeyOperation | undefined => {
  return capabilities?.abilities?.keyAllowedOperations;
};

export const formatReconciliationConditionData = (
  resource: Condition,
  metaData: IFormatReconciliationMetaData
):
  | {display: IReconciliationDisplay; resourceData: any; conflictFields: IReconciliationConflictField[]}
  | undefined => {
  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.condition,
  ]);

  const conditionResource: Condition = resource;
  const conditionDate = getHieConditionDate(resource);
  const formattedDate = conditionDate ? getFormattedDate(conditionDate, DISPLAY_DATE_FORMAT) : '';
  const fieldCapabilities = getFieldCapabilities(capabilities);

  const status = resource?.clinicalStatus?.coding?.length
  ? resource.clinicalStatus?.coding[0]
  : {code: resource.clinicalStatus?.text};

  const name = getHieConditionCodes(resource, fieldCapabilities?.code?.allowedCodeSystems)?.text || '';
  const type = resource.extension?.find((ext: any) => ext.url == fieldCapabilities?.type?.extensionUrl)?.valueString || '';
  const severity = resource.severity?.coding?.length
  ? resource.severity?.coding[0]
  : {code: resource.severity?.text};
  const display = {
    title: name,
    status: [formattedDate, status?.display, type, severity?.display].filter(Boolean).join(' • '),
    date: resource.note?.[0]?.text,
  };

  const validatedData = validateAndFormatConditionHieResource(
    (metaData?.targetResourceData || resource) as Condition,
    fieldCapabilities || {},
    metaData
  );

  return {
    resourceData: validatedData.resource,
    display,
    conflictFields: validatedData.conflictFields,
  };
};

const getHieConditionDate = (
  resource: Condition,
  onsetDateFormat?: string | undefined
) => {
  const conditionDate = resource.onsetDateTime || resource.onsetPeriod?.start;

  if (conditionDate) {
    const onsetDate = onsetDateFormat
      ? getDateStrFromFormat(conditionDate, onsetDateFormat)
      : getMomentObj(conditionDate || new Date()).toISOString();

    return onsetDate;
  }
};

const getHieClinicalStatus = (
  resource: Condition,
  possibleValues?: {code: string; display: string}[]
) => {
  const clinicalStatus = resource?.clinicalStatus?.coding?.length
    ? resource.clinicalStatus?.coding[0]
    : {
        code: resource.clinicalStatus?.text,
        display: resource.clinicalStatus?.text,
      };

  return possibleValues?.find(
    (possibleValue) => possibleValue.code === clinicalStatus.code?.toLowerCase()
  );
};

const getHieNote = (resource: Condition) => {
  const noteText = resource?.note?.[0]?.text;
  return noteText;
};

const getHieConditionCodes = (
  resource: Condition,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.code;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: getValidCoding(codeObj, allowedCodeSystems), text: text};
};

export const getConditionCodeInvalidField = (isConflict?: boolean, value?: any) => {
  return {
    inputType: FHIRConflictFields.code,
    field: ConditionFields.code,
    invalid: !value?.coding?.length,
    label: 'Problem',
    placeholder: 'Search Problem',
    isConflict: isConflict,
    value: value,
    isRequired: true,
    extraData: {
      searchType: PAMISearchType.problem,
    }
  }
}

/**
 * Validates and formats a FHIR Condition resource based on provided fields and metadata
 *
 * @param resource - The FHIR Condition resource to validate and format
 * @param fields - Key operations defining validation rules and allowed values for each field
 * @param metaData - Metadata containing target resource data and other formatting configuration
 * @returns The validated and formatted Condition resource with any validation conflicts
 */
export const validateAndFormatConditionHieResource = (
  resource: Condition,
  fields: IKeyOperation,
  metaData: IFormatReconciliationMetaData
) => {
  const conflictFields: IReconciliationConflictField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    let field = ConditionFields[key as keyof typeof ConditionFields];
    if (!fields[key as keyof typeof fields]?.isHidden) {
      if (key === 'type') {
        field = ConditionFields.conditionType;
      }
      switch (field) {
        case ConditionFields.code:
          {
            const conditionCodes = metaData.source === ReconciliationSource.MSO
              ? resource.code
              : getHieConditionCodes(
                  resource,
                  fields?.code?.allowedCodeSystems
                );

            resource.code = conditionCodes;
            conflictFields.push(
              getConditionCodeInvalidField(false, resource.code)
            );
          }
          break;

        case ConditionFields.onsetDateTime:
          {
            const onsetDateFormat = fields?.onsetDateTime?.format;
            const onsetDateTime = getHieConditionDate(
              resource,
              onsetDateFormat
            );
            const isMissing =
              !onsetDateTime && fields?.onsetDateTime?.isRequired;

            conflictFields.push({
              inputType: FHIRConflictFields.date,
              field: ConditionFields.onsetDateTime,
              invalid: isMissing || false,
              value: onsetDateTime,
              label: 'Since',
              isRequired: fields?.onsetDateTime?.isRequired || false,
              extraData: {
                dateFormat: onsetDateFormat,
              },
            });
          }
          break;

        case ConditionFields.clinicalStatus:
          {
            const status = getHieClinicalStatus(
              resource,
              fields?.clinicalStatus?.possibleValues
            );
            const isMissing =
              !status?.code && fields?.clinicalStatus?.isRequired;

            if (!isMissing && status) {
              resource.clinicalStatus = {
                coding: [status],
              };
            }
            conflictFields.push({
              field: ConditionFields.clinicalStatus,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: status?.code,
              label: 'Status',
              isRequired: fields?.clinicalStatus?.isRequired || false,
              extraData: {
                statusList: fields?.clinicalStatus?.possibleValues || [],
              },
            });
          }
          break;
        case ConditionFields.severity:
          {
            const severityCode = resource.severity?.coding?.[0]?.code;
            const isMissing =
              !severityCode && fields?.severity?.isRequired;

            conflictFields.push({
              field: ConditionFields.severity,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: severityCode,
              label: 'Severity',
              isRequired: fields?.severity?.isRequired || false,
              extraData: {
                statusList: fields?.severity?.possibleValues || [],
              },
            });
          }
          break;

        case ConditionFields.note:
          {
            const hieNote = getHieNote(resource);
            const isMissing = !hieNote && fields?.note?.isRequired;
            if (!isMissing && hieNote) {
              resource.note = [{text: hieNote}];
            }
            conflictFields.push({
              field: ConditionFields.note,
              inputType: FHIRConflictFields.textArea,
              invalid: isMissing || false,
              value: hieNote,
              isRequired: fields?.note?.isRequired || false,
              label: 'Note',
            });
          }
          break;

        case ConditionFields.conditionType:
          {
            const extension = resource.extension?.find(
              (ext: any) => ext.url == fields?.type?.extensionUrl
            );
            const conditionType = fields?.type?.possibleValues?.find(
              (value: any) => value.code == extension?.valueString
            );
            const isMissing = !conditionType && fields?.type?.isRequired;
            if (!isMissing && conditionType) {
              resource.extension = resource.extension?.filter(
                (ext: any) => ext.url !== fields?.type?.extensionUrl
              ) || [];
              resource.extension.push({
                url: fields?.type?.extensionUrl || '',
                valueString: conditionType?.code,
              });
            }
            conflictFields.push({
              field: ConditionFields.conditionType,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: conditionType?.code,
              isRequired: fields?.type?.isRequired || false,
              label: 'Condition Type',
              extraData: {
                statusList: fields?.type?.possibleValues,
              },
            });
          }
          break;
      }
    }
  });

  return {
    conflictFields: conflictFields,
    resource: resource,
  };
};

export const updateHieConditionMatchedData = (
  resource: Condition,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  const updatedResource = updateHieConditionData(resource, conflictFields, metaData);
  return formatReconciliationConditionData(updatedResource, metaData);
};

export const updateHieConditionData = (
  resource: Condition,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  conflictFields.map((conflictField) => {
    switch (conflictField.field) {
      case ConditionFields.code:
        {
          resource.code = conflictField.value;
        }
        break;
      case ConditionFields.onsetDateTime:
        {
          resource.onsetDateTime = conflictField.value;
        }
        break;
      case ConditionFields.note:
        {
          resource.note = [{text: conflictField.value}];
        }
        break;
      case ConditionFields.clinicalStatus:
        {
          const capabilities = getEHRCapability(metaData.ehrCapabilities, [
            CapabilityResource.condition,
          ]);
          const fieldCapabilities = getFieldCapabilities(capabilities);
          const status =
            fieldCapabilities?.clinicalStatus?.possibleValues?.find(
              (value: any) => value.code == conflictField.value
            );
          if (status) {
            resource.clinicalStatus = {
              coding: [status],
            };
          }
        }
        break;
      case ConditionFields.severity:
        {
          const capabilities = getEHRCapability(metaData.ehrCapabilities, [
            CapabilityResource.condition,
          ]);
          const fieldCapabilities = getFieldCapabilities(capabilities);
          const status =
            fieldCapabilities?.severity?.possibleValues?.find(
              (value: any) => value.code == conflictField.value
            );
          if (status) {
            resource.severity = {
              coding: [status],
            };
          }
        }
        break;
      case ConditionFields.conditionType:
        {
          const capabilities = getEHRCapability(metaData.ehrCapabilities, [
            CapabilityResource.condition,
          ]);
          const fieldCapabilities = getFieldCapabilities(capabilities);
          const extension =
            resource.extension?.filter(
              (ext: any) => ext.url !== fieldCapabilities?.type?.extensionUrl
            ) || [];
          if (extension) {
            extension.push({
              url: fieldCapabilities?.type?.extensionUrl || '',
              valueString: conflictField.value,
            });
          }
          resource.extension = extension;
        }
        break;
    }
    return conflictField;
  });
  return resource;
};

export const formatConditionsRequestResourceObj = (
  resource: Condition,
  metaData: IFormatReconciliationMetaData
) => {
  return {
    resourceType: FHIR_RESOURCE.CONDITION,
    ...(resource.clinicalStatus && {
      clinicalStatus: resource.clinicalStatus,
    }),
    subject: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.note && {
      note: resource.note,
    }),
    ...((resource.extension || []).length > 0 && {
      extension: resource.extension,
    }),
    onsetDateTime: resource.onsetDateTime,
    code: resource.code,
    category: [
      {
        coding: [
          {
            code: ComponentType.Condition,
          },
        ],
      },
    ],
    ...(resource?.meta && {meta: resource?.meta}),
    ...(resource?.extension && {meta: resource?.extension}),
  };
};


