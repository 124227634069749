import React, { useContext } from 'react';
import {IFormCommonData, IFormComponentProps} from '../../CustomWrapper/CustomWrapper';
import ClinicalParametersView from './ClinicalParametersView';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';

const ClinicalParametersWrapper: React.FC<IFormComponentProps> = (props) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  return (
    <>
      <ClinicalParametersView
        contactCareProgramId={props?.options?.contactCareProgramId || contextData?.contactCareProgramId}
        onChange={props?.onChange}
        validateRef={props?.validateRef}
        component={props?.component}
        disableForm={props?.disabled}
      />
    </>
  );
};

export default ClinicalParametersWrapper;
