import { useCallback, useContext, useEffect, useState } from 'react';
import {
  IPatientInformationComponentState,
  IPatientInformationComponentValue,
  IAddPatientInformationVerificationHookParams,
} from './interface';
import { PatientInformationVerificationField } from '../../CustomComponentUtils';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import ContactsQueries from '../../../../../../../services/Contacts/ContactsQueries';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { cloneDeep } from 'lodash';
import { GetAccountSettingsByCode } from '../../../../../../../services/UserSettings/UserSettingQueries';
import { IFormCommonData } from '../../CustomWrapper/CustomWrapper';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';

export const useAddPatientInformationVerification = (
  params: IAddPatientInformationVerificationHookParams
) => {
  const {component, validateRef, onChange, previewMode, isBuilderPreviewMode, proxyHeaders} = params;
  const contextData = useContext(CommonDataContext) as IFormCommonData;

  const [accountSettingsApi] = useLazyQuery(GetAccountSettingsByCode, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  });

  const [componentValue, setComponentValue] =
    useState<IPatientInformationComponentValue>(
      component?.selectedValue || {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        zipCode: '',
        contactId: '',
      }
    );

  const [componentState, setComponentState] =
    useState<IPatientInformationComponentState>({
      loading: false,
      showErrors: false,
      errorCode: undefined,
      isValidPatient: false,
      isApiLoading: false,
      accountConfig: {},
      accountSettingError: undefined,

    });

  const [validatePatientDetails] = useLazyQuery(
    ContactsQueries.VALIDATE_PATIENT_INFORMATION,
    {
      fetchPolicy: 'no-cache',
      context: {
        headers: proxyHeaders,
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const handleAccountSettings = async () => {

      setComponentState((prev) => ({
        ...prev,
        loading: true,
      }));
      let accountConfig = {};
      try {
      const accountSettingResponse = await accountSettingsApi({
        variables: {
          tenantId: contextData?.tenantId,
          code: 'patient_information_widget_config'
        },
      });
      if (accountSettingResponse?.data?.defaultUserSettings) {
        (accountSettingResponse?.data?.defaultUserSettings || []).forEach(
          (defaultUserSetting: any) => {
            if (!defaultUserSetting) return;
            const code: string = defaultUserSetting?.code;
            if (code !== 'patient_information_widget_config') return;

            let value: string = defaultUserSetting?.value;

            if (defaultUserSetting?.userSettings?.[0]) {
              value = defaultUserSetting?.userSettings[0]?.value;
            }

            const formatedValue = JSON.parse(value);
            accountConfig= formatedValue?.patientInformationWidgetConfig;
          }
        );
      }
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        accountConfig,
      }));
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        isApiLoading: false,
        isValidPatient: false,
        accountSettingError: error as any,
      }));
    }
  };

  const handleChange = (
    field: keyof IPatientInformationComponentValue,
    value: string
  ) => {
    setComponentValue((prev) => {
      return {
        ...prev,
        [field]: value,
      };
    });
  };

  const isInvalid = (
    field: PatientInformationVerificationField,
    value?: string,
    showErrors?: boolean
  ) => {
    const canShowErrors = showErrors || componentState.showErrors;
    return !value && canShowErrors;
  };

  const isValidData = () => {
    let isValid = true;
    isValid &&= !isInvalid(
      PatientInformationVerificationField.FIRST_NAME,
      componentValue.firstName
    );
    isValid &&= !isInvalid(
      PatientInformationVerificationField.LAST_NAME,
      componentValue.lastName
    );
    isValid &&= !isInvalid(
      PatientInformationVerificationField.DATE_OF_BIRTH,
      componentValue.dateOfBirth
    );
    isValid &&= !isInvalid(
      PatientInformationVerificationField.ZIP_CODE,
      componentValue.zipCode
    );
    return isValid;
  };

  const validateData = () => {
    if (!isValidData()) {
      setComponentState((prev) => {
        return {
          ...prev,
          showErrors: true,
          isValidPatient: false,
        };
      });
      return {
        isValid: false,
        message: `${component?.label}: Please fill all the mandatory fields`,
      };
    } else {
      const isValidPatient =
        !componentState?.isApiLoading && componentState?.isValidPatient;

      return {
        isValid: isValidPatient || false,
        message: !isValidPatient ? `${component.label}: Please enter valid patient details.` : '',
      };
    }
  };

  const validatePatientInfo = async (
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    zipCode: string
  ) => {
    const isValid = firstName && lastName && dateOfBirth && zipCode;

    if (!isValid && !isBuilderPreviewMode) {
      return;
    }

    setComponentState((prev) => ({
      ...prev,
      isApiLoading: true,
    }));

    try {
      const patientData = await validatePatientDetails({
        variables: {
          params: {
            firstName,
            lastName,
            birthDate: dateOfBirth,
            zipcode: zipCode,
          },
        },
      });

      if (patientData?.data?.validatePatientInformation?.contactId) {
        setComponentState((prev) => ({
          ...prev,
          isValidPatient: true,
          showErrors: false,
          isApiLoading: false,
        }));
        setComponentValue((prev) => ({
          ...prev,
          contactId: patientData?.data?.validatePatientInformation?.contactId,
        }));
      } else if (patientData?.data?.validatePatientInformation?.errorCode) {
        setComponentState((prev) => ({
          ...prev,
          errorCode: patientData?.data?.validatePatientInformation?.errorCode,
          showErrors: true,
          isApiLoading: false,
          isValidPatient: false,
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          isValidPatient: false,
          showErrors: true,
          isApiLoading: false,
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        isApiLoading: false,
        isValidPatient: false,
      }));
    }
  };

  const checkPatientInfoVerificationSuccess = () => {
    if (
      componentState?.isValidPatient &&
      !previewMode &&
      componentValue?.firstName &&
      componentValue?.lastName &&
      componentValue?.dateOfBirth &&
      componentValue?.zipCode
    ) {
      return true;
    }
    return false;
  };

  const debouncedValidatePatientInfo = useCallback(
    debounce(
      (
        firstName: string,
        lastName: string,
        dateOfBirth: string,
        zipCode: string
      ) => {
        validatePatientInfo(firstName, lastName, dateOfBirth, zipCode);
      },
      500
    ),
    []
  );

  useEffect(() => {
    if (
      componentValue?.firstName &&
      componentValue?.lastName &&
      componentValue?.dateOfBirth &&
      componentValue?.zipCode &&
      !previewMode
    ) {
      debouncedValidatePatientInfo(
        componentValue?.firstName,
        componentValue?.lastName,
        componentValue?.dateOfBirth,
        componentValue?.zipCode
      );
    }
  }, [
    componentValue?.firstName,
    componentValue?.lastName,
    componentValue?.dateOfBirth,
    componentValue?.zipCode,
    previewMode
  ]);

  useEffect(() => {
    onChange(cloneDeep(componentValue));
  }, [componentValue]);

  useEffect(() => {
    handleAccountSettings();
  }, []);

  return {
    componentValue,
    componentState,
    handleChange,
    isInvalid,
    validateData,
    checkPatientInfoVerificationSuccess,
  };
};
