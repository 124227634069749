import React from 'react';
import {Path, Svg} from 'react-native-svg';

const MultiSelectSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        fill="#F5F0FF"
      />
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        stroke="#D7C0FF"
        strokeWidth="0.5"
      />
      <Path
        d="M7.66666 10.5833L8.67856 11.8333L12.25 8.5M7.66666 16.4167L8.67856 17.6667L12.25 14.3333M24.3333 21.8333H16M24.3333 16H16M24.3333 10.1667H16M11.8333 21.8333H8.49999"
        stroke="#8C5AE2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </Svg>
  );
};

export default React.memo(MultiSelectSvg);
