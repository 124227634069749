

import {useLazyQuery} from '@apollo/client';
import {AutoComplete, Drawer, Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {FormControl, HStack, Spinner, useMediaQuery, View, Text} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {BUTTON_TYPE, CONSENT_CONST, IPAD_MINI_WIDTH, IPAD_WIDTH, PATIENT_CONSENT_ERROR} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {COMMUNICATION_APP_BASE_URL} from '../../../constants/BaseUrlConst';
import { MLOV_CATEGORY, USER_ROLE_CODES } from '../../../constants/MlovConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {LeadQueries, UserQueries} from '../../../services';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {Colors} from '../../../styles';
import {
  getAccountId,
  getAccountName,
  getAccountUUID,
  getAllowedUserPracticeLocationUuids,
  getUserId,
  getUserUUID,
  isCallConsentGiven,
  isContactConsentGiven,
  isContactConsentRequired,
  isZoomEnabled,
} from '../../../utils/commonUtils';
import {getContactTypeId, getMlovId, getMlovIdFromCode, getMlovListFromCategory} from '../../../utils/mlovUtils';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
// import {PERSON_ACTION_CODES} from '../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import {DisplayText} from '../DisplayText/DisplayText';
import MemberInfoListItem from '../MemberInfoListItem/MemberInfoListItem';
import {ModalActionAntSelect} from '../ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionCheckbox} from '../ModalActionCommonComponent/ModalActionCheckBox';
import {ModalActionInput} from '../ModalActionCommonComponent/ModalActionInput';
import { ModalActionUserMultiSelect } from '../ModalActionCommonComponent/ModalActionUserMultiSelect';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {scheduleHMSMeet} from '../ZoomView/ZoomServices';
import {
  IAccount,
  IHostInfo,
  IMeeting,
  IMeetingView,
  IUserList,
} from './interface';
import {styles, reactStyles} from './MeetingStyle';
import {MeetingInviteVia} from './MeetingInviteVia';
import {IMlov} from '../../../Interfaces';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { CustomUserSearch } from '../CustomUserSearch/CustomUserSearch';
import { useIntl } from 'react-intl';
import { USER_SEARCH_TYPE } from '../../RightSideContainer/constants';
import { IInternalUsers } from '../CustomUserSearch/interface';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { getEhrConfig } from '../../../utils/capabilityUtils';
const {Option} = Select;
const { Option: AutoCompleteOption } = AutoComplete;
const MeetingView = (props: IMeetingView) => {
  const {personData} = props;
  const accountId = getAccountId();
  const accountUUID = getAccountUUID();
  const accountName = getAccountName();
  const userUuid = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const userData = mlovData?.userData;
  const userId = getUserId()
  const intl = useIntl();
  const ehrConfig = getEhrConfig('', '');
  const isAthena = ehrConfig.isAthena;
  const [showModal, setShowModal] = useState(true);
  const currentUrl = window.location.href;
  const [error, setError] = useState({} as any);
  // const contactTypeUuid = getContactTypeId('CUSTOMER');
  const [startMeetLoading, setStartMeetLoading] = React.useState(false);
  const contactTypeUuid = getContactTypeId('CUSTOMER');
  const isConsentRequired = isContactConsentRequired();
  const isSideCarContext = mlovData.sidecarContext?.isSidecar;
  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );
  const [meetingObj, setMeetingObj] = useState({
    meetingName: '',
    patientName: personData.name,
    sendEmail: true,
    sendEmailToPatient: false,
    sendSmsToPatient: false,
    sendNotificationToPatient: false,
    error: '',
    inviteeList: [] as IUserList[],
    selectedInvitee: [] as number[],
    account: {
      accountId,
      accountUUID,
      accountName,
    } as IAccount,
    hosInfo: {
      name: userData?.name,
      email: userData?.email,
      uuid: userData?.uuid,
    } as IHostInfo,
  } as IMeeting);
  const [
    CreateEmailConversationDrawerState,
    setCreateEmailConversationDrawerState,
] = useState({
    isPatientDataLoading: false,
    selectedPatient: {} as IContact,
    patientLists: [] as IContact[],
    errors: {} as any,
    isConsentGiven: true,
    selectedUserData: [] as IInternalUsers[],
});
  const [userList, setUserList] = useState([] as IUserList[]);
  const consentMlovList: IMlov[] = getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.CONSENT) || [];
  const callConsentId = getMlovIdFromCode(
    consentMlovList,
    CONSENT_CONST.consentToCall
  );
  const [getAccountUsers] = useLazyQuery(InboxQueries.GetAgents);
  const [getUserCustomAttr] = useLazyQuery(UserQueries.GET_USER_CUSTOM_ATTR, {
    fetchPolicy: 'no-cache'
  })
  const [getPatientList] = useLazyQuery(
    LeadQueries.GetContactsForMeetingCreate,
    {
        fetchPolicy: 'no-cache',
    }
);

  const [getPatientListByLocationUuids] = useLazyQuery(
    LeadQueries.GetContactsForGroupByLocationUuids,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getUserObj = (userList: IUserList[]) => {
    const newObj = userList.map((user: IUserList) => {
      const newUserObj = {...user, name: user?.user?.name};
      return newUserObj;
    });
    return newObj;
  };

  const closeModal = () => {
    setShowModal(false);
    setTimeout(() => {
      props.onPersonActionPerformed(COMMON_ACTION_CODES.CANCEL);
    }, 500);
  };
  const handleAddInvite = (value: number[]) => {
    const selectedInvites = userList.filter((user: IUserList) => {
      return value.includes(user.userId);
    });
    setMeetingObj((prev) => {
      return {
        ...prev,
        inviteeList: selectedInvites,
      };
    });
  };
  const getUsersData = async () => {
    const responseData = await getAccountUsers({
      variables: {accountId: accountId,
        roleCode: USER_ROLE_CODES.EMPLOYER,
      },
    });
    if (responseData?.data?.accountUsers?.length) {
      setMeetingObj((prev) => {
        return {
          ...prev,
          usersList: responseData?.data?.accountUsers,
        };
      });
      responseData?.data?.accountUsers.forEach((user: IUserList) => {
        if (user.userId == userData?.id) {
          setMeetingObj((prev: any) => {
            return {
              ...prev,
              selectedInvitee: [user.userId],
              inviteeList: [user],
            };
          });
          setCreateEmailConversationDrawerState((prev) => {
            return {
              ...prev,
              selectedUserData: [user],
            };
          });
        }
      });
      setUserList(responseData?.data?.accountUsers);
    } else {
    }
  };


  const getUserSubtitle = async () => {
    try {
      const response = await getUserCustomAttr({variables: {
        userId: userId
      }})
      const subtitle = response?.data?.user?.subtitle || ''
      setMeetingObj(prev => {
        return {
          ...prev,
          hosInfo: {
            ...prev.hosInfo,
            subtitle
          }
        }
      })
    } catch (error) {

    }
  }

  const isZoomEnabledAccount = isZoomEnabled();

  const handleStartMeeting = async () => {
    if (!meetingObj.patientName?.length) {
      setCreateEmailConversationDrawerState((prev: any) => {
      return {
      ...prev,
      errors: {
      ...prev.errors,
      patient: 'Please select member',
      },
      };
      });
      return;
      }
    if (meetingObj.selectedInvitee.length < 1) {
      setError((prev: any) => {
        return {
          ...prev,
          internalUsers: 'Please select internal users',
        };
      });
      return;
    }
    meetingObj.formattedContactData = personData;
    meetingObj.leaveUrl = currentUrl;
    // const scheduleMeetRes = await scheduleMeet(
    //   accountId,
    //   accountUUID,
    //   accountName,
    //   meetingObj,
    //   30,
    //   [meetingObj.formattedContactData],
    //   meetingObj.hosInfo || ({} as IHostInfo)
    // );
    setStartMeetLoading(true);
    const scheduleMeetRes = await scheduleHMSMeet(
      accountId,
      accountUUID,
      accountName,
      meetingObj,
      [meetingObj.formattedContactData],
      meetingObj.hosInfo || ({} as IHostInfo),
      isZoomEnabledAccount
    ).catch((error) => {
      setStartMeetLoading(false);
      return error;
    });
    if (isZoomEnabledAccount) {
      window.open(scheduleMeetRes.data.link, '_blank', 'noopener,noreferrer');
      setStartMeetLoading(false);
      closeModal();
      return;
    }
    const meeting: any = scheduleMeetRes?.data?.data;

    // const joinMeetingObj = {
    //   meetingId: meeting.data.id as string | number,
    //   joineeDetails: {
    //     email: meeting.data.host_email as string,
    //   },
    // };
    const joinMeetingObj = {
      userName:
        meeting.meetingData.hostInfo.first_name +
        ' ' +
        meeting.meetingData.hostInfo.last_name || '',
      authToken: meeting.meetingData.clientToken,
      meetingUrl: meeting?.meetingData?.externalMeetingData?.meetingUrl,
      settings: {
        isAudioMuted: false,
        isVideoMuted: true,
      },
      accountId: accountId,
      accountUUID: accountUUID,
      meetingId: meeting.meetingData.id,
    };

    const stringRep = JSON.stringify(joinMeetingObj);
    const encoded = window.btoa(stringRep);
    // window.open(`http://localhost:3000?token=${encoded}`, '_blank');
    window.open(`${COMMUNICATION_APP_BASE_URL()}?token=${encoded}`, '_blank', 'noopener,noreferrer');
    setStartMeetLoading(false);
    closeModal();
  };

  const setEmptyPatientList = () => {
    setCreateEmailConversationDrawerState((prev) => {
        return {
            ...prev,
            patientLists: [],
            isPatientDataLoading: false,
        };
    });
}
  const getPatientLists = async (searchPatient: string): Promise<any[]> => {
    if (searchPatient.length) {
        setCreateEmailConversationDrawerState((prev) => {
            return {
                ...prev,
                patientLists: [],
                selectedPatient: {} as IContact,
                loadingPatientData: true,
                errors: {
                    ...prev.errors,
                    patient: '',
                },
            };
        });
        let patientData = [] as IContact[];
        if (isCommunicationLocationHandlingEnabled) {
          const responseData = await getPatientListByLocationUuids({
            variables: {
              accountId: accountId,
              typeId: contactTypeUuid,
              isDeleted: false,
              searchString: `%${searchPatient}%`,
              locationUuids: allowedUserPracticeLocationUuids,
              searchConditions: [
                {name: {_ilike: `%${searchPatient}%`}},
                {email: {_ilike: `%${searchPatient}%`}},
                {person: {chosenName: {_ilike: `%${searchPatient}%`}}},
                ...(isAthena ? [{patient: {patientId: {_ilike: `%${searchPatient}%`}}}] : [])
              ]
            },
          });
          if (responseData?.data?.contacts?.length) {
            patientData = responseData?.data?.contacts || [];
          }
        } else {
          const responseData = await getPatientList({
            variables: {
              accountId: accountId,
              typeId: contactTypeUuid,
              isDeleted: false,
              searchString: `%${searchPatient}%`,
              searchConditions: [
                {name: {_ilike: `%${searchPatient}%`}},
                {email: {_ilike: `%${searchPatient}%`}},
                {person: {chosenName: {_ilike: `%${searchPatient}%`}}},
                ...(isAthena ? [{patient: {patientId: {_ilike: `%${searchPatient}%`}}}] : [])
              ]
            },
          });
          if (responseData?.data?.contacts?.length) {
            patientData = responseData?.data?.contacts || [];
          }
        }
        setCreateEmailConversationDrawerState((prev) => {
          return {
            ...prev,
            patientLists: patientData || [],
            isPatientDataLoading: false,
          };
        });
    } else {
        setEmptyPatientList();
    }

    return [];
};

const getPatientListOptElem = () => {
    return CreateEmailConversationDrawerState?.patientLists?.map((patient) => {
        return (
            <AutoCompleteOption
                key={patient.id}
                patientData={patient}
                value={patient.name}
            >
                 <MemberInfoListItem
                    contactData={patient}
                    isPatientSearchCard
                  />
            </AutoCompleteOption>
        );
    });
};
  useEffect(() => {
    getUsersData();
    getUserSubtitle();
    setMeetingObj(prev=> {
      return {
        ...prev,
        meetingName: `Meeting : ${userData?.name}  ${personData.name ? "<> " + personData.name :  ''}`,
      }
    })
  }, []);

  useEffect(()=> {
    if (props?.personData?.id) {
      checkForConsent(props?.personData);
    }
  }, [props?.personData?.id]);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');

  const finalWidth = isSideCarContext
    ? '100%'
    : isIPadMiniScreen || isIPadScreen
    ? width / 2
    : width / 3;

  const checkForConsent = async (contactData: any) => {
    if (!isConsentRequired) {
      setCreateEmailConversationDrawerState((prev) => ({
        ...prev,
        isConsentGiven: true,
      }));
      return;
    }
    if (contactData?.hasOwnProperty('contactConsents')) {
      const isGiven = isCallConsentGiven(contactData?.contactConsents, callConsentId);
      setCreateEmailConversationDrawerState((prev) => ({
        ...prev,
        isConsentGiven: isGiven,
      }));
    }
  };

  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      mask={isSideCarContext ? false : true}
      onClose={() => closeModal()}
      // title={<DisplayText textType={'Heading'} textLocalId={'startMeeting'} />}
      title={
        <ModalActionTitle
          title={'startMeeting'}
          titleColor={''}
          buttonList={[
            {
              ...(isSideCarContext && {
                show: true,
                id: 1,
                btnText: 'back',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  closeModal()
                },
              }),
            },
            {
              show: true,
              id: 1,
              btnText: 'startMeet',
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              isLoading: startMeetLoading,
              isDisabled: !CreateEmailConversationDrawerState.isConsentGiven,
              onClick: () => {
                if (meetingObj.meetingName) {
                  handleStartMeeting();
                  // props.onPersonActionPerformed(
                  //   PERSON_ACTION_CODES.CREATE_MEETING,
                  //   meetingObj
                  // );
                } else {
                  setMeetingObj((prev) => {
                    return {
                      ...prev,
                      error: 'Please enter Topic',
                    };
                  });
                }
              },
            },
          ]}
        />
      }
    >
      <View mx={0}>
        <View>
          <ModalActionInput
            fieldIs={'input'}
            label={'topic'}
            isRequired={true}
            isInvalid={meetingObj.error}
            value={meetingObj.meetingName}
            errors={meetingObj.error}
            errorText={meetingObj.error}
            onChangeText={(value: any) => {
              setMeetingObj((prev) => {
                return {
                  ...prev,
                  meetingName: value,
                  error: '',
                };
              });
            }}
            extraStyle={{flex: 1}}
          />
          {!props.isFromGlobal ? (
            <ModalActionAntSelect
              isRequired={true}
              marginTop={3}
              label={'patient'}
              value={meetingObj.patientName}
              mode="multiple"
              allowClear={true}
              placeholder="Select users"
              disabled={true}
              extraStyle={{flex: 1}}
            />
          ) : (
            <FormControl
              isInvalid={CreateEmailConversationDrawerState?.errors?.patient}
            >
              <FormControl.Label style={styles.formLabel} isRequired>
                <DisplayText
                  size={'smRegular'}
                  extraStyles={{color: Colors.FoldPixel.GRAY250}}
                  textLocalId={'channelPatient'}
                />
              </FormControl.Label>
              {CreateEmailConversationDrawerState.isPatientDataLoading && (
                <Spinner zIndex={1000} position={'absolute'} left={16} />
              )}

              <AutoComplete
                size="large"
                style={reactStyles.autoComplete}
                clearIcon={false}
                placeholder="Search Patients"
                onSearch={debounce(getPatientLists, 300)}
                //disabled={selectedPatient?.id ? true : false}
                value={CreateEmailConversationDrawerState.selectedPatient?.name}
                // onChange={(value, options: any) => {
                //   debounce(() => {
                //     getPatientLists(value);
                //   }, 300);
                // }}
                onSelect={(data: any, options: any) => {
                  props.selectedContact?.(options);
                  setMeetingObj((prev) => {
                    return {
                      ...prev,
                      meetingName: `Meeting : ${userData?.name}  ${
                        options?.patientData.name
                          ? '<> ' + options?.patientData.name
                          : ''
                      }`,
                    };
                  });
                  setCreateEmailConversationDrawerState((prev) => {
                    return {
                      ...prev,
                      selectedPatient: options?.patientData,
                    };
                  });
                  setMeetingObj((prev) => {
                    return {
                      ...prev,
                      patientName: options?.value,
                    };
                  });
                  checkForConsent(options?.patientData);
                }}
                notFoundContent={
                  CreateEmailConversationDrawerState.isPatientDataLoading && (
                    <Spin size="small" />
                  )
                }
              >
                {getPatientListOptElem()}
              </AutoComplete>

              {CreateEmailConversationDrawerState.errors.patient && (
                <FormControl.ErrorMessage
                  _text={{
                    fontSize: 'xs',
                    color: 'error.500',
                    fontWeight: 500,
                  }}
                >
                  {CreateEmailConversationDrawerState.errors.patient}
                </FormControl.ErrorMessage>
              )}
            </FormControl>
          )}

          <MeetingInviteVia
            personData={personData}
            onInviteViaChange={(inviteViaData)=>{
              setMeetingObj((prev)=>{
                return {
                  ...prev,
                  sendEmailToPatient: inviteViaData.sendEmail,
                  sendSmsToPatient: inviteViaData.sendSms,
                  sendNotificationToPatient: inviteViaData.sendAppNotification
                }
              })
            }}
          />

          {!CreateEmailConversationDrawerState?.isConsentGiven && (
            <HStack pl={1}>
              <Text fontSize={12} color={Colors.Custom.MissedCallIconColor}>
                {`Please give consent for "${
                  props?.isFromGlobal
                    ? CreateEmailConversationDrawerState?.selectedPatient?.name
                    : meetingObj.patientName
                }" ${PATIENT_CONSENT_ERROR}`}
              </Text>
            </HStack>
          )}

          <FormControl
            isRequired={true}
            isInvalid={error.internalUsers}
            marginTop={1}
            style={styles.formElement}
          >
            <FormControl.Label style={styles.formLabel}>
              <DisplayText
                size={'smRegular'}
                extraStyles={{color: Colors.FoldPixel.GRAY250}}
                textLocalId={'addUsers'}
              />
            </FormControl.Label>
            <CustomUserSearch
              userSelectRef={undefined}
              isRequired={false}
              label={''}
              disabledUsers={[]}
              selectedUsersData={CreateEmailConversationDrawerState.selectedUserData}
              userSearchType={
                isCommunicationLocationHandlingEnabled
                  ? USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER
                  : USER_SEARCH_TYPE.DEFAULT
              }
              onChange={(selectedUsersUUIDs: string[], selectedUsersData) => {
                const selectedUserIds = selectedUsersData?.map((user) => {
                  return user?.userId;
                });
                setMeetingObj((prev) => {
                  return {
                    ...prev,
                    selectedInvitee: selectedUserIds || [],
                  };
                });
                setCreateEmailConversationDrawerState((prev) => {
                  return {
                    ...prev,
                    selectedUserData: selectedUsersData || [],
                  }
                })
                handleAddInvite(selectedUserIds || []);
              }}
            />
            {error.internalUsers && (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {error.internalUsers}
              </FormControl.ErrorMessage>
            )}
          </FormControl>
          <View
            style={styles.checkboxContainer}
          >
            <ModalActionCheckbox
              isChecked={meetingObj.sendEmail}
              onChange={(value) => {
                setMeetingObj((prev) => {
                  return {
                    ...prev,
                    sendEmail: value,
                  };
                });
              }}
              value={'sendEmail'}
            />
            <DisplayText
              size={'smMedium'}
              extraStyles={{
                color: Colors.FoldPixel.GRAY400,
                marginLeft: 10,
              }}
              textLocalId={'sendEmailTo'}
              testID={'sendEmailTo'}
            />
          </View>
        </View>
        {/* <Button.Group
          mx={{
            base: 'auto',
            md: 0,
          }}
          justifyContent={'flex-end'}
          width={'100%'}
        >
          <ModalActionBtn
            btnText="startMeet"
            btnStype={BUTTON_TYPE.PRIMARY}
            onClickAction={() => {
              if (meetingObj.meetingName) {
                handleStartMeeting();
                // props.onPersonActionPerformed(
                //   PERSON_ACTION_CODES.CREATE_MEETING,
                //   meetingObj
                // );
                closeModal();
              } else {
                setMeetingObj((prev) => {
                  return {
                    ...prev,
                    error: 'Please enter Topic',
                  };
                });
              }
            }}
          />
        </Button.Group> */}
      </View>
    </Drawer>
  );
};

export default MeetingView;

