import React, {useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {Text} from 'native-base';
import {IPatientNoteCardProps} from '../interfaces';
import {IFormState} from '../PatientNotes/components/DocumentationView';
import {getCapabilitiesFormComponents} from '../PatientNotes/components/DocumentationViewHelper';
import {getResourceAbilities} from '../../../../utils/capabilityUtils';
import {FHIR_RESOURCE} from '../../../../constants/FhirConstant';
import Header from '../PatientNotes/components/Header/Header';
import {DocStatus} from '../PatientNotes/interfaces';
import {getTitle} from '../PatientNotes/components/AddOrUpdateTemplate/AddOrUpdateTemplateHelper';
import {HStack, ScrollView} from 'native-base';
import {renderContent} from '../PatientNotes/components/ReadOnlyDocumentView/Helper';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { IFormCommonData } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { getBooleanFeatureFlag, replaceHashValueToString } from '../../../../utils/commonUtils';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { Colors } from '../../../../styles/Colors';
import LockKeyholeIcon from '../../../../assets/Icons/LockKeyholeIcon';
import { useIntl } from 'react-intl';
import LockKeyHoleSvg from '../../../common/Svg/LockKeyHoleSvg';

const ReadOnlyPatientNoteView = (props: {
  cardData: IPatientNoteCardProps;
  ehrCapabilities: any;
  handleGoBack: () => void;
  locationId: string | undefined
}) => {
  const context = useContext(CommonDataContext) as IFormCommonData;
  const userSettings = context.userSettings;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isNoteLocked = isMultiTenancyEnabled && !props.cardData?.isContactActive;
  const intl = useIntl();
  const {cardData, locationId} = props;
  const [formState, setFormState] = useState<IFormState>();
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    locationId
  );
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };

  useEffect(() => {
    setFormState(
      getCapabilitiesFormComponents(elationFormData, cardData.formId || '', {
        formContent: cardData.formContent,
        formName: cardData.formName,
      })
    );
  }, []);
  const isSignedNote = () => cardData?.status === DocStatus.FINAL;
  return (
    <View>
      {isWeb() && (
        <Header
          showBackButton
          form={{name: formState?.name || '', id: formState?.id || ''}}
          showTemplateSelection={!cardData?.resourceId}
          backButtonAction={() => {
            props.handleGoBack();
          }}
          title={getTitle(
            isSignedNote(),
            cardData.createdByUserName || '',
            cardData.signedByUserName || '',
            cardData.formName || '',
            cardData.status || '',
            cardData.signedByUserId || '',
            cardData.authorUuid
          )}
          currentTemplateId={formState?.id}
          locationId={locationId}
        />
      )}
      {isNoteLocked && (
        <HStack padding={2} backgroundColor={Colors.Custom.infoBannerBgColor} alignItems={'center'} space={1}>
          <LockKeyHoleSvg size={14} customStrokeColor={Colors.FoldPixel.INFO_COLOR}/>
          <Text
            style={{
              fontSize: 12,
              color: Colors.FoldPixel.GRAY400,
              fontWeight: '400',
            }}
            paddingX={isWeb() ? 0 : 1}
          >
            {replaceHashValueToString(
              {name: 'Patient Note'},
              intl.formatMessage({id: 'onlyViewAccessMsg2'})
            )}
          </Text>
        </HStack>
      )}

      <ScrollView flex={isWeb() ? 1 : undefined} h="100%" mx={5} mt={5}>
        {formState && renderContent(formState)}
      </ScrollView>
    </View>
  );
};

export default ReadOnlyPatientNoteView;
