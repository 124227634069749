import { USER_ROLE_CODES } from '../../../../../constants/MlovConst';
import { ILocationData } from '../AccountLocations/interface';
import {IUser} from '../interfaces';
import {ehrCodeDisplay, PractitionerData} from '../InviteUserDrawer/interfaces';
import {IAccountRole, IAccountRoleResp} from './interface';

export const getEhrPracticeNameName = (
  userData: any,
  practitionerData: PractitionerData[],
  ehrList: ehrCodeDisplay[],
  currentCodeEHR: string
) => {
  if (!userData.externalUserId) {
    return '';
  }
  const selectedUser = practitionerData.find((item) => {
    return item.id === userData.externalUserId;
  });
  return `${selectedUser?.displayName} ${getEhrDisplayName(ehrList, currentCodeEHR)}`;
};

export const getEhrDisplayName = (
  ehrList: ehrCodeDisplay[],
  currentCodeEHR: string
) => {
  return (
    (ehrList || []).find((ehr) => ehr.code === (currentCodeEHR))
      ?.display || ''
  );
};


export const isAddPracticeBtnActive = (accountUserRoleList: IAccountRole[]) => {
  let isAddPracticeActive = true;
  accountUserRoleList.forEach((accountRole) => {
    if (!(accountRole.accountUuid || accountRole.locationUuid) || !accountRole?.rolesToAdd?.length) {
      isAddPracticeActive = false
    }
  })
  return isAddPracticeActive
}
export const validAccountUserLocation = (accountUserRoleList: IAccountRole[])=> {
  return accountUserRoleList.filter(locationRole=> !(locationRole.isDeleted === true))
}
export const getAssociatedAccountList = (user: IUser) => {
  const accountRoleList: IAccountRole[] = []
  if (user) {
    const {userRoles, userPracticeLocations, externalUserMap} = user
      userPracticeLocations?.forEach((location, index)=> {
        if (!location.accountLocations?.length) {
          return
        }
        const externalUserId = externalUserMap?.find((externalUser)=>{
          return externalUser.locationGroupId === location.accountLocations?.[0]?.locationGroupId
        })
        const userRoleList = userRoles?.filter((userRole) => {
          return userRole?.accountLocationId === location?.accountLocationUuid
        })?.map((userRole) => userRole?.userRole?.id) || []
        const obj: IAccountRole = {
          locationUuid: location.accountLocationUuid,
          locationGroupId: location.accountLocations?.[0]?.locationGroupId,
          userId: user.id || -1,
          rolesToAdd: userRoleList,
          roles: userRoleList,
          editedRoles: userRoleList,
          editMode: true,
          externalUserId: externalUserId?.externalUserId || '',
          id: index
        }
        accountRoleList.push(obj)
      })
  }
  return accountRoleList
}

export const getGlobalRoles = (user: IUser) => {
  const {userRoles} = user
  const userRoleList = userRoles?.filter((userRole) => {
    return !userRole?.accountLocationId
  })?.map((userRole) => {
    if (!userRole?.userRole?.id) {
      return undefined;
    }
    return userRole?.userRole?.id;
  }).filter((id): id is string => id !== undefined) || []
  return userRoleList
}

export const getFilteredLocations = (args:{
  locationUuid?: string,
  locationAndRolesList: IAccountRole[],
  masterLocation: ILocationData[]
}) => {
  const {locationUuid, masterLocation, locationAndRolesList} = args
  const selectedLocations = locationAndRolesList
    .filter(
      (locationAndRoles) =>
        locationAndRoles.locationUuid !== null &&
        locationAndRoles.locationUuid !== locationUuid && !(locationAndRoles.isDeleted === true)
    )
    .map((locationAndRoles) => locationAndRoles.locationUuid);
  return masterLocation.filter(
    (location) => !selectedLocations.includes(location.uuid)
  );
};

export const getUserAccountRoleId = (associatedAccounts: IAccountRole[]) => {
  const rolesToAdd = associatedAccounts
    .flatMap((item) => item.rolesToAdd)
    .filter((role): role is string => role !== undefined);
  const userAccountRoleIds: string[] = [...new Set(rolesToAdd)];
  return userAccountRoleIds
};

export const processRoles = (roles: IAccountRoleResp[], excludeCode: string) => {
  return roles
    .filter(role => role.customRoleCode !== excludeCode)
    .map(role => ({
      id: role.id,
      code: role.customRoleCode,
      value: role.roleName,
      roleId: role.roleId
    }));
};

export const getEmployerRoles = (roles: IAccountRoleResp[]) => {
  return roles
    .filter(role => role.customRoleCode === USER_ROLE_CODES.EMPLOYER)
    .map(role => ({
      id: role.id,
      code: role.customRoleCode,
      value: role.roleName,
      roleId: role.roleId
    }));
};