import { ADDITIONAL_ATTRIBUTE_KEY } from '../../../../../../common/MemebersView/constant';
import {IFormComponentProps} from '../../CustomWrapper/CustomWrapper';

export interface IDischargeDetailsFormProps {
  contactCareProgramId?: string;
  component: any;
  validateRef: any;
  disableForm: boolean;
  onChange: (componentValue: any, flag?: any) => void;
  handleUpdateCareProgram: (actionCode?: string, actionData?: any) => void;
}

export interface ICareProgramAttributeValue {
  valueString: string;
}

export interface ICareProgramAdditionalAttribute {
  attributeKey: string;
  attributeValue: ICareProgramAttributeValue;
  attributeValueDataTypeCode: string;
  source: string;
}

export interface IDischargeDetailsParams {
  contactCareProgramId: string;
  componentSelectedValue?: any;
  component: any
  onChange: (componentValue: any, flag?: any) => void;
  handleUpdateCareProgram: (action: string, value: string) => void
}

interface IDischargeDetailsField<T = any> {
  value: T;
  source: string;
}

export interface IDischargeDetailsFormState {
  primaryDiagnosis: IDischargeDetailsField<string[]>;
  dischargeDiagnosis: IDischargeDetailsField<string[]>;
  admissionDate: IDischargeDetailsField<string>;
  dischargeDate: IDischargeDetailsField<string>;
  lengthOfStay: IDischargeDetailsField<number>;
  lengthOfStayUnit: IDischargeDetailsField<string>;
  dischargeDisposition: IDischargeDetailsField<string>;
  admissionType: IDischargeDetailsField<string>;
  predictiveReAdmissionRiskScore?: IDischargeDetailsField<string>;
  hospitalName: IDischargeDetailsField<string>;
  selectedDischargeDispostionOption: DischargeDisposition;
}

export interface IDischargeDetailsComponentValue {
  dischargeDetails: IDischargeDetailsFormState;
}

export interface IDischargeDetailsSources {
  hospitalName?: string;
  primaryDiagnosis?: string;
  admissionDate?: string;
  dischargeDate?: string;
  admissionType?: string;
  [key: string]: string | null | undefined;
}

export interface IComponentState {
  loadingStatus: 'loading' | 'loaded' | 'error';
  diagnosisCodesLoading: boolean;
  sources: IDischargeDetailsSources;
  primaryDiagnosisOptions: IDiagnosisCodes['primaryDiagnosisOptions'];
  dischargeDiagnosisOptions: IDiagnosisCodes['dischargeDiagnosisOptions'];
  complexity: string;
  showErrors: boolean;
}

export interface IDiagnosisCodes {
  primaryDiagnosisOptions: any[];
  dischargeDiagnosisOptions: any[];
}
export const FIELD_TO_ATTRIBUTE_KEY_MAP: Record<string, string> = {
  admissionDate: ADDITIONAL_ATTRIBUTE_KEY.ADMITTED_DATE,
  dischargeDate: ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DATE,
  hospitalName: ADDITIONAL_ATTRIBUTE_KEY.FACILITY,
  primaryDiagnosis: ADDITIONAL_ATTRIBUTE_KEY.PRIMARY_DIAGNOSIS_CODE,
  admissionType: ADDITIONAL_ATTRIBUTE_KEY.ADMISSION_TYPE,
  dischargeDisposition: ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DISPOSITION,
  dischargeDiagnosis: ADDITIONAL_ATTRIBUTE_KEY.DISCHARGE_DIAGNOSIS_CODE,
};

export enum DischargeDisposition {
  HOME = 'home',
  ER = 'er',
  EXPIRED = 'expired',
  HOSPICE = 'hospice',
  LONG_TERM_CARE = 'longTermCare',
  SNF = 'snf',
  READMITTED = 'readmitted',
  STILL_INPATIENT = 'stillInpatient',
  OTHER = 'other'
}

export const dischargeDispositionOptions = [
  { value: DischargeDisposition.HOME, label: 'Home' },
  { value: DischargeDisposition.ER, label: 'ER' },
  { value: DischargeDisposition.EXPIRED, label: 'Expired' },
  { value: DischargeDisposition.HOSPICE, label: 'Hospice' },
  { value: DischargeDisposition.LONG_TERM_CARE, label: 'Long Term Care' },
  { value: DischargeDisposition.SNF, label: 'SNF' },
  { value: DischargeDisposition.READMITTED, label: 'Readmitted' },
  { value: DischargeDisposition.STILL_INPATIENT, label: 'Still Inpatient' },
  { value: DischargeDisposition.OTHER, label: 'Other' },
];


export const Value = {
  OTHER: 'other'
}
