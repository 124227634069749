import ContactsQueries from '../../../../services/Contacts/ContactsQueries';
import {useLazyQuery} from '@apollo/client';
import {useGetDefaultContactProfile} from '../../CustomHooks/useGetDefaultContactProfile';
import {
  IContact,
  IContactProfile,
} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';

export const usePatientContactProfileHook = () => {
  const [getContactProfiles] = useLazyQuery(
    ContactsQueries.GET_CONTACT_PROFILES_BY_CONTACT_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const {getContactProfilesByUserAllowedLocationIds} =
    useGetDefaultContactProfile();

  const handleInitialData = async (contactData: IContact) => {
    try {
      if (
        contactData?.contactProfiles &&
        contactData?.contactProfiles?.length > 0
      ) {
        const contactProfiles = processContactProfiles(contactData?.contactProfiles);
        return {
          contactProfiles: contactProfiles,
          selectedProfile: contactData?.contactProfile,
        };
      } else {
        const response = await getContactProfiles({
          variables: {
            contactUuid: contactData?.uuid,
          },
        });
        const {contactProfiles, defaultContactProfile} =
          getContactProfilesByUserAllowedLocationIds(
            response.data?.contactProfiles,
            contactData?.uuid
          );
        return {
          contactProfiles: contactProfiles,
          selectedProfile: defaultContactProfile,
        };
      }
    } catch (error) {
      return {
        selectedProfile: undefined,
        contactProfiles: [],
      };
    }
  };

  const processContactProfiles = (contactProfiles: IContactProfile[]) => {
    if (!contactProfiles || contactProfiles?.length === 0) {
      return [];
    }
    const processedContactProfiles = contactProfiles?.reduce(
        (acc, profile) => {
          // Check if profile with same ehrPatientId already exists
          const existingProfile = acc.find(
            (p) => p.ehrPatientId === profile?.ehrPatientId
          );
          if (!existingProfile) {
            acc.push({
              id: profile?.id || '',
              ehrPatientId: profile?.ehrPatientId || '',
              patientUuid: profile?.patientUuid || '',
              accountLocationUuid: profile?.accountLocationUuid || '',
              accountLocation: {
                uuid: profile?.accountLocation?.uuid || '',
                practiceLocation: {
                  name:
                    profile?.accountLocation?.practiceLocation?.name || '',
                },
              },
            } as IContactProfile);
          }
          return acc;
        },
        [] as IContactProfile[]
      );
      return processedContactProfiles;
  }

  return {
    handleInitialData,
    processContactProfiles
  };
};
