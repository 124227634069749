import React, {useEffect, useState} from 'react';
import {NativeBaseProvider} from 'native-base';
import {getNativeBaseTheme} from '../../../../../../theme/NativeBaseTheme';
import LocalStorage from '../../../../../../utils/LocalStorage';
import {ApolloProvider, useLazyQuery, useQuery} from '@apollo/client';
import {ApolloClient} from '../../../../../../services';
import {I18nProvider} from '../../../../../../i18n';
import {ConfigProvider} from 'antd';
import {getAntdBaseTheme} from '../../../../../../theme/AntdBaseTheme';
import {CARESTUDIO_PROXY_TO_CRM_CONTEXT} from '../../../../../../constants/Configs';
import {
  CommonDataContext,
  ICommonData,
} from '../../../../../../context/CommonDataContext';
import ContactsQueries from '../../../../../../services/Contacts/ContactsQueries';
import {onError} from '@apollo/client/link/error';
import FormComponentLoader from './FormComponentLoader/FormComponentLoader';
import {IEhrCapability} from '../../../../../../Interfaces';
import {getEhrCapabilitiesWithResource} from '../../../../../../services/CommonService/AidBoxService';
import {CapabilityResource, FormError} from './CustomComponentHelper';
import {Resource} from '../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces';
import { ComponentType } from '../Diagnosis/interfaces';
import { FHIR_RESOURCE } from '../../../../../../constants/FhirConstant';
import {ISidecarContext} from '../../../../../SideCar/interfaces';
import { ICareProgramData } from '../../../../ContactCareProgram/interface';
import ToastProvider from '../../../../../Toast/ToastProvider';
import { IContactProfile } from '../../../../TeamInbox/Conversations/interfaces';

export interface IFormComponentProps {
  validateRef: any;
  disabled: boolean;
  component: any;
  options: any;
  setValueRef?: any;
  onChange: (componentValue: any) => void;
  isPatientAddress?: boolean;
  componentType?:ComponentType;
  fileService?: any;
  value?: any;
  isBuilderMode?: boolean;
}

export enum FormViewType {
  detail = 'detail',
  compact = 'compact',
  graph = 'graph',
  note = 'note',
  widget = 'widget'
}

export enum FormContext {
  patientForm = 'patientForm',
  patientProfile = 'patientProfile',
  patientNotes = 'patientNotes',
  recentReportedView = 'recentReportedView',
  patientCarePlan = 'patientCarePlan'
}

export interface IFormCommonData extends ICommonData {
  patientId?: string;
  contactId?: string;
  contactIntId?: string;
  tenantId?: string;
  headers: {[index: string]: any};
  isBuilderMode: boolean;
  hasLoggedInContext: boolean;
  capabilities?: IEhrCapability;
  formError?: FormError;
  backgroundSaveEnabled: boolean;
  componentView: FormViewType;
  showMoreMaxCount: number;
  defaultCollapse: boolean;
  questionnaireList: Resource[];
  locationId?: string;
  formContext: FormContext;
  openEhrUrl?: string;
  ordering?: {
    isLabEnabled: boolean;
    isRadEnabled: boolean;
    isMedEnabled: boolean;
  }
  reasonForVisitData?: any[];
  isPreviewMode?: boolean;
  fetchDataForPreview?: boolean;
  updateLoadingStatus?: (componentKey: string, isLoading: boolean, isDataAvailable: boolean) => void;
  // formPrefilledByFoldProgressByComponent?: {[index: string]: number};
  updateFormPrefilledByFoldProgress?: (componentKey: string, data: any) => void;
  tenantName?: string,
  organizationCapability?: IEhrCapability
  ccmDate?: string;
  isDoNotAllowToReplaceNextLine?: boolean;
  sidecarContext?: ISidecarContext;
  contactCareProgramId?: string;
  careProgramData?: ICareProgramData;
  contactCarePlanId?: string;
  contactDetails?: {
    contactId?: string;
    contactUuid?: string;
    patientId?: string;
    accountLocationUuid?: string;
    contactName?: string;
  }
  ehrActivePatient?: IContactProfile;
  isMobileView?: boolean;
  showDeleteButton?: boolean;
  carePlanDetailsStartDateTime?: string;
  foldContactId?: string;
  proxyHeaders?: any;
  isBuilderPreviewMode?: boolean;
  isPrintForm?: boolean;
  tpaCareAdvocateId?: string;
  widgetType?: string;
  isCareProgramEnabled?: boolean;
  isSidecar?: boolean;
  goals?: any[];
  relevantVitals?: any[];
  onDeleteClick?: (component: any) => void;
  handleUpdateCareProgram?: (actionCode?: string, actionData?: any) => void;
  isPrintMode?: boolean;
}

const CustomWrapper = (props: {
  children: JSX.Element;
  formOptionData?: any;
  capabilityList?: CapabilityResource[];
}) => {
  const [theme, setTheme] = useState<any>({});

  useEffect(() => {
    LocalStorage.getItem('accountTheme').then((data) => {
      if (data) {
        const defaultTheme = JSON.parse(data);
        setTheme(defaultTheme);
        const customThemeColors = getAntdBaseTheme(defaultTheme);
        ConfigProvider.config({
          theme: customThemeColors,
        });
      }
    });
  }, []);

  const errorLink = onError((data) => {
    //
  });

  return (
    <I18nProvider>
      <ApolloProvider client={ApolloClient.getClient(errorLink)}>
        <ConfigProvider>
          <NativeBaseProvider theme={getNativeBaseTheme(theme)}>
            <ToastProvider>
              <CustomCommonDataWrapper
                formOptionData={props.formOptionData}
              capabilityList={props.capabilityList}
            >
              {props.children}
              </CustomCommonDataWrapper>
            </ToastProvider>
          </NativeBaseProvider>
        </ConfigProvider>
      </ApolloProvider>
    </I18nProvider>
  );
};

const CustomCommonDataWrapper = (props: {
  children: JSX.Element;
  formOptionData?: any;
  capabilityList?: CapabilityResource[];
}) => {
  const headers = {
    ...(props.formOptionData.accountUUID && {
      'account-uuid': props.formOptionData.accountUUID,
    }),
    ...(props.formOptionData.foldAccessToken && {
      'fold-access-token': props.formOptionData.foldAccessToken,
    }),
  };

  const [loading, setLoading] = useState<{
    patient: boolean;
    capability: boolean;
  }>({patient: false, capability: false});

  const getEHRCapability = (
    capabilityList: string[]
  ): IEhrCapability | undefined => {
    if (props.formOptionData?.ehrCapabilities?.length) {
      const capabilities: IEhrCapability[] =
        props.formOptionData.ehrCapabilities;
      const matchingCapability = capabilities.find(
        (item) =>
          item.resourceName && capabilityList.includes(item.resourceName)
      );
      if (matchingCapability) {
        return matchingCapability;
      }
    }
  };

  const getCapabilityFromProps = () => {
    if (props.capabilityList?.length) {
      return getEHRCapability(props.capabilityList);
    }
  };

  const getOrderingCapabilityFromProps = () => {
    if (props.formOptionData?.ehrCapabilities?.length) {
      const list: IEhrCapability[] = props.formOptionData.ehrCapabilities;
      const serviceRequest = list.find((item) => item.resourceName === FHIR_RESOURCE.SERVICE_REQUEST);
      const medicationRequest = list.find((item) => item.resourceName === FHIR_RESOURCE.MEDICATION_REQUEST);
      return {
        isLabEnabled: serviceRequest?.abilities?.isEnabled || false,
        isRadEnabled: serviceRequest?.abilities?.isEnabled || false,
        isMedEnabled: medicationRequest?.abilities?.isEnabled || false,
      }
    }
    return {
      isLabEnabled: false,
      isRadEnabled: false,
      isMedEnabled: false,
    }
  };
  const [commonData, setCommonData] = useState<IFormCommonData>({
    CARE_STUDIO_MLOV: props.formOptionData.CARE_STUDIO_MLOV || {},
    CLOUD_TELEPHONY_MLOV: props.formOptionData.CLOUD_TELEPHONY_MLOV || {},
    MLOV: props.formOptionData.MLOV || {},
    userPermissions: props.formOptionData.userPermissions || [],
    userSettings: props.formOptionData.userSettings,
    currentUserRoles: props.formOptionData.currentUserRoles || [],
    userRoleCategories: props.formOptionData.userRoleCategories || [],
    patientId: props.formOptionData.patientId,
    contactId: props.formOptionData.contactId,
    contactIntId: props.formOptionData?.contactIntId,
    tenantId: props.formOptionData.accountUUID,
    tenantName: props.formOptionData?.tenantName,
    isPreviewMode: props.formOptionData?.renderMode === 'html' ? true : false,
    fetchDataForPreview: props.formOptionData?.fetchDataForPreview || false,
    updateLoadingStatus: props.formOptionData?.updateLoadingStatus,
    // formPrefilledByFoldProgressByComponent: props.formOptionData?.formPrefilledByFoldProgressByComponent,
    updateFormPrefilledByFoldProgress:
      props.formOptionData?.updateFormPrefilledByFoldProgress,
    isBuilderMode: props.formOptionData?.attachMode === 'builder',
    hasLoggedInContext: !!props.formOptionData.foldAccessToken,
    backgroundSaveEnabled: props.formOptionData.backgroundSaveEnabled || false,
    componentView: props.formOptionData.componentView || FormViewType.detail,
    showMoreMaxCount: props.formOptionData.showMoreMaxCount || 5,
    defaultCollapse: props.formOptionData.defaultCollapse || false,
    questionnaireList: props.formOptionData.questionnaireList || [],
    reasonForVisitData: props.formOptionData.reasonForVisitData || [],
    capabilities: getCapabilityFromProps(),
    headers,
    locationId: props.formOptionData?.accountLocationUuid,
    formContext: props.formOptionData?.formContext || FormContext.patientProfile,
    openEhrUrl: props.formOptionData?.openInEhrUrl,
    ordering: getOrderingCapabilityFromProps(),
    mobileAppDimension: {
      height: 0,
      width: 0,
    },
    organizationCapability: props.formOptionData?.ehrCapabilities?.find(
      (item: IEhrCapability) => item.resourceName === FHIR_RESOURCE.ORGANIZATION
    ),
    ccmDate: props?.formOptionData?.ccmDate,
    ehrCapabilities:  props.formOptionData.ehrCapabilities,
    userData: props.formOptionData.userData,
    sidecarContext: props.formOptionData.sidecarContext,
    accountConfigCapabilities: props.formOptionData.accountConfigCapabilities,
    loggedInUserLocationDetails: props?.formOptionData?.loggedInUserLocationDetails,
    contactCareProgramId: props.formOptionData.contactCareProgramId,
    careProgramData: props.formOptionData.careProgramData,
    contactDetails: props?.formOptionData?.contactDetails,
    contactCarePlanId: props.formOptionData.contactCarePlanId,
    isMobileView: props.formOptionData.isMobileView || false,
  });

  const [getPatientData] = useLazyQuery(
    ContactsQueries.GET_PATIENT_ID_BY_CONTACT_UUID,
    {
      fetchPolicy: 'no-cache',
      context: {
        ...(!commonData.hasLoggedInContext && {
          service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        }),
        headers,
      },
      variables: {
        contactId: props.formOptionData.contactId,
      },
    }
  );

  const fetchCapability = (capabilityList: string[]) => {
    if (commonData.capabilities) {
      return;
    }

    setLoading((prev) => ({...prev, capability: true}));
    getEhrCapabilitiesWithResource(
      capabilityList[0],
      !commonData.hasLoggedInContext,
      (response) => {
        if (response?.data?.length) {
          setCommonData((prev) => ({
            ...prev,
            capabilities: response.data[0],
          }));
        } else {
          setCommonData((prev) => ({
            ...prev,
            formError: FormError.configurationDataAPIFail,
          }));
        }
        setLoading((prev) => ({...prev, capability: false}));
      },
      () => {
        setCommonData((prev) => ({
          ...prev,
          formError: FormError.configurationDataAPIFail,
        }));
        setLoading((prev) => ({...prev, capability: false}));
      },
      headers,
      props.formOptionData?.accountLocationUuid
    );
  };

  const fetchPatientData = (contactId: string) => {
    setLoading((prev) => ({...prev, patient: true}));
    getPatientData({variables: {contactId}})
      .then((response) => {
        if (response?.data?.contacts) {
          const contact = response.data.contacts[0];
          const patientId =
            contact.patient?.patientId || contact.patient?.patientUuid;
          const locationId =
            contact.contactPracticeLocations?.length > 0
              ? contact.contactPracticeLocations[0].accountLocation?.uuid || ''
              : '';
          setCommonData((prev) => ({
            ...prev,
            patientId,
            formError: !patientId ? FormError.noPatientIdFound : prev.formError,
            locationId: locationId,
            // formError: FormError.noPatientIdFound,
          }));
        } else {
          setCommonData((prev) => ({
            ...prev,
            formError: FormError.existingDataAPIFail,
          }));
        }
        setLoading((prev) => ({...prev, patient: false}));
      })
      .catch(() => {
        setLoading((prev) => ({...prev, patient: false}));
        setCommonData((prev) => ({
          ...prev,
          formError: FormError.existingDataAPIFail,
        }));
      });
  };

  useEffect(() => {
    if (
      props.formOptionData.contactId &&
      !props.formOptionData.patientId &&
      props.formOptionData?.formContext !== FormContext.patientCarePlan
    ) {
      fetchPatientData(props.formOptionData.contactId);
    }
    if (props.capabilityList?.length) {
      fetchCapability(props.capabilityList);
    }
  }, []);

  useEffect(() => {
    if (props.formOptionData?.questionnaireList?.length) {
      setCommonData((prev) => ({
        ...prev,
        questionnaireList: props.formOptionData?.questionnaireList,
      }));
    }
  }, [props.formOptionData?.questionnaireList]);

  const isComponentLoading = loading.capability || loading.patient;

  return (
    <CommonDataContext.Provider value={commonData}>
      {!isComponentLoading && props.children}
      {isComponentLoading && <FormComponentLoader />}
    </CommonDataContext.Provider>
  );
};

export default CustomWrapper;
