import React, { useEffect, useState } from "react";
import { camelCase, cloneDeep } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { DataGridSchema } from "../Schema/ComponentsSchema";
import { DataGridComponent } from "../BaseComponents/BaseComponentInterface";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useFormRendererContext } from "../Context/FormRenderer.context";
import Description from "../BaseComponents/Description";
import { SortableRow } from "./DataGrid/SortableRow";
import FoldButtonV2, { ButtonType } from "../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2";
import { v4 as uuidV4 } from 'uuid';

export const DataGridFieldFormElement: FormElement = {
  type: DataGridSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(DataGridSchema),
    key: getUniqueKey(map, DataGridSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(DataGridSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    label: DataGridSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  return <></>;
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: any[];
  isReadOnly?: boolean;
}) {
  const { state } = useFormRendererContext();
  const [rows, setRows] = useState<any[]>(() => {
    if (defaultValue?.length) return defaultValue;
    if (elementInstance.selectedValue?.length) return elementInstance.selectedValue;
    if (elementInstance.defaultValue?.length) return elementInstance.defaultValue;

    // Create initial empty row if not readonly
    if (!isReadOnly) {
      const emptyRow = (elementInstance as DataGridComponent).components.reduce((acc, comp) => {
        acc[comp.key] = '';
        return acc;
      }, {} as Record<string, string>);
      return [emptyRow];
    }
    return [];
  });

  const [rowIds, setRowIds] = useState<string[]>(() =>
    Array(rows.length).fill(null).map(() => crypto.randomUUID())
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Add a small threshold to prevent accidental drags
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleAddRow = () => {
    const emptyRow = (elementInstance as DataGridComponent).components.reduce((acc, comp) => {
      acc[comp.key] = '';
      return acc;
    }, {} as Record<string, string>);

    setRows(prevRows => [...prevRows, emptyRow]);
    setRowIds(prevIds => [...prevIds, crypto.randomUUID()]);
    submitValue?.(elementInstance.key, [...rows, emptyRow]);
  };

  const handleDeleteRow = (index: number) => {
    const newRows = rows.filter((_, i) => i !== index);
    const newRowIds = rowIds.filter((_, i) => i !== index);
    setRows(newRows);
    setRowIds(newRowIds);
    submitValue?.(elementInstance.key, newRows);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = rowIds.indexOf(active.id as string);
    const newIndex = rowIds.indexOf(over.id as string);

    const newRows = arrayMove(rows, oldIndex, newIndex);
    const newRowIds = arrayMove(rowIds, oldIndex, newIndex);

    setRows(newRows);
    setRowIds(newRowIds);
    submitValue?.(elementInstance.key, newRows);
  };

  const handleRowChange = (index: number, key: string, value: string) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[index] = { ...newRows[index], [key]: value, value: camelCase(value) };
      submitValue?.(elementInstance.key, newRows);
      return newRows;
    });
  };

  return (
    <ComponentView
      elementInstance={elementInstance}
      rows={rows}
      rowIds={rowIds}
      onAddRow={handleAddRow}
      onDeleteRow={handleDeleteRow}
      onDragEnd={handleDragEnd}
      onRowChange={handleRowChange}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      sensors={sensors}
    />
  );
}

function ComponentView({
  elementInstance,
  rows = [],
  rowIds = [],
  onAddRow,
  onDeleteRow,
  onDragEnd,
  onRowChange,
  errorMessage,
  isReadOnly,
  sensors,
}: {
  elementInstance: FormElementInstance;
  rows: any[];
  rowIds: string[];
  onAddRow?: () => void;
  onDeleteRow?: (index: number) => void;
  onDragEnd?: (event: DragEndEvent) => void;
  onRowChange?: (index: number, key: string, value: string) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
  sensors?: any;
}) {
  const { label, validate, description, tooltip, allowToShare, components = [] } = elementInstance as DataGridComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;

  return (
    <div
      className={classNames(
        'flex w-full flex-col gap-2',
        isReadOnly ? (isAllowToShare ? 'page-break' : 'disallow-to-share') : ''
      )}
    >
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />

      {!isReadOnly && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={rowIds}
            strategy={verticalListSortingStrategy}
          >
            <div className="flex flex-col gap-2">
              {rows.map((row, index) => (
                <SortableRow
                  key={rowIds[index]}
                  id={rowIds[index]}
                  components={components}
                  rowData={row}
                  onDelete={() => onDeleteRow?.(index)}
                  onChange={(key, value) => onRowChange?.(index, key, value)}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      )}

      {!isReadOnly && (
        <div className="flex gap-2 flex-start">
          <FoldButtonV2
            label="+ Add"
            buttonType={ButtonType.primary}
            onPress={onAddRow}
          />
        </div>
      )}

      {errorMessage && <span className="error-message">{errorMessage}</span>}

      {description && <Description description={description} />}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  return <></>;
}
