import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { IUserSettingsByCode } from "../../services/UserSettings/interfaces";
import { CARESTUDIO_APOLLO_CONTEXT } from '../../constants/Configs';
import { GetAccountSettingsByCodes } from '../../services/UserSettings/UserSettingQueries';
import { decodeTpaCareAdvocateParams } from '../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';

const useUserSettings = (args: {
  codes: string[];
  tenantId?: string;
  token?: string;
}) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IUserSettingsByCode>({});

  const [getAccountSettingsByCodes] = useLazyQuery(
    GetAccountSettingsByCodes,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  useEffect(() => {
    callFetchAccountSettings();
  }, [args.codes.length, args.tenantId, args.token]);

  const callFetchAccountSettings = async () => {
    const params = args.token ? await decodeTpaCareAdvocateParams(args.token || '') : undefined;
    if (args.codes.length > 0 && (args.tenantId || params?.accountId)) {
      fetchAccountSettings(args.tenantId || params?.accountId);
    }
  }

  const fetchAccountSettings = async (tenantId: string) => {
    try {
      setLoading(true);
      const accountSettings = await getAccountSettingsByCodes({
        variables: {
          codes: args.codes,
          tenantId: tenantId,
        },
      });
      const userSettings: IUserSettingsByCode = {};
      (accountSettings?.data?.defaultUserSettings || []).forEach(
        (defaultUserSetting: any) => {
          if (!defaultUserSetting) return;
          const id: string = defaultUserSetting.id;
          const code: string = defaultUserSetting.code;
          let value: string = defaultUserSetting.value;
          if (defaultUserSetting.userSettings?.[0]) {
            value = defaultUserSetting.userSettings[0].value;
          }
          userSettings[code] = {id, code, value};
        }
      );
      setData(userSettings);
    } finally {
      setLoading(false);
    }
  }

  return {
    data, loading
  };
}

export default useUserSettings
