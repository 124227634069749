import React from 'react';
import { useMutation } from '@apollo/client';
import {
  Skeleton, View,
  VStack
} from 'native-base';
import { useState } from 'react';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { NoteQueries } from '../../../services';
import { Colors } from '../../../styles';
import { isWeb } from '../../../utils/platformCheckUtils';
import { DeleteConfirmationDrawer } from '../DeleteConfirmationDrawer/DeleteConfirmationDrawer';
import FHAlertDialog from '../FHAlertDialog/FHAlertDialog.native';
import { DisplayText } from '../DisplayText/DisplayText';
import ContactNoteItem from './ContactNoteItem';

const ContactNotesListView = (props: any) => {
  const [stateData, setStateData] = useState({
    showConfirmModal: false as boolean,
    selectedNote: {},
    loading: false as boolean,
  })
  const [deleteContactNote]= useMutation(NoteQueries.DeleteContactNote)
  const {contactNotesList, loading, contactId} = props;
  const handleNoteDelete = async (note:any) =>{
    setStateData((prev)=> {
      return {
        ...prev,
        loading: true,
      }
    })
    if (note?.id) {
      const deleteNote = await deleteContactNote({
        variables : {
          id:note?.id,
          contactId: contactId,
        }
      })
      onShowConfirmModalChange();
      if(deleteNote?.data?.updateNotes?.returning?.length){
        props.onNotesListActionPerform(COMMON_ACTION_CODES.COMPLETED, {});
      }
    } else {
      onShowConfirmModalChange();
    }
  }
  const onShowConfirmModalChange = ()=> {
    setStateData((prev)=> {
      return {
        ...prev,
        showConfirmModal: false,
        loading: false,
        selectedNote: {},
      }
    })
  }

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CONFIRM:
        setStateData((prev) => {
          return {
            ...prev,
            showConfirmModal: true,
            selectedNote: actionData,
          };
        });
        break;
    }
  }

  return (
    <VStack>
      {loading ? (
        <View padding={3}>
          <Skeleton.Text lines={3} />
        </View>
      ) : (
        <>
          {contactNotesList?.map((item: any, index: any) => {
            return (
              <ContactNoteItem
                key={item?.id + "_" + index}
                index={index}
                item={item}
                onNotesListActionPerform={props.onNotesListActionPerform}
                onActionPerformed={onActionPerformed}
                contactData={props?.contactData}
              />
            );
          })}
        </>
      )}
      {stateData?.showConfirmModal && <>
        {isWeb() ? <DeleteConfirmationDrawer
          isOpen={true}
          titleId={'deleteContactNote'}
          message={'deleteContactNoteMessage'}
          onCancel={()=> {
            onShowConfirmModalChange();
          }}
          onConfirm={()=> {
            handleNoteDelete(stateData?.selectedNote);
          }}
          isLoading={stateData?.loading}
        /> :
          <FHAlertDialog
            isOpen={true}
            header={
              <DisplayText
                textType={'Heading'}
                textLocalId={'deleteContactNote'}
              />
            }
            message={<DisplayText size={'sm'} textLocalId={'deleteContactNoteMessage'} />}
            showCloseBtn={true}
            onClose={() => {
              onShowConfirmModalChange();
            }}
            buttonActions={[
              {
                textLocalId: 'Cancel',
                buttonProps: {
                  colorScheme: 'muted',
                  variant: 'outline',
                },
                onPress: () => {
                  onShowConfirmModalChange();
                },
              },
              {
                isDeleting: stateData?.loading,
                textLocalId: 'Delete',
                textColor: '#fff',
                buttonProps: {
                  backgroundColor: Colors.primary['700'],
                },
                onPress: () => {
                  handleNoteDelete(stateData?.selectedNote);
                },
              },
            ]}
          />
      }
      </>}
    </VStack>
  );
};

export default ContactNotesListView;
