import {useState, useEffect} from 'react';
import {getUserPracticeLocations} from '../utils/commonUtils';

interface RestrictedEmployeeProfile {
  isRestricted: boolean; // true if the user is restricted from viewing the patient's profile
  isLoading: boolean;
  error: string;
}

export const useCheckCurrentEmployeeRestricted = (
  isRestrictedParticularLocationEmployeeEnabled: boolean,
  patientLocationIds: string[],
  restrictedLocationIds: string[]
): RestrictedEmployeeProfile => {
  const [isRestricted, setIsRestricted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const checkRestrictions = async () => {
      try {
        // Skip restriction checks if either:
        // - Feature is disabled
        // - No location data is available to evaluate
        if (
          !isRestrictedParticularLocationEmployeeEnabled ||
          restrictedLocationIds.length === 0 ||
          patientLocationIds.length === 0
        ) {
          setIsRestricted(false);
          return;
        }

        // Get user's practice locations
        const userLocationIds = await getUserPracticeLocations();

        // Split any comma-separated location IDs into separate array elements
        const normalizedRestrictedLocationIds = restrictedLocationIds.flatMap(
          (id) => (id.includes(',') ? id.split(',') : id)
        );

        // Check if patient has any restricted locations
        const patientHasRestrictedLocations = patientLocationIds.some(
          (locationId) => normalizedRestrictedLocationIds.includes(locationId)
        );

        if (!patientHasRestrictedLocations) {
          setIsRestricted(false);
          return; // Patient has no restricted locations, allow access
        }

        // Check if user has access to the restricted locations that patient has
        const patientRestrictedLocations = patientLocationIds.filter(
          (locationId) => normalizedRestrictedLocationIds.includes(locationId)
        );

        const userHasAccessToRestrictedLocations =
          patientRestrictedLocations.some((locationId) =>
            userLocationIds.includes(locationId)
          );

        setIsRestricted(!userHasAccessToRestrictedLocations);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    checkRestrictions();
  }, [
    isRestrictedParticularLocationEmployeeEnabled,
    patientLocationIds,
    restrictedLocationIds,
  ]);

  return {isRestricted, isLoading, error};
};
