import {unmaskPhoneNumber, isNumeric, isPhoneNumber} from "../../../utils/commonUtils";
import { getStartAndEndOfDayForSearchByBirthDate } from "../../../utils/DateUtils";
import { getPatientSearchORCondition } from "../../RightSideContainer/Contacts/ContactsUtils";
import {ISearchContactFilter, SortDirection} from "../../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/LeadListView/interface";
import { IParticipantSearch } from "../CalendarWidget/ParticipantAutoComplete/ParticipantInterfaces";
import { IUserPatientSearchItem } from "../CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native";

export const getSearchQuery = (args: {
  searchString: string;
  contactTypeId?: string;
  employerId?: string;
  isActive?: boolean;
  accountId?: number | string;
  isFromCreateSmsConversation?:boolean,
  searchPatientWithEmail?: boolean,
  isPatient?: boolean,
  userLocationUuids?: string[],
  accountLocationUuids?: string[],
}) => {
  const {contactTypeId, employerId, isActive, accountId, isFromCreateSmsConversation, searchPatientWithEmail, isPatient, userLocationUuids, accountLocationUuids} = args;
  let  {searchString}:any  = args
  let where: any = {};
  if (
    searchString &&
    (searchString.includes('+') ||
      searchString.includes('(') ||
      searchString.includes(')') ) &&
    searchString?.length > 10 &&
    !searchString.includes('@')
  ) {
    searchString = unmaskPhoneNumber(searchString);
  }
  if (searchString && isSearchStringADate(searchString.trim())) {
    const searchStringIsADate = isSearchStringADate(searchString?.trim())
    if (searchStringIsADate) {
      const startAndEndOfDay = getStartAndEndOfDayForSearchByBirthDate(searchStringIsADate)
      if (startAndEndOfDay) {
        where = {
          ...where,
          person:
          {
            birthDate: {
              _gte: startAndEndOfDay[0],
              _lte: startAndEndOfDay[1]
            }
          }
        }
      }
      else return undefined // if searchString is a date(birth date), but not a valid date or if it is a future date, then no need to run query
    }
  }
  else {
    let searchStringArray = searchString?.split(' ');
    searchStringArray = searchStringArray?.filter((char: any) => {
      return char.length >= 1;
    });

    if (searchString) {
      where = {
        ...where,
        _or: getPatientSearchORCondition(searchString, searchStringArray),
      };
    }
  }

  if (isFromCreateSmsConversation) {
    where.phoneNumber = {
      _neq: '',
    };
  }

  if (contactTypeId) {
    if (isPatient) {
      where.contactType = {
        typeId: {
          _eq: contactTypeId,
        },
      };
    } else {
      where.contactType = {
        typeId: {
          _neq: contactTypeId,
        },
      };
    }
  }

  if (accountId) {
    where.accountId = {
      _eq: accountId,
    };
  }

  where.isActive = {
    _eq: isActive,
  };

  where.isDeleted = {
    _eq: false,
  };

  if (searchPatientWithEmail) {
    where.email = {
      _is_null: false,
    };
  }

  // where._or = [
  //   {
  //     email: {
  //       _is_null: false,
  //     },
  //   },
  //   {
  //     phoneNumber: {
  //       _is_null: false,
  //     },
  //   },
  // ];

  if (employerId) {
    where.employees = {
      employerId: {
        _eq: employerId,
      },
    };
  }
  if (userLocationUuids && userLocationUuids?.length) {
    where.contactPracticeLocations = {
      practiceLocationUuid: {
        _in: userLocationUuids,
      },
    };
  }
  if (accountLocationUuids && accountLocationUuids?.length) {
    where.contactPracticeLocations = {
      accountLocation: {
        uuid: {
          _in: accountLocationUuids,
        },
      },
    };
  }
  return {where};
};
export const getContactSearchActionQuery = (args: {
  searchString: string;
  contactTypeId?: string;
  employerId?: string;
  isActive?: boolean;
  isFromCreateSmsConversation?: boolean;
  searchPatientWithEmail?: boolean;
  contactTypeNotId?: string;
  orderBy?: {
    name?: SortDirection;
    created_at?: SortDirection;
    updated_at?: SortDirection;
  },
  accountLocationIds?: string[],
  showContactsWithInactiveProfiles?: boolean
}) => {
  const {contactTypeId, employerId, isActive, orderBy, isFromCreateSmsConversation, searchPatientWithEmail, contactTypeNotId, accountLocationIds} = args;
  let  {searchString}:any  = args
  const searchContactActionFilter:ISearchContactFilter = {} as ISearchContactFilter
  if (orderBy) {
    searchContactActionFilter.orderBy = orderBy
  } else {
    searchContactActionFilter.orderBy = {
      updated_at: 'desc'
    }
  }
  if (isFromCreateSmsConversation) {
    searchContactActionFilter.filterEmptyPhoneNumberContacts  = isFromCreateSmsConversation
  }
  if (isFromCreateSmsConversation) {
    searchContactActionFilter.filterEmptyEmailContacts  = searchPatientWithEmail
  }
  if (isPhoneNumber(searchString)) {
    searchString = unmaskPhoneNumber(searchString);
  }
  searchContactActionFilter.searchString =  searchString
  if (contactTypeId) {
    searchContactActionFilter.contactTypeIds = [contactTypeId]
  }
  if (contactTypeNotId) {
    searchContactActionFilter.contactTypeIdsNotIn = [contactTypeNotId]
  }
  searchContactActionFilter.isActive = isActive

  if (employerId) {
    searchContactActionFilter.employerIds = [employerId]
  }
  if (accountLocationIds?.length) {
    searchContactActionFilter.accountLocationIds = accountLocationIds
  }
  if (args?.showContactsWithInactiveProfiles) {
    searchContactActionFilter.showContactsWithInactiveProfiles = args?.showContactsWithInactiveProfiles
  }
  return searchContactActionFilter;
};

export function isSearchStringADate(dateString: string) {
  if (dateString.match(/^\d{1,2}[-\/]\d{1,2}[-\/]\d{4}$/)) {
    const dateParts = dateString.split(/[-\/]/);

    if (dateParts[0].length === 1) {
      dateParts[0] = '0' + dateParts[0];
    }

    if (dateParts[1].length === 1) {
      dateParts[1] = '0' + dateParts[1];
    }

    const formattedDateString = dateParts.join('-');
    return formattedDateString;
  }
  return undefined;
}

export const getMatchedValue = (
  key: string,
  list: IParticipantSearch[]
): IParticipantSearch | undefined => {
  const matchedData = list.filter((item) => {
    return item.key === key;
  });
  if (matchedData.length > 0) {
    return matchedData[0];
  }
  return undefined;
};

export const getMatchedValueForMobile = (
  key: string,
  list: IUserPatientSearchItem[]
): IUserPatientSearchItem | undefined => {
  const matchedData = list.filter((item) => {
    return item.key === key;
  });
  if (matchedData.length > 0) {
    return matchedData[0];
  }
  return undefined;
};
