import {IKeyOperation} from '../../../../../../../Interfaces';
import {IAddOrUpdateAllergyParams, IAllergy, IAllergyComponentValue, IAllergyReaction} from '../interfaces';
import { AllergyFields } from '../../CustomComponentUtils';
import { IFormatReconciliationMetaData, IReconciliationFormattedData, IReconciliationConflictField, ReconciliationSource } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { AllergyIntolerance, AllergyIntoleranceReaction } from 'fhir/r4';
import { getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { FHIRConflictFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { InputType, getFieldCapabilities } from '../../../../../../../utils/capabilityUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { getDateStrFromFormat, getFormattedDate, getMomentObj } from '../../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants/StringConst';

export enum AllergySeverity {
  mild = 'mild',
  severe = 'severe',
  moderate = 'moderate',
}

export const getRecordListFormatted = (list: any[],fields?:IKeyOperation) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries,fields);
  return sortItems(formattedData);
}

const getReactionBtnStatus = (reactionListLength:number,disableMultiple?:boolean) => {
  if (disableMultiple && reactionListLength < 1) {
    return false;
  }
  return true;
}

export const getRecordDataFormatted = (listData: any[],fields?:IKeyOperation): IAllergy[] => {
  const tempFormattedList: IAllergy[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const status = item?.clinicalStatus?.coding?.length
        ? item.clinicalStatus?.coding[0]
        : {code: item.clinicalStatus?.text};

      const tempObj: IAllergy = {
        id: item?.id,
        onSetDateTime: item?.onsetDateTime,
        allergy: item.code,
        name: getAllergyName(item.code),
        clinicalStatus: status,
        recordedDate: item?.recordedDate,
        uniqueId: item?.id,
        criticality: item?.criticality,
        isFreeTextRecord: !item.code?.coding?.length,
        type: item.type,
        ...(item.note?.length > 0 && {
          note: item.note[item.note.length - 1].text,
        }),
        ...(item?.reaction?.length && {
          reactions: getReactionForDisplay(item.reaction),
        }),
        showAddReaction: true,
        showReactionInput: false,
        showNote:
          item.note?.length > 0 &&
          item.note[item.note.length - 1].text.length > 0,
        meta: item?.meta
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

const getReactionForDisplay = (reaction: AllergyIntoleranceReaction[]) => {
  return reaction.map((reaction: any) => {
    return {
      ...(reaction.manifestation?.length > 0 &&
        (reaction.manifestation?.[0]?.text || reaction.manifestation?.[0]?.coding?.length > 0) && {
          manifestation: {
            code: reaction.manifestation?.[0]?.coding?.[0]?.code,
            display: reaction.manifestation?.[0]?.text || reaction.manifestation?.[0].coding?.[0]?.display,
          },
        }),
      severity: reaction?.severity,
    };
  });
};

export const getSampleData = (): IAllergyComponentValue => {
  return {
    allergies: [{
      id: '34234324',
      onSetDateTime: '2020-12-24',
      allergy: {
        text: 'Sample Allergy',
        coding: []
      },
      name: 'Sample Allergy',
      clinicalStatus: {code: 'active', display: 'Active'},
      criticality: 'low',
      note: 'Test notes',
      uniqueId: '1234',
      isFreeTextRecord: false,
      type: 'allergy',
      reactions: [
        {
          manifestation: {
            code: 'SAMPLE_REACTION',
            display: 'Test Reaction',
          },
          severity: 'moderate',
        }
      ]
    }],
  }
};

export const sortItems = (records: IAllergy[]) => {
  return records.sort((a, b) => {
    const statusA = a?.clinicalStatus?.code || a?.status || '';
    const statusB = b?.clinicalStatus?.code || b?.status || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    const dateA = a?.recordedDate || a?.onSetDateTime || a?.date || new Date();
    const dateB = b?.recordedDate || b?.onSetDateTime || b?.date || new Date();
    return (new Date(dateB).getTime() - new Date(dateA).getTime());
  })
};

export const getAllergyName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: AllergyFields,
  item: IAllergy,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  switch (field) {
    case AllergyFields.onSetDateTime:
      if ((fields?.onsetDateTime?.isRequired || false) && submitTriggered) {
        return !item.onSetDateTime;
      }
      return false;

    case AllergyFields.clinicalStatus:
      if ((fields?.clinicalStatus?.isRequired || false) && submitTriggered) {
        return (
          !item.clinicalStatus || !item.clinicalStatus.code
        );
      }
      return false;

    case AllergyFields.reaction:
      if ((fields?.reaction?.isRequired || false) && submitTriggered) {
        return !item.reactions || item.reactions?.length === 0;
      }
      if (item.reactions?.length && submitTriggered) {
        return item.reactions.some((reaction) => !reaction.manifestation?.display);
      }
      return false;

    case AllergyFields.criticality:
      if ((fields?.criticality?.isRequired || false) && submitTriggered) {
        return !item.criticality;
      }
      return false;

    case AllergyFields.note:
      if ((fields?.note?.isRequired || false) && submitTriggered) {
        return !item.note;
      }
      return false;

    case AllergyFields.type:
      if ((fields?.type?.isRequired || false) && submitTriggered) {
        return !item.type;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateAllergyParams) => {
  return {
    resourceType: 'AllergyIntolerance',
    ...(params.id && {id: params.id}),
    patient: {
      reference: `Patient/${params.patientId}`,
    },
    code: params.allergy,
    ...(params.recordedDate && {recordedDate: params.recordedDate}),
    ...(params.reactions && {
      reaction: params.reactions.map((reaction) => {
        return {
          manifestation: [
            {
              coding: [
                {
                  display: reaction.manifestation?.display,
                  ...(reaction.manifestation?.code && {
                    system: "http://snomed.info/sct",
                    code: reaction.manifestation?.code,
                  }),
                },
              ],
            },
          ],
          severity: reaction.severity
        }
      }),
    }),
    ...(params.note && {
      note: [
        {
          text: params.note,
        },
      ],
    }),
    ...(params.type && { type: params.type }),
    ...(params.criticality && { criticality: params.criticality }),
    ...(params.onSetDateTime && {onsetDateTime: params.onSetDateTime}),
    ...(params.clinicalStatus && {
      clinicalStatus: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
            code: params.clinicalStatus.code,
            display: params.clinicalStatus.display,
          },
        ],
      },
    }),
    ...(params?.meta && { meta: params?.meta })
  };
};

export const getAllergySeverityDisplayName = (severity: string) => {
  switch (severity) {
    case AllergySeverity.mild:
      return 'Mild';
    case AllergySeverity.moderate:
      return 'Moderate';
    case AllergySeverity.severe:
      return 'Severe';
    default:
      return '';
  }
}

export const formatReconciliationAllergyData = (
  resource: any,
  metaData: IFormatReconciliationMetaData
): IReconciliationFormattedData | undefined => {
  const allergyResource: AllergyIntolerance = resource;

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.allergyIntolerance,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);
  const status = fieldCapabilities.clinicalStatus?.possibleValues?.find(
    (value: any) => value.code == allergyResource.clinicalStatus?.coding?.[0]?.code
  )?.display;

  const allergyTitle = getHieAllergyTitle(resource);
  const date = allergyResource.onsetDateTime || allergyResource.onsetPeriod?.start;
  const formattedDate = date ? getFormattedDate(date, DISPLAY_DATE_FORMAT) : '';
  const note = allergyResource.note?.[0]?.text;
  const type = fieldCapabilities.type?.possibleValues?.find(
    (value: any) => value.code == allergyResource.type
  )?.display;
  const criticality = fieldCapabilities.criticality?.possibleValues?.find(
    (value: any) => value.code == allergyResource.criticality
  )?.display;
  const reactions = allergyResource.reaction?.map((reaction: any) => {
    return reaction.manifestation?.[0]?.coding?.[0]?.display;
  }).join(', ');
  const display =  {
    title: allergyTitle || '',
    status: [formattedDate, status, type, criticality, reactions ? `Reactions: ${reactions}` : ''].filter(Boolean).join(' • '),
    date: note,
  };

  const validatedData = validateAndFormatAllergyHieResource(
    (metaData?.targetResourceData || resource) as AllergyIntolerance,
    fieldCapabilities,
    metaData
  );

  return {
    display,
    resourceData: validatedData.resource,
    conflictFields: validatedData.conflictFields
  };
};

const getHieAllergyTitle = (resource: AllergyIntolerance) => {
  return resource?.code?.text || resource.code?.coding?.[0]?.display;
}

export const getAllergyCodeInvalidField = (isConflict?: boolean, value?: any) => {
  return {
    inputType: FHIRConflictFields.code,
    field: AllergyFields.code,
    invalid: !value,
    label: 'Allergy',
    placeholder: 'Search Allergy',
    isConflict: isConflict,
    value: value,
    isRequired: true,
    extraData: {
      searchType: PAMISearchType.allergy,
    },
  }
}

const getHieAllergyCodes = (
  resource: AllergyIntolerance,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.code || resource.reaction?.[0]?.substance;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text
  return {coding: coding, text: text};
};

const getResourceDate = (
  resource: AllergyIntolerance,
  onsetDateFormat?: string | undefined
) => {
  const date = resource.onsetDateTime || resource.onsetPeriod?.start;

  if (date) {
    const onsetDate = onsetDateFormat
      ? getDateStrFromFormat(date, onsetDateFormat)
      : getMomentObj(date || new Date()).toISOString();

    return onsetDate;
  }
};

const getClinicalStatus = (
  resource: AllergyIntolerance,
  possibleValues?: {code: string; display: string}[]
) => {
  const clinicalStatus = resource?.clinicalStatus?.coding?.length
    ? resource.clinicalStatus?.coding[0]
    : {
        code: resource.clinicalStatus?.text,
        display: resource.clinicalStatus?.text,
      };

  return possibleValues?.find(
    (possibleValue) => possibleValue.code === clinicalStatus.code?.toLowerCase()
  );
};

export const validateAndFormatAllergyHieResource = (
  resource: AllergyIntolerance,
  fields: IKeyOperation,
  metaData: IFormatReconciliationMetaData
) => {
  const conflictFields: IReconciliationConflictField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = AllergyFields[key as keyof typeof AllergyFields];
    if (!fields[key as keyof typeof fields]?.isHidden) {
      switch (field) {
        case AllergyFields.code:
          {
            const conditionCodes = metaData.source === ReconciliationSource.MSO
              ? resource.code
              : getHieAllergyCodes(resource, fields?.code?.allowedCodeSystems);
            resource.code = conditionCodes;
            conflictFields.push(
              getAllergyCodeInvalidField(false, resource.code)
            );
          }
          break;

        case AllergyFields.reaction:
          {
            const reactions = getReactionForDisplay(resource.reaction || []);

            const isMissing =
              !reactions?.length && fields?.reaction?.isRequired;

            conflictFields.push({
              inputType: FHIRConflictFields.allergyReaction,
              field: AllergyFields.reaction,
              invalid: isMissing || false,
              value: reactions,
              label: 'Reactions',
              isRequired: fields?.reaction?.isRequired || false,
              extraData: {
                disableMultiple: fields?.reaction?.disableMultiple || false,
                inputType: fields?.reaction?.inputType || InputType.openChoice,
                severityList: fields?.severity?.possibleValues || [],
              },
            });
          }
          break;

        case AllergyFields.note:
          {
            const hieNote = resource?.note?.[0]?.text;
            const isMissing = !hieNote && fields?.note?.isRequired;
            if (!isMissing && hieNote) {
              resource.note = [{text: hieNote}];
            }
            conflictFields.push({
              field: AllergyFields.note,
              inputType: FHIRConflictFields.textArea,
              invalid: isMissing || false,
              value: hieNote,
              isRequired: fields?.note?.isRequired || false,
              label: 'Note',
            });
          }
          break;
        case AllergyFields.onSetDateTime:
          {
            const onsetDateFormat = fields?.onsetDateTime?.format;
            const onsetDateTime = getResourceDate(resource, onsetDateFormat);
            const isMissing =
              !onsetDateTime && fields?.onsetDateTime?.isRequired;
            if (!isMissing && onsetDateTime) {
              resource.onsetDateTime = onsetDateTime;
            }
            conflictFields.push({
              inputType: FHIRConflictFields.date,
              field: AllergyFields.onSetDateTime,
              invalid: isMissing || false,
              value: onsetDateTime,
              label: 'Since',
              isRequired: fields?.onsetDateTime?.isRequired || false,
              extraData: {
                dateFormat: onsetDateFormat,
              },
            });
          }
          break;

        case AllergyFields.clinicalStatus:
          {
            const status = getClinicalStatus(
              resource,
              fields?.clinicalStatus?.possibleValues
            );
            const isMissing =
              !status?.code && fields?.clinicalStatus?.isRequired;
            if (!isMissing && status) {
              resource.clinicalStatus = {
                coding: [status],
              };
            }
            conflictFields.push({
              field: AllergyFields.clinicalStatus,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: status?.code,
              label: 'Status',
              isRequired: fields?.clinicalStatus?.isRequired || false,
              extraData: {
                statusList: fields?.clinicalStatus?.possibleValues || [],
              },
            });
          }
          break;
        case AllergyFields.criticality:
          {
            const criticality = resource.criticality;
            const isMissing = !criticality && fields?.criticality?.isRequired;
            if (!isMissing && criticality) {
              resource.criticality = criticality;
            }
            conflictFields.push({
              field: AllergyFields.criticality,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: criticality,
              label: 'Criticality',
              isRequired: fields?.criticality?.isRequired || false,
              extraData: {
                statusList: fields?.criticality?.possibleValues || [],
              },
            });
          }
          break;
        case AllergyFields.type:
          {
            const type = resource.type;
            const isMissing = !type && fields?.type?.isRequired;
            if (!isMissing && type) {
              resource.type = type;
            }
            conflictFields.push({
              field: AllergyFields.type,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: type,
              label: 'Type',
              isRequired: fields?.type?.isRequired || false,
              extraData: {
                statusList: fields?.type?.possibleValues || [],
              },
            });
          }
          break;
      }
    }
  });

  return {
    conflictFields: conflictFields,
    resource: resource,
  };
};

export const updateHieAllergyMatchedData = (
  resource: AllergyIntolerance,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  const updatedResource = updateHieAllergyData(resource, conflictFields, metaData);
  return formatReconciliationAllergyData(updatedResource, metaData);
};

export const updateHieAllergyData = (
  resource: AllergyIntolerance,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  conflictFields.map((conflictField) => {
    if (conflictField.value) {
      switch (conflictField.field) {
        case AllergyFields.code:
          {
            resource.code = conflictField.value;
          }
          break;
        case AllergyFields.reaction:
          {
            resource.reaction = conflictField.value.map((reaction: IAllergyReaction) => {
              return {
                manifestation: [
                  {
                    coding: [
                      {
                        display: reaction.manifestation?.display,
                        ...(reaction.manifestation?.code && {
                          system: 'http://snomed.info/sct',
                          code: reaction.manifestation?.code,
                        }),
                      },
                    ],
                  },
                ],
                severity: reaction.severity,
              };
            });
          }
          break;
        case AllergyFields.note:
          {
            resource.note = [{text: conflictField.value}];
          }
          break;
        case AllergyFields.type:
          {
            resource.type = conflictField.value;
          }
          break;
        case AllergyFields.criticality:
          {
            resource.criticality = conflictField.value;
          }
          break;
        case AllergyFields.onSetDateTime:
          {
            resource.onsetDateTime = conflictField.value;
          }
          break;
        case AllergyFields.clinicalStatus:
          {
            const capabilities = getEHRCapability(metaData.ehrCapabilities, [
              CapabilityResource.condition,
            ]);
            const fieldCapabilities = getFieldCapabilities(capabilities);
            const status =
              fieldCapabilities?.clinicalStatus?.possibleValues?.find(
                (value: any) => value.code == conflictField.value
              );
            if (status) {
              resource.clinicalStatus = {
                coding: [status],
              };
            }
          }
          break;
      }
    }
    return conflictField;
  });
  return resource;
};

export const getHieAllergyReactions = (
  resource: AllergyIntolerance,
  allowedCodeSystems?: string[]
) => {
  const manifestationCodes = resource.reaction?.[0]?.manifestation;
  return getValidCoding(undefined, allowedCodeSystems, manifestationCodes);
};

export const formatAllergyRequestResourceObj = (
  resource: AllergyIntolerance,
  metaData: any
) => {

  const onsetDateTime = resource.onsetDateTime || resource.onsetPeriod?.start;

  const requestObj = {
    resourceType: 'AllergyIntolerance',
    patient: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.code && {code: resource.code}),
    ...(resource.reaction && {reaction: resource.reaction}),
    ...(resource.note && {
      note: resource.note,
    }),
    ...(resource.type && { type: resource.type }),
    ...(resource.criticality && { criticality: resource.criticality }),
    ...(onsetDateTime && {onsetDateTime: onsetDateTime}),
    ...(resource.clinicalStatus && {
      clinicalStatus: resource.clinicalStatus,
    }),
    ...(resource?.meta && { meta: resource?.meta })
  };
  return requestObj;
};

