import parse from 'html-react-parser';
import { Liquid } from "liquidjs";
import { ScrollView,VStack,View } from "native-base";
import { useContext,useEffect,useState } from "react";
import { WorkflowContext } from "../../../../../context/WorkflowContext";
import { Colors } from "../../../../../styles";
import { ContentTypes } from "../../../ContentManagement/ContentManagementConsts";
import { getTemplates } from "../../../ContentManagement/ContentManagementUtils";
import { PushNotificationPreview } from "../../../ContentManagement/PushNotifications/PushNotificationPreview";
import { getParseBody } from "../../../ContentManagement/PushNotifications/PushNotificationsUtils";
import { SMSPreview } from "../../../ContentManagement/SMSTemplates/SMSPreview";
import { styles } from './../../../../RightSideContainer/ContentManagement/CampaignManagement/EmailOrSmsCreatorForm/EmailOrSmsCreatorFormStyles';
import { getDisplayValue,getFormOutputDisplayTemplate,getNodeLevelGraphQl } from "./FlowNodeHelper";
import { IUserInputField } from "./FlowNodeInterface";
import '../styles.css'

const displayStrString: any = {
  SendAppointmentBookingLink: 'Send appointment booking link via email {% if appointmentType %} with appointment type {{appointmentType}} {% endif %}'
}

function boldTextReplacer(match: string, offset: number) {
  return `<text textSize={'smMedium'} style="
  text-decoration-color: red;
  text-decoration: solid;
  font-weight: bold;
  color:${Colors.FoldPixel.GRAY300}
">${match}</text>`
}

function addBoldText(text: string) {
  // return text.replace(/{{[^}]+}}/g, boldTextReplacer);
  return text
}

const pastTense: any = {
  Send: 'sent',
  Add: 'added'
}



const getPreviewHtml = (templateData: any): string => {
  if (!templateData?.templateHtml) {
    return '';
  }
  try {
    const engine = new Liquid();
    const tpl = engine.parse(templateData?.templateHtml);
    return engine.renderSync(tpl, {});
  } catch (error) {
    return '';
  }
};


const DisplayCommunicationTemplate = (props: {
  ratio: number;
  subject?: string;
  templateId: string;
  type: any;
  templateBody?: string;
}) => {
  const [templateInfo, setTemplateInfo] = useState({
    templateHtml: '',
    isLoading: false,
    template: '',
    subject: props.subject,
    templateBody: props.templateBody || '',
  });

  const searchTemplateList = (callback?: any) => {
    const path = `${props.type}/${props.templateId}`;
    getTemplates(path).then((response) => {
      callback ? callback(response.data) : undefined;
    });
  };
  useEffect(() => {
    if (props.templateId) {
      setTemplateInfo((old: any) => {
        return {...old, isLoading: true};
      });
      searchTemplateList((response: any) => {
        let templateBody = '';
        if (props.type == ContentTypes.emails.path) {
          templateBody = response?.attributes?.bodyHtml;
        } else if (props.type == ContentTypes.textMessages.path) {
          templateBody = response?.attributes?.body;
        } else if (props.type == ContentTypes.pushNotifications.path) {
          templateBody = response?.attributes?.body;
        }
        const subject = response?.attributes?.subject || '';
        setTemplateInfo((old: any) => {
          return {
            ...old,
            isLoading: false,
            template: props.templateId,
            templateBody: templateBody || '',
            subject: subject,
          };
        });
      });
    }
  }, [props.templateId]);
  if (props.type == ContentTypes.emails.path) {
    return templateInfo?.isLoading ||
      !templateInfo?.template ||
      !templateInfo?.templateBody ? (
      <></>
    ) : (
        <ScrollView
        marginTop={1.5}
        style={styles.scrollView}

        >
          {parse(templateInfo?.templateBody)}
        </ScrollView>
    );
  } else if (
    props.type == ContentTypes.textMessages.path &&
    templateInfo?.templateBody
  ) {
    return (
      <ScrollView>
        <SMSPreview bodyText={templateInfo?.templateBody || 'body'} showMobilePreview={false} />
      </ScrollView>
    );
  } else if (
    props.type == ContentTypes.pushNotifications.path &&
    templateInfo?.templateBody
  ) {
    return (
        <ScrollView showsHorizontalScrollIndicator={false}>
          <PushNotificationPreview
            notificationBody={templateInfo?.templateBody || 'Body'}
            notificationSubject={templateInfo.subject || 'Title'}
            widthHeightRatio = {props?.ratio}
          ></PushNotificationPreview>
        </ScrollView>
    );
  }
  return <></>;
};


const WorkflowNodeDisplayText = (props: {ratio?:number,isShowTemplate?:boolean, isSimpleView?: boolean, postFixChar?: string, nodeDisplayTemplate?: string, previousImmediateNode: any, displayName: string, nodeType: string, userInputFieldList: IUserInputField[], isHoveredOverNode?: boolean, widthHeightRatio?: number, isTitle?: boolean }) => {
  const isSimpleView = props?.isSimpleView;
  const isHoveredOverNode = props.isHoveredOverNode;
  const workflowContext = useContext(WorkflowContext);
  const nodeMetaData = workflowContext.nodeMetaData;
  const nodeMasterDataMap = workflowContext.nodeMasterDataMap;
  const userInputFieldList = props.userInputFieldList || [];
  const nodeType = props.nodeType;
  const previousImmediateNode = props.previousImmediateNode;
  const prevNodeCategoryDisplay = nodeMasterDataMap[props?.previousImmediateNode?.data?.nodeType]?.category?.display || '';
  const prevNodeCategoryPastTense = prevNodeCategoryDisplay ? (['Moment', 'Operation'].indexOf(prevNodeCategoryDisplay) == -1 ? (pastTense[prevNodeCategoryDisplay] || prevNodeCategoryDisplay) : '') : '';
  const previousImmediateNodeData = nodeMasterDataMap[props?.previousImmediateNode?.data?.nodeType];
  const previousImmediateNodeDisplay = (previousImmediateNodeData?.displayName || '');
  const widthHeightRatio = props?.widthHeightRatio;
  const isTitle = props?.isTitle;
  const parentUserInputFieldList = previousImmediateNode?.data?.metaData?.userInputFieldList || [];

  const nodeLevelGraphQl = getNodeLevelGraphQl(userInputFieldList, nodeType, nodeMetaData, workflowContext?.locationIds?.[0]);
  const formOutputDisplayTemplate = getFormOutputDisplayTemplate(userInputFieldList)
  const keyMap: any = {
    previousImmediateNodeDisplay: previousImmediateNodeDisplay.trim(),
    prevNodeCategoryDisplay: prevNodeCategoryDisplay.trim(),
    prevNodeCategoryPastTense: prevNodeCategoryPastTense.trim(),
    nodeLevelGraphQl: nodeLevelGraphQl,
    formOutputDisplayTemplate: formOutputDisplayTemplate,
    parent: {

    }
  }

  parentUserInputFieldList.forEach((userInputField: IUserInputField) => {
    keyMap['parent'][userInputField.key] = ((getDisplayValue(userInputField, nodeMetaData)));
  })

  userInputFieldList.forEach((userInputField: IUserInputField) => {
    const keyName: string = (userInputField.key || '').replace(/\./g, '-');
    keyMap[keyName] = ((getDisplayValue(userInputField, nodeMetaData)));
  })

  const getTemplateString = (isSimpleView: boolean) => {
    let nodeLevelTemplate = (props.nodeDisplayTemplate || workflowContext?.nodeMasterDataMap[props?.nodeType]?.nodeDisplayTemplate || displayStrString[nodeType] || '').trim();
    nodeLevelTemplate = nodeLevelTemplate.replace(/{{nodeLevelGraphQl}}/g, nodeLevelGraphQl).trim();
    nodeLevelTemplate = nodeLevelTemplate.replace(/{{formOutputDisplayTemplate}}/g, formOutputDisplayTemplate).trim();
    if (isSimpleView) {
      return nodeLevelTemplate || workflowContext?.nodeMasterDataMap[props?.nodeType]?.displayName || '' + (props.postFixChar || '')
    }
    return (addBoldText(nodeLevelTemplate || workflowContext?.nodeMasterDataMap[props?.nodeType]?.displayName || '')) + (props.postFixChar || '')
  }
  const args = {
    bodyString: getTemplateString(isSimpleView || false),
    mergeTags: keyMap
  }


  let body = getParseBody(args);
  body = (body || '').replace("Micro-Automation,", "Micro-Automation");

  if (isSimpleView) {
    body = body.replace(/<br\s*\/?>/gi, "")
    return (
      // <Text style={ { fontSize: 12, fontWeight: '400', lineHeight: 14.4, color: isHoveredOverNode ? Colors.FoldPixel.GRAY300 : Colors.FoldPixel.GRAY200 }} numberOfLines={2}>
      //   {body?.trim ? body?.trim() : body}
      // </Text>
      body?.trim ? body?.trim() : body
    )
  }

  let template:any;
  let type = '';
  const isTemplate = (props.userInputFieldList||[]).some((input)=>{
    if(input?.fieldType == 'GENERAL_TEMPLATE_SEARCH'){
      template = input;
      type = ContentTypes.emails.path
      return true;
    } else if(input?.fieldType == 'GENERAL_SMS_TEMPLATE_SEARCH' ){
      template = input;
      type = ContentTypes.textMessages.path
      return true;
    } else if(input?.fieldType == 'GENERAL_PUSH_NOTIFICATION_TEMPLATE_SEARCH' ){
      template = input;
      type = ContentTypes.pushNotifications.path
      return true;
    }
  });

  const htmlBody = (
    <VStack flexWrap={'wrap'} flex={1} width={'100%'} maxHeight={window.innerHeight * 0.3} overflow={'scroll'}>
      <div style={{maxWidth: '100%', textOverflow: 'ellipsis', fontSize: 12, fontWeight: '400', lineHeight: '120%', color: isTitle ? Colors.FoldPixel.GRAY400 : isHoveredOverNode ? Colors.FoldPixel.GRAY400 : Colors.FoldPixel.GRAY200 }}
        className="automationTitle" dangerouslySetInnerHTML={{ __html: body }} >
      </div>

      {props.isShowTemplate && isTemplate ?

        <View width={'100%'} alignItems={'center'}>
        <DisplayCommunicationTemplate
          subject={template?.value?.subject}
          templateBody={template?.value?.body}
          ratio={props.ratio || 1}
          templateId={template?.value?.template?.id}
          type={type}/> 
        </View>:
         <></>
      }
    </VStack>
  )
  return htmlBody;

};

export default WorkflowNodeDisplayText;
