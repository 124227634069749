import { Button, FormControl, HStack, Text, View } from "native-base";
import { IInputElement } from "../../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface";
import { Colors } from "../../../styles/Colors";
import { InputNumber, Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import React, { useContext, useState } from "react";
import { reactStyles } from "../../RightSideContainer/Workflow/workflowStyles";
import useOutreachStepOutComes from "../CareProgramStepOutreach/useOutreachStepOutComes";
import { reminderUnits, smsRepliedOptions } from "./SmsNodeConstant";
import { isEnableCareProgram } from "../../../utils/commonUtils";
import { CommonDataContext } from "../../../context/CommonDataContext";


export interface ISmsNodeFilter {
    isSmsReplied: string;
    smsRepliedDuration?: {
        unit: string | null;
        value: number | null;
    };
    outreachInfo: {
        outcomeId: string | null;
    };
};

const { Option } = Select;

const SmsNodeFilter = (props: { value: any, isShowError: boolean, onChange: (value: any) => void }) => {
    const context = useContext(CommonDataContext);
    const { outreachStepOutComes } = useOutreachStepOutComes();
    const [smsFiltersData, setSmsFiltersData] = useState<ISmsNodeFilter>({
        isSmsReplied: props.value.isSmsReplied || 'YES',
        smsRepliedDuration: {
            unit: props.value.smsRepliedDuration?.unit || null,
            value: props.value.smsRepliedDuration?.value || 0
        },
        outreachInfo: {
            outcomeId: props.value.outreachInfo?.outcomeId || null
        }
    } as ISmsNodeFilter);
    const isEnableCareProgramFlag = isEnableCareProgram(context.userSettings);


    const handleValueChange = (newValue: number) => {
        if (newValue < 0) {
            setSmsFiltersData((prev) => {
                return {
                    ...prev,
                    smsRepliedDuration: {
                        unit: null,
                        value: null
                    }
                };
            });
        }
        setSmsFiltersData((prev) => {
            return {
                ...prev,
                smsRepliedDuration: {
                    unit: prev?.smsRepliedDuration?.unit || null,
                    value: newValue || null
                }
            };
        });
    };

    const handleUnitChange = (newUnit: string) => {
        setSmsFiltersData((prev) => {
            return {
                ...prev,
                smsRepliedDuration: {
                    unit: newUnit,
                    value: smsFiltersData.smsRepliedDuration?.value || 0
                }
            };
        });
    };

    const onChangeHandler = (
        isSmsReplied: string,
        smsRepliedDuration?: { unit: string | null; value: number | null },
        outreachInfo?: {
            outcomeId: string | null;
        }
    ) => {
        if (!isSmsReplied) {
            props.onChange(null);
        }
        if (isSmsReplied === 'YES') {
            props.onChange({
                isSmsReplied: isSmsReplied,
                ...(smsRepliedDuration?.value && { smsRepliedDuration: { unit: smsRepliedDuration.unit, value: smsRepliedDuration.value } }),
                ...(outreachInfo?.outcomeId && { outreachInfo: { outcomeId: outreachInfo.outcomeId } }),
            });
        } else {
            props.onChange({
                isSmsReplied: isSmsReplied,
            })
        }
    }

    return (
        <>
            <HStack>
                <HStack flex={2.4}>
                    <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
                        {'Patient Replied to SMS'}
                        {<Text color="error.500">*</Text>}
                    </Text>
                </HStack>
                <HStack flex={7}>
                    <View marginY={1} flex={1}>
                        <Select
                            suffixIcon={
                                <CaretDownOutlined
                                    style={{
                                        color: Colors.FoldPixel.GRAY300
                                    }}
                                />
                            }
                            allowClear={false}
                            value={smsFiltersData.isSmsReplied || 'NO'}
                            showSearch={false}
                            placeholder={'Select Replied on SMS'}
                            status={props.isShowError && !smsFiltersData?.isSmsReplied ? 'error' : ''}
                            filterOption={(input, option: any) => {
                                return (
                                    option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            onChange={(itemValue) => {
                                setSmsFiltersData((prev) => {
                                    return {
                                        ...prev,
                                        isSmsReplied: itemValue,
                                        ...(itemValue === 'NO' && {
                                            smsRepliedDuration: {
                                                unit: null,
                                                value: null
                                            },
                                            outreachInfo: {
                                                outcomeId: null
                                            }
                                        })
                                    };
                                });
                                onChangeHandler(itemValue, { unit: smsFiltersData?.smsRepliedDuration?.unit || null, value: smsFiltersData?.smsRepliedDuration?.value || null }, smsFiltersData?.outreachInfo || { outcomeId: null });
                            }}
                        >
                            {(smsRepliedOptions || []).map((smsRepliedOption) => {
                                return (
                                    <Option
                                        value={smsRepliedOption.value} title={smsRepliedOption.label} key={smsRepliedOption.value}>{smsRepliedOption.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </View>
                </HStack>
            </HStack>
            {smsFiltersData.isSmsReplied === 'YES' && (
                <View style={{ marginTop: 18 }}>
                    <HStack>
                        <HStack flex={2.4}>
                            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
                                {'Maximum Time Interval Between SMS Sent and Response'}
                            </Text>
                        </HStack>
                    </HStack>
                    <HStack flex={1} space={4} alignItems="center">
                        {/* <Button.Group isAttached>
                            {[{ code: 'AFTER', display: 'After' }].map((offsetData: any) => {
                                return (
                                    <Button
                                        key={offsetData.code}
                                        alignSelf="center"
                                        variant='solid'
                                    >
                                        {offsetData.display}
                                    </Button>
                                );
                            })}
                        </Button.Group> */}
                        <FormControl flex={1}>
                            <InputNumber
                                min={0}
                                height={'36px'}
                                value={smsFiltersData?.smsRepliedDuration?.value}
                                type="number"
                                onChange={(text: any) => {
                                    handleValueChange(Number(text));
                                    onChangeHandler(smsFiltersData?.isSmsReplied, { unit: smsFiltersData?.smsRepliedDuration?.unit || null, value: Number(text) || null }, smsFiltersData?.outreachInfo || { outcomeId: null });
                                }}
                                className={props.isShowError && !smsFiltersData?.smsRepliedDuration?.value ? 'field-error' : ''}
                                style={reactStyles.fullWidthHeight36}
                            />
                        </FormControl>
                        <FormControl flex={2}>
                            <Select
                                suffixIcon={
                                    <CaretDownOutlined
                                        style={reactStyles.colorGray300}
                                    />
                                }
                                status={props.isShowError && !smsFiltersData?.smsRepliedDuration?.unit ? 'error' : ''}
                                value={smsFiltersData?.smsRepliedDuration?.unit}
                                showSearch={false}
                                filterOption={(input, option: any) => {
                                    return (
                                        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={(itemValue) => {
                                    handleUnitChange(itemValue);
                                    onChangeHandler(smsFiltersData?.isSmsReplied, { unit: itemValue || null, value: smsFiltersData?.smsRepliedDuration?.value || null }, smsFiltersData?.outreachInfo || { outcomeId: null });
                                }}
                            >
                                {reminderUnits.map((reminderUnit) => {
                                    return (
                                        <Option
                                            value={reminderUnit.value}
                                            key={reminderUnit.value}
                                            title={reminderUnit.label}>
                                            {reminderUnit.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </HStack>
                </View>
            )}
            {(smsFiltersData.isSmsReplied === 'YES' && isEnableCareProgramFlag) && (
                <View style={{ marginTop: 18 }}>
                    <HStack>
                        <HStack flex={2.4}>
                            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400} flex={2} marginTop={1}>
                                {'Outreach Outcome'}
                            </Text>
                        </HStack>
                    </HStack>
                    <HStack flex={7}>
                        <View marginY={1} flex={1}>
                            <Select
                                suffixIcon={
                                    <CaretDownOutlined
                                        style={{
                                            color: Colors.FoldPixel.GRAY300
                                        }}
                                    />
                                }
                                allowClear={false}
                                value={smsFiltersData.outreachInfo?.outcomeId}
                                showSearch={false}
                                placeholder={'Select outreach outcome'}
                                filterOption={(input, option: any) => {
                                    return (
                                        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={(itemValue) => {
                                    setSmsFiltersData((prev) => {
                                        return {
                                            ...prev,
                                            outreachInfo: {
                                                outcomeId: itemValue,
                                            }
                                        };
                                    });
                                    onChangeHandler(smsFiltersData?.isSmsReplied, { unit: smsFiltersData?.smsRepliedDuration?.unit || null, value: smsFiltersData?.smsRepliedDuration?.value || null }, { outcomeId: itemValue || null });
                                }}
                            >
                                {(outreachStepOutComes || []).map((outreachStepOutCome) => {
                                    return (
                                        <Option
                                            value={outreachStepOutCome.id}
                                            title={outreachStepOutCome.value}
                                            key={outreachStepOutCome.id}>
                                            {outreachStepOutCome.value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </View>
                    </HStack>
                </View>
            )}
        </>
    )
}

export default SmsNodeFilter;