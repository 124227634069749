import {Divider, HStack, Text, View} from 'native-base';
import {StyleSheet} from 'react-native';
import { Colors } from '../../../styles';
import { testID } from '../../../testUtils';

interface IModalActionSubTitle {
  subTitle?: string;
  fontSize?: string | number;
  textColor?: string;
  fontWeight?: string | number;
  marginTop?: string | number;
  marginLeft?: string | number;
  marginBottom?: string | number;
  rightView?: () => JSX.Element;
  leftView?: () => JSX.Element;
  hideDivider?:boolean;
}

export const ModalActionSubTitle = (props: IModalActionSubTitle) => {
  const {
    subTitle,
    fontSize,
    textColor,
    fontWeight,
    marginTop,
    leftView,
    rightView,
    marginLeft
  } = props;
  return (
    <View>
      <HStack
        mt={marginTop ? marginTop : ''}
        ml={marginLeft ? marginLeft : undefined}
        mb={props?.marginBottom ? props?.marginBottom : ''}
        alignItems={'center'}
      >
        {leftView && leftView?.()}
        {subTitle && (
          <Text
            color={textColor ? textColor : Colors.FoldPixel.GRAY400}
            size={'mdMediumNew'}
            {...testID(subTitle)}
          >
            {subTitle}
          </Text>
        )}
        {rightView && rightView?.()}
      </HStack>
      {props.hideDivider ? <></>: <Divider my={4} />}

    </View>
  );
};

const styles = StyleSheet.create({});
