import {TASK_TYPES} from "../../../constants";
import {ISubTasks} from "../../common/AddTask/interfaces";

export const getAlertTitle = (alertType?: string, taskId?: string) => {
  if (taskId) {
    switch (alertType) {
      case TASK_TYPES.CARE_GAPS:
        return 'careGapDetails';
      case TASK_TYPES.DIAGNOSIS_GAPS:
        return 'reviewDiagnosisGap';
      case TASK_TYPES.CLINICAL_FINDINGS:
        return 'reviewAlerts';
      default:
        return 'reviewAlerts';
    }
  }
  switch (alertType) {
    case TASK_TYPES.CARE_GAPS:
      return 'newCareGap';
    case TASK_TYPES.DIAGNOSIS_GAPS:
      return 'newDiagnosisGap';
    case TASK_TYPES.CLINICAL_FINDINGS:
      return 'newAlert';
    default:
      return 'newAlert';
  }
}


export const isDiagnosisGapAlertValid = (isDiagnosisGapAlert?: boolean, updatedSubTasks?: ISubTasks[]): boolean => {
  if (!isDiagnosisGapAlert) {
    return true;
  }
  if (!updatedSubTasks || !updatedSubTasks.length) {
    return false;
  }
  return updatedSubTasks.every(item => item?.title?.trim()?.length > 0);
};
