import {useLazyQuery} from '@apollo/client';
import {Divider, notification, Popover} from 'antd';
import {FlatList, Pressable, Skeleton} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  GROUP_ACTION_CODES,
} from '../../../constants/ActionConst';
import {GROUP_TYPE_CODES, MLOV_CATEGORY} from '../../../constants/MlovConst';
import {
  BUTTON_TYPE,
  CONSENT_ERROR,
  CONSENT_ERROR_FOR_SIDE_CAR,
  GROUP_MEMBER_TYPE,
  PARENT_CODE,
} from '../../../constants/StringConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import GroupQueries from '../../../services/Conversations/GroupQueries';
import {Colors} from '../../../styles/Colors';
import {getAccountId, getAllowedUserPracticeLocationUuids, getUserId, getUserUUID, isConsentRequiredAndGiven} from '../../../utils/commonUtils';
import {
  getMlovCodeIdObj,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import CreateSmsConversationDrawer from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {
  CHANNEL_TYPE_CODE,
  CONVERSATION_TAB_CODES,
} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {TimeType} from '../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {renderConversationFooter} from '../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListComponents';
import {ConversationListItem} from '../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/ConversationListItem';
import CreateAllGroupPopup from '../../RightSideContainer/TeamInbox/Conversations/ConversationContainer/CreateGroupPopup/CreateAllGroupPopup';
import {
  IContact,
  IConversationData,
  IConversationResponse,
} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import CreateChannelView from '../CreateChannelView/CreateChannelView';
import {DisplayText} from '../DisplayText/DisplayText';
import NoDataFound from '../NoDataFound/NoDataFound';
import {IGroupListByMember, IGroupListByMemberState} from './interface';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import InstantChat from '../ChatDrawer/InstantChat';
import {EmailDrawerCommonV2} from '../EmailDrawerCommonV2';
import Stack from '../LayoutComponents/Stack';
import {NoChatFound} from '../../SideCar/assets/images/NoChatFound';
import {MESSAGE_CUSTOM_CARD_TYPE} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {useSideCarPatientContextEventHook} from './SiderCarPatientContextHook/SideCarPatientContextHook';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';

const GroupListByMember = (props: IGroupListByMember) => {
  const {
    groupMemberData,
    onGroupListActionPerformed,
    onSelect,
    parentCode,
    selectedConversation,
    updateCommunicationList,
  } = props;
  const userId = getUserId();
  const userUuidId = getUserUUID();
  const accountId = getAccountId();
  const commonData = useContext(CommonDataContext);
  const groupTypeList = getMlovListFromCategory(
    commonData.MLOV,
    MLOV_CATEGORY.GroupType
  );
  const contactData = props?.contactData || ({} as any);
  const consentCheck = isConsentRequiredAndGiven(contactData);
  const groupTypeCodeIdObj = getMlovCodeIdObj(groupTypeList);
  const isPatientMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.PATIENT_MESSAGING_ENABLED
  );
  const isP360DateReadAccessEnabledForAllUsers = isAccountConfigEnabled(CONFIG_CODES.P360_DATA_READ_ACCESS_ENABLED_FOR_ALL_USERS);
  const menuCode = isP360DateReadAccessEnabledForAllUsers ? MAIN_MENU_CODES.P360_INBOX : MAIN_MENU_CODES.INBOX;
  const allowedUserAccountP360LocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    menuCode
  );
  const isMSOEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING);
  const [conversationChannelTabState, setConversationChannelTabState] =
    useState<any>({
      isLoading: false,
      visibleDrawerCode: '',
      filterModal: false as boolean,
      openCount: 0,
      displayCreateChatPopup: false,
      isNewChatDrawerVisible: false,
      groupMemberContactData: {} as IContact,
    });
  const [groupListByMemberState, setGroupListByMemberState] =
    useState<IGroupListByMemberState>({
      isLoading: true,
      isFetchMore: false,
      showFetchMore: true,
      selectedConversation: {} as IConversationData,
      groupList: [] as any[],
      userList: [] as any[],
      messageConversationList: [] as any[],
      page: 1 as number,
      conversationList: [] as IConversationData[],
    });
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const [getGroupMemberConversations, {fetchMore}] =
    useLazyQuery<IConversationResponse>(
      GroupQueries.GetSingleNonCallGroupConversations,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
      }
    );

  const hideNewConversationButton =
    !isPatientMessagingEnabled &&
    !contactData.email?.length &&
    !contactData.phoneNumber?.length
      ? true
      : false;
  const getGroupList = () => {
    const variableQueryObj = {
      limit: 30,
      offset: 0,
      loginUserId: userId,
      loginUserIntId: userId,
      loginUserUuid: userUuidId,
      whereCondition: {
        accountId: {
          _eq: accountId,
        },
        _or: [
          {
            inboxId: {
              _neq: -1,
            },
            contactInbox: {
              contact: {
                uuid: {
                  _eq: groupMemberData?.groupUserId || contactData?.uuid,
                },
              },
            },
          },
        ],
      },
      whereConversationConditions: {
        messageType: {
          _nin: [
            MESSAGE_CUSTOM_CARD_TYPE.INCOMING_MISSED_CALL_CARD,
            MESSAGE_CUSTOM_CARD_TYPE.INCOMING_COMPLETED_CALL_CARD,
            MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_MISSED_CALL_CARD,
            MESSAGE_CUSTOM_CARD_TYPE.OUTGOING_COMPLETED_CALL_CARD,
          ],
        },
      },
    };
    if (isPatientMessagingEnabled) {
      variableQueryObj.whereCondition._or.push({
        groupConversation: {
          groupMembers: {
            groupUserId: {
              _eq: groupMemberData?.groupUserId || contactData?.uuid,
            },
            groupUserType: {
              code: {
                _eq: GROUP_MEMBER_TYPE.PATIENT,
              },
            },
          },
          groupType: {
            _or: [
              {
                id: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PRIVATE],
                },
              },
              {
                id: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.PATIENT_BROADCAST],
                },
              },
              {
                id: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.INTERNAL],
                },
              },
              {
                id: {
                  _eq: groupTypeCodeIdObj[GROUP_TYPE_CODES.USER_BROADCAST],
                },
              },
            ],
          },
        },
        ...(isMSOEnabled && allowedUserAccountP360LocationUuids?.length > 0 && {
          practiceLocationUuid: {
            _in: allowedUserAccountP360LocationUuids,
          },
        }),
      } as any);
    }
    return getGroupMemberConversations({
      variables: {...variableQueryObj},
    });
  };

  const getSingleGroupConversation = async (conversationUuid: string) => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const groupResp = await getGroupMemberConversations({
      variables: {
        limit: 1,
        offset: 0,
        loginUserId: userId,
        loginUserUuid: userUuidId,
        loginUserIntId: userId,
        whereCondition: {
          accountId: {
            _eq: accountId,
          },
          uuid: {
            _eq: conversationUuid,
          },
        },
      },
    }).catch(() => {
      emptyRespHandler();
    });
    if (groupResp?.data) {
      const allConversationList = groupResp?.data?.conversations || [];
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isLoading: false,
          selectedConversation: allConversationList?.[0] || {},
          groupList: [...allConversationList],
        };
      });
      if (allConversationList[0]?.id) {
        if (parentCode === PARENT_CODE.PATIENT_INFO_LIST_VIEW) {
          onGroupListActionPerformed?.(COMMON_ACTION_CODES.ITEM_CLICKED, {
            selectedConversation: allConversationList?.[0],
          });
          setGroupListByMemberState((prev) => {
            return {
              ...prev,
              selectedConversation: allConversationList?.[0],
            };
          });
        } else if (parentCode === PARENT_CODE.MESSAGING_DETAIL_PANEL) {
          onSelect && onSelect(allConversationList?.[0]);
        }
      }
    }
  };

  const emptyRespHandler = () => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isLoading: false,
        groupList: [],
        conversationList: [],
      };
    });
  };

  const updateFetchMore = (fetchMoreResult: {
    conversations?: IConversationData[];
  }) => {
    if (
      fetchMoreResult &&
      fetchMoreResult?.conversations &&
      fetchMoreResult?.conversations?.length < 30
    ) {
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
          showFetchMore: false,
        };
      });
    } else {
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isFetchMore: false,
        };
      });
    }
  };

  const loadMoreConversations = async (): Promise<void> => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isFetchMore: true,
      };
    });
    const conversationListData = await fetchMore({
      variables: {
        offset: groupListByMemberState?.conversationList?.length,
      },
    });
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isLoading: false,
        page: groupListByMemberState.page + 1,
        conversationList: [
          ...groupListByMemberState.conversationList,
          ...conversationListData?.data?.conversations,
        ],
      };
    });
    updateFetchMore(conversationListData?.data);
  };

  useSideCarPatientContextEventHook({
    conversationListState: groupListByMemberState,
    setConversationListState: setGroupListByMemberState,
    commonData: commonData,
    contactUuid: contactData?.uuid,
    contactId: contactData?.id,
  });

  useEffect(() => {
    getConversationData();
  }, [
    groupMemberData.id,
    parentCode,
    contactData?.id,
    updateCommunicationList,
  ]);

  useEffect(() => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        selectedConversation: selectedConversation || ({} as IConversationData),
      };
    });
  }, [selectedConversation?.uuid]);

  const getConversationData = async () => {
    setGroupListByMemberState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    try {
      const conversationData = await getGroupList();
      setGroupListByMemberState((prev) => {
        return {
          ...prev,
          isLoading: false,
          page: groupListByMemberState.page + 1,
          conversationList: conversationData?.data?.conversations || [],
        };
      });
    } catch {
      emptyRespHandler();
    }
  };

  const onCreateGroupPopupActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_POPUP: {
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
          };
        });

        break;
      }
      case CONVERSATION_ACTION_CODES.ON_PATIENT_SELECTION: {
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
          };
        });
        if (actionData) {
          getSingleGroupConversation(actionData?.conversationUuid);
        }
        break;
      }
      case CONVERSATION_ACTION_CODES.START_GROUP_CHAT:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            isCreateChannelViewDisplay: true,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_SMS:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS,
          };
        });
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        if (consentCheck) {
          setConversationChannelTabState((prev: any) => {
            return {
              ...prev,
              displayCreateChatPopup: false,
              visibleDrawerCode: CHANNEL_TYPE_CODE.CHANNEL_EMAIL,
            };
          });
        } else {
          const message = isSidecarContext
            ? CONSENT_ERROR_FOR_SIDE_CAR
            : CONSENT_ERROR;
          notification.error({
            message,
          });
        }

        break;
      case CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
          };
        });
        break;
    }
  };

  const isCreateConversationDrawerVisible = (channelCode: string) => {
    return channelCode === conversationChannelTabState.visibleDrawerCode;
  };

  const onCreateSmsConversationActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
      case COMMON_ACTION_CODES.RESET:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        getConversationData();
        // if (actionData?.selectedInboxConversationData?.conversations?.[0]?.id) {
        //   navigate('/inbox/sms', {replace: true});
        // }
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        {
          setConversationChannelTabState((prev: any) => {
            return {
              ...prev,
              isCreateChannelViewDisplay: false,
              visibleDrawerCode: '',
            };
          });
        }
        break;
      case COMMON_ACTION_CODES.RESET: {
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            displayCreateChatPopup: false,
            visibleDrawerCode: '',
          };
        });

        break;
      }
    }
  };

  const getCreateChannelTabCode = () => {
    if (
      conversationChannelTabState.selectedTabCode ===
      CHANNEL_TYPE_CODE.CHANNEL_INTERNAL
    ) {
      return CONVERSATION_TAB_CODES.INTERNAL;
    } else {
      return CONVERSATION_TAB_CODES.PRIVATE;
    }
  };

  const onCreateChannelActionPerformed = (
    actionCode: string,
    actionData?: any
  ) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.DRAWER_CLOSE:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
      case GROUP_ACTION_CODES.GROUP_CREATE:
        setConversationChannelTabState((prev: any) => {
          return {
            ...prev,
            isCreateChannelViewDisplay: false,
            visibleDrawerCode: '',
          };
        });
        break;
    }
  };

  const showCreateGroupPopup = (e: any) => {
    setConversationChannelTabState((prev: any) => {
      return {
        ...prev,
        displayCreateChatPopup: e,
        openCount: prev.openCount + 1,
      };
    });
  };

  const getNewConversationActionCodes = () => {
    const conversationActionCodeList = [];
    if (contactData?.patient?.id || contactData?.patient?.uuid) {
      conversationActionCodeList.push(
        CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT
      );
      conversationActionCodeList.push(
        CONVERSATION_ACTION_CODES.START_GROUP_CHAT
      );
    }

    if (contactData?.email) {
      conversationActionCodeList.push(CONVERSATION_ACTION_CODES.START_EMAIL);
    }

    if (contactData?.phoneNumber) {
      conversationActionCodeList.push(CONVERSATION_ACTION_CODES.START_SMS);
    }
    return conversationActionCodeList;
  };

  return (
    <View>
      <SafeAreaView style={{marginBottom: 10, flex: 1}}>
        {groupListByMemberState?.isLoading ? (
          <Skeleton.Text lines={6} />
        ) : groupListByMemberState?.conversationList?.length > 0 ? (
          <View style={{flex: 1}}>
            <View style={{flex: 1, marginTop: props?.renderNewUI ? 8 : 0}}>
              <FlatList                
                data={groupListByMemberState?.conversationList}
                renderItem={({item, index}) => {
                  return (
                    <Pressable
                      onPress={() => {
                        if (parentCode === PARENT_CODE.PATIENT_INFO_LIST_VIEW) {
                          onGroupListActionPerformed?.(
                            COMMON_ACTION_CODES.ITEM_CLICKED,
                            {
                              selectedConversation: item,
                            }
                          );
                          setGroupListByMemberState((prev) => {
                            return {
                              ...prev,
                              selectedConversation: item,
                            };
                          });
                        } else if (
                          parentCode === PARENT_CODE.MESSAGING_DETAIL_PANEL
                        ) {
                          setGroupListByMemberState((prev) => {
                            return {
                              ...prev,
                              selectedConversation: item,
                            };
                          });
                          onSelect && onSelect(item);
                        }
                      }}
                      backgroundColor={props?.renderNewUI ? '#ffffff' : undefined}
                      style={{
                        borderRadius:
                          selectedConversation?.id === item?.id ? 8 : 8,
                        backgroundColor:
                          selectedConversation?.id === item?.id
                            ? Colors.Custom.Gray100
                            : '#ffffff',
                        marginTop: props?.renderNewUI ? 0 : 2,    
                        marginVertical: props?.renderNewUI ? 4 : 0,
                        marginHorizontal: props?.renderNewUI ? 12 : 0,
                        borderColor: Colors.FoldPixel.GRAY150,
                        borderWidth: props?.renderNewUI ? 0.5 : 0   
                      }}
                      key={`group_member_list_item_${item?.id}_index_${index}`}
                    >
                      <View style={{paddingVertical: 4}}>
                        <ConversationListItem
                          index={index}
                          singleConversationData={item || {}}
                          searchString={''}
                          parentCode={parentCode}
                          displayTimeType={TimeType.timeAgoFullDate}
                          hideOnlineIndicator
                          showLocationName={isMSOEnabled || false}
                        />
                      </View>
                      {props?.renderNewUI ? <></> : <Divider style={{margin: 0}}/>}
                    </Pressable>
                  );
                }}
                keyExtractor={(item: any) => {
                  return 'ConversationList_' + item?.id + +'_' + new Date();
                }}
              />
            </View>
            {renderConversationFooter(
              groupListByMemberState.showFetchMore,
              groupListByMemberState.isFetchMore,
              groupListByMemberState.conversationList?.length,
              loadMoreConversations,
              false
            )}
          </View>
        ) : (
          <>
            {isWeb() ? (
              <View
                style={[
                  {
                    backgroundColor: props?.renderNewUI ? Colors.FoldPixel.GRAY50 : '#ffffff',
                    borderRadius: 8,
                    // @ts-expect-error: Let's ignore a compile error like this unreachable code
                    boxShadow:  props?.renderNewUI ? undefined : `0px 1px 2px rgba(16, 24, 40, 0.06)`,
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                    ...( props?.renderNewUI && {
                      height: 600,
                      justifyContent: 'center',
                    }),
                  },
                ]}
              >
                <View style={{marginBottom: 30}}>
                  {props?.renderNewUI ? (
                    <Stack
                      direction="column"
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 500,
                      }}
                    >
                      <NoChatFound />
                      <DisplayText
                        textLocalId={'noChatFound'}
                        extraStyles={{
                          color: Colors.Custom.Gray400,
                          fontSize: 16,
                          width: '70%',
                          textAlign: 'center',
                        }}
                      />
                    </Stack>
                  ) : (
                    <NoDataFound displayString="noChatFound" />
                  )}
                </View>
                {!hideNewConversationButton && (
                  <Popover
                    style={{
                      width: '200px',
                    }}
                    className={'create-chat'}
                    onVisibleChange={showCreateGroupPopup}
                    trigger="click"
                    visible={conversationChannelTabState.displayCreateChatPopup}
                    content={
                      <CreateAllGroupPopup
                        selectedTabCode={CONVERSATION_TAB_CODES.PATIENTS}
                        openCount={conversationChannelTabState.openCount}
                        onCreateGroupPopupActionPerformed={
                          onCreateGroupPopupActionPerformed
                        }
                        displayTabCodes={getNewConversationActionCodes()}
                        contactData={contactData}
                      />
                    }
                  >
                    <FoldButton
                      nativeProps={{
                        variant: BUTTON_TYPE.PRIMARY,
                        onPress: () => {
                          setConversationChannelTabState((prev: any) => {
                            return {
                              ...prev,
                              displayCreateChatPopup: true,
                            };
                          });
                        },
                      }}
                      customProps={{
                        btnText: (
                          <DisplayText
                            textLocalId={'newConversations'}
                            size={'smBold'}
                            extraStyles={{
                              color: Colors.Custom.mainPrimaryPurple,
                            }}
                          />
                        ),
                        withRightBorder: false,
                      }}
                    ></FoldButton>
                  </Popover>
                )}
                {conversationChannelTabState.isNewChatDrawerVisible && (
                  <InstantChat
                    contactData={contactData}
                    contactUuid={contactData?.uuid}
                    isDrawerVisible={
                      conversationChannelTabState.isNewChatDrawerVisible
                    }
                    onActionPerformed={(actionCode) => {
                      if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                        setConversationChannelTabState((prev: any) => {
                          return {
                            ...prev,
                            isNewChatDrawerVisible: false,
                          };
                        });
                        getConversationData();
                      }
                    }}
                  />
                )}

                <CreateSmsConversationDrawer
                  isDrawerVisible={isCreateConversationDrawerVisible(
                    CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
                  )}
                  selectedInbox={conversationChannelTabState.selectedInbox}
                  onCreateSmsConversationActionPerformed={
                    onCreateSmsConversationActionPerformed
                  }
                  selectedPatient={contactData}
                />
                {isCreateConversationDrawerVisible(
                  CHANNEL_TYPE_CODE.CHANNEL_EMAIL
                ) && (
                  <EmailDrawerCommonV2
                    contactIds={[contactData.id]}
                    isOpen={isCreateConversationDrawerVisible(
                      CHANNEL_TYPE_CODE.CHANNEL_EMAIL
                    )}
                    onClose={() => {
                      onCreateSmsConversationActionPerformed(
                        COMMON_ACTION_CODES.RESET,
                        {}
                      );
                    }}
                    onEmailSent={() => {
                      onCreateSmsConversationActionPerformed(
                        COMMON_ACTION_CODES.RESET,
                        {}
                      );
                    }}
                  />
                )}
                <CreateChannelView
                  isDrawerVisible={isCreateConversationDrawerVisible(
                    CONVERSATION_ACTION_CODES.START_GROUP_CHAT
                  )}
                  onCreateChannelActionPerformed={
                    onCreateChannelActionPerformed
                  }
                  selectedTabCode={getCreateChannelTabCode()}
                />
              </View>
            ) : (
              <View style={{marginBottom: 30}}>
                <NoDataFound displayString="noConversations" />
              </View>
            )}
          </>
        )}
      </SafeAreaView>
    </View>
  );
};

export default GroupListByMember;
