import React, {useEffect, useState} from 'react';
import {ISearchImagingReportLabelsProps, ILabel} from './interfaces';
import {getImagingReportLabels} from '../../../services/CommonService/IntegrationService';
import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import {FormControl, Text} from 'native-base';
import {Colors} from '../../../styles';

const SearchImagingReportLabels = (props: ISearchImagingReportLabelsProps) => {
  const {selectedLabelId, onSelectLabel, errorText, label, isRequired} = props;
  const [labels, setLabels] = useState<ILabel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getLabelsBySearchString = async (searchString: string) => {
    const valueSet = await getImagingReportLabels({
      searchString: searchString,
    });
    const contains = valueSet?.expansion?.contains ?? [];
    const labels: ILabel[] = [];
    contains.forEach((item) => {
      if (item.code && item.display && item.system) {
        labels.push({
          code: item.code,
          display: item.display,
          system: item.system,
        });
      }
    });
    return labels;
  };

  const setInitialState = async () => {
    const labels = await getLabelsBySearchString(selectedLabelId || '');
    setLabels(labels);
  };

  const searchLabels = async (searchString: string) => {
    if (!searchString) return;
    setIsLoading(true);
    setLabels([]);
    const labels = await getLabelsBySearchString(searchString);
    setLabels(labels);
    setIsLoading(false);
  };

  const onChange = (value: string) => {
    if (!value) return;
    const label = labels.find((label) => label.code === value);
    if (!label) return;
    onSelectLabel(label);
  };

  useEffect(() => {
    setInitialState();
  }, []);

  return (
    <FormControl isInvalid={!!errorText} isRequired={isRequired}>
      {label && (
        <FormControl.Label>
          <Text
            style={{
              color: Colors.FoldPixel.GRAY250,
              fontWeight: '400',
              fontSize: 14,
            }}
          >
            {label}
          </Text>
        </FormControl.Label>
      )}
      <Select
        size="large"
        showSearch
        placeholder="Search label"
        filterOption={false}
        status={!!errorText ? 'error' : ''}
        loading={isLoading}
        value={selectedLabelId}
        onSearch={debounce(searchLabels, 500)}
        onChange={onChange}
        notFoundContent={isLoading && <Spin size="large" />}
      >
        {labels.map((label) => (
          <Select.Option
            key={label.code}
            value={label.code}
            label={label.display}
          >
            {label.display}
          </Select.Option>
        ))}
      </Select>
      {errorText && (
        <FormControl.ErrorMessage
          _text={{
            fontSize: 'xs',
            color: 'error.500',
            fontWeight: 500,
          }}
        >
          {errorText}
        </FormControl.ErrorMessage>
      )}
    </FormControl>
  );
};

export default SearchImagingReportLabels;
