import {Builders, Components, Displays, Formio} from '@foldhealth/formiojs';
import BuilderUtils from '@foldhealth/formiojs/lib/utils/builder';
import {isNil, cloneDeep, set, isObject, isEmpty, has, map, some, every, get, clone, omit} from 'lodash';
import NativePromise from 'native-promise-only';
import {forEachExtensiveFormComponent} from '../../FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import Utils from '@foldhealth/formiojs/lib/utils';
import ConditionOperators from '@foldhealth/formiojs/lib/utils';
import { getValue } from '@foldhealth/formiojs/lib/utils/formUtils';
import { FormComponents } from '../../../../PublicPages/PublicForm/PublicFormHelper';
import { CHECK_FIELDS_FOR_EXACTLY_EQUAL_VALUE, CHECK_FIELDS_FOR_HAS_ENTERED_VALUE } from '../../FormBuilderWidget/RiskScoreConfiguration/RiskScoreHelper'
import {v4 as uuidv4} from 'uuid';

const WebformBuilder = Builders.builders.webform;
const Webform = Displays.displays.webform;

Utils.uniqueKey = function (map: any, base: string) {
  let newKey = base;
  const keys = newKey.split('_');
  if (map.hasOwnProperty(newKey) || keys.length === 1) {
    newKey = `${keys[0]}_${uuidv4()}`;
  }
  return newKey;
}

function setPathToComponentAndPerentSchema(component: any) {
  component.path = Utils.getComponentPath(component);
  const dataParent = Utils.getDataParentComponent(component);
  if (dataParent && typeof dataParent === 'object') {
    dataParent.path = Utils.getComponentPath(dataParent);
  }
}

function getRow(component: any, row: any, instance: any, conditional?: any) {
  const condition = conditional || component.conditional;
  // If no component's instance passed (happens only in 6.x server), calculate its path based on the schema
  if (!instance) {
    instance = cloneDeep(component);
    setPathToComponentAndPerentSchema(instance);
  }
  const dataParent = Utils.getDataParentComponent(instance);
  const parentPath = dataParent ? Utils.getComponentPath(dataParent) : null;
  if (dataParent && condition.when?.startsWith(parentPath)) {
    const newRow = {};
    set(newRow, parentPath, row);
    row = newRow;
  }

  return row;
}

export function getComponentActualValue(compPath: any, data: any, row: any) {
  let value = null;

  if (row) {
    value = getValue({ data: row }, compPath);
  }
  if (data && isNil(value)) {
    value = getValue({ data }, compPath);
  }
  if (isNil(value) || (isObject(value) && isEmpty(value))) {
    value = '';
  }
  return value;
}

export function checkForExist(value: any, exist: string, condition: any) {
  if (condition.when === FormComponents.PHONE_NUMBER && value === 'undefined') {
    value = '';
  }
  if (exist === 'true') {
    return String(value || '').trim()?.length > 0;
  }
  else if (exist === 'false') {
    return String(value || '').trim()?.length === 0;
  }
  else {
    return true;
  }
}

export const checkIfComponentIsVisible = (component: any, data: any) => {
  const {conditional: condition} = component;
  if (condition?.when) {
    let value = data[condition.when];
    if (typeof value === 'string') {
      const doc = new DOMParser().parseFromString(value, 'text/html');
      value = doc.body.textContent || "";
    }
    const eq = String(condition.eq ?? '');
    const show = String(condition.show ?? '');
    const exist = String(condition.exist ?? '');
    const operator = String(condition.operator ?? '');

    if (Object.prototype.hasOwnProperty.call(value || {}, 'chiefComplaint') && eq?.trim()?.length > 0) {
      //if component is chiefComplaint
      const displayName = value?.chiefComplaint?.displayName;
      return String(displayName) === eq;
    }

    if (isObject(value) && has(value, (condition as any).eq)) {
      // if value is an object
      return String(value[condition.eq as keyof typeof value]) === show;
    }
    if (Array.isArray(value) && value.map(String).includes(eq)) {
      // if value is an array
      return show === 'true';
    }
    if (CHECK_FIELDS_FOR_HAS_ENTERED_VALUE.some(field => condition?.when?.toLowerCase()?.includes(field))) {
      // if the component in 'when' condition has a component for which we show 'has entered' field
      if (exist === 'true' || exist === 'false') {
        value = value || '';
        if (condition?.when?.toLowerCase()?.includes(FormComponents.PHONE_NUMBER.toLowerCase())) {
          // handling to extract numbers from the string that includes characters in it
          const regexToMatchOnlyNumbers = /\d/g;
          value = value.match(regexToMatchOnlyNumbers, '')?.join('');
        }

        if (eq?.trim()?.length > 0) {
          // if the string has to be matched with a specfic text eq
          if (CHECK_FIELDS_FOR_EXACTLY_EQUAL_VALUE.some(field => condition?.when?.toLowerCase()?.includes(field))) {
            return (String(value) === eq) === (show === 'true');
          }
          return String(value)?.toLocaleLowerCase()?.includes(eq?.toLocaleLowerCase()) === (show === 'true');
        }
        else {
          return checkForExist(value, exist, condition) === (show === 'true');
        }
      }
      else {
        // if 'has entered' field is left empty
        return true;
      }
    }
    if (condition?.when?.toLowerCase()?.includes(FormComponents.CHECKBOX.toLowerCase())) {
      value = value || false;
    }
    // Match exact values for fields other than CHECK_FIELDS_FOR_HAS_ENTERED_VALUE
    // return (String(value) === eq) === (show === 'true');
    return compareValues(value, eq, operator) === (show === 'true');
  }
  else {
   return true;
  }
}

export function checkSimpleConditional(component: any, condition: any, row: any, data: any, instance: any) {
  if (condition?.when) {
    let value = getComponentActualValue(condition.when, data, row);
    if (typeof value === 'string') {
      const doc = new DOMParser().parseFromString(value, 'text/html');
      value = doc.body.textContent || "";
    }
    const eq = String(condition.eq ?? '');
    const show = String(condition.show ?? '');
    const exist = String(condition.exist ?? '');
    const operator = String(condition.operator ?? '');

    if (isObject(value) && has(value, (condition as any).eq)) {
      // if value is an object
      return String(value[condition.eq as keyof typeof value]) === show;
    }
    if (Array.isArray(value) && value.map(String).includes(eq)) {
      // if value is an array
      return show === 'true';
    }
    if (CHECK_FIELDS_FOR_HAS_ENTERED_VALUE.some(field => condition?.when?.toLowerCase()?.includes(field))) {
      // if the component in 'when' condition has a component for which we show 'has entered' field
      if (exist === 'true' || exist === 'false') {
        if (condition?.when?.toLowerCase()?.includes(FormComponents.PHONE_NUMBER.toLowerCase())) {
          // handling to extract numbers from the string that includes characters in it
          const regexToMatchOnlyNumbers = /\d/g;
          value = value.match(regexToMatchOnlyNumbers, '')?.join('');
        }

        if (eq?.trim()?.length > 0) {
          // if the string has to be matched with a specfic text eq
          if (CHECK_FIELDS_FOR_EXACTLY_EQUAL_VALUE.some(field => condition?.when?.toLowerCase()?.includes(field))) {
            return (String(value) === eq) === (show === 'true');
          }
          return String(value)?.toLocaleLowerCase()?.includes(eq?.toLocaleLowerCase()) === (show === 'true');
        }
        else {
          return checkForExist(value, exist, condition) === (show === 'true');
        }
      }
      else {
        // if 'has entered' field is left empty
        return true;
      }
    }
    if (condition?.when?.toLowerCase()?.includes(FormComponents.CHECKBOX.toLowerCase())) {
      value = value || false;
    }
    // Match exact values for fields other than CHECK_FIELDS_FOR_HAS_ENTERED_VALUE
    // return (String(value) === eq) === (show === 'true');
    return compareValues(value, eq, operator) === (show === 'true');
  }
  else {
    const { conditions = [], conjunction = 'all', show = true } = condition;
    if (!conditions.length) {
      return true;
    }
    const conditionsResult = map(conditions, (cond) => {
      const { value: comparedValue, operator, component: conditionComponentPath } = cond;
      if (!conditionComponentPath) {
        return true;
      }
      const value = getComponentActualValue(conditionComponentPath, data, row);
      const ConditionOperator = ConditionOperators[operator];
      return ConditionOperator
        ? new ConditionOperator().Utils.getResult({ value, comparedValue, instance, component, conditionComponentPath })
        : true;
    });

    let result = false;

    switch (conjunction) {
      case 'any':
        result = some(conditionsResult, res => !!res);
        break;
      default:
        result = every(conditionsResult, res => !!res);
    }

    return show ? result : !result;
  }
}

function compareValues(value: any, eq: string, operator: string) {
  if (value === '' || value === undefined || value === null) {
    return false;
  }
  const numValue = parseFloat(value);
  const numEq = parseFloat(eq);
  if (isNaN(numValue) || isNaN(numEq)) {
    return String(value) === eq;
  }
  switch (operator) {
    case 'equal':
      return numValue === numEq;
    case 'not_equal':
      return numValue !== numEq;
    case 'less':
      return numValue < numEq;
    case 'less_or_equal':
      return numValue <= numEq;
    case 'greater':
      return numValue > numEq;
    case 'greater_or_equal':
      return numValue >= numEq;
    default:
      return String(value) === eq;
  }
}

Utils.checkCondition = function (component: any, row: any, data: any, form: any, instance: any) {
  const { customConditional, conditional } = component;
  if (customConditional) {
    return Utils.checkCustomConditional(component, customConditional, row, data, form, 'show', true, instance);
  }
  else if (conditional && conditional.when) {
    row = getRow(component, row, instance);
    return checkSimpleConditional(component, conditional, row, data, instance);
  }
  else if (conditional && conditional.json) {
    return Utils.checkJsonConditional(component, conditional.json, row, data, form, true);
  }

  // Default to show.
  return true;
}

Utils.getCurrencyAffixes = function getCurrencyAffixes(_ref: any) {
  const _ref$currency = _ref.currency || void 0,
    currency = _ref$currency === void 0 ? 'USD' : _ref$currency,
    decimalLimit = _ref.decimalLimit,
    decimalSeparator = _ref.decimalSeparator,
    lang = _ref.lang;
  // Get the prefix and suffix from the localized string.
  let regex = '(.*)?'.concat((100).toLocaleString(lang));

  if (decimalLimit) {
    regex += ''
      .concat(decimalSeparator === '.' ? '\\.' : decimalSeparator)
      .concat((0).toLocaleString(lang), '{')
      .concat(decimalLimit, '}');
  }

  regex += '(.*)?';
  const parts = (100)
    .toLocaleString(lang, {
      style: 'currency',
      currency: currency,
      useGrouping: true,
      maximumFractionDigits: decimalLimit || 0,
      minimumFractionDigits: decimalLimit || 0,
    })
    .replace('.', decimalSeparator)
    .match(new RegExp(regex));
  return {
    prefix: (parts === null || parts === void 0 ? void 0 : parts[1]) || '',
    suffix: (parts === null || parts === void 0 ? void 0 : parts[2]) || '',
  };
};

WebformBuilder.prototype.saveComponent = function (
  component: any,
  parent: any,
  isNew: any,
  original: any
) {
  // this.editForm.detach();
  const parentContainer = parent ? parent.formioContainer : this.container;
  const parentComponent = parent ? parent.formioComponent : this;
  // this.dialog.close();
  const path = parentContainer
    ? this.getComponentsPath(component, parentComponent.component)
    : '';
  if (!original) {
    original = parent.formioContainer.find(
      (comp: any) => comp.id === component.id
    );
  }
  const index = parentContainer ? parentContainer.indexOf(original) : 0;
  // Additional check added to confirm the selected components and component for which editForm is opened is same or not
  // For immediate switching between components or fast dragging of components, editForm and selected component mismatches, causing selected component to be replaced with editForm component
  // which causes API key is not unique issue
  if (
    index !== -1 &&
    this.editForm?.data?.key &&
    original?.key &&
    this.editForm.data.key === original.key
  ) {
    let submissionData = this.editForm.submission.data;
    submissionData = submissionData.componentJson || submissionData;
    const fieldsToRemoveDoubleQuotes = ['label', 'tooltip', 'placeholder'];

    if (submissionData) {
      fieldsToRemoveDoubleQuotes.forEach((key) => {
        if (submissionData[key]) {
          submissionData[key] = submissionData[key].replace(/"/g, "'");
        }
      });
    }
    this.hook('beforeSaveComponentSettings', submissionData);

    let comp: any = null;
    parentComponent.getComponents().forEach((component: any) => {
      if (component.component.key === original.key) {
        comp = component;
      }
    });
    const originalComp = comp.component;
    const originalComponentSchema = comp.schema;

    const isParentSaveChildMethod = this.isParentSaveChildMethod(
      parent.formioComponent
    );

    if (parentContainer && !isParentSaveChildMethod) {
      parentContainer[index] = submissionData;
      if (comp) {
        comp.component = submissionData;
      }
    } else if (isParentSaveChildMethod) {
      parent.formioComponent.saveChildComponent(submissionData);
    }

    const currentScrollPosition = this.refs.form.scrollTop;
    const rebuild = parentComponent.rebuild() || NativePromise.resolve();
    return rebuild.then(() => {
      const schema = parentContainer
        ? parentContainer[index]
        : comp
        ? comp.schema
        : [];
      this.emitSaveComponentEvent(
        schema,
        originalComp,
        parentComponent.schema,
        path,
        index,
        isNew,
        originalComponentSchema
      );
      this.emit('change', this.form);
      this.highlightInvalidComponents();
      this.setActiveClassToComponent(original, parentComponent);
      this.refs.form.scrollTop = this.scrollPosition || currentScrollPosition;
      this.scrollPosition = undefined;
    });
  } else if (index !== -1 && original && parentComponent) {
    this.setActiveClassToComponent(original, parentComponent);
  }

  this.highlightInvalidComponents();
  return NativePromise.resolve();
};

WebformBuilder.prototype.setActiveClassToComponent = function (
  selectedComponent: any,
  parentComponent: any
) {
  const prevElements = document.getElementsByClassName(
    'builder-component active'
  );
  if (prevElements.length > 0) {
    prevElements[0].classList.remove('active');
  }
  parentComponent.getComponents().forEach((component: any) => {
    if (component.component.key === selectedComponent.key) {
      component.element.classList.add('active');
    }
  });
};

WebformBuilder.prototype.editComponent = function (
  component: any,
  parent: any,
  isNew: any,
  isJsonEdit: any,
  original: any,
  flags = {}
) {
  if (!component.key) {
    return;
  }
  let saved = true;
  // const componentCopy = Utils.fastCloneDeep(component);
  const componentCopy = component;
  let ComponentClass = Components.components[componentCopy.type];
  const isCustom = ComponentClass === undefined;
  isJsonEdit = isJsonEdit || isCustom;
  ComponentClass = isCustom ? Components.components.unknown : ComponentClass;
  // Make sure we only have one dialog open at a time.
  // if (this.dialog) {
  //   this.dialog.close();
  //   this.highlightInvalidComponents();
  // }

  // This is the render step.
  const editFormOptions = clone(get(this, 'options.editForm', {}));
  if (this.editForm) {
    this.editForm.destroy();
  }
  this.componentInEdit = component;

  // Allow editForm overrides per component.
  const overrides = get(this.options, `editForm.${componentCopy.type}`, {});

  // Pass along the form being edited.
  editFormOptions.editForm = this.form;
  editFormOptions.editComponent = component;
  editFormOptions.flags = flags;
  this.hook('editComponentParentInstance', editFormOptions, parent);

  this.editForm = new Webform({
    ...omit(this.options, [
      'hooks',
      'builder',
      'events',
      'attachMode',
      'skipInit',
    ]),
    language: this.options.language,
    ...editFormOptions,
  });

  this.hook('editFormProperties', parent);

  this.editForm.form =
    isJsonEdit && !isCustom
      ? {
          components: [
            {
              type: 'textarea',
              as: 'json',
              editor: 'ace',
              weight: 10,
              input: true,
              key: 'componentJson',
              label: 'Component JSON',
              tooltip: 'Edit the JSON for this component.',
            },
            {
              type: 'checkbox',
              key: 'showFullSchema',
              label: 'Full Schema',
            },
          ],
        }
      : ComponentClass.editForm(cloneDeep(overrides));
  const instanceOptions = {};
  this.hook('instanceOptionsPreview', instanceOptions);

  const instance = new ComponentClass(componentCopy, instanceOptions);
  const schema = this.hook('builderComponentSchema', component, instance);

  this.editForm.submission = isJsonEdit
    ? {
        data: {
          componentJson: schema,
          showFullSchema: this.options.showFullJsonSchema,
        },
      }
    : {
        data: instance.component,
      };

  // if (this.preview) {
  //   this.preview.destroy();
  // }

  this.componentEdit = this.ce('div', {class: 'component-edit-container'});
  this.setContent(
    this.componentEdit,
    this.renderTemplate('builderEditForm', {
      componentInfo: ComponentClass.builderInfo,
      editForm: this.editForm.render(),
      preview: this.preview ? this.preview.render() : false,
      helplinks: this.helplinks,
    })
  );

  // added a callback on editing a form component
  this.options?.onEditComponent?.();

  this.loadRefs(this.element, {
    formedit: 'single',
  });
  this.setContent(this.refs.formedit, '');
  this.refs.formedit.appendChild(this.componentEdit);
  this.refs.formedit.classList.add('formedit_flex');

  // This is the attach step.
  this.editForm.attach(this.componentEdit.querySelector('[ref="editForm"]'));
  this.hook('editFormWrapper');
  this.updateComponent(componentCopy);

  if (this.scrollPosition) {
    this.refs.form.scrollTop = this.scrollPosition;
  }

  this.editForm.on('change', (event: any) => {
    if (event.changed) {
      if (
        event.changed.component &&
        event.changed.component.key === 'showFullSchema'
      ) {
        const {value} = event.changed;
        this.editForm.submission = {
          data: {
            componentJson: value ? instance.component : component,
            showFullSchema: value,
          },
        };
        return;
      }
      // See if this is a manually modified key. Treat custom component keys as manually modified
      // In our case key field is disabled and hidden for user, so it can not be changed thus commenting below code
      //   if (
      //     (event.changed.component && event.changed.component.key === 'key') ||
      //     isJsonEdit
      //   ) {
      //     componentCopy.keyModified = true;
      //   }
      event.data.keyModified = false;
      if (
        event.changed.component &&
        ['label', 'title'].includes(event.changed.component.key)
      ) {
        // Ensure this component has a key.
        if (isNew) {
          if (!event.data.keyModified) {
            this.editForm.everyComponent((component: any) => {
              if (
                component.key === 'key' &&
                component.parent.component.key === 'tabs'
              ) {
                component.setValue(this.updateComponentKey(event.data));
                return false;
              }
            });
          }

          if (this.form) {
            let formComponents = this.findNamespaceRoot(parent.formioComponent);
            // excluding component which key uniqueness is to be checked to prevent the comparing of the same keys
            formComponents = formComponents.filter(
              (comp: any) => editFormOptions.editComponent.id !== comp.id
            );

            // Set a unique key for this component.
            BuilderUtils.uniquify(formComponents, event.data);
          }
        }
      }

      // Update the component.
      this.updateComponent(
        event.data.componentJson || event.data,
        event.changed
      );

      this.saveComponent(event.data, parent, isNew);
    }
  });

  this.addEventListener(
    this.componentEdit.querySelector('[ref="cancelButton"]'),
    'click',
    (event: any) => {
      event.preventDefault();
      this.editForm.detach();
      this.emit('cancelComponent', component);
      this.dialog.close();
      this.highlightInvalidComponents();
    }
  );

  this.addEventListener(
    this.componentEdit.querySelector('[ref="removeButton"]'),
    'click',
    (event: any) => {
      event.preventDefault();
      // Since we are already removing the component, don't trigger another remove.
      saved = true;
      this.editForm.detach();
      this.removeComponent(component, parent, original);
      this.dialog.close();
      this.highlightInvalidComponents();
    }
  );

  this.addEventListener(
    this.componentEdit.querySelector('[ref="saveButton"]'),
    'click',
    (event: any) => {
      event.preventDefault();
      if (
        !this.editForm.checkValidity(
          this.editForm.data,
          true,
          this.editForm.data
        )
      ) {
        this.editForm.setPristine(false);
        this.editForm.showErrors();
        return false;
      }
      saved = true;
      this.saveComponent(component, parent, isNew, original);
    }
  );

  const dialogClose = () => {
    this.editForm.destroy(true);
    if (this.preview) {
      this.preview.destroy(true);
      this.preview = null;
    }
    if (isNew && !saved) {
      this.removeComponent(component, parent, original);
      this.highlightInvalidComponents();
    }
    // Clean up.
    this.removeEventListener(this.dialog, 'close', dialogClose);
    this.dialog = null;
  };
  this.addEventListener(this.dialog, 'close', dialogClose);

  // Called when we edit a component.
  this.emit('editComponent', component);
};

Formio.Components.components.component.prototype.attach = function attach(element: any) {
  if (!this.builderMode && !this.previewMode && this.component.modalEdit) {
    const modalShouldBeOpened = this.componentModal ? this.componentModal.isOpened : false;
    const currentValue = modalShouldBeOpened ? this.componentModal.currentValue : this.dataValue;
    const openModalTemplate = this.componentModal && modalShouldBeOpened ? this.componentModal.openModalTemplate : null;
    this.componentModal = this.createComponentModal(element, modalShouldBeOpened, currentValue);
    this.setOpenModalElement(openModalTemplate);
  }

  this.attached = true;
  this.element = element;
  element.component = this; // If this already has an id, get it from the dom. If SSR, it could be different from the initiated id.

  if (this.element.id) {
    this.id = this.element.id;
    this.component.id = this.id;
  }

  this.loadRefs(element, {
    messageContainer: 'single',
    tooltip: 'multiple',
    componentDelete: 'single'
  });
  if (this.refs?.componentDelete) {
    this.addEventListener(
      this.refs.componentDelete,
      'click',
      (event: any) => {
        this.options.onDeleteClick?.(this.component);
      });
  }

  this.attachTooltips(this.refs.tooltip); // Attach logic.

  this.attachLogic();
  this.autofocus(); // Allow global attach.

  this.hook('attachComponent', element, this); // Allow attach per component type.

  const type = this.component.type;

  if (type) {
    this.hook("attach".concat(type.charAt(0).toUpperCase() + type.substring(1, type.length)), element, this);
  }

  this.restoreFocus();
  this.addons.forEach(function (addon: any) {
    return addon.attach(element);
  });
  return NativePromise.resolve();
}

WebformBuilder.prototype.attachComponent = function (
  element: any,
  component: any
) {
  if (this.refs?.form && this.scrollPosition) {
    this.refs.form.scrollTop = this.scrollPosition;
  }
  // Add component to element for later reference.
  element.formioComponent = component;
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const _this3 = this;

  component.loadRefs(element, {
    removeComponent: 'single',
    duplicateComponent: 'single',
    editComponent: 'single',
    moveComponent: 'single',
    copyComponent: 'single',
    pasteComponent: 'single',
    editJson: 'single',
  });

  const parent = this.getParentElement(element);

  component.addEventListener(element, 'click', (event: any) => {
    // added isHandled flag to prevent opening parent component's edit screen
    if (event.isHandled) return;
    event.isHandled = true;

    // if (_this3.refs.formedit && !_this3.refs.formedit.contains(event.target)) {
    //   // event.stopPropagation();
    // }

    if (
      _this3.componentInEdit &&
      _this3.componentInEdit.id === component.component.id
    ) {
      return;
    }

    _this3.editComponent(
      component.component,
      parent,
      false,
      false,
      component.component,
      {inDataGrid: component.isInDataGrid}
    );
  });

  if (component.refs.duplicateComponent) {
    this.attachTooltip(component.refs.removeComponent, this.t('Duplicate'));

    component.addEventListener(
      component.refs.duplicateComponent,
      'click',
      (event: any) => {
        if (
          _this3.refs.formedit &&
          !_this3.refs.formedit.contains(event.target)
        ) {
          event.stopPropagation();
        }
        const schema = cloneDeep(component.schema);
        forEachExtensiveFormComponent([schema], (comp: any) => {
          comp.referenceId = undefined;
          comp.componentId = undefined;
          comp.formComponentId = undefined;
        });
        const parent = this.getParentElement(component.element);
        if (parent) {
          BuilderUtils.uniquify(
            this.findNamespaceRoot(parent.formioComponent),
            schema
          );
          let path = '';
          let index = 0;

          const isParentSaveChildMethod = this.isParentSaveChildMethod(
            parent.formioComponent
          );

          if (parent.formioContainer && !isParentSaveChildMethod) {
            index = parent.formioContainer.indexOf(component.component);
            path = this.getComponentsPath(
              schema,
              parent.formioComponent.component
            );
            parent.formioContainer.splice(index + 1, 0, schema);
          } else if (isParentSaveChildMethod) {
            parent.formioComponent.saveChildComponent(schema, false);
          }
          parent.formioComponent.rebuild();

          this.emitSaveComponentEvent(
            schema,
            schema,
            parent.formioComponent.component,
            path,
            index + 1,
            true,
            schema
          );
        }
        this.emit('change', this.form);
      }
    );
  }

  if (component.refs.removeComponent) {
    this.attachTooltip(component.refs.removeComponent, this.t('Remove'));

    component.addEventListener(
      component.refs.removeComponent,
      'click',
      (event: any) => {
        if (
          _this3.refs.formedit &&
          !_this3.refs.formedit.contains(event.target)
        ) {
          event.stopPropagation();
        }
        const thisComponent = component.formioComponent || component.component;
        let isSelectedComponentRemoved = false;
        if (
          _this3.componentInEdit &&
          _this3.componentInEdit.id === thisComponent.id
        ) {
          _this3.componentInEdit = undefined;
          isSelectedComponentRemoved = true;
          this.refs.formedit.classList.remove('formedit_flex');
          this.setContent(this.refs.formedit, '');
        }
        _this3.removeComponent(
          component.schema,
          parent,
          component.component,
          isSelectedComponentRemoved
        );
      }
    );
  }

  return element;
};

WebformBuilder.prototype.removeComponent = function (
  component: any,
  parent: any,
  original: any,
  isSelectedComponentRemoved: any
) {
  if (!parent) {
    return;
  }
  let remove = true;
  const removingComponentsGroup =
    !component.skipRemoveConfirm &&
    ((Array.isArray(component.components) && component.components.length) ||
      (Array.isArray(component.rows) && component.rows.length) ||
      (Array.isArray(component.columns) && component.columns.length));

  if (this.options.alwaysConfirmComponentRemoval || removingComponentsGroup) {
    const message = removingComponentsGroup
      ? 'Removing this component will also remove all of its children. Are you sure you want to do this?'
      : 'Are you sure you want to remove this component?';
    remove = window.confirm(this.t(message));
  }
  if (!original) {
    original = parent.formioContainer.find(
      (comp: any) => comp.id === component.id
    );
  }
  const index = parent.formioContainer
    ? parent.formioContainer.indexOf(original)
    : 0;
  if (remove && index !== -1) {
    const path = this.getComponentsPath(
      component,
      parent.formioComponent.component
    );
    if (parent.formioContainer) {
      parent.formioContainer.splice(index, 1);
    } else if (
      parent.formioComponent &&
      parent.formioComponent.removeChildComponent
    ) {
      parent.formioComponent.removeChildComponent(component);
    }
    const currentScrollPosition = this.refs.form.scrollTop;
    const rebuild = parent.formioComponent.rebuild() || NativePromise.resolve();
    rebuild.then(() => {
      this.emit(
        'removeComponent',
        component,
        parent.formioComponent.schema,
        path,
        index
      );
      this.emit('change', this.form);
      if (!isSelectedComponentRemoved && this.componentInEdit) {
        const parentComponent = parent ? parent.formioComponent : this;
        this.setActiveClassToComponent(this.componentInEdit, parentComponent);
      }
      if (this.refs?.form) {
        this.refs.form.scrollTop = currentScrollPosition;
      }
      // added a callback on deleting a form component
      this.options?.onRemoveComponent?.(isSelectedComponentRemoved);
    });
  }
  return remove;
};
