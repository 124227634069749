import { useLazyQuery } from '@apollo/client';
import {
  HStack,
  Icon,
  Pressable,
  Spinner,
  Text,
  View,
  VStack
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { useNavigate } from 'react-router-dom';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { FormsQueries } from '../../../../../../services';
import { getAccountUUID, getUserUUID } from '../../../../../../utils/commonUtils';
import PatientCareJourneyDetail from '../../../../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourneyDetail/PatientCareJourneyDetail';
import { isPathValidForUserRolePermission, isSettingAllowed, USER_ACCESS_PERMISSION } from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';
import {
  getWorkFlowFormListByEventEntity,
  getWorkFlowListByEventEntity
} from '../../../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { FormStatus } from './AppointmentBookingEnums';
import CollapsePanel from './CollapsePanel';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { redirectToNewTab } from '../../../../../SideCar/SidecarUtils';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import {usePermissions} from '../../../../../CustomHooks/usePermissions';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import { Colors } from '../../../../../../styles';

const WorkFlowListByEntityEvents = (props: {
  contactIds?: any;
  tenantId?: string;
  isScheduledBack?: boolean;
  isDetailsPage?: boolean;
  workflowlist?: any;
  addUrl?: string;
  title?: string;
  subtitle?: string;
  entityEventList?: any;
  onValueChanage?: (eventEntityList: any) => void;
  backgroundColor?: string;
}) => {
  const [workflowEventEntityAllList, setWorkFlowEventEntityAllList] = useState(
    [] as any
  );
  const [isLoading, setIsLoading] = useState(false);
  const tenantId = props.tenantId || getAccountUUID();
  const {check} = usePermissions()
  const automationAllowedLocationIds = check(MAIN_MENU_CODES.AUTOMATION,USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code).allowedLocationIds
  const isSettingAllowedConfig = isSettingAllowed();
  const [journeyDetailState, setJourneyDetailState] = useState<{
    isJourneyOpen: boolean;
    flowType:string,
    selectedJourneyWorkflowId: any;
  }>({
    isJourneyOpen: false,
    flowType:'',
    selectedJourneyWorkflowId: '',
  });

  const commonContext = useContext(CommonDataContext);
  const isSidecarContext = commonContext.sidecarContext?.isSidecar;
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const userUuid = getUserUUID();
  const [ userPracticeLocations, setUserPracticeLocations] = useState<string[]>([])

  const getUserPracticeLocation = async () => {
    const response = await getUserPracticeLocations()
    const data = response?.data;
    const uuidList: string[] = []

    if (data?.userPracticeLocations?.length > 0) {
      data.userPracticeLocations.forEach((object: any) => {
        if(object?.accountLocations[0] && object?.accountLocations[0]?.uuid)
        uuidList.push(object?.accountLocations[0]?.uuid)
      })
    }
    setUserPracticeLocations(uuidList)
    return uuidList
  };

  const [getUserPracticeLocations] = useLazyQuery(
    UserPracticeLocationQueries.GetMyPracticeLocations,
    {
      fetchPolicy: 'no-cache',
      variables: {
        userUuid: userUuid,
        accountUuid: tenantId,
      },
    }
  );

  useEffect(() => {
    if (props.isScheduledBack && props?.workflowlist?.length > 0) {
      setWorkFlowEventEntityAllList(props.workflowlist);
    } else {
      if (isMsoEnabled) {
        getAllEntityEventsList(automationAllowedLocationIds)
      }
      else {
        getAllEntityEventsList();
      }
    }
  }, [props.contactIds]);

  useEffect(() => {
    if (!props.isDetailsPage) {
      props.onValueChanage && props.onValueChanage(workflowEventEntityAllList);
    }
  }, [workflowEventEntityAllList]);

  const headers = {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  };

  const [getFormAggregate] = useLazyQuery(
    FormsQueries.GET_FORMS_AGGREGATE_FOR_CONTACT,
    {
      fetchPolicy: 'no-cache',
      ...headers,
    }
  );

  const getAllEntityEventsList = (locationIds?: string[]) => {
    setIsLoading(true);
    getWorkFlowListByEventEntity(
      props.entityEventList,
      tenantId,
      isMsoEnabled,
      locationIds || [],
      async (dataList: any) => {
        dataList = dataList || [];
        const finalList = dataList.map((data: any) => {
          return {
            name: data.name,
            description: data.description,
            entity: data.entity,
            eventType: data.eventType,
            isEnabled: data.isEnabled,
            workflowMasterId: data.workflowMasterId,
            flowType: data.flowType,
            workFlowLocations: data?.workflowLocations?.map((loc:any)=>loc?.locationId) || [],
            formNodeList: []
          };
        });
        setWorkFlowEventEntityAllList(finalList)
        setIsLoading(false)
        return
        if (dataList.length === 0) {
          setIsLoading(false);
        }
        if (
          props.workflowlist &&
          props.workflowlist.length > 0 &&
          !props.isDetailsPage &&
          !props.isScheduledBack
        ) {
          for (let i = 0; i < updateWorkFlowList(finalList).length; i++) {
            await getFormlistByEntityEvents(
              updateWorkFlowList(finalList)[i],
              updateWorkFlowList(finalList).length
            );
          }
        } else if (
          (props.workflowlist && props.workflowlist.length > 0) ||
          (props.workflowlist &&
            props.workflowlist.length > 0 &&
            (props.isDetailsPage || props.isScheduledBack))
        ) {
          for (let i = 0; i < updateWorkFlowList(finalList).length; i++) {
            await getFormlistByEntityEvents(
              updateWorkFlowList(finalList)[i],
              updateWorkFlowList(finalList).length
            );
          }
        } else {
          for (let i = 0; i < finalList.length; i++) {
            await getFormlistByEntityEvents(finalList[i], finalList.length);
          }
        }

        Promise.all([allFormIds, formDataList]).then(async (response) => {
          const finalWorkFlowAndFormList = await getFormAggregateData(
            response[0],
            response[1]
          );
          setWorkFlowEventEntityAllList(finalWorkFlowAndFormList);
          setIsLoading(false);
        });
      },
      (error: any) => {

        setIsLoading(false);
        setWorkFlowEventEntityAllList([]);
      }
    );
  };
  const navigate = useNavigate();

  const onSheduleFormClicked = () => {
    if (isSidecarContext) {
      redirectToNewTab(props.addUrl || '');
    } else {
      navigate(props.addUrl || '');
    }
  };

  const updateWorkFlowList = (data: any) => {
    const newList = data;
    data &&
      data.map((workflowList: any, index: number) => {
        props.workflowlist &&
          props.workflowlist.map((propsList: any) => {
            if (workflowList.workflowMasterId === propsList.workflowMasterId) {
              const replaceItem = {
                name: workflowList.name,
                description: workflowList.description,
                entity: workflowList.entity,
                eventType: workflowList.eventType,
                workflowMasterId: workflowList.workflowMasterId,
                isEnabled: propsList.isEnabled,
                flowType: propsList.flowType,
                formNodeList: propsList.formNodeList,
              };
              if (index !== -1) {
                newList[index] = replaceItem;
              }
            }
          });
      });
    return newList;
  };

  const formDataList: any = [];
  const allFormIds: any = [];
  const getFormlistByEntityEvents = async (data: any, length: number) => {
    await getWorkFlowFormListByEventEntity(
      data.workflowMasterId,
      data.entity,
      data.eventType,
      (dataList: any) => {
        dataList = dataList || [];
        dataList &&
          dataList.map((formList: any) => {
            const workflowMasterId = formList.workflowMasterId;
            const formLists: any = [];
            formList.graph &&
              formList.graph.nodes.map((nodelist: any) => {
                if (nodelist.data.nodeType === 'SendForms') {
                  nodelist.data.metaData.userInputFieldList.map(
                    (formList: any) => {
                      if (formList.key === 'formList') {
                        formList.value = formList?.value || [];
                        formList.value.map((form: any) => {
                          const list = {
                            id: form.id,
                            name: form.name,
                            isEnabled: true,
                            formStatus: null,
                            nodeId: nodelist.id,
                          };
                          allFormIds.push(form.id);
                          formLists.push(list);
                        });
                      }
                    }
                  );
                }
              });
            const finalFormData = {
              name: data.name,
              description: data.description,
              entity: data.entity,
              eventType: data.eventType,
              isEnabled: data.isEnabled,
              flowType: data.flowType,
              workflowMasterId: workflowMasterId,
              formNodeList: formLists,
              workFlowLocations: data?.workFlowLocations || []
            };
            formDataList.push(finalFormData);
            // if (formDataList.length === length) {
            //   getFormAggregateData(allFormIds, formDataList);
            //   setIsLoading(false);
            // }
          });
      },
      (error: any) => {

      }
    );
  };

  const getFormAggregateData = async (formIds: any, formDataList: any) => {
    if (props.contactIds) {
      return getFormAggregate({
        variables: {
          contactId: props.contactIds,
          formIds: formIds,
        },
      }).then((data) => {
        if (data.data && data.data.forms && data.data.forms.length > 0) {
          if (
            props.workflowlist &&
            props.workflowlist.length > 0 &&
            !props.isDetailsPage &&
            !props.isScheduledBack
          ) {
            return updateFormAggregate(
              updateWorkFlowFormList(formDataList),
              data.data.forms
            );
          } else if (
            props.workflowlist &&
            props.workflowlist.length > 0 &&
            (props.isDetailsPage || props.isScheduledBack)
          ) {
            return updateFormAggregate(
              updateWorkFlowFormList(formDataList),
              data.data.forms
            );
          } else {
            return updateFormAggregate(formDataList, data.data.forms);
          }
        } else {
          if (
            props.workflowlist &&
            props.workflowlist.length > 0 &&
            !props.isDetailsPage &&
            !props.isScheduledBack
          ) {
            return updateWorkFlowFormList(formDataList);
          } else if (
            props.workflowlist &&
            props.workflowlist.length > 0 &&
            (props.isDetailsPage || props.isScheduledBack)
          ) {
            return updateWorkFlowFormList(formDataList);
          } else {
            return formDataList;
          }
        }
      });
    } else {
      if (
        props.workflowlist &&
        props.workflowlist.length > 0 &&
        !props.isDetailsPage &&
        !props.isScheduledBack
      ) {
        return updateWorkFlowFormList(formDataList);
      } else if (
        props.workflowlist &&
        props.workflowlist.length > 0 &&
        (props.isDetailsPage || props.isScheduledBack)
      ) {
        return updateWorkFlowFormList(formDataList);
      } else {
        return formDataList;
      }
    }
  };

  const updateWorkFlowFormList = (data: any) => {
    const newList = data;
    data &&
      data.map((workflowList: any, index: number) => {
        const formDataNew: any = [];
        workflowList.formNodeList &&
          workflowList.formNodeList.map((formData: any) => {
            props.workflowlist &&
              props.workflowlist.map((propsList: any) => {
                propsList.formNodeList &&
                  propsList.formNodeList.map((propsFormList: any) => {
                    if (formData.id === propsFormList.id) {
                      const formList = {
                        id: formData.id,
                        name: formData.name,
                        isEnabled: propsFormList.isEnabled,
                        formStatus: null,
                        nodeId: formData.nodeId,
                      };
                      formDataNew.push(formList);
                    }
                  });
              });
          });
        const replaceItem = {
          name: workflowList.name,
          description: workflowList.description,
          entity: workflowList.entity,
          eventType: workflowList.eventType,
          workflowMasterId: workflowList.workflowMasterId,
          isEnabled: workflowList.isEnabled,
          flowType: workflowList.flowType,
          formNodeList: formDataNew,
        };
        if (index !== -1) {
          newList[index] = replaceItem;
        }
      });
    return newList;
  };

  const updateFormAggregate = async (data: any, formData: any) => {
    const newList = data;
    data &&
      data.map((workflowList: any, index: number) => {
        workflowList.formNodeList &&
          workflowList.formNodeList.map((formDataOld: any, index1: number) => {
            formData &&
              formData.map((formDataNew: any) => {
                if (formDataOld.id === formDataNew.id) {
                  const formStatus =
                    formDataNew.formResponses_aggregate &&
                    formDataNew.formResponses_aggregate.aggregate &&
                    formDataNew.formResponses_aggregate.aggregate?.count > 0
                      ? FormStatus.received
                      : FormStatus.pending;

                  const formList = {
                    id: formDataOld.id,
                    name: formDataOld.name,
                    isEnabled: formDataOld.isEnabled,
                    formStatus: formStatus,
                    nodeId: formDataOld.nodeId,
                  };
                  newList[index].formNodeList[index1] = formList;
                }
              });
          });
      });
    return newList;
  };

  return (
    <>
      <VStack space={1} marginTop={2} marginBottom={8}>
        <View
          style={{
            backgroundColor: props?.backgroundColor || '#fafafa',
            borderRadius: 8,
          }}
        >
          <View style={{padding: 4, marginLeft: 3, marginRight: 3}}>
            <HStack space={0} marginBottom={0} alignItems="center" flex={1}>
              <Icon
                as={MaterialCommunityIcons}
                marginTop={0.5}
                name="lightning-bolt"
                size="6"
                color="green.500"
                marginLeft={-2}
              />
              <Text flex={9.5} color={Colors.FoldPixel.GRAY400}>{props.title}</Text>
              <Icon
                as={MaterialCommunityIcons}
                marginTop={0.5}
                name="refresh"
                size="6"
                color="green.500"
                right={0}
                flex={0.5}
                alignSelf="flex-end"
                testID='refreshBtn' 
                justifyContent={'flex-end'}
                onPress={() => {
                  if (isMsoEnabled) {
                    getAllEntityEventsList(userPracticeLocations)
                  }
                  else {
                    getAllEntityEventsList();
                  }
                }}
              />
            </HStack>
            {/* <Text marginBottom={2} marginLeft={0} color="gray.500">
              {props.subtitle}
            </Text> */}
            {workflowEventEntityAllList &&
              workflowEventEntityAllList.length > 0 &&
              workflowEventEntityAllList.map((data: any, index: number) => {
                return (
                  <CollapsePanel
                    data={data}
                    workflowEventEntityAllList={workflowEventEntityAllList}
                    index={index}
                    entityEventList={props.entityEventList}
                    onValueChanage={(eventEntityList: any) => {
                      setWorkFlowEventEntityAllList(eventEntityList);
                      props.onValueChanage &&
                        props.onValueChanage(eventEntityList);
                    }}
                    isDetailsPage={props.isDetailsPage}
                    isJourneyShow={(isShow: boolean, workflowMasterId: any, flowType:string) => {
                      // window.open('/#/admin/patients/automation/update/'+workflowMasterId+'?flowType='+flowType);
                      setJourneyDetailState((prev) => ({
                        ...prev,
                        selectedJourneyWorkflowId: workflowMasterId,
                        isJourneyOpen: isShow,
                        flowType: flowType
                      }));
                    }}
                  />
                );
              })}
            {isPathValidForUserRolePermission(
              props?.addUrl ? props?.addUrl : ''
            ) && isSettingAllowedConfig && (
              <Pressable
                onPress={() => {
                  onSheduleFormClicked();
                }}
                {...testID(TestIdentifiers.createCustomAutomation)}
              >
                <HStack
                  space={2}
                  marginTop={5}
                  marginBottom={2}
                  alignItems="center"
                >
                  <Icon
                    as={MaterialIcon}
                    name="add"
                    size="6"
                    color="blue.900"
                  />
                  <Text color="blue.900" fontSize="md" fontWeight={900}>
                    Create custom automation
                  </Text>
                </HStack>
              </Pressable>
            )}
          </View>
          {isLoading && (
            <Spinner
              size="lg"
              position="absolute"
              alignSelf="center"
              top="50%"
              left="50%"
            />
          )}
        </View>

       {journeyDetailState.isJourneyOpen &&
        <PatientCareJourneyDetail
          title={'Automation details'}
          visible={
            journeyDetailState.isJourneyOpen
          }
          flowType={journeyDetailState.flowType}
          patientCareJourney={{
            id: '',
            careJourneyId: journeyDetailState.selectedJourneyWorkflowId,
            careJourneyVersionId: journeyDetailState.selectedJourneyWorkflowId,
            workflowConfig: null,
            careJourney: {
              title: ' ', // pass white space
              duration: 0,
              description: ' ', // pass white space
              durationUnitId: ''
            },
          }}
          showJourneyTab={true}
          showActivityTab={false}
          onClose={() => {
            setJourneyDetailState((prev) => ({
              ...prev,
              selectedJourneyWorkflowId: '',
              isJourneyOpen: false,
            }));
          }}
        />
      }
      </VStack>
    </>
  );
};

export default WorkFlowListByEntityEvents;
