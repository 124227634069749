import {IAddOrUpdateFamilyHistoryParams, IFamilyHistory, IFamilyHistoryComponentValue} from '../interfaces';
import {IKeyOperation} from '../../../../../../../Interfaces';
import { IFormatReconciliationMetaData, IReconciliationFormattedData, IReconciliationConflictField, ReconciliationSource } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { FamilyMemberHistory } from 'fhir/r4';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { FHIRConflictFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { InputType } from '../../../../../../../utils/capabilityUtils';

export enum FamilyHXFields {
  relationShip,
  condition,
  relationship,
}

export const getRecordListFormatted = (list: any[]) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  return getRecordDataFormatted(entries);
}

export const getRecordDataFormatted = (listData: any[]): {list: IFamilyHistory[]; hasIncompleteData: boolean;} => {
  const tempFormattedList: IFamilyHistory[] = [];
  let incompleteData = false;
  const occurrenceMap = new Map<string, number>();
  listData.map((item: any) => {
    const problem = item?.condition?.length
      ? item.condition[0].code
      : undefined;
    const relation = item?.relationship?.coding?.length
      ? item.relationship.coding[0]
      : undefined;

    if (!problem?.text && problem?.coding?.length) {
      problem.text = problem.coding.length
        ? problem.coding[0].display //|| problem.coding[0].code
        : undefined;
    }
    if (problem?.text && item?.condition?.length) {
      const conditionCode = problem?.coding?.[0]?.code || problem?.text;
      const relationshipCode = relation?.code || relation?.display;
      const baseKey = `${conditionCode}-${relationshipCode}`;
      const count = (occurrenceMap.get(baseKey) || 0) + 1;
      occurrenceMap.set(baseKey, count);
      const onsetDate = item.condition?.[0]?.onsetDateTime || item.condition?.[0]?.onsetAge?.value;
      const uniqueId = onsetDate
        ? `${baseKey}-${onsetDate}`
        : `${baseKey}-${count}`;

      const tempObj: IFamilyHistory = {
        id: item?.id,
        condition: problem,
        relationship: {
          code: relation?.display,
          display: relation?.display,
        },
        originalCondition: item.condition?.[0],
        note: item.condition?.[0]?.note?.[0]?.text,
        onsetAge: item.condition?.[0]?.onsetAge?.value,
        deceasedAge: item.deceasedAge?.value,
        uniqueId: uniqueId,
        isFreeTextRecord: !problem?.coding?.length,
      };
      tempFormattedList.push(tempObj);
    } else {
      incompleteData = true;
    }
  });
  return {list: tempFormattedList, hasIncompleteData: incompleteData};
};

export const getSampleData = (): IFamilyHistoryComponentValue => {
  return {
    history: [{
      id: '34234324',
      relationship: {
        code: 'Father',
        display: 'Father',
      },
      condition: {
        text: 'Sample Problem 1',
        coding: [],
      },
      uniqueId: '1234',
      isFreeTextRecord: false,
    },
    {
      id: '34234324',
      relationship: {
        code: 'Father',
        display: 'Father',
      },
      condition: {
        text: 'Sample Problem 2',
        coding: [],
      },
      uniqueId: '1235',
      isFreeTextRecord: false,
    },
    {
      id: '34234324',
      relationship: {
        code: 'Mother',
        display: 'Mother',
      },
      condition: {
        text: 'Sample Problem 3',
        coding: [],
      },
      uniqueId: '1236',
      isFreeTextRecord: false,
    }],
  }
};

export const getConditionName = (item: any) => {
  let name = item?.condition?.text;
  if (!name && item?.condition?.coding?.length) {
    name = item.condition.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: FamilyHXFields,
  item: IFamilyHistory,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  switch (field) {
    case FamilyHXFields.relationShip:
      if ((fields?.relationship?.isRequired || false) && submitTriggered) {
        return !item.relationship || !item.relationship.code;
      }
      return false;

    case FamilyHXFields.condition:
      if ((fields?.condition?.isRequired || false) && submitTriggered) {
        return !item.condition;
      }
      return false;

    default:
      return false;
  }
};

export const getGroupedHXByRelation = (items: IFamilyHistory[]) => {
  const groups: {relation: string; list: IFamilyHistory[]}[] = [];
  const noRelationFamilyHX: IFamilyHistory[] = [];
  items.forEach((item) => {
    if (item.relationship.display) {
      const relation = item.relationship.display;
      const group = groups.find((group) => group.relation === relation);
      if (group) {
        group.list.push(item);
      } else {
        groups.push({relation, list: [item]});
      }
    } else {
      noRelationFamilyHX.push(item);
    }
  });
  if (noRelationFamilyHX.length) {
    return [...groups, {relation: '', list: noRelationFamilyHX}];
  }
  return groups;
};

export const getFHIRTemplate = (params: IAddOrUpdateFamilyHistoryParams) => {
  if (params?.condition?.coding?.[0]?.hasOwnProperty('code')) {
    const isProblemChanged =
      params.condition?.coding?.[0]?.code !==
      params.originalCondition?.code?.coding?.[0]?.code;
    return {
      resourceType: 'FamilyMemberHistory',
      ...(params.id && {id: params.id}),
      relationship: {
        coding: [
          {
            display: params.relationship.display,
          },
        ],
      },
      patient: {
        reference: `Patient/${params.patientId}`,
      },
    ...(!isProblemChanged && params.originalCondition && {
      condition: [params.originalCondition]
        }),
      ...(isProblemChanged && {
        condition: [
          {
            ...(params.note && {
              note: [
                {
                  text: params.note,
              }
            ]
            }),
            ...(params.onsetAge && {
              onsetAge: [
                {
                  value: params.onsetAge,
              }
            ]
            }),
            ...params.originalCondition,
            code: params.condition,
          },
      ]
      }),
      ...(params.deceasedAge && {
        deceasedAge: {
          value: params.deceasedAge,
        },
      }),
    };
  } else {
    return {
      resourceType: 'FamilyMemberHistory',
      ...(params.id && {id: params.id}),
      relationship: {
        coding: [
          {
            display: params.relationship.display,
          },
        ],
      },
      patient: {
        reference: `Patient/${params.patientId}`,
      },

      condition: [
        {
          code: {
            coding: [
              {
                display: params.freeTextPatientHistoryName || params.condition?.text,
              },
            ],
          },
        },
      ],
      ...(params.deceasedAge && {
        deceasedAge: {
          value: params.deceasedAge,
        },
      }),
    };
  }
};

export const getAdditionalDetails = (item: IFamilyHistory, shortVersion: boolean) => {
  let details: string[] = [];
  if (item.onsetAge) {
    details.push(`Onset Age: ${item.onsetAge}`);
  }
  if (item.deceasedAge) {
    details.push(`Died: ${item.deceasedAge}`);
  }
  if (details.length) {
    const firstLine = details.join(' • ');
    details = [firstLine];
  }
  if (item.note && !shortVersion) {
    details.push(`Note: ${item.note}`);
  }
  return details.join('\n');
}


export const formatReconciliationFamilyHistoryData = (
  resource: FamilyMemberHistory,
  metaData: IFormatReconciliationMetaData
): IReconciliationFormattedData | undefined => {

    const familyMemberHistoryResource: FamilyMemberHistory = resource;
    // const formattedFamilyHistories = getRecordDataFormatted([
    //   familyMemberHistoryResource,
    // ]);

    const capabilities = getEHRCapability(metaData.ehrCapabilities, [
      CapabilityResource.familyHistory,
    ]);

    const fieldCapabilities = capabilities?.abilities?.keyAllowedOperations;

    const title = getConditionName(familyMemberHistoryResource.condition?.[0]?.code) || getConditionName({condition: familyMemberHistoryResource.condition?.[0]?.code});

    const relationText = getRelationshipObj(resource)?.display;
    const display = {
      title: title,
      subTitle: relationText,
      status: familyMemberHistoryResource?.status,
    };

    const validatedData = validateAndFormatFamilyHistoryHieResource((metaData?.targetResourceData || resource) as FamilyMemberHistory, fieldCapabilities || {}, metaData);

    return {
      display,
      resourceData: validatedData.resource,
      conflictFields: validatedData.conflictFields
    };
};

const getRelationshipObj = (resource: FamilyMemberHistory) => {
  return resource.relationship?.coding?.[0];
};

const getHieFamilyMemberHistoryConditions = (
  resource: FamilyMemberHistory,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.condition?.[0]?.code;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: coding, text: text};
};

export const getFamilyMemberInvalidCodeField = (isConflict?: boolean, value?: any) => {
  return {
    field: FamilyHXFields.condition,
    inputType: FHIRConflictFields.code,
    invalid: !value?.coding?.length,
    isConflict: isConflict,
    label: 'Condition',
    placeholder: 'Search and add new problem',
    value: value,
    isRequired: true,
    extraData: {
      searchType: PAMISearchType.problem,
    },
  }
}

export const validateAndFormatFamilyHistoryHieResource = (
  resource: FamilyMemberHistory,
  fields: IKeyOperation,
  metaData: IFormatReconciliationMetaData
) => {
  const conflictFields: IReconciliationConflictField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = FamilyHXFields[key as keyof typeof FamilyHXFields];
    if (!fields[key as keyof typeof fields]?.isHidden) {
      switch (field) {
        case FamilyHXFields.condition:
          const conditions = metaData.source === ReconciliationSource.MSO ?
          resource.condition?.[0]?.code :
          getHieFamilyMemberHistoryConditions(
            resource,
            fields?.condition?.allowedCodeSystems
          );

          if (conditions) {
            resource.condition = [
              {
                code: conditions,
              },
            ];
          }
          conflictFields.push(getFamilyMemberInvalidCodeField(false, conditions));
          break;

        case FamilyHXFields.relationship:
          const relationshipList = fields?.relationship?.possibleValues;
          const relationshipText = getRelationshipObj(resource)?.display;
          const relationFromCapability = relationshipList?.find(
            (relationship: any) => relationship.display === relationshipText
          );
          const isMissing = !relationFromCapability && fields?.relationship?.isRequired;
          if (!isMissing && relationFromCapability) {
            resource.relationship = {
              coding: [
                {
                  display: relationFromCapability?.display,
                },
              ],
            };
          }
          conflictFields.push({
            inputType: FHIRConflictFields.select,
            field: FamilyHXFields.relationship,
            invalid: isMissing || false,
            label: 'Relationship',
            placeholder: 'Add new relation',
            value: relationFromCapability,
            isRequired: fields?.relationship?.isRequired,
            extraData: {
              statusList: fields?.relationship?.possibleValues,
            },
          });
          break;
      }
    }
  });

  return {
    conflictFields: conflictFields,
    resource: resource,
  };
};

export const updateHieFamilyHistoryMatchedData = (
  resource: FamilyMemberHistory,
  conflictFields: IReconciliationConflictField[],
  metaData: any
) => {
  const updatedResource = updateHieFamilyHistoryData(resource, conflictFields, metaData);
  return formatReconciliationFamilyHistoryData(updatedResource, metaData);
};

export const updateHieFamilyHistoryData = (
  resource: FamilyMemberHistory,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  conflictFields.map((conflictField) => {
    if (conflictField.value) {
      switch (conflictField.field) {
        case FamilyHXFields.condition:
          resource.condition = [
            {
              code: conflictField.value,
            },
          ];
          break;

        case FamilyHXFields.relationship:
          resource.relationship = {
            coding: [
              {
                display: conflictField.value,
              },
            ],
          };
          break;
      }
    }
    return conflictField;
  });
  return resource;
}

export const formatHieFamilyHistoryResourceObj = (
  resource: FamilyMemberHistory,
  metaData: any
) => {
  return {
    resourceType: 'FamilyMemberHistory',
    ...(resource.relationship && {relationship: resource.relationship}),
    patient: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.condition && {condition: resource.condition}),
    ...(resource.deceasedAge && {
      deceasedAge: {
        value: resource.deceasedAge,
      },
    }),
  };
};
