import React from 'react';
import {Path, Svg} from 'react-native-svg';

const TextFieldSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        fill="#F5F0FF"
      />
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        stroke="#D7C0FF"
        strokeWidth="0.5"
      />
      <Path
        d="M15.5 23.5C15.5 23.7761 15.7239 24 16 24C16.2762 24 16.5 23.7761 16.5 23.5H15.5ZM22.1667 12.625C22.1667 12.9011 22.3905 13.125 22.6667 13.125C22.9428 13.125 23.1667 12.9011 23.1667 12.625H22.1667ZM8.83334 12.625C8.83334 12.9011 9.0572 13.125 9.33334 13.125C9.60949 13.125 9.83334 12.9011 9.83334 12.625H8.83334ZM22.1785 8.98816L22.5321 8.6346V8.6346L22.1785 8.98816ZM11.8333 23C11.5572 23 11.3333 23.2239 11.3333 23.5C11.3333 23.7761 11.5572 24 11.8333 24V23ZM20.1667 24C20.4428 24 20.6667 23.7761 20.6667 23.5C20.6667 23.2239 20.4428 23 20.1667 23V24ZM12.6667 9H16V8H12.6667V9ZM16 9H19.3333V8H16V9ZM16.5 23.5V8.5H15.5V23.5H16.5ZM22.1667 11.8333V12.625H23.1667V11.8333H22.1667ZM8.83334 11.8333V12.625H9.83334V11.8333H8.83334ZM19.3333 9C20.1332 9 20.6827 9.00106 21.0955 9.05656C21.4939 9.11013 21.6893 9.20602 21.825 9.34171L22.5321 8.6346C22.1796 8.28214 21.7381 8.13395 21.2288 8.06548C20.7338 7.99894 20.1049 8 19.3333 8V9ZM23.1667 11.8333C23.1667 11.0618 23.1677 10.4328 23.1012 9.93792C23.0327 9.42862 22.8845 8.98706 22.5321 8.6346L21.825 9.34171C21.9607 9.4774 22.0566 9.67276 22.1101 10.0712C22.1656 10.484 22.1667 11.0335 22.1667 11.8333H23.1667ZM12.6667 8C11.8951 8 11.2662 7.99894 10.7713 8.06548C10.262 8.13395 9.82041 8.28214 9.46795 8.6346L10.1751 9.34171C10.3107 9.20602 10.5061 9.11013 10.9045 9.05656C11.3173 9.00106 11.8669 9 12.6667 9V8ZM9.83334 11.8333C9.83334 11.0335 9.83441 10.484 9.8899 10.0712C9.94347 9.67276 10.0394 9.4774 10.1751 9.34171L9.46795 8.6346C9.11548 8.98706 8.9673 9.42862 8.89882 9.93792C8.83228 10.4328 8.83334 11.0618 8.83334 11.8333H9.83334ZM11.8333 24H20.1667V23H11.8333V24Z"
        fill="#8C5AE2"
      />
    </Svg>
  );
};

export default React.memo(TextFieldSvg);
