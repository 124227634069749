import React, { useEffect } from 'react';
import {Divider, Pressable, Spinner, Text, View} from 'native-base';
import {Colors} from '../../../../../../styles';
import {Popover} from 'antd';
import ThreeDotsSvg from '../../../../../common/Svg/SideCarSvg/ThreeDotsSvg';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {useCareProgramContentHeaderAction} from '../useCareProgramContentHeaderAction';
import FoldButtonV2 from '../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import StepWiseSubtitleView from './StepWiseSubtitleView';
import {getStepViewConfig} from '../../ContactCareProgramView.utils';
import {IContactCareProgramStep} from '../../../interface';
import {useSelectedCareProgramStatus} from '../../hooks/useSelectedCareProgramStatus';
import { useContactCareProgramContext } from '../../ContactCareProgram.context';
import {StyleSheet} from 'react-native';
import GenerateBillActionView from './BillingView/GenerateBillActionView';
import { CARE_PROGRAM_EVENT_CODES } from '../../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { EventBus } from '../../../../../../utils/EventBus';
import { SendClaimModalView } from './ClaimView/SendClaimModalView';
import { CareProgramActiveDrawer, StepAction } from '../../hooks/useCareProgramStepActions';
import { CARE_PROGRAM_EVENTS } from '../../../useCPEventhandler';
import CustomConfirmationModal from '../../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import {DISABLE_STATUS_CODES} from '../../../../../../constants/MlovConst';
import { testID } from '../../../../../../testUtils';

const ContentHeader: React.FC = () => {
  const {
    isDropdownVisible,
    actionList,
    isDropdownLoading,
    isDropdownDisabled,
    isActionDisabled,
    dropdownActionList,
    activeStep,
    mainContentContextState,
    isLoading,
    userMap,
    showSubtitleMessage,
    handleHeaderStatusActions,
    handleDropdownVisibleChange,
    handleHeaderDropdownActions,
    isModalOpen,
    closeModal,
    handleTimeLog,
    totalMinutes,
    updateCareProgramStatus,
    activeDrawer,
    closeActiveDrawer,
    checkPrevFormInstanceExists,
    handleAcceptPreviousFormInstanceAction,
    handleAllowToSubmitForm,
    isPrevFormInstanceExists
  } = useCareProgramContentHeaderAction();
  
  const {programStatusCode} = useSelectedCareProgramStatus();
  const {state} = useContactCareProgramContext();
  const forceReadonly = state?.forceReadonly;
  const isDisabled = !forceReadonly ? DISABLE_STATUS_CODES.includes(programStatusCode) : true;
  const {formScoreMap, contactCareProgramDetails} = state;
  const scores = formScoreMap?.get(activeStep?.id as string) || [];

  const displayTitle = activeStep?.title as string;


  const callGenerateBill = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENT_CODES.GENERATE_BILLING_DATA, {
      contactCareProgramId: contactCareProgramDetails?.id,
    });
  }

  const callSendClaim = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENT_CODES.SEND_CLAIM, {
      contactCareProgramId: contactCareProgramDetails?.id,
      careProgramType: contactCareProgramDetails?.payerCareProgram?.careProgramType?.code,
    });
  }

  const renderSendClaimConfirmationModal = () => {
    return (
      <SendClaimModalView
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={() => {
          callSendClaim();
          // closeModal();
        }}
      />
    );
  };

  const renderGenerateBillModal = () => {
    return (
      <GenerateBillActionView
        isOpen={isModalOpen}
        totalMinutes={totalMinutes}
        contactCareProgramId={contactCareProgramDetails?.id}
        onClose={() => {
          closeModal();
          callGenerateBill();
        }}
        onConfirm={() => {
          handleTimeLog();
          closeModal();
        }}
        onTimeLog={() => {
          closeModal();
        }}
      />
    );
  };


  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    checkPrevFormInstanceExists();
    eventBus.removeEventListener(updateCareProgramStatus);
    eventBus.removeEventListener(handleAcceptPreviousFormInstanceAction);
    eventBus.removeEventListener(handleAllowToSubmitForm);
    eventBus.addEventListener(CARE_PROGRAM_EVENTS.CARE_PROGRAM_ACTION_TAKEN, updateCareProgramStatus);
    eventBus.addEventListener(CARE_PROGRAM_EVENTS.FORM_RESPONSE_ACCEPTED_DECLINED, handleAcceptPreviousFormInstanceAction);
    eventBus.addEventListener(CARE_PROGRAM_EVENTS.ALLOW_TO_SUBMIT_FORM, handleAllowToSubmitForm);
    return () => {
      eventBus.removeEventListener(updateCareProgramStatus);
      eventBus.removeEventListener(handleAcceptPreviousFormInstanceAction);
      eventBus.removeEventListener(handleAllowToSubmitForm);
    }
  }, []);

  return (
    <div className="divider-bottom content-header-container">
      <div className="flex flex-row">
        <Stack direction="column">
          <span className="content-header-title" {...testID(displayTitle)}>{displayTitle}</span>
          {showSubtitleMessage && activeStep && (
            <StepWiseSubtitleView
              selectedStep={activeStep}
              userMap={userMap}
              scores={scores}
            />
          )}
        </Stack>
      </div>
      <div className="flex flex-row items-center">
        {mainContentContextState.headerSavingIndicator ? (
          <Text color={Colors.Custom.Gray500} fontStyle={'italic'} mx={2}>
            Saving...
          </Text>
        ) : (
          <></>
        )}
        {actionList.map((action) => (
          <FoldButtonV2
            {...action}
            isLoading={(isLoading === action.code) && (isLoading!==StepAction.SEND_CLAIM)}
            isDisabled={isActionDisabled || action?.isDisabled}
            onPress={() => handleHeaderStatusActions(action.code)}
          />
        ))}
        {!!dropdownActionList?.length && !isDisabled && (
          <Popover
            overlayClassName="cm-action-popover"
            key={activeStep?.id}
            placement="bottomLeft"
            overlayInnerStyle={{padding: -8, borderRadius: 8}}
            overlayStyle={{padding: 0}}
            overlay={{padding: 0}}
            style={{padding: 0, borderRadius: 16}}
            trigger="click"
            open={isDropdownVisible}
            onOpenChange={handleDropdownVisibleChange}
            content={() => (
              <Stack direction="column" style={{padding: 0, borderRadius: 16}}>
                {dropdownActionList.map((dropdownAction, index) => (
                  <React.Fragment key={dropdownAction.action}>
                    <Pressable
                      style={{padding: 4, borderRadius: 4}}
                      onPress={() => {
                        handleHeaderDropdownActions(dropdownAction.action);
                      }}
                      _hover={{
                        backgroundColor: Colors.FoldPixel.GRAY50,
                      }}
                    >
                      <Stack
                        direction="row"
                        style={{padding: 4, justifyContent: 'space-between'}}
                        space={4}
                      >
                        <Stack
                          direction="row"
                          style={{alignItems: 'center'}}
                          space={4}
                        >
                          <Text
                            style={{
                              color: Colors.FoldPixel.GRAY400,
                              fontSize: 14,
                              fontWeight: '400',
                            }}
                          >
                            {dropdownAction.label}
                          </Text>
                        </Stack>
                      </Stack>
                    </Pressable>
                    {!!dropdownActionList[index + 1] &&
                      dropdownActionList[index + 1]?.action !==
                        dropdownAction.action && (
                        <Divider style={{marginTop: 8, marginBottom: 8}} />
                      )}
                  </React.Fragment>
                ))}
              </Stack>
            )}
          >
            <Pressable
              onPress={() => {
                handleDropdownVisibleChange(true);
              }}
              _hover={{
                backgroundColor: Colors.FoldPixel.GRAY50,
              }}
            >
              {isDropdownLoading ? (
                <Spinner />
              ) : (
                <ThreeDotsSvg
                  customStrokeColor={
                    isDropdownDisabled ? Colors?.FoldPixel?.GRAY150 : undefined
                  }
                />
              )}
            </Pressable>
          </Popover>
        )}
        {isLoading === StepAction.GENERATE_BILL && (
          <View style={styles.modalContainer}>{renderGenerateBillModal()}</View>
        )}
        {isLoading === StepAction.SEND_CLAIM && (
          <View style={styles.modalContainer}>{renderSendClaimConfirmationModal()}</View>
        )}
      </div>
      {
        activeDrawer === CareProgramActiveDrawer.DELETE_CONFIRMATION && (
          <CustomConfirmationModal
            isOpen={activeDrawer === CareProgramActiveDrawer.DELETE_CONFIRMATION}
            headerText={'deleteProgramStep'}
            message={'deleteProgramStepMessage'}
            width={350}
            onCancel={() => {
              closeActiveDrawer();
            }}
            onConfirm={() => {
              handleHeaderDropdownActions(StepAction.DELETE);
              closeActiveDrawer();
            }}
          />
        )
      }
    </div>
  );
};

export default ContentHeader;

const styles = StyleSheet.create({
  modalContainer: {
    marginTop: 8,
  },
  timeLoggingContainer: {
    position: 'absolute',
    top: '100%',
    right: 0,
    zIndex: 1000,
    backgroundColor: 'white',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: '200',
    color: Colors.Custom.Gray500,
    fontFamily: 'Inter_18pt-Regular'
  },
});
