import React, {useCallback, useEffect, useState} from 'react';
import {HStack, VStack, WarningOutlineIcon} from 'native-base';
import {IFormComponentProps} from '../CustomWrapper/CustomWrapper';
import {Checkbox} from 'antd';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {Colors} from '../../../../../../styles';
import FormComponentLoader from '../CustomWrapper/FormComponentLoader/FormComponentLoader';
import { IFormValidationOutput } from '../CustomWrapper/interfaces';
import { convertObjectToArray } from '../../../CustomFormEngine/CustomFormEngineUtils';

interface IMultiSelectCheckBoxesState {
  loading: boolean;
  items: ISelectOption[];
  selectedItems: string[];
}

interface ISelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

const MultiSelectCheckBoxes = (props: IFormComponentProps) => {
  const [componentState, setComponentState] =
    useState<IMultiSelectCheckBoxesState>({
      loading: true,
      items: [],
      selectedItems: convertObjectToArray(props.value || {}),
    });

  const validateData = (currentData: {[index: string]: boolean}): IFormValidationOutput => {
    const updatedList: string[] = [];
    componentState.items.forEach((item) => {
      if (currentData && currentData[item.value]) {
        updatedList.push(item.value);
      }
    });
    const isValid = isValidData(updatedList);
    // Not setting message here as we are already showing an error in view
    return { isValid: isValid, message: '' };
  };

  const isValidData = (currentData: string[]) => {
    if (props.component?.validate?.required) {
      return currentData.length > 0;
    }
    return true;
  };

  const setItems = (): ISelectOption[] => {
    const component = props.component;
    if (component) {
      switch (component.dataSrc) {
        case 'values':
          const items = component.data?.values || [];
          setEmptyStateWithItems(items);
          setComponentState((prev) => ({...prev, loading: false}));
          break;

        case 'asyncOptions':
          if (
            component.data?.asyncOptions &&
            typeof component.data.asyncOptions === 'function'
          ) {
            setComponentState((prev) => ({...prev, loading: true}));
            component.data
              .asyncOptions(props.component, props.options)
              .then((items: ISelectOption[]) => {
                setEmptyStateWithItems(items);
                setComponentState((prev) => ({...prev, loading: false}));
              });
          }
          break;

        default:
          return [];
      }
    }
    return [];
  };

  const setEmptyStateWithItems = (items: ISelectOption[]) => {
    setComponentState((prev) => ({
      ...prev,
      items,
      selectedItems: prev.selectedItems.length > 0 ? prev.selectedItems : items.map((item) => item.value),
    }));
  };

  const setValue = useCallback((value: {[index: string]: boolean}) => {
    if (value && Object.keys(value).length > 0) {
      setComponentState((prev) => {
        const updatedList: string[] = [];
        prev.items.forEach((item) => {
          if (value[item.value]) {
            updatedList.push(item.value);
          }
        });
        return {
          ...prev,
          selectedItems: updatedList,
        };
      });
    }
  }, []);

  useEffect(() => {
    setItems();
  }, []);

  useEffect(() => {
    const data: any = {};
    componentState.items.forEach((item) => {
      data[item.value] = componentState.selectedItems.includes(item.value);
    });
    props.onChange(data);
  }, [componentState.selectedItems]);

  props.validateRef.current = validateData;
  props.setValueRef.current = setValue;

  return (
    <VStack space={2}>
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && (
        <Checkbox.Group
          value={componentState.selectedItems}
          onChange={(values) => {
            const updatedValues = values.map((value) => value as string);
            setComponentState((prev) => ({
              ...prev,
              selectedItems: [...updatedValues],
            }));
          }}
        >
          <VStack>
            {componentState.items.map((item) => {
              return (
                <Checkbox disabled={item?.disabled} value={item.value} key={item.value}>
                  {item.label}
                </Checkbox>
              );
            })}
          </VStack>
        </Checkbox.Group>
      )}
      {!isValidData(componentState.selectedItems) && !componentState.loading && (
        <HStack alignItems="center" space={2}>
          <WarningOutlineIcon color="error.500" size="xs" />
          <DisplayText
            textLocalId="selectOneItemError"
            extraStyles={{color: Colors.error[500]}}
          />
        </HStack>
      )}
    </VStack>
  );
};

export default MultiSelectCheckBoxes;
