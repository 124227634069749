import { Popover, Tooltip } from 'antd';
import {Badge, Box, Divider, HStack, Icon, Pressable, Skeleton, Stack, Text, useMediaQuery, View, VStack, Tooltip as NativeBaseTooltip} from 'native-base';
import React, { useContext, useEffect, useState } from 'react';
import EditSvg from '../../assets/Icons/EditSvg';
import {BANNER_SCREEN_OFFSET_WIDTH, DATE_FORMATS, IS_DEACTIVATE_CONTACT_ENABLED, SMALL_WINDOW, PERSON_TYPES, BUTTON_TYPE, SCORE_TYPE} from '../../constants';
import BaseService from '../../services/CommonService/BaseService';
import {Colors} from '../../styles';
import {  getBooleanFeatureFlag, getHccScoreData, hasValue, isEmployerRole, isEnableCareProgram, isEnableRAFScore, isValidJsonString } from '../../utils/commonUtils';
import {IUserType} from '../../utils/interfaces';
import {isWeb} from '../../utils/platformCheckUtils';
import {capitalizeText} from '../common/ContactRelationView/ContactRelationUtils';
import {DisplayCardAvatar} from '../common/DisplayCard/DisplayCardAvatar';
import {IAvatarStyle, IGroupType} from '../common/DisplayCard/interface';
import ChatCircleSvg from '../common/Svg/ChatCircleSvg';
import MailCircleSvg from '../common/Svg/MailCircleSvg';
import AppUsingPatientSvg from '../common/Svg/SideMenu/AppUsingPatientSvg';
import SMSCircleSvg from '../common/Svg/SMSCircleSvg';
import Feather from 'react-native-vector-icons/Feather';
import {CHANNEL_TYPE} from '../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import MsgHighlighter from '../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationList/MsgHighlighter';
import {
  IContact,
  IContactProfile,
  IConversationData,
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {  isPatientDetailViewAllowed } from '../RightSideContainer/UserAccess/UserAccessPermission';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { getAgeValue, getDateStrFromFormat } from '../../utils/DateUtils';
import { IMessageSubtitleWithMemberHeaderInfo } from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/interface';
import {GROUP_ACTION_CODES, SIDECAR_ALLOWED_VIEWS} from '../../constants/ActionConst';
import { MEMBER_SUBTITLE_DATA_CODE } from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MsgConst';
import { SubtitleData } from '../common/EmailPopupView/interface';
import { testID, TestIdentifiers } from '../../testUtils';
import { getSideCarSubStringObject } from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import AssignActionSvg from '../common/Svg/AssignActionSvg';
import ArrowRightFillSvg from '../common/Svg/ArrowRightFillSvg';
import {isAffiliatePatient} from '../RightSideContainer/Contacts/ContactsUtils';
import { getColorCodeForHCC } from '../../sidecar/common/SideCardCommonUtils';
import { CommonDataContext } from '../../context/CommonDataContext';
import RafScoreDiff from '../RafScore/RafScoreDiff';
import { IcdConditionsList } from '../RafScore/interface';
import '../RafScore/PopOverRafScore.css';
import {RAFScorePopoverContent} from '../RAFScorePopoverContent';
import {styles, reactStyles} from './styles'
import PatientCareProgramConsentView from '../common/CareProgramConsent/PatientCareProgramConsentView';
import { CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE } from '../RightSideContainer/Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import HintPlanDetailView from '../common/HintPlanDetailView/HintPlanDetailView';
import LocationSvg from '../common/Svg/LocationSvg';
import ViewDetailPaneActionSvg from '../common/Svg/PersonActionSvgIcons/ViewDetailPaneActionSvg';
import ViewInEhr from '../common/Svg/PersonActionSvgIcons/ViewInEhr';
import CrossIcon from '../common/Svg/CrossIcon';
import PatientContactProfilesView from '../PersonOmniView/PatientContactProfile/PatientContactProfilesView';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import FeatureFlags from '../../constants/FeatureFlags.enums';

interface PatientInfoBannerProps {
  icdConditionsList?: IcdConditionsList[];
  isHideViewDetailedProfile?: boolean;
  identifier?: string;
  userId: string | number;
  userType: IUserType;
  genderCode?: any;
  groupType?: IGroupType;
  subtitleString?: string;
  subtitleElement?: JSX.Element;
  loggedInTime?: string;
  isSelected?: boolean;
  isHorizontal?: boolean;
  searchString?: string;
  headerString?: string;
  headerSubString?: string;
  shouldShowSubtitleHighlighter?: boolean;
  bannerType: 'listViewBanner' | 'headerBanner' | 'bigBanner' | 'callLogBanner';
  showDetailProfile?: boolean;
  showEditProfile?: boolean;
  onShowDetails?: any;
  onEditDetails?: any;
  contactActiveStatus?: boolean;
  contactData?: IContact;
  hintExtraDetail?:any
  isBigBannerNameTruncate?:string;
  lastMessageElement?: any;
  showEditProfileOnPatientInfoBanner?: boolean;
  singleConversationData?: IConversationData;
  groupName?:string;
  hideConversationTypeImage?: boolean;
  bannerDirection?:string;
  getConversationTypeAvatar?: any;
  showCustomAvatar?: boolean;
  getConversationTypeImgSvg?: boolean;
  deceasedDate?:string;
  assignUserName?:string;
  isShowAssignedIcon?:boolean;
  showRightArrow?:boolean;
  name?:string;
  isLetterAvatarShow?:boolean;
  onLockScreen?: boolean;
  onCallScreen?: boolean;
  contactType?: {
    code?: string
  };
  showPronounName?: string;
  headerContainerWidth?: number;
  onPatientProfileIconClick?: () => void;
  deceasedDateOrientation?: 'row' | 'column';
  memberSubtitleData?: IMessageSubtitleWithMemberHeaderInfo[];
  onActionPerformed?: (code: string , data?: any) => void;
  isVerticalWithBigBanner?: boolean;
  patientHieSyncCallView?: JSX.Element;
  messagingWindowHeaderLoading?: boolean
  isSidecarView?: boolean;
  hideOnlineIndicator?: boolean;
  viewStylePropsForBigBannerSubHeaderView?: any;
  isCompactView?: boolean;
  hideFullNameTooltip?: boolean;
  sideCarDashboardEnabled?: boolean;
  shouldShowCloseButton?: boolean;
  handleCloseClick?: () => void;
  isShowConsentButton?: boolean;
  currentView?: string;
  isReadOnly?: boolean;
  onProfileChange?: (profile: IContactProfile) => void;
  showContactProfilesSwitch?: boolean;
  locationName?: string;
  showLocationName?: boolean;
}

export const PatientInfoBanner = React.memo((props: PatientInfoBannerProps) => {
  const { patientHieSyncCallView, isSidecarView, sideCarDashboardEnabled } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState('');
  const {assignUserName, isShowAssignedIcon, contactType} = props;
  const mlovData = useContext(CommonDataContext);
  const isRAFScoreEnabled = false;//isEnableRAFScore(mlovData.userSettings);
  const isValidJson = isValidJsonString(props?.subtitleString || '');
  const isCompactView = isSidecarView || props.isCompactView;
  const enableCareProgram = isEnableCareProgram(mlovData.userSettings);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  useEffect(()=>{
    if (props?.contactData?.contactProfilePhotos?.length && props?.contactData?.contactProfilePhotos[0]?.attachmentId) {
     getProfilePic();
    }else{
      setProfilePhoto('')
    }
  }, [props?.contactData?.contactProfilePhotos?.length]);
  const isPatient = contactType?.code == 'CUSTOMER'
  const [isSmallWindow] = useMediaQuery([
    { maxWidth: SMALL_WINDOW }
  ]);
  const isSmallScreen =  isSmallWindow;
  const { hccScore, hccScoreDiff, hccScoreValue, formattedHccScoreDiff } = getHccScoreData(
    props?.contactData?.contactScore,
    props?.contactData?.contactScoreAudits,
    SCORE_TYPE,
    hasValue
  );
  const hccScoreColor = getColorCodeForHCC(hccScore || 0);

  const headerNameWidth = props?.headerContainerWidth
    ? props?.headerContainerWidth / 2
    : isSmallScreen
    ? BANNER_SCREEN_OFFSET_WIDTH.BANNER_SCREEN_OFFSET_250
    : BANNER_SCREEN_OFFSET_WIDTH.BANNER_SCREEN_OFFSET_300;

    const canShowContactProfilesView =
      isMultiTenancyEnabled &&
      !!props?.showContactProfilesSwitch &&
      !!props?.contactData &&
      props?.contactData?.contactType?.contactType?.code ===
        PERSON_TYPES.CUSTOMER;
      

  const getProfilePic = async ()=> {
    const baseService = BaseService.getSharedInstance().axios;
    const url = `/crm-nest/api/attachment/` + props?.contactData?.contactProfilePhotos[0]?.attachmentId;
    baseService.get(url)
      .then((resp) => {
        setProfilePhoto(resp.data?.url)
        //return resp.data?.url;
      });
  }

  const getHeader = () => {
    if (props?.bannerType == 'bigBanner') {
      return (
        <HStack mr={props?.isVerticalWithBigBanner ? 0 : 2}>
          <Tooltip
            title={
              isCompactView
                ? capitalizeText(props?.headerString || '')
                : ''
            }
          >
            <Text
              marginTop={1}
              color={Colors.FoldPixel.GRAY400}
              numberOfLines={isWeb() ? (isCompactView ? 1 : 0) : 1}
              maxWidth={
                isCompactView
                  ? BANNER_SCREEN_OFFSET_WIDTH.BANNER_SCREEN_OFFSET_300
                  : 'full'
              }
              size={props?.onCallScreen ? 'smRegular' : 'lgMedium'}
              flexWrap={'wrap'}
              textAlign={
                props?.onCallScreen || props?.isVerticalWithBigBanner
                  ? 'center'
                  : undefined
              }
              marginLeft={props?.onCallScreen ? 2 : undefined}
              {...testID('HeaderString')}
            >
              {props?.headerString || ''}
            </Text>
          </Tooltip>
          {props?.userType == 'PATIENT' && (
            <View mt={2}>
              <AppUsingPatientSvg loggedInTime={props?.loggedInTime} />
            </View>
          )}
        </HStack>
      );
    }
    if (props?.bannerType == 'headerBanner') {
      const isPatientProfileOrSidecar = [SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE, SIDECAR_ALLOWED_VIEWS.SIDECAR].includes(props?.currentView || '');
      return (
        <View style={styles.flexDirectionRowAlignItemsCenter}>
          <Tooltip title={props?.currentView == SIDECAR_ALLOWED_VIEWS.SIDECAR ? capitalizeText(props?.headerString || '') : ''}>
          <div
            className={!isPatientProfileOrSidecar ? "pressable" : ''}
            onClick={() => {
              if(!isPatientProfileOrSidecar) {
                props?.onPatientProfileIconClick?.()
              }
            }}
            {...testID('HeaderInfoClick')}
          >
            <Text
              noOfLines={1}
              isTruncated={true}
              size={props.sideCarDashboardEnabled ? 'lgMedium' : 'mdMediumNew'}
              color={Colors.FoldPixel.GRAY400}
              style={styles.marginRight4}
              {...testID('HeaderString')}
            >
              {capitalizeText(props?.headerString || '')}
            </Text>
          </div>
          </Tooltip>
          {props?.showPronounName?.length ? (
            <Text
              noOfLines={1}
              fontWeight={700}
              fontSize={12}
              color={Colors.Custom.Gray500}
              style={styles.marginRight4}
              {...testID('PronounName')}
            >
              {props?.showPronounName || ''}
            </Text>
          ) : (
            <></>
          )}
          {props.showRightArrow ? (
            <ArrowRightFillSvg customStrokeColor={Colors.Custom.Gray500}/>
          ) : (
            <></>
          )}
        </View>
      );
    }

    if (props?.bannerType == 'callLogBanner') {
      return (
        <>
          <View>
            <Text
              // noOfLines={1}
              style={styles.fontSize16MarginBottom2}
              size={'smSemibold'}
              color={'#000000'}
            >
              {props?.headerSubString || '-'}
            </Text>
            <Text
              // noOfLines={1}
              style={styles.fontSize14MarginBottom4}
              size={'xsMedium'}
              color={Colors?.Custom?.Gray500}
            >
              {props?.headerString || '-'}
            </Text>
          </View>
        </>
      );
    }

    return (
      // <Tooltip title={props?.headerString} placement="topLeft">
        <View style={styles.width100}>
          <Text
            size={
              props?.bannerType == 'listViewBanner' ? 'smMedium' : 'smMedium'
            }
            isTruncated={props?.bannerType == 'listViewBanner' ? true : false}
            maxW={props?.bannerType == 'listViewBanner' ?  props?.locationName ? '60%' : '87%' : '100%'} 
            noOfLines={1}
            fontWeight={500}
            color={ props?.isReadOnly ? Colors.FoldPixel.GRAY300 : Colors.FoldPixel.GRAY400}
            //style={ {height: 20}}
            {...testID('GroupName')}
          >
            {props?.searchString ? (
              <MsgHighlighter
                searchString={props?.searchString || ''}
                contentString={props?.headerString || ''}
              />
            ) : (
              capitalizeText((props.groupName ?  props.groupName : props?.headerString) || '')
            )}
          </Text>
          {!!props?.locationName && !!props?.showLocationName && (
            <View style={styles.locationContainer}>
              <NativeBaseTooltip label={props?.locationName} hasArrow>
                <HStack
                  style={
                    isWeb() ? styles.locationBadgeWeb : styles.locationBadgeApp
                  }
                >
                  <View style={styles.locationSvg}>
                    <LocationSvg height={'12'} width={'12'} />
                  </View>
                  <Text numberOfLines={1} style={styles.locationText}>
                    {props?.locationName}
                  </Text>
                </HStack>
              </NativeBaseTooltip>
            </View>
          )}
        </View>
      // </Tooltip>
    );
  };

  const getViewDetails = (): JSX.Element => {
    return (
        <HStack space={1} alignItems={'center'} marginTop={1}>
          <Pressable
            onPress={() =>{
              isSidecarView ? props?.onPatientProfileIconClick?.() : props?.onShowDetails?.()
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.PRIMARY100,
              paddingRight: 1,
              paddingY: 3
            }}
            height={17} 
            borderRadius={4}
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
            paddingRight={1}
            paddingY={3}
          >
            <ViewDetailPaneActionSvg height={16} width={16} />
            <Text
              color={Colors.FoldPixel.PRIMARY300}
              size={'smRegular'}
              textAlign='center'
              width={'79px'}
            >
              View Profile
            </Text>
          </Pressable>
        {
          isSidecarView ? <></> : 
          <>
          <Divider height={17} orientation='vertical' />
          <Pressable
            onPress={props?.onPatientProfileIconClick}
            {...testID('ExternalLink')}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50,
              padding: 1
            }}
            padding={1}
            borderRadius={4}
          >
            <ViewInEhr width={16} height={16} />
          </Pressable>
          </>
        }
        </HStack>
    );
  };

  const getContactActiveStatus = () => {
    return (
      IS_DEACTIVATE_CONTACT_ENABLED && isPatient && (
        <>
          {props.contactActiveStatus ? (
            <></>
          ) : (
            <HStack>
              <View alignItems={'center'} flexDirection={'row'}>
                <Text color={Colors.Custom.Gray500} {...testID('FoldStatusLabel')}>
                  Fold Status:
                </Text>
                <Text color={Colors.danger[500]} {...testID('FoldStatus')}>
                  {` Inactive`}
                </Text>
              </View>
            </HStack>
          )}
        </>
      )
    );
  };
  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed();
  const showProfileView = () => {
    return (
      <>
        {(props?.showDetailProfile ?? true) &&
        !isEmployerRole() &&
        (isPatientDetailViewAllowedConfig ||
          contactType?.code != PERSON_TYPES.CUSTOMER) &&
          !props?.isHideViewDetailedProfile && props?.currentView != SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER
          ? getViewDetails()
          : null}
        {props?.showEditProfile && !isEmployerRole()
          ? getEditPatientDetails()
          : null}
      </>
    );
  };

  const formattedBirthDate = getDateStrFromFormat(
    props?.contactData?.person?.birthDate || '',
    DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
  )

  const getEditPatientDetails = () => {
    if (isAffiliatePatient(props.contactData?.additionalAttributes)) {
      return <></>;
    }
    return (
      <Text
        size={'smNormal'}
        fontWeight={500}
        noOfLines={1}
        style={styles.editProfileStyle}
        onPress={() => {
          props?.onEditDetails?.();
        }}
        {...testID('EditProfile')}
      >
        Edit Profile
      </Text>
    );
  };

  const getPracticeLocations = () => {
    return (
      <Text size={'xsNormal'} color={Colors.FoldPixel.GRAY250}>
        {props?.contactData?.contactPracticeLocations?.[0]?.practiceLocation
          ?.name || ''}
      </Text>
    );
  };

  const getSubstringByObject = () => {
    if (isCompactView && props?.contactData) {
      const subStringData = getSideCarSubStringObject(props?.contactData, props?.deceasedDate || '', props?.sideCarDashboardEnabled)
      return (
        getOtherSubstringData(subStringData, 0)
      )
    }
    else if (props?.messagingWindowHeaderLoading) {
      return (
        <Text style={styles.colorGray500}>Loading ...</Text>
        // <Skeleton.Text  lines={1} width={headerNameWidth}></Skeleton.Text>
      )
    } else if (isValidJson) {
      const subStringData: any = JSON.parse(props?.subtitleString || '')?.[0];
      const keys = Object.keys(subStringData);
      return (
        <View style={styles.flexDirectionRowAlignItemsCenter}>
          {keys.map((key, index) => {
            const value = subStringData[key];
            if (key === SubtitleData.gender && value?.code?.length) {
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Tooltip
                    title={value?.genderHoverText}
                  >
                    <Text
                      size={
                        props?.bannerType == 'headerBanner'
                          ? 'smNormal'
                          : 'xsNormal'
                      }
                      fontWeight={400}
                      noOfLines={1}
                      style={styles.text}
                      {...testID('GenderLabelText')}
                    >
                      {'\u00A0' + value?.genderLabelText + '\u00A0'}
                    </Text>
                  </Tooltip>
                </View>
              );
            }
            else if(
              key === SubtitleData.dateOfBirth && value?.dobLabelText?.length && value?.dobHoverText?.length
            ){
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Tooltip title={value?.dobHoverText}>
                    <Text
                      size={
                        props?.bannerType == 'headerBanner'
                          ? 'smNormal'
                          : 'xsNormal'
                      }
                      fontWeight={400}
                      noOfLines={1}
                      style={styles.text0}
                      {...testID('DOBLabelText')}
                    >
                      {'\u00A0' + value?.dobLabelText + '\u00A0'}
                    </Text>
                  </Tooltip>
                </View>
              );
            }
            else if (
              key === SubtitleData.birthSex &&
              !subStringData['gender']?.code?.length
            ) {
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Tooltip title={subStringData['gender']?.genderHoverText}>
                    <Text
                      size={
                        props?.bannerType == 'headerBanner'
                          ? 'smNormal'
                          : 'xsNormal'
                      }
                      fontWeight={400}
                      noOfLines={1}
                      style={styles.text}
                      {...testID('GenderLabelText')}
                    >
                      {'\u00A0' + subStringData['gender']?.genderLabelText + '\u00A0'}
                    </Text>
                  </Tooltip>
                </View>
              );
            } else if (key === SubtitleData.memberCountStr) {
              return (getMemberCountString(subStringData[key], index));
            } else if (
              key !== SubtitleData.gender &&
              key !== SubtitleData.birthSex &&
              value?.length
            ) {
              return (
                <View
                  key={key}
                  flexDir={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  {index > 0 && ' | '}
                  <Text
                    size={
                      props?.bannerType == 'headerBanner'
                        ? 'smNormal'
                        : 'xsNormal'
                    }
                    fontWeight={400}
                    noOfLines={1}
                    style={styles.text2}
                  >
                    {(index > 0 ? '\u00A0' : '') + subStringData[key] + '\u00A0'}
                  </Text>
                </View>
              );
            }
            return null;
          })}
        </View>
      );
    }
    else{
      if (props?.memberSubtitleData){
        const memberSubtitleString = props?.memberSubtitleData;
        return(
          getMemberSubtitleDataElement(memberSubtitleString)
        );
      }
      return (
        <>
         {!!props?.subtitleElement ? (
            props?.subtitleElement
          ) : (
          <Text
          size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
          fontWeight={400}
          noOfLines={1}
          maxWidth={headerNameWidth + 100}
          style={{
            color: Colors.FoldPixel.GRAY250,
            marginTop: props?.bannerType == 'headerBanner' ? 0 : 3,
            height: 18,
            marginEnd: 1,
            marginStart: 1,
          }}
          {...testID('SubTitleString')}
        >
          {props?.subtitleString}
        </Text>
         )}
        </>
      );
    }
  };

  const getMemberSubtitleDataElement = (
    memberSubtitleString: IMessageSubtitleWithMemberHeaderInfo[]
  ) => {
    return memberSubtitleString?.map(
      (data: IMessageSubtitleWithMemberHeaderInfo, index: number) => {
        switch (data?.code) {
          case MEMBER_SUBTITLE_DATA_CODE.MEMBER_COUNT:
            return getMemberCountString(data?.value, index);
          default:
            return getOtherSubstring(data?.value, index);
        }
      }
    );
  };

  const getOtherSubstringData = (data: string, index: number) => {
    return (
      <Text
        size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
        fontWeight={400}
        noOfLines={1}
        style={styles.text2}
      >
        {(index > 0 ? '\u00A0' : '') + data + '\u00A0'}
      </Text>
    );
  }

  const getOtherSubstring = (data: string, index: number) => {
    return (
      <View
        key={`${data}_${index}`}
        flexDir={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {index > 0 && '|'}
        {!!props?.contactData?.person?.birthDate &&
        data ===
          getDateStrFromFormat(
            props?.contactData?.person?.birthDate,
            DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
          ) ? (
          <Tooltip
            title={getAgeValue(
              props?.contactData?.person?.birthDate || '',
              props?.deceasedDate || ''
            )}
          >
            {getOtherSubstringData(data, index)}
          </Tooltip>
        ) : (
           getOtherSubstringData(data, index)
        )}
      </View>
    );
  }

  const getMemberCountString = (data: string, index: number) => {
    return (
      <View
        key={`${data}_${index}`}
        flexDir={'row'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {index > 0 && '|'}
        <Pressable
          style={styles.marginLeft4}
          disabled={!props?.singleConversationData?.isActive}
          onPress={() => {
            props.onActionPerformed?.(
              GROUP_ACTION_CODES.GROUP_HEADER_CLICKED
            );
          }}
          {...testID('MemberCount')}
        >
          <Text color={Colors.FoldPixel.PRIMARY300}>
            {data}
          </Text>
        </Pressable>
      </View>
    );
  }

  const getSubtitle = () => {
    if (props?.bannerType == 'callLogBanner') { //not used
      return (
        <>
        {!!props?.subtitleElement ? props?.subtitleElement :
        <Text
          size={'smMedium'}
          // noOfLines={1}
          style={styles.text3}
          {...testID('SubTitleString')}
        >
          {props?.subtitleString || '-'}
        </Text>
        }
        </>
      );
    }
    if (props?.bannerType == 'bigBanner') {
      return (
        <VStack>
          <HStack>
            {!!props?.deceasedDate && (
              <Stack
                alignItems={
                  props.deceasedDateOrientation === 'row' ? 'center' : undefined
                }
                direction={props.deceasedDateOrientation || 'row'}
              >
                <Badge
                  fontSize={12}
                  fontWeight={700}
                  backgroundColor={Colors.Custom.DeceasedTagBackgroundColor}
                  _text={{
                    color: Colors.Custom.DeceasedTagColor,
                  }}
                  marginLeft={props?.onCallScreen ? 100 : undefined}
                  alignSelf={
                    props.deceasedDateOrientation === 'row'
                      ? 'center'
                      : 'flex-start'
                  }
                  rounded="full"
                  justifyContent={'center'}
                  borderColor={Colors.Custom.DeceasedTagBorderColor}
                  marginRight={2}
                  marginBottom={1}
                >
                  {`Deceased : ${props.deceasedDate}`}
                </Badge>
              </Stack>
            )}
          </HStack>
          {!!props?.subtitleElement ? (
            props.subtitleElement
          ) : (
          <Text
            size={'smMedium'}
            // noOfLines={1}
            style={styles.colorGray500}
          >
            {props?.subtitleString}
          </Text>
          )}
        </VStack>
      );
    }

    const getRafScore = (hccScoreValue: string | number) => {
      if (!isCompactView || !isRAFScoreEnabled || !hccScoreValue) {
        return null;
      }

      return (
        <HStack borderColor={Colors.Custom.Gray200} alignItems={'center'}>
          <Text
            size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
            fontWeight={400}
            noOfLines={1}
            style={styles.text2}
          >
            • RAF :
          </Text>
          <Popover
            overlayClassName="raf-score-popover"
            overlayInnerStyle={{ padding: 0, borderRadius: 16 }}
            style={{ borderRadius: 16 }}
            placement="bottom"
            trigger="click"
            content={
              <RAFScorePopoverContent
                contactScore={props?.contactData?.contactScore}
                contactScoreAudits={props?.contactData?.contactScoreAudits}
                icdConditionsList={props?.icdConditionsList}
              />
            }
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              space={2}
              marginLeft={2}
            >
              <Text
                size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
                color={hccScoreColor}
              >
                {hccScoreValue}
              </Text>
                <RafScoreDiff 
                  hccScore={hccScore}
                  hccScoreDiff={hccScoreDiff}
                  formattedHccScoreDiff={formattedHccScoreDiff}
                  arrowSize={12}
                  textSize={'xsNormal'}
                  height={18}
                />
            </Stack>
          </Popover>
        </HStack>
      );
    }

    const getConversationTypeImgSvg = (
      singleConversationData: IConversationData
    ): JSX.Element => {
      if (singleConversationData?.conversationInbox?.channelType) {
        const type =
          singleConversationData?.conversationInbox?.channelType || '';
        if (type === CHANNEL_TYPE.CHANNEL_EMAIL) {
          return <MailCircleSvg></MailCircleSvg>;
        } else if (type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS) {
          return <SMSCircleSvg></SMSCircleSvg>;
        } else {
          return <ChatCircleSvg></ChatCircleSvg>;
        }
      } else {
        return <ChatCircleSvg></ChatCircleSvg>;
      }
    };

    return (
      <HStack>
        {props?.getConversationTypeImgSvg &&
        <View style={styles.width20Height20MarginRight5}>
          {!props.hideConversationTypeImage && getConversationTypeImgSvg(
            props?.singleConversationData || ({} as IConversationData)
          )}
        </View>
        }
        <VStack>
        <Text
          size={props.sideCarDashboardEnabled ? 'smRegular' : props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
          fontWeight={400}
          noOfLines={1}
          maxWidth={headerNameWidth + 100}
          style={{
            color: Colors.Custom.Gray500,
            marginTop: props?.bannerType == 'headerBanner' ? 0 : 3,
            height: props?.messagingWindowHeaderLoading ? 20 : 22,
          }}
          {...testID('SubTitleString')}
        >
          {props?.shouldShowSubtitleHighlighter ? (
            <MsgHighlighter
              searchString={props?.searchString || ''}
              contentString={props?.subtitleString || ''}
            />
          ) : (
            <>
            {getSubstringByObject()}
            {sideCarDashboardEnabled && getRafScore(hccScoreValue)}
            </>
          )}
        </Text>
          {props.contactData?.id && sideCarDashboardEnabled && (
            <VStack mt={2}>
              <HStack alignItems={'center'}>
              <View>
              <LocationSvg height='16' width='16' />
              </View>
              <Text 
               size={props?.bannerType == 'headerBanner' ? 'smNormal' : 'xsNormal'}
               fontWeight={400}
               noOfLines={1}
               style={styles.text2}
               lineHeight={16.8}
              >
                {props?.contactData?.contactPracticeLocations?.[0]?.practiceLocation
                ?.name}
              </Text>
              </HStack>
              <HintPlanDetailView
                id={props.contactData.id}
                isEmployerReportDrillDown={false}
                locationId={props.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid}
                sideCarDashboardEnabled={sideCarDashboardEnabled}
              />
            </VStack>
          )}
          {canShowContactProfilesView && props?.contactData && (
            <PatientContactProfilesView
              contactData={props.contactData}
              onProfileChange={(profile) =>
                props?.onProfileChange && props?.onProfileChange(profile)
              }
            />
          )}
        {showProfileView()}
        </VStack>
      </HStack>
    );
  };

  if (props?.bannerType == 'headerBanner') {
    return (
      <VStack width={sideCarDashboardEnabled ? '100%' : ''} >
      {sideCarDashboardEnabled && patientHieSyncCallView}
      <HStack marginTop={props?.sideCarDashboardEnabled ? 3 : 0} style={styles.width100} >
        <VStack>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12'
            }}
            isLetterAvatarShow
            hideFullNameTooltip={props?.hideFullNameTooltip}
            userData={{
              userId: props?.userId,
              userType: props?.userType,
              genderCode: props?.genderCode as any,
              imgSrc: profilePhoto,
              userName: props.name,
            }}
            groupType={props?.groupType as any}
            hideOnlineIndicator={props?.hideOnlineIndicator}
          />
        </VStack>
        <VStack flex={1} marginLeft={3} justifyContent={'center'}>
          <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack flex={1} alignItems={'center'}>
            {props?.headerString && getHeader()}
            {props?.userType == 'PATIENT' && (
              <AppUsingPatientSvg loggedInTime={props?.loggedInTime} />
              )}
            </HStack>
            {enableCareProgram && sideCarDashboardEnabled && props?.isShowConsentButton && props?.userType == 'PATIENT' && (
              <PatientCareProgramConsentView
                contactId={props?.contactData?.uuid || ''}
                subjectTypeCode={CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT}
                isSidecarContext={isSidecarView}
              />
            )}
            {props?.shouldShowCloseButton && (
              <Pressable
              onPress={props.handleCloseClick}
              style={{marginRight: 4, marginLeft: 4}}
              {...testID('CloseBtnClick')}
            >
                <CrossIcon size={30}/>
              </Pressable>
            )}
          </HStack>
          {props?.subtitleString || props?.subtitleElement
            ? getSubtitle()
            : null}
        </VStack>
        {(isCompactView && isRAFScoreEnabled && !!hccScoreValue && !sideCarDashboardEnabled) &&
          <Stack
            flexDirection={'column'}
            borderColor={Colors.Custom.Gray200}
            style={styles.rafScoreContainer}
          >
            <Text
              style={styles.text4}
            >
              RAF
            </Text>
            <Popover
              overlayClassName='raf-score-popover'
              overlayInnerStyle={{padding: 0, borderRadius: 16}}
              style={{borderRadius: 16}}
              placement={'bottom'}
              trigger="click"
              content={
                <RAFScorePopoverContent
                contactScore={props?.contactData?.contactScore}
                contactScoreAudits={props?.contactData?.contactScoreAudits}
                  icdConditionsList={props?.icdConditionsList}
                />
              }
            >
              <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  borderWidth={isHovered ? 1 : 0}
                  borderColor={
                    isHovered ? Colors.Custom.Gray300 : 'transparent'
                  }
                  borderRadius={isHovered ? 4 : 0}
                  backgroundColor={
                    isHovered ? Colors.Custom.Gray200 : 'transparent'
                  }
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: hccScoreColor,
                    }}
                  >
                    {hccScoreValue}
                  </Text>
                  <RafScoreDiff hccScore={hccScore} hccScoreDiff={hccScoreDiff} formattedHccScoreDiff={formattedHccScoreDiff} />
                </Stack>
              </div>
            </Popover>
          </Stack>
        }
      </HStack>
      </VStack>
    );
  }

  if (props?.bannerType == 'listViewBanner') {
    const userData= {
      userId: props?.userId,
      userType: props?.userType,
    }

    return (
      <HStack flex={1}>
        <VStack>
          {props?.showCustomAvatar && props.getConversationTypeAvatar ? (
           <DisplayCardAvatar
              hideFullNameTooltip={props?.hideFullNameTooltip}
              customAvatarElem={props.getConversationTypeAvatar}
              userData={{
                userId: props?.userId,
                userType: props?.userType,
                genderCode: props?.genderCode as any,
                imgSrc: profilePhoto,
                userName: props.name
              }}
              groupType={props?.groupType as any}
              hideOnlineIndicator={props?.hideOnlineIndicator}
            />
          ) : (
            <DisplayCardAvatar
              hideFullNameTooltip={props?.hideFullNameTooltip}
              avatarStyle={{
                avatarSize: '12',
              }}
              isLetterAvatarShow
              userData={{
                userId: props?.userId,
                userType: props?.userType,
                genderCode: props?.genderCode as any,
                imgSrc: profilePhoto,
                userName: props.name
              }}
              groupType={props?.groupType as any}
            />
          )}
        </VStack>
        <VStack justifyContent={'center'} flex={1}>
          <HStack alignItems={'center'} key={'listViewBanner'}>
            {isShowAssignedIcon && assignUserName ? (
              <Tooltip
                title={assignUserName?.length ? assignUserName : 'Unassigned'}
              >
                <Pressable style={styles.marginRight2MarginLeftMinus4}>
                  <VStack alignItems={'center'}>
                    <AssignActionSvg
                      customStrokeColor={
                        assignUserName && assignUserName.length
                          ? '#12B76A'
                          : Colors.Custom.Gray500
                      }
                    />
                  </VStack>
                </Pressable>
              </Tooltip>
            ) : (
              <></>
            )}
            {props?.headerString && getHeader()}
            {props?.identifier != 'CALL_LOGS' &&
              props?.userType == 'PATIENT' && (
                <AppUsingPatientSvg loggedInTime={props?.loggedInTime} />
              )}
          </HStack>
          {(props?.subtitleString || props?.subtitleElement) ? getSubtitle() : null}
          {props?.lastMessageElement ? props.lastMessageElement : <></>}
        </VStack>
      </HStack>
    );
  }

  if (props?.bannerType == 'callLogBanner') {
    return (
      <HStack>
        <VStack>
          <DisplayCardAvatar
            hideFullNameTooltip={props?.hideFullNameTooltip}
            avatarStyle={{
              avatarSize: '10',
            }}
            isLetterAvatarShow
            userData={{
              userId: props?.userId,
              userType: props?.userType,
              genderCode: props?.genderCode as any,
              imgSrc: profilePhoto,
              userName: props.name
            }}
            groupType={props?.groupType as any}
          />
        </VStack>
        <VStack ml={3}>
          {props?.headerString || props?.headerSubString ? getHeader() : null}
          {(props?.subtitleString || props?.subtitleElement) ?  getSubtitle() : null}
        </VStack>
      </HStack>
    );
  }

  const renderViewDetailsButton = () => {
    if ((props?.showDetailProfile ?? true) && !isEmployerRole() && (isPatientDetailViewAllowedConfig || contactType?.code != PERSON_TYPES.CUSTOMER )) {
      return getViewDetails();
    }
    return <></>
  }

  if (props?.bannerType == 'bigBanner') {
    if(props.bannerDirection == 'HORIZONTAL') {
      return(
        <HStack justifyContent={'space-between'} style={styles.positionRelative} flex={ isWeb() ?  1 : 0}>
          <View  style={styles.flexColumnAlignItemsCenter} flex={1}>
            <VStack>
              <DisplayCardAvatar
                hideFullNameTooltip={props?.hideFullNameTooltip}
                avatarStyle={{
                  avatarSize: '20',
                  textStyle: {
                    fontSize: 40
                  }
                }}
                isLetterAvatarShow
                userData={{
                  userId: props?.userId,
                  userType: props?.userType,
                  genderCode: props?.genderCode as any,
                  imgSrc: profilePhoto,
                  userName: props.name
                }}
                groupType={props?.groupType as any}
              />
            </VStack>
            <View flex={1} style={styles.alignItemsCenterJustifyContentCenter}>
              {props?.headerString && getHeader()}
              {(props?.subtitleString || props?.subtitleElement) ? getSubtitle() : null}
              {props.hintExtraDetail ? props.hintExtraDetail : null}
              {getContactActiveStatus()}
              {showProfileView()}
            </View>
          </View>
          {props.showEditProfileOnPatientInfoBanner && !isEmployerRole() ? (
            <View flex={.1} style={styles.box34PositionAbsoluteRight0}>
              <Pressable
                style={styles.width36Height36}
                onPress={() => {
                  props?.onEditDetails?.();
                }}
              >
              <EditSvg />
              </Pressable>
            </View>
          ) : (
            <></>
          )}
        </HStack>
      )
    }
    if (props?.isHorizontal) {
      return (
        <HStack justifyContent={'space-between'} flex={isWeb() ? 1 : 0}>
          <HStack flex={1}>
            <VStack>
              <DisplayCardAvatar
                hideFullNameTooltip={props?.hideFullNameTooltip}
                avatarStyle={{
                  avatarSize: '20',
                  textStyle: {
                    fontSize: 40,
                  },
                }}
                isLetterAvatarShow
                userData={{
                  userId: props?.userId,
                  userType: props?.userType,
                  genderCode: props?.genderCode as any,
                  imgSrc: profilePhoto,
                  userName: props.name,
                }}
                groupType={props?.groupType as any}
              />
            </VStack>
            <View
              flex={1}
              style={{
                marginLeft: 16,
                ...props?.viewStylePropsForBigBannerSubHeaderView,
              }}
            >
              {props?.headerString && getHeader()}
              {props?.subtitleString || props?.subtitleElement
                ? getSubtitle()
                : null}
              {(props?.contactData?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER && enableCareProgram && hccScoreValue) ?
                <Stack
                  flexDirection={'row'}
                  borderColor={Colors.Custom.Gray200}
                  alignItems={'center'}
                >
                  <Text
                    size={'xsNormal'}
                    style={{
                      color: Colors.FoldPixel.GRAY250,
                      paddingTop: 1,
                    }}
                  >
                    RAF Score:
                  </Text>
                  <Popover
                    overlayClassName='raf-score-popover'
                    overlayInnerStyle={{padding: 0, borderRadius: 16}}
                    style={{borderRadius: 16}}
                    placement={'bottom'}
                    trigger="click"
                    content={
                      <RAFScorePopoverContent
                        contactScore={props?.contactData?.contactScore}
                        contactScoreAudits={props?.contactData?.contactScoreAudits}
                        icdConditionsList={props?.icdConditionsList}
                      />
                    }
                  >
                    <div
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        borderWidth={isHovered ? 1 : 0}
                        borderColor={
                          isHovered ? Colors.Custom.Gray300 : 'transparent'
                        }
                        borderRadius={isHovered ? 4 : 0}
                        backgroundColor={
                          isHovered ? Colors.Custom.Gray200 : 'transparent'
                        }
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            color: hccScoreColor,
                          }}
                          {...testID(TestIdentifiers.rafScoreValue)}
                        >
                          {` ${hccScoreValue}`}
                        </Text>
                        <RafScoreDiff hccScore={hccScore} hccScoreDiff={hccScoreDiff} formattedHccScoreDiff={formattedHccScoreDiff} />
                      </Stack>
                    </div>
                  </Popover>
                </Stack> : null
              }
              {getPracticeLocations()}
              {props.hintExtraDetail ? props.hintExtraDetail : null}
              {getContactActiveStatus()}
              {showProfileView()}
              {canShowContactProfilesView && props?.contactData && (
                <PatientContactProfilesView
                  contactData={props.contactData}
                  onProfileChange={(profile) =>
                    props?.onProfileChange && props?.onProfileChange(profile)
                  }
                />
              )}
            </View>
            {patientHieSyncCallView}
          </HStack>
          {enableCareProgram && props?.isShowConsentButton && props?.userType == 'PATIENT' && (
            <PatientCareProgramConsentView
              contactId={props?.contactData?.uuid || ''}
              subjectTypeCode={CARE_PROGRAM_CONSENT_SUBJECT_TYPE_CODE.CONTACT}
            />
          )}
          {props.showEditProfileOnPatientInfoBanner && !isEmployerRole() ? (
            <View flex={0.1} style={styles.width36Height36}>
              <Pressable
                style={styles.width36Height36}
                onPress={() => {
                  props?.onEditDetails?.();
                }}
                {...testID('EditIcon')}
              >
                <EditSvg />
              </Pressable>
            </View>
          ) : (
            <></>
          )}
        </HStack>
      );
    }

    return (
      <VStack
        justifyContent={props?.isVerticalWithBigBanner ? 'center' : undefined}
        alignItems={props?.isVerticalWithBigBanner ? 'center' : undefined}
      >
        <VStack>
          <DisplayCardAvatar
            hideFullNameTooltip={props?.hideFullNameTooltip}
            avatarStyle={{
              avatarSize: 20,
              textStyle: {
                fontSize: 25
              }
            }}
            isLetterAvatarShow
            userData={{
              userId: props?.userId,
              userType: props?.userType,
              genderCode: props?.genderCode as any,
              imgSrc: profilePhoto,
              userName: props.name
            }}
            groupType={props?.groupType as any}
          />
        </VStack>
        <View
          style={styles.marginTop16}
          alignItems={'center'}
          justifyContent={'center'}
          >
          {props?.headerString ? getHeader() : null}
          {(props?.subtitleString || props?.subtitleElement) ? getSubtitle() : null}
          {props?.isVerticalWithBigBanner && (
            <>
            {getPracticeLocations()}
            {props.hintExtraDetail ? props.hintExtraDetail : null}
            </>
          )}
          {getContactActiveStatus()}
          {props?.isVerticalWithBigBanner && (
            <>
              {showProfileView()}
            </>
          )}
        </View>
      </VStack>
    );
  }

  return null;
});


