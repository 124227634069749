import {Formio} from '@foldhealth/formiojs';
import {DATE_FORMATS} from '../../../../constants';
import {FIELD_TYPES_WITH_RISK_SCORE, getOptionsOfComponent, DISPLAY_HAS_ENTERED_VALUE_FOR_FIELDS, DISPLAY_OPERATORS_FOR_FIELDS} from '../FormBuilderWidget/RiskScoreConfiguration/RiskScoreHelper';
import {
  BasicComponentsKeys,
  HealthComponents,
} from './Builder/SupportedComponents';

export const DISPLAY_DATE_FORMAT = DATE_FORMATS.FORM_DATE_PICKER_FORMAT;

export const getContextComponents = (context: any) => {
  const values: any[] = [];

  context.utils.eachComponent(
    context.instance.options.editForm.components,
    (component: any, path: any) => {
      if (component.key !== context.data.key) {
        values.push({
          label: `${component.label || component.key}`,
          value: path,
        });
      }
    }
  );
  return values;
};

export const getContextOptions = (context: any) => {
  const conditionalComponentKey = context.data.conditional.when;
  let optionList: any[] = [];
  context.utils.eachComponent(
    context.instance.options.editForm.components,
    (component: any) => {
      if (component.key === conditionalComponentKey) {
        optionList = getOptionsOfComponent(component);
      }
    }
  );
  return optionList;
};

export const canClearConditionalValue = (context: any) => {
  const conditionalComponentKey = context.data.conditional.when;
  const selectedValue = context.data.conditional.eq;
  let canClear = false;
  context.utils.eachComponent(
    context.instance.options.editForm.components,
    (component: any) => {
      if (
        component.key === conditionalComponentKey &&
        FIELD_TYPES_WITH_RISK_SCORE.includes(component.type)
      ) {
        const options = getOptionsOfComponent(component);
        const data = options.filter(
          (option: any) => `${option.value}` === `${selectedValue}`
        );
        canClear = data.length === 0;
      }
    }
  );
  return canClear;
};

export const canShowConditionalDropdownOptions = (context: any) => {
  let canShow = false;
  if (context.row && context.row.conditional && context.row.conditional.when) {
    const conditionalFieldKey = context.row.conditional.when;
    context.utils.eachComponent(
      context.instance.options.editForm.components,
      (component: any) => {
        if (component.key === conditionalFieldKey) {
          canShow = FIELD_TYPES_WITH_RISK_SCORE.includes(component.type);
        }
      }
    );
  }
  return canShow;
};

export const canShowFieldHasEnteredValue = (context: any) => {
  let canShow = false;
  if (context.row && context.row.conditional && context.row.conditional.when) {
    const conditionalFieldKey = context.row.conditional.when;
    context.utils.eachComponent(
      context.instance.options.editForm.components,
      (component: any) => {
        if (component.key === conditionalFieldKey) {
          canShow = DISPLAY_HAS_ENTERED_VALUE_FOR_FIELDS.includes(component?.type);
        }
      }
    );
  }
  return canShow;
};

export const canShowOperatorForRating = (context: any) => {
  let canShow = false;
  if (context.row && context.row.conditional && context.row.conditional.when) {
    const conditionalFieldKey = context.row.conditional.when;
    context.utils.eachComponent(
      context.instance.options.editForm.components,
      (component: any) => {
        if (component.key === conditionalFieldKey) {
          canShow = DISPLAY_OPERATORS_FOR_FIELDS.includes(component?.type);
        }
      }
    );
  }
  return canShow;
};

export const canShowHideLabelOption = (context: any) => {
  return ![
    'subjectiveComplaint',
    'objectiveFindings',
    'assessment',
    'plan',
  ].includes(context.row.key);
};

export const canShowDisplayRulesForSOAPHealthComponents = (type: any) => {
  return [
    'subjectiveComplaint',
    'objectiveFindings',
    'assessment',
    'plan',
  ].includes(type);
};

export function getBuilderConfig() {
  return {
    basic: false,
    advanced: false,
    layout: false,
    data: false,
    premium: false,
    healthComponents: {
      title: 'Health components',
      weight: 0,
      components: getEnabledComponents(HealthComponents),
    },
    customBasic: {
      title: 'Basic',
      default: true,
      weight: 1,
      components: getEnabledComponents(BasicComponentsKeys),
    },
    customComponents: {
      title: 'Custom',
      weight: 2,
    },
  };
}

function getEnabledComponents(list: any[]) {
  const listObject: any = {};
  list.forEach((component) => {
    listObject[component] = true;
  });
  return listObject;
}

export function dataURItoBlob(dataURI: any) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString: any;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type: mimeString});
}

export function addOptionalTextInPlaceholder() {
  const optionalText = '(Optional)';

  Object.values(Formio.Components.components).forEach((component: any) => {
    if (component?.editForm) {
      const editFormResult = component?.editForm();
      if (!editFormResult) return;

      if (editFormResult.key) {
      }

      const subComponentList = editFormResult.components;
      if (!subComponentList || !subComponentList.length) return;

      subComponentList.forEach((subComponent: any) => {
        if (
          subComponent?.validate?.required !== true &&
          subComponent.placeholder &&
          !(subComponent.placeholder as string).endsWith(optionalText)
        ) {
          subComponent.placeholder = `${subComponent.placeholder} ${optionalText}`;
        }
      });

      component.editForm = function () {
        return editFormResult;
      };
    }
  });
}

export default {
  DISPLAY_DATE_FORMAT,
  getContextComponents,
  getBuilderConfig,
  dataURItoBlob,
  addOptionalTextInPlaceholder,
};
