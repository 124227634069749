import {IContactProfile} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getAllowedUserAccountLocationUuids} from '../../../utils/commonUtils';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {getAccountUUID} from '../../../utils/commonUtils';
import { CENTRAL_PROFILE_NAME } from '../../../constants/ConstantValues';

export const useGetDefaultContactProfile = () => {
  const allowedUserAccountLocationIds = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );

  const defaultAccountUuid = getAccountUUID();

  const getContactProfilesByUserAllowedLocationIds = (
    contactProfiles: IContactProfile[],
    contactUuid: string,
    accountUuid?: string
  ) => {
    if (!contactProfiles || contactProfiles?.length === 0) {
      contactProfiles = [];
    }

    const filteredContactProfiles =
      contactProfiles.filter((profile: IContactProfile) =>
        allowedUserAccountLocationIds.includes(
          profile?.accountLocationUuid || profile?.accountLocation?.uuid || ''
        )
      ) || [];

    const finalAccountUuid = defaultAccountUuid || accountUuid;

    const primaryContactProfile: IContactProfile = {
      ehrPatientId: contactUuid,
      isActive: true,
      accountUuid: finalAccountUuid,
      contactUuid: contactUuid,
      patientUuid: contactUuid,
      accountLocationUuid: finalAccountUuid,
      accountLocation: {
        uuid: finalAccountUuid,
        practiceLocation: {
          name: CENTRAL_PROFILE_NAME,
        },
      },
    };

    let defaultContactProfile = primaryContactProfile;
    if (filteredContactProfiles?.length === 1) {
      defaultContactProfile = filteredContactProfiles?.[0];
    }
    return {
      contactProfiles: [...filteredContactProfiles, primaryContactProfile],
      defaultContactProfile: defaultContactProfile,
    };
  };

  return {
    getContactProfilesByUserAllowedLocationIds,
  };
};
