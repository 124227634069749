import { Card, Modal, Typography, Tooltip } from 'antd';
import { Badge, Box, Button, HStack, Spinner, View, VStack } from 'native-base';
import { Colors } from '../../../styles';
import ReferralOrderCard from './ReferralOrderCard';
import { useLazyQuery, useMutation } from '@apollo/client';
import CustomAttributeQueries from '../../RightSideContainer/Contacts/CustomField/CustomAttributeQueries';
import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import AddSquareSvg from '../../../assets/Icons/AddSquareSvg';
import ThreeSquaresSvg from '../../../assets/Icons/ThreeSquaresSvg';
import SideCarListSvg from '../../../assets/Icons/SideCarListSvg';
import InsightsCreateReferral from './InsightsCreateReferral';
import { GET_DOC_BY_ATTACHMENTS_IDS } from '../../../services/Document/DocumentQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { Pressable, StyleSheet, useWindowDimensions } from 'react-native';
import SideCarReferralOrderNoDataSvg from '../../../assets/Icons/SideCarReferralOrderNoDataSvg';
import { getAccountId, getBooleanFeatureFlag, getUserId, getUserUUID, replaceHashValueToString } from '../../../utils/commonUtils';
import { IInsightsReferralOrdersView } from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateEFaxConversationDrawer/interface';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import { useIntl } from 'react-intl';
import TrashBinIcon2024 from '../../../assets/Icons/TrashBinIcon2024';
import { MONTH_NAMES, SIDECAR_REFERRAL_MANAGEMENT } from '../constants';
import { CommonDataContext } from '../../../context/CommonDataContext';
import PlusIcon from '../../../components/common/Svg/PlusSvg'
import { GET_CARE_PROGRAM_STEP_LAST_ACTIVITY_LOGS } from '../../../services/ContactCareProgram/ContactCareProgram';
import useCareProgramStepStatus from '../../RightSideContainer/ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStepStatus';
import { testID, TestIdentifiers } from '../../../testUtils';
import { showAddorUpdate } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomComponentUtils';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { getActivePatientProfile } from '../../common/ContactProfile/commonUtils';
import useLocationAccess from '../../common/ContactProfile/useLocationAccess';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';

const { Text } = Typography;

const InsightsReferralOrdersView = (props: IInsightsReferralOrdersView) => {/* main file */
  const { contactData, onBack, formattedContactData, createReferralClicked, onSaveReferralOrder, referralOrderIds, canFetchCareProgramReferrals, isDisabled } = props;
  const [isCreateReferralFormVisible, setCreateReferralFormVisible] = useState<boolean>(props?.isCreateReferralFormVisible || false);
  const { careProgramStepStatus } = useCareProgramStepStatus();
  const [selectedCardDetails, setSelectedCardDetails] = useState<any>(null);
  const [referralOrders, setReferralOrders] = useState<any[]>([]);
  const [deleteValue, setDeleteValue] = useState<any>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('ALL');
  const [preview, setPreview] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const userUUID = getUserUUID();
  const userId = getUserId();
  const accountId = getAccountId();
  const intl = useIntl();
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const [isStepReviewedOrSkipped, setIsStepReviewedOrSkipped] = useState<boolean>(false);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const locationAndPatientData = {
    locationId: contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid,
    patientId: contactData?.patient.patientId
  }
  // const haveAccessToActiveLocation = showAddorUpdate(isMultiTenancyEnabled, true, locationAndPatientData, commonData, formattedContactData?.contactProfile)
  const activePatientProfile = getActivePatientProfile([formattedContactData?.contactProfile]);
  const {haveAccessToLocation : haveAccessToActiveLocation} = useLocationAccess({
    locationUuids: [activePatientProfile?.accountLocationUuid || ""],
    userAccessMainMenuCode: MAIN_MENU_CODES.CONSUMER
  });
  const [getReferralOrderList] = useLazyQuery(
    CustomAttributeQueries.GET_REFERRAL_ORDERS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const {height} = useWindowDimensions();
  const containerHeight = height - 325;

  const [getInboxes] = useLazyQuery(InboxQueries.GetEFaxInboxList, {
    fetchPolicy: 'no-cache',
  });

  const [getCareProgramStepLastActivityLogs] = useLazyQuery(
    GET_CARE_PROGRAM_STEP_LAST_ACTIVITY_LOGS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const checkIfStepReviewedOrSkipped = async (stepId: string) => {
    try {
      const response = await getCareProgramStepLastActivityLogs({
        variables: {
          stepId,
        },
      });

      const logs = response?.data?.getActivityLogs?.activityLogs;
      if (logs && logs.length > 0) {
        const lastLog = logs[0];
        const statusId = lastLog.data?.newData?.careProgramStepStatusId;

        const isReviewedOrSkipped = [
          careProgramStepStatus?.done,
          careProgramStepStatus?.skip
        ].includes(statusId);

        setIsStepReviewedOrSkipped(isReviewedOrSkipped);
        return isReviewedOrSkipped;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const getList = async (referralOrderId?: string, isReferralDeleted?: boolean) => {
    setLoading(true);
    try {
      let response: any = [];
      let customBooleanExpression: any = {};
      if (status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT) {
        customBooleanExpression.status = {
          _eq: SIDECAR_REFERRAL_MANAGEMENT.DRAFT,
        };
      }
      if (!!canFetchCareProgramReferrals) {
        const updatedReferralOrderIds = referralOrderId
          ? isReferralDeleted
            ? referralOrderIds?.filter(id => id !== referralOrderId)
            : [...new Set([...(referralOrderIds || []), referralOrderId])]
          : referralOrderIds;
        customBooleanExpression.id = {_in: updatedReferralOrderIds};

        if (updatedReferralOrderIds && updatedReferralOrderIds?.length > 0) {
          response = await getReferralOrderList({
            variables: {customBooleanExpression},
          });
        }
      } else {
        customBooleanExpression = {
          _and: [
            { contactUuid: { _eq: formattedContactData?.contactUUID } },
            {
              _or: [
                { status: { _neq: SIDECAR_REFERRAL_MANAGEMENT.DRAFT } },
                {
                  _and: [
                    { status: { _eq: SIDECAR_REFERRAL_MANAGEMENT.DRAFT } },
                    { senderUuid: { _eq: userUUID } }
                  ]
                }
              ]
            }
          ]
        };


        response = await getReferralOrderList({
          variables: {customBooleanExpression},
        });
      }

      const fetchedInboxList = await getInboxes({
        variables: {
          userId: userId,
          accountId: accountId,
        },
      });

      const inboxData = fetchedInboxList?.data?.inboxes || [];
      const referralManagementData = response?.data?.referralManagement || [];

      const inboxIdToEFaxMap = inboxData?.reduce((acc: any, inbox: any) => {
        acc[inbox.id] = inbox.channelEfax?.efaxNumber;
        return acc;
      }, {});

      const allAttachmentIds = referralManagementData?.reduce((ids: string[], referral: any) => {
        const attachmentIds = referral?.referralAttachments?.map(
          (attachment: any) => attachment?.attachmentUuid
        ) || [];
        return [...ids, ...attachmentIds];
      }, []);

      const docInfo = await documentInformationByAttachmentIds(allAttachmentIds);
      const documentsMap = docInfo?.data?.documents?.reduce((acc: any, doc: any) => {
        doc?.documentAttachments?.forEach((att: any) => {
          if (att?.attachment && !att?.isDeleted) {
            acc[att?.id] = doc;
          }
        });
        return acc;
      }, {});

      const modifiedReferralManagement = referralManagementData.map((referral: any) => {
        const efaxNumber = inboxIdToEFaxMap[referral?.efaxNumber];
        const attachmentIds = referral?.referralAttachments?.map(
          (attachment: any) => attachment?.attachmentUuid
        ) || [];

        const documentInfo = attachmentIds.map((id: string) => documentsMap[id]).filter(Boolean);

        return {
          ...referral,
          attachmentIds,
          documentInfo,
          senderEfaxNo: efaxNumber || null,
          isDisabled: isMsoEnabled && !haveAccessToActiveLocation
        };
      });

      const groupedByMonth = modifiedReferralManagement.reduce((acc: any, referral: any) => {
        const date = new Date(referral.createdAt);
        const month = date.getMonth();
        const year = date.getFullYear();
        const monthYear = `${MONTH_NAMES[month]} ${year}`;

        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(referral);

        return acc;
      }, {});

      setReferralOrders(groupedByMonth);
      setLoading(false);
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  };

  const [getDocInfo] = useLazyQuery(
    GET_DOC_BY_ATTACHMENTS_IDS,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const documentInformationByAttachmentIds = async (ids: string[]) => {
    try {
      if (!ids?.length) {
        return { data: { documents: [] } };
      }
      const variables = { ids };
      const response = await getDocInfo({
        variables
      });
      if (!response?.data?.documents) {
        return { data: { documents: [] } };
      }
      const filteredDocs = response.data.documents.filter((doc: any) =>
        doc.documentAttachments?.some((att: any) =>
          att.attachment && !att.isDeleted && ids.includes(att?.id)
        )
      );
      return {
        data: {
          documents: filteredDocs
        }
      };

    } catch (err) {
      console.error('Error fetching document info:', err);
      return { data: { documents: [] } };
    }
  }

  const [deleteReferralOrderList] = useMutation(
    CustomAttributeQueries.DELETE_REFERRAL_ORDER,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const deleteOrder = async (id: string) => {
    try {
      const variables = {
        id
      };
      const response = await deleteReferralOrderList({
        variables
      });
      onSaveReferralOrder && onSaveReferralOrder(id, true);
      getList(id, true);
    } catch (err) {
      console.log(err)
    }
  }

  const handleSetStatus = (status: string) => {
    setStatus(status)
  }

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    const checkStepStatus = async () => {
      if (props?.stepId && props?.isCareManagementView) {
         await checkIfStepReviewedOrSkipped(props?.stepId);
      }
    };
    checkStepStatus();
  }, [props.stepId]);

  const handleCardClick =  (details: any) => {
    setSelectedCardDetails(details);
    if(createReferralClicked){
    const data = {
      contactData,
      preview: details ? preview : false,
      receiverDetails: { name: details?.receiver?.name, uuid: details?.receiver?.uuid, phoneNumber: details?.receiver?.eFaxNo?.[0]?.value, cityName: details?.receiver?.personAddress?.[0]?.cities?.name, zipCode: details?.receiver?.personAddress?.[0]?.zipcodes?.code, speciality: details?.receiver?.speciality?.[0]?.value },
      isEdit: details && preview ? true : false,
      documents: details?.documentInfo,
      reasonForReferral: details?.reasonForReferral,
      details,
      note: details?.summary,
      inboxId: (details?.efaxNumber),
      onBack: () => {
        getList()
        onBack()
        setPreview(true)
      },
      formattedContactData,
      setCreateReferralFormVisible: () => setCreateReferralFormVisible(false),
      accountLocationUuid: formattedContactData?.contactProfile?.accountLocationUuid
    }
      createReferralClicked && createReferralClicked(data);
    }else{
      setCreateReferralFormVisible(true)
    }
  };

  if (isCreateReferralFormVisible) {
    if (createReferralClicked) {
      const data = {
        contactData,
        preview: selectedCardDetails ? preview : false,
        receiverDetails: selectedCardDetails ? { name: selectedCardDetails?.receiver?.name, uuid: selectedCardDetails?.receiver?.uuid, phoneNumber: selectedCardDetails?.receiver?.eFaxNo?.[0]?.value, cityName: selectedCardDetails?.receiver?.personAddress?.[0]?.cities?.name, zipCode: selectedCardDetails?.receiver?.personAddress?.[0]?.zipcodes?.code, speciality: selectedCardDetails?.receiver?.speciality?.[0]?.value } : null,
        isEdit: selectedCardDetails && preview ? true : false,
        documents: selectedCardDetails?.documentInfo,
        reasonForReferral: selectedCardDetails?.reasonForReferral,
        details: selectedCardDetails,
        note: selectedCardDetails ? selectedCardDetails?.summary : '',
        inboxId: selectedCardDetails ? (selectedCardDetails?.efaxNumber) : '',
        onBack: () => {
          getList()
          onBack()
          setPreview(true)
        },
        formattedContactData,
        setCreateReferralFormVisible: () => setCreateReferralFormVisible(false),
        accountLocationUuid: formattedContactData?.contactProfile?.accountLocationUuid
      }
      createReferralClicked(data);
    } else {
      return (
        <InsightsCreateReferral
          contactData={contactData}
          preview={selectedCardDetails ? preview : false}
          receiverDetails={selectedCardDetails ? { name: selectedCardDetails?.receiver?.name, uuid: selectedCardDetails?.receiver?.uuid, phoneNumber: selectedCardDetails?.receiver?.eFaxNo?.[0]?.value, cityName: selectedCardDetails?.receiver?.personAddress?.[0]?.cities?.name, zipCode: selectedCardDetails?.receiver?.personAddress?.[0]?.zipcodes?.code, speciality: selectedCardDetails?.receiver?.speciality?.[0]?.value } : null}
          isEdit={selectedCardDetails && preview ? true : false}
          documents={selectedCardDetails?.documentInfo}
          reasonForReferral={selectedCardDetails?.reasonForReferral}
          details={selectedCardDetails}
          note={selectedCardDetails ? selectedCardDetails?.summary : ''}
          inboxId={selectedCardDetails ? (selectedCardDetails?.efaxNumber) : ''}
          onBack={(referralOrderId?: string) => {
            getList(referralOrderId)
            onBack()
            setPreview(true)

          }}
          formattedContactData={formattedContactData}
          setCreateReferralFormVisible={() => setCreateReferralFormVisible(false)}
          isCareManagementView={props?.isCareManagementView}
          handleSetStatus={handleSetStatus}
          isReadOnlyView={props?.isReadOnlyView}
          onComplete={onSaveReferralOrder}
          heightOffsetForContainer={props?.heightOffsetForContainer}
          accountLocationUuid={formattedContactData?.contactProfile?.accountLocationUuid}
        />
      );
    }
  }

  if (loading) {
    return (
      <VStack
        paddingBottom={0}
        minHeight={'70vh'}
        alignItems='center'
        justifyContent='center'
        height={'100%'}
        padding={4}
        position="relative"
      >
          <Spinner
            size='lg'
            position="absolute"
            top="50%"
            left="50%"
            zIndex={1}
          />
      </VStack>
    );
  }

  const filteredReferralOrders = Object.keys(referralOrders).reduce<Record<string, any[]>>((acc, month) => {
    if (referralOrders[month as keyof typeof referralOrders]) {
      acc[month] = status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT
        ? referralOrders[month as keyof typeof referralOrders]?.filter((item: any) => item.status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT)
        : referralOrders[month as keyof typeof referralOrders];
    }
    return acc;
  }, {});

  const draftCount = Object.values(referralOrders).flat().filter((item: any) => item.status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT).length;

  const hasReferralOrders = Object.keys(filteredReferralOrders).length > 0;

  if (Object.keys(referralOrders).length == 0) {
    return (
      <View style={[props?.isCareManagementView ? styles.card : {}, { alignItems: 'center', justifyContent: 'center', height: containerHeight }]}>
            <SideCarReferralOrderNoDataSvg />
            <Text style={{ marginTop: 16, marginBottom: 8, fontSize: 16, color: Colors.FoldPixel.GRAY300, textAlign: 'center' }}>
            {isStepReviewedOrSkipped && props?.isReadOnlyView ? intl.formatMessage({ id:  'editReferralOrder' }) : intl.formatMessage({ id:  'createReferralOrder' })}
            </Text>
            {!props?.isReadOnlyView && (
          <Tooltip 
            title={!haveAccessToActiveLocation 
              ? replaceHashValueToString(
                  { place: '' },
                  intl.formatMessage({ id: 'referralLocationMismatchedMsg' })
                ) 
              : 'Create Referral'}
              placement="bottom"
          >
            <Pressable onPress={() => {
              setCreateReferralFormVisible(true);
              setPreview(false);
              setSelectedCardDetails(null);
            }}
            disabled={props.isDisabled && props.isCareManagementView || !haveAccessToActiveLocation}
            >
              <HStack
                fontSize={14}
                justifyContent={'center'}
                space={1}
                alignItems={'center'}
                padding={2}
                color={'white'}
                backgroundColor={props?.isCareManagementView ? 'transparent' : !haveAccessToActiveLocation ? Colors.FoldPixel.GRAY50 : Colors.Custom.PrimaryColor100}
                borderRadius={4}
                borderColor={props?.isCareManagementView ? 'transparent' : !haveAccessToActiveLocation ? Colors.FoldPixel.GRAY150 : Colors.Custom.PrimaryColor200}
                borderWidth={props?.isCareManagementView ? 0 : 1}
                cursor={'pointer'}
              >
                <AddSquareSvg
                  color={
                    props.isDisabled && props.isCareManagementView
                      ? Colors.Custom.primaryLight
                      : !haveAccessToActiveLocation
                      ? Colors.FoldPixel.GRAY150
                      : undefined
                  }
                />
                <Text
                  style={{
                    color: props.isDisabled && props.isCareManagementView
                      ? Colors.Custom.primaryLight
                      : !haveAccessToActiveLocation
                      ? Colors.FoldPixel.GRAY150
                      : Colors.FoldPixel.PRIMARY300
                  }}
                  {...testID(TestIdentifiers.newReferralBtn)}
                >
                  {intl.formatMessage({ id: 'newReferral' })}
                </Text>
              </HStack>
            </Pressable>
          </Tooltip>
        )}
      </View>
    );
  }

  return (
    <Card
      title={
        <HStack justifyContent={'space-between'} alignItems={'center'}>
          <HStack space={2} paddingBottom={0}>
            <Pressable onPress={() => { setStatus(SIDECAR_REFERRAL_MANAGEMENT.ALL) }}>
              <HStack
                gap={4}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'32px'}
                cursor={'pointer'}
                padding={'8px 6px'}
                borderRadius={4}
                borderWidth={status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? 0.5 : 0}
                borderColor={status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? Colors.Custom.shadeGray : 'transparent'}
                backgroundColor={status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? Colors.Custom.White : 'transparent'}
              >
                <SideCarListSvg />
                <Text style={{ marginLeft: 4, fontSize: 14, color: status === SIDECAR_REFERRAL_MANAGEMENT.ALL ? Colors.Custom.Gray700 : Colors.FoldPixel.GRAY250 }}>All</Text>
              </HStack>
            </Pressable>
            <Pressable onPress={() => { setStatus(SIDECAR_REFERRAL_MANAGEMENT.DRAFT) }}>
              <HStack
                gap={4}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'32px'}
                cursor={'pointer'}
                padding={'8px 6px'}
                borderRadius={4}
                borderWidth={status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? 0.5 : 0}
                borderColor={status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? Colors.Custom.shadeGray : 'transparent'}
                backgroundColor={status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? Colors.Custom.White : 'transparent'}
              >
                <ThreeSquaresSvg />
                <Text style={{ fontSize: '14px', marginLeft: 6, color: status === SIDECAR_REFERRAL_MANAGEMENT.DRAFT ? Colors.Custom.Gray700 : Colors.FoldPixel.GRAY250 }}>Drafts</Text>
                <Badge
                  fontWeight={500}
                  backgroundColor={Colors.FoldPixel.GRAY50}
                  _text={{
                    color: Colors.FoldPixel.GRAY300,
                    fontSize: 10,
                  }}
                  marginLeft={2}
                  alignSelf="center"
                  rounded="full"
                  borderRadius={4}
                  justifyContent={'center'}
                  paddingX={2}
                  paddingY={1}
                  style={{
                    borderColor: Colors.FoldPixel.GRAY200,
                    borderWidth: 0.5,
                  }}
                >
                 {draftCount}
                </Badge>
              </HStack>
            </Pressable>
          </HStack>
          {!props?.isReadOnlyView && 
          <Tooltip 
            title={!haveAccessToActiveLocation 
              ? replaceHashValueToString(
                  { place: 'Referral' },
                  intl.formatMessage({ id: 'referralLocationMismatchedMsg' })
                ) 
              : 'Create Referral'}
              placement="bottom"
          >
            <Pressable onPress={() => {
              setCreateReferralFormVisible(true);
              setPreview(false);
              setSelectedCardDetails(null)
            }}
            disabled={!haveAccessToActiveLocation}
            >
                <HStack
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  paddingX={2}
                  backgroundColor={props?.isCareManagementView ? 'transparent' : !haveAccessToActiveLocation ? Colors.FoldPixel.GRAY50 : Colors.Custom.PrimaryColor100}
                  borderRadius={4}
                  borderColor={props?.isCareManagementView ? 'transparent' : !haveAccessToActiveLocation ? Colors.FoldPixel.GRAY150 : Colors.Custom.PrimaryColor200}
                  borderWidth={props?.isCareManagementView ? 0 : '0.5px'}
                  cursor={'pointer'}
                  width={'123px'}
                  height={'33px'}
                >
                  <PlusIcon defaultColor={!haveAccessToActiveLocation ? Colors.FoldPixel.GRAY150 : Colors.Custom.mainPrimaryPurple} width={16} height={16} />
                  <Text style={{ color: !haveAccessToActiveLocation ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.PRIMARY300, fontSize: '14px', fontWeight: '500' }}> {intl.formatMessage({ id: 'newReferral' })}</Text>
                </HStack>
            </Pressable>
          </Tooltip>
          }
        </HStack>
      }
      style={{ backgroundColor: props?.isCareManagementView ? 'transparent' : Colors.FoldPixel.GRAY50, boxShadow: 'none', border: 'none', ...(!isSidecarContext ? { height: 'auto', minHeight: '100%' } : {}) }}
    >
      {!hasReferralOrders ? (
        <View style={[props?.isCareManagementView ? styles.card : {}, { alignItems: 'center', justifyContent: 'center' }]}>
          <SideCarReferralOrderNoDataSvg />
          <Text style={{ marginTop: 16, marginBottom: 8, fontSize: 16, color: Colors.FoldPixel.GRAY300, textAlign: 'center' }}>
            {intl.formatMessage({ id: 'createReferralOrder' })}
          </Text>
          {!props?.isReadOnlyView &&
            <Tooltip
              title={!haveAccessToActiveLocation
                ? replaceHashValueToString(
                  { place: '' },
                  intl.formatMessage({ id: 'referralLocationMismatchedMsg' })
                )
                : 'Create Referral'}
              placement="bottom"
            >
              <Pressable
                onPress={() => {
                  setCreateReferralFormVisible(true);
                  setPreview(false);
                  setSelectedCardDetails(null);
                }}
                disabled={!haveAccessToActiveLocation || (props.isDisabled && props.isCareManagementView)}
            >
              <HStack
                fontSize={14}
                justifyContent={'center'}
                space={1}
                alignItems={'center'}
                padding={2}
                color={'white'}
                backgroundColor={props?.isCareManagementView ? 'transparent' : !haveAccessToActiveLocation ? Colors.FoldPixel.GRAY50 : Colors.Custom.PrimaryColor100}
                borderRadius={4}
                borderColor={props?.isCareManagementView ? 'transparent' : !haveAccessToActiveLocation ? Colors.FoldPixel.GRAY150 : Colors.Custom.PrimaryColor200}
                borderWidth={props?.isCareManagementView ? 0 : 1}
                cursor={'pointer'}
              >
                <AddSquareSvg
                  color={props.isDisabled && props.isCareManagementView
                    ? Colors.Custom.primaryLight
                    : !haveAccessToActiveLocation 
                    ? Colors.FoldPixel.GRAY150 
                    : undefined}
                />
                <Text
                  style={{
                    color: props.isDisabled && props.isCareManagementView
                      ? Colors.Custom.primaryLight
                      : !haveAccessToActiveLocation 
                      ? Colors.FoldPixel.GRAY150 
                      : Colors.FoldPixel.PRIMARY300
                  }}
                >
                  {intl.formatMessage({ id: 'newReferral' })}
                </Text>
              </HStack>
            </Pressable>
          </Tooltip>
          }
        </View>
      ) : (
        Object.keys(filteredReferralOrders)?.map((month: any, index: number) => (
        <VStack key={index} gap={2} space={2}>
          <Text style={{ fontSize: '14px', fontWeight: 500, marginTop: 3, color: Colors.FoldPixel.GRAY300 }}>
            {month}
          </Text>
        {filteredReferralOrders[month]?.map((item: any, idx: any) => (
            <ReferralOrderCard
              key={idx}
              onEditClick={(val: any) => {
                setCreateReferralFormVisible(true);
                setSelectedCardDetails(val);
                setPreview(false);
              }}
              onDelete={(val: any) => {
                setDeleteValue(val);
                setDeleteModal(true);
              }}
              details={item}
              onClick={() => handleCardClick(item)}
              contactData={contactData}
              isReadOnlyView={props?.isReadOnlyView}
            />
          ))}
        </VStack>
      ))
    )}

      <Modal
        visible={deleteModal}
        onOk={() => {
          if (deleteValue) {
            deleteOrder(deleteValue);
          }
          setDeleteModal(false);
        }}
        onCancel={() => setDeleteModal(false)}
        footer={null}
        centered
        bodyStyle={{ padding: '20px' }}
        width={320}
      >
        <View style={{ alignItems: 'center', marginBottom: 8 }}>
          <TrashBinIcon2024 color={'#D92D20'} height={24} width={24} />
        </View>
        <View style={{ alignItems: 'center', marginBottom: 16 }}>
          <Text style={{ textAlign: 'center', fontWeight: '600', color: Colors.FoldPixel.GRAY400 }}>
            Are you Sure?
          </Text>

          <Text style={{ textAlign: 'center', fontWeight: '600', marginTop: 8 }} color={Colors.FoldPixel.GRAY300}>
            It will permanently delete the record.
          </Text>
        </View>
        <HStack space={4} alignItems={'center'} justifyContent={'center'}>
          <Button
            variant='outline'
            backgroundColor={Colors.Custom.MonochromeWhite}
            borderColor={Colors.FoldPixel.GRAY250}
            borderWidth={0.5}
            _text={{
              color: Colors.FoldPixel.GRAY300,
              fontWeight: '500',
              fontSize: '14px'
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50
            }}
            width={131}
            onPress={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            colorScheme='custom'
            backgroundColor={Colors.Custom.Red100}
            borderRadius={4}
            borderWidth={0.5}
            borderColor={Colors.FoldPixel.STATUS_ERROR}
            _text={{
              color: Colors.FoldPixel.STATUS_ERROR,
              fontWeight: '500',
            }}
            _hover={{
              backgroundColor: Colors.FoldPixel.STATUS_ERROR,
              _text: {
                color: Colors.Custom.Red100
              }
            }}
            width={131}
            onPress={() => {
              if (deleteValue) {
                deleteOrder(deleteValue);
              }
              setDeleteModal(false);
            }}
          >
            Confirm
          </Button>
        </HStack>
      </Modal>


    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    borderColor: Colors.FoldPixel.GRAY100,
    borderRadius: 6,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default InsightsReferralOrdersView;
