import React, {createContext, useContext, ReactNode} from 'react';
import {BatchValidationResult, FormElementInstance, ValidationFunction} from '../FormComponents/FormComponents';
import {useFormRenderer} from '../Hooks/useFormRenderer';
import { FormRendererAction } from './FormRendererReducer';
import { FormRendererState } from './FormRendererReducer';


interface FormRendererContextType {
  state: FormRendererState;
  dispatch: React.Dispatch<FormRendererAction>;
  onRegisterValidation: (key: string, validationFn: ValidationFunction) => void;
  validateForm: () => Promise<BatchValidationResult>;
  isValidating: boolean;
  validationProgress: number;
}

const FormRendererContext = createContext<FormRendererContextType | undefined>(
  undefined
);

export const useFormRendererContext = () => {
  const context = useContext(FormRendererContext);
  if (context === undefined) {
    throw new Error(
      'useFormRendererContext must be used within a FormRendererProvider'
    );
  }

  return context;
};

export const FormRendererProvider = ({
  children,
  components,
  defaultValues,
  isReadOnly,
  builderComponents,
  onDefaultDataSet,
  hideErrorMessages,
}: {
  children: ReactNode;
  components: FormElementInstance[];
  defaultValues: Record<string, string>;
  isReadOnly: boolean;
  builderComponents?: FormElementInstance[];
  onDefaultDataSet?: (defaultValues: Record<string, string>) => void;
  hideErrorMessages?: boolean;
}) => {
  const {
    state,
    dispatch,
    registerValidation,
    isValidating,
    validationProgress,
    handleSubmit
  } = useFormRenderer({
    components,
    defaultValues,
    isReadOnly,
    builderComponents,
    hideErrorMessages,
    onDefaultDataSet,
  });

  return (
    <FormRendererContext.Provider value={{
      state,
      dispatch,
      onRegisterValidation: registerValidation,
      isValidating,
      validationProgress,
      validateForm: handleSubmit,
    }}>
      {children}
    </FormRendererContext.Provider>
  );
};
