import React from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {HStack, View, VStack, Checkbox, Text, Badge} from 'native-base';
import {Colors} from '../../../../../styles';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import {USER_ROLE_CODES} from '../../../../../constants/MlovConst';
import {GROUP_MEMBER_TYPE} from '../../../../../constants';
import {useIntl} from 'react-intl';
import {IAccountRoles, IRole, IUserRole} from '../interfaces';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import Feather from 'react-native-vector-icons/Feather';
import { Pressable } from 'react-native';
import { testID, TestIdentifiers } from '../../../../../testUtils';

const CareTeamMemberCard = (props: {
  index?: number;
  name: string;
  roles: IAccountRoles[];
  id: string;
  uuid: string;
  isLocal?: boolean;
  primaryCarePhysicianId?: string;
  isSearchComponent?: boolean;
  profileUrl: string;
  handleDeleteConfirmation?: (id: string) => void;
  actionView?: JSX.Element;
  disableAPIDelete?: boolean;
  getUserCareTeamRoles?: (userId: string) => string[], 
  paddingHorizontal?: number;
  isDisabled?: boolean;
}) => {

  return (
    <>
      <HStack
        width={'100%'}
        alignItems="flex-start"
        paddingY={2}
        paddingX={props.paddingHorizontal || 4}
        style={
          props?.isSearchComponent
            ? {}
            : {
                borderColor: Colors.Custom.Gray200,
                backgroundColor: props.isLocal ? Colors.Custom.Primary50 : Colors.Custom.White,
                borderBottomWidth: 0.5,
              }
        }
      >
        <View>
          <DisplayCardAvatar
            avatarStyle={{
              avatarSize: '12',
              width: 48,
              height: 48,
              disableTop: !isWeb()
            }}
            userData={{
              userType: GROUP_MEMBER_TYPE.USER,
              userId: props.uuid,
              roles: props.roles.map((item) => item.customRoleCode || item.userRole.code),
              name: props.name,
              userName: props.name,
              imgSrc: props.profileUrl,
            }}
            isLetterAvatarShow
          />
        </View>
        <VStack
          alignSelf="stretch"
          flex={1}
          marginLeft={2}
          justifyContent={props.roles.length === 0 ? 'center' : 'flex-start'}
        >
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400} {...testID(props?.name)} >
            {props?.name}
          </Text>
          <Text
            size={'xsNormal'}
            marginRight={2}
            color={Colors.FoldPixel.GRAY250}
            noOfLines={1}
            isTruncated
            {...testID(TestIdentifiers.userRole)}
          >
            {props.roles.map(
              (role, index) =>
                `${role.roleName || role.userRole.value}${props?.roles?.length > index + 1 ? ', ' : ''}`
            )}
          </Text>
          <HStack flexWrap={'wrap'}>
              {props?.getUserCareTeamRoles?.(props.uuid)?.map(role => <Badge mr={0.5} py={-10} rounded={'full'} key={role}>{role}</Badge>)}
          </HStack>
        </VStack>
        {props?.actionView && !props?.isDisabled ? (
          <View alignSelf={'stretch'} justifyContent={'center'}>
            {props?.actionView}
          </View>
        ) : (
          <></>
        )}
      </HStack>
    </>
  );
};
export default CareTeamMemberCard;
