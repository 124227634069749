import { ComponentContext } from "./BaseComponentInterface";
import { ColumnsComponent } from "./BaseComponentInterface";
import { ContainerComponent } from "./BaseComponentInterface";

export default [
  {
    type: 'conditional',
    title: 'Display rules',
    key: 'conditional',
    theme: 'default',
    show: 'true',
    when: '',
    eq: '',
    operator: 'equal',
    exist: '',
    customVisibility: (element: Record<string, any>, context: ComponentContext) => {
      const components = context.builderComponents || [];
      const hasComponents = components?.length > 1 ||
        (components?.[0] as ContainerComponent)?.components?.length > 0 ||
        (components?.[0] as ColumnsComponent)?.columns?.length > 0;
      return hasComponents;
    }
  },
];
