import { gql } from '@apollo/client';
import BaseService from '../CommonService/BaseService';

export const CreateStickyNotes = async ({
  contactId,
  contactUuid,
  note,
  locationGroupId,
  ehrPatientId,
  contactProfileId,
  accountLocationId
}: {
  contactId: number,
  contactUuid: string,
  note: string,
  locationGroupId?: string,
  ehrPatientId?: string,
  contactProfileId?: string,
  accountLocationId?: string
}) => {
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    contactId: contactId,
    contactUuid: contactUuid,
    note: note,
    ...(locationGroupId && { locationGroupId: locationGroupId }),
    ...(ehrPatientId && { ehrPatientId: ehrPatientId }),
    ...(contactProfileId && { contactProfileId: contactProfileId }),
    ...(accountLocationId && { accountLocationUuid: accountLocationId })
  };
  return await baseService.post('crm-nest/account/contact-sticky-notes', {
    ...body,
  });
};

export const UpdateStickyNotes = async (args: {
  contactId: number,
  contactUuid: string,
  note: string,
  noteUuid: string,
  locationGroupId?: string,
  ehrPatientId?: string,
  contactProfileId?: string,
  accountLocationId?: string
}) => {
  const { contactId, contactUuid, note, noteUuid, locationGroupId, ehrPatientId, contactProfileId, accountLocationId } = args;
  const baseService = BaseService.getSharedInstance().axios;
  const body = {
    contactId: contactId,
    contactUuid: contactUuid,
    note: note,
    noteUuid: noteUuid,
    ...(locationGroupId && { locationGroupId: locationGroupId }),
    ...(ehrPatientId && { ehrPatientId: ehrPatientId }),
    ...(contactProfileId && { contactProfileId: contactProfileId }),
    ...(accountLocationId && { accountLocationUuid: accountLocationId })
  };
  return await baseService.put('crm-nest/account/contact-sticky-notes', {
    ...body,
  });
};

const GetStickyNotes = gql`
  query GetStickyNotes($stickyNotesCondition: sticky_notes_bool_exp) {
    stickyNotes(
      where: $stickyNotesCondition,
      order_by: {updatedAt: desc}
    ) {
      noteUuid
      id
      contactNote {
        content
      }
      updatedByUser {
        name
      }
      updatedAt
      locationGroupId
      ehrPatientId
    }
  }
`;

const GetStickNoteVersionByNoteUuid = gql`
  query GetStickNoteVersionByNoteUuid(
    $contactNotesWhereCondition: contact_note_versions_bool_exp
    $limit: Int!
    $offset: Int!
  ) {
    contactNoteVersions(
      where: $contactNotesWhereCondition
      limit: $limit
      offset: $offset
      order_by: {createdAt: desc}
    ) {
      updatedByUser {
        name
      }
      id
      updatedAt
      note
      noteUuid
      locationGroupId
      ehrPatientId
    }
  }
`;

export default { GetStickyNotes, GetStickNoteVersionByNoteUuid }
