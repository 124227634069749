import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormControl } from 'native-base';
import { ElementsType, FormElement, FormElementInstance, SubmitFunction, ValidationFunction, ValidationResult } from '../FormComponents/FormComponents';
import Label from '../BaseComponents/Label';
import { getUniqueKey } from '../CustomFormEngineUtils';
import { ChiefComplaintSchema } from '../Schema/ComponentsSchema';
import { cloneDeep, debounce } from 'lodash';
import Description from '../BaseComponents/Description';
import AddOrUpdateChiefComplaint, { IChiefComplaintComponentValue } from '../../FHFormio/CustomComponents/ChiefComplaint/AddOrUpdateChiefComplaint/AddOrUpdateChiefComplaint';
import { useFormRendererContext } from '../Context/FormRenderer.context';
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from '../CustomFormEngineInterfaces';
import { useCustomFormBuilderContext } from '../Context/CustomFormBuilder.context';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import QuillConfig from '../../FHFormio/Builder/QuillConfig';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import SearchableComponentFields from '../../FHFormio/EditFormFields/SearchableComponentFields';
import MacroTemplateField from '../../FHFormio/EditFormFields/MacroTemplateField';
import { usePropertiesFormRenderer } from '../Hooks/usePropertiesFormRenderer';
import { FormRenderer } from '../FormRenderer';
import ChiefComplaintSvg from '../../../../../assets/Icons/FormBuilder/ChiefComplaintSvg';
import { v4 as uuidV4 } from 'uuid';


export const ChiefComplaintFieldFormElement: FormElement = {
  type: ChiefComplaintSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(ChiefComplaintSchema),
    key: getUniqueKey(map, ChiefComplaintSchema.key),
  }),
  designerBtnElement: {
    icon: ChiefComplaintSvg,
    label: ChiefComplaintSchema.label || '',
  },
  propertiesComponent: PropertiesComponent,
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(ChiefComplaintSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,

};

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  disabled,
  validationRef,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: IChiefComplaintComponentValue;
  onChange?: (value: IChiefComplaintComponentValue) => void;
  errorMessage?: string;
  disabled?: boolean;
  validationRef?: MutableRefObject<any>;
  isReadOnly?: boolean;
}) {
  const { label, validate, description, tooltip, placeholder } = elementInstance;

  const onChangeHandler = useCallback((value: IChiefComplaintComponentValue) => {
    onChange?.(value);
  }, [onChange]);

  return (
    <FormControl isInvalid={!!errorMessage}>
      {!isReadOnly && (
        <Label
          label={label || ''}
          isRequired={validate?.required}
          tooltip={tooltip}
          isReadOnly={isReadOnly}
        />
      )}
      <AddOrUpdateChiefComplaint
        component={{
          ...elementInstance,
          selectedValue: defaultValue,
          placeholder: placeholder || 'Enter chief complaint',
        }}
        disabled={disabled || isReadOnly || false}
        options={{}}
        validateRef={validationRef || {}}
        setValueRef={{}}
        value={defaultValue}
        onChange={debounce(onChangeHandler, 500)}
      />
      {description && <Description description={description} />}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </FormControl>
  );
}

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const [value, setValue] = useState<IChiefComplaintComponentValue>();
  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={setValue}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: IChiefComplaintComponentValue;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const element = elementInstance;
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<IChiefComplaintComponentValue | undefined>(defaultValue || selectedValue);
  const validationRef = useRef<any>(undefined);
  const isInitialMount = useRef(true);
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: IChiefComplaintComponentValue, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      const result = await validationRef.current?.(valueToValidate, silentCheck);
      return {
        isValid: result?.isValid ?? true,
        errorMessage: result?.message || '',
        key: element.key,
        fieldValue: valueToValidate
      };
    } catch (error) {
      console.error(`Validation error for ${element.key}:`, error);
      return {
        isValid: true,
        errorMessage: 'Validation failed unexpectedly',
        key: element.key,
        fieldValue: valueToValidate
      };
    }
  }, [element.key]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: IChiefComplaintComponentValue) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: result.key, errorMessage: result.errorMessage },
      });
    }, 300),
    [validateField]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: IChiefComplaintComponentValue) => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      disabled={isReadOnly}
      validationRef={validationRef}
      isReadOnly={isReadOnly}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance;
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);

  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    ...MacroTemplateField,
    ...(isAllowShare ? ShareWithPatientFields : []),
    {
      type: 'oldtextfield',
      input: true,
      key: 'placeHolder',
      label: 'Placeholder',
      tooltip:'The placeholder text that will appear when this field is empty',
      placeholder:'Placeholder (Optional)'
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip:
        'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData, element]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default ChiefComplaintFieldFormElement;
