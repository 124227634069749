import React, { useMemo } from 'react';
import {CaretDownOutlined} from '@ant-design/icons';
import {Colors} from '../../../../../styles/Colors';
import {Select} from 'antd';

interface DropdownFieldProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
  options?: any[];
  testId?: string;
  placeholder?: string;
  allowClear?: boolean;
  errorMessage?: string;
  showSearch?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  filterOption?: (input: string, option: any) => boolean;
}

const DropdownField = ({
  defaultValue,
  onChange,
  options,
  testId,
  placeholder,
  allowClear,
  errorMessage,
  showSearch,
  filterOption,
  disabled,
  isInvalid,
}: DropdownFieldProps) => {

  const suffixIcon = useMemo(() => (<CaretDownOutlined
    style={{
      color: Colors.FoldPixel.GRAY300,
    }}
  />), [])

  return (
    <div className="flex flex-col gap-1">
      <Select
        value={defaultValue || undefined}
        disabled={disabled}
        showSearch={showSearch}
        onChange={onChange}
        options={options}
        data-testid={testId}
        placeholder={placeholder || 'Select an option'}
        allowClear={allowClear}
        suffixIcon={suffixIcon}
        className="custom-select-box"
        status={errorMessage || isInvalid ? 'error' : ''}
        filterOption={filterOption}
      />
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </div>
  );
};

export default React.memo(DropdownField);
