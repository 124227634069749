import {IntlShape} from 'react-intl';
import { DIASTOLIC_BLOOD_PRESSURE_LOINC, SYSTOLIC_BLOOD_PRESSURE_LOINC } from '../../../../constants';
import {IMlov, ITaskCategoryData} from '../../../../Interfaces/CommonInterfaces';
import {getPatientName} from '../../../../utils/commonUtils';
import {
  isCurrentDateInFutureComparedToOther,
  isPastTime,
} from '../../../../utils/DateUtils';
import {getMlovId} from '../../../../utils/mlovUtils';
import { IObservation } from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces';
import {
  IPatient,
  IPatientEducation,
  ITask,
} from '../../CareDashboard/CareDashboardInterfaces';
import {IKanBanColumnType} from '../../KanBanView/interfaces';
import { BoardType, DuedateColumnType, DuedateType } from '../../CareDashboard/CareDashboardTopBar/interfaces';
import { ITopBarData } from '../../CareDashboard/CareDashboardTopBar/CareDashboardTopBar';

export const TASK_COMPLETED_STATUS_CODE = 'completed';
export const TASK_COLUMN_CODE = {
  today: 'TODAY',
  dueNow: 'DUE_NOW',
  completed: 'COMPLETED',
};

const todayColumn: IKanBanColumnType = {
  id: TASK_COLUMN_CODE.today,
  columnName: 'Today',
  list: [],
  columnHeight: 395,
};

const dueNowColumn: IKanBanColumnType = {
  id: TASK_COLUMN_CODE.dueNow,
  columnName: 'Due Now',
  list: [],
  columnHeight: 395,
};

const completedColumn: IKanBanColumnType = {
  id: TASK_COLUMN_CODE.completed,
  columnName: 'Completed',
  list: [],
  columnHeight: 395,
};

export const TASK_COLUMNS: IKanBanColumnType[] = [
  todayColumn,
  dueNowColumn,
  completedColumn,
];

export const parseTaskDataIntoColumns = (
  taskList: ITask[],
  intl: IntlShape
): IKanBanColumnType[] => {
  todayColumn.columnName = intl.formatMessage({id: 'today'});
  dueNowColumn.columnName = intl.formatMessage({id: 'dueNow'});
  completedColumn.columnName = intl.formatMessage({id: 'completed'});
  [todayColumn, dueNowColumn, completedColumn].forEach((item) => {
    item.list = [];
  });
  if (taskList.length > 0) {
    const sortedTasks = taskList.sort((task1, task2) => {
      if (task1.startDateTime && task2.startDateTime) {
        return isCurrentDateInFutureComparedToOther(
          task1.startDateTime,
          task2.startDateTime
        )
          ? 1
          : -1;
      }
      return 0;
    });

    sortedTasks.forEach((task) => {
      if (task.status?.code === TASK_COMPLETED_STATUS_CODE) {
        // push in completed
        completedColumn.list.push(task);
      } else if (task.isEscalated) {
        // push in due now
        dueNowColumn.list.splice(0, 0, task);
      } else if (task.startDateTime) {
        if (isPastTime(task.startDateTime)) {
          // push in due now
          dueNowColumn.list.push(task);
        } else {
          // push in today
          todayColumn.list.push(task);
        }
      }
    });
    return [todayColumn, dueNowColumn, completedColumn];
  } else {
    return TASK_COLUMNS;
  }
};

export const getColumnHeaderColorScheme = (
  column: IKanBanColumnType
): string | undefined => {
  switch (column.id) {
    case TASK_COLUMN_CODE.today:
      return 'info';
    case TASK_COLUMN_CODE.dueNow:
      return 'primary';
    case TASK_COLUMN_CODE.completed:
      return 'success';
    default:
      return 'success';
  }
};

export const getKanBanItemColorScheme = (item: ITask): string | undefined => {
  if (item.isEscalated) {
    return 'danger';
  }
  return 'info';
};

export const getAttachmentIcon = (attachment: IPatientEducation): string => {
  if (attachment.documentType.code === 'PDF') {
    return 'pdffile1';
  }
  return 'file1';
};

export const getCompletedTaskStatusId = (mlovs: IMlov[]): string => {
  const completedStatusMlov = mlovs.filter((mlov) => {
    return mlov.code === TASK_COMPLETED_STATUS_CODE;
  });
  if (completedStatusMlov.length > 0) {
    return completedStatusMlov[0].id;
  }
  return '';
};

export const setPatientDataInTasks = (
  columns: IKanBanColumnType[],
  patients: IPatient[]
): IKanBanColumnType[] => {
  const patientData: any = {};

  columns.forEach((column: IKanBanColumnType) => {
    column.list.forEach((task: ITask) => {
      if (task.contactId) {
        if (patientData[task.contactId]) {
          task.patientName = patientData[task.contactId];
        } else {
          const matchedPatient = patients.filter((patient: IPatient) => {
            return patient.id === task.contactId;
          });
          if (matchedPatient.length > 0) {
            const patientName = getPatientName(matchedPatient[0]);
            task.patientName = patientName;
            patientData[task.contactId] = patientName;
          }
        }
      }
    });
  });
  return columns;
};

export const getTaskIdsWithVitalData = (tasks: ITask[]): string[] => {
  const taskIds: string[] = [];
  tasks.forEach((task) => {
    if (
      task.status?.code === TASK_COMPLETED_STATUS_CODE &&
      task.referenceData &&
      task.referenceData.entityType &&
      task.referenceData.entityType === 'VITAL'
    ) {
      taskIds.push(task.id);
    }
  });
  return taskIds;
};

export const setVitalDataInTasks = (
  columns: IKanBanColumnType[],
  vitalData: {resource: IObservation}[]
): IKanBanColumnType[] => {
  columns.forEach((column: IKanBanColumnType) => {
    column.list.forEach((task: ITask) => {
      if (task.contactId) {
        const matchedData = vitalData.filter((observation) => {
          return observation.resource?.id === task.referenceData?.externalId;
        });
        if (matchedData.length > 0) {
          if (matchedData[0].resource?.valueQuantity?.value) {
            task.capturedData = {
              value: `${matchedData[0].resource.valueQuantity.value}`,
              valueUnit: matchedData[0].resource.valueQuantity.unit || ''
            };
          } else if (matchedData[0].resource?.component?.length) {
            const systolicData = matchedData[0].resource?.component?.find(
              (item) =>
                item.code?.coding?.length &&
                item.code.coding[0].code === SYSTOLIC_BLOOD_PRESSURE_LOINC
            );
            const diastolicData = matchedData[0].resource?.component?.find(
              (item) =>
                item.code?.coding?.length &&
                item.code.coding[0].code === DIASTOLIC_BLOOD_PRESSURE_LOINC
            );
            if (systolicData?.valueQuantity?.value || diastolicData?.valueQuantity?.value) {
              task.capturedData = {
                value: `${systolicData?.valueQuantity?.value || '-'}/${diastolicData?.valueQuantity?.value || '-'}`,
                valueUnit: systolicData?.valueQuantity?.unit || '',
              };
            }
          }
        } else if (task.referenceData?.value) {
          task.capturedData = {
            value: `${task.referenceData.value}`,
            valueUnit: task.referenceData?.unit || '',
          };
        }
      }
    });
  });
  return columns;
};

export const getDisplayCategoryId = (
  taskDisplayCategoryId: string,
  mlovData: any
) => {
  const allAssignedCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'all_assigned'
  );
  const dooTodayCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'do_today'
  );
  const doTomorrowCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'do_tomorrow'
  );
  const doLaterCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'do_later'
  );

  const displayCategoryId =
    taskDisplayCategoryId === 'All Assigned' ||
    taskDisplayCategoryId === 'all_assigned'
      ? allAssignedCategoryId
      : taskDisplayCategoryId === 'Do Today' ||
        taskDisplayCategoryId === 'do_today'
      ? dooTodayCategoryId
      : taskDisplayCategoryId === 'Do Tomorrow' ||
        taskDisplayCategoryId === 'do_tomorrow'
      ? doTomorrowCategoryId
      : taskDisplayCategoryId === 'Do Later' ||
        taskDisplayCategoryId === 'do_later'
      ? doLaterCategoryId
      : allAssignedCategoryId;

  return displayCategoryId;
};

export const getCategoryName = (category: string) => {
  const categoryCode =
    category === 'All Assigned' || category === 'all_assigned'
      ? 'all_assigned'
      : category === 'Do Today' || category === 'do_today'
      ? 'do_today'
      : category === 'Do Tomorrow' || category === 'do_tomorrow'
      ? 'do_tomorrow'
      : category === 'Do Later' || category === 'do_later'
      ? 'do_later'
      : '';

  return categoryCode;
};

export const getTasksFilterCount = (filterdData: any) => {
    let count = 0
    if(filterdData?.labels?.length){
      count += 1
    }
    if(filterdData?.selectedPriorityList?.length){
      count += 1
    }
    if(filterdData?.selectedDueDate?.length){
      count += 1
    }
    if(filterdData?.selectedContactUuid?.length){
      count += 1
    }
    if(filterdData?.selectedStatusList?.length){
      count += 1
    }
    if(filterdData?.selectedLocationUuids?.length){
      count += 1
    }
    return count
}

export const getMembersTaskFilterCount = (filterdData: any) => {
  let count = 0
  if(filterdData?.selectedPriorityList?.length){
    count += 1
  }
  if(filterdData?.selectedTaskEntityTypesIds?.length){
    count += 1
  }
  if(filterdData?.selectedDueDateUuid?.length){
    count += 1
  }
  if(filterdData?.selectedLocationUuids?.length){
    count += 1
  }
  return count
}
export const getTaskFilteredCategoryData = <T extends ({ id?: string; code?: string } | IKanBanColumnType)>(
  categoryData: Array<T>,
  topBarData: ITopBarData,
  accessProperty: keyof T,
  selectedBoardType?: BoardType,
) => {
  let filterCodes: Array<string> = []
  if (topBarData) {
    switch (selectedBoardType) {
      case BoardType.Priority:
        filterCodes = topBarData?.selectedPriorityList?.map((filterItem: IMlov) => filterItem.code) || [];
        break;
      case BoardType.Status:
        filterCodes = topBarData?.selectedStatusList?.map((filterItem: IMlov) => filterItem.code) || [];
        break;
      case BoardType.dueDate:
        filterCodes = topBarData?.selectedDueDateType || [];
        const filteredCategoryDataDuedate: T[] = [];
        if (filterCodes.length > 0) {
          if (Array.isArray(categoryData) && categoryData.length > 0 && accessProperty in categoryData[0]) {
            categoryData.forEach((category) => {
              if (filterCodes.includes(DuedateType.Overdue) && (category)[accessProperty] === DuedateColumnType.past) {
                filteredCategoryDataDuedate.push(category);
              }
              if (filterCodes.includes(DuedateType.Today) && (category)[accessProperty] === DuedateColumnType.today) {
                filteredCategoryDataDuedate.push(category);
              }
              if ((filterCodes.includes(DuedateType.Tomorrow) || filterCodes.includes(DuedateType.Later)) && (category)[accessProperty] === DuedateColumnType.future) {
                filteredCategoryDataDuedate.push(category);
              }
              if (filterCodes.includes(DuedateType.DateRange)) {
                filteredCategoryDataDuedate.push(category);
              }
              if (filterCodes.includes(DuedateType.All)) {
                filteredCategoryDataDuedate.push(category);
              }
            })
          };
          const filteredCodes = filteredCategoryDataDuedate.map((item: T) => item.code || item.id);
          return filteredCodes;
        }
        else {
          return categoryData.map((item: T) => item.code || item.id);
        }
      case BoardType.taskPool:
        filterCodes = topBarData?.selectedPools?.map((filterItem) => filterItem.id || filterItem.key) || [];
        break;
      default:
        filterCodes = topBarData?.selectedStatusList?.map((filterItem: IMlov) => filterItem.code) || [];
        break;
    }
  }
  return filterCodes;
}
