import {Popover, Skeleton} from 'antd';
import React, {useContext, useState} from 'react';
import ProviderSelectForReview from '../../../../PersonOmniView/MiddleContainer/CarePlan/components/ProviderSelectForReview';
import {Pressable, StyleSheet, View} from 'react-native';
import AssignActionSvg from '../../../../common/Svg/AssignActionSvg';
import {Colors} from '../../../../../styles';
import {ICareProgramAssignmentView} from '../../interface';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Text} from 'native-base';
import {useIntl} from 'react-intl';
import {useLazyQuery, useMutation} from '@apollo/client';
import ContactCareProgram from '../../../../../services/ContactCareProgram/ContactCareProgram';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {GET_SINGLE_USER} from '../../../../../services/User/UserQueries';
import {MLOV_CATEGORY} from '../../../../../constants';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import {antStyles} from '../../../CareManagementView/CareManageMentViewStyles';
import {CARE_PROGRAM_ASSIGN_ACTION_CODE} from '../../../CareManagementBilling/CareManagementConstants';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {useCustomToast} from '../../../../Toast/ToastProvider';
import CustomConfirmationModal from '../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import WarningSvgV2 from '../../../../common/Svg/WarningSvgV2';
import {replaceHashValueToString} from '../../../../../utils/commonUtils';
import { testID } from '../../../../../testUtils';

const CareProgramAssignmentView = ({
  contactCareProgramId,
  contactId,
  careProgramAssigneeId,
  onAssigneeActionPerformed,
  popoverContent,
  parentPopoverVisible = true,
  onCareProgramAssigneeChange,
  closeParentPopover,
  isReadOnly
}: ICareProgramAssignmentView) => {
  const intl = useIntl();
  const customToast = useCustomToast();
  const [state, setState] = useState({
    visiblePopover: false,
    selectedUser: {} as any,
    userDataLoading: careProgramAssigneeId ? true : false,
    tempSelectedUser: {} as any,
    showConfirmationModal: false,
  });
  const commonContextData = useContext(CommonDataContext);

  const taskAssigneeList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ASSIGNEE_TYPE
    ) || [];

  const userAssigneeType = getMlovIdFromCode(taskAssigneeList, 'USER');

  const [updateContactCareProgramForAssignee] = useMutation(
    ContactCareProgram.UPDATE_CONTACT_CARE_PROGRAM_STATUS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  const handleAssigneeChange = async (userUuid: string) => {
    try {
      onClose();
      const response = await updateContactCareProgramForAssignee({
        variables: {
          params: {
            id: contactCareProgramId,
            contactId: contactId,
            assigneeData: {
              assignActionCode:
                CARE_PROGRAM_ASSIGN_ACTION_CODE.ASSIGN_CARE_PROGRAM,
              assigneeId: userUuid,
              assigneeTypeId: userAssigneeType,
            },
          },
        },
      });
      if (response?.data?.updateContactCareProgram?.id) {
        onAssigneeActionPerformed(userUuid || '');
        await getAssigneeUserData(userUuid);
      }
      showToast(
        customToast,
        intl.formatMessage({id: 'careProgramAssignSuccessMsg'}),
        ToastType.success,
        3000,
        true
      );
    } catch (e) {
      setState((prev) => {
        return {
          ...prev,
          selectedUser: prev.selectedUser,
          userDataLoading: false,
        };
      });
      onClose();
      showToast(
        customToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error,
        3000,
        true
      );
    }
  };

  const handleVisibleChange = (visible: boolean) => {
    if (parentPopoverVisible) {
      setState((prev) => ({
        ...prev,
        visiblePopover: visible,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        visiblePopover: false,
      }));
    }
  };

  const onClose = () => {
    setState((prev) => {
      return {
        ...prev,
        visiblePopover: false,
        showConfirmationModal: false,
        tempSelectedUser: {} as any,
      };
    });
    if (closeParentPopover) {
      closeParentPopover();
    }
  };

  const getAssigneeUserData = async (userId?: string) => {
    if (userId) {
      try {
        const assigneeUserResonse = await getSingleUserById({
          variables: {
            userId: userId,
          },
        });
        const assigneeUser = assigneeUserResonse?.data?.users?.[0];
        setState((prev) => {
          return {
            ...prev,
            selectedUser: assigneeUser,
            userDataLoading: false,
          };
        });
        if (onCareProgramAssigneeChange) {
          onCareProgramAssigneeChange({
            name: assigneeUser?.name,
            uuid: assigneeUser?.uuid,
          });
        }
      } catch (e) {
        setState((prev) => {
          return {
            ...prev,
            selectedUser: prev.selectedUser,
            userDataLoading: false,
          };
        });
      }
    } else {
      setState((prev) => {
        return {
          ...prev,
          selectedUser: prev.selectedUser,
          userDataLoading: false,
        };
      });
    }
  };

  const handleConfirmationModal = (
    visible: boolean,
    tempSelectedUser?: any
  ) => {
    setState((prev) => {
      return {
        ...prev,
        visiblePopover: false,
        showConfirmationModal: visible,
        tempSelectedUser: tempSelectedUser || {},
      };
    });
    if (closeParentPopover) {
      closeParentPopover();
    }
  };

  React.useEffect(() => {
    if (careProgramAssigneeId) {
      getAssigneeUserData(careProgramAssigneeId);
    }
    return () => {
      setState((prev) => {
        return {
          ...prev,
          visiblePopover: false,
          userDataLoading: false,
        };
      });
    };
  }, [parentPopoverVisible]);

  const content = (
    <View style={styles.popoverContent}>
      <ProviderSelectForReview
        selectedProviderId={state.selectedUser?.id}
        onCancel={() => {
          setState((prev) => {
            return {
              ...prev,
              visiblePopover: false,
            };
          });
        }}
        contactId={contactId}
        hideCancelButton
        hidePCPUser
        showAllRolesOfUsers
        resetSearchTextOnPopoverOpen={parentPopoverVisible ? state.visiblePopover : false}
        titleString="assignTo"
        flatlistMaxHeight={300}
        onCareProgramAssigneeChange={(value) => {
          handleConfirmationModal(true, value);
        }}
      />
    </View>
  );

  return (
    <Stack direction="row">
      {state.userDataLoading && !popoverContent ? (
        <Skeleton.Input active size={'small'} style={antStyles.skeletonStyle} />
      ) : (
        <Popover
          content={content}
          overlayClassName="contact-popover"
          trigger="click"
          placement="bottomLeft"
          open={!isReadOnly && parentPopoverVisible ? state.visiblePopover : false}
          onOpenChange={() => handleVisibleChange(false)}
          showArrow={false}
          align={{
            offset: [30, -10],
          }}
          overlayInnerStyle={antStyles.popoverOverlayInnerStyle}
        >
          {popoverContent ? (
            <Pressable
              disabled={isReadOnly}
              onPress={() => handleVisibleChange(!state.visiblePopover)}
            >
              {popoverContent}
            </Pressable>
          ) : (
            <Stack direction="row" style={styles.content}>
              <Text style={styles.dotDivider}>{' • '}</Text>
              <Pressable
                disabled={isReadOnly}
                onPress={() => handleVisibleChange(!state.visiblePopover)}
              >
                <Stack direction="row" style={styles.assignButton}>
                  <AssignActionSvg
                    customStrokeColor={
                      state?.selectedUser?.id
                        ? Colors.Custom.SuccessColor
                        : Colors.Custom.Gray500
                    }
                    width={16}
                    height={16}
                  />
                  <Text
                    size={'xsNormal'}
                    style={
                      state?.selectedUser?.id
                        ? styles.assignedText
                        : styles.unassignedText
                    }
                    {...testID(state?.selectedUser?.name || 'assign')}
                  >
                    {state?.selectedUser?.name ||
                      intl.formatMessage({id: 'assign'})}
                  </Text>
                </Stack>
              </Pressable>
            </Stack>
          )}
        </Popover>
      )}
      {state.showConfirmationModal && (
        <CustomConfirmationModal
          isOpen={state.showConfirmationModal}
          headerText={'assignCareProgramHeaderText'}
          message={replaceHashValueToString(
            {selected_user: state.tempSelectedUser?.name || 'user'},
            intl.formatMessage({id: 'assignCareProgramConfirmationText'})
          )}
          customIcon={
            <WarningSvgV2 strokeColor={Colors.Custom.crossIconColor} />
          }
          onConfirm={() => {
            handleAssigneeChange(state.tempSelectedUser?.uuid);
          }}
          onCancel={() => {
            handleConfirmationModal(false);
          }}
        />
      )}
    </Stack>
  );
};

const styles = StyleSheet.create({
  popoverContent: {
    padding: 8,
    width: 300,
  },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 4,
  },
  assignButton: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 4,
  },
  unassignedText: {
    paddingLeft: 4,
    color: Colors.Custom.Gray500,
  },
  assignedText: {
    paddingLeft: 4,
    color: Colors.Custom.SuccessColor,
  },
  dotDivider: {
    color: Colors.Custom.Gray400,
    fontWeight: '700',
  },
});
export default CareProgramAssignmentView;
