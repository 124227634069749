import React, { ReactNode, useContext, useEffect, useState } from "react";
import { FormContext, FormViewType, IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { IEhrCapability } from "../../../../../Interfaces";
import { FHIR_RESOURCE } from "../../../../../constants/FhirConstant";
import { Resource } from "../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces";
import { ICareProgramData } from "../../../ContactCareProgram/interface";
import { FormElementInstance } from "../FormComponents/FormComponents";
import { useLazyQuery } from "@apollo/client";
import ContactsQueries from "../../../../../services/Contacts/ContactsQueries";
import { CARESTUDIO_PROXY_TO_CRM_CONTEXT } from "../../../../../constants/Configs";
import { FormError } from "../../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper";
import FormComponentLoader from "../../FHFormio/CustomComponents/CustomWrapper/FormComponentLoader/FormComponentLoader";
import { IContactProfile } from "../../../TeamInbox/Conversations/interfaces";
import { IUserSettingsByCode } from "../../../../../services/UserSettings/interfaces";


export const FormCommonDataProvider = ({
  children,
  patientId,
  contactId,
  accountUUID,
  contactIntId,
  tenantName,
  isBuilderMode,
  isPreviewMode,
  fetchDataForPreview,
  foldAccessToken,
  backgroundSaveEnabled,
  componentView,
  showMoreMaxCount,
  defaultCollapse,
  questionnaireList,
  locationId,
  formContext,
  openEhrUrl,
  ccmDate,
  contactCareProgramId,
  careProgramData,
  contactCarePlanId,
  contactDetails,
  ehrCapabilities,
  isMobileView,
  isDoNotAllowToReplaceNextLine,
  showDeleteButton,
  contactUuid,
  contactName,
  foldContactId,
  carePlanDetailsStartDateTime,
  proxyHeaders,
  isBuilderPreviewMode,
  isPrintForm,
  tpaCareAdvocateId,
  widgetType,
  isCareProgramEnabled,
  isSidecar,
  goals,
  relevantVitals,
  ehrActivePatient,
  accountLocationUuid,
  userSettings,
  onDeleteClick,
  updateLoadingStatus,
  updateFormPrefilledByFoldProgress,
  handleUpdateCareProgram,
  isPrintMode
}: {
  children: ReactNode;
  isBuilderMode: boolean;
  patientId?: string;
  contactId?: string;
  contactIntId?: string;
  accountUUID?: string;
  tenantName?: string;
  isPreviewMode?: boolean;
  fetchDataForPreview?: boolean;
  foldAccessToken?: string;
  backgroundSaveEnabled?: boolean;
  componentView?: FormViewType;
  showMoreMaxCount?: number;
  defaultCollapse?: boolean;
  questionnaireList?: Resource[];
  locationId?: string;
  formContext?: FormContext;
  openEhrUrl?: string;
  ccmDate?: string;
  contactCareProgramId?: string;
  careProgramData?: ICareProgramData;
  contactCarePlanId?: string;
  ehrCapabilities?: IEhrCapability[];
  isMobileView?: boolean;
  isDoNotAllowToReplaceNextLine?: boolean;
  showDeleteButton?: boolean;
  carePlanDetailsStartDateTime?: string;
  isBuilderPreviewMode?: boolean;
  isPrintForm?: boolean;
  tpaCareAdvocateId?: string;
  widgetType?: string;
  isCareProgramEnabled?: boolean;
  isSidecar?: boolean;
  // deprecate goals
  goals?: any[];
  relevantVitals?: any[];
  // deprecate contactUuid and contactName and foldContactId
  contactUuid?: string;
  contactName?: string;
  foldContactId?: string;
  proxyHeaders?: any;
  contactDetails?: {
    contactId?: string;
    contactUuid?: string;
    patientId?: string;
    accountLocationUuid?: string;
    contactName?: string;
  }
  accountLocationUuid?: string;
  ehrActivePatient?: IContactProfile;
  userSettings?: IUserSettingsByCode;
  updateLoadingStatus?: (componentKey: string, isLoading: boolean, isDataAvailable: boolean) => void;
  updateFormPrefilledByFoldProgress?: (componentKey: string, data: any) => void;
  handleUpdateCareProgram?: (actionCode?: string, actionData?: any) => void;
  onDeleteClick?: (component: FormElementInstance) => void;
  isPrintMode?: boolean;
}) => {
  const context = useContext(CommonDataContext);

  const [commonData, setCommonData] = useState<IFormCommonData>({
    ...context,
    CARE_STUDIO_MLOV: context.CARE_STUDIO_MLOV || {},
    CLOUD_TELEPHONY_MLOV: context.CLOUD_TELEPHONY_MLOV || {},
    MLOV: context.MLOV || {},
    userPermissions: context.userPermissions || [],
    userSettings: Object.keys(context.userSettings || {}).length > 0 ? context.userSettings : (userSettings || {}),
    currentUserRoles: context.currentUserRoles || [],
    userRoleCategories: context.userRoleCategories || [],
    patientId: patientId,
    contactId: contactId,
    contactIntId: contactIntId,
    tenantId: accountUUID,
    tenantName: tenantName,
    isPreviewMode: isPreviewMode,
    fetchDataForPreview: fetchDataForPreview || false,
    updateLoadingStatus: updateLoadingStatus,
    updateFormPrefilledByFoldProgress: updateFormPrefilledByFoldProgress,
    isBuilderMode: isBuilderMode,
    hasLoggedInContext: !!foldAccessToken,
    backgroundSaveEnabled: backgroundSaveEnabled || false,
    componentView: componentView || FormViewType.detail,
    showMoreMaxCount: showMoreMaxCount || 5,
    defaultCollapse: defaultCollapse || false,
    questionnaireList: questionnaireList || [],
    reasonForVisitData: [],
    headers: {
      ...(accountUUID && {
        'account-uuid': accountUUID,
      }),
      ...(foldAccessToken && {
        'fold-access-token': foldAccessToken,
      }),
    },
    locationId:  locationId || accountLocationUuid || contactDetails?.accountLocationUuid,
    formContext: formContext || FormContext.patientProfile,
    openEhrUrl: openEhrUrl,
    ordering: {
      isLabEnabled: false,
      isRadEnabled: false,
      isMedEnabled: false,
    },
    mobileAppDimension: {
      height: 0,
      width: 0,
    },
    organizationCapability: ehrCapabilities?.find(
      (item: IEhrCapability) => item.resourceName === FHIR_RESOURCE.ORGANIZATION
    ),
    ccmDate: ccmDate,
    ehrCapabilities:  ehrCapabilities,
    userData: context.userData,
    sidecarContext: context.sidecarContext,
    accountConfigCapabilities: context.accountConfigCapabilities,
    loggedInUserLocationDetails: context.loggedInUserLocationDetails,
    contactCareProgramId: contactCareProgramId,
    careProgramData: careProgramData,
    contactDetails: {
      ...(contactDetails || {}),
      contactUuid: contactDetails?.contactUuid || contactUuid,
      contactName: contactDetails?.contactName || contactName,
    },
    contactCarePlanId: contactCarePlanId,
    isMobileView: isMobileView || false,
    isDoNotAllowToReplaceNextLine: isDoNotAllowToReplaceNextLine || false,
    showDeleteButton: showDeleteButton || false,
    carePlanDetailsStartDateTime: carePlanDetailsStartDateTime,
    foldContactId: foldContactId,
    proxyHeaders: proxyHeaders,
    isBuilderPreviewMode: isBuilderPreviewMode,
    isPrintForm: isPrintForm,
    tpaCareAdvocateId: tpaCareAdvocateId,
    widgetType,
    isCareProgramEnabled: isCareProgramEnabled || false,
    isSidecar: isSidecar || false,
    goals: goals,
    relevantVitals: relevantVitals,
    ehrActivePatient: ehrActivePatient,
    onDeleteClick: onDeleteClick,
    handleUpdateCareProgram: handleUpdateCareProgram,
    isPrintMode: isPrintMode,
  });

  const [loading, setLoading] = useState({
    patient: false,
  });

  const [getPatientData] = useLazyQuery(
    ContactsQueries.GET_PATIENT_ID_BY_CONTACT_UUID,
    {
      fetchPolicy: 'no-cache',
      context: {
        ...(!commonData.hasLoggedInContext && {
          service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        }),
        headers: {
          ...commonData.headers,
          ...proxyHeaders,
        },
      },
      variables: {
        contactId: commonData.contactId,
      },
    }
  );

  useEffect(() => {
    if (
      commonData.contactId &&
      !commonData.patientId &&
      commonData?.formContext !== FormContext.patientCarePlan
    ) {
      fetchPatientData(commonData.contactId);
    }
  }, []);

  const fetchPatientData = (contactId: string) => {
    setLoading((prev) => ({...prev, patient: true}));
    getPatientData({variables: {contactId}})
    .then((response) => {
      if (response?.data?.contacts) {
        const contact = response.data.contacts[0];
        const patientId =
          contact.patient?.patientId || contact.patient?.patientUuid;
        const locationId =
          contact.contactPracticeLocations?.length > 0
            ? contact.contactPracticeLocations[0].accountLocation?.uuid || ''
            : '';
        setCommonData((prev) => ({
          ...prev,
          patientId,
          formError: !patientId ? FormError.noPatientIdFound : prev.formError,
          locationId: locationId,
        }));
      } else {
        setCommonData((prev) => ({
          ...prev,
          formError: FormError.existingDataAPIFail,
        }));
      }
      setLoading((prev) => ({...prev, patient: false}));
    })
    .catch(() => {
      setLoading((prev) => ({...prev, patient: false}));
      setCommonData((prev) => ({
        ...prev,
        formError: FormError.existingDataAPIFail,
      }));
    });
  }

  const isComponentLoading = loading.patient;

  return (
    <CommonDataContext.Provider value={commonData}>
      {!isComponentLoading && children}
      {isComponentLoading && <FormComponentLoader />}
    </CommonDataContext.Provider>
  );
};
