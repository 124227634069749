import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SubjectiveComplaintSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        fill="#F5F0FF"
      />
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        stroke="#D7C0FF"
        strokeWidth="0.5"
      />
      <Path
        d="M21.15 12.6995L21.5362 12.3133C22.1761 11.6734 23.2136 11.6734 23.8534 12.3133C24.4933 12.9531 24.4933 13.9906 23.8534 14.6305L23.4672 15.0167M21.15 12.6995C21.15 12.6995 21.1983 13.5201 21.9224 14.2443C22.6466 14.9684 23.4672 15.0167 23.4672 15.0167M21.15 12.6995L17.5995 16.25C17.359 16.4905 17.2388 16.6107 17.1354 16.7433C17.0134 16.8997 16.9088 17.0689 16.8235 17.2479C16.7511 17.3997 16.6974 17.5611 16.5898 17.8837L16.1341 19.2509M23.4672 15.0167L19.9167 18.5672C19.6762 18.8077 19.556 18.9279 19.4234 19.0313C19.267 19.1533 19.0978 19.2579 18.9187 19.3432C18.767 19.4155 18.6056 19.4693 18.283 19.5769L16.9158 20.0326M16.9158 20.0326L16.5816 20.144C16.4228 20.1969 16.2478 20.1556 16.1294 20.0373C16.0111 19.9189 15.9698 19.7439 16.0227 19.5851L16.1341 19.2509M16.9158 20.0326L16.1341 19.2509M12.6667 16.8334H14.75M12.6667 13.5H18.0833M12.6667 20.1667H13.9167M22.5237 8.643C21.5474 7.66669 19.976 7.66669 16.8333 7.66669H15.1667C12.024 7.66669 10.4526 7.66669 9.47631 8.643C8.5 9.61931 8.5 11.1907 8.5 14.3334V17.6667C8.5 20.8094 8.5 22.3807 9.47631 23.357C10.4526 24.3334 12.024 24.3334 15.1667 24.3334H16.8333C19.976 24.3334 21.5474 24.3334 22.5237 23.357C23.3096 22.5711 23.4629 21.3997 23.4928 19.3334"
        stroke="#8C5AE2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default React.memo(SubjectiveComplaintSvg);
