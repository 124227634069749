import {View, Text, Pressable} from 'react-native';
import Stack from '../../../../../common/LayoutComponents/Stack';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../../../styles';
import {IBarrier} from '../Barriers/interfaces';
import {Divider} from 'react-native-paper';
import { getMlovValueFromId } from '../../../../../../utils/mlovUtils';
import { IMlov } from '../../../../../../Interfaces';
import { styles } from '../../../Analytics/style';
import CarePlanActionView, { CarePlanAction } from '../Goals/CarePlanActionView';
import { CARE_PLAN_ITEM_STATUS_CODES } from '../../../../../../constants/MlovConst';
import BarrierSvg from '../../../../../common/Svg/CarePlanIcons/BarrierSvg';

const CustomListViewHealthComponent = (props: {
  dataSource: IBarrier[];
  // getDeleteFunction: (item: IBarrier) => void;
  isHideDelete?: boolean;
  isHideBorder?: boolean;
  isShowSubtitle?: boolean;
  carePlanCategoryList: IMlov[]
  statusList: IMlov[];
  showTopBorder?: boolean;
  showStatusChange?: boolean;
  isDisabled?: boolean;
  onActionPerformed: (action: CarePlanAction, uniqueId: string, data?: any) => void;
  forceReadonly?: boolean;
}) => {
  const {dataSource, onActionPerformed, isHideBorder, isShowSubtitle, carePlanCategoryList, statusList, showTopBorder} = props;

  return (
    <View
      style={{
        borderRadius: 10,
        borderColor: Colors?.Custom?.Gray200,
        borderWidth: isHideBorder? 0 : 1,
        marginHorizontal: isHideBorder ? 0 : 10,
        paddingVertical: 2,
        marginBottom: 10,
      }}
    >
      {dataSource?.map((item, dataSourceIndex) => {
        const key = `${
          item.barrierId || item.uniqueId || item.title
        }_${dataSourceIndex}`;


        const categoryName =
          isShowSubtitle && item.categoryId
            ? getMlovValueFromId(carePlanCategoryList, item.categoryId)
            : '';

        return (
          <>
            <Stack
              direction="row"
              key={key}
              style={{
                flex: 1,
                borderTopWidth:
                  dataSourceIndex === 0 && showTopBorder ? 1 : 0,
                borderBottomWidth:
                  dataSourceIndex !== dataSource.length - 1 ? 1 : 0,
                padding: 12,
                alignItems: 'center',
                borderColor: Colors?.Custom?.Gray200,
              }}
            >
              <View style={{marginRight: 16}}>
                <BarrierSvg size={20} />
              </View>
              <Stack direction='column' style={styles.flex1}>
                <Text
                  style={styles.textStyle2}
                >
                  {item?.title}
                </Text>
                {/* {categoryName ? (
                   <Text
                    style={styles.textStyle1}
                 >
                   {categoryName}
                 </Text>
                ) : (
                  <></>
                )} */}
              </Stack>

              <CarePlanActionView
                uniqueId={item.id || item.uniqueId || ''}
                selectedStatusCode={item.status?.code || ''}
                showStatusChange={props.showStatusChange || false}
                showDelete={!props.isHideDelete}
                showEdit={false}
                isDisabled={props.isDisabled || false}
                statusOptions={(statusList || []).map(item => ({ label: item.value, value: item.id, code: item.code }))}
                onActionPerformed={onActionPerformed}
                forceReadonly={props?.forceReadonly}
              />

              {/* {!props.isHideDelete && (
                <Pressable onPress={() => getDeleteFunction(item)}>
                  <Feather
                    name="trash-2"
                    color={Colors.Custom.Gray500}
                    size={18}
                  />
                </Pressable>
              )} */}
            </Stack>
          </>
        );
      })}
    </View>
  );
};

export default CustomListViewHealthComponent;
