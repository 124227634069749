import React, { useEffect, useMemo } from "react";
import { cloneDeep } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
} from "../FormComponents/FormComponents";
import ReactHtmlParser from 'react-html-parser';
import { FormRenderer } from "../FormRenderer";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { ParagraphSchema } from "../Schema/ComponentsSchema";
import { Component } from "../BaseComponents/BaseComponentInterface";
import ParagraphComponent from "../../FHFormio/CustomComponents/Paragraph/Paragraph";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import ParagraphSvg from "../../../../../assets/Icons/FormBuilder/ParagraphSvg";
import { v4 as uuidV4 } from 'uuid';
export const ParagraphFieldFormElement: FormElement = {
  type: ParagraphSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(ParagraphSchema),
    key: getUniqueKey(map, ParagraphSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(ParagraphSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: ParagraphSvg,
    label: ParagraphSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  isReadOnly?: boolean;
}) {
  return (
    <ComponentView
      elementInstance={elementInstance}
      isReadOnly={isReadOnly}
    />
  );
}

const ComponentView = ({
  elementInstance,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  isReadOnly?: boolean;
}) => {
  const { content, allowToShare } = elementInstance as ParagraphComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      {ReactHtmlParser(content)}
    </div>
  );
};

ComponentView.displayName = 'ParagraphComponentView';

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance as Component;

  const formElements = useMemo(() => [
    {
      type: 'textarea',
      input: true,
      key: 'content',
      label: 'Content',
      placeholder: 'Content to be displayed',
      tooltip:
        'The content is a readonly data like header, title or instructions',
      editor: 'quill',
      isUploadEnabled: true,
      uploadStorage: 'base64',
      wysiwyg: QuillConfig,
    },
    ...(isAllowShare ? ShareWithPatientFields : []),
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
