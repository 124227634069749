import {useLazyQuery} from '@apollo/client';
import {Pressable, Text, View} from 'native-base';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {LeadQueries} from '../../../services';
import {Colors} from '../../../styles';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import CrossSvgIcon from '../Svg/CrossSvgIcon';
import WarningSvgIcon from '../Svg/WarningSvgIcon';
import {ACTION_MESSAGE_CODE, ACTION_MESSAGE_ICON_TYPE} from './ActionMessageViewConst';
import { isWeb } from '../../../utils/platformCheckUtils';
import AntDesign from 'react-native-vector-icons/AntDesign';
import StopSvgIcon from '../Svg/StopSvgIcon';
import { CONSENT_ERROR, PATIENT_NUMBER_CHANGED_ERROR } from '../../../constants';
import EditActionBtnSvg from '../Svg/PersonActionSvgIcons/EditActionBtnSvg';
import { useIntl } from 'react-intl';
import { testID } from '../../../testUtils';
import { replaceHashValueToString } from '../../../utils/commonUtils';
import InfoCircleV2Svg from '../Svg/InfoCircleV2Svg';

interface IActionMessageView {
  actionCode: string;
  conversationId: string;
  message?: string;
  contactData?: IContact;
  headerContainer?: any;
  customStyle?: any;
  onClickAction?: () => void
}

const ActionMessageView = (props: IActionMessageView) => {
  const {
    actionCode,
    contactData,
    message,
    conversationId,
    customStyle,
  } = props;
  const [stateData, setStateData] = useState({
    viewElem: (<></>) as any,
    loading: false,
    bottomPosition: 0,
    isShowContent: true,
  });
  const intl = useIntl();

  const [GetContactLoginTimeStamp] = useLazyQuery(
    LeadQueries.GetContactLoginTimeStamp,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const getActionMessageView = async () => {
    let viewCode: any = {
      iconType: ACTION_MESSAGE_ICON_TYPE.WARNING,
      message: '',
    };
    switch (actionCode) {
      case ACTION_MESSAGE_CODE.INBOX_DELETED:
        viewCode = {
          ...viewCode,
          message: message
            ? message
            : 'You cannot reply to this conversation because the associated inbox has been deleted.',
        };
        break;
      case ACTION_MESSAGE_CODE.IS_EXIST_IN_CHAT:
        viewCode = {
          ...viewCode,
          message: message
            ? message
            : intl.formatMessage({id: 'conversationThreadWarningMsg'}),
        };
        break;
      case ACTION_MESSAGE_CODE.IS_ONLINE_CHECK:
        let contactRes: any;
        if (contactData?.additionalAttributes) {
          contactRes = contactData;
        } else {
          contactRes = await GetContactLoginTimeStamp({
            variables: {
              id: contactData?.id,
            },
          });
          if (contactRes?.data?.contact?.id) {
            contactRes = contactRes?.data?.contact;
          }
        }
        if (!contactRes?.additionalAttributes?.loggedInTime) {
          viewCode = {
            ...viewCode,
            message: message
              ? message
              : 'Patient doesn’t seem to have download or logged in to patient app. May not be able to see your messages.',
          };
        }
        break;
      case ACTION_MESSAGE_CODE.INBOX_UNSUBSCRIBE:
          viewCode = {
            ...viewCode,
            iconType: ACTION_MESSAGE_ICON_TYPE.STOP,
            message: message
              ? message
              : 'Unsubscribed from our SMS chat, You can’t reply to this conversation.',
          };
          break;
      case ACTION_MESSAGE_CODE.CONSENT_NOT_CHECK:
            viewCode = {
              ...viewCode,
              message: message
                ? message
                : CONSENT_ERROR,
            };
            break;
      case ACTION_MESSAGE_CODE.PATIENT_NUMBER_CHANGED:
              viewCode = {
                ...viewCode,
                message: message
                  ? message
                  : PATIENT_NUMBER_CHANGED_ERROR,
              };
            break;
      case ACTION_MESSAGE_CODE.CONVERSATION_DEACTIVATED:
        viewCode = {
          ...viewCode,
          message: message
            ? message
            : intl.formatMessage({id: 'conversationDeactivated'}),
        };
        break;
      case ACTION_MESSAGE_CODE.USER_LOCATION_MIS_MATCHED:
        viewCode = {
          ...viewCode,
          message: message
            ? message
            : intl.formatMessage({id: 'userLocationMisMatched'}),
        };
        break;
      case ACTION_MESSAGE_CODE.CONTACT_PROFILE_DEACTIVATED:
        viewCode = {
          ...viewCode,
          message: message
            ? message
            : intl.formatMessage({id: 'contactProfileDeactivated'}),
        };
        break;
      case ACTION_MESSAGE_CODE.CONTACT_DEACTIVATED:
        viewCode = {
          ...viewCode,
          message: message
            ? message
            : intl.formatMessage({id: 'contactDeactivated'}),
        };
        break;
    }

    if (viewCode.message) {
      let message = viewCode.message;

      const splitMsgByName = viewCode.message.split(contactData?.name);
      if (splitMsgByName.length == 2) {
        message = <>
          {splitMsgByName[0]}
          <Text style={{ fontWeight: 'bold' }}>{contactData?.name}</Text>
          {splitMsgByName[1]}
        </>
      }

      const tempView: any = (
        <View
          style={{
            top: customStyle?.marginTop
              ? customStyle.marginTop
              : undefined,
            left: 0,
            zIndex: 1212121,
            width: '100%',
            height: customStyle?.height ? customStyle.height : undefined,
            borderEndWidth: 1,
            borderColor: Colors.Custom.Gray200,
            backgroundColor: customStyle?.backgroundColor || '#f8ece6',
            paddingVertical: isWeb() ? 16 : 4,
            paddingHorizontal: 16,
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          {!isWeb() ? (
            <View style={{alignItems: 'center', alignSelf: 'center'}}>
              {viewCode.iconType == ACTION_MESSAGE_ICON_TYPE.WARNING ? (
                <InfoCircleV2Svg size={32} />
              ) : (
                viewCode.iconType == ACTION_MESSAGE_ICON_TYPE.STOP && (
                  <StopSvgIcon />
                )
              )}
            </View>
          ) : (
            <View
              marginLeft={5}
              style={{
                width: 20,
                height: 20,
                alignItems: 'center',
                alignSelf: 'center',
              }}
              {...testID('image')}
            >
              {viewCode.iconType == ACTION_MESSAGE_ICON_TYPE.WARNING ? (
                <InfoCircleV2Svg />
              ) : (
                viewCode.iconType == ACTION_MESSAGE_ICON_TYPE.STOP && (
                  <StopSvgIcon customColor={Colors.Custom.Gray500} />
                )
              )}
            </View>
          )}
          <Text
            size={'smMedium'}
            paddingLeft={1.5}
            paddingRight={3}
            alignItems={'center'}
            alignSelf={'center'}
            style={{color: customStyle?.textColor || '#B74A14'}}
            textAlign={'left'}
            {...testID('ActionMessage')}
          >
            {message}
          </Text>
          {actionCode !== ACTION_MESSAGE_CODE.INBOX_UNSUBSCRIBE && false && (
            <Pressable
              onPress={() => {
                if (actionCode === ACTION_MESSAGE_CODE.CONSENT_NOT_CHECK) {
                  props?.onClickAction?.();
                  return;
                }
                setStateData((prev) => {
                  return {
                    ...prev,
                    isShowContent: false,
                  };
                });
              }}
              style={{
                width: 24,
                height: 24,
                alignItems: 'center',
                alignSelf: 'center',
              }}
            >
              {!isWeb() ? (
                <>
                  {actionCode === ACTION_MESSAGE_CODE.CONSENT_NOT_CHECK ? (
                    <></>
                  ) : (
                    <AntDesign
                      size={24}
                      name={'close'}
                      color={Colors.secondary['700']}
                      {...testID('image')}
                    />
                  )}
                </>
              ) : (
                <>
                  {actionCode === ACTION_MESSAGE_CODE.CONSENT_NOT_CHECK ? (
                    <View style={{width: 20, height: 20}} {...testID('image')}>
                      <EditActionBtnSvg />
                    </View>
                  ) : (
                    <CrossSvgIcon customColor={'#B74A14'} />
                  )}
                </>
              )}
            </Pressable>
          )}
        </View>
      );
      setStateData((prev) => {
        return {
          ...prev,
          viewElem: tempView,
          // bottomPosition: tempView?.ref?.current?.offsetHeight,
        };
      });
    }
  };
  useEffect(() => {
    getActionMessageView();
  }, [conversationId]);

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        bottomPosition: props.headerContainer?.current?.offsetHeight,
      };
    });
  }, []);

  return stateData.loading ? (
    <></>
  ) : !stateData.isShowContent ? (
    <></>
  ) : (
    stateData.viewElem
  );
};

export default React.memo(ActionMessageView);
