import {DayOptimizerSelectedColumnCode} from '../../DayOptimizerConstants';
import {DayOptimizerViewAction} from '../../DayOptimizerConstants';
import {PatientData} from '../../interfaces';

export const getFormattedPatientForAutomation = (selectedPatient: PatientData) => {
  return {
    contactData: {
      selectedPatient,
      id: selectedPatient.patientId,
      name: selectedPatient.name,
    },
    id: selectedPatient.patientId,
    name: selectedPatient.name,
  };
};


export const getColumnViewCodeFromActionCode = (actionCode: DayOptimizerViewAction) => {
  if(actionCode === DayOptimizerViewAction.CLICK_ON_ALERT) {
    return DayOptimizerSelectedColumnCode.ALERT;
  }
  return undefined;
}