import React, { useContext, useEffect, useState } from "react";
import { ICustomFormLibraryElement, IUseFormSidebarBuilderProps, LibraryElement } from "../CustomFormEngineInterfaces";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../../constants/Configs";
import { useLazyQuery } from "@apollo/client";
import { ComponentsQueries } from "../../../../../services";
import { getLibraryElements } from "../CustomFormEngineUtils";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { isEnableCareProgram } from "../../../../../utils/commonUtils";

export const useFormSidebarBuilder = (args: IUseFormSidebarBuilderProps) => {
  const contextData = useContext(CommonDataContext);
  const isCareProgramEnabled = isEnableCareProgram(contextData.userSettings);
  const [state, setState] = useState<{
    libraryElements: ICustomFormLibraryElement[],
    metadataLoading: boolean,
  }>({
    libraryElements: [] as ICustomFormLibraryElement[],
    metadataLoading: false,
  });

  // Lifecycle hooks
  useEffect(() => {
    if (args.reusableComponents) {
      setLibraryElements(args.reusableComponents);
    } else {
      fetchMetadata();
    }
  }, []);


  // APIs
  const [getReusableComponents] = useLazyQuery(
    ComponentsQueries.GET_ALL_REUSABLE_COMPONENTS,
    {fetchPolicy: 'no-cache', context: {service: CARESTUDIO_APOLLO_CONTEXT}}
  );

  // Other functions
  const fetchMetadata = async () => {
    try {
      setState((prevState) => ({...prevState, metadataLoading: true}));

      const customComponents = await getReusableComponentsData();
      setLibraryElements(customComponents || []);
    } catch (error) {
      console.error(error);
    } finally {
      setState((prevState) => ({...prevState, metadataLoading: false}));
    }
  };

  const setLibraryElements = (reusableComponents: {component: LibraryElement}[]) => {
    const libraryElements = getLibraryElements(reusableComponents, {
      ehrCapabilities: args.ehrCapabilities,
      selectedCategory: args.selectedCategory,
      isCareProgramEnabled,
    });
    setState((prevState) => ({...prevState, libraryElements}));
  };

  const getReusableComponentsData = async () => {
    try {
      const response = await getReusableComponents();
      return response?.data?.components;
    } catch (error) {
      console.error(error);
      return [];
    }
  };


  return { metadataLoading: state.metadataLoading, libraryElements: state.libraryElements };
};

export default useFormSidebarBuilder;

