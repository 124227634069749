import {Box, Divider, HStack, Icon, Image, Text, VStack} from 'native-base';
import React, {useMemo} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {DATE_FORMATS} from '../../../constants';
import {Colors} from '../../../styles';
import {getDateStrFromFormat} from '../../../utils/DateUtils';
import {DisplayText} from '../../common/DisplayText/DisplayText';
import {fontStyle} from 'html2canvas/dist/types/css/property-descriptors/font-style';
import {useIntl} from 'react-intl';

const AppointmentBookingSuccessPage = (props: {
  appointmentName: string;
  appointmentStartDate: string;
  appointmentEndDate: string;
  appointmentLocation: string;
  isRSVPAppointment: boolean;
  customWhereMessage?: string;
  providerName?: string;
}) => {
  const getImageSrc = () => require('../../../assets/gifs/success.gif');
  const intl = useIntl();
  const dateString = useMemo(
    () =>
      `${getDateStrFromFormat(
        props.appointmentStartDate || new Date(),
        DATE_FORMATS.SLOT_DATE_FORMAT_WITH_DAY
      )} ${getDateStrFromFormat(
        props.appointmentStartDate || new Date(),
        DATE_FORMATS.DISPLAY_TIME_FORMAT
      )} - ${getDateStrFromFormat(
        props.appointmentEndDate || new Date(),
        DATE_FORMATS.DISPLAY_TIME_FORMAT
      )}`,
    [props.appointmentStartDate, props.appointmentEndDate]
  );


  return (
      <VStack space={1} alignItems={'center'} justifyContent={'center'} minH="full" px={'lg'}>
        <Image size={24} source={getImageSrc()} alt="image" />
        <Text fontSize={30} color={Colors.FoldPixel.GRAY400} fontWeight={"bold"} textAlign={'center'}>
          {intl.formatMessage({
            id: props.isRSVPAppointment
              ? 'appointmentScheduledRSVPHeader'
              : 'appointmentScheduledHeader',
          })}
        </Text>
        <Text fontSize={20} color={Colors.FoldPixel.GRAY400} textAlign={'center'}>
          {props.providerName}
        </Text>
        <Text fontSize={18} color={Colors.FoldPixel.GRAY200} textAlign={'center'}>
          {dateString}
        </Text>
        <Text fontSize={18} color={Colors.FoldPixel.GRAY200} textAlign={'center'}>
          {props?.customWhereMessage || props.appointmentLocation}
        </Text>
      </VStack>
  );
};

export default AppointmentBookingSuccessPage;
