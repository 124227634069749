import React, {useState, useCallback, useMemo} from 'react';
import {useDraggable} from '@dnd-kit/core';
import {
  ElementSource,
  FormElementInstance,
  FormElements,
} from '../FormComponents/FormComponents';
import {CustomFormBuilderActionTypes} from '../CustomFormEngineInterfaces';
import {useCustomFormBuilderContext} from '../Context/CustomFormBuilder.context';
import classNames from 'classnames';
import {ComponentDroppableOverlay} from './ComponentDroppableOverlay';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles';
import { findElementIndexAndParentRecursively } from '../CustomFormEngineUtils';
import TrashSvg from '../../../../common/Svg/TrashSvg';
import CustomConfirmationModal from '../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import { ColumnsSchema, ContainerSchema } from '../Schema/ComponentsSchema';
import { ContainerComponent } from '../BaseComponents/BaseComponentInterface';

function ElementWrapper({element, isLastElement}: {element: FormElementInstance, isLastElement?: boolean}) {
  const {state, dispatch} = useCustomFormBuilderContext();
  const [mouseIsOver, setMouseIsOver] = useState<boolean>(false);
  const [componentState, setComponentState] = useState<{
    deleteConfirmation: {
      isOpen: boolean;
      element: FormElementInstance | null;
      activeElementDetails: {parent: FormElementInstance | null, index: number} | null;
    };
  }>({
    deleteConfirmation: {
      isOpen: false,
      element: null,
      activeElementDetails: null,
    },
  });

  const draggableConfig = useMemo(
    () => ({
      id: `${element.id}-drag-handler`,
      data: {
        type: element.type,
        
        elementId: element.id,
        source: ElementSource.form,
      },
      disabled: state.isDragAndDropDisabled,
    }),
    [element.id, element.type, state.isDragAndDropDisabled]
  );

  const {isDragging, setNodeRef, attributes, listeners} =
    useDraggable(draggableConfig);

  const DesignerElement = useMemo(
    () =>
      FormElements[element.type as keyof typeof FormElements]?.designerComponent,
    [element.id]
  );

  const handleMouseEnter = useCallback(() => setMouseIsOver(true), []);
  const handleMouseLeave = useCallback(() => setMouseIsOver(false), []);


  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch?.({
        type: CustomFormBuilderActionTypes.SELECT_ELEMENT,
        payload: element,
      });
    },
    [dispatch, element]
  );

  const handleClone = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch?.({
        type: CustomFormBuilderActionTypes.CLONE_ELEMENT,
        payload: element,
      });
    },
    [dispatch, element]
  );

  const handleDelete = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const activeElementDetails = element.id
        ? findElementIndexAndParentRecursively(state.elements, element.id)
        : null;
      showDeleteConfirmation(element, activeElementDetails);
    },
    [dispatch, element]
  );

  const showDeleteConfirmation = useCallback((element: FormElementInstance, activeElementDetails: {parent: FormElementInstance | null, index: number} | null) => {
    if ((element.type === ContainerSchema.type && (element as ContainerComponent).components?.length) || element.type === ColumnsSchema.type) {
      setComponentState((prev) => ({...prev, deleteConfirmation: {isOpen: true, element, activeElementDetails }}));
      dispatch?.({
        type: CustomFormBuilderActionTypes.DRAG_AND_DROP_DISABLED,
        payload: true,
      });
    } else {
      dispatch?.({
        type: CustomFormBuilderActionTypes.REMOVE_ELEMENT,
        payload: {element, resetSelectedElement: true, parentElement: activeElementDetails?.parent},
      });
    }
  }, []);

  const handleDeleteCancel = useCallback(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.DRAG_AND_DROP_DISABLED,
      payload: false,
    });
    setComponentState((prev) => ({...prev, deleteConfirmation: {isOpen: false, element: null, activeElementDetails: null}}));
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.REMOVE_ELEMENT,
      payload: {element: componentState.deleteConfirmation.element, resetSelectedElement: true, parentElement: componentState.deleteConfirmation.activeElementDetails?.parent},
    });
    dispatch?.({
      type: CustomFormBuilderActionTypes.DRAG_AND_DROP_DISABLED,
      payload: false,
    });
    setComponentState((prev) => ({...prev, deleteConfirmation: {isOpen: false, element: null, activeElementDetails: null}}));
  }, [componentState.deleteConfirmation.element, componentState.deleteConfirmation.activeElementDetails?.parent]);

  if (isDragging) {
    return null;
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="element-wrapper"
    >
      <ComponentDroppableOverlay element={element} isDragging={isDragging} isLastElement={isLastElement}>
        <div
          className={classNames(
            'element-wrapper-main-container flex w-full items-center rounded-md  px-4 py-4',
            {
              'element-wrapper--active': mouseIsOver || state.selectedElement?.id === element.id,
            }
          )}
          style={{
            opacity: isDragging ? 0.3 : 1,
          }}
        >
          <DesignerElement elementInstance={element} />
        </div>
      </ComponentDroppableOverlay>
      {mouseIsOver && (
        <div className="element-actions">
          <div onClick={handleClone} className="pressable element-action-button">
            <Feather
              name="copy"
              color={Colors.Custom.Gray500}
              size={14}
            />
          </div>
          <div onClick={handleDelete} className="pressable element-action-button">
            <Feather
              name="trash-2"
              color={Colors.Custom.ErrorColor}
              size={14}
            />
          </div>
        </div>
      )}
      <CustomConfirmationModal
        isOpen={componentState.deleteConfirmation.isOpen}
        headerText="deleteComponentConfirmation"
        message="deleteComponentConfirmationMessage"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        customIcon={<TrashSvg />}
      />
    </div>
  );
}

export default React.memo(ElementWrapper);
