import {Tooltip, Typography} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import React from 'react';
import { testID } from '../../../../../testUtils';

const Label = ({
  label,
  isRequired,
  tooltip,
  isReadOnly,
  labelClassName,
}: {
  label: string;
  isRequired?: boolean;
  tooltip?: string;
  isReadOnly?: boolean;
  labelClassName?: string;
}) => {
  // Split the label into words
  const words = label.trim().split(' ');
  const lastWord = words.pop();
  const remainingWords = words.join(' ');

  return (
    <div className="flex items-start">
      <Typography.Text className={`field-label ${isReadOnly ? 'readonly-label' : ''} ${labelClassName}`} {...testID(remainingWords)}>
        <span className="inline whitespace-normal">
          {remainingWords && `${remainingWords} `}
          <span className="inline-flex items-center gap-1 whitespace-nowrap">
            {lastWord}
            {Boolean(tooltip) && (
              <Tooltip title={tooltip}>
                <InfoCircleOutlined className="text-gray-500 text-sm" />
              </Tooltip>
            )}
            {isRequired && <span className="required-asterisk">•</span>}
          </span>
        </span>
      </Typography.Text>
    </div>
  );
};

export default React.memo(Label);
