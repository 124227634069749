import { TASK_TITLE_COMPATIBLE_COMPONENTS } from '../FHFormio/Builder/SupportedComponents';
import { getFlatListedFormComponents } from './AddOrUpdateForm/AddOrUpdateFormHelper';
import {
  IFormMetaData,
  IRiskScoreState,
} from './RiskScoreConfiguration/RiskScoreConfiguration';
import { IRiskScoreData } from './RiskScoreConfiguration/RiskScoreInterface';

export enum FormRequiredField {
  formName = 'FormName',
  riskScoreName = 'RiskScoreName',
  riskScoreStates = 'RiskScoreStates',
  riskScoreFields = 'RiskScoreFields',
  formComponents = 'FormComponents',
  formComponentsOptionValues = 'FormComponentsOptionValues',
  currency = 'currency',
  image = 'image',
  category = 'category',
  ehr = 'ehr',
  location = 'location',
}

export const errorMessages = {
  formName: 'Form name is required',
  riskScoreName: 'Score: Score name is required',
  riskScoreStates: 'Score: Duplicate score values',
  riskScoreFields: 'Score: Please fill all details of selected fields',
  formComponents:
    'Please add at least one form component to create/update the form',
  formComponentsOptionValues: 'Please fill all the values for the options',
  currency: 'Please select currency unit',
  noteName: 'Note name is required',
  category: 'Category is required',
  ehr: 'Please select EHR',
  location: 'Please select location',
};

export const isValidForm = (
  formName: string,
  formMetadata: IFormMetaData,
  formComponents: any[],
  ehrCode: string,
  locationList: any[],
  isEnabledMultiTenancy?: boolean
): {isValid: boolean; error: string} => {
  formName = formName ? formName.trim() : formName;
  let toastMessage = '';
  let isValid = true;
  const flatComponents = getFlatListedFormComponents(formComponents || []);
  if (isInvalid(FormRequiredField.formName, formName)) {
    toastMessage = errorMessages.formName;
    isValid = false;
  } else if (isInvalid(FormRequiredField.formComponents, formComponents)) {
    toastMessage = errorMessages.formComponents;
    isValid = false;
  } else if (
     isInvalid(FormRequiredField.formComponentsOptionValues, flatComponents)
  ) {
    toastMessage = getInvalidOptionFieldErrorMessage(flatComponents);
    isValid = false;
  } else if (isInvalid(FormRequiredField.currency, formComponents)) {
    toastMessage = errorMessages.currency;
    isValid = false;
  } else if (isInvalid(FormRequiredField.image, formComponents)) {
    toastMessage = 'Please upload image in the image component';
    isValid = false;
  }
  else if (formMetadata.metadata.length > 0) {
    (formMetadata.metadata).some(metaData => {
      if (metaData.fields.length > 0) {
        const stateErrorMessage = getInvalidRiskScoreFieldMessage(metaData.states, metaData);
        if (stateErrorMessage?.length) {
          toastMessage = stateErrorMessage;
          isValid = false;
          return true;
        } else if (
          isInvalid(FormRequiredField.riskScoreFields, metaData.fields)
        ) {
          toastMessage = errorMessages.riskScoreFields;
          isValid = false;
          return true;
        }
      }
    });
  } else if (isEnabledMultiTenancy && isInvalid(FormRequiredField.ehr, ehrCode)) {
    toastMessage = errorMessages.ehr;
    isValid = false;
  } else if (isEnabledMultiTenancy && isInvalid(FormRequiredField.location, locationList)) {
    toastMessage = errorMessages.location;
    isValid = false;
  }
  return {isValid: isValid, error: toastMessage};
};


export const getInvalidOptionFieldErrorMessage = (value: any) => {
  let errorMessage = '';
  const checkOptionFieldsComponentExist = value.some((componentItem: any) =>
    ['selectBoxes', 'select', 'radio'].includes(componentItem.type)
  );
  if (checkOptionFieldsComponentExist) {
    const taskCompatibleComponents = [];
    const optionValueComponents = value.filter((componentItem: any) => {
      if (TASK_TITLE_COMPATIBLE_COMPONENTS.includes(componentItem.type) && componentItem?.allowToChangeNoteTaskTitle) {
        taskCompatibleComponents.push(componentItem);
      }
      return ['selectBoxes', 'select', 'radio'].includes(componentItem.type)
    });
    optionValueComponents.some((componentItem: any) => {
      const optionValues = componentItem.values || componentItem.data?.values || [];
      if (!optionValues?.length) {
        errorMessage = `Please add options for ${componentItem?.label || componentItem?.type} field`;
        return true;
      } else {
        return optionValues.some((optionItem: any) => {
          if (!optionItem.value) {
            errorMessage = `Please add options for ${componentItem?.label  || componentItem?.type} field`;
            return true;
          }
        });
      }
    });
    if (taskCompatibleComponents.length > 1) {
      errorMessage = `More than one components have been selected for populating the task title for that note. Please select one only`;
    }
  }
  return errorMessage;
};

export const isInvalid = (field: FormRequiredField, value: any) => {
  switch (field) {
    case FormRequiredField.formName:
    case FormRequiredField.category:
      return !value || value.length === 0 || value.trim().length === 0;

    case FormRequiredField.formComponents:
      return !value || value.length === 0;

    case FormRequiredField.formComponentsOptionValues:
      const checkOptionFieldsComponentExist = value.some((componentItem: any) =>
        ['selectBoxes', 'select', 'radio'].includes(componentItem.type)
      );
      let isOptionsValueComponentsValid = true;

      if (checkOptionFieldsComponentExist) {
        const taskCompatibleComponents = [];
        const optionValueComponents = value.filter((componentItem: any) => {
          if (componentItem?.allowToChangeNoteTaskTitle) {
            taskCompatibleComponents.push(componentItem);
          }
          return ['selectBoxes', 'select', 'radio'].includes(componentItem.type)
        });
        optionValueComponents.forEach((componentItem: any) => {
          const optionValues = componentItem.values || componentItem.data?.values || [];
          if (!optionValues?.length) {
            isOptionsValueComponentsValid = false;
          } else {
            optionValues.forEach((optionItem: any) => {
              if (!optionItem.value) {
                isOptionsValueComponentsValid = false;
              }
            });
          }
        });
        if (taskCompatibleComponents.length > 1) {
          isOptionsValueComponentsValid = false;
        }
      }

      return !isOptionsValueComponentsValid;

    case FormRequiredField.currency:
      const checkForCurrencyComponent = value.some((componentItem: any) =>
        ['currency'].includes(componentItem.type)
      );
      let isCurrencyComponentValid = true;

      if (checkForCurrencyComponent) {
        const currencyComponents = value.filter((componentItem: any) =>
          ['currency'].includes(componentItem.type)
        );
        currencyComponents.forEach((componentItem: any) => {
          if (!componentItem.currency) {
            isCurrencyComponentValid = false;
          }
        });
      }

      return !isCurrencyComponentValid;

    case FormRequiredField.riskScoreName:
      return !value || value.length === 0;

    case FormRequiredField.riskScoreStates:
      let isInvalidState = false;
      value.forEach((state: IRiskScoreState) => {
        isInvalidState =
          isInvalidState ||
          (!state.to && state.to !== 0) ||
          (!state.from && state.from !== 0) ||
          state.state.length === 0;
      });
      return isInvalidState;

    case FormRequiredField.riskScoreFields:
      let isInvalidFields = false;
      value.forEach((field: any) => {
        isInvalidFields =
          isInvalidFields ||
          !field.componentId ||
          field.componentId.length === 0;
      });
      return isInvalidFields;

    case FormRequiredField.image:
      let isInvalidImageComponent = false;
      const isImageComponentExist = value.some((componentItem: any) =>
        ['image'].includes(componentItem.type)
      );
      if (isImageComponentExist) {
        const imageComponents = value.filter((componentItem: any) =>
          ['image'].includes(componentItem.type)
        );
        imageComponents.some((componentItem: any) => {
          if (!componentItem?.file?.[0]?.url) {
            isInvalidImageComponent = true;
          }
        });
      }
      return isInvalidImageComponent;

    case FormRequiredField.ehr:
      return !value || value.length === 0;

    case FormRequiredField.location:
      return !value || value.length === 0;
    default:
      break;
  }
  return false;
};

export const getInvalidRiskScoreFieldMessage = (value: any, metaData: IRiskScoreData) => {
  let isInvalidState = false;
  let selectedState: IRiskScoreState = {} as IRiskScoreState;
  isInvalidState = (value || []).some((state: IRiskScoreState) => {
    selectedState = state;
    return (state?.from || 0) > (state?.to || 0);
  });
  if (isInvalidState) {
    return `Lower limit of score state is greater than upper limit`;
  }
  isInvalidState = (value || []).some((state: IRiskScoreState, index: number) => {
    return value.some((state1: IRiskScoreState, index1: number) => {
      return state1.to === state.to && state1.from === state.from && index1 !== index;
    });
  });
  if (isInvalidState) {
    return `Duplicate score state values entered`;
  }
  isInvalidState = (value || []).some((state: IRiskScoreState, index: number) => {
    return value.some((state1: IRiskScoreState, index1: number) => {
      const fromValue = state.from || 0;
      const fromValue1 = state1.from || 0;
      const toValue = state.to || 0;
      const toValue1 = state1.to || 0;
      if (index1 !== index &&
          (
            (fromValue >= fromValue1 && (fromValue <= toValue1)) || (toValue >= fromValue1 && (toValue <= toValue1))
          )) {
        return true;
      }
    });
  });
  if (isInvalidState) {
    return `Overlapping score state values entered`;
  }
  isInvalidState = (value || []).some((state: IRiskScoreState) => state.state.length === 0 || state.state.trim().length === 0);
  if (isInvalidState) {
    return `Please enter risk score name to proceed.`;
  }
  return '';
};
