import {Drawer, Select as AntSelect} from 'antd';
import {
  Checkbox,
  FormControl,
  HStack,
  Input,
  Select,
  Text,
  useMediaQuery,
  useToast,
  View,
  VStack,
} from 'native-base';
import {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {COMMON_ACTION_CODES} from '../../../../../constants/ActionConst';
import {Colors} from '../../../../../styles';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {getAccountMergeTagData} from '../../ContentManagementUtils';
import {ITemplateCategory} from '../../EmailTemplates/interfaces';
import {SingleNotification} from '../../PushNotifications/interfaces';
import {AddOrUpdateSMSTemplatesProps, SingleSMSTemplate} from '../interfaces';
import {OverrideDrawer} from '../OverrideDrawer';
import {SMSPreview} from '../SMSPreview';
import {
  createSMSTemplate,
  getAddOrUpdateText,
  getParseBody,
  updateSMSTemplates,
  validateTemplate,
} from '../SMSTemplatesUtils';
import AddOrUpdateSMSTemplatesBody from './AddOrUpdateSMSTemplatesBody';
import {styles} from './AddOrUpdateSMSTemplatesStyles';
import { filteredFormsCategoryList } from '../../../../../utils/commonUtils';
import { useIntl } from 'react-intl';

const {ErrorMessage, Label, HelperText} = FormControl;
const {Item} = Select;

const AddOrUpdateSMSTemplates = (props: AddOrUpdateSMSTemplatesProps) => {
  const {onFormCompleteAction, singleSMSTemplate, categoryList, isReadOnly} =
    props;
  const intl = useIntl();
  const [smsTemplateData, setSmsTemplateData] = useState(
    singleSMSTemplate || ({} as SingleSMSTemplate)
  );
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [override, setOverride] = useState(false);

  const toast = useToast();

  const showSuccess = () => {
    showToast(toast, successText, ToastType.success);
  };
  const showError = () => {
    showToast(
      toast,
      'Something went wrong. Please try again later',
      ToastType.error
    );
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const {width} = Dimensions.get('window');
  const drawerWidth =
    isIPadMiniScreen || isIPadScreen ? width * 0.7 : width * 0.33;

  const onSave = (id?: number) => {
    showSuccess();
    const updatedSmsTemplateData = {
      ...smsTemplateData,
      ...(id ? {id: id} : {}),
    }
    onFormCompleteAction?.(COMMON_ACTION_CODES.ADDED_OR_UPDATED, updatedSmsTemplateData);
  };

  const onCancel = () => {
    onFormCompleteAction?.(COMMON_ACTION_CODES.CANCEL);
  };

  const onCategorySelect = (id: string) => {
    const selectedCategory = categoryList.find(
      (item) => item.id === parseInt(id)
    );
    setSmsTemplateData((prev) => {
      return {
        ...prev,
        category: selectedCategory as ITemplateCategory,
      };
    });
  };

  const {btnText, successText, title} = getAddOrUpdateText(singleSMSTemplate);

  const onSubmit = () => {
    const {errors, valid} = validateTemplate(smsTemplateData);
    setErrors(errors);
    if (valid) {
      setLoading(true);
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (smsTemplateData.id) {
      handleUpdateTemplate();
    } else {
      handleCreateTemplate();
    }
  };

  const handleCreateTemplate = (override?: boolean) => {
    createSMSTemplate({
      singleSMSTemplate: smsTemplateData,
      override: override,
    })
      .then((response) => {
        setSmsTemplateData((prev) => {
          return {
            ...prev,
            id: response?.data?.data?.id,
          }
        })
        setLoading(false);
        onSave(response?.data?.data?.id);
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  const handleUpdateTemplate = (override?: boolean) => {
    updateSMSTemplates({
      id: smsTemplateData.id,
      singleSMSTemplate: smsTemplateData,
      override: override,
    })
      .then((response) => {
        setLoading(false);
        onSave();
      })
      .catch((error) => {
        setLoading(false);
        errorHandler(error);
      });
  };

  const errorHandler = (error: any) => {
    const statusCode = error?.response?.data?.statusCode;
    if (statusCode === 400) {
      setOverride(true);
    } else {

      showError();
      onCancel();
    }
  };

  const handleOverrideConfirm = () => {
    setLoading(true);
    if (smsTemplateData.id) {
      handleUpdateTemplate(true);
    } else {
      handleCreateTemplate(true);
    }
  };


  const filteredCategories = filteredFormsCategoryList(categoryList)

  const accountMergeTags = getAccountMergeTagData({hideLogo: true});

  const getMergeTags = () => {
    if (smsTemplateData.category) {
      return {
        ...smsTemplateData?.category?.mergeTags,
        global: accountMergeTags,
      };
    }
    return {
      global: accountMergeTags,
    };
  };

  if (isReadOnly) {
    return (
      <VStack
        width={drawerWidth}
        justifyItems={'center'}
        alignSelf={'center'}
        style={[styles.templateCard]}
      >
        <HStack style={styles.readonlyLineContainer}>
          <Text paddingTop={'3px'} width={100} fontWeight={300}>
            Name
          </Text>
          <Text
            style={styles.readPillContainer}
            fontWeight={500}
            numberOfLines={1}
          >
            {smsTemplateData?.name || ''}
          </Text>
        </HStack>

        <HStack style={styles.readonlyLineContainer}>
          <Text paddingTop={'3px'} width={100} fontWeight={300}>
            Category
          </Text>
          <Text
            style={styles.readPillContainer}
            fontWeight={500}
            numberOfLines={1}
          >
            {smsTemplateData?.category?.name || ''}
          </Text>
        </HStack>
        <HStack style={styles.readonlyLineContainer}>
          <Text paddingTop={'3px'} width={100} fontWeight={300}>
            Body
          </Text>
          <Text
            style={styles.readPillContainer}
            fontWeight={500}
            //numberOfLines={1}
          >
            {smsTemplateData?.body || ''}
          </Text>
        </HStack>
        <SMSPreview
          bodyText={
            getParseBody({
              bodyString: smsTemplateData.body || '',
              mergeTags: getMergeTags(),
            }) || 'Body'
          }
        />
      </VStack>
    );
  }

  return (
    <Drawer
      visible
      destroyOnClose
      // onClose={() => {
      //   onCancelWrapper();
      // }}
      width={drawerWidth}
      title={
        <>
          <ModalActionTitle
            title={title}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'cancel',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onCancel();
                },
              },
              {
                show: true,
                id: 2,
                btnText: btnText,
                textColor: Colors.Custom.mainPrimaryPurple,
                isLoading: loading,
                variant: BUTTON_TYPE.PRIMARY,
                isTransBtn: false,
                onClick: () => {
                  onSubmit();
                },
              },
            ]}
          />
        </>
      }
    >
      <View mt={0} mx={0}>
        <FormControl
          style={styles.formContainer}
          isRequired
          isInvalid={errors.name}
        >
          <Label style={styles.labelContainer}>Name</Label>
          <Input
            _focus={{borderColor: '#D0D5DD'}}
            value={smsTemplateData?.name || ''}
            onChangeText={(name) => {
              setSmsTemplateData((prev) => {
                return {
                  ...prev,
                  name: name,
                };
              });
            }}
          />
          {errors.name && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.name}
            </ErrorMessage>
          )}
        </FormControl>

        <FormControl
          style={styles.formContainer}
          isRequired
          isInvalid={errors.category}
        >
          <Label>Category</Label>
          <AntSelect
            size="large"
            showSearch
            value={smsTemplateData?.category?.name.toString() || ''}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value) => {
              onCategorySelect(value);
            }}
          >

            {filteredCategories.map((item) => {
              return (
                <AntSelect.Option key={item.id} value={item.id}>
                  {item.name}
                </AntSelect.Option>
              );
            })}
          </AntSelect>
          {errors.category && (
            <ErrorMessage
              _text={{
                fontSize: 'xs',
                color: 'error.500',
                fontWeight: 500,
              }}
            >
              {errors.category}
            </ErrorMessage>
          )}
        </FormControl>

        <AddOrUpdateSMSTemplatesBody
          errors={errors}
          showAttachment={true}
          smsTemplateData={smsTemplateData}
          setSmsTemplateData={setSmsTemplateData}
          onAddOrUpdateAttachment={(newPreviewElements) => {
            setSmsTemplateData((prev) => {
              return {
                ...prev,
                attachments: newPreviewElements,
              };
            });
          }}
        ></AddOrUpdateSMSTemplatesBody>

        <FormControl style={styles.defaultOptionContainer}>
          <Checkbox
            value="Set As Default"
            isChecked={smsTemplateData.isDefault || false}
            onChange={(isSelected) => {
              setSmsTemplateData((prev) => {
                return {
                  ...prev,
                  isDefault: isSelected,
                };
              });
            }}
          >
            Set As Default
          </Checkbox>
        </FormControl>

        <OverrideDrawer
          isOpen={override}
          onCancel={() => {
            setOverride(false);
          }}
          onConfirm={() => {
            setOverride(false);
            handleOverrideConfirm();
          }}
          textContents={{
            headerText: 'Override Default Template',
            body: intl.formatMessage({
              id: 'overrideDefaultTemplateMessage',
            }),
          }}
        />
      </View>
    </Drawer>
  );
};

export default AddOrUpdateSMSTemplates;
