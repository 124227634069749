import { MedicationRequest, RequestGroup } from "fhir/r4";
import { IMlov } from "../../../Interfaces";
import { IFilterTag } from "../../common/ResponsiveTagRender/ResponsiveTagRender";
import { IFormState } from "./PatientNotes/components/DocumentationView";
import { IPatientNotesSelectedFilters } from "./PatientNotes/components/FilterPatientNotesDrawer";
import { IFormComponent, ISelectedAppointment, NoteEntry } from "./PatientNotes/interfaces";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { MembersScreenStackParamList } from "../../../navigation/StackNavigation/MemberScreenStack";
import { ICommonData } from "../../../context/CommonDataContext";
import { ITask } from "../../common/CareDashboard/CareDashboardInterfaces";
import { ILabel } from "../../common/FilterView/interfaces";
import {IContact, IContactProfile} from "../../RightSideContainer/TeamInbox/Conversations/interfaces";

export interface IMiddleContainerProps {
  personData: IPersonData;
  personType: string;
  middleContainerSelectedTabCode: string;
  conversation?: any;
  conversationInbox:any
  unFormattedContactData?: any;
  enableCareProgram?: boolean;
}

export interface ISingleTimeline {
  timelineType: string;
  timelineTitleElem: JSX.Element;
  timelineDate: string;
  timelineComponent?: JSX.Element;
  fhirData?: any;
  displayType?: string;
}

export interface ICarePlanTimeline {
  commonData: ICommonData;
  timelineComponent?: JSX.Element;
  fhirData?: any;
  displayType?: string;
  personData: IPersonData | undefined
  unFormattedContactData?: any;
  ehrConfig: IAvailableEhr
  ehrCapabilities: any
  accountUserList:any
  elationFormData: any;
  isLastIndex?: boolean;
}

export interface ITimelineViewProps {
  mainTimelineTitle: string;
  timelineData: ISingleTimeline[];
  fhirDataList?: Array<any>;
  personData?: IPersonData;
  conversation?: any;
  conversationInbox?:any
  unFormattedContactData?: any;
}

export interface ITabCodes {
  tabCode: string;
  tabTitle: string;
  tabType: string;
}
export interface IQuickAction {
  displayString: string;
  actionCode: string;
  leftIcon?: JSX.Element;
}
export interface IQuickActionProps {
  personType: string;
  onActionClick?: (actionCode: string) => void;
}

export interface ITimelineNoteProps {
  noteColor: string;
  noteText: string;
  onSave?: (note: string) => void;
}

export interface ITimelineFilterProps {
  isOpen: boolean;
  onModalClose: () => void;
}

export interface IPersonData {
  leadId: number;
  firstName: string;
  name: string;
  cityId: string;
  stateId: string;
  zipCodeId: string;
  email: string;
  phone: string;
  emailObj: IEmailObj;
  phoneObj: IEmailObj;
  emailContactTypeId: string;
  phoneContactTypeId: string;
  personAddressId: string;
  cityName: string;
  id: number;
  stateName: string;
  typeId: string;
  zipCode: string;
  patientId?: any;
  patientUuid: string;
  personId: string;
  lastName: string;
  middleName: string;
  genderId: string;
  genderName: string;
  birthDate: string;
  age: string;
  noteId: number;
  personTypeId: string;
  note: string;
  line1: string;
  line2: string;
  contactUUID?: string;
  contactType?: string;
  accountLocationUuid?: string;
  uuid?: string;
  contactData?: IContact;
  contactProfiles?: IContactProfile[];
  contactProfile?: any;
}

export interface IEmailObj {
  personContactId: number;
  value: string;
  id: string;
  personContactType: IPersonContactType;
  typeId: string;
}

export interface IPersonContactType {
  accountId?: any;
  value: string;
  id: string;
  categoryId: string;
}

export interface IMiddleContainerTab {
  tabTitle: string;
  tabCode: string;
}

export interface IPersonTabBarProps {
  selectedTabCode: string;
  onSelectTab: (selectedTabCode: string) => void;
  personType: string;
  contactId?: string;
  locationId?: string;
}


export interface ICareTimelineProps {
  personData?: any;
  unFormattedContactData?: any;
  orderTimelineNoteData?: IPatientNoteCardProps;
  goBackHandler?: () => void;
  navigation?: any
}
export interface IPatientNoteOperationProps {
  isAllowToCreate: boolean;
  isAllowToPrint: boolean;
  isAllowToDelete: boolean;
  isAllowToUnlock: boolean;
  isAllowToDownload: boolean;
  isAllowToEdit: boolean;
  isAllowToAppointmentLink: boolean;
  isAllowToFilterNote: boolean;
  isAllowToAutoSave: boolean;
  isAllowToSign: boolean;
  isAllowToSave: boolean;
}

export enum NoteOperation {
  CREATE = "CREATE",
  PRINT = "PRINT",
  DELETE = "DELETE",
  UNLOCK = "UNLOCK",
  DOWNLOAD = "DOWNLOAD",
  EDIT = "EDIT",
  APPOINTMENT_LINK = "APPOINTMENT_LINK",
  FILTER_NOTE = "FILTER_NOTE",
  AUTO_SAVE = "AUTO_SAVE",
  SIGN = "SIGN",
  SAVE = "SAVE",
  ORDERS = 'ORDERS',
  READ_ONLY = 'READ_ONLY'
}

export interface IAvailableEhr {
  isFold: boolean;
  isElation: boolean;
  isAthena: boolean;
  isCanvas: boolean;
  isECW: boolean;
}

export interface ICareTimelineCategory {
  type: string;
  isTimelineCategoryEnabled: boolean;
}

export interface ICareTimelineViewProps extends  ICareTimelineProps {
   loading?: boolean;
   ehrConfig: IAvailableEhr,
   ehrCapabilities?: any;
   accountUserList?: any[];
   currentUserData?: any;
   noteConfig: {
    flag: IPatientNoteOperationProps;
   }
   timelineCategories: ICareTimelineCategory[];
   defaultTemplate: any;
   soapNote: any;
   header: any;
   footer: any;
   orderTimelineNoteData: IPatientNoteCardProps;
   goBackHandler?: () => void;
   navigation?: any;
   activeAndNonActiveUserList?: any[];
}

export interface ICareTimelinePatientNoteState {
  isFilterModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  isNoteOpen: boolean;
  isNoteLinkWithAppointment: boolean;
  selectedFilters:  IPatientNotesSelectedFilters | undefined;
  todayInprogressNote: any;
  filterCount: number;
  filterTags: IFilterTag[];
  isNewNote: boolean;
  isAddNewLoading: boolean;
}

export interface ICareTimelineListViewState {
  loading: boolean;
  patientNotes: any[];
  currentView: string;
  appointments?: any[];
  latestAppointment?: any;
  selectedNoteCard: IPatientNoteCardProps;
  paginationLoading: boolean,
  noteLabels?: ILabel[],
}

export interface IPatientNoteCardProps {
  status: string;
  authorUuid: string;
  linkedAppointmentId?: string;
  resourceId: string | number;
  createdDate: string;
  formName?: string;
  formId?: string;
  signedByUserId?: string;
  signedDate?: string;
  additionalSections?: string;
  signedByUserName?: string;
  createdByUserName?: string;
  formContent?: IFormComponent[];
  unlockedByUserName?: string;
  unlockedByUserId?: string;
  amendedByUserId?: string;
  description?:string;
  amendedByUserName?: string;
  amendedDate?: string;
  isCreateUserAndLoginUserIsSame: boolean;
  isSignedByUserAndLoginUserIsSame: boolean;
  isAllowToDelete: boolean;
  isAllowToPrint: boolean;
  isSignedDocumentReference?: boolean;
  isAllowToUnlock: boolean;
  isAllowToAutoSave: boolean;
  isAllowToSign: boolean;
  isAllowToSave: boolean;
  isReadOnly: boolean;
  isEncounterNote: boolean;
  encounterType?: string;
  visitName?:string;
  providerName?:string;
  noteRiskScore?: INoteRiskScore[];
  linkTasks?: ITask[];
  contactId?: string;
  allowToShowToPatient?: boolean;
  formLogId?: string;
  formResponseId?: string;
  hideCancel?: boolean;
  isFoldDrivenNote?: boolean;
  profileId?: string;
  isContactActive?: boolean;
  isMultiTenancyNoteReadOnly?: boolean;
  notesAccountLocationId?: string;
  locationGroupID?: string[];
}

export interface INoteRiskScore {
  operation: string;
  name: string;
  score: string;
  state: string;
  groupId?: string
}

export interface INotesFormattedDataProps {
  noteResponse: NoteEntry[];
  ehrConfig: IAvailableEhr;
  loginUserId: string;
  accountUserList: any[];
  elationFormData?: any;
  contextData: ICommonData;
  additionalFlags:{
    foldVisitNoteEnabled:boolean;
    isMultiTenancyEnabled?: boolean;
  };
  linkTasks?: ITask[],
  resourceMapList?: {
    sourceId: string;
    resourceId: string;
  }[];
  activeAndNonActiveUserList?:  any[];
}

export interface IPatientNotesCard {
  cardProps: IPatientNoteCardProps,
  printProcessId?: string;
  ehrCapabilities: any,
  personData: any,
  unFormattedContactData: any,
  header: any;
  footer: any;
  onActionPerformed?: (operationCode: string, selectedNoteCard: IPatientNoteCardProps) => void;
  accountUserList?: any[];
  elationFormData?: any;
  ehrConfig: IAvailableEhr;
  isPhysicianUser: boolean;
  appointmentStatusList?: IMlov[];
  navigation?: any;
  contextData: ICommonData;
}

export interface ISingleNoteViewProps {
  cardProps: IPatientNoteCardProps,
  noteView?: string;
  personData?: any;
  unFormattedContactData?: any;
  ehrConfig: IAvailableEhr;
  ehrCapabilities?: any;
  accountUserList?: any[];
  elationFormData?: any;
  onActionPerformed?: (operationCode: string, selectedNoteCard: IPatientNoteCardProps) => void;
  header: any;
  footer: any;
  goBackHandler?: () => void;
  activeAndNonActiveUserList?: any[];
}

export interface IOrderPrintViewProps {
  title: string;
  orders: RequestGroup[]
};

export interface IErxPrintViewProps {
  title: string;
  orders: MedicationRequest[]
};

export interface INoteAuditTrail {
  id: string;
  resourceId: string;
  patientId: string;
  resourceType: string;
  actionDate: string;
  action: string;
  userId: string;
};

export interface ISignedNoteViewState {
  templateData?: IFormState;
  categoryWiseOrderData?: ICategoryWiseOrder;
  isLoading: boolean;
  formData?: any;
  appointmentData?: any;
  isProcessing: boolean;
  startPrinting: boolean;
  formOptions?: any;
  goBackHandler?: () => void;
  resourceAudit: INoteAuditTrail[];
  auditpopoverVisible: boolean;
  resourceAuditData?: INoteAuditTrail[];
  loadingFormData?: boolean;
  selectedVersion?: INoteAuditTrail;
  currentVersionAuditid?: string;
  printShareable: boolean;
  selectedVersionSignedBy?: {
    signedByUserName?: string;
    signedDate?: string;
    amendedByUserName?: string;
    amendedDate?: string;
  };
};

export interface  ICategoryWiseOrder {
  labTest: [],
  radiology: [],
  medications: [],
};

export interface IPrintPatientNoteProps {
  isDefaultDataAvailable: boolean;
  templateData?: IFormState;
  categoryWiseOrderData?: ICategoryWiseOrder;
  appointmentData?: ISelectedAppointment;
  personData: any;
  unFormattedContactData?: any;
  notesData: IPatientNoteCardProps;
  formOptions: any,
  onRenderComponent: (error?: any) => void;
  ehrConfig: IAvailableEhr;
  printShareable: boolean;
};

export interface IPrintPatientNoteState {
  templateData?: IFormState;
  categoryWiseOrderData?: ICategoryWiseOrder
  patientDemographics: any;
  unFormattedContactData?: any;
  isLoading: boolean;
  signedByDate: string;
  signedByUserName: string;
};

export interface IAddOrUpdateNoteProps {
  personData?: any;
  unFormattedContactData: any;
  documentReferenceId?: string;
  isNewNote: boolean;
  isLinkAppointment: boolean;
  noteData: IPatientNoteCardProps;
  ehrConfig: IAvailableEhr;
  ehrCapabilities: any;
  accountUserList: any[];
  currentUserData?: any;
  isPhysicianUser: boolean;
  onActionPerformed?: (
    operationCode: string,
    selectedNoteCard: IPatientNoteCardProps,
    isUpdate?: boolean,
  ) => void;
  header?: any;
  footer?: any;
  defaultTemplate?: any;
  elationFormData: any;
  hideBottomOrderActionBar?: boolean;
  goBackHandler?: () => void;
  prefillData?: IPatientNotePrefillDataItem[];
  viewType?: ViewType;
  disableDefaultTemplateChange?: boolean;
  onTemplateChangeCallback?: (templateId: string) => void;
  onAddNoteSuccessCallback?: (data:any) => void;
  navigation?: NativeStackNavigationProp<MembersScreenStackParamList, "AddOrUpdateNoteScreen", undefined>
  isClinicalNoteFromMessage?: boolean;
  noteLabels?: ILabel[];
  paddingHorizontal?: number;
  isNewView?: boolean;
}

export interface IPatientNotePrefillDataItem {
  label?: string;
  key: string;
  data: any;
}

export enum ViewType {
  DRAWER = "drawer",
  INLINE = "inline",
}

export interface IAction {
  btnText: string;
  icon: JSX.Element;
  action: string;
  isDisabled: boolean;
}