import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ElementsType, FormElement, FormElementInstance, SubmitFunction, ValidationFunction, ValidationResult } from '../FormComponents/FormComponents';
import { getUniqueKey } from '../CustomFormEngineUtils';
import { MedicationsSchema } from '../Schema/ComponentsSchema';
import { cloneDeep, debounce } from 'lodash';
import Description from '../BaseComponents/Description';
import AddOrUpdateMedications from '../../FHFormio/CustomComponents/Medications/AddOrUpdateMedications/AddOrUpdateMedications';
import { useFormRendererContext } from '../Context/FormRenderer.context';
import { CustomFormBuilderActionTypes } from '../CustomFormEngineInterfaces';
import { useCustomFormBuilderContext } from '../Context/CustomFormBuilder.context';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import { usePropertiesFormRenderer } from '../Hooks/usePropertiesFormRenderer';
import { FormRenderer } from '../FormRenderer';
import { CapabilityResource } from '../../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { CustomComponentKey, getComponentField, getFieldDisplayName, isRequiredField } from '../../FHFormio/CustomComponents/CustomComponentUtils';
import { IEhrCapability } from '../../../../../Interfaces';
import { IMedicationComponentValue } from '../../FHFormio/CustomComponents/Medications/interfaces';
import { FormRendererActionType } from '../Context/FormRendererReducer';
import MedicationsSvg from '../../../../../assets/Icons/FormBuilder/MedicationsSvg';
import { v4 as uuidV4 } from 'uuid';

export const MedicationFieldFormElement: FormElement = {
  type: MedicationsSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(MedicationsSchema),
    key: getUniqueKey(map, MedicationsSchema.key),
  }),
  designerBtnElement: {
    icon: MedicationsSvg,
    label: MedicationsSchema.label || '',
  },
  propertiesComponent: PropertiesComponent,
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(MedicationsSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
};

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  disabled,
  validationRef,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: IMedicationComponentValue;
  onChange: (value: IMedicationComponentValue) => void;
  errorMessage?: string;
  disabled?: boolean;
  validationRef?: React.MutableRefObject<any>;
}) {
  const { label, validate, description, tooltip, hideLabel } = elementInstance;

  return (
    <div className="flex flex-col gap-1 w-full">
      {/* {!hideLabel && (
        <Label
          label={label || ''}
          isRequired={validate?.required}
          tooltip={tooltip}
          isReadOnly={disabled}
        />
      )} */}
      <AddOrUpdateMedications
        component={{
          ...elementInstance,
          selectedValue: defaultValue,
        }}
        disabled={disabled || false}
        options={{}}
        validateRef={validationRef || {}}
        setValueRef={{}}
        value={defaultValue}
        onChange={onChange}
      />
      {description && <Description description={description} />}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const [value, setValue] = useState<IMedicationComponentValue>();
  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={setValue}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: IMedicationComponentValue;
  isReadOnly?: boolean;
  onRegisterValidation?: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<IMedicationComponentValue | undefined>(defaultValue || selectedValue);
  const validationRef = useRef<any>(undefined);
  const isMounted = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: IMedicationComponentValue, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      const result = await validationRef.current?.(valueToValidate, silentCheck);
      return {
        isValid: result?.isValid ?? true,
        errorMessage: result?.message || '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key]);

  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: IMedicationComponentValue) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: IMedicationComponentValue) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      disabled={isReadOnly}
      validationRef={validationRef}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, ehrCapabilities, userSettings } = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance;

  const options = useMemo(() => {
    const capabilities: IEhrCapability[] = ehrCapabilities || [];
    const capability = capabilities.find((capability) => capability.resourceName == CapabilityResource.medicationStatement);
    const keyAllowedOperations = capability?.abilities?.keyAllowedOperations || {};
    const commField = getComponentField(CustomComponentKey.MEDICATION, keyAllowedOperations);
    const finalFieldList = commField.map((field) => {
      return {
        label: getFieldDisplayName(CustomComponentKey.MEDICATION, field, keyAllowedOperations),
        value: field,
        disabled: isRequiredField(CustomComponentKey.MEDICATION, field, keyAllowedOperations),
      };
    });
    return finalFieldList;
  }, [ehrCapabilities]);

  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    ...(isAllowShare ? ShareWithPatientFields : []),
    {
      type: 'checkBoxes',
      key: 'enabledFields',
      label: 'Select Medication Field',
      input: true,
      validate: {
        required: true,
      },
      dataSrc: 'values',
      data: {
        values: options,
      },
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [options, isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData, element]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default MedicationFieldFormElement;
