import React from 'react'
import { HStack, Skeleton, Spacer, Text } from 'native-base'
import CustomViewButton from '../../PersonOmniView/LeftContainer/RecentActivity/PatientReportedView/components/CustomViewButton'
import LoadingIndicator from '../../PersonOmniView/LeftContainer/RecentActivity/PatientReportedView/components/LoadingIndicator';
import { testID } from '../../../testUtils';

interface IRecentReportedViewHeaderProps {
  title: string;
  buttonOnPress: () => void;
  loading: boolean,
  noData: boolean
}

const RecentReportedViewHeader = (props: IRecentReportedViewHeaderProps) => {
  if (props.loading) {
    return <LoadingIndicator />
  }
  if (!props.loading && props.noData) {
    return <></>;
  }
  return (
    <HStack px={4} alignItems="center" mb={props.noData ? undefined : 2}>
      <Text fontSize={15} {...testID(`PatientReported${props.title}`)}>Patient Reported {props.title}</Text>
      <Spacer />
      <CustomViewButton onPress={props.buttonOnPress} />
    </HStack>
  );
};

export default RecentReportedViewHeader