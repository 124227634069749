import React, {useState} from 'react';
import {
  HStack,
  IconButton,
  Select,
  Text,
  Tooltip,
  // View,
  VStack,
} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import {DatePicker} from 'antd';

import {styles} from './TimelineTaskStyles';
import {Colors} from '../../../../styles';
import {ReminderConst} from '../TimelineConsts';
import './TimelineTaskCss.css';

const {Item} = Select;

const antdDatePickerStyle: React.CSSProperties = {
  height: 30,
};

export interface ITimelineTaskProps {
  isTaskComplete: boolean;
  taskTitle: string;
  taskDueDate: string;
  assignToList: string[];
}

const TimelineTask = (props: ITimelineTaskProps) => {
  const {isTaskComplete, taskTitle, assignToList} = props;
  const [taskStatus, setTaskStatus] = useState(isTaskComplete);

  const handleCheckBtnClick = () => {
    setTaskStatus((prev) => !prev);
  };

  return (
    <div className="timeline-task-container">
    <VStack>
      <HStack>
        <HStack style={styles.taskTitleStack}>
          <Tooltip
            placement="top"
            label={`Mark as ${taskStatus ? 'Incomplete' : 'Complete'}`}
          >
            <IconButton
              onPress={() => handleCheckBtnClick()}
              style={styles.checkBtn}
              width={'fit-content'}
              _hover={{backgroundColor: 'transparent'}}
              icon={
                <Icon
                  name="check"
                  color={taskStatus ? 'white' : Colors.Custom.BackgroundColor}
                  style={[
                    styles.checkBtnIcon,
                    {
                      backgroundColor: taskStatus
                        ? Colors.Custom.SuccessColor
                        : Colors.Custom.IconColorWhite,
                      borderWidth: 0.5,
                      borderColor: taskStatus
                        ? '#3ccc38'
                        : Colors.Custom.BackgroundColor,
                    },
                  ]}
                />
              }
            />
          </Tooltip>
          <Text
            strikeThrough={taskStatus ? true : false}
            style={styles.taskTitleText}
          >
            {taskTitle}
          </Text>
        </HStack>
      </HStack>

      {/* selector components */}
      <HStack style={styles.alignCenter}>
        {/* to assign a task */}
        <VStack style={styles.ml10}>
          <Text style={styles.headerText}>ASSIGNED TO</Text>
          <Select
            placeholder="Assign to"
            style={styles.selectContainer}
            onValueChange={(value) => {}}
          >
            {assignToList.map((assignTo, index) => {
              return <Item key={index} value={assignTo} label={assignTo} />;
            })}
          </Select>
        </VStack>

        {/* to select a due date */}
        <VStack style={styles.ml10}>
          <Text style={styles.headerText}>DUE DATE</Text>
          <DatePicker style={antdDatePickerStyle} />
        </VStack>

        {/* to set the reminder */}
        <VStack style={styles.ml10}>
          <Text style={styles.headerText}>SET REMINDER</Text>
          <Select
            placeholder="SET REMINDER"
            style={styles.selectContainer}
            onValueChange={(value) => {}}
          >
            {ReminderConst.map((reminder, index) => {
              return (
                <Item
                  value={reminder.reminderValue}
                  label={reminder.reminderLabel}
                  key={index}
                />
              );
            })}
          </Select>
        </VStack>
      </HStack>
    </VStack>
    </div>
  );
};

export default TimelineTask;
