import React from 'react';
import {Path, Svg} from 'react-native-svg';

const NumberSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        fill="#F5F0FF"
      />
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        stroke="#D7C0FF"
        strokeWidth="0.5"
      />
      <Path
        d="M10.1667 14.7669L11 14.3502V18.0517M10.1667 18.1002H11.8333M13.5 14.7669C13.9167 13.9335 15.5833 14.3502 15.5833 15.6002C15.5833 16.4335 14.3333 17.4058 13.5 18.1002H15.5833M17.25 14.7669C17.6667 14.3502 19.3333 13.9335 19.3333 15.1835C19.3333 16.1151 17.6667 16.4335 17.6667 16.4335C17.6667 16.4335 19.3333 16.1435 19.3333 17.2669C19.3333 18.5169 17.25 18.1002 17.25 18.1002M21.5907 13.5H20.6816M21.5907 13.5H22.4998M21.5907 13.5V18.5M21.5907 18.5H20.6816M21.5907 18.5H22.4998M14.3333 22.6666H17.6667C20.8094 22.6666 22.3807 22.6666 23.357 21.6903C24.3333 20.714 24.3333 19.1427 24.3333 16C24.3333 12.8573 24.3333 11.2859 23.357 10.3096C22.3807 9.33331 20.8094 9.33331 17.6667 9.33331H14.3333C11.1906 9.33331 9.61928 9.33331 8.64297 10.3096C7.66666 11.2859 7.66666 12.8573 7.66666 16C7.66666 19.1427 7.66666 20.714 8.64297 21.6903C9.61928 22.6666 11.1906 22.6666 14.3333 22.6666Z"
        stroke="#8C5AE2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default React.memo(NumberSvg);
