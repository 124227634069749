import {useContext} from "react";
import {CommonDataContext, ICommonData} from "../../context/CommonDataContext";
import {getAccountLocationUuidFromRolesResp, getResorceCode} from "../../screens/MainScreen/utils";
import {ACCESS_LEVEL} from "../../constants";
import {isLoggedInUserGlobalAdmin, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from "../../utils/commonUtils";
import { CONFIG_CODES, ON_OFF_CONFIG_VALUE } from "../../constants/AccountConfigConst";
import { permitState } from '../../lib/permitio-wrapper';


export const usePermissions = (commonDataObject?: ICommonData) => {
  const commonData = commonDataObject ?? useContext(CommonDataContext)
  const userPracticeLocations = commonData.userPracticeLocations || []
  const userAccountLocationUuids = commonData.userAccountLocationUuids
  const accountLocationIds = userAccountLocationUuids?.length
    ? userAccountLocationUuids
    : getAccountLocationUuidFromRolesResp([], userPracticeLocations);
  const allAccountLocationIds = commonData.accountLocationListWithEHR?.map((location)=>location.uuid) || [];
  const allPracticeLocationUuids = commonData.accountLocationListWithEHR?.map((location)=>location?.practiceLocation?.uuid) || [];
  const allLocationGroupIds = [...new Set(commonData.accountLocationListWithEHR?.map((location)=>location?.locationGroupId)?.filter((item)=>!!item) || [])];

  const isAllowedAllLocation =
    isLoginUserBusinessOwner() ||
    isLoggedInUserGlobalAdmin() ||
    isLoggedInUserWorkFlowOrCustomerSuccess();

  const check = (
    resource: string,
    action: string,
    locationId?: string
  ) => {
    let accessLevel = 'N/A';
    const allowedLocationIds = [];
    const allowedPracticeLocationUuids: string[] = [];
    let hasGlobalAccess = false;

    const locations = locationId ? [locationId] : [...accountLocationIds, 'null'];
    const isAllowedAllLocationForAction = isAllowedAllLocation || (isP360DateReadAccessEnabledForAllUsers(commonData) && action?.startsWith('P360_'));
    if (!isP360DateReadAccessEnabledForAllUsers(commonData) && action && action?.startsWith('P360_')) {
      action = action.replace('P360_', '');
    }

    for (const location of locations) {
      const currentResource =  getResorceCode({
        actionCode: action,
        accountLocationId: location,
        sectionCode: resource
      })
      const hasAccess = permitState?.check(action, currentResource, {});
      if (hasAccess) {
        if(location !== 'null') {
          allowedLocationIds.push(location);
        }

        if (location === 'null') {
          hasGlobalAccess = true;
        }
      }
    }
    const allowedLocationGroupIdsSet = new Set<string>();

    for (const practiceLocation of userPracticeLocations) {
      const currentResource =  getResorceCode({
        actionCode: action,
        accountLocationId: practiceLocation?.accountLocations?.[0]?.uuid,
        sectionCode: resource
      })
      const hasAccess = permitState?.check(action, currentResource, {});
      if (hasAccess) {
        if (practiceLocation?.accountLocations[0]?.practiceLocation?.uuid) {
          allowedPracticeLocationUuids.push(practiceLocation?.accountLocations[0]?.practiceLocation?.uuid);
        }
        const locationGroupId = practiceLocation?.accountLocations[0]?.locationGroupId;
        if (locationGroupId) {
          allowedLocationGroupIdsSet.add(locationGroupId);
        }
      }
    }

    const allowedLocationGroupIds: string[] = [...new Set(allowedLocationGroupIdsSet)];

    if (hasGlobalAccess) {
      accessLevel = ACCESS_LEVEL.GLOBAL;
    } else if (allowedLocationIds.length > 0) {
      accessLevel = ACCESS_LEVEL.LOCATION;
    }
    return {
      hasAccess: isAllowedAllLocationForAction ? true : allowedLocationIds.length > 0 || hasGlobalAccess ? true : false ,
      accessLevel: accessLevel,
      allowedLocationIds: isAllowedAllLocationForAction ? allAccountLocationIds : allowedLocationIds,
      allowedPracticeLocationUuids: isAllowedAllLocationForAction ? allPracticeLocationUuids : allowedPracticeLocationUuids,
      allowedLocationGroupIds: isAllowedAllLocationForAction ? allLocationGroupIds : allowedLocationGroupIds,
    };
  };

  return { check };
};

function isP360DateReadAccessEnabledForAllUsers(commonData: ICommonData) {
  const accountConfigData = commonData?.accountConfigData;
  const p360DateReadAccessConfig = accountConfigData?.[CONFIG_CODES.P360_DATA_READ_ACCESS_ENABLED_FOR_ALL_USERS];
  return p360DateReadAccessConfig?.accountConfigurations?.[0]?.value === ON_OFF_CONFIG_VALUE.ON;
}
