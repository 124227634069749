import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../styles/Colors';

export const antdStyles: Record<string, React.CSSProperties> = {
  drawerStyle: {
    padding: 0,
  },
  popoverStyle: {
    padding: 0,
    borderRadius: 8,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  popoverStyleOverlay: {
    padding: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  popoverStyleContent: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    borderRadius: 8,
    width: 130,
  },
  formulaContainer: {
    marginRight: 12,
  },
};

export const webStyles = StyleSheet.create({
  width78height24: {
    width: 78,
    height: 24,
  },
  box24: {
    width: 24,
    height: 24,
  },
  emptyScoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyScoreTitle: {
    marginTop: 16,
    fontWeight: '800',
    fontSize: 18,
    width: 192,
    height: 30,
  },
  emptyScoreText: {
    textAlign: 'center',
    fontWeight: '400',
    fontSize: 14,
    width: 210,
    height: 40,
    color: Colors.Custom.Gray500,
  },
});

export const nativeStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  sideContainer: {
    flex: 0.3,
    borderRightWidth: 1,
    borderColor: Colors.Custom.Gray200,
  },
  middleContainer: {
    flex: 0.4,
    borderRightWidth: 1,
    borderColor: Colors.Custom.Gray200,
  },
  resultContainer: {
    flex: 0.3,
    borderColor: Colors.Custom.Gray200,
  },
  headerContainer: {
    padding: 16,
  },
  headerText: {
    fontSize: 16,
    fontWeight: '700',
    paddingLeft: 4,
  },
  scoreRow: {
    marginVertical: 4,
    marginLeft: 4,
    paddingLeft: 4,
    flex: 1,
  },
  scoreInputContainer: {
    flex: 0.49,
  },
  scoreNameContainer: {
    flex: 0.49,
  },
  scoreNameInput: {
    width: '85%',
  },
  deleteButton: {
    marginTop: 4,
    width: 24,
    height: 24,
    backgroundColor: '#FDFAFF',
  },
  resultCard: {
    margin: 8,
    paddingBottom: 8,
    backgroundColor: Colors.Custom.ContainerBGColor,
    alignSelf: 'stretch',
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
  },
  resultCardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultCardTitle: {
    fontSize: 14,
    fontWeight: '600',
    paddingTop: 8,
    paddingBottom: 8,
    marginLeft: 8,
    paddingLeft: 4,
  },
  headerRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
