import {HStack, Pressable, Text, View} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {useEffect, useState} from 'react';
import {Colors} from '../../../../../styles';
import {SingleEmployer, UserData} from '../InviteUserDrawer/interfaces';
import AddAccountRoleView from './AddAccountRoleView';
import {ACCOUNTS_AND_ROLES_ACTION_CONST} from '../../../../../constants';
import {IAccountRole} from './interface';
import {getEmployerRoles, getFilteredLocations, getUserAccountRoleId, isAddPracticeBtnActive, processRoles, validAccountUserLocation} from './utils';
import {IMlov} from '../../../../../Interfaces';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {useLazyQuery} from '@apollo/client';
import TeamQueries from '../../../../../services/Team/TeamQueries';
import {getFormattedAccountLocationList} from '../AccountLocations/utils';
import {ILocationData} from '../AccountLocations/interface';
import permissionQueries from '../../../UserAccess/permissionQueries';
import {Skeleton} from 'antd';
import {MLOV_CATEGORY, USER_ROLE_CODES} from '../../../../../constants/MlovConst';
import { IUserRoleMlov } from '../AddEditUser/interfaces';
import { ModalActionSubTitle } from '../../../../common/ModalActionTitle/ModalActionSubTitle';
import {ModalActionAntSelect} from '../../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import {StyleSheet} from 'react-native';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import { getMlovIdFromCodeAndCategory } from '../../../../../utils/mlovUtils';
import EmployerQueries from '../../../../../services/Employer/EmployerQueries';
interface IAssociatedAccountView {
  userData: UserData;
  onActionPerformed?: (code: string, data?: IAccountRole[] | string[] | any) => void;
  isDisabled: boolean;
  associatedAccounts?: IAccountRole[];
  isEditMode?: boolean;
  selectedGlobalRoles: string[];
  errors?: any;
  selectedEmplyerId?: string;
}

const styles = StyleSheet.create({
  positionRelative: {
    position: 'relative',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    width: 1.5,
    // backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
    height: 20,
  },
  addLocationHStack: {
    justifyContent: 'flex-start',
  },
  addLocationHStack2: {
    justifyContent: 'flex-start',
  },
});

const AssociatedAccountView = (props: IAssociatedAccountView) => {
  const { onActionPerformed, isDisabled, associatedAccounts, isEditMode, selectedGlobalRoles, errors, selectedEmplyerId} =
    props;

  const accountUuid = getAccountUUID();
  const [count, setCount] = useState(2);
  const employerId = getMlovIdFromCodeAndCategory(USER_ROLE_CODES.EMPLOYER,MLOV_CATEGORY.USER_ROLES,false)
  const [accountUserRoleList, setAccountUserRoleList] = useState([
    {
      id: 1,
      editMode: false,
      externalUserId: '',
      rolesToAdd: [] as string[],
      isDeleted: false,
      roles: [] as string[],
      editedRoles: [] as string[],
      ...(isEditMode ? {
        editedRoles: [] as string[],
        rolesToDelete: [] as string[],
        isDelete: false
      } : {}),
      locationUuid: '',
      locationId: -1,
      locationGroupId: '',
      accountUuid: '',
      accountId: -1,
    },
  ] as IAccountRole[]);
  const [stateData, setStateData] = useState({
    loading: false,
    roleLoading: false,
    pageSize: 10,
    offset: 0,
    currentPage: 1,
    searchString: '',
    accountLocation: [] as ILocationData[],
    userRoles: [] as IUserRoleMlov[],
    globalRoles: [] as IUserRoleMlov[],
    selectedGlobalRoles: []  as string[],
    selectedUserRoles: getUserAccountRoleId(associatedAccounts || []),
    employerList: [] as SingleEmployer[],
    selectedEmplyerId: '',
    selectedEmplyerName: '',
  })
  const [getLocations] = useLazyQuery(TeamQueries.getAccountLocationForUser, {
    fetchPolicy: 'no-cache',
  });
  const [getAccountRoles] = useLazyQuery(
    permissionQueries.getAccountRoles,{
      fetchPolicy: 'no-cache',
    }
  );
  const [getEmployers] = useLazyQuery(EmployerQueries.employersSearch, {
    fetchPolicy: 'no-cache',
    variables: {
      accountUuid: accountUuid,
      query: '%%',
    },
  });
  const addPractice = () => {
    const singlePracticeObj = {
      id: count,
      accountUuid: '',
      accountId: 0,
      externalUserId: '',
      rolesToAdd: accountUserRoleList[0].rolesToAdd as string[],
      rolesToDelete: [] as string[],
      roles: [] as string[],
      editedRoles: [] as string[],
      locationUuid: '',
      locationId: 0,
      locationGroupId: '',
      isDeleted: false
    };
    setCount((prv) => prv + 1);
    setAccountUserRoleList([...accountUserRoleList, singlePracticeObj]);
    onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,[...accountUserRoleList, singlePracticeObj]);
  };
  const isGlobalRolesFieldRequired = stateData.selectedUserRoles.length > 0 || !!accountUserRoleList?.[0]?.locationId;

  const removePractice = (locationRole: IAccountRole) => {
    let updatedList: IAccountRole[] = []
    if (isEditMode && locationRole.editMode) {
      updatedList =  accountUserRoleList.map((item) => {
        if (item.id == locationRole.id) {
          return {...item, isDeleted: true}
        }
        return {...item}
      });
    } else {
      updatedList =  accountUserRoleList.filter((item) => item.id !== locationRole.id);
    }
    setAccountUserRoleList(updatedList);
    onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,updatedList);
  };
  const addUpdateFieldValue = (index: number, code: string, value: any) => {
    const locationRolesList  = [...accountUserRoleList]
    let selectedUserRoleObj = {...locationRolesList[index]};
    switch (code) {
      case ACCOUNTS_AND_ROLES_ACTION_CONST.PRACTICE_NAME:
        if (value.locationUuid) {
          const externalUserId =
            accountUserRoleList.find(
              (item) => item.locationGroupId === value.locationGroupId
            )?.externalUserId || '';
          selectedUserRoleObj.locationId = value.locationId
          selectedUserRoleObj.locationUuid = value.locationUuid
          selectedUserRoleObj.locationGroupId = value.locationGroupId
          selectedUserRoleObj.externalUserId = externalUserId
          {/* /TEMPORARY PLACED THIS: WILL REMOVE IN SEPTEMBER RELEASE/ */}
          selectedUserRoleObj.rolesToAdd = stateData.selectedUserRoles
          selectedUserRoleObj.editedRoles = stateData.selectedUserRoles
          locationRolesList[index] = selectedUserRoleObj;
        } else {
          locationRolesList[index] =  {}
        }
        setAccountUserRoleList([...locationRolesList]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,locationRolesList);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.EHR_USER:
        let updatelocationEhr = locationRolesList;
        if (!value?.externalUserId) {
          selectedUserRoleObj.externalUserId = '';
          updatelocationEhr[index] = selectedUserRoleObj
        } else {
          updatelocationEhr = locationRolesList.map((locationRole) => {
            return locationRole.locationGroupId === value.locationGroupId
              ? {...locationRole, externalUserId: value.externalUserId}
              : {...locationRole};
          });
        }
        setAccountUserRoleList([...updatelocationEhr]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,updatelocationEhr);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.ROLES:
        selectedUserRoleObj = {...locationRolesList[index]};
        selectedUserRoleObj.rolesToAdd = value.rolesToAdd;
        selectedUserRoleObj.editedRoles = value.rolesToAdd;
        locationRolesList[index] = selectedUserRoleObj;
        setAccountUserRoleList([...locationRolesList]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,locationRolesList);
        break;
      case ACCOUNTS_AND_ROLES_ACTION_CONST.UPDATE_ALL_LOCATION_ROLES:
        {/* /TEMPORARY PLACED CASE: WILL REMOVE IN SEPTEMBER RELEASE/ */}
        const updateLocationRoleList = locationRolesList.map((locationRole)=> {
          return {
            ...locationRole,
            rolesToAdd: value,
            editedRoles: value,
          } as IAccountRole
        })
        setAccountUserRoleList([...updateLocationRoleList]);
        onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,updateLocationRoleList);
        break;
      default:
        break;
    }
  };
  const getLocationDataByAccount = async (offSet?: number) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const locationData = await getLocations({
      variables: {
        accountUuid: accountUuid
      },
    });
    if (locationData?.data?.accountLocations?.length) {
      const formattedData = getFormattedAccountLocationList(
        locationData?.data?.accountLocations
      );
      setStateData(prev=> {
        return {
          ...prev,
          accountLocation: formattedData,
          loading: false
        }
      })
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          accountLocation: [],
          loading: false,
        };
      });
    }
  };
  
  const getAccountRolesFunc = async () => {
    setStateData(prev => ({
      ...prev,
      roleLoading: true,
    }));

    const globalUserRolesResponse = await  getAccountRoles({
      variables: {
        category: [MLOV_CATEGORY.GLOBAL_USER_ROLES]
      }
    })
    const userRolesResponse = await  getAccountRoles({
      variables: {
        category: [MLOV_CATEGORY.USER_ROLES]
      }
    })

    let userRoles: IUserRoleMlov[] = [];
    let globalRoles: IUserRoleMlov[] = [];
  
    if (userRolesResponse.data?.accountRoles?.length) {
      userRoles = processRoles(userRolesResponse.data.accountRoles, USER_ROLE_CODES.EMPLOYER);
    }
    
    if (globalUserRolesResponse.data?.accountRoles?.length) {
      globalRoles = processRoles(globalUserRolesResponse.data.accountRoles, USER_ROLE_CODES.EMPLOYER);
    }

    const employerRoles = getEmployerRoles(userRolesResponse.data.accountRoles);
    const finalGlobalRoles = [...globalRoles, ...employerRoles];

    setStateData(prev => ({
      ...prev,
      userRoles,
      globalRoles: finalGlobalRoles,
      employerRoles,
      roleLoading: false
    }));
  };

  useEffect(() => {
    setStateData(prev=> {
      return {
        ...prev,
        selectedGlobalRoles: selectedGlobalRoles || [],
        selectedEmplyerId: selectedEmplyerId || ''
      }
    })
    onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.GLOBAL_USER_ROLES,selectedGlobalRoles);
    if (associatedAccounts?.length) {
      setCount(associatedAccounts?.length + 1);
      setAccountUserRoleList(() => {
        return [...associatedAccounts];
      });
      setStateData(prev=> {
        return {
          ...prev,
          selectedGlobalRoles: selectedGlobalRoles || [],
          selectedEmplyerId: selectedEmplyerId || ''
        }
      })
      onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,associatedAccounts);
    } else {
      setAccountUserRoleList([
        {
          id: 1,
          accountUuid: '',
          accountId: 0,
          externalUserId: '',
          rolesToAdd: [] as string[],
          rolesToDelete: [] as string[],
          roles: [] as string[],
          editedRoles: [] as string[],
          locationUuid: '',
          locationId: 0,
          isDeleted: false
        },
      ]);
      setCount(2);
    }
  }, [associatedAccounts?.length]);

  useEffect(()=> {
    getLocationDataByAccount();
    getAccountRolesFunc();
    getEmployersList();
  },[])
  
  useEffect(() => {
    if (!stateData.selectedUserRoles.length) {
      const updateAssociatedAccounts = associatedAccounts?.map(
        (locationRoles) => {
          return {
            ...locationRoles,
            isDeleted: true,
          };
        }
      );
      onActionPerformed?.(
        ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE,
        updateAssociatedAccounts
      );
    }
  }, [stateData.selectedUserRoles.length]);

  const getEmployersList = async () => {
    try {
      const employersResponse = await getEmployers();
      setStateData(prev=> {
        return {
          ...prev,
          employerList: employersResponse?.data?.employers
        }
      })
    } catch (error) {
    }
  }

  const showEmployerSelection = (selectedAdminRolesValue?: string[]) => {
    const selectedAdminRoles = selectedAdminRolesValue || stateData.selectedGlobalRoles;
    if (selectedAdminRoles.length) {
      for (const roleId of selectedAdminRoles) {
        const role = stateData.globalRoles.find((role) => role.id === roleId);
        if (role?.roleId === employerId) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const findEmployerRoleData = (selectedAdminRoles: string[]) => {
    if (selectedAdminRoles?.length) {
      for (const roleId of selectedAdminRoles) {
        const role = stateData.globalRoles.find((role) => role.id === roleId);
        if (role?.roleId === employerId) {
          return role;
        }
      }
    }
    return null;
  };

  return (
    <View>
      {!stateData.loading && !stateData.roleLoading && stateData.userRoles?.length > 0 && 
        (<><ModalActionAntSelect
              //mode="multiple"
              isRequired={!isGlobalRolesFieldRequired}
              value={stateData?.selectedGlobalRoles?.[0]}
              allowClear={true}
              label={'globalRoles'}
              placeholder={'Assign Administrative roles'}
              errors={errors?.userRoleRequiered}
              errorText={errors?.userRoleRequiered}
              onClear={() => {
              setStateData(prev=> {
                  return {
                    ...prev,
                  selectedGlobalRoles: [],
                  selectedEmplyerId: ''
                }
              })
              }}
              onChange={(value: any) => {
              const selectedGlobalRolesValue = [value];
              setStateData(prev=> {
                  return {
                    ...prev,
                  selectedGlobalRoles: selectedGlobalRolesValue
                }
              })
              onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.GLOBAL_USER_ROLES,selectedGlobalRolesValue);
              //need employer mlov data
              const employerRoleData = findEmployerRoleData(selectedGlobalRolesValue) || {} as IUserRoleMlov;
              onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.EMPLOYER_MLOV_DATA, employerRoleData);
              if (employerRoleData?.id) {
                setAccountUserRoleList([
                  {
                    id: 1,
                    editMode: false,
                    externalUserId: '',
                    rolesToAdd: [] as string[],
                    isDeleted: false,
                    roles: [] as string[],
                    editedRoles: [] as string[],
                    ...(isEditMode ? {
                      editedRoles: [] as string[],
                      rolesToDelete: [] as string[],
                      isDelete: false
                    } : {}),
                    locationUuid: '',
                    locationId: -1,
                    locationGroupId: '',
                    accountUuid: '',
                    accountId: -1
                  },
                ]);
                setStateData((prev) => {
                  return {
                    ...prev,
                    selectedUserRoles: getUserAccountRoleId(associatedAccounts || []),
                  };
                });
                onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.LOCATION_AND_ROLE, []);
              } else {
                setStateData((prev) => {
                  return {
                    ...prev,
                    selectedEmplyerId: '',
                    selectedEmplyerName: ''
                  };
                });
                onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.EMPLOYER_ROLE_DATA, ['']);
              }
              }}
              filterOption={(input: string, option: any) => {
                return (option?.children || '')
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              data={stateData.globalRoles}
              optionProps={{key: 'id', value: 'id', label: 'value'}}
            extraStyle={{flex: 1,
                fontColor: Colors.FoldPixel.GRAY250,
                fontWeight: 400,
                fontSize: 14,
              }}
            />
            {/* /TEMPORARY PLACED WILL REMOVE IN SEPTEMBER RELEASE/ */}
            {!showEmployerSelection() && (
              <ModalActionAntSelect // here
                marginTop={2}
                value={stateData.selectedUserRoles}
              mode="multiple"
        labelInfoView={<DisplayText
                  textLocalId="clinalRolesInfo"
                  size="exsSemibold"
                  extraStyles={{
                    color: Colors.Custom.Gray500,
                    marginBottom: 6,
                  }}
        />}
        isRequired={stateData.selectedGlobalRoles.length > 0 ? false : true}
              errors={errors?.userRoleRequiered}
              errorText={errors?.userRoleRequiered}
              disabled={isDisabled}
              allowClear={true}
              label={'clinicalOperationalRoles'}
              placeholder={'Assign Clinical & Operational roles'}
              onChange={(value: any) => {
          setStateData(prev=> {
                  return {
                    ...prev,
              selectedUserRoles: value
            }
          })
                addUpdateFieldValue(
                  -1,
                  ACCOUNTS_AND_ROLES_ACTION_CONST.UPDATE_ALL_LOCATION_ROLES,
                  value
                );
              }}
              filterOption={(input: string, option: any) => {
                return (option?.children || '')
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              data={stateData?.userRoles}
              optionProps={{key: 'id', value: 'id', label: 'value'}}
              extraStyle={{
                flex: 1,
                fontColor: Colors.FoldPixel.GRAY250,
                fontWeight: 400,
          fontSize: 14}}
            />
            )}
          </>
      )
    }
    {!showEmployerSelection() && stateData.selectedUserRoles.length > 0 && (<>
      <ModalActionSubTitle marginBottom={5} marginTop={5} hideDivider={true} subTitle={'Locations'} />
          {!stateData.loading &&
            !stateData.roleLoading &&
            stateData.userRoles?.length > 0 &&
            accountUserRoleList?.map(
              (singleAccountRoleObj: IAccountRole, index) => {
                if (singleAccountRoleObj?.isDeleted && isEditMode) {
                  return <></>;
                }
                return (
                  <View
                    key={`${singleAccountRoleObj.locationUuid}_${index}`}
                    style={styles.positionRelative}
                  >
                    <Pressable
                      onPress={() => {
                        removePractice(singleAccountRoleObj);
                      }}
                      style={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        zIndex: 99,
                display: (!props.isDisabled && accountUserRoleList.length > 1) || isEditMode  ? 'flex' : 'none',
                      }}
                    >
              <View
                style={styles.flexRow}
              >
                        <View
                          style={styles.separator}
                          backgroundColor={Colors.Custom.Gray200}
                        />
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} />
                      </View>
                    </Pressable>
                    <AddAccountRoleView
                      globalRoles={stateData.selectedGlobalRoles}
              userRoles={ stateData.userRoles}
                      isEditMode={isEditMode}
                      isDisabled={isDisabled}
                      accountUserRoleList={accountUserRoleList}
                      accountLocations={getFilteredLocations({
                        locationUuid: singleAccountRoleObj.locationUuid,
                        masterLocation: stateData.accountLocation,
                locationAndRolesList: accountUserRoleList
                      })}
                      addUpdateFieldValue={(code: string, data?: any) => {
                        addUpdateFieldValue(index, code, data);
                      }}
                      singleAccountRoleObj={singleAccountRoleObj}
                    />
                  </View>
                );
      })}
          <Pressable
            onPress={() => {
              addPractice();
            }}
            display={
          (stateData.loading || stateData?.roleLoading) ||
              isDisabled ||
          ( validAccountUserLocation(accountUserRoleList).length === stateData.accountLocation.length) ||
              !isAddPracticeBtnActive(accountUserRoleList)
                ? 'none'
                : 'flex'
            }
          >
        <HStack
          style={styles.addLocationHStack2}
        >
          <AntIcon name="plus" size={15} color={Colors.FoldPixel.PRIMARY300} />
          <Text size={'smRegular'} color={Colors.FoldPixel.PRIMARY300}>{'Add Location'}</Text>
            </HStack>
          </Pressable>
      {(stateData.loading || stateData?.roleLoading) && <Skeleton></Skeleton>}
      </>)}

      {showEmployerSelection() && (
        <ModalActionAntSelect
          marginTop={2}
          value={stateData?.selectedEmplyerId || undefined}
          isRequired={true}
          errors={errors?.employerId}
          errorText={errors?.employerId}
          allowClear={true}
          label={'employer'}
          placeholder={'Select employer'}
          onChange={(value: any) => {
            const employerName = stateData.employerList?.filter((employer) => {
              return employer?.id === value;
            });
            if (props.userData?.id === -1) {
              setStateData((prev) => {
                return {
                  ...prev,
                  selectedEmplyerId: value,
                  selectedEmplyerName: employerName[0]?.name,
                };
              });
              onActionPerformed?.(ACCOUNTS_AND_ROLES_ACTION_CONST.EMPLOYER_ROLE_DATA, [value]);
            }
          }}
          filterOption={(input: string, option: any) => {
            return (option?.children || '')
              ?.toLowerCase()
              ?.includes(input?.toLowerCase());
          }}
          data={stateData.employerList}
          optionProps={{key: 'id', value: 'id', label: 'name'}}
          extraStyle={{
            flex: 1,
            fontColor: Colors.FoldPixel.GRAY250,
            fontWeight: 400,
            fontSize: 14,
          }}
        />
      )}
    </View>
  );
};

export default AssociatedAccountView;
