import {useLazyQuery, useMutation} from '@apollo/client';
import React, {useCallback, useContext, useEffect} from 'react';
import {
  IConversationMessage,
  IConversationMessageData,
  IConversationMessageMentionPrevResponse,
  IConversationMessageResponse,
  IMessageBoxData,
  ISearchMessageData,
  ISelectedConversationResponse,
  IStickNotesAndLastMessageResponse,
} from '../interfaces';
import ConversationsQueriesV2 from '../../../../../services/Conversations/V2/ConversationsQueriesV2';
import {
  IGetMessages,
  IMessageRespData,
  IMessagingWindowCommonHook,
} from './interface';
import {
  addCommunicationTypesByMessageUuids,
  checkAnyMessageSenderDeleted,
  getFormattedMessagingList,
  getLastEmailMessageUuid,
  getLastNonInternalMessageInList,
  getMessageBoxDataObj,
  getMessageBoxDataObjForGroup,
  getMessageDataFromId,
  getUniqueDisplayMessageList,
  getUpdatedConversationResponse,
  getUpdatedSenderUserData,
  getUpdatedTaskCountData,
  handleDeletedContact,
  handleMarkMentionAsUnread,
  isChatOrSmsConversation,
  isCommunicationTypeAssignedToSelectedMessages,
  isInternalChat,
  isSMSNotSent,
  messageDataKeysConverter,
  shouldSetMessageData,
  showPracticeMessageOnRightSide,
  sortMessages,
} from './MessagingUtils';
import {
  GROUP_TYPE_CODES,
  MLOV_CATEGORY,
} from '../../../../../constants/MlovConst';
import {
  CHAT_DELIVERY_STATUS,
  CONVERSATION_STATUS_STRING,
  EVENT_NAMES,
  LAST_SEEN_UPDATE_TIMEOUT,
  MODULE_PAGINATION_COUNT,
  ONLINE_STATUS,
  SENDER_TYPE,
} from '../../../../../constants';
import {
  getAccountId,
  getAllowedUserAccountLocationUuids,
  getBooleanFeatureFlag,
  getFeatureFlag,
  getLocationGroupIdFromLocationsUsingUuid,
  getUserId,
  getUserUUID,
  mapAndGetLocationGroupIds,
} from '../../../../../utils/commonUtils';
import {EventBus} from '../../../../../utils/EventBus';
import {setSearchParamsForSelectedConversation} from '../ConversationChannelNew/ConversationSidebarUtils';
import {
  CONVERSATION_LOCAL_EVENT_CODES,
  CUSTOM_MESSAGE_EVENT_CODES,
  SUPPORTED_EVENT_CODE,
} from '../../../../../constants/WebSocketConst';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import ConversationsQueries from '../../../../../services/Conversations/ConversationsQueries';
import {ILoginUserData} from '../../../../../Interfaces';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../ConversationConst';
import useMessagingAdditionalData from './useMessagingAdditionalData';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../../../utils/CaptureTransaction';
import {CONVERSATION_TASK_CODE} from './MsgConst';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import ConversationTaskResourcesQueries from '../../../../../services/Conversations/ConversationTaskResourcesQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {useToast} from 'native-base';
import {ICommunicationType} from '../CommunicationTypes/interfaces';
import {IReplyMessageObject} from '../../../../common/ChatUI/MessageBox/interfaces';
import {
  getDateStrFromFormat,
  getDateToMomentISOString,
} from '../../../../../utils/DateUtils';
import {MessageBus} from '../../../../../utils/MessageBus';
import {MessagingEventQueue} from '../MessagingEventQueue/MessagingEventQueue';
import {CallBackArgs} from '../../../../../utils/interfaces';
import {IMessageCreated} from '../../../../../Interfaces/WebSocketEventInterfaces';
import {IGroupMessageCreatedData} from '../../../../../Interfaces/GroupMessageInterface';
import {getUserIdListFromMsgContent} from './ConversationMentions/ConversationMentions';
import {getMlovCodeIdObj} from '../../../../../utils/mlovUtils';
import {IMessagingWindowLocalEventData} from '../ConversationContainer/ConversationList/ConversationListEventHook/interface';
import {goToMessages} from '../../../../common/ChatUI/MessageBox/MessageBoxUtils';
import {sendConversationNotificationNoMessageEvent} from '../../../../../services/SendNotification';
import {IStickyNoteData} from '../../../../common/ActionMessageView/interfaces';
import {useMessageDBService} from '../../../../CustomHooks/useMessageDBService';
import FeatureFlags from '../../../../../constants/FeatureFlags.enums';
import { USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { checkLocationAccess, getActivePatientProfile } from '../../../../common/ContactProfile/commonUtils';

export const MessagingWindowCommonHook = (
  params: IMessagingWindowCommonHook
) => {
  const {
    selectedConversation,
    isComponentMounted,
    selectedInboxTypeCode,
    msgListData,
    setMsgListData,
    isSeachMsgContainerVisible,
    searchMessageUuid,
    moduleCode,
    isInstantChatView,
    onUpdateUserLastSeen,
    conversationUpdateOnMsgReceived,
    onNewMessageReceived,
    onLoadingLatestMessage,
    onLoadingMsgFirstBatch,
    onGetStickyNotes,
  } = params;
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );
  const refetchRef = React.useRef<NodeJS.Timeout>();
  const loadingExtraMessageRef = React.useRef<boolean>(false);
  const updateLastSeenRef = React.useRef<NodeJS.Timeout>();
  const isScrollingToPrevMessages = React.useRef(false);
  const commonData = useContext(CommonDataContext);
  const userData = commonData?.userData || ({} as ILoginUserData);
  const mlovData = commonData.MLOV;
  const userId = getUserId();
  const userUuid = getUserUUID();
  const accountId = getAccountId();
  const groupMemberTypeList = mlovData[MLOV_CATEGORY.GroupMemberType];
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CONSUMER
  );
  const allowedUserAccountP360LocationGroupIds = mapAndGetLocationGroupIds(allowedUserAccountP360LocationUuids, commonData?.accountLocationListWithEHR);
  const showPracticeMessageTogether =
    !isInternalChat(selectedConversation) &&
    getFeatureFlag(CONFIG_CODES.SHOW_PRACTICE_MESSAGE_TOGETHER, commonData);
  const showConversationTaskCount = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_TASK_COUNT_ON_MESSAGE
  );
  const isRefreshConversationAfterOfflineOnlineEnabled = isAccountConfigEnabled(
    CONFIG_CODES.REFRESH_CONVERSATION_AFTER_OFFLINE_ONLINE
  );
  const intl = useIntl();
  const toast = useToast();
  const eventQueueRef = React.useRef(
    new MessagingEventQueue({moduleCode: moduleCode || 'MessagingWindow'})
  );
  const eventQueue = eventQueueRef?.current;

  const captureTransactionInst = CaptureTransaction.getInstance();
  const {getSelectedConversationResponse, fetchGroupChatMessageAndAddToDB} = useMessageDBService()

  const [GetMessageDataForSelectedChatV2] =
    useLazyQuery<ISelectedConversationResponse>(
      ConversationsQueriesV2.GetMessageDataForSelectedChatV2,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      }
    );

  const [GetMessageDataForSelectedConversationInboxV2] =
    useLazyQuery<ISelectedConversationResponse>(
      ConversationsQueriesV2.GetMessageDataForSelectedConversationInboxV2,
      {
        fetchPolicy: 'no-cache', //FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      }
    );

  const [getMessagesByMessageIds] = useLazyQuery(
    ConversationsQueries.getMessagesByMessageIds,
    {
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    }
  );

  const [getConversationMessages] = useLazyQuery<IConversationMessageResponse>(
    ConversationsQueriesV2.GetConversationMessages,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    }
  );

  const [GetMentionConversationMessages] =
    useLazyQuery<IConversationMessageResponse>(
      ConversationsQueriesV2.GetMentionConversationMessages,
      {
        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      }
    );

  const [getPrevConversationMentionMessages] =
    useLazyQuery<IConversationMessageMentionPrevResponse>(
      ConversationsQueriesV2.GetPrevConversationMentionMessages,
      {
        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      }
    );

  const [getNextConversationMentionMessages] =
    useLazyQuery<IConversationMessageMentionPrevResponse>(
      ConversationsQueriesV2.GetNextConversationMentionMessages,
      {
        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      }
    );

  const [getMessageByMentionId] = useLazyQuery(
    ConversationsQueries.GetMessageByMentionId,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    }
  );

  const [getMessageByMessageUuid] = useLazyQuery(
    ConversationsQueries.GetMessageByMessageUuid,
    {
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    }
  );

  const [CreateConversationTask] = useMutation(
    ConversationTaskResourcesQueries.CREATE_CONVERSATION_TAGGED_ITEM,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [getLastConversationMessages] =
    useLazyQuery<IConversationMessageMentionPrevResponse>(
      ConversationsQueries.GetConversationLastMessages,
      {
        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      }
    );

  // created different function due to function overrides data of two different calls.
  const [getLastFiveConversationMessages] =
    useLazyQuery<IConversationMessageMentionPrevResponse>(
      ConversationsQueries.GetConversationLastMessages,
      {
        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
        variables: {
          id: selectedConversation?.id,
          limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
        },
      }
    );

  const [GetMentionsConversationsByMessageAndConversationId] = useLazyQuery(
    ConversationsQueries.GetMentionsConversationsByMessageAndConversationId,
    {
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: abortControllerRef?.current.signal,
        },
      },
    }
  );

  const [getStickNotesAndLastMessageByConversationId] =
    useLazyQuery<IStickNotesAndLastMessageResponse>(
      ConversationsQueriesV2.GetStickNotesAndLastMessageByConversationId,
      {
        fetchPolicy: 'no-cache',
        context: {
          fetchOptions: {
            signal: abortControllerRef?.current.signal,
          },
        },
      }
    );

  const {getAdditionalDataForMessages} = useMessagingAdditionalData();

  const getLocationGroupIdFronAccountLocationId = (locationUuid?: string) => {
    if (!locationUuid) {
      return '';
    }
    return getLocationGroupIdFromLocationsUsingUuid(locationUuid, commonData?.accountLocationListWithEHR)
  }
  
  const getPrevConversationMessages = async (
    limit: number,
    offset: number,
    dateTime: string
  ) => {
    const msgResp = await getPrevConversationMentionMessages({
      variables: {
        id: selectedConversation?.id,
        limit: limit,
        dateTime: dateTime,
      },
    });
    if (msgResp?.data?.conversation) {
      const prevMsgData = msgResp?.data?.conversation?.prevMsg || [];
      return prevMsgData;
    }
  };

  const getNextConversationMessages = async (
    limit: number,
    offset: number,
    dateTime: string
  ) => {
    const msgResp = await getNextConversationMentionMessages({
      variables: {
        id: selectedConversation?.id,
        limit: limit,
        dateTime: dateTime,
      },
    });
    if (msgResp?.data?.conversation) {
      return msgResp?.data?.conversation?.nextMsg || [];
    }
  };

  const getGroupMessageData = async () => {
    const chatConversationResponseWithOtherData =
      await GetMessageDataForSelectedChatV2({
        variables: {
          limit: 30,
          createdAtCursor: new Date(),
          conversationId: selectedConversation?.id,
        },
      });
    return chatConversationResponseWithOtherData;
  };

  const getSelectedConversationMessageData = async (
    conversationMessageData: IConversationMessageData,
    isComponentMounted: boolean,
    isFirstBatch = true
  ) => {
    if (
      conversationMessageData &&
      (conversationMessageData?.conversationMessages || []).length <
        MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE
    ) {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            areMessagesRemaining: false,
          };
        });
      }
    } else {
      if (isComponentMounted) {
        onLoadingLatestMessage(false);
      }
    }
    const listOfParentId = conversationMessageData?.conversationMessages
      ?.filter((messageObj) => {
        return messageObj?.parentMessage?.id;
      })
      .map((messageObj) => {
        return messageObj?.parentMessage?.id;
      });
    if ((listOfParentId || []).length) {
      const response = await getMessagesByMessageIds({
        variables: {
          messageIds: listOfParentId,
          accountId: accountId,
          conversationId: selectedConversation.id,
        },
      });
      const parentMessageList = response.data?.messages?.length
        ? response.data?.messages
        : [];
      conversationMessageData = getUpdatedConversationResponse(
        conversationMessageData,
        parentMessageList
      );
    }
    if (conversationMessageData) {
      getMessagesAdditionalData(
        true,
        isComponentMounted,
        conversationMessageData,
        isFirstBatch,
        undefined
      );
    }
  };

  const getPrevNextMentionMessages = async (
    isPrev: boolean,
    isComponentMounted: boolean
  ) => {
    //let apiMsgResp = {} as IConversationMessageData;
    //let isOlderMsgFetch= '';
    if (isPrev) {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            loadingLoadMore: true,
          };
        });
      }
      // isOlderMsgFetch= 'isPrev';
      const prevMentionResp = await getPrevConversationMessages(
        MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
        0,
        msgListData.displayData?.[msgListData.displayData?.length - 1].date ||
          ''
      );

      //concate data
      if (prevMentionResp?.length) {
        const allMsgData = [...(prevMentionResp || [])];
        const apiMsgResp = {
          conversationMessages: allMsgData,
          unreadMessages: [],
        };
        getMessagesAdditionalData(
          false,
          isComponentMounted,
          apiMsgResp,
          false,
          'isPrev'
        );
      } else {
        if (isComponentMounted) {
          setMsgListData((prev) => {
            return {
              ...prev,
              msgDataLoading: false,
              displayData: prev?.displayData,
              loadingLoadMore: false,
              apiData: prev?.apiData,
              footerMsgLoading: false,
            };
          });
          onLoadingMsgFirstBatch(false);
        }
      }
    } else {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            loadingLoadMore: true,
          };
        });
      }
      //isOlderMsgFetch= 'isNext';
      const nextMentionResp = await getNextConversationMessages(
        MODULE_PAGINATION_COUNT.CONVERSATION_PAGE_SIZE,
        0,
        msgListData.displayData?.[0].date || ''
      );

      //concate data
      if (nextMentionResp?.length) {
        const allMsgData = [...(nextMentionResp.reverse() || [])];
        const apiMsgResp = {
          conversationMessages: allMsgData,
          unreadMessages: [],
        };
        getMessagesAdditionalData(
          false,
          isComponentMounted,
          apiMsgResp,
          false,
          'isNext'
        );
      } else {
        if (isComponentMounted) {
          setMsgListData((prev) => {
            return {
              ...prev,
              msgDataLoading: false,
              displayData: prev?.displayData,
              loadingLoadMore: false,
              apiData: prev?.apiData,
              footerMsgLoading: false,
            };
          });
          onLoadingMsgFirstBatch(false);
        }
      }
    }
  };

  const getMessagesAdditionalData = async (
    isInitialConversationRender: boolean,
    isComponentMounted: boolean,
    apiMsgResp: IConversationMessageData,
    isFirstBatch: boolean,
    isOlderMsgFetch?: string,
    loadingLatestMessages?: boolean
  ) => {
    const isAnySenderDeleted = checkAnyMessageSenderDeleted(
      apiMsgResp?.conversationMessages
    );
    if (isAnySenderDeleted && !msgListData.isAnyMsgSenderDeleted) {
      const deletedSenderDetails = await handleDeletedContact(
        apiMsgResp?.conversationMessages,
        selectedConversation,
        isAnySenderDeleted,
        abortControllerRef?.current?.signal
      );
      setMsgListData((prev) => {
        return {
          ...prev,
          isAnyMsgSenderDeleted: true,
        };
      });
      apiMsgResp.conversationMessages = getUpdatedSenderUserData(
        apiMsgResp?.conversationMessages,
        deletedSenderDetails
      );
    }
    const apiRespMsgList = apiMsgResp?.conversationMessages || [];
    let lastMsgDate = '';
    if (apiRespMsgList && apiRespMsgList.length) {
      const displayMsgList = getFormattedMessagingList(
        apiRespMsgList,
        [],
        userData,
        showPracticeMessageTogether
      );
      if (isComponentMounted) {
        let displayDataLength = 0;
        let apiDataLength = 0;
        setMsgListData((prev) => {
          const sortedDisplayData = sortMessages(
            isFirstBatch
              ? displayMsgList
              : isOlderMsgFetch
              ? isOlderMsgFetch === 'isPrev'
                ? [...prev?.displayData, ...displayMsgList]
                : [...displayMsgList, ...prev?.displayData]
              : [...prev?.displayData, ...displayMsgList]
          );

          lastMsgDate =
            apiRespMsgList?.[apiRespMsgList?.length - 1]?.createdAt || '';
          const combinedApiData = isFirstBatch
            ? apiRespMsgList
            : isOlderMsgFetch
            ? isOlderMsgFetch === 'isPrev'
              ? [...(prev?.apiData || []), ...(apiRespMsgList || [])]
              : [...(apiRespMsgList || []), ...(prev?.apiData || [])]
            : [...(prev?.apiData || []), ...(apiRespMsgList || [])];
            
          const updatedApiData = getUniqueDisplayMessageList(Array.from(new Set(combinedApiData)) as any[]);
          displayDataLength = sortedDisplayData.length;
          apiDataLength = updatedApiData.length;
          const lastEmailMessageUuid = isFirstBatch ? getLastEmailMessageUuid(sortedDisplayData) : '';
          return {
            ...prev,
            displayData: sortedDisplayData,
            apiData: updatedApiData,
            msgDataLoading: false,
            loadingLoadMore: false,
            footerMsgLoading: false,
            lastEmailMessageUuid: lastEmailMessageUuid
          };
        });
        onLoadingMsgFirstBatch(false);
        onLoadingLatestMessage(false);
        if (isFirstBatch) {
          captureTransactionInst.finishTransaction(
            'CONVERSATION_CHANGE',
            selectedConversation?.id
          );
        }
        if (isFirstBatch && selectedInboxTypeCode) {
          captureTransactionInst.finishTransaction(
            'TAB_CHANGE',
            selectedInboxTypeCode
          );
        }
        if (
          isScrollingToPrevMessages.current &&
          displayDataLength &&
          apiDataLength
        ) {
          captureTransactionInst.finishTransaction(
            TRANSACTION_NAMES.SCROLL_TO_PREV_MESSAGES,
            selectedConversation?.id,
            {
              displayDataLength: displayDataLength,
              apiDataLength: apiDataLength,
            }
          );
          isScrollingToPrevMessages.current = false;
        }
      }
      updateLastSeenAt(displayMsgList);
      if (
        loadingLatestMessages &&
        selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
      ) {
        if (displayMsgList?.length && displayMsgList?.[0]?.messageData?.uuid) {
          goToMessages(displayMsgList?.[0]?.messageData?.uuid);
        }
      }
      if (isInitialConversationRender) {
        getStickyNotesAndLastMessageData(isComponentMounted);
      }
      getAdditionalMessageData(
        isComponentMounted,
        apiMsgResp,
        isFirstBatch,
        isOlderMsgFetch
      );
      if(isFirstBatch && isInitialConversationRender && selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        updateMessageListFromLastSynced();
      }
      const eventBus = EventBus.getEventBusInstance();
      setSearchParamsForSelectedConversation({
        selectedConversation: selectedConversation,
      }).then(() => {
        eventBus.broadcastEvent(SUPPORTED_EVENT_CODE.CONVERSATION_CHANGED, {});
      });
      if (isFirstBatch && isChatOrSmsConversation(selectedConversation)) {
        getLoadMoreMessages(lastMsgDate, isComponentMounted);
      }
    } else {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            msgDataLoading: false,
            displayData: prev?.displayData,
            loadingLoadMore: false,
            apiData: prev?.apiData,
            footerMsgLoading: false,
          };
        });
        onLoadingMsgFirstBatch(false);
      }
      onLoadingLatestMessage(false);
      updateLastSeenAt(msgListData?.displayData);
    }
  };

  const updateMessageListFromLastSynced = async () => {
    const response = await fetchGroupChatMessageAndAddToDB({
      conversationUuid: selectedConversation?.uuid,
      conversationId: selectedConversation?.id,
    });
    if (
      !response ||
      (response?.data?.conversationMessagesData?.conversationMessages || [])
        .length === 0
    ) {
      return;
    }
    const conversationMessagesData = response?.data?.conversationMessagesData;
    if (!conversationMessagesData) {
      return;
    }
    getSelectedConversationMessageData(
      conversationMessagesData,
      isComponentMounted.current,
      false
    );
  };

  const getAdditionalMessageData = async (
    isComponentMounted: boolean,
    apiResponse: IConversationMessageData,
    isFirstBatch: boolean,
    isOlderMsgFetch?: string
  ) => {
    const updatedMessageList = await getAdditionalDataForMessages(
      {
        apiResponse,
        conversationDisplayId: selectedConversation?.displayId,
        showPracticeMessageTogether,
        conversationUuid: selectedConversation?.uuid,
      },
      abortControllerRef?.current?.signal
    );
    const updatedMessages = updatedMessageList?.messageData;
    if (isComponentMounted) {
      setMsgListData((prev) => {
        const sortedDisplayData = sortMessages(
          isFirstBatch
            ? updatedMessages
            : isOlderMsgFetch
            ? isOlderMsgFetch === 'isPrev'
              ? [...prev?.displayData, ...updatedMessages]
              : [...updatedMessages, ...prev?.displayData]
            : [...prev?.displayData, ...updatedMessages]
        );
        return {
          ...prev,
          displayData: sortedDisplayData,
        };
      });
    }
  };

  const getStickyNotesAndLastMessageData = async (
    isComponentMounted: boolean
  ) => {
    if (isComponentMounted) {
      const conversationAdditionalData =
        await getStickNotesAndLastMessageByConversationId({
          variables: {
            lastLimit: 1,
            conversationId: selectedConversation?.id,
            stickyNotesCondition: {
              contactUuid: {
                _eq: selectedConversation?.conversationContact?.uuid,
              },
               ...(isMultiTenancyEnabled ? {
                locationGroupId: {
                  _in: allowedUserAccountP360LocationGroupIds,
                },
              } : {})
            },
          },
        });
      const stickyNotesList = conversationAdditionalData?.data?.stickyNotes || [];
      const contactProfiles = selectedConversation?.conversationContact?.contactProfiles || [];
      const activePatientProfile = getActivePatientProfile(contactProfiles || []);
      const activeContactProfileLocationGroupId = getLocationGroupIdFronAccountLocationId(activePatientProfile?.accountLocationUuid);

      const stickyNoteOfActiveLocation = stickyNotesList?.find((stickyNote: any) => {
        return stickyNote?.locationGroupId === activeContactProfileLocationGroupId && stickyNote?.ehrPatientId === activePatientProfile?.ehrPatientId;
      });
      const stickyNote = stickyNoteOfActiveLocation ? stickyNoteOfActiveLocation : stickyNotesList?.[0];
      const lastMsgUuid =
        conversationAdditionalData?.data?.lastMessage?.lastMsg?.[0]?.uuid;
      let contactStickyNote = {} as IStickyNoteData;
      if (
        stickyNote &&
        stickyNote?.noteUuid
      ) {
        contactStickyNote = stickyNote;
      }
      onGetStickyNotes(contactStickyNote);

      if (lastMsgUuid) {
        setMsgListData((prev) => {
          return {
            ...prev,
            lastConversationMsgUuid: lastMsgUuid,
          };
        });
      }
    }
  };

  const getLoadMoreMessages = async (
    lastMsgDate: string | any,
    isComponentMounted: boolean
  ) => {
    try {
      const msgResp = await getConversationMessages({
        variables: {
          id: selectedConversation?.id,
          limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
          createdAtCursor: lastMsgDate,
        },
      });
      const apiRespMsgList =
        msgResp?.data?.conversation?.conversationMessages || [];
      if (apiRespMsgList?.length) {
        let updatedApiData: IConversationMessage[] = [];
        setMsgListData((prev) => {
          const combinedApiData = [...prev?.apiData, ...apiRespMsgList];
          updatedApiData =  getUniqueDisplayMessageList(Array.from(new Set(combinedApiData)) as any[]);
          return {
            ...prev,
            apiData: updatedApiData,
          };
        });
        if (loadingExtraMessageRef?.current) {
          getMessages(
            {isFirstBatch: false, apiResponse: updatedApiData},
            isComponentMounted
          );
        }
        loadingExtraMessageRef.current = false;
      } else {
        setMsgListData((prev) => {
          return {
            ...prev,
            areMessagesRemaining: false,
          }
        })
      }
    } catch (error) {
      loadingExtraMessageRef.current = false;
      setMsgListData((prev) => {
        return {
          ...prev,
          msgDataLoading: false,
        };
      });
      onLoadingMsgFirstBatch(false);
    }
  };

  const getSelectedConversationMessageDataWithLastMessage = async (
    isComponentMounted: boolean
  ) => {
    try {
      if (selectedConversation?.id) {
        if (isComponentMounted) {
          setMsgListData((prev) => {
            return {
              ...prev,
              apiData: [] as IConversationMessage[],
              displayData: [] as IMessageBoxData[],
              msgDataLoading: true,
              loadingLoadMore: false,
              areMessagesRemaining: true,
              lastConversationMsgUuid: '',
              clickMessageUuid: '',
              clickEventId: '',
              newMessageUuids: [] as string[],
              selectedMessageItem: {} as any,
              footerMsgLoading: false,
            };
          });
          onLoadingMsgFirstBatch(false);
        }
        const response = await getSelectedConversationResponse({
          conversationId: selectedConversation?.id,
          conversationUuid: selectedConversation?.uuid,
        })
        getSelectedConversationMessageData(
          response?.data
            ?.conversationMessagesData,
          isComponentMounted
        );
      } else {
        setMsgListData((prev) => {
          return {
            ...prev,
            msgDataLoading: false,
            loadingLoadMore: false,
            footerMsgLoading: false,
          };
        });
      }
    } catch (e) {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            msgDataLoading: false,
            loadingLoadMore: false,
            footerMsgLoading: false,
          };
        });
      }
    }
  };

  const getMessages = async (
    data: IGetMessages,
    isComponentMounted: boolean,
    isInitialConversationRender?: boolean
  ) => {
    const {isFirstBatch, apiResponse, loadingLatestMessages} = data;
    const apiResponseData =
      apiResponse && apiResponse?.length ? apiResponse : msgListData?.apiData;
    const msgListLength = apiResponseData?.length;
    const lastMsgDate =
      !isFirstBatch && apiResponseData?.[0]?.createdAt
        ? apiResponseData?.[msgListLength - 1]?.createdAt
        : new Date();
    let apiMsgResp = {} as IConversationMessageData;
    let msgResp = {} as any;
    if (selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
      if (isChatOrSmsConversation(selectedConversation) && !isFirstBatch) {
        if (apiResponseData?.length > msgListData?.displayData?.length) {
          setMsgListData((prev) => {
            return {
              ...prev,
              loadingLoadMore: true,
            };
          });
          getLoadMoreMessages(lastMsgDate, isComponentMounted);
          const apiRespMsgList = apiResponseData?.filter(
            (item) =>
              !msgListData?.displayData?.some(
                (displayMsg) => displayMsg.id === item.id
              )
          );
          apiMsgResp = {
            ...apiMsgResp,
            conversationMessages: apiRespMsgList,
          };
        } else if (msgListData?.areMessagesRemaining) {
          loadingExtraMessageRef.current = true;
        }
      } else {
        msgResp = await getConversationMessages({
          variables: {
            id: selectedConversation?.id,
            limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
            createdAtCursor: lastMsgDate,
          },
        });
        apiMsgResp = msgResp?.data?.conversation;
      }
    } else {
      msgResp = await GetMentionConversationMessages({
        variables: {
          id: selectedConversation?.id,
          loginUserId: userId,
          loginUserIntId: userId,
          limit: MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE,
          createdAtCursor: lastMsgDate,
        },
      });
      apiMsgResp = msgResp?.data?.conversation;
    }
    if (
      apiMsgResp &&
      apiMsgResp?.conversationMessages?.length <
        MODULE_PAGINATION_COUNT.MESSAGES_PAGE_SIZE
    ) {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            areMessagesRemaining: false,
          };
        });
      }
    } else {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            msgDataLoading: false,
          };
        });
        onLoadingLatestMessage(false);
      }
    }
    const listOfParentId = apiMsgResp?.conversationMessages
      ?.filter((messageObj) => {
        return messageObj?.parentMessage?.id;
      })
      ?.map((messageObj) => {
        return messageObj?.parentMessage?.id;
      });
    if (listOfParentId?.length) {
      const response = await getMessagesByMessageIds({
        variables: {
          messageIds: listOfParentId,
          accountId: accountId,
          conversationId: selectedConversation?.id,
        },
      });
      const parentMessageList = response.data?.messages?.length
        ? response.data?.messages
        : [];
      apiMsgResp = getUpdatedConversationResponse(
        apiMsgResp,
        parentMessageList
      );
    }
    if (apiMsgResp) {
      getMessagesAdditionalData(
        isInitialConversationRender || false,
        isComponentMounted,
        apiMsgResp,
        isFirstBatch,
        undefined,
        loadingLatestMessages
      );
    }
  };

  const resetAndFetchFirstBatch = async (
    isComponentMounted: boolean,
    isInitialConversationRender?: boolean
  ) => {
    if (selectedConversation?.id) {
      if (isComponentMounted) {
        setMsgListData((prev) => {
          return {
            ...prev,
            msgDataLoading: true,
            apiData: [],
            displayData: [],
            loadingLoadMore: false,
            footerMsgLoading: false,
          };
        });
        onLoadingMsgFirstBatch(false);
      }

      if (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION) {
        let apiMsgResp = {} as IConversationMessageData;
        Promise.all([
          getPrevConversationMessages(
            MODULE_PAGINATION_COUNT.MENTION_MESSAGES_PAGE_SIZE,
            0,
            selectedConversation?.conversationMessages[0].createdAt || ''
          ),
          getMessageByMentionId({
            variables: {
              mentionId: selectedConversation.conversationMentionId,
            },
          }),
          getNextConversationMessages(
            5,
            0,
            selectedConversation?.conversationMessages[0].createdAt || ''
          ),
        ]).then((responses) => {
          const prevMentionResp = responses?.[0];
          const singleMentionMsgResp = responses?.[1]?.data?.messages || [];
          const nextMentionResp = responses?.[2];

          if (
            prevMentionResp?.length ||
            singleMentionMsgResp?.length ||
            nextMentionResp?.length
          ) {
            const allMsgData = [
              ...(prevMentionResp?.reverse() || []),
              ...singleMentionMsgResp,
              ...(nextMentionResp || []),
            ];
            apiMsgResp = {
              conversationMessages: allMsgData.reverse(),
              unreadMessages: [],
            };
          }
          if (apiMsgResp) {
            getMessagesAdditionalData(
              isInitialConversationRender || false,
              isComponentMounted,
              apiMsgResp,
              true
            );
          }
        });
      } else {
        getMessages(
          {isFirstBatch: true},
          isComponentMounted,
          isInitialConversationRender || false
        );
      }
    }
  };

  const updateLastSeenAt = (displayMsgList?: IMessageBoxData[]) => {
    if (selectedConversation && selectedConversation.id) {
      const lastSeenTimeOut = setTimeout(() => {
        if (isInstantChatView) {
          onUpdateUserLastSeen(displayMsgList);
        }
      }, LAST_SEEN_UPDATE_TIMEOUT);
      if (updateLastSeenRef?.current) {
        updateLastSeenRef.current = lastSeenTimeOut;
      }
    }
  };

  const onFetchMoreIfAvailableCallback = useCallback(
    (isComponentMounted: boolean) => {
      if (
        selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION ||
        (isSeachMsgContainerVisible && searchMessageUuid)
      ) {
        getPrevNextMentionMessages(true, isComponentMounted);
      } else {
        isScrollingToPrevMessages.current = true;
        captureTransactionInst.initiateTransaction({
          name: TRANSACTION_NAMES.SCROLL_TO_PREV_MESSAGES,
          identifier: selectedConversation?.id,
        });
        getMessages({isFirstBatch: false}, isComponentMounted);
      }
    },
    [
      selectedInboxTypeCode,
      isSeachMsgContainerVisible,
      searchMessageUuid,
      getPrevNextMentionMessages,
      getMessages,
    ]
  );

  const onFetchMoreRecentIfAvailableCallback = useCallback(
    (isComponentMounted: boolean) => {
      getPrevNextMentionMessages(false, isComponentMounted);
    },
    [getPrevNextMentionMessages]
  );

  const handleOnScrollToMessageFail = async (
    msg: ISearchMessageData,
    isComponentMounted: boolean
  ) => {
    if (isComponentMounted) {
      setMsgListData((prev) => {
        return {
          ...prev,
          msgDataLoading: true,
          apiData: [],
          displayData: [],
          clickMessageUuid: msg.uuid || '',
        };
      });
      onLoadingMsgFirstBatch(false);
    }
    // setOffset(0);
    let apiMsgResp = {} as IConversationMessageData;
    const responses = await Promise.all([
      getPrevConversationMessages(
        MODULE_PAGINATION_COUNT.CONVERSATION_PAGE_SIZE,
        0,
        msg?.createdAt || ''
      ),
      getMessageByMessageUuid({
        variables: {
          messageUuid: msg.uuid,
        },
      }),
      getNextConversationMessages(5, 0, msg?.createdAt || ''),
    ]);
    const prevResp = responses?.[0];
    const singleMessageResp = responses?.[1]?.data?.messages || [];
    const nextResp = responses?.[2];
    if (prevResp?.length || singleMessageResp?.length || nextResp?.length) {
      const allMsgData = [
        ...(prevResp?.reverse() || []),
        ...singleMessageResp,
        ...(nextResp || []),
      ];
      apiMsgResp = {
        conversationMessages: allMsgData.reverse(),
        unreadMessages: [],
      };
    }
    if (apiMsgResp) {
      getMessagesAdditionalData(false, isComponentMounted, apiMsgResp, true);
    }
  };

  const showApiErrorMessage = () => {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  };

  const handleCreateConversationTask = async (
    taskId: string,
    isComponentMounted: boolean,
    messageList?: string[]
  ) => {
    const eventBus = EventBus.getEventBusInstance();
    if (messageList && messageList?.length > 0) {
      const finalVariables: any = [];
      messageList?.forEach((msgUuid: string) => {
        finalVariables.push({
          resourceId: taskId,
          resourceTypeCode: CONVERSATION_TASK_CODE.TASK,
          sourceId: msgUuid,
          sourceTypeCode: CONVERSATION_TASK_CODE.MESSAGE,
        });
      });
      try {
        const response = await CreateConversationTask({
          variables: {
            data: finalVariables,
          },
        });
        if (response?.data) {
          eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {});
          if (isComponentMounted) {
            setMsgListData((prev) => {
              const displayData = prev.displayData ? [...prev.displayData] : [];
              messageList?.forEach((msgUuid: string) => {
                const index = displayData.findIndex(
                  (item) => item.uuid === msgUuid
                );
                if (showConversationTaskCount && index >= 0) {
                  displayData[index] = getUpdatedTaskCountData(
                    displayData,
                    index
                  );
                }
              });
              return {
                ...prev,
                displayData: displayData,
              };
            });
          }
        } else {
          showApiErrorMessage();
        }
      } catch (error) {
        showApiErrorMessage();
      }
    } else {
      try {
        const finalData = {
          resourceId: taskId,
          resourceTypeCode: CONVERSATION_TASK_CODE.TASK,
          sourceId: msgListData?.selectedMessageItem?.uuid,
          sourceTypeCode: CONVERSATION_TASK_CODE.MESSAGE,
        };
        const response = await CreateConversationTask({
          variables: {
            data: finalData,
          },
        });
        if (response?.data) {
          eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {});
          if (isComponentMounted) {
            setMsgListData((prev) => {
              const displayData = prev.displayData ? [...prev.displayData] : [];
              const index = displayData.findIndex(
                (item) => item.uuid === msgListData?.selectedMessageItem?.uuid
              );
              if (showConversationTaskCount && index >= 0) {
                displayData[index] = getUpdatedTaskCountData(
                  displayData,
                  index
                );
              }
              return {
                ...prev,
                displayData: displayData,
              };
            });
          }
        } else {
          showApiErrorMessage();
        }
      } catch (error) {
        showApiErrorMessage();
      }
    }
  };

  const getLastMessage = async (isComponentMounted: boolean) => {
    const msgResp = await getLastConversationMessages({
      variables: {
        id: selectedConversation?.id,
        limit: 1,
      },
    });
    if (msgResp?.data?.conversation) {
      const lastUUID = msgResp?.data?.conversation?.lastMsg?.[0]?.uuid;
      if (isComponentMounted) {
        setMsgListData((prev: any) => {
          return {
            ...prev,
            lastConversationMsgUuid: lastUUID,
          };
        });
      }
      return lastUUID;
    }
    return '';
  };

  const onAddCommunicationType = (
    data: {messageUuid: string; types: ICommunicationType[]},
    isComponentMounted: boolean
  ) => {
    const {types: communicationTypes, messageUuid} = data;
    if (isComponentMounted) {
      setMsgListData((prev) => {
        const displayData = prev.displayData ? [...prev.displayData] : [];
        const idx = displayData.findIndex((item) => item.uuid === messageUuid);
        const messageObject = displayData[idx];
        messageObject.communicationTypes = communicationTypes;
        displayData[idx] = messageObject;
        return {
          ...prev,
          displayData: displayData,
        };
      });
    }
  };

  const resetAndFetchAllMessages = (isComponentMounted: boolean) => {
    const refetchTimeout = setTimeout(() => {
      if (
        selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION ||
        searchMessageUuid
      ) {
        if (
          msgListData.lastConversationMsgUuid !==
            msgListData.displayData?.[1]?.uuid ||
          msgListData.lastConversationMsgUuid !==
            msgListData.displayData?.[0]?.uuid
        ) {
          if (isComponentMounted) {
            onLoadingLatestMessage(true);
          }
          getMessages({isFirstBatch: true}, isComponentMounted);
        }
      }
    }, 100);
    if (refetchRef?.current) {
      refetchRef.current = refetchTimeout;
    }
  };

  const onMessageSendAction = async (
    isComponentMounted: boolean,
    msgText: string,
    msgTempData: IMessageRespData,
    parentMessage?: IReplyMessageObject
  ) => {
    const msgAttachments = msgTempData?.attachments || [];
    const newMsgObj = getMessageBoxDataObj(
      msgText,
      'right',
      msgTempData.id || Math.random(),
      {
        ...msgTempData,
        sender: {name: userData.name, id: userData.id},
      } as any,
      msgAttachments,
      msgTempData?.currentTime
    );
    if (parentMessage?.id) {
      newMsgObj['parentMessage'] = {
        id: parentMessage.id,
        displayContent: parentMessage.text,
        dateStr: getDateStrFromFormat(parentMessage.dateStr || ''),
        position: 'left',
        senderFullName: parentMessage.senderFullName || 'You',
        messageType: parentMessage.messageType || -1,
        msgAttachment: parentMessage.attachments || [],
        private: parentMessage.private || false,
        messageData: parentMessage,
        senderType: '',
        uuid: parentMessage.uuid,
      };
    }
    const newMsgList = [newMsgObj];
    if (isComponentMounted) {
      setMsgListData((prev) => {
        return {
          ...prev,
          displayData: sortMessages([...newMsgList, ...prev.displayData]),
        };
      });
    }
    //if user cursor not in bottom last message
    const lastMessageId: string =
      (await getLastMessage(isComponentMounted)) || '';
    let isLastMessageExist = false;
    if (lastMessageId.trim().length > 0) {
      const lastMessageExist = msgListData.displayData.find(
        (singleDisplayData) => {
          return singleDisplayData.uuid === lastMessageId;
        }
      );
      if (lastMessageExist?.id) {
        isLastMessageExist = true;
      }
    }
    if (!isLastMessageExist) {
      resetAndFetchAllMessages(isComponentMounted);
    }
  };

  const updateMessageIfGroupConversation = (data: IGroupMessageCreatedData) => {
    if (
      data?.conversations?.groupConversations?.[0].id &&
      data?.conversations?.id + '' === '' + selectedConversation?.id
    ) {
      const messageData = data;
      const newMsgObj = getMessageBoxDataObjForGroup(
        messageData.content,
        'left',
        messageData.id,
        messageData as any,
        [],
        messageData?.createdAt,
        messageData?.uuid,
        messageData?.parentMessageUuid
      );
      const newMsgList = [newMsgObj];
      if (isComponentMounted?.current) {
        setMsgListData((prev) => {
          return {
            ...prev,
            displayData: sortMessages([...newMsgList, ...prev.displayData]),
          };
        });
      }
      onUpdateUserLastSeen();
    }
  };

  const updateReadStatusOfMentionOnMsgReceived = async (
    data: IMessageCreated
  ) => {
    const userUuids = getUserIdListFromMsgContent(
      data?.content,
      groupMemberCodeIdObj
    );
    const conversationUuid = data?.conversationUuid || data?.conversation_uuid;
    if (
      selectedInboxTypeCode == CHANNEL_TYPE_CODE.CHANNEL_MENTION &&
      selectedConversation?.uuid === conversationUuid &&
      userUuids?.includes(userUuid) &&
      data?.uuid &&
      conversationUuid
    ) {
      const conversationMentionIdsResp =
        await GetMentionsConversationsByMessageAndConversationId({
          variables: {
            conversationUuid: conversationUuid,
            userId: userUuid,
            messageUuid: data?.uuid,
          },
        });
      if (conversationMentionIdsResp?.data?.conversationMentions?.length) {
        const conversationMentionIds: any[] = [];
        conversationMentionIdsResp?.data?.conversationMentions?.forEach(
          (singleMentionData: any) => {
            if (singleMentionData?.id) {
              conversationMentionIds.push(singleMentionData?.id);
            }
          }
        );
        if (conversationMentionIds?.length) {
          try {
            await handleMarkMentionAsUnread([...conversationMentionIds], true);
          } catch (error) {
            showToast(toast, 'Something went wrong.', ToastType.error);
          }
        }
      }
    }
  };

  const calculateDiffAndUpdateMessageInList = async (
    receivedMessageData: IMessageCreated
  ) => {
    const msgResp = await getLastFiveConversationMessages({
      variables: {
        id: selectedConversation?.id,
        limit: 5,
      },
    });
    if (msgResp?.data?.conversation) {
      const lastMessagesUuid = msgResp?.data?.conversation?.lastMsg?.map(
        (item) => {
          return item?.uuid;
        }
      );
      if (lastMessagesUuid?.includes(msgListData?.displayData?.[0]?.uuid)) {
        if (isComponentMounted?.current) {
          setMsgListData((oldData) => {
            const existingMessageData = getMessageDataFromId(
              oldData.displayData,
              receivedMessageData?.id
            );
            const newMsgObj = getMessageBoxDataObj(
              receivedMessageData.content,
              showPracticeMessageOnRightSide(
                userData,
                receivedMessageData,
                showPracticeMessageTogether
              )
                ? 'right'
                : 'left',
              receivedMessageData.id,
              receivedMessageData as any,
              receivedMessageData.attachments ||
                existingMessageData?.msgAttachment ||
                []
            );
            const newMsgList = [newMsgObj];
            return {
              ...oldData,
              displayData: sortMessages([
                ...newMsgList,
                ...oldData.displayData,
              ]),
            };
          });
        }
        if (
          receivedMessageData?.sender_type !== SENDER_TYPE.USER ||
          receivedMessageData?.sender_id !== userId
        ) {
          getLastMessage(isComponentMounted?.current);
        }
        onUpdateUserLastSeen();
        updateReadStatusOfMentionOnMsgReceived(receivedMessageData);
      } else {
        if (shouldSetMessageData(receivedMessageData, Number(userId))) {
          if (isComponentMounted?.current) {
            setMsgListData((prev) => {
              const previousNewMsgIds = prev.newMessageUuids;
              if (
                receivedMessageData?.messageType === 2 &&
                receivedMessageData?.message_type === 2 &&
                receivedMessageData?.contentAttributes?.assigneeByUserData
                  ?.id !== userId &&
                receivedMessageData?.contentAttributes?.performedBy?.id !==
                  userId
              ) {
                previousNewMsgIds.push(receivedMessageData?.uuid);
              } else if (
                receivedMessageData?.messageType !== 2 &&
                receivedMessageData?.message_type !== 2
              ) {
                previousNewMsgIds.push(receivedMessageData?.uuid);
              }
              if (previousNewMsgIds?.length) {
                onNewMessageReceived(previousNewMsgIds);
              }
              return {
                ...prev,
                newMessageUuids: [...previousNewMsgIds],
              };
            });
          }
        }
      }
    }
  };

  const handleSMSDeliveryStatusUpdate = (data: any) => {
    if (!isSMSNotSent(data.status)) {
      return;
    }
    if (isComponentMounted?.current) {
      setMsgListData((prev) => {
        const id = data.id;
        const status = data.status || '';
        const displayData = [...prev.displayData];
        const idx = displayData.findIndex((item) => item.id === id);
        const messageObj = {...displayData[idx]};
        messageObj.status = status;
        displayData[idx] = messageObj;
        return {
          ...prev,
          displayData: displayData,
        };
      });
    }
  };

  const onMessageUpdate = async (data: IMessageCreated) => {
    const msgText = data?.content;
    const id = data?.id;
    if (
      data?.conversationUuid === selectedConversation?.uuid ||
      data?.conversation_uuid === selectedConversation?.uuid
    ) {
      if (msgText || data?.attachments?.length || data?.contentAttributes) {
        if (isComponentMounted?.current) {
          setMsgListData((oldData: any) => {
            const newMsgData = oldData.displayData.map(
              (value: IMessageBoxData) => {
                if (value.id == id && msgText) {
                  value['displayContent'] = msgText;
                }
                if (value.id == id && data?.attachments?.length) {
                  value.msgAttachment = data?.attachments;
                }
                if (value.id == id && data?.updatedAt) {
                  value.updatedDateStr = data?.updatedAt;
                }
                if (value.id == id && value?.messageData) {
                  value.messageData.updatedAt = data?.updatedAt;
                }
                if (value.id == id && value?.messageData) {
                  value.messageData.contentAttributes = data?.contentAttributes
                }
                return value;
              }
            );
            const newMsgList = [];
            if (
              data?.channel === CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
              (data?.content || data?.attachments)
            ) {
              const newMsgObj = getMessageBoxDataObj(
                data.content,
                showPracticeMessageOnRightSide(
                  userData,
                  data,
                  showPracticeMessageTogether
                )
                  ? 'right'
                  : 'left',
                data.id,
                data as any,
                data.attachments || [],
                data?.message_created_at
              );
              newMsgList.push(newMsgObj);
            }

            return {
              ...oldData,
              displayData: sortMessages([...newMsgList, ...newMsgData]),
            };
          });
        }
        onUpdateUserLastSeen();
        return;
      }
    }
  };

  const onAttachmentDelete = (data: any) => {
    if (isComponentMounted?.current) {
      setMsgListData((oldData) => {
        const newMsgData = oldData.displayData.map((item) => {
          if (item.id === data?.id) {
            const updatedAttachments = item?.msgAttachment?.filter(
              (attachment: any) => {
                return attachment.id !== data?.selectedAttachment;
              }
            );
            delete item?.msgAttachment;
            item = {...item, msgAttachment: updatedAttachments};
            return item;
          } else {
            return item;
          }
        });
        newMsgData.forEach((item) => {
          if (item?.parentMessage?.id === data?.id) {
            if (!item?.parentMessageUuid) {
              item.parentMessageUuid = item?.parentMessage?.uuid;
            }
            item.parentMessage = undefined;
          }
        });
        return {
          ...oldData,
          displayData: [...newMsgData],
        };
      });
    }

    onUpdateUserLastSeen();
  };

  const onMessageDelete = (id: any) => {
    if (isComponentMounted?.current) {
      setMsgListData((oldData) => {
        const newMsgData = oldData.displayData.filter((item) => item.id !== id);
        newMsgData.forEach((item) => {
          if (item?.parentMessage?.id && item?.parentMessage?.id === id) {
            if (!item?.parentMessageUuid) {
              item.parentMessageUuid = item?.parentMessage?.uuid;
            }
            item.parentMessage = undefined;
          }
        });
        return {
          ...oldData,
          displayData: [...newMsgData],
        };
      });
    }
    onUpdateUserLastSeen();
  };

  const callbackAfterMessageFails = (data: any) => {
    if (isComponentMounted?.current) {
      setMsgListData((prev) => {
        const echoId = data.echoId;
        const status = CHAT_DELIVERY_STATUS.API_FAILED;
        const displayData = [...prev.displayData];
        const idx = displayData.findIndex((item) => item.echoId === echoId);
        const messageObj = {...displayData[idx]};
        messageObj.status = status;
        displayData[idx] = messageObj;
        return {
          ...prev,
          displayData: displayData,
        };
      });
    }
  };

  const callbackAfterMessageSent = async (args: CallBackArgs) => {
    const {msgTempData, msgText, parentMessage} = args;
    if (selectedConversation?.uuid !== args?.selectedConversation?.uuid) {
      return;
    }
    if (!isComponentMounted?.current) {
      return;
    }
    if (isComponentMounted?.current) {
      setMsgListData((prev) => {
        const echoId = msgTempData?.content_attributes?.echo_id;
        const displayData = prev.displayData ? [...prev.displayData] : [];
        const msgIndex = displayData.findIndex(
          (item) => item.echoId && item.echoId == echoId
        );
        const currentTime =
          msgTempData?.currentTime || getDateToMomentISOString();
        const newMsgObj = getMessageBoxDataObj(
          msgText,
          'right',
          msgTempData.id,
          {
            ...msgTempData,
            echo_id: echoId,
            sender: {
              name: userData.name,
              type: msgTempData?.sender?.type,
              id: userData.id,
            },
          } as any,
          [],
          currentTime
        );
        if (parentMessage?.id) {
          newMsgObj['parentMessage'] = {
            id: parentMessage.id,
            displayContent: parentMessage.text,
            dateStr: getDateStrFromFormat(parentMessage.dateStr || ''),
            position: 'left',
            senderFullName: parentMessage.senderFullName || 'You',
            messageType: parentMessage.messageType || -1,
            msgAttachment: parentMessage.attachments || [],
            private: parentMessage.private || false,
            messageData: parentMessage,
            senderType: '',
            uuid: parentMessage.uuid,
          };
        }
        if (msgIndex === -1) {
          displayData.unshift(newMsgObj);
        } else {
          displayData[msgIndex] = newMsgObj;
        }
        return {
          ...prev,
          displayData: sortMessages([...displayData]),
        };
      });
    }
  };

  const onMsgUpdatedListenerFn = useCallback(
    (data: IMessageCreated) => {
      if (!isComponentMounted?.current) {
        return;
      }
      data =
        Object.keys(data?.data || {}).length > 0
          ? messageDataKeysConverter(data.data)
          : data;
      if (
        data?.conversationUuid === selectedConversation?.uuid ||
        data?.conversation_uuid === selectedConversation?.uuid
      ) {
        if (data?.id) {
          if (data?.sid) {
            handleSMSDeliveryStatusUpdate(data);
          } else {
            onMessageUpdate(data);
          }
        }
      }
    },
    [selectedConversation?.id, msgListData?.displayData]
  );

  const onMsgReceivedListenerFn = useCallback(
    (data: IMessageCreated) => {
      if (!isComponentMounted?.current) {
        return;
      }
      data =
        Object.keys(data?.data || {}).length > 0
          ? messageDataKeysConverter(data.data)
          : data;
      let condition = false;
      if (
        (data?.conversationUuid || data?.conversation_uuid) &&
        (data?.conversationUuid === selectedConversation?.uuid ||
          data?.conversation_uuid === selectedConversation?.uuid)
      ) {
        condition = true;
      }
      if (condition) {
        if (
          data?.content ||
          data?.attachments?.length ||
          data?.channel === CHANNEL_TYPE.CHANNEL_EFAX
        ) {
          calculateDiffAndUpdateMessageInList(data);
        }
        if (
          (selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_ARCHIVED ||
            isInstantChatView) &&
          !data?.private
        ) {
          let conversationStatus = 0;
          const conversationStatusStr = data?.conversation?.status as any;

          if (!isNaN(data?.conversationStatus as any)) {
            conversationStatus = data?.conversationStatus as any;
          } else if (
            conversationStatusStr &&
            conversationStatusStr === CONVERSATION_STATUS_STRING.OPEN
          ) {
            conversationStatus = 0;
          } else if (
            conversationStatusStr &&
            conversationStatusStr === CONVERSATION_STATUS_STRING.RESOLVED
          ) {
            conversationStatus = 1;
          }
          if (isComponentMounted?.current) {
            conversationUpdateOnMsgReceived(conversationStatus);
          }
        }
      } else {
        updateMessageIfGroupConversation(data as any);
      }
    },
    [selectedConversation?.id, msgListData?.displayData]
  );

  const onMsgDeletedListenerFn = useCallback(
    (data: any) => {
      if (!isComponentMounted?.current) {
        return;
      }
      data =
        Object.keys(data?.data || {}).length > 0
          ? messageDataKeysConverter(data.data)
          : data;
      if (
        data?.conversationUuid === selectedConversation?.uuid ||
        data?.conversation_uuid === selectedConversation?.uuid
      ) {
        if (data?.isAttachment) {
          onAttachmentDelete(data);
        } else {
          onMessageDelete(data?.id);
        }
      }
    },
    [selectedConversation?.id, msgListData?.displayData]
  );

  const onLocalConversationActionEvent = useCallback(
    (data: IMessagingWindowLocalEventData) => {
      if (!isComponentMounted.current) {
        return;
      }
      onMsgReceivedListenerFn(data?.messageData);
    },
    [selectedConversation?.id, msgListData]
  );

  const handleCommunicationTypeSaveOnMessageList = useCallback(
    async (messageUuids: any[]) => {
      const displayData = await addCommunicationTypesByMessageUuids({
        messageUuids,
        displayMsgList: msgListData.displayData,
      });

      if (isComponentMounted.current) {
        setMsgListData((prev) => ({
          ...prev,
          displayData: displayData,
        }));
      }
    },
    [
      addCommunicationTypesByMessageUuids,
      msgListData.displayData,
      isComponentMounted,
    ]
  );

  const onUserOnlineStatusChanged = useCallback(
    (eventData: any) => {
      if (isRefreshConversationAfterOfflineOnlineEnabled) {
        if (eventData?.onlineStatus === ONLINE_STATUS.ONLINE) {
          if (
            selectedConversation?.id &&
            selectedInboxTypeCode !== CHANNEL_TYPE_CODE.CHANNEL_MENTION
          ) {
            getLastMessage(isComponentMounted?.current);
            resetAndFetchFirstBatch(isComponentMounted?.current);
          }
        } else if (
          selectedConversation?.conversationMentionId &&
          selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_MENTION
        ) {
          getLastMessage(isComponentMounted?.current);
          resetAndFetchFirstBatch(isComponentMounted?.current);
        }
      }
    },
    [msgListData?.displayData[0]?.id]
  );

  const checkCommunicationTypeAssignedToSelectedMessages = useCallback(
    (messageUuids: string[]) =>
      isCommunicationTypeAssignedToSelectedMessages({
        uuids: messageUuids,
        displayData: msgListData.displayData,
      }),
    [msgListData.displayData]
  );

  const onMessageUpdateAfterEditMessageByCurrentUser = useCallback(
    (data: IMessageCreated, shouldSendNotification?: boolean) => {
      const msgText = data?.content;
      const id = data?.id;
      if (
        (data?.conversationUuid === selectedConversation?.uuid ||
          data?.conversation_uuid === selectedConversation?.uuid) &&
        (msgText || data?.attachments?.length) &&
        isComponentMounted.current
      ) {
        setMsgListData((oldData: any) => {
          const newMsgDataList = oldData.displayData.map(
            (value: IMessageBoxData) => ({
              ...value,
              ...(value.id === id && {
                displayContent: msgText,
                msgAttachment: data?.attachments?.length
                  ? data.attachments
                  : value.msgAttachment,
                updatedDateStr: data?.updatedDateStr || value.updatedDateStr,
              }),
            })
          );
          return {
            ...oldData,
            displayData: sortMessages(newMsgDataList),
          };
        });

        onUpdateUserLastSeen();
        if (shouldSendNotification) {
          try {
            sendConversationNotificationNoMessageEvent({
              conversationUuid: selectedConversation.uuid,
              eventCode: SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
              eventData: {
                id,
                uuid: data?.uuid,
                message: msgText,
                content: msgText,
                message_created_at: data?.dateStr,
                createdAt: data?.dateStr,
                updatedAt: data?.updatedDateStr,
              },
            });
          } catch (e) {
            // Consider logging the error here
          }
        }
      }
    },
    [
      isComponentMounted,
      selectedConversation,
      setMsgListData,
      onUpdateUserLastSeen,
      sendConversationNotificationNoMessageEvent,
    ]
  );

  const checkLastNonInternalMessageData = useCallback(() => {
    const lastNonInternalMessageData = getLastNonInternalMessageInList(
      msgListData.displayData
    );
    return lastNonInternalMessageData?.uuid || '';
  }, [msgListData.displayData]);

  useEffect(() => {
    //check consent status
    const eventBus = EventBus.getEventBusInstance();
    const messageBus = MessageBus.getMessageBusInstance();
    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_DELETED,
      onMsgDeletedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_UPDATED,
      onMsgUpdatedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );
    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MESSAGE_CREATED,
      onMsgReceivedListenerFn
    );
    ///////
    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.ASSIGNEE_CHANGED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_OPENED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.CONVERSATION_RESOLVED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MEMBER_ADDED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_MEMBER_REMOVED,
      onMsgReceivedListenerFn
    );

    eventQueue.addEventListener(
      SUPPORTED_EVENT_CODE.GROUP_NAME_UPDATED,
      onMsgReceivedListenerFn
    );
    ///////

    // Local Call Events Start
    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ARCHIVED,
      onLocalConversationActionEvent
    );

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ARCHIVED,
      onLocalConversationActionEvent
    );

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_ASSIGNED,
      onLocalConversationActionEvent
    );

    eventQueue.addEventListener(
      CONVERSATION_LOCAL_EVENT_CODES.LOCAL_CONVERSATION_UN_ASSIGNED,
      onLocalConversationActionEvent
    );

    eventBus.addEventListener(
      CUSTOM_MESSAGE_EVENT_CODES.REFRESH_CONVERSATION_ON_USER_ONLINE,
      onUserOnlineStatusChanged
    );

    messageBus.registerCallbacks({
      callback(args) {
        callbackAfterMessageSent(args);
      },
      errorCallback(data) {
        callbackAfterMessageFails(data);
      },
    });

    return () => {
      eventBus.removeEventListener(onMsgReceivedListenerFn);
      eventBus.removeEventListener(onMsgUpdatedListenerFn);
      eventBus.removeEventListener(onMsgDeletedListenerFn);
      eventBus.removeEventListener(onLocalConversationActionEvent);
      messageBus.removeCallback({callback: callbackAfterMessageSent});
      eventBus.removeEventListener(onUserOnlineStatusChanged);
    };
  }, [msgListData?.displayData[0]?.id]);

  useEffect(() => {
    return () => {
      abortControllerRef?.current?.abort();
      isScrollingToPrevMessages.current = false;
      if (refetchRef?.current) {
        clearTimeout(refetchRef?.current);
      }
      if (updateLastSeenRef?.current) {
        clearTimeout(updateLastSeenRef?.current);
      }
    };
  }, []);

  return {
    getSelectedConversationMessageDataWithLastMessage,
    getMessages,
    onFetchMoreIfAvailableCallback,
    onFetchMoreRecentIfAvailableCallback,
    resetAndFetchFirstBatch,
    handleOnScrollToMessageFail,
    handleCreateConversationTask,
    onAddCommunicationType,
    resetAndFetchAllMessages,
    onMessageSendAction,
    onMessageDelete,
    onAttachmentDelete,
    handleCommunicationTypeSaveOnMessageList,
    checkCommunicationTypeAssignedToSelectedMessages,
    onMessageUpdateAfterEditMessageByCurrentUser,
    checkLastNonInternalMessageData,
  };
};
