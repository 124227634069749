import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import SignaturePad from "react-signature-canvas";
import { SyncOutlined } from "@ant-design/icons";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { SignatureSchema } from "../Schema/ComponentsSchema";
import { SignatureComponent } from "../BaseComponents/BaseComponentInterface";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import { FileService } from "../../FHFormio";
import SignatureSvg from "../../../../../assets/Icons/FormBuilder/SignatureSvg";
import { v4 as uuidV4 } from 'uuid';

export const SignatureFieldFormElement: FormElement = {
  type: SignatureSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(SignatureSchema),
    key: getUniqueKey(map, SignatureSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(SignatureSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: SignatureSvg,
    label: SignatureSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, currentValue: string, silentCheck?: boolean): ValidationResult => {
  if (elementInstance.validate?.required && !currentValue) {
    return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: currentValue };
  }
  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: currentValue };
};

function SignaturePadComponent({
  value,
  onChange,
  isReadOnly,
}: {
  value?: string;
  onChange: (signature: string) => void;
  isReadOnly?: boolean;
}) {
  const signaturePad = useRef<SignaturePad | null>(null);

  const handleClear = () => {
    if (signaturePad.current) {
      signaturePad.current.clear();
      onChange('');
    }
  };

  const handleEnd = () => {
    if (signaturePad.current && !signaturePad.current.isEmpty()) {
      const signatureData = signaturePad.current.toDataURL();
      onChange(signatureData);
    }
  };

  const debouncedFromDataURL = useMemo(
    () => debounce((dataURL: string) => {
      signaturePad.current?.fromDataURL(dataURL);
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedFromDataURL.cancel();
    };
  }, [debouncedFromDataURL]);

  useEffect(() => {
    if (value) {
      debouncedFromDataURL(value);
    }
  }, [value]);

  return (
    <div className="signature-container relative">
      {!isReadOnly ? (
        <>
          <SyncOutlined onClick={handleClear} className="absolute top-2 left-2 z-10" />
          <div className="signature-pad-wrapper">
            <SignaturePad
              ref={signaturePad}
              canvasProps={{
                className: "signature-pad",
              }}
              onEnd={handleEnd}
            />
          </div>
        </>
      ) : value ? (
        <div className="signature-preview">
          <img src={value} alt="Signature" />
        </div>
      ) : (
        <div className="no-signature">No signature provided</div>
      )}
    </div>
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (signature: string) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const { label, validate, tooltip, allowToShare, footer } = elementInstance as SignatureComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      <SignaturePadComponent
        value={defaultValue}
        onChange={onChange}
        isReadOnly={isReadOnly}
      />
      {Boolean(footer) && !isReadOnly && (
        <div className="footer-label">{footer}</div>
      )}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [signature, setSignature] = useState<string>();

  const handleChange = (newSignature: string) => {
    setSignature(newSignature);
  };

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={signature}
      onChange={handleChange}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: any;
  isReadOnly?: boolean;
  onRegisterValidation?: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const fileService = new FileService();
  const selectedValue = elementInstance.selectedValue;
  const [signature, setSignature] = useState<any | undefined>(defaultValue || selectedValue);
  const [hasLoadedFromUrl, setHasLoadedFromUrl] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    const loadSignature = async () => {
      if (defaultValue && !hasLoadedFromUrl) {
        // Check if the defaultValue is not a base64 string
        const isBase64String = typeof defaultValue === 'string' && defaultValue.startsWith('data:image');
        const isFileObject = typeof defaultValue === 'object' && defaultValue.url;
        if (!isBase64String && isFileObject) {
          try {
            const result = await fileService.downloadFile(defaultValue);
            setSignature(result ? result.url : undefined);
          } catch (error) {
            console.error('Error downloading signature:', error);
          }
        } else {
          setSignature(defaultValue);
        }
        setHasLoadedFromUrl(true);
      }
    };

    loadSignature();
  }, [defaultValue, hasLoadedFromUrl]);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || '', silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: string) => {
    setSignature(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={signature}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as SignatureComponent;
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);

  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Required',
      input: true,
    },
    ...(isAllowShare ? ShareWithPatientFields : []),
    ...SearchableComponentFields,
    {
      type: 'oldtextfield',
      input: true,
      key: 'footer',
      label: 'Footer Label',
      tooltip: 'The footer text that appears below the signature area.',
      placeholder: 'Footer Label',
      defaultValue: 'Sign above',
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowShare]);

  const { formData, formattedFormData, components, handleFormDataChange } =
    usePropertiesFormRenderer({
      initialValues: element as Record<string, any>,
      components: formElements,
    });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default SignatureFieldFormElement;
