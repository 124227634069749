import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import BasicFields from "../../FHFormio/EditFormFields/BasicFields";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { CurrencySchema } from "../Schema/ComponentsSchema";
import PrefixSuffixFields from "../../FHFormio/EditFormFields/PrefixSuffixFields";
import InputNumberField from "../BaseComponents/InputNumberField";
import { CurrencyComponent } from "../BaseComponents/BaseComponentInterface";
import { CURRENCY_CODES } from "../Constants/constants";
import CurrencySvg from "../../../../../assets/Icons/FormBuilder/CurrencySvg";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { v4 as uuidV4 } from 'uuid';
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";

const formatCurrency = (value: string | number | null, currency?: string): string => {
  if (!value) return '';

  // Remove any non-numeric characters except decimal point
  const cleanValue = String(value).replace(/[^0-9.]/g, '');
  const numValue = Number(cleanValue);
  if (isNaN(numValue)) return '';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const formatted = formatter.format(numValue);
  return formatted.endsWith('.00') ? formatted.slice(0, -3) : formatted;
};

const parseCurrency = (value: string): string => {
  // Only allow numbers and one decimal point
  const cleaned = value ? String(value).replace(/[^0-9.]/g, '') : '';
  const parts = cleaned.split('.');

  // Keep only first decimal point if multiple exist
  if (parts.length > 2) {
    return `${parts[0]}.${parts[1]}`;
  }

  return cleaned;
};

export const CurrencyFieldFormElement: FormElement = {
  type: CurrencySchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(CurrencySchema),
    key: getUniqueKey(map, CurrencySchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(CurrencySchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: CurrencySvg,
    label: CurrencySchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,

};

const localValidate = (elementInstance: FormElementInstance, value: string, silentCheck?: boolean): ValidationResult => {
  if (!value) {
    if (elementInstance.validate?.required) {
      return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: value };
    }
    return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value };
  }

  const element = elementInstance as CurrencyComponent;
  const min = element.validate?.min;
  const max = element.validate?.max;

  const numValue = parseCurrency(value);

  if (isNaN(Number(numValue))) {
    return { isValid: false, errorMessage: 'Please enter a valid amount', key: elementInstance.key, fieldValue: value };
  }

  if (min !== undefined && Number(numValue) < Number(min)) {
    return { isValid: false, errorMessage: `Amount must be greater than or equal to ${formatCurrency(min, element.currency)}`, key: elementInstance.key, fieldValue: value };
  }

  if (max !== undefined && Number(numValue) > Number(max)) {
    return { isValid: false, errorMessage: `Amount must be less than or equal to ${formatCurrency(max, element.currency)}`, key: elementInstance.key, fieldValue: value };
  }

  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (value: string | null) => {
    const result = localValidate(elementInstance, value || '');
    setErrorMessage(result.errorMessage);
    setValue(value || '');
  }

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        onChange={debounce(onChange, 500)}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<string | null>(defaultValue || selectedValue || elementInstance.defaultValue || null);
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: string | null, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || '', silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: true,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string | null) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: result.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: string | null) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value || undefined}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (value: string | null) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const { label, validate, placeholder, description, tooltip, prefix, suffix, allowToShare, currency = 'USD' } = elementInstance as CurrencyComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  const formatter = useMemo(() => ({
    formatter: (value: string | undefined, info: { userTyping: boolean; input: string }) => {
      if (!value) return '';

      // When user is typing, only format if they're not entering decimals
      if (info.userTyping) {
        const isEnteringDecimals = info.input.includes('.');
        if (isEnteringDecimals) {
          return info.input;
        }
      }

      return formatCurrency(value, currency);
    },
    parser: (value: string | undefined) => {
      if (!value) return '';
      return parseCurrency(value);
    },
  }), [currency]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow: backspace, delete, tab, escape, enter, decimal point, and numbers
    const allowedKeys = [
      'Backspace', 'Delete', 'Tab', 'Escape', 'Enter', '.',
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ];

    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }

    // Only allow one decimal point
    if (e.key === '.' && (e.target as HTMLInputElement).value.includes('.')) {
      e.preventDefault();
    }
  };

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label label={label || ''} isRequired={validate?.required || false} tooltip={tooltip} isReadOnly={isReadOnly} />
      {isReadOnly && !isPrintForm ? (
        <div className="field-value">{defaultValue ? formatCurrency(defaultValue, currency) : '-'}</div>
      ) : (
        <InputNumberField
          addonBefore={prefix}
          addonAfter={suffix}
          errorMessage={errorMessage}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          min={validate?.min || validate?.min === 0 ? String(validate.min) : undefined}
          max={validate?.max || validate?.max === 0 ? String(validate.max) : undefined}
          formatter={formatter.formatter}
          parser={formatter.parser}
          precision={2}
          decimalSeparator="."
          onKeyDown={handleKeyDown}
          controls={false}
          disabled={isReadOnly || false}
        />
      )}
      {description && (
        <Description description={description} />
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as CurrencyComponent;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() =>  [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'oldtextfield',
      input: true,
      key: 'placeholder',
      label: 'Placeholder',
      placeholder: 'Placeholder',
      tooltip: 'The placeholder text that will appear when this field is empty.',
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
    {
      type: 'select',
      input: true,
      key: 'currency',
      label: 'Currency',
      tooltip: 'The currency to use in currency formatting.',
      defaultValue: 'USD',
      dataSrc: 'values',
      data: {
        values: CURRENCY_CODES,
      },
    },
    ...PrefixSuffixFields,
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
