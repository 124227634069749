import {useLazyQuery} from '@apollo/client';
import {AutoComplete, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';
import {IAutoComplete} from '../../../Interfaces';
import {ZipCodeQueries} from '../../../services';
import {ModalActionAutoComplete} from '../ModalActionCommonComponent/ModalActionAutoComplete';
import {IState, IStateVar} from './interfaces';
const {Option} = AutoComplete;

const StateAutoComplete = (props: IAutoComplete) => {
  const [stateData, setStateData] = useState<IState[]>([]);
  const [stateName, setStateName] = useState('');
  const [getStates] = useLazyQuery<{states: IState[]}, IStateVar>(
    ZipCodeQueries.getStatesBySearchString,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [loadingStates, setLoadingStates] = useState(false);
  const searchState = (searchString: string) => {
    setStateData([]);
    if (searchString.length > 1) {
      setLoadingStates(true);
      getStates({
        variables: {
          searchString: searchString + '%',
          limit: 50,
        },
      })
        .then((stateRespData) => {
          setStateData(stateRespData?.data?.states || []);
          setLoadingStates(false);
        })
        .catch((error) => {

          setStateData([]);
          setLoadingStates(false);
        });
    }
  };
  useEffect(() => {
    setStateName(props?.name || '');
  }, [props.name]);
  return (
    <>
      <ModalActionAutoComplete
        isInvalid={props.isInvalid}
        errors={props.errors}
        errorText={props.errorText}
        value={stateName}
        disabled={props?.isTabDisabled}
        label={'state'}
        defaultValue={props.name}
        placeholder="Search State"
        isRequired={props?.isRequired}
        onClear={() => {
          props.onAutoCompleteChange(null);
        }}
        onSelect={(value: any, options: any) => {
          const selectedState = stateData.find(
            (item) => item.id == options?.key || ''
          );
          props.onAutoCompleteChange(
            options?.key || '',
            selectedState?.abbr || '',
            selectedState?.name || ''
          );
        }}
        onSearch={debounce(searchState, 500)}
        onChange={(value: any) => {
          if (!value) {
            props.onAutoCompleteChange(null);
          }
          setStateName(value || '');
        }}
        notFoundContent={loadingStates && <Spin size="small" />}
        customStyle={{flex: props.flex ? props.flex : 0.48}}
        data={stateData}
        optionProps={{id: 'id', code: 'name', value: 'name'}}
      />
    </>
  );
};

export default StateAutoComplete;
