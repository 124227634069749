import { useApolloClient } from "@apollo/client";
import React from "react";
import LeadQueries from "../../../services/Lead/LeadQueries";
import { IContactProfile } from "../../RightSideContainer/TeamInbox/Conversations/interfaces";
import { CARESTUDIO_PROXY_TO_CRM_CONTEXT } from "../../../constants/Configs";

export interface IUseContactProfileResponse {
  id: number,
  uuid: string,
  name: string,
  contactProfiles: IContactProfile[]
};

const useContactProfile = (props: {
  contactId?: string | number,
  contactUuid?: string,
  fetchAllProfiles?: boolean,
  headers?:{[index: string]: any}
}) => {
  const [contactProfile, setContactProfile] = React.useState<IUseContactProfileResponse | null>(null);
  const client = useApolloClient();

  const getAllPatientContactProfiles = async (props: {
    contactId?: string | number,
    contactUuid?: string,
    fetchAllProfiles?: boolean,
    contactIds?: string[],
    contactUuids?: string[],
    headers?:{[index: string]: any}
  }) => {
    const { contactId, contactUuid, fetchAllProfiles, contactIds, contactUuids } = props;
    if (contactId || contactUuid || contactIds || contactUuids) {
      const whereCondition = {
        ...(contactId && {
          id: {
            _eq: contactId,
          },
        }),
        ...(contactUuid && {
          uuid: {
            _eq: contactUuid,
          },
        }),
        ...(contactIds && {
          id: {
            _in: contactIds,
          },
        }),
        ...(contactUuids && {
          uuid: {
            _in: contactUuids,
          },
        }),
        ...(!fetchAllProfiles && {
          contactProfiles: {
            isActive: {
              _eq: true,
            },
          },
        }),
      }
      try {
        const contact = await client.query({
          query: LeadQueries.GetContactProfiles,
          variables: {
            getContactProfilesWhereCondition: whereCondition,
          },
          fetchPolicy: 'no-cache',
          context: {
            service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
            headers: props?.headers,
          },
        });
        return contact?.data?.contacts?.[0] as IUseContactProfileResponse;
      } catch (error) {
      }
    } else {
      return null;
    }
  }

  const getData = async () => {
    const patientContactProfiles = await getAllPatientContactProfiles({
      contactId: props.contactId,
      contactUuid: props.contactUuid,
      fetchAllProfiles: props.fetchAllProfiles,
    });
    if(patientContactProfiles){
      setContactProfile(patientContactProfiles);
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  return {
    contactProfile,
    getAllPatientContactProfiles,
  }
}

export default useContactProfile;