import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
  Drawer,
  Skeleton,
  Tooltip,
  notification
} from 'antd';
import parse from 'html-react-parser';
import {
  Badge,
  Box,
  Button,
  HStack,
  Icon,
  Skeleton as NBSkeleton,
  Pressable,
  Spacer,
  Spinner,
  Text,
  VStack,
  View,
  useMediaQuery,
  useToast
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { useNavigate } from 'react-router-dom';
import { IMlov, IUser } from '../../../../../../Interfaces/CommonInterfaces';
import {
  BUTTON_TYPE,
  CUSTOM_AUTOMATION_URL,
  DATE_FORMATS,
  DISPLAY_DATE_FORMAT,
  ENTITY_EVENTS_TITLE_SUBTITLE,
  ENTITY_EVENTS_TYPE,
  GROUP_MEMBER_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
  MLOV_CATEGORY
} from '../../../../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  APPOINTMENT_STATUS_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CODES,
  NOTE_SUBJECT_TYPE_CODES,
} from '../../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import {
  AppointmentQueries,
  ScheduleEventQueries,
  UserQueries,
} from '../../../../../../services';
import ContactsQueries from '../../../../../../services/Contacts/ContactsQueries';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import { Colors } from '../../../../../../styles';
import {
  getCurrentTimeZone, getDateStrFromFormat, getMomentObj
} from '../../../../../../utils/DateUtils';
import {
  getAccountUUID,
  getContactUuid,
  getPatientActiveProfileLocationIds,
  getPatientActiveProfiles,
  getUserData,
  getUserEmail,
  getUserUUID,
  isResourceLocationActive,
  isZoomEnabled
} from '../../../../../../utils/commonUtils';

import { Coding } from 'fhir/r4';
import { PATIENT_QUICK_PROFILE_PARENT_CODES } from '../../../../../../constants/ActionConst';
import { getEhrConfig } from '../../../../../../utils/capabilityUtils';

import { cloneDeep } from 'lodash';
import ReccuringAppointmentIcon from '../../../../../../assets/Icons/ReccuringAppointmentIcon';
import {
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromCode,
} from '../../../../../../utils/mlovUtils';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import { withMiniContactViewHOC } from '../../../../../MiniContactViewHOC';
import {
  USER_ACCESS_PERMISSION,
  isAutomationAllowed,
} from '../../../../../RightSideContainer/UserAccess/UserAccessPermission';
import {
  getGroupsList
} from '../../../../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';
import { TaskCheckList } from '../../../../AppointmentTaskCheckList/TaskCheckList';
import ContactAutoCompleteCustom from '../../../../ContactRelationView/ContactAutoCompleteCustom';
import { DisplayCardAvatar } from '../../../../DisplayCard/DisplayCardAvatar';
import { DisplayText } from '../../../../DisplayText/DisplayText';
import FHActionPopover, {
  IFHPopoverAction,
} from '../../../../FHActionPopover/FHActionPopover';
import FHDrawerAlert from '../../../../FHDrawerAlert/FHDrawerAlert';
import { ModalActionAntSelect } from '../../../../ModalActionCommonComponent/ModalActionAntSelect';
import { ModalActionTitle } from '../../../../ModalActionTitle/ModalActionTitle';
import { joinZoomMeeting } from '../../../../ZoomView/ZoomServices';
import { IAppointmentDetail } from '../../../CalendarWidgetInterfaces';
import { getAppointmentDateTime } from '../../BookAppointment/BookAppointmentHelper';
import BlockTimeBooking from '../BlockTimeBooking/BlockTimeBooking';
import AppointmentBooking from './AppointmentBooking';
import {
  AppointmentAction, AppointmentAPIErrorCodes
} from './AppointmentBookingEnums';
import {
  getAppointmentActionSuccessToastMessage,
  getAppointmentActions,
  getAppointmentStatus,
  getCompletePracticeLocation,
  getDataForAppointmentStatusUpdate,
  getDataForAppointmentWorkflowUpdate,
  getLocationCode,
  getPrimaryAndSecondaryContacts,
  getSecondaryUsersFromParticipantObjectsForRescheduleView,
  getUserFromParticipantObjectsForRescheduleView,
  isFutureMeeting,
  isPastMeeting,
  mapParticipantObjectsForRescheduleView,
  generateAppointmentBookingLongLink
} from './AppointmentBookingHelper';
import {
  GroupAppointmentParticipantActions,
  IAppointmentDetailState,
  IAppointmentNote,
  IBookingLinkParams,
  IParticipantData,
  IRecordingLink,
} from './AppointmentBookingIntefaces';
import AppointmentBookingLinkCard from './AppointmentBookingLinkCard';
import AppointmentCancelReason from './AppointmentCancelReason';
import AppointmentContactBanner from './AppointmentContactBanner';
import { AppointmentPatientBannerLoading } from './AppointmentPatientBannerLoading';
import { AppointmentRSVP } from './AppointmentRSVP';
import SideTrackingView from './SideTrackingView/SideTrackingView';
import WorkFlowListByEntityEvents from './WorkFlowListByEntityEvents';
import { SendAppointmentLinkDrawer } from './SendAppointmentLinkDrawer';
import { GET_APPOINTMENT_MEETING_LINK_OF_PARTICIPANT, GET_PARTICIPANT_ID_BY_CONTACT_ID, GET_PARTICIPANT_ID_BY_CONTACT_ID_LIST } from '../../../../../../services/Appointment/AppointmentQueries';
import RecordingListView from './components/RecordingListView';
import { isZoomMeetingId } from '../../../../../../services/HmsMeetingServices';
import BottomTabs, { AUDIT_RESOURCE } from './BottomTabs';
import { TASK_VIEW_TABS } from '../../../../AddTask/AddEditComment/TaskCommentsHelper';
import { IAppointmentType } from '../../BookAppointment/Interfaces';
import { getShortLink } from '../../../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import LinkIcon from '../../../../../../assets/Icons/LinkIcon';
import AddInviteMembersView from './AddInviteMembersView/AddInviteMembersView';
import { IContact, IContactProfile } from '../../../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { useUserSetting } from '../../../../../CustomHooks/useUserSetting';
import CrossIconSvg from '../../../../Svg/CrossIconSvg';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import useLoggedInUserAllowedLocationsByTab from '../../../../../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import { StyleSheet } from 'react-native';
import useContactProfile from '../../../../ContactProfile/useContactProfile';

const TRACKING_SECTION_VIEW = {
  DEFAULT: 'default',
  SEARCH: 'search',
  SEE_ALL: 'see_all',
};
const AppointmentDetail = (props: {
  isLoading?: boolean;
  isAppointmentSummary?: boolean;
  hideAction?: boolean;
  event?: any;
  reasonsForVisitList?: any[];
  isVisible: boolean;
  onClose: (refreshCalendar?: boolean) => void;
  onStatusChange: () => void;
  onComplete?: (data?: any) => void;
  navigateOrOpenContactIdDrawer?: (contactId: string) => void;
  appointmentTypeList?: IAppointmentType[];
  lastAwvProgramDate?:string
  showAwvCard?: boolean;
  drawerWidth?: string;
  isHideViewDetailedProfile?: boolean;
  disableSelectSlotBy?: boolean;
  disablePrimaryUserSelect?: boolean;
  appointmentVisitType?: string;
  onlyAtClinicAppointmentEnabled?: boolean;
  disableAppointmentReccurence?: boolean;
  isCareProgramAppointment?: boolean;
  contactId?: string;
  contactLocationId?: string;
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const intl = useIntl();
  const userEmail = getUserEmail();
  const accountUUID = getAccountUUID();
  const mlovData = useContext(CommonDataContext);
  const userUuid = getUserUUID();
  const userData = getUserData();
  const ehrConfig = getEhrConfig(props?.event?.detail?.accountLocationId, '');
  const [statusLoading, setStatusLoading] = useState(false);
  const [showModalAwvCard, setShowModalAwvCard] = useState(true);
  const [popover, setPopover] = useState(false);
  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;
  const [contactProfiles, setContactProfiles] = useState<IContactProfile[]>([]);
  const [filterStatusId, setFilterStatusId] = useState<
    string | undefined | null
  >(undefined);
  const [shouldShowTracking, setShouldShowTracking] = useState(false);
  const [shouldShowInviteUser, setShouldShowInviteUser] = useState(false);
  const [inviteUserLoading, setInviteUserLoading] = useState(false);
  const [inviteSentOrDeleted, setInviteSentOrDeleted] = useState(false)
  const disableBlockTime = useUserSetting('disable_block_time_scheduling');
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const [participantStatusLoadingId, setParticipantStatusLoadingId] =
    useState<string>('');
  const [isMultipleBlockWithExternalId, setIsMultipleBlockWithExternalId] = useState(false);
  const userRoles =
    getMlovListFromCategory(mlovData.MLOV, MLOV_CATEGORY.USER_ROLES) || [];
  const defaultTimeZone = getCurrentTimeZone();

  const [getContactDetailsById] = useLazyQuery(
    ContactsQueries.GET_CONTACT_DETAILS_BY_ID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const appointmentStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const APPOINTMENT_PARTICIPANT_STATUS_LIST: any = {
    accepted: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
    ),
    declined: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
    ),
    // need_action: getMlovId(
    //   mlovData.CARE_STUDIO_MLOV,
    //   MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
    //   APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    // )
  };

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
    scheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.SCHEDULED
    ),
    noShow: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.NO_SHOW
    ),
  };

  const appointmentParticipantType =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_TYPE
    ) || [];
  const APPOINTMENT_PARTICIPANT_TYPE_IDS = {
    patient: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT
    ),
    primaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
    ),
    secondaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.SECONDARY_USER
    ),
  };

  const scheduleLocationTypeList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE
    ) || [];

  const noteSubjectTypeList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.NOTE_SUBJECT_TYPE
    ) || [];

  const currentUserUuid = getUserUUID();

  const [events, setEvents] = useState<any>(props.event);
  const [detailLoading, setDetailLoading] = useState<boolean>(false);


  const [getContactsFromIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
    }
  );


  const [getUsersByUserUuidsAPI] = useLazyQuery<{
    accountUsers: {
      users: IUser;
    }[];
  }>(UserQueries.GetUsersByUserUuids, {
    fetchPolicy: 'no-cache',
  });

  const [getParticipantIdByContactId] = useLazyQuery(
    GET_PARTICIPANT_ID_BY_CONTACT_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getParticipantIdByContactIdList] = useLazyQuery(
    GET_PARTICIPANT_ID_BY_CONTACT_ID_LIST,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [getAppointmentMeetingLinkOfParticipantAPI] = useLazyQuery(
    GET_APPOINTMENT_MEETING_LINK_OF_PARTICIPANT,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    },
  );

  const [getAppointmentApi] = useLazyQuery<{
    appointments?: IAppointmentDetail[];
  }>(AppointmentQueries.GET_APPOINTMENT_BY_ID_WITH_GROUP, {
    fetchPolicy: 'no-cache',
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [getAppointmentIdsFromExternalIds] = useLazyQuery(AppointmentQueries.GET_APPOINTMENTS_BY_EXTERNAL_ID, {
    fetchPolicy: 'no-cache',
    context: { service: CARESTUDIO_APOLLO_CONTEXT }
  });

  const getAppointmentsByExternalId = async (externalAppointmentId: string) => {
    try {
      const externalIdMatchAppointmentResponse = await getAppointmentIdsFromExternalIds({
        variables: {externalAppointmentIds: [externalAppointmentId], foldAppointmentIds: []}
      });
      return externalIdMatchAppointmentResponse?.data?.appointments || [];
    } catch(error){

    }
  };

  const isBlockedInterval = props?.event?.detail?.isBlockedInterval;
  //function to get contact and user info and add it to appointmentdetails
  const updateAppointmentDetails = async (eventsInput: any) => {
    const isBlockedInterval = props?.event?.detail?.isBlockedInterval;
    const appointmentId = props?.event?.detail?.id;
    let matchExternalAppointments = [];
    let externalAppointmentId = props?.event?.detail?.externalAppointmentId;
    // fetch appointment detail based on external appointment id
    if (isBlockedInterval) {
      setDetailLoading(true);
      if (externalAppointmentId) {
        matchExternalAppointments = await getAppointmentsByExternalId(externalAppointmentId);
      } else {
        const appointmentResponse = await getAppointmentIdsFromExternalIds({
          variables: {externalAppointmentIds: [], foldAppointmentIds: [appointmentId]},
        });
        if (appointmentResponse?.data?.appointments?.length) {
          externalAppointmentId = appointmentResponse?.data?.appointments?.[0]?.externalAppointmentId;
          matchExternalAppointments = await getAppointmentsByExternalId(externalAppointmentId);
        }
      }
      if (matchExternalAppointments?.length && matchExternalAppointments?.length > 1) {
        setIsMultipleBlockWithExternalId(true);
      }
    }
    if (
      events.isContactProcessed ||
      !eventsInput.detail?.participants?.length
    ) {
      setDetailLoading(false);
      return;
    }

    const eventsTemp = {...eventsInput};

    if (eventsInput.detail?.participants?.length) {
      setDetailLoading(true);
      const contactDetails = await getContactsFromIds({
        variables: {
          contactIds: eventsInput.detail?.participants
            ?.filter((contact: any) => (contact.contactId ? true : false))
            ?.map((contact: any) => contact.contactId),
        },
      });

      const formattedContactData: any = {};
      if (contactDetails.data?.contacts?.length) {
        contactDetails.data.contacts.forEach((contact: any) => {
          formattedContactData[contact.uuid] = contact;
        });
      }
      const userData = await getUsersByUserUuidsAPI({
        variables: {
          userUuids: eventsInput.detail?.participants
            ?.filter((contact: any) => (contact.userId ? true : false))
            ?.map((contact: any) => contact.userId),
        },
      });

      if (userData?.data?.accountUsers?.length) {
        userData?.data?.accountUsers.forEach((accountuser: any) => {
          if (accountuser?.user?.uuid) {
            formattedContactData[accountuser.user.uuid] = accountuser.user;
          }
        });
      }

      if (contactDetails.data?.contacts?.length) {
        eventsTemp.detail.participants = eventsTemp.detail.participants.map(
          (participant: any, index: number) => {
            let data = {
              ...participant,
            };
            if (participant.contactId) {
              const contactData = formattedContactData[participant.contactId];
              data = {
                ...data,
                contact: contactData,
              };
            }

            if (participant.userId) {
              const userDataRaw = formattedContactData[participant.userId];
              data = {
                ...data,
                user: userDataRaw,
              };
            }
            return data;
          }
        );
      }
      setDetailLoading(false);
    }

    if(eventsInput.detail?.contactId){
      const response = await getContactDetailsById({
        variables: {
          contactId: eventsInput.detail?.contactId,
        },
      });
      eventsTemp.detail.contact = response?.data?.contacts?.[0];
    }
    eventsTemp.isContactProcessed = true;
    setEvents(eventsTemp);
  };

  const defaultState: IAppointmentDetailState = {
    detail: events?.detail,
    appointmentStatusId: undefined,
    isReschedule: false,
    location: undefined,
    appointmentTypeRole: undefined,
    participant: {},
    contactList: [],
    primaryUsers: [],
    secondaryUsers: [],
    userList: [],
    primary: undefined,
    otherParticipants: [],
    rescheduleType: undefined,
  };

  const [detailState, setDetailState] =
    useState<IAppointmentDetailState>(defaultState);

   const { getAllPatientContactProfiles } = useContactProfile({});
   const accountLocationListWithEHR = mlovData?.accountLocationListWithEHR || [];
   const activecontactProfiles = getPatientActiveProfiles(contactProfiles || []);
   const activeLocationIds = getPatientActiveProfileLocationIds(activecontactProfiles || []);
   const hasResourceLocationActiveByLocationId = isResourceLocationActive(activeLocationIds || [], detailState?.detail?.accountLocationId || '');
   const locationGroupIdListByPatientContext = accountLocationListWithEHR
   ?.filter((item) => item.uuid === props?.contactLocationId) 
   .map((item) => item.locationGroupId) 
   .filter((locationGroupId, index, self) => locationGroupId && self.indexOf(locationGroupId) === index);
   const hasResourceLocationActiveByLocationGroupId = isResourceLocationActive(locationGroupIdListByPatientContext || [],  detailState?.detail?.locationGroupId|| '');
   const hasResourceLocationActive = ( detailState?.detail?.accountLocationId !== null) ? hasResourceLocationActiveByLocationId : hasResourceLocationActiveByLocationGroupId ;
   const isActionsDisabled = isMsoFlagEnabled ? !hasResourceLocationActive : false;
  const [modalState, setModalState] = useState({
    cancelAppointment: false as boolean,
    noShow: false as boolean,
    cancelWithNoShow: false as boolean,
    cancelReason: undefined as Coding | undefined,
    showEditBlockTime: false as boolean,
    isForSeries: false as boolean,
    removeSeries: false as boolean,
    type: '',
    isDeleted: false,
  });

  const [sendAppointmentLinkState, setSendAppointmentLinkState] = useState({
    isOpen: false,
    linkCode: '',
    participantId: '',
    appointmentId: '',
    contactData: undefined as any,
    appointment: undefined as IAppointmentDetail | undefined,
  });

  const [joinMeetingConfirmation, setJoinMeetingConfirmation] = useState<{
    visible: boolean;
    header: string;
    message: string;
  }>({
    visible: false,
    header: '',
    message: '',
  });

  const isGroupSessionAppointment =
    detailState?.detail?.appointmentType?.category?.code ===
    MLOV_CODES.GROUP_SESSION;

  const isRecurringAppointment = detailState.detail?.isRecurrentAppointment;
  const isRsvpEnabled = detailState?.detail?.isRsvpEnabled || false;

  const [getContactDetails, contactDetailAPIQuery] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getAppointmentLocation, locationAPIQuery] = useLazyQuery(
    UserPracticeLocationQueries.GET_ACCOUNT_LOCATIONS_DETAIL,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [bookAppointment] = useMutation(AppointmentQueries.BOOK_APPOINTMENT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: (e) => {
      props.onStatusChange();

      if (e?.createBookedAppointments?.length) {
        setDetailState((prev) => ({
          ...prev,
          appointmentStatusId: e.createBookedAppointments[0].statusId,
        }));
      }
      closeModal();
      setStatusLoading(false);
    },
    onError: (error) => {
      setStatusLoading(false);

    },
  });

  const [bookAppointmentWorkflowUpdate] = useMutation(
    AppointmentQueries.UPDATE_APPOINTMENT_WORKFLOW,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: () => {
        props.onStatusChange();
        setStatusLoading(false);
      },
      onError: (error) => {

      },
    }
  );

  const [getAppointmentType, appointmentTypeQuery] = useLazyQuery(
    ScheduleEventQueries.GET_APPOINTMENT_TYPES,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const [updateAppointmentParticipant] = useMutation(
    AppointmentQueries.UPDATE_PARTICIPANT_STATUS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const locationCode = getLocationCode(
    detailState?.detail,
    scheduleLocationTypeList
  );

  const appointmentStatusId = detailState?.detail?.statusId;
  const isAppointmentStatusPending =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.pending;
  const isAppointmentStatusDecline =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.decline;
  const isAppointmentStatusProposed =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.proposed;

  const onEventChange = async () => {
    let isZoomMeeting = false;
    let participants: any = undefined;
    if (events && events.detail) {
      const uniqueParticipantIds: any = [];
      const meetingId = events.detail?.reference?.meetingInfo?.meeting?.meetingId || events.detail.reference?.meetingInfo?.id;
      if (meetingId) {
        isZoomMeeting = await isZoomMeetingId({meetingId});
      }
      if (events.detail?.participants?.length) {
        events.detail.participants = events.detail.participants.filter(
          (participant: any) => {
            const uniqueId =
              participant?.contact?.uuid || participant?.user?.uuid;
            if (uniqueId) {
              if (uniqueParticipantIds.includes(uniqueId)) {
                return false;
              }
              uniqueParticipantIds.push(uniqueId);
              return true;
            } else {
              return true;
            }
          }
        );
      }

      participants = getPrimaryAndSecondaryContacts(
        events.detail,
        APPOINTMENT_PARTICIPANT_TYPE_IDS
      );
    }

    if (events?.detail?.participants) {
      const loggedInParticipant = events.detail.participants.find(
        (participant: IParticipantData) => {
          if (
            participant?.user?.uuid &&
            participant.user.uuid === currentUserUuid
          )
            return true;
        }
      );

      if (loggedInParticipant) {
        const participantFound = events?.detail?.participants?.find(
          (participant: any) => participant?.id === loggedInParticipant.id
        );
        const isInitiator = participantFound?.isInitiator || false;
        const isParticipantLoggedInUser =
          participantFound?.id === loggedInParticipant.id;
        const isAppointmentStatusProposed =
          events?.detail?.statusId === APPOINTMENT_STATUS_IDS.proposed;
        const showProposedTimeBanner =
          isParticipantLoggedInUser &&
          isAppointmentStatusProposed &&
          isInitiator;
        setDetailState((prevState) => ({
          ...prevState,
          loggedInParticipantId: loggedInParticipant.id,
          participant: participantFound,
          isInitiator: isInitiator,
          isParticipantLoggedInUser: isParticipantLoggedInUser,
          showProposedTimeBanner: showProposedTimeBanner,
          isZoomMeeting: isZoomMeeting,
        }));
      }
    }
    const groupIdListMap = new Set<string>();

    participants?.contactList?.forEach((participant: any) => {
      if (participant?.reference?.groups) {
        participant?.reference?.groups?.forEach((group: any) => {
          groupIdListMap.add(group?.groupId);
        });
      }
    });
    const groupIdList = Array.from(groupIdListMap);
    const groupParticipantsMap: any = {};
    groupIdList.map((groupId) => {
      const contactIds: string[] = [];
      participants?.contactList?.forEach((participant: any) => {
        const idList = participant?.reference?.groups?.map(
          (group: any) => group.groupId
        );
        if (idList?.includes(groupId)) {
          contactIds.push(participant.contactId);
        }
      });
      groupParticipantsMap[groupId] = contactIds;
    });
    const groupDataMap: any = {};
    if (groupIdList.length) {
      const res = await getGroupsList(
        accountUUID,
        100,
        '',
        undefined,
        undefined,
        undefined,
        groupIdList
      );
      res?.data?.map((group: {id: string; name: string}) => {
        groupDataMap[group?.id as keyof typeof groupParticipantsMap] =
          group?.name;
      });
    }
    if(participants?.contactList?.length) {
      const contactUuid = participants?.contactList?.[0]?.contactId || participants?.contactList?.[0]?.contact?.uuid;
      if(isMsoFlagEnabled){
        await fetchContactProfiles(contactUuid);
      }
    }

    setDetailState((prevState) => ({
      ...prevState,
      userList: participants?.userList || [],
      primaryUsers: participants?.primaryUsers || [],
      secondaryUsers: participants?.secondaryUsers || [],
      contactList: participants?.contactList || [],
      individual: participants?.contactList?.filter(
        (participant: any) => !participant.reference?.groups
      ),
      groupDataMap,
      groupParticipantsMap,
      detail: events?.detail,
      appointmentStatusId: events?.detail?.statusId,
      isReschedule: false,
      location: undefined,
      isZoomMeeting: isZoomMeeting,
    }));

    if (events?.detail?.accountLocationId) {
      getAppointmentLocation({
        variables: {
          accountLocationIds: [events.detail.accountLocationId],
        },
      })
        .then((response) => {
          if (response?.data?.accountLocations?.length) {
            const accountPracticeLocation = response?.data?.accountLocations[0];
            setDetailState((prev) => ({...prev, accountPracticeLocation}));
          }
        })
        .catch((error: ApolloError) => {

        });
    }
    setInviteUserLoading(false)

    // getAppointmentType({
    //   variables: {
    //     searchString: `%%`,
    //     categoryCodes: [MLOV_CODES.ONE_ON_ONE_APPOINTMENT, MLOV_CODES.GROUP_SESSION],
    //   },
    // })
    //   .then((response) => {
    //     const appointmentList: IAppointmentType[] =
    //       response?.data?.appointmentTypes || [];
    //     const appointment = events.detail;
    //     const appointmentTypeId = appointment?.appointmentType?.id;
    //     let selectedAppointment: any = undefined;
    //     let selectedAppointmentRoleType: string | undefined = undefined;
    //     if (appointmentTypeId && appointmentList?.length) {
    //       selectedAppointment =
    //         appointmentList.find((appointmentType) => {
    //           return appointmentType.id === appointmentTypeId;
    //         }) || ({} as IAppointmentType);
    //       if (
    //         selectedAppointment?.id &&
    //         selectedAppointment?.appointmentTypeGroup?.length
    //       ) {
    //         const roleId = selectedAppointment.appointmentTypeGroup[0].roleId;
    //         userRoles.some((role) => {
    //           if (role.id === roleId) {
    //             selectedAppointmentRoleType = role.value;
    //           }
    //         });
    //       }
    //     }
    //     setDetailState((prev) => ({
    //       ...prev,
    //       appointmentTypeRole: selectedAppointmentRoleType,
    //     }));
    //   })
    //   .catch((error: ApolloError) => {
    //
    //   });
  };

  useEffect(() => {
    onEventChange();
  }, [events]);

  useEffect(() => {
    updateAppointmentDetails(props.event);
  }, [props.event]);

  const showLocalToast = (message: string) => {
    showToast(toast, message, ToastType.success);
  };

  const onContactClicked = (contactId: any, contactType?: string) => {
    if (contactType == 'CUSTOMER') {
      props?.navigateOrOpenContactIdDrawer?.(contactId);
    } else if (contactType == 'LEAD') {
      navigate(`/members/prospect/${contactId}`);
    }
  };

  // cancel Top bar action
  const getCancelConfirmation = (
    isForSeries?: boolean,
    type?: AppointmentAction,
    isDeleted?: boolean
  ) => {
    setModalState((prev) => ({
      ...prev,
      cancelAppointment: true,
      isForSeries: !!isForSeries,
      isDeleted: !!isDeleted,
      type: type || '',
    }));
  };
  // Status Change All in One Fn
  const onStatusUpdate = (statusId: string) => {
    if (statusId === APPOINTMENT_STATUS_IDS.cancelled) {
      getCancelConfirmation();
    } else if (statusId === APPOINTMENT_STATUS_IDS.noShow) {
      handleNoShowConfirmation();
    } else {
      if (events && events.detail) {
        const appointment = events.detail;
        const data = getDataForAppointmentStatusUpdate(appointment, statusId);
        setStatusLoading(true);
        bookAppointment({
          variables: {
            data: data,
          },
        });
      }
    }
  };
  // TOP BAR ACTION : CANCEL
  const onCancelAppointment = (
    isForSeries?: boolean,
    type?: AppointmentAction,
    isDeleted?: boolean
  ) => {
    if (events && events.detail) {
      setStatusLoading(true);
      const appointment = events.detail;
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.cancelled,
        false,
        modalState.cancelReason,
        isForSeries,
        isDeleted
      );
      bookAppointment({
        variables: {
          data: data,
        },
        onCompleted: (e) => {
          props.onStatusChange();

          if (e?.createBookedAppointments?.length) {
            setDetailState((prev) => ({
              ...prev,
              appointmentStatusId: e.createBookedAppointments[0].statusId,
            }));
          }
          closeModal();
          setStatusLoading(false);
          if (type) {
            notification.success({
              message: getAppointmentActionSuccessToastMessage(
                type as AppointmentAction
              ),
            });
          }
        },
        onError: () => {
          notification.error({
            message: intl.formatMessage({id: 'apiErrorMsg'}),
          });
          setStatusLoading(false);
        },
      });
    }
  };

  // TOP BAR ACTION : UnBlock
  const onUnblockUserSlots = (
    isForSeries?: boolean,
    type?: AppointmentAction
  ) => {
    if (events && events.detail && isMultipleBlockWithExternalId) {
      notification.info({
        message: 'The associated slot in EHR will be removed only when all associated locations on Fold are unblocked for this time slot.',
        placement: 'topRight',
      });
    }
    if (events && events.detail) {
      setStatusLoading(true);
      const appointment = events.detail;
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.cancelled,
        true,
        undefined,
        isForSeries
      );
      bookAppointment({
        variables: {
          data: data,
        },
        onCompleted: (e) => {
          props.onStatusChange();

          if (e?.createBookedAppointments?.length) {
            setDetailState((prev) => ({
              ...prev,
              appointmentStatusId: e.createBookedAppointments[0].statusId,
            }));
          }
          closeModal();
          setStatusLoading(false);
          if (type !== AppointmentAction.unblock) {
            notification.success({
              message: getAppointmentActionSuccessToastMessage(
                type as AppointmentAction
              ),
            });
          }
        },
        onError: (error) => {
          setStatusLoading(false);

          notification.error({
            message: intl.formatMessage({id: 'apiErrorMsg'}),
          });
        },
      });
    }
  };
  // Modal has to be shown so different fn
  const onNoShowAppointment = () => {
    setStatusLoading(true);
    if (events && events.detail) {
      const appointment = events.detail;
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.noShow
      );
      bookAppointment({
        variables: {
          data: data,
        },
      });
    }
  };
  // TOP BAR ACTION : RESCHEDULE
  const onRescheduleAppointment = () => {
    setDetailState((prev) => ({...prev, isReschedule: true}));
  };

  const closeModal = (isAppointmentDataChanged?: boolean) => {
    setDetailState({
      ...defaultState,
      detail: undefined,
      rescheduleType: undefined,
    });
    setModalState((prev) => ({
      ...prev,
      cancelAppointment: false,
      cancelWithNoShow: false,
      cancelReason: undefined,
      isForSeries: false,
      isDeleted: false,
      type: '',
    }));
    resetJoinMeetingData();
    props.onClose(isAppointmentDataChanged);
  };

  const fetchContactProfiles = async (contactId?: string) => {
    try {
      setStatusLoading(true);
      const profiles = await getAllPatientContactProfiles({ contactUuid: contactId, fetchAllProfiles: true });
      setContactProfiles(profiles?.contactProfiles || []);
    } catch(error) {
    } finally {
      setStatusLoading(false);
    }
  };
    

  const closeNoShowModal = (isAppointmentDataChanged?: boolean) => {
    setDetailState({...defaultState, detail: undefined});
    setModalState((prev) => ({...prev, noShow: false}));
    resetJoinMeetingData();
    props.onClose(isAppointmentDataChanged);
  };
  // TOP BAR ACTION : JOIN MEETING CLICK
  const onJoinMeetingClicked = () => {
    const isFutureAppointment = isFutureMeeting(events?.detail);
    const isPastAppointment = isPastMeeting(events?.detail);
    if (isFutureAppointment || isPastAppointment) {
      setJoinMeetingConfirmation({
        visible: true,
        header: intl.formatMessage({
          id: isFutureAppointment
            ? 'joinFutureAppointment'
            : 'joinPastAppointment',
        }),
        message: intl.formatMessage({
          id: isFutureAppointment
            ? 'joinFutureAppointmentMsg'
            : 'joinPastAppointmentMsg',
        }),
      });
    } else {
      joinMeeting();
    }
  };

  const resetJoinMeetingData = () => {
    setJoinMeetingConfirmation({
      visible: false,
      message: '',
      header: '',
    });
  };
  // TOP BAR ACTION : JOIN MEETING
  const isZoomEnabledForAccount = isZoomEnabled();
  const joinMeeting = async () => {
    if (isGroupSessionAppointment) {
      const loggedInParticipant = events.detail.participants.find(
        (participant: IParticipantData) => {
          if (
            participant?.user?.uuid &&
            participant.user.uuid === currentUserUuid
          )
            return true;
        }
      );
      if (loggedInParticipant?.reference?.meetingInfo?.link) {
        window.open(
          loggedInParticipant?.reference?.meetingInfo?.link,
          '_blank',
          'noopener,noreferrer'
        );
      }
      return;
    }
    const meetingId = events?.detail?.reference?.meetingInfo?.id;
    const isZoomMeeting = await isZoomMeetingId({meetingId});
    const appointmentId = events?.detail?.id;
    joinZoomMeeting(
      meetingId,
      userEmail,
      appointmentId,
      accountUUID,
      userUuid,
      userData.name,
      isZoomMeeting
    );
    resetJoinMeetingData();
  };

  const handleNoShowConfirmation = () => {
    if (ehrConfig.isAthena) {
      setModalState((prev) => ({...prev, cancelWithNoShow: true}));
    } else {
      setModalState((prev) => ({...prev, noShow: true}));
    }
  };
  const [topBarActions, setTopBarActions] = useState<IFHPopoverAction[]>([]);

  useEffect(() => {
    if(!detailState?.detail?.id){
      return;
    }
    let meetingId = events?.detail?.reference?.meetingInfo?.id;
    if (isGroupSessionAppointment) {
      const loggedInParticipant = events.detail.participants.find(
        (participant: IParticipantData) => {
          if (
            participant?.user?.uuid &&
            participant.user.uuid === currentUserUuid
          )
            return true;
        }
      );
      if (loggedInParticipant?.reference?.meetingInfo?.link) {
        meetingId = loggedInParticipant?.reference?.meetingInfo?.link;
      } else {
        meetingId = undefined;
      }
      if (!shouldShowInviteUser) {
        setShouldShowTracking(true);
      }
    }
    const actions = getAppointmentActions(
      detailState,
      intl,
      APPOINTMENT_STATUS_IDS,
      userUuid,
      ehrConfig,
      meetingId,
      locationCode,
      disableBlockTime,
      isActionsDisabled
    );
    setTopBarActions(actions);
  }, [detailState, modalState]);

  const appointmentStatus = getAppointmentStatus(
    detailState,
    appointmentStatusList,
    ehrConfig,
    isActionsDisabled
  );

  const onEditRescheduleAppointment = (type: AppointmentAction) => {
    setDetailState((prev) => ({
      ...prev,
      isReschedule: true,
      rescheduleType: type,
    }));
  };

  const onRemoveReccurentEvent = (type: AppointmentAction) => {
    setModalState((prev) => ({
      ...prev,
      isForSeries: [
        AppointmentAction.removeSeries,
        AppointmentAction.removeBlockSeries,
      ].includes(type),
      removeSeries: true,
      type: type,
    }));
  };

  const onEditBlockTime = (type: AppointmentAction) => {
    setDetailState((prev) => ({...prev, rescheduleType: type}));
    setModalState((prev) => {
      return {
        ...prev,
        showEditBlockTime: true,
      };
    });
  };

  // call actions based on top main action clicks
  const handleTopBarActions = (actionCode: string) => {
    switch (actionCode) {
      // checkout case
      case AppointmentAction.cancel:
        getCancelConfirmation();
        break;
      case AppointmentAction.unblock:
      case AppointmentAction.removeBlockOccurence:
        onUnblockUserSlots(false, actionCode);
        break;
      case AppointmentAction.removeBlockSeries:
        onUnblockUserSlots(true, actionCode);
        break;
      case AppointmentAction.convertAWV:
        onEditRescheduleAppointment(AppointmentAction.convertAWV);
        break;
      case AppointmentAction.reschedule:
        onEditRescheduleAppointment(AppointmentAction.editOccurence);
        break;
      case AppointmentAction.editBlockOccurence:
        onEditBlockTime(AppointmentAction.editBlockOccurence);
        break;
      case AppointmentAction.editBlockSeries:
        onEditBlockTime(AppointmentAction.editBlockSeries);
        break;
      case AppointmentAction.joinMeeting:
        onJoinMeetingClicked();
        break;
      case AppointmentAction.editSeries:
        onEditRescheduleAppointment(AppointmentAction.editSeries);
        break;
      case AppointmentAction.removeSeries:
        if (ehrConfig.isAthena) {
          getCancelConfirmation(true, AppointmentAction.removeSeries, false);
        } else {
          onRemoveReccurentEvent(AppointmentAction.removeSeries);
        }
        break;
      case AppointmentAction.removeOccurence:
        if (ehrConfig.isAthena) {
          getCancelConfirmation(
            false,
            AppointmentAction.removeOccurence,
            false
          );
          break;
        }
        onRemoveReccurentEvent(AppointmentAction.removeOccurence);
        break;
    }
  };

  // update status bases on selection dropdown
  const handleStatusUpdate = (statusId: string) => {
    onStatusUpdate(statusId);
  };

  function onSendResponseSuccess() {
    closeModal(true);
  }

  const getLocationName = () => {
    const locationTypeId = detailState.detail?.locationTypeId;
    let locationDetail = '';
    const accountPracticeLocationName =
      detailState?.accountPracticeLocation?.practiceLocation?.name;
    if (locationTypeId) {
      const locationType: IMlov =
        scheduleLocationTypeList.find((locationType) => {
          return locationType.id === locationTypeId;
        }) || ({} as IMlov);

      if (locationType?.code === LOCATION_TYPE_CODES.VIRTUAL) {
        return `${locationType.value}`;
      }
      if (detailState.detail?.accountLocationId) {
        locationDetail = getCompletePracticeLocation(
          detailState?.accountPracticeLocation?.practiceLocation
        );
        if (locationDetail.trim().length || accountPracticeLocationName) {
          return `${locationDetail.trim()} ${
            accountPracticeLocationName
              ? `(${accountPracticeLocationName})`
              : ''
          }`;
        }
      }
      if (locationType.value) {
        return `${locationType.value}`;
      }
    } else {
      if (detailState.detail?.accountLocationId) {
        locationDetail = getCompletePracticeLocation(
          detailState?.accountPracticeLocation?.practiceLocation
        );
        if (locationDetail.trim().length || accountPracticeLocationName) {
          return `${locationDetail.trim()} ${
            accountPracticeLocationName
              ? `(${accountPracticeLocationName})`
              : ''
          }`;
        }
      }
      return `${getMlovValueFromCode(
        scheduleLocationTypeList,
        LOCATION_TYPE_CODES.VIRTUAL
      )}`;
    }
    return '';
    // if (locationCode === LOCATION_TYPE_CODES.VIRTUAL) {
    //   return getMlovValueFromCode(
    //     scheduleLocationTypeList,
    //     LOCATION_TYPE_CODES.VIRTUAL
    //   );
    // } else if (detailState.location?.accountLocation?.practiceLocation?.name) {
    //   const userPracticeLocation =
    //     detailState.location?.accountLocation?.practiceLocation;
    //   let location = getCompletePracticeLocation(userPracticeLocation);
    //   location = location
    //     ? `${location} (${userPracticeLocation.name})`
    //     : userPracticeLocation.name;
    //   return location;
    // }
    // const locationTypeId = detailState.detail?.locationTypeId;
    // if (locationTypeId) {
    //   const locationType =
    //     scheduleLocationTypeList.find((locationType) => {
    //       return locationType.id === locationTypeId;
    //     }) || ({} as IMlov);
    //   return locationType ? locationType.value : '';
    // }
    // return detailState.detail?.appointmentType?.locationType?.value || '';
  };
  const locationName = getLocationName();

  const UpdateWorkFlow = (workflowEventList: any) => {
    if (events && events.detail) {
      const appointment = events.detail;
      const data = getDataForAppointmentWorkflowUpdate(workflowEventList);
      bookAppointmentWorkflowUpdate({
        variables: {
          id: appointment.id,
          workflowListObj: data,
        },
      });
    }
  };

  const scrollableHeight = window.innerHeight - 120;

  const getUserNameWithAppointmentTypeRole = (username: string) => {
    if (detailState.appointmentTypeRole) {
      return `${username} (${detailState.appointmentTypeRole})`;
    }
    return `${username}`;
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const groupCountDetails = () => {

    if (!isRsvpEnabled) {
      return (
        <HStack
          borderColor={Colors.Custom.Gray200}
          borderRadius={8}
          borderWidth={1}
          px={4}
          py={2}
        >
          <Text
            color={Colors.Custom.Gray500}
            size="smSemiBold"
            fontSize={16}
            fontWeight={600}
          >
            {`${intl.formatMessage({id: 'totalMembers'})}: `}
          </Text>
          <Text
            size="smMedium"
            fontSize={16}
            fontWeight={700}
            style={{marginRight: 8}}
          >
            {detailState.contactList?.length}
          </Text>
        </HStack>
      );
    }

    return (
      <HStack
        style={{
          paddingHorizontal: 4,
          paddingVertical: 8,
          borderColor: Colors.Custom.Gray200,
          borderRadius: 8,
          borderWidth: 1,
        }}
      >
        <View
          style={{
            borderRightWidth: 1,
            borderRightColor: Colors.Custom.Gray200,
            overflow: 'hidden',
            justifyContent: 'space-between',
            flex: 1,
            paddingHorizontal: 12,
          }}
        >
          <HStack alignItems={'flex-start'} justifyContent={'flex-start'}>
            {/* <Feather name='check-circle' size={18} color={Colors.success[500]} style={{ marginRight: 8, paddingTop:4  }} /> */}
            <VStack alignItems={'flex-start'}>
              <Text
                color={Colors.Custom.Gray500}
                size="smSemiBold"
                fontSize={16}
                fontWeight={600}
              >
                Total Invited
              </Text>
              <HStack alignItems={'center'} justifyContent={'center'}>
                <Text
                  size="smMedium"
                  fontSize={18}
                  fontWeight={700}
                  style={{marginRight: 8}}
                >
                  {detailState.contactList?.length}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </View>
        <View
          style={{
            borderRightWidth: 1,
            borderRightColor: Colors.Custom.Gray200,
            overflow: 'hidden',
            justifyContent: 'space-between',
            flex: 1,
            paddingHorizontal: 12,
          }}
        >
          <HStack alignItems={'flex-start'} justifyContent={'flex-start'}>
            <Feather
              name="check-circle"
              size={18}
              color={Colors.success[500]}
              style={{marginRight: 8, paddingTop: 4}}
            />

            <VStack alignItems={'flex-start'}>
              <Text
                color={Colors.Custom.Gray500}
                size="smSemiBold"
                fontSize={16}
                fontWeight={600}
              >
                Accepted
              </Text>
              <HStack alignItems={'center'} justifyContent={'center'}>
                <Text
                  size="smMedium"
                  fontSize={18}
                  fontWeight={700}
                  style={{marginRight: 8}}
                >
                  {
                    detailState.contactList?.filter(
                      (participant) =>
                        participant.statusId ==
                        APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted
                    ).length
                  }
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </View>

        <View
          style={{
            borderRightWidth: 1,
            borderRightColor: Colors.Custom.Gray200,
            overflow: 'hidden',
            justifyContent: 'space-between',
            flex: 1,
            paddingHorizontal: 12,
          }}
        >
          <HStack alignItems={'flex-start'} justifyContent={'flex-start'}>
            <AntIcon
              name="closecircleo"
              size={18}
              color={Colors.error[500]}
              style={{marginRight: 8, paddingTop: 4}}
            />

            <VStack alignItems={'flex-start'}>
              <Text
                color={Colors.Custom.Gray500}
                size="smSemiBold"
                fontSize={16}
                fontWeight={600}
              >
                Declined
              </Text>
              <HStack alignItems={'center'} justifyContent={'center'}>
                <Text
                  size="smMedium"
                  fontSize={18}
                  fontWeight={700}
                  style={{marginRight: 8}}
                >
                  {
                    detailState.contactList?.filter(
                      (participant) =>
                        participant.statusId ==
                        APPOINTMENT_PARTICIPANT_STATUS_LIST.declined
                    ).length
                  }
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </View>

        <View
          style={{
            borderRightWidth: 0,
            borderRightColor: Colors.Custom.Gray200,
            overflow: 'hidden',
            justifyContent: 'space-between',
            flex: 1,
            paddingHorizontal: 12,
          }}
        >
          <HStack alignItems={'flex-start'} justifyContent={'flex-start'}>
            <AntIcon
              name="clockcircleo"
              size={18}
              color={Colors.warning[400]}
              style={{marginRight: 8, paddingTop: 4}}
            />

            <VStack alignItems={'flex-start'}>
              <Text
                color={Colors.Custom.Gray500}
                size="smSemiBold"
                fontSize={16}
                fontWeight={600}
              >
                No Response
              </Text>
              <HStack alignItems={'center'} justifyContent={'center'}>
                <Text
                  size="smMedium"
                  fontSize={18}
                  fontWeight={700}
                  style={{marginRight: 8}}
                >
                  {
                    detailState.contactList?.filter(
                      (participant) => !participant.statusId
                    ).length
                  }
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </View>
      </HStack>
    );
  };
  const getHeaderBlock = () => {
    if (isGroupSessionAppointment) {
      if (contactDetailAPIQuery.loading) {
        return null;
      }
      return (
        <Pressable
          onPress={() => {
          }}
        >
          <HStack space={2} alignItems="center">
            <VStack>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: '16',
                }}
                isLetterAvatarShow={true}
                userData={{
                  userId: -1,
                  userType: GROUP_MEMBER_TYPE.CONTACT,
                  genderCode: '' as any,
                  userName: detailState.detail?.name,
                }}
              />
            </VStack>
            <VStack flex={1}>
              <HStack space={2} alignItems={'center'}>
                <Text
                  numberOfLines={2}
                  marginLeft={3}
                  size={'smMedium'}
                  fontSize={24}
                  color={Colors.Custom.Gray900}
                >
                  {`${detailState.detail?.name}`}
                </Text>
              </HStack>
              <HStack marginLeft={3}>
                {!isPastAppointment && !shouldShowInviteUser &&
                 ((getCurrentAppointmentStatus()?.id !== APPOINTMENT_STATUS_IDS.cancelled) && (getCurrentAppointmentStatus()?.id !== APPOINTMENT_STATUS_IDS.checkedOut)) && (
                  <Pressable
                    onPress={() => {
                      setShouldShowInviteUser(true);
                      setShouldShowTracking(false);
                    }}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <Feather
                      name="user-plus"
                      size={16}
                      color={Colors.Custom.PurpleColor}
                    />
                    <Text marginLeft={1} color={Colors.Custom.PurpleColor} >
                      {`${isRsvpEnabled ? 'Invite' : 'Add'} Members`}
                    </Text>
                  </Pressable>
                )}
                {isGroupSessionAppointment && (
                  <Pressable
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginLeft={3}
                    onPress={() => {
                      navigator.clipboard.writeText(detailState.detail?.id || '');
                      notification.info({
                        message: 'Event ID Copied to Clipboard!',
                        placement: 'top',
                      });
                    }}
                    >
                    <Feather name='copy' size={14} color={Colors.Custom.PurpleColor} />
                    <Text marginLeft={1} color={Colors.Custom.PurpleColor}>
                      Copy Event ID
                    </Text>
                  </Pressable>
                )}
                {
                  isGroupSessionAppointment && isRecurringAppointment && (
                    <Pressable
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginLeft={3}
                    onPress={() => {
                      navigator.clipboard.writeText(detailState.detail?.id || '');
                      notification.info({
                        message: 'Event ID Copied to Clipboard!',
                        placement: 'top',
                      });
                    }}
                    >
                    <Feather name='copy' size={14} color={Colors.Custom.PurpleColor} />
                    <Text marginLeft={1} color={Colors.Custom.PurpleColor}>
                      Copy Series ID
                    </Text>
                  </Pressable>
                  )
                }
              </HStack>
            </VStack>
          </HStack>
        </Pressable>
      );
    }
    return (
      <>
        {!detailState.contactList?.length && (
          <Pressable>
            <HStack space={2} alignItems="center">
              <VStack>
                <DisplayCardAvatar
                  avatarStyle={{
                    avatarSize: '12',
                  }}
                  isLetterAvatarShow={true}
                  userData={{
                    userId: -1,
                    userType: GROUP_MEMBER_TYPE.CONTACT,
                    genderCode: '' as any,
                    userName: detailState.primaryUsers?.[0]?.user?.name,
                  }}
                />
              </VStack>
              <VStack>
                <HStack space={2}>
                  <View justifyContent={'center'} marginLeft={3}>
                    <Text size={'smMedium'} color="secondary.600">
                      {detailState.primaryUsers?.[0]?.user?.name}
                    </Text>
                  </View>
                  <View marginTop={1}>
                    <Icon
                      as={AntIcon}
                      name="right"
                      size="3"
                      alignSelf={'flex-end'}
                    />
                  </View>
                </HStack>
                {/* {detailState.primaryUsers?.[0] && (
                  <HStack space={2} marginLeft={3}>
                    <Text
                      fontSize={12}
                      fontWeight={400}
                      color="gray.500"
                    >{`${"contactTypeValue"}`}</Text>
                    {detailState.primary.age > 0 ? (
                        <Text
                          fontSize={12}
                          fontWeight={400}
                          color="gray.500"
                        >
                          {`|  `}
                          {detailState.primary.age}
                          {'  yrs'}
                        </Text>
                      ) : null}
                      {detailState.primary.gender && (
                        <Text
                          fontSize={12}
                          fontWeight={400}
                          color="gray.500"
                        >
                          {`|   `}
                          {detailState.primary.gender}
                        </Text>
                      )}
                  </HStack>
                )} */}
                {/* {detailState.primary.contactTypeValue && (
                        <HStack>
                          <View
                            flex={1}
                            justifyContent={'center'}
                            marginLeft={3}
                          >
                            <Text
                              color={Colors.Custom.Gray500}
                              //size={'xsMedium'}
                            >
                              {`${detailState.primary.contactTypeValue}${
                                detailState.primary.age ||
                                gender
                                  ? ','
                                  : ''
                              }${
                                detailState.primary.age
                                  ? ' '  detailState.primary.age  ' yrs'
                                  : ''
                              }${
                                gender ? ' '  gender : ''
                              }`}
                            </Text>
                          </View>
                        </HStack>
                      )} */}
              </VStack>
            </HStack>
          </Pressable>
        )}
        {(detailState.contactList?.length || 0) > 0 &&
          !!detailState.contactList?.[0]?.contact && (
            <VStack marginBottom={4}>
              <AppointmentContactBanner
                isHideViewDetailedProfile={props?.isHideViewDetailedProfile}
                contactData={detailState.contactList?.[0]?.contact}
                parentCode={
                  PATIENT_QUICK_PROFILE_PARENT_CODES.APPOINTMENT_DETAIL
                }
              />
            </VStack>
          )}
      </>
    );
  };

  //get Current appoint status from status list
  const getCurrentAppointmentStatus = () => {
    return appointmentStatusList.find(
      (appointment) => appointment.id === detailState?.appointmentStatusId
    );
  };
  //Show First action always in Top bar and further one's in dropdrown
  const getFilteredActions = (arr: IFHPopoverAction[]) => {
    const [, ...rest] = arr;
    return rest;
  };

  const isPatientInstructionNote = (note: IAppointmentNote): boolean => {
    const subjectTypeId = getMlovIdFromCode(
      noteSubjectTypeList,
      NOTE_SUBJECT_TYPE_CODES.PATIENT_INSTRUCTION
    );
    return !note?.subjectTypeId || note?.subjectTypeId === subjectTypeId;
  };
  const showAutomationTab = isAutomationAllowed()

  const getSecondaryUserName = () => {
    const users: string[] = [];

    detailState?.secondaryUsers?.forEach((user: any) => {
      if (user?.user?.name) {
        users.push(user?.user?.name);
      }
    });

    return users.join(' • ');
  };

  const getPrimaryUserName = () => {
    const users: string[] = [];

    detailState?.primaryUsers?.forEach((user: any) => {
      if (user?.user?.name) {
        users.push(user?.user?.name);
      }
    });
    return users.join(' • ');
  };
  const isPastAppointment = isPastMeeting(events?.detail);
  const memberLimit = isGroupSessionAppointment ? detailState?.detail?.appointmentType.memberLimit?.[
    detailState?.detail?.locationType?.code as string
  ] as number : 0;

  const totalAcceptedInvites = detailState?.contactList?.filter(
    (contact) =>
      contact.statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted
  )?.length as number;

  const getMeetingLinkOfParticipant = async (participantId?: string) => {
    if (!participantId) {
      return;
    }

    const participantData = await getAppointmentMeetingLinkOfParticipantAPI({
      variables: {
        participantId: participantId,
      },
    });

    const link = participantData?.data?.appointmentParticipant?.reference?.meetingInfo?.link;
    return link;
  }

  const copyMeetingLinkOfParticipant = async (participantId?: string) => {
    const link = await getMeetingLinkOfParticipant(participantId);
    if (!link) {
      notification.error({
        message: `Something went wrong.`,
      });
    }

    navigator.clipboard.writeText(link || '');
    notification.info({
      message: 'Meeting link copied to clipboard!',
      placement: 'top',
    });
  }

  const copyRsvpLinkOfParticipant = async (participantId?: string) => {
    const appointmentId = defaultState?.detail?.id;
    const rsvpLink = encodeURI(`${window.location.origin}/#/public/appointment/booking/rsvp?appointmentId=${appointmentId}&participantId=${participantId}&accountId=${accountUUID}&isWidget=true`);

    navigator.clipboard.writeText(rsvpLink || '');
    notification.info({
      message: 'Invite link copied to clipboard!',
      placement: 'top',
    });
  }

  const handleParticipantLinkActions = async (
    code: string,
    data: IParticipantData
  ) => {
    const participantId = data?.id;
    const appointmentId = defaultState?.detail?.id;

    switch (code) {
      case GroupAppointmentParticipantActions.SEND_RSVP_LINK:
      case GroupAppointmentParticipantActions.SEND_MEETING_LINK:
        const linkCode = (code === GroupAppointmentParticipantActions.SEND_RSVP_LINK) ? 'RSVP_LINK' : 'VIRTUAL_MEETING_LINK';

        if (!participantId || !appointmentId) {
          notification.error({
            message: `Something went wrong.`,
          });
          return;
        }

        setSendAppointmentLinkState((prev) => {
          return {
            ...prev,
            isOpen: true,
            appointmentId: appointmentId,
            participantId: participantId,
            linkCode: linkCode,
            contactData: data.contact,
            appointment: detailState.detail,
          };
        });
        break;

      case GroupAppointmentParticipantActions.COPY_RSVP_LINK:
        setParticipantStatusLoadingId(data?.contactId ? data?.contactId : '');
        try {
          await copyRsvpLinkOfParticipant(participantId);
        } catch (error) {
          notification.error({
            message: `Something went wrong.`,
          });
        }
        setParticipantStatusLoadingId('');
        break;
      case GroupAppointmentParticipantActions.COPY_MEETING_LINK:
        setParticipantStatusLoadingId(data?.contactId ? data?.contactId : '');
        try {
          await copyMeetingLinkOfParticipant(participantId)
        } catch (error) {
          notification.error({
            message: `Something went wrong.`,
          });
        }
        setParticipantStatusLoadingId('');
        break;
    }
  };

  const handleParticipantActions = async (
    code: string,
    data: IParticipantData
  ) => {
    if (
      code === GroupAppointmentParticipantActions.SEND_RSVP_LINK
      || code === GroupAppointmentParticipantActions.SEND_MEETING_LINK
      || code === GroupAppointmentParticipantActions.COPY_RSVP_LINK
      || code === GroupAppointmentParticipantActions.COPY_MEETING_LINK
    ) {
      return handleParticipantLinkActions(code, data);
    }
    setParticipantStatusLoadingId(data?.contactId ? data?.contactId : '');

    let newStatusID = '';
    if (code === GroupAppointmentParticipantActions.ACCEPT) {
      newStatusID = APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted;
    }
    if (code === GroupAppointmentParticipantActions.DECLINE) {
      newStatusID = APPOINTMENT_PARTICIPANT_STATUS_LIST.declined;
    }

    if (
      newStatusID === APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted &&
      totalAcceptedInvites >= memberLimit
    ) {
      notification.warning({
        message: `Appointment registrations are full.`,
      });
      setParticipantStatusLoadingId('');
      return;
    }

    const payload = {
      id: detailState?.detail?.id,
      statusId: detailState?.detail?.statusId,
      isApplyForSeries: isRecurringAppointment,
      participants: [
        {
          id: data?.id,
          contactId: data?.contactId,
          statusId:
            code === GroupAppointmentParticipantActions.DELETE_INVITATION
              ? data?.statusId
              : newStatusID,
          isDeleted:
            code === GroupAppointmentParticipantActions.DELETE_INVITATION,
        },
      ],
    };
    const response = await updateAppointmentParticipant({
      variables: {
        data: payload,
      },
    });
    if (response.errors) {
      notification.error({
        message: intl.formatMessage({id: 'apiErrorMsg'}),
      });
      setParticipantStatusLoadingId('');
    } else {
      if (code === GroupAppointmentParticipantActions.DELETE_INVITATION) {
        setInviteSentOrDeleted(true)
        const newList = cloneDeep(
          detailState?.contactList?.filter(
            (item) => item.contactId !== data?.contactId
          )
        );
        const updatedParticipantsList = cloneDeep(
          detailState?.detail?.participants?.filter(
            (item) => item.contactId !== data?.contactId
          )
        );
        setDetailState((prev) => ({
          ...prev,
          individual: newList,
          contactList: newList,
          detail: {
            ...prev.detail as IAppointmentDetail,
            participants: (updatedParticipantsList || []) as IParticipantData[]
          }
        }));
        setParticipantStatusLoadingId('');
        setEvents((prev: any) => {
          return {
            ...prev,
            detail: {
              ...prev?.detail,
              participants: prev?.detail?.participants?.filter((participant: any) =>
                participant.contactId !== data?.contactId
              )
            }
          };
        })
      } else {
        const newList = cloneDeep(
          detailState?.contactList?.map((item) =>
            item.contactId === data?.contactId
              ? {...item, statusId: newStatusID}
              : item
          )
        );
        const updatedParticipantsList = cloneDeep(
          detailState?.detail?.participants?.map((item) =>
            item.contactId === data?.contactId
              ? {...item, statusId: newStatusID}
              : item
          )
        );
        setDetailState((prev) => ({
          ...prev,
          individual: newList,
          contactList: newList,
          detail: {
            ...prev.detail as IAppointmentDetail,
            participants: (updatedParticipantsList || []) as IParticipantData[]
          }
        }));
        setParticipantStatusLoadingId('');
        setEvents((prev: any) => {
          return {
            ...prev,
            detail: {
              ...prev?.detail,
              participants: prev?.detail?.participants?.map((participant: any) =>
                participant.contactId === data?.contactId
                  ? { ...participant, statusId: newStatusID }
                  : participant
              )
            }
          };
        });
      }
    }
  };

  const onInviteUsers = async (contacts: IContact[]): Promise<string[] | undefined> => {
    try {
      setInviteUserLoading(true);
      const statusId = isRsvpEnabled
        ? null
        : APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted;

      const newParticipantPayload = contacts?.map((contact) => ({
        contactId: contact?.uuid,
        statusId: statusId,
      }));


      const response = await updateAppointmentParticipant({
        variables: {
          data: {
            id: detailState?.detail?.id,
            statusId: detailState?.detail?.statusId,
            participants: newParticipantPayload,
            isApplyForSeries: isRecurringAppointment,
          },
        },
      });

      if (response?.errors) {
        notification.error({message: intl.formatMessage({id: 'apiErrorMsg'})});
        setInviteUserLoading(false);
        return [];
      }
      setInviteSentOrDeleted(true)

      if (response?.data?.updateParticipantStatus) {
        const contactUUIDsList = contacts.map((contact) => contact?.uuid);
        const participantResponse = await getParticipantIdByContactIdList({
          variables: {
            contactUuids: contactUUIDsList,
            appointmentId: detailState?.detail?.id,
          },
        });

        if (participantResponse?.error) {
          notification.error({message: intl.formatMessage({id: 'apiErrorMsg'})});
          setInviteUserLoading(false);
          return [];
        }

        const newParticipantList = contacts.map((contact) => {
          const participantId =
            participantResponse?.data?.appointmentParticipants.filter(
              (item: {id: string; contactId: string}) =>
                item.contactId === contact?.uuid
            )?.[0]?.id;

          return {
            contactId: contact?.uuid,
            statusId: statusId,
            isInitiator: false,
            contact,
            id: participantId,
          };
        })

        const currentEvents = {...events};
        currentEvents.detail.participants?.push(...newParticipantList);
        setEvents(currentEvents);
        return contactUUIDsList;
      }
    } catch (err: any) {
      const errorCode =
        err?.networkError?.result?.message?.extensions?.errorCode;
      let messageCode = "apiErrorMsg";

      if (errorCode === AppointmentAPIErrorCodes.APPT_MEMBER_LIMIT_REACHED) {
        messageCode = "groupAppointmentMemberLimitExceeded";
      }
      notification.error({message: intl.formatMessage({id: messageCode})});
      setInviteUserLoading(false);
      return [];
    }
  };

  const inviteUserFlow = () => {
    return (
      <VStack
        flex={2}
        style={{
          backgroundColor: Colors.Custom.Gray50,
          borderRadius: 8,
          height: '87.5vh',
          marginLeft: 8,
        }}
      >
        <AddInviteMembersView
          isRsvpAppointment={isRsvpEnabled}
          memberLimit={memberLimit}
          onClose={() => {
            setShouldShowInviteUser(false);
            setShouldShowTracking(true);
          }}
          title={
            isRsvpEnabled
              ? intl.formatMessage({id: 'inviteMembers'})
              : intl.formatMessage({id: 'addMembers'})
          }
          subtitle={
            isRecurringAppointment
              ? intl.formatMessage({id: 'membersWillbeAddedToSeries'})
              : ''
          }
          inviteButtonTitle={
            isRsvpEnabled
              ? 'Invite'
              : 'Add'
          }
          onInviteUser={onInviteUsers}
          inviteUserLoading={inviteUserLoading}
          externalContactList={detailState.contactList?.map(
            (item) => item?.contact?.uuid
          )}
        />
      </VStack>
    );
  };
  const isAllowToShowBookingLink = (): boolean => {
    if (isGroupSessionAppointment) {
      return false;
    }
    const meetingId = detailState?.detail?.reference?.meetingInfo?.id;
    if (
      (!meetingId || detailState?.detail?.isBlockedInterval) &&
      !detailState.detail?.reference?.meetingInfo?.meeting?.meetingId
    ) {
      return false;
    }
    const appointmentStatusId = detailState?.detail?.statusId;
    const isAppointmentStatusCancel =
      appointmentStatusId === APPOINTMENT_STATUS_IDS.cancelled;
    const isAppointmentStatusDecline =
      appointmentStatusId === APPOINTMENT_STATUS_IDS.decline;
    const isAppointmentStatusNoShow =
      appointmentStatusId === APPOINTMENT_STATUS_IDS.noShow;
    const isAppointmentStatusCheckout =
      appointmentStatusId === APPOINTMENT_STATUS_IDS.checkedOut;
    if (
      isAppointmentStatusCancel ||
      isAppointmentStatusDecline ||
      isAppointmentStatusNoShow ||
      isAppointmentStatusCheckout
    ) {
      return false;
    }
    return true;
  };

  const getFilteredList = () => {
    if (filterStatusId === null) {
      return detailState?.contactList?.filter(
        (item: IParticipantData) => item.statusId === null
      ) as IParticipantData[];
    }
    switch (filterStatusId) {
      case APPOINTMENT_PARTICIPANT_STATUS_LIST?.accepted:
        return detailState?.contactList?.filter(
          (item: IParticipantData) =>
            item.statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted
        ) as IParticipantData[];
      case APPOINTMENT_PARTICIPANT_STATUS_LIST?.declined:
        return detailState?.contactList?.filter(
          (item: IParticipantData) =>
            item.statusId === APPOINTMENT_PARTICIPANT_STATUS_LIST.declined
        ) as IParticipantData[];
      default:
        return detailState?.contactList as IParticipantData[];
    }
  };

  const componentLoading = props.isLoading || detailLoading;

  const renderActions = () => {
    if (!!props.hideAction) {
      return <></>;
    }
    return (
      <>
        {topBarActions?.length > 0 && (
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              onPress: () => {
                handleTopBarActions(topBarActions?.[0]?.code);
              },
              style: {marginHorizontal: 4},
            }}
            customProps={{
              btnText:
                topBarActions[0]?.name &&
                intl.formatMessage({
                  id: topBarActions[0]?.name || '',
                }),
              withRightBorder: false,
            }}
          ></FoldButton>
        )}
        {topBarActions.length > 1 && (
          <FHActionPopover
            actions={getFilteredActions(topBarActions)}
            onActionClick={(code) => {
              handleTopBarActions(code);
            }}
          />
        )}
      </>
    );
  }

  const renderRSVPExipiredStatus = () => {

    if (
      isGroupSessionAppointment &&
      !!detailState.detail?.reference?.expiryDateTime
    ) {
      const isExipired = getMomentObj(
        detailState.detail?.reference?.expiryDateTime
      ).isBefore(getMomentObj(new Date()));
      return (
        <Text
          fontSize={'xs'}
          color={isExipired ? Colors.Custom.ErrorColor : Colors.Custom.Gray500}
        >{`RSVP link expire${isExipired ? 'd' : 's'} on ${getMomentObj(
          detailState.detail?.reference?.expiryDateTime
        ).format(DATE_FORMATS.CRM_ACTIVITY_DATE_FORMAT)}`}</Text>
      );
    }
  };

  const automationTab = <WorkFlowListByEntityEvents
    isDetailsPage={true}
    workflowlist={
      detailState?.detail &&
      detailState?.detail.reference &&
      detailState.detail.reference.workflowList &&
      detailState.detail.reference.workflowList.length >
        0 &&
      detailState.detail.reference.workflowList
    }
    addUrl={CUSTOM_AUTOMATION_URL.SCHEDULE_APPOINTMENT}
    title={
      ENTITY_EVENTS_TITLE_SUBTITLE.SCHEDULE_APPOINTMENT_TITLE
    }
    subtitle={
      ENTITY_EVENTS_TITLE_SUBTITLE.SCHEDULE_APPOINTMENT_SUB_TITLE
    }
    entityEventList={ENTITY_EVENTS_TYPE.APPOINTMENT_BOOKING}
    onValueChanage={(eventEntityList: any) => {
      UpdateWorkFlow(eventEntityList);
    }}
  />


  const getPrimaryUserMeetingLink = async (primaryUser: any): Promise<string> => {
    let link = '';
    const meetingId = events.detail?.reference?.meetingInfo?.meeting?.meetingId || events.detail.reference?.meetingInfo?.id;
    if (isGroupSessionAppointment) {
      link = primaryUser?.reference?.meetingInfo?.link;
    } else {
      if (detailState.isZoomMeeting) {
        link = detailState.detail?.reference?.meetingInfo?.meeting?.primaryUserMeetingLink;
      } else {
        const provider: IBookingLinkParams = {
          meetingId: meetingId,
          joineeEmail: primaryUser?.user?.email,
          appointmentId: detailState.detail?.id || '',
          accountUUID: accountUUID,
          userUuid: primaryUser?.user?.uuid || primaryUser?.userId,
          name: primaryUser?.user?.name,
          isZoomEnabled: false,
        };
        const providerAppointmentLink: string = generateAppointmentBookingLongLink(provider) || '';
        link = await getShortLink(providerAppointmentLink);
      }
    }
    return link;
  };

  const getSecondaryUserMeetingLink = async (secondaryUser: any): Promise<string> => {
    let link = '';
    const meetingId = events.detail?.reference?.meetingInfo?.meeting?.meetingId || events.detail.reference?.meetingInfo?.id;
    if (isGroupSessionAppointment) {
      link = secondaryUser?.reference?.meetingInfo?.link;
    } else {
      if (detailState.isZoomMeeting) {
        const secondaryUserMeetingLinks = detailState.detail?.reference?.meetingInfo?.meeting?.secondaryUserMeetingLinks || [];
        const secondaryUserMeetingLink = (secondaryUserMeetingLinks || []).find((secondaryUserLink: any) => {
          return secondaryUserLink?.userUuid === secondaryUser?.user?.uuid;
        });
        link = secondaryUserMeetingLink?.link || '';
      } else {
        const provider: IBookingLinkParams = {
          meetingId: meetingId,
          joineeEmail: secondaryUser?.user?.email,
          appointmentId: detailState.detail?.id || '',
          accountUUID: accountUUID,
          userUuid: secondaryUser?.user?.uuid || secondaryUser?.userId,
          name: secondaryUser?.user?.name,
          isZoomEnabled: false,
        };
        const providerAppointmentLink: string = generateAppointmentBookingLongLink(provider) || '';
        link = await getShortLink(providerAppointmentLink);
      }
    }
    return link;
  };

  const getProviderMeetingLink = async (userId: string): Promise<string> => {
    let link = '';
    const primaryUser = (detailState?.primaryUsers || []).find(primaryUser => {
      return primaryUser?.user?.uuid === userId;
    });
    if (primaryUser && primaryUser?.user?.uuid) {
      link = await getPrimaryUserMeetingLink(primaryUser);
    } else {
      const secondaryUser = (detailState.secondaryUsers || []).find(secondaryUser => {
        return secondaryUser?.user?.uuid === userId;
      });
      link = await  getSecondaryUserMeetingLink(secondaryUser);
    }
    return link;
  };

  const getContactMeetingLink = async (contactId: string): Promise<string> => {
    let link = '';
    if (!isGroupSessionAppointment) {
      const contactList = detailState?.contactList || [];
      const findContact = (contactList || []).find((selectedContact: any) => {
        return selectedContact?.contact?.uuid === contactId;
      });
      if (detailState.isZoomMeeting) {
        link =  events.detail?.reference?.meetingInfo?.meeting?.contactMeetingLink || '';
      } else {
        const meetingId = events.detail?.reference?.meetingInfo?.meeting?.meetingId || events.detail.reference?.meetingInfo?.id;
        const contactObj: IBookingLinkParams = {
          meetingId: meetingId,
          joineeEmail: findContact?.contact?.email,
          appointmentId: detailState.detail?.id || '',
          accountUUID: accountUUID,
          contactUuid: findContact?.contact?.uuid || contactId,
          name: findContact?.contact?.name,
          isZoomEnabled: false,
        };
        const contactAppointmentLink: string = generateAppointmentBookingLongLink(contactObj) || '';
        link = await getShortLink(contactAppointmentLink);
      }
    }
    return link;
  };
  const onAuditRefetchData = () => setInviteSentOrDeleted(false);

  const isContactMeetingLinkExist = (): boolean => {
    let linkIdOrLink: string | undefined = undefined;
    if (!isGroupSessionAppointment) {
      if (detailState.isZoomMeeting) {
        linkIdOrLink =  events.detail?.reference?.meetingInfo?.meeting?.contactMeetingLink || '';
      } else {
        linkIdOrLink = events.detail?.reference?.meetingInfo?.meeting?.meetingId || events.detail.reference?.meetingInfo?.id;
      }
    }
    return linkIdOrLink != undefined && linkIdOrLink != '';
  }

  const isPrimaryProviderMeetingLinkExist = (userId: string):boolean => {
    let linkIdOrLink: string | undefined = undefined;
    const primaryUser = (detailState?.primaryUsers || []).find(primaryUser => {
      return primaryUser?.user?.uuid === userId;
    });
    const meetingId = events.detail?.reference?.meetingInfo?.meeting?.meetingId || events.detail.reference?.meetingInfo?.id;
    if (isGroupSessionAppointment) {
      linkIdOrLink = primaryUser?.reference?.meetingInfo?.link;
    } else {
      if (detailState.isZoomMeeting) {
        linkIdOrLink = detailState.detail?.reference?.meetingInfo?.meeting?.primaryUserMeetingLink;
      } else {
        linkIdOrLink = meetingId;
      }
    }
    return linkIdOrLink != undefined && linkIdOrLink != '';
  }

  const getAppointmentData = () => {
    switch (getCurrentAppointmentStatus()?.id) {
      case APPOINTMENT_STATUS_IDS.cancelled:
      case APPOINTMENT_STATUS_IDS.checkedOut:
        return [getCurrentAppointmentStatus()];
      default:
        return [getCurrentAppointmentStatus(), ...appointmentStatus];
    }
  };
  const isSecondaryProviderMeetingLinkExist = (userId: string):boolean => {
    let linkIdOrLink: string | undefined = undefined;
    const secondaryUser = (detailState.secondaryUsers || []).find(secondaryUser => {
      return secondaryUser?.user?.uuid === userId;
    });
    const meetingId = events.detail?.reference?.meetingInfo?.meeting?.meetingId || events.detail.reference?.meetingInfo?.id;
    if (isGroupSessionAppointment) {
      linkIdOrLink = secondaryUser?.reference?.meetingInfo?.link;
    } else {
      if (detailState.isZoomMeeting) {
        const secondaryUserMeetingLinks = detailState.detail?.reference?.meetingInfo?.meeting?.secondaryUserMeetingLinks || [];
        const secondaryUserMeetingLink = (secondaryUserMeetingLinks || []).find((secondaryUserLink: any) => {
          return secondaryUserLink?.userUuid === secondaryUser?.user?.uuid;
        });
        linkIdOrLink = secondaryUserMeetingLink?.link || '';
      } else {
        linkIdOrLink = meetingId;
      }
    }
    return linkIdOrLink != undefined && linkIdOrLink != '';
  }

  return (
    <>
      <Drawer
        title={
          <HStack justifyContent={'space-between'} alignItems="center">
            <ModalActionTitle
              title={ isBlockedInterval ? 'blockDetails' : 'appointmentDetail' }
              titleColor={'#4f2d90'}
              titleSize={24}
              isHeadNotSticky
            />

            {/* {topBarActions.length > 0 && ( */}
            {statusLoading ? (
              <Spinner />
            ) : (
              <HStack alignItems={'center'} justifyContent="space-between">
                <FoldButton
                  nativeProps={{
                    variant: props?.isAppointmentSummary
                      ? BUTTON_TYPE.PRIMARY
                      : BUTTON_TYPE.SECONDARY,
                    onPress: () => {
                      props.onClose(
                        JSON.stringify(props.event?.participants) !==
                          JSON.stringify(detailState?.contactList)
                      );
                      if (props?.onComplete) {
                        props.onComplete(
                          props?.isAppointmentSummary
                            ? detailState.detail
                            : undefined
                        );
                      }
                    },
                  }}
                  customProps={{
                    btnText: intl.formatMessage({
                      id: props?.isAppointmentSummary ? 'Done' : 'Close',
                    }),
                    withRightBorder: false,
                  }}
                ></FoldButton>
                <Spacer />
                {renderActions()}
              </HStack>
            )}

            {/* )}*/}
          </HStack>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          closeModal();
          if (props?.onComplete) {
            props.onComplete(props?.isAppointmentSummary
              ? detailState.detail
              : undefined);
          }
        }}
        visible={props.isVisible && !detailState.isReschedule}
        closable={true}
        bodyStyle={{
          paddingTop: 0,
          paddingRight: 8
        }}
        width={ !!props?.drawerWidth ? props?.drawerWidth : (isSidecarContext) ? '100%' :
          (isIPadScreen || isIPadMiniScreen || shouldShowTracking || shouldShowInviteUser) //here
            ? '70%'
            : '45%'
        }
      >
        {componentLoading && (
          <View width={'full'}>
            <Skeleton active />
          </View>
        )}
        {!componentLoading && (
          <HStack>
            <VStack flex={3}>
              {!props.isLoading && !!detailState?.detail && (
                <VStack style={{marginTop: 0}} width={'full'}>
                  {detailState?.showProposedTimeBanner && (
                    <>
                      <AppointmentRSVP
                        showProposeTimeBanner
                        appointmentDetails={
                          detailState.detail as IAppointmentDetail
                        }
                        participant={detailState?.participant || {}}
                        careStudioMlovData={mlovData.CARE_STUDIO_MLOV}
                        loggedInParticipantId={
                          detailState.loggedInParticipantId as string
                        }
                        onSendResponseSuccess={onSendResponseSuccess}
                        isGroupAppointmentWidget={false}
                        accountSettings={mlovData.userSettings}
                      />
                    </>
                  )}
                  <VStack space={4} overflowY="scroll" height={scrollableHeight} width={'full'}>
                    {contactDetailAPIQuery.loading && (
                      <AppointmentPatientBannerLoading />
                    )}

                    {getHeaderBlock()}

                    {isGroupSessionAppointment ? groupCountDetails() : null}
                    {(props.showAwvCard && showModalAwvCard) && <HStack
                      padding={3}
                      borderWidth={0.5}
                      borderRadius={8}
                      justifyContent={'space-between'}
                      borderColor={Colors.Custom.Primary300}
                      backgroundColor={Colors.Custom.insuranceBgColor}>
                      <HStack space={2}>
                        <View
                          height={'100%'}
                          bgColor={Colors.Custom.Primary300}
                          marginLeft={'-13px'}
                          width={'7px'}
                          borderBottomRightRadius={8}
                          borderTopRightRadius={8} />
                        <VStack justifyContent={'center'} marginLeft={1}>
                          <Text>Eligible for Annual Wellness Visit (AWV) Program</Text>
                          {(props.lastAwvProgramDate && props.lastAwvProgramDate?.length > 0) && <Text fontSize={13} color={Colors.Custom.Gray400}>{`Last Annual Wellness Visit(AWV) done on ${ getDateStrFromFormat(props.lastAwvProgramDate)}`}</Text>}
                        </VStack>
                      </HStack>
                      <HStack alignItems={'center'}>
                        <Button onPress={() => {
                          handleTopBarActions(AppointmentAction.convertAWV)
                        }}
                        isDisabled={isActionsDisabled}
                          color={'white'}
                          style={{ margin: 3, backgroundColor: Colors.Custom.Primary300 }}>Convert</Button>
                        <View height={'60%'} bgColor={'#A7AFBE'} width={'1px'} margin={2} />
                        <Pressable
                          onPress={() => {
                            setShowModalAwvCard(false)
                          }}
                          style={{ justifyContent: 'center' }}
                        >
                          <CrossIconSvg
                            size={30}
                          />
                        </Pressable>
                      </HStack>
                    </HStack>}
                    <VStack space={6} alignItems="flex-start" paddingLeft={4} width={'full'}>
                      <VStack style={{width: '100%'}}>
                        <Text
                          color={Colors.FoldPixel.GRAY250}
                          size={'smRegular'}
                          style={{marginBottom: 8}}
                        >
                          DATE & TIME
                        </Text>
                        <HStack style={{width: '100%'}}>
                          <Icon
                            as={AntIcon}
                            name="calendar"
                            size="4"
                            alignSelf={'flex-start'}
                            justifySelf={'flex-start'}
                            color={Colors.Custom.Gray400}
                            ml={0}
                            mt={1}
                          />
                          <VStack style={{marginLeft: 10, flex: 1}}>
                            <HStack alignItems={'center'} space={4}>
                            <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                                {isGroupSessionAppointment
                                  ? detailState.detail?.appointmentType
                                      .eventName
                                  : detailState.detail?.name}
                              </Text>
                              {detailState.detail?.isRecurrentAppointment &&
                                !!detailState.detail.seriesId && (
                                  <Badge
                                    backgroundColor={
                                      Colors.Custom.mainPrimaryPurple
                                    }
                                    alignSelf="center"
                                    flexDir={'row'}
                                    alignItems={'center'}
                                    variant="solid"
                                    ml={2}
                                    rounded={'full'}
                                  >
                                    <ReccuringAppointmentIcon />
                                    <Text
                                      ml={2}
                                      color={Colors.Custom.FontColorWhite}
                                      fontSize={12}
                                    >
                                      Recurring
                                    </Text>
                                  </Badge>
                                )}
                            </HStack>
                            <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300}>
                              {getAppointmentDateTime(
                                detailState.detail,
                                defaultTimeZone
                              )}
                            </Text>
                            {detailState?.detail?.isBlockedInterval && (
                              <Text color={Colors.Custom.Gray500}>
                                {detailState?.detail?.isVirtualLocation
                                  ? 'Virtual'
                                  : detailState?.accountPracticeLocation
                                      ?.practiceLocation?.name}
                              </Text>
                            )}
                            {renderRSVPExipiredStatus()}
                          </VStack>
                        </HStack>
                        {isGroupSessionAppointment && locationCode === LOCATION_TYPE_CODES.VIRTUAL && (
                          <RecordingListView
                            appointmentId={props?.event?.detail?.id}
                          />
                        )}
                      </VStack>

                      {detailState.primaryUsers &&
                        detailState.primaryUsers.length > 0 && (
                          <VStack width={'full'} space={2}>
                            <Text
                              color={Colors.FoldPixel.GRAY250}
                              size={'smRegular'}
                              style={{marginBottom: 8}}
                            >
                              PRIMARY PROVIDER
                            </Text>
                            <HStack width={'full'}>
                              <Icon
                                as={FontAwesome}
                                name="user-md"
                                size="5"
                                alignSelf={'flex-start'}
                                justifySelf={'flex-start'}
                                color={Colors.Custom.Gray400}
                                ml={0}
                                mt={1}
                              />
                              <VStack style={{marginLeft: 10}}>
                                {appointmentTypeQuery.loading ? (
                                  <NBSkeleton rounded="md" />
                                ) : (
                                  <Text
                                    size={'mdNormal'}
                                    color={Colors.FoldPixel.GRAY400}
                                  >
                                    {getPrimaryUserName()}
                                  </Text>
                                )}
                              </VStack>
                              <Spacer></Spacer>
                              { isPrimaryProviderMeetingLinkExist(detailState.primaryUsers?.[0]?.user?.uuid) &&
                                <View alignContent={'flex-end'}>
                                  <Pressable
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    marginLeft={3}
                                    onPress={async () => {
                                      const userId = detailState.primaryUsers?.[0]?.user?.uuid;
                                      const link = await getProviderMeetingLink(userId);
                                      // below code execute only for group appointment
                                      // const link = detailState.primaryUsers?.[0]?.reference?.meetingInfo?.link;
                                      if (!link) {
                                        notification.error({
                                          message: `Something went wrong.`,
                                        });
                                        return;
                                      }
                                      navigator.clipboard.writeText(link || '');
                                      notification.info({
                                        message: 'Meeting link copied to clipboard!',
                                        placement: 'top',
                                      });
                                    }}
                                    >
                                    {/* <Feather name='copy' size={14} color={Colors.Custom.PurpleColor} /> */}
                                    <LinkIcon/>
                                    <Text marginLeft={1} color={Colors.Custom.PurpleColor}>
                                      Copy Meeting Link
                                    </Text>
                                  </Pressable>
                                </View>
                              }
                            </HStack>
                          </VStack>
                        )}
                      {detailState.secondaryUsers &&
                        detailState.secondaryUsers.length > 0 && (
                          <VStack width={'full'}>
                            <Text
                              color={Colors.Custom.Gray500}
                              size="smMedium"
                              fontSize={14}
                              style={{marginBottom: 8}}
                            >
                              SECONDARY PROVIDER
                            </Text>
                            {
                              detailState.secondaryUsers.map((secondaryUser) => {
                                if (!secondaryUser?.user?.name || !secondaryUser?.id) {
                                  return <></>;
                                }
                                return (
                                  <VStack key={secondaryUser?.id} space={2} width={'full'}>
                                    <HStack width={'full'}>
                                      <Icon
                                        as={FontAwesome}
                                        name="user-md"
                                        size="5"
                                        alignSelf={'flex-start'}
                                        justifySelf={'flex-start'}
                                        color={Colors.Custom.Gray400}
                                        ml={0}
                                        mt={1}
                                      />
                                      <VStack
                                        style={{
                                          marginLeft: 10,
                                          overflow: 'hidden',
                                          maxHeight: 80,
                                        }}
                                      >
                                        {appointmentTypeQuery.loading ? (
                                          <NBSkeleton rounded="md" />
                                        ) : (
                                          <Text
                                            textOverflow={'ellipsis'}
                                            fontSize={16}
                                            fontWeight={500}
                                            size="smMedium"
                                          >
                                            {secondaryUser?.user?.name}
                                          </Text>
                                        )}
                                      </VStack>
                                      <Spacer></Spacer>
                                      { isSecondaryProviderMeetingLinkExist(secondaryUser?.user?.uuid) &&
                                        <View alignContent={'flex-end'}>
                                          <Pressable
                                            flexDirection={'row'}
                                            alignItems={'center'}
                                            marginLeft={3}
                                            onPress={async () => {
                                              const userId = secondaryUser?.user?.uuid;
                                              const link = await getProviderMeetingLink(userId);
                                              // below code execute only for group appointment
                                              // const link = secondaryUser?.reference?.meetingInfo?.link;
                                              if (!link) {
                                                notification.error({
                                                  message: `Something went wrong.`,
                                                });
                                                return;
                                              }
                                              navigator.clipboard.writeText(link || '');
                                              notification.info({
                                                message: 'Meeting link copied to clipboard!',
                                                placement: 'top',
                                              });
                                            }}
                                            >
                                            {/* <Feather name='copy' size={14} color={Colors.Custom.PurpleColor} /> */}
                                            <LinkIcon/>
                                            <Text marginLeft={1} color={Colors.Custom.PurpleColor}>
                                              Copy Meeting Link
                                            </Text>
                                          </Pressable>
                                        </View>
                                      }
                                    </HStack>
                                  </VStack>
                                );
                              })
                            }

                          </VStack>
                        )}
                      {isGroupSessionAppointment &&
                        detailState.detail.description && (
                          <VStack width={'full'}>
                            <Text
                              color={Colors.Custom.Gray500}
                              size="smMedium"
                              fontSize={14}
                              style={{marginBottom: 8}}
                            >
                              {'DESCRIPTION'}
                            </Text>
                            <HStack width={'full'}>
                              <Icon
                                as={MaterialIcon}
                                name="event-note"
                                size="5"
                                alignSelf={'flex-start'}
                                justifySelf={'flex-start'}
                                color={Colors.Custom.Gray400}
                                ml={0}
                                mt={1}
                              />
                              <Text
                                fontSize={16}
                                fontWeight={500}
                                size="smMedium"
                                marginLeft={3}
                              >
                                {detailState.detail.description}
                              </Text>
                              {/* <VStack style={{marginLeft: 10}}>
                                {

                                }
                              </VStack> */}
                            </HStack>
                          </VStack>
                        )}
                      {(detailState.location?.accountLocation?.practiceLocation
                        ?.name ||
                        detailState.detail.appointmentType?.locationType ||
                        detailState.detail.locationTypeId) && (
                        <VStack width={'full'}>
                          <Text
                            color={Colors.FoldPixel.GRAY250}
                            size={'smRegular'}
                            style={{marginBottom: 8}}
                          >
                            LOCATION
                          </Text>
                          <HStack width={'full'}>
                            <Icon
                              as={MaterialIcon}
                              name="location-on"
                              size="5"
                              alignSelf={'flex-start'}
                              justifySelf={'flex-start'}
                              color={Colors.Custom.Gray400}
                              ml={0}
                              mt={1}
                            />
                            {
                              locationAPIQuery.loading ?
                                <NBSkeleton rounded={'md'} /> :
                                <Tooltip title={locationName} >
                                  {locationName}
                                </Tooltip>
                            }
                            {/* <VStack style={{marginLeft: 10}}>
                              {locationAPIQuery.loading ? (
                                <NBSkeleton rounded="md" />
                              ) :
                            </VStack> */}
                          </HStack>
                        </VStack>
                      )}
                      {detailState?.detail?.statusId
                        && !!detailState?.contactList?.length  && isAllowToShowBookingLink() && (
                          <VStack width={'full'}>
                            <Text
                              color={Colors.Custom.Gray500}
                              size="smMedium"
                              fontSize={14}
                              style={{marginBottom: 8}}
                            >
                              MEMBER MEETING LINK
                            </Text>
                            <VStack key={detailState?.contactList?.[0]?.id} space={2} width={'full'}>
                              <HStack width={'full'}>
                                <Icon
                                  as={FontAwesome}
                                  name="user"
                                  size="5"
                                  alignSelf={'flex-start'}
                                  justifySelf={'flex-start'}
                                  color={Colors.Custom.Gray400}
                                  ml={0}
                                  mt={1}
                                />
                                <VStack
                                  style={{
                                    marginLeft: 10,
                                    overflow: 'hidden',
                                    maxHeight: 80,
                                  }}
                                >
                                {appointmentTypeQuery.loading ?
                                  (
                                    <NBSkeleton rounded="md" />
                                  ) :
                                  (
                                    <Text
                                    textOverflow={'ellipsis'}
                                    fontSize={16}
                                    fontWeight={500}
                                    size="smMedium"
                                    >
                                      {detailState.contactList?.[0]?.contact?.name}
                                    </Text>
                                  )}
                                          </VStack>
                                          <Spacer></Spacer>
                                          { isContactMeetingLinkExist() &&
                                            <View alignContent={'flex-end'}>
                                              <Pressable
                                                flexDirection={'row'}
                                                alignItems={'center'}
                                                marginLeft={3}
                                                onPress={async () => {
                                                  const contactId = detailState.contactList?.[0]?.contact?.uuid;
                                                  const link = await getContactMeetingLink(contactId);
                                                  if (!link) {
                                                    notification.error({
                                                      message: `Something went wrong.`,
                                                    });
                                                    return;
                                                  }
                                                  navigator.clipboard.writeText(link || '');
                                                  notification.info({
                                                    message: 'Meeting link copied to clipboard!',
                                                    placement: 'top',
                                                  });
                                                }}
                                                >
                                                <LinkIcon/>
                                                {/* <Feather name='copy' size={14} color={Colors.Custom.PurpleColor} /> */}
                                                <Text marginLeft={1} color={Colors.Custom.PurpleColor}>
                                                  Copy Meeting Link
                                                </Text>
                                              </Pressable>
                                            </View>
                                          }
                                        </HStack>
                            </VStack>
                          </VStack>
                        )}

                      {detailState?.detail?.contactId
                        && !!detailState?.detail?.contact && (
                          <VStack width={'full'}>
                            <Text
                              color={Colors.Custom.Gray500}
                              size="smMedium"
                              fontSize={14}
                              style={{marginBottom: 8}}
                            >
                              MEMBER
                            </Text>
                            <VStack key={detailState?.contactList?.[0]?.id} space={2} width={'full'}>
                              <HStack width={'full'}>
                                <Icon
                                  as={FontAwesome}
                                  name="user"
                                  size="5"
                                  alignSelf={'flex-start'}
                                  justifySelf={'flex-start'}
                                  color={Colors.Custom.Gray400}
                                  ml={0}
                                  mt={1}
                                />
                                <VStack
                                  style={{
                                    marginLeft: 10,
                                    overflow: 'hidden',
                                    maxHeight: 80,
                                  }}
                                >
                                 <Text
                                    textOverflow={'ellipsis'}
                                    fontSize={16}
                                    fontWeight={500}
                                    size="smMedium"
                                    >
                                      {detailState?.detail?.contact?.name}
                                  </Text>
                                 </VStack>
                                </HStack>
                            </VStack>
                          </VStack>
                        )}

                      {detailState.detail.reasonForVisit && (
                        <VStack>
                          <Text
                            color={Colors.FoldPixel.GRAY250}
                            size={'smRegular'}
                            style={{marginBottom: 8}}
                          >
                            {'REASON FOR VISIT'}
                          </Text>
                          <HStack>
                            <Icon
                              as={MaterialIcon}
                              name="event-note"
                              size="5"
                              alignSelf={'flex-start'}
                              justifySelf={'flex-start'}
                              color={Colors.Custom.Gray400}
                              ml={0}
                              mt={1}
                            />
                            <VStack style={{marginLeft: 10}}>
                              {
                                <Text
                                  size={'mdNormal'}
                                  color={Colors.FoldPixel.GRAY400}
                                >
                                  {
                                    detailState.detail?.reasonForVisit
                                      ?.displayName
                                  }
                                </Text>
                              }
                            </VStack>
                          </HStack>
                        </VStack>
                      )}
                      {!props.hideAction &&
                        detailState?.appointmentStatusId &&
                        !detailState.detail?.isBlockedInterval && (
                          <VStack>
                            <Text
                              color={Colors.FoldPixel.GRAY250}
                              size={'smRegular'}
                              style={{marginBottom: 8}}
                            >
                              APPOINTMENT STATUS
                            </Text>
                            <HStack>
                              <Icon
                                as={MaterialIcon}
                                name="update"
                                size="5"
                                alignSelf={'flex-start'}
                                justifySelf={'flex-start'}
                                color={Colors.Custom.Gray400}
                                ml={0}
                                mt={1}
                              />
                              <VStack style={{marginLeft: 10}}>
                              <ModalActionAntSelect
                                disabled={statusLoading}
                                selectActionLoading={statusLoading}
                                data={getAppointmentData()}
                                value={
                                  getCurrentAppointmentStatus()?.id
                                }
                                toShowSelectDropdownArrowSvg={true}
                                onChange={(value: string) => {
                                  handleStatusUpdate(value);
                                }}
                                optionProps={{
                                  key: 'id',
                                  value: 'id',
                                  label: 'value',
                                }}
                                hideCustomIcon={statusLoading ? false : true}
                                />
                              </VStack>
                            </HStack>
                          </VStack>
                        )}
                      {detailState.detail.cancelReason?.display && (
                        <VStack>
                          <Text
                            color={Colors.Custom.Gray500}
                            size="smMedium"
                            fontSize={14}
                            style={{marginBottom: 8}}
                          >
                            CANCEL REASON
                          </Text>
                          <HStack>
                            <Icon
                              as={MaterialIcon}
                              name="speaker-notes"
                              size="5"
                              alignSelf={'flex-start'}
                              justifySelf={'flex-start'}
                              color={Colors.Custom.Gray400}
                              ml={0}
                              mt={1}
                            />
                            <VStack style={{marginLeft: 10}}>
                              <Text
                                fontSize={16}
                                fontWeight={500}
                                size="smMedium"
                              >
                                {`Cancel Reason: ${detailState.detail.cancelReason.display}`}
                              </Text>
                            </VStack>
                          </HStack>
                        </VStack>
                      )}
                      {!props.hideAction &&
                        (isAppointmentStatusPending ||
                          isAppointmentStatusDecline ||
                          isAppointmentStatusProposed) &&
                        detailState.loggedInParticipantId &&
                        !detailState?.showProposedTimeBanner && (
                          <VStack>
                            <Text
                              color={Colors.Custom.Gray500}
                              size="smMedium"
                              fontSize={14}
                              style={{marginBottom: 8}}
                            >
                              RSVP
                            </Text>
                            <HStack>
                              <Icon
                                as={MaterialIcon}
                                name="reply"
                                size="5"
                                alignSelf={'flex-start'}
                                justifySelf={'flex-start'}
                                color={Colors.Custom.Gray400}
                                ml={0}
                                mt={1}
                              />
                              <VStack style={{marginLeft: 10}} maxW={'100%'}>
                                {detailState.detail.participants?.length &&
                                  detailState.detail.participants.map(
                                    (participant, index) => {
                                      return (
                                        <AppointmentRSVP
                                          key={index}
                                          appointmentDetails={
                                            detailState.detail as IAppointmentDetail
                                          }
                                          participant={participant}
                                          careStudioMlovData={
                                            mlovData.CARE_STUDIO_MLOV
                                          }
                                          loggedInParticipantId={
                                            detailState.loggedInParticipantId as string
                                          }
                                          onSendResponseSuccess={
                                            onSendResponseSuccess
                                          }
                                          isGroupAppointmentWidget={false}
                                          accountSettings={mlovData?.userSettings}
                                        />
                                      );
                                    }
                                  )}
                              </VStack>
                            </HStack>
                          </VStack>
                        )}
                      {detailState.detail.notes &&
                        detailState.detail.notes.length > 0 && (
                          <View>
                            {detailState.detail.notes.map(
                              (note: any, index: number) => {
                                return (
                                  <VStack w='100%'>
                                    <Text
                                      color={Colors.Custom.Gray500}
                                      size="smMedium"
                                      fontSize={14}
                                      style={{marginBottom: 8}}
                                    >
                                      NOTES
                                    </Text>
                                    <HStack>
                                      <Icon
                                        as={AntIcon}
                                        name={
                                          isPatientInstructionNote(note)
                                            ? 'database'
                                            : 'filetext1'
                                        }
                                        size="5"
                                        alignSelf={'flex-start'}
                                        justifySelf={'flex-start'}
                                        color={Colors.Custom.Gray400}
                                        ml={0}
                                        mt={1}
                                      />
                                      <View
                                        size="xsMedium"
                                        key={index}
                                        flex={1}
                                        marginLeft={3}
                                      >
                                        {parse(note.content)}
                                      </View>
                                    </HStack>
                                  </VStack>
                                );
                              }
                            )}
                          </View>
                        )}

                      {(detailState?.detail?.tasks?.length || 0) > 0 && (
                        <VStack>
                          <Text
                            color={Colors.Custom.Gray500}
                            size="smMedium"
                            fontSize={14}
                            style={{marginBottom: 8}}
                          >
                            INSTRUCTION FOR STAFF
                          </Text>
                          <HStack>
                            <Icon
                              as={FontAwesome5}
                              name={'tasks'}
                              size="5"
                              alignSelf={'flex-start'}
                              justifySelf={'flex-start'}
                              color={Colors.Custom.Gray400}
                              ml={0}
                              mt={3}
                            />
                            <VStack style={{marginLeft: 10}}>
                              <View
                                size="xsMedium"
                                width={'full'}
                                marginLeft={2}
                                marginTop={-1}
                                maxW={500}
                              >
                                <View width={'99%'}>
                                  <TaskCheckList
                                    tasks={detailState.detail?.tasks}
                                    canAddNewTasks={false}
                                    canDeleteTasks={false}
                                    canEditStatus={false}
                                    canEditTitle={false}
                                  />
                                </View>
                              </View>
                            </VStack>
                          </HStack>
                        </VStack>
                      )}
                    </VStack>
                    <BottomTabs
                    tabs={TASK_VIEW_TABS}
                    showAutomationTab={showAutomationTab && !detailState?.detail?.isBlockedInterval}
                    showActivityTab={!detailState?.detail?.isBlockedInterval}
                    automationTabView={automationTab}
                    auditResource={AUDIT_RESOURCE.APPOINTMENT}
                    appointmentId={defaultState?.detail?.id}
                    appointmentTypeList={props?.appointmentTypeList}
                    appointmentParticipantTypeIds={APPOINTMENT_PARTICIPANT_TYPE_IDS}
                    isGroupSessionAppointment={isGroupSessionAppointment}
                    refetchData={inviteSentOrDeleted}
                    onAuditRefetchData={onAuditRefetchData}
                    />
                  </VStack>
                </VStack>
              )}
            </VStack>
            {!(props.isLoading || detailLoading) && (shouldShowTracking || shouldShowInviteUser) && (
              <View flex={2}>
                {shouldShowTracking ? (
                  <SideTrackingView
                    subTitle={
                      isRecurringAppointment && isRsvpEnabled
                       ? intl.formatMessage({
                           id: 'memberActionsWillApplyToWholeSeriesMessage',
                         })
                       : undefined
                    }
                    appointment={detailState?.detail}
                    onFilterChange={(status?: string | null) => setFilterStatusId(status)}
                    filterStatusId={filterStatusId}
                    allContactList={getFilteredList()}
                    groupParticipantsMap={
                      detailState?.groupParticipantsMap || {}
                    }
                    groupNameMap={detailState?.groupDataMap || {}}
                    participantStatusLoadingId={participantStatusLoadingId}
                    handleParticipantActions={handleParticipantActions}
                    currentAppointmentStatus={getCurrentAppointmentStatus()}
                    appointmentStatusIds={APPOINTMENT_STATUS_IDS}
                  />
                ) : null}
                {shouldShowInviteUser ? inviteUserFlow() : null}
              </View>
            )}
          </HStack>
        )}

        <Drawer
          title={
            <ModalActionTitle
              title={intl.formatMessage({id: 'cancelAppointment'})}
              titleColor={''}
              titleSize={24}
              isHeadNotSticky
            />
          }
          destroyOnClose
          placement="right"
          onClose={() => {
            closeModal();
          }}
          open={modalState.cancelAppointment || modalState.cancelWithNoShow}
          closable={false}
          width={props?.drawerWidth || 450}
        >
          <VStack space={6}>
            <HStack>
              {totalAcceptedInvites === 0 ?
                <DisplayText textLocalId="cancelAppointmentConfirmationMsg" />
                :
                <Text>
                  Appointment will be cancelled for all {totalAcceptedInvites} confirmed participant(s). Are you sure you want to proceed?
                </Text>
              }
            </HStack>
            {ehrConfig.isAthena && (
              <AppointmentCancelReason
                isNoShow={modalState.cancelWithNoShow}
                onReasonSelect={(reason) => {
                  setModalState((prev) => ({...prev, cancelReason: reason}));
                }}
                locationId={detailState?.detail?.accountLocationId}
              />
            )}
            <HStack space={2}>
              <Spacer />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    setModalState((prev) => ({
                      ...prev,
                      cancelAppointment: false,
                      cancelWithNoShow: false,
                      cancelReason: undefined,
                      isForSeries: false,
                    }));
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'close'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainSecondaryBrown,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    setModalState((prev) => ({
                      ...prev,
                      cancelAppointment: false,
                      cancelWithNoShow: false,
                      cancelReason: undefined,
                    }));
                    onCancelAppointment(
                      modalState.isForSeries,
                      modalState.type as AppointmentAction,
                      modalState.isDeleted
                    );
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'cancelAppointment'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainPrimaryPurple,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </VStack>
        </Drawer>

        <Drawer
          title={
            <ModalActionTitle
              title={intl.formatMessage({id: 'noShow'})}
              titleColor={''}
              titleSize={24}
              isHeadNotSticky
            />
          }
          destroyOnClose
          placement="right"
          onClose={() => {
            closeNoShowModal();
          }}
          open={modalState.noShow}
          closable={false}
          width={450}
        >
          <VStack space={6}>
            <HStack>
              <DisplayText textLocalId="noShowNoShowConfirmationMessage" />
            </HStack>
            <HStack space={2}>
              <Spacer />
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.SECONDARY,
                  onPress: () => {
                    setModalState((prev) => ({...prev, noShow: false}));
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'close'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainSecondaryBrown,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
              <FoldButton
                nativeProps={{
                  variant: BUTTON_TYPE.PRIMARY,
                  onPress: () => {
                    setModalState((prev) => ({...prev, noShow: false}));
                    onNoShowAppointment();
                  },
                }}
                customProps={{
                  btnText: (
                    <DisplayText
                      textLocalId={'confirm'}
                      size={'smBold'}
                      extraStyles={{
                        color: Colors.Custom.mainPrimaryPurple,
                      }}
                    />
                  ),
                  withRightBorder: false,
                }}
              ></FoldButton>
            </HStack>
          </VStack>
        </Drawer>

        <FHDrawerAlert
          key={'remove-series-drawer'}
          isOpen={modalState.removeSeries}
          header={`Remove ${modalState.isForSeries ? 'Series' : 'Occurrence'}`}
          message={`${
            modalState.isForSeries
              ? 'Upcoming appointments in the cancelled series'
              : 'Appointment occurence'
          } will not be visible on Calendar. Do you wish to continue?`}
          closeModal={() => {
            setModalState((prev) => ({
              ...prev,
              removeSeries: false,
              isForSeries: false,
              type: '',
            }));
          }}
          buttonActions={[
            {
              textLocalId: 'Close',
              buttonProps: {
                colorScheme: 'muted',
                variant: 'outline',
              },
              onPress: () => {
                setModalState((prev) => ({
                  ...prev,
                  removeSeries: false,
                  isForSeries: false,
                  type: '',
                }));
              },
            },
            {
              textLocalId: 'Ok',
              textColor: 'white',
              buttonProps: {
                colorScheme: 'primary',
              },
              onPress: () => {
                onCancelAppointment(
                  modalState.isForSeries,
                  modalState.type as AppointmentAction,
                  true
                );
                setModalState((prev) => ({
                  ...prev,
                  removeSeries: false,
                  isForSeries: false,
                  type: '',
                }));
              },
            },
          ]}
        />

        <FHDrawerAlert
          isOpen={joinMeetingConfirmation.visible}
          header={joinMeetingConfirmation.header}
          message={joinMeetingConfirmation.message}
          closeModal={() => {
            resetJoinMeetingData();
          }}
          buttonActions={[
            {
              textLocalId: 'Close',
              buttonProps: {
                colorScheme: 'muted',
                variant: 'outline',
              },
              onPress: () => {
                resetJoinMeetingData();
              },
            },
            {
              textLocalId: 'Join',
              textColor: 'white',
              buttonProps: {
                colorScheme: 'primary',
              },
              onPress: () => {
                joinMeeting();
              },
            },
          ]}
        />
      </Drawer>

      {detailState.isReschedule && (
        <AppointmentBooking
          isVisible={detailState.isReschedule}
          editType={detailState?.rescheduleType}
          isGroupAppointmentEnable={isGroupSessionAppointment}
          rescheduleData={{
            isRsvpEnabled: detailState?.detail?.isRsvpEnabled || false,
            id: detailState.detail?.id || '',
            appointmentTypeId: detailState.detail?.appointmentType?.id || '',
            previousDate: new Date(),
            selectedUser: getUserFromParticipantObjectsForRescheduleView(
              detailState.detail?.participants || [],
              APPOINTMENT_PARTICIPANT_TYPE_IDS.primaryUser
            ),
            selectedSecondaryUserIds:
              getSecondaryUsersFromParticipantObjectsForRescheduleView(
                detailState.detail?.participants || [],
                APPOINTMENT_PARTICIPANT_TYPE_IDS.secondaryUser
              ),
            notes: detailState.detail?.notes || [],
            participants: mapParticipantObjectsForRescheduleView(
              detailState.detail?.participants || []
            ),
            originalParticipantList: detailState.detail?.participants || [],
            selectedLocation: detailState?.location,
            reasonForVisit: detailState?.detail?.reasonForVisit,
            accountLocationId: detailState?.detail?.accountLocationId || '',
            locationTypeId: detailState?.detail?.locationTypeId || '',
            tasks: detailState?.detail?.tasks,
            startDateTime: detailState.detail?.startDateTime,
            endDateTime: detailState.detail?.endDateTime,
            isReccurentAppointment:
              detailState?.detail?.isRecurrentAppointment || false,
            seriesId: detailState?.detail?.seriesId,
            reccurenceData: detailState?.detail?.reference?.recurrenceData,
            invites: detailState?.contactList,
            name: detailState?.detail?.name,
            reference: detailState?.detail?.reference,
            description: detailState?.detail?.description,
            locationGroupId: detailState?.detail?.locationGroupId,
          }}
          onComplete={(data?: any) => {
            if (props?.isCareProgramAppointment && props?.onComplete) {
              props.onComplete(data);
            }
            if (!detailState?.detail?.isRecurrentAppointment) {
              showLocalToast('Appointment rescheduled successfully!');
            }
            props?.onStatusChange && props?.onStatusChange();
            closeModal();
          }}
          onCancel={() => {
            closeModal();
          }}
          appointmentVisitType={props?.appointmentVisitType}
          disableSelectSlotBy={props?.disableSelectSlotBy}
          disablePrimaryUserSelect={props?.disablePrimaryUserSelect}
          onlyAtClinicAppointmentEnabled={props?.onlyAtClinicAppointmentEnabled}
          disableAppointmentReccurence={props?.disableAppointmentReccurence}
        />
      )}

      {modalState?.showEditBlockTime && (
        <BlockTimeBooking
          editType={detailState?.rescheduleType}
          userUUID={detailState?.detail?.participants[0]?.user?.uuid}
          slotSelected={{} as any}
          isVisible={modalState?.showEditBlockTime}
          onComplete={(data?: any) => {
            showLocalToast(
              intl.formatMessage({
                id: 'blockTimeEditMsg',
              })
            );
            setModalState((prev) => ({...prev, showEditBlockTime: false}));
            props.onStatusChange();
          }}
          onCancel={() => {
            setModalState((prev) => ({...prev, showEditBlockTime: false}));
          }}
          details={detailState?.detail}
        />
      )}

      {
        sendAppointmentLinkState?.isOpen && (
          <SendAppointmentLinkDrawer
            isOpen={sendAppointmentLinkState?.isOpen}
            appointmentId={sendAppointmentLinkState.appointmentId}
            participantId={sendAppointmentLinkState.participantId}
            linkCode={sendAppointmentLinkState.linkCode}
            appointment={sendAppointmentLinkState.appointment as IAppointmentDetail}
            contactData={sendAppointmentLinkState.contactData}
            onClose={() => {
              setSendAppointmentLinkState((prev) => ({
                ...prev,
                isOpen: false,
                appointmentId: '',
                participantId: '',
                linkCode: '',
                appointment: undefined,
                contactData: undefined,
              }));
            }}
          />
        )
      }
    </>
  );
};

export default withMiniContactViewHOC(AppointmentDetail);

export const styles = StyleSheet.create({
  editButton: {
    borderRadius: 12,
    maxHeight: 40,
    marginLeft:4,
    alignItems: 'center',
    borderColor: Colors.Custom.Gray300
  }
});
