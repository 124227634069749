import React, { useContext } from 'react';
import CustomWrapper, { IFormCommonData } from '../../CustomWrapper/CustomWrapper';
import DischargeDetailsView from './DischargeDetailsView';
import {IFormComponentProps} from '../../CustomWrapper/CustomWrapper';
import {useContactCareProgramContext} from '../../../../../ContactCareProgram/ContactCareProgramView/ContactCareProgram.context';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';

const DischargeDetailsWrapper: React.FC<IFormComponentProps> = (props) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  return (
    <>
      <DischargeDetailsView
        contactCareProgramId={props?.options?.contactCareProgramId || contextData?.contactCareProgramId}
        onChange={props?.onChange}
        validateRef={props?.validateRef}
        component={props?.component}
        disableForm={props?.disabled}
        handleUpdateCareProgram={props?.options?.handleUpdateCareProgram || contextData?.handleUpdateCareProgram}
      />
    </>
  );
};

export default DischargeDetailsWrapper;
