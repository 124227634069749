import { Radio, Stack, Text, View, VStack} from 'native-base';
import { useState } from 'react';
import { Colors } from '../../../../../../styles';
import {Answer, IRadioComponentProps} from '../interfaces';
import {styles} from './QuestionnaireComponentStyles';

const RadioComponent = (props: IRadioComponentProps) => {
  const {answerOptions, onSelectOption, title, required} = props;
  const [answer, setAnswer] = useState<string | undefined>(props.answer?.display);
  return (
    <VStack style={styles.container}>
      <View style={styles.titleView}>
        <Text style={styles.titleText}>{title} {required && <Text color={Colors.error[500]}>*</Text>}</Text>
      </View>

      <View>
        <Radio.Group
          defaultValue={props.answer?.display}
          name="radio"
          accessibilityLabel="radio"
          aria-label="radio"
          onChange={(value) => {
            const selectedAnswer = answerOptions.find(
              (item) => item.display === value
            ) as Answer;
            setAnswer(selectedAnswer.display);
            onSelectOption(selectedAnswer);
          }}
        >
          <Stack direction={answerOptions.length < 3 ? 'row' : 'column'} space={2}>
            {answerOptions.map((item) => {
              return (
                <Radio
                  key={`${item.code}_${item.display}`}
                  isDisabled={props.readonly}
                  value={item.display || ''}
                >
                  {item.display}
                </Radio>
              );
            })}
          </Stack>
        </Radio.Group>
        {required && !answer && <Text mt={2} color={Colors.error[500]}>This field is required</Text>}
      </View>
    </VStack>
  );
};

export default RadioComponent;
