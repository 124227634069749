import {
  Button,
  Divider,
  HStack,
  Pressable,
  Text,
  Tooltip,
  View,
  VStack,
  IconButton,
} from 'native-base';
import Icon from 'react-native-vector-icons/AntDesign';
import FeatherIcon from 'react-native-vector-icons/Feather';
import React, { useContext, useEffect } from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import {styles} from './PatientJourneyActionViewStyle';
import {
  getQuickActionList,
  isCurrentMenuLast,
} from './PatientCareJourneyHelper';
import {IPatientActionsViewProps} from './PatientJourneyInterface';
import {Colors} from '../../../../../styles/Colors';
import {IPatientJourneyAction} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/interfaces';
import {PATIENT_JOURNEY_ACTION_CODES} from '../../../../PersonOmniView/PersonHeaderBar/PersonAction/PersonActionPopover/ActionConst';
import FHActionPopover from '../../../../common/FHActionPopover/FHActionPopover';
import { PATIENT_CARE_JOURNEY_OPERATION_CODES } from '../../../../../constants/ConstantValues';
import { getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, getOnFocusButtonStyles } from '../../../../../utils/commonUtils';
import { isJourneyAllowed, USER_ACCESS_PERMISSION } from '../../../UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';
import { Popover } from 'antd';
import GoalNoteSvg from '../../../../common/Svg/GoalNoteSvg';
import { PATIENT_CARE_JOURNEY_REASON_CODE, PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { checkLocationAccess, getActivePatientProfile } from '../../../../common/ContactProfile/commonUtils';
import FeatureFlags from '../../../../../constants/FeatureFlags.enums';

const PatientJourneyActionsView = (props: IPatientActionsViewProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [quickActionList, setQuickActions] = React.useState<
    IPatientJourneyAction[]
  >(getQuickActionList(props.record) || []);
  const commonContext = useContext(CommonDataContext);
  const mlovData = useContext(CommonDataContext);
  const isSidecarContext = commonContext.sidecarContext?.isSidecar;
  const isCompactView = props?.isCompactView;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);

  useEffect(() => {
    let quickActions = getQuickActionList(props.record) || [];

    if(isSidecarContext || isCompactView){
      quickActions = quickActions.filter((action)=> action.actionCode !== PATIENT_JOURNEY_ACTION_CODES.EDIT)
    }
    setQuickActions(quickActions);
  }, [ props.record?.statusCode ]);

  const showJourneyTab = isJourneyAllowed()

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };

  const isPauseBtnVisible = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.PAUSE;
    });
  };

  const isStopBtnVisible = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.STOP;
    });
  };

  const isRestartBtnVisible = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.RESTART;
    });
  };

  const isStartBtnVisible = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.START;
    });
  };

  const isDeleteBtnVisible = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.DELETE;
    });
  };

  const isEditBtnVisible = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.EDIT;
    });
  };

  const isShowJourneyVisible = () => {
    return quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY;
    });
  };

  const isShowCareTeamVisible = () => {
    return quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM;
    });
  };

  const isCompleteBtnVisible = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.COMPLETE;
    });
  };

  const isShowChangeGoalStatusToAchieved = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_ACHIEVED;
    });
  }

  const isShowChangeGoalStatusToNotAchieved = () => {
    return showJourneyTab && quickActionList.some(action => {
      return action.actionCode == PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED;
    });
  }

  const filterOptionsForPopOver = () => {
    return quickActionList.filter(action => {
      return (
        action.actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM ||
        action.actionCode === PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY
      );
    });
  };

  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CARE_JOURNEY
  );  
  const isAccessToLocation = checkLocationAccess({
    locationUuids: [props?.record?.accountLocationId || ""],
    allowedLocationUuids:  allowedUserAccountLocationUuids.find(location => location === props?.activePatientAccountLocationId) ? [props?.activePatientAccountLocationId || ""] : [ ],
    isMultiTenancyEnabled: isMultiTenancyEnabled,
  });

  const isShowStopButton = allowedUserAccountLocationUuids.find((location)=> props?.record?.accountLocationId == location)

  const content = (
    <VStack style={{padding: 0, borderRadius: 16}}>
      {(filterOptionsForPopOver() || []).map((action: any, index: any) => {
        const displayString = action.displayString;
        return (
          <Pressable
            key={index}
            onPress={() => {
              props.onActionClick(action.actionCode);
              setIsOpen(!isOpen);
            }}>
            <HStack style={[styles.mainMenuContent, styles.firstMenu]}>
              <Text style={styles.mainMenuTxt}>
                <DisplayText size={'smMedium'} textLocalId={displayString} />
              </Text>
            </HStack>
            {!isCurrentMenuLast(action) && (
              <Divider my={1} style={styles.dividerStyle} />
            )}
          </Pressable>
        );
      })}
    </VStack>
  );

  const actions = [
    isShowCareTeamVisible() && {
      code: PATIENT_JOURNEY_ACTION_CODES.SHOW_CARE_TEAM,
      name: 'Show Care Team',
      iconName: 'users',
    },
    isShowJourneyVisible() && {
      code: PATIENT_JOURNEY_ACTION_CODES.SHOW_JOURNEY,
      name: 'Show Journey Flow',
      iconName: 'trello',
    },
    isStartBtnVisible() && isAccessToLocation && {
      code: PATIENT_JOURNEY_ACTION_CODES.START,
      name: 'Start Journey',
      iconName: 'play',
    },
    isPauseBtnVisible() && isAccessToLocation && {
      code: PATIENT_JOURNEY_ACTION_CODES.PAUSE,
      name: 'Pause Journey',
      iconName: 'pause',
    },
    isRestartBtnVisible() && isAccessToLocation && {
      code: PATIENT_JOURNEY_ACTION_CODES.RESTART,
      name: 'Restart Journey',
      iconName: 'play',
    },
    isStopBtnVisible() && (isAccessToLocation || isShowStopButton)&& {
      code: PATIENT_JOURNEY_ACTION_CODES.STOP,
      name: 'Stop Journey',
      iconName: 'stop-circle',
    },
    isEditBtnVisible() && isAccessToLocation && {
      code: PATIENT_JOURNEY_ACTION_CODES.EDIT,
      name: 'Edit Journey',
      iconName: 'edit-3',
    },
    isDeleteBtnVisible() && isAccessToLocation && {
      code: PATIENT_JOURNEY_ACTION_CODES.DELETE,
      name: 'Delete Journey',
      iconName: 'trash-2',
    },
    isCompleteBtnVisible() && isAccessToLocation && {
      code: PATIENT_JOURNEY_ACTION_CODES.COMPLETE,
      name: 'Complete Journey',
    },
    isShowChangeGoalStatusToAchieved() && isAccessToLocation && { 
      code: PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_ACHIEVED,
      name: 'Update Goal Status',
    },
    isShowChangeGoalStatusToNotAchieved() && isAccessToLocation && {
      code: PATIENT_JOURNEY_ACTION_CODES.CHANGE_GOAL_STATUS_TO_NOT_ACHIEVED,
      name: !props?.record?.goalStatusId ? 'Add Goal Status' : 'Update Goal Status',
    },
    {
      code: PATIENT_JOURNEY_ACTION_CODES.SHOW_GOALS,
      name: 'Show Journey Goal',
      iconName: 'eye',
    },
  ].filter((condition) => !!condition) as {code: string, name: string, iconName: string}[];

  let popoverActions: {code: string, name: string, iconName: string}[] = [];

  if (actions.length > 2) {
    popoverActions = actions.splice(1);
  }

  return (
    <View style={styles.iconView} justifyContent={'flex-start'} alignItems={isSidecarContext || isCompactView? "center" : 'flex-end'}>
      <HStack flexWrap={'wrap'} justifyContent={'flex-start'}>
        {
          props?.record?.statusCode === PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED &&
            props.record?.statusReasonCode ===
              PATIENT_CARE_JOURNEY_REASON_CODE.PATIENT_OPTED_OUT &&
            props?.record?.note && (
              <Popover
                overlayInnerStyle={{
                  borderRadius: 16,
                  padding: 0,
                }}
                content={
                  <div>
                    <div dangerouslySetInnerHTML={{__html: props?.record?.note}} />
                  </div>
                }
                trigger={'hover'}
                placement={'bottom'}
              >
                <View flex={1} justifyContent={'center'} marginRight={1}>
                  <GoalNoteSvg width={18} height={18}/>
                </View>
              </Popover>
            )
        }
        {
          actions?.length > 0 && actions.map((action) => {
            return (
              <Tooltip key={action.code} label={action.name} openDelay={100}>
                <Button
                  onPress={() => {
                    props.onActionClick(action.code);
                  }}
                  _focus={{...getOnFocusButtonStyles()}}
                  style={styles.btn}
                >
                  <Feather name={action.iconName} style={[styles.icons]} />
                </Button>
              </Tooltip>
            );
          })
        }
        {
          popoverActions?.length > 0 && (
            <View flex={2} alignItems={'flex-start'} padding={2}>
              <FHActionPopover
                customIconSize={18}
                isPrimary={false}
                actions={popoverActions}
                onActionClick={(code, event) => {
                  props.onActionClick(code);
                }}
              />
            </View>
          )
        }

      </HStack>
    </View>
  );
};

export default PatientJourneyActionsView;

