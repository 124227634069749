import { cloneDeep } from "lodash";
import { getBuilderVisibleComponents } from "../CustomFormEngineUtils";
import { FormElementInstance } from "../FormComponents/FormComponents";

export interface FormRendererState {
  components: FormElementInstance[];
  filteredComponents: FormElementInstance[];
  filledDataMap: Record<string, any>;
  invalidFields: Record<string, string>;
  isSubmitClicked: boolean;
  isSubmitting: boolean;
  hideErrorMessages: boolean;
}

export enum FormRendererActionType {
  SET_COMPONENTS = 'SET_COMPONENTS',
  SET_FILTERED_COMPONENTS = 'SET_FILTERED_COMPONENTS',
  UPDATE_FIELD_VALUES = 'UPDATE_FIELD_VALUES',
  SET_INVALID_FIELDS = 'SET_INVALID_FIELDS',
  INITIALIZE_DATA = 'INITIALIZE_DATA',
  UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE',
  SET_MULTIPLE_INVALID_FIELDS = 'SET_MULTIPLE_INVALID_FIELDS',
  SET_IS_SUBMIT_CLICKED = 'SET_IS_SUBMIT_CLICKED',
  SET_IS_SUBMITTING = 'SET_IS_SUBMITTING',
}

export type FormRendererAction =
  | {
      type: FormRendererActionType.SET_COMPONENTS;
      payload: {components: FormElementInstance[], builderComponents?: FormElementInstance[]};
    }
  | {
      type: FormRendererActionType.SET_FILTERED_COMPONENTS;
      payload: FormElementInstance[];
    }
  | {
      type: FormRendererActionType.UPDATE_FIELD_VALUE;
      payload: {key: string; value: any, builderComponents?: FormElementInstance[]};
    }
  | {
      type: FormRendererActionType.SET_INVALID_FIELDS;
      payload: {key: string; errorMessage: string};
    }
  | {
      type: FormRendererActionType.INITIALIZE_DATA;
      payload: Record<string, string>;
    }
  | {
      type: FormRendererActionType.SET_MULTIPLE_INVALID_FIELDS;
      payload: Record<string, string>;
    }
  | {
      type: FormRendererActionType.SET_IS_SUBMIT_CLICKED;
      payload: boolean;
    }
  | {
      type: FormRendererActionType.SET_IS_SUBMITTING;
      payload: boolean;
    };

  export const formRendererReducer = (
    state: FormRendererState,
    action: FormRendererAction
  ): FormRendererState => {
    switch (action.type) {
      case FormRendererActionType.SET_COMPONENTS:
        const clonedComponents = cloneDeep(action.payload.components);
        const visibleComponents = getBuilderVisibleComponents(clonedComponents, state.filledDataMap, action.payload.builderComponents);
        return {
          ...state,
          components: action.payload.components,
          filteredComponents: visibleComponents,
        };

      case FormRendererActionType.SET_FILTERED_COMPONENTS:
        return {
          ...state,
          filteredComponents: action.payload,
        };

      case FormRendererActionType.SET_INVALID_FIELDS:
        return {
          ...state,
          invalidFields: {
            ...state.invalidFields,
            [action.payload.key]: action.payload.errorMessage,
          },
        };

      case FormRendererActionType.SET_MULTIPLE_INVALID_FIELDS:
        return {
          ...state,
          invalidFields: action.payload,
        };

      case FormRendererActionType.UPDATE_FIELD_VALUE:
        {
          const updatedFilledDataMap = {
            ...state.filledDataMap,
            [action.payload.key]: action.payload.value,
          };
          const clonedComponents = cloneDeep(state.components);
          return {
            ...state,
            filledDataMap: updatedFilledDataMap,
            filteredComponents: getBuilderVisibleComponents(clonedComponents, updatedFilledDataMap, action.payload.builderComponents),
          };
        }

      case FormRendererActionType.INITIALIZE_DATA:
        return {
          ...state,
          filledDataMap: action.payload,
        };

      case FormRendererActionType.SET_IS_SUBMIT_CLICKED:
        return {
          ...state,
          isSubmitClicked: action.payload,
        };

      case FormRendererActionType.SET_IS_SUBMITTING:
        return {
          ...state,
          isSubmitting: action.payload,
        };
      default:
        return state;
    }
  };
