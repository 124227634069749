// Styles
import '@foldhealth/formiojs/dist/formio.full.min.css';
import './FormioStyles.scss';
import 'bootstrap/dist/js/bootstrap.min.js';

// Templates
import './Builder/Templates';
import './Builder/CustomWebBuilder';
import './Builder/CustomComponent';

// Custom components
import './OverridenComponents/RegisterOldComponents';
import './CustomComponents/TextAreaComponent'
import './CustomComponents/TextFieldComponent'
import './CustomComponents/Date/Date';
import './CustomComponents/Paragraph/Paragraph';
import './CustomComponents/Image';
import './CustomComponents/Survey/Survey';
import './CustomComponents/SubjectiveComplaint/SubjectiveComplaint';
import './CustomComponents/ObjectiveFindings/ObjectiveFindings';
import './CustomComponents/Assessment/Assessment';
import './CustomComponents/Plan/Plan';
import './CustomComponents/Signature/Signature';
import './CustomComponents/File/File';
import './CustomComponents/Conditions';
import './CustomComponents/Immunizations';
import './CustomComponents/Allergies';
import './CustomComponents/PatientDemographics';
import './CustomComponents/PatientCommunication';
import './CustomComponents/PatientAddress';
import './CustomComponents/EmergencyContact';
import './CustomComponents/FamilyHistory';
import './CustomComponents/SocialHistory';
import './CustomComponents/Vitals';
import './CustomComponents/Medications';
import './CustomComponents/SurgicalHistory';
import './CustomComponents/MultiSelectCheckBoxes';
import './CustomComponents/ChiefComplaint';
import './CustomComponents/Diagnosis';
import './CustomComponents/DatePicker';
import './CustomComponents/FormDatePicker';
import './CustomComponents/Rating';
import './CustomComponents/Select';
import './CustomComponents/ColorPicker';
import './CustomComponents/PastMedicalHistory';
import './CustomComponents/ConsentAndPreferredCommunication'
import './CustomComponents/PatientIdentifier'
import './CustomComponents/NoteSubStatus/NoteSubStatus'
import './CustomComponents/NoteStatus/NoteStatus'
import './CustomComponents/Barriers'
import './CustomComponents/Intervention'
import './CustomComponents/Goals';
import './CustomComponents/CarePlanSections/SymptomMonitoring';
import './CustomComponents/CarePlanSections/MedicationManagement';
import './CustomComponents/CarePlanSections/Diet';
import './CustomComponents/CarePlanSections/Exercise';
import './CustomComponents/CarePlanSections/Habits';
import './CustomComponents/CarePlanSections/HomeMonitoring';
import './CustomComponents/CarePlanSections/LabMonitoring';
import './CustomComponents/CarePlanSections/HealthMaintenance';
import './CustomComponents/HomeMonitoringView';
import './CustomComponents/DischargeDetailsForm';
import './CustomComponents/CareProgramAppointments';
import './CustomComponents/AwvHistory'
import './CustomComponents/ClinicalParameters';
import './CustomComponents/PatientInformationVerification'
import './CustomComponents/CustomTable';

// Overriden components
// import './OverridenComponents/TextField';
// import './OverridenComponents/TextArea';
import './OverridenComponents/Email';
import './OverridenComponents/SelectBoxes';
import './OverridenComponents/Select';
import './OverridenComponents/PhoneNumber';
import './OverridenComponents/Radio';
import './OverridenComponents/Number';
import './OverridenComponents/Currency';
import './OverridenComponents/Columns';
import './OverridenComponents/Button';
import './OverridenComponents/Hidden';
import './OverridenComponents/Panel';
import './OverridenComponents/Checkbox';

// Component sequence
import './Builder/ComponentSequence';

import {addOptionalTextInPlaceholder} from './Utils';

addOptionalTextInPlaceholder();
