import {
  Text,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {componentKeys, FormError} from '../../CustomWrapper/CustomComponentHelper';
import {
  FormContext,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import { cloneDeep } from 'lodash';
import DetailPreview from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { Colors } from '../../../../../../../styles';
import ReasonForVisitSearch from '../../../../../../common/ReasonForVisitSearch/ReasonForVisitSearch';
import { ADD_NEW_REASON_FOR_VISIT } from '../../../../../../../constants';
import ReasonForVisitFreeText from '../../../../../../PublicPages/AppointmentBookingWidget/ReasonForVisitFreeText';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';


export interface IChiefComplaintComponentValue {
  chiefComplaint?: {
    code?: string;
    displayName?: string,
    type?: string,
  }
}

interface IAddOrUpdateChiefComplaintState {
  showErrors: boolean;
  loading: boolean;
  formError?: FormError;
}

const AddOrUpdateChiefComplaint = (props: IFormComponentProps) => {
  const { options } = props;
  const componentRef = useRef();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isDoNotAllowToReplaceNextLine = options?.isDoNotAllowToReplaceNextLine || contextData?.isDoNotAllowToReplaceNextLine || false;
  const [componentValue, setComponentValue] =
    useState<IChiefComplaintComponentValue | undefined>(
      props.component?.selectedValue?.chiefComplaint ? { chiefComplaint: props.component?.selectedValue?.chiefComplaint } : props.value || undefined
    );
  const isPrintMode = options?.isPrintMode || contextData.isPrintMode || false;
  const [componentState, setComponentState] =
    useState<IAddOrUpdateChiefComplaintState>({
      showErrors: false,
      loading: false,
      // For chief complaint, we don't need to show form error as save to resource fail or section not applicable
      // Thus commenting below change
      // formError: contextData.formError,
    });

  const validateData = (currentData: IChiefComplaintComponentValue, silentCheck?: boolean): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: !silentCheck}));
    if (props.component?.validate?.required) {
      return { isValid: !!currentData?.chiefComplaint?.displayName, message: !currentData?.chiefComplaint?.displayName ? `${props.component.label} is required` : '' };
    }
    return { isValid: true, message: '' };
  };

  useEffect(() => {
    props.onChange(cloneDeep(componentValue));
  }, [componentValue]);

  useEffect(() => {
    const isDataAvailable = !!componentValue?.chiefComplaint?.displayName;
    contextData?.updateLoadingStatus?.(componentKeys.CHIEF_COMPLAINT, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  props.validateRef.current = validateData;
  if (isPreviewMode) {
    let displayValue = componentValue?.chiefComplaint?.displayName ?? '-';
    if (!isPrintMode && (displayValue || '')?.trim().length > 0 && !isDoNotAllowToReplaceNextLine) {
      const linBreakRegex = /<br\s*[\/]?>/gi;
      const parsedValue = typeof displayValue === 'string' ? displayValue.replaceAll(linBreakRegex, '\n') : displayValue;
      displayValue = parsedValue;
    }
    // If print patient note and existing string has \n then replace with </br> tag
    if (isPrintMode) {
      const parsedValue = typeof displayValue === 'string' ? displayValue.replaceAll('\n', '</br>') : displayValue;
      displayValue = parsedValue;
    }
    if (!displayValue) {
      displayValue = '-';
    }
    return (
      <>
        {!componentState.loading && !componentState.formError && (
          <>
            {displayValue ? (
              <div className={`${isAllowToShare ? 'page-break' : 'disallow-to-share'}`}>
                <DetailPreview titleLocalId={props.component.label}>
                  <VStack>
                    <Text fontSize="md">
                      {displayValue}
                    </Text>
                  </VStack>
                </DetailPreview>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <VStack ref={componentRef}>
      {componentState.loading && <FormComponentLoader />}
      {!componentState.loading && componentState.formError && (
        <FormComponentError error={componentState.formError} />
      )}
      {!componentState.loading && !componentState.formError && (
        <VStack space={4} flex={1}>
          <ReasonForVisitFreeText
            macroTemplatesEnabled={props.component?.macroTemplatesEnabled && !isPatientForm}
            customStyles={{fontSize:17}}
            numberOfLines={3}
            value={componentValue?.chiefComplaint?.displayName}
            isDisabled={props.disabled}
            isRequired={props.component?.validate?.required}
            isShowError={componentState.showErrors && props.component?.validate?.required && !componentValue?.chiefComplaint?.displayName}
            customPlaceHolder={props.component.placeHolder}
            onChange={(value) => {
              if (value?.type === ADD_NEW_REASON_FOR_VISIT) {
                value.code = undefined;
              }
              // removing ...prev as componentValue contains only one key
              setComponentValue((prev) => ({chiefComplaint: value}));
            }}
          />
        </VStack>
      )}
    </VStack>
  );
};

export default AddOrUpdateChiefComplaint;
