import { Popover } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import {
  Badge,
  Button,
  HStack, Pressable,
  Skeleton,
  Spinner,
  Text,
  VStack,
  View,
  useMediaQuery
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Fontisto from 'react-native-vector-icons/Fontisto';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../constants';
import { Colors } from '../../../styles';
import { getCaslAbility, isEmployerRole, isMasterAccount, isSettingScreen } from '../../../utils/commonUtils';
import { AutomationSettingBtn } from '../AutomationSettingBtn';
import { styles } from './CustomTabsViewStyles';
import { ICustomTabsView, ITabsList } from './interfaces';
import { testID, TestIdentifiers } from '../../../testUtils';
import { FlowType } from '../../../context/WorkflowContext';
import {USER_ACCESS_PERMISSION} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';
import { CommonDataContext } from '../../../context/CommonDataContext';

const CustomTabsView = (props: ICustomTabsView) => {
  const {
    borderBottomWidth,
    fontWeight,
    basePath,
    tabsList,
    onTabClick,
    isAddMaxTab,
    canNavigate,
    tabListWrapperStyle,
    handleWithOnTab,
    rightView,
    isLoading,
    onTabChanged,
    onActionPerformed,
    contentTopMargin,
    buttonList,
    showBackButton,
    backButtonRouteFunc,
    heading,
    showHeading,
    extraBigRightView,
    minScreenSupportWidth,
    titleStyle,
    hideAutomationSettingsButton,
    searchAndClose,
    leftOffset,
    flowType,
    workflowMasterId,
    isDrawerOpen,
    tabsLoading,
    hideSettingButton
  } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const location = useLocation();
  const pathList = location?.pathname?.split('/') || [];
  const lastPathStr = pathList[pathList?.length - 1];
  const isMasterAccountFlag = isMasterAccount()
  const selectedPathItem =
    tabsList.find((item) => {
      return item.path === lastPathStr;
    }) || tabsList[0];
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData?.sidecarContext?.isSidecar;
  const [selectedTab, setSelectedTab] = useState(selectedPathItem);
  const activeTab = (): ITabsList => {
    return canNavigate ? selectedPathItem : selectedTab;
  };
  const navigateToTabPath = (tabItem: ITabsList) => {
    let finalPath = tabItem?.path || '';
    if (basePath) {
      finalPath = basePath + '/' + (tabItem?.path || '');
    }
    navigate(finalPath);
  };

  const [isOpen, setIsOpen] = useState(false);
  const {height} = Dimensions.get('window');


  const handleParamsOnTabChange = (tabKey: string, flowType: FlowType | undefined, workflowMasterId: string | undefined, isDrawerOpen: string | undefined) => {
    if (workflowMasterId && isDrawerOpen !== undefined && flowType !== undefined) {
      setSearchParams({ workflowMasterId: workflowMasterId, flowtype: flowType, key: tabKey, isdraweropen: isDrawerOpen });
    }
  };

  const handleParamsForTriggerState = (tabKey: string, isDrawerOpen: string | undefined) => {
    if (tabKey) {
      const currentParams = new URLSearchParams(searchParams.toString());
      currentParams.set('key', tabKey);
      if (isDrawerOpen === 'true') {
        currentParams.set('isdraweropen', 'true');
      } else {
        currentParams.delete('isdraweropen');
      }
      setSearchParams(currentParams.toString());
    }
  };

  useEffect(() => {
    if (
      canNavigate &&
      selectedPathItem?.path &&
      basePath &&
      lastPathStr != selectedPathItem.path
    ) {
      // navigateToTabPath(selectedPathItem);
    }
  }, []);

  useEffect(() => {
    if (canNavigate && selectedPathItem?.path && basePath) {
      onTabClick && onTabClick(selectedPathItem);
    }
  }, []);

  useEffect(() => {
    if (searchParams.has('key')) {
      const key = searchParams.get('key');
      const activeTab = tabsList.find(tab => tab.key === key);
      if (activeTab) {
        setSelectedTab(activeTab);
      }
    }
  }, [searchParams, tabsList]);


  useEffect(() => {
    if (onTabChanged) {
      onTabChanged(lastPathStr);
    }
  }, [lastPathStr]);

  const shimmerView = (): JSX.Element => {
    return (
      <View
        zIndex={10}
        position="absolute"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        top="50%"
        left="50%"
      >
        <Spinner size="lg" />
      </View>
    );
  };

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  let isLessThanMinScreenSupportWidth = false;
  if(minScreenSupportWidth != undefined){
    [isLessThanMinScreenSupportWidth] = useMediaQuery([
      {maxWidth: minScreenSupportWidth},
    ]);
  }

  const isSmallScreen = isIPadMiniScreen || isIPadScreen || isLessThanMinScreenSupportWidth;

  let maxTab = 1;
  if (isAddMaxTab && window && window?.innerWidth) {
    maxTab = Math.floor((window.innerWidth-(leftOffset || 800)) / 100);
    if (maxTab <= 0) {
      maxTab = 1;
    }

  }
  const isShowMoreOption = !isSideCarContext && isSmallScreen && tabsList?.length > maxTab;

  const getOptionView = (tabItem: ITabsList, index: number) => {
    return (
      <HStack key={`${tabItem.title}_${index}`}>
        {tabItem.showLeftBorder && (
          <View
            width={'100%'}
            style={{
              width: 1,
              height: 36,
              backgroundColor: Colors.Custom.BorderColor,
              borderRadius: 6,
            }}
          />
        )}
        <Pressable
          // style={[tabListWrapperStyle ? tabListWrapperStyle : {},]}
          key={index}
          isDisabled={tabItem.isDisabled}
          style={{width: '100%'}}
          onPress={() => {
            setIsOpen(!isOpen);
            if (canNavigate && tabItem?.path) {
              navigateToTabPath(tabItem);
            } else {
              setSelectedTab({...selectedTab, ...tabItem});
            }
            if (onTabClick) {
              onTabClick(tabItem, index);
            }
          }}
        >
          <VStack
            style={[styles.tabStyles,{marginRight: 0, width: '100%', alignItems: 'flex-start'}]}
            backgroundColor={
              activeTab().title === tabItem.title
                ? tabItem.backgroundColor
                  ? tabItem.backgroundColor
                  : Colors.FoldPixel.PRIMARY100
                : '#fff'
            }
          >
            <Text size={'smRegular'} color={activeTab().title === tabItem.title
                ? Colors.FoldPixel.PRIMARY300
                : Colors.FoldPixel.GRAY300}>
              {tabItem.title}
            </Text>
          </VStack>
        </Pressable>
      </HStack>
    )
  }
  const moreOptionsElements = () => {
    const tempList = tabsList.slice(maxTab);
    return (
      <VStack maxH={700} overflow={'scroll'}>
        {tempList.map((tabItem: ITabsList, index: number) => {
          return (
            <>
            {tabItem?.resourceCode ? (
              <FoldPermitCan 
                key={`${tabItem.title}_${index}`}
              resource={tabItem?.resourceCode} 
              action={tabItem.actionEvent || USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
              component={getOptionView(tabItem,index)}
            />) : getOptionView(tabItem,index)}
            </>
          );
        })}
      </VStack>
    );
  };


  const getTabList = () => {
    if (isShowMoreOption) {
      return tabsList.slice(0, maxTab);
    }
    return tabsList;
  };

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };
  const getTabButton = (tabItem: ITabsList, index: number) => {
    const isActive = activeTab().title === tabItem.title;
    return (<HStack
      key={tabItem.title}
      style={{
        alignItems: 'center',
      }}
    >
      {tabItem.showLeftBorder && (
        <View
          style={{
            width: 1,
            height: 36,
            backgroundColor: Colors.Custom.BorderColor,
            marginRight: 12,
            borderRadius: 6,
            marginLeft: 2,
          }}
        />
      )}
      <Pressable
        // style={[tabListWrapperStyle ? tabListWrapperStyle : {},]}
        key={index}
        isDisabled={tabItem.isDisabled}
        onPress={() => {
          if (canNavigate && tabItem?.path) {
            navigateToTabPath(tabItem);
          } else {
            setSelectedTab({...selectedTab, ...tabItem});
          }
          if (onTabClick) {
            onTabClick(tabItem, index);
          }
        }}
      >
        <VStack
          style={[
            styles.tabStyles
          ]}
          color={
            activeTab().title === tabItem.title
              ? tabItem.backgroundColor
                ? tabItem.backgroundColor
                : Colors.primary[300]
              : Colors.Custom.Gray500
          }
          fontWeight={
            activeTab().title === tabItem.title && fontWeight !== undefined ? fontWeight :
            activeTab().title === tabItem.title ? 700 : 600
          }
          borderBottomWidth={
            activeTab().title === tabItem.title && borderBottomWidth !== undefined ? borderBottomWidth :
            activeTab().title === tabItem.title ? 5 : 0
          }
          borderBottomColor={
            activeTab().title === tabItem.title
              ? tabItem.backgroundColor
                ? tabItem.backgroundColor
                : Colors.primary[300]
              : '#fff'
          }
        >
          <HStack alignItems={'center'} space={2}>
            <Text size={'ctNormal'} style={{color: activeTab().title === tabItem.title 
                ? Colors.primary[300] 
                : Colors.FoldPixel.GRAY300
            }}
            {...testID(tabItem.title)}
            >
              {tabItem.title}
            </Text>
            {tabItem?.count && tabItem?.count > 0 ? (
              <Badge
                size={'exsMedium'}
                backgroundColor={'#F2F4F7'}
                _text={{
                  color: Colors.Custom.AlertsDescriptionColor,
                }}
                alignSelf="center"
                rounded="full"
                justifyContent={'center'}
                paddingX={2}
                paddingY={1}
              >
                {tabItem.count}
              </Badge>
            ) : null}
          </HStack>
        </VStack>
      </Pressable>
    </HStack>)
  }

  const isDrawerOpened = searchParams.get('isdraweropen') === 'true';

  return (
    <View>
      <View
        style={{
          backgroundColor: '#ffffff',
          borderBottomWidth: 1,
          borderBottomColor: Colors.Custom.BorderColor,
        }}
      >
        <HStack
          alignItems={'center'}
          justifyContent="space-between"
          pt={0}
          pb={0}
          style={[
            {width: '100%', backgroundColor: '#ffffff',paddingLeft:12,paddingRight:24, height: 54},
          ]}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center'}}>
            {showBackButton ? (
              <View style={{marginHorizontal: 12}}>
                <Button
                  backgroundColor={'white'}
                  _hover={{bg: Colors.primary['100'] + '7a' || ''}}
                  style={styles.backButtonStyle}
                  onPress={() => {
                    if (backButtonRouteFunc) {
                      backButtonRouteFunc()
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <AntIcon name="arrowleft" size={18} />
                </Button>
              </View>
            ) : (
              <></>
            )}
            {/* {showHeading && (
              <DisplayText
                size={'xlBold'}
                extraStyles={{fontSize: 30, fontWeight: 600}}
                textLocalId={heading || activeTab().key || ''}
              />
            )} */}
            <HStack
              style={{
                alignItems: 'center',
                justifyContent: showHeading
                  ? extraBigRightView
                    ? 'flex-end'
                    : 'center'
                  : 'flex-start',
              }}
            >
              {searchAndClose}
              {props.showSearchBar ? props.searchBar :
              <>
              {tabsLoading && <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: 600,
              }}>
                {[...Array(4)].map((_, index) => (
                  <Skeleton key={index} rounded="md" width={130} height={30} />
                ))}
              </View>}
              {!tabsLoading && getTabList()?.map((tabItem: ITabsList, index: number) => {
                return (
                  <>
                  {tabItem?.resourceCode ? (
                    <FoldPermitCan 
                    key={`custom_tab_${index}`}
                    resource={tabItem?.resourceCode} 
                    action={tabItem.actionEvent || USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                    component={getTabButton(tabItem,index)}
                />) : getTabButton(tabItem,index)}
                  </>
                );
              })}
              {buttonList &&
                buttonList.map((button: any, index: number) => {
                  if (button.show)
                    return (
                      <Pressable
                        // style={[tabListWrapperStyle ? tabListWrapperStyle : {},]}
                        key={index}
                        onPress={() => {
                          button.onClick();
                        }}
                      >
                        <VStack
                          style={styles.tabStyles}
                          color={Colors.Custom.Gray500}
                          backgroundColor={'#fff'}
                        >
                          {button.btnText}
                        </VStack>
                      </Pressable>
                      // <ModalActionBtn
                      //   isDisabled={button.isDisabled}
                      //   loading={button.isLoading}
                      //   textColor={button.textColor}
                      //   btnText={button.btnText || ''}
                      //   btnStype={button.variant}
                      //   onClickAction={() => {
                      //     button.onClick();
                      //   }}
                      //   leftIcon={button.leftIcon}
                      //   rightIcon={button.rightIcon}
                      // />
                    );
                })}
                </>
                }
            </HStack>
          </View>


            {isShowMoreOption && !props.showSearchBar && (
              <HStack style={{marginLeft:'auto',marginRight:'50px'}}>
              <Popover
                content={moreOptionsElements}
                trigger="click"
                overlayInnerStyle={{padding: 0, borderRadius: 16}}
                overlayStyle={{padding: 0}}
                overlay={{padding: 0}}
                style={{padding: 0, borderRadius: 16}}
                placement={'bottom'}
                visible={isOpen}
                onVisibleChange={handleVisibleChange}
              >
                <Button
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 18,
                    borderWidth: 1,
                    borderColor: Colors.secondary['200'],
                    backgroundColor: Colors.secondary['100'],
                  }}
                  {...testID(TestIdentifiers.moreBtn)}
                  onPress={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <Fontisto
                    name="more-v-a"
                    size={18}
                    color={Colors.secondary['800']}
                  />
                </Button>
              </Popover>
              </HStack>
            )}
            <HStack style={{}}>
            {rightView ? (
              <Content>{rightView(activeTab())}</Content>
            ) : (
              <View></View>
            )}
            {!isEmployerRole() && !hideAutomationSettingsButton && !isMasterAccountFlag && !isDrawerOpened && (
              <AutomationSettingBtn
                flowType={props.flowType}
                settingsPath={props.settingsPath}
                hideSettingButton={hideSettingButton}
              />
            )}
          </HStack>
        </HStack>
        {/* <HStack style={{marginLeft: 20}}>
          {[1, 2, 3, 4].map((val) => {
            return <QuickHelpCard />;
          })}
        </HStack> */}
      </View>
      {!handleWithOnTab && (
        <Content
          className="common-scroll"
          style={{
            backgroundColor: '#fff',
            marginTop: contentTopMargin === 0 ? contentTopMargin : 16,
            ...(isSettingScreen()
              ? {
                  height: height - (props.bottomOffset || 225),
                  overflow: 'scroll',
                }
              : {}),
          }}
        >
          {isLoading ? shimmerView() : activeTab()?.tabContainerElem?.()}
        </Content>
      )}
    </View>
  );
};

export default CustomTabsView;
