declare const window: any;
import {Form} from '@foldhealth/formio-react';
import React, {memo, useCallback, useContext, useMemo, useState} from 'react';
import '../FHFormio';
import FileService from '../FileService/FileService';
import {dataURItoBlob} from '../Utils';
import {merge, cloneDeep} from 'lodash';
import './FmFormStyle.css'
import FormUtils from '@foldhealth/formiojs/lib/utils';
import {isSideCarWindow} from '../../../../../constants/BaseUrlConst';
import { FormRenderer } from '../../CustomFormEngine/FormRenderer';
import { FormCommonDataProvider } from '../../CustomFormEngine/Context/FormCommonData.context';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { isNewFormRendererEnabled } from '../../../../../utils/commonUtils';
// Disable eval in formio which is against CSP
FormUtils.Evaluator.noeval = isSideCarWindow();

const FHForm = (props: {
  components: any;
  readOnly?: boolean;
  submittedResponse?: any;
  optionData?: any;
  canUpdateOnChangeRef?: boolean;
  onChange?: (data: any) => void;
  onDefaultDataSet?: (defaultValues: {data: Record<string, string>, changed: boolean}) => void;
  onSubmit?: (data: any) => void;
  isPreviewMode?: boolean;
  fetchDataForPreview?: boolean;
  updateLoadingStatus?: (componentKey: string, isLoading: boolean) => void;
  // formPrefilledByFoldProgressByComponent?: {[index: string]: number};
  updateFormPrefilledByFoldProgress?: (componentKey: string, data: any) => void;
  onFormReady?: (instance: any) => void;
  isNewFormRenderer?: boolean;
  formRef?: React.RefObject<{
    validateForm: () => Promise<{ isValid: boolean; }>;
  }>;
  hideErrorMessages?: boolean;
}) => {
  const contextData = useContext(CommonDataContext);
  const newFormRendererEnabled = props.isNewFormRenderer || isNewFormRendererEnabled(contextData.userSettings) || false;
  const fileService = new FileService();
  fileService.formOptions = props.optionData;
  let signatureComponents: any[] = [];

  const replaceToNextLineText = () => {
    const answer = props?.submittedResponse?.data;
    if (props.isPreviewMode && answer && typeof answer === 'object') {
      Object.keys(answer).forEach(key => {
        if (key && props.submittedResponse?.data?.[key] && typeof props.submittedResponse?.data?.[key] === 'string') {
          props.submittedResponse.data[key] = props.submittedResponse?.data?.[key].replaceAll('\n', '<br>');
        }
      });
    }
  };

  replaceToNextLineText();

  const setValue = (components: any[], submittedData: any) => {
    components.forEach((component: any) => {
      const currentComponent = component.component || component;
      if (submittedData[currentComponent.key] || submittedData[currentComponent.key] == 0) {
        currentComponent.selectedValue = submittedData[currentComponent.key];
        if (currentComponent.type === 'signature') {
          signatureComponents.push(currentComponent);
        }
      }

      if (currentComponent.columns && currentComponent.columns.length > 0) {
        currentComponent.columns.forEach((column: any) => {
          setValue(column.components, submittedData);
        });
      }
      if (
        currentComponent.components &&
        currentComponent.components.length > 0
      ) {
        setValue(currentComponent.components, submittedData);
      }
    });
  };

  const onSubmit = useCallback(
    (formData: any) => {
      signatureComponents = [];
      let uploadCompleteCount = 0;
      const updatedComponents = cloneDeep(props.components);
      setValue(updatedComponents, formData.data);
      const signatureWithData = signatureComponents.filter((item) => !!item.selectedValue);
      if (signatureWithData.length > 0) {
        signatureWithData.forEach((component) => {
          fileService
            .uploadFile(
              'url',
              dataURItoBlob(component.selectedValue),
              component.label
            )
            .then((fileInfo) => {
              component.selectedValue = fileInfo;
              uploadCompleteCount++;
              if (
                uploadCompleteCount === signatureWithData.length &&
                props.onSubmit
              ) {
                props.onSubmit(updatedComponents);
              }
            })
            .catch(() => {
              alert('There is an error while uploading ' + component.label);
              uploadCompleteCount++;
              if (
                uploadCompleteCount === signatureWithData.length &&
                props.onSubmit
              ) {
                props.onSubmit(updatedComponents);
              }
            });
        });
      } else if (props.onSubmit) {
        props.onSubmit(updatedComponents);
      }
    },
    [props.components]
  );

  const onChange = useCallback(
    (formData: any) => {
      if (props.onChange) {
        props.onChange(newFormRendererEnabled ? {data: formData, changed: true} : formData);
      }
    },
    props.canUpdateOnChangeRef ? [props.onChange] : []
  );

  const onDefaultDataSet = useCallback((defaultValues: Record<string, string>) => {
    if (props.onDefaultDataSet) {
      props.onDefaultDataSet({data: defaultValues, changed: true});
    }
  }, [props.onDefaultDataSet]);

  const onFormReady = useCallback(
    (instance: any) => {
      props.onFormReady?.(instance);
    },
    []
  );

  let formOptions: any = {
    fileService,
  };
  if (props.readOnly) {
    formOptions['readOnly'] = true;
    // formOptions['renderMode'] = 'html';
  }
  if (props.isPreviewMode) {
    formOptions['renderMode'] = 'html';
  }
  if (props.fetchDataForPreview) {
    formOptions.fetchDataForPreview = true;
  }
  if (props.updateLoadingStatus) {
    formOptions.updateLoadingStatus = props.updateLoadingStatus;
  }
  // if (props.formPrefilledByFoldProgressByComponent) {
  //   formOptions.formPrefilledByFoldProgressByComponent = props.formPrefilledByFoldProgressByComponent;
  // }
  if (props.updateFormPrefilledByFoldProgress) {
    formOptions.updateFormPrefilledByFoldProgress = props.updateFormPrefilledByFoldProgress;
  }
  if (props.optionData) {
    formOptions = merge(formOptions, props.optionData);
  }

  const componentsWithSubmitButton = useMemo(() => {
    return( props.components || []).filter((component: any) => component.type !== 'button');
  }, [props.components]);

  const hasSubmitButton = useMemo(() => {
    return(props.components || []).some((component: any) => component.type === 'button');
  }, [props.components]);

  if (newFormRendererEnabled) {
    return (
      <FormCommonDataProvider
        {...formOptions}
        isPreviewMode={props.isPreviewMode}
      >
        <FormRenderer
          formReference={props.formRef}
          components={componentsWithSubmitButton}
          showSubmitButton={hasSubmitButton}
          onFormDataChange={onChange}
          onDefaultDataSet={onDefaultDataSet}
          defaultValues={props.submittedResponse?.response?.data || props.submittedResponse?.data}
          isReadOnly={props.readOnly || props.isPreviewMode}
          onSubmit={onSubmit}
          hideErrorMessages={props.hideErrorMessages}
      />
      </FormCommonDataProvider>
    );
  }

  return (
    <Form
      form={{components: props.components}}
      onSubmit={onSubmit}
      onChange={onChange}
      options={formOptions}
      submission={props.submittedResponse}
      formReady={onFormReady}
    />
  );
};

export default memo(FHForm);
