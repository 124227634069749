import { CodeableConcept, Extension, MedicationStatement } from 'fhir/r4';
import {IKeyOperation} from '../../../../../../../Interfaces';
import { getExtensionValue } from '../../../../../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { EXTENSION_URLS } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { EHRName } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { MedicationFields } from '../../CustomComponentUtils';
import {
  IAddOrUpdateMedicationParams,
  IMedicationComponentValue,
  IMedicationStatement,
} from '../interfaces';
import { FHIR_CODE_SYSTEMS, FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
import { IFormatReconciliationMetaData, IReconciliationConflictField, ReconciliationSource } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { FHIRConflictFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { getFieldCapabilities } from '../../../../../../../utils/capabilityUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { getEffectivePeriodFromTime, getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { MCG } from '../../../../../../../constants/ConstantValues';

export const getMedicationCodeObject = (item: any) => {
  return item.medicationCodeableConcept || item.medicationReference
}

export const getRecordListFormatted = (list: any[], fields: IKeyOperation | undefined, ehrName?: string | null) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries, fields, ehrName);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[], fields: IKeyOperation | undefined, ehrName?: string | null): IMedicationStatement[] => {
  const tempFormattedList: IMedicationStatement[] = [];
  listData.map((item: any) => {
    const isAthena = ehrName == EHRName.ATHENA;
    const isMedicationStatement = item.resourceType === FHIR_RESOURCE.MEDICATION_STATEMENT;
    const discontinuedDate = getExtensionValue(item, EXTENSION_URLS.discontinuedDate);
    const medicationObject = getMedicationCodeObject(item);
    const statusReason = item.statusReason;
    const sig = item.extension?.find((extension: Extension) => fields?.sig?.extensionUrl && extension.url == fields?.sig?.extensionUrl)?.valueString;

    if (medicationObject) {
      const tempObj: IMedicationStatement = {
        id: item?.id,
        effectivePeriod: item?.effectivePeriod?.start || item?.dispenseRequest?.validityPeriod?.start,
        medicationStatement: medicationObject,
        name: getMedicationName(medicationObject),
        informationSource: getPractitioner(item),
        ...(item?.status && {
          status: {
            code: item?.status,
            display: item?.status,
          },
        }),
        uniqueId: item?.id,
        isFreeTextRecord: !medicationObject?.coding?.length,
        reason: (item?.status === 'stopped' || item?.status === 'completed' || isAthena) && statusReason?.length ? statusReason[statusReason.length - 1] : undefined,
        statusReason: statusReason,
        discontinuedDate,
        showNote: item.note?.[0]?.text?.length > 0,
        sig: sig,
        stopDate: item?.effectivePeriod?.end,
        extension: item?.extension || [],
        ...(item.note?.length > 0 && {
          note: item.note[0].text,
        }),
        isReadOnly: !isMedicationStatement,
        meta: item?.meta,
        dose: item?.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.value,
        dosageUnit: item?.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit,
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): IMedicationComponentValue => {
  return {
    medicationStatements: [
      {
        id: '1234',
        name: 'Sample Medication',
        effectivePeriod: '2020-12-14',
        medicationStatement: {
          text: 'Sample Medication',
        },
        status: {code: 'active', display: 'Active'},
        informationSource: {
          resourceType: "Practitioner",
          id: 121,
          name:
          [
              {
                  "given":
                  [
                      "Practitioner Test"
                  ],
                  "family": "Doctor"
              }
          ],
      },
        uniqueId: '1234',
        isFreeTextRecord: false
      }
    ]
  }
};

export const sortItems = (records: IMedicationStatement[]) => {
  return records.sort((a, b) => {
    const statusA = a.status?.code || '';
    const statusB = b.status?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(b.effectivePeriod || new Date()).getTime() -
      new Date(a.effectivePeriod || new Date()).getTime()
    );
  })
};

export const getPractitioner = (item: any) => {
  if (item?.informationSource?.reference) {
    const reference: string = item.informationSource.reference;
    const practitionerId = reference.replace('Practitioner/', '');
    if (reference) {
      return {
        id: practitionerId,
      };
    }
  }
};

export const getMedicationName = (item: any) => {
  let name = item?.text || item?.display;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: MedicationFields,
  data: IMedicationStatement,
  submitTriggered: boolean,
  fields?: IKeyOperation,
  isMedOrderEnabled?: boolean,
) => {
  switch (field) {
    case MedicationFields.effectivePeriod:
      if ((fields?.effectivePeriod?.isRequired || false) && submitTriggered) {
        return !data.effectivePeriod;
      }
      return false;

    case MedicationFields.status:
      if ((fields?.status?.isRequired || false) && submitTriggered) {
        return !data.status || !data.status.code;
      }
      return false;

    case MedicationFields.dose:
      if ((fields?.dosage?.isRequired || false) && submitTriggered) {
        return !data.dose;
      }
      return false;

    case MedicationFields.dosageUnit:
      if ((fields?.dosageUnit?.isRequired || false) && submitTriggered) {
        return !data.dosageUnit;
      }
      return false;

    case MedicationFields.informationSource:
      if ((fields?.informationSource?.isRequired || false) && submitTriggered) {
        return !data.informationSource?.id;
      }
      return false;

    case MedicationFields.statusReason:
      // When status is stopped and med order is enabled then only ask for status reason
      if (fields?.statusReason?.type === 'string' && data.status?.code === 'stopped' && isMedOrderEnabled && submitTriggered) {
        return !data.reason?.text;
      }
      if (fields?.statusReason?.type === 'codeable' && (fields?.status?.isRequired || false) && submitTriggered) {
        return !data.reason || !data.reason.coding?.[0]?.code;
      }
      return false;

    case MedicationFields.stopDate:
      if ((fields?.stopDate?.isRequired || false) && submitTriggered) {
        return !data.stopDate;
      }
      return false;

    case MedicationFields.note:
      if ((fields?.note?.isRequired || false) && submitTriggered) {
        return !data.note;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateMedicationParams) => {
  const statusReason: CodeableConcept[] = [];
  if (params.reason) {
    statusReason.push(params.reason);
  }
  return {
    resourceType: 'MedicationStatement',
    ...(params.id && {id: params.id}),
    ...(params.status?.code && {status: params.status.code}),
    medicationCodeableConcept: params.medicationStatement,
    dosage: [
      {
        doseAndRate: [
          {
            doseQuantity: {
              value: Number(params.dose),
              unit: params.dosageUnit,
            },
          },
        ],
      },
    ],
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.effectiveDateTime && {
      effectiveDateTime: params.effectiveDateTime,
    }),
    ...(params.effectivePeriod && {
      effectivePeriod: {
        start: params.effectivePeriod,
        ...(params.stopDate && { end: params.stopDate })
      },
    }),
    ...(params.informationSource?.id && {
      informationSource: {
        reference: `Practitioner/${params.informationSource.id}`,
      },
    }),
    ...(params.dateAsserted && {dateAsserted: params.dateAsserted}),
    ...(params.dosage && {
      dosage: [
        {
          text: params.dosage,
        },
      ],
    }),
    ...((params.extension || []).length > 0 && {extension: params.extension}),
    ...(statusReason.length > 0 && { statusReason: statusReason }),
    ...(params.note && {
      note: [
        {
          text: params.note,
        },
      ],
    }),
    ...(params?.meta && { meta: params?.meta })
  };
};



const getHieMedicationCodes = (
  resource: MedicationStatement,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.medicationCodeableConcept;
  const text = getTextFromCoding(codeObj?.coding) || codeObj?.text;
  return { coding: getValidCoding(codeObj, allowedCodeSystems), text: text };
};

export const formatReconciliationMedicationStatementData = (
  resource: MedicationStatement,
  metaData: IFormatReconciliationMetaData
) => {
  const medicationStatementResource: MedicationStatement = resource;

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.medicationStatement,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);

  const validatedData = validateMedicationStatementHieResource(
    (metaData?.targetResourceData || resource) as MedicationStatement,
    fieldCapabilities,
    metaData
  );

  const display = {
    title: getMedicationName(medicationStatementResource.medicationCodeableConcept),
    date: getHieMedicationPerformedDate(medicationStatementResource),
    status: medicationStatementResource.status,
  };

  return {
    display,
    resourceData: validatedData.resource,
    conflictFields: validatedData.conflictFields,
  };
}

const getHieMedicationPerformedDate = (
  medicationStatementResource: MedicationStatement
) => {
  return (
    medicationStatementResource.effectiveDateTime ||
    medicationStatementResource.effectivePeriod?.start
  );
};

export const getMedicationInvalidCodeField = (isConflict?: boolean, value?: any) => {
  return {
    field: MedicationFields.medicationCodeableConcept,
    inputType: FHIRConflictFields.code,
    invalid: !value,
    label: 'Medications',
    placeholder: 'Search and add new medication',
    isConflict: isConflict,
    value: value,
    isRequired: true,
    extraData: {
      searchType: PAMISearchType.medication,
    },
  }
}

export const validateMedicationStatementHieResource = (
  resource: MedicationStatement,
  fields: IKeyOperation,
  metaData: IFormatReconciliationMetaData
) => {
  const conflictFields: IReconciliationConflictField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = MedicationFields[key as keyof typeof MedicationFields];

    if (!fields[key as keyof typeof fields]?.isHidden) {
      switch (field) {
        case MedicationFields.medicationCodeableConcept:
          {
            const medicationCodes = metaData.source === ReconciliationSource.MSO
              ? resource.medicationCodeableConcept
              : getHieMedicationCodes(
                  resource,
                  fields?.medicationCodeableConcept?.allowedCodeSystems
                );

            resource.medicationCodeableConcept = medicationCodes;
            conflictFields.push(
              getMedicationInvalidCodeField(
                false,
                resource.medicationCodeableConcept
              )
            );
          }
          break;
        case MedicationFields.status:
          {
            const status = fields?.status?.possibleValues?.find(
              (possibleValue) =>
                possibleValue.code === resource.status?.toLowerCase()
            );
            const isMissing = !status?.code && fields?.status?.isRequired;

            if (!isMissing && status) {
              resource.status = status?.code as MedicationStatement['status'];
            }
            conflictFields.push({
              field: MedicationFields.status,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: status?.code,
              label: 'Status',
              isRequired: fields?.status?.isRequired || false,
              extraData: {
                statusList: fields?.status?.possibleValues || [],
              },
            });
          }
          break;
        case MedicationFields.effectivePeriod:
          {
            const isMissing =
              !resource.effectivePeriod?.start &&
              fields?.effectivePeriod?.isRequired;

            if (!isMissing) {
              resource.effectivePeriod = {
                ...(resource.effectivePeriod || {}),
                start: resource.effectivePeriod?.start,
              };
            }
            conflictFields.push({
              field: MedicationFields.effectivePeriod,
              inputType: FHIRConflictFields.date,
              invalid: isMissing || false,
              value: resource.effectivePeriod?.start,
              label: 'Start Date',
              isRequired: fields?.effectivePeriod?.isRequired || false,
              extraData: {
                // dateFormat: fields?.effectivePeriod?.dateFormat || '',
              },
            });
          }
          break;
        case MedicationFields.stopDate:
          {
            const isMissing =
              !resource.effectivePeriod?.end && fields?.stopDate?.isRequired;

            if (!isMissing) {
              resource.effectivePeriod = {
                ...(resource.effectivePeriod || {}),
                end: resource.effectivePeriod?.end,
              };
            }
            conflictFields.push({
              field: MedicationFields.stopDate,
              inputType: FHIRConflictFields.date,
              invalid: isMissing || false,
              value: resource.effectivePeriod?.end,
              label: 'Stop Date',
              isRequired: fields?.stopDate?.isRequired || false,
              extraData: {
                // dateFormat: fields?.stopDate?.dateFormat || '',
              },
            });
          }
          break;
        case MedicationFields.note:
          {
            const hieNote = resource?.note?.[0]?.text;
            const isMissing = !hieNote && fields?.note?.isRequired;
            if (!isMissing && hieNote) {
              resource.note = [{text: hieNote}];
            }
            conflictFields.push({
              field: MedicationFields.note,
              inputType: FHIRConflictFields.textArea,
              invalid: isMissing || false,
              value: hieNote,
              isRequired: fields?.note?.isRequired || false,
              label: 'Note',
            });
          }
          break;
        case MedicationFields.sig:
          {
            const sig = resource.extension?.find(
              (ext: any) => ext.url == fields?.sig?.extensionUrl
            )?.valueString;
            const isMissing = !sig && fields?.sig?.isRequired;

            conflictFields.push({
              field: MedicationFields.sig,
              inputType: FHIRConflictFields.input,
              invalid: isMissing || false,
              value: sig,
              isRequired: fields?.sig?.isRequired || false,
              label: 'Sig',
              extraData: {
                sigList: fields?.sig?.possibleValues,
              },
            });
          }
          break;

        case MedicationFields.dose:
          {
            const isMissing =
              !resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.value &&
              fields?.dose?.isRequired;

            conflictFields.push({
              field: MedicationFields.dose,
              inputType: FHIRConflictFields.input,
              invalid: isMissing || false,
              value: resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.value,
              label: 'Dose',
              isRequired: fields?.dose?.isRequired || false,
              extraData: {
                doseQuantityUnit: resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit,
              },
            });
          }
          break;
        case MedicationFields.dosageUnit:
          {
            const isMissing =
              !resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit &&
              fields?.dosageUnit?.isRequired;

            conflictFields.push({
              field: MedicationFields.dosageUnit,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit,
              label: 'Dosage Unit',
              isRequired: fields?.dosageUnit?.isRequired || false,
              extraData: {
                doseQuantityUnit: resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit,
                statusList: fields?.dosageUnit?.possibleValues,
              },
            });
          }
          break;
      }
    }
  });

  return {
    conflictFields: conflictFields,
    resource: resource,
  };
};

export const updateHieMedicationStatementMatchedData = (
  resource: MedicationStatement,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  const updatedResource = updateHieMedicationStatementData(resource, conflictFields, metaData);
  return formatReconciliationMedicationStatementData(updatedResource, metaData);
};

export const updateHieMedicationStatementData = (
  resource: MedicationStatement,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  conflictFields.map((conflictField) => {
    if (conflictField.value) {
      switch (conflictField.field) {
        case MedicationFields.medicationCodeableConcept:
          {
            const codeObj = conflictField.value?.coding?.[0];
            if (metaData.ehrConfig?.isAthena) {
              codeObj.system = FHIR_CODE_SYSTEMS.ATHENA;
            }
            resource.medicationCodeableConcept = {
              ...conflictField.value,
              coding: [codeObj],
            };
          }
          break;

        case MedicationFields.status:
          {
            resource.status = conflictField.value;
          }
          break;

        case MedicationFields.effectivePeriod:
          {
            resource.effectivePeriod = {
              ...(resource.effectivePeriod || {}),
              start: conflictField.value,
            }
          }
          break;

        case MedicationFields.stopDate:
          {
            resource.effectivePeriod = {
              ...(resource.effectivePeriod || {}),
              end: conflictField.value,
            }
          }
          break;

        case MedicationFields.note:
          {
            resource.note = [{text: conflictField.value}];
          }
          break;

        case MedicationFields.sig:
          {
            const capabilities = getEHRCapability(metaData.ehrCapabilities, [
              CapabilityResource.medicationStatement,
            ]);
            const fieldCapabilities = getFieldCapabilities(capabilities);
            const extension =
              resource.extension?.filter(
                (ext: any) => ext.url !== fieldCapabilities?.sig?.extensionUrl
              ) || [];
            if (extension) {
              extension.push({
                url: fieldCapabilities?.sig?.extensionUrl || '',
                valueString: conflictField.value,
              });
            }
            resource.extension = extension;
          }
          break;

        case MedicationFields.dose:
          {
            const doseUnit = resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.unit;
            resource.dosage = [
              {
                doseAndRate: [
                  {
                    doseQuantity: {
                      value: conflictField.value,
                      unit: doseUnit,
                    },
                  },
                ],
              },
            ];
          }
          break;

        case MedicationFields.dosageUnit:
          {
            const doseValue = resource.dosage?.[0]?.doseAndRate?.[0]?.doseQuantity?.value;
            resource.dosage = [
              {
                doseAndRate: [
                  {
                    doseQuantity: {
                      unit: conflictField.value,
                      value: doseValue,
                    },
                  },
                ],
              },
            ];
          }
          break;
      }
    }
    return conflictField;
  });
  return resource;
}


export const formatHieMedicationResourceObj = (
  resource: MedicationStatement,
  metaData: IFormatReconciliationMetaData
) => {

  let effectiveDatePeriod = resource.effectivePeriod;

  if (!effectiveDatePeriod) {
    effectiveDatePeriod = getEffectivePeriodFromTime(resource.effectiveDateTime);
  }

  return {
    resourceType: 'MedicationStatement',
    ...(resource.status && {status: resource.status}),
    medicationCodeableConcept: resource.medicationCodeableConcept,
    subject: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.effectiveDateTime && {
      effectiveDateTime: resource.effectiveDateTime,
    }),
    ...(effectiveDatePeriod && {
      effectivePeriod: effectiveDatePeriod
      }
    ),
    ...(metaData?.practitioner && {
      informationSource: {
        reference: `Practitioner/${metaData?.practitioner.id}`,
      },
    }),
    ...(resource.dateAsserted && {dateAsserted: resource.dateAsserted}),
    ...(resource.dosage && {
      dosage: resource.dosage,
    }),
    ...((resource.extension || []).length > 0 && {extension: resource.extension}),
    ...(resource.note && {
      note: resource.note,
    }),
    ...(resource?.meta && { meta: resource?.meta })
  };
};
