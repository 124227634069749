import {Divider, Spinner, View, VStack, Text} from 'native-base';
import {Drawer} from 'antd';
import CustomComponentFlow from './CustomComponentFlow';
import {useContext, useEffect, useState} from 'react';
import {
  ICareTeam,
  ICareTeamEdge,
  ICareTeamFlow,
  ICareTeamNode,
  ICustomFlow,
  IPatientCareJourneyResponse,
} from './interfaces';
import {useLazyQuery} from '@apollo/client';
import {CareJourneyQueries} from '../../../../services';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {
  formatCareTeamResponse,
  getFormatedPrimaryCareTeamData,
} from './CareTeamHelper';
import {getMlovListFromCategory} from '../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../context/CommonDataContext';
import {MLOV_CATEGORY} from '../../../../constants';
import {ModalActionTitle} from '../../../common/ModalActionTitle/ModalActionTitle';
import {Colors} from '../../../../styles';
import {BUTTON_TYPE} from '../../../../constants';
import { capitalizeText } from '../../../common/ContactRelationView/ContactRelationUtils';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import {useIntl} from 'react-intl';
import { getPatientCareJourneyFetchCondition } from '../../../RightSideContainer/Journeys/JourneysOfCare/PatientCareJourney/PatientCareJourneyHelper';
import { getAllowedUserAccountLocationUuids, getBooleanFeatureFlag } from '../../../../utils/commonUtils';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import React from 'react';
import { USER_ACCESS_PERMISSION } from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';

const CareTeamFlow = (props: ICareTeamFlow) => {
  const commonData = React.useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CARE_JOURNEY
  );

  const intl = useIntl();
  const {personData, userList, careTeam} = props;
  const [isLoadCareTeam, updateCareTeamState] = useState<boolean>(true);
  const [flowState, setFlowState] = useState<ICustomFlow>({
    nodes: [],
    edges: [],
    fitView: false,
  });
  const commonContextData = useContext(CommonDataContext);
  const roleList = getMlovListFromCategory(
    commonContextData.MLOV,
    MLOV_CATEGORY.USER_ROLES
  );

  const CARE_TEAM_NODE_TYPE = 'CareTeam';
  const JOURNEY_NODE_TYPE = 'Journey';
  const PATIENT_NODE_TYPE = 'Patient';
  const rootNodeXPosition = 500;
  const rootNodeYPosition = 0;

  const configureNodeAndEdge = (formattedCareTeamList: ICareTeam[]) => {
    const nodes: ICareTeamNode[] = [];
    const edges: ICareTeamEdge[] = [];
    const rootNode = createRootNode();
    (formattedCareTeamList || []).forEach(
      (careTeam: ICareTeam, index: number) => {
        const journeyNode = createJourneyNodeAndEdge(
          rootNode,
          careTeam,
          index,
          edges,
          nodes
        );
        const memberCount = careTeam?.patientCareJourneyTeam?.length;
        if (memberCount) {
          (careTeam.patientCareJourneyTeam || []).forEach((teamMember) => {
            const memberNode = createTeamMemberNodeAndEdge(
              journeyNode,
              teamMember,
              edges,
              nodes
            );
            nodes.push(memberNode);
          });
        }
        if (memberCount > 1) {
          const lastJourneyNode = getLastCareMember(nodes);
          const journeyXPosition =
            (journeyNode.position.x + lastJourneyNode.position.x) / 2;
          journeyNode.position.x = journeyXPosition;
        }
        nodes.push(journeyNode);
      }
    );
    const lastNode = getLastCareMember(nodes);
    let lastXPosition = 0;
    if (lastNode?.id) {
      lastXPosition = getNodeXPosition(lastNode);
    }
    rootNode.position.x = lastXPosition / 2;
    nodes.push(rootNode);
    setFlowState((prev) => {
      return {...prev, nodes: nodes, edges: edges};
    });
  };

  const createJourneyNodeAndEdge = (
    rootNode: ICareTeamNode,
    careTeam: ICareTeam,
    indexNo: number,
    edges: ICareTeamEdge[],
    nodes: ICareTeamNode[]
  ) => {
    const parentX = getNodeXPosition(rootNode);
    const lastNode = getLastCareMember(nodes);
    let lastXPosition = 0;
    if (lastNode?.id) {
      lastXPosition = getNodeXPosition(lastNode);
    }
    edges.push(createEdge(rootNode.id, careTeam.journeyId));
    return {
      id: careTeam.journeyId,
      type: CARE_TEAM_NODE_TYPE,
      position: {
        x: indexNo ? lastXPosition + 300 : parentX / 2,
        y: 200,
      },
      data: {
        name: careTeam.journeyName,
        nodeType: JOURNEY_NODE_TYPE,
        rootNode: false,
        footNode: careTeam.patientCareJourneyTeam?.length ? false : true,
        isWorkFlowEditable: false,
      },
    } as ICareTeamNode;
  };

  const getLastCareMember = (nodes: ICareTeamNode[]) => {
    let lastCareTeamNode: ICareTeamNode = {} as ICareTeamNode;
    nodes.forEach((node) => {
      if (node.data.nodeType === CARE_TEAM_NODE_TYPE) {
        lastCareTeamNode = node;
      }
    });
    return lastCareTeamNode;
  };

  const createTeamMemberNodeAndEdge = (
    rootNode: ICareTeamNode,
    teamMember: any,
    edges: ICareTeamEdge[],
    nodes: ICareTeamNode[]
  ) => {
    const parentX = getNodeXPosition(rootNode);
    edges.push(createEdge(rootNode.id, rootNode.id.concat(teamMember.userId)));
    const lastNode = getLastCareMember(nodes);
    let lastXPosition = 0;
    if (lastNode?.id) {
      lastXPosition = getNodeXPosition(lastNode);
    }
    return {
      id: rootNode.id.concat(teamMember.userId),
      type: CARE_TEAM_NODE_TYPE,
      position: {
        x: lastXPosition ? lastXPosition + 200 : parentX / 2,
        y: 400,
      },
      data: {
        name: teamMember.name,
        nodeType: CARE_TEAM_NODE_TYPE,
        rootNode: false,
        footNode: true,
        isWorkFlowEditable: false,
        id: teamMember.userId,
        role: teamMember?.role,
      },
    } as ICareTeamNode;
  };

  const getNodeXPosition = (node: ICareTeamNode) => {
    return node.position.x;
  };

  const createRootNode = () => {
    return {
      id: personData.patientUuid,
      type: CARE_TEAM_NODE_TYPE,
      position: {
        x: rootNodeXPosition,
        y: rootNodeYPosition,
      },
      data: {
        name: personData.name,
        nodeType: PATIENT_NODE_TYPE,
        rootNode: true,
        footNode: false,
        isWorkFlowEditable: false,
        id: personData.patientUuid,
      },
    } as ICareTeamNode;
  };

  const createEdge = (sourceId: string, targetId: string) => {
    return {
      id: 'edge-'.concat(targetId).concat(sourceId),
      source: sourceId,
      target: targetId,
      type: 'bezier',
    } as ICareTeamEdge;
  };

  const [getPatientCareTeamOfAllJourney] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_ALL_CARE_JOURNEY,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (response) => {
        if (
          (response &&
            response.patientCareJourneys &&
            response.patientCareJourneys?.length) ||
          props?.careTeam?.members?.length
        ) {
          const formattedCareTeamList = formatCareTeamResponse(
            response.patientCareJourneys || [],
            userList,
            roleList
          );
          if (props?.careTeam?.members?.length) {
            formattedCareTeamList.unshift(
              getFormatedPrimaryCareTeamData(
                props?.careTeam,
                careTeam?.primaryCarePhysician || ''
              )
            );
          }
          configureNodeAndEdge(formattedCareTeamList);
        }

        updateCareTeamState(false);
      },
      onError: (error: any) => {

        updateCareTeamState(false);
      },
    }
  );

  const [getCareJourneyDetailById] = useLazyQuery(
    CareJourneyQueries.GET_PATIENT_CARE_JOURNEY_BY_CARE_JOURNEY_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: response => {
        if (response.patientCareJourneys?.length) {
          const formattedCareTeamList = formatCareTeamResponse(
            response.patientCareJourneys,
            userList,
            roleList,
          );
          configureNodeAndEdge(formattedCareTeamList);
        }
        updateCareTeamState(false);
      },
      onError: (error: any) => {

        updateCareTeamState(false);
      },
    },
  );

  useEffect(() => {
    const condition = getPatientCareJourneyFetchCondition({
      patientIds: [props.personData.patientUuid],
      ...(props.personData.uuid ? {contactIds: [props.personData.uuid]} : {}),
      isMultiTenancyEnabled: isMultiTenancyEnabled,
      accountLocationIds: allowedUserAccountP360LocationUuids
    })
    if (props.careJourneyId) {
      getCareJourneyDetailById(
        {
          variables: {
            condition: condition,
            patientId: props.personData.patientUuid,
            careJourneyId: props.careJourneyId
          }
        }
      );
    } else {
      getPatientCareTeamOfAllJourney({
        variables: {
          condition: condition
        },
      });
    }
  }, [personData]);

  return (
    <Drawer
      title={
        <ModalActionTitle
          title={intl.formatMessage({id: 'careTeam'})}
          titleColor={''}
          buttonList={[
            {
              show: true,
              id: 1,
              btnText: 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: () => {
                props.onClose();
              },
            },
          ]}
        />
      }
      destroyOnClose
      placement="right"
      onClose={() => {
        props.onClose();
      }}
      visible={true}
      closable={false}
      width={props.drawerWidth || '85%'}>
      {isLoadCareTeam ? (
        <View
          zIndex={10}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          top="50%"
          left="50%"
        >
          <Spinner size="lg" />
        </View>
      ) : (
        <View height={props?.journeyTitle ? '86%' : '92%'}>
          {props?.journeyTitle && (
            <View>
              <VStack margin={2}>
                <Text
                  size={'mdMediumNew'}
                  color={Colors.FoldPixel.GRAY800}
                  >
                  {props?.journeyTitle}
                </Text>
              </VStack>
              <Divider margin={2} />
            </View>
          )}
          <CustomComponentFlow
            fitView={flowState.fitView}
            nodes={flowState.nodes}
            edges={flowState.edges}
          />
        </View>
      )}
    </Drawer>
  );
};

export default CareTeamFlow;
