import { Resource } from 'fhir/r4';
import {IEhrCapability, IVitalData} from '../../../../../Interfaces';
import { IAvailableEhr } from '../../../MiddleContainer/interfaces';
import { FHIRConflictFields } from './HieRequestsUtils';

export interface IReconciliationRequestResponse {
  data: string;
  id: string;
  display_type: string;
  time_stamp: string;
  sync_status?: ReconciliationStatus;
}

export enum ReconciliationActionCode {
  add = 'add',
  update = 'update',
  delete = 'delete',
  noChange = 'no_change',
}

export enum ReconciliationSource {
  HIE = 'HIE',
  MSO = 'MSO'
}

export interface IReconciliationConflictField {
  inputType: FHIRConflictFields;
  invalid: boolean;
  label?: string;
  value?: any;
  extraData?: any;
  subType?: string;
  field: any;
  placeholder?: string;
  isConflict?: boolean;
  isRequired?: boolean;
  sequence?: number;
}

export interface IBaseReconciliationRequest {
  id: string;
  resourceType: string;
  actionCode: ReconciliationActionCode;
  showDeleteAction: boolean;
  showUpdateAction: boolean;
  requesterUserId?: string;
  statusCode?: ReconcileStatusCodes;
  display?: IReconciliationDisplay;
  error?: string;
  conflictFields?: IReconciliationConflictField[];
  isMatchDataViewVisible?: boolean;
  syncStatus?: ReconciliationStatus;
  sourceInfo?: string;
}

export interface IHIEReconciliationRequest extends IBaseReconciliationRequest {
  resourceData: Resource;
  syncDate: string;
}

export interface IMSOReconciliationRequest extends IBaseReconciliationRequest {
  contactId: string;
  sourceResourceId: string;
  targetResourceId?: string;
  sourcePatientId: string;
  targetPatientId: string;
  reconcileResourceId?: string;
  sourceResourceData: Resource;
  targetResourceData?: Resource;
  sourceActionCode?: string;
  targetActionCode?: string;
  eventSource?: string;
  modifiedResource?: Resource;
}

export interface IMSOReconciliationRequestResponse extends Omit<IMSOReconciliationRequest, 'source' | 'actionCode'> {}

// Use this type when you need to handle both types of requests
export type IReconciliationRequest = IHIEReconciliationRequest | IMSOReconciliationRequest;

export interface IReconciliationRequestGroup {
  orders: IReconciliationRequest[];
  resourceType: string;
  acceptAllLoading?: boolean;
  offset: number;
  pageSize: number;
  total?: number;
  pageLoading?: boolean;
}

export enum ReconciliationStatus {
  accepted = 'accepted',
  declined = 'declined',
  deleted = 'deleted',
}

export enum ReconcileStatusCodes {
  pending = 'pending',
  resolved = 'resolved',
  dismissed = 'dismissed',
  autoResolved = 'auto-resolved',
  autoResolvedOlderVersion = 'auto-resolved-older-version',
}

export interface IReconciliationDisplay {
  title: string;
  subTitle?: string;
  date?: string;
  status?: string;
}

export interface IFormatReconciliationMetaData {
  enabledVitalList: IVitalData[];
  ehrCapabilities: IEhrCapability[];
  accountUuid: string;
  patientId: string;
  practitioner?: any;
  practitionerData?: any[];
  ehrConfig?: IAvailableEhr;
  accountLocationUuid?: string
  source: ReconciliationSource;
  targetResourceData?: Resource;
  localEhrCapabilities?: IEhrCapability[];
  locationGroupPatientIdMap?: Record<string, string>;
}

export interface IReconciliationFormattedData {
  display: IReconciliationDisplay,
  resourceData: any;
  conflictFields?: IReconciliationConflictField[]
};
