import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Typography, Drawer, Modal, Divider, Select } from 'antd';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { Box, HStack, VStack, Skeleton, Tooltip, useToast, Spinner, Text as NativeText } from 'native-base';
import { Colors } from '../../../styles';
import { Pressable } from 'react-native';
import SummarySvg from '../../../assets/Icons/SummarySvg';
import HardRefreshIconSvg from '../../common/Svg/HardRefreshSvg';
import { getAgeValue, getDateStrFromFormat } from '../../../utils/DateUtils';
import { DATE_FORMATS } from '../../../constants';
import AlphabetAvatar from '../../common/Avatar/AlphabetAvatar';
import TrashBinIcon2024 from '../../../assets/Icons/TrashBinIcon2024';
import CommonService from '../../../services/CommonService/CommonService';
import { useLazyQuery } from '@apollo/client';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import { getAccountId, getAccountUUID, getUserId, isUnitySetupEnabled } from '../../../utils/commonUtils';
import AttachmentSvg from '../../../assets/Icons/AttachmentSvg';
import CrossIconSvg from '../../common/Svg/CrossIconSvg';
import EFaxActionSvg from '../../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import { useIntl } from 'react-intl';
import PlusIcon from '../../common/Svg/PlusSvg'
import { testID, TestIdentifiers } from '../../../testUtils';
import { options } from 'jodit/esm/core/helpers';

const { Text } = Typography;

interface InsightsCreateReferralFormProps {
  contactData: any;
  formattedContactData: any;
  selectedProvider: any;
  onChangeSelectedProvider: any;
  selectedDocuments: any;
  additionalNote: any;
  onChangeAdditionalNote: any;
  onChangeisSelectingProvider: any;
  onChangeDeleteDoc: any;
  onChangeInboxId: any;
  selectedInbox: any;
  onAddAttachments: any;
  onChangeReasonForReferral?: (val:string) => void;
  reasonForReferral?: string | '';
}

  const InsightsCreateReferralForm = ({
    contactData,
    formattedContactData,
    selectedProvider,
    onChangeSelectedProvider,
    selectedDocuments,
    additionalNote,
    onChangeAdditionalNote,
    onChangeisSelectingProvider,
    onChangeDeleteDoc,
    onChangeInboxId,
    selectedInbox,
    onAddAttachments,
    onChangeReasonForReferral,
    reasonForReferral
  }: InsightsCreateReferralFormProps) => {
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData?.sidecarContext?.isSidecar;
  const [showDocs, setShowDocs] = useState(true);
  const [generatedSummary, setGeneratedSummary] = useState<string | null>(null);
  const [isSumary, setIsSumary] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const commonService = CommonService.getCommonServiceInstance();
  const llmflowService = commonService.llmService;
  const toast = useToast();
  const userId = getUserId();
  const accountId = getAccountId();
  const [inboxList, setInboxList] = useState([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const fetchInboxList = async () => {
    setLoading(true);
    try {
      const response = await getInboxes({
        variables: {
          userId: userId,
          accountId: accountId,
        },
      });
      const fetchedInboxList = response?.data?.inboxes || [];
      setInboxList(fetchedInboxList);
      if (fetchedInboxList?.length > 0 && !selectedInbox) {
        onChangeInboxId(fetchedInboxList?.[0]?.id);
      }
    } catch (error) {
      console.error('Error fetching inbox list:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInboxList()
  }, []);

  const renderSelectedDocumentItem = (document: any) => {
    const documentName =
      document?.notes ||
      document?.documentAttachments
        ?.map((documentAttachment: any) => documentAttachment?.attachment?.name)
        .filter((documentName: any) => !!documentName)
        .join(', ');

    return (
      <Box
        padding={1}
        borderWidth={1}
        borderRadius='md'
        borderColor={Colors.Custom.Primary200}
        backgroundColor={Colors.Custom.PrimaryColor100}
        key={document.id}
        maxWidth='250px'
      >
        <HStack alignItems='center' space={2} justifyContent='space-between'>
          <AttachmentSvg />
        <Text style={{ fontWeight: 500, color: Colors.Custom.Primary300 }}>
            {document?.caption?.length > 10
              ? `${document?.caption?.substring(0, 10)}...` 
              : document?.caption || (documentName?.length > 10 
                ? `${documentName?.substring(0, 10)}...`
                : documentName)}
          </Text>
          <Pressable onPress={() => onChangeDeleteDoc(document)}>
            <CrossIconSvg size={12} customStrokeColor={Colors.FoldPixel.PRIMARY300} />
          </Pressable>
        </HStack>
      </Box>
    );
  };

  const [getInboxes] = useLazyQuery(InboxQueries.GetEFaxInboxList, {
    fetchPolicy: 'no-cache',
  });

  const handleAddToNote = () => {
    if (generatedSummary && isSumary) {
      onChangeAdditionalNote(additionalNote ? additionalNote + '\n' + generatedSummary : generatedSummary)
      setGeneratedSummary(null);
      setIsSumary(false)
    }
  };

  const handleGeneratePatientSummary = async (catched = true) => {
    try {
      setIsLoading(true);
        const response = await llmflowService
        .post(`/v1/referralnote`, {
          contactUuid: formattedContactData?.contactUUID,
          speciality: selectedProvider?.speciality || '',
          isCatched: catched,
        })
        if(response?.data?.response?.[0]?.description) {
          setGeneratedSummary(response?.data?.response?.[0]?.description);
          setIsSumary(true)
        }
        else{
          setGeneratedSummary(intl.formatMessage({id: 'NoDataForReferralSummary'}))
        }
      setIsLoading(false);
    } catch (error) {
      console.error('Error generating patient summary:', error);
      setIsLoading(false);
    }
  };

  return (
    <Form layout='vertical' >
      <Form.Item label={
        <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>Patient</NativeText>
      } style={{ marginBottom: 8 }}>
        <Input {...testID(TestIdentifiers.patientName)} value={`${contactData?.name}, ${contactData?.person?.sexAtBirth?.value} • ${getAgeValue(contactData?.person?.birthDate, '')} (${getDateStrFromFormat(
          contactData?.person?.birthDate,
          DATE_FORMATS?.DISPLAY_BIRTH_DATE_FORMAT)})`} disabled />
      </Form.Item>
      <Form.Item label={<NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{intl.formatMessage({id: 'selectYourEfaxNumber'})}</NativeText>} required style={{ marginBottom: 8 }}>
        <Select
          placeholder='Search and select an eFax number'
          {...testID(TestIdentifiers.efaxNumber)}
          showSearch
          value={selectedInbox ? +selectedInbox : null}
          loading={loading}
          filterOption={(input, option: any) =>
            option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(value) => {
            const selected: any = inboxList?.find((inbox: any) => inbox?.id === value);
            onChangeInboxId(selected?.id);
          }}
          options={inboxList?.map((inbox: any) => ({
            value: inbox?.id,
            label: `(${inbox?.channelEfax?.efaxNumber}) - ${inbox?.name}`,
          }))
          } />
      </Form.Item>

      {selectedProvider && (
        <Form.Item
          label={<NativeText fontSize={'14px'} fontWeight={400} color={Colors.FoldPixel.GRAY250}> {intl.formatMessage({id: 'selectedProvider'})} </NativeText>}
          style={{ marginBottom: 8 }} 
        >
          <HStack
            style={{
              alignItems: 'center',
              borderRadius: 6,
              borderWidth: 1,
              borderColor: Colors.Custom.Gray300,
              padding: 10,
            }}
            justifyContent='space-between'
          >
            <HStack alignItems='center'>
              <AlphabetAvatar
                size={10}
                style={{
                  alignItems: 'center',
                  borderRadius: 6,
                  borderWidth: 1,
                  marginRight: 8,
                }}
                disableTop
                name={selectedProvider?.name}
                textStyle={{
                  fontSize: 14,
                  textAlign: 'center',
                  padding: 1,
                }}
                userType={'USER'}
              />
              <VStack>
                <NativeText fontSize={'14px'} fontWeight={500} color={Colors.FoldPixel.GRAY400}>{selectedProvider?.name}</NativeText>
                <NativeText fontSize={'12px'} fontWeight={400} color={Colors.FoldPixel.GRAY250}>
                  {selectedProvider?.cityName && selectedProvider?.zipCode
                    ? `${selectedProvider.cityName}, ${selectedProvider.zipCode}`
                    : selectedProvider?.cityName
                      ? `${selectedProvider.cityName}`
                      : selectedProvider?.zipCode
                        ? `${selectedProvider.zipCode}`
                        : ''}
                  {selectedProvider?.phoneNumber && (
                    <>
                      {' • '}
                      <EFaxActionSvg width={12} height={12} />
                      {' '}
                      {selectedProvider.phoneNumber}
                    </>
                  )}
                </NativeText>
              </VStack>
            </HStack>

            <HStack alignItems='center' space={1.5}>
              <Tooltip label='Replace' placement='top'>
                <Pressable onPress={() => {
                  onChangeisSelectingProvider(true);
                }
                }>
                  <HardRefreshIconSvg iconKey='diagnosis' referral={true} />
                </Pressable>
              </Tooltip>
              <Divider type='vertical' />
              <Tooltip label='Remove' placement='top'>
                <Pressable onPress={() => onChangeSelectedProvider(null)}>
                  <TrashBinIcon2024 />
                </Pressable>
              </Tooltip>
            </HStack>
          </HStack>
        </Form.Item>)}

      {
        !selectedProvider &&
        <Form.Item
          label={
            <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{intl.formatMessage({id: 'referTo'})}</NativeText>
          }
          required
          rules={[{ required: true, message: 'Please select a provider' }]}
          validateStatus={!selectedProvider ? 'error' : ''}
          style={{ marginBottom: 8 }} 
        >
          <Pressable onPress={() => {
            onChangeisSelectingProvider(true);
            onChangeSelectedProvider(null)
          }}>
            <HStack alignItems="center" justifyContent="left" space={1}>
              <PlusIcon width={16} height={16} />
              <NativeText size={'smRegular'} color={Colors.FoldPixel.PRIMARY300} {...testID(TestIdentifiers.addProviderBtn)}>{intl.formatMessage({id: 'addProvider'})}</NativeText>
            </HStack>
          </Pressable>
        </Form.Item>
      }
      <Form.Item label={
        <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{intl.formatMessage({ id: 'attachments' })}</NativeText>
      } required style={{ marginBottom: 8 }}>
        <HStack width={'90%'} flexWrap='wrap' alignItems='center' space={2} marginTop={2}>
          {(selectedDocuments?.length > 0 && showDocs) &&
            selectedDocuments?.map((document: any) => (
              renderSelectedDocumentItem(document)
            ))
          }
          <Pressable
            onPress={() => {
              onAddAttachments()
            }}
          >
            <HStack alignItems="center" justifyContent="left" space={1}>
              <PlusIcon width={16} height={16} />
              <NativeText size={'smRegular'} color={Colors.FoldPixel.PRIMARY300} {...testID(TestIdentifiers.addAttachmentsBtn)}>{intl.formatMessage({ id: 'addAttachments' })}</NativeText>
            </HStack>
          </Pressable>
        </HStack>
      </Form.Item>
      <Form.Item label={
        <NativeText size={'smRegular'} color={Colors.FoldPixel.GRAY250}>{intl.formatMessage({id: 'referralReason'})}</NativeText> 
      } style={{ marginBottom: 8 }}>
        <Input.TextArea
          placeholder='Add the Reason for Referral'
          value={reasonForReferral}
          {...testID(TestIdentifiers.reasonForReferral)}
          onChange={(e) => onChangeReasonForReferral && onChangeReasonForReferral(e.target.value)}
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
      </Form.Item>
      {
        selectedProvider && isUnitySetupEnabled(getAccountUUID()) &&
        <Form.Item style={{ marginBottom: 8 }}>
          <HStack marginBottom={2} width={'100%'} justifyContent='space-between' alignItems='center'>
            <Text style={{ color: Colors.FoldPixel.GRAY250 }}>{intl.formatMessage({id: 'additionalNote'})}</Text>
            {generatedSummary ? (
              <Text style={{ color: Colors.Custom.Primary300, fontWeight: '600' }}>
                <SummarySvg /> {intl.formatMessage({id: 'generatedSummary'})}
              </Text>
            ) : isLoading ? (<Text style={{ color: Colors.Custom.Primary300, fontWeight: '600' }}>
              <SummarySvg /> {intl.formatMessage({id: 'aiIsWriting'})}
            </Text>) : (
              !isLoading && (
                <Pressable onPress={() => handleGeneratePatientSummary(true)}>
                  <Text style={{ color: Colors.Custom.Primary300, fontWeight: '600' }}>
                    <SummarySvg /> {intl.formatMessage({id: 'generateSummary'})}
                  </Text>
                </Pressable>
              )
            )}
          </HStack>
          {isLoading && (
            <Box
              borderColor='coolGray.200'
              borderWidth={1}
              borderRadius={4}
              padding={3}
              marginTop={2}
              marginBottom={5}
              width={'100%'}
              position='relative'
            >
              <VStack space={3} alignItems='center' marginBottom={4}>
                <Skeleton.Text lines={3} width='100%' />
              </VStack>
              <Box position='absolute' bottom={2} right={2}>
                <Spinner size='sm' />
              </Box>
            </Box>
          )}
      
          {generatedSummary && (
            <Box
              borderColor='coolGray.200'
              borderWidth={1}
              borderRadius={4}
              padding={3}
              marginTop={2}
              marginBottom={5}
              width={'100%'}
            >
              <Text style={{ padding: 8, backgroundColor: Colors.FoldPixel.GRAY50, borderRadius: 4, color: Colors.FoldPixel.GRAY400 }}>{generatedSummary}</Text>

              <HStack space={3} marginTop={2} justifyContent= {isSumary ? 'space-between' : 'end'}>
               {
                isSumary &&
                <Pressable onPress={handleAddToNote}>
                  <Text style={{ fontWeight: '600', color: Colors.Custom.Primary300 }} >
                    + Add to Note
                  </Text>
                </Pressable>
               } 
                <HStack alignItems={'center'} space={3}>
                  <Tooltip label='Generate new' placement='top'>
                    <Pressable onPress={() => {
                      setGeneratedSummary(null);
                      setIsSumary(false)
                      handleGeneratePatientSummary(false);
                    }}>
                      <HardRefreshIconSvg referral={true} iconKey='diagnosis' />
                    </Pressable>
                  </Tooltip>
                  {
                    isSumary && 
                    <Tooltip label='Discard' placement='top'>
                    <Pressable
                      onPress={() => {
                        setGeneratedSummary(null)
                        setIsSumary(false)
                      }}
                    >
                      <TrashBinIcon2024 />
                    </Pressable>
                  </Tooltip>
                  }
                  
                </HStack>
              </HStack>
            </Box>
          )}

          <HStack space={3} alignItems='center'>
            <Input.TextArea
              placeholder='Add additional note or generate patient summary'
              value={additionalNote}
              onChange={(e) => onChangeAdditionalNote(e.target.value)} 
              autoSize={{ minRows: 2 }}
              style={{ resize: 'vertical' }}
            />
          </HStack>
        </Form.Item>
      }

    </Form>
  );
};

export default InsightsCreateReferralForm;
