export const smsRepliedOptions = [
    {
        label: 'Yes',
        value: 'YES'
    },
    {
        label: 'No',
        value: 'NO'
    }
];
export const reminderUnits = [
    {
        label: 'Hours',
        value: 'hour'
    },
    {
        label: 'Days',
        value: 'day'
    },
    {
        label: 'Weeks',
        value: 'week'
    },
    {
        label: 'Months',
        value: 'month'
    }
];