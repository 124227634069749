import {Button, Spinner} from 'native-base';
import React from 'react';
import {Pressable, Text, View, StyleSheet} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../../styles';
import {ToastType} from '../../../../utils/commonViewUtils';
import {getBase64} from '../../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import {
  downloadEmailAttachments,
  getEmailAttachments,
} from '../../EmailDrawerCommonV2/EmailInboxApi';
import {IEmailAttachmentResponse} from '../../EmailDrawerCommonV2/interfaces';
import {IEmailAttachmentsProps} from './interfaces';
import { BUTTON_TYPE, SENDER_TYPE } from '../../../../constants';
import {Modal} from 'antd';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';
import {useToast as useCustomToast} from '../../../Toast/ToastProvider';


const EmailAttachments = (props: IEmailAttachmentsProps) => {
  const [state, setState] = React.useState({
    isLoading: false,
    attachments: [] as IEmailAttachmentResponse[],
    isPHIAlertOpen: false,
    selectedAttachment: {} as IEmailAttachmentResponse
  });
  const customToast = useCustomToast();
  const intl = useIntl();

  const downloadAttachment = async (data: {
    attachment: IEmailAttachmentResponse;
  }) => {
    customToast({
      message: 'Downloading...',
      toastType: ToastType.info,
    });
    try {
      const file: any = {};
      const response = await downloadEmailAttachments({
        attachmentId: data.attachment.crmAttachments.id,
      });
      file.blob = response.data;
      file.blobUrl = URL.createObjectURL(file.blob);
      file.preview = await getBase64(response.data);
      const link = document.createElement('a');
      link.href = file.blobUrl;
      link.download = data.attachment.crmAttachments.name;
      link.click();
    } catch (error) {
      customToast({
        message: 'Failed to download attachment',
        toastType: ToastType.error,
      });
    }
  };

  const getAttachmentsForEmail = async () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      const response = await getEmailAttachments({
        messageUuid: props.messageUuid,
      });
      setState((prev) => ({
        ...prev,
        isLoading: false,
        attachments: response,
      }));
      if (props?.onAttachmentLoading) {
        props?.onAttachmentLoading()
      }
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
        attachments: [],
      }));
      if (props?.onAttachmentLoading) {
        props?.onAttachmentLoading()
      }
    }
  };

  React.useEffect(() => {
    getAttachmentsForEmail();
  }, []);

  return (
    <View>
      {state.isLoading ? (
        <View>
          <Spinner />
        </View>
      ) : (
        <View style={styles.attachmentsContainer}>
          {state.attachments.map((item) => (
            item?.crmAttachments?.id ? (
            <Pressable
              key={item.id}
              style={styles.attachmentItem}
              onPress={() => {
                if (
                  props?.senderType &&
                  props?.senderType === SENDER_TYPE.CONTACT
                ) {
                  setState((prev) => ({
                    ...prev,
                    isPHIAlertOpen: true,
                    selectedAttachment: item
                  }));
                } else {
                  downloadAttachment({attachment: item});
                }
              }}
            >
              <AntIcon name="paperclip" color={'white'} />
              <Text style={styles.attachmentText}>
                {item?.crmAttachments?.name || 'Attachment'}
              </Text>
            </Pressable>
            ) : null
          ))}
        </View>
      )}

      {state.isPHIAlertOpen && (
        <Modal
          title="PHI Warning"
          open={state.isPHIAlertOpen}
          footer={null}
          bodyStyle={{
            paddingTop: 12,
            zIndex: 9999999999,
          }}
          closeIcon={<></>}
          maskClosable={false}
          destroyOnClose
        >
          <Text style={styles.modalText}>
            {intl.formatMessage({id: 'fileDownloadPhiAlert'})}
          </Text>
          <Button.Group
            gap={'0.5'}
            style={styles.buttonGroup}
            justifyContent={'flex-end'}
          >
            <FoldButton
              customProps={{
                btnText: 'Confirm',
              }}
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                onPress() {
                  downloadAttachment({attachment: state.selectedAttachment});
                  setState((prev) => ({
                    ...prev,
                    isPHIAlertOpen: false,
                  }));
                },
              }}
            />
            <FoldButton
              customProps={{
                btnText: 'Cancel',
              }}
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress() {
                  setState((prev) => ({
                    ...prev,
                    isPHIAlertOpen: false,
                  }));
                },
              }}
            />
          </Button.Group>
        </Modal>
      )}
    </View>
  );
};

export default EmailAttachments;

const styles = StyleSheet.create({
  attachmentsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  attachmentItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.Custom.mainPrimaryPurple,
    padding: 4,
    borderRadius: 6,
    margin: 2,
  },
  attachmentText: {
    color: 'white',
    marginHorizontal: 4,
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14.4,
  },
  modalText: {
    color: 'black',
  },
  buttonGroup: {
    marginTop: 12,
  },
});
