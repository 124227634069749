import {PERSON_TYPES} from '../../../../../../constants/StringConst';
import {USER_ACCESS_PERMISSION} from '../../../../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import {getAllowedUserAccountLocationUuids} from '../../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {usePermissions} from '../../../../../CustomHooks/usePermissions';

export const useConversationPatientProfileAccess = () => {
  const {check} = usePermissions();

  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX
  );

  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );

  const canViewPatientProfile = ({
    contactTypeCode,
    accountLocationUuids,
  }: {
    contactTypeCode: string;
    accountLocationUuids: string[];
  }) => {
    if (!isCommunicationLocationHandlingEnabled) {
      return true;
    }
    let accessCodeValue = '';
    if (contactTypeCode === PERSON_TYPES.CUSTOMER) {
      accessCodeValue = MAIN_MENU_CODES.CONSUMER;
    } else {
      accessCodeValue = MAIN_MENU_CODES.LEAD;
    }
    const permissionWiseLocationData = check(
      USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      accessCodeValue,
      ''
    );
    let isPatientOfUserLocation = false;
    if (permissionWiseLocationData?.hasAccess !== true) {
      return false;
    }
    if (accountLocationUuids?.length > 0) {
      isPatientOfUserLocation = accountLocationUuids?.some((accountLocationUuid) =>
        allowedUserAccountLocationUuids?.includes(accountLocationUuid)
      );
    } else if (contactTypeCode !== PERSON_TYPES.CUSTOMER) {
      isPatientOfUserLocation = true;
    }
    return isPatientOfUserLocation;
  };

  return {
    canViewPatientProfile,
  };
};
