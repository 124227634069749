import {Colors} from '../../../styles';
import {StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  popoverContent: {
    padding: 12,
    minWidth: 200,
  },
  profileItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray100,
    backgroundColor: Colors.Custom.White,
  },
  locationText: {
    marginLeft: 8,
    fontSize: 16,
    color: Colors.Custom.Gray700,
  },
  profileButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.Custom.Gray100,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 16,
    marginVertical: 8,
    marginHorizontal: 4,
    maxWidth: 300,
    width: 'auto',
    flex: 1,
  },
  profileTitle: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.Custom.Gray500,
    marginRight: 4,
    flexShrink: 1,
    flex: 1,
  },
  profileText: {
    fontSize: 14,
    color: Colors.Custom.Gray600,
  },
  selectedProfileItem: {
    backgroundColor: Colors.Custom.Gray100,
  },
  selectedLocationText: {
    color: Colors.Custom.Gray700,
  },
});

export const antDStyles: Record<string, React.CSSProperties> = {
  overlayInnerStyle: {
    borderRadius: 8,
    maxHeight: 300,
    overflowY: 'auto',
  },
};
