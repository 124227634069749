import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {Button, Drawer, Segmented, notification} from 'antd';
import {Divider, HStack, Skeleton, Spinner, Text, useToast, View, VStack, Stack} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {CUSTOM_AUTOMATION_URL, DATE_FORMATS, ENTITY_EVENTS_TITLE_SUBTITLE, ENTITY_EVENTS_TYPE} from '../../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {
  MLOV_CATEGORY,
  PATIENT_CARE_JOURNEY_STATUS_CODE,
} from '../../../constants/MlovConst';
import {CareJourneyQueries, TaskQueries} from '../../../services';
import {Colors} from '../../../styles';
import {getBooleanFeatureFlag, replaceHashValueToString} from '../../../utils/commonUtils';
import {
  getDateStrFromFormat,
  isCurrentDateInFutureComparedToOther,
} from '../../../utils/DateUtils';
import {updateContactWorkflow} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateQueryUtils';
import {IProductDetailForm} from '../../RightSideContainer/Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/interfaces';
import {
  USER_ACCESS_PERMISSION,
} from '../../RightSideContainer/UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {BottomViewAction, canShowBottomView, descriptionDisplayModes, getBottomView, getDescription, isTaskWithType} from '../../TaskCard/TaskCardHelper';
import {IAddOrUpdateTaskState, ISubTasks, ISubTasksRes} from '../AddTask/interfaces';
import {ITask} from '../CareDashboard/CareDashboardInterfaces';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import {getVitalListFromCapability} from '../../../utils/capabilityUtils';
import {AppointmentPatientBannerLoading} from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentPatientBannerLoading';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import AddSubTasks from '../AddTask/AddEditTaskView/AddSubTasks';
import { DisplayText } from '../DisplayText/DisplayText';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovCodeFromId, getMlovListFromCategory } from '../../../utils/mlovUtils';
import { TASK_ACTIONS, getCompletedTaskStatusId } from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { LabelDataListView } from '../CareDashboard/CareDashboardWidget/LabelDataListView';
import AddOrUpdateCommentForTask from '../AddTask/AddEditComment/AddOrUpdateCommentForTask';
import AttachmentsItemList from '../AddTask/AddEditTaskView/AttachmentsItemList';
import { manageAttachmentsListData } from '../AddTask/AddTaskUtils';
import {EntityType} from '../../TaskCard/TaskEnum';
import { TaskViewTabsCode, TASK_VIEW_TABS } from '../AddTask/AddEditComment/TaskCommentsHelper';
import { TASK_EVENTS } from '../CareDashboard/CareDashboardConstants';
import useTaskActionManager from '../CareDashboard/CustomHook/useTaskActionManager';
import { EventBus } from '../../../utils/EventBus';
import { IPatientNoteCardProps } from '../../PersonOmniView/MiddleContainer/interfaces';
import { PatientLinkNoteCard } from '../../PersonOmniView/MiddleContainer/CareTimeline/PatientLinkNoteCard';
import { useNavigate } from 'react-router-dom';
import { ToastType } from '../../../utils/commonViewUtils';
import { redirectToNewTab } from '../../SideCar/SidecarUtils';
import { TaskExtraDetailsView } from '../AddTask/AddEditTaskView/TaskExtraDetailsView';
import WorkFlowListForTasks from '../../TaskCard/WorkFlowListForTasks';
import { TaskDrawerActionWithSelect } from '../AddTask/AddEditTaskView/TaskDrawerActionWithSelect';
import LeftArrowIconSvg from '../Svg/AwvSvgs/LeftArrowIconSvg';
import '../../common/CalendarWidget/BookingWorkflows/ScheduleSuggestor/components/ScheduleRuleMemberList/styles.scss'
import PatientLinkCarePlanCard from '../AddTask/AddEditTaskView/components/CarePlanLinkCard';
import CarePlan from '../../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import { ICarePlanProps } from '../../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';
import { useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import FeatureFlags from '../../../constants/FeatureFlags.enums';

const ReadOnlyTaskView = (props: {
  value: IAddOrUpdateTaskState;
  task: ITask;
  isVisible: boolean;
  onCancel: () => void;
  taskConfig: any;
  fetchAllTypeTask?:(selectedAssigneeUserId?: string) => void;
  labelsLoading?: boolean;
  onStatusChangeComplete?: () => void;
  linkPatientNote?: IPatientNoteCardProps;
  onChangeCarePlanVisible?: (isVisible: boolean) => void;
  linkedCarePlanId?: string;
  loading?: boolean;
  onNoteRedirect?: () => void;
  personData?: any;
  accountUsers: any;
  onEdit?: () => void;
  canShowEdit?: boolean;
  carePlanProps?: ICarePlanProps;
  showLinkedCarePlan?: boolean;
  taskLocationName?: string;
}) => {
  const navigate = useNavigate();

  const toast = useToast();
  const intl = useIntl();
  const eventBus = EventBus.getEventBusInstance();
  const {value, isVisible, onCancel, task, taskConfig, labelsLoading, linkPatientNote, onStatusChangeComplete} =
    props;
  const accountLocationUuid = props.personData?.accountLocationUuid || '';
  const vitals = getVitalListFromCapability('', accountLocationUuid);
  const mlovData = useContext(CommonDataContext);
  const userSettings = mlovData.userSettings;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const canUpdateStatus = task?.hasTaskAccess;
  const mlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PATIENT_CARE_JOURNEY_STATUS
    ) || [];
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const defaultDate = getDateStrFromFormat(
    new Date(),
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
  const [expandSubtask, setExpandSubtask] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [journeyDetails, setJourneyDetails] = useState<IProductDetailForm>({
    name: '',
    description: '',
    memberOutcome: '',
    businessOutcome: '',
    triggerEvents: [],
    careTeam: [],
    prevCareTeam: [],
    shipping_category_id: '',
    available_on: '',
    price: '',
    cost_currency: 'USD',
    compare_at_price: '',
    sku: '',
    startDate: defaultDate,
    prices: [],
  });
  const [contactDetails, setContactDetails] = useState<{
    details: any;
    loading: boolean;
    subTasks: ISubTasks[],
  }>({
    details: undefined,
    loading: false,
    subTasks: [] as ISubTasks[],
  });
  const [selectedTab, setSelectedTab] = useState(TASK_VIEW_TABS?.find((tab) => tab?.code === TaskViewTabsCode?.ACTIVITY))
  const tabOptions = TASK_VIEW_TABS.map((tab) => ({
    label: tab.title,
    value: tab.code,
  }));

  const [updateTaskWorkflow] = useMutation(TaskQueries.UPDATE_TASK_WORKFLOW, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onError: (error) => {

    },
  });
  const [getContactDetails] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;

  const {loading} = useQuery(CareJourneyQueries.GET_CARE_JOURNEY_BY_ID, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      id: props.task?.referenceData?.careJourney?.id,
    },
    skip: !props.task?.referenceData?.careJourney?.id,
    onCompleted: (data: any) => {
      if (data && data.careJourney) {
        const careJourney = data.careJourney;
        const careTeam = careJourney.careJourneyTeam.map(
          (team: any) => team.roleId
        );
        data.careJourney.careTeam = careTeam;
        setJourneyDetails((prev) => ({
          ...prev,
          ...data.careJourney,
        }));
      } else {
        setJourneyDetails((prev) => ({
          ...prev,
          careTeamUsers: [],
          careTeam: [],
        }));
      }
    },
  });
  const UpdateWorkFlow = (referenceData: any, workflowEventList: any) => {
    const data = updateContactWorkflow(workflowEventList);
    updateTaskWorkflow({
      variables: {
        params: {
          id: props.task?.id,
          data: {
            referenceData: {
              ...(referenceData || {}),
              ...data
            },
          },
        },
      },
    });
  };

  const onShowToast = (message: string, type: ToastType, additionalData?: any) => {
    notification.destroy();
    const notificationMethod = type === ToastType.info ?
      notification.info :
      (type === ToastType.error ? notification.error : notification.success);
    notificationMethod({
      message,
      duration: additionalData?.duration || 3.0,
      placement: 'top',
      style: additionalData?.style,
      onClick: () => {
        notification.destroy();
        additionalData?.onClick?.();
      }
    });
  }

  const {handleTaskActions, taskActionsInProgress} = useTaskActionManager({
    showToast: onShowToast,
    locationUuid: accountLocationUuid,
  });



  const getJourneyDates = (careJourney: {
    startDateTime: string;
    endDateTime: string;
  }) => {
    let date = '';
    if (careJourney.startDateTime && careJourney.endDateTime) {
      date = getDateStrFromFormat(
        careJourney.startDateTime,
        DATE_FORMATS.DISPLAY_DATE_FORMAT
      );

      if (
        isCurrentDateInFutureComparedToOther(
          new Date(),
          careJourney.endDateTime
        )
      ) {
        date +=
          ' - ' +
          getDateStrFromFormat(
            careJourney.endDateTime,
            DATE_FORMATS.DISPLAY_DATE_FORMAT
          );
      } else {
        date += ' - Present';
      }
    }
    return date;
  };
  useEffect(() => {
    if (props.task && props.task.contactId) {
      setContactDetails((prev) => ({
        ...prev,
        loading: true,
        details: undefined
      }));
      getContactDetails({
        variables: {
          contactId: props.task.contactId,
        },
      })
      .then((response) => {
        if (
          response?.data?.contacts?.length > 0 &&
          response.data.contacts[0].person
        ) {
          const contactDetail = response.data.contacts[0];
          setContactDetails((prev) => ({
            ...prev,
            details: contactDetail,
          }));
        }
      })
      .catch((error) => {

        setContactDetails((prev) => ({
          ...prev,
          details: undefined
        }));
      }).finally(() => {
        setContactDetails((prev) => ({
          ...prev,
          loading: false,
        }));
      }
      )
    }
  }, [props?.task?.contactId]);

  useEffect(()=> {
    if (props.task?.id && props.task?.subTasks?.length) {
      getSubTask(props.task);
    }
  }, [props?.task?.id])

  const getSubTask = async (task: ITask) => {
    const subTasksId = task.subTasks?.map((item) => item.id.toString());
    setContactDetails((prev) => ({...prev, loading: true}));
    const response = await getSubTaskByIds({
      variables: {
        ids: subTasksId,
      },
    });
    if (response?.data?.getTasks?.tasks?.length) {
      const subTaskRes = response.data.getTasks.tasks;
      const updatedItems = subTaskRes.map((item: ISubTasksRes) => ({
        ...item,
        isChecked: item.statusId === completedStatusId ? true : false,
        assignee: {
         value: item?.assigneeUser?.uuid,
         label: item?.assigneeUser?.name,
         key: item?.assigneeUser?.uuid,
         details: item?.assigneeUser,
        },
      }));
      setContactDetails((prev) => {
        return {
          ...prev,
          subTasks: updatedItems,
        };
      });
    }
    setContactDetails((prev) => ({...prev, loading: false}));
  };

  const isVitalTask = isTaskWithType(props?.task || {} as ITask, EntityType.VITAL);
  const isFormTask = isTaskWithType(props?.task || {} as ITask, EntityType.FORM);

  const canShowMarkAsComplete = ()=> {
    if (isVitalTask || isFormTask){
      return false;
    } else {
      return props?.taskConfig?.canMarkAsComplete
    }
  }

  const onTaskMarkAsComplete = async (task: ITask) => {
    setActionLoading(true);
    const statusMlov = taskStatusMlov.filter((item) => item.id === completedStatusId)?.[0];
    await handleTaskActions(task, BottomViewAction.markAsComplete, {});
    eventBus.broadcastEvent(TASK_EVENTS.TASK_UPDATED, {
       task: {
        ...task,
        statusId: completedStatusId,
        isCompleted: true,
        status: {
          id: statusMlov?.id || '',
          code: statusMlov?.code || '',
          value: statusMlov?.value || '',
        }
      }
    });
    setActionLoading(false);
    props?.fetchAllTypeTask?.();
  }

  const getTaskLoading = (task: ITask) => {
    return (
      taskActionsInProgress.filter((item) => item.taskId === task.id).length > 0
    );
  };

  const optedOutJourneyElement = (patientCareJourney: any) => {
    if (patientCareJourney?.statusId && patientCareJourney?.statusReasonCode) {
      const statusReasonCode = patientCareJourney?.statusReasonCode;
      const code = getMlovCodeFromId(mlovs, patientCareJourney?.statusId);
      if (
        code === PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED &&
        statusReasonCode === 'PATIENT_OPTED_OUT'
      ) {
        return (
          <>
            <Text color={Colors.Custom.Gray500}> • </Text>
            <Text fontWeight={400} color={Colors.FoldPixel.STATUS_ERROR}>
              Opted Out
            </Text>
          </>
        );
      }
      return <></>;
    }
    return <></>;
  };

  const onBottomViewAction = async (task: ITask, action: BottomViewAction, data?: any) => {
    const patientId = props?.personData?.patientId || props?.personData?.patientUuid;
    const statusMlov = taskStatusMlov.filter((item) => item.id === completedStatusId)?.[0];
    switch (action) {
      case BottomViewAction.captureLabTest:
      case BottomViewAction.captureVital:
        await handleTaskActions(task, action, data, { patientId, accountLocationUuid });
        eventBus.broadcastEvent(TASK_EVENTS.TASK_UPDATED, {
          task: {
           ...task,
           statusId: completedStatusId,
           isCompleted: true,
           status: {
             id: statusMlov?.id || '',
             code: statusMlov?.code || '',
             value: statusMlov?.value || '',
           }
         }
       });
       props?.fetchAllTypeTask?.();
       onCancel?.();
       break;

      default:
        await handleTaskActions(task, action, data);
        break;
    }
  }

  const showBottomView = canShowBottomView(task);

  return (
    <Drawer
      closable
      className={isSidecarContext? 'custom-task-drawer-sidecar' : 'custom-task-drawer-web'}
      width={isSidecarContext ? '100%' : '50%'}
      mask={isSidecarContext ? false : true}
      open={props.isVisible}
      title={
        <ModalActionTitle
          isHeadNotSticky
          title={'task'}
          titleSize={24}
          titleColor={'#000000'}
          leftBackButton={
            true ? (
              <Button
                onClick={() => props?.onCancel?.()}
                type="text"
                icon={<LeftArrowIconSvg />}
                style={{ marginBottom: 6 }}
              />
            ) : undefined
          }
        />
      }
    >
      {loading || contactDetails.loading ? (
        <Spinner size={'lg'} />
      ) : (
        <>
          <VStack space={4} mx={isSidecarContext? 0 : 6} mt={isSidecarContext? 0 : 3}>
            {/* {task?.contact &&
              task?.contact?.name &&
              task?.contactId && (
                <VStack flex={1}>
                  {contactDetails.loading && (
                    <AppointmentPatientBannerLoading />
                  )}
                  {!contactDetails.loading && (
                    <SidecarPatientInfoBanner
                      contactData={contactDetails.details || task?.contact}
                    />
                  )}
                  {!contactDetails.loading && (<View marginTop={4} marginX={-6}><Divider  /></View>)}
                </VStack>
              )} */}
            {(task?.isReadOnly) && (
              <HStack padding={2} backgroundColor={Colors.Custom.infoBannerBgColor} alignItems={'center'} space={1}>
                <InfoCircleOutlined style={{color: Colors.FoldPixel.INFO_COLOR,}}/>
                <Text 
                  style={{
                    fontSize: 14,
                    color: Colors.FoldPixel.GRAY400,
                  }}
                  fontWeight={400}
                >
                  {
                    canUpdateStatus ?
                      intl.formatMessage({id: 'disableTaskEditMsg'})
                      : replaceHashValueToString(
                        {name: 'Task'},
                        intl.formatMessage({id: 'onlyViewAccessMsg3'})
                      )
                  }
                </Text>
              </HStack>
            )}
            {props.loading ? (
              <Skeleton.Text lines={4}></Skeleton.Text>
            ) : (
              <View style={{ width: '24%', paddingRight: 1 }}>
                <TaskDrawerActionWithSelect
                  actions={TASK_ACTIONS}
                  task={task || ({} as ITask)}
                  onComplete={() => {
                    onStatusChangeComplete?.();
                    props?.onCancel?.();
                  }}
                  linkPatientNote={linkPatientNote}
                  disableTaskStatusChange={!canUpdateStatus}
                />
              </View>
            )}
            <View>
              <VStack gap={4} marginBottom={2}>
                <Text
                  fontSize={14}
                  color={Colors.Custom.alertsDescriptionColor}
                  fontWeight={400}
                >
                  Title
                </Text>
                <Text
                  fontSize={14}
                  color={Colors.FoldPixel.GRAY400}
                  fontWeight={400}
                >
                  {props.task.title}
                </Text>
              </VStack>
              {getDescription(
                props.task,
                task?.contact?.name,
                descriptionDisplayModes.VERBOSE,
                vitals || []
              )}
              {(props?.task?.attachments?.length || 0) > 0 && (
                <AttachmentsItemList
                  disableStrictColumns
                  value={value}
                  fileList={manageAttachmentsListData(
                    props?.task?.attachments || []
                  )}
                  isViewOnly
                />
              )}
              {showBottomView &&
                getBottomView(
                  task,
                  getTaskLoading(task),
                  vitals,
                  onBottomViewAction,
                  false
              )}
              {props.task.patientCareJourney &&
                props.task.patientCareJourney.careJourney &&
                (props?.task?.patientCareJourney?.title ||
                  props?.task?.patientCareJourney?.careJourney?.title) && (
                  <VStack>
                    <DisplayText
                      size={'smMedium'}
                      extraStyles={{
                        color: Colors.Custom.alertsDescriptionColor,
                        fontWeight: '400',
                        fontSize: 14,
                      }}
                      textLocalId={'Linked Journey'}
                    />
                    <VStack
                      padding={2}
                      paddingLeft={0}
                      backgroundColor={Colors.FoldPixel.GRAY50}
                      borderWidth={1}
                      borderColor={Colors.FoldPixel.GRAY100}
                      borderRadius={6}
                      marginY={1}
                    >
                      <Stack direction={'row'} my={0.5} alignItems="center">
                        <Text color={Colors.Custom.Gray500} fontSize={14}>
                          {getJourneyDates(props.task?.patientCareJourney)}
                        </Text>
                        {optedOutJourneyElement(
                          props.task?.patientCareJourney
                        )}
                      </Stack>
                      <Text color={Colors.FoldPixel.GRAY400} fontSize={14} fontWeight={500}>
                        {task?.patientCareJourney?.title ||
                          task?.patientCareJourney?.careJourney?.title}
                      </Text>
                    </VStack>
                  </VStack>
                )}
              <TaskExtraDetailsView
                task={props?.task}
                accountUsers={props?.accountUsers}
              />
            </View>
            {!!props.linkedCarePlanId && (
              <PatientLinkCarePlanCard
                assignByUserName={task?.assignedByUser?.name}
                key={props.linkedCarePlanId}
                linkedCarePlanId={props.linkedCarePlanId}
                onCarePlanLinkClick={() => {
                  const url = `/#/members/patient/${task?.contact?.id}/carePlan`;
                  if (isSidecarContext) {
                    props.onChangeCarePlanVisible?.(true);
                  }
                  else{
                    window.open(url, '_blank', 'noopener,noreferrer');
                  }
                }}
              />
            )}
            {!!linkPatientNote && !!linkPatientNote?.resourceId && (
              <PatientLinkNoteCard
                data={linkPatientNote}
                onNoteLinkClick={() => {
                  if (isSidecarContext) {
                    redirectToNewTab(
                      `/members/patient/${linkPatientNote?.contactId}/notes/${linkPatientNote?.resourceId}`
                    );
                  } else {
                    navigate(
                      `/members/patient/${linkPatientNote?.contactId}/notes/${linkPatientNote?.resourceId}`
                    );
                  }
                  !!props?.onNoteRedirect && props?.onNoteRedirect();
                }}
                accountLocationUuid={accountLocationUuid}
              />
            )}
            {(contactDetails?.subTasks || [])?.length > 0 && (
              <VStack margin={0}>
                <Text
                  fontSize={14}
                  color={Colors.Custom.alertsDescriptionColor}
                  fontWeight={400}
                >
                  Subtasks
                </Text>
                {contactDetails.subTasks?.map((data, index) => (
                  <AddSubTasks key={index} subTaskItem={data} isDisable />
                ))}
              </VStack>
            )}
            <HStack space={2} style={{justifyContent: 'space-around'}}>
              <View style={{width: '49%', paddingRight: 1}}>
                <VStack gap={4}>
                  <Text
                    fontSize={14}
                    color={Colors.Custom.alertsDescriptionColor}
                    fontWeight={400}
                  >
                    Assigned To
                  </Text>
                  <Text
                    fontSize={14}
                    color={Colors.FoldPixel.GRAY400}
                    fontWeight={400}
                  >
                    {value.assignee?.label || '-'}
                  </Text>
                </VStack>
              </View>
              <View style={{width: '49%', paddingRight: 1}}>
                <VStack gap={4}>
                  <Text
                    fontSize={14}
                    color={Colors.Custom.alertsDescriptionColor}
                    fontWeight={400}
                  >
                    Task Pool
                  </Text>
                  <Text
                    fontSize={14}
                    color={Colors.FoldPixel.GRAY400}
                    fontWeight={400}
                  >
                    {task.userPool?.name || '-'}
                  </Text>
                </VStack>
              </View>
            </HStack>
            <HStack space={2} style={{justifyContent: 'space-around'}}>
              <View style={{width: '49%', paddingRight: 1}}>
                <VStack gap={4}>
                  <Text
                    fontSize={14}
                    color={Colors.Custom.alertsDescriptionColor}
                    fontWeight={400}
                  >
                    Due Date
                  </Text>
                  <Text
                    fontSize={14}
                    color={Colors.FoldPixel.GRAY400}
                    fontWeight={400}
                  >
                    {getDateStrFromFormat(
                      task.endDateTime,
                      DATE_FORMATS.TASK_DUE_DATE_DISPLAY_FORMAT
                    ) || '-'}
                  </Text>
                </VStack>
              </View>
              <View style={{width: '49%', paddingRight: 1}}>
                <VStack gap={4}>
                  <Text
                    fontSize={14}
                    color={Colors.Custom.alertsDescriptionColor}
                    fontWeight={400}
                  >
                    Priority
                  </Text>
                  <Text
                    fontSize={14}
                    color={Colors.FoldPixel.GRAY400}
                    fontWeight={400}
                  >
                    {task?.priority?.value || '-'}
                  </Text>
                </VStack>
              </View>
            </HStack>
            <HStack space={2} style={{justifyContent: 'space-around'}}>
              <View style={{width: '49%', paddingRight: 1}}>
                <VStack gap={4}>
                  <Text
                    fontSize={14}
                    color={Colors.Custom.alertsDescriptionColor}
                    fontWeight={400}
                  >
                    Member
                  </Text>
                  <Text
                    fontSize={14}
                    color={Colors.FoldPixel.GRAY400}
                    fontWeight={400}
                  >
                    {task.contact?.name || '-'}
                  </Text>
                </VStack>
              </View>
              <View style={{width: '49%', paddingRight: 1}}>
                <VStack gap={4} flex={1}>
                  <Text
                    fontSize={14}
                    color={Colors.Custom.alertsDescriptionColor}
                    fontWeight={400}
                  >
                    Labels
                  </Text>
                  <View style={{flex: 1}}>
                    {!!value?.taskLabels?.length ? (
                      <LabelDataListView
                        key={task.id}
                        hideAddMoreButton={true}
                        tagList={value?.taskLabels || []}
                        style={{width: 800}}
                      />
                    ) : (
                      <Text>-</Text>
                    )}
                  </View>
                </VStack>
              </View>
            </HStack>
            <Segmented
              options={tabOptions}
              value={selectedTab?.code}
              onChange={(value) => {
                const selected = TASK_VIEW_TABS.find(
                  (tab) => tab.code === value
                );
                setSelectedTab(selected);
              }}
              style={{
                marginTop: 2,
                marginBottom: -4,
                width: 'fit-content',
                maxWidth: '35%',
                height: 32,
                borderRadius: 4,
              }}
              onResize={undefined}
              onResizeCapture={undefined}
            />
            {(!!props.task?.id && !!props.accountUsers?.length) &&
              selectedTab?.code === TaskViewTabsCode?.ACTIVITY && (
                <AddOrUpdateCommentForTask 
                  task={props.task}
                  accountUsers={props.accountUsers}
                  hideCommentBox={!canUpdateStatus}
                />
              )}

            {selectedTab?.code === TaskViewTabsCode?.AUTOMATION && (
              <FoldPermitCan
                resource={MAIN_MENU_CODES.AUTOMATION}
                action={USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code}
                component={<WorkFlowListForTasks
                  isDetailsPage={true}
                  workflowlist={
                    props.task.referenceData &&
                    props.task.referenceData.workflowList &&
                    props.task.referenceData.workflowList.length > 0
                  }
                  addUrl={CUSTOM_AUTOMATION_URL.ADD_TASK}
                  title={ENTITY_EVENTS_TITLE_SUBTITLE.ADD_TASK_TITLE}
                  subtitle={ENTITY_EVENTS_TITLE_SUBTITLE.ADD_TASK_SUB_TITLE}
                  entityEventList={ENTITY_EVENTS_TYPE.ADD_TASK}
                  onValueChanage={(eventEntityList: any) => {
                    UpdateWorkFlow(props?.task?.referenceData, eventEntityList);
                  }}
                />}
              />
            )}
          </VStack>
        </>
      )}
      {isSidecarContext && props?.showLinkedCarePlan && (
        <Drawer className='create-note-from-message-drawer' onClose={() => {}} width={'100%'} open>
          <CarePlan
            {...(props?.carePlanProps as ICarePlanProps)}
            onClose={(isAdded: boolean) => {
              props?.carePlanProps?.onClose?.(isAdded);
          }}/>
        </Drawer>
      )}
    </Drawer>
  );
};

export default ReadOnlyTaskView;
