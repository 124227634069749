import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import BasicFields from "../../FHFormio/EditFormFields/BasicFields";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { PhoneNumberSchema } from "../Schema/ComponentsSchema";
import { Component } from "../BaseComponents/BaseComponentInterface";
import MaskedInputField from "../BaseComponents/MaskedInputField";
import PhoneNumberSvg from "../../../../../assets/Icons/FormBuilder/PhoneNumberSvg";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { v4 as uuidV4 } from 'uuid';
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import { CommonDataContext } from "../../../../../context/CommonDataContext";

const PHONE_NUMBER_PATTERN = '^[0-9]{10}$';

export const PhoneNumberFieldFormElement: FormElement = {
  type: PhoneNumberSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(PhoneNumberSchema),
    key: getUniqueKey(map, PhoneNumberSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(PhoneNumberSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: PhoneNumberSvg,
    label: PhoneNumberSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (formElement: FormElementInstance, currentValue: string, silentCheck?: boolean): ValidationResult => {
  if (formElement.validate?.required && !currentValue) {
    return { isValid: false, errorMessage: `${formElement.label || 'This field'} is required`, key: formElement.key, fieldValue: currentValue };
  }

  const isValidPattern = currentValue ? new RegExp(PHONE_NUMBER_PATTERN).test(currentValue) : true;
  if (!isValidPattern) {
    return { isValid: false, errorMessage: 'Please enter a valid phone number', key: formElement.key, fieldValue: currentValue };
  }

  return { isValid: true, errorMessage: '', key: formElement.key, fieldValue: currentValue };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = e.target.value?.replace(/[^\d]/g, '');
    const valid = localValidate(elementInstance, formattedValue);
    setErrorMessage(valid.errorMessage);
    setValue(formattedValue);
  }, [elementInstance]);

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        onChange={debounce(onChange, 500)}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState(formatPhoneNumber(defaultValue || selectedValue || ''));
  const isMounted = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || '', silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: ChangeEvent<HTMLInputElement>) => {
    const value = newValue.target.value;
    setValue(value);
    if (!submitValue) return;
    const formattedValue = value.replace(/[^\d]/g, '');
    await debouncedValidation(formattedValue);
    submitValue(elementInstance.key, formattedValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={debounce(handleChange, 500)}
    />
  );
}

const ComponentView = React.memo(({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) => {
  const { label, validate, placeholder, description, tooltip, allowToShare, inputMask } = elementInstance;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;
  const inputMaskValue = inputMask || "(999) 999-9999";
  const defaultPlaceholder = inputMaskValue.replace(/9/g, '_');

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      {isReadOnly && !isPrintForm ? (
        <div className="field-value">{defaultValue || '-'}</div>
      ) : (
        <MaskedInputField
          mask={inputMaskValue}
          errorMessage={errorMessage}
          placeholder={placeholder || defaultPlaceholder}
          onChange={onChange}
          defaultValue={defaultValue}
          data-testid={`phone-field-${elementInstance.key}`}
          disabled={isReadOnly || false}
        />
      )}
      {description && (
        <Description description={description} />
      )}
    </div>
  );
});

ComponentView.displayName = 'PhoneNumberComponentView';

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as Component;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'oldtextfield',
      input: true,
      key: 'placeholder',
      label: 'Placeholder',
      placeholder: 'Placeholder',
      tooltip: 'The placeholder text that will appear when this field is empty.',
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
      {
        type: 'oldtextfield',
        input: true,
        key: 'inputMask',
        label: 'Input Mask',
        tooltip:
          "An input mask helps the user with input by ensuring a predefined format.<br>9 indicates a numeric input expected from user<br>Total no of 9's in the mask denote expected length<br><br>Example telephone mask: (999) 999-9999",
      },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

// Helper function to format phone numbers
function formatPhoneNumber(value: string): string {
  if (!value) return value;

  // Remove all non-digits
  const phoneNumber = value.replace(/\D/g, '');

  // Format the number as (XXX) XXX-XXXX
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}
