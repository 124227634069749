import {View, StyleSheet} from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import {IContact, IContactProfile} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {IFormResponse} from '../../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import HomeMonitoringView from '../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/HomeMonitoringView';
import AddOrUpdateVitals from '../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitals';
import { CapabilityResource, VITAL_INTAKE_COMPONENT } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { CommonDataContext } from '../../../context/CommonDataContext';
import LabReports from '../../RightSideContainer/Forms/FHFormio/CustomComponents/LabReports/LabReports';
import { useCarePlanGoalsApi } from '../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/CarePlanGoalsHook';
import { Colors } from '../../../styles';
import { getCapability, getEnabledFormComponents } from '../../../utils/capabilityUtils';
import useFormOptions from '../../RightSideContainer/ContactCareProgram/ContactCareProgramView/hooks/useFormOptions';
import { FormViewType } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import useEHRCapabilities from '../../../screens/BusinessStudio/useEHRCapabilities';
import FHForm from '../../RightSideContainer/Forms/FHFormio/FHForm/FHForm';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { useLazyQuery } from '@apollo/client';
import ContactCareProgram from '../../../services/ContactCareProgram/ContactCareProgram';
import { getContactProfileAccountLocationUuid, isEnableCareProgram } from '../../../utils/commonUtils';
import { PROGRAM_FILTER_CODE } from '../../common/MemebersView/constant';
import { CONTACT_CARE_PROGRAM_STATUS_CODES } from '../../../constants/MlovConst';
import { useIntl } from 'react-intl';
import { usePersonOmniViewContext } from '../../PersonOmniView/PersonOmniView.context';

const InsightsCareBoardView = (props: {
  contactData?: IContact;
  formattedContactData?: IContact;
  isSidecarContext?: boolean;
}) => {
  const {contactData, formattedContactData} = props;
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const {ehrPatientId} = usePersonOmniViewContext();
  const patientId = ehrPatientId || contactData?.patient?.patientId || contactData?.patient?.patientUuid;
  const locationId = contactData?.contactPracticeLocations && contactData?.contactPracticeLocations?.length > 0
    ? contactData.contactPracticeLocations[0]?.accountLocation?.uuid : ''

  const accountLocationUuid = getContactProfileAccountLocationUuid(contactData);
  // const ehrCapabilitiesForObservations = mlovData.ehrCapabilities.find((item: { resourceName: any; }) => item.resourceName && [CapabilityResource.observation].includes(item.resourceName));
  const [careProgramIds, setCareProgramIds] = useState<string[]>([]);
  const [getContactCarePrograms] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const fetchCareProgramIds = async () => {
    try {
      const response = await getContactCarePrograms({
        variables: {
          contactId: contactData?.uuid,
          codes: [PROGRAM_FILTER_CODE.CCM, PROGRAM_FILTER_CODE.CHF],
          statusCodes: [CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED, CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED, CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED]
        },
      });
      const fetchedCareProgramIds = response?.data?.contactCarePrograms?.map((program: any) => program.id) || [];
      return fetchedCareProgramIds;
    } catch (error) {
    }
  };

  useEffect(() => {
    const getCareProgramIds = async () => {
      const ids = await fetchCareProgramIds();
      setCareProgramIds(ids);
    };
    getCareProgramIds();
  }, [contactData]);

  const ehrCapabilitiesForObservations = getCapability(
    CapabilityResource.observation,
    '',
    accountLocationUuid
  );

  const [fetchedGoals, goalsLoading, fetchGoals] = useCarePlanGoalsApi(careProgramIds);

  const {intakeOptions} = useFormOptions({
    accountLocationUuid,
    patientId,
    contactId: String(contactData?.id),
    contactUuid: contactData?.uuid,
    backgroundSaveEnabled: true,
    componentView: FormViewType.compact,
    ehrActivePatient: contactData?.contactProfile,
    locationId: locationId,
    contactIntId: String(contactData?.id)
  });
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const [enabledVitalComponents] = React.useState(getEnabledFormComponents(VITAL_INTAKE_COMPONENT, ehrCapabilities));

  useEffect(() => {
    if (isCareProgramEnabled && !!careProgramIds.length) {
      fetchGoals(contactData?.uuid);
    }
  }, [careProgramIds])

  return (
    <>
      <View style={[styles.container, styles.containerBgColor]}>
        <HomeMonitoringView
          contactId={`${contactData?.uuid}`}
          patientId={patientId || ''}
          locationId={accountLocationUuid || ''}
          defaultCollapse={false}
          component={{
            label: 'Home Monitoring',
          }}
          isSidecar={props?.isSidecarContext}
          goals={fetchedGoals}
          contactIntId={contactData?.id}
        />
      </View>
      {props?.isSidecarContext ? (
        <View style={[styles.container, styles.containerBgColor]}>
          <AddOrUpdateVitals
            validateRef={undefined}
            disabled={true}
            component={{
              label: 'Vitals',
            }}
            options={{
              capabilities: ehrCapabilitiesForObservations,
              contactId: `${contactData?.uuid}`,
              patientId: patientId || '',
              locationId: accountLocationUuid || '',
              isSidecar: true,
              goals: fetchedGoals,
              contactIntId:contactData?.id
            }}
            onChange={() => {}}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <FHForm
            optionData={intakeOptions}
            components={enabledVitalComponents}
          />
        </View>
      )}
      <View style={[props.isSidecarContext ? styles.container : styles.container1, styles.containerBgColor]}>
        <LabReports
          contactId={contactData?.uuid}
          accountLocationId={accountLocationUuid || ''}
          patientId={patientId}
          ccmDate={''}
          isHeaderView={!props?.isSidecarContext}
          relevantCodes={[]}
          isSideCar={props?.isSidecarContext}
          goals={fetchedGoals}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    marginHorizontal: 12,
    overflow: 'hidden',
    marginTop: 20
  },
  container1: {
    borderRadius: 8,
    marginHorizontal: 12,
    overflow: 'hidden',
    marginTop: 4
  },
  containerBgColor: {
    backgroundColor: Colors.Custom.White,
  },
});

export default InsightsCareBoardView;
