import React, { useMemo } from 'react';
import {Svg, Path} from 'react-native-svg';

function PatientEducationSvg(props: {width?: number, height?: number, color?: string}) {
  const svgContent = useMemo(() => (
    <Svg width={props.width || 16} height={props.height || 17} fill="none" viewBox="0 0 16 17">
      <Path
        fill={props.color || '#D0D5DD'}
        d="M13.265 11.667a.5.5 0 100-1v1zm-9.184-.432l-.13-.483.13.483zM2.184 12.52a.5.5 0 10.966.258l-.966-.258zm1.983-1.353a.5.5 0 101 0h-1zm1-9a.5.5 0 10-1 0h1zm1.5.166h2.666v-1H6.667v1zm6.166 3.5v5.334h1V5.833h-1zm-3.5 8.834H6.667v1h2.666v-1zm-6.166-3.5V5.833h-1v5.334h1zm3.5 3.5c-.957 0-1.625-.001-2.128-.07-.49-.065-.748-.185-.933-.37l-.707.707c.401.402.907.574 1.507.655.585.079 1.332.078 2.26.078v-1zm-4.5-3.5c0 .928-.001 1.675.077 2.26.081.6.254 1.106.655 1.508l.707-.708c-.184-.184-.305-.443-.37-.932-.068-.504-.07-1.171-.07-2.128h-1zm10.666 0c0 .957 0 1.624-.068 2.128-.066.489-.186.748-.37.932l.706.707c.401-.4.574-.906.655-1.506.079-.586.077-1.333.077-2.261h-1zm-3.5 4.5c.93 0 1.676 0 2.262-.078.6-.08 1.105-.253 1.506-.655l-.707-.707c-.184.185-.444.305-.933.37-.503.069-1.17.07-2.128.07v1zm0-13.334c.957 0 1.625.001 2.128.07.49.065.749.185.933.37l.707-.707c-.401-.402-.906-.574-1.506-.655-.586-.079-1.333-.078-2.262-.078v1zm4.5 3.5c0-.928.002-1.675-.077-2.26-.08-.6-.254-1.106-.655-1.507l-.707.707c.185.184.305.443.37.932.068.504.07 1.171.07 2.128h1zm-7.166-4.5c-.929 0-1.676 0-2.261.078-.6.08-1.106.253-1.507.655l.707.707c.185-.185.444-.305.933-.37.503-.069 1.17-.07 2.128-.07v-1zm-3.5 4.5c0-.957 0-1.624.069-2.128.065-.489.186-.748.37-.932L2.9 2.066c-.401.4-.574.906-.655 1.506-.078.586-.077 1.333-.077 2.261h1zm2.098 5.834h8v-1h-8v1zm0-1c-.588 0-.98-.005-1.313.085l.258.966c.175-.047.403-.051 1.055-.051v-1zm-1.313.085a2.5 2.5 0 00-1.768 1.768l.966.258a1.5 1.5 0 011.06-1.06l-.258-.966zm1.215.415v-9h-1v9h1z"
      ></Path>
    </Svg>
  ), [props.width, props.height]);

  return svgContent;
}

export default PatientEducationSvg;
