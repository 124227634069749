import {
  View,
  Text,
  ScrollView,
  Center,
  Spinner,
  Pressable,
  Actionsheet,
  Box,
  Popover as NativePopover,
} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';

import React, {useState, useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Colors} from '../../../../../../styles';
import {BUTTON_TYPE, GROUP_MEMBER_TYPE} from '../../../../../../constants';
import {Popover} from 'antd';
import {useLazyQuery, useQuery} from '@apollo/client';
import {GET_ELATION_USERS_BY_EXTERNAL_USER_ID, GET_ELATION_USERS_HAVING_EXT_USER_IDS} from '../../../../../../services/User/UserQueries';
import {CARESTUDIO_PROXY_TO_CRM_CONTEXT} from '../../../../../../constants/Configs';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {debounce} from 'lodash';
import {IUser} from '../../../../../../Interfaces';
import {Dimensions, FlatList, Keyboard} from 'react-native';
import {DisplayCardAvatar} from '../../../../../common/DisplayCard/DisplayCardAvatar';
import {SearchBar} from '../../../../../common/SearchBar';
import {FoldButton} from '../../../../../CommonComponents/FoldButton/FoldButton';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import {CustomScrollView} from '../../../../../common/ScrollView/CustomScrollView';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {StyleSheet} from 'react-native';
import SelectDownArrowSvg from '../../../../../common/Svg/SelectDownArrowSvg';
import { IAction } from '../../../interfaces';
import SignIconSvg from '../../../../../common/Svg/SignIconSvg';
import { SaveType } from '../../AddOrUpdatePatientNote';

interface ProviderSelectionForNoteSaveProps {
  loading: boolean;
  disabled: boolean;
  locationGroupId: string | undefined;
  isSidecarContext?: boolean;
  isAllowToSign?: boolean;
  onHeaderAction: (action: string, user?: IUser) => void;
  signOnlyMode?: boolean;
  authorUUID?: string;
  isloggedInUserMappedToEHR?: boolean;
  isSimpleUserSelection?: boolean;
}
const width = Dimensions.get('window').width;

const styles = StyleSheet.create({
  pressableContainer: {
    width: 25,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userInfoMessage: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    padding: 8,
    marginHorizontal: 16,
    marginBottom: 8,
  },
  itemContainer: {
    marginLeft: isWeb() ? 10 : 8,
    width: isWeb() ? '80%' : '100%',
  },
  mainListView: {
    flexGrow: 1,
  },
  mainListView1: {
    flexGrow: 1,
    width: isWeb() ? undefined : width - 20,
  },
  searchContainer: {
    paddingX: 4,
    paddingTop: 4,
  },
  noUserFoundText: {
    textAlign: 'center',
    color: Colors.Custom.Gray500,
    fontWeight: '400',
    fontSize: 12,
    marginVertical: 5,
  },
  featherIcon: {
    alignSelf: 'center',
    paddingRight: isWeb() ? 4: 0,
    padding: isWeb() ? 5 : 0
  },
  selectDownArrowContainer: {
    width: 12,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionMenuContent: {
    backgroundColor: 'white',
    borderRadius: 16,
    width: 140,
    padding: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 5,
    maxWidth: isWeb() ? '95vw' : '95%',
    position: 'relative',
    right: isWeb() ? 0 : 8,
  }
});

const antdStyles: Record<string, React.CSSProperties> = {
  popoverInnerStyle: {
    borderRadius: 16,
  },
};

interface ProviderSelectionState {
  isOpen: boolean;
  isSubMenuOpen: boolean;
  isShowAction: boolean;
  selectedAction: string;
}

const ProviderSelectionForNoteSave = (
  props: ProviderSelectionForNoteSaveProps
) => {
  const {loading, disabled, locationGroupId, isSidecarContext, isAllowToSign, signOnlyMode} = props;
  const intl = useIntl();
  const [state, setState] = useState<ProviderSelectionState>({
    isOpen: false,
    isSubMenuOpen: false,
    isShowAction: false,
    selectedAction: '',
  });
  const userQuery = useQuery<{
    users: IUser[];
  }>(GET_ELATION_USERS_HAVING_EXT_USER_IDS, {
    fetchPolicy: 'no-cache',
    variables: {
      name: '%%',
      locationGroupId: locationGroupId,
    },
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
    },
  });

  const [userQueryByExternalUserId, dataByExternalUserId] = useLazyQuery<{
    users: IUser[];
  }>(GET_ELATION_USERS_BY_EXTERNAL_USER_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
    },
  });

  useEffect(() => {
    if (props.authorUUID) {
      userQueryByExternalUserId({
        variables: {
          externalUserId: props.authorUUID,
          locationGroupId: locationGroupId,
        },
      });
    }
  }, [props.authorUUID]);

  const toggle = useCallback((force?: boolean) => {
    setState(prev => ({
      ...prev,
      isOpen: force !== undefined ? force : !prev.isOpen,
    }));
  }, []);

  const toggleSubMenu = useCallback((force?: boolean) => {
    setState(prev => ({
      ...prev,
      isSubMenuOpen: force !== undefined ? force : !prev.isSubMenuOpen,
    }));
  }, []);

  const toggleShowAction = useCallback((force?: boolean) => {
    setState(prev => ({
      ...prev,
      isShowAction: force !== undefined ? force : !prev.isShowAction,
    }));
  }, []);

  const handleUserSelection = (user: IUser, selectedActionParam?: string) => {
    if (props.isSimpleUserSelection) {
      props.onHeaderAction(SaveType.SAVE, user);
    }
    const selectedAction = selectedActionParam || state.selectedAction;

    if (!!selectedAction) {
      props.onHeaderAction(selectedAction, user);
    }
    setState(prev => ({
      ...prev,
      isOpen: false,
      isShowAction: false,
      isSubMenuOpen: false,
      selectedAction: '',
    }));
  };

  const renderUserInfoMessage = () => (
    <Stack direction="row" style={styles.userInfoMessage}>
      <Feather name="info" size={16} color={Colors.Custom.Gray500} />
      <Text
        pl={1}
        fontSize={12}
        textAlign={isWeb() ? undefined : 'center'}
        color={Colors.Custom.Gray500}
      >
        {props.authorUUID ? intl.formatMessage({
          id: 'theSelectedProviderWillBeTheSignedByOfTheCurrentNote',
        }) : intl.formatMessage({
          id: 'theSelectedProviderWillBeTheAuthorOfTheCurrentNote',
        })}
      </Text>
    </Stack>
  );

  const renderItem = React.useCallback(
    ({item}: {item: IUser}) => {
      const roleString = item?.userRoles
        ?.map((role) => role?.userRole?.userRole?.value)
        .filter((role) => !!role?.length)
        .join(', ');
      return (
        item?.externalUserMap?.[0]?.externalUserId ?
        <Pressable
          _hover={{
            backgroundColor: Colors.Custom.Gray100,
          }}
          _pressed={{
            backgroundColor: Colors.Custom.Gray100,
          }}
          rounded={'md'}
          flexDir={'row'}
          mb={2}
          p={2}
          alignItems={'flex-start'}
          onPress={() => {
            toggle();
            const user:IUser = {
              ...item,
              externalUserId: item?.externalUserMap?.[0]?.externalUserId,
            }
            handleUserSelection(user);
          }}
        >
          <DisplayCardAvatar
            avatarStyle={{
              width: 36,
              height: 36,
              disableTop: true,
              textStyle: {
                fontSize: 16,
              },
            }}
            userData={{
              userType: GROUP_MEMBER_TYPE.USER,
              userId: item?.userId || '',
              name: item?.name,
              userName: item?.name,
              imgSrc: '',
            }}
            isLetterAvatarShow
          />
          <Stack direction='column' style={styles.itemContainer}>
            <Text color={Colors.Custom.Gray700} fontWeight={'400'}>
              {item?.name}
            </Text>
            <Text
              fontSize={12}
              fontWeight={'400'}
              color={Colors.Custom.Gray500}
              maxW={'90%'}
              isTruncated
            >
              {roleString}
            </Text>
          </Stack>
        </Pressable>
        : <></>
      );
    },
    [state.selectedAction]
  );

  const handleActionPress = (item: IAction) => {
    if(props.isloggedInUserMappedToEHR){
      props.onHeaderAction(SaveType.SAVE_AND_SIGN);
      return;
    }
    setState(prev => ({
      ...prev,
      selectedAction: item.action,
    }));
    if (isWeb()) {
      toggleSubMenu(true);
    } else {
      toggleShowAction(false);
      toggle(true);
    }
  };

  const renderActionMenu = () => {
    const actions: IAction[] = [];
    if(props.isAllowToSign){
      actions.push({
        btnText: intl.formatMessage({
          id: SaveType.SAVE_AND_SIGN,
        }),
        icon: <View width={5} height={5}>
          <SignIconSvg />
        </View>,
        action: SaveType.SAVE_AND_SIGN,
        isDisabled: disabled,
      });
    }
    return (
      actions.length > 0 && (
        <FlatList
          data={actions}
          renderItem={({item, index}) => (
            <>
              {isWeb() ? (
                <Popover
                  overlayInnerStyle={antdStyles.popoverInnerStyle}
                  overlayClassName="note-template"
                  placement="bottom"
                  open={state.isSubMenuOpen}
                  trigger={'click'}
                  onOpenChange={toggleSubMenu}
                  content={renderMainListView()}
                >
                  <Pressable
                    disabled={item.isDisabled}
                    flexDirection="row"
                    alignItems="center"
                    my={1.5}
                    onPress={() => handleActionPress(item)}
                  >
                    {item.icon}
                    <Text
                      color={Colors.Custom.Gray500}
                      fontSize={12}
                      fontWeight="400"
                      marginLeft={4}>
                      {item.btnText}
                    </Text>
                  </Pressable>
                </Popover>
              ) : (
                <Pressable
                  disabled={item.isDisabled}
                  flexDirection="row"
                  mb={6}
                  mt={2}
                  width={"300px"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  onPress={() => handleActionPress(item)}
                >
                  {item.icon}
                  <Text
                    color={Colors.Custom.Gray500}
                    fontSize={16}
                    fontWeight="400"
                    marginLeft={4}>
                    {item.btnText}
                  </Text>
                </Pressable>
              )}
            </>
          )}
        />
      )
    );
  };

  const getButtonsPopover = () => {
    if (isWeb()) {
      return (
        <Popover
          overlayClassName="custom-popover-styles"
          showArrow={true}
          destroyTooltipOnHide
          placement="bottomRight"
          content={renderActionMenu}
          open={state.isShowAction && !loading && !disabled}
          trigger={'click'}
          onOpenChange={toggleShowAction}
          overlayInnerStyle={{
            borderRadius: 16,
            width: 140,
            padding: 0,
          }}
        >
          <Box>
            {isSidecarContext ? (
              <View style={styles.selectDownArrowContainer}>
                <SelectDownArrowSvg />
              </View>
            ) : (
              <Feather
                name={'chevron-down'}
                size={20}
                color={loading || disabled ? Colors.Custom.Gray300 : Colors.Custom.mainPrimaryPurple}
                style={styles.featherIcon}
                onPress={() => {
                  if (loading) return;
                  if (state.isOpen) {
                    toggle(false);
                  }
                  toggleShowAction(true);
                }}
              />
            )}
          </Box>
        </Popover>
      );
    }
    return (
      <Pressable
        disabled={loading}
        onPress={() => {
          Keyboard.dismiss();
          if (loading) return;
          if (state.isOpen) {
            toggle(false);
          }
          toggleShowAction(true);
        }}
        style={styles.pressableContainer}
      >
        <Feather
          name={'chevron-down'}
          size={20}
          color={loading || disabled ? Colors.Custom.Gray300 : Colors.Custom.mainPrimaryPurple}
          style={styles.featherIcon}
        />
      </Pressable>
    );
  };

  const handleSaveAction = () => {
    Keyboard.dismiss();
    if (loading) return;
    if(props.isloggedInUserMappedToEHR){
      props.onHeaderAction(SaveType.SAVE);
      return;
    }
    const selectedAction = isAllowToSign && signOnlyMode 
      ? SaveType.SAVE_AND_SIGN 
      : SaveType.SAVE;

    setState(prev => ({
      ...prev,
      selectedAction,
    }));
    toggle(true);
  }

  const renderSaveButton = () => (
    <FoldButton
      nativeProps={{
        backgroundColor: Colors.Custom.BackgroundColor,
        isDisabled: disabled,
        isLoading: loading,
        variant: BUTTON_TYPE.PRIMARY,
        onPress: handleSaveAction,
        ...(props.signOnlyMode ? {} : {
          rightIcon: loading ? (
            <Spinner size={'sm'} />
          ) : isAllowToSign ? (
            getButtonsPopover()
          ) : undefined
        }),
        minWidth: props.signOnlyMode ? 0 : (isWeb() ? 'auto' : 30),
      }}
      customProps={{
        btnText: intl.formatMessage({id: props.signOnlyMode ? 'sign' : 'save'}),
        withRightBorder: isAllowToSign && !loading && !props.signOnlyMode,
        isDisableOnPress: true,
      }}
    />
  );

  const renderMainListView = () => {
    return (
      <CustomScrollView
        style={styles.mainListView1}
      >
        <View width={isWeb() ? 350 : undefined}>
          <View paddingX={4} paddingTop={props.authorUUID ? 0 : 4}>
            {!props.authorUUID &&
              <SearchBar
                placeholderText="Search Users"
                onChange={debounce(
                  (value) =>
                    userQuery.refetch({
                      name: `%${value}%`,
                    }),
                  200
              )}
              />
            }
            <Text
              fontSize={14}
              fontWeight={'400'}
              color={Colors.Custom.Gray500}
              pt={2}
            >
              {intl.formatMessage({
                id: state.selectedAction === SaveType.SAVE_AND_SIGN ? 'signOnBehalfOf' : 'saveOnBehalfOf'
              })}
            </Text>
          </View>

          <ScrollView maxH={400} padding={2}>
            {userQuery.loading ? (
              <Center h="100%" my={2}>
                <Spinner />
              </Center>
            ) : (
              <Stack direction='column'>
                {userQuery.data?.users?.length || dataByExternalUserId?.data?.users?.length ? (
                  <FlatList
                    renderItem={renderItem}
                    data={props.authorUUID ? dataByExternalUserId?.data?.users || [] : userQuery.data?.users || []}
                    keyExtractor={(item) => item.uuid}
                  />
                ) : (
                  <Text
                    textAlign={'center'}
                    color={Colors.Custom.Gray500}
                    fontWeight={'400'}
                    fontSize={12}
                    my={5}
                  >
                   {intl.formatMessage({id: 'noUserFound'})}
                  </Text>
                )}
              </Stack>
            )}
          </ScrollView>
          {isWeb() && renderUserInfoMessage()}
        </View>
      </CustomScrollView>
    );
  };

  if (!isWeb()) {
    if(props.isSimpleUserSelection){
      return renderMainListView();
    }
    return (
      <>
        {renderSaveButton()}
        <Actionsheet isOpen={state.isOpen} onClose={() => {
          toggle(false);
          setState(prev => ({
            ...prev,
            selectedAction: '',
          }));
          }}>
          <Actionsheet.Content backgroundColor={'white'} maxH={500}>
            {renderMainListView()}
            {renderUserInfoMessage()}
          </Actionsheet.Content>
        </Actionsheet>
        <Actionsheet isOpen={state.isShowAction && !loading && !disabled} onClose={() => {
          toggleShowAction(false);
          }}>
          <Actionsheet.Content backgroundColor={'white'} maxH={500}>
            {renderActionMenu()}
          </Actionsheet.Content>
        </Actionsheet>
      </>
    );
  }

  if (props.isSimpleUserSelection) {
    return renderMainListView();
  }

  return (
    <Popover
      overlayInnerStyle={antdStyles.popoverInnerStyle}
      overlayClassName="note-template"
      placement="bottom"
      open={state.isOpen}
      onOpenChange={toggle}
      content={renderMainListView()}
    >
      {renderSaveButton()}
    </Popover>
  );
};

export default ProviderSelectionForNoteSave;
