import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles/Colors';

export const styles = StyleSheet.create({
  container: {
    padding: 12,
    paddingHorizontal: 0
  },
  formElement: {
    flex: 1,
    marginBottom: 12,
  },
  labelText: {
    marginBottom: 4,
    color: Colors.FoldPixel.GRAY250
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container2: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.FoldPixel.GRAY150,
    borderRadius: 12,
    padding: 16,
    marginBottom: 16,
    position: 'relative',
  },
  pressableStyle: {
    position: 'absolute',
    right: 12,
    top: 12,
    zIndex: 99,
    flexDirection: 'row',
    alignItems: 'center'
  }
});

export const antdStyles: Record<string, React.CSSProperties> = {
  dropdownStyle: {
    borderRadius: 20,
  },
  optionStyle: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  graph: {
    maxHeight: 200, 
    width: '100%'
  }
};
