import {Popover} from 'antd';
import moment from 'moment';
import {HStack, Link, Pressable, Skeleton, Spinner, Text, View, VStack} from 'native-base';
import React, {useContext, useState, useMemo} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import ReactPlayer from 'react-player';
import {ATTACHMENT_TYPE, DATE_FORMATS} from '../../../../constants';
import {Colors} from '../../../../styles';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {IMsgAttachment} from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {capitalizeText} from '../../ContactRelationView/ContactRelationUtils';
import {DisplayText} from '../../DisplayText/DisplayText';
import {MessageActionID} from '../../MessageAction/MessageActionView';
import MessageOptionSvg from '../../Svg/MessageOptionSvg';
import ImgAttachment from './ImgAttachment';
import {IMsgAttachmentProps} from './interfaces';
import {styles} from './MsgAttachmentStyles';
import { MessageCommunicationTypes } from '../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {getFileNameWithoutSpace} from '../../../../utils/commonUtils';
import { testID, TestIdentifiers } from '../../../../testUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import MenuHorizontalIcon from '../../Svg/MenuHorizontalIcon';
import { isImageExtension } from '../MessageBox/MessageBoxUtils';
import { StyleSheet } from 'react-native';

const MsgAttachment: React.FC<IMsgAttachmentProps> = (props) => {
  const {
    position,
    dateStr,
    senderFullName,
    isEmptyName,
    messageAction,
    getActionItemView,
    attachmentActionVisible,
    handleAttachmentPopover,
    msgWithAttachment,
    onRemoveCommunicationType
  } = props;
  let {attachments} = props;
  const [showPopover, setShowPopover] = useState(false);
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;

  const handleMorePopover = () => {
    setShowPopover(!showPopover);
  };
  const getBackgroundColor = () => {
    if (!props?.isNestedMessageEle && props?.private) {
      return Colors.Custom.brandAccentColor;
    }
    return Colors.Custom.BackgroundColor;
  }

  const getMsgAttachmentElem = (): JSX.Element => {
    const mainContainerStyle = position === 'left' ? msgStyles.leftContainer : msgStyles.rightContainer;
    const positionCls = [
      styles.rceMbox,
      position === 'left' ? styles.rceMboxLeft : styles.rceMboxRight,
      { backgroundColor: getBackgroundColor() },
    ];
    const [currentAttachement, setCurrentAttachment] = useState({id: -1});
    attachments = attachments.filter((attachment) => attachment?.id);

    const attachmentStyles = useMemo(() => StyleSheet.create({
      attachmentView: {
        alignItems: position == 'left' ? 'flex-start' : 'flex-end',
        alignSelf: position == 'left' ? 'flex-start' : 'flex-end',
        flexDirection: 'row',
        marginVertical: 4,
        marginRight: 6,
        minWidth: isSideCarContext ? 220 : position == 'left' ? 286 : 300,
        maxWidth: position == 'left' ? '77.2%' : '75.8%',
      },
      attachmentContent: {
        justifyContent: position == 'left' ? 'flex-start' : 'flex-end',
        width: '100%',
      },
      headerContainer: {
        flexDirection: 'row',
        marginLeft: position == 'left' ? 16 : 0,
        marginRight: position != 'left' ? 4 : 0,
        justifyContent: 'space-between',
        width: '100%',
      },
      pressableStyle: {
        marginLeft: position == 'left' ? 16 : 0,
        marginRight: position != 'left' ? 25 : 0,
        width: '100%',
      },
    }), [position, isSideCarContext]);

    const attachmentElemList = attachments.map((attachment, index) => {
      const key = `${attachment.id}_${index}`;
      return (
        <View
          key={key}
          style={attachmentStyles.attachmentView}
        >
          <View
            style={attachmentStyles.attachmentContent}
          >
            {props.isNestedMessageEle ? (
              <></>
            ) : (
              <View
                style={attachmentStyles.headerContainer}
              >
                <HStack
                  style={msgStyles.senderNameContainer}
                >
                  <Text
                    size={'smBold'}
                    color={Colors.Custom.GRAY350}
                    noOfLines={1}
                    {...testID(TestIdentifiers.senderName)}
                  >
                    {senderFullName}
                  </Text>
                </HStack>
                <HStack style={msgStyles.communicationTypesContainer}>
                  {props.loading ? (
                    <Spinner {...testID(TestIdentifiers.lazyLoading)}/>
                  ) : (
                    <MessageCommunicationTypes
                      types={props.communicationTypes || []}
                      isMessageBox
                      messageUuid={props.item?.uuid || ''}
                      onRemoveCommunicationType={(id) => {
                        if (onRemoveCommunicationType) {
                          onRemoveCommunicationType(id);
                        }
                      }}
                    />
                  )}
                  <View style={msgStyles.menuIconContainer}>
                    {isWeb() ? (
                      <Pressable
                        onPress={() => {
                          setCurrentAttachment(attachment);
                          handleAttachmentPopover();
                        }}
                      >
                        <Popover
                          overlayInnerStyle={antdStyles.popoverOverlay}
                          overlayStyle={{zIndex: 99}}
                          content={
                            <>
                              {messageAction?.actionModel?.actionList?.map(
                                (action: MessageActionID) => {
                                  return getActionItemView(action, attachment);
                                }
                              )}
                            </>
                          }
                          title=""
                          trigger="click"
                          placement={'bottomRight'}
                          visible={
                            attachmentActionVisible &&
                            attachment.id === currentAttachement?.id
                          }
                          onVisibleChange={() => {
                            handleAttachmentPopover();
                          }}
                        >
                          {!msgWithAttachment && (
                              <View {...testID('image')}>
                                <MenuHorizontalIcon height={18} width={18}/>
                              </View>
                          )}
                        </Popover>
                      </Pressable>
                    ) : (
                      <></>
                    )}
                  </View>
                </HStack>
              </View>
            )}
            <Pressable
              onPress={() => {
                if (props?.messageObjectCallBack) {
                  props?.messageObjectCallBack(attachment?.id);
                }
              }}
              key={attachment?.id}
              style={attachmentStyles.pressableStyle}
            >
              <View style={[styles.mainContainer, mainContainerStyle]}>
                <View style={styles.rceContainerMbox}>
                  <View style={styles.rceMboxClearPadding}>
                    <View style={styles.rceMboxBody}>
                      <View
                        style={[
                          positionCls,
                          msgStyles.attachmentBox,
                        ]}
                        {...testID(TestIdentifiers.attachmentView)}
                      >
                        {attachment?.data_url ? getElementFromAttachmentType(attachment) :
                        (
                          <Skeleton.Text
                            mx={1}
                            mt={7}
                            lines={4}
                            width={'100%'}
                        ></Skeleton.Text>
                        )}
                        {isWeb() ? (
                          <>
                            <Pressable
                              style={msgStyles.moreOptionsButton}
                              onPress={() => {
                                setCurrentAttachment(attachment);
                                handleAttachmentPopover();
                              }}
                            ></Pressable>
                          </>
                        ) : null}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </Pressable>
            <View style={msgStyles.dateContainer}>
              <Text
                {...testID(TestIdentifiers.messageDateString)}
                size={'xsMedium'}
                color={Colors.Custom.messageTimeColor}
                fontSize={12}
                fontWeight={400}
              >
                {moment(dateStr).format(DATE_FORMATS.DISPLAY_TIME_FORMAT)}
              </Text>
              {props?.readMessageStatusView}
            </View>
          </View>
        </View>
      );
    });
    return <>{attachmentElemList}</>;
  };

  const getElementFromAttachmentType = (
    attachment: IMsgAttachment
  ): JSX.Element => {
    let attachmentElem = <></>;
    const attachmentCode = attachment?.file_type;
    switch (attachmentCode) {
      case ATTACHMENT_TYPE.IMAGE:
      case /^image\/.+/i.test(attachmentCode || '') ? attachmentCode : null:
        {
          if (attachment.thumb_url) {
            attachmentElem = <ImgAttachment attachment={attachment} />;
          }
        }
        break;
      case ATTACHMENT_TYPE.VIDEO:
        {
          if (attachment.data_url) {
            attachmentElem = (
              <ReactPlayer
                width={300}
                height={200}
                controls={true}
                url={attachment.data_url}
                data-testid={'VideoPlayer'}
              />
            );
          }
        }
        break;
      case ATTACHMENT_TYPE.AUDIO:
        {
          if (attachment.data_url) {
            const fileObj = getFileExtension(attachment.data_url);
            attachmentElem = (
              <VStack space={1}>
                <Text size={'smMedium'} color={Colors.Custom.Gray700}>
                  {getFileNameWithoutSpace(fileObj.fileName)}
                </Text>
                <ReactPlayer
                  width={300}
                  height={50}
                  controls={true}
                  url={attachment.data_url}
                  data-testid={'AudioPlayer'}
                />
              </VStack>
            );
          }
        }
        break;
      case ATTACHMENT_TYPE.FILE:
        {
          const fileObj = getFileExtension(attachment.data_url);
          if (isImageExtension(fileObj.ext)) {
            if (attachment.thumb_url) {
              attachmentElem = <ImgAttachment attachment={attachment} />;
            }
          } else {
          attachmentElem = (
            <HStack flex={1}>
              <View style={msgStyles.fileIconContainer}>
                <View style={msgStyles.fileIcon} backgroundColor={Colors.primary['100'] + '7a'}>
                  <AntIcon
                    name="file1"
                    size={14}
                    color={Colors.primary['500']}
                    {...testID('image')}
                  />
                </View>
              </View>
              <VStack flex={1}>
                <Link href={attachment?.data_url} isExternal>
                  <Text {...testID('FileName')} overflow={'hidden'} size={'smMedium'} color={Colors.Custom.Gray700}>
                    {getFileNameWithoutSpace(fileObj.fileName)}
                  </Text>
                </Link>
                <HStack>
                  <Link
                    href={attachment.data_url}
                    isExternal
                    _hover={{
                      color: 'white',
                    }}
                  >
                    <DisplayText
                      textLocalId="download"
                      size={'smMedium'}
                      extraStyles={{
                        color: Colors.Custom.Gray500,
                      }}
                    />
                  </Link>
                </HStack>
              </VStack>
            </HStack>
          );
          }
        }
        break;
    }
    return attachmentElem;
  };


  const getFileExtension = (url: string): {fileName: string; ext: string} => {
    const strList = url.split('/');
    let ext = '';
    let fileNameStr = 'File';
    if (strList && strList.length) {
      const fileName = strList[strList.length - 1];
      if (fileName) {
        const stringList = fileName.split('.');
        fileNameStr = fileName;
        ext = stringList[1];
      }
    }
    return {
      fileName: fileNameStr,
      ext,
    };
  };

  return <>{getMsgAttachmentElem()}</>;
};

const msgStyles = StyleSheet.create({
  leftContainer: {
    flexDirection: 'row',
  },
  rightContainer: {
    flexDirection: 'row-reverse',
  },
  attachmentContainer: {
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    marginVertical: 4,
    marginRight: 6,
    minWidth: 220,
    maxWidth: '77.2%',
  },
  leftAttachment: {
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
  },
  rightAttachment: {
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  },
  attachmentContent: {
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerContainer: {
    flexDirection: 'row',
    marginLeft: 16,
    marginRight: 4,
    justifyContent: 'space-between',
    width: '100%',
  },
  senderNameContainer: {
    alignItems: 'center',
  },
  communicationTypesContainer: {
    alignItems: 'center',
    marginTop: 1,
  },
  menuIconContainer: {
    marginLeft: 5,
  },
  attachmentPressable: {
    marginLeft: 16,
    marginRight: 25,
    width: '100%',
  },
  attachmentBox: {
    minHeight: 60,
    borderColor: Colors.Custom.Gray50,
  },
  moreOptionsButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    zIndex: 99,
  },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  fileIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 10,
    // backgroundColor: Colors.primary['100'] + '7a',
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  popoverOverlay: {
    bottom: 3,
    borderRadius: 16,
    padding: 0,
  },
};

export default MsgAttachment;
