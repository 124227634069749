import {useLazyQuery, useMutation} from '@apollo/client';
import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor} from '@toast-ui/editor';
import {Drawer, notification, Tabs, Tooltip, Upload} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {UploadFile} from 'antd/lib/upload/interface';
import moment from 'moment';
import {
  HStack,
  Pressable,
  TextArea,
  useToast,
  View,
  VStack,
  Text,
  Center,
  Spinner
} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import {
  BUTTON_TYPE,
  getInActiveContactError,
  GROUP_TYPES,
  MLOV_CATEGORY,
} from '../../../../../../constants';
import {
  CONVERSATION_ACTION_CODES,
} from '../../../../../../constants/ActionConst';
import {SUPPORTED_EVENT_CODE} from '../../../../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import CommonService from '../../../../../../services/CommonService/CommonService';
import InboxQueries from '../../../../../../services/Inbox/InboxQueries';
import {Colors} from '../../../../../../styles/Colors';
import {
  getAccountUUID,
  getMsgEchoId,
  getUserUUID,
  isActiveContact,
  isEmailChannelInboxType,
  isEmployerRole,
  isWebChannelInboxType,
  parseHtmlToPlainText,
  findStringDifference,
  canCreateSMSInboxWithPhoneNumber,
  getPatientActiveProfiles,
  isActiveProfileMatchAllowedLocations,
  isChannelTwillioSms
} from '../../../../../../utils/commonUtils';
import {showPhiWarningToast, showToast, ToastType, validateSmsAttachment} from '../../../../../../utils/commonViewUtils';
import {getDateToMomentISOString} from '../../../../../../utils/DateUtils';
import {EventBus} from '../../../../../../utils/EventBus';
import {getMlovCodeIdObj} from '../../../../../../utils/mlovUtils';
import GroupUserMentionList from '../../../../../common/GroupUserMentionList/GroupUserMentionList';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import {NOTIFICATION_TYPES} from '../../../../../common/Notifications/NotificationsUtils';
import EducationContent from '../../../../../common/Svg/EducationContent';
import CalendarSvg from '../../../../../common/Svg/SideMenu/CalendarSvg';
import FormSvg from '../../../../../common/Svg/SideMenu/FormSvg';
import {updateSingleNotificationReadStatus} from '../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {sendEmailMessageAPI} from '../../ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../../ConversationConst';
import {ITypingStatus} from '../../interfaces';
import {AttachArticleDrawer} from '../AttachArticleDrawer';
import AttachFormDrawer from '../AttachFormDrawer';
import AttachAppointmentDrawer from '../AttachmentAppointmentDrawer/AttachAppointmentDrawer';
import CannedResponses from '../CannedResponses/CannedResponses';
import {ICannedResponses} from '../CannedResponses/interface';
import {getConversationMentionsData, getUserIdListFromMsgContent, getUserIdsFromMsgContent, sendNotificationOnMention} from '../ConversationMentions/ConversationMentions';
import {getFilteredMentionMessage, getMessageBodyWithAllParams, getSearchStringForCannedResponseForInput, getSendMsgOptionList, isGroupConversation, messageDataKeysConverter} from '../MessagingUtils';
import {hideEditorToolbar, KEY_PRESS_CODES, MSG_SEND_OPTIONS, ToastUiEditorToolbarItems} from '../MsgConst';
import {IMsgReplyDrawerProps} from './interface';
import './MsgReplyDrawerStyles.css';
import ContactSearchDrawer from '../ContactSearchDrawer';
import {MESSAGE_CUSTOM_CARD_KEY, MESSAGE_CUSTOM_CARD_KEY_NAME} from '../MessagingCustomCard/CustomCardConst';
import {sendMessageWithRawBody} from '../../../../../../services/Message/MessageService';
import { IMessageData } from '../../../../../../services/Conversations/interfaces';
import ConversationsQueries from '../../../../../../services/Conversations/ConversationsQueries';
import { IMessageResp } from '../interface';
import { getSearchStringForCannedResponseEditor } from '../CannedResponses/CannedResponsesUtils.web';
import CustomEditor from '../CustomEditor/CustomEditor';
import {cssProperties, getStyles} from './styles';
import {isFormAccessAllowed, USER_ACCESS_PERMISSION} from '../../../../UserAccess/UserAccessPermission';
import {usePermissions} from '../../../../../CustomHooks/usePermissions';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import useLoggedInUserAllowedLocationsByTab from '../../../../../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {useCustomToast} from '../../../../../Toast/ToastProvider';

export const MsgReplyDrawer = (props: IMsgReplyDrawerProps) => {
  const [topValue, setTopValue] = useState(220);
  const editorRef = useRef<Editor>(null);
  const {
    conversationData,
    onMsgSend,
    selectedInboxTypeCode,
    onMsgReplyActionPerformed,
    isDrawerVisible,
    selectedMessage,
    conversationInbox,
    contactData,
    msgText,
  } = props;
  const intl = useIntl();
  const userUUID = getUserUUID();
  const commonData = useContext(CommonDataContext);
  const isSideCarContext = commonData.sidecarContext?.isSidecar;
  const mlovDataObj = commonData.MLOV;
  const groupMemberTypeList = mlovDataObj[MLOV_CATEGORY.GroupMemberType];
  const inboxTypeFromConversation =
    conversationData?.conversationInbox?.channelType || '';
  const isSms = isChannelTwillioSms(inboxTypeFromConversation || '');
  const groupMemberCodeIdObj = getMlovCodeIdObj(groupMemberTypeList);
  const {check} = usePermissions();
  const isAppointmenetAccess = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CALENDAR
  ).hasAccess;
  const isFormAccess = isFormAccessAllowed();
  const customToast = useCustomToast();
  const allowSmsAttachment = isAccountConfigEnabled(CONFIG_CODES.ALLOW_ATTACHMENT_IN_SMS)
  const GroupTypeIs = () => {
    if (
      conversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPES.USER_BROADCAST ||
      conversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPES.PATIENT_BROADCAST ||
      conversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPES.INTERNAL
    ) {
      return false;
    } else {
      return true;
    } 
  };
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.INBOX);
  const activecontactProfiles = getPatientActiveProfiles(props?.contactData?.contactProfiles || []);
  const hasActiveProfile = isActiveProfileMatchAllowedLocations(activecontactProfiles || [], allowedLocationIds)
  const isActionsDisabled = isMsoFlagEnabled ? (!hasActiveProfile && activecontactProfiles?.length > 0) : false;
  const tooltipMessage = !isActionsDisabled ? '' :  intl.formatMessage({id :'inactiveLocationPatientActionMsg'});
  const isSendSMSAllowed = canCreateSMSInboxWithPhoneNumber(
    conversationData?.conversationInbox?.channelTwilioSms?.phoneNumber?.toString() ||
      ''
  );

  const styles = React.useMemo(() => getStyles({topValue}), [topValue]);

  const [getMessageData] = useLazyQuery<{
    message: IMessageData;
  }>(ConversationsQueries.getMessageDataId, {
    fetchPolicy: 'no-cache',
  });

  const [createConversationMentions] = useMutation(
    ConversationsQueries.createConversationMentions
  );
  const accountUUID = getAccountUUID();

  const toShowPhiWarning = () => {
    if (conversationData?.conversationInbox?.channelType) {
      const type = conversationData?.conversationInbox?.channelType || '';
      if (
        type === CHANNEL_TYPE.CHANNEL_EMAIL &&
        messagingWindowFooterState?.messageType !== 'internalMessage'
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
        messagingWindowFooterState?.messageType !== 'internalMessage'
      ) {
        return true;
      } else if (
        type === CHANNEL_TYPE.CHANNEL_WEB_WIDGET &&
        messagingWindowFooterState?.messageType !== 'internalMessage'
      ) {
        return true;
      }
      return false;
    } else {
      if (contactData?.source) {
        if (contactData?.source === 'WEB_WIDGET') {
          return true;
        }
        return false;
      }
      return false;
    }
  };

  const [updateMessageQuery] = useMutation<any>(InboxQueries.updateMessage, {
    fetchPolicy: 'no-cache',
  });

  const [messagingWindowFooterState, setMessagingWindowFooterState] = useState({
    showAgentList: false,
    privateNote: 'false',
    fileList: [] as UploadFile<any>[],
    richTextValue: '',
    uploadingMsg: false,
    isDrawerVisible: false,
    messageType: 'reply',
    isCurrentUserTyping: false,
    typingIntervalFn: null,
    articleLink: '',
    formLink: '',
    articleDrawer: false,
    contactDrawer: false,
    formDrawer: false,
    appointmentDrawer: false,
    browseContactUUID: '',
  });

  const hideCannedResponse = () => {
    isDisplayUserAgents(false);
  };

  const canShowReplyTab = () => {
    if (
      conversationData?.conversationInbox?.channelTwilioSms?.phoneNumber &&
      !isSendSMSAllowed
    ) {
      return false;
    }
    return true;
  };

  const msgToast = useToast();

  const concatRichTextValue = (value: string, concatWidth = "\n") => {
    let updatedText = messagingWindowFooterState.richTextValue;
    updatedText += concatWidth + value;

    messagingWindowFooterState.richTextValue = updatedText;
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        richTextValue: updatedText,
      };
    });

    if (editorRef?.current) {
      const inst = editorRef.current;
      inst.setMarkdown(updatedText);
    }
  };

  useEffect(() => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        richTextValue: props.selectedMessage?.displayContent
          ? props.selectedMessage?.displayContent
          : msgText || "",
      };
    });

    if (editorRef?.current) {
      const inst = editorRef.current;
      try {
        inst.setMarkdown(props.selectedMessage?.displayContent);
      } catch (e) {
      }
    }
  }, [props.selectedMessage]);

  useEffect(() => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        richTextValue: props.selectedMessage?.displayContent
          ? props.selectedMessage?.displayContent
          : props.msgText || "",
        messageType: props?.messageType ? props?.messageType : 'reply',
      };
    });
    if (editorRef?.current) {
      const inst = editorRef.current;
      try {
        inst.setMarkdown(props.selectedMessage?.displayContent || props.msgText);
      } catch (e) {
      }
    }
  }, [props.selectedMessage?.displayContent, props?.msgText]);

  useEffect(() => {
    setMessagingWindowFooterState((prev) => {
      let privateNote = 'false';
      if (props?.messageType) {
        privateNote = props?.messageType !== 'reply' ? 'true' : 'false';
      } else if (props?.selectedMessage?.id) {
        privateNote = props?.selectedMessage?.private ? 'true' : 'false';
      }
      return {
        ...prev,
        fileList: [],
        privateNote: privateNote,
        messageType:  props?.messageType ? props?.messageType : 'reply',
        showAgentList: false,
      };
    });
    if (editorRef && editorRef?.current) {
      const inst = editorRef.current;
      inst.reset();
      try {
        inst.setMarkdown(props.selectedMessage?.displayContent || props.msgText);
      } catch (e) {
      }
    }
    updateSingleNotificationReadStatus({
      type: NOTIFICATION_TYPES.CONVERSATION_UPDATED,
      typeId: conversationData.id + '',
      userId: userUUID,
      isRead: true,
    });
  }, [conversationData.id]);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const newNotification = (data: any) => {
      const singleNotification = data?.data || {};
      const conversationId = conversationData.id + '';
      if (
        singleNotification.type == NOTIFICATION_TYPES.CONVERSATION_UPDATED &&
        singleNotification.typeId === conversationId
      ) {
        updateSingleNotificationReadStatus({
          type: NOTIFICATION_TYPES.CONVERSATION_UPDATED,
          typeId: conversationId,
          userId: userUUID,
          isRead: true,
        });
      }
    };
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.NOTIFICATION_CREATED,
      newNotification
    );

    return () => {
      eventBus.removeEventListener(newNotification);
    };
  }, []);

  const showToastMsg = (isError: boolean) => {
    showToast(msgToast, intl.formatMessage({ id: isError ? 'errorOnMsgSend' : 'onAttachmentSend' }), isError ? ToastType.error : ToastType.success);
  };

  const handleSendMessage = () => {
    let plainString = '';
    if (isWebChannelInboxType(selectedInboxTypeCode || '')) {
      if (editorRef?.current) {
        const inst = editorRef.current;
        plainString = inst.getMarkdown();
      }
      const editorHtmlElemList: any = document?.querySelectorAll(
        '.msg-reply-drawer-editor .ProseMirror.toastui-editor-contents .ProseMirror-trailingBreak'
      );
      if (editorHtmlElemList?.length) {
        for (let index = 0; index < editorHtmlElemList.length; index++) {
          const element = editorHtmlElemList[index];
          if (element?.remove) {
            element.remove();
          }
        }
      }
      const editorHtmlElem: any = document?.querySelector(
        '.msg-reply-drawer-editor .ProseMirror.toastui-editor-contents'
      );
      if (editorHtmlElem && editorHtmlElem.innerHTML) {
        editorHtmlElem.innerHTML = '';
        if (editorHtmlElem.focus) {
          editorHtmlElem.focus();
        }
      }
      if (editorRef?.current) {
        const inst = editorRef.current;
        inst.reset();
      }
    } else {
      plainString = messagingWindowFooterState.richTextValue;
    }
    if (plainString && plainString.trim().length > 0 ||
      (messagingWindowFooterState.fileList && messagingWindowFooterState.fileList.length)) {
      if (props?.isUpdateMode) {
        updateMessage(plainString);
      } else {
        sendMessage(plainString);
      }
    } else {
      if (props?.isUpdateMode) {
        onMsgSend('');
      }
    }
  };

  const updateMessage = (message: string) => {
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        uploadingMsg: true,
      };
    });

    updateMessageQuery({
      variables: {
        content: message,
        messageId: props.selectedMessage.id,
        updatedAt: moment().format(),
      },
    })
      .then(async (responseMsg) => {
        if (responseMsg && responseMsg.data) {
          responseMsg.data['sender_type'] = 'user';
          props.selectedMessage.messageData.updatedAt =
            responseMsg.data?.updateMessage?.updatedAt;
          let updatedTime = responseMsg.data?.updateMessage?.updatedAt;
          if (updatedTime && (updatedTime.includes('Z') || updatedTime.includes('z'))) {
            updatedTime = updatedTime;
          } else if (updatedTime) {
            updatedTime = updatedTime + 'Z';
          } else {
            updatedTime = getDateToMomentISOString();
          }
          const responseData = {...props.selectedMessage, updatedDateStr: updatedTime};
          if (props?.conversationData?.uuid) {
            responseData.conversationUuid = props?.conversationData?.uuid;
          }
          responseData.content = message;
          await updateConversationMentionsData(responseMsg?.data?.updateMessage);
          onMsgSend(message, responseData);
          if (
            messagingWindowFooterState.fileList &&
            messagingWindowFooterState.fileList.length
          ) {
            showToastMsg(false);
          }
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              fileList: [],
              uploadingMsg: false,
              richTextValue: '',
            };
          });
          const editorHtmlElem: any = document?.querySelector(
            '.msg-reply-drawer-editor .ProseMirror.toastui-editor-contents'
          );
          if (editorHtmlElem && editorHtmlElem.innerHTML) {
            editorHtmlElem.innerHTML = '';
            if (editorHtmlElem.focus) {
              editorHtmlElem.focus();
            }
          }
          try {
            if (editorRef?.current) {
              const inst = editorRef.current;
              inst.reset();
            }
          } catch (error) {

          }
        }
      })
      .catch(() => {
        showToastMsg(true);
        setMessagingWindowFooterState((prev) => {
          return {
            ...prev,
            uploadingMsg: false,
          };
        });
      });
  };

  const chkInternal = () => {
    return (
      conversationData?.groupConversation?.groupType?.code ===
      GROUP_TYPES.INTERNAL
    );
  };

  const sendMessage = (message: string) => {
    const currentStatus = isActiveContact(contactData);
    if (!currentStatus && chkInternal() === false) {
      const message = getInActiveContactError(contactData);
      notification.error({
        message,
      });
      return;
    }
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        uploadingMsg: true,
      };
    });
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const accountId = conversationData.accountId;
    const conversationId = conversationData.displayId;
    // const htmlString = richTextValue || '';
    message = parseHtmlToPlainText(message) || '';
    message = message.replace(/\\/g, '')
    const formData = new FormData();
    formData.append('echo_id', getMsgEchoId());
    formData.append('private', messagingWindowFooterState.privateNote);
    formData.append('cc_emails', '');
    formData.append('bcc_emails', '');
    if (message) {
      // plainString = trimEnd(plainString, '\n');
      formData.append('content', message);
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          richTextValue: '',
        };
      });
    }

    if (
      messagingWindowFooterState.fileList &&
      messagingWindowFooterState.fileList.length
    ) {
      messagingWindowFooterState.fileList.forEach((fileObj) => {
        if (fileObj.originFileObj) {
          fileObj.originFileObj;
          formData.append('attachments[]', fileObj.originFileObj as any);
        }
      });
    }
    let userMentionIdStr = '';
    if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && message) {
      const userMentionIds = getUserIdListFromMsgContent(message, groupMemberCodeIdObj);
      if (userMentionIds?.length) {
        userMentionIdStr = userMentionIds.toString();
        formData.append('user_mention_ids', userMentionIdStr);
      }
    }

    if (conversationData?.conversationInbox?.channelEmail?.forwardRouteId && messagingWindowFooterState.privateNote == 'false') {
      sendEmailMessageAPI({
        private: false,
        content: '',
        conversationDisplayId: conversationData?.displayId,
        inboxId: conversationData?.inboxId,
        subject: conversationData?.additionalAttributes?.mail_subject || '',
        user_mention_ids: userMentionIdStr
      }).then(async (responseMsg: any) => {
        if (responseMsg && responseMsg.data) {
          responseMsg.data['sender_type'] = 'user';
          const messageRepData = messageDataKeysConverter(responseMsg.data);
          const currentTime = getDateToMomentISOString();
          const responseData = {...messageRepData, currentTime};
          await updateConversationMentionsData(messageRepData);
          onMsgSend(message, responseData);
          if (
            messagingWindowFooterState.fileList &&
            messagingWindowFooterState.fileList.length
          ) {
            showToastMsg(false);
          }
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              fileList: [],
              uploadingMsg: false,
              richTextValue: '',
            };
          });
          const editorHtmlElem: any = document?.querySelector(
            '.msg-reply-drawer-editor .ProseMirror.toastui-editor-contents'
          );
          if (editorHtmlElem && editorHtmlElem.innerHTML) {
            editorHtmlElem.innerHTML = '';
            if (editorHtmlElem.focus) {
              editorHtmlElem.focus();
            }
          }
          try {
            if (editorRef?.current) {
              const inst = editorRef.current;
              inst.reset();
            }
          } catch (error) {

          }
        }
      })
        .catch((error) => {
          showToastMsg(true);
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              uploadingMsg: false,
            };
          });
        });
    } else {
      axiosService
        .post(
          `/accounts/${accountId}/conversations/${conversationId}/messages`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        )
        .then(async (responseMsg: any) => {
          if (responseMsg && responseMsg.data) {
            responseMsg.data['sender_type'] = 'user';
            const currentTime = getDateToMomentISOString();
            const responseData = {...responseMsg.data, currentTime};
            await updateConversationMentionsData(responseMsg.data);
            onMsgSend(message, responseData);
            if (
              messagingWindowFooterState.fileList &&
              messagingWindowFooterState.fileList.length
            ) {
              showToastMsg(false);
            }
            setMessagingWindowFooterState((prev) => {
              return {
                ...prev,
                fileList: [],
                uploadingMsg: false,
                richTextValue: '',
              };
            });
            const editorHtmlElem: any = document?.querySelector(
              '.msg-reply-drawer-editor .ProseMirror.toastui-editor-contents'
            );
            if (editorHtmlElem && editorHtmlElem.innerHTML) {
              editorHtmlElem.innerHTML = '';
              if (editorHtmlElem.focus) {
                editorHtmlElem.focus();
              }
            }
            try {
              if (editorRef?.current) {
                const inst = editorRef.current;
                inst.reset();
              }
            } catch (error) {

            }
          }
        })
        .catch((error) => {
          showToastMsg(true);
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              uploadingMsg: false,
            };
          });
        });
    }
  };

  const toggleTypingStatus = () => {
    if (
      conversationData?.inboxId !== -1 &&
      conversationData?.conversationInbox?.id !== -1 &&
      isWebChannelInboxType(selectedInboxTypeCode || '')
    ) {
      setMessagingWindowFooterState((prev) => {
        return {
          ...prev,
          isCurrentUserTyping: true,
        };
      });

      if (!messagingWindowFooterState.isCurrentUserTyping) {
        toggleTypingStatusApi('on');
        const typingIntervalFn = setTimeout(() => {
          toggleTypingStatusApi('off');
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              isCurrentUserTyping: false,
            };
          });
        }, 2000);
        if (!messagingWindowFooterState.typingIntervalFn) {
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              typingIntervalFn: typingIntervalFn as any,
            };
          });
        }
      } else {
        if (messagingWindowFooterState.typingIntervalFn) {
          clearTimeout(messagingWindowFooterState.typingIntervalFn);
        }
      }
    }
  };

  const updateConversationMentionsData = async (messageData: IMessageResp) => {
    const userListData = getUserIdsFromMsgContent(
      messageData?.content,
      groupMemberCodeIdObj
    );

    if (userListData?.length && conversationData && messageData) {
      const messageResp = await getMessageData({
        variables: {
          messageId: messageData?.id,
        },
      })

      if (
        messageResp?.data?.message?.id === messageData?.id &&
        conversationData?.uuid &&
        conversationData?.displayId
      ) {
        const conversationMentionData = getConversationMentionsData({
          accountUUID,
          userUUID,
          msgData: messageResp?.data?.message,
          selectedConversationData: conversationData,
          userList: userListData,
        });
        const mentionResponse = await createConversationMentions({
          variables: {
            mentionDataList: conversationMentionData,
          },
        });
        await sendNotificationOnMention({
          mentionResponse:
            mentionResponse?.data?.createConversationMentions?.returning || [],
          conversationUuid: conversationData?.uuid,
          displayId: conversationData?.displayId,
        });
      }
    }
  };

  const toggleTypingStatusApi = (typingStatus: ITypingStatus) => {
    const commonService = CommonService.getCommonServiceInstance();
    const axiosService = commonService.axiosService;
    const accountId = conversationData.accountId;
    const conversationId = conversationData.displayId;
    const bodyData = JSON.stringify({
      is_private: messagingWindowFooterState.privateNote == 'true',
      typing_status: typingStatus,
    });
    axiosService
      .post(
        `/accounts/${accountId}/conversations/${conversationId}/toggle_typing_status`,
        bodyData,
        {
          data: bodyData,
          headers: {
            'content-type': 'application/json',
          },
        }
      )
      .then(() => {
      })
      .catch(() => {
      });
  };

  const isDisplayCannedResponses = () => {
    return (
      messagingWindowFooterState.richTextValue &&
      messagingWindowFooterState.richTextValue.indexOf('/') === 0
    );
  };

  const isDisplayUserAgents = (listCheck: boolean) => {
        setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        showAgentList: listCheck,
      };
    });
  };

  const previousRichTextValue = useRef("");

  const onUserAgentSelection = (userSelected: any) => {
    const inst = editorRef?.current;
    const plainString: string = (inst && inst?.getMarkdown()) || '';
    const currentText = parseHtmlToPlainText(plainString);
    const diffLocation = findStringDifference(
      currentText,
      previousRichTextValue?.current
    );
    const diffIndex = diffLocation != -1 ? diffLocation + 1 : undefined;
    const firstText = currentText?.slice(0, diffIndex);
    const updateIndex = firstText?.lastIndexOf('@');

    const searchText = currentText?.slice(updateIndex + 1, diffIndex);
    let startIndex = firstText?.length;
    let cursorPadding = 1;
    if (firstText[firstText?.length - 1] === '\n') {
      const searchTextSplit = searchText?.split('\n');
      if (searchTextSplit?.length > 0 && searchTextSplit[0]?.length == 0) {
        startIndex -= searchText?.length - 1;
        cursorPadding = searchText?.length - 1;
      } else if (searchTextSplit?.length > 2) {
        startIndex -= searchText?.length - searchTextSplit[0]?.length - 1;
        cursorPadding = searchTextSplit?.length - 2;
      } else {
        cursorPadding = 0;
      }
    }

    let replaced = currentText?.slice(0, updateIndex)?.replace(/\n/g, '<br>');
    replaced += `${userSelected}&nbsp;`;
    replaced += currentText
      ?.slice(startIndex, currentText.length)
      ?.replace(/\n/g, '<br>');

    previousRichTextValue.current = replaced;
    setMessagingWindowFooterState((prev) => {
      return {
        ...prev,
        richTextValue: replaced,
        showAgentList: false,
      };
    });
    if (editorRef?.current) {
      const inst = editorRef.current;
      const selection = inst?.getSelection();
      const searchTextLength = currentText?.slice(updateIndex, diffIndex)?.length - 2;
      const selectedUserTextLength = getFilteredMentionMessage(userSelected)?.length - 1;
      const index = selectedUserTextLength - searchTextLength;
      inst.setMarkdown(replaced);
      const selectionPosition = selection[1] as number;
      const selectionIndex = (selectionPosition) + index - 1 + cursorPadding;
      inst.setSelection(selectionIndex);
    }
  }

  const getUserListElem = () => {
    let listElem = <View></View>;
    const currentText =  parseHtmlToPlainText(messagingWindowFooterState?.richTextValue)?.split("\n")?.join(" ");
    const previousText = parseHtmlToPlainText(previousRichTextValue?.current)?.split("\n")?.join(" ");
    const diffLocation = findStringDifference(currentText, previousText)
    const diffIndex = diffLocation != -1 ? diffLocation + 1 : undefined;
    const firstText = currentText?.slice(0, diffIndex);
    const updateIndex = firstText?.lastIndexOf('@');
    const text = currentText?.slice(updateIndex, diffIndex)
    if (
      messagingWindowFooterState.showAgentList &&
      ((isGroupConversation(conversationData) &&
        conversationData?.groupConversation?.groupMembers?.length) ||
        (conversationData?.inboxId !== -1 &&
          messagingWindowFooterState?.privateNote == 'true'))
    ) {
      listElem = (
        <GroupUserMentionList
          disableTop={true}
          memberList={
            conversationData?.groupConversation?.groupMembers ||
            conversationData?.conversationInbox?.inboxMembers ||
            []
          }
          searchString={text}
          onMemberSelection={(selectedUser: any) => {
            onUserAgentSelection(selectedUser);
          }}
          hideCannedResponse={hideCannedResponse}
          selectedTab={messagingWindowFooterState?.privateNote == 'true' ? "internalMessage" : messagingWindowFooterState.messageType}
          inboxId={conversationData?.inboxId}
          conversationUuid={conversationData?.uuid}
          memberListAggergateCount={
            conversationData?.groupConversation?.groupMembers_aggregate?.aggregate?.count ||
            conversationData?.conversationInbox?.inboxMembers_aggregate?.aggregate?.count
          }
          onActionPerformed={props?.onMentionActionPerformed}
        />
      );
    }

    return listElem;
  };

  const getAttachment = () => {
    if (!props?.isUpdateMode) {
      return (
        <Tooltip title="Attachments">
          <View paddingRight={5} mt={0.5}>
            <Upload
              accept={isSms ? "image/*" : undefined}
              style={cssProperties.upload}
              multiple={true}
              beforeUpload={() => false}
              maxCount={10}
              onChange={(info) => {
                const filteredFileList = info.fileList?.filter((file) => {
                  return getValidateSmsAttachment(file);
                });
                if (filteredFileList?.length === 0) {
                  return;
                }
                const tempFileList = [
                  ...messagingWindowFooterState.fileList,
                  ...(filteredFileList || []),
                ];
                setMessagingWindowFooterState((prev) => {
                  return {
                    ...prev,
                    fileList: tempFileList,
                    isDrawerVisible: true,
                  };
                });
              }}
              fileList={[]}
              className="msg-attachment"
            >
              {conversationData?.groupConversation?.groupType?.code ===
                GROUP_TYPES.USER_BROADCAST ||
                conversationData?.groupConversation?.groupType?.code ===
                GROUP_TYPES.INTERNAL ? (
                <Icon name="paperclip" size={18} color={Colors.Custom.Gray500} />
              ) : (
                <>
                  <Icon name="paperclip" size={18} color={Colors.Custom.Gray500} />
                </>
              )}
            </Upload>
          </View>
        </Tooltip>
      );
    } else {
      return <View></View>;
    }
  };

  const getBrowseFormsElem = () => {
    return (
      <Tooltip title={isActionsDisabled ? tooltipMessage :"Browse Forms"}>
        <Pressable
          paddingRight={5}
          onPress={() => {
            if(isActionsDisabled) return;
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              formDrawer: true,
            });
          }}
        >
          <View display={'inline'} size={18}>
            <FormSvg defaultColor={isActionsDisabled ? Colors.FoldPixel.GRAY100 : Colors.Custom.Gray500} />
          </View>
        </Pressable>
      </Tooltip>
    );
  };

  const getBrowseArticlesElem = () => {
    return (
      <Tooltip title="Browse Articles">
        <Pressable
          paddingRight={5}
          onPress={() => {
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              articleDrawer: true,
            });
          }}
        >
          {/* <MaterialIcons name="article" size={20} /> */}
          <View display={'inline'} size={18}>
            <EducationContent defaultColor={Colors.Custom.Gray500} />
          </View>
        </Pressable>
      </Tooltip>
    );
  };

  const getSendAppointmentLinkElem = () => {
    return (
      <Tooltip title={isActionsDisabled ? tooltipMessage :"SendAppointment Link"}  >
        <Pressable
          paddingRight={5}
          onPress={() => {
            if (isActionsDisabled) return;
            const currentStatus = isActiveContact(contactData);
            if (!currentStatus) {
              const message = getInActiveContactError(contactData);
              notification.error({
                message,
              });
              return;
            }
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              appointmentDrawer: true,
            });
          }}
        >
          <View display={'inline'} size={18}>
            <CalendarSvg defaultColor={ isActionsDisabled ? Colors.FoldPixel.GRAY100 : Colors.Custom.Gray500} />
          </View>
        </Pressable>
      </Tooltip>
    );
  };

  const getBrowseContact = () => {
    if (!isEmployerRole()) {
      return (
        <Tooltip title="Browse Contact">
          <Pressable
            paddingRight={5}
            onPress={() => {
              setMessagingWindowFooterState({
                ...messagingWindowFooterState,
                contactDrawer: true,
              });
            }}
          >
            <View display={'inline'} size={18} mt={0.5}>
              <Icon name="contacts" size={18} color={Colors.Custom.Gray500} />
            </View>
          </Pressable>
        </Tooltip>
      );
    } else {
      <></>
    }
  };

  const getSendMsgOptionElemFromCode = (singleMsgOptionCode: string) => {
    switch (singleMsgOptionCode) {
      case MSG_SEND_OPTIONS.BROWSE_ATTACHMENTS:
        return getAttachment();
      case MSG_SEND_OPTIONS.BROWSE_FORMS:
        return getBrowseFormsElem();
      case MSG_SEND_OPTIONS.BROWSE_ARTICLES:
        return getBrowseArticlesElem();
      case MSG_SEND_OPTIONS.SEND_APPOINTMENT_LINK:
        return getSendAppointmentLinkElem();
      case MSG_SEND_OPTIONS.BROWSE_CONTACT:
        return getBrowseContact();
    }
  };

  const getAttachmentContentElem = () => {
    let sendMsgOptionList = getSendMsgOptionList(
      selectedInboxTypeCode || '',
      conversationData,
      conversationInbox,
      {allowSmsAttachment}
    );
    if (!isFormAccess) {
      sendMsgOptionList = sendMsgOptionList.filter(
        (option: string) => option !== MSG_SEND_OPTIONS.BROWSE_FORMS
      );
    }
    if (!isAppointmenetAccess) {
      sendMsgOptionList = sendMsgOptionList.filter(
        (option: string) => option !== MSG_SEND_OPTIONS.SEND_APPOINTMENT_LINK
      );
    }
    if (sendMsgOptionList.length > 0) {
      return (
        // alignItems={'center'} justifyContent={'center'}
        <HStack mt={4} alignItems={'inherit'}>
          {sendMsgOptionList.map((singleMsgOptionCode) => {
            return getSendMsgOptionElemFromCode(singleMsgOptionCode);
          })}
        </HStack>
      );
    }
  };

  const getMarginLeft = () => {
    if (messagingWindowFooterState?.messageType === 'reply') {
      return 4;
    }
    return 380;
  };

  const getSearchString = () => {
    let searchText = messagingWindowFooterState.richTextValue || '';
    if (
      isWebChannelInboxType(selectedInboxTypeCode || '') ||
      isEmailChannelInboxType(selectedInboxTypeCode || '') ||
      messagingWindowFooterState?.privateNote == 'true'
    ) {
      searchText = getSearchStringForCannedResponseEditor(editorRef);
    } else {
      searchText = getSearchStringForCannedResponseForInput(messagingWindowFooterState.richTextValue || '');
    }
    return searchText;
  };

  const handleBeforeImageUpload = (blob: any, callback: any) => {
    // Prevent image from being uploaded
    return false;
  };

  const getMSgReplyBodyElem = () => {
    return (
      <View justifyContent={'start'}>
        <VStack>
          {toShowPhiWarning?.() && (
            <View px={2} mt={2} mb={2}>
              {showPhiWarningToast(
                intl.formatMessage({
                  id: 'phiWarning',
                })
              )}
            </View>
          )}

          <View mb={14}>
            <View style={styles.messageReplyBodyElemView}>
              <Content
                className={`messagewindow ${
                  messagingWindowFooterState?.messageType !== 'reply'
                    ? 'internalMessage'
                    : ''
                }`}
              >
                <HStack flex={1} justifyContent={'space-between'}>
                  {props.showMessageTypeTab ? (
                    <VStack>
                      <Tabs
                        onTabClick={(tabCode: string) => {
                          let isInternalMessage = false;
                          setMessagingWindowFooterState((prev) => {
                            return {
                              ...prev,
                              messageType: tabCode,
                            };
                          });

                          if (tabCode === 'reply') {
                            let formattedText =
                              messagingWindowFooterState?.richTextValue;
                            if (
                              props.selectedInboxTypeCode ==
                              CHANNEL_TYPE_CODE.CHANNEL_TWILIO_SMS
                            ) {
                              formattedText =
                                getFilteredMentionMessage(formattedText);
                            }
                            setMessagingWindowFooterState((prev) => {
                              return {
                                ...prev,
                                richTextValue: formattedText,
                                privateNote: 'false',
                              };
                            });
                          } else {
                            isInternalMessage = true;
                            setMessagingWindowFooterState((prev) => {
                              return {
                                ...prev,
                                privateNote: 'true',
                                showAgentList: false,
                              };
                            });
                          }
                        }}
                        activeKey={messagingWindowFooterState?.messageType}
                        style={cssProperties.tab}
                      >
                        {canShowReplyTab() && (
                          <Tabs.TabPane
                            style={cssProperties.tabPane}
                            tab={'Reply'}
                            key={'reply'}
                          />
                        )}
                        {GroupTypeIs() && (
                          <Tabs.TabPane
                            style={cssProperties.tabPane}
                            tab={
                              <HStack space={1} style={styles.tabContainer}>
                                <Text
                                  color={
                                    messagingWindowFooterState?.messageType ===
                                    'reply'
                                      ? Colors.Custom.Gray500
                                      : Colors.secondary['500']
                                  }
                                >
                                  Internal
                                </Text>
                              </HStack>
                            }
                            key={'internalMessage'}
                          />
                        )}
                      </Tabs>
                    </VStack>
                  ) : null}
                </HStack>
              </Content>
            </View>
            <View style={styles.editorContainer}>
              <View mr={4} style={styles.editorView}>
                {isWebChannelInboxType(selectedInboxTypeCode || '') ||
                isEmailChannelInboxType(selectedInboxTypeCode || '') ||
                messagingWindowFooterState?.privateNote == 'true' ? (
                  <Content
                    className={`msg-reply-drawer-editor ${
                      messagingWindowFooterState?.messageType !== 'reply'
                        ? 'internalMessage-editor'
                        : ''
                    }`}
                  >
                    <CustomEditor
                      initialValue={messagingWindowFooterState.richTextValue}
                      height="420px"
                      initialEditType="wysiwyg"
                      useCommandShortcut={true}
                      placeholder="Type message here"
                      previewHighlight={false}
                      customRef={editorRef}
                      toolbarItems={
                        hideEditorToolbar
                          ? [[]]
                          : ToastUiEditorToolbarItems.toolbar
                      }
                      hooks={{
                        addImageBlobHook: handleBeforeImageUpload,
                      }}
                      onKeydown={(editorType: any, event: KeyboardEvent) => {
                        const keyCode = event.which || event.keyCode;
                        // if (keyCode === 8) {
                        //   isDisplayUserAgents(false);
                        // }
                        if (
                          (keyCode === 37 ||
                            keyCode === 38 ||
                            keyCode === 39 ||
                            keyCode === 40) &&
                          messagingWindowFooterState.showAgentList
                        ) {
                          event.preventDefault();
                          event.stopPropagation();
                          return;
                        }
                        if (keyCode === 27 || keyCode === 32) {
                          isDisplayUserAgents(false);
                        }
                        if (keyCode === 50 && event.key !== '2') {
                          isDisplayUserAgents(true);
                        }
                        if (
                          keyCode === 13 &&
                          !event.shiftKey &&
                          !messagingWindowFooterState.showAgentList
                        ) {
                          event.preventDefault();
                          event.stopPropagation();
                        }
                        if (
                          isDisplayCannedResponses() &&
                          (event.key === KEY_PRESS_CODES.ARROW_UP ||
                            event.key === KEY_PRESS_CODES.ARROW_DOWN ||
                            event.key === KEY_PRESS_CODES.ENTER)
                        ) {
                          event.preventDefault();
                          return;
                        }
                      }}
                      onKeyup={(editorType: any, event: KeyboardEvent) => {
                        const keyCode = event.which || event.keyCode;
                        return;
                        if (
                          keyCode === 13 &&
                          !event.shiftKey &&
                          !messagingWindowFooterState.showAgentList
                        ) {
                          event.preventDefault();
                          event.stopPropagation();
                        }
                      }}
                      onChange={() => {
                        if (editorRef && editorRef?.current) {
                          const inst = editorRef?.current;
                          const plainString: string =
                            (inst && inst.getMarkdown()) || '';
                          const currentText =
                            parseHtmlToPlainText(plainString) || '';
                          let len = 1;
                          currentText.split('\n').forEach((ele: string) => {
                            if (ele.length > 82) {
                              len = len + Math.ceil(ele.length / 82);
                            } else {
                              len++;
                            }
                          });
                          const topVal = 220 + 20 * len;
                          setTopValue(topVal > 490 ? 490 : topVal);

                          const includeAt = currentText?.slice(
                            currentText?.lastIndexOf('@')
                          );
                          if (!plainString.length) {
                            isDisplayUserAgents(false);
                          } else if (!includeAt.includes('@')) {
                            isDisplayUserAgents(false);
                          }

                          previousRichTextValue.current =
                            messagingWindowFooterState.richTextValue;
                          setMessagingWindowFooterState((prev) => {
                            return {
                              ...prev,
                              richTextValue: currentText,
                            };
                          });
                          toggleTypingStatus();
                        }
                      }}
                    />
                  </Content>
                ) : (
                  <View mt={10} padding={2}>
                    <TextArea
                      defaultValue={getFilteredMentionMessage(
                        messagingWindowFooterState?.richTextValue
                      )}
                      variant={'outline'}
                      value={getFilteredMentionMessage(
                        messagingWindowFooterState?.richTextValue
                      )}
                      style={styles.textArea}
                      height={120}
                      width={'100%'}
                      autoComplete="off"
                      multiline={true}
                      onKeyPress={(event: any) => {
                        const keyCode = event.which || event.keyCode;
                        if (keyCode === 13 && !event.shiftKey) {
                          event.preventDefault();
                          event.stopPropagation();
                          handleSendMessage();
                        }
                      }}
                      onChangeText={(plainString) => {
                        setMessagingWindowFooterState((prev) => {
                          return {
                            ...prev,
                            richTextValue: plainString,
                          };
                        });
                      }}
                    />
                  </View>
                )}
                <View
                  position={'absolute'}
                  ml={
                    !hideEditorToolbar
                      ? !isWebChannelInboxType(selectedInboxTypeCode || '') &&
                        !isEmailChannelInboxType(selectedInboxTypeCode || '')
                        ? getMarginLeft()
                        : 380
                      : 10
                  }
                >
                  {getAttachmentContentElem()}
                </View>
              </View>
            </View>
          </View>
        </VStack>
      </View>
    );
  };
const closeModal = () => {
    let plainString = '';
    let cursorPosition = -1;
    if (editorRef?.current) {
      const inst = editorRef.current;
      plainString = inst.getMarkdown();
      const cursorSelectionArr = inst.getSelection();
      if (cursorSelectionArr?.length) {
        cursorPosition = cursorSelectionArr[0] as number;
      }
      onMsgReplyActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE, {
        content: plainString,
        cursorPosition: cursorPosition
      });
    } else {
      plainString = messagingWindowFooterState.richTextValue;
      onMsgReplyActionPerformed(CONVERSATION_ACTION_CODES.DRAWER_CLOSE, {
        content: plainString,
      });
    }
  };
  const {width} = Dimensions.get('window');
  const finalWidth = isSideCarContext ? '100%': parseInt('' + (40 / 100) * width);

  const sendContactCard = async (messageCustomCardKey: string) => {
    if (messagingWindowFooterState?.browseContactUUID) {
      const accountId = conversationData.accountId;
      const conversationId = conversationData.displayId;

      const messageBodyWithAllParams = getMessageBodyWithAllParams(
        messageCustomCardKey as MESSAGE_CUSTOM_CARD_KEY_NAME,
        accountId,
        conversationId,
        messagingWindowFooterState.browseContactUUID,
        messagingWindowFooterState.privateNote
      );

      let userMentionIdStr = '';
      if (groupMemberCodeIdObj && Object.keys(groupMemberCodeIdObj)?.length && messageBodyWithAllParams?.messageBody?.content) {
        const userMentionIds = getUserIdListFromMsgContent(messageBodyWithAllParams?.messageBody?.content, groupMemberCodeIdObj);
        if (userMentionIds?.length) {
          userMentionIdStr = userMentionIds.toString();
          if (userMentionIdStr) {
            messageBodyWithAllParams.messageBody.user_mention_ids = userMentionIdStr;
          }
        }
      }

      const messageResp = await sendMessageWithRawBody(
        messageBodyWithAllParams
      );
      if (messageResp) {
        try {
          setMessagingWindowFooterState({
            ...messagingWindowFooterState,
            contactDrawer: false,
            browseContactUUID: '',
          });
          if (messageResp) {
            const currentTime = getDateToMomentISOString();
            const responseData = {...messageResp.data, currentTime};
            onMsgSend(messageResp.data.content, responseData);
          }
        } catch {
          (err: any) => {
          }
        }
      }
    }
  };
  const getValidateSmsAttachment = (file: UploadFile) => {
   return validateSmsAttachment(file, isSms ?? false, customToast, intl);
  };

  return (
    <Drawer
      visible={
        props?.isUpdateMode
          ? isDrawerVisible && selectedMessage
          : isDrawerVisible
      }
      width={finalWidth}
      mask={isSideCarContext ? false : true}
      onClose={() => closeModal()}
      style={cssProperties.drawer}
      title={<ModalActionTitle
        title={
          props?.isUpdateMode
            ? 'update'
            : props?.isInternalNote
            ? 'internalNote'
            : 'reply'
        }
        titleColor={''}
        buttonList={[
          {
            show: true,
            id: 1,
            btnText: isSideCarContext ? 'back' : 'cancel',
            textColor: Colors.Custom.mainSecondaryBrown,
            variant: BUTTON_TYPE.SECONDARY,
            isTransBtn: false,
            onClick: () => {
              closeModal();
            },
          },
          {
            show: true,
            id: 2,
            btnText: props?.isUpdateMode ? 'update' : 'send',
            textColor: Colors.Custom.mainPrimaryPurple,
            variant: BUTTON_TYPE.PRIMARY,
            isTransBtn: false,
            onClick: () => {
              handleSendMessage();
            },
          },
        ]}
      />}
    >
      {messagingWindowFooterState.uploadingMsg && (
        <View
          height={'full'}
          width={'full'}
          position="absolute"
          zIndex={10}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      {getMSgReplyBodyElem()}
        <Upload
          className="attachment-drawer upload-list-inline"
          listType="picture"
          style={cssProperties.upload}
          multiple={true}
          accept={isSms ? "image/*" : undefined}
          beforeUpload={() => false}
          onChange={(info) => {
            const tempFileList = [...info.fileList];
            setMessagingWindowFooterState((prev) => {
              return {
                ...prev,
                fileList: tempFileList,
              };
            });
          }}
          onRemove={(file) => {
            setMessagingWindowFooterState((prev) => {
              const index = prev.fileList.indexOf(file);
              const newFileList = prev.fileList.slice();
              newFileList.splice(index, 1);
              return {
                ...prev,
                fileList: newFileList,
              };
            });
          }}
          fileList={messagingWindowFooterState.fileList}
        ></Upload>
      <View
        style={styles.cannedResponseContainer}
      >
        {isDisplayCannedResponses() ? (
          <View>
            <CannedResponses
              searchString={getSearchString()}
              onCannedResponsesSelection={(
                cannedResponseData: ICannedResponses
              ) => {
                const finalText = `${cannedResponseData.content}${(
                  messagingWindowFooterState.richTextValue || ''
                ).replace(getSearchString(), '')}`;

                setMessagingWindowFooterState((prev) => {
                  return {
                    ...prev,
                    richTextValue: finalText,
                  };
                });
                if (editorRef?.current) {
                  const inst = editorRef.current;
                  const currentText = inst.getMarkdown();
                  const finalText =
                    `${cannedResponseData.content}${currentText}`.replace(
                      getSearchString(),
                      ''
                    );
                  inst.setMarkdown(finalText);
                  inst.moveCursorToStart(true);
                }
              }}
            />
          </View>
        ) : (
          <View></View>
        )}
        {getUserListElem()}
      </View>
      <AttachArticleDrawer
        isDrawerVisible={messagingWindowFooterState.articleDrawer}
        privateNote={messagingWindowFooterState.privateNote}
        updateMessage={concatRichTextValue}
        isUpdateMode={props?.isUpdateMode || props?.isReplyMode}
        onClose={() => {
          setMessagingWindowFooterState((prev) => {
            return {
              ...prev,
              articleDrawer: false,
            };
          });
        }}
        conversationData={conversationData}
        onMsgSend={onMsgSend}
        onArticleSelect={(articleLink: string) => {
          if (editorRef?.current) {
            const inst = editorRef.current;
            inst.setMarkdown(articleLink);
          }

          setMessagingWindowFooterState(prev=>{
            return {
              ...prev,
              articleLink: articleLink,
              articleDrawer: false,
              richTextValue: articleLink,
            }
          });
        }}
      />
      <AttachFormDrawer
        conversationData={conversationData}
        onMsgSend={onMsgSend}
        updateMessage={concatRichTextValue}
        isUpdateMode={props?.isUpdateMode || props?.isReplyMode}
        isOpen={messagingWindowFooterState.formDrawer}
        privateNote={messagingWindowFooterState.privateNote}
        onClose={() => {
          setMessagingWindowFooterState(prev=>{
            return {
              ...prev,
              formDrawer: false,
            }
          });
        }}
        onFormSelect={(formLink: any) => {
          if (editorRef?.current) {
            const inst = editorRef.current;
            inst.setMarkdown(formLink);
          }

          setMessagingWindowFooterState(prev=>{
            return {
              ...prev,
              formLink: formLink,
              richTextValue: formLink,
              formDrawer: false,
            }
          });
        }}
      />
      {messagingWindowFooterState.appointmentDrawer && (
        <AttachAppointmentDrawer
          isOpen={messagingWindowFooterState.appointmentDrawer}
          isUpdateMode={props?.isUpdateMode || props?.isReplyMode}
          conversationData={conversationData}
          onClose={() => {
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              appointmentDrawer: false,
            });
          }}
          onSendMessage={(appointmentMessage: string) => {
            if (props?.isUpdateMode || props?.isReplyMode) {
              concatRichTextValue(appointmentMessage);
            } else {
              sendMessage(appointmentMessage);
            }
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              appointmentDrawer: false,
            });

            if (
              (props?.isUpdateMode && !props?.isUpdateMode) ||
              (props?.isReplyMode && !props?.isReplyMode)
            ) {
              closeModal();
            }
          }}
        />
      )}

      {messagingWindowFooterState.contactDrawer && (
        <ContactSearchDrawer
          onMsgSend={() => {
            sendContactCard(MESSAGE_CUSTOM_CARD_KEY.CONTACT_CARD);
          }}
          onFormCompleteAction={(actionCode: string) => {
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              contactDrawer: false,
              browseContactUUID: '',
            });
          }}
          onAutoCompleteChange={(action: any, data: any) => {
            setMessagingWindowFooterState({
              ...messagingWindowFooterState,
              browseContactUUID: data,
            });
          }}
        />
      )}
    </Drawer>
  );
};

export default MsgReplyDrawer;
