import { useContext} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {useLazyQuery} from '@apollo/client';
import TaskPoolQueries from '../../../services/TaskPool/TaskPoolQueries';
import {getUserUUID} from '../../../utils/commonUtils';
import {getMlovId} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';

export const useTaskManageAccess = () => {
  const loggedInUserUuid = getUserUUID();
  const mlovData = useContext(CommonDataContext);
  const manageAccessUsersTaskPools = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'UserPoolType',
    'access_user_task'
  );

  const [getUserTaskAccess] = useLazyQuery(
    TaskPoolQueries.GetUsersTaskPoolAccess,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        userId: [loggedInUserUuid],
        userPoolTypeId: manageAccessUsersTaskPools,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const getLoggedInUserTaskAccessUsers = async () => {
    let manageAccessUsers = [];
    try {
      const response = await getUserTaskAccess();
      if (response?.data?.userPoolAccesses?.length) {
        manageAccessUsers =
          response?.data?.userPoolAccesses?.[0]?.user_pool?.userPoolUsers?.map(
            (user: any) => user?.userId
          ) || [];
      }
    } catch (error) {
      manageAccessUsers = [];
    }

    // null is added to handle tasks without assignees and logged in user uuid is added to handle tasks assigned to logged in user
    manageAccessUsers.push(loggedInUserUuid);
    manageAccessUsers.push(null);
    return {
      data: manageAccessUsers,
    };
  };
  
  return {getLoggedInUserTaskAccessUsers};
};
