import { Coding, Extension, HumanName, Observation, Patient } from 'fhir/r4';
import moment from 'moment';
import {
  MLOV_CATEGORY,
  PERSON_CONTACT_TYPE_CODES
} from '../../../../../../constants';
import {
  CONTACT_SOURCE_OF_CREATION,
  DISPLAY_DATE_FORMAT,
  PATIENT_QUERRY_DATE_FROMATED,
  SEXUAL_ORIENTATION_OTHER_CODE
} from '../../../../../../constants/StringConst';
import { ICommonData } from '../../../../../../context/CommonDataContext';
import { IMlov, IPersonContact } from '../../../../../../Interfaces';
import {
  getEmailContactObjFromList,
  getPhoneContactObjFromList
} from '../../../../../../utils/commonUtils';
import { getDateStrFromFormat } from '../../../../../../utils/DateUtils';
import {
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../../../../../utils/mlovUtils';
import { toObservationFhirItem } from '../../../../../../utils/VitalUtils';
import { IObservation } from '../../../../Forms/FHFormio/CustomComponents/Vitals/interfaces';
import { ILeadData } from '../../interfaces';

export const getContactStickyNote = (notes: any[]) => {
  return notes.find(n => n.contactStickyNotes?.id) || "";
};

export const getFormDataFromCustomerData = (
  singleCustomerData: any,
  mlovData: ICommonData
): any => {
  const personContactTypeList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.PERSON_CONTACT_TYPE
  );
  // const personGenderList = getMlovListFromCategory(
  //   mlovData.MLOV,
  //   MLOV_CATEGORY.PERSON_GENDER
  // );
  const emailContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.EMAIL
  );

  const phoneContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.PHONE
  );

  let contactList = singleCustomerData?.personContact || [];

  if (contactList && contactList.length) {
    contactList = updateContactTypeIdIfNotExist(
      contactList,
      personContactTypeList
    );
  }
  const emailObj = getEmailContactObjFromList(
    contactList,
    personContactTypeList
  );
  const phoneObj = getPhoneContactObjFromList(
    contactList,
    personContactTypeList
  );

  let ageInYears;
  let birthDate =
    singleCustomerData?.person?.birthDate &&
    new Date(singleCustomerData?.person?.birthDate);
  if (birthDate) {
    birthDate = moment(birthDate).format(DISPLAY_DATE_FORMAT);
    ageInYears = moment().diff(birthDate, 'years', false);
  }

  const address = singleCustomerData?.personAddress[0];
  // let genderName = '';
  // if (singleCustomerData?.person?.genderId) {
  //   genderName = getMlovValueFromId(personGenderList, singleCustomerData?.person?.genderId)
  // }
  const firstName = singleCustomerData?.person?.firstName
    ? singleCustomerData?.person?.firstName
    : singleCustomerData?.name
    ? singleCustomerData?.name
    : '';
  const middleName = singleCustomerData?.person?.middleName
    ? ' ' + singleCustomerData?.person?.middleName
    : '';
  const lastName = singleCustomerData?.person?.lastName
    ? ' ' + singleCustomerData?.person?.lastName
    : '';
  const fullName = firstName + middleName + lastName;
  const note =
    singleCustomerData?.notes && singleCustomerData?.notes.length
      ? getContactStickyNote(singleCustomerData?.notes)
      : '';
  const formTempData = {
    leadId: singleCustomerData?.id || '',
    firstName: singleCustomerData?.person?.firstName || '',
    name: fullName ? fullName : singleCustomerData?.name,
    cityId: address?.cityId || '',
    stateId: address?.stateId || '',
    zipCodeId: address?.zipcodeId || '',
    email: singleCustomerData?.email || '',
    phone: singleCustomerData?.phoneNumber || '',
    emailObj: emailObj || {},
    phoneObj: phoneObj || {},
    emailContactTypeId: emailContactTypeId,
    phoneContactTypeId: phoneContactTypeId,
    personAddressId: address?.id || '',
    cityName: address?.cities?.name || '',
    id: singleCustomerData?.id || '',
    stateName: address?.states?.name || '',
    typeId: address?.typeId || '',
    zipCode: address?.zipcodes?.code || '',
    patientId: singleCustomerData?.patient
      ? singleCustomerData?.patient?.patientId
      : '',
    patientUuid: singleCustomerData?.patient
      ? singleCustomerData?.patient?.patientUuid
      : '',
    annualVisitDate: singleCustomerData?.patient?.annualVisitDate
      ? singleCustomerData?.patient?.annualVisitDate
      : undefined,
    isWeeklyCheckin: singleCustomerData?.patient?.isWeeklyCheckin
      ? singleCustomerData?.patient?.isWeeklyCheckin
      : false,
    // genderId: singleCustomerData?.person?.genderId || '',
    // genderName: genderName,
    // birthDate: birthDate || '',
    // age: ageInYears || '',
    // note: singleCustomerData?.note || '',
    // cityId: address?.cityId || '',
    // cityName: address?.city?.name || '',
    // id: singleCustomerData?.person?.address[0]?.id || '',
    // line1: singleCustomerData?.person?.address[0]?.line1 || '',
    // line2: singleCustomerData?.person?.address[0]?.line2 || '',
    // stateId: address?.stateId || '',
    // stateName: address?.state?.name || '',
    // typeId: singleCustomerData?.person?.address[0]?.typeId || '',
    // zipCodeId: address?.zipCodeId || '',
    // zipCode: address?.zipCode?.code || '',
    personId: singleCustomerData?.person?.id || '',
    lastName: singleCustomerData?.person?.lastName || '',
    middleName: singleCustomerData?.person?.middleName || '',
    genderId: singleCustomerData?.person?.genderId || '',
    genderName: singleCustomerData?.person?.gender?.value || '',
    birthDate: birthDate || '',
    age: ageInYears || '',
    noteId: note?.id || '',
    note: note?.content || '',
    line1: singleCustomerData?.address?.line1 || '',
    line2: singleCustomerData?.address?.line2 || '',
  };
  return formTempData;
};

const updateContactTypeIdIfNotExist = (
  contactList: IPersonContact[],
  personContactTypeList: IMlov[]
): IPersonContact[] => {
  const tempContactList: IPersonContact[] = [];
  const emailContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.EMAIL
  );
  const phoneContactTypeId = getMlovIdFromCode(
    personContactTypeList,
    PERSON_CONTACT_TYPE_CODES.PHONE
  );
  contactList.forEach((singleContact: IPersonContact) => {
    const tempContactObj = {...singleContact};
    if (!tempContactObj.typeId) {
      if (tempContactObj.value?.includes('@')) {
        tempContactObj.typeId = emailContactTypeId;
      } else {
        tempContactObj.typeId = phoneContactTypeId;
      }
    }
    tempContactList.push({...tempContactObj});
  });
  return tempContactList;
};

export const validates = (leadFormData: any) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const errors: any = {};
  let validate = true;

  errors.firstName = !leadFormData.firstName || leadFormData.firstName?.trim()?.length === 0
    ? 'FirstName is required'
    : leadFormData.firstName?.trim()?.length < 2
    ? 'FirstName is too short'
    : '';
  errors.email = !leadFormData.email
    ? 'Email is required'
    : !leadFormData.email.match(emailRegex)
    ? 'Invalid Email'
    : '';
  errors.lastName = !leadFormData.lastName || leadFormData.lastName?.trim()?.length === 0
    ? 'Lastname is required'
    : leadFormData.lastName?.trim()?.length < 2
    ? 'Lastname is too short'
    : '';

  // errors.mobileNumber = !leadFormData.phone ? 'PhoneNumber is required' : '';
  errors.birthdate = !leadFormData.birthDate ? 'Birthdate is required' : '';
  errors.gender = !leadFormData.genderId ? 'gender is required' : '';
  errors.zipcode = !leadFormData.zipCodeId ? 'zipcode is required' : '';

  if (
    errors.firstName ||
    errors.email ||
    errors.lastName ||
    // errors.mobileNumber ||
    errors.birthdate ||
    errors.gender ||
    errors.zipcode
  ) {
    validate = false;
  }

  return {validate: validate, errors: errors};
};

export const validatesIndividually = (leadFormData: any) => {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let error = '';

  error = !leadFormData.firstName || leadFormData.firstName?.trim()?.length === 0
    ? 'First name is required'
    : leadFormData.firstName?.trim()?.length < 2
    ? 'First name is too short'
    : '';
  if (error !== '') {
    return {validate: false, errors: error};
  }
  error = !leadFormData.lastName || leadFormData.firstName?.trim()?.length === 0
    ? 'Last name is required'
    : leadFormData.lastName?.trim()?.length < 2
    ? 'Last name is too short'
    : '';
  if (error !== '') {
    return {validate: false, errors: error};
  }
  const emailId = leadFormData.email;
  error = !emailId
    ? 'Email is required'
    : !emailId.match(emailRegex)
    ? 'Invalid Email'
    : '';
  if (error !== '') {
    return {validate: false, errors: error};
  }

  // errors.mobileNumber = !leadFormData.phone ? 'PhoneNumber is required' : '';
  error = !leadFormData.birthDate ? 'Birthdate is required' : '';
  if (error !== '') {
    return {validate: false, errors: error};
  }
  // errors.gender = !leadFormData.genderId ? 'gender is required' : '';
  error = !leadFormData.zipCodeId ? 'zipcode is required' : '';
  if (error !== '') {
    return {validate: false, errors: error};
  }

  return {validate: true, errors: error};
};

export const createPatientBodyObject = (data: any) => {
  const birthDate = getDateStrFromFormat(
    data.birthDate,
    PATIENT_QUERRY_DATE_FROMATED
  );
  const body = {
    resourceType: 'Patient',
    name: [
      {
        given: [data.firstName],

        family: data.lastName,

        text: data.firstName + ' ' + data.lastName,
        use: 'official',
      },
    ],
    birthDate: birthDate,
    gender: data.genderName,
    telecom: [
      {
        system: 'email',

        value: data.email,
      },

      {
        system: 'phone',

        value: data.phone,

        use: 'mobile',
      },
    ],
    address: [
      {
        use: 'home',
        type: 'both',
        text: data.address,
        line: [data.address],
        city: data.cityName,
        state: data.stateName,
        postalCode: data.zipCode,
      },
    ],
  };
  return body;
};

export const createPatientBodyObjec = (data: any) => {
  const birthDate = getDateStrFromFormat(
    data.birthDate,
    PATIENT_QUERRY_DATE_FROMATED
  );
  const body = {
    resourceType: 'Patient',

    name: [
      {
        given: [data.firstName],

        family: data.lastName,

        text: data.firstName + ' ' + data.lastName,
      },
    ],

    birthDate: birthDate,

    ...(data.genderName && {gender: data.genderName}),

    telecom: [
      {
        system: 'email',

        value: (data.email || '').toLowerCase().trim(),
      },

      // {
      //   system: 'phone',

      //   value: data.phone,

      //   use: 'mobile',
      // },

      // {
      //   system: 'phone',

      //   value: data.phone,

      //   use: 'home',
      // },
    ],
  };

  if (data?.sexAtBirthCode) {
    body.gender = data.sexAtBirthCode;
  }

  return body;
};

export const createPatientBodyObjectForIntegration = (args: {
  data: any;
  addTagObjectList: any[];
  sendInvite: boolean;
  defaultLocationUuid?: string;
  userId: string | number;
  userUuid: string;
}) => {
  const {data, addTagObjectList, sendInvite, userId, userUuid} = args;
  const birthDate = getDateStrFromFormat(
    data.birthDate,
    PATIENT_QUERRY_DATE_FROMATED
  );
  const marriageAnniversaryDate = getDateStrFromFormat(
    data.marriageAnniversaryDate,
    PATIENT_QUERRY_DATE_FROMATED
  );
  const extension: any[] = [];
  const {genderId, genderName} = getGenderNameAndId(data);
  const fhirTemplateVitalData: Observation[] = data?.vitalObservations?.map((item: IObservation) => toObservationFhirItem(item)) || [];

  if (data?.sexAtBirthCode || data?.contactData?.person?.sexAtBirthCode) {
    extension.push({
      url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
      valueCode: data.sexAtBirthCode || data?.contactData?.person?.sexAtBirthCode
    });
  } else if (data?.sexAtBirth) {
    extension.push({
      url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
      valueCode: data.sexAtBirth,
    });
  }

  const note = data.note?.trim();
  // if (note) {
  //   extension.push({
  //     url: 'http://schemas.canvasmedical.com/fhir/extensions/administrative-note',
  //     valueString: note,
  //   });
  // }
  if(data?.pronounName) {
    extension.push({
      url: 'http://xcaliber-fhir/structureDefinition/pronouns',
      valueString: data?.pronounName,
    });
  }

  if (data?.sexualOrientation) {
    const sexualOrientationCapability = data?.patientCapability?.keyAllowedOperations?.sexualOrientation;
    const sexualOrientationText = data?.sexualOrientationText?.trim();
    if (sexualOrientationCapability?.extensionUrl || sexualOrientationCapability?.otherInfoExtensionUrl) {
      extension.push({
        url: sexualOrientationCapability.extensionUrl,
        valueString: data?.sexualOrientation,
      });
      if (sexualOrientationText && data?.sexualOrientation === SEXUAL_ORIENTATION_OTHER_CODE) {
        extension.push({
          url: sexualOrientationCapability.otherInfoExtensionUrl,
          valueString: sexualOrientationText?.length ? sexualOrientationText : '',
        });
      }
    }
  }
  if (data?.race?.length) {
    const fieldCapability = data?.patientCapability?.keyAllowedOperations?.race;
    if (fieldCapability?.extensionUrl) {
      extension.push({
        url: fieldCapability.extensionUrl,
        extension: data.race.map((item: Coding) => {
          return {
            url: fieldCapability.extensionUrl,
            valueCode: item.code,
          }
        }),
      });
    }
  }
  if (data?.note?.trim()) {
    extension.push({
      url: 'http://xcaliber-fhir/structureDefinition/notes',
      valueString: data?.note,
    });
  }
  if (data?.ethnicity?.length) {
    const fieldCapability = data?.patientCapability?.keyAllowedOperations?.ethnicity;
    if (fieldCapability?.extensionUrl) {
      extension.push({
        url: fieldCapability.extensionUrl,
        extension: data.ethnicity.map((item: Coding) => {
          return {
            url: fieldCapability.extensionUrl,
            valueCode: item.code,
          }
        }),
      });
    }
  }

  const given: string[] = [];
  if (data.firstName?.trim()) {
    given.push(data.firstName?.trim());
  }

  if (data.middleName?.trim()) {
    given.push(data.middleName.trim());
  }

  const body:any = {
    resourceType: 'Patient',
    extension: extension,
    name: [
      {
        given: given,
        family: data.lastName?.trim(),
        text: data.firstName?.trim() + ' ' + data.lastName?.trim(),
        use: 'official',
      },
    ],
    birthDate: birthDate,
    ...(genderName && {gender: genderName}),
    ...(data?.maritalStatus && {maritalStatus: {
      coding: [{
        system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
        code: data.maritalStatus,
      }]
    }}),
    telecom: getTelecomObject(data),
    generalPractitioner: getPractitionerObject(data),
    contained: [...fhirTemplateVitalData,...(data?.ehrAttributes || []),...data?.contained || []],
    reference: {
      practiceLocationUuid: data.locationUuid || args.defaultLocationUuid,
      marriageAnniversaryDate: marriageAnniversaryDate,
      cityId: data.cityId,
      stateId: data.stateId,
      zipcodeId: data.zipCodeId,
      typeId: data.typeId,
      ...(genderId && {genderId: genderId}),
      contactTagging: addTagObjectList,
      bloodGroupId: data.bloodGroupId ? data.bloodGroupId : null,
      userId: userId,
      annualVisitDate: data.annualVisitDate,
      isWeeklyCheckin: data.isWeeklyCheckin,
      workflowMetaData: data.workflow_meta_data,
      patientSource: data.source ?? CONTACT_SOURCE_OF_CREATION,
      ...(data.leadId && {contactId: data.leadId}), // for convert to patient flow
      ...(data.legalSex && {legalSex: data.legalSex}),
      userUuid: userUuid,
      preferredCommunicationModeId: data.preferredModeId,
      consentToCall: data?.consentToCall || false,
      consentToText: data?.consentToText || false,
      consentToEmail: data?.consentToEmail || false,
    },
    communication: getCommunicationObject(data),
  };

  let usualName:any = undefined;
  if(data?.chosenName) {
   usualName = {
    text: data?.chosenName || '',
    use: 'usual',
    }
    }
  if (usualName) {
    body.name.push(usualName);
  }
  const addressObj = {} as any;
  const addressLine1 = data.line1?.trim();
  if (addressLine1) {
    addressObj.use = addressLine1;
    addressObj.line =  [addressLine1 || '', data.line2?.trim() || ''];
  }
  if (data.cityName) {
    addressObj.city = data.cityName;
  }
  if (data.abbr) {
    addressObj.state = data.abbr;
  }
  if (data.zipCode) {
    addressObj.postalCode = data.zipCode;
  }
  if (addressObj && Object.keys(addressObj).length) {
    addressObj.use = 'home';
    addressObj.type = 'both';
    body.address = [addressObj];
  }
  return body;
};

export const updatePatientObject = (args: {
  data: any;
  addTagObjectList: any[];
  isSexAtBirthRequired?: boolean;
  defaultLocationUuid?: string;
  sendInvite?: boolean;
  userId: string | number;
  pronounList:IMlov[],
}) => {
  const {data, addTagObjectList, userId,pronounList} = args;

  const {genderId, genderName} = getGenderNameAndId(data);

  const birthDate = getDateStrFromFormat(
    data.birthDate,
    PATIENT_QUERRY_DATE_FROMATED
  );

  const marriageAnniversaryDate = getDateStrFromFormat(
    data.marriageAnniversaryDate,
    PATIENT_QUERRY_DATE_FROMATED
  );

  const extension: any[] = [];
  const patientId = data.patientId || data.patientUuid;
  const fhirTemplateVitalData: Observation[] = data?.vitalObservations?.map((item: IObservation) => toObservationFhirItem(item)) || [];

  if (args.isSexAtBirthRequired) {
    if (data?.sexAtBirthCode) {
      extension.push({
        url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
        valueCode: data.sexAtBirthCode,
      });
    } else if (data?.sexAtBirth) {
      extension.push({
        url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
        valueCode: data.sexAtBirth,
      });
    }
  }

  if (data?.sexualOrientationText || data?.sexualOrientation) {
    const sexualOrientationCapability = data?.patientCapability?.keyAllowedOperations?.sexualOrientation;
    const sexualOrientationText = data?.sexualOrientationText?.trim();
    if (sexualOrientationCapability?.extensionUrl || sexualOrientationCapability?.otherInfoExtensionUrl) {
      extension.push({
        url: sexualOrientationCapability.extensionUrl,
        valueString: data?.sexualOrientation,
      });
      if (sexualOrientationText && data?.sexualOrientation === SEXUAL_ORIENTATION_OTHER_CODE) {
        extension.push({
          url: sexualOrientationCapability.otherInfoExtensionUrl,
          valueString: sexualOrientationText?.length ? sexualOrientationText : '',
        });
      }
    }
  }
  if (data?.race?.length) {
    const fieldCapability = data?.patientCapability?.keyAllowedOperations?.race;
    if (fieldCapability?.extensionUrl) {
      extension.push({
        url: fieldCapability.extensionUrl,
        extension: data.race.map((item: Coding) => {
          return {
            url: fieldCapability.extensionUrl,
            valueCode: item.code,
          }
        }),
      });
    }
  }
  if (data?.ethnicity?.length) {
    const fieldCapability = data?.patientCapability?.keyAllowedOperations?.ethnicity;
    if (fieldCapability?.extensionUrl) {
      extension.push({
        url: fieldCapability.extensionUrl,
        extension: data.ethnicity.map((item: Coding) => {
          return {
            url: fieldCapability.extensionUrl,
            valueCode: item.code,
          }
        }),
      });
    }
  }

  const note = data.note?.trim();
  if (note) {
    extension.push({
      url: 'http://xcaliber-fhir/structureDefinition/notes',
      valueString: note,
    });
  }

  if (data?.pronounName || data?.pronounId) {
    const selectedPronoun = pronounList?.find((pronoun:any)=> {
      return pronoun?.id == data?.pronounId
    })
    extension.push({
      url: 'http://xcaliber-fhir/structureDefinition/pronouns',
      valueString: selectedPronoun?.code,
    });
  }

  const body: any = {
    resourceType: 'Patient',
    id: patientId,
    extension: extension,
    name: [
      {
        given: [data.firstName?.trim(), data.middleName?.trim()],
        family: data.lastName?.trim(),
        text: data.firstName?.trim() + ' ' + data.lastName?.trim(),
        use: 'official',
      }
    ],
    birthDate: birthDate,
    ...(genderName && {gender: genderName}),
    ...(data?.maritalStatus && {maritalStatus: {
      coding: [{
        system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
        code: data.maritalStatus,
      }]
    }}),
    telecom: getTelecomObject(data),
    contained:[...fhirTemplateVitalData,...(data?.ehrAttributes || [])],
    reference: {
      practiceLocationUuid: data.locationUuid || args.defaultLocationUuid,
      contactUuid: data.contactUUID,
      marriageAnniversaryDate: marriageAnniversaryDate,
      cityId: data.cityId,
      stateId: data.stateId,
      zipcodeId: data.zipCodeId,
      typeId: data.typeId,
      ...(genderId && {genderId: genderId}),
      contactTagging: addTagObjectList,
      annualVisitDate: data.annualVisitDate,
      isWeeklyCheckin: data.isWeeklyCheckin,
      contactId: data.leadId,
      bloodGroupId: data.bloodGroupId ? data.bloodGroupId : null,
      personAddressId: data.personAddressId,
      userId: userId,
      ...(data.legalSex && {legalSex: data.legalSex}),
      preferredCommunicationModeId: data.preferredModeId,
      consentToCall: data?.consentToCall || false,
      consentToText: data?.consentToText || false,
      consentToEmail: data?.consentToEmail || false,
    },
    generalPractitioner: getPractitionerObject(data),
    communication: getCommunicationObject(data),
    contactConsents: data?.contactConsents
  };
  let usualName:any = undefined;
  if(data?.chosenName) {
   usualName = {
    text: data?.chosenName || '',
    use: 'usual',
    }
    }
  if (usualName) {
    body.name.push(usualName);
  }
  const addressObj = {} as any;
  const addressLine1 = data.line1?.trim();
  if (addressLine1) {
    addressObj.use = addressLine1;
    addressObj.line =  [addressLine1 || '', data.line2?.trim() || ''];
  }
  if (data.cityName) {
    addressObj.city = data.cityName;
  }
  if (data.abbr) {
    addressObj.state = data.abbr;
  }
  if (data.zipCode) {
    addressObj.postalCode = data.zipCode;
  }
  if (addressObj && Object.keys(addressObj).length) {
    addressObj.use = 'home';
    addressObj.type = 'both';
    body.address = [addressObj];
  }

  if (!!data?.contact) {
    body.contact = data?.contact;
  }

  return body;
};

export const getCommunicationObject = (data: any) => {
  const communication: any[] = [];
  const contactLanguages = data.contactLanguages;
  const code = contactLanguages?.languageCode;
  const id = contactLanguages?.id || '';
  const obj = {
    ...(id && {id: id}),
    ...(code && {coding: [
      {
        system: 'http://hl7.org/fhir/ValueSet/all-languages',
        code: code,
      },
    ]}),
  };
  communication.push({
    language: obj,
  });

  return communication;
};

export const getQueryStringForPatientSearch = (args: {
  birthDate: string;
  firstName: string;
  lastName: string;
}) => {
  const {birthDate, firstName, lastName} = args;

  // const dateString = new Date(birthDate).toISOString().split('T')[0];
  const dateString = formatDate(birthDate);

  const firstNameString = `given=${firstName}`;
  const lastNameString = `family=${lastName}`;

  const queryString =
    dateString !== 'Invalid Date'
      ? `?birthdate=${dateString}&${lastNameString}&${firstNameString}`
      : ``;

  return queryString;
};

export const formatDate = (date: string) => {
  const d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  const year = d.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
};

export const getGenderNameAndId = (
  data: any
): {
  genderId: string;
  genderName: string;
} => {
  const {genderId, genderName} = data;
  // const check = genderIdsNotToSave.some((id) => id === genderId);
  // if (check) {
  //   return {
  //     genderId: '876dd258-aed4-4423-b7eb-8f6f5b806b42',
  //     genderName: 'other',
  //   };
  // }
  return {
    genderId,
    genderName,
  };
};

export const genderIdsNotToSave: string[] = [
  'fc6ad559-808b-45ea-9679-204aeafa355f',
  '839f026d-76b3-48b6-986e-5035488a170b',
  '58102b8b-4672-467e-8c26-770343b748be',
];

export const getTelecomObject = (data: any) => {
  const telecom: any[] = [];
  if (data.email) {
    telecom.push({
      system: 'email',
      value: (data.email || '').toLowerCase().trim(),
      use: 'home',
      rank: 1,
    });
  }

  if (data.phone) {
    telecom.push({
      system: 'phone',
      value: data.phone,
      use: 'mobile',
      rank: 1,
    });
  }
  if (data.secondaryPhone) {
    const temp = {
      system: 'phone',
      value: data.secondaryPhone,
      use: 'mobile',
      rank: 2,
    };
    telecom.push(temp);
  }
  if (data.secondaryEmail) {
    const temp = {
      system: 'email',
      value: (data.secondaryEmail || '').toLowerCase().trim(),
      use: 'home',
      rank: 2,
    };
    telecom.push(temp);
  }
  return telecom;
};

export const getPractitionerObject = (data: any) => {
  if (data.practitionerId) {
    return [
      {
        reference: `Practitioner/${data.practitionerId}`,
      },
    ];
  }
  return [];
};

export const getLeadObjectForIntegration = (args: {
  data: any,
  addTagObjectList: any[];
  defaultLocationUuid?: string;
  userId: string | number;
  userUuid: string;
}): ILeadData => {
  const {data, addTagObjectList, defaultLocationUuid, userId, userUuid} = args;
  const result: ILeadData = {resourceType: 'Patient', leadData: data};
  const birthDate = getDateStrFromFormat(
    data.birthDate,
    PATIENT_QUERRY_DATE_FROMATED
  );
  const extension: Extension[] = [];
  const {genderName} = getGenderNameAndId(data);
  if (data?.pronounName) {
    extension.push({
      url: 'http://xcaliber-fhir/structureDefinition/pronouns',
      valueString: data?.pronounName,
    });
  }
  extension.push({
    url: 'https://foldhealth.io/structure/source',
    valueString: data.source ?? CONTACT_SOURCE_OF_CREATION,
  });

  extension.push({
    url: 'http://foldhealth.io/structure/contact-type',
    valueString: data.contactTypeId || data?.contactType?.id,
  });
  if (data?.note) {
    extension.push({
      url: 'http://xcaliber-fhir/structureDefinition/notes',
      valueString: data?.note,
    });
  }

  result.extension = extension;
  result.name = getHumanName(data);
  result.birthDate = birthDate;
  if (genderName) {
    result.gender = genderName as any;
  }
  result.telecom = getTelecomObject(data);
  result.generalPractitioner = getPractitionerObject(data);
  result.reference = getReferenceObject({
    data,
    addTagObjectList,
    defaultLocationUuid,
    userId,
    userUuid,
  });
  result.communication = getCommunicationObject(data);
  result.managingOrganization = {
    reference: `Organization/LEAD`,
  };
  const addressObj = {} as any;
  const addressLine1 = data.line1?.trim();
  if (addressLine1) {
    addressObj.use = addressLine1;
    addressObj.line = [addressLine1 || '', data.line2?.trim() || ''];
  }
  if (data.cityName) {
    addressObj.city = data.cityName;
  }
  if (data.abbr) {
    addressObj.state = data.abbr;
  }
  if (data.zipCode) {
    addressObj.postalCode = data.zipCode;
  }
  if (addressObj && Object.keys(addressObj).length) {
    addressObj.use = 'home';
    addressObj.type = 'both';
    result.address = [addressObj];
  }
  return result;
};

export const getHumanName = (data: any): HumanName[] => {
  return [
    {
      given: getGiven(data),
      family: data.lastName,
      text: data.firstName + ' ' + data.lastName,
      use: 'official',
    },
  ];
};

export const getGiven = (data: any) => {
  const given: string[] = [];
  if (data.firstName) {
    given.push(data.firstName);
  }
  if (data.middleName) {
    given.push(data.middleName);
  }
  return given;
};

export const getReferenceObject = (args: {
  data: any;
  defaultLocationUuid?: string;
  userId: string | number;
  addTagObjectList: any[];
  userUuid: string;
}) => {
  const {addTagObjectList, data, defaultLocationUuid, userId, userUuid} = args;
  const {genderId} = getGenderNameAndId(data);
  return {
    practiceLocationUuid: data.locationUuid || defaultLocationUuid,
    cityId: data.cityId,
    stateId: data.stateId,
    zipcodeId: data.zipCodeId,
    typeId: data.typeId,
    ...(genderId && {genderId: genderId}),
    contactTagging: addTagObjectList,
    bloodGroupId: data.bloodGroupId ? data.bloodGroupId : null,
    userId: userId,
    preferredCommunicationModeId: data.preferredModeId,
    annualVisitDate: data.annualVisitDate,
    isWeeklyCheckin: data.isWeeklyCheckin,
    workflowMetaData: data.workflow_meta_data,
    patientSource: data.source ?? CONTACT_SOURCE_OF_CREATION,
    ...(data.leadId && {contactId: data.leadId}), // for convert to patient flow
    ...(data.legalSex && {legalSex: data.legalSex}),
    userUuid: userUuid,
    consentToCall: data?.consentToCall || false,
    consentToText: data?.consentToText || false,
    consentToEmail: data?.consentToEmail || false,
    emailContactTypeId: data.emailContactTypeId,
    phoneContactTypeId: data.phoneContactTypeId
  };
};
