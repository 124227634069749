import {Collapse, Grid, Space} from '@arco-design/web-react';
//FIXME: for mobile needs to be commented
import '@arco-themes/react-easy-email-theme-purple/css/arco.css';
import {Drawer} from 'antd';
import {AdvancedType, BasicType, JsonToMjml} from '@foldhealth/easy-email-core';
import {
  EmailEditor,
  EmailEditorProvider,
  IEmailTemplate,
  Stack,
  useFocusIdx,
} from '@foldhealth/easy-email-editor';
//FIXME: for mobile needs to be commented
import '@foldhealth/easy-email-editor/lib/style.css';
import {
  AttributesPanelWrapper,
  BlockAttributeConfigurationManager,
  ColorPickerField,
  ExtensionProps,
  FontFamily,
  InputWithUnitField,
  StandardLayout,
  TextAreaField,
  TextField,
} from '@foldhealth/easy-email-extensions';
// FIXME: for mobile needs to be commented
import '@foldhealth/easy-email-extensions/lib/style.css';
import {Liquid} from 'liquidjs';
import {
  Button,
  Checkbox,
  FormControl,
  HStack,
  Input,
  Select,
  Spinner,
  Stack as NBStack,
  Tooltip,
  useMediaQuery,
  useToast,
  View,
} from 'native-base';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
  BUTTON_TYPE,
  IPAD_MINI_WIDTH,
  IPAD_WIDTH,
} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {commonBodyContentContainer} from '../../../../../styles/CommonBodyContentStyle';
import {getOnFocusButtonStyles} from '../../../../../utils/commonUtils';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import EmailDrawerCommon from '../../../../common/EmailPopupView/EmailDrawerCommon';
import ModalActionBtn from '../../../../common/ModalActionBtn/ModalActionBtn';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import SuggestionsSvg from '../../../../common/Svg/SideMenu/SuggestionsSvg';
import TitleSubtitleView from '../../../../common/TitleSubtitleView/TitleSubtitleView';
import {ContentTypes, fontList, mergeTags} from '../../ContentManagementConsts';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {
  getAccountMergeTagData,
  getCustomAttributeMergeTags,
  getTemplateById,
  getTemplateCategories,
  getTemplateCategoryList,
  updateTemplate,
} from '../../ContentManagementUtils';
import {EmailComponentsListDrawer} from '../../EmailComponents/EmailComponentsListDrawer';
import {OverrideModal} from '../../OverrideModal';
import ViewDalleSuggestions from '../../ViewDalleSuggestions/ViewDalleSuggestions';
import ViewMedlineSuggestions from '../../ViewMedlineSuggestions/ViewMedlineSuggestions';
import ViewOpenaiSuggestions from '../../ViewOpenaiSuggestions/ViewOpenaiSuggestions';
import {ComingSoonModal} from '../ComingSoonModal';
import {
  emailTemplateValidation,
  getEmailTemplateFormattedObj,
  getFormattedSingleEmailTemplate,
  mergeTagGenerator,
  uploadImage,
} from '../EmailTemplatesUtils';
import {
  IEmailTemplateData,
  IEmailTemplateUpdateProps,
  ISuggestions,
  ITemplateCategory,
} from '../interfaces';
// FIXME: for mobile needs to be commented
import './CreateOrEditTemplateCss.css';
import {
  getEmailTemplateButtonStyles,
  styles,
} from './CreateOrEditTemplateStyles';
import CustomSuggestionsButton, {
  SuggestionsOptions,
} from './CustomSuggestionsButton';
import ConfirmationModal from '../../../../PersonOmniView/RightContainer/CareTeamView/components/ConfirmationModal';
import {OverrideDrawer} from '../../PushNotifications/OverrideDrawer';
import {EmailDrawerCommonV2} from '../../../../common/EmailDrawerCommonV2';
import {FormsBlockWrapper} from './CustomBlocks/FormsBlock';
import {SubjectInput} from '../SubjectInput';
import {filteredFormsCategoryList} from '../../../../../utils/commonUtils';

const {Item} = Select;
const {
  Label,
  // ErrorMessage
} = FormControl;

const EmailTemplateEditor = (props: IEmailTemplateUpdateProps) => {
  const {focusIdx} = useFocusIdx();
  BlockAttributeConfigurationManager.add({
    [BasicType.PAGE]: () => (
      <AttributesPanelWrapper style={{padding: 0}}>
        <Stack.Item fill>
          <Collapse defaultActiveKey={['0', '1']}>
            <Collapse.Item name="0" header="Email Setting">
              <Space direction="vertical">
                <TextField label="SubTitle" name={'subTitle'} inline />
                <InputWithUnitField
                  label="Width"
                  name={`${focusIdx}.attributes.width`}
                  inline
                />
                <InputWithUnitField
                  label="Breakpoint"
                  helpText="Allows you to control on which breakpoint the layout should go desktop/mobile."
                  name={`${focusIdx}.data.value.breakpoint`}
                  inline
                />
              </Space>
            </Collapse.Item>
            <Collapse.Item name="1" header="Theme Setting">
              <Stack vertical spacing="tight">
                <Grid.Row>
                  <Grid.Col span={11}>
                    <FontFamily name={`${focusIdx}.data.value.font-family`} />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <InputWithUnitField
                      label="Font size"
                      name={`${focusIdx}.data.value.font-size`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col span={11}>
                    <InputWithUnitField
                      label="Line height"
                      unitOptions="percent"
                      name={`${focusIdx}.data.value.line-height`}
                    />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <InputWithUnitField
                      label="Font weight"
                      unitOptions="percent"
                      name={`${focusIdx}.data.value.font-weight`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col span={11}>
                    <ColorPickerField
                      label="Text color"
                      name={`${focusIdx}.data.value.text-color`}
                    />
                  </Grid.Col>
                  <Grid.Col offset={1} span={11}>
                    <ColorPickerField
                      label="Background"
                      name={`${focusIdx}.attributes.background-color`}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <ColorPickerField
                    label="Content background"
                    name={`${focusIdx}.data.value.content-background-color`}
                  />
                </Grid.Row>

                <TextAreaField
                  autoSize
                  label="User style"
                  name={`${focusIdx}.data.value.user-style.content`}
                />
                <Stack.Item />
                <Stack.Item />
                {/* <AddFont /> */}
                <Stack.Item />
                <Stack.Item />
              </Stack>
            </Collapse.Item>
          </Collapse>
        </Stack.Item>
      </AttributesPanelWrapper>
    ),
  });

  const {id} = useParams();
  const templateId = props?.id ? props?.id : id;
  const [searchParams] = useSearchParams();
  const isDuplicate = !!searchParams.get('isDuplicate');
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const {width} = Dimensions.get('window');

  const drawerWidth =
    isIPadMiniScreen || isIPadScreen ? width * 0.7 : width * 0.4;

  const isSmallScreen = isIPadMiniScreen || isIPadScreen;

  const [editedTemplate, setEditedTemplate] = useState<IEmailTemplateData>(
    {} as IEmailTemplateData
  );
  const [initialValues, setInitialValues] = useState({} as IEmailTemplate);
  const [templateHtml, setTemplateHtml] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [categories, setCategories] = useState<ITemplateCategory[]>([]);
  const [tSuggestion, setTSuggestion] = useState<string>('');
  const [suggestion, setSuggestion] = useState<ISuggestions>({suggestions: []});
  const [loading, setLoading] = useState(false);
  const [overrideModal, setOverrideModal] = useState(false);
  const [tValues, setTValues] = useState<any>();
  const [openEmailDrawerModal, setOpenEmailDrawerModal] = useState(false);
  const [openSuggestionsModal, setOpenSuggestionsModal] = useState(false);
  const [openEduSuggestionsModal, setOpenEduSuggestionsModal] = useState(false);
  const [openImageSuggestionsModal, setOpenImageSuggestionsModal] =
    useState(false);
  // const {height} = Dimensions.get('window');
  const [customDrawerOpen, setCustomDrawerOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [customAttributeMergeTags, setCustomAttributeMergeTags] = useState<
    Record<string, string>
  >({});

  const toast = useToast();
  const intl = useIntl();
  const defaultCategories: ExtensionProps['categories'] = [
    {
      label: 'Content',
      active: true,
      blocks: [
        {
          type: AdvancedType.TEXT,
        },
        {
          type: AdvancedType.IMAGE,
          payload: {attributes: {padding: '0px 0px 0px 0px'}},
        },
        {
          type: AdvancedType.BUTTON,
        },
        {
          type: AdvancedType.SOCIAL,
        },
        {
          type: AdvancedType.DIVIDER,
        },
        {
          type: AdvancedType.SPACER,
        },
        {
          type: AdvancedType.HERO,
        },
        {
          type: AdvancedType.WRAPPER,
        },
        {
          type: AdvancedType.ACCORDION,
        },
        // {
        //   type: AdvancedType.CAROUSEL,
        // },
        {
          type: AdvancedType.NAVBAR,
        },
        {
          type: AdvancedType.GROUP,
        },
        {
          type: AdvancedType.COLUMN,
        },
        {
          type: AdvancedType.SECTION,
        },
      ],
    },

    {
      label: 'Layout',
      active: true,
      displayType: 'column',
      blocks: [
        {
          title: '2 columns',
          payload: [
            ['50%', '50%'],
            ['33%', '67%'],
            ['67%', '33%'],
            ['25%', '75%'],
            ['75%', '25%'],
          ],
        },
        {
          title: '3 columns',
          payload: [
            ['33.33%', '33.33%', '33.33%'],
            ['25%', '25%', '50%'],
            ['50%', '25%', '25%'],
          ],
        },
        {
          title: '4 columns',
          payload: [[['25%', '25%', '25%', '25%']]],
        },
      ],
    },
    {
      label: 'Custom',
      active: true,
      displayType: 'custom',
      blocks: [<FormsBlockWrapper />],
    },
  ];
  const handleOverride = () => {
    const mjml = JsonToMjml({
      data: tValues.content,
      mode: 'production',
      context: tValues.content,
      dataSource: mergeTags,
    });
    const newTemplate: IEmailTemplateData = {
      ...editedTemplate,
      templateLastUpdated: new Date(Date.now()),
      templateMjml: mjml,
      templateHtml: templateHtml,
      templateData: {
        ...tValues,
        subject: editedTemplate.templateData.subject,
      },
    };
    const emailTemplateData = getEmailTemplateFormattedObj(
      newTemplate,
      categories,
      true
    );
    const data = JSON.stringify(emailTemplateData);
    setSubmitting(true);
    updateTemplate(ContentTypes.emails.path, templateId, data)
      .then((response) => {
        setSubmitting(false);
        setLoading(true);
        return response;
      })
      .then((response) => {
        setLoading(false);
        navigate('/admin/contentManagement/emailTemplates');
      })
      .catch((error) => {
        if (error.response) {
        }
      });
    setOverrideModal(false);
  };

  const getAndSetCustomAttributeMergeTags = async () => {
    const customAttributeMergeTags = await getCustomAttributeMergeTags();
    setCustomAttributeMergeTags(customAttributeMergeTags);
  };

  useEffect(() => {
    getAndSetCustomAttributeMergeTags();
    getTemplateById(ContentTypes.emails.path, templateId)
      .then((data) => getFormattedSingleEmailTemplate(data))
      .then((singleTemplate) => {
        setEditedTemplate(singleTemplate);
        setInitialValues({
          subject: singleTemplate.templateData.subject,
          content: singleTemplate.templateData.content,
          subTitle: singleTemplate.templateData.subTitle,
        });
      })
      .catch((error) => error);
    getTemplateCategories()
      .then((data) => getTemplateCategoryList(data))
      .then((list) => {
        const filteredList = filteredFormsCategoryList(list);
        setCategories(filteredList);
      })
      .catch((error) => {});
  }, [templateId]);

  const navigate = useNavigate();

  // function for saving the template
  const handleOnTemplateSave = (values: IEmailTemplate) => {
    setTValues(values);
    const mjml = JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content,
      dataSource: mergeTags,
    });
    const newTemplate: IEmailTemplateData = {
      ...editedTemplate,
      templateData: {
        ...values,
        subject: editedTemplate.templateData.subject,
      },
      templateLastUpdated: new Date(Date.now()),
      templateMjml: mjml,
      templateHtml: templateHtml,
    };
    setErrors(emailTemplateValidation(newTemplate).errors);
    if (emailTemplateValidation(newTemplate).validate) {
      // setEditedTemplate(newTemplate);
      const emailTemplateData = getEmailTemplateFormattedObj(
        newTemplate,
        categories
      );

      showToast(
        toast,
        isDuplicate ? 'Saving...' : 'Updating...',
        ToastType.success
      );
      const data = JSON.stringify(emailTemplateData);
      setSubmitting(true);
      updateTemplate(
        ContentTypes.emails.path,
        isDuplicate ? undefined : templateId,
        emailTemplateData
      )
        .then((response) => {
          setSubmitting(false);
          setLoading(true);
          return response;
        })
        .then((response) => {
          setLoading(false);
          if (props?.customOnSubmitFn) {
            props?.customOnSubmitFn(response);
          } else {
            navigate('/admin/contentManagement/emailTemplates');
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else {
      setSubmitting(false);
    }
  };

  const errorHandler = (error: any) => {
    if (
      error.response?.status === 400 &&
      error.response.data?.error === 'Default Template Already Present'
    ) {
      setSubmitting(false);
      setOverrideModal(true);
    } else {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  };

  const onBeforePreview = (html: string, mergeTags: any) => {
    setTemplateHtml(html);
    const engine = new Liquid();
    const tpl = engine.parse(html);
    return engine.renderSync(tpl, mergeTags);
  };

  // account specific merge tags
  const accountMergeTags = getAccountMergeTagData();

  const getMergeTags = (category: string) => {
    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return {
      ...mergeTags,
      ...mergeTagsByCategory,
      global: accountMergeTags,
      customAttribute: customAttributeMergeTags,
    };
  };

  const onHeaderBackClick = () => {
    navigate(-1);
  };

  const setDefaultChecked = (isChecked: boolean) => {
    setEditedTemplate((prevTemplate) => {
      return {
        ...prevTemplate,
        templateIsDefault: isChecked,
      };
    });
  };

  useEffect(() => {
    const preventUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', preventUnload);

    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
  }, []);

  return (
    <View flex={1}>
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <View>
          {!props?.shouldHideTitle ? (
            <View style={{backgroundColor: '#fff'}}>
              <TitleSubtitleView
                title={isDuplicate ? 'createTemplate' : 'updateTemplate'}
                titleLabelId={isDuplicate ? 'createTemplate' : 'updateTemplate'}
                titleStyle={{fontSize: 24}}
                subtitle={
                  isDuplicate
                    ? 'Here you can create the email template'
                    : 'Here you can update the email template'
                }
                showBackButton={true}
                onBackClick={onHeaderBackClick}
              />
            </View>
          ) : null}
          <View
            style={[
              commonBodyContentContainer.pageContentContainer,
              {padding: 14},
            ]}
          >
            <EmailEditorProvider
              fontList={fontList}
              mergeTags={getMergeTags(editedTemplate.templateCategory)}
              mergeTagGenerate={mergeTagGenerator}
              data={initialValues}
              height={'85vh'}
              enabledLogic
              autoComplete
              dashed={false}
              onSubmit={handleOnTemplateSave}
              onBeforePreview={onBeforePreview}
              onUploadImage={(blob: Blob) => {
                return uploadImage(blob);
              }}
            >
              {({}, {submit}) => {
                return (
                  <View overflow="scroll">
                    <View style={{padding: 2}}>
                      <View
                        style={{
                          backgroundColor: '#fff',
                          borderColor: Colors.Custom.BorderColor,
                          borderWidth: 1,
                          borderRadius: 8,
                        }}
                      >
                        <HStack style={[styles.topbarContainer]}>
                          <HStack
                            style={{
                              justifyContent: isSmallScreen
                                ? 'space-between'
                                : 'space-between',
                              flex: 1,
                            }}
                          >
                            <HStack>
                              <View style={styles.inputStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.templateName}
                                >
                                  <HStack
                                    style={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Input
                                      _focus={{borderColor: '#D0D5DD'}}
                                      defaultValue={editedTemplate.templateName}
                                      placeholder="Enter Name"
                                      onChangeText={(text: string) => {
                                        setEditedTemplate((prevTemplate) => {
                                          return {
                                            ...prevTemplate,
                                            templateName: text,
                                          };
                                        });
                                      }}
                                    />
                                  </HStack>
                                </FormControl>
                              </View>
                              <View style={styles.dropdownStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.templateCategory}
                                >
                                  <HStack
                                    style={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Select
                                      selectedValue={
                                        editedTemplate.templateCategory
                                      }
                                      isDisabled={
                                        props?.shouldDisableChangeCategory
                                      }
                                      placeholder={'Select Category'}
                                      onValueChange={(value) =>
                                        setEditedTemplate((prevTemplate) => {
                                          return {
                                            ...prevTemplate,
                                            templateCategory: value,
                                          };
                                        })
                                      }
                                    >
                                      {categories.map((singleCategory) => {
                                        return (
                                          <Item
                                            key={singleCategory.id}
                                            label={singleCategory.name}
                                            value={singleCategory.name}
                                          />
                                        );
                                      })}
                                    </Select>
                                  </HStack>
                                </FormControl>
                              </View>
                              <View style={styles.inputStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.subject}
                                >
                                  <HStack>
                                    <SubjectInput
                                      isError={!!errors.subject}
                                      mergeTags={getMergeTags(
                                        editedTemplate.templateCategory
                                      )}
                                      _focus={{borderColor: '#D0D5DD'}}
                                      value={
                                        editedTemplate.templateData.subject
                                      }
                                      placeholder={'Enter Subject'}
                                      onChangeText={(text: string) => {
                                        setEditedTemplate({
                                          ...editedTemplate,
                                          templateData: {
                                            subject: text,
                                          },
                                        });
                                      }}
                                    />
                                  </HStack>
                                </FormControl>
                              </View>
                            </HStack>
                            <HStack>
                              {!props.shouldHideDefault ? (
                                <View style={styles.switchView}>
                                  <FormControl>
                                    <HStack
                                      style={{
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Label marginTop={2}>Default</Label>
                                      <Checkbox
                                        marginTop={2}
                                        value="true"
                                        isChecked={
                                          editedTemplate.templateIsDefault
                                        }
                                        onChange={setDefaultChecked}
                                      />
                                    </HStack>
                                  </FormControl>
                                </View>
                              ) : null}
                              <View style={styles.inputStyle}>
                                <FormControl
                                  isRequired
                                  isInvalid={errors.templateName}
                                >
                                  <HStack
                                    style={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <CustomSuggestionsButton
                                      title={intl.formatMessage({
                                        id: 'contentSuggestions',
                                      })}
                                      onClick={(action: SuggestionsOptions) => {
                                        if (action === 'suggestContent') {
                                          setOpenSuggestionsModal(true);
                                        }
                                        if (action === 'suggestEduContent') {
                                          setOpenEduSuggestionsModal(true);
                                        }
                                        if (action === 'suggestImageContent') {
                                          setOpenImageSuggestionsModal(true);
                                        }
                                      }}
                                      leftIcon={<SuggestionsSvg />}
                                    />
                                  </HStack>
                                </FormControl>
                              </View>
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                _focus={{
                                  ...getOnFocusButtonStyles(),
                                }}
                                onPress={() => {
                                  setCustomDrawerOpen(true);
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Custom
                              </Button>
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                _focus={{
                                  ...getOnFocusButtonStyles(),
                                }}
                                leftIcon={
                                  <AntIcon
                                    name="mail"
                                    color={'#825AC7'}
                                    size={16}
                                  />
                                }
                                onPress={() => {
                                  setOpenEmailDrawerModal(true);
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Test Mail
                              </Button>
                              {/* <Tooltip label="Cancel" placement="top"> */}
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                _focus={{
                                  ...getOnFocusButtonStyles({
                                    backgroundColor: Colors.danger['100'],
                                    borderColor: Colors.danger['200'],
                                  }),
                                }}
                                leftIcon={
                                  <AntIcon
                                    name="back"
                                    size={15}
                                    color="#825AC7"
                                  />
                                }
                                onPress={() => {
                                  props?.onCancel
                                    ? props.onCancel()
                                    : navigate(
                                        '/admin/contentManagement/emailTemplates'
                                      );
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Discard
                              </Button>
                              {/* </Tooltip> */}
                              {/* <Tooltip label="Save" placement="top"> */}
                              <Button
                                style={getEmailTemplateButtonStyles({
                                  btnType: 'primary',
                                })}
                                isLoading={submitting}
                                _focus={{
                                  ...getOnFocusButtonStyles({
                                    backgroundColor: Colors.secondary['100'],
                                    borderColor: Colors.secondary['200'],
                                  }),
                                }}
                                leftIcon={
                                  <Feather
                                    name="save"
                                    color={'#825AC7'}
                                    size={16}
                                  />
                                }
                                onPress={() => {
                                  submit();
                                }}
                                _text={{
                                  color: '#825AC7',
                                }}
                              >
                                Save & Exit
                              </Button>
                              {/* </Tooltip> */}
                            </HStack>
                          </HStack>
                        </HStack>
                        <ComingSoonModal
                          isOpen={isModalOpen}
                          onModalClose={() => setIsModalOpen(false)}
                          headerText="Coming Soon"
                          bodyText="Coming Soon"
                        />

                        <View style={styles.emailContainer}>
                          <StandardLayout
                            compact={!isSmallScreen}
                            categories={defaultCategories}
                          >
                            <EmailEditor />
                          </StandardLayout>
                        </View>
                      </View>
                    </View>
                    <Drawer
                      open={openEduSuggestionsModal}
                      onClose={() => {
                        setOpenEduSuggestionsModal(false);
                      }}
                      width={drawerWidth}
                      title={
                        <ModalActionTitle
                          title={'viewMedlineSuggestions'}
                          titleColor={''}
                          buttonList={[
                            {
                              show: true,
                              id: 1,
                              btnText: 'cancel',
                              textColor: Colors.Custom.mainSecondaryBrown,
                              variant: BUTTON_TYPE.SECONDARY,
                              isTransBtn: false,
                              onClick: () => {
                                setOpenEduSuggestionsModal(false);
                              },
                            },
                          ]}
                        />
                      }
                    >
                      <ViewMedlineSuggestions
                        selectedGroupInfo={
                          editedTemplate?.templateData?.subject
                        }
                        template={editedTemplate}
                        onModalClose={() => {
                          setOpenEduSuggestionsModal(false);
                        }}
                      />
                    </Drawer>
                    <Drawer
                      open={openSuggestionsModal}
                      onClose={() => {
                        setOpenSuggestionsModal(false);
                      }}
                      width={drawerWidth}
                      title={
                        <ModalActionTitle
                          title={'contentSuggestions'}
                          titleColor={''}
                          buttonList={[
                            {
                              show: true,
                              id: 1,
                              btnText: 'cancel',
                              textColor: Colors.Custom.mainSecondaryBrown,
                              variant: BUTTON_TYPE.SECONDARY,
                              isTransBtn: false,
                              onClick: () => {
                                setOpenSuggestionsModal(false);
                              },
                            },
                          ]}
                        />
                      }
                    >
                      <ViewOpenaiSuggestions
                        onModalClose={() => {
                          setOpenSuggestionsModal(false);
                        }}
                        template={editedTemplate}
                      />
                    </Drawer>
                    <Drawer
                      open={openImageSuggestionsModal}
                      onClose={() => {
                        setOpenImageSuggestionsModal(false);
                      }}
                      width={drawerWidth}
                      title={
                        <ModalActionTitle
                          title={'contentSuggestions'}
                          titleColor={''}
                          buttonList={[
                            {
                              show: true,
                              id: 1,
                              btnText: 'cancel',
                              textColor: Colors.Custom.mainSecondaryBrown,
                              variant: BUTTON_TYPE.SECONDARY,
                              isTransBtn: false,
                              onClick: () => {
                                setOpenImageSuggestionsModal(false);
                              },
                            },
                          ]}
                        />
                      }
                    >
                      <ViewDalleSuggestions
                        onModalClose={() => {
                          setOpenImageSuggestionsModal(false);
                        }}
                        template={editedTemplate}
                      />
                    </Drawer>
                    {customDrawerOpen && (
                      <EmailComponentsListDrawer
                        isOpen={customDrawerOpen}
                        onClose={() => {
                          setCustomDrawerOpen(false);
                        }}
                      />
                    )}
                  </View>
                );
              }}
            </EmailEditorProvider>
          </View>
        </View>
      )}
      {openEmailDrawerModal && (
        <EmailDrawerCommonV2
          isOpen={openEmailDrawerModal}
          onClose={() => {
            setOpenEmailDrawerModal(false);
          }}
          onEmailSent={() => {
            setOpenEmailDrawerModal(false);
          }}
          html={templateHtml}
          subject={editedTemplate?.templateData?.subject || ''}
        />
      )}

      <OverrideModal
        isOpen={overrideModal}
        onClose={() => setOverrideModal(false)}
        textContent={{
          headerText: 'Override Default Template',
          message: intl.formatMessage({
            id: 'overrideDefaultTemplateMessage',
          }),
        }}
        onConfirm={() => handleOverride()}
      />
    </View>
  );
};

export default EmailTemplateEditor;
