import React from 'react';
import { Skeleton, Text, View, VStack } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { IVitalData } from '../../../../../../Interfaces';
import { getVitals } from '../../../../../../services/CommonService/AidBoxService';
import { Vital } from '../../../../../../utils/VitalUtils';
import { EmptyStateSvg } from '../../../../../common/Svg';
import { IObservation } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/interfaces';
import { IPAMIProps, IVitals } from '../../interfaces';
import { styles } from '../DetailTableStyles';
import { getFormattedVitalData } from '../DetailTableUtils';
import {
  converGraphDataToVitalGraphData,
  getBloodPressureGraphDataWithCoords,
  getMeasure,
  getRangeTextFromGraph,
  getVitalGraphDataInCoords
} from './VitalsGraphUtils';
import { Colors } from '../../../../../../styles';
import { getDateStrFromFormat } from '../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../constants';
import useLatestCareProgramEnrollmentDate from '../../../../LeftContainer/PamiDetail/hooks/useCareProgramEnrollmentDate';
import { CONTACT_CARE_PROGRAM_STATUS_CODES } from '../../../../../../constants/MlovConst';
import { FlatList } from 'react-native';
import Stack from '../../../../../common/LayoutComponents/Stack';
import { Line } from '@ant-design/charts';
import { CustomLineGraph } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomLineGraph/CustomLineGraph';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { useCarePlanGoalsApi } from '../../../../LeftContainer/RecentActivity/RecentReport/CarePlanGoalsHook';
import VitalGraphCard from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/components/VitalGraphCard';
import { getLastRecordText } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper';
import { GraphsTypes, IHighLightValues } from '../../../../../RightSideContainer/Forms/FHFormio/CustomComponents/HomeMonitoringView/interface';
import SysIconSvg from '../../../../../../assets/Icons/SysIconSvg';
import DiaIconSvg from '../../../../../../assets/Icons/DiaIconSvg';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { useLazyQuery } from '@apollo/client';
import ContactCareProgram from '../../../../../../services/ContactCareProgram/ContactCareProgram';
import { PROGRAM_FILTER_CODE } from '../../../../../common/MemebersView/constant';
import { isEnableCareProgram } from '../../../../../../utils/commonUtils';

interface IVitalGraphViewProps extends IPAMIProps {
  existingData?: IObservation[];
  vitalList: IVitalData[];
  accountLocationUuid?: string;
  isCheckInVitalList?: boolean;
  startDate?: string;
  endDate?: string;
  contactId?: string;
  columnSize: number;
  graphHeight?: number;
  isSmallView?: boolean;
  goals?: any;
  contactUuid?: string;
}

const VitalsGraphView = (props: IVitalGraphViewProps) => {
  const {patientId: patientUuid, vitalList, accountLocationUuid , contactId} = props;
  const mlovData = useContext(CommonDataContext);
  const [vitalData, setVitalData] = useState<IVitals[]>([]);
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const [observationRecords, setObservationRecords] = useState<IObservation[]>(props.existingData || []);

  const [loading, setLoading] = useState(false);
  const ccmDate = undefined;
  const ccmDateLoading = false;
  const headCircumferenceGraphData = getVitalGraphDataInCoords(
    Vital.headCircumference,
    vitalData,
    vitalList,
    ccmDate
  );
  const bodyTemperatureGraphData = getVitalGraphDataInCoords(
    Vital.temperature,
    vitalData,
    vitalList,
    ccmDate
  );
  const waistCircumferenceGraphData = getVitalGraphDataInCoords(
    Vital.waistCircumference,
    vitalData,
    vitalList,
    ccmDate
  );
  const heightGraphData = getVitalGraphDataInCoords(
    Vital.height,
    vitalData,
    vitalList,
    ccmDate
  );
  const weightGraphData = getVitalGraphDataInCoords(Vital.weight, vitalData, vitalList, ccmDate);
  const oxygenSaturationGraphData = getVitalGraphDataInCoords(
    Vital.oxygenSaturationByPulseOximetry,
    vitalData,
    vitalList,
    ccmDate
  );
  const painScaleGraphData = getVitalGraphDataInCoords(
    Vital.painScale,
    vitalData,
    vitalList,
    ccmDate
  );
  const bloodPressureGraphData = getBloodPressureGraphDataWithCoords(
    vitalData,
    vitalList,
    ccmDate
  );
  const bmiGraphData = getVitalGraphDataInCoords(Vital.bmi, vitalData, vitalList, ccmDate);
  const heartRateGraphData = getVitalGraphDataInCoords(Vital.heartRate, vitalData, vitalList, ccmDate);
  const respiratoryRateGraphData = getVitalGraphDataInCoords(Vital.respirationRate, vitalData, vitalList, ccmDate);

  const fontSize =  props.isSmallView ? 24 : 8;
  const graphHeight = props.isSmallView ? undefined : 200;
  const graphPadding = props.isSmallView ? {top: 60, bottom: 60, left: 70, right:60} : undefined;

  const responseHandler = (response: any) => {
    setLoading(false);
    let data = response?.data?.entry || [];
    data = data.map((item: any) => item.resource);
    const listData = getFormattedVitalData(
      data,
      vitalList,
      props.startDate,
      props.endDate
    );
    setObservationRecords(data || []);
    setVitalData(listData);
  };
  const errorHandler = (error: any) => {
    setLoading(false);
  };
  const [careProgramIds, setCareProgramIds] = useState<string[]>([]);
  const [getContactCarePrograms, {loading: getContactCareProgramsLoading}] = useLazyQuery(
    ContactCareProgram.GET_CONTACT_CARE_PROGRAM_ID,
    {
      fetchPolicy: 'no-cache',
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const fetchCareProgramIds = async () => {
    try {
      const response = await getContactCarePrograms({
        variables: {
          contactId: props?.contactUuid,
          codes: [PROGRAM_FILTER_CODE.CCM, PROGRAM_FILTER_CODE.CHF],
          statusCodes: [CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED, CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED, CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED]
        },
      });
      const fetchedCareProgramIds = response?.data?.contactCarePrograms?.map((program: any) => program.id) || [];
      return fetchedCareProgramIds;
    } catch (error) {
    }
  };

  useEffect(() => {
    const getCareProgramIds = async () => {
      const ids = await fetchCareProgramIds();
      setCareProgramIds(ids);
    };
    getCareProgramIds();
  }, [props?.contactUuid]);

  useEffect(() => {
    if (props.existingData?.length) {
      const listData = getFormattedVitalData(props.existingData, vitalList);
      setVitalData(listData);
    } else if (patientUuid) {
      setLoading(true);
      getVitals(
        patientUuid,
        (response: any) => responseHandler(response),
        (error: any) => errorHandler(error),
        accountLocationUuid,
      );
    }
  }, [props.existingData?.length]);


  let isSkipBloodPressure = false;
  if (props.isCheckInVitalList) {
    isSkipBloodPressure = !(props.vitalList || []).some((vitalConfig) => {
      return vitalConfig.loinc == Vital?.bloodPressure;
    })
  }

  const getFilteredGraphData = () => {
    const graphData = [];

    if(!bloodPressureGraphData.isEmpty && !isSkipBloodPressure){
      graphData.push(bloodPressureGraphData);
    }

    graphData.push(
      ...[
        heartRateGraphData,
        respiratoryRateGraphData,
        bmiGraphData,
        painScaleGraphData,
        bodyTemperatureGraphData,
        waistCircumferenceGraphData,
        heightGraphData,
        weightGraphData,
        oxygenSaturationGraphData,
        headCircumferenceGraphData,
      ]
    );

    return graphData.filter((vitalGraph)=> {
      let isSkip = false;
      if (props.isCheckInVitalList) {
        isSkip = !(props.vitalList || []).some((vitalConfig) => {
          return vitalConfig.loinc == vitalGraph?.loinc;
        });
      }
      if (vitalGraph.isEmpty || isSkip){
        return false;
      }
      return true;
    })

  }

  const [goals, goalsLoading, fetchGoals] = useCarePlanGoalsApi(careProgramIds);

  useEffect(() => {

    if (!props?.goals && !!careProgramIds.length) {
      fetchGoals(props.contactId);
    }
  }, [careProgramIds])

  const getBloodPressureGraphInfoView = () => {
    return (
      <Stack direction="row" style={styles.graphInfoView}>
        <SysIconSvg />
        <Text color={Colors.FoldPixel.GRAY400} fontSize={12} ml={1} mr={1}>
          Sys
        </Text>
        <DiaIconSvg />
        <Text color={Colors.FoldPixel.GRAY400} fontSize={12} ml={1} mr={2}>
          Dia
        </Text>
      </Stack>
    );
  };

   const renderItem = ({item}: {item: any}) => {
    const measure = getMeasure(item?.loinc, props?.goals || goals);
    const vitalConfig = vitalList.find((vitalListitem) => {
      return vitalListitem.loinc === item.loinc;
    });

    const data = getLastRecordText(
      observationRecords || [],
      vitalConfig,
      true
    ) as {valueText: string; unit?: string, recordedDate: string, value: string};

    let graphData = item;

    const highLightValues: IHighLightValues[] = [
      {
        value: `${data?.valueText}`,
        subValue: 'Latest',
        hideUnit: vitalConfig?.loinc === Vital.height ? true : false
      }
    ];

    if(data?.unit){
      graphData.displayUnit = data.unit
    }

    if (item.loinc === Vital.bloodPressure) {
      const sysRangeText = getRangeTextFromGraph(graphData.graphData?.[0])
      const diaRangeText = getRangeTextFromGraph(graphData.graphData?.[1])

      highLightValues.push({
        value: sysRangeText,
        subValue: 'Sys Range',
      });

      highLightValues.push({
        value: diaRangeText,
        subValue: 'Dia Range',
      });

      graphData = converGraphDataToVitalGraphData(
        graphData,
        GraphsTypes.VERTICAL_SCATTER,
        highLightValues
      );


      graphData.graphInfoView = getBloodPressureGraphInfoView();

    } else {
      const rangeText = getRangeTextFromGraph(graphData.graphData)

      highLightValues.push({
        value: rangeText,
        subValue: 'Range',
      });

      graphData = converGraphDataToVitalGraphData(graphData, GraphsTypes.LINE, highLightValues);
    }

    return (
      <VitalGraphCard
        key={item.loinc}
        vital={graphData}
        ccmDate={ccmDate}
        measure={measure}
        lastRecorded={{value: data?.valueText, date: data?.recordedDate}}
      />
    );
  };

  return (
    <View flex={1} height={400}>
      {(loading || ccmDateLoading || (isCareProgramEnabled && getContactCareProgramsLoading) || goalsLoading) ? (
        <Skeleton.Text lines={5} />
      ) : (
        <VStack flex={1}>
          {props.columnSize === 2 ? (
            <FlatList
              numColumns={2}
              data={getFilteredGraphData()}
              key={'num_column_2'}
              keyExtractor={item => "_" + item.code}
              contentContainerStyle={{justifyContent: 'space-between'}}
              renderItem={renderItem}
            />
          ) : (
            <FlatList
              numColumns={1}
              data={getFilteredGraphData()}
              key={'num_column_1'}
              keyExtractor={item => "_" + item.code}
              contentContainerStyle={{justifyContent: 'space-between'}}
              renderItem={renderItem}
            />
          )}

          {vitalData.length === 0 && !loading && (
            <EmptyStateSvg titleId="noVitalsData" />
          )}
        </VStack>
      )}
    </View>
  );
};

export default VitalsGraphView;
