import { StyleSheet } from 'react-native';
import { Colors } from '../../../../../styles/Colors';

export const styles = StyleSheet.create({
  actionContainer: {
    marginRight: 8,
  },
  personActionsPopoverBody: {
    minWidth: 170,
    height: 'auto',
    boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 1px',
    backgroundColor: '#FFF',
    paddingTop: 3,
  },
  mainMenuContent: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    cursor: 'pointer',
  },
  lastMenu: {
    paddingBottom: 15,
  },
  firstMenu: {
    paddingTop: 10,
  },
  mainMenuTxt: {
    marginLeft: 10
  },
  icons: {
    marginRight: 5,
    fontSize: 10,
    color: Colors.Custom.IconColorWhite,
  },
  btnText: {
    fontSize: 12,
    color: Colors.Custom.FontColorWhite,
    marginLeft: 10,
  },
  actionBtns: {
    height: 30,
    borderRadius: 15,
    color: Colors.Custom.IconColorWhite,
  },
  dividerStyle: {
    width: '100%',
    color: '#E4E7EC',
    backgroundColor: '#E4E7EC'
  },
  actionIcons: {
    color: Colors.Custom.SuccessColor,
    marginTop: 0,
    marginRight: 0,
    position: 'absolute',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    bottom: 10,
    left: 14
  },
  preferredIcon: {
    height: 12,
    width: 12,
    marginTop: 0,
    marginRight: 0,
    position: 'absolute',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    bottom: 12,
    left: 16
  },
  topViewActionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topViewActionIcon: {
    width: 20,
    height: 20,
  },
  topViewActionPressable: {
    height: 36,
    alignItems: 'center',
    paddingLeft: 4,
    // paddingRight: 16,
  },  
  topViewActionPressable2: {
    height: 36,
    alignItems: 'center',
    paddingLeft: 4,
    paddingRight: 16,
  },  
  topViewActionContainer2: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: '#98A2B3',
    padding: 16,
  },
  dividerStyle2: {
    height: 18,
    width: 1,
    backgroundColor: Colors.Custom.Gray200,
  },
  mainMenuContainer: {
    marginHorizontal: 24,
  },
  triggerBtn: {
    height: 32,
    width: 32,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export const antdStyles: Record<string, React.CSSProperties> = {
  drawerStyle: {
    padding: 0,
  },
  popoverStyle: {
    padding: 0,
    borderRadius: 16,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  popoverStyleOverlay: {
    padding: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  popoverStyleContent: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    borderRadius: 16,
    width: 230,
  }
}


