import {useLazyQuery} from '@apollo/client';
import {FlatList, Skeleton, Text, View, VStack} from 'native-base';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {DATE_FORMATS} from '../../../constants';
import StickyNotesApis from '../../../services/StickyNotes/StickyNotesApis';
import {Colors} from '../../../styles';
import {getAccountUUID, getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, mapAndGetLocationGroupIds} from '../../../utils/commonUtils';
import {getDateAndTimeOnLocalFormate} from '../../../utils/DateUtils';
import {IStickyNotesVersion} from './interface';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { IStickyNotes } from '../../PersonOmniView/LeftContainer/OtherDetails/interfaces';
import { isWeb } from '../../../utils/platformCheckUtils';
import useLocationGroup from '../MemebersView/customHook/useLocationGroup';

const StickyNotesVersion = (props: IStickyNotesVersion) => {
  const {noteUuid} = props;
  const accountUuid = getAccountUUID();
  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CONSUMER
  );
  const commonContextData = useContext(CommonDataContext);
  const allowedUserAccountP360LocationGroupIds = mapAndGetLocationGroupIds(allowedUserAccountP360LocationUuids, commonContextData?.accountLocationListWithEHR);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonContextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const [stateData, setStateData] = useState({
    notesList: [] as any[],
    loading: false,
    page: 1,
    offSet: 0,
    pageLimit: 10,
    isData: true
  });
  const {locationGroups} = useLocationGroup();
  const getEhrInstanceName = (locationGroupId: string) => {
    const locationGroup = locationGroups?.find((group: any) => group.code === locationGroupId);
    return locationGroup?.display || '';
  }
  const [GetNotesVersionList] = useLazyQuery(
    StickyNotesApis.GetStickNoteVersionByNoteUuid,
    {
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }
  );

  const selectedNoteData = useMemo(() => ({
    ...props?.selectedNote,
    note: props?.selectedNote?.contactNote?.content
  }), [props?.selectedNote, props?.showSelectedNote]);

  const getStickyNotesList = async () => {

    const tempStateData = {...stateData};
    const pageNumber = tempStateData.page;
    const pageLimit = tempStateData.pageLimit;
    const offset = pageNumber * pageLimit - pageLimit;
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await GetNotesVersionList({
      variables: {
        contactNotesWhereCondition: {
          noteUuid: {_eq: noteUuid},
          accountUuid: {_eq: accountUuid},
          ...(isMultiTenancyEnabled && {
            locationGroupId: {
              _in: allowedUserAccountP360LocationGroupIds,
            },
          }),
        },
        limit: tempStateData.pageLimit,
        offset: offset || 1
      },
    }).catch(() => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.contactNoteVersions?.length) {
      let notesList = response?.data?.contactNoteVersions;
      notesList = notesList?.filter((note: IStickyNotes) => {
        return note?.locationGroupId === props?.selectedNote?.locationGroupId;
      })
      const updatedList = [...stateData.notesList, ...notesList]
      const uniqueList = updatedList?.filter((note: IStickyNotes, index: number, self: IStickyNotes[]) =>
        index === self.findIndex((t: IStickyNotes) => t.id === note.id)
      );
      const filteredList = filterNotes(uniqueList);
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          notesList: filteredList,
          isData: true
        };
      });
      if(response?.data?.contactNoteVersions?.length < 10) {
        setStateData((prev) => {
          return {
            ...prev,
            isData: false
          };
        });
      }
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
          isData: false
        };
      });
    }
    setStateData(prev=> {
      return {
        ...prev,
        page: pageNumber + 1,
      }
    })
  };

  const filterNotes = (customNotesList?: any[]) => {
    let updatedNotesList:any[] = customNotesList ? customNotesList : stateData.notesList;
    if (selectedNoteData) {
      if (props?.showSelectedNote) {
        const existingNote = updatedNotesList?.find((note) => {
          return note.id === selectedNoteData?.id
        })
        if (!existingNote && selectedNoteData?.contactNote?.content) {
          updatedNotesList?.unshift(selectedNoteData)
        }
      } else {
        const existingNote = updatedNotesList?.find((note) => {
          return note.id === selectedNoteData?.id
        })
        if (existingNote) {
          const filteredList = updatedNotesList?.filter((note) => {
            return existingNote?.id !== note?.id;
          })
          updatedNotesList = filteredList;
        }
      }
    }
    return updatedNotesList
  }

  useEffect(() => {
    if (noteUuid)
    getStickyNotesList();
  }, []);

  useEffect(() => {
    const newList = filterNotes(stateData.notesList);
    setStateData((prev) => {
      return {
        ...prev,
        notesList: newList
      }
    })
  }, [props?.showSelectedNote, selectedNoteData])

  const maxHeightForMobile = props?.showSelectedNote ? (props?.height || 400): (props?.height || 400) - 200 ;

  return (
    <View height={props?.height || undefined}>
      <FlatList
        style={{
          maxHeight: isWeb() ? undefined : maxHeightForMobile,
          flex: 1,
          overflow: 'scroll',
        }}
        onEndReachedThreshold={0.01}
        onEndReached={({distanceFromEnd}) => {
          if(stateData.isData){
            getStickyNotesList()
          }
        }}

        ListFooterComponent={stateData.loading  ? <Skeleton.Text lines={4}></Skeleton.Text> : <></>}
        data={stateData.notesList}
        renderItem={({item}) => {
          if (stateData.loading) {
            return null;
          }
          return (
            <VStack flex={1} justifyContent={'center'}>
              <Text size={'mdSemibold'} color={Colors.Custom.Gray900}>
                {item?.note}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 10,
                  alignItems: 'center',
                }}
              >
                <Text style={{color: Colors.Custom.Gray500}} size={'smMedium'}>
                  {item?.updatedByUser?.name} •{' '}
                </Text>
                <Text style={{color: Colors.Custom.Gray500}} size={'smMedium'}>
                  {getDateAndTimeOnLocalFormate(
                    item?.updatedAt,
                    DATE_FORMATS.STICKY_NOTE_DATE
                  )}
                </Text>
              </View>
              { isMultiTenancyEnabled ? (
                  <Text style={{color: Colors.Custom.Gray500}} size={'smMedium'}>
                    {`EHR Instance: ${getEhrInstanceName(item?.locationGroupId)}`}
                  </Text>
                ) : null
              }
            </VStack>
          )
        }}
        ItemSeparatorComponent={({highlighted}) => (
          <View
            style={[
              {
                height: 1,
                backgroundColor: Colors.Custom.BorderColor,
                // marginLeft: 60,
                marginVertical: 20,
              },
              highlighted && {marginLeft: 0},
            ]}
          />
        )}
        keyExtractor={(item, index) =>
          `StickyNoteList_${item.noteUuid}_${index}`
        }
      />
    </View>
  );
};

export default StickyNotesVersion;
