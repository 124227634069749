import { IMlov } from "../../../../Interfaces";
import { TASK_STATUS_CODES } from "../../../../constants/MlovConst";
import { getPatientActiveProfiles } from "../../../../utils/commonUtils";
import { getPatientActiveProfileLocationIds } from "../../../../utils/commonUtils";
import { isResourceLocationActive } from "../../../../utils/commonUtils";
import { getMlovObjectFromCode } from "../../../../utils/mlovUtils";
import { IContactProfile } from "../../../RightSideContainer/TeamInbox/Conversations/interfaces";
import { ParticipantType } from "../../CalendarWidget/ParticipantAutoComplete/ParticipantEnum";
import { TASK_TABS, TaskField } from "../CareDashboardConstants";
import { ITask, ITaskFilters, IUpdatedTaskData } from "../CareDashboardInterfaces";
import { BoardType, TaskViewFrom } from "../CareDashboardTopBar/interfaces";

export const setTaskDetailsBasedOnUpdatedData = (
  task: ITask,
  updatedData: IUpdatedTaskData,
  supportingData: {
    taskStatusList: IMlov[];
    taskPriorityList: IMlov[];
  }
) => {
  switch (updatedData.field) {
    case TaskField.isDeleted:
      break;

    case TaskField.status:
      const updatedStatusId = updatedData.value as string;
      const updatedStatusMlov = supportingData.taskStatusList.filter((item) => item.id === updatedStatusId)?.[0];
      task.statusId = updatedStatusId;
      task.status = {
        id: updatedStatusId,
        code: updatedStatusMlov?.code,
        value: updatedStatusMlov?.value,
      };
      task.isCompleted = updatedStatusMlov?.code === TASK_STATUS_CODES.COMPLETED;
      break;

    case TaskField.assignee:
      const type = updatedData.additionalData?.type;
      const isAssigneeTypePatientOrProspect = type === ParticipantType.patient || type === ParticipantType.leads;
      const personData = updatedData.additionalData?.label?.props?.contactData;
      const updatedAssigneeId = updatedData.value as string;
      task.assigneeId = updatedAssigneeId;
      task.assigneeTypeCode = isAssigneeTypePatientOrProspect ? 'CONTACT' : 'USER';
      task.assigneeUser = personData;
      break;

    case TaskField.priority:
      const updatedPriorityCode = updatedData.value as string;
      const priorityMlov = getMlovObjectFromCode(updatedPriorityCode, supportingData.taskPriorityList);
      task.priorityId = priorityMlov?.id;
      task.priority = {
        id: priorityMlov?.id,
        code: priorityMlov?.code || '',
        value: priorityMlov?.value || '',
      };;
      break;
  }
}

export const isStatusMatchingFilters = (statusId: string, filters: ITaskFilters) => {
  // If board type is status and if status is changed then it will never be part of same list, thus return false
  // If patient task context then it's always status board, so if status is changed then it will never be part of same list, thus return false
  if (
    (filters.selectedTab === TASK_TABS.ASSIGNED &&
    filters.topBarData.selectedBoardType === BoardType.Status) ||
    filters.isPatientTask
  ) {
      return false;
  }
  // Check if the updated status is selected in filter as well
  const selectedStatusList = filters.topBarData.selectedStatusList || [];
  if (selectedStatusList.length) {
    return !!selectedStatusList.filter((item) => item.id === statusId).length;
  }
  // By default it should stay in the list
  return true;
}

export const addContactProfilesToTasks = (tasks: ITask[], contactProfiles?: IContactProfile[]) => {
  if (!tasks?.length || !contactProfiles?.length) {
    return tasks;
  }
  const contactProfileMap = new Map();
  contactProfiles.forEach(profile => {
    const contactId = profile?.id;
    if (contactId) {
      if (!contactProfileMap.has(contactId)) {
        contactProfileMap.set(contactId, []);
      }
      contactProfileMap.get(contactId).push(profile);
    }
  });
  return tasks.map(task => {
    if (!task?.contactId || !task?.contact) {
      return task;
    }
    const relevantProfiles = contactProfileMap.get(task.contactId) || [];
    return {
      ...task,
      contact: {
        ...task.contact,
        contactProfiles: relevantProfiles,
      }
    };
  });
};

export const addContactProfilesToTask = (task: ITask, contactProfiles?: IContactProfile[]) => {
  return {
    contactProfiles: contactProfiles,
    ...task.contact
  };
};

export const getContactActiveStatus = (task: ITask, contactProfiles?: IContactProfile[]) => {
  const activeProfiles = getPatientActiveProfiles(contactProfiles || task?.contact?.contactProfiles);
  const activeLocationIds = getPatientActiveProfileLocationIds(activeProfiles);
  const taskLocationId = getTaskLocationId(task);
  return isResourceLocationActive(activeLocationIds, taskLocationId);
};

export const getUserActiveInTaskLocationStatus = (task: ITask, primaryLocationIds: string[]) => {
  const taskLocationId = getTaskLocationId(task);
  const hasLocationAccess = isResourceLocationActive(primaryLocationIds, taskLocationId);
  return hasLocationAccess;
}

export const getTaskLocationId = (task: ITask) => {
  return task?.taskLocations?.[0]?.locationId || '';
}

export const getLocationIdsParamForView = (
  allAllowedLocationIds: string[], 
  primaryLocationIds: string[], 
  isMultiTenancyEnabled: boolean,
  viewFrom?: TaskViewFrom,
  filterDataAccountLocationIds?: string[]
) => {
  if (!isMultiTenancyEnabled) {
    return {};
  }
  if (filterDataAccountLocationIds?.length) {
    return { accountLocationIds: filterDataAccountLocationIds };
  }
  const locationIds = !!viewFrom && [TaskViewFrom.patientTaskManager, TaskViewFrom.sidecar].includes(viewFrom)
    ? allAllowedLocationIds
    : primaryLocationIds;
  return { accountLocationIds: locationIds };
};

export const isPriorityMatchingFilters = (priorityId: string, filters: ITaskFilters) => {
  // If board type is priority and if priority is changed then it will never be part of same list, thus return false
  if (
    filters.selectedTab === TASK_TABS.ASSIGNED &&
    filters.topBarData.selectedBoardType === BoardType.Priority
  ) {
      return false;
  }
  // Check if the updated priority is selected in filter as well
  const selectedPriorityList = filters.isPatientTask ?
    filters.patientTaskFilters?.formData?.selectedPriorityTypes || [] :
    filters.topBarData.selectedPriorityList || [];
  if (selectedPriorityList.length) {
    return !!selectedPriorityList.filter((item: IMlov) => item.id === priorityId).length;
  }
  // By default it should stay in the list
  return true;
}

export const isAssigneeMatchingFilters = (assigneeId: string, filters: ITaskFilters) => {
  // Check if the updated assignee is selected in filter as well
  const selectedUsersList = filters.topBarData.selectedUsers || [];
  if (selectedUsersList.length) {
    return !!selectedUsersList.filter((item) => item.value === assigneeId).length;
  }
  // By default it should stay in the list
  return true;
}
