import {useState, useEffect, useContext} from 'react';
import {getUserPracticeLocations} from './commonUtils';
import {CONFIG_CODES} from '../constants/AccountConfigConst';
import { CommonDataContext } from '../context/CommonDataContext';

export interface IRestrictedEmployeeParams {
  isRestrictedParticularLocationEmployeeEnabled: boolean;
  restrictedLocationIds: string[];
  restrictedEmployerIds: string[];
  loggedInUserUuidForShowAllEmployer: string[];
  isLoading?: boolean;
}

export interface IRestrictedEmployeeProfileConfig {
  IS_RESTRICTED_EMPLOYEE_PROFILE_ENABLED: boolean;
  RESTRICTED_LOCATION_IDS: string[];
  RESTRICTED_EMPLOYER_IDS: string[];
  LOGGED_IN_USER_UUID_FOR_SHOW_ALL_EMPLOYER: string[];
}
// Create a new hook to handle the restricted employee profile logic
export const useRestrictedEmployeeProfile = () => {
  const commonData = useContext(CommonDataContext);
    // Extract only the specific configuration we need
  const restrictedProfileConfig = commonData?.accountConfigData?.[CONFIG_CODES.RESTRICTED_EMPLOYEE_PROFILE_VIEW]?.accountConfigurations?.[0]?.value;
  const [restrictedEmployeeProfileData, setRestrictedEmployeeProfileData] =
    useState<IRestrictedEmployeeParams>({
      isRestrictedParticularLocationEmployeeEnabled: false,
      restrictedLocationIds: [],
      restrictedEmployerIds: [],
      loggedInUserUuidForShowAllEmployer: [],
      isLoading: false,
    });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRestrictedProfileData = async () => {
      setIsLoading(true);
      // Default configuration - no restrictions
      const defaultParams: IRestrictedEmployeeParams = {
        isRestrictedParticularLocationEmployeeEnabled: false,
        restrictedLocationIds: [], //account location uuid
        restrictedEmployerIds: [], //employer uuid
        loggedInUserUuidForShowAllEmployer: [], //logged in user uuid
      };

      try {
        // Get restricted profile view configuration
        if (!restrictedProfileConfig?.length) {
          setRestrictedEmployeeProfileData(defaultParams);
          return;
        }

        // Parse configuration
        let config: IRestrictedEmployeeProfileConfig;
        try {
          config = JSON.parse(restrictedProfileConfig);
        } catch (error) {
          setRestrictedEmployeeProfileData(defaultParams);
          return;
        }

        // Skip if restrictions are not enabled in config
        if (!config.IS_RESTRICTED_EMPLOYEE_PROFILE_ENABLED) {
          setRestrictedEmployeeProfileData(defaultParams);
          return;
        }

        // Get user's practice locations
        const userLocationIds = await getUserPracticeLocations();

        // Apply restrictions based on location access
        const restrictedParams: IRestrictedEmployeeParams = {
          isRestrictedParticularLocationEmployeeEnabled: true,
          restrictedLocationIds: config.RESTRICTED_LOCATION_IDS,
          restrictedEmployerIds: config.RESTRICTED_EMPLOYER_IDS,
          loggedInUserUuidForShowAllEmployer: config.LOGGED_IN_USER_UUID_FOR_SHOW_ALL_EMPLOYER,
        };

        // If user has no locations, apply restrictions
        if (userLocationIds?.length === 0) {
          setRestrictedEmployeeProfileData(restrictedParams);
          return;
        }

        // Check if user has access to any restricted locations
        const hasAccessToRestrictedLocation = userLocationIds.some(
          (locationId) => config.RESTRICTED_LOCATION_IDS.includes(locationId)
        );

        // Set final restrictions
        setRestrictedEmployeeProfileData(
          hasAccessToRestrictedLocation ? defaultParams : restrictedParams
        );
      } catch (error) {
        setRestrictedEmployeeProfileData(defaultParams);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRestrictedProfileData();
  }, [restrictedProfileConfig]); // Add restrictedProfileConfig as dependency since we use it in the effect

  return {
    ...restrictedEmployeeProfileData,
    isLoading
  };
};
