import {useLazyQuery, useMutation} from '@apollo/client';
import {useContext, useEffect, useState} from 'react';
import {
  CARESTUDIO_APOLLO_CONTEXT,
  CARESTUDIO_PROXY_TO_CRM_CONTEXT,
} from '../../../constants/Configs';
import {ITimezone} from '../../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../../services/Location/UserPracticeLocationQueries';
import * as UserPreferencesQueries from '../../../services/UserPreferences/UserPreferencesQueries';
import {getCurrentTimeZone} from '../../../utils/DateUtils';
import {ModalActionAntSelect} from '../ModalActionCommonComponent/ModalActionAntSelect';
import {HStack, Icon, Text, View} from 'native-base';
import {Colors} from '../../../styles';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {MLOV_CATEGORY} from '../../../constants';
import {USER_PREFERENCES_TYPES} from '../../../constants/MlovConst';
import {IUserPreference} from '../../../services/UserPreferences/interfaces';
import {getUserUUID} from '../../../utils/commonUtils';
import {countryListArr} from '../../../constants/ConstantValues';


export interface IProps {
    className?: string;
    showLabel?: boolean;
    label?: string;
    selectedTimezoneId?: string; // matches on timezone.uuid
    selectedTimezoneValue?: string; // matches on timezoneList.displayValue
    headers?: any;
    toShowSelectDropdownArrowSvg?: boolean;
    isRequired?: boolean;
    showErrors?: boolean;
    showSelectBorder?: boolean;
    selectColor?: string;
    labelFontSize?: any;
    popupClassName?: string;
    isDisabled?: boolean;
    fontColor?: string;
    memorizeTimezone?: boolean;
    width?: number;
  
    onChange: (selectedTimezone: ITimezone | undefined) => void;
    onApiError?: (error: any) => void;
    onLoadingStatusChange?: (isLoading: boolean) => void;
    customStyle?: any;
  }
  
  type ITimezoneOption = ITimezone & {
    timezone: string;
    uuid: string;
  };
  
  interface IComponentState {
    selectedTimezone?: ITimezoneOption;
    isLoading: boolean;
    timezoneList: ITimezoneOption[];
    lastUsedTimezoneUserPreference?: IUserPreference;
  }
  
  let defaultTimezoneValue: any;
  let timezoneListCache: ITimezoneOption[] = [];
  let memorizedTimezoneId: string;
  const currentTimezone = getCurrentTimeZone();
  
  function getTimezone(
    timezoneList: ITimezoneOption[],
    timezoneValue?: string,
    timezoneId?: string,
    memorizedTimezone?: boolean
  ) {
    let key = 'displayValue';
    let value = timezoneValue;
  
    defaultTimezoneValue = defaultTimezoneValue || currentTimezone;
  
    if (!timezoneValue && !timezoneId) {
      if (memorizedTimezone && memorizedTimezoneId) {
        key = 'uuid';
        value = memorizedTimezoneId;
      } else {
        key = 'displayValue';
        value = defaultTimezoneValue;
      }
    } else if (timezoneId) {
      key = 'uuid';
      value = timezoneId;
    } else if (timezoneValue) {
      key = 'displayValue';
      value = timezoneValue;
    }
  
    if (!timezoneList?.length) {
      return undefined;
    }
  
    const timezone = timezoneList.find((timezone) => {
      return (timezone as any)[key] === value;
    });
  
    return timezone || timezoneList[0];
  }
  
  export function filterTimezoneListByUTC(timezoneList: ITimezoneOption[]) {
    return timezoneList.sort((a, b) => {
      if (a.utcOffset && b.utcOffset) {
        const offset1 = parseFloat(a.utcOffset.substring(3).replace(':', '.'));
        const offset2 = parseFloat(b.utcOffset.substring(3).replace(':', '.'));
        if (offset1 > offset2) {
          return 1;
        }
      }
      return -1;
    });
  }
  
 export function getTimezonesByCountry(
    timezoneList: ITimezoneOption[],
    country: string
  ) {
    const timezones = timezoneList.filter((timezone) => {
      const timeZoneCountry = timezone.timezone.split('/')[0];
      return timeZoneCountry === country;
    });
    return filterTimezoneListByUTC(timezones);
  }
  
  function getFilteredTimeZoneList(timezoneList: ITimezoneOption[], selectedTimezone?: ITimezoneOption) {
    let filteredList: ITimezoneOption[] = [];
    const countryList = countryListArr;
  
    countryList.forEach((country) => {
      filteredList = filteredList.concat(
        getTimezonesByCountry(timezoneList, country)
      );
    });
  
    if (currentTimezone) {
      const isCurrentTimezonePresent = filteredList.find(timezone => timezone?.timezone === currentTimezone);
      if (!isCurrentTimezonePresent) {
        const currentTimezoneObj = timezoneList.find(timezone => timezone?.timezone === currentTimezone);
        if (currentTimezoneObj) {
          filteredList.push(currentTimezoneObj);
        }
      }
    }
  
    return filteredList;
  }

export const useTimeZoneSelect = (props: IProps) => {
    const [componentState, setComponentState] = useState<IComponentState>({
        isLoading: false,
        timezoneList: [],
      });
    
      const userId = getUserUUID();
    
      const {CARE_STUDIO_MLOV} = useContext(CommonDataContext);
      const userPreferenceTypes =
        getMlovListFromCategory(
          CARE_STUDIO_MLOV,
          MLOV_CATEGORY.USER_PREFERENCES_TYPE
        ) || [];
      const lastUsedTimezoneUserPreferenceType = getMlovIdFromCode(
        userPreferenceTypes,
        USER_PREFERENCES_TYPES.LAST_USED_TIMEZONE
      );
    
      const {isRequired = true, showLabel = true, label} = props;
    
      const [getLastSavedTimezone, { loading: isGetLastSavedTimezoneAPILoading }] = useLazyQuery(
        UserPreferencesQueries.GetUserPreferencesByTypeId,
        {
          context: {
            service: CARESTUDIO_APOLLO_CONTEXT,
            ...(props.headers && {
              headers: props.headers,
            }),
          },
          variables: {
            userPreferencesTypeId: lastUsedTimezoneUserPreferenceType,
            userId: userId,
          },
          fetchPolicy: 'no-cache',
          onCompleted: (res) => {
            try {
              if (res?.userPreferences?.[0]) {
                const userPreference = res.userPreferences[0] as IUserPreference;
                const preferencesJson = JSON.parse(userPreference.preferencesJson);
                if (preferencesJson?.timezoneId) {
                  memorizedTimezoneId = preferencesJson.timezoneId;
                }
                setComponentState((prev) => ({
                  ...prev,
                  lastUsedTimezoneUserPreference: userPreference,
                }));
              }
            } catch (error) {}
            initializeSelectedTimezone();
          },
          onError: () => {
            initializeSelectedTimezone();
          },
        }
      );
    
      const [setLastSavedTimezone] = useMutation(
        UserPreferencesQueries.CreateOrUpdateUserPreference,
        {
          context: {
            service: CARESTUDIO_APOLLO_CONTEXT,
            ...(props.headers && {
              headers: props.headers,
            }),
          }
        }
      );
    
      const [getTimezonesQuery] = useLazyQuery(
        UserPracticeLocationQueries.GetAllTimezones,
        {
          context: {
            service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
            ...(props.headers && {
              headers: props.headers,
            }),
          },
          fetchPolicy: 'no-cache',
          onCompleted: (res) => {
            if (res?.timezones?.length) {
              timezoneListCache = (res.timezones || []).map((timezone: ITimezone) => {
                return {
                  ...timezone,
                  label: `${timezone.displayValue}`,
                };
              });
    
              const selectedTimezone = getTimezone(
                timezoneListCache,
                componentState.selectedTimezone?.displayValue || props.selectedTimezoneValue,
                componentState.selectedTimezone?.uuid || props.selectedTimezoneId,
                props.memorizeTimezone,
              );
    
    
              setComponentState((prev) => ({
                ...prev,
                selectedTimezone: selectedTimezone,
                isLoading: false,
                timezoneList: getUserFacingTimezones(timezoneListCache, selectedTimezone),
              }));
    
              props?.onLoadingStatusChange?.(false);
            } else {
              props.onChange(undefined);
              setComponentState((prev) => ({
                ...prev,
                selectedTimezone: undefined,
                isLoading: false,
                timezoneList: [],
              }));
            }
          },
          onError: (error) => {
            setComponentState((prev) => ({...prev, isLoading: false}));
            props?.onLoadingStatusChange?.(false);
          },
        }
      );
    
      function getUserFacingTimezones(timezoneList: ITimezone[], selectedTimezone?: ITimezoneOption) {
        const filteredTimezoneList = getFilteredTimeZoneList(timezoneList, selectedTimezone);
        if (selectedTimezone?.uuid) {
          const isSelectedTimezonePresent = filteredTimezoneList?.find(timezone => timezone.uuid === selectedTimezone.uuid);
          if (!isSelectedTimezonePresent) {
            filteredTimezoneList?.unshift(selectedTimezone);
          }
        }
        return filteredTimezoneList.filter((timezone) => (timezone.isUserFacing || (selectedTimezone?.uuid && selectedTimezone?.uuid === timezone.uuid)));
      }
    
      function initializeSelectedTimezone() {
        setComponentState((prev) => ({ ...prev, isLoading: true }));
        props?.onLoadingStatusChange?.(true);
        if (!timezoneListCache?.length) {
          getTimezonesQuery();
        } else {
          const selectedTimezone = getTimezone(
            timezoneListCache,
            componentState.selectedTimezone?.displayValue || props.selectedTimezoneValue,
            componentState.selectedTimezone?.uuid || props.selectedTimezoneId,
            props.memorizeTimezone,
          );
          setComponentState((prev) => ({
            ...prev,
            selectedTimezone: selectedTimezone,
            isLoading: false,
            timezoneList: getUserFacingTimezones(timezoneListCache, selectedTimezone),
          }));
        }
      }
    
      function updateMemorizeTimezone(timezone?: ITimezoneOption) {
        if (props.memorizeTimezone && timezone?.uuid) {
          setLastSavedTimezone({
            variables: {
              object: {
                ...(componentState.lastUsedTimezoneUserPreference?.id && {
                  id: componentState.lastUsedTimezoneUserPreference.id,
                }),
                preferencesJson: JSON.stringify({timezoneId: timezone.uuid, timezone: timezone?.displayValue }),
                preferencesModelVersion: 'v1',
                userPreferencesTypeId: lastUsedTimezoneUserPreferenceType,
                userId: userId,
              },
            },
            onCompleted: () => {
              memorizedTimezoneId = timezone.uuid;
            }
          });
        }
      }
    
      useEffect(() => {
        if (props.memorizeTimezone) {
          setComponentState((prev) => ({...prev, isLoading: true}));
          getLastSavedTimezone();
        } else {
          initializeSelectedTimezone();
        }
      }, []);
    
      useEffect(() => {
        if (timezoneListCache?.length) {
          const timezone = getTimezone(
            timezoneListCache,
            props.selectedTimezoneValue,
            props.selectedTimezoneId,
            props.memorizeTimezone
          );
          setComponentState((prev) => ({...prev, selectedTimezone: timezone}));
        }
      }, [props.selectedTimezoneId, props.selectedTimezoneValue]);
    
      useEffect(() => {
        if (componentState.selectedTimezone) {
          props.onChange(componentState.selectedTimezone);
        }
      }, [componentState.selectedTimezone?.uuid]);

      return {
        componentState,
        setComponentState,
        getTimezonesQuery,
        getLastSavedTimezone,
        updateMemorizeTimezone,
        initializeSelectedTimezone,
      }
};