import React, { useState, useEffect } from 'react';
import {View, Text} from 'react-native';
import {
  getPatientNotesLogModeOfContactTabIcon,
  PatientNotesLogModeOfContact,
} from '../constants';
import {Pressable} from 'native-base';
import { Popover, Button } from 'antd';
import {Colors} from '../../../styles/Colors';
import {IMlov} from '../../../Interfaces/CommonInterfaces';
import ChevronDownSvg from '../../common/Svg/ChevronDownSvg';

const CommunicationModeSelector = (props: {
  mode: IMlov[];
  activeMode: string;
  onModeChange: (mode: IMlov) => void;
  disabled?: boolean;
}) => {
  const [visibleModes, setVisibleModes] = useState<IMlov[]>([]);
  const [hiddenModes, setHiddenModes] = useState<IMlov[]>([]);

  useEffect(() => {
    setVisibleModes(props.mode.slice(0, 4));
    setHiddenModes(props.mode.slice(4));
  }, [props.mode]);

  const handleModeSelect = (mode: IMlov, isFromPopover: boolean) => {
    if (!props?.disabled) {
      if (isFromPopover) {
        const newVisibleModes = [...visibleModes.slice(0, -1), mode];
        const lastVisibleMode = visibleModes[visibleModes.length - 1];
        const newHiddenModes = [
          ...hiddenModes.filter(m => m.code !== mode.code),
          lastVisibleMode
        ];
        
        setVisibleModes(newVisibleModes);
        setHiddenModes(newHiddenModes);
      }
      props.onModeChange(mode);
    }
  };

  const ModeButton = ({ mode, isFromPopover = false }: { mode: IMlov, isFromPopover?: boolean }) => {
        const isActive = mode.code === props.activeMode;
        const Icon = getPatientNotesLogModeOfContactTabIcon(mode.code);
        return (
          <Pressable
            paddingX={'6px'}
            paddingY={'4px'}
            borderWidth={0.5}
            borderRadius={4}
            style={{
              borderColor: isActive ? Colors.FoldPixel.GRAY150 : 'transparent',
              backgroundColor: isActive
                ? Colors.FoldPixel.GRAY50
                : 'transparent',
            }}
            disabled={props?.disabled}
            _hover={{
              backgroundColor: Colors.FoldPixel.GRAY50,
            }}
            onPress={() => handleModeSelect(mode, isFromPopover)}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {Icon ? (
                <Icon
                  width={16}
                  height={16}
                  customStrokeColor={
                    isActive
                      ? Colors.FoldPixel.GRAY300
                      : Colors.FoldPixel.GRAY250
                  }
                />
              ) : (
                <></>
              )}
              <Text
                style={{
                  marginLeft: '4px',
                  color: isActive
                    ? Colors.FoldPixel.GRAY400
                    : Colors.FoldPixel.GRAY300,
                }}
              >
                {mode.value}
              </Text>
            </View>
          </Pressable>
        );
      };
      return (
        <div className="patient-notes-editor-header">
          {visibleModes.map((mode) => (
            <ModeButton key={mode.code} mode={mode} />
          ))}
          
          {hiddenModes.length > 0 && (
            <Popover
              content={
                <div>
                  {hiddenModes.map((mode) => (
                    <ModeButton
                      key={mode.code}
                      mode={mode}
                      isFromPopover={true}
                    />
                  ))}
                </div>
              }
              trigger="click"
              overlayInnerStyle={{padding: 0, borderRadius: 16}}
              overlayStyle={{padding: 0}}
              overlay={{padding: 0}}
              style={{padding: 0, borderRadius: 16}}
              placement={'bottom'}
            >
              <Button
                type="text"
                icon={<ChevronDownSvg size={10}/>}
                style={{
                  padding: '4px 6px',
                  height: 'auto',
                }}
              />
            </Popover>
          )}
    </div>
  );
};

export default React.memo(CommunicationModeSelector);
