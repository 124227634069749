
import React from 'react';
import CarePlan from "../../../../../PersonOmniView/MiddleContainer/CarePlan/CarePlan";
import {useSelectedStepInCareProgram} from './hooks/useSelectedStepInCareProgram';
import { useContactCareProgramContext } from '../../ContactCareProgram.context';
import { DISABLE_STATUS_CODES } from '../../../../../../constants/MlovConst';


const CarePlanStepView: React.FC = React.memo(() => {
  const {accountLocationUuid, personData} = useSelectedStepInCareProgram();
  const {state} = useContactCareProgramContext();
  const isDisabled = !state.forceReadonly ? DISABLE_STATUS_CODES.includes(state.contactCareProgramDetails?.status?.code || '') : true;
  return (
    <CarePlan
      accountLocationUuid={accountLocationUuid ?? ''}
      contactUuid={personData?.uuid ?? ''}
      contactName={personData?.name ?? ''}
      contactId={personData?.id ?? -1}
      personData={personData}
      careProgramStatusId={state.contactCareProgramDetails?.statusId ?? ''}
      forceReadonly={isDisabled}
    />
  );
});

export default CarePlanStepView;
