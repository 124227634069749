import {Button, Space} from 'antd';
import { HStack, Spacer, Text, VStack } from 'native-base';
import {useRef} from 'react';
import { useIntl } from 'react-intl';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../../styles';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {DisplayText} from '../DisplayText/DisplayText';
import FHActionPopover from '../FHActionPopover/FHActionPopover';
import ModalActionBtn from '../ModalActionBtn/ModalActionBtn';
import { testID } from '../../../testUtils';
import {LeftOutlined} from '@ant-design/icons';

import {getCaslAbility} from '../../../utils/commonUtils';
import FoldPermitCan from '../../CommonComponents/FoldPermitCan/FoldPermitCan';

export const ModalActionTitle = (props: any) => {
  const {
    titleColor,
    buttonList,
    titleFontSize,
    actionButtons,
    onActionClick,
    subtitleColor,
    titleSize,
    text,
    showCustomButton,
    customButton,
    leftButton,
    titleNode,
    rightButton,
    leftBackButton,
    fontWeight,
    actionEvent,
    resourceCode,
    title
  } = props;
  const intl = useIntl();

  const btnActionItemView = (button: any, index: number) => {
    return (
      <FoldButton
        key={button?.btnPrefix ? `${button?.btnPrefix}_${index}` : index}
        nativeProps={{
          variant: button.variant,
          onPress: () => {
            button.onClick();
          },
          leftIcon:button.leftIcon,
          rightIcon:button.rightIcon,
          isDisabled: button.isDisabled,
          isLoading: button.isLoading,
          marginBottom: 1,
          marginTop: 1,
        }}
        customProps={{
          btnText: ( intl.formatMessage({id: button.btnText})),
          withRightBorder: false,
        }}
      ></FoldButton>
    )
  }
  return (
    <>
        <HStack
        style={[
          styles.hStackContainer,
          !props.isHeadNotSticky && false ? styles.stickyHeader : {}
        ]}
        alignItems={'flex-start'}
        justifyContent='space-between'
        flexWrap={'nowrap'}
        flex={1}
        >
        {leftBackButton}
        <View style={[
          styles.titleContainer,
          !!leftBackButton && styles.titleContainerWithLeftButton,
          { flex: 1, marginRight: 16 }
        ]}>
            {props.titleNode && props.titleNode}
            {props.title ? (
              <Text
                flexWrap={'wrap'}
                fontSize={titleSize ? titleSize : null}
                {...(!fontWeight && {size: 'xslMedium'})}
                {...(fontWeight && { fontWeight })}
                color={Colors.FoldPixel.GRAY400}
                alignSelf={'flex-start'}
                {...testID(props?.title)}
              >
             {   intl.formatMessage({id: props?.title || ''})}

              </Text>
            ) : text ? (
              <Text
                fontSize={titleSize ? titleSize : null}
                size={'xslMedium'}
                color={titleColor || Colors.FoldPixel.GRAY400}
                alignSelf={'flex-start'}
                {...testID(text)}
              >
                {text}
              </Text>
            ) : (
              <Text fontSize={titleFontSize || ''} color={titleColor || ''}>
                {''}
              </Text>
            )}
            {props.subTitle && (
              <Text
                width={'100%'}
                height={'auto'}
                size={12}
                style={styles.subtitle}
                color={subtitleColor || ''}
                {...testID(props.subTitle)}
              >
                {props.subTitle}
              </Text>
            )}
            {props?.subtitleBlock}
        </View>

        <HStack justifyContent='flex-end' space={2} alignItems='center' flexShrink={0}>
           {leftButton && leftButton}
            {buttonList &&
              buttonList.map((button: any, index:number) => {
                if (button.show) {
                  return (
                    <>
                    {button?.resourceCode && <FoldPermitCan
                      key={button?.btnPrefix ? `${button?.btnPrefix}_${index}` : index}
                      resource={button?.resourceCode}
                      action={button.actionEvent}
                      component={btnActionItemView(button , index)}
                      />}
                    {!button.resourceCode && btnActionItemView(button , index)}
                    </>
                  );
                }
              })}
            {rightButton && rightButton}
            <View>
              {actionButtons && (
                <FHActionPopover
                  actions={actionButtons}
                  onActionClick={(code) => {
                    onActionClick(code);
                  }}
                />
              )}
            </View>
            {showCustomButton && customButton}
          </HStack>
        </HStack>
    </>
  );
};

const styles = StyleSheet.create({
  hStackContainer: {
    marginTop: 0,
    marginHorizontal: 0,
    minHeight: 40,
  },
  fullWidth: {
    width: '100%',
  },
  stickyHeader: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    padding: 24,
    backgroundColor: '#fff',
    zIndex: 1
  },
  titleContainer: {
    flexDirection: 'column',
    minWidth: 0,
    overflow: 'hidden',
  },
  titleContainerWithLeftButton: {
    marginLeft: 6,
  },
  subtitle: {
    marginTop: 10,
  },
});
