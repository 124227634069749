import React from 'react';
import {QUICK_ACTION_CODES} from '../../constants/ActionConst';
import {Colors} from '../../styles';
import TasksIconSvg from '../common/Svg/GlobalActionSvg/TasksIconSvg';
import VideoMeeting from '../common/Svg/GlobalActionSvg/VideoMeeting';
import VoiceCall from '../common/Svg/GlobalActionSvg/VoiceCall';
import ChatActionSvg from '../common/Svg/PersonActionSvgIcons/ChatActionSvg';
import EFaxActionSvg from '../common/Svg/PersonActionSvgIcons/EFaxActionSvg';
import EmailActionSvg from '../common/Svg/PersonActionSvgIcons/EmailActionSvg';
import SmsActionSvg from '../common/Svg/PersonActionSvgIcons/SmsActionSvg';
import {IMlov} from '../../Interfaces';
import { TASK_ALERT_CATEGORY_CODES } from "../../constants/MlovConst";
import { AUTOMATION_ICONS } from "../common/CircleIconView/CustomAutomationIcon";
import DocumentTextSvg from '../common/Svg/DayOptimization/DocumentTextSvg';
import PhoneSvg from '../common/Svg/DayOptimization/PhoneSvg';
import PersonNewSvg from '../common/Svg/DayOptimization/PersonNewSvg';

export enum PatientNotesLogModeOfContact {
  GENERAL = 'general',
  VOICE_CALL = 'call',
  IN_PERSON = 'in-person',
  SMS = 'sms',
  CHAT = 'chat',
  EMAIL = 'email',
}

export const getPatientNotesLogModeOfContactTabIcon = (mode: PatientNotesLogModeOfContact | string) : React.ElementType => {
  switch (mode) { 
    case PatientNotesLogModeOfContact.GENERAL:
      return DocumentTextSvg;
    case PatientNotesLogModeOfContact.VOICE_CALL:
      return PhoneSvg;
    case PatientNotesLogModeOfContact.IN_PERSON:
      return PersonNewSvg;
    case PatientNotesLogModeOfContact.SMS:
      return SmsActionSvg;
    case PatientNotesLogModeOfContact.CHAT:
      return ChatActionSvg;
    case PatientNotesLogModeOfContact.EMAIL:
      return EmailActionSvg;
  }
  return DocumentTextSvg;
}

export const SIDE_CAR_ACTIONS = {
  USER_MISMATCH: 'USER_MISMATCH',
  CHAT: 'CHAT',
  TASKS: 'TASKS',
  INSIGHTS: 'INSIGHTS',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
} as const;

export const SIDECAR_DRAWER_WIDTH = '100%';

export const ASSESSMENT_FORM_CATEGORY = 'CLINICAL_ASSESSMENT';
export const INSIGHTS_JOURNEYS_STATUSES = ['active'];

export const FORM_SCORE_OPERATIONS = {
  SUM: 'SUM',
};

export const SIDECAR_REFERRAL_MANAGEMENT ={
  DRAFT : 'DRAFT',
  SENT : 'SENT',
  ADD_PROVIDER : 'Add Provider',
  ALL : 'ALL'
}

export const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const SIDE_CAR_MODULE_CODE = {
  SIDE_CAR_ACTIONS: 'sidecar/actions',
  SIDE_CAR_WINDOW_ACTIONS: 'sidecar/mainWindow',
} as const;

export const SIDE_CAR_CREATE_OPTION = [
  {
    code: QUICK_ACTION_CODES.ADD_NEW_TASK,
    key: 'task',
    icon: (
      <TasksIconSvg
        width={16}
        height={16}
        customStrokeColor={Colors.Custom.Gray400}
      />
    ),
  },
  {
    code: QUICK_ACTION_CODES.CREATE_VOICE_MEETING,
    key: 'voiceCall',
    icon: (
      <VoiceCall
        width={16}
        height={16}
        customStrokeColor={Colors.Custom.Gray400}
      />
    ),
  },
  {
    code: QUICK_ACTION_CODES.CREATE_CHAT,
    key: 'chat',
    icon: (
      <ChatActionSvg
        width={16}
        height={16}
        customStrokeColor={Colors.Custom.Gray400}
      />
    ),
  },
  {
    code: QUICK_ACTION_CODES.CREATE_SMS,
    key: 'sms',
    icon: (
      <SmsActionSvg
        width={16}
        height={16}
        customStrokeColor={Colors.Custom.Gray400}
      />
    ),
  },
  {
    code: QUICK_ACTION_CODES.CREATE_EMAIL,
    key: 'email',
    icon: (
      <EmailActionSvg
        width={16}
        height={16}
        customStrokeColor={Colors.Custom.Gray400}
      />
    ),
  },
  {
    code: QUICK_ACTION_CODES.CREATE_EFAX,
    key: 'faxTab',
    icon: (
      <EFaxActionSvg
        width={16}
        height={16}
        customStrokeColor={Colors.Custom.Gray400}
      />
    ),
  },
  {
    code: QUICK_ACTION_CODES.CREATE_VIDEO_MEETING,
    key: 'videoMeeting',
    icon: <VideoMeeting width={16} height={16} />,
  },
];
export const SIDE_BAR_CARE_MENU_CODES = {
  SUMMARY: 'summary',
  ALERTS: 'alerts',
  REFERRAL: 'referral',
  TASKS: 'tasks',
  CHAT: 'chat'
};

export const SIDE_CAR_INSIGHTS_MENU_BAR = [
  {
    code: SIDE_BAR_CARE_MENU_CODES.SUMMARY,
    value: 'Summary',
  },
  {
    code: SIDE_BAR_CARE_MENU_CODES.ALERTS,
    value: 'Alerts',
  },
];

export const ALERT_ACTION_CODES = {
  SUGGESTED_ACTION: 'suggestedAction',
  CREATE_TASK: 'create_task',
  SCHEDULE_APPOINTMENT: 'schedule_appointment',
  SEND_ASSESSMENT: 'send_assessment',
  SEND_PATIENT_EDUCATION: 'send_patient_education',
  RESOLVE: 'resolve',
  SNOOZE: 'snooze',
  DISMISS: 'dismiss',
  MOVE_TO_PENDING: 'move_to_pending',
  ADD_NOTE: 'add_patient_clinical_note',
  ADD_TO_NOTE_IN_EHR: 'add_patient_ehr_note',
  CREATE_ORDER: 'create_order',
  REVIEW_DIAGNOSIS_GAP: 'diagnosis_gap',
  ADD_IMAGING_ORDER: 'add_imaging_order',
  RUN_AUTOMATION: 'run_automation',
};

export const CATEGORY_CODES_OF_ALERT_ACTIONS ={
  MANAGEMENT_ACTIONS: 'managementsActions',
  STATUS_ACTIONS: 'statusActions',
  DIAGNOSIS_GAP_ACTIONS: 'diagnosisGapActions',
};

export const ALERTS_ACTIONS_MENU = [
  {
    code: ALERT_ACTION_CODES.REVIEW_DIAGNOSIS_GAP,
    value: 'Review',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.DIAGNOSIS_GAP_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.CREATE_TASK,
    value: 'Create Task',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.SCHEDULE_APPOINTMENT,
    value: 'Schedule Appointment',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.SEND_ASSESSMENT,
    value: 'Send Assessment',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.SEND_PATIENT_EDUCATION,
    value: 'Send Patient Education',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.ADD_NOTE,
    value: 'Create Note',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  //comment for now, untill PO call 
  {
    code: ALERT_ACTION_CODES.ADD_TO_NOTE_IN_EHR,
    value: 'Add Note To Result',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.ADD_IMAGING_ORDER,
    value: 'Add Imaging Order',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.RUN_AUTOMATION,
    value: 'Run Automation',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.MANAGEMENT_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.RESOLVE,
    value: 'Resolve',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.STATUS_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.SNOOZE,
    value: 'Snooze',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.STATUS_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.MOVE_TO_PENDING,
    value: 'Move To Pending',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.STATUS_ACTIONS,
  },
  {
    code: ALERT_ACTION_CODES.DISMISS,
    value: 'Dismiss',
    categoryCode: CATEGORY_CODES_OF_ALERT_ACTIONS.STATUS_ACTIONS,
  },
];

export const ALERT_CATEGORY_CODES_MAPPED = {
  [ALERT_ACTION_CODES.DISMISS]: TASK_ALERT_CATEGORY_CODES.DISMISSED,
  [ALERT_ACTION_CODES.RESOLVE]: TASK_ALERT_CATEGORY_CODES.RESOLVED,
  [ALERT_ACTION_CODES.SNOOZE]: TASK_ALERT_CATEGORY_CODES.SNOOZED,
};

export const NODE_INPUT_KEYS = {
  EDUCATION_CONTENT: 'educationContent',
  PRIORITY: 'priority',
  PATIENT_FACING_TITLE: 'patientFacingTitle',
  COMMUNICATION_VIA_NODE: 'communicationViaNode',
  EMAIL_TEMPLATE: 'emailTemplate',
  SMS_BODY: 'smsBody',
};

export const EDUCATION_CONTENT_NODE_DETAILS = {
  code: AUTOMATION_ICONS.EDUCATION_CONTENT,
  value: 'Send Educational Content',
  type: 'CareJourneyContentV3',
  userInputFieldListKeys: [
    NODE_INPUT_KEYS.EDUCATION_CONTENT,
    NODE_INPUT_KEYS.PRIORITY,
    NODE_INPUT_KEYS.PATIENT_FACING_TITLE,
    NODE_INPUT_KEYS.COMMUNICATION_VIA_NODE,
    NODE_INPUT_KEYS.EMAIL_TEMPLATE,
  ],
};

export const ALERT_SEVERITY_NAMES = {
  SEVERE: 'Severe',
  MODERATE: 'Moderate',
  ROUTINE: 'Routine',
};
