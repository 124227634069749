import { useContext, useState } from "react";
import { CONFIG_CODES } from "../../constants/AccountConfigConst";
import FeatureFlags from "../../constants/FeatureFlags.enums";
import { getBooleanFeatureFlag, getPatientActiveProfiles, isActiveProfileMatchAllowedLocations, isLoginUserBusinessOwner } from "../../utils/commonUtils";
import { useUserSetting } from "./useUserSetting";
import { CommonDataContext } from "../../context/CommonDataContext";
import useLoggedInUserAllowedLocationsByTab from "./useLoggedInUserAllowedLocationsByTab";
import { MAIN_MENU_CODES } from "../SideMenuBar/SideBarConst";
import { IContactProfile } from "../RightSideContainer/TeamInbox/Conversations/interfaces";
import { useLazyQuery } from "@apollo/client";
import { CARESTUDIO_PROXY_TO_CRM_CONTEXT } from "../../constants/Configs";
import LeadQueries from "../../services/Lead/LeadQueries";
import React from "react";

export const useIsActionsDisabled = (
  props: {
    contactProfiles?: IContactProfile[] | undefined,
    contactId?: string,
  }
) => {
  const [contactProfiles, setContactProfiles] = useState<IContactProfile[] | undefined>(props?.contactProfiles);
  const [getContactProfile] = useLazyQuery(
    LeadQueries.GetContactProfileById,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
      },
    },
  );

  const fetchContactProfile = async () => {
    const contactProfiles = await getContactProfile({
      variables: {
        params: {
          contactUuid: props?.contactId,
          isActive: true,
        }
      }
    });
    return contactProfiles;
  }

  React.useEffect(() => {
    const getProfile = async () => {
      if (!contactProfiles?.length && props?.contactId) {
        const contactProfile = await fetchContactProfile();
        setContactProfiles(contactProfile?.data?.contactProfiles);
      }
    };
    getProfile();
  }, [props?.contactId]);


  const mlovdata = useContext(CommonDataContext);
  const isMultiTenancyEnabled = useUserSetting(CONFIG_CODES.IS_MSO_ENABLED) || getBooleanFeatureFlag(mlovdata.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.CONSUMER);
  const activecontactProfiles = getPatientActiveProfiles(contactProfiles || []);
  const hasActiveProfile = isActiveProfileMatchAllowedLocations(activecontactProfiles || [], allowedLocationIds)
  const isActionsDisabled = !isLoginUserBusinessOwner(mlovdata) && isMultiTenancyEnabled ? !hasActiveProfile : false;


  return {
    isActionsDisabled
  };
};
