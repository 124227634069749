import { View, StyleSheet } from 'react-native'
import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { ITaskCategory, ITaskDisplayConfig, ITaskMetaData } from '../../TaskInterfaces'
import { ITask } from '../../../common/CareDashboard/CareDashboardInterfaces';
import { DragDropContext, DragStart, DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { EventBus } from '../../../../utils/EventBus';
import { TASK_EVENTS } from '../../../common/CareDashboard/CareDashboardConstants';
import { CARE_DASHBOARD_COLUMN_CODE } from '../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { TASK_DUE_DATE_CODES } from '../../../../constants/MlovConst';
import TaskKanbanColumn from './TaskKanbanColumn';
import { HStack, useToast } from 'native-base';
import useTaskCountManager from '../../../common/CareDashboard/CustomHook/useTaskCountManager';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../../utils/CaptureTransaction';
import { TaskViewFrom } from '../../../common/CareDashboard/CareDashboardTopBar/interfaces';
import { useIntl } from 'react-intl';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { getBooleanFeatureFlag, replaceHashValueToString } from '../../../../utils/commonUtils';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';

interface ITaskKanbanView {
  config: ITaskDisplayConfig;
  metaData: ITaskMetaData;
  onActionPerformed?: (tabCode: any, rawData: any) => void;
  onTaskDetail: (data: ITask) => void;
  onMemberClick?: (task: ITask) => void;
  viewFrom?: TaskViewFrom;
  isP360PatientProfile?: boolean;
}

const TaskKanbanView = (props: ITaskKanbanView) => {
  const { config, metaData, onActionPerformed, onTaskDetail, onMemberClick, viewFrom, isP360PatientProfile } = props;
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const categories = config.categories;
  const eventBus = useMemo(() => EventBus.getEventBusInstance(), []);
  const taskCountData = useTaskCountManager({
    params: config.categories,
    isFetchTodaysCount: true,
    viewFrom,
  });
  const intl = useIntl();
  const toast = useToast();
  const dragSourceColumnRef = useRef<string | undefined>();

  const captureTransactionInst = CaptureTransaction.getInstance();
  const draggableIdRef = React.useRef<string | null>(null);

  // Other methods
  const getColumnAndIndex = useCallback((location: DraggableLocation) => ({
    column: categories.find(item => item.code === location.droppableId),
    index: location.index,
  }), [categories]);

  const onDragHandler = useCallback(async (result: DropResult) => {
    dragSourceColumnRef.current = undefined;

    // If no destination then return
    if (!result.destination) {
      return;
    }
    // If element is dragged and dropped in same column (rearranged in same column) then return
    if (result.destination.droppableId === result.source.droppableId) {
      return;
    }
    const identifier = result.draggableId;
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.TASK_DRAG_FROM_SOURCE,
      identifier,
      {
        source: result.source.droppableId,
        destination: result.destination.droppableId,
      }
    );
    draggableIdRef.current = null;

    eventBus.broadcastEvent(TASK_EVENTS.ON_TASK_DRAG_FROM_SOURCE, {
      source: getColumnAndIndex(result.source),
      destination: getColumnAndIndex(result.destination)
    });
  }, [eventBus, getColumnAndIndex]);

  const isDropDisabled = useCallback((column: ITaskCategory) => {
    const dragSourceColumn = dragSourceColumnRef.current;
    return (
      dragSourceColumn ===
        CARE_DASHBOARD_COLUMN_CODE.completed ||
      (dragSourceColumn === CARE_DASHBOARD_COLUMN_CODE.dueNow &&
        column.code === CARE_DASHBOARD_COLUMN_CODE.today) ||
      (dragSourceColumn === CARE_DASHBOARD_COLUMN_CODE.today &&
        column.code === CARE_DASHBOARD_COLUMN_CODE.dueNow) ||
      column.code === dragSourceColumn || Object?.values(TASK_DUE_DATE_CODES)?.includes(column?.code)
    );
  }, []);

  const onDragStart = useCallback((start: DragStart) => {
    dragSourceColumnRef.current = start.source.droppableId;
    const identifier = start.draggableId;
    draggableIdRef.current = identifier;
    captureTransactionInst.initiateTransaction({
      identifier: identifier,
      name: TRANSACTION_NAMES.TASK_DRAG_FROM_SOURCE,
    });
  }, []);

const memoizedColumns = useMemo(() =>
  categories.map((category, index) => (
    <TaskKanbanColumn
      key={`${category.code}_${index}`}
      category={category}
      metaData={metaData}
      noOfColumns={categories.length}
      taskCountData={taskCountData}
      isDropDisabled={isDropDisabled(category)}
      onActionPerformed={onActionPerformed}
      onTaskDetail={onTaskDetail}
      onMemberClick={onMemberClick}
      viewFrom={viewFrom}
      isP360PatientProfile={isP360PatientProfile || false}
    />
  )),
[categories, metaData, taskCountData, isDropDisabled, onActionPerformed, onTaskDetail, onMemberClick]);

  React.useEffect(() => {
    return () => {
      if (draggableIdRef.current) {
        captureTransactionInst.clearTransaction(
          TRANSACTION_NAMES.TASK_DRAG_FROM_SOURCE,
          draggableIdRef.current
        );
      }
    };
  }, []);

  return (
    <View style={styles.container}>
      <DragDropContext
        onDragStart={onDragStart}
        onDragEnd={onDragHandler}
      >
        <HStack space={4} marginX={4}>
          {memoizedColumns}
        </HStack>
      </DragDropContext>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingBottom: 8,
    paddingTop: 16,
  },
});

export default React.memo(TaskKanbanView);
