import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import BackArrowSvg from '../common/Svg/SideCarSvg/BackArrowSvg';
import {useIntl} from 'react-intl';
import {Colors} from '../../styles';
import {getAlertTitle} from './SideCarHelpers/utils';
import {HStack, ScrollView, Spinner, Text} from 'native-base';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import {ALERT_ACTION_CODES} from './constants';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {getUserUUID, getUserFullName} from '../../utils/commonUtils';
import {CommonDataContext} from '../../context/CommonDataContext';
import {getMlovIdFromCode, getMlovListFromCategory} from '../../utils/mlovUtils';
import {ITaskAlert} from './SideBarCustomHooks/useFetchAlerts';
import {ILoginUserData} from '../../Interfaces/CommonInterfaces';
import {MLOV_CATEGORY, TASK_STATUS} from '../../constants/MlovConst';
import TaskQueries, {CREATE_AUDIT_LOG_FOR_ALERT_ACTION} from '../../services/Task/TaskQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {useMutation} from '@apollo/client';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {addOrUpdateAlertStyles} from './SideCarHelpers/commonStyle';
import AddOrUpdateAlertView from './AddOrUpdateAlertView';
import {ITask} from '../common/CareDashboard/CareDashboardInterfaces';
import { BUTTON_TYPE, TASK_TYPES } from '../../constants';
import { Popover } from 'antd';
import { DiagnosisGapDismissal } from './DiagnosisGapDismissal';
import { SIDECAR_EVENT_CODES } from '../../sidecar/common/constants';
import { broadcastEventToSidecarActionsScreen } from '../../sidecar/common/SideCardCommonUtils';
import { FoldButton } from '../CommonComponents/FoldButton/FoldButton';
import { EventBus } from '../../utils/EventBus';
import {getTaskSusccessMessage} from '../common/AddTask/AddTaskUtils';

interface IAddOrUpdateAlert {
  onBackClick: () => void;
  alertType?: string;
  formattedContactData?: any;
  task?: ITask;
}

interface IAddOrUpdateAlertState {
  loading: boolean;
  showDismissalDrawer: boolean;
  showDeclinePopover: boolean;
  value: any;
  selectedContact?: any;
}

const AddOrUpdateAlert = (props: IAddOrUpdateAlert) => {
  const {onBackClick, alertType, task} = props;
  const mlovData = useContext(CommonDataContext);
  const editTask = task;
  const userData = mlovData.userData || ({} as ILoginUserData);
  const [actionsState, setActionsState] = useState({
    showAddTaskDrawer: false,
    selectedTaskAlertForAction: undefined,
  });
  const [componentState, setComponentState] = useState<IAddOrUpdateAlertState>({
    loading: false,
    showDismissalDrawer: false,
    showDeclinePopover: false,
    value: {},
    selectedContact: undefined
  });
  const isDiagnosisGapAlert = alertType === TASK_TYPES.DIAGNOSIS_GAPS;
  const isCareGapAlert = alertType === TASK_TYPES.CARE_GAPS;
  const intl = useIntl();
  const title = getAlertTitle(alertType, task?.id);
  const taskTypeActionsMlovList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_TYPE_ACTIONS
  );
  const taskStatusMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_STATUS
  ) || [];
  const isTaskRejected = task?.statusId === getMlovIdFromCode(taskStatusMlov, TASK_STATUS.REJECTED);
  const [createAuditLogForAlertAction] = useMutation(CREATE_AUDIT_LOG_FOR_ALERT_ACTION, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });
  const [updateTaskStatus] = useMutation(
    TaskQueries.UPDATE_TASK_STATUS_AND_REFERENCE,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const onDiagnosisAdded = async () => {
    if (!editTask?.id) {
      return;
    }
    const statusId = getMlovIdFromCode(taskStatusMlov, TASK_STATUS.COMPLETED);
    const ids: string[] = [editTask?.id];
    editTask?.subTasks?.forEach((subTask) => {
      if (subTask?.id) {
        ids.push(subTask?.id);
      }
    });
    await Promise.all(
      ids.map((item) => {
        return updateTaskStatus({
          variables: {
            params: {
              id: item,
              data: {
                statusId,
              },
            },
          },
        })
      })
    );
    props.onBackClick?.();
  }

  const foundElementListener = useCallback((eventData) => {
    if (eventData?.elementData === 'SUCCESS_ELEMENT_FOUND') {
      onDiagnosisAdded();
      // const resourceId = diagnosisGapDetail?.conditions?.[0]?.resourceId;
      // if (resourceId) {
      //   addDemoDataToLocalStorage('acceptedDiagnosisGaps', resourceId);
      // }
    }
  }, []);

  useEffect(()=> {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(SIDECAR_EVENT_CODES.CHECK_ELEMENTS_CALLBACK, foundElementListener);
    return () => {
      eventBus.removeEventListenerByEventName(SIDECAR_EVENT_CODES.CHECK_ELEMENTS_CALLBACK, foundElementListener);
    };
  }, []);

  const onAccept = async () => {
    try {
      if(!!task?.subTasks?.length && task?.subTasks?.length > 1){
        onDiagnosisAdded();
        return;
      }
      setComponentState(prev => ({
        ...prev,
        loading: true
      }));

      const display = task?.subTasks?.[0]?.title;
      broadcastEventToSidecarActionsScreen(
        '',
        SIDECAR_EVENT_CODES.ADD_DIAGNOSES,
        {
          diagnosesName: display,
        }
      );

    } catch  {
    } finally {
      setComponentState(prev => ({
        ...prev,
        loading: false
      }));
    }
  };

  const getNewTaskAssignee = () => {
    const userUUID = getUserUUID();
    return {
      value: getUserUUID(),
      label: getUserFullName(),
      key: userUUID,
      type: ParticipantType.staff,
      details: userData,
    };
  };

  const getNewTaskMember = () => {
    return {
      value: props?.formattedContactData?.contactUUID,
      label: props?.formattedContactData?.name,
      key: props?.formattedContactData?.contactUUID,
      type: ParticipantType.patient,
      contactData: props?.formattedContactData?.contactData,
      data: props?.formattedContactData?.contactData,
    };
  };

  const logAuditAction = (
    actionCode: string,
    auditData: {
      formLogIds?: string[];
      appointmentId?: string;
      taskId?: string;
      documentId?: string;
      noteId?: string;
      docStatus?: string;
    },
    selectedTaskAlertForAction?: ITaskAlert
  ) => {
    const taskAlert = selectedTaskAlertForAction || actionsState.selectedTaskAlertForAction;
    const actionId = getMlovIdFromCode(taskTypeActionsMlovList, actionCode);
    setActionsState((prev) => ({
      ...prev,
      selectedTaskAlertForAction: undefined,
    }));
    createAuditLogForAlertAction({
      variables: {
        params: {
          taskId: taskAlert?.id,
          taskTypeActionId: actionId,
          auditData: auditData,
        },
      },
    });
  };

  let formSubmitFunction: (() => void) | null = null;

  const setFormSubmitHandler = (func: () => void) => {
    formSubmitFunction = func;
  };
  const renderActionButtons = () => {
    if (task?.id && isDiagnosisGapAlert && !isTaskRejected) {
      return (
        <HStack space={2} alignItems="center">
          <FoldButton
            customProps={{
              btnText: intl.formatMessage({id: 'accept'}),
            }}
            nativeProps={{
              backgroundColor: Colors.Custom.White,
              isLoading: componentState.loading,
              variant: BUTTON_TYPE.PRIMARY,
              color: Colors.FoldPixel.PRIMARY300,
              // rightIcon: !isTaskRejected ? <AntIcon name="down" size={12} /> : undefined,
              rightIcon:
                componentState.loading ? (
                  <Spinner size={'sm'} />
                ) :
                  !isTaskRejected ? <Pressable onPress={() => {
                    setComponentState(prev => ({
                      ...prev,
                      showDeclinePopover: !prev.showDeclinePopover
                    }));
                  }}><AntIcon name="down" size={12} /></Pressable> : undefined,
              onPress: (e) => {
                onAccept();
                // if (e?.target === e?.currentTarget) {
                //   onAccept();
                // } else if (!isTaskRejected) {
                //   setComponentState(prev => ({
                //     ...prev,
                //     showDeclinePopover: !prev.showDeclinePopover
                //   }));
                // }
              },
            }}
          />
            <Popover
              open={componentState.showDeclinePopover}
              onOpenChange={(visible) => {
                setComponentState(prev => ({
                  ...prev,
                  showDeclinePopover: visible
                }));
              }}
              placement="bottomRight"
              content={
                <Pressable
                  onPress={() => {
                    setComponentState(prev => ({
                      ...prev,
                      showDeclinePopover: false,
                      showDismissalDrawer: true
                    }));
                  }}
                  style={addOrUpdateAlertStyles.declineOption}
                >
                  <Text color={Colors.FoldPixel.GRAY300} size={'smSemibold'}>
                    {intl.formatMessage({id: 'dismiss'})}
                  </Text>
                </Pressable>
              }
            >
              <View /> {/* Empty view as anchor for Popover */}
            </Popover>
        </HStack>
      );
    }

    return (
      <View style={{flex: 1, alignItems: 'flex-end'}}>
        <Pressable
          onPress={() => {
            handleSaveClick();
          }}
          style={addOrUpdateAlertStyles.saveCareGapTaskButton}
        >
          <Text color={Colors.FoldPixel.PRIMARY300} size={'smSemibold'}>Save</Text>
        </Pressable>
      </View>
    );
  };

  const handleSaveClick = () => {
    if (formSubmitFunction) {
      formSubmitFunction(); // Call the form submit function
    }
  };
  return (
    <>
    <View
      style={{
        backgroundColor: Colors.FoldPixel.GRAY50,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 12,
          paddingVertical: 6,
          backgroundColor: '#ffffff',
          borderBottomWidth: .5,
          borderBottomColor: Colors.FoldPixel.GRAY150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Pressable
            onPress={() => {
              onBackClick();
            }}
            style={{
              width: 28,
              height: 28,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BackArrowSvg />
          </Pressable>
          <Text size={'smSemibold'} style={{marginLeft: 6}}>
            {intl.formatMessage({id: title})}
          </Text>
        </View>
        {/* <View style={{flex: 1, alignItems: 'flex-end'}}> //here
          <Pressable
            onPress={() => {
              handleSaveClick();
            }}
            style={addOrUpdateAlertStyles.saveCareGapTaskButton}
          >
            <Text color={Colors.FoldPixel.PRIMARY300} size={'smSemibold'}>Save</Text>
          </Pressable>
        </View> */}
        {renderActionButtons()}
      </View>
      <View
        style={{
          backgroundColor: '#ffffff',
          padding: 16
        }}
      >
        <ScrollView
          contentContainerStyle={addOrUpdateAlertStyles.scrollViewContent}
          style={addOrUpdateAlertStyles.scrollView}
        >
          <AddOrUpdateAlertView
            saveBtnText={'Save'}
            task={task}
            alertType={alertType}
            setFormHandler={setFormSubmitHandler}
            successMessage={getTaskSusccessMessage(alertType!)}
            member={getNewTaskMember() as any}
            assignee={getNewTaskAssignee() as any}
            isVisible={actionsState.showAddTaskDrawer}
            onComplete={(data) => {
              if (actionsState?.selectedTaskAlertForAction) {
                logAuditAction(
                  ALERT_ACTION_CODES.CREATE_TASK,
                  {taskId: data?.addOrUpdateTask?.id},
                  actionsState?.selectedTaskAlertForAction
                );
              }
              onBackClick()
            }}
            onCancel={() => {
              setActionsState((prev) => ({
                ...prev,
                showAddTaskDrawer: false,
              }));
            }}
            restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
          />
        </ScrollView>
      </View>
    </View>
    {componentState.showDismissalDrawer && task && (
      <DiagnosisGapDismissal
        open={componentState.showDismissalDrawer}
        onClose={() => {
          setComponentState(prev => ({
            ...prev,
            showDismissalDrawer: false
          }));
          onBackClick();
        }}
        contactData={componentState.selectedContact}
        diagnosisGap={task}
      />
    )}
    </>
  );
};

export default AddOrUpdateAlert;
