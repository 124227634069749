import React from "react";
import { FormElements } from "../FormComponents/FormComponents";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";

function EditConfigurationView() {
  const { state } = useCustomFormBuilderContext();
  const selectedElement = state.selectedElement;
  if (!selectedElement) return null;

  const PropertiesForm = FormElements[selectedElement.type as keyof typeof FormElements]?.propertiesComponent;
  const designerBtnElement = FormElements[selectedElement.type as keyof typeof FormElements]?.designerBtnElement;

  return (
    <div id="edit-configuration-view" className="flex flex-col">
      <div className="flex items-center justify-center w-full edit-configuration-view-title">
        <p>{designerBtnElement?.label}</p>
      </div>
      <div className="flex flex-col overflow-y-auto edit-configuration-view-content">
        <PropertiesForm elementInstance={selectedElement} key={`properties-form-${selectedElement.id}`} />
      </div>
    </div>
  );
}

export default EditConfigurationView;
