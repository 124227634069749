import React, {useContext} from 'react';
import {Stack, Text, Divider, VStack, HStack} from 'native-base';
import {StyleSheet} from 'react-native';
import {Radio} from 'antd';
import {Colors} from '../../../../../../../styles/Colors';
import {useTCMBilling} from '../../../hooks/useTCMBilling';
import {CPTCode} from '../../../../interface';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';

interface TCMBillingViewProps {
  contactCareProgramId: string;
  cptCodes?: CPTCode[];
  title?: string;
  selectedCptCode?: string;
  totalBill?: number;
}

const TCMBillingView: React.FC<TCMBillingViewProps> = ({
  contactCareProgramId,
  cptCodes,
  title,
  selectedCptCode,
  totalBill
}) => {
  const commonDataContext = useContext(CommonDataContext);
  const { tcmCodes, totalBillValue, isLoading, selectedCode } = useTCMBilling({ 
    contactCareProgramId, 
    commonDataContext 
  });

  if (isLoading) {
    return (
      <Stack style={styles.outerContainer}>
        <Stack style={styles.container}>
          <Text>Loading...</Text>
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack style={styles.outerContainer}>
      <Stack style={styles.container}>
        <Text style={styles.totalBill}>Total Bill: ${totalBill || totalBillValue}</Text>
        <Divider style={styles.divider} />
        <Text style={styles.title}>{title || 'TCM'}</Text>

        <Radio.Group
          name="cptCode"
          value={selectedCptCode || selectedCode}
          style={antStyles.radioGroup}
        >
          <VStack space={3}>
            {(cptCodes || tcmCodes).map((option: CPTCode) => (
              <VStack key={option.cptCode} style={styles.radioOption}>
                <Radio
                  value={option.cptCode}
                  disabled={
                    Boolean(selectedCode) && selectedCode !== option.cptCode
                  }
                >
                  <HStack space={2} alignItems="center">
                    <Text size={'smRegular'} style={styles.cptCode}>{option.cptCode}:</Text>
                    <Text size={'smRegular'} style={styles.value}>${option.amount}</Text>
                  </HStack>
                </Radio>
                <Text size={'smRegular'} style={styles.description}>{option.description}</Text>
              </VStack>
            ))}
          </VStack>
        </Radio.Group>
      </Stack>
    </Stack>
  );
};
const styles = StyleSheet.create({
  outerContainer: {
    width: '60%',
    alignSelf: 'center',
    marginTop: 16,
  },
  container: {
    padding: 16,
    backgroundColor: Colors.Custom.White,
    borderRadius: 8,
    width: '100%',
  },
  totalBill: {
    fontSize: 24,
    fontWeight: '500',
    color: Colors.FoldPixel.GRAY400,
    marginBottom: 16,
  },
  divider: {
    marginVertical: 16,
  },
  title: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.Custom.Gray700,
    marginBottom: 16,
  },

  cptCode: {
    fontSize: 14,
    color: Colors.FoldPixel.GRAY400,
    fontWeight: '500',
  },
  value: {
    fontSize: 14,
    color: Colors.FoldPixel.GRAY400,
  },
  description: {
    color: Colors.FoldPixel.GRAY250,
    marginTop: 4,
    marginLeft: 24,
  },
  radioOption: {
    marginBottom: 12,
  },
});

const antStyles: Record<string, React.CSSProperties> = {
  radioGroup: {
    width: '100%',
  },
};

export default TCMBillingView;
