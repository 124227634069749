import React, { useEffect, useReducer } from "react";
import { CustomFormBuilderReducer, ICustomFormBuilderReducer } from "../Context/CustomBuilderReducer";
import { ICustomFormBuilderState, IUseCustomFormBuilderProps } from "../CustomFormEngineInterfaces";
import { getAllElementKeys } from "../CustomFormEngineUtils";

export const useCustomFormBuilder = (args: IUseCustomFormBuilderProps) => {
  const [state, dispatch] = useReducer<ICustomFormBuilderReducer>(CustomFormBuilderReducer, {
    elements: args.defaultComponents || [] as ICustomFormBuilderState['elements'],
    selectedElement: null as ICustomFormBuilderState['selectedElement'],
    uniqueKeysMap: getAllElementKeys(args.defaultComponents || []),
    isDragAndDropDisabled: false,
  });

  // Lifecycle hooks
  useEffect(() => {
    args.onSchemaChange?.({ components: state.elements || [] });
  }, [state.elements]);


  return { state, dispatch, ehrCapabilities: args.ehrCapabilities, questionnaireList: args.questionnaireList };
};

export default useCustomFormBuilder;

