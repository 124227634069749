import { useLazyQuery } from '@apollo/client';
import { GET_SINGLE_USER } from '../../../../services/User/UserQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS } from '../../../../services/ContactCareProgram/ContactCareProgram';
import { CONTACT_CARE_PROGRAM_STATUS_CODES } from '../../../../constants/MlovConst';
import { IContactCareProgram } from '../../ContactCareProgram/interface';
import useLocationAccess from '../../../common/ContactProfile/useLocationAccess';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';

export const useContactCareProgramCardAdditionalData = (props: {contactCareProgram: IContactCareProgram}) => {
  const {isMultiTenancyEnabled, haveAccessToLocation} = useLocationAccess({
    locationUuids: [props?.contactCareProgram?.accountLocationId || ""],
    userAccessMainMenuCode: MAIN_MENU_CODES.CONSUMER
  })
  const {haveAccessToLocation: isCareProgramOfActiveLocation} = useLocationAccess({
    locationUuids: [props?.contactCareProgram?.accountLocationId || ""],
    usePatientLocationsFromOminiContext: true
  })
  const haveAccessToCareProgramAccountLocation = isCareProgramOfActiveLocation && haveAccessToLocation;
  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  const [getCareProgramActivityLogs] = useLazyQuery(
    GET_CARE_PROGRAM_LAST_ACTIVITY_LOGS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
    }
  );

  const getContactCareProgramAdditionalData = async (
    assigneeId: string,
    contactCareProgramId: string,
    careProgramStatusCode?: string
  ) => {
    try {
      const promiseList: Promise<any>[] = [];
      const results: any = {};
      if (assigneeId) {
        const userPromise = getSingleUserById({
          variables: {userId: assigneeId}
        }).then((response) => {
          results.user = response?.data?.users?.[0];
        });
        promiseList.push(userPromise);
      }
      if (contactCareProgramId && careProgramStatusCode === CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS) {
        const activityLogsPromise = getCareProgramActivityLogs({
          variables: {contactCareProgramId: contactCareProgramId}
        }).then((response) => {
          results.careProgramActivityLogs = 
            response?.data?.getActivityLogs?.activityLogs?.[0];
        });
        promiseList.push(activityLogsPromise);
      }

      if (promiseList.length === 0) {
        return {
          user: null,
          careProgramActivityLogs: null,
        };
      }

      await Promise.all(promiseList);
      
      return {
        user: results?.user || null,
        careProgramActivityLogs: results?.careProgramActivityLogs || null,
      };
    } catch (error) {
      return {
        user: null,
        careProgramActivityLogs: null,
      };
    }
  };

  return {
    getContactCareProgramAdditionalData,
    haveAccessToCareProgramAccountLocation,
    isMultiTenancyEnabled
  };
};
