import {ContainerComponent} from '../BaseComponents/BaseComponentInterface';
import {
  CustomFormBuilderActionTypes,
  ICustomFormBuilderReducerAction,
  ICustomFormBuilderState,
} from '../CustomFormEngineInterfaces';
import {
  findElementIndexAndParentRecursively,
  findElementRecursively,
  getAllElementKeys,
} from '../CustomFormEngineUtils';
import {ElementsType, FormElements} from '../FormComponents/FormComponents';
import {v4 as uuidV4} from 'uuid';
export type ICustomFormBuilderReducer = (
  state: ICustomFormBuilderState,
  action: ICustomFormBuilderReducerAction
) => ICustomFormBuilderState;

export const CustomFormBuilderReducer: ICustomFormBuilderReducer = (
  state: ICustomFormBuilderState,
  action: ICustomFormBuilderReducerAction
) => {
  switch (action.type) {
    // Update elements in the form builder
    case CustomFormBuilderActionTypes.UPDATE_ELEMENTS:
      return {...state, elements: [...action.payload]};

    // Select element in the form builder
    case CustomFormBuilderActionTypes.SELECT_ELEMENT:
      return {...state, selectedElement: action.payload};

    // Add element to the form builder
    case CustomFormBuilderActionTypes.ADD_ELEMENT:
      const {elements} = state;
      const {
        index,
        element,
        defaultSelect,
        parentElement: parentElementOfAddedElement,
      } = action.payload;

      if (parentElementOfAddedElement) {
        (parentElementOfAddedElement as ContainerComponent).components.splice(
          index,
          0,
          element
        );
      } else {
        elements.splice(index, 0, element);
      }

      const addedKeys = getAllElementKeys([element]);
      addedKeys.forEach((_, key) => {
        state.uniqueKeysMap.set(key, true);
      });
      return {
        ...state,
        elements: [...elements],
        selectedElement: defaultSelect ? element : state.selectedElement,
        uniqueKeysMap: state.uniqueKeysMap,
      };

    // Remove element from the form builder
    case CustomFormBuilderActionTypes.REMOVE_ELEMENT:
      const {
        element: elementToRemove,
        resetSelectedElement,
        parentElement: parentElementOfRemovedElement,
      } = action.payload;

      let updatedElementsAfterRemove = state.elements;
      const resetSelection =
        resetSelectedElement &&
        state.selectedElement?.id === elementToRemove.id;

      if (parentElementOfRemovedElement) {
        (parentElementOfRemovedElement as ContainerComponent).components = (
          parentElementOfRemovedElement as ContainerComponent
        ).components.filter((element) => element.id !== elementToRemove.id);
      } else {
        updatedElementsAfterRemove = updatedElementsAfterRemove.filter(
          (element) => element.id !== elementToRemove.id
        );
      }

      const removedKeys = getAllElementKeys([elementToRemove]);
      removedKeys.forEach((_, key) => {
        state.uniqueKeysMap.delete(key);
      });
      return {
        ...state,
        elements: [...updatedElementsAfterRemove],
        selectedElement: resetSelection ? null : state.selectedElement,
      };

    // Update element in the form builder
    case CustomFormBuilderActionTypes.UPDATE_ELEMENT:
      const {updatedElement} = action.payload;
      const elementToUpdate = findElementRecursively(
        state.elements,
        updatedElement.id
      );
      if (elementToUpdate) {
        Object.assign(elementToUpdate, {
          ...updatedElement,
          id: elementToUpdate.id, // Preserve original id
        });
      }
      return {
        ...state,
        elements: [...state.elements],
      };

    // Clone element in the form builder
    case CustomFormBuilderActionTypes.CLONE_ELEMENT:
      const elementToClone = action.payload;
      const elementDetails = findElementIndexAndParentRecursively(
        state.elements,
        elementToClone.id
      );
      if (!elementDetails) return state;

      const newElements = [...state.elements];
      const clonedElement = FormElements[
        elementToClone.type as ElementsType
      ].clone(uuidV4(), elementToClone, state.uniqueKeysMap);
      if (elementDetails.parent) {
        (elementDetails.parent as ContainerComponent).components.splice(
          elementDetails.index + 1,
          0,
          clonedElement
        );
      } else {
        newElements.splice(elementDetails.index + 1, 0, clonedElement);
      }
      return {
        ...state,
        elements: newElements,
        uniqueKeysMap: state.uniqueKeysMap,
      };

    // Disable drag and drop in the form builder
    case CustomFormBuilderActionTypes.DRAG_AND_DROP_DISABLED:
      return {...state, isDragAndDropDisabled: action.payload};

    default:
      return state;
  }
};
