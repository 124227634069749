import { gql } from '@apollo/client';

export const CreateBatchContactRelation = gql`
  mutation CreateBatchContactRelation(
    $contactRelation: [contact_relations_insert_input!]!
  ) {
    createContactRelations(objects: $contactRelation) {
      returning {
        contactId
        id
        relationId
        relationContactId
      }
    }
  }
`;

export const GetRelativeContact = gql`
  query GetRelativeContact($contactId: Int!) {
    contactRelations(
      where: {_and: {contactId: {_eq: $contactId}, isDeleted: {_eq: false}}}
    ) {
      id
      contactId
      relationType {
        id
        code
      }
      isCaregiver
      relationContactId
      realatedContact {
        additionalAttributes
        contactPracticeLocations(
          where: {_and: {practiceLocationUuid: {_is_null: false}, isDeleted: {_eq: false}}}
        ) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          accountLocation {
            uuid
          }
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        contactProfiles {
          id
          isActive
          accountLocationUuid
        }
        contactDeactivationInfo {
          isActive
        }
        isActive
        contactPreferredCommunicationChannel {
          preferredCommunicationModeId
          id
          preferredCommunicationModeMlov {
            code
            value
          }
        }
        contactType {
          contactType {
            code
            value
            id
            isDefault
            can_convert_to_patient
          }
          id
        }
        accountId
        uuid
        phoneNumber
        pubsubToken
        updatedAt
        source
        notes {
          contactId
          content
          userId
          id
        }
        name
        email
        id
        patient {
          patientId
          patientUuid
          annualVisitDate
          isWeeklyCheckin
          id
        }
        person {
          firstName
          birthDate
          marriageAnniversaryDate
          id
          bloodGroup {
            value
            code
            id
          }
          chosenName
          pronounId
          gender {
            value
            code
          }
          genderId
          bloodGroupId
          birthSexId
          sexAtBirth {
            value
            code
            id
          }
          contactId
          middleName
          lastName
        }
        personContact {
          personContactId
          value
          id
          personContactType {
            accountId
            value
            id
            categoryId
          }
          typeId
          rank
        }
        createdAt
        lastActivityAt
        identifier
        contactConsents {
          contactUuid
          consentId
          id
        }
      }
      relationId
    }
  }
`;

export const DeleteContactRelation = gql`
  mutation DeleteContactRelation($contactId: Int, $relationContactId: Int) {
    updateContactRelations(
      where: {
        _and: {
          contactId: {_eq: $contactId}
          relationContactId: {_eq: $relationContactId}
        }
      }
      _set: {isDeleted: true}
    ) {
      returning {
        realatedContact {
          email
        }
        relationContactId
        relationId
        createdAt
        isDeleted
      }
    }
  }
`;

export const GetRelationShipWithContact = gql`
  query GetRelationShipWithContact($contactId: Int) {
    contactRelations(
      where: {
        relationContactId: {_eq: $contactId}
        contactInfo: {id: {_is_null: false}}
      }
    ) {
      contactId
      relationType {
        value
      }
      contactInfo {
        isActive
        contactType {
          contactType {
            value
          }
        }

      contactDeactivationInfo {
        isActive
      }
      additionalAttributes
      contactPracticeLocations(where: { practiceLocationUuid: { _is_null: false}}) {
        uuid
        practiceLocationUuid
        id
        contactUuid
        practiceLocation {
          uuid
          name
          practiceCity {
            name
            id
          }
        }
      }
      isDeleted
      contactType {
        contactType {
          code
          value
          id
        }
        id
      }
      accountId
      notes {
        contactId
        content
        userId
        id
      }
      phoneNumber
      pubsubToken
      source
      updatedAt
      name
      email
      id
      uuid
      createdAt
      personContact {
        personContactId
        value
        id
        personContactType {
          accountId
          value
          id
          categoryId
        }
        typeId
        rank
      }
      patient {
        patientId
        patientUuid
        annualVisitDate
        isWeeklyCheckin
        id
      }
      person {
        firstName
        birthDate
        id
        bloodGroup {
          value
          code
          id
        }
        gender {
          value
          code
        }
        genderId
        contactId
        middleName
        lastName
      }
      lastActivityAt
      identifier
      workflowMetaData
      employees {
        employerId
        contactId
      }
      }
    }
  }
`;

export const UpdateContactRelations = gql`
  mutation updateContactRelations($idList: [uuid!]!, $contactRelationData: contact_relations_set_input) {
    updateContactRelations(_set: $contactRelationData, where: {id: {_in: $idList}}) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const GetRelativeContactByLocationUuids = gql`
  query GetRelativeContact($contactId: Int!, $locationUuids: [uuid!]) {
    contactRelations(
      where: {
        _and: {
          realatedContacts: {
            contactPracticeLocations: {
              practiceLocationUuid: {_in: $locationUuids}
            }
          }
          contactId: {_eq: $contactId}
          isDeleted: {_eq: false}
        }
      }
    ) {
      id
      contactId
      relationType {
        id
        code
      }
      relationId
      relationContactId
      realatedContact {
        contactPracticeLocations(
          where: {
            _and: {
              practiceLocationUuid: {_is_null: false, _in: $locationUuids}
              isDeleted: {_eq: false}
            }
          }
        ) {
          uuid
          practiceLocationUuid
          id
          contactUuid
          accountLocation {
            uuid
          }
          practiceLocation {
            name
            practiceCity {
              name
              id
            }
          }
        }
        isActive
        contactType {
          contactType {
            code
            value
            id
            isDefault
          }
          id
        }
        accountId
        uuid
        phoneNumber
        name
        email
        id
      }
    }
  }
`;

export default {
  CreateBatchContactRelation,
  GetRelativeContact,
  GetRelativeContactByLocationUuids,
  DeleteContactRelation,
  GetRelationShipWithContact,
  UpdateContactRelations
};
