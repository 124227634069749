import { createContext, Dispatch, useContext } from "react";
import { ICustomFormBuilderReducerAction, ICustomFormBuilderState } from "../CustomFormEngineInterfaces";
import { IEhrCapability } from "../../../../../Interfaces";
import { Resource } from "../../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces";
import { IUserSettingsByCode } from "../../../../../services/UserSettings/interfaces";

export const CustomFormBuilderContext = createContext<{
  dispatch: Dispatch<ICustomFormBuilderReducerAction> | undefined;
  state: ICustomFormBuilderState;
  ehrCapabilities: IEhrCapability[];
  questionnaireList?: Resource[];
  userSettings?: IUserSettingsByCode;
}>({
  dispatch: undefined,
  ehrCapabilities: [],
  questionnaireList: [],
  userSettings: {},
  state: {
    elements: [],
    selectedElement: null,
    libraryElements: [],
    metadataLoading: false,
    uniqueKeysMap: new Map(),
    isDragAndDropDisabled: false,
  } as ICustomFormBuilderState,
});

export const useCustomFormBuilderContext = () => {
  const context = useContext(CustomFormBuilderContext);
  if (context === undefined) {
    throw new Error('useCustomFormBuilderContext must be used within a CustomFormBuilderProvider')
  }
  return context;
};

export const CustomFormBuilderProvider = CustomFormBuilderContext.Provider;

export default {
  CustomFormBuilderContext,
  useCustomFormBuilderContext,
  CustomFormBuilderProvider,
};
