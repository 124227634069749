import React from 'react';
import '../Styles/Common.css';
import {CustomFormBuilderProvider} from '../Context/CustomFormBuilder.context';
import useCustomFormBuilder from '../Hooks/useCustomFormBuilder';
import CustomFormBuilderSidebar from './CustomFormBuilderSidebar';
import {ICustomFormBuilderProps} from '../CustomFormEngineInterfaces';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core';
import DragOverlayWrapper from './DragOverlayWrapper';
import {CustomBuilderDropArea} from './CustomBuilderDropArea';
import EditConfigurationView from './EditConfigurationView';
import { useFormSidebarBuilder } from '../Hooks/useFormSidebarBuilder';

const CustomFormBuilder = (props: ICustomFormBuilderProps) => {
  const {state, dispatch} = useCustomFormBuilder({
    ehrCapabilities: props.ehrCapabilities,
    selectedCategory: props.selectedCategory,
    defaultComponents: props.defaultComponents,
    onSchemaChange: props.onSchemaChange,
  });

  const {metadataLoading, libraryElements} = useFormSidebarBuilder({
    ehrCapabilities: props.ehrCapabilities,
    selectedCategory: props.selectedCategory,
    reusableComponents: props.reusableComponents,
  });

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  if (metadataLoading) {
    return <div>Loading...</div>;
  }

  return (
    <CustomFormBuilderProvider
      value={{
        dispatch,
        state,
        ehrCapabilities: props.ehrCapabilities,
        questionnaireList: props.questionnaireList || [],
        userSettings: props.userSettings,
      }}
    >
      <DndContext sensors={sensors} collisionDetection={rectIntersection}>
        <div className="flex h-full w-full">
          <div className="flex-none w-[20vw] right-border">
            <CustomFormBuilderSidebar libraryElements={libraryElements} />
          </div>
          <div className="flex-1 min-w-0">
            <CustomBuilderDropArea />
          </div>
          {Boolean(state.selectedElement?.id) && (
            <div className="flex-none w-[20vw]">
              <EditConfigurationView />
            </div>
          )}
        </div>
        <DragOverlayWrapper />
      </DndContext>
    </CustomFormBuilderProvider>
  );
};

export default CustomFormBuilder;
