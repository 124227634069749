import {useLazyQuery} from '@apollo/client';
import {useContext, useEffect, useState} from 'react';
import {IAccountPracticeLocationResp} from '../../services/Location/interfaces';
import UserPracticeLocationQueries from '../../services/Location/UserPracticeLocationQueries';
import {getAccountUUID, getUserUUID} from '../../utils/commonUtils';
import {CommonDataContext} from '../../context/CommonDataContext';
import {IAccountLocation} from '../RightSideContainer/Contacts/TeamMembers/interfaces';

const useUserPracticeLocations = (isAllowedAllLocation: boolean) => {
  const mlovData = useContext(CommonDataContext);
  const userUuid = getUserUUID();

  const accountUuid = getAccountUUID();
  const [userPracticeLocations, setUserPracticeLocations] = useState<
    IAccountLocation[]
  >([]);
  const [loading, setLoading] = useState(true);
  const allAccountLocationIds = mlovData.accountLocationListWithEHR;

  const [getUserPracticeLocationsByUserId] =
    useLazyQuery<IAccountPracticeLocationResp>(
      UserPracticeLocationQueries.GetUserPracticeLocationsByUserId(),
      {
        variables: {userId: userUuid, accountUuid: accountUuid},
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          setUserPracticeLocations(
            (isAllowedAllLocation
              ? allAccountLocationIds
              : data.accountLocations) || []
          );
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      }
    );
  useEffect(() => {
    setLoading(true);
    getUserPracticeLocationsByUserId();
  }, [getUserPracticeLocationsByUserId]);

  return {userPracticeLocations, loading};
};

export default useUserPracticeLocations;
