import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { Radio, Checkbox } from "antd";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { CustomSurveySchema } from "../Schema/ComponentsSchema";
import { SurveyComponent } from "../BaseComponents/BaseComponentInterface";
import TableSvg from "../../../../../assets/Icons/FormBuilder/TableSvg";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { v4 as uuidV4 } from 'uuid';
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
interface TableValue {
  [key: string]: string | string[];
}

interface TableQuestion {
  label: string;
  value: string;
}

interface TableOption {
  label: string;
  value: string;
}

export const TableFieldFormElement: FormElement = {
  type: CustomSurveySchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(CustomSurveySchema),
    key: getUniqueKey(map, CustomSurveySchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(CustomSurveySchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: TableSvg,
    label: CustomSurveySchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, currentValue: TableValue, silentCheck?: boolean): ValidationResult => {
  const element = elementInstance as SurveyComponent;

  if (!element.validate?.required) {
    return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: currentValue };
  }

  if (!currentValue || Object.keys(currentValue).length === 0) {
    return { isValid: false, errorMessage: 'Please answer all the questions', key: elementInstance.key, fieldValue: currentValue };
  }

  // Check if all questions have valid answers
  const hasInvalidAnswers = element.questions.some(question => {
    const answer = currentValue[question.value];

    // Check if answer exists and is not empty
    if (!answer) return true;

    // For multi-select (array values)
    if (Array.isArray(answer)) {
      return answer.length === 0 || answer.some(value => !value.trim());
    }

    // For single-select (string values)
    return !answer.trim();
  });

  if (hasInvalidAnswers) {
    return { isValid: false, errorMessage: 'Please answer all questions', key: elementInstance.key, fieldValue: currentValue };
  }

  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: currentValue };
};

function TableRowComponent({
  question,
  options,
  value,
  onChange,
  isMultiSelect,
  isReadOnly,
}: {
  question: TableQuestion;
  options: TableOption[];
  value?: string | string[];
  onChange: (value: string | string[]) => void;
  isMultiSelect?: boolean;
  isReadOnly?: boolean;
}) {
  const handleChange = (newValue: string | string[]) => {
    onChange(newValue);
  };

  return (
    <tr className="table-row">
      <td className="table-cell question-cell">
        {question.label}
      </td>
      {options.map((option) => (
        <td key={option.value} className="table-cell option-cell">
          {isMultiSelect ? (
            <Checkbox
              checked={Array.isArray(value) && value.includes(option.value)}
              onChange={(e) => {
                const newValue = Array.isArray(value) ? [...value] : [];
                if (e.target.checked) {
                  newValue.push(option.value);
                } else {
                  const index = newValue.indexOf(option.value);
                  if (index > -1) {
                    newValue.splice(index, 1);
                  }
                }
                handleChange(newValue);
              }}
              disabled={isReadOnly}
            />
          ) : (
            <Radio
              checked={value === option.value}
              onChange={(e) => handleChange(e.target.value)}
              value={option.value}
              disabled={isReadOnly}
            />
          )}
        </td>
      ))}
    </tr>
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: TableValue;
  onChange: (value: TableValue) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const {
    label,
    validate,
    description,
    tooltip,
    allowToShare,
    questions,
    values: options,
    questionHeader,
    allowMultiSelection
  } = elementInstance as SurveyComponent;

  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  const handleRowChange = (questionValue: string, newValue: string | string[]) => {
    onChange({
      ...defaultValue,
      [questionValue]: newValue
    });
  };

  const getAnswerLabel = (questionValue: string) => {
    const answer = defaultValue?.[questionValue];
    if (!answer) return '-';

    if (Array.isArray(answer)) {
      return answer
        .map(value => options.find(opt => opt.value === value)?.label || value)
        .join(', ');
    }
    return options.find(opt => opt.value === answer)?.label || answer;
  };

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      <div className="table-container">
        {isReadOnly && !isPrintForm ? (
          <table className="custom-table readonly-table">
            <thead>
              <tr>
                <th className="question-header">{questionHeader}</th>
                <th className="answer-header">Answer</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question) => (
                <tr key={question.value} className="table-row">
                  <td className="question-cell">{question.label}</td>
                  <td className="answer-cell field-value">{getAnswerLabel(question.value)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="custom-table">
            <thead>
              <tr>
                <th className="question-header">{questionHeader}</th>
                {options.map((option) => (
                  <th key={option.value} className="option-header">
                    {option.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {questions.map((question) => (
                <TableRowComponent
                  key={question.value}
                  question={question}
                  options={options}
                  value={defaultValue?.[question.value]}
                  onChange={(value) => handleRowChange(question.value, value)}
                  isMultiSelect={allowMultiSelection}
                  isReadOnly={isReadOnly}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
      {description && (
        <Description description={description} />
      )}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState<TableValue>({});

  const handleChange = (newValue: TableValue) => {
    setValue(newValue);
  };

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: TableValue;
  isReadOnly?: boolean;
  onRegisterValidation?: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<TableValue | undefined>(defaultValue || selectedValue);

  const isMounted = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: TableValue, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || {}, silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: TableValue) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: TableValue) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as SurveyComponent;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);

  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
    {
      type: 'oldtextfield',
      input: true,
      label: 'Question Header',
      key: 'questionHeader',
      tooltip: 'The header of the question column',
      defaultValue: 'Questions',
    },
    {
      type: 'datagrid',
      input: true,
      label: 'Questions',
      key: 'questions',
      tooltip: 'The questions you would like to ask in this survey.',
      reorder: true,
      components: [
        {
          label: 'Label',
          key: 'label',
          input: true,
          type: 'oldtextfield',
        },
        {
          label: 'Value',
          key: 'value',
          input: true,
          type: 'oldtextfield',
          allowCalculateOverride: true,
          hidden: true,
          clearOnHide: false,
          calculateValue: {_camelCase: [{var: 'row.label'}]},
        },
      ],
    },
    {
      type: 'datagrid',
      input: true,
      label: 'Possible Answers',
      key: 'values',
      tooltip:
        "The values that can be selected per question. Example: 'Satisfied', 'Very Satisfied', etc.",
      weight: 1,
      reorder: true,
      components: [
        {
          label: 'Label',
          key: 'label',
          input: true,
          type: 'oldtextfield',
        },
        {
          label: 'Value',
          key: 'value',
          input: true,
          type: 'oldtextfield',
          allowCalculateOverride: true,
          hidden: true,
          clearOnHide: false,
          calculateValue: {_camelCase: [{var: 'row.label'}]},
        },
      ],
    },
    {
      type: 'checkbox',
      label: 'Allow multiple answers for a question',
      tooltip:
        'This allows user to select multiple values against each question.',
      key: 'allowMultiSelection',
      input: true,
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const { formData, formattedFormData, components, handleFormDataChange } =
    usePropertiesFormRenderer({
      initialValues: element as Record<string, any>,
      components: formElements,
    });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default TableFieldFormElement;
