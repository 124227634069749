import {useLazyQuery, useQuery} from '@apollo/client';
import {Viewer} from '@toast-ui/react-editor';
import {Popover, Statistic, notification} from 'antd';
import {Badge, Box, VStack, View, useToast} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import sound from '../../../assets/audio/message.mp3';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../constants/ActionConst';
import {SUPPORTED_EVENT_CODE} from '../../../constants/WebSocketConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import {Colors} from '../../../styles';
import {EventBus} from '../../../utils/EventBus';
import {getAccountId, getAccountUUID, getUserId, getUserUUID, isChannelEmail, isHTMLString, isMentionedMessage, isValidJsonString} from '../../../utils/commonUtils';
import {IThemeAttachments} from '../../BodyContainer/interface';
import {SingleNotification} from '../../RightSideContainer/ContentManagement/PushNotifications/interfaces';
import {MESSAGE_CUSTOM_CARD_TYPE} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import {getContactDataAndType} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {IContact, IConversationData, INewConversationResponse} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {getNotificationCount} from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import {LoadAppointmentDetailsDrawer} from '../CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/LoadAppointmentDetailsDrawer';
import InstantChatView from '../ChatDrawer/InstantChatView';
import FormResponseView from '../FormResponseView/FormResponseView';
import PopulationGroupFormDrawer from '../PopulationGroupFormDrawer/PopulationGroupFormDrawer';
import NotificationSvg from '../Svg/NotificationSvg';
import {DoseSpotNotification} from './DoseSpotNotification';
import {NotificationList} from './NotificationList';
import {NOTIFICATION_TYPES, getNotificationDataForEmailNotification, getTitleAndDescriptionForEmailNotification, isConversationNotification} from './NotificationsUtils';
import TaskNotificationDrawer from './components/TaskNotificationDrawer';
import {INotificationDrawerState} from './interfaces';
import {cloneDeep} from 'lodash';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import { useIntl } from 'react-intl';
import {StyleSheet} from 'react-native';

const {Countdown} = Statistic

const NotificationsC = () => {
  const toast = useToast();
  const commonData = useContext(CommonDataContext);
  const accountThemeConfig: any = commonData.accountThemeConfig || {};
  const isSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window;
  let permission: string;
  if (isSupported()) {
    permission = Notification.permission;
  }
  const intl = useIntl();
  const accountId = getAccountId();

  const [notificationDrawerState, setNotificationDrawerState] =
    useState<INotificationDrawerState>({
      type: '',
      showModal: false,
      showAppointmentDetailsDrawer: false,
      showFormResponseDrawer: false,
      showChatDrawer: false,
      showPopulationGroupFormStatusDrawer: false,
      showTaskDrawer: false
    });

    const [getConversationAdditionalAttributes] = useLazyQuery(
      ConversationsQueries.GetConversationAdditionalAttributes
    );

  const [populationGroupFormDrawerState, setPopulationGroupFormDrawerState] = useState(
    {
      dataSource: [],
    }
  )

  const [conversation, setConversation] = useState<IConversationData>()
  const [contactData, setContactData] = useState<IContact>()

  const getLogoUrl = (): string => {
    const tempThemeAdditionalData: string =
      accountThemeConfig?.additionalAttributes || '{}';
    const finalThemeAdditionalAttributes: IThemeAttachments = JSON.parse(
      tempThemeAdditionalData
    );
    const logoUrl = finalThemeAdditionalAttributes?.attachment?.logo?.url || '';
    return logoUrl;
  };

  const playSound = async () => {
    const audio = new Audio(sound);
    try {
      await audio.play();
    } catch (err) {
    }
  }

  const showNotification = (title: string, description: string, info: any) => {
    notification.open({
      message: info?.type === NOTIFICATION_TYPES.POPULATION_GROUP_ACTIVITY ?
        `${info?.groups?.[0]?.forms?.formName} was sent to ${info?.groups?.[0]?.forms?.successCount}/${info?.groups?.[0]?.forms?.successCount + info.groups?.[0]?.forms?.failCount + info.groups?.[0]?.forms?.pendingCount} members` :
        info.title || '',
      description: isMentionedMessage(description) ? (
        <Viewer initialValue={description} />
      ) : (
        info?.type === NOTIFICATION_TYPES.POPULATION_GROUP_ACTIVITY ? 'Details of send form action on population group available' : info.type === NOTIFICATION_TYPES.CONVERSATION_UPDATED ? description : info.description || ''
      ),
      duration: 3,
      closeIcon: (
        <Countdown
          valueStyle={{
            fontSize: 12,
          }}
          value={Date.now() + 4000}
          format="s"
        />
      ),
      placement: 'top',
      style: {
        borderRadius: 8,
      }
    });
    if (title || description || info?.title || info?.description) {
      playSound();
    }
    if (title || description) {
      if (!document?.hidden) {
        return;
      }
      const icon = getLogoUrl();
      const body: string = getBody(description, info) || '';
      const notificationChrome = new Notification(title, {body, icon});
      notificationChrome.onclick = () => {
        notificationChrome.close();
        window.parent.focus();
      };
    }
  };

  const getBody = (description: string, info: any) => {
    let body = '';
    if (info?.messageDetails?.content && description) {
      if (info?.messageDetails?.contentType === MESSAGE_CUSTOM_CARD_TYPE.CONTACT_CARD) {
        body = description.split(':')[0] + ': ' + 'Custom card';
      } else {
        body = description;
      }
    } else if (description && description.split(':').length > 1 && description.split(':')[1].replace(/\s+/g, ' ').trim() === 'null') {
      body = description.split(':')[0] + ': ' + 'Image';
    }
    return body;
  };

  const requestAndShowPermission = (
    title: string,
    description: string,
    info: any
  ) => {
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        showNotification(title, description, info);
      }
    });
  };

  const showNewNotification = (
    title: string,
    description: string,
    info: any
  ) => {
    if (permission) {
      if (permission === 'granted') {
        showNotification(title, description, info);
      } else if (permission === 'default') {
        requestAndShowPermission(title, description, info);
      }
    } else {
      // alert("Use normal alert");
    }
  };

  const [count, setCount] = useState<number>(0);
  const [visible, setVisible] = useState(false);
  const userIdBigInt = getUserId()
  const callGetConversation = async (conversationId?: string) => {
    if (conversationId) {
      try {
        const response = await GetConversationByConversationId({
          variables: {
            conversationId: parseInt(conversationId),
            loginUserIntId: userIdBigInt,
            loginUserUuid: userId,
          },
        });
        if (response?.data) {
          setConversation(response?.data?.conversations[0])
          setContactData(getContactDataAndType(response?.data?.conversations[0])?.contactData || {})
        }
      } catch (error) {
        setNotificationDrawerState((prev) => ({
          ...prev,
          showChatDrawer: false,
        }));
        showToast(
          toast,
          intl.formatMessage({id: 'errorMsg'}),
          ToastType.info,
        );
      }
    }
  }


  const [getContactsFromIds] = useLazyQuery(
    ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const getPopulationGroupActivity = (singleNotification: any) => {
    const groupIds: any[] = []
    const groupActivityLogIds = [singleNotification?.metaData?.groups?.[0]?.groupActivityLogId]
    const formIds = [singleNotification?.metaData?.groups?.[0]?.form[0]?.formId]
    singleNotification?.metaData?.groups?.forEach((group: any) => {groupIds.push(group?.groupId)})
    const objectForCall = {groupIds: groupIds, groupActivityLogIds: groupActivityLogIds, formIds: formIds}
    const response: any[] = []
    const contactIds = response.map((resp) => {resp?.contactId})
    // make call for contactIds data. use then in it and save the data below
    const allContactsData = []
    const contactQuery = useQuery(ContactsQueries.GET_CONTACTS_BY_CONTACT_IDS, {
      fetchPolicy: 'no-cache',
      variables: {
        contactIds: contactIds,
      },
      onCompleted: (data: any) => {
        if (data && data.contacts && data.contacts.length > 0) {
          data?.contacts?.forEach((contact: any) => {
            response?.some((item, index) => {
              if (item.contactId === contact?.uuid) {
                response[index].contactdata = contact
              }
            })
          })
        }
      }, onError: (error: any) => {
      }
    });
    return
  }

  const [GetConversationByConversationId, conversationLoading] = useLazyQuery<INewConversationResponse>(
    ConversationsQueries.GetConversationByConversationId,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const onClick = async (type: string, singleNotification: SingleNotification) => {
    switch (type) {
      case NOTIFICATION_TYPES.PRESCRIBE_DOSE_SPOT_MEDICATION_COUNT_UPDATE:
        setNotificationDrawerState((prev) => {
          return {
            ...prev,
            selectedNotification: singleNotification,
            showModal: true,
            type,
          }
        });
        break;
      case NOTIFICATION_TYPES.NEW_APPOINTMENT:
      case NOTIFICATION_TYPES.APPOINTMENT_RSVP:
        setNotificationDrawerState((prev) => ({
          ...prev,
          selectedNotification: singleNotification,
          showAppointmentDetailsDrawer: true,
        }));
        break;
      case NOTIFICATION_TYPES.FORM_SUBMIT:
        setNotificationDrawerState((prev) => ({
          ...prev,
          selectedNotification: singleNotification,
          showFormResponseDrawer: true,
        }));
        break;
      case NOTIFICATION_TYPES.POPULATION_GROUP_ACTIVITY:
        setNotificationDrawerState((prev) => ({
          ...prev,
          selectedNotification: singleNotification,
          showPopulationGroupFormStatusDrawer: true,
        }));
        break;
      case NOTIFICATION_TYPES.POPULATION_GROUP_ACTIVITY:
        setNotificationDrawerState((prev) => ({
          ...prev,
          selectedNotification: singleNotification,
          showPopulationGroupFormStatusDrawer: true,
        }));
        break;
      case NOTIFICATION_TYPES.CONVERSATION_UPDATED:
        setNotificationDrawerState((prev) => ({
          ...prev,
          selectedNotification: singleNotification,
          showChatDrawer: true,
        }));
        await callGetConversation(singleNotification?.typeId)
        break;
        case NOTIFICATION_TYPES.TASK_MENTIONED:
        case NOTIFICATION_TYPES.TASK_CREATED:
        if (
          singleNotification?.metaData?.taskId ||
          singleNotification?.metaData?.task?.id
        ) {
          setNotificationDrawerState((prev) => ({
            ...prev,
            selectedNotification: singleNotification,
            showTaskDrawer: true,
          }));
        }
        break;
    }
    setVisible(false);
    refreshCount();
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };
  const userType = 'USER';
  const userId = getUserUUID();
  const tenantId = getAccountUUID();
  const refreshCount = (isPageRefresh?: boolean) => {
    const wi: any = window;
    if (isPageRefresh && wi && wi?.notificationCount != undefined) {
      setCount(wi?.notificationCount);
      return;
    }
    getNotificationCount({
      userId,
      userType,
      tenantId,
    })
      .then((response) => {
        const count = response?.aggregateNotification?.aggregate?.count;
        wi.notificationCount = count;
        setCount(count);
      })
      .catch((error) => {

      });
  };

  useEffect(() => {
    try {
      refreshCount(true);
    } catch (error) {

    }
  }, []);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const newNotification = async (data: any) => {
      if (data?.account_id === accountId) {
        const singleNotification = data?.data || {};
        const hideScreenList = singleNotification.hideScreenList || [];
        let isRefresh = false;
        const isHide = hideScreenList.some(
          (screen: {code: string; isRefresh?: boolean}) => {
            if (
              document?.URL?.indexOf(screen.code) != -1 ||
              screen.code == 'any'
            ) {
              isRefresh =
                screen.code == 'any' &&
                (screen.isRefresh ? screen.isRefresh : false);
              return true;
            }
          }
        );
        if (isHide && document.visibilityState === 'visible') {
          if (isRefresh) {
            const wi:any = window;
            if(count > 12 ||  wi?.notificationCount > 12){
              setCount((oldCount)=>{
                const newCount = (oldCount || wi?.notificationCount) - 1;
                wi.notificationCount = newCount;
                return newCount
              });
              return
            }
            refreshCount();
          }
          return;
        }
        const notificationData = getNotificationDataForEmailNotification(cloneDeep(singleNotification));
        const isConversation = isConversationNotification(notificationData);
        if (isConversation) {
          const response = await getConversationAdditionalAttributes({
            variables: {
              ids: [Number(notificationData.typeId)],
            },
          });
          const conversations: Record<string, any>[] =
            response.data?.conversations || [];
          const emailConversations = conversations.filter((item: any) =>
            isChannelEmail(item?.conversationInbox?.channelType || '')
          );
          if (emailConversations.length > 0) {
            const singleEmailConversation = emailConversations[0];
            const {description, title} =
              getTitleAndDescriptionForEmailNotification({
                singleEmailConversation,
                notificationData: {
                  ...notificationData,
                  metaData:{messageData: data?.data?.messageData || {}},
                },
              });
            notificationData.title = title;
            notificationData.description = description;
          }
        }

        const isHtmlDescription = isHTMLString(singleNotification?.description);
        const isJSONDescription = isValidJsonString(singleNotification?.description)
        singleNotification.title = notificationData?.title || '';
        if (isHtmlDescription || isJSONDescription) {
          singleNotification.description = '';
        }
        refreshCount();
        showNewNotification(
          notificationData?.title || '',
          (isHtmlDescription || isJSONDescription) ? '' : notificationData?.description || '',
          singleNotification,
        );
      }
    };
    eventBus.addEventListener(
      SUPPORTED_EVENT_CODE.NOTIFICATION_CREATED,
      newNotification
    );

    return () => {
      eventBus.removeEventListener(newNotification);
    };
  }, []);

  return (
    <>
      <View>
        <Popover
          placement="bottom"
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
          style={reactStyles.popover}
          content={
            <View padding={0} width={300}>
              {visible ? (
                <NotificationList
                  onUpdate={refreshCount}
                  onClick={onClick}
                ></NotificationList>
              ) : (
                <></>
              )}{' '}
            </View>
          }
        >
          <VStack>
            {count > 0 && (
              <>
                {
                  <Badge
                    rounded={'full'}
                    mb={-2}
                    mr={-4}
                    mt={count > 0 ? -2 : 0}
                    zIndex={100}
                    variant="solid"
                    backgroundColor={Colors.error[500]}
                    alignSelf="flex-end"
                    size={'smMedium'}
                    style={styles.badge}
                    _text={{
                      fontSize: 9,
                      fontWeight: 'bold',
                    }}
                  >
                    {count > 9 ? '9+' : count}
                  </Badge>
                }
                {false && (
                  <View
                    zIndex={1}
                    style={styles.badgeView} 
                    backgroundColor={'#DB0C3D'}
                  ></View>
                )}
              </>
            )}

            <View>
              <NotificationSvg />
              {/* <BellSvg isSelected={true} /> */}
              {/* <AntIcon name="bells" size={30} /> */}
            </View>
          </VStack>
        </Popover>
        {/* <BulkImportedPreviewPopup
        isOpen={isOpenBulkImportResultModal}
        metadata={bulkImportResultData}
        onClose={() => setIsOpenBulkImportResultModal(false)}
      /> */}
      </View>
      {notificationDrawerState.showModal && (
        <DoseSpotNotification
          notification={notificationDrawerState.selectedNotification}
          type={notificationDrawerState.type}
          onClose={() => {
            setNotificationDrawerState((prev) => {
              return {
                ...prev,
                showModal: false,
              };
            });
          }}
        />
      )}
      {
        notificationDrawerState.showAppointmentDetailsDrawer &&
        notificationDrawerState.selectedNotification?.metaData?.appointment?.id &&
        <LoadAppointmentDetailsDrawer
          key={notificationDrawerState.selectedNotification?.id}
          appointmentId={notificationDrawerState.selectedNotification?.metaData?.appointment?.id}
          onClose={() => {
            setNotificationDrawerState((prev) => ({
              ...prev,
              showAppointmentDetailsDrawer: false,
              selectedNotification: undefined,
            }));
          }}
        />
      }
      {
        notificationDrawerState.showFormResponseDrawer &&
        notificationDrawerState.selectedNotification?.typeId &&
        notificationDrawerState.selectedNotification?.metaData?.contactData?.uuid &&
        notificationDrawerState.selectedNotification?.metaData?.formData?.[0]?.id &&
        <FormResponseView
          onClose={() => {
            setNotificationDrawerState((prev) => ({
              ...prev,
              showFormResponseDrawer: false,
              selectedNotification: undefined,
            }));
          }}
          contactId={notificationDrawerState.selectedNotification.metaData.contactData.uuid}
          formId={notificationDrawerState.selectedNotification.metaData.formData[0].id}
          formLogId={notificationDrawerState.selectedNotification.typeId}
          formName={notificationDrawerState.selectedNotification.metaData.formData[0].name}
          showPatientBanner={true}
        />
      }
      {
        notificationDrawerState.showChatDrawer && conversation && contactData && (
          <InstantChatView
            selectedConversation={conversation}
            contactData={contactData}
            isLoading={conversationLoading?.loading}
            isDrawerVisible={true}
            isInstantChatView={true}
            instantChatFromNotifications={true}
            onActionPerformed={(actionCode: any, actionData?: any) => {
              if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                setNotificationDrawerState((prev) => ({
                  ...prev,
                  showChatDrawer: false,
                  selectedNotification: undefined,
                }));
                setConversation(undefined)
                setContactData(undefined)
              } else if (actionCode === CONVERSATION_ACTION_CODES.MESSAGE_READ) {
                setConversation({
                  ...conversation,
                  unreadMessages: [],
                  unreadMsgCount: 0
                })
              } else if (actionCode === COMMON_ACTION_CODES.ITEM_CLICKED || CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT) {
                setConversation(actionData)
              }
            }}
          />
        )
      }
      {
        notificationDrawerState.showPopulationGroupFormStatusDrawer && (
          <PopulationGroupFormDrawer
            open={true}
            title={'Population Group'}
            width={'45%'}
            onClose={() =>
              setNotificationDrawerState((prev) => ({
                ...prev,
                showPopulationGroupFormStatusDrawer: false,
              }))
            }
            notification={notificationDrawerState?.selectedNotification}
          />
      )}
      {
        notificationDrawerState.showPopulationGroupFormStatusDrawer && (
          <PopulationGroupFormDrawer
            open={true}
            title={'Population Group'}
            width={'45%'}
            onClose={() =>
              setNotificationDrawerState((prev) => ({
                ...prev,
                showPopulationGroupFormStatusDrawer: false,
              }))
            }
            notification={notificationDrawerState?.selectedNotification}
          />
      )}
      {notificationDrawerState.showTaskDrawer && (
        <TaskNotificationDrawer
          taskId={notificationDrawerState.selectedNotification?.metaData?.taskId || notificationDrawerState.selectedNotification?.metaData?.task?.id}
          visible={notificationDrawerState.showTaskDrawer}
          onClose={() =>
            setNotificationDrawerState((prev) => ({
              ...prev,
              showTaskDrawer: false,
              selectedNotification: {},
            }))
          }
        />
      )}
    </>
  );
};
const Notifications = React.memo(NotificationsC);
export default Notifications;

const reactStyles: Record<string, React.CSSProperties> = {
  popover: {
    minWidth: 'max-content',
  },
};

const styles = StyleSheet.create({
  badge: {
    padding: 0
  },
  badgeView: {
    width: 8,
    height: 8,
    // backgroundColor: '#DB0C3D',
    borderRadius: 12,
    left: 11,
    top: 4,
  },
});
