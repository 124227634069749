import React from 'react';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
} from '../FormComponents/FormComponents';
import {getUniqueKey} from '../CustomFormEngineUtils';
import {ObjectiveFindingsSchema} from '../Schema/ComponentsSchema';
import {cloneDeep} from 'lodash';
import { SubjectiveComplaintFieldFormElement } from './SubjectiveComplaintField';
import SubjectiveComplaintSvg from '../../../../../assets/Icons/FormBuilder/SubjectiveComplaintSvg';
import { v4 as uuidV4 } from 'uuid';

export const ObjectiveFindingsFieldFormElement: FormElement = {
  type: ObjectiveFindingsSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    ...cloneDeep(ObjectiveFindingsSchema),
    key: getUniqueKey(map, ObjectiveFindingsSchema.key),
    referenceId: uuidV4(),
  }),
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(ObjectiveFindingsSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: SubjectiveComplaintSvg,
    label: ObjectiveFindingsSchema.label || '',
  },
  designerComponent: SubjectiveComplaintFieldFormElement.designerComponent,
  formComponent: SubjectiveComplaintFieldFormElement.formComponent,
  propertiesComponent: SubjectiveComplaintFieldFormElement.propertiesComponent,
};

export default ObjectiveFindingsFieldFormElement;
