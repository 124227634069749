import React from "react";
import { useDraggable } from "@dnd-kit/core";
import { ElementSource, FormElementInstance } from "../FormComponents/FormComponents";
import LibNodeCard from "../../../Workflow/LibNodeCard/LibNodeCard";
import DefaultComponentSvg from "../../../../../assets/Icons/FormBuilder/DefaultComponentSvg";

function SidebarElement({
  index,
  component,
  label,
  icon,
}: {
  index: number,
  component: FormElementInstance,
  label: string,
  icon?: React.ElementType,
}) {
  const LibIcon: React.ElementType = icon || DefaultComponentSvg;
  const title = label;
  const draggable = useDraggable({
    id: `designer-btn-${component.type}-${index}`,
    data: {
      type: component.type,
      source: ElementSource.sidebar,
      title,
      icon: LibIcon,
      component,
    },
  });
  return (
    <div
      ref={draggable.setNodeRef}
      {...draggable.listeners}
      {...draggable.attributes}
      className="flex cursor-grab flex-col library-element-card"
    >
      <LibNodeCard
        title={title || ''}
        style={{
          paddingHorizontal: 8,
          paddingVertical: 8,
        }}
        iconElement={<LibIcon />}
    />
    </div>
  );
}

export default React.memo(SidebarElement);
