
export const MedicationDataElation = {
    medicationName: 'ARIPiprazole 10 mg Tab Disintegrating',
    sig: '1 tablet orally daily',
    qty: 2,
    unit: 'amp',
    riffils: 2,
    daysSupply: 10,
    diagnosisCode: 'abc (aneurysmal bone cyst)',
    instructionToPharmacy: 'Please call me before deliver medicine',
    doNotFillBeforeDate:  '09/30/2024' //getNextDate(2),
}

export const MedicationDataAthena = {
    ordersName: 'aspirin 325 mg tablet, delayed release',
    dosageQuantity: 20,
    qty: 2,
}

//demo example
export const DiagnosesDataAthena = {
    diagnosesName: 'ankle pain',
}

export const DiagnosesDataElation = {
    diagnosesName: 'Test anxiety',
}

export const ImagingOrderDataAthena = {
    imagingName: 'MAMMO, 3D rendering',
}