import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from '../FormComponents/FormComponents';
import {getUniqueKey} from '../CustomFormEngineUtils';
import {PatientDemographicsSchema} from '../Schema/ComponentsSchema';
import {cloneDeep, debounce} from 'lodash';
import Description from '../BaseComponents/Description';
import AddOrUpdatePatientDemographics, {
  IPatientDemographicsComponentValue,
} from '../../FHFormio/CustomComponents/PatientDemographics/AddOrUpdatePatientDemographics/AddOrUpdatePatientDemographics';
import {
  useFormRendererContext,
} from '../Context/FormRenderer.context';
import { FormRendererActionType } from '../Context/FormRendererReducer';
import {CustomFormBuilderActionTypes} from '../CustomFormEngineInterfaces';
import {useCustomFormBuilderContext} from '../Context/CustomFormBuilder.context';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import QuillConfig from '../../FHFormio/Builder/QuillConfig';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import {usePropertiesFormRenderer} from '../Hooks/usePropertiesFormRenderer';
import {FormRenderer} from '../FormRenderer';
import { ALLOW_OVERRIDE_COMPONENT, CustomComponentKey, getComponentField, getFieldDisplayName, isRequiredField } from '../../FHFormio/CustomComponents/CustomComponentUtils';
import { IEhrCapability } from '../../../../../Interfaces';
import { CapabilityResource } from '../../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import PatientDemographicsSvg from '../../../../../assets/Icons/FormBuilder/PatientDemographicsSvg';
import { v4 as uuidV4 } from 'uuid';

export const PatientDemographicsFieldFormElement: FormElement = {
  type: PatientDemographicsSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(PatientDemographicsSchema),
    key: getUniqueKey(map, PatientDemographicsSchema.key),
  }),
  designerBtnElement: {
    icon: PatientDemographicsSvg,
    label: PatientDemographicsSchema.label || '',
  },
  propertiesComponent: PropertiesComponent,
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(PatientDemographicsSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
};

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  disabled,
  validationRef,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: IPatientDemographicsComponentValue;
  onChange: (value: IPatientDemographicsComponentValue) => void;
  errorMessage?: string;
  disabled?: boolean;
  validationRef?: React.MutableRefObject<any>;
}) {
  const {label, validate, description, tooltip, hideLabel} = elementInstance;

  return (
    <div className="flex flex-col gap-1 w-full">
      {/* {!hideLabel && false && (
        <Label
          label={label || ''}
          isRequired={validate?.required}
          tooltip={tooltip}
          isReadOnly={disabled}
        />
      )} */}
      <AddOrUpdatePatientDemographics
        component={{
          ...elementInstance,
          selectedValue: defaultValue,
        }}
        disabled={disabled || false}
        options={{}}
        validateRef={validationRef || {}}
        setValueRef={{}}
        value={defaultValue}
        onChange={onChange}
      />
      {description && <Description description={description} />}
      {Boolean(errorMessage) && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState<IPatientDemographicsComponentValue>();
  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={setValue}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: IPatientDemographicsComponentValue;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const {state, dispatch} = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<
    IPatientDemographicsComponentValue | undefined
  >(defaultValue || selectedValue);
  const element = elementInstance;
  const validationRef = useRef<any>(undefined);
  const isMounted = useRef(true);
  const isInitialMount = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(
    async (
      valueToValidate?: IPatientDemographicsComponentValue,
      silentCheck?: boolean
    ): Promise<ValidationResult> => {
      try {
        if (!isMounted.current) return {
          isValid: true,
          errorMessage: '',
          key: elementInstance.key,
          fieldValue: valueToValidate
        };
        const result = await validationRef.current?.(valueToValidate, silentCheck);
        return {
          isValid: result?.isValid ?? true,
          errorMessage: result?.message || '',
          key: element.key,
          fieldValue: valueToValidate,
        };
      } catch (error) {
        console.error(`Validation error for ${element.key}:`, error);
        return {
          isValid: true,
          errorMessage: 'Validation failed unexpectedly',
          key: element.key,
          fieldValue: valueToValidate,
        };
      }
    },
    [element.key]
  );

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () =>
      debounce(async (valueToValidate: IPatientDemographicsComponentValue) => {
        if (!isMounted.current) return;
        const result = await validateField(valueToValidate);
        dispatch({
          type: FormRendererActionType.SET_INVALID_FIELDS,
          payload: {key: result.key, errorMessage: result.errorMessage},
        });
        return result;
      }, 300),
    [validateField]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(
    async (newValue: IPatientDemographicsComponentValue) => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }
      setValue(newValue);
      if (!submitValue) return;
      await debouncedValidation(newValue);
      submitValue(elementInstance.key, newValue);
    },
    [debouncedValidation, elementInstance.key, submitValue]
  );

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
      validationRef={validationRef}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      disabled={isReadOnly}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const {state, dispatch, ehrCapabilities, userSettings} = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance;

  const options = useMemo(() => {
    const capabilities: IEhrCapability[] = ehrCapabilities || [];
    const capability = capabilities.find((capability) => capability.resourceName == CapabilityResource.patient);
    const keyAllowedOperations = capability?.abilities?.keyAllowedOperations || {};
    const commField = getComponentField(CustomComponentKey.PATIENT_DEMOGRAPHICS, keyAllowedOperations);
    const finalFieldList = commField.map((demographicField) => {
      return {
        label: getFieldDisplayName(CustomComponentKey.PATIENT_DEMOGRAPHICS, demographicField, keyAllowedOperations),
        value: demographicField,
        disabled: isRequiredField(CustomComponentKey.PATIENT_DEMOGRAPHICS, demographicField, keyAllowedOperations),
      };
    });
    return finalFieldList;
  }, [ehrCapabilities]);

  const formElements = useMemo(
    () => [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      ALLOW_OVERRIDE_COMPONENT,
      ...(isAllowShare ? ShareWithPatientFields : []),
      {
        type: 'checkBoxes',
        key: 'enabledFields',
        label: 'Select Communication Field',
        input: true,
        validate: {
          required: true,
        },
        dataSrc: 'values',
        data: {
          values: options,
        },
      },
      {
        type: 'textarea',
        input: true,
        key: 'description',
        label: 'Description',
        placeholder: 'Description for this field.',
        tooltip:
          'The description is text that will appear below the input field.',
        editor: 'quill',
        wysiwyg: QuillConfig,
      },
      ...NewConditionalFields,
      ...KeyField,
    ],
    [options, isAllowShare]
  );

  const {formData, formattedFormData, components, handleFormDataChange} =
    usePropertiesFormRenderer({
      initialValues: element as Record<string, any>,
      components: formElements,
    });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: {updatedElement: formData, builderComponents: state.elements},
    });
  }, [formData, element]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default PatientDemographicsFieldFormElement;
