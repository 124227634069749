import {IKeyOperation} from '../../../../../../../Interfaces';
import {IAddOrUpdatePastMedicalHistoryParams, IPastMedicalHistory, IPastMedicalHistoryComponentValue} from '../interfaces';
import { PastMedicalHistoryFields } from '../../CustomComponentUtils';
import { ComponentType } from '../../Diagnosis/interfaces';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { getFieldCapabilities } from '../../../../../../../utils/capabilityUtils';
import { IReconciliationDisplay } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { Condition } from 'fhir/r4';
import { IFormatReconciliationMetaData, IReconciliationConflictField } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { FHIRConflictFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { getMomentObj } from '../../../../../../../utils/DateUtils';
import { getDateStrFromFormat } from '../../../../../../../utils/DateUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { getFormattedDate } from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants/StringConst';
import { getTextFromCoding } from '../../../../../Integration/FhirUtils';
import { getValidCoding } from '../../../../../Integration/FhirUtils';

const extension_url_type = 'type'
export const getRecordListFormatted = (list: any[],fields?:IKeyOperation) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries, fields);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[],fields?:IKeyOperation): IPastMedicalHistory[] => {
  const tempFormattedList: IPastMedicalHistory[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const status = item?.clinicalStatus?.coding?.length
        ? item.clinicalStatus?.coding[0]
        : {code: item.clinicalStatus?.text};
      let note = '';
      if (item?.note?.length) {
        note = item.note[0].text || '';
      }
      const pastMedicalHistoryType = item?.extension?.length && fields?.type?.extensionUrl ? item.extension.find((ext: {url:string,valueString:string}) => ext.url == fields?.type?.extensionUrl)?.valueString : null;
      const type = item?.extension?.length ? item.extension.filter((ext: {url:string,valueString:string}) => ext.url.includes(extension_url_type))?.[0]?.valueString : null;
      const tempObj: IPastMedicalHistory = {
        id: item?.id,
        onSetDateTime: item?.onsetDateTime,
        pastMedicalHistory: item.code,
        name: getPastMedicalHistoryName(item.code),
        clinicalStatus: status,
        note,
        uniqueId: item?.id,
        isFreeTextRecord: !item.code?.coding?.length,
        type,
        showNote: note.length > 0 || fields?.note?.isRequired,
        pastMedicalHistoryType: {code: pastMedicalHistoryType},
        fieldType: item?.category?.[0]?.code || 'past-medical-history', // if no category then set it to PastMedicalHistory for backward compatibility
        extension: item.extension,
      };

      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): IPastMedicalHistoryComponentValue => {
  return {
    pastMedicalHistories: [
      {
        id: '34234324',
        onSetDateTime: '2020-12-24',
        pastMedicalHistory: {
          text: `Sample ${
            'Medical History'
          }`,
          coding: [],
        },
        name: `Sample ${
          'Medical History'
        }`,
        clinicalStatus: {code: 'active', display: 'Active'},
        note: 'Test notes',
        uniqueId: '1234',
        isFreeTextRecord: false,
      },
    ],
  };
};

export const sortItems = (records: IPastMedicalHistory[]) => {
  return records.sort((a: IPastMedicalHistory, b: IPastMedicalHistory) => {
    const statusA = a.clinicalStatus?.code || '';
    const statusB = b.clinicalStatus?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(
        b.recordedDate || b.onSetDateTime || new Date()
      ).getTime() -
      new Date(
        a.recordedDate || a.onSetDateTime || new Date()
      ).getTime()
    );
  })
};

export const getPastMedicalHistoryName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: PastMedicalHistoryFields,
  pastMedicalHistoryData: IPastMedicalHistory,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== PastMedicalHistoryFields.code && pastMedicalHistoryData.isFreeTextRecord && fields?.code?.hideFieldsForFreeText) {
    return false;
  }
  switch (field) {

    case PastMedicalHistoryFields.code:
      if ((fields?.type?.isRequired || false) && submitTriggered) {
        return !pastMedicalHistoryData.pastMedicalHistoryType || !pastMedicalHistoryData.pastMedicalHistoryType.code;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdatePastMedicalHistoryParams) => {
  return {
    resourceType: 'Condition',
    ...(params.id && {id: params.id}),
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...((params.extension || []).length > 0 && {extension: params.extension}),
    code: params.pastMedicalHistory,
    category: [
      {
        coding: [
          {
            code: ComponentType?.Condition,
          },
        ],
      },
    ],
  };
};

const getHieConditionDate = (
  resource: Condition,
  onsetDateFormat?: string | undefined
) => {
  const conditionDate = resource.onsetDateTime || resource.onsetPeriod?.start;

  if (conditionDate) {
    const onsetDate = onsetDateFormat
      ? getDateStrFromFormat(conditionDate, onsetDateFormat)
      : getMomentObj(conditionDate || new Date()).toISOString();

    return onsetDate;
  }
};

const getHieClinicalStatus = (
  resource: Condition,
  possibleValues?: {code: string; display: string}[]
) => {
  const clinicalStatus = resource?.clinicalStatus?.coding?.length
    ? resource.clinicalStatus?.coding[0]
    : {
        code: resource.clinicalStatus?.text,
        display: resource.clinicalStatus?.text,
      };

  return possibleValues?.find(
    (possibleValue) => possibleValue.code === clinicalStatus.code?.toLowerCase()
  );
};

const getHieNote = (resource: Condition) => {
  const noteText = resource?.note?.[0]?.text;
  return noteText;
};

export const getConditionCodeInvalidField = (isConflict?: boolean, value?: any) => {
  return {
    inputType: FHIRConflictFields.code,
    field: PastMedicalHistoryFields.code,
    invalid: !value?.coding?.length,
    label: 'Past Medical History',
    placeholder: 'Search Past Medical History',
    isConflict: isConflict,
    value: value,
    isRequired: true,
    extraData: {
      searchType: PAMISearchType.PastMedicalHistory,
    }
  }
}

/**
 * Validates and formats a FHIR Condition resource based on provided fields and metadata
 *
 * @param resource - The FHIR Condition resource to validate and format
 * @param fields - Key operations defining validation rules and allowed values for each field
 * @param metaData - Metadata containing target resource data and other formatting configuration
 * @returns The validated and formatted Condition resource with any validation conflicts
 */
export const validateAndFormatPastMedicalHistoryHieResource = (
  resource: Condition,
  fields: IKeyOperation,
  metaData: IFormatReconciliationMetaData
) => {
  const conflictFields: IReconciliationConflictField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = PastMedicalHistoryFields[key as keyof typeof PastMedicalHistoryFields];
    if (!fields[key as keyof typeof fields]?.isHidden) {
      switch (field) {
        case PastMedicalHistoryFields.code:
          {
            conflictFields.push(
              getConditionCodeInvalidField(false, resource.code)
            );
          }
          break;

        case PastMedicalHistoryFields.onsetDateTime:
          {
            const onsetDateFormat = fields?.onsetDateTime?.format;
            const onsetDateTime = getHieConditionDate(
              resource,
              onsetDateFormat
            );
            const isMissing =
              !onsetDateTime && fields?.onsetDateTime?.isRequired;

            conflictFields.push({
              inputType: FHIRConflictFields.date,
              field: PastMedicalHistoryFields.onsetDateTime,
              invalid: isMissing || false,
              value: onsetDateTime,
              label: 'Since',
              isRequired: fields?.onsetDateTime?.isRequired || false,
              extraData: {
                dateFormat: onsetDateFormat,
              },
            });
          }
          break;

        case PastMedicalHistoryFields.clinicalStatus:
          {
            const status = getHieClinicalStatus(
              resource,
              fields?.clinicalStatus?.possibleValues
            );
            const isMissing =
              !status?.code && fields?.clinicalStatus?.isRequired;

            if (!isMissing && status) {
              resource.clinicalStatus = {
                coding: [status],
              };
            }
            conflictFields.push({
              field: PastMedicalHistoryFields.clinicalStatus,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: status?.code,
              label: 'Status',
              isRequired: fields?.clinicalStatus?.isRequired || false,
              extraData: {
                statusList: fields?.clinicalStatus?.possibleValues || [],
              },
            });
          }
          break;

        case PastMedicalHistoryFields.note:
          {
            const hieNote = getHieNote(resource);
            const isMissing = !hieNote && fields?.note?.isRequired;
            if (!isMissing && hieNote) {
              resource.note = [{text: hieNote}];
            }
            conflictFields.push({
              field: PastMedicalHistoryFields.note,
              inputType: FHIRConflictFields.textArea,
              invalid: isMissing || false,
              value: hieNote,
              isRequired: fields?.note?.isRequired || false,
              label: 'Note',
            });
          }
          break;
      }
    }
  });

  return {
    conflictFields: conflictFields,
    resource: resource,
  };
};

const getHieConditionCodes = (
  resource: Condition,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.code;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: getValidCoding(codeObj, allowedCodeSystems), text: text};
};

export const formatReconciliationPastMedicalHistoryData = (
  resource: Condition,
  metaData: IFormatReconciliationMetaData
):
  | {display: IReconciliationDisplay; resourceData: any; conflictFields: IReconciliationConflictField[]}
  | undefined => {
  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.pastMedicalHistory,
  ]);

  const conditionDate = getHieConditionDate(resource);
  const formattedDate = conditionDate ? getFormattedDate(conditionDate, DISPLAY_DATE_FORMAT) : '';
  const fieldCapabilities = getFieldCapabilities(capabilities);

  const status = resource?.clinicalStatus?.coding?.length
  ? resource.clinicalStatus?.coding[0]
  : {code: resource.clinicalStatus?.text};

  const name = getHieConditionCodes(resource, fieldCapabilities?.code?.allowedCodeSystems)?.text || '';
  const display = {
    title: name,
    status: [formattedDate, status?.display].filter(Boolean).join(' • '),
    date: resource.note?.[0]?.text,
  };

  const validatedData = validateAndFormatPastMedicalHistoryHieResource(
    (metaData?.targetResourceData || resource) as Condition,
    fieldCapabilities || {},
    metaData
  );

  return {
    resourceData: validatedData.resource,
    display,
    conflictFields: validatedData.conflictFields,
  };
};

export const updateHieConditionMatchedData = (
  resource: Condition,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  const updatedResource = updateHiePMHData(resource, conflictFields, metaData);
  return formatReconciliationPastMedicalHistoryData(updatedResource, metaData);
};

export const updateHiePMHData = (
  resource: Condition,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  conflictFields.map((conflictField) => {
    switch (conflictField.field) {
      case PastMedicalHistoryFields.code:
        {
          resource.code = conflictField.value;
        }
        break;
      case PastMedicalHistoryFields.onsetDateTime:
        {
          resource.onsetDateTime = conflictField.value;
        }
        break;
      case PastMedicalHistoryFields.note:
        {
          resource.note = [{text: conflictField.value}];
        }
        break;
      case PastMedicalHistoryFields.clinicalStatus:
        {
          const capabilities = getEHRCapability(metaData.ehrCapabilities, [
            CapabilityResource.pastMedicalHistory,
          ]);
          const fieldCapabilities = getFieldCapabilities(capabilities);
          const status =
            fieldCapabilities?.clinicalStatus?.possibleValues?.find(
              (value: any) => value.code == conflictField.value
            );
          if (status) {
            resource.clinicalStatus = {
              coding: [status],
            };
          }
        }
        break;
    }
    return conflictField;
  });
  return resource;
};