import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { FormElementInstance } from "../FormComponents/FormComponents";
import { useFormValidation } from "./useFormValidation";
import { FormRendererActionType, formRendererReducer } from "../Context/FormRendererReducer";

interface IUseFormRendererProps {
  components: FormElementInstance[];
  defaultValues: Record<string, string>;
  isReadOnly: boolean;
  hideErrorMessages?: boolean;
  builderComponents?: FormElementInstance[];
  onSubmit?: () => Promise<void>;
  batchSize?: number;
  onDefaultDataSet?: (defaultValues: Record<string, string>) => void;
}

export const useFormRenderer = (args: IUseFormRendererProps) => {
  const [state, dispatch] = useReducer(formRendererReducer, {
    components: [],
    filteredComponents: [],
    filledDataMap: args.defaultValues || {},
    invalidFields: {},
    isSubmitClicked: false,
    isSubmitting: false,
    hideErrorMessages: args.hideErrorMessages ?? false,
  });

  // Memoize validation dependencies
  const validationDeps = useMemo(() => ({
    components: args.components,
    batchSize: args.batchSize,
    dispatch: dispatch
  }), [args.components, args.batchSize, state.filledDataMap]);

  const {
    isValidating,
    validationProgress,
    registerValidation,
    validateForm
  } = useFormValidation(validationDeps);

  // Memoize component state updates
  const updateComponents = useCallback(() => {
    dispatch({
      type: FormRendererActionType.SET_COMPONENTS,
      payload: {
        components: args.components,
        builderComponents: args.builderComponents
      },
    });
  }, [args.components, args.builderComponents]);

  useEffect(() => {
    updateComponents();
  }, [updateComponents]);

  useEffect(() => {
    dispatch({
      type: FormRendererActionType.INITIALIZE_DATA,
      payload: args.defaultValues,
    });
    args.onDefaultDataSet?.(args.defaultValues);
  }, [args.defaultValues]);

  const handleSubmit = useCallback(async (silentCheck?: boolean) => {
    const validationResult = await validateForm(state.filledDataMap, silentCheck);

    if (validationResult.isValid) {
      await args.onSubmit?.();
    }

    return validationResult;
  }, [validateForm, args.onSubmit, state.filledDataMap]);

  return {
    state,
    dispatch,
    isValidating,
    validationProgress,
    registerValidation,
    handleSubmit,
  };
};
