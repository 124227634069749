import {Drawer, Pagination, Table} from 'antd';
import {Center, HStack, Input, Pressable, Select, Spinner, View} from 'native-base';
import React, {useContext, useEffect} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {BUTTON_TYPE} from '../../../../../constants';
import {Colors} from '../../../../../styles';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {TableWrapper} from '../../../../common/TableWrapper';
import {ContentTypes} from '../../ContentManagementConsts';
import {getMediaContent, getSortString} from '../../ContentManagementUtils';
import {AddMediaModal} from '../../MediaLibrary/AddMediaModal';
import {
  MediaContentApiData,
  MediaContentMetaData,
} from '../../MediaLibrary/MediaLibraryListView/interfaces';
import {
  getFormattedMediaContent,
  mediaFilter,
} from '../../MediaLibrary/MediaLibraryUtils';
import {MediaPreview} from '../../MediaLibrary/MediaPreview';
import {IMediaFilter, IMediaLibraryData} from '../../MediaLibrary/interfaces';
import {styles} from './MediaSelectioModalStyle';
import {getMediaSelectionListColumn} from './MediaSelectionModalHelper';
import {Dimensions} from 'react-native';
import {StrCaseConverter} from '../../../../../utils/CaseConverter';
import {NoDataFound} from '../../../../common/NoDataFound';
import NoDataFileIcon from '../../../../common/Svg/WidgetsSvg/NoDataFileIcon';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { testID, TestIdentifiers } from '../../../../../testUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {ToastType} from '../../../../../utils/commonViewUtils';
import {showToast} from '../../../../../utils/commonViewUtils';
import {useCustomToast} from '../../../../Toast/ToastProvider';
import {useIntl} from 'react-intl';

export interface IMediaSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (mediaData: IMediaLibraryData) => void;
  filterKey?: 'image' | 'video' | 'audio' | string;
  mediaFilter?: IMediaFilter[];
  acceptedFiles?: string;
  supportedFormatString?: string;
  filterMediaType?: string[];
  isSms?: boolean;
  isFromSmsTemplate?: boolean;
}

const MediaSelectionModal = (props: IMediaSelectionModalProps) => {
  const {isOpen, onClose} = props;

  const [mediaContentListState, setMediaContentListState] = React.useState({
    isLoading: true,
    isNoDataFound: false,
    mediaList: [] as MediaContentApiData[],
    mediaDisplayList: [] as IMediaLibraryData[],
    mediaMeta: {} as MediaContentMetaData,
    page: 1,
    pageSize: 10,
    searchString: '',
    viewCode: 'listView' as 'listView' | 'gridView',
    isAddMediaModalOpen: false,
    mediaType: props.filterKey,
    loadingStates: false,
  });
  const customToast = useCustomToast();
  const intl = useIntl();
  const {height} = Dimensions.get('window');
  const showAttachment = isAccountConfigEnabled(CONFIG_CODES.ALLOW_ATTACHMENT_IN_SMS)
  const commonContext = useContext(CommonDataContext);
  const isSideCarContext = commonContext.sidecarContext?.isSidecar;
  const mediaTypeList = props?.filterMediaType?.length
    ? (props?.mediaFilter || mediaFilter).filter((item) =>
        props?.filterMediaType?.includes(item.value)
      )
    : props?.mediaFilter || mediaFilter;

  const getMediaData = async () => {
    setMediaContentListState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const mediaResponse = await getMediaContent(ContentTypes.attachments.path, {
      page: mediaContentListState.page,
      pageSize: mediaContentListState.pageSize,
      type: mediaContentListState.mediaType || props.filterKey,
      name: mediaContentListState.searchString,
    });
    const data = getFormattedMediaContent(mediaResponse.data.data);
    setMediaContentListState((prev) => {
      return {
        ...prev,
        isLoading: false,
        mediaList: mediaResponse.data,
        mediaDisplayList: data,
        mediaMeta: mediaResponse.data.meta,
      };
    });
  };

  useEffect(() => {
    try {
      getMediaData();
    } catch (error) {

      setMediaContentListState((prev) => {
        return {
          ...prev,
          isNoDataFound: true,
          isLoading: false,
        };
      });
    }
  }, [
    mediaContentListState.page,
    mediaContentListState.pageSize,
    mediaContentListState.searchString,
    mediaContentListState.mediaType,
  ]);

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Drawer
      visible={isOpen}
      destroyOnClose
      title={
        <ModalActionTitle
          title={'Select Media'}
          titleColor={''}
          buttonList={[
            {
              ...(isSideCarContext && {
                show: true,
                id: 1,
                btnText: 'back',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  handleOnClose();
                },
              }),
            },
          ]}
          leftButton={
            isSideCarContext ? null : (
              <HStack>
                <Pressable
                  onPress={() => {
                    setMediaContentListState((prev) => {
                      return {
                        ...prev,
                        isAddMediaModalOpen: true,
                      };
                    });
                  }}
                  {...testID(TestIdentifiers.uploadBtn)}
                >
                  <Feather
                    name="upload"
                    size={20}
                    color={Colors.Custom.Gray500}
                  />
                </Pressable>
                <View
                  style={{
                    width: 1.5,
                    backgroundColor: Colors.Custom.Gray400,
                    marginHorizontal: 8,
                  }}
                />
                <Pressable
                  onPress={() => {
                    handleOnClose();
                  }}
                  {...testID(TestIdentifiers.closeBtn)}
                >
                  <AntIcon
                    name="close"
                    size={20}
                    color={Colors.Custom.Gray500}
                  />
                </Pressable>
              </HStack>
            )
          }
        />
      }
      closable
      placement="right"
      width={isSideCarContext ? '100%' : '50vw'}
      mask={isSideCarContext ? false : true}
      onClose={() => handleOnClose()}
    >
      {mediaContentListState.loadingStates && (
        <View
          height={'full'}
          width={'full'}
          position='absolute'
          zIndex={10}
          left={0}
          bg={'coolGray.50:alpha.70'}
        >
          <Center
            top={'30%'}
            justifyContent='center'
            alignItems='center'
            alignContent='center'
          >
            <Spinner size={'lg'} />
          </Center>
        </View>
      )}
      <View style={[styles.listContainer]}>
        <HStack
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
          width={'100%'}
        >
          <HStack
            style={{
              flex: isSideCarContext? 0.6 : 0.5,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                marginRight: 8,
              }}
            >
              <Select
                style={{
                  height: '100%',
                }}
                selectedValue={mediaContentListState.mediaType}
                onValueChange={(value) => {
                  setMediaContentListState((prev) => {
                    return {
                      ...prev,
                      mediaType: value,
                      page: 1,
                      pageSize: 10,
                    };
                  });
                }}
                placeholder="Media Type (All)"
              >
                {mediaTypeList
                  .filter((item) => item.value !== 'pdf')
                  .map((item) => (
                    <Select.Item
                      label={StrCaseConverter.toTitleCase(item.text)}
                      value={item.value}
                    />
                  ))}
              </Select>
            </View>
            {!isSideCarContext && <HStack
              style={{
                borderWidth: 1,
                borderColor: Colors.Custom.Gray300,
                borderRadius: 6,
                height: 36,
              }}
            >
              <Pressable
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 6,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() => {
                  setMediaContentListState((prev) => {
                    return {
                      ...prev,
                      viewCode: 'listView',
                      pageSize: 10,
                    };
                  });
                }}
              >
                <AntIcon
                  name="bars"
                  color={
                    mediaContentListState.viewCode === 'listView'
                      ? Colors.Custom.mainPrimaryPurple
                      : Colors.Custom.Gray400
                  }
                  style={{
                    fontSize: 20,
                  }}
                />
              </Pressable>
              <Pressable
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 6,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onPress={() => {
                  setMediaContentListState((prev) => {
                    return {
                      ...prev,
                      viewCode: 'gridView',
                      pageSize: 10,
                    };
                  });
                }}
              >
                <AntIcon
                  name="appstore-o"
                  color={
                    mediaContentListState.viewCode === 'gridView'
                      ? Colors.Custom.mainPrimaryPurple
                      : Colors.Custom.Gray400
                  }
                  style={{
                    fontSize: 20,
                  }}
                />
              </Pressable>
            </HStack>}
          </HStack>
          <View
            style={{
              flex: isSideCarContext? 0.4 : 0.5,
              height: 40,
            }}
            {...testID('tableTopSearchBar')}
          >
            <Input
              style={{
                height: 40,
              }}
              value={mediaContentListState.searchString}
              placeholder="Search Media"
              onChangeText={(text) => {
                setMediaContentListState((prev) => {
                  return {
                    ...prev,
                    searchString: text,
                  };
                });
              }}
              InputLeftElement={
                <View
                  style={{
                    margin: 8,
                  }}
                >
                  <Feather
                    name="search"
                    size={15}
                    color={Colors.Custom.Gray500}
                  />
                </View>
              }
            />
          </View>
        </HStack>
        {mediaContentListState.isLoading ? (
          <View
            style={{
              padding: 10,
            }}
          >
            <Spinner />
          </View>
        ) : (
          <>
            {(mediaContentListState.viewCode === 'listView' && !isSideCarContext) ? (
              <>
                <TableWrapper
                  pagination={{
                    current: mediaContentListState.page,
                    pageSize: mediaContentListState.pageSize,
                    total: mediaContentListState.mediaMeta.pagination.total,
                    onChange: (currentPage, currentPageSize) => {
                      setMediaContentListState((prev) => {
                        return {
                          ...prev,
                          page: currentPage,
                          pageSize: currentPageSize,
                        };
                      });
                    },
                  }}
                >
                  <Table
                    rowClassName={(record, index) =>
                      index % 2 == 0 ? 'table-row-light' : ''
                    }
                    dataSource={mediaContentListState.mediaDisplayList}
                    columns={getMediaSelectionListColumn()}
                    rowKey={(row) => row.id as string}
                    scroll={{x: isSideCarContext ? 400 : 700, y: height - 280}}
                    onChange={(_, __, sorter: any) => {
                      setMediaContentListState((prev) => {
                        return {
                          ...prev,
                          sort: getSortString(sorter['order']),
                        };
                      });
                    }}
                    onRow={(data) => {
                      return {
                        onClick: () => {
                          const size = data.size;
                          const sizeInMB = parseFloat((size / 1000).toFixed(2));
                          if(sizeInMB > 5 && showAttachment && props.isFromSmsTemplate){
                            showToast(
                              customToast,
                              intl.formatMessage({id: 'maxSize5mb'}),
                              ToastType.info,
                              3000,
                              true
                            );
                            return
                          }
                          setMediaContentListState((prev) => {
                            return {
                              ...prev,
                              loadingStates: true
                            };
                          });
                          props.onSelect(data);
                        },
                      };
                    }}
                    pagination={false}
                  />
                </TableWrapper>
              </>
            ) : (
              <>
                <HStack
                  style={[
                    styles.mediaRow,
                    {
                      height: height - 280,
                      overflow: 'scroll',
                    },
                  ]}
                >
                  {mediaContentListState.mediaDisplayList.length === 0 ? (
                    <View
                      style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <NoDataFileIcon />
                    </View>
                  ) : (
                    mediaContentListState.mediaDisplayList.map((item) => {
                      delete item.imageHeight;
                      delete item.imageWidth;
                      return (
                        <Pressable
                          key={item.fileId}
                          display={'flex'}
                          flexDirection="column"
                          onPress={() => {
                            setMediaContentListState((prev) => {
                              return {
                                ...prev,
                                loadingStates: true
                              };
                            });
                            props.onSelect(item);
                          }}
                        >
                          <View style={styles.mediaContainer}>
                            <MediaPreview
                              mediaType={item.mime}
                              mediaUrl={item.url}
                              mediaData={item}
                              audioProps={{
                                width: 150,
                                height: 40,
                              }}
                            />
                          </View>
                        </Pressable>
                      );
                    })
                  )}
                </HStack>
                <View
                  style={{
                    marginTop: 10,
                  }}
                >
                  <Pagination
                    current={mediaContentListState.page}
                    pageSize={mediaContentListState.pageSize}
                    total={mediaContentListState.mediaMeta.pagination.total}
                    onChange={(page, pageSize) => {
                      setMediaContentListState((prev) => {
                        return {
                          ...prev,
                          page: page,
                          pageSize: pageSize,
                        };
                      });
                    }}
                  />
                </View>
              </>
            )}
          </>
        )}
      </View>

      {mediaContentListState.isAddMediaModalOpen && (
        <AddMediaModal
          acceptedFiles={props?.acceptedFiles}
          isSms={props?.isSms}
          onClose={() => {
            setMediaContentListState((prev) => {
              return {
                ...prev,
                isAddMediaModalOpen: false,
              };
            });
          }}
          onUpload={(data) => {
            setMediaContentListState((prev) => {
              return {
                ...prev,
                isAddMediaModalOpen: false,
              };
            });
            getMediaData();
          }}
          supportedFormatString={props?.supportedFormatString}
        />
      )}
    </Drawer>
  );
};

export default MediaSelectionModal;
