import {createContext, Dispatch, useContext} from 'react';
import { IPersonData } from './MiddleContainer/interfaces';
import { IContact } from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import { PersonOmniViewAction } from './PersonOmniViewReducer';


export const PersonOmniViewContext = createContext<{
    formattedData: IPersonData,
    data: IContact,
    ehrPatientId: string,
    dispatch: Dispatch<PersonOmniViewAction> | undefined
}>({
    formattedData: {} as IPersonData,
    data: {} as IContact,
    ehrPatientId: '',
    dispatch: undefined
});


export const usePersonOmniViewContext = () => {
    return useContext(PersonOmniViewContext);
}

const PersonOmniViewProvider = PersonOmniViewContext.Provider;

export default PersonOmniViewProvider;
