import {isLoginUserBusinessOwner} from '../../utils/commonUtils';
import {isActiveProfileMatchAllowedLocations} from '../../utils/commonUtils';
import {getPatientActiveProfiles} from '../../utils/commonUtils';
import {useContext} from 'react';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';

import {CommonDataContext} from '../../context/CommonDataContext';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import useLoggedInUserAllowedLocationsByTab from './useLoggedInUserAllowedLocationsByTab';
import {MAIN_MENU_CODES} from '../SideMenuBar/SideBarConst';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {PERSON_TYPES} from '../../constants';

const usePatientProfileActionAccess = () => {
  const commonData = useContext(CommonDataContext);
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(
    MAIN_MENU_CODES.CONSUMER
  );

  const checkPatientProfileActionAccess = (contactInfo?: IContact): boolean => {
    if (!isMsoFlagEnabled) {
      return false;
    }

    if (contactInfo?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER) {
      const contactProfiles = contactInfo?.contactProfile?.ehrPatientId ? [contactInfo.contactProfile] : (contactInfo?.contactProfiles || []);
      const activecontactProfiles = getPatientActiveProfiles(
        contactProfiles || []
      );
      const hasActiveProfile = isActiveProfileMatchAllowedLocations(
        activecontactProfiles || [],
        allowedLocationIds
      );

      const isActionsDisabled =
        !isLoginUserBusinessOwner(commonData) && isMsoFlagEnabled
          ? !hasActiveProfile
          : false;
      return isActionsDisabled;
    } else if (
      contactInfo?.contactPracticeLocations &&
      contactInfo?.contactPracticeLocations?.length > 0
    ) {
      const contactAccountLocationUuids =
        contactInfo?.contactPracticeLocations?.map(
          (location: any) => location?.accountLocation?.uuid
        );

      const hasLocationAccess = contactAccountLocationUuids?.some(
        (locationUuid: string) => allowedLocationIds?.includes(locationUuid)
      );
      return !hasLocationAccess;
    }
    return false;
  };

  return {
    checkPatientProfileActionAccess,
  };
};

export default usePatientProfileActionAccess;
