import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import BasicFields from "../../FHFormio/EditFormFields/BasicFields";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import InputField from "../BaseComponents/InputField";
import { EmailSchema } from "../Schema/ComponentsSchema";
import { TextFieldComponent } from "../BaseComponents/BaseComponentInterface";
import EmailSvg from "../../../../../assets/Icons/FormBuilder/EmailSvg";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { v4 as uuidV4 } from 'uuid';
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";

export const EmailFieldFormElement: FormElement = {
  type: EmailSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(EmailSchema),
    key: getUniqueKey(map, EmailSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(EmailSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: EmailSvg,
    label: EmailSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, value: string, silentCheck?: boolean): ValidationResult => {
  if (elementInstance.validate?.required && !value) {
    return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: value };
  }

  const isValidPattern = elementInstance.validate?.pattern ? new RegExp(elementInstance.validate?.pattern).test(value) : true;
  if (!isValidPattern && value) {
    return { isValid: false, errorMessage: 'Please enter a valid email address', key: elementInstance.key, fieldValue: value };
  }

  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const valid = localValidate(elementInstance, e.target.value);
    setErrorMessage(valid.errorMessage);
    setValue(e.target.value);
  }

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        onChange={debounce(onChange, 500)}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState(defaultValue || selectedValue || '');
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || '', silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: ChangeEvent<HTMLInputElement>) => {
    const value = newValue.target.value;
    setValue(value);
    if (!submitValue) return;
    await debouncedValidation(value);
    submitValue(elementInstance.key, value);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const { label, validate, placeholder, description, tooltip, prefix, suffix, allowToShare } = elementInstance;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label label={label || ''} isRequired={validate?.required || false} tooltip={tooltip} isReadOnly={isReadOnly} />
      {isReadOnly && !isPrintForm ? (
        <div className="field-value">{defaultValue || '-'}</div>
      ) : (
        <InputField
          prefix={prefix}
          suffix={suffix}
          errorMessage={errorMessage}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          disabled={isReadOnly || false}
        />
      )}
      {description && (
        <Description description={description} />
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as TextFieldComponent;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'oldtextfield',
      input: true,
      key: 'placeholder',
      label: 'Placeholder',
      placeholder: 'Placeholder',
      tooltip: 'The placeholder text that will appear when this field is empty.',
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
