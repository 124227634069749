import React from 'react';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
} from '../FormComponents/FormComponents';
import {getUniqueKey} from '../CustomFormEngineUtils';
import {PlanSchema} from '../Schema/ComponentsSchema';
import {cloneDeep} from 'lodash';
import SubjectiveComplaintFieldFormElement from './SubjectiveComplaintField';
import SubjectiveComplaintSvg from '../../../../../assets/Icons/FormBuilder/SubjectiveComplaintSvg';
import { v4 as uuidV4 } from 'uuid';

export const PlanFieldFormElement: FormElement = {
  type: PlanSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(PlanSchema),
    key: getUniqueKey(map, PlanSchema.key),
  }),
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(PlanSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: SubjectiveComplaintSvg,
    label: PlanSchema.label || '',
  },
  designerComponent: SubjectiveComplaintFieldFormElement.designerComponent,
  formComponent: SubjectiveComplaintFieldFormElement.formComponent,
  propertiesComponent: SubjectiveComplaintFieldFormElement.propertiesComponent,
};

export default PlanFieldFormElement;
