import { Procedure } from 'fhir/r4';
import {IKeyOperation} from '../../../../../../../Interfaces';
import { IFormatReconciliationMetaData, IReconciliationFormattedData, IReconciliationConflictField, ReconciliationSource } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import {
  IAddOrUpdateSurgicalHistoryParams,
  ISurgicalHistory,
  ISurgicalHXComponentValue,
} from '../interfaces';
import { getTextFromCoding, getValidCoding } from '../../../../../Integration/FhirUtils';
import { FHIRConflictFields } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { getEHRCapability } from '../../../../../../../utils/commonUtils';
import { CapabilityResource } from '../../CustomWrapper/CustomComponentHelper';
import { InputType, getFieldCapabilities } from '../../../../../../../utils/capabilityUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants/StringConst';
import { getFormattedDate } from '../../../../../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import { getMomentObj } from '../../../../../../../utils/DateUtils';
import { getDateStrFromFormat } from '../../../../../../../utils/DateUtils';

export enum SurgicalHistoryFields {
  code,
  status,
  performedDateTime,
  note,
}

export const getRecordListFormatted = (list: any[]) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries);
  return sortItems(formattedData);
}

export const getRecordDataFormatted = (listData: any[]): ISurgicalHistory[] => {
  const tempFormattedList: ISurgicalHistory[] = [];
  listData.map((item: any) => {
    if (item?.code) {
      const tempObj: ISurgicalHistory = {
        id: item?.id,
        performedDateTime: item?.performedDateTime,
        surgicalHistory: item?.code,
        name: getHistoryName(item.code),
        ...(item?.status && {
          status: {
            code: item?.status,
            display: item?.status,
          },
        }),
        uniqueId: item?.id,
        isFreeTextRecord: !item.code?.coding?.length,
        note: item?.note?.[0]?.text,
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): ISurgicalHXComponentValue => {
  return {
    surgicalHistory: [{
      id: '34234324',
      performedDateTime: '2020-12-24',
      surgicalHistory: {
        text: 'Sample Procedure',
        coding: []
      },
      name: 'Sample Procedure',
      status: {code: 'completed', display: 'Completed'},
      uniqueId: '1234',
      isFreeTextRecord: false,
    }],
  }
};

export const sortItems = (records: ISurgicalHistory[]) => {
  return records.sort((a, b) => {
    const statusA = a.status?.code || '';
    const statusB = b.status?.code || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(b.performedDateTime || new Date()).getTime() -
      new Date(a.performedDateTime || new Date()).getTime()
    );
  })
};

export const getHistoryName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: SurgicalHistoryFields,
  data: ISurgicalHistory,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  switch (field) {
    case SurgicalHistoryFields.performedDateTime:
      if ((fields?.performedDateTime?.isRequired || false) && submitTriggered) {
        return !data.performedDateTime;
      }
      return false;

    case SurgicalHistoryFields.status:
      if ((fields?.status?.isRequired || false) && submitTriggered) {
        return !data.status || !data.status.code;
      }
      return false;

    case SurgicalHistoryFields.note:
      if ((fields?.note?.isRequired || false) && submitTriggered) {
        return !data.note;
      }
      return false;

    default:
      return false;
  }
};

export const getFHIRTemplate = (params: IAddOrUpdateSurgicalHistoryParams) => {
  return {
    resourceType: 'Procedure',
    ...(params.id && {id: params.id}),
    ...(params.status && {status: params.status.code}),
    code: params.surgicalHistory,
    subject: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.performedDateTime && {
      performedDateTime: params.performedDateTime,
    }),
    ...(params.note && {note: [{text: params.note}]}),
  };
};


const getResourceDate = (
  resource: Procedure,
  dateFormat?: string | undefined
) => {
  const date = resource.performedDateTime;

  if (date) {
    const onsetDate = dateFormat
      ? getDateStrFromFormat(date, dateFormat)
      : getMomentObj(date || new Date()).toISOString();

    return onsetDate;
  }
};

export const formatReconciliationSurgicalHistoryData = (
  resource: any,
  metaData: IFormatReconciliationMetaData
): IReconciliationFormattedData | undefined => {
  const procedureResource: Procedure = resource;

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.procedure,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);
  const date = procedureResource.performedDateTime;
  const formattedDate = date ? getFormattedDate(date, DISPLAY_DATE_FORMAT) : '';
  const status = fieldCapabilities.status?.possibleValues?.find(
    (value: any) => value.code == procedureResource.status
  )?.display;
  const note = procedureResource.note?.[0]?.text;

  const validatedData = validateAndFormatSurgicalHistoryHieResource(
    (metaData?.targetResourceData || resource) as Procedure,
    fieldCapabilities,
    metaData,
  );

  const display = {
    title: getHieSurgicalhistoryCodes(resource, fieldCapabilities?.code?.allowedCodeSystems)?.text || '',
    status: [formattedDate, status].filter(Boolean).join(' • '),
    date: note,
  };

  return {
    display,
    resourceData: validatedData.resource,
    conflictFields: validatedData.conflictFields,
  };
};

const getHieSurgicalhistoryCodes = (
  resource: Procedure,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.code;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: coding, text: text};
};

const getHieClinicalStatus = (
  resource: Procedure,
  possibleValues?: {code: string; display: string}[]
) => {
  const status = resource?.status;

  return possibleValues?.find(
    (possibleValue) => possibleValue.code === status?.toLowerCase()
  );
};

export const getSurgicalInvalidCodeField = (isConflict?: boolean, value?: any) => {
  return {
    field: SurgicalHistoryFields.code,
    inputType: FHIRConflictFields.code,
    invalid: !value?.coding?.length,
    label: 'Surgical History',
    placeholder: 'Search and add new Procedure',
    isConflict: isConflict,
    value: value,
    isRequired: true,
    extraData: {
      searchType: PAMISearchType.procedure,
    },
  }
}

export const validateAndFormatSurgicalHistoryHieResource = (
  resource: Procedure,
  fields: IKeyOperation,
  metaData: IFormatReconciliationMetaData
) => {
  const conflictFields: IReconciliationConflictField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field =
      SurgicalHistoryFields[key as keyof typeof SurgicalHistoryFields];
    if (!fields[key as keyof typeof fields]?.isHidden) {
      switch (field) {
        case SurgicalHistoryFields.code:
          {
            const surgicalHistoryCodes =
              metaData.source === ReconciliationSource.MSO
                ? resource.code
                : getHieSurgicalhistoryCodes(
                    resource,
                    fields?.code?.allowedCodeSystems
                  );

            resource.code = surgicalHistoryCodes;
            conflictFields.push(
              getSurgicalInvalidCodeField(false, resource?.code)
            );
          }
          break;

        case SurgicalHistoryFields.status:
          {
            const status = getHieClinicalStatus(
              resource,
              fields?.status?.possibleValues
            );

            const isMissing = !status?.code && fields?.status?.isRequired;
            if (!isMissing && status?.code) {
              resource.status = status.code as Procedure['status'];
            }

            conflictFields.push({
              field: SurgicalHistoryFields.status,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              label: 'Status',
              placeholder: 'Select Status',
              isRequired: fields?.status?.isRequired || false,
              value: status?.code,
              extraData: {
                statusList: fields?.status?.possibleValues,
              },
            });
          }
          break;

        case SurgicalHistoryFields.performedDateTime:
          {
            const dateFormat = fields?.performedDateTime?.format;
            const performedDateTime = getResourceDate(resource, dateFormat);
            const isMissing =
              !performedDateTime && fields?.performedDateTime?.isRequired;

            const formattedPerformedDateTime = performedDateTime
              ? getMomentObj(performedDateTime).toISOString()
              : undefined;
            if (!isMissing && formattedPerformedDateTime) {
              resource.performedDateTime = formattedPerformedDateTime;
            }
            conflictFields.push({
              inputType: FHIRConflictFields.date,
              field: SurgicalHistoryFields.performedDateTime,
              invalid: isMissing || false,
              value: formattedPerformedDateTime,
              label: 'Date Performed',
              isRequired: fields?.performedDateTime?.isRequired || false,
              extraData: {
                dateFormat: dateFormat,
              },
            });
          }
          break;

        case SurgicalHistoryFields.note:
          {
            const hieNote = resource.note?.[0]?.text;
            const isMissing = !hieNote && fields?.note?.isRequired;
            if (!isMissing && hieNote) {
              resource.note = [{text: hieNote}];
            }
            conflictFields.push({
              field: SurgicalHistoryFields.note,
              inputType: FHIRConflictFields.textArea,
              invalid: isMissing || false,
              value: hieNote,
              isRequired: fields?.note?.isRequired || false,
              label: 'Note',
            });
          }
      }
    }
  });

  return {
    conflictFields: conflictFields,
    resource: resource,
  };
};

export const updateHieSurgicalHistoryMatchedData = (
  resource: Procedure,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  const surgicalHistoryData = formatReconciliationSurgicalHistoryData(resource, metaData);
  return formatReconciliationSurgicalHistoryData(surgicalHistoryData, metaData);
};

export const updateHieSurgicalHistoryData = (
  resource: Procedure,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  conflictFields.map((conflictField) => {
    if (conflictField.value) {
      switch (conflictField.field) {
        case SurgicalHistoryFields.code:
          resource.code = conflictField.value;
          break;

        case FHIRConflictFields.select:
          resource.status = conflictField.value;
          break;

        case SurgicalHistoryFields.note:
          resource.note = [{text: conflictField.value}];
          break;

        case SurgicalHistoryFields.performedDateTime:
          resource.performedDateTime = conflictField.value;
          break;
      }
    }
    return conflictField;
  });
  return resource;
};

export const formatSurgicalHistoryRequestResourceObj = (
  resource: Procedure,
  metaData: IFormatReconciliationMetaData
) => {
  return {
    resourceType: 'Procedure',
    ...(resource.status && {status: resource.status}),
    code: resource.code,
    subject: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.performedDateTime && {
      performedDateTime: resource.performedDateTime,
    }),
  };
};
