import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { chunk } from 'lodash';
import { FormRendererAction, FormRendererActionType } from '../Context/FormRendererReducer';
import { BatchValidationResult, FormElementInstance, ValidationFunction, ValidationResult } from '../FormComponents/FormComponents';

interface UseFormValidationProps {
  components: FormElementInstance[];
  batchSize?: number;
  dispatch: React.Dispatch<FormRendererAction>;
}

interface ValidationEntry {
  key: string;
  validate: ValidationFunction;
}

export const useFormValidation = ({
  components,
  batchSize = 50,
  dispatch,
}: UseFormValidationProps) => {
  const validationRegistry = useRef<Map<string, ValidationFunction>>(new Map());
  const [validationState, setValidationState] = useState({
    isValidating: false,
    progress: 0
  });

  // Memoize component keys for cleanup
  const componentKeys = useMemo(() =>
    new Set(components.map(c => c.key)),
    [components]
  );

  const registerValidation = useCallback((key: string, validationFn: ValidationFunction) => {
    validationRegistry.current.set(key, validationFn);
  }, []);

  const processBatch = useCallback(async (
    batch: ValidationEntry[],
    filledDataMap: Record<string, any>,
    silentCheck?: boolean
  ): Promise<ValidationResult[]> => {
    try {
      return await Promise.all(
        batch.map(({ key, validate }) => validate(filledDataMap[key], silentCheck))
      );
    } catch (error) {
      console.error('Batch validation error:', error);
      return [];
    }
  }, []);

  const validateForm = useCallback(async (filledDataMap: Record<string, any>, silentCheck?: boolean): Promise<BatchValidationResult> => {
  setValidationState(prev => ({ ...prev, isValidating: true, progress: 0 }));

  try {
    dispatch({
      type: FormRendererActionType.SET_IS_SUBMIT_CLICKED,
      payload: true,
    });
    dispatch({
      type: FormRendererActionType.SET_IS_SUBMITTING,
      payload: true,
    });
    const validations = Array.from(validationRegistry.current.entries()).map(([key, validate]) => ({ key, validate }));
    const totalValidations = validations.length;

    if (!totalValidations) {
      return { isValid: true, results: [], errors: {} };
    }

    const results: ValidationResult[] = [];
    const batches = chunk(validations, batchSize);
    const errors: Record<string, string> = {};
    let isValid = true;

    for (let i = 0; i < batches.length; i++) {
      const batchResults = await processBatch(batches[i], filledDataMap, silentCheck);

      batchResults.forEach(result => {
        results.push(result);
        if (!result.isValid && result.key) {
          isValid = false;
          errors[result.key] = result.errorMessage;
        }
      });

      const progress = Math.round(((i + 1) * batchSize / totalValidations) * 100);
      setValidationState(prev => ({ ...prev, progress: Math.min(progress, 100) }));
    }

    dispatch({
      type: FormRendererActionType.SET_MULTIPLE_INVALID_FIELDS,
      payload: errors
    });

    if (!isValid) {
      dispatch({
        type: FormRendererActionType.SET_IS_SUBMITTING,
        payload: false,
      });
    }
    return { isValid, results, errors };
  } finally {
    setValidationState({ isValidating: false, progress: 0 });
  }
}, [batchSize, processBatch]);

  // Efficient cleanup using memoized keys
  useEffect(() => {
    validationRegistry.current.forEach((_, key) => {
      if (!componentKeys.has(key)) {
        validationRegistry.current.delete(key);
      }
    });
  }, [componentKeys]);

  return {
    isValidating: validationState.isValidating,
    validationProgress: validationState.progress,
    registerValidation,
    validateForm,
  };
};
