import React, { useState, useMemo, useCallback } from "react";
import { ScrollView } from "react-native";
import Stack from "../../../../common/LayoutComponents/Stack";
import { View, Text } from "react-native";
import { SearchBar } from "../../../../common/SearchBar";
import { styles } from '../../Analytics/style';
import { useIntl } from "react-intl";
import SidebarElement from "../BaseComponents/SidebarElement";
import { FormElements } from "../FormComponents/FormComponents";
import { ICustomFormLibraryElement } from "../CustomFormEngineInterfaces";
import DefaultComponentSvg from "../../../../../assets/Icons/FormBuilder/DefaultComponentSvg";
import { Segmented, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const CustomFormBuilderSidebar = ({libraryElements}: {libraryElements: ICustomFormLibraryElement[]}) => {
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = useState('HEALTH_COMPONENTS');
  const [searchText, setSearchText] = useState('');

  // Memoize filtered elements to prevent unnecessary recalculations
  const filteredElements = useMemo(() => {
    if (!searchText) return libraryElements;

    return libraryElements.map(element => ({
      ...element,
      components: element.components.filter(
        component => component.label?.toLowerCase()?.includes(searchText.toLowerCase())
      )
    }));
  }, [libraryElements, searchText]);

  const handleSearchTextChange = useCallback((text: string) => {
    setSearchText(text || '');
  }, []);

  // Memoize tabs array to prevent recreation on each render
  const tabsArray = useMemo(() =>
    libraryElements.map((element) => ({
      text: element.label,
      code: element.code,
      shouldShowInfoIcon: element.code === 'HEALTH_COMPONENTS',
      toolTipMessage: element.code === 'HEALTH_COMPONENTS' ? element.description: undefined
    })),
    [libraryElements]
  );

  // Memoize the selected element's components
  const selectedElementComponents = useMemo(() =>
    filteredElements.find(element => element.code === selectedTab)?.components || [],
    [filteredElements, selectedTab]
  );

  return (
    <Stack direction="column" space={4} style={{backgroundColor: 'white'}}>
      <div className="flex align-center justify-left p-v-8">
        <Segmented
          options={tabsArray.map((tab) => ({
            label: (
              <div className="flex flex-row items-center gap-1">
                <div style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', }}>{tab.text}</div>
                {tab.shouldShowInfoIcon && (
                  <Tooltip title={intl.formatMessage({id: tab.toolTipMessage})}>
                    <InfoCircleOutlined className="text-gray-500 text-sm" />
                  </Tooltip>
                )}
              </div>
            ),
            value: tab.code,
          }))}
          value={selectedTab}
          onChange={(code) => {
            setSelectedTab(code as string);
          }}
          style={{
            width: 'fit-content',
            height: 32,
            borderRadius: 4,
            backgroundColor: 'white',
          }}
          className="custom-form-segmented"
          onResize={undefined}
          onResizeCapture={undefined}
        />
      </div>

      <View style={{paddingHorizontal: 8}}>
        <View style={{marginBottom: 8}}>
          <SearchBar onChange={handleSearchTextChange} />
        </View>
        <View style={styles.scrollViewStyle2}>
          <ScrollView
            style={{backgroundColor: 'white'}}
          >
            {selectedElementComponents.length > 0 ? (
              selectedElementComponents.map((item, index) => {
                const data = FormElements[item.type as keyof typeof FormElements]?.designerBtnElement;
                const isCustomComponent = selectedTab === 'CUSTOM_COMPONENTS';
                return (
                <div key={index + item.type + 'view'} style={{marginBottom: 8}}>
                  <SidebarElement
                    index={index}
                    component={item}
                    label={item.name || item.label || data?.label}
                    icon={isCustomComponent ? DefaultComponentSvg : data?.icon}
                  />
                </div>
              )})
            ) : (
              <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <Text>
                  {intl.formatMessage({id: 'noComponentsAvailable'})}
                </Text>
              </View>
            )}
          </ScrollView>
        </View>
      </View>
    </Stack>
  );
}

export default React.memo(CustomFormBuilderSidebar);
