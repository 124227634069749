import { Box, View, VStack, Text } from "native-base";
import { IHealthMapPrintProps } from "./interfaces"
import { Radar } from '@ant-design/charts';
import React, { useEffect } from "react";
import { Colors } from "../../../styles/Colors";
import { getCurrentTimeZoneAbbr, getDateStrFromFormat, getFormattedAge } from "../../../utils/DateUtils";
import { DATE_FORMATS } from "../../../constants/StringConst";
import { StrCaseConverter } from "../../../utils/CaseConverter";
import { Table } from "antd";
import FoldLogoSvg from "../../common/Svg/FoldLogoSvg";


const HealthMapPrint = (props: IHealthMapPrintProps) => {
    const { personData, chartConfig, mapData } = props;
    const patientAge = getFormattedAge(personData?.birthDate || '');
    const patientName = personData?.name;
    const patientGender = StrCaseConverter.toTitleCase(personData?.genderName || personData?.sexAtBirth || 'Unknown');
    const timeZone = getCurrentTimeZoneAbbr();

    const radarChartConfig = {
        data: chartConfig?.data,
        xField: 'key',
        yField: 'value',
        area: {
          style: {
            fillOpacity: 0.1
          },
        },
        meta: {
          value: {alias: 'reason'},
        },
        yAxis: {
          min: -1,
          max: 5,
          offset: 0,
          label: {
            formatter: (val: number) => {
              return val == -1 ? '' : val;
            },
            style: {
                fontSize: 14,
                textAlign: 'center',
                fontWeight: '400',
                fill: Colors.FoldPixel.GRAY800
            }
          },
        },
        xAxis: {
          label: {
            offset: 10,
            position: 'center',
            style: {
              fontSize: 16,
              textAlign: 'center',
              fontWeight: '400',
              fill: Colors.FoldPixel.GRAY800
            },
          },
        },
        axis: {
          x: {
            title: false,
            grid: true,
          },
          y: {
            gridAreaFill: 'rgba(0, 0, 0, 0.04)',
            label: false,
            title: false,
          },
        },
        height: 500,
        width: 900
      };


    const getTableViewColumns = () => {
        return [
            {
                title: <Text fontWeight={'500'} fontSize={16} color={Colors.FoldPixel.GRAY300}>Parameter</Text>,
                key: 'parameter',
                dataIndex: 'parameter',
                width: '33%',
                onCell: () => ({}),
                render: (name: string, record: {key: string, value: number, score: number, reason: string}) => (
                    <Text fontWeight={'500'} fontSize={16} color={Colors.FoldPixel.GRAY400}>{record?.key}</Text>
                )
            },
            {
                title:  <Text fontWeight={'500'} fontSize={16} color={Colors.FoldPixel.GRAY300}>Score</Text>,
                key: 'score',
                dataIndex: 'score',
                width: '15%',
                onCell: () => ({}),
                render: (name: string, record: {key: string, value: number, score: number, reason: string}) => (
                    <Text fontWeight={'500'} fontSize={16} color={Colors.FoldPixel.GRAY400}>{record?.value}</Text>
                )
            },
            {
                title:  <Text fontWeight={'500'} fontSize={16} color={Colors.FoldPixel.GRAY300}>Inference</Text>,
                key: 'inference',
                dataIndex: 'inference',
                width: '50%',
                onCell: () => ({}),
                render: (name: string, record: {key: string, value: number, score: number, reason: string}) => (
                    <Text fontWeight={'500'} fontSize={16} color={Colors.FoldPixel.GRAY400}>{record?.reason}</Text>
                )
            }
        ]
    }

    useEffect(() => {
        setTimeout(() => {
            props.onRenderComponent();
        }, 500);
    }, [props.onRenderComponent]);

    return (
        <Box padding={4} testID="health-map-print-container">
            <VStack space={4} testID="health-map-print">
                <View>
                    <Text color={Colors.FoldPixel.GRAY300} fontWeight={'500'} fontSize={18}> Patient Details</Text>
                    <Text color={Colors.FoldPixel.GRAY800} fontWeight={'500'} fontSize={16}> {patientName} </Text>
                    <Text color={Colors.FoldPixel.GRAY700} fontWeight={'500'} fontSize={16}> {patientGender}, {patientAge}</Text>
                </View>
                <Text color={Colors.FoldPixel.GRAY300} fontWeight={'500'} fontSize={16}> Generated On: {getDateStrFromFormat(new Date().toISOString(), DATE_FORMATS.CARE_DASHBOARD_DATE_FORMAT)}{` ${timeZone}`} </Text>
                <View borderWidth={1} borderColor={Colors.FoldPixel.GRAY200} borderRadius={8} justifyContent={'center'} alignItems={'center'} minHeight={600}>
                    <Radar {...radarChartConfig} animation={false}  />
                </View>
                <Text color={Colors.FoldPixel.GRAY300} fontWeight={'500'} fontSize={16} marginTop={8}> Summary </Text>
                <View>
                    <Table
                        dataSource={mapData?.radarChartdata}
                        columns={(getTableViewColumns())}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                        style={
                            { 
                                padding: 0,
                                backgroundColor: 'white',
                                border: `1px solid ${Colors.FoldPixel.GRAY250}`,
                                borderRadius: '8px',
                                overflow: 'hidden'
                        }}
                        size="large"
                    />
                </View>
                <View flex={'1'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'flex-end'}>
                    <FoldLogoSvg/>
                </View>
            </VStack>
        </Box>
    );
}


export default HealthMapPrint;