import {useLazyQuery, useMutation} from '@apollo/client';
import {useContext, useEffect, useMemo, useState} from 'react';
import FeedsQueries from '../../../../../services/Feeds/FeedsQueries';
import {FEED_SERVICE_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {
  FEED_RESOURCE_TYPE,
  TIME_LINE_SOURCE_TYPES,
} from '../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst';
import {
  formatFhirRequestObj,
  formatHieDataResponse,
  getFilteredHieRequests,
  getUpdateGroupHieRequestsStatus,
  getUpdatedErrorGroupHieRequests,
  groupHieRequests,
  getInValidCodeObj,
  updateHieGroupInArr,
  formatReconciliationDataResponse,
  DEFAULT_RECONCILIATION_PAGE_SIZE,
} from './HieRequestsUtils';
import {
  ReconciliationStatus,
  IReconciliationRequest,
  IReconciliationRequestGroup,
  IHIEReconciliationRequest,
  ReconciliationSource,
  IFormatReconciliationMetaData,
  ReconcileStatusCodes,
  IMSOReconciliationRequest,
  IReconciliationRequestResponse,
  IMSOReconciliationRequestResponse,
  ReconciliationActionCode,
} from './interface';
import {
  getEhrConfig,
  getMergedCapability,
  getVitalListFromCapability,
} from '../../../../../utils/capabilityUtils';
import {fhirAddOrUpdate, getReconciliationRequestCounts, getReconciliationRequests, getResourceContentPromise, updateReconciliationRequest} from '../../../../../services/CommonService/AidBoxService';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {
  FHIR_RESOURCE,
  RESOURCE_BLOCK_MESSAGE_ID,
} from '../../../../../constants/FhirConstant';
import {LOADING_TYPES} from '../../../../common/PendingRequestListItem/PendingRequestLisItemConst';
import {showPopupNotification} from '../../../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {HTTP_ERROR_CODE} from '../../../../../constants';
import {EventBus} from '../../../../../utils/EventBus';
import {ADD_UPDATE_EVENTS} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import usePractitioners from '../../../../common/FHIRPractitionerSearch/usePractitioners';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';
import { ReconciliationGroupBy } from '../../../../../Interfaces';
import { getAppNameForHeader } from '../../../../../utils/APIUtils';
import { Condition, Resource } from 'fhir/r4';
import { ComponentType } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Diagnosis/interfaces';
import { cloneDeep } from 'lodash';

interface IReconciliationRequestsParams {
  patientUuid?: string;
  accountLocationUuid: string;
  patientId?: string;
  contactUuid?: string;
  skip?: boolean;
  source: ReconciliationSource;
  resourceTypes?: string[];
  locationGroupPatientIdMap?: Record<string, string>;
}

interface ILoader {
  id: string;
  type: string;
}

interface IReconciliationRequestsState {
  pendingRequests: IReconciliationRequest[];
  reconciliationRequestGroups: IReconciliationRequestGroup[];
  updateRequestsLoading: ILoader[];
  loading: boolean;
  latestResourceData: {[key: string]: Resource[]};
  requestCounts: {[key: string]: number};
}

export const useReconciliationRequests = (
  params: IReconciliationRequestsParams
) => {
  const {
    patientUuid,
    accountLocationUuid,
    patientId,
    contactUuid,
    skip,
    source,
    resourceTypes,
    locationGroupPatientIdMap,
  } = params;
  const accountUuid = getAccountUUID();
  const intl = useIntl();
  const [componentState, setComponentState] =
    useState<IReconciliationRequestsState>({
      pendingRequests: [],
      reconciliationRequestGroups: [],
      updateRequestsLoading: [],
      loading: false,
      latestResourceData: {},
      requestCounts: {},
    });

  const practitionerData = usePractitioners({
    skip: false,
    hasLoggedInContext: true,
    locationId: accountLocationUuid,
  });

  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE) || false;
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid, filterBasedOnLocation: true});
  const commonEhrCapabilities = useEHRCapabilities({locationId: accountUuid});
  const ehrConfig = useMemo(() => getEhrConfig(accountLocationUuid, ''), [accountLocationUuid]);

  const mergedEhrCapabilities = useMemo(() =>
    getMergedCapability(commonEhrCapabilities, ehrCapabilities),
  [commonEhrCapabilities, ehrCapabilities]);

  const vitalList = useMemo(() => {
    const vitalCapability = mergedEhrCapabilities?.find((capability) => capability.resourceName === FHIR_RESOURCE.OBSERVATION)
    const vitalList = vitalCapability?.abilities?.allowedVitalList || [];
    return vitalList.filter((vital) => !vital.isHidden);
  }, [mergedEhrCapabilities]);


  const metaData: IFormatReconciliationMetaData = useMemo(() => ({
    enabledVitalList: vitalList,
    ehrCapabilities: mergedEhrCapabilities,
    localEhrCapabilities: ehrCapabilities,
    patientId: patientId || '',
    accountUuid: accountUuid,
    practitionerData: practitionerData.data,
    ehrConfig: ehrConfig,
    accountLocationUuid: accountLocationUuid,
    source: source,
    locationGroupPatientIdMap: locationGroupPatientIdMap,
  }), [vitalList, mergedEhrCapabilities, patientId, accountUuid, practitionerData.data, ehrConfig, accountLocationUuid, source, ehrCapabilities, locationGroupPatientIdMap]);

  const [getHieRequests] = useLazyQuery(FeedsQueries.GET_FEEDS, {
    fetchPolicy: 'no-cache',
    context: {
      service: FEED_SERVICE_APOLLO_CONTEXT,
      headers: {},
    },
  });

  const [updateHieRequest] = useMutation(FeedsQueries.UPDATE_FEED_STATUS, {
    fetchPolicy: 'no-cache',
    context: {
      service: FEED_SERVICE_APOLLO_CONTEXT,
      headers: {},
    },
  });

  const broadcastUpdateEvent = (resourceType: string) => {
    let eventType = '';

    switch (resourceType) {
      case FHIR_RESOURCE.OBSERVATION:
        eventType = ADD_UPDATE_EVENTS.VITAL;
        break;
      case FHIR_RESOURCE.CONDITION:
        eventType = ADD_UPDATE_EVENTS.PROBLEM;
        break;
      case FHIR_RESOURCE.ALLERGY:
        eventType = ADD_UPDATE_EVENTS.ALLERGY;
        break;
      case FHIR_RESOURCE.MEDICATION_STATEMENT:
        eventType = ADD_UPDATE_EVENTS.MED;
        break;
      case FHIR_RESOURCE.IMMUNIZATION:
        eventType = ADD_UPDATE_EVENTS.IMMUNIZATION;
        break;
      case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
        eventType = ADD_UPDATE_EVENTS.FAMILY_HISTORY;
        break;
      case FHIR_RESOURCE.PROCEDURE:
        eventType = ADD_UPDATE_EVENTS.SURGICAL_HISTORY;
        break;
    }
    if (eventType) {
      const eventBus = EventBus.getEventBusInstance();
      eventBus.broadcastEvent(eventType, 'detail');
    }
  };

  useEffect(() => {
    if (!isHIEEnabled) {
      return;
    }

    if (!skip) {
      setComponentState((prev) => ({
        ...prev,
        loading: true,
      }));
    }
    if (!skip && practitionerData.fetched) {
      fetchReconciliationRequests(true);
    }
  }, [practitionerData.fetched]);

  const updateHieRequestsLoader = (
    ids: string[],
    status: string,
    isRemove?: boolean
  ) => {
    setComponentState((prev) => {
      let updatedLoadingHieRequests = prev.updateRequestsLoading;
      if (isRemove) {
        updatedLoadingHieRequests = updatedLoadingHieRequests.filter(
          (loadingObj) => !ids.includes(loadingObj.id)
        );
      } else {
        const loadingType =
          status === ReconciliationStatus.accepted
            ? LOADING_TYPES.ACCEPT
            : LOADING_TYPES.DECLINE;
        for (let i = 0; i < ids.length; i++) {
          updatedLoadingHieRequests.push({id: ids[i], type: loadingType});
        }
      }

      return {
        ...prev,
        updateRequestsLoading: updatedLoadingHieRequests,
      };
    });
  };

  const updateFeedStatus = async (
    hieRequests: IReconciliationRequest[],
    status: ReconciliationStatus
  ) => {
    try {
      const response = await updateHieRequest({
        variables: {
          ids: hieRequests?.map((hieRequest) => hieRequest.id),
          syncStatus: status,
        },
      });

      if (response.data.updateFeedsData.affected_rows) {
        let currentHieRequestGroups: IReconciliationRequestGroup[] = [];

        setComponentState((prev) => {
          currentHieRequestGroups = prev.reconciliationRequestGroups;
          return prev;
        });

        const updatedGroupHieRequests = getUpdateGroupHieRequestsStatus(
          hieRequests,
          currentHieRequestGroups,
          status
        );

        setComponentState((prev) => ({
          ...prev,
          reconciliationRequestGroups: updatedGroupHieRequests,
        }));
      }
      return response;
    } catch (error) {
      showError(intl.formatMessage({id: 'apiErrorMsg'}));
    }
  };

  const updateRequestStatus = async (
    hieRequest: IReconciliationRequest,
    status: ReconciliationStatus
  ) => {
    if (source === ReconciliationSource.HIE) {
      return updateHIERequestStatus(hieRequest as IHIEReconciliationRequest, status);
    } else if (source === ReconciliationSource.MSO) {
      return updateMSORequestStatus(hieRequest as IMSOReconciliationRequest, status);
    }
  }

  const getUpdatedResourceForAddUpdate = (request: IMSOReconciliationRequest) => {
    if (request.modifiedResource) {
      const resource = cloneDeep(request.modifiedResource);
      const isAdd = !request.targetResourceData?.id;
      if (isAdd) {
        delete resource.id;
      }
      (resource as Condition).subject = {
        reference: `Patient/${request.targetPatientId}`,
      };
      return resource;
    }
  }

  const handleRequestResolution = async (request: IReconciliationRequest) => {
    setComponentState((prev) => ({
      ...prev,
      pendingRequests: prev.pendingRequests.filter((req) => req.id !== request.id),
      reconciliationRequestGroups: prev.reconciliationRequestGroups.map((group) => {
        if (group.resourceType === request.resourceType) {
          const filteredOrders = group.orders.filter((order) => order.id !== request.id);
          const diff = group.orders.length - filteredOrders.length;
          return {
            ...group,
            orders: filteredOrders,
            total: group.total ? group.total - diff : 0
          };
        }
        return group;
      }).filter((group) => group.orders.length > 0),
    }));
  }

  const updateMSORequestStatus = async (
    request: IMSOReconciliationRequest,
    status: ReconciliationStatus
  ) => {
    updateHieRequestsLoader([request.id], status);

    try {
      const finalResource = getUpdatedResourceForAddUpdate(request);

      const acceptedStatus = status === ReconciliationStatus.accepted;
      const deletedStatus = status === ReconciliationStatus.deleted;

      if (acceptedStatus || deletedStatus) {
        const updatedFhirResponse = await updateReconciliationRequest({
          id: request.id,
          statusCode: ReconcileStatusCodes.resolved,
          eventSource: getAppNameForHeader(),
          resource: finalResource,
          targetActionCode: status,
        });

        if (updatedFhirResponse?.data) {
          updateHieRequestsLoader([request.id], status, true);
          if (status === ReconciliationStatus.accepted) {
            broadcastUpdateEvent(request?.resourceType);
          }
          handleRequestResolution(request);
        } else if (updatedFhirResponse?.data?.error) {
          updateHieRequestsLoader([request.id], status, true);
          request.error = getErrorMessage(updatedFhirResponse?.data?.error);
          const invalidObj = getInValidCodeObj(request.resourceType);
          if (invalidObj) {
            request.conflictFields = [invalidObj];
          }
          const updatedErrorRequestGroups = getUpdatedErrorGroupHieRequests(
            [request],
            componentState.reconciliationRequestGroups
          );
          updateRequestGroupsState(updatedErrorRequestGroups);
        }
      } else {
        const updatedFhirResponse = await updateReconciliationRequest({
          id: request.id,
          statusCode: ReconcileStatusCodes.dismissed,
          eventSource: getAppNameForHeader(),
        });

        if (updatedFhirResponse?.data) {
          updateHieRequestsLoader([request.id], status, true);
          handleRequestResolution(request);
        }
      }
    } catch (error) {
      showError('', error);
      updateHieRequestsLoader([request.id], status, true);
    }
  };

  const updateHIERequestStatus = async (
    hieRequest: IHIEReconciliationRequest,
    status: ReconciliationStatus
  ) => {
    updateHieRequestsLoader([hieRequest.id], status);

    try {
      const formattedRequestObj = formatFhirRequestObj(
        hieRequest.resourceType,
        hieRequest.resourceData,
        metaData,
        false
      );

      const acceptedStatus = status === ReconciliationStatus.accepted;

      if (acceptedStatus) {
        const updatedFhirResponse = (await addOrUpdateFhirRecord(
          hieRequest,
          formattedRequestObj
        )) as any;

        if (updatedFhirResponse?.response) {
          const response = await updateFeedStatus([hieRequest], status);

          if (response) {
            updateHieRequestsLoader([hieRequest.id], status, true);
            if (status === ReconciliationStatus.accepted) {
              broadcastUpdateEvent(hieRequest?.resourceType);
            }
          }
        } else if (updatedFhirResponse?.error) {
          updateHieRequestsLoader([hieRequest.id], status, true);
          hieRequest.error = getErrorMessage(updatedFhirResponse?.error);
          const invalidObj = getInValidCodeObj(hieRequest.resourceType);
          if (invalidObj) {
            hieRequest.conflictFields = [invalidObj];
          }
          const updatedErrorRequestGroups = getUpdatedErrorGroupHieRequests(
            [hieRequest],
            componentState.reconciliationRequestGroups
          );
          updateRequestGroupsState(updatedErrorRequestGroups);
        }
      } else {
        const response = await updateFeedStatus([hieRequest], status);

        if (response) {
          updateHieRequestsLoader([hieRequest.id], status, true);
        }
      }
    } catch (error) {
      showError('', error);
      updateHieRequestsLoader([hieRequest.id], status, true);
    }
  };

  const updateHieGroupLoader = (
    hieGroup: IReconciliationRequestGroup,
    updateObj: any
  ) => {
    const updatedHieGroups = updateHieGroupInArr(
      componentState.reconciliationRequestGroups,
      hieGroup,
      updateObj
    );
    setComponentState((prev) => ({
      ...prev,
      reconciliationRequestGroups: updatedHieGroups,
    }));
  };

  const callAcceptAllApi = async (requests: IHIEReconciliationRequest[]) => {
    const allRecordsCount = requests?.length;
    const errorRequests = [];
    const successRequests = [];

    updateHieRequestsLoader(
      requests?.map((request) => request.id),
      ReconciliationStatus.accepted
    );

    for (let i = 0; i < allRecordsCount; i++) {
      try {
        const hieRequest = requests[i];

        const formattedRequestObj = formatFhirRequestObj(
          hieRequest.resourceType,
          hieRequest.resourceData,
          metaData,
          false
        );
        const updatedFhirResponse = (await addOrUpdateFhirRecord(
          hieRequest,
          formattedRequestObj
        )) as any;

        if (updatedFhirResponse?.response) {
          const response = await updateFeedStatus(
            [hieRequest],
            ReconciliationStatus.accepted
          );
          if (response) {
            successRequests.push(updatedFhirResponse.hieRequest);
          }
        } else if (updatedFhirResponse?.error) {
          hieRequest.error = getErrorMessage(updatedFhirResponse?.error);
          const invalidObj = getInValidCodeObj(hieRequest.resourceType);
          if (invalidObj) {
            hieRequest.conflictFields = [invalidObj];
          }
          errorRequests.push(hieRequest);
        }
      } catch (error) {
        showError(intl.formatMessage({id: 'apiErrorMsg'}));
      }
    }

    updateHieRequestsLoader(
      requests?.map((request) => request.id),
      ReconciliationStatus.accepted,
      true
    );

    return {
      errorRequests: errorRequests,
      successRequests: successRequests,
    };
  };

  const handleAcceptAll = async (hieGroup: IReconciliationRequestGroup) => {
    const invalidRecordExist = hieGroup.orders.some(
      (order) => order.conflictFields && order.conflictFields?.length > 0
    );

    if (invalidRecordExist) {
      showPopupNotification(
        'Error',
        "Review and correct each field. Some are missing data or don't match records.",
        'error',
        400
      );
      return;
    }

    updateHieGroupLoader(hieGroup, {acceptAllLoading: true});

    const filteredRequests = getFilteredHieRequests(
      (hieGroup?.orders || []) as IHIEReconciliationRequest[]
    );

    const {errorRequests, successRequests} = await callAcceptAllApi(
      filteredRequests as IHIEReconciliationRequest[]
    );

    if (errorRequests?.length > 0) {
      showError(
        `Can not reconcile ${errorRequests?.length} out of ${filteredRequests?.length} records at this time.`
      );

      const updatedErrorRequestsGroups = getUpdatedErrorGroupHieRequests(
        errorRequests,
        componentState.reconciliationRequestGroups
      );
      updateRequestGroupsState(updatedErrorRequestsGroups);
    }

    if (successRequests?.length > 0) {
      broadcastUpdateEvent(hieGroup?.orders?.[0]?.resourceType);
      updateHieGroupLoader(hieGroup, {acceptAllLoading: false});
    }
  };

  const getErrorMessage = (error?: any) => {
    let errorMessageText = '';
    if (error) {
      const errorData = error?.response?.data;
      const errorText =
        errorData?.extensions?.detailedError?.[0]?.details?.text || '';
      errorMessageText =
        error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
          ? intl.formatMessage({
              id: RESOURCE_BLOCK_MESSAGE_ID,
            })
          : errorData?.message;

      if (errorText && errorText.trim().startsWith('{')) {
        const errorObj = JSON.parse(errorText);
        errorMessageText = errorObj?.error;
      } else if (errorText && typeof errorText === 'string') {
        errorMessageText = errorText;
      }
    }
    return errorMessageText;
  };

  const showError = (errorMessage: string, error?: any) => {
    const errorMessageText = getErrorMessage(error) || errorMessage;
    showPopupNotification('Error', errorMessageText, 'error', 400);
  };

  const addOrUpdateFhirRecord = (
    hieRequest: IReconciliationRequest,
    formattedRequestObj: any,
    resourceId?: string
  ) => {
    return new Promise((resolve, reject) => {
      fhirAddOrUpdate(
        hieRequest.resourceType,
        resourceId,
        formattedRequestObj,
        {
          patientId: patientId,
          locationId: accountLocationUuid,
        },
        (response) => {
          if (response) {
            resolve({response: response, hieRequest});
          }
        },
        (error) => {
          resolve({error: error, hieRequest});
        }
      );
    });
  };

  const updateRequestGroupsState = (
    reconciliationRequestGroups: IReconciliationRequestGroup[]
  ) => {
    setComponentState((prev) => ({
      ...prev,
      reconciliationRequestGroups: reconciliationRequestGroups,
    }));
  };

  const fetchReconciliationRequests = async (showLoader: boolean) => {
    if (source === ReconciliationSource.HIE) {
      await fetchHIERequests(showLoader);
    } else if (source === ReconciliationSource.MSO) {
      await fetchMSORequests(showLoader);
    }
  };

  const fetchRequestCount = async (resourceTypeList?: string[], showLoader?: boolean) => {
    try {
      if (showLoader) {
        setComponentState((prev) => ({
          ...prev,
          loading: showLoader,
        }));
      }
      const fetchCount = await getReconciliationRequestCounts({
        filters: {
          resourceTypes: resourceTypeList || resourceTypes,
          statusCodes: [ReconcileStatusCodes.pending],
          contactId: contactUuid,
          targetPatientId: patientId,
        },
        groupBy: ReconciliationGroupBy.resourceType,
      });
      const countMap = fetchCount?.data?.aggregate;
      setComponentState((prev) => ({
        ...prev,
        requestCounts: countMap,
        ...(showLoader && { loading: false }),
      }));
      return countMap;
    } catch (error) {
      showError(intl.formatMessage({id: 'apiErrorMsg'}));
      setComponentState((prev) => ({
        ...prev,
        ...(showLoader && { loading: false }),
      }));
      return {};
    }
  };

  const fetchMoreRequests = async (resourceType: string, reset: boolean) => {
    if (source !== ReconciliationSource.MSO) {
      return;
    }

    // Find the current group for this resource type
    const currentGroup = componentState.reconciliationRequestGroups.find(
      (group) => group.resourceType === resourceType
    );

    if (!currentGroup) {
      return;
    }

    // If not reset, check if we need to fetch more
    if (!reset) {
      const currentCount = currentGroup.orders?.length || 0;
      const totalCount = currentGroup.total || 0;

      if (currentCount >= totalCount) {
        return; // All records already fetched
      }
    }

    try {
      setComponentState((prev) => {
        const updatedGroups = prev.reconciliationRequestGroups.map((group) => {
          if (group.resourceType === resourceType) {
            return {
              ...group,
              pageLoading: true,
            };
          }
          return group;
        });
        return {
          ...prev,
          reconciliationRequestGroups: updatedGroups,
        };
      });

      // Pass offset as 0 if reset is true, otherwise pass current orders length
      const offset = reset ? 0 : currentGroup.orders?.length || 0;
      const response = await getReconciliationRequests({
        resourceTypes: [resourceType],
        statusCodes: [ReconcileStatusCodes.pending],
        contactId: contactUuid,
        targetPatientId: patientId,
        limit: DEFAULT_RECONCILIATION_PAGE_SIZE,
        offset,
      })

      if (response?.data?.reconciliationRequests?.length) {
        // Format new data
        const newReconciliationRequests = formatReconciliationDataResponse(
          response.data?.reconciliationRequests,
          metaData
        );

        // Update state
        setComponentState((prev) => {
          // Update pending requests
          const updatedPendingRequests = reset
            ? newReconciliationRequests // If reset, use only new data
            : [...prev.pendingRequests, ...newReconciliationRequests]; // If pagination, combine data

          // Update groups
          const updatedGroups = prev.reconciliationRequestGroups.map(
            (group) => {
              if (group.resourceType === resourceType) {
                const newRequests =
                  groupHieRequests(newReconciliationRequests, [])?.[0]
                    ?.orders || [];
                return {
                  ...group,
                  pageLoading: false,
                  orders: reset
                    ? newRequests // If reset, use only new orders
                    : [...(group.orders || []), ...newRequests], // If pagination, combine orders
                };
              }
              return group;
            }
          );

          return {
            ...prev,
            pendingRequests: updatedPendingRequests,
            reconciliationRequestGroups: updatedGroups,
          };
        });
      } else {
        setComponentState((prev) => ({
          ...prev,
          reconciliationRequestGroups: prev.reconciliationRequestGroups.map((group) => {
            if (group.resourceType === resourceType) {
              return { ...group, pageLoading: false };
            }
            return group;
          }),
        }));
      }
    } catch (error) {
      showError(intl.formatMessage({id: 'apiErrorMsg'}));
      setComponentState((prev) => ({
        ...prev,
        reconciliationRequestGroups: prev.reconciliationRequestGroups.map((group) => {
          if (group.resourceType === resourceType) {
            return { ...group, pageLoading: false };
          }
          return group;
        }),
      }));
    }
  };

  const fetchMSORequests = async (showLoader: boolean) => {
    if (showLoader) {
      setComponentState((prev) => ({
        ...prev,
        loading: showLoader,
      }));
    }
    try {
      const dataPromises = (resourceTypes || []).map((resourceType) => {
        return getReconciliationRequests({
          resourceTypes: [resourceType],
          statusCodes: [ReconcileStatusCodes.pending],
          contactId: contactUuid,
          targetPatientId: patientId,
          limit: DEFAULT_RECONCILIATION_PAGE_SIZE,
          offset: 0,
        });
      });
      const ehrDataPromise = (resourceTypes || []).map((resourceType) => {
        return fetchLatestData(resourceType);
      });
      // Separate the promises into their respective groups
      const responseData = await Promise.all([
        fetchRequestCount(resourceTypes),
        Promise.all(dataPromises),
        Promise.all(ehrDataPromise),
      ]);

      // Destructure the responses
      const [countMap, reconciliationResponses, ehrResponses] = responseData;

      // Create a map of latest EHR data by resource type
      const latestEhrData = (resourceTypes || []).reduce((acc, resourceType, index) => {
        acc[resourceType] = ehrResponses[index] || [];
        return acc;
      }, {} as { [key: string]: any[] });

      // Combine all reconciliation requests into single array
      const allReconciliationRequests = reconciliationResponses.reduce(
        (acc: any[], response) => {
          response.data?.reconciliationRequests?.forEach((item: IMSOReconciliationRequestResponse) => {
            if (item.targetResourceId) {
              const modifiedTargetResourceId = item.targetResourceId.split('-').pop() || item.targetResourceId;
              const resourceData = latestEhrData[item.resourceType]?.find((resource: any) => resource.id === item.targetResourceId);
              if (resourceData) {
                item.targetResourceData = resourceData;
              }
            }
          });
          return [...acc, ...(response.data?.reconciliationRequests || [])];
        },
        []
      );

      const formattedData = formatReconciliationDataResponse(
        allReconciliationRequests,
        metaData
      );
      const reconciliationRequestGroups = groupHieRequests(formattedData, []);

      // Add count to each group
      const groupsWithCount = reconciliationRequestGroups.map((group) => {
        const countData = countMap[group.resourceType] || 0;
        return {
          ...group,
          total: countData,
        };
      });

      setComponentState((prev) => ({
        ...prev,
        pendingRequests: formattedData,
        reconciliationRequestGroups: groupsWithCount,
        loading: false,
        requestCounts: countMap,
        latestResourceData: latestEhrData,
      }));
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const fetchHIERequests = async (showLoader: boolean) => {
    if (showLoader) {
      setComponentState((prev) => ({
        ...prev,
        loading: showLoader,
      }));
    }
    try {
      const hieRequestsResponse = await getHieRequests({
        variables: {
          feedDataWhereCondition: {
            isDeleted: {_eq: false},
            _and: [
              {
                _or: [
                  {
                    patient_id: {
                      _eq: patientUuid,
                    },
                  },
                  {
                    patient_id: {
                      _eq: contactUuid,
                    },
                  },
                ],
              },
              {
                display_type: {
                  _in: [
                    FHIR_RESOURCE.ALLERGY,
                    FHIR_RESOURCE.CONDITION,
                    FHIR_RESOURCE.IMMUNIZATION,
                    FHIR_RESOURCE.MEDICATION_STATEMENT,
                    FHIR_RESOURCE.OBSERVATION,
                    FHIR_RESOURCE.PROCEDURE,
                    FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
                  ],
                },
              },
              {
                sync_status: {
                  _is_null: true,
                },
              },
              {
                source: {
                  _eq: TIME_LINE_SOURCE_TYPES.HIE,
                },
              },
            ],
            categoryId: {
              _is_null: true,
            },
          },
        },
      });

      if (hieRequestsResponse?.data?.feed_data?.length) {
        const formattedHieRequests = formatHieDataResponse(
          hieRequestsResponse?.data?.feed_data,
          metaData
        );
        const reconciliationRequestGroups = groupHieRequests(
          formattedHieRequests,
          []
        );

        setComponentState((prev) => ({
          ...prev,
          pendingRequests: formattedHieRequests,
          reconciliationRequestGroups: reconciliationRequestGroups,
          loading: false,
        }));
      } else {
        setComponentState((prev) => ({
          ...prev,
          loading: false,
          pendingRequests: [],
          reconciliationRequestGroups: [],
        }));
      }
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const fetchLatestData = async (resourceType: string) => {
    try {
      const additionalQueryParams = getAdditionalQueryParams(resourceType);
      const response = await getResourceContentPromise({
        resourceName: resourceType,
        query: `patient=${patientId}${additionalQueryParams}`,
        useProxy: false,
        interactiveData: true,
        locationId: accountLocationUuid,
      });
      const list = response.data?.entry?.map((entry: any) => entry.resource);
      setComponentState((prev) => ({
        ...prev,
        latestResourceData: {
          ...prev.latestResourceData,
          [resourceType]: list,
        },
      }));
      return list;
    } catch (error) {
      console.error('Error fetching latest data:', error);
      return [];
    }
  };

  const getAdditionalQueryParams = (resourceType: string) => {
    switch (resourceType) {
      case FHIR_RESOURCE.CONDITION:
        return `&category=${ComponentType.Condition}`;
      default:
        return '';
    }
  };

  return {
    pendingRequests: componentState.pendingRequests,
    reconciliationRequestGroups: componentState.reconciliationRequestGroups,
    countMap: componentState.requestCounts,
    updateRequestStatus: updateRequestStatus,
    updateRequestsLoading: componentState.updateRequestsLoading,
    updateRequestGroupsState: updateRequestGroupsState,
    loading: componentState.loading,
    fetchReconciliationRequests: fetchReconciliationRequests,
    handleAcceptAll: handleAcceptAll,
    metaData: metaData,
    fetchMoreRequests: fetchMoreRequests,
    fetchRequestCount: fetchRequestCount,
  };
};
