import classNames from 'classnames';
import React from 'react';
import InputMask from 'react-input-mask';

const MaskedInputField = ({
  mask,
  onChange,
  value,
  defaultValue,
  placeholder,
  errorMessage,
  disabled,
}: {
  mask: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <InputMask
        mask={mask}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        className={classNames("form-input-field padding-horizontal-11px", Boolean(errorMessage) && "error-field")}
        disabled={disabled}
      />
      {Boolean(errorMessage) && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default React.memo(MaskedInputField);
