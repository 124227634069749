import { Box, Heading, Icon, View, VStack } from 'native-base';
import { Colors, Icons } from '../../../styles';
import AntIcon from 'react-native-vector-icons/AntDesign';

export default function ErrorPage() {
  return (
    <>
      <style type="text/css">{Icons.iconsStyle}</style>
      <View flex={1}>
        <Box
          borderColor={Colors.Custom.BorderColor}
          rounded="lg"
          borderWidth={1}
          backgroundColor={'white'}
          marginX={{
            base: 0,
            lg: 32,
          }}
          marginY={{
            base: 0,
            lg: 8,
          }}
        >
          <View
            padding={{
              base: 4,
              sm: 2,
              md: 4,
              lg: 8,
              xl: 8,
            }}
          >
            <VStack space={2}>
              <View alignItems="center" justifyContent="center">
                <Icon
                  as={AntIcon}
                  name="warning"
                  size="24"
                  color="error.500"
                />
              </View>
              <View alignItems="center" justifyContent="center">
                <Heading>
                  This link is either expired or invalid. Please contact
                  support.
                </Heading>
              </View>
            </VStack>
          </View>
        </Box>
      </View>
    </>
  );
}
