import { FORM_SOURCE } from "../../../../../../../../../../constants/MlovConst";
import { IEhrCapability, IMlov } from "../../../../../../../../../../Interfaces";
import { getMlovCodeFromId } from "../../../../../../../../../../utils/mlovUtils";
import { IFormSender } from "../../../../../../../../../PersonOmniView/MiddleContainer/FormResponseTableView/interfaces";
import {forEachExtensiveFormComponent} from "../../../../../../../../Forms/FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper";
import jwtDecode from 'jwt-decode';

export const getSubmittedDataFromComponents = (formComponents: any): object => {
  const submittedData: any = {};
  const data: any = {};
  if (formComponents && formComponents.length) {
    forEachExtensiveFormComponent(formComponents, (component: any) => {
      data[component.key] = component.selectedValue;
    });
  }
  submittedData.data = data;
  return submittedData;
};

export const getFormSenderDetail = (form: any, accountUsers: IFormSender[], sourceMlovList: IMlov[]) => {
  const sourceCode = getMlovCodeFromId(sourceMlovList, form?.sourceId);
  if (sourceCode === FORM_SOURCE.WORKFLOW) {
    return 'Automation';
  } else if (sourceCode === FORM_SOURCE.CARE_JOURNEY) {
    return 'Care Journey';
  } else {
    const createdBy = form?.createdBy;
    const findUser = (accountUsers).find(user => {
      return user?.uuid === createdBy;
    });
    if (findUser?.uuid) {
      return `${findUser.name} ${findUser.accountUsers?.[0]?.isActive === false ? '(Inactive)' : ''}`;
    }
    return 'Unknown';
  }
};


export const getOrganizationCapabilites = (
  capability?: IEhrCapability,
  resourceName?: string
) => {
  return capability?.abilities?.capabilities?.find(
    (item) => item.resourceName === resourceName
  )?.abilities?.allowedOperations;
};

export const decodeTpaCareAdvocateParams = async (accessToken: string) => {
  try {
      const decoded = await jwtDecode(accessToken || '');
      const decodedObj = JSON.parse(JSON.stringify(decoded));
      return decodedObj;
  } catch (error) {
    return '';
  }
};
