import { useLazyQuery } from '@apollo/client';
import { Pressable, Spinner, Text, Tooltip, useToast, View, VStack } from 'native-base';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import EditSvg from '../../../../assets/Icons/EditSvg';
import { DASHBOARD_PERMISSION_ACTION, DATE_FORMATS, PERSON_TYPES, RIGHT_SIDE_CONTAINER_CODE } from '../../../../constants';
import { COMMON_ACTION_CODES } from '../../../../constants/ActionConst';
import StickyNotesApis, {
  CreateStickyNotes,
  UpdateStickyNotes,
} from '../../../../services/StickyNotes/StickyNotesApis';
import { Colors } from '../../../../styles';
import { getAccountUUID, getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, getContactProfileAccountLocationUuid, getLocationGroupIdFromLocationsUsingUuid, mapAndGetLocationGroupIds, replaceHashValueToString } from '../../../../utils/commonUtils';
import { getDateAndTimeOnLocalFormate } from '../../../../utils/DateUtils';
import { AddNoteView } from '../../../common/AddNoteView';
import { AddActionView } from '../../../common/Buttons/AddActionView';
import { DisplayText } from '../../../common/DisplayText/DisplayText';
import { styles } from './OtherDetailsStyles';
import { EventBus } from '../../../../utils/EventBus';
import { CUSTOM_COMMON_EVENT_CODES } from '../../../../constants/WebSocketConst';
import { testID, TestIdentifiers } from '../../../../testUtils';
import { getActivePatientProfile } from '../../../common/ContactProfile/commonUtils';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import HistorySvg from '../../../common/Svg/HistorySvg';
import { USER_ACCESS_PERMISSION } from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import { IStickyNotes } from './interfaces';
import EditActionBtnSvg from '../../../common/Svg/PersonActionSvgIcons/EditActionBtnSvg';
import { useIntl } from 'react-intl';
import { isWeb } from '../../../../utils/platformCheckUtils';
import LockTooltipIcon from '../../../common/LockTooltipIcon';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import useLocationGroup from '../../../common/MemebersView/customHook/useLocationGroup';
import usePatientProfileActionAccess from '../../../CustomHooks/usePatientProfileActionAccess';
import {useGetDefaultContactProfile} from '../../CustomHooks/useGetDefaultContactProfile';
import {usePersonOmniViewContext} from '../../PersonOmniView.context';

const StickyNotes = (props: any) => {
  const intl = useIntl();
  const toast = useToast();
  const {contactId, contactUuid, contactData} = props;
  const accountUuid = getAccountUUID();
  const [getStickyNote] = useLazyQuery(StickyNotesApis.GetStickyNotes);
  const [stateData, setStateData] = useState({
    selectedView: '',
    selectedData: {} as any,
    noteDataList: [] as IStickyNotes[],
    noteData: {} as IStickyNotes,
    loading: false,
  });
  const getLocationGroupIdFronAccountLocationId = (locationUuid?: string) => {
    if (!locationUuid) {
      return '';
    }
    return getLocationGroupIdFromLocationsUsingUuid(locationUuid, commonContextData?.accountLocationListWithEHR)
  }
  const {locationGroups} = useLocationGroup();
  const getEhrInstanceName = (locationGroupId: string) => {
    const locationGroup = locationGroups?.find((group: any) => group.code === locationGroupId);
    return locationGroup?.display || '';
  }
  
  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CONSUMER
  );
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );
  const isCustomerContact = contactData?.contactType?.code === PERSON_TYPES.CUSTOMER;
  const commonContextData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonContextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const activePatientProfile = getActivePatientProfile(contactData?.contactProfiles || []);
  const activeContactProfileLocationGroupId = getLocationGroupIdFronAccountLocationId(activePatientProfile?.accountLocationUuid);
  const allowedUserAccountLocationGroupIds = mapAndGetLocationGroupIds(allowedUserAccountLocationUuids, commonContextData?.accountLocationListWithEHR);
  const haveAccessToActivePatientsLocationGroup = isMultiTenancyEnabled && isCustomerContact ? allowedUserAccountLocationGroupIds?.includes(activeContactProfileLocationGroupId) : true;
  const allowedUserAccountP360LocationGroupIds = mapAndGetLocationGroupIds(allowedUserAccountP360LocationUuids, commonContextData?.accountLocationListWithEHR);
  const {formattedData: contextContactData } = usePersonOmniViewContext();
  const contextContactProfile = contextContactData?.contactProfile;
  const contextLocationGroupId = mapAndGetLocationGroupIds(
    [contextContactProfile?.accountLocationUuid],
    commonContextData?.accountLocationListWithEHR
  )?.[0] || contextContactProfile?.accountLocationUuid;

  const addStickyNotes = async (note: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const noteResponseData = await CreateStickyNotes({
      contactId,
      contactUuid,
      note,
      ...(isMultiTenancyEnabled && {
        locationGroupId: contextLocationGroupId,
        ehrPatientId: contextContactProfile?.ehrPatientId,
        contactProfileId: contextContactProfile?.id || contactData?.uuid,
        accountLocationId: contextContactProfile?.accountLocationUuid
      })
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (noteResponseData?.data?.contactInfo?.stickyNotes?.length || noteResponseData?.data?.contactInfo?.content) {
      getStickyNotes(true);
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
 
  const updateStickyNotes = async (note: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const noteResponseData = await UpdateStickyNotes({
      contactId,
      contactUuid,
      note,
      noteUuid: stateData.noteData?.noteUuid || '',
      ...(isMultiTenancyEnabled && {
        locationGroupId: contextLocationGroupId,
        ehrPatientId: contextContactProfile?.ehrPatientId,
        contactProfileId: contextContactProfile?.id || contactData?.uuid,
        accountLocationId: contextContactProfile?.accountLocationUuid
      })
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (noteResponseData?.data?.contactInfo) {
      getStickyNotes(true);
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
 
  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.AddNoteAction:
        addStickyNotes(actionData.content);
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.EditNoteAction:
        updateStickyNotes(actionData.content);
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: '',
          };
        });
        break;
      case COMMON_ACTION_CODES.ADD_NOTE:
        let selectedNote = stateData.noteData;
        if (actionData) {
          selectedNote = actionData;
        }
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE,
            selectedData: selectedNote,
          };
        });
        break;
      default:
        setStateData((prev) => {
          return {
            ...prev,
            selectedView: '',
            selectedData: '',
          };
        });
        break;
    }
  };
 
  const getStickyNotes = async (sendBroadcast?: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const response = await getStickyNote({
      variables: {
        stickyNotesCondition: {
          contactUuid: {
            _eq: contactUuid
          },
          accountUuid: {
            _eq: accountUuid
          },
          ...(isMultiTenancyEnabled && {
            locationGroupId: {
              _in: [contextLocationGroupId]
            },
          }),
        }
      },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.stickyNotes?.length) {
      const notes:IStickyNotes[] = response.data?.stickyNotes || [{}] as IStickyNotes[];
      const activeLocationNoteData = isMultiTenancyEnabled ? notes?.find((note: IStickyNotes) => {
        const haveAccessToLocation = note?.locationGroupId === activeContactProfileLocationGroupId && note?.ehrPatientId === activePatientProfile?.ehrPatientId;
        return haveAccessToLocation;
      }) : notes[0];

      if (notes.length === 0) {
        notes.push({} as IStickyNotes);
      }
     
      if (sendBroadcast) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(
          CUSTOM_COMMON_EVENT_CODES.UPDATE_STICKY_NOTES,
          {
            noteDataList: notes as IStickyNotes[],
            noteData: activeLocationNoteData || {} as IStickyNotes,
          }
        );
      }
      setStateData((prev) => {
        return {
          ...prev,
          noteDataList: notes as IStickyNotes[],
          noteData: activeLocationNoteData || {} as IStickyNotes,
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          noteData: {} as IStickyNotes,
          noteDataList: [{}] as IStickyNotes[],
          loading: false,
        };
      });
    }
  };
  useEffect(() => {
    getStickyNotes();
  }, [contactId]);
 
  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CUSTOM_COMMON_EVENT_CODES.UPDATE_STICKY_NOTES,
      onUpdateStickyNotes
    );
    return () => {
      eventBus.removeEventListener(onUpdateStickyNotes);
    };
  }, []);
 
  const onUpdateStickyNotes = useCallback(
    (data: any) => {
      const note = data?.noteData;
      setStateData((prev) => {
        return {
          ...prev,
          noteData: note,
        };
      });
    },
    [contactId]
  );
 
  const renderContent = (noteData: IStickyNotes) => {
    let haveAccessToNote = noteData?.locationGroupId === activeContactProfileLocationGroupId && noteData?.ehrPatientId === activePatientProfile?.ehrPatientId && haveAccessToActivePatientsLocationGroup; 
    haveAccessToNote = isMultiTenancyEnabled ? haveAccessToNote : true;
    const locationName = getEhrInstanceName(noteData?.locationGroupId || '');
    const addStickyNoteAccess = isMultiTenancyEnabled ? !noteData?.noteUuid && haveAccessToActivePatientsLocationGroup : true;
    const stickyNoteDateTime = getDateAndTimeOnLocalFormate(
      noteData?.updatedAt || '',
      DATE_FORMATS.STICKY_NOTE_DATE,
    )
    const stickyNoteDate = stickyNoteDateTime?.split(' • ')?.[0];
    const stickyNoteTime = stickyNoteDateTime?.split(' • ')?.[1];
    return (
      <>
        {stateData.loading ? (
          <Spinner size="lg" style={styles.spinnerStyle} />
        ) : (
          <></>
        )}
        <View
          style={[stickyNoteStyles.noteContainer, {marginHorizontal: props?.sideCarDashboardEnabled ? 0 : 6}]}
          borderColor={addStickyNoteAccess ? '#FDD7AD' : '#FAE8B2'}
          backgroundColor={addStickyNoteAccess ? '#FEFCF6' : '#FEFDF7'}
        >
          {noteData?.noteUuid ? (
            <VStack flex={1} justifyContent={'center'}>
              <Text size={'mdMedium'} color={Colors.FoldPixel.GRAY400} {...testID(TestIdentifiers.stickyNoteInfo)}>
                {!haveAccessToNote ? (
                  <>
                    <LockTooltipIcon name="Sticky Note" customIconSize={12} customBadgeStyles={{width: isWeb() ? undefined : 20}} />
                    {" "}
                  </>
                ) : null} 
                {noteData?.contactNote?.content || ""}
              </Text>
              <View style={stickyNoteStyles.noteInfoContainerWrapper}>
                <View style={stickyNoteStyles.noteInfoContainer}>
                  <Text style={stickyNoteStyles.noteInfoText} size={'smMedium'} {...testID(noteData?.updatedByUser?.name || '')}>
                    {noteData?.updatedByUser?.name} • {' '}
                  </Text>
                  <Text style={stickyNoteStyles.noteInfoText} size={'smMedium'} {...testID(TestIdentifiers.dateAndTime)}>
                    {stickyNoteDate} • {' '}
                  </Text>
                  <Text style={stickyNoteStyles.noteInfoText} size={'smMedium'} {...testID(TestIdentifiers.dateAndTime)}>
                    {stickyNoteTime}
                  </Text>
                </View>
                { isMultiTenancyEnabled ? (
                  <Pressable 
                    onPress={() => {
                      onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE, noteData);
                    }}
                  >
                    {haveAccessToNote ? <EditActionBtnSvg size={18} customStrokeColor='#6F7A90' /> : <HistorySvg size={28} />}
                  </Pressable>
                ) : null}
              </View>
              { isMultiTenancyEnabled ? (
                <Text style={stickyNoteStyles.noteInfoText} size={'smMedium'}>
                  {`EHR Instance: ${locationName}`}
                </Text>
              ) : null}
            </VStack>
          ) : (
            <Tooltip
              label={addStickyNoteAccess ? intl.formatMessage({id: 'clickToAddStickyNote'}) : replaceHashValueToString(
                {place: 'Sticky Notes'},
                intl.formatMessage({id: 'onlyViewAccessMsg1ShortV2'})
              )}
              placement={'top'}
              maxWidth={300}
            >
              <Pressable 
                onPress={() => {
                  if (!addStickyNoteAccess) {
                    showToast(
                      toast,
                      replaceHashValueToString(
                        {place: 'sticky note'},
                        intl.formatMessage({id: 'onlyViewAccessMsg1ShortV2'})
                      ),
                      ToastType.info
                    );
                    return;
                  }
                  onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE);
                }}
                disabled={isWeb() ? !addStickyNoteAccess : false}
              >
                <View>
                  <DisplayText 
                    textLocalId='emptyStickNotes' 
                    extraStyles={{
                      color: addStickyNoteAccess ? Colors.Custom.Gray500 : Colors.FoldPixel.GRAY200,
                      fontWeight: '400',
                    }} 
                    size='smNormal' 
                  />
                </View>
              </Pressable>
            </Tooltip>
          )}
        </View>
      </>
    );
  };

  const getSvgIcon = (haveAccess = true) => {
    if (isMultiTenancyEnabled && (!haveAccessToActivePatientsLocationGroup || !haveAccess)) {
      return <HistorySvg />
    } else if (stateData.noteData?.noteUuid) {
      return <EditSvg />;
    } else {
      return <AddActionView />;
    }
  }

  const getTooltipLabel = () => {
    let tooltipLabel;
    if (!haveAccessToActivePatientsLocationGroup) {
      tooltipLabel = 'History';
    } else if (stateData.noteData?.noteUuid) {
      tooltipLabel = 'Update Sticky Notes';
    } else {
      tooltipLabel = 'Add Sticky Notes';
    }
    return tooltipLabel;
  }

  return (
    <>
      <View p={2}>
        <View paddingX={props?.sideCarDashboardEnabled ? 0 : '12px'} paddingY={'12px'}>
          <View style={stickyNoteStyles.headerContent}>
            <View
              style={stickyNoteStyles.flexRow}
            >
              <DisplayText size={'lgMedium'} textLocalId={'stickyNotes'} extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}} testID='stickyNotes' />
            </View>
              {
                ((haveAccessToActivePatientsLocationGroup && !stateData.noteData?.noteUuid) || !isMultiTenancyEnabled) ? (
                  <Tooltip label={getTooltipLabel()} placement={'top'}>
                    <Pressable
                      style={stickyNoteStyles.addButton}
                      testID={stateData.noteData?.noteUuid ? TestIdentifiers.editStickyNotesBtn : TestIdentifiers.addStickyNotesBtn}
                      onPress={() => {
                        onActionPerformed(COMMON_ACTION_CODES.ADD_NOTE);
                      }}
                    >
                      {getSvgIcon()}
                    </Pressable>
                  </Tooltip>
                ) : null
              }
          </View>
        </View>
        <View>
          {
            stateData?.noteDataList?.map((noteData) => {
              return renderContent(noteData)
            })
          }
        </View>
      </View>
      {stateData.selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_ADD_NOTE ? (
        <AddNoteView
          isStickyNote={true}
          titleText={props?.titleText}
          contactId={contactId}
          contactData={contactData}
          selectedData={stateData.selectedData}
          onFormActionPerformed={(actionCode: string, actionData: any) => {
            onActionPerformed(actionCode, actionData);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};
 
const stickyNoteStyles = StyleSheet.create({
  headerContainer: {
    padding: 12,
    // marginLeft: 12
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconContainer: {
    marginRight: 10,
  },
  addButton: {
    width: 36,
    height: 36,
  },
  noteContainer: {
    // backgroundColor: '#FEFCF6',
    borderRadius: 8,
    boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderWidth: 1,
    marginTop: 8,
  },
  noteInfoContainer: {
    flexDirection: 'row',
    marginTop: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '90%',
  },
  noteInfoContainerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  noteInfoText: {
    color: Colors.Custom.Gray500,
  },
  emptyNoteText: {
    color: Colors.Custom.Gray500,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
  }
});
 
export default React.memo(StickyNotes);