import React, {ReactNode, useMemo} from 'react';
import {IBookingWidgetData} from '../AppointmentBookingWidget';
import {Avatar, HStack, Text, VStack} from 'native-base';
import {Colors} from '../../../../styles';
import {ModalActionAntSelect} from '../../../common/ModalActionCommonComponent/ModalActionAntSelect';
import InviteeContact from './InviteeContact';
import {useIntl} from 'react-intl';
import {IMlov} from '../../../../Interfaces';
import {
  getLocationTypeValue,
  getSelectedLocationValue,
} from '../AppointmentBookingWidgetHelper';
import {TimezoneSelect} from '../../../common/TimezoneSelect/TimezoneSelect';
import {IUserPracticeLocation} from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBookingIntefaces';
import {ITimezone} from '../../../../services/Location/interfaces';
import {getInitialsFromFullName} from '../../../../utils/commonUtils';
import {getMlovValueFromId} from '../../../../utils/mlovUtils';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {DATE_FORMATS, GROUP_MEMBER_TYPE} from '../../../../constants';
import TagHorizontal from '../../../../assets/Icons/TagHorizontal';
import Feather from 'react-native-vector-icons/Feather';
import MapIcon from '../../../../assets/Icons/MapIcon';
import ClockIcon2024 from '../../../../assets/Icons/ClockIcon2024';
import {getDateStrFromFormat} from '../../../../utils/DateUtils';
import CalendarCheckedIcon from '../../../../assets/Icons/CalendarCheckedIcon';
import {StyleSheet} from 'react-native';
import './AppointmentWidgetDetails.css';

interface IWidgetAppointmentDetailsProps {
  bookingData: IBookingWidgetData;
  scheduleLocationTypeList: IMlov[];
  accountIdHeaders: any;
  handleModeOfAppointmentChange?: (value: any[], data: any) => void;
  handleLocationChange?: (value: any[], data: any) => void;
  handleTimezoneChange?: (timezone?: ITimezone) => void;
  getUserRole?: () => string | undefined;
  isReadOnly: boolean;
}

const WidgetAppointmentDetails = (props: IWidgetAppointmentDetailsProps) => {
  const {
    bookingData,
    scheduleLocationTypeList,
    accountIdHeaders,
    isReadOnly,
    handleModeOfAppointmentChange,
    handleLocationChange,
    handleTimezoneChange,
    getUserRole,
  } = props;
  const intl = useIntl();
  const isMobile = useMemo(
    () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    []
  );
  const getLocationNameForDropdown = (location?: IUserPracticeLocation) => {
    if (location?.accountLocation?.practiceLocation?.name) {
      return location.accountLocation.practiceLocation.name;
    }
    return bookingData?.appointmentType?.locationType?.value || '';
  };
  const getlocationFromParams = () => {
    const locationFromParams= bookingData?.allGroupLocations ? bookingData?.allGroupLocations.length > 0 ?  bookingData?.allGroupLocations.filter((location:any) => location.accountLocation.uuid == bookingData.locationIdFromParams) : [] : [];
    return locationFromParams;
  }
   const filteredLocationTypeList = bookingData?.appointmentType?.isPatientFacingLocationType
     ? scheduleLocationTypeList
     : scheduleLocationTypeList.filter(
         (locationType) => locationType.id === bookingData.appointmentType?.locationTypeId
       );

  const horizontalContainer = (children: ReactNode) => {
    return (
      <HStack space={2} alignItems="center">
        {children}
      </HStack>
    );
  };

  const getLocationData = () => {
    if(bookingData?.isMultiTenancyEnabled){
      const locations = getlocationFromParams();
      return locations.map((location, index) => {
        const locationName = getLocationNameForDropdown(location);
        return {
          value: location.accountLocation?.uuid,
          label: locationName,
          key: location.accountLocation?.uuid,
        };
      })

    }
    else{
      const locationData = bookingData.locationList.map((location, index) => {
        const locationName = getLocationNameForDropdown(location);
        return {
          value: location.accountLocation?.uuid,
          label: locationName,
          key: location.accountLocation?.uuid,
        };
      })
      return locationData;
    }  
  }


  const appointmentTypeName = bookingData.appointmentType?.eventName;

  const duration = bookingData.appointmentType?.duration;

  const locationName = useMemo(
    () =>
      bookingData?.locationTypeId ||
      bookingData?.appointmentType?.locationTypeId
        ? getLocationTypeValue(
            scheduleLocationTypeList,
            bookingData?.locationTypeId ||
              bookingData?.appointmentType?.locationTypeId
          )
        : '',
    [bookingData?.locationTypeId, bookingData?.appointmentType?.locationTypeId]
  );
  const dateString = useMemo(
    () =>
      `${getDateStrFromFormat(
        bookingData.slot?.startDateTime || new Date(),
        DATE_FORMATS.SLOT_DATE_FORMAT_WITH_DAY
      )} `,
    [bookingData.slot?.startDateTime, bookingData.slot?.endDateTime]
  );

  const slotString = useMemo(
    () =>
      `${getDateStrFromFormat(
        bookingData.slot?.startDateTime || new Date(),
        DATE_FORMATS.DISPLAY_TIME_FORMAT
      )} - ${getDateStrFromFormat(
        bookingData.slot?.endDateTime || new Date(),
        DATE_FORMATS.DISPLAY_TIME_FORMAT
      )}`,
    [bookingData.slot?.startDateTime, bookingData.slot?.endDateTime]
  );

  const componentView = () => {
    if (isReadOnly && !!bookingData.user) {
      return (
        <VStack space={2}>
          {isMobile && <Text color={Colors.FoldPixel.GRAY400} fontSize={18} fontWeight={'500'}>
            {intl.formatMessage({id: 'appointmentDetail'})}
          </Text>}
          <VStack
            rounded="lg"
            padding={2}
            borderColor={Colors.FoldPixel.GRAY150}
            borderWidth={1}
            borderRadius={4}
            backgroundColor={Colors.FoldPixel.GRAY50}
            space={1}
            marginBottom={isMobile ? 2 : 0}
          >
            <HStack space={2} alignItems="center" mb={2}>
              <DisplayCardAvatar
                avatarStyle={{
                  avatarSize: isMobile ? '10' : '8',
                  textStyle: {fontSize: isMobile ? 16 : 14},
                }}
                isLetterAvatarShow
                userData={{
                  userId: bookingData.user?.id,
                  userType: GROUP_MEMBER_TYPE.USER,
                  userName: bookingData.user?.name,
                }}
              />
              <VStack flex={1}>
                <Text size={"smNormal"} fontSize={isMobile ? 18 : 16}>{bookingData.user?.name}
                {getUserRole?.() && (
                  <Text size={"xsLight"} fontSize={14} color={Colors.Custom.Gray500} paddingLeft={1}>
                    ({getUserRole()})
                  </Text>
                )}
                </Text>
              </VStack>
            </HStack>
            {dateString &&
              horizontalContainer(
                <>
                  <CalendarCheckedIcon />
                  <Text style={isMobile ? styles.textMobile : styles.text}>{dateString}</Text>
                </>
              )}
            {slotString &&
              horizontalContainer(
                <>
                  <ClockIcon2024 />
                  <Text style={isMobile ? styles.textMobile : styles.text}>{slotString}</Text>
                </>
              )}
          </VStack>
          <VStack
            rounded="lg"
            padding={2}
            borderColor={Colors.FoldPixel.GRAY150}
            borderWidth={1}
            borderRadius={4}
            backgroundColor={Colors.FoldPixel.GRAY50}
            space={1}
          >
            {appointmentTypeName &&
              horizontalContainer(
                <>
                  <TagHorizontal />
                  <Text style={isMobile ? styles.textMobile : styles.text}>{appointmentTypeName}</Text>
                </>
              )}
            {duration &&
              horizontalContainer(
                <>
                  <ClockIcon2024 />
                  <Text style={isMobile ? styles.textMobile : styles.text}>{duration} Min</Text>
                </>
              )}
            {locationName &&
              horizontalContainer(
                <>
                  <MapIcon />
                  <Text style={isMobile ? styles.textMobile : styles.text}>{locationName}</Text>
                </>
              )}
              {
                bookingData.selectedTimezone?.displayValue &&
                horizontalContainer(
                  <>
                    <Feather name='globe' size={16} color={Colors.FoldPixel.GRAY300} />
                    <Text style={isMobile ? styles.textMobile : styles.text}>
                      {bookingData.selectedTimezone?.displayValue}
                    </Text>
                  </>
                )
              }
          </VStack>
        </VStack>
      );
    }

    return (
      <VStack space={2}>
        {bookingData.appointmentType &&
          bookingData.appointmentType.eventName && (
            <ModalActionAntSelect
              className={isMobile 
                ? "custom-mobile-appointment-widget-select-box" 
                : "custom-select-box"}
              popupClassName={isMobile ? "custom-mobile-appointment-widget-select-box-dropdown": undefined}
              customStyle={{
                height: isMobile ? 40 : 32,
              }}
              extraStyle={{
                flex: 1,
                fontSize: isMobile ? '16px' : '14px',
              }}
              disabled
              label={intl.formatMessage({id: 'appointmentType'})}
              data={[
                {
                  value: bookingData.appointmentType.eventName,
                  label: bookingData.appointmentType.eventName,
                  key: bookingData.appointmentType.id,
                },
              ]}
              value={bookingData.appointmentType.eventName}
              optionProps={{
                key: 'value',
                label: 'label',
                value: 'value',
              }}
            />
          )}
        {bookingData.appointmentType?.duration && (
          <ModalActionAntSelect
            className={isMobile 
              ? "custom-mobile-appointment-widget-select-box" 
              : "custom-select-box"}
            popupClassName={isMobile ? "custom-mobile-appointment-widget-select-box-dropdown": undefined}
            customStyle={{
              height: isMobile ? 40 : 32,
            }}
            extraStyle={{
              flex: 1,
              fontSize: isMobile ? '16px' : '14px',
            }}
            disabled
            label={intl.formatMessage({id: 'duration'})}
            data={[
              {
                value: `${bookingData.appointmentType?.duration} min`,
                label: `${bookingData.appointmentType?.duration} min`,
                key: `${bookingData.appointmentType?.duration} min`,
              },
            ]}
            value={`${bookingData.appointmentType?.duration} min`}
            optionProps={{
              key: 'value',
              label: 'label',
              value: 'value',
            }}
          />
        )}
        {(bookingData?.locationTypeId ||
          bookingData?.appointmentType?.locationTypeId) && (
            <ModalActionAntSelect
            className={isMobile 
              ? "custom-mobile-appointment-widget-select-box" 
              : "custom-select-box"}
            popupClassName={isMobile ? "custom-mobile-appointment-widget-select-box-dropdown": undefined}
            customStyle={{
              height: isMobile ? 40 : 32,
            }}
            extraStyle={{
              flex: 1,
              fontSize: isMobile ? '16px' : '14px',
            }}
            label={'Mode of Appointment'}
            data={filteredLocationTypeList.map((locationType, index) => {
              return {
                value: locationType.id,
                label: locationType.value,
                key: locationType.id,
              };
            })}
            value={bookingData.locationTypeId}
            optionProps={{
              key: 'value',
              label: 'label',
              value: 'value',
            }}
            onChange={handleModeOfAppointmentChange}
          />
        )}
        <ModalActionAntSelect
          className={isMobile 
            ? "custom-mobile-appointment-widget-select-box" 
            : "custom-select-box"}
          popupClassName={isMobile ? "custom-mobile-appointment-widget-select-box-dropdown": undefined}
          customStyle={{
            height: isMobile ? 40 : 32,
          }}
          extraStyle={{
            flex: 1,
            fontSize: isMobile ? '16px' : '14px',
          }}
          disabled={!bookingData.locationList.length}
          label={'Location'}
          data={getLocationData()}
          value={bookingData?.isMultiTenancyEnabled ? bookingData?.locationIdFromParams : bookingData.location?.accountLocation?.uuid}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          optionProps={{
            key: 'value',
            label: 'label',
            value: 'value',
          }}
          onChange={handleLocationChange}
        />
        {bookingData.locationList.length === 0 && (
          <Text size="smNormal" color="error.500">
            No Locations available
          </Text>
        )}
        <TimezoneSelect
          className={isMobile 
            ? "custom-mobile-appointment-widget-select-box" 
            : "custom-select-box"}
          popupClassName={isMobile ? "custom-mobile-appointment-widget-select-box-dropdown": undefined}
          customStyle={{
            height: isMobile ? 40 : 32,
          }}
          labelFontSize={isMobile ? '16px' : '14px'}
          isRequired={false}
          showLabel={true}
          label={'selectTimezoneAppointmentWidget'}
          headers={{
            ...accountIdHeaders,
          }}
          selectedTimezoneId={bookingData.selectedTimezone?.uuid}
          onChange={handleTimezoneChange ?? (() => {})}
        />
      </VStack>
    );
  };

  return (
    <VStack
      flex={['none', 'none', 0.4]}
      padding={2}
      paddingRight={4}
      borderRightWidth={[0, 0, 1]}
      borderRightColor={Colors.Custom.BorderColor}
      space={2}
    >
      {bookingData.inviteeContact && (
        <InviteeContact inviteeContact={bookingData.inviteeContact} isMobile={isMobile}/>
      )}
      {componentView()}
    </VStack>
  );
};

export default WidgetAppointmentDetails;


const styles =  StyleSheet.create({
  text: {
    fontSize: 14,
    color: Colors.FoldPixel.GRAY300,
  },
  textMobile: {
    fontSize: 16,
    color: Colors.FoldPixel.GRAY300,
  },
});