import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { ICommonSvgProps } from '../interfaces';

const ConversationSelectionSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.width ? props?.width : "45"}
      height={props?.height ? props?.height : "44"}
      viewBox="0 0 45 44"
      fill="none"
    >
      <Path
        d="M22.5 0.9875C10.8951 0.9875 1.4875 10.3951 1.4875 22C1.4875 33.6049 10.8951 43.0125 22.5 43.0125C34.1049 43.0125 43.5125 33.6049 43.5125 22C43.5125 10.3951 34.1049 0.9875 22.5 0.9875Z"
        fill={props.customBgColor || "#E8FDF3"}
      />
      <Path
        d="M22.5 0.9875C10.8951 0.9875 1.4875 10.3951 1.4875 22C1.4875 33.6049 10.8951 43.0125 22.5 43.0125C34.1049 43.0125 43.5125 33.6049 43.5125 22C43.5125 10.3951 34.1049 0.9875 22.5 0.9875Z"
        stroke={props.customBgColor || "#12B76A"}
        stroke-width="1.025"
      />
      <Path
        d="M15.325 22.0931L19.1309 26.3219C19.5237 26.7584 20.2023 26.7762 20.6175 26.361L29.0786 17.8999"
        stroke={props.customStrokeColor || "#12B76A"}
        stroke-width="1.025"
        stroke-linecap="round"
      />
    </Svg>
  );
};

export default ConversationSelectionSvg;
