import {useLazyQuery, useMutation} from '@apollo/client';
import {useContext, useEffect, useRef, useState, useMemo} from 'react';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../constants/Configs';
import {GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID} from '../../../../services/Appointment/AppointmentQueries';
import {
  getAuditTrailByResourceId,
  getDocumentRefByReferenceId,
  getMedicationForNote,
  getOrderByRefAndPatientId,
  getVersionDetailByAuditId,
  unlockPatientNoteById
} from '../../../../services/CommonService/OrderService';
import {GET_FORM_BY_ID} from '../../../../services/Forms/FormsQueries';
import {
  ICategoryWiseOrder,
  INoteAuditTrail,
  ISignedNoteViewState,
  ISingleNoteViewProps,
} from '../interfaces';
import {getExtensionValue} from '../Orders/OrdersAndReports/OrderUtils';
import {EXTENSION_URLS} from '../PatientNotes/components/AddOrUpdateTemplate/constant';
import {
  appointmentDataExist,
  getFormComponents,
  getSubmittedResponse,
} from '../PatientNotes/components/DocumentationViewHelper';
import {IFormComponent} from '../PatientNotes/interfaces';
import {
  filterOrderContentFromDocumentRef,
  findUserById,
  getAuditStatusByCode,
  getFilterResourceAudit,
  getFormContentByEhrAndFormData,
  getNoteHeaderText,
  getNoteSubHeaderTextForSignedNoteView,
  getUserNameById,
  hasChiefComplaintComponent,
  isOrderTypeDataExist,
} from '../PatientNotes/PatientNotesHelper';
import {Box, Button, Divider, FlatList, HStack, Pressable, Spacer, Text, useToast, View, VStack, Spinner} from 'native-base';
import {BUTTON_TYPE, ORDER_TYPE, DATE_FORMATS} from '../../../../constants/StringConst';
import {OrderType} from '../Orders/OrdersAndReports/OrderConstants';
import TimeLineSkeletonLoader from '../../../common/TimeLineSkeletonLoader/TimeLineSkeletonLoader';
import {FHForm} from '../../../RightSideContainer/Forms/FHFormio';
import {FormContext} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {allowToShareForNoteWithPatient, getAccountUUID, getBooleanFeatureFlag, isMultiTenancyEnabled} from '../../../../utils/commonUtils';
import {Colors} from '../../../../styles/Colors';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {FoldButton} from '../../../CommonComponents/FoldButton/FoldButton';
import {useIntl} from 'react-intl';
import Feather from 'react-native-vector-icons/Feather';
import {FHIR_RESOURCE} from '../../../../constants/FhirConstant';
import {PATIENT_NOTE_OPERATION} from '../PatientNotes/constants';
import ReactToPrint from 'react-to-print';
import {ComponentToPrint} from '../../../common/PrintComponent/ComponentToPrint';
import {PatientNotePrintPreview} from './PatientNotePrintPreview';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import { cloneDeep } from 'lodash';
import OrderList from '../Orders/OrdersAndReports/OrderList/OrderList';
import MedOrderList from '../Orders/OrdersAndReports/MedOrderList/MedOrderList';
import AppointmentBanner from '../PatientNotes/components/AppointmentBanner';
import { NoteAuditView } from './NoteAuditView';
import { getResourceAbilities } from '../../../../utils/capabilityUtils';
import { MLOV_CATEGORY } from '../../../../constants/MlovConst';
import { getMlovListFromCategory } from '../../../../utils/mlovUtils';
import { getDefaultTaskStatusId, getMissedTaskStatusId, isTaskDueDateIsPass } from '../../../common/CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import TaskQueries from '../../../../services/Task/TaskQueries';
import ReadIcon from '../../../../assets/Icons/ReadIcon';
import CustomViewButton from '../../LeftContainer/RecentActivity/PatientReportedView/components/CustomViewButton';
import ClockHistorySvg from '../../../common/Svg/ClockHistorySvg';
import { Popover, Tooltip } from 'antd';
import { getCurrentTimeZone, getDateStrFromFormatWithTimezone } from '../../../../utils/DateUtils';
import EyeOpenSvg from '../../../common/Svg/WidgetsSvg/EyeOpenSvg';
import { getPreviousVersionFormDataByResponse } from './NoteUtils';
import Stack from '../../../common/LayoutComponents/Stack';
import { checkIsFoldDrivenNotes } from '../PatientNotes/components/AddOrUpdateTemplate/helper';
import { StyleSheet } from 'react-native';
import { reactStyles } from '../PatientNotes/PatientNotesStyles';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../constants/AccountConfigConst';

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    paddingHorizontal: 8,
    paddingVertical: 16,
    alignItems: 'center',
    backgroundColor: Colors.Custom.Gray100,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Custom.Gray200,
  },
  headerContainer2: {
    marginLeft: 10,
  },
  actionButton2: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 7,
    backgroundColor: Colors.Custom.BackgroundColor,
    borderWidth: 1,
    borderColor: Colors.Custom.mainPrimaryPurple,
  },
  versionHistoryText: {
    textTransform: 'uppercase',
    letterSpacing: 0.1,
    color: Colors?.FoldPixel?.GRAY300,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  printComponent: {
    display: 'none',
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: '700',
    color: '#101828',
  },
  actionButton: {
    height: 40,
    width: 40,
    borderRadius: 30,
  },
  contentContainer: {
    backgroundColor: '#fff',
    paddingHorizontal: 16,
  },
  spacer: {
    height: 3,
  },
  container: {
    padding: 8,
  },
  dateText: {
    color: Colors?.FoldPixel?.GRAY400,
  },
  statusContainer: {
    alignItems: 'center',
  },
  statusText: {
    fontWeight: '400',
    color: Colors?.FoldPixel?.GRAY300,
  },
  chevronDownIcon: {
    paddingHorizontal: 10,
    alignSelf: 'center',
  },
  divider: {
    height: '100%',
    width: 1,
    backgroundColor: Colors.Custom.Gray200,
  },
  currentVersionContainer: {
    paddingHorizontal: 6,
    paddingVertical: 2.5,
    borderRadius: 4,
    // backgroundColor: Colors?.Custom?.PrimaryColor100,
    width: 'fit-content',
  },
  currentVersionText: {
    color: Colors?.Custom?.Primary300,
    fontWeight: '400',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 6,
  },
  itemText: {
    color: Colors.Custom.Gray500,
    fontSize: 12,
    fontWeight: '400',
    marginLeft: 16,
  },
});

const antdStyles: Record<string, React.CSSProperties> = {
  popoverContent: {
    minWidth: 'max-content',
  },
  popoverOverlay: {
    borderRadius: 16,
    minWidth: 140,
    padding: 0,
  },
};

export const SignedNoteView = (props: ISingleNoteViewProps) => {
  const intl = useIntl();
  const toast = useToast();
  const accountUUID = getAccountUUID();
  const selectedTemplateId = props?.cardProps.formId;
  const resourceId = props?.cardProps?.resourceId;
  const {personData, unFormattedContactData, cardProps, ehrCapabilities, header, footer, ehrConfig, accountUserList, activeAndNonActiveUserList} = props;
  const { linkTasks } = cardProps;
  const commonContextData = useContext(CommonDataContext);
  const userSettings = commonContextData.userSettings;
  const allowToSharePatientHtml = allowToShareForNoteWithPatient(userSettings);
  const [updateTask] = useMutation(TaskQueries.UPDATE_TASK);
  const [signNoteState, setSignedNoteViewState] =
    useState<ISignedNoteViewState>({
      isLoading: false,
      isProcessing: false,
      startPrinting: false,
      resourceAudit: [],
      auditpopoverVisible: false,
      printShareable: false,
      selectedVersionSignedBy: {
        signedByUserName: cardProps?.signedByUserName,
        signedDate: cardProps?.signedDate,
        amendedByUserName: cardProps?.amendedByUserName,
        amendedDate: cardProps?.amendedDate,
      },
    } as ISignedNoteViewState);
  const [getAppointmentByExtId] = useLazyQuery(
    GET_BOOKED_APPOINTMENT_BY_EXTERNAL_ID,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      variables: {
        externalAppointmentId: cardProps.linkedAppointmentId,
      },
    },
  );
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    personData?.accountLocationUuid
  );
  const isFoldDrivenNote = props.cardProps?.isFoldDrivenNote;
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;

  const taskStatusMlov = getMlovListFromCategory(commonContextData.CARE_STUDIO_MLOV, MLOV_CATEGORY.TASK_STATUS) || [];
  const defaultStatusId: string = getDefaultTaskStatusId(taskStatusMlov);
  const missedStatusId: string = getMissedTaskStatusId(taskStatusMlov);


  const componentRef = useRef(null);
  const printRef: any = useRef(null);

  const [getFormById] = useLazyQuery(GET_FORM_BY_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const getCategoryWiseOrderData = (
    responseList: any,
    orderBySequence: any[],
  ): ICategoryWiseOrder => {
    const categoryWiseOrders: ICategoryWiseOrder = {
      labTest: [],
      radiology: [],
      medications: [],
    };
    if (responseList?.length) {
      (orderBySequence || []).forEach((sequence, index) => {
        const response = responseList[index];
        const resourceList = response?.data?.entry || [];
        switch (sequence) {
          case ORDER_TYPE.LAB:
            categoryWiseOrders.labTest = resourceList;
            break;
          case ORDER_TYPE.RAD:
            categoryWiseOrders.radiology = resourceList;
            break;
          case ORDER_TYPE.MED:
            categoryWiseOrders.medications = resourceList;
            break;
        }
      });
    }
    return categoryWiseOrders;
  };

  const processOnFormAndNoteData = (
    formData: any,
    documentRefData: any,
    appointmentData?: any,
    resourceAuditData?: any,
  ) => {
    const isFoldDrivenNote = checkIsFoldDrivenNotes(documentRefData);
    setSignedNoteViewState((prev) => ({
      ...prev,
      appointmentData: appointmentData,
    }));
    const orderBySequence: any[] = [];
    const orderPromiseList: any[] = [];
    const initData = {
      formContent: [] as any[],
    };
    const auditTrailList = resourceAuditData &&  resourceAuditData?.length ? getFilterResourceAudit(resourceAuditData, true) : [];
    let formComponents: any[] = [];
    let formAnswer: any = {};
    let sequence = -1;

    // process on reference data
    const patientContactId = personData?.id || '';
    const patientId = personData?.patientId || personData?.patientUuid || '';
    const documentRefContent =
      filterOrderContentFromDocumentRef(documentRefData);
    const additionalSections = getExtensionValue(
      documentRefData,
      EXTENSION_URLS.docRefAdditionalSection,
    );
    const formContent: IFormComponent[] = getFormContentByEhrAndFormData(
      documentRefData?.content || [],
      isFoldDrivenNote,
    );
    if (formData?.form) {

      initData.formContent = formContent;
      formComponents = getFormComponents(
        formData?.form?.components,
        appointmentData,
        initData,
        !!appointmentData?.externalAppointmentId
      );
      formAnswer = getSubmittedResponse(
        initData,
        appointmentData,
        !!appointmentData?.externalAppointmentId,
        hasChiefComplaintComponent(formData),
      );
    }
    if (documentRefContent?.length || additionalSections?.length) {
      if (
        isOrderTypeDataExist(ORDER_TYPE.LAB, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.LAB)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.LAB;
        orderPromiseList.push(
          getOrderByRefAndPatientId(
            OrderType.LAB,
            patientId,
            `${resourceId || ''}`,
            personData?.accountLocationUuid,
          ),
        );
      }
      if (
        isOrderTypeDataExist(ORDER_TYPE.RAD, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.RAD)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.RAD;
        orderPromiseList.push(
          getOrderByRefAndPatientId(
            OrderType.RAD,
            patientId,
            `${resourceId || ''}`,
            personData?.accountLocationUuid,
          ),
        );
      }
      if (
        isOrderTypeDataExist(ORDER_TYPE.MED, documentRefContent) ||
        additionalSections?.includes(ORDER_TYPE.MED)
      ) {
        sequence += 1;
        orderBySequence[sequence] = ORDER_TYPE.MED;
        orderPromiseList.push(
          getMedicationForNote(patientContactId, `${resourceId || ''}`),
        );
      }
    }
    const signedByUserId = getExtensionValue(documentRefData, EXTENSION_URLS.signedBy);
    const signedUser = findUserById(signedByUserId, accountUserList);
    const signedDate = getExtensionValue(documentRefData, EXTENSION_URLS.signedDate);
    const amendedByUserId = getExtensionValue(documentRefData, EXTENSION_URLS.amendedBy);
    const amendedUser = findUserById(amendedByUserId, accountUserList);
    const amendedDate = getExtensionValue(documentRefData, EXTENSION_URLS.amendedDate);
    Promise.all(orderPromiseList)
      .then((responseList: any) => {
        const categoryWiseOrderData: ICategoryWiseOrder =
          getCategoryWiseOrderData(responseList, orderBySequence);
        const templateData = {components: formComponents, answers: formAnswer};
        setSignedNoteViewState((prev: any) => {
          return {
            ...prev,
            templateData,
            categoryWiseOrderData,
            resourceAudit: auditTrailList || [],
            currentVersionAuditid: auditTrailList?.[0]?.id,
            selectedVersionSignedBy: {
              signedByUserName: signedUser?.name,
              signedDate: signedDate,
              amendedByUserName: amendedUser?.name,
              amendedDate: amendedDate
            },
            isLoading: false,
            formOptions: {
              accountUUID,
              ehrCapabilities,
              userSettings,
              formContext: FormContext.patientNotes,
              ehrActivePatient: props.unFormattedContactData?.contactProfile || personData?.contactProfile,
            },
            loadingFormData: false,
          };
        });
      })
      .catch(error => {
        setSignedNoteViewState((prev: any) => {
          return {
            ...prev,
            isLoading: false,
            loadingFormData: false,
          };
        });
      })
  };


  const fetchFormAndNoteOrders = async () => {
    try {
      const promiseList: any[] = [
        getFormById({variables: {id: selectedTemplateId}}),
        getDocumentRefByReferenceId(
          `${cardProps.resourceId || ''}`,
          isFoldDrivenNote && foldVisitNoteWithEncountersEnabled,
          personData?.accountLocationUuid,
        ),
        getAuditTrailByResourceId(`${cardProps.resourceId || ''}`, personData?.accountLocationUuid),
      ];
      if (cardProps.linkedAppointmentId) {
        promiseList.push(
          getAppointmentByExtId({
            variables: {externalAppointmentId: cardProps.linkedAppointmentId},
          }),
        );
      }
      const noteResponse = await Promise.all(promiseList);
      const formData = noteResponse?.[0]?.data || undefined;
      const documentRefData = noteResponse?.[1]?.data || undefined;
      const appointmentData = noteResponse?.[3]?.data || undefined;
      const resourceAuditData = noteResponse?.[2]?.data || undefined;
      setSignedNoteViewState(prev => ({...prev, formData: formData, resourceAuditData: resourceAuditData}));
      await processOnFormAndNoteData(
        formData,
        documentRefData,
        appointmentData?.appointments?.[0],
        resourceAuditData
      );
    } catch (error) {
      setSignedNoteViewState(prev => ({...prev, isLoading: false}));
    }
  };

  const getNoteVersionPreview = (auditListItem: INoteAuditTrail) => {
    getVersionDetailByAuditId(auditListItem.id, personData?.accountLocationUuid)
      .then((response) => {
        const currentFormData = cloneDeep(signNoteState?.formData);
        const versionFormData = getPreviousVersionFormDataByResponse(
          currentFormData,
          response?.data?.resource
        );
        processOnFormAndNoteData(
          versionFormData,
          response?.data?.resource,
          signNoteState?.appointmentData,
          signNoteState?.resourceAuditData
        );
      })
      .catch(() => {
        showToast(toast, 'Error in fetching note version preview', ToastType.error)
        setSignedNoteViewState((prev: any) => {
          return {
            ...prev,
            isLoading: false,
            loadingFormData: false,
          };
        });
      });
  }

  const renderPrintComponent = () => {
    return (
      <>
        {signNoteState.startPrinting && (
          <PatientNotePrintPreview
            printShareable={signNoteState?.printShareable}
            ehrConfig={ehrConfig}
            appointmentData={signNoteState.appointmentData || {}}
            personData={personData}
            unFormattedContactData={unFormattedContactData}
            isDefaultDataAvailable={true}
            notesData={{
              ...cardProps,
              signedByUserName: signNoteState?.selectedVersionSignedBy?.signedByUserName,
              signedDate: signNoteState?.selectedVersionSignedBy?.signedDate,
              amendedByUserName: signNoteState?.selectedVersionSignedBy?.amendedByUserName,
              amendedDate: signNoteState?.selectedVersionSignedBy?.amendedDate,
            }}
            formOptions={signNoteState.formOptions}
            templateData={cloneDeep(signNoteState.templateData)}
            categoryWiseOrderData={cloneDeep(signNoteState.categoryWiseOrderData)}
            onRenderComponent={() => {
              printPatientNotes();
            }}
          />
        )}
      </>
    );
  };

  const renderAuditListItem = (
    auditListItem: INoteAuditTrail,
  ) => {
    const isCurrentVersion = auditListItem?.id === signNoteState?.currentVersionAuditid;
    return (
      <Pressable
        onPress={() => {
          setSignedNoteViewState((prev) => ({
            ...prev,
            auditpopoverVisible: false,
            loadingFormData: true,
            selectedVersion: auditListItem,
          }));
          getNoteVersionPreview(auditListItem);
        }}
        _hover={{bg: Colors.Custom?.Gray100}}
      >
        <Stack
          style={styles.container}
          direction="column"
        >
          <Text style={styles.dateText}>
            {getDateStrFromFormatWithTimezone(
              auditListItem.actionDate,
              getCurrentTimeZone(),
              DATE_FORMATS.SIGNED_NOTE_DATE_FOR_AUDIT
            )}
          </Text>
          <Stack direction="row" style={styles.statusContainer} space={2}>
            <Text style={styles.statusText}>
              {getAuditStatusByCode(auditListItem?.action)?.trim()}:{' '}
              {getUserNameById(auditListItem?.userId, activeAndNonActiveUserList ||  accountUserList || [])}
            </Text>
            <Spacer />
            <Divider style={styles.divider} />
            <EyeOpenSvg />
          </Stack>
          {isCurrentVersion && (
            <View style={styles.currentVersionContainer} backgroundColor={Colors?.Custom?.PrimaryColor100}>
              <Text style={styles.currentVersionText}>
                {intl.formatMessage({id: 'currentVersion'})}
              </Text>
            </View>
          )}
        </Stack>
      </Pressable>
    );
  };

  const getHeaderTitle = () => {
    const headerText = getNoteHeaderText(cardProps);
    return (
      <VStack style={styles.headerContainer2} display={'flex'} flex={1}>
        {(props?.ehrConfig.isFold || props.ehrConfig.isAthena || props.cardProps?.isFoldDrivenNote) && signNoteState?.resourceAudit?.length > 0 && (
          <Popover
            placement="bottom"
            showArrow={false}
            trigger="click"
            overlayClassName="note-template"
            open={signNoteState?.auditpopoverVisible}
            onOpenChange={(newVisible) => {
              setSignedNoteViewState((prev) => ({
                ...prev,
                auditpopoverVisible: newVisible,
              }));
            }}
            style={antdStyles.popoverContent}
            content={
              <View width={350} borderRadius={8}>
                {signNoteState?.auditpopoverVisible ? (
                  <VStack maxHeight={400} overflow={'scroll'}>
                    <Text
                      style={styles.versionHistoryText}
                    >
                      {intl.formatMessage({id: 'versionHistory'})}
                    </Text>
                    {signNoteState?.resourceAudit?.map((item, index) => {
                      return (
                        <View key={`${item.id}_${index}`} marginX={2}>
                          {renderAuditListItem(item)}
                        </View>
                      );
                    })}
                  </VStack>
                ) : (
                  <></>
                )}
              </View>
            }
          >
            <HStack alignItems={'center'} space={1} cursor={'pointer'} alignSelf={'flex-start'}>
              <ClockHistorySvg />
              <Text color={Colors?.Custom?.Gray500} marginLeft={1}>
                {signNoteState?.resourceAudit?.length} {signNoteState?.resourceAudit?.length === 1 ? 'change' : 'changes'}
              </Text>
            </HStack>
          </Popover>
        )}

        <Text style={styles.headerTitle} isTruncated>
          {headerText}
        </Text>
        {cardProps.allowToShowToPatient && (
          <HStack gap={2} alignItems={'center'}>
            <ReadIcon />
            <Text ml={2} color={Colors.Custom.SuccessColor}>
              {intl.formatMessage({
                id: 'displayedToMember',
              })}
            </Text>
          </HStack>
        )}
      </VStack>
    );
  };

  const printPatientNotes = () => {
    if (printRef?.current && printRef?.current?.handleClick) {
      printRef.current.handleClick();
    }
  };

  const unlockPatientNote = () => {
    setSignedNoteViewState(prev => ({...prev, isProcessing: true}));
    unlockPatientNoteById(`${cardProps.resourceId}`, personData?.accountLocationUuid, cardProps.isFoldDrivenNote).then(response => {
      setSignedNoteViewState(prev => ({...prev, isProcessing: false}));
      if (linkTasks && linkTasks?.length && linkTasks?.[0]?.id) {
        const linkTask = linkTasks[0];
        const isPassDate = isTaskDueDateIsPass(linkTask?.endDateTime || '');
        updateTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: linkTask?.id,
              data: {
                statusId: isPassDate ? missedStatusId : defaultStatusId,
              }
            },
          }
        });
      }
      if (props?.onActionPerformed && typeof props?.onActionPerformed === 'function') {
        props?.onActionPerformed(PATIENT_NOTE_OPERATION.UNLOCK, cardProps);
      }
    }).catch(error => {
      setSignedNoteViewState(prev => ({...prev, isProcessing: false}));
      showToast(
        toast,
        'Facing issue while unlock note. Please check and try again.',
        ToastType.error,
        2000
      );
    });
  };


  const renderActionMenu = () => {
    const actions: any[] = [];
    actions.push(
      {
        id: PATIENT_NOTE_OPERATION.PRINT,
        btnText: intl.formatMessage({
          id: 'print',
        }),
        icon: (
          <Feather
            name="printer"
            size={20}
            color={Colors.Custom.mainPrimaryPurple}
          />
        ),
      },
      {
        id: PATIENT_NOTE_OPERATION.PRINT_SHAREABLE,
        btnText: intl.formatMessage({
          id: 'printShareable',
        }),
        icon: (
          <Feather
            name="printer"
            size={20}
            color={Colors.Custom.mainPrimaryPurple}
          />
        ),
      }
    );

    return (
      actions.length > 0 && (
        <FlatList
          data={actions}
          renderItem={({item, index}) => (
            <>
              <Pressable
                key={item?.btnText}
                disabled={item.isDisabled}
                flexDirection="row"
                alignItems="center"
                my={1.5}
                onPress={() =>
                  setSignedNoteViewState((prev) => {
                    return {
                      ...prev,
                      startPrinting: true,
                      printShareable: item?.id === PATIENT_NOTE_OPERATION.PRINT_SHAREABLE,
                    };
                  })
                }
              >
                {item.icon}
                <Text
                  color={Colors.Custom.Gray500}
                  fontSize={12}
                  fontWeight="400"
                  marginLeft={4}
                >
                  {item.btnText}
                </Text>
              </Pressable>
              {index !== actions.length - 1 && <Divider />}
            </>
          )}
        />
      )
    );
  };

  const renderSignedNoteHeader = () => {
    return (
      <HStack
        width="100%"
        px={2}
        paddingY={4}
        alignItems="center"
        backgroundColor={Colors.Custom.Gray100}
        borderBottomWidth={1}
        borderBottomColor={Colors.Custom.Gray200}>
        {/* <Button
          backgroundColor={Colors.Custom.Gray100}
          _hover={{bg: Colors.primary['100'] + '7a' || ''}}
          style={ {
            height: 40,
            width: 40,
            borderRadius: 30,
          }}
          onPress={() => {
            if (props?.goBackHandler && typeof props?.goBackHandler === 'function') {
              props?.goBackHandler();
            } else {
              if (
                props?.onActionPerformed &&
                typeof props?.onActionPerformed === 'function'
              ) {
                props?.onActionPerformed(
                  PATIENT_NOTE_OPERATION.WITHOUT_REFETCH_LIST_VIEW,
                  cardProps,
                );
              }
            }
          }}>
          <AntIcon name="arrowleft" size={18} />
        </Button> */}
        {getHeaderTitle()}
        <HStack space={2} alignItems="center">
          {cardProps.isAllowToPrint && (
            <FoldButton
              customProps={{
                btnText: intl.formatMessage({
                  id: 'print',
                }),
                withRightBorder: !signNoteState.startPrinting && allowToSharePatientHtml,
                isDisableOnPress: true,
              }}
              nativeProps={{
                leftIcon: (
                  <Feather
                    name="printer"
                    size={20}
                    color={Colors.Custom.mainPrimaryPurple}
                  />
                ),
                rightIcon: (!allowToSharePatientHtml || signNoteState.startPrinting) ? (
                  undefined
                ) : (
                  <Popover
                    overlayClassName="custom-popover-styles"
                    showArrow={true}
                    destroyTooltipOnHide
                    placement="bottomRight"
                    content={renderActionMenu}
                    trigger="click"
                    overlayInnerStyle={antdStyles.popoverOverlay}
                  >
                    <Box>
                      <Feather
                        name={'chevron-down'}
                        size={20}
                        color={Colors.Custom.mainPrimaryPurple}
                        style={styles.chevronDownIcon}
                      />
                    </Box>
                  </Popover>
                ),
                style: {minWidth: 'auto'},
                isDisabled: signNoteState.isProcessing || signNoteState.startPrinting,
                variant: BUTTON_TYPE.PRIMARY,
                backgroundColor: Colors.Custom.BackgroundColor,
                isLoading: signNoteState.startPrinting,
                color: Colors.Custom.mainPrimaryPurple,
                onPress: () => {
                  setSignedNoteViewState(prev => {
                    return {
                      ...prev,
                      startPrinting: true,
                    };
                  });
                },
              }}
            />
          )}
          {cardProps.isAllowToUnlock && (
            <Tooltip title={intl.formatMessage({ id: 'addendum' })} placement="top">
              <Button
                onPress={() => unlockPatientNote()}
                style={styles.actionButton2}
                startIcon={signNoteState.isProcessing ? (
                  <Spinner size={'sm'} />
                ) : (
                  <Feather
                    name="lock"
                    size={20}
                    color={Colors.Custom.mainPrimaryPurple}
                  />
                )}
              />
            </Tooltip>
          )}
          {!cardProps.hideCancel && (
          <Button
            backgroundColor={Colors.Custom.Gray100}
            _hover={{bg: Colors.primary['100'] + '7a' || ''}}
            style={styles.actionButton}
            onPress={() => {
              if (props?.goBackHandler && typeof props?.goBackHandler === 'function') {
                props?.goBackHandler();
              } else {
                if (
                  props?.onActionPerformed &&
                  typeof props?.onActionPerformed === 'function'
                ) {
                  props?.onActionPerformed(
                    PATIENT_NOTE_OPERATION.WITHOUT_REFETCH_LIST_VIEW,
                    cardProps,
                  );
                }
              }
            }}
          >
            <AntIcon name="close" size={18} />
          </Button>
          )}
        </HStack>
      </HStack>
    );
  };

  const getDocumentTitle = () => {
    return `${personData.name}`;
  };

  useEffect(() => {
    setSignedNoteViewState(prev => ({...prev, isLoading: true}));
    fetchFormAndNoteOrders();
  }, []);
  return (
    <>
      {signNoteState.isLoading && <TimeLineSkeletonLoader />}
      {!signNoteState.isLoading && (
        <VStack
          height={'100%'}
          flex={1}
          backgroundColor={Colors.Custom.Gray50}
          borderRadius={4}
        >
          {renderSignedNoteHeader()}
          <VStack flex={1}>
            <VStack
              flex={1}
              h="100%"
              style={styles.contentContainer}
            >
              <View overflowY={'scroll'} height={'100%'}>
                <Box h={3} />
                {appointmentDataExist(signNoteState.appointmentData) && (
                  <AppointmentBanner
                    appointmentId={signNoteState.appointmentData?.id}
                    name={signNoteState.appointmentData?.name}
                    location={
                      signNoteState.appointmentData?.appointmentType
                        ?.locationType?.value || ''
                    }
                    statusId={signNoteState.appointmentData?.statusId}
                    date={signNoteState.appointmentData?.startDateTime}
                    reasonForVisit={
                      signNoteState.appointmentData?.reasonForVisit?.displayName
                    }
                    extraStyles={{marginBottom: 22}}
                  />
                )}
                {signNoteState?.loadingFormData ? (
                  <TimeLineSkeletonLoader />
                ) : (
                  <div className="note-preview-styles custom-form-styles">
                    <FHForm
                      components={signNoteState?.templateData?.components}
                      submittedResponse={signNoteState?.templateData?.answers}
                      optionData={signNoteState.formOptions}
                      isPreviewMode={true}
                      readOnly={true}
                    />
                  </div>
                )}
                {signNoteState.categoryWiseOrderData?.labTest &&
                  signNoteState.categoryWiseOrderData?.labTest?.length > 0 && (
                    <OrderList
                      isPreviewMode={true}
                      title={'Lab Order'}
                      orderType={OrderType.LAB}
                      patientContactId={personData?.id || ''}
                      patientId={
                        personData?.patientId || personData?.patientUuid || ''
                      }
                      documentReferenceId={`${cardProps.resourceId || ''}`}
                      unFormattedContactData={props.unFormattedContactData}
                      personData={props.personData}
                      onOrderListUpdate={(list: any[]) => {
                        /** */
                      }}
                      disabled={true}
                      addDiagnosisFormOrderList={() => {
                        /** */
                      }}
                    />
                  )}
                {signNoteState.categoryWiseOrderData?.radiology &&
                  signNoteState.categoryWiseOrderData?.radiology?.length >
                    0 && (
                    <OrderList
                      isPreviewMode={true}
                      title={'Imaging Order'}
                      orderType={OrderType.RAD}
                      patientContactId={personData?.id || ''}
                      patientId={
                        personData?.patientId || personData?.patientUuid || ''
                      }
                      documentReferenceId={`${cardProps.resourceId || ''}`}
                      unFormattedContactData={props.unFormattedContactData}
                      personData={props.personData}
                      onOrderListUpdate={(list: any[]) => {
                        /** */
                      }}
                      disabled={true}
                      addDiagnosisFormOrderList={() => {
                        /** */
                      }}
                    />
                  )}
                {signNoteState.categoryWiseOrderData?.medications &&
                  signNoteState.categoryWiseOrderData?.medications?.length >
                    0 && (
                    <MedOrderList
                      isPreviewMode={true}
                      addDiagnosisFormOrderList={() => {
                        /** */
                      }}
                      patientContactId={personData?.id || ''}
                      patientId={
                        personData?.patientId || personData?.patientUuid || ''
                      }
                      documentReferenceId={`${cardProps?.resourceId}`}
                      unFormattedContactData={props.unFormattedContactData}
                      personData={props.personData}
                      onOrderListUpdate={(list: any[]) => {
                        /** */
                      }}
                      disabled={true}
                    />
                  )}
              </View>
            </VStack>
          </VStack>
        </VStack>
      )}
      <>
        <ReactToPrint
          trigger={() => <></>}
          content={() => componentRef.current}
          ref={printRef}
          onAfterPrint={() => {
            setSignedNoteViewState((prev) => {
              return {
                ...prev,
                startPrinting: false,
                printShareable: false,
              };
            });
          }}
          print={(target) => {
            return new Promise<any>((resolve, reject) => {
              if (target.contentDocument) {
                target.contentDocument.title = getDocumentTitle();
              }
              target?.contentWindow?.print?.();
              resolve(true);
            });
          }}
          removeAfterPrint={true}
        />
        <div style={reactStyles.displayNone}>
          <ComponentToPrint ref={componentRef} header={header} footer={footer}>
            {renderPrintComponent()}
          </ComponentToPrint>
        </div>
      </>
    </>
  );
};
