import React from 'react';
import {Path, Svg} from 'react-native-svg';

const PatientAddressSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        fill="#F5F0FF"
      />
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        stroke="#D7C0FF"
        strokeWidth="0.5"
      />
      <Path
        d="M17.2191 24.062L17.0074 23.6091L17.2191 24.062ZM14.7809 24.062L14.9926 23.6091L14.7809 24.062ZM17.0074 23.6091C16.3677 23.9081 15.6323 23.9081 14.9926 23.6091L14.5692 24.515C15.4772 24.9394 16.5228 24.9394 17.4308 24.515L17.0074 23.6091ZM8.83333 14.4527C8.83333 16.4013 9.38907 18.4972 10.3728 20.3086C11.3556 22.1181 12.7871 23.682 14.5692 24.515L14.9926 23.6091C13.4549 22.8903 12.1626 21.5089 11.2516 19.8313C10.3415 18.1555 9.83333 16.2226 9.83333 14.4527H8.83333ZM22.1667 14.4527C22.1667 16.2226 21.6585 18.1555 20.7484 19.8313C19.8374 21.5089 18.5451 22.8903 17.0074 23.6091L17.4308 24.515C19.2129 23.682 20.6444 22.1181 21.6272 20.3086C22.6109 18.4972 23.1667 16.4013 23.1667 14.4527H22.1667ZM16 8.16666C19.3974 8.16666 22.1667 10.9726 22.1667 14.4527H23.1667C23.1667 10.4372 19.9664 7.16666 16 7.16666V8.16666ZM16 7.16666C12.0336 7.16666 8.83333 10.4372 8.83333 14.4527H9.83333C9.83333 10.9726 12.6026 8.16666 16 8.16666V7.16666ZM18 14.3333C18 15.4379 17.1046 16.3333 16 16.3333V17.3333C17.6569 17.3333 19 15.9902 19 14.3333H18ZM16 16.3333C14.8954 16.3333 14 15.4379 14 14.3333H13C13 15.9902 14.3431 17.3333 16 17.3333V16.3333ZM14 14.3333C14 13.2288 14.8954 12.3333 16 12.3333V11.3333C14.3431 11.3333 13 12.6765 13 14.3333H14ZM16 12.3333C17.1046 12.3333 18 13.2288 18 14.3333H19C19 12.6765 17.6569 11.3333 16 11.3333V12.3333Z"
        fill="#8C5AE2"
      />
    </Svg>
  );
};

export default React.memo(PatientAddressSvg);
