import { View, Text, FormControl, HStack, VStack, Select, Button, Spacer, Stack, useMediaQuery, IconButton, Icon, Pressable } from 'native-base'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl';
import { Colors } from '../../../../../../../styles';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import PAMISearch, { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { IAllergyReaction } from '../interfaces';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import Feather from 'react-native-vector-icons/Feather';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';

export interface IAllergyReactionEditProps {
  reactionInputType?: InputType,
  isDisabled: boolean;
  isRequired: boolean;
  disableMultiple: boolean;
  addNewReactionEnabled: boolean;
  severityList: {code: string, display: string}[];
  reactions: IAllergyReaction[];
  isReconciliation?: boolean;
  onChange: (reactions: IAllergyReaction[]) => void;
}

const AllergyReactionEdit = (props: IAllergyReactionEditProps) => {
  const {isDisabled, isRequired, disableMultiple, reactions, severityList, onChange, addNewReactionEnabled, isReconciliation} = props;
  const intl = useIntl();
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreen] = isReconciliation ? [false] : [width <= 480];

  const severityLabel = intl.formatMessage({id: 'severity'});
  const reactionLabel = intl.formatMessage({id: 'reaction'});
  const getSelectedValue = (reaction?: IAllergyReaction) => {
    if (reaction) {
      return {
        text: reaction.manifestation?.display,
        coding: [
          {
            display: reaction.manifestation?.display,
            code: reaction.manifestation?.code,
          }
        ]
      }
    }
  }

  return reactions.length > 0 ? (
    <HStack flex={1}>
      {!isReconciliation && (
        <Text
          flex={1.8}
          mr={2}
          ml={1}
          size={'smRegular'}
          color={Colors.FoldPixel.GRAY250}
        >
          Reactions
        </Text>
      )}
      <VStack
        ref={componentRef}
        space={isMobileScreen ? 6 : 2}
        flex={8.2}
        w="100%"
      >
        {reactions.map((reaction: IAllergyReaction, index: number) => {
          return (
            <HStack
              key={index}
              direction={isMobileScreen ? 'column' : 'row'}
              space={2}
              alignItems={isMobileScreen ? 'flex-start' : 'center'}
            >
              <View width="100%" flex={isMobileScreen ? 2.5 : 7}>
                <PAMISearch
                  value={getSelectedValue(reaction)}
                  disabled={isDisabled}
                  addNewOptionEnabled={addNewReactionEnabled}
                  searchType={PAMISearchType.allergyReactions}
                  placeholder={reactionLabel}
                  isShowError={
                    (isRequired || !!reaction.severity) &&
                    !reaction.manifestation
                  }
                  onChange={(value) => {
                    if (value?.text || value?.coding?.length) {
                      reactions[index].manifestation = {
                        code: value.coding?.[0]?.code,
                        display: value?.text || value.coding?.[0]?.display,
                      };
                    } else {
                      reactions[index].manifestation = undefined;
                      reactions[index].severity = undefined;
                    }
                    onChange(reactions);
                  }}
                />
              </View>
              {reaction.manifestation && (
                <View flex={2} alignSelf="stretch">
                  <FormControl>
                    <Select
                      placeholder={severityLabel}
                      selectedValue={reaction.severity}
                      isDisabled={isDisabled}
                      onValueChange={(value: string) => {
                        reactions[index].severity = value;
                        onChange(reactions);
                      }}
                    >
                      {severityList.map((option: any, index: number) => {
                        return (
                          <Select.Item
                            key={`${option.id}_${index}`}
                            value={option.code}
                            label={option.display}
                          />
                        );
                      })}
                    </Select>
                  </FormControl>
                </View>
              )}

              {!isDisabled && (
                <Pressable
                  onPress={() => {
                    reactions.splice(index, 1);
                    onChange(reactions);
                  }}
                  alignSelf={isMobileScreen ? 'flex-end' : 'center'}
                  borderWidth={0.3}
                  backgroundColor={Colors.Custom.Base50}
                  borderColor={Colors.Custom.ActionBtnBorder}
                  px={2}
                  py={1}
                  rounded="full"
                  shadow={2}
                  {...testID(TestIdentifiers.deleteBtn)}
                >
                  <Feather
                    name={'trash-2'}
                    size={20}
                    color={Colors.Custom.PurpleColor}
                  />
                </Pressable>
              )}
            </HStack>
          );
        })}
        {/* {!isDisabled && !disableMultiple && (
          <HStack>
            <Button
              rounded="3xl"
              size="sm"
              variant="link"
              onPress={() => {
                reactions.push({});
                onChange(reactions);
              }}
            >
              {`+ Add New Reaction`}
            </Button>
            <Spacer />
          </HStack>
        )} */}
      </VStack>
    </HStack>
  ) : (
    <></>
  );
}

export default AllergyReactionEdit
