import {useLazyQuery, useMutation} from '@apollo/client';
import {
  Alert,
  Drawer,
  Modal,
  Select,
  Switch,
  Tooltip,
  Upload,
  notification,
} from 'antd';
import {UploadFile} from 'antd/es/upload';
import {Content} from 'antd/lib/layout/layout';
import parse from 'html-react-parser';
import {Liquid} from 'liquidjs';
import {Button, FormControl, Input, Spinner} from 'native-base';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {useIntl} from 'react-intl';
import {Pressable, Text, View} from 'react-native';
import AntIcon from 'react-native-vector-icons/AntDesign';
import Feather from 'react-native-vector-icons/Feather';
import {v4} from 'uuid';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../constants/Configs';
import {FORM_SOURCE, MLOV_CATEGORY, USER_PREFERENCE_CODE} from '../../../constants/MlovConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {getCurrentSubdomain} from '../../../screens/MainScreen/MainScreenHelper';
import {FormsQueries, UserQueries} from '../../../services';
import {GetAccountIdBySubdomain} from '../../../services/AccountSubdomain/AccountSubdomainQuery';
import ContactsQueries from '../../../services/Contacts/ContactsQueries';
import ConversationsQueries from '../../../services/Conversations/ConversationsQueries';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {Colors} from '../../../styles';
import {getDateToMomentISOString} from '../../../utils/DateUtils';
import {
  getAccountId,
  getAccountUUID,
  getBooleanFeatureFlag,
  getMsgEchoId,
  getPatientActiveProfileIds,
  getPatientActiveProfileLocationIds,
  getPatientActiveProfiles,
  getUserId,
  getUserUUID,
  isActiveProfileMatchAllowedLocations,
  isContactConsentRequired,
  isJourneyPackagesEnabled,
  isMultiTenancyEnabled,
  sleep,
} from '../../../utils/commonUtils';
import {ToastType} from '../../../utils/commonViewUtils';
import {
  getContactTypeId,
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import {FoldButton} from '../../CommonComponents/FoldButton/FoldButton';
import {AddOrUpdateLead} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {
  getAccountMergeTagData,
  getContactCustomAttributeMergeTagValues,
  getTemplateById,
  getTemplateCategories,
  getTemplateCategoryList,
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import {
  IEmailTemplateData,
  ITemplateCategory,
} from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import {IMediaLibraryData} from '../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';
import {MediaSelectionModal} from '../../RightSideContainer/ContentManagement/PatientEducation/MediaSelectionModal';
import {IForm} from '../../RightSideContainer/Forms/interfaces';
import {
  sendEmailMessageAPI,
} from '../../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/SmsConversationServices';
import {
  ALLOWED_TEMPLATES,
  CHANNEL_TYPE,
  TEMPLATES_CONST,
} from '../../RightSideContainer/TeamInbox/Conversations/ConversationConst';
import {AttachArticleDrawer} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/AttachArticleDrawer';
import AttachFormDrawer from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/AttachFormDrawer';
import AttachAppointmentDrawer from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/AttachmentAppointmentDrawer/AttachAppointmentDrawer';
import {
  getShortLink,
  messageDataKeysConverter,
} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {ISendOutlookEmailBody} from '../../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/EmailInboxCreate/interfaces';
import {AllTemplateSearch} from '../AllTemplateSearch';
import {getEmailDataFromContentAttributes} from '../ChatUI/EmailMessageBox/EmailMessageBoxUtils';
import {ModalActionTitle} from '../ModalActionTitle/ModalActionTitle';
import MultipleFormSearch from '../MultipleFormSearch/MultipleFormSearch';
import EducationContent from '../Svg/EducationContent';
import CalendarSvg from '../Svg/SideMenu/CalendarSvg';
import FormSvg from '../Svg/SideMenu/FormSvg';
import EmailAttachmentDrawer from './EmailAttachmentDrawer';
import {
  createEmailConversation,
  deleteDraft,
  downloadDocument,
  getDraft,
  saveDraft,
  sendCMSEmailWithAttachment,
} from './EmailDrawerCommonV2Api';
import {
  DRAFT_INTERVAL,
  MESSAGE_DRAFT_TYPES,
  MAX_ATTACHMENT_UPLOAD_SIZE,
  SEND_EMAIL_OPTIONS,
  NEW_DRAFT_CONVERSATION_UUID,
  SEND_BULK_EMAIL_DELAY,
  ID_FOR_EMAIL_SIGNATURE,
} from './EmailDrawerCommonV2Const';
import './EmailDrawerCommonV2Styles.scss';
import {
  extractFormIds,
  getAppointmentBookingLinkReplaceHtml,
  getCurrentAndPendingList,
  getExistingEmailMessageContent,
  getFormattedEmailInboxes,
  getLinkText,
  getMembershipLinkReplacedHtml,
  getPlaceholderMembershipLink,
  getPlaceholderUrl,
  validateForm,
} from './EmailDrawerCommonV2Utils';
import {
  downloadEmailAttachments,
  forwardEmail,
  getEmailAttachments,
  replyEmail,
  sendOutlookEmailAPI,
  uploadEmailAttachment,
} from './EmailInboxApi';
import SearchRecipient from './SearchRecipient';
import {
  IAllDraftBody,
  IContactSearchResponse,
  IMessageDraftTypes,
  IEmailDrawerCommonV2Props,
  IEmailInbox,
  IEmailRecipient,
  IInboxResponse,
  IInputErrors,
  IMessageData,
  ISearchRecipientRef,
  ISelectedForm,
  ISendEmailOptions,
  IUserSearchResponse,
  ICmsEmailBody,
  IDocumentData,
} from './interfaces';
import {styles, reactStyles} from './styles';
import {ContentTypes} from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {getFormattedSingleEmailTemplate} from '../../RightSideContainer/ContentManagement/EmailTemplates/EmailTemplatesUtils';
import {SearchPackages} from '../SearchPackages';
import {IProduct} from '../SearchPackages/interfaces';
import {createCopyLinkPackageApi} from '../../RightSideContainer/Sales/ProductsAndServices/JourneyPackages/PackageServices';
import {BUTTON_TYPE, PARENT_CODE} from '../../../constants';
import {debounce} from 'lodash';
import {GPTPrompt} from '../GPTPrompt';
import CannedResponses from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CannedResponses/CannedResponses';
import {ICannedResponses} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/CannedResponses/interface';
import {getLoggedInAccountInfoForSidecar} from '../../../sidecar/common/SideCardCommonUtils';
import {KEY_PRESS_CODES} from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MsgConst';
import InfoSvg from '../Svg/InfoSvg';
import Stack from '../LayoutComponents/Stack';
import JoditRichEditor from '../JoditRichEditor/JoditRichEditor';
import { EDITOR_ACTIONS } from '../JoditRichEditor/interface';
import { Jodit } from 'jodit-react';
import PreferenceAddSignatureSvg from '../Svg/PreferenceAddSignatureSvg';
import {AttachSignatureOptionPopover} from '../PreferencesSetting/AttachSignatureOptionPopover';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {IEmailSignature, IUserPreferenceData} from '../PreferencesSetting/PreferencesInterface';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import {useCustomToast} from '../../Toast/ToastProvider';
import PatientChangeAlertModal from './PatientChangeAlertModal';
import { testID, TestIdentifiers } from '../../../testUtils';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import useLoggedInUserAllowedLocationsByTab from '../../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
const EmailDrawerCommonV2 = (props: IEmailDrawerCommonV2Props) => {
  const abortControllerRef = React.useRef<AbortController>(
    new AbortController()
  );
  const [state, setState] = React.useState({
    emailInboxes: [] as IEmailInbox[],
    selectedEmailInbox: {} as IEmailInbox,
    subject: '',
    isSending: false,
    isLoading: false,
    showCc: false,
    showBcc: false,
    isFormDrawerOpen: false,
    isArticleDrawerOpen: false,
    isDiscardAlertOpen: false,
    isAppointmentLinkDrawerOpen: false,
    isAttachMediaDrawerOpen: false,
    isFileListDrawerOpen: false,
    isAddOrUpdateLeadDrawerOpen: false,
    toLoading: false,
    ccLoading: false,
    bccLoading: false,
    contacts: [] as IEmailRecipient[],
    ccUsers: [] as IEmailRecipient[],
    bccUsers: [] as IEmailRecipient[],
    templateCategories: [] as ITemplateCategory[],
    selectedTemplateCategory: {} as ITemplateCategory,
    selectedTemplate: {} as IEmailTemplateData,
    selectedForms: [] as {id: string; name: string}[],
    selectedPackages: [] as IProduct[],
    editorText: '',
    editorContent: '',
    editorHeight: 300,
    isContactNotFound: false,
    isContactSearchOpen: false,
    sendEmailOptions: SEND_EMAIL_OPTIONS.RAW_INPUT as ISendEmailOptions,
    isDiscardLoading: false,
    isAttachmentLoading: false,
    isSaveAsDraftLoading: false,
    isUploadingAttachment: false,
    isSignaturePopoverOpen: false,
    selectedSignaturePreference: {} as IEmailSignature,
    userSignaturesDefault: {} as IEmailSignature,
    userSignatures: [] as IEmailSignature[],
    showPatientChangeModal: false
  });

  const [errors, setErrors] = React.useState<IInputErrors>({
    from: '',
    subject: '',
    to: '',
    template: '',
  });
  const [selectedToRecipient, setSelectedToRecipient] = React.useState<IEmailRecipient[]>([]);
  const [to, setTo] = React.useState<IEmailRecipient[]>([]);
  const [cc, setCc] = React.useState<IEmailRecipient[]>([]);
  const [ccRecipientUuids, setCcRecipientUuids] = React.useState<string[]>([]);
  const [bcc, setBcc] = React.useState<IEmailRecipient[]>([]);
  const [bccRecipientUuids, setBccRecipientUuids] = React.useState<string[]>([]);
  const [fileList, setFileList] = React.useState<UploadFile<any>[]>([]);
  const editorRef = React.useRef<any>(null);
  const toSearchRecipientRef = React.useRef<ISearchRecipientRef>(null);
  const existingDraftDataRef = React.useRef<Partial<IAllDraftBody>>({});
  const subjectInputRef = React.useRef<any>(null);
  const isSendingEmailRef = React.useRef(false);
  const isShowDiscardAlertRef = React.useRef<boolean>(false);
  const intl = useIntl();
  const userId = getUserId();
  const userUuid = getUserUUID();
  const accountId = getAccountId();
  const accountUuid = getAccountUUID();
  const subdomain = getCurrentSubdomain();
  const customToast = useCustomToast();
  const mlovData = React.useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const userPreferenceList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCE_TYPE
  );
  const userPreferenceId = getMlovIdFromCode(
    userPreferenceList,
    USER_PREFERENCE_CODE.USER_CONVERSATION_PREFERENCE
  );

  const isSideCarContext = mlovData.sidecarContext?.isSidecar;
  const accountMergeTags = getAccountMergeTagData();
  const formSourceList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.FORM_SOURCE
    ) || [];
  const formSourceId = getMlovIdFromCode(formSourceList, FORM_SOURCE.EMAIL);
  const patientContactTypeId = getContactTypeId('CUSTOMER');
  const isConsentRequired = isContactConsentRequired();
  const isJourneyPackagesEnabledForAccount = isJourneyPackagesEnabled();
  const isEnabledMultiTenancy = getBooleanFeatureFlag(mlovData?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const [isCcBccDisabled, setIsCcBccDisabled] = React.useState<boolean>(isEnabledMultiTenancy && selectedToRecipient?.length === 0);
  const [selectedLocationId, setSelectedLocationId] = React.useState<string | undefined>(selectedToRecipient?.[0]?.contactPracticeLocations?.[0]?.practiceLocationUuid);
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.INBOX);
  const activecontactProfiles = getPatientActiveProfiles(to?.[0]?.contactProfiles || []);
  const hasActiveProfile = isActiveProfileMatchAllowedLocations(activecontactProfiles || [], allowedLocationIds)
  const isActionsDisabled = isMsoFlagEnabled ? (!hasActiveProfile && activecontactProfiles?.length > 0) : false;
  const tooltipMessage = !isActionsDisabled ? '' :  intl.formatMessage({id :'inactiveLocationPatientActionMsg'});
  const singleToLocationGroupId = isEnabledMultiTenancy
    ? to?.[0]?.contactPracticeLocations?.[0]?.accountLocation?.locationGroupId || ''
    : '';
  // derived variables
  const isMultiplePatientCategorySelected =
    state.sendEmailOptions === 'TEMPLATE' &&
    state.selectedTemplateCategory?.code ===
      TEMPLATES_CONST.MULTIPLE_PATIENT_FORMS;

  const isSharePackageCategorySelected =
    state.sendEmailOptions === 'TEMPLATE' &&
    state.selectedTemplateCategory?.code === TEMPLATES_CONST.SHARE_PACKAGES;

  const showEditor =
    state.sendEmailOptions === SEND_EMAIL_OPTIONS.RAW_INPUT ||
    state.selectedTemplateCategory?.code === TEMPLATES_CONST.GENERAL;

  // queries and mutations
  const [getContactByCondition] = useLazyQuery<{
    contacts: IContactSearchResponse[];
  }>(ContactsQueries.GET_CONTACT_BY_CONDITION, {fetchPolicy: 'no-cache'});

  const [getUsersAndContactsByCondition] = useLazyQuery<{
    users: IEmailRecipient[];
    contacts: IEmailRecipient[];
  }>(UserQueries.GET_USERS_AND_CONTACTS_BY_CONDITION, {
    fetchPolicy: 'no-cache',
  });

  const [getInboxesForEmail] = useLazyQuery<{inboxes: IInboxResponse[]}>(
    InboxQueries.GetInboxesForEmail,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [createFormLogLink] = useMutation(FormsQueries.CREATE_FORM_LOG_LINK, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  });

  const [getAccountIdBySubdomain] = useLazyQuery(GetAccountIdBySubdomain);

  const [getMessageDataByMessageUuid] = useLazyQuery<{
    messages: IMessageData[];
  }>(ConversationsQueries.GetMessageDataByMessageUuid);

  const [getUserPreference] = useLazyQuery(UserQueries.GET_USER_PREFERENCE, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: userUuid,
      userPreferencesTypeId: userPreferenceId,
    },
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  // search functions
  const getEmailInboxes = async () => {
    const response = await getInboxesForEmail({
      variables: {
        whereCondition: {
          channelType: {
            _eq: CHANNEL_TYPE.CHANNEL_EMAIL,
          },
          isDeleted: {
            _eq: false,
          },
          inboxMembers: {
            // if the inboxId is not passed from the props then add this
            ...(props.inboxId === undefined
              ? {
                  userId: {
                    _eq: userId,
                  },
                  isDeleted: {
                    _eq: false,
                  },
                }
              : {}),
          },
        },
      },
    });
    if (response.data?.inboxes && response.data.inboxes.length > 0) {
      return response.data.inboxes;
    }
    return [];
  };

  const getSupportEmail = async (): Promise<string> => {
    let currentSubdomain = subdomain
    if (isSideCarContext) {
      const loggedInUserAccountInfo = await getLoggedInAccountInfoForSidecar();
      currentSubdomain = loggedInUserAccountInfo?.subdomain;
    }
    const response = await getAccountIdBySubdomain({
      variables: {
        subdomain: currentSubdomain,
      },
    });
    return response.data.accountSubdomainMaps[0].accountToSubdomain
      .supportEmail;
  };

  const getEmailTemplateById = async (id?: number | string) => {
    if (!id) {
      return {} as IEmailTemplateData;
    }
    const response = await getTemplateById(
      ContentTypes.emails.path,
      id.toString()
    );
    const template = getFormattedSingleEmailTemplate(response);
    return template;
  };

  const getMessageData = async (messageUuid: string) => {
    if (!messageUuid) {
      return {} as IMessageData;
    }
    const response = await getMessageDataByMessageUuid({
      variables: {
        messageUuid: messageUuid,
      },
    });
    if (response.data?.messages && response.data.messages.length > 0) {
      return response.data.messages[0];
    }
    return {} as IMessageData;
  };

  const getCCAndBCCForReplyAll = async (data: {messageData: IMessageData}) => {
    const {bcc, cc} = getEmailDataFromContentAttributes(
      data.messageData.contentAttributes || {}
    );
    const allEmails = Array.from(new Set([...cc, ...bcc]));
    const ccUsers: IUserSearchResponse[] = [];
    const bccUsers: IUserSearchResponse[] = [];
    const ccRecipients: IEmailRecipient[] = [];
    const bccRecipients: IEmailRecipient[] = [];
    if (allEmails.length === 0 || !props.isReplyAll) {
      return {
        ccUsers,
        bccUsers,
        ccRecipients,
        bccRecipients,
      };
    }
    const response = await getUsersAndContactsByCondition({
      variables: {
        contactWhere: {
          isActive: {_eq: true},
          _and: [{email: {_in: allEmails}}],
          isDeleted: {_eq: false},
          ...(isConsentRequired
            ? {
                contactConsents: {
                  isDeleted: {
                    _eq: false,
                  },
                },
              }
            : {}),
          contactType: {
            typeId: {
              _neq: patientContactTypeId,
            },
          },
        },
        userWhere: {
          accountUsers: {isActive: {_eq: true}},
          email: {_in: allEmails},
          isDeleted: {_eq: false},
          _not: {
            userRoles: {
              userRole: {
                userRole: {code: {_in: ['WORKFLOW', 'CUSTOMER_SUCCESS']}},
              },
            },
          },
        },
      },
    });
    const allUsers = [
      ...(response.data?.users || []),
      ...(response.data?.contacts || []),
    ];

    allUsers.forEach((item) => {
      if (cc.includes(item.email)) {
        ccUsers.push(item);
        ccRecipients.push({
          email: item.email,
          id: item.id,
          name: item.name,
          uuid: item.uuid,
        });
      }
      if (bcc.includes(item.email)) {
        bccUsers.push(item);
        bccRecipients.push({
          email: item.email,
          id: item.id,
          name: item.name,
          uuid: item.uuid,
        });
      }
    });
    return {
      ccUsers,
      bccUsers,
      ccRecipients,
      bccRecipients,
    };
  };

  // onMount Helper functions
  const getSelectedEmailInbox = (result: IEmailInbox[], inboxId?: number) => {
    const selectedEmailInbox = result.find((item) => item.id === inboxId);
    return selectedEmailInbox || ({} as IEmailInbox);
  };

  const getMessageContent = (data: {
    messageData?: IMessageData;
    selectedEmailInbox?: IEmailInbox;
  }) => {
    const {messageData, selectedEmailInbox} = data;
    let messageContent: string | undefined = '';
    if (selectedEmailInbox?.id && messageData?.id) {
      messageContent = getExistingEmailMessageContent({
        inboxAddress: selectedEmailInbox?.email || '',
        inboxName: selectedEmailInbox?.name || '',
        messageData: messageData,
      });
    }
    return messageContent;
  };

  const getInitialSendEmailOption = (data: {
    selectedTemplateId?: string | number;
    templateCategoryCode?: string;
  }) => {
    const {selectedTemplateId, templateCategoryCode} = data;
    return selectedTemplateId || templateCategoryCode || props.html
      ? SEND_EMAIL_OPTIONS.TEMPLATE
      : SEND_EMAIL_OPTIONS.RAW_INPUT;
  };

  const getSelectedTemplateCategory = (data: {
    selectedTemplate: IEmailTemplateData;
    templateCategories: ITemplateCategory[];
    templateCategoryCode: string;
  }) => {
    const {selectedTemplate, templateCategories, templateCategoryCode} = data;
    let selectedTemplateCategory: ITemplateCategory | undefined;
    if (selectedTemplate.id && selectedTemplate.templateCategoryCode) {
      selectedTemplateCategory = templateCategories.find(
        (item) => item.code === selectedTemplate.templateCategoryCode
      );
    }
    if (templateCategoryCode) {
      selectedTemplateCategory = templateCategories.find(
        (item) => item.code === templateCategoryCode
      );
    }
    return selectedTemplateCategory;
  };

  const filterRestrictedTemplateCategories = (templateCategories: ITemplateCategory[]) => {
    const restrictedTemplates: string[] = [];

    if(isEnabledMultiTenancy && isActionsDisabled){
      restrictedTemplates.push(TEMPLATES_CONST.MULTIPLE_PATIENT_FORMS);
      restrictedTemplates.push(TEMPLATES_CONST.PATIENT_FORM);
    }

    const filteredCategories = templateCategories.filter((item) => {
      return !restrictedTemplates.includes(item.code);
    })

    return filteredCategories;
  }

  const getInitialTemplateCategories = (data: {
    templateCategories: ITemplateCategory[];
    templateId?: string | number;
  }) => {
    const {templateCategories} = data;
    if (props.templateId) {
      return templateCategories;
    }
    const filteredRestrictedCategories = filterRestrictedTemplateCategories(templateCategories);
    const filteredCategories = filteredRestrictedCategories
      .filter((item) => ALLOWED_TEMPLATES.includes(item.code))
      .filter((item) => {
        return item.code !== TEMPLATES_CONST.PATIENT_FORM;
      });
    if (!isJourneyPackagesEnabledForAccount) {
      return filteredCategories.filter(
        (item) => item.code !== TEMPLATES_CONST.SHARE_PACKAGES
      );
    }
    return filteredCategories;
  };

  const getExistingDraft = async (): Promise<Partial<IAllDraftBody>> => {
    const draftType = getDraftType();
    const conversationUuidForDraft = getConversationUuidForDraft();
    const existingDraftData = await getDraft({
      conversationUuid: conversationUuidForDraft,
      draftType: draftType,
      abortSignal: abortControllerRef?.current?.signal
    });
    return existingDraftData || {};
  };

  const getConversationUuidForDraft = () => {
    return props.conversationUuid || NEW_DRAFT_CONVERSATION_UUID;
  };

  const onActionPerformed = (actionCode: string, actionData?: IEmailSignature) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_POPUP:
        setState((prev) => {
          return {
            ...prev,
            isSignaturePopoverOpen: !prev?.isSignaturePopoverOpen
          }
        })
        return;
      case COMMON_ACTION_CODES.ITEM_SELECT:
        if (actionData?.id) {
          setUserSignature(actionData)
        }
        return;
      default:
        return;
    }
  }

  const getDraftType = (): IMessageDraftTypes => {
    if (props.conversationUuid && props.replyToMessageId && props.isReplyAll) {
      return MESSAGE_DRAFT_TYPES.REPLY_ALL_DRAFT;
    }
    if (props.conversationUuid && props.replyToMessageId) {
      return MESSAGE_DRAFT_TYPES.REPLY_DRAFT;
    }
    if (props.conversationUuid && props.forwardMessageId) {
      return MESSAGE_DRAFT_TYPES.FORWARD_DRAFT;
    }
    return MESSAGE_DRAFT_TYPES.NEW_DRAFT;
  };

  const debouncedSaveEmailDraft = useRef(
    debounce((html) => {
      saveEmailDraft({ content: html });
    }, DRAFT_INTERVAL)
  ).current;

  const saveContentInDraft = () => {
    const html = getEmailHtml();
    setState((prev) => {
      return {
        ...prev,
        editorText: html,
      };
    });

    setIsShowDiscardAlert(true);
    debouncedSaveEmailDraft(html);
  };

  const saveEmailDraft = async (data: Partial<IAllDraftBody>) => {
    const draftType = getDraftType();
    const conversationUuidForDraft = getConversationUuidForDraft();
    const isNewDraft = conversationUuidForDraft === NEW_DRAFT_CONVERSATION_UUID;
    const draftData = {
      ...existingDraftDataRef.current,
      ...data,
    };
    existingDraftDataRef.current = draftData;
    await saveDraft({
      conversationUuid: conversationUuidForDraft,
      draftType: draftType,
      draftData: isNewDraft
        ? {
            content: data.content,
          }
        : draftData,
    });
  };

  const deleteEmailDraft = async () => {
    const draftType = getDraftType();
    const conversationUuidForDraft = getConversationUuidForDraft();
    const response = await deleteDraft({
      conversationUuid: conversationUuidForDraft,
      draftType: draftType,
    });
    return response;
  };

  const onMount = async () => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const existingDraftData = await getExistingDraft();
    const contactIds =
      existingDraftData.to && existingDraftData.to.length > 0
        ? existingDraftData.to.map((item) => item.id)
        : props.contactIds || [];
    const templateId = existingDraftData.templateId || props.templateId;
    const content = existingDraftData.content || '';
    const templateCategoryCode =
      existingDraftData.templateCategoryCode || props.templateCategoryCode;
    const subjectFromDraft = existingDraftData.subject || '';
    const cc = existingDraftData.cc || [];
    const bcc = existingDraftData.bcc || [];
    const selectedForms =
      existingDraftData.selectedForms || props.selectedForms || [];

    const commonResponse = await Promise.all([
      getSupportEmail(),
      getEmailInboxes(),
      getContactsByIds(contactIds || []),
      getMessageData(
        content && content.length > 0
          ? ''
          : props.replyToMessageId || props.forwardMessageId || ''
      ),
      getTemplateCategories(),
      getEmailTemplateById(templateId),
      getPlaceholderMembershipLink(
        props.membershipData?.productId,
        props.membershipData?.plan
      ),
    ]);
    const supportEmail = commonResponse[0];
    const emailInboxes = commonResponse[1];
    const contacts = commonResponse[2];
    const messageData = commonResponse[3];
    const templateCategoriesResponse = commonResponse[4];
    const selectedTemplate = commonResponse[5];
    const placeholderMembershipLink = commonResponse[6];
    const templateCategoryList = getTemplateCategoryList(
      templateCategoriesResponse
    );
    const result = getFormattedEmailInboxes({emailInboxes, supportEmail});
    const selectedEmailInbox = getSelectedEmailInbox(result, props.inboxId);
    const ccAndBccData = await getCCAndBCCForReplyAll({messageData});
    const {bccRecipients, bccUsers, ccRecipients, ccUsers} = ccAndBccData;
    const messageContent = getMessageContent({messageData, selectedEmailInbox});
    const selectedTemplateCategory = getSelectedTemplateCategory({
      selectedTemplate,
      templateCategories: templateCategoryList,
      templateCategoryCode: templateCategoryCode || '',
    });
    const subject =
      subjectFromDraft ||
      selectedTemplate?.templateData?.subject ||
      getSubject() ||
      '';
    const sendEmailOptions = getInitialSendEmailOption({
      selectedTemplateId: selectedTemplate.id,
      templateCategoryCode: templateCategoryCode,
    });
    const templateCategories = getInitialTemplateCategories({
      templateCategories: templateCategoryList,
      templateId: templateId,
    });

    setCc(cc.length > 0 ? cc : ccRecipients);
    setBcc(bcc.length > 0 ? bcc : bccRecipients);
    setTo(contacts);
    setIsCcBccDisabled(isEnabledMultiTenancy && contacts.length === 0)
    if(isEnabledMultiTenancy && contacts.length > 0){
      setSelectedLocationId(contacts?.[0]?.contactPracticeLocations?.[0]?.practiceLocationUuid)
    }
    setSelectedToRecipient(contacts);
    existingDraftDataRef.current = existingDraftData;
    setState((prev) => {
      return {
        ...prev,
        emailInboxes: result,
        isLoading: false,
        selectedEmailInbox: selectedEmailInbox || ({} as IEmailInbox),
        subject: subject,
        contacts: contacts,
        ccUsers: cc.length > 0 ? cc : ccUsers,
        bccUsers: bcc.length > 0 ? bcc : bccUsers,
        showCc: cc.length > 0 || ccUsers.length > 0 ? true : false,
        showBcc: bcc.length > 0 || bccUsers.length > 0 ? true : false,
        selectedTemplate: selectedTemplate,
        sendEmailOptions: sendEmailOptions,
        templateCategories: templateCategories,
        selectedTemplateCategory:
          selectedTemplateCategory || ({} as ITemplateCategory),
        selectedForms: selectedForms,
      };
    });
    if (
      (messageContent && messageContent.length > 0) ||
      (content && content.length > 0) ||
      (placeholderMembershipLink && placeholderMembershipLink.length > 0)
    ) {
      setMessageContentInEditor(
        placeholderMembershipLink || messageContent || content
      );
      if (editorRef?.current?.editor) {
        editorRef?.current?.editor?.click();
        editorRef?.current?.editor?.focus();
      }

    }
    getExistingAttachments(props.forwardMessageId);
    if (props.documentList && props.documentList.length > 0) {
      getDocuments(props.documentList);
    }
  };

  const getContactsByIds = async (contactIds: number[]) => {
    const response = await getContactByCondition({
      variables: {
        where: {
          id: {
            _in: contactIds,
          },
          // email: {_is_null: false},
          _and: [
            {
              email: {
                _is_null: false,
              },
            },
            {
              email: {
                _neq: '',
              },
            },
          ],
          ...(isConsentRequired
            ? {
                contactConsents: {
                  isDeleted: {
                    _eq: false,
                  },
                },
              }
            : {}),
        },
        limit: 100,
      },
    });
    return response.data?.contacts || [];
  };

  const handleOnNewContactAdd = async (contactId: string) => {
    setState((prev) => {
      return {
        ...prev,
        isAddOrUpdateLeadDrawerOpen: false,
      };
    });
    if (!contactId) {
      return;
    }
    const existingContactIds = to.map((item) => item.id);
    const allContactIds = [...existingContactIds, Number(contactId)];
    const contacts = await getContactsByIds(allContactIds);
    const contactUuids = contacts.map((item) => item.uuid);
    setTo(contacts);
    if (toSearchRecipientRef.current) {
      toSearchRecipientRef.current?.refetchToRecipients(contactUuids);
    }
    setState((prev) => {
      return {
        ...prev,
        isAddOrUpdateLeadDrawerOpen: false,
      };
    });
  };

  // mutations
  const getFormLink = async (data: {
    contactUuid: string;
    formId: string;
  }): Promise<string> => {
    let contactActiveProfileIds = undefined;
    try{
      if(isMultiTenancyEnabled){
        contactActiveProfileIds = getPatientActiveProfileIds(to?.[0]?.contactProfiles || []);
      }
    } catch (error){
    }
    const params = {
      contactId: data.contactUuid,
      ...(!!isMultiTenancyEnabled && !!contactActiveProfileIds && {contactProfileId: contactActiveProfileIds?.[0]}),
      formId: data.formId,
      sourceId: formSourceId,
      requestedByUserId: userUuid,
      subjectId: v4(),
    };
    const response = await createFormLogLink({
      variables: {
        params,
      },
    });
    return response.data?.createFormLogLink?.formLink;
  };

  // utilities
  const getSubject = () => {
    if (
      props.forwardMessageId &&
      props.subject &&
      props.subject.trim().toLowerCase().indexOf('fw') !== 0
    ) {
      return `Fw: ${props.subject}`;
    }
    return props.subject || '';
  };

  const insertTextAtCursorPosition = (text: string) => {
    if (!editorRef?.current) {
      return '';
    }

    const editorInstance = Jodit?.instances?.[editorRef?.current?.id];
    editorInstance?.selection && editorInstance?.selection?.insertHTML(text);
  };
  const resetEditorContent = () => {
    if (editorRef.current) {
      const editorInstance = Jodit?.instances?.[editorRef.current.id];
      if (editorInstance) {
        editorInstance.value = '';
        editorInstance.selection.clear();
      }
    }
  }

  const onSelectSuggestion = (text: string) => {
    if (!editorRef?.current) {
      return;
    }
    const finalText = text?.replace(/&lt;/g, '<')?.replace(/&gt;/g, '>');
    insertTextAtCursorPosition(finalText)
  };

  const setEditorHeight = (height: number) => {
    const toolbarHeight = editorRef?.current?.parentNode?.children[0]?.children[0]?.getBoundingClientRect()?.height || 0;
    setState(prev => {
      return {
        ...prev,
        editorHeight: height - toolbarHeight
      }
    })
  };

  const getPlainText = () => {
    let plainText = '';
    if (editorRef?.current) {
      const editorInstance = Jodit?.instances?.[editorRef?.current?.id];
      if (editorInstance) {
        const htmlContent = editorInstance?.value;
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent?.replaceAll('<br>', '\n');
        plainText = tempElement?.textContent || tempElement?.innerText || '';
      }
    }
    return plainText;
  };

  const getEmailHtml = () => {
    if (!editorRef?.current) {
      return '';
    }
    try {
      const value = editorRef?.current?.value;
      return value || '';
    } catch (e) {
      return '';
    }
  };

  const handleFormSelect = (form: Pick<IForm, 'id' | 'name'>) => {
    const tempForUrl = getPlaceholderUrl('form', form.id);
    insertTextAtCursorPosition(getLinkText(form.name, tempForUrl));
    setState((prev) => {
      return {
        ...prev,
        isFormDrawerOpen: false,
      };
    });
  };

  const handleArticleSelect = async (link: string) => {
    const shortLink = await getShortLink(link);
    insertTextAtCursorPosition(getLinkText(shortLink, shortLink));
    setState((prev) => {
      return {
        ...prev,
        isArticleDrawerOpen: false,
      };
    });
  };

  const handleAppointmentMessage = (message: string) => {
    insertTextAtCursorPosition(getLinkText('Appointment', message));
    setState((prev) => {
      return {
        ...prev,
        isAppointmentLinkDrawerOpen: false,
      };
    });
  };

  const handleAttachMedia = async (mediaData: IMediaLibraryData) => {
    if (!mediaData.url) {
      return;
    }
    const shortLink = await getShortLink(mediaData.url);
    insertTextAtCursorPosition(getLinkText(shortLink, shortLink));
    setState((prev) => {
      return {
        ...prev,
        isAttachMediaDrawerOpen: false,
      };
    });
  };

  const setIsShowDiscardAlert = (value: boolean) => {
    isShowDiscardAlertRef.current = value;
  };

  const handleOnClose = () => {
    if (isShowDiscardAlertRef.current) {
      setState((prev) => {
        return {
          ...prev,
          isDiscardAlertOpen: true,
        };
      });
      return;
    }
    props.onClose();
  };

  const handleDiscard = async () => {
    setState((prev) => {
      return {
        ...prev,
        isDiscardLoading: true,
      };
    });
    await deleteEmailDraft();
    props.onClose();
  };

  const handleSaveAsDraft = async () => {
    setState((prev) => {
      return {
        ...prev,
        isSaveAsDraftLoading: true,
      };
    });
    await saveEmailDraft({subject: state.subject, content: getEmailHtml()});
    props.onClose();
  };

  const getFormIdsAndFormLinkMap = async (
    contactUuid: string,
    formIds: string[]
  ) => {
    const formIdsToLinkMap: Record<string, string> = {};
    if (formIds.length === 0) {
      return formIdsToLinkMap;
    }
    for (const formId of formIds) {
      const formLink = await getFormLink({
        contactUuid: contactUuid,
        formId: formId,
      });
      formIdsToLinkMap[formId] = formLink;
    }
    return formIdsToLinkMap;
  };

  const getExistingAttachments = async (messageUuid?: string) => {
    if (!messageUuid) {
      return [];
    }
    setState((prev) => {
      return {
        ...prev,
        isAttachmentLoading: true,
      };
    });
    const response = await getEmailAttachments({
      messageUuid: messageUuid,
    });
    const promiseList = response.map(async (item) => {
      const downloaded = await downloadEmailAttachments({
        attachmentId: item.crmAttachments.id,
      });
      const imageSrc = URL.createObjectURL(downloaded.data);
      const file = new File([downloaded.data], item.crmAttachments.name, {
        lastModified: new Date().getTime(),
        type: item.crmAttachments.type,
      });
      const result: UploadFile = {
        name: item.crmAttachments.name,
        uid: item.crmAttachments.id,
        thumbUrl: imageSrc,
        originFileObj: file as any,
        size: item.size,
        percent: 0,
      };
      return result;
    });
    const downloadedFileList = await Promise.all(promiseList);
    setFileList(downloadedFileList);
    setState((prev) => {
      return {
        ...prev,
        isAttachmentLoading: false,
      };
    });
  };

  const getDocuments = async (documentData: IDocumentData[]) => {
    setState((prev) => {
      return {
        ...prev,
        isAttachmentLoading: true,
      };
    });
    let isShowMaxSizeAlert = false;
    const downloadedDocumentList: UploadFile[] = [];
    for (const item of documentData) {
      const document = await downloadDocument(item.id);
      const src = URL.createObjectURL(document);
      const file = new File([document], item.name, {
        lastModified: new Date().getTime(),
        type: item.type,
      });
      if (file.size > MAX_ATTACHMENT_UPLOAD_SIZE) {
        isShowMaxSizeAlert = true;
      } else {
        const result: UploadFile = {
          name: item.name,
          uid: v4(),
          thumbUrl: item.type.includes('image') ? src : undefined,
          originFileObj: file as any,
          size: file.size,
          percent: 0,
          type: item.type,
        };
        downloadedDocumentList.push(result);
      }
    }
    if (isShowMaxSizeAlert) {
      showMaxAttachmentSizeAlert();
    }
    setFileList(downloadedDocumentList);
    setState((prev) => {
      return {
        ...prev,
        isAttachmentLoading: false,
      };
    });
  };

  const getPreviewHtml = () => {
    const engine = new Liquid();
    const rawInputHtml = getEmailHtml();
    const mergeTags: Record<string, any> = {};
    mergeTags.global = accountMergeTags;
    const formList = state.selectedForms.map((item) => ({
      name: item.name,
      link: '',
    }));
    if (formList.length > 0) {
      mergeTags.formList = formList;
    }
    const packages = (state.selectedPackages || []).map((item) => {
      return {
        name: item.name,
        link: '',
      };
    });
    if (packages.length > 0) {
      mergeTags.packages = packages;
    }
    if (rawInputHtml) {
      mergeTags.general = {text: rawInputHtml};
    }
    mergeTags.patient = {
      name: 'John Doe',
      firstName: 'John',
      lastName: 'Doe',
    };
    const tpl = engine.parse(
      state.selectedTemplate?.templateHtml || props.html || ''
    );
    const result = engine.renderSync(tpl, mergeTags);
    return result;
  };

  const getPreviewSubjectHtml = (selectedTemplate: IEmailTemplateData, subject: string) => {
    if (!selectedTemplate?.templateData) {
      return subject || '';
    }
    const finalMergeTag = {
      ...selectedTemplate?.templateData?.category?.data?.attributes?.mergeTags,
      global: accountMergeTags,
    };
    try {
      const engine = new Liquid();
      const tpl = engine.parse(subject);
      return engine.renderSync(tpl, finalMergeTag);
    } catch (error) {
      return subject;
    }
  };

  const getFormListWithNameAndLink = async (data: {contactUuid: string}) => {
    const forms = state.selectedForms;
    if (forms.length === 0) {
      return [];
    }
    const formList: {
      name: string;
      link: string;
    }[] = [];
    for (const form of forms) {
      const formLink = await getFormLink({
        contactUuid: data.contactUuid,
        formId: form.id,
      });
      formList.push({name: form.name, link: formLink});
    }
    return formList;
  };

  const getHtmlFromTemplate = async (data: {recipient: IEmailRecipient}) => {
    const engine = new Liquid();
    const selectedCategoryCode = state.selectedTemplate?.templateCategoryCode;
    const mergeTags: Record<string, any> = {};
    mergeTags.global = accountMergeTags;
    const customAttributeValues = await getContactCustomAttributeMergeTagValues(
      data.recipient.uuid
    );
    mergeTags.customAttribute = customAttributeValues;
    if (selectedCategoryCode === TEMPLATES_CONST.GENERAL) {
      const rawInputHtml = await getHtmlFromRawText({
        recipient: data.recipient,
      });
      mergeTags.general = {text: rawInputHtml};
    }
    if (selectedCategoryCode === TEMPLATES_CONST.MULTIPLE_PATIENT_FORMS) {
      const formList = await getFormListWithNameAndLink({
        contactUuid: data.recipient.uuid,
      });
      mergeTags.formList = formList;
    }
    if (selectedCategoryCode === TEMPLATES_CONST.SHARE_PACKAGES) {
      const promiseList = state.selectedPackages.map((item) =>
        createCopyLinkPackageApi(item.id)
      );
      const packages = await Promise.all(promiseList);
      mergeTags.packages = packages;
    }
    mergeTags.patient = {
      name: data.recipient.name,
      firstName: data.recipient.name,
    };
    const tpl = engine.parse(
      state.selectedTemplate?.templateHtml || props.html || ''
    );
    const result = engine.renderSync(tpl, mergeTags);
    return result;
  };

  const getFormLinkReplacedHtml = async (html: string, contactUuid: string) => {
    let currentHtml = html;
    const formIds = extractFormIds(currentHtml);
    const formIdsToLinkMap = await getFormIdsAndFormLinkMap(
      contactUuid,
      formIds
    );
    Object.keys(formIdsToLinkMap).forEach((item) => {
      const link = formIdsToLinkMap[item];
      const tempForUrl = getPlaceholderUrl('form', item);
      const formRegex = new RegExp(tempForUrl, 'g');
      currentHtml = currentHtml.replace(formRegex, link);
    });
    return currentHtml;
  };

  const getHtmlFromRawText = async (data: {recipient: IEmailRecipient}) => {
    const contactUUID = data.recipient.uuid;
    let contactActiveProfileIds = undefined;
    let contactActiveLocationIds = undefined;
    try{
      if(contactUUID && isMultiTenancyEnabled){
        contactActiveProfileIds = getPatientActiveProfileIds(data?.recipient?.contactProfiles || []);
        contactActiveLocationIds = getPatientActiveProfileLocationIds(data?.recipient?.contactProfiles || []);
      }
    } catch (error){
    }
    let currentHtml = getEmailHtml();
    currentHtml = await getFormLinkReplacedHtml(
      currentHtml,
      data.recipient.uuid
    );
    currentHtml = getAppointmentBookingLinkReplaceHtml({
      html: currentHtml,
      contactUuid: data.recipient.uuid,
      ...(isMultiTenancyEnabled && {contactProfileId: contactActiveProfileIds?.[0]}),
      accountUuid: accountUuid,
      locationId: isMultiTenancyEnabled ? contactActiveLocationIds?.[0] : undefined,
    });
    currentHtml = await getMembershipLinkReplacedHtml({
      html: currentHtml,
      contactUuid: data.recipient.uuid,
      accountId: accountId,
      accountUuid: accountUuid,
    });
    return currentHtml;
  };

  const getFinalHtml = async (data: {recipient: IEmailRecipient}) => {
    // if the template is selected, then resolve merge tag and then return resolved html
    if (
      (state.selectedTemplate?.id || props.html) &&
      state.sendEmailOptions === SEND_EMAIL_OPTIONS.TEMPLATE
    ) {
      return getHtmlFromTemplate({recipient: data.recipient});
    }
    // if the template is not selected
    return getHtmlFromRawText({recipient: data.recipient});
  };

  const setMessageContentInEditor = (messageContent: string) => {
    setState((prev) => {
      return {
        ...prev,
        editorContent: messageContent
      }
    })
  };

  const isEmailConversationExist = props.replyToMessageId || props.isReplyAll || props.forwardMessageId;

  const getTitleText = () => {
    if (props.replyToMessageId && props.isReplyAll) {
      return intl.formatMessage({id: 'replyAll'});
    }
    if (props.replyToMessageId) {
      return intl.formatMessage({id: 'reply'});
    }
    if (props.forwardMessageId) {
      return intl.formatMessage({id: 'forward'});
    }
    return intl.formatMessage({id: 'composeEmail'});
  };

  const showMaxAttachmentSizeAlert = () => {
    notification.destroy();
    notification.warn({
      message:
        'You can add attachments totaling 34MB, with each attachment limited to 3MB',
    });
  };

  // sender functions
  const sendEmail = async () => {
    const {inputErrors, isValid} = validateForm({
      inboxId: state.selectedEmailInbox.id,
      subject: state.subject,
      to: to,
      sendEmailOption: state.sendEmailOptions,
      templateId: props.html ? -1 : Number(state.selectedTemplate?.id) || 0,
    });
    setErrors(inputErrors);
    if (!isValid) {
      return;
    }
    setState((prev) => {
      return {
        ...prev,
        isSending: true,
      };
    });
    isSendingEmailRef.current = true;
    const emailInboxType = state.selectedEmailInbox.type;
    deleteEmailDraft();
    switch (emailInboxType) {
      case 'OUTLOOK':
        sendOutlookEmail();
        break;
      case 'GMAIL':
      case 'MAILGUN_DEFAULT_INBOX':
        sendGmailEmail();
        break;
      case 'DEFAULT':
        sendDefaultEmail();
        break;
    }
  };

  const sendOutlookEmail = async () => {
    if (props.replyToMessageId) {
      handleReplyEmail();
      return;
    }

    if (props.forwardMessageId) {
      handleForwardEmail();
      return;
    }

    // create new email conversation
    try {
      const attachmentIds = await uploadAttachments();
      const response = await handleSendNewEmailInBulk({
        to: to,
        result: [],
        attachmentIds: attachmentIds,
      });
      if (to.length !== 1) {
        successHandler('Email Sent');
        return;
      }
      const first = response[0];
      props.onEmailSent({msgText: first.content || '', msgData: first});
      successHandler('Email Sent');
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSendNewEmailInBulk = async (data: {
    to: IEmailRecipient[];
    result: any[];
    attachmentIds: string[];
  }) => {
    const {attachmentIds, result, to} = data;
    if (to.length === 0) {
      return result;
    }
    const {currentList, pendingList} = getCurrentAndPendingList(to);
    const promiseList = currentList.map((item) =>
      sendNewSingleEmail({recipient: item, attachmentIds})
    );
    const responses = await Promise.all(promiseList);
    result.push(...responses);
    await sleep(SEND_BULK_EMAIL_DELAY);
    await handleSendNewEmailInBulk({to: pendingList, result, attachmentIds});
    return result;
  };

  const handleReplyEmail = async () => {
    const conversationId = props.conversationId;
    const inboxId = props.inboxId;
    const replyToMessageId = props.replyToMessageId;
    if (!conversationId || !inboxId || !replyToMessageId) {
      return;
    }
    const promiseList = to.map((item) => {
      return replySingleEmail({
        recipient: item,
        conversationId: conversationId,
        inboxId: inboxId,
        replyToMessageId: replyToMessageId,
      });
    });
    try {
      const response = await Promise.all(promiseList);
      if (to.length !== 1) {
        successHandler('Email Sent');
        return;
      }
      const first = response[0];
      props.onEmailSent({msgData: first, msgText: first.content});
      successHandler('Email Sent');
    } catch (error) {
      errorHandler(error);
    }
  };

  const replySingleEmail = async (data: {
    recipient: IEmailRecipient;
    conversationId: number;
    inboxId: number;
    replyToMessageId: string;
  }) => {
    const content = await getFinalHtml({recipient: data.recipient});
    const attachmentIds = await uploadAttachments();
    const response = await replyEmail({
      bcc: bcc.map((item) => item.email),
      cc: cc.map((item) => item.email),
      contactId: data.recipient.id,
      content: content,
      conversationId: data.conversationId,
      echo_id: getMsgEchoId(),
      inboxId: data.inboxId,
      isCreateNewConversation: false,
      private: false,
      replyToMessageId: data.replyToMessageId,
      subject: state.subject,
      user_mention_ids: '',
      attachmentIds: attachmentIds,
    });
    return response;
  };

  const sendNewSingleEmail = async (data: {
    recipient: IEmailRecipient;
    attachmentIds: string[];
  }) => {
    const {recipient, attachmentIds} = data;
    const echoId = getMsgEchoId();
    const content = await getFinalHtml({recipient: recipient});
    const requestBody: ISendOutlookEmailBody = {
      cc: cc.map((item) => item.email),
      bcc: bcc.map((item) => item.email),
      contactId: recipient.id, // null or undefined for internal message
      content: content,
      echo_id: echoId,
      inboxId: state.selectedEmailInbox.id,
      isCreateNewConversation: props.inboxId ? false : true, // true for internal message and same conversation reply and reply all
      private: false, // true for internal message
      subject: state.subject,
      user_mention_ids: '',
      attachmentIds: attachmentIds,
    };
    return sendOutlookEmailAPI(requestBody);
  };

  const handleForwardEmail = async () => {
    const forwardMessageId = props.forwardMessageId;
    if (!forwardMessageId) {
      return;
    }
    const existingAttachmentIds = await getExistingAttachmentIdsFromMessage({
      messageUuid: forwardMessageId,
    });
    const attachmentIds = await uploadAttachments();
    try {
      await handleForwardEmailInBulk({
        to: to,
        result: [],
        attachmentIds: [...attachmentIds, ...existingAttachmentIds],
      });
      successHandler('Email Sent');
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleForwardEmailInBulk = async (data: {
    to: IEmailRecipient[];
    result: any[];
    attachmentIds: string[];
  }) => {
    const {attachmentIds, result, to} = data;
    const conversationId = props.conversationId;
    const inboxId = props.inboxId;
    const forwardMessageId = props.forwardMessageId;
    if (!conversationId || !inboxId || !forwardMessageId || to.length === 0) {
      return result;
    }
    const {currentList, pendingList} = getCurrentAndPendingList(to);
    const promiseList = currentList.map((item) =>
      forwardSingleEmail({
        recipient: item,
        conversationId: conversationId,
        inboxId: inboxId,
        forwardMessageId: forwardMessageId,
        attachmentIds: attachmentIds,
      })
    );
    const responses = await Promise.all(promiseList);
    result.push(...responses);
    await sleep(SEND_BULK_EMAIL_DELAY);
    await handleForwardEmailInBulk({
      to: pendingList,
      result,
      attachmentIds,
    });
    return result;
  };

  const forwardSingleEmail = async (data: {
    recipient: IEmailRecipient;
    conversationId: number;
    inboxId: number;
    forwardMessageId: string;
    attachmentIds: string[];
  }) => {
    const content = await getFinalHtml({recipient: data.recipient});
    const response = await forwardEmail({
      bcc: bcc.map((item) => item.email),
      cc: cc.map((item) => item.email),
      contactId: data.recipient.id,
      content: content,
      conversationId: data.conversationId,
      echo_id: getMsgEchoId(),
      forwardMessageId: data.forwardMessageId,
      inboxId: data.inboxId,
      isCreateNewConversation: true,
      private: false,
      subject: state.subject,
      user_mention_ids: '',
      attachmentIds: [...data.attachmentIds],
    });
    return response;
  };

  const sendGmailEmail = async () => {
    const attachmentIds = await uploadAttachments();
    const promiseList = to.map((item) => {
      return sendSingleGmailEmail({recipient: item, attachmentIds: attachmentIds});
    });
    try {
      const response = await Promise.all(promiseList);
      if (to.length !== 1) {
        successHandler('Email Sent');
        return;
      }
      const responseMsg = response[0];
      responseMsg.data['sender_type'] = 'user';
      const content = responseMsg.data.content;
      const messageRepData = messageDataKeysConverter(responseMsg.data);
      const currentTime = getDateToMomentISOString();
      const responseData = {...messageRepData, currentTime};
      props.onEmailSent({msgData: responseData, msgText: content});
      successHandler('Email Sent');
    } catch (error) {
      errorHandler(error);
    }
  };

  const sendSingleGmailEmail = async (data: {recipient: IEmailRecipient, attachmentIds: string[]}) => {
    let conversationDisplayId = props.conversationDisplayId;
    const inboxId = state.selectedEmailInbox.id;
    if (!conversationDisplayId) {
      // create new conversation
      const emailConversation = await createEmailConversation({
        additionalAttributes: {
          mail_subject: state.subject,
        },
        subject: state.subject,
        contactId: data.recipient.id,
        inboxId: inboxId,
      });
      conversationDisplayId = emailConversation?.displayId;
    }
    const content = await getFinalHtml({recipient: data.recipient});
    const responseMsg = await sendEmailMessageAPI({
      private: false,
      content: content,
      conversationDisplayId: conversationDisplayId as number,
      inboxId: inboxId,
      user_mention_ids: '',
      subject: state.subject,
      attachmentIds: data.attachmentIds,
    });
    return responseMsg;
  };

  const sendDefaultEmail = async () => {
    try {
      const promiseList = to.map((item) =>
        sendSingleDefaultEmail({recipient: item})
      );
      await Promise.all(promiseList);
      successHandler('Email sent');
    } catch (error) {
      errorHandler(error);
    }
  };

  const sendSingleDefaultEmail = async (data: {recipient: IEmailRecipient}) => {
    const html = await getFinalHtml({recipient: data.recipient});
    const formData = new FormData();
    const body: ICmsEmailBody = {
      accountId: accountUuid,
      bcc: bcc.map((item) => item.email),
      cc: cc.map((item) => item.email),
      data: {
        patient: {
          name: data.recipient.name,
          firstName: data.recipient.name,
        },
      },
      subject: state.subject,
      tags: [],
      toContactIds: [data.recipient.id],
      bodyHtml: html || '<p></p>',
      template: undefined,
      templateCategoryCode: undefined,
    };
    formData.append('data', JSON.stringify(body));
    fileList.forEach((item) => {
      if (item.originFileObj) {
        const a = item.originFileObj;
        formData.append('files', a);
      }
    });
    const response = await sendCMSEmailWithAttachment(formData);
    return response;
  };

  const uploadAttachments = async () => {
    const attachmentIds: string[] = [];
    if (fileList.length === 0) {
      return [];
    }
    setState((prev) => {
      return {
        ...prev,
        isUploadingAttachment: true,
      };
    });
    const promiseList = fileList.map((item) => uploadSingleAttachment(item));
    const response = await Promise.all(promiseList);
    response.forEach((item) => {
      if (item && item.id) {
        attachmentIds.push(item.id);
      }
    });
    setState((prev) => {
      return {
        ...prev,
        isUploadingAttachment: false,
      };
    });
    return attachmentIds;
  };

  const uploadSingleAttachment = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file.originFileObj as any);
    try {
      const response = await uploadEmailAttachment({formData: formData});
      return response;
    } catch (error) {}
  };

  const getExistingAttachmentIdsFromMessage = async (data: {
    messageUuid: string;
  }) => {
    const attachmentIds: string[] = [];
    return [];
    try {
      const response = await getEmailAttachments({
        messageUuid: data.messageUuid,
      });
      response.forEach((item) => {
        if (item && item.crmAttachments.id) {
          attachmentIds.push(item.crmAttachments.id);
        }
      });
      return attachmentIds;
    } catch (error) {
      return [];
    }
  };

  const setUserPreferenceDefault = (userPreferenceData: IUserPreferenceData) => {
    if (userPreferenceData?.data?.userPreferences?.length > 0 && userPreferenceData?.data?.userPreferences?.[0]?.preferencesJson) {
      const preferenceData = JSON.parse(userPreferenceData?.data?.userPreferences?.[0]?.preferencesJson);
      const userSignatures = preferenceData?.userSignature;
      const userSignaturesDefault = userSignatures?.find((signature: IEmailSignature) => {
        return signature?.isDefault
      })
      setState(prev => {
        return {
          ...prev,
          userSignaturesDefault: userSignaturesDefault,
          selectedSignaturePreference: userSignaturesDefault,
          userSignatures: userSignatures
        }
      })
      return userSignaturesDefault;
    }
  }

  const onHandlePatientChangeDiscard = ()=> {
    setState(prev=> {
      return {
        ...prev,
        showPatientChangeModal: false
      }
    })
  }
  const onHandlePatientChangeConfirm = () => {
    setState((prev) => ({
      ...prev,
      showPatientChangeModal: false
    }));

    if (isEnabledMultiTenancy) {
      resetEditorContent();
      handleSetRecipientAndSaveDraft(selectedToRecipient);
    }
  };
  const resetFormsIfEnabled = () => {
    if (isEnabledMultiTenancy && !props?.disabledFormSearch) {
      setState((prev) => ({
        ...prev,
        selectedForms: [],
      }));
    }
  };
  const handleSetRecipientAndSaveDraft = (recipient: IEmailRecipient[]) => {
    setTo(recipient);
    saveEmailDraft({ to: recipient });
    setIsShowDiscardAlert(true);
    resetFormsIfEnabled();
    setIsCcBccDisabled(isEnabledMultiTenancy && recipient?.length === 0);
    if (isEnabledMultiTenancy) {
      setSelectedLocationId(recipient?.[0]?.contactPracticeLocations?.[0]?.practiceLocationUuid);
      setCc([]);
      setBcc([]);
    }
  };
  const handleToRecipientChange = (selected: IEmailRecipient[]) => {
    if (isEnabledMultiTenancy && singleToLocationGroupId && state.editorText) {
      setState((prev) => ({
        ...prev,
        showPatientChangeModal: true
      }));
      setSelectedToRecipient(selected);
      return;
    }
    handleSetRecipientAndSaveDraft(selected);
  };
  const handleOpenSendAppointmentLinkDrawer = () => {
    if (isEnabledMultiTenancy && !singleToLocationGroupId) {
      customToast({
        toastType: ToastType.info,
        message:
          'Please Select Recipient To',
      });
      return
    }
    setState((prev) => {
      return {
        ...prev,
        isAppointmentLinkDrawerOpen: true,
      };
    });
  };
  // API response handlers
  const successHandler = (message: string) => {
    notification.destroy();
    notification.success({
      message: message,
    });
    props.onClose();
  };

  const errorHandler = (error: any) => {
    const message =
      error?.response?.data?.message ||
      'Something went wrong. Please try again later.';
    notification.destroy();
    notification.error({
      message: message,
    });
    setState((prev) => {
      return {
        ...prev,
        isSending: false,
      };
    });
  };

  const getUserPreferenceAndSetSignature = async () => {
    const userPreferenceData = await getUserPreference();
    const userSignaturesDefault = setUserPreferenceDefault(userPreferenceData);
    if (!isEmailConversationExist) {
      setUserSignature(userSignaturesDefault, true);
    }
  }

  const setUserSignature = (selectedSignature: IEmailSignature, firstTime?: boolean) => {
    if (selectedSignature?.id) {
      const editor = editorRef?.current;
      const currentContent = editor?.value ?? '';
      const signatureId = ID_FOR_EMAIL_SIGNATURE;
      const signature = `<div id="${signatureId}">${selectedSignature.bodyText}</div>`;
      const updatedContent = currentContent + signature;
      if (editor) {
        editor.value = updatedContent;
      }
    }

    // Update the state
    setState((prev) => ({
      ...prev,
      selectedSignaturePreference: selectedSignature,
      // isSignaturePopoverOpen: firstTime ? prev.isSignaturePopoverOpen : !prev.isSignaturePopoverOpen
    }));
  };

  const fetchData = async () => {
    await onMount();
    getUserPreferenceAndSetSignature();
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    setCcRecipientUuids(cc.map((item) => item.uuid));
  }, [cc]);
  React.useEffect(() => {
    setBccRecipientUuids(bcc.map((item) => item.uuid));
  }, [bcc]);

  React.useEffect(() => {
    if (isEnabledMultiTenancy) {
      const filteredCategories = filterRestrictedTemplateCategories(state.templateCategories);
      setState((prev) => {
        return {
          ...prev,
          templateCategories: filteredCategories,
        };
      });
    }
  }, [isActionsDisabled, state?.templateCategories?.length]);

  const getFormListBasedOnSelectedContact = useCallback((contacts: IEmailRecipient[]) => {
    if (!isEnabledMultiTenancy) {
      return undefined;
    }
    const formLocationList: Set<string> = new Set();
    contacts.forEach((recipient) =>
      recipient.contactPracticeLocations?.forEach((location) => {
        if (location.accountLocation?.uuid) {
          formLocationList.add(location.accountLocation?.uuid);
        }
      })
    );

    return Array.from(formLocationList);
  }, [isEnabledMultiTenancy]);

  const formLocationListBasedOnSelectedContact = useMemo(
    () => getFormListBasedOnSelectedContact(to),
    [to, isEnabledMultiTenancy]
  );

  const GetDivider = (props: {height?: number}) => {
    return (
      <View
        style={[styles.dividerStyle, {height: props.height || 16}]}
      />
    );
  };

  const isDisplayCannedResponses = () => {
    if (!editorRef?.current) {
      return false;
    }
    const text = getPlainText();
    return text.indexOf('/') === 0;
  };

  const getSearchStringForCannedResponse = () => {
    if (!editorRef?.current) {
      return '';
    }
    const text = getPlainText();
    if (text?.indexOf('\n') !== -1) {
      return text?.substring(0, text?.indexOf('\n'));
    }
    return text;
  };
  // useEffect(() => {
  //   if (isEnabledMultiTenancy && singleToLocationGroupId) {
  //     setState(prev=> {
  //       return {
  //         ...prev,
  //         showPatientChangeModal: !prev.showPatientChangeModal
  //       }
  //     })
  //   }
  // }, [singleToLocationGroupId, isEnabledMultiTenancy]);
  return (
    <Drawer
      destroyOnClose
      width={isSideCarContext ? '100vw' : '50vw'}
      mask={isSideCarContext ? false : true}
      title={
        <ModalActionTitle
          title={getTitleText()}
          titleColor={Colors.primary[400]}
          rightButton={
            <View style={styles.rightButtonContainer}>
              <FoldButton
                customProps={{
                  btnText: state.isUploadingAttachment
                    ? 'Uploading Attachments'
                    : state.isSending
                    ? 'Sending Email'
                    : 'Send',
                }}
                nativeProps={{
                  onPress() {
                    sendEmail();
                  },
                  isLoading: state.isSending,
                  backgroundColor: Colors.Custom.Primary300,
                  _text: {
                    fontSize: 14,
                    lineHeight: 16.8,
                    fontWeight: 'bold',
                  },
                }}
              />
              <GetDivider />
              <Pressable
                onPress={() => {
                  handleOnClose();
                }}
                disabled={state.isSending}
              >
                <AntIcon name="close" size={20} color={Colors.Custom.Gray500} {...testID(TestIdentifiers.closeBtn)} />
              </Pressable>
            </View>
          }
        />
      }
      open={props.isOpen}
    >
      {state.isLoading ? (
        <View style={styles.spinnerContainer}>
          <Spinner />
        </View>
      ) : (
        <View
          style={styles.contentContainer}
          pointerEvents={state.isSending ? 'none' : undefined}
        >
          <FormControl isRequired isInvalid={!!errors?.from}>
            <FormControl.Label>
              <Text style={styles.label}>
                {intl.formatMessage({id: 'from'})}
              </Text>
            </FormControl.Label>
            <Select
              style={reactStyles.select}
              placeholder="Select inbox"
              data-testid='selectInbox'
              status={!!errors.from ? 'error' : ''}
              value={state?.selectedEmailInbox?.id?.toString()}
              disabled={!!props.inboxId}
              onSelect={(id) => {
                const selectedEmailInbox = state.emailInboxes.find(
                  (item) => item.id.toString() == id
                );
                if (selectedEmailInbox && selectedEmailInbox.id) {
                  setState((prev) => {
                    return {
                      ...prev,
                      selectedEmailInbox,
                    };
                  });
                  setIsShowDiscardAlert(true);
                }
              }}
            >
              {state.emailInboxes.map((item) => {
                return (
                  <Select.Option
                    value={item.id.toString()}
                    key={item.id.toString()}
                  >
                    {`${item.name} (${item.email})`}
                  </Select.Option>
                );
              })}
            </Select>
            {errors.from ? (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {intl.formatMessage({id: errors.from})}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>

          <FormControl
            isRequired
            isInvalid={!!errors?.to}
            style={styles.formControl}
          >
            <View
              style={styles.modalBodyView}
            >
              <FormControl.Label>
                <Text style={styles.label}>
                  {intl.formatMessage({id: 'to'})}
                </Text>
              </FormControl.Label>
              <View
                style={styles.modalBodyView2}
              >
                {state.showCc ? (
                  <> </>
                ) : (
                  <Pressable
                    onPress={() => {
                      setState((prev) => {
                        return {
                          ...prev,
                          showCc: true,
                        };
                      });
                    }}
                  >
                    <Text
                      style={styles.text}
                    >
                      {intl.formatMessage({id: 'cc'})}
                    </Text>
                  </Pressable>
                )}

                {state.showBcc || state.showCc ? (
                  <> </>
                ) : (
                  <GetDivider height={15} />
                )}

                {state.showBcc ? (
                  <></>
                ) : (
                  <Pressable
                    onPress={() => {
                      setState((prev) => {
                        return {
                          ...prev,
                          showBcc: true,
                        };
                      });
                    }}
                  >
                    <Text
                      style={styles.text}
                    >
                      {intl.formatMessage({id: 'bcc'})}
                    </Text>
                  </Pressable>
                )}

                {isSideCarContext || props.replyToMessageId ? (
                  <></>
                ) : (
                  <FoldButton
                    customProps={{
                      btnText: intl.formatMessage({id: 'addNewContact'}),
                    }}
                    nativeProps={{
                      variant: 'link',
                      _text: {
                        color: Colors.FoldPixel.GRAY300,
                      },
                      onPress() {
                        setState((prev) => {
                          return {
                            ...prev,
                            isAddOrUpdateLeadDrawerOpen: true,
                          };
                        });
                      },
                    }}
                  />
                )}
              </View>
            </View>
            <SearchRecipient
              moduleAccessLocationIds={props?.selectedForms?.flatMap((form) => form.locationIds || [])}
              disabled={!!props.replyToMessageId}
              singleMode={isEnabledMultiTenancy}
              updateInternalState={isEnabledMultiTenancy}
              selectedToRecipient={to}
              onChange={handleToRecipientChange}
              recipientType="to"
              selectedRecipients={to.map((item) => item.uuid)}
              status={!!errors.to ? 'error' : ''}
              ref={toSearchRecipientRef}
            />

            {errors.to ? (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {intl.formatMessage({id: errors.to})}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>

          {state.showCc ? (
            <FormControl style={styles.formControl}>
              <FormControl.Label>
                <Text style={styles.label}>
                  {intl.formatMessage({id: 'cc'})}
                </Text>
              </FormControl.Label>
              <SearchRecipient
                selectedLocationId={selectedLocationId}
                disabled={isCcBccDisabled}
                onChange={(selected) => {
                  setCc(selected);
                  saveEmailDraft({cc: selected});
                  setIsShowDiscardAlert(true);
                }}
                recipientType="cc"
                selectedRecipients={ccRecipientUuids}
                status={''}
              />
            </FormControl>
          ) : (
            <></>
          )}

          {state.showBcc ? (
            <FormControl style={styles.formControl}>
              <FormControl.Label>
                <Text style={styles.label}>
                  {intl.formatMessage({id: 'bcc'})}
                </Text>
              </FormControl.Label>
              <SearchRecipient
                selectedLocationId={selectedLocationId}
                disabled={isCcBccDisabled}
                onChange={(selected) => {
                  setBcc(selected);
                  saveEmailDraft({bcc: selected});
                  setIsShowDiscardAlert(true);
                }}
                recipientType="bcc"
                selectedRecipients={bccRecipientUuids}
                status={''}
              />
            </FormControl>
          ) : (
            <></>
          )}

          <FormControl
            isRequired
            style={styles.formControl}
            isInvalid={!!errors?.subject}
          >
            <FormControl.Label>
              <Text style={styles.label}>
                {intl.formatMessage({id: 'subject'})}
              </Text>
            </FormControl.Label>
            <Input
              size={'intNormal'}
              style={styles.input}
              placeholderTextColor={Colors.FoldPixel.GRAY200}
              placeholder="Add a subject"
              {...testID(TestIdentifiers.subject)}
              value={getPreviewSubjectHtml(
                state.selectedTemplate,
                state.subject
              )}
              ref={subjectInputRef}
              isDisabled={
                !!props.subject && !props.forwardMessageId && !props.html
              }
              onChangeText={(text) => {
                setState((prev) => {
                  return {
                    ...prev,
                    subject: text,
                  };
                });
                setIsShowDiscardAlert(true);
              }}
            />

            {errors.subject ? (
              <FormControl.ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {intl.formatMessage({id: errors.subject})}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          {props.templateId || props.templateCategoryCode || props.html ? (
            <></>
          ) : (
            <View
              style={styles.modalBodyView3}
            >
              <Switch
                checked={state.sendEmailOptions === 'TEMPLATE'}
                {...testID(TestIdentifiers.toggleBtn)}
                style={{
                  backgroundColor:
                    state.sendEmailOptions === 'TEMPLATE'
                      ? Colors.FoldPixel.PRIMARY300
                      : '',
                }}
                onChange={(checked) => {
                  if (checked) {
                    setEditorHeight(200);
                  } else {
                    setEditorHeight(300);
                  }
                  setState((prev) => {
                    return {
                      ...prev,
                      sendEmailOptions: checked ? 'TEMPLATE' : 'RAW_INPUT',
                      ...(checked
                        ? {}
                        : {selectedTemplate: {} as IEmailTemplateData}),
                    };
                  });
                  setIsShowDiscardAlert(true);
                  if (!checked) {
                    saveEmailDraft({
                      templateId: undefined,
                      templateCategoryCode: undefined,
                    });
                  }
                }}
              />

              <Text
                style={styles.text2}
              >
                {intl.formatMessage({id: 'emailTemplate'})}
              </Text>
            </View>
          )}

          {state.sendEmailOptions === SEND_EMAIL_OPTIONS.TEMPLATE &&
          !props.templateId &&
          !props.html ? (
            <View
              style={styles.modalBodyView4}
            >
              <View
                style={styles.view}
              >
                <FormControl>
                  <FormControl.Label>
                    <Text style={[styles.label, {lineHeight: 21}]}>
                      {intl.formatMessage({id: 'templateCategories'})}
                    </Text>
                  </FormControl.Label>
                  <Select
                    placeholder="Select category"
                    allowClear
                    size="large"
                    value={state?.selectedTemplateCategory?.id?.toString()}
                    onClear={() => {
                      setState((prev) => {
                        return {
                          ...prev,
                          selectedTemplateCategory: {} as ITemplateCategory,
                          selectedTemplate: {} as IEmailTemplateData,
                        };
                      });
                    }}
                    onSelect={(id) => {
                      const selectedTemplateCategory =
                        state.templateCategories.find(
                          (item) => item.id.toString() == id
                        );
                      if (
                        selectedTemplateCategory &&
                        selectedTemplateCategory.id
                      ) {
                        setState((prev) => {
                          return {
                            ...prev,
                            selectedTemplateCategory,
                            selectedTemplate: {} as IEmailTemplateData,
                          };
                        });
                        saveEmailDraft({
                          templateCategoryCode: selectedTemplateCategory.code,
                        });
                      }
                    }}
                  >
                    {state.templateCategories.map((item) => {
                      return (
                        <Select.Option
                          value={item.id.toString()}
                          key={item.id.toString()}
                        >
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </FormControl>
              </View>

              <View
                style={styles.modalBodyView6}
              >
                <FormControl isRequired isInvalid={!!errors.template}>
                  <FormControl.Label>
                    <Text style={styles.label}>
                      {intl.formatMessage({id: 'templateList'})}
                    </Text>
                  </FormControl.Label>
                  <AllTemplateSearch
                    multipleCategoryCodes={state.templateCategories.map(
                      (item) => item.code
                    )}
                    status={!!errors.template ? 'error' : ''}
                    contentType="email"
                    categoryCode={state.selectedTemplateCategory.code || ''}
                    onSelect={(selected: IEmailTemplateData) => {
                      const templateSubject =
                        selected?.templateData?.subject || '';
                      setState((prev) => {
                        return {
                          ...prev,
                          selectedTemplate: selected,
                          ...(props.inboxId ? {} : {subject: templateSubject}),
                        };
                      });
                      saveEmailDraft({templateId: selected?.id});
                      setIsShowDiscardAlert(true);
                    }}
                  />
                  {errors.template ? (
                    <FormControl.ErrorMessage
                      _text={{
                        fontSize: 'xs',
                        color: 'error.500',
                        fontWeight: 500,
                      }}
                    >
                      {intl.formatMessage({id: errors.template})}
                    </FormControl.ErrorMessage>
                  ) : (
                    <></>
                  )}
                </FormControl>
              </View>
            </View>
          ) : (
            <></>
          )}

          {isMultiplePatientCategorySelected ? (
            <FormControl style={styles.formControl}>
              <FormControl.Label>
                <Text style={styles.label}>
                  {intl.formatMessage({id: 'forms'})}
                </Text>
              </FormControl.Label>
              <MultipleFormSearch
                key={`${to.length}`}
                disableInlineFormEdit={props?.disableInlineFormEdit}
                disabled={props?.disabledFormSearch || (isEnabledMultiTenancy && to.length === 0)}
                value={state?.selectedForms || []}
                isShowError={false}
                onChange={(value: ISelectedForm[]) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      selectedForms: value,
                    };
                  });
                  saveEmailDraft({selectedForms: value});
                  setIsShowDiscardAlert(true);
                }}
                formLocations={formLocationListBasedOnSelectedContact}
                useExactMatchForFormLocations={isEnabledMultiTenancy ? true : false}
              />
            </FormControl>
          ) : (
            <></>
          )}

          {isSharePackageCategorySelected ? (
            <FormControl style={styles.formControl}>
              <FormControl.Label>
                <Text style={styles.label}>
                  {intl.formatMessage({id: 'packages'})}
                </Text>
              </FormControl.Label>
              <SearchPackages
                onSelectPackages={(selectedPackages) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      selectedPackages: selectedPackages,
                    };
                  });
                }}
              />
            </FormControl>
          ) : (
            <></>
          )}

          {showEditor ? (
            <View
              style={styles.marginTop16}
            >
              <GPTPrompt
                conversationUuid={props.conversationUuid}
                messageUuid={props.replyToMessageId}
                onSelect={(text) => {
                  onSelectSuggestion(text);
                }}
              />
            </View>
          ) : (
            <></>
          )}

          {isDisplayCannedResponses() ? (
            <CannedResponses
              isReplyMessage={false}
              categoryCode={'EMAIL'}
              parentCode={PARENT_CODE.MESSAGING_WINDOW_FOOTER}
              searchString={getSearchStringForCannedResponse()}
              onCannedResponsesSelection={(
                cannedResponseData: ICannedResponses
              ) => {
                if (editorRef?.current) {
                  const currentText = getEmailHtml();
                  const finalText =
                    `${cannedResponseData.content}${currentText}`.replace(
                      getSearchStringForCannedResponse(),
                      ''
                    );
                  setState((prev) => {
                    return {
                      ...prev,
                      editorContent: finalText,
                    };
                  });
                  editorRef?.current?.editor?.focus();
                }
              }}
            />
          ) : (
            <View></View>
          )}
          {/* editor */}
          <View
            style={[styles.editorContainer, { marginTop: isDisplayCannedResponses() ? 2 : 8}]}
          >
            <Stack
              direction="row"
              style={styles.editorHeader}
            >
              <InfoSvg
                customDimension="16"
                customColor={Colors.Custom.DeceasedTagColor}
              />
              <Text
                style={styles.editorHeaderText}
              >
                {intl.formatMessage({id: 'phiWarningForEmail'})}
              </Text>
            </Stack>
            <View
              style={[
                styles.editorContent,
                showEditor ? {} : {display: 'none'},
              ]}
            >
              <Content
                style={reactStyles.content}
                id="email-drawer-container-v2"
                className="editor-container"
              >
                <JoditRichEditor
                  setRef={(ref) => {
                    editorRef.current = ref;
                  }}
                  placeholder={'Type message here'}
                  height={state.editorHeight}
                  content={state.editorContent}
                  editorActions={(actionCode, actionData) => {
                    switch (actionCode) {
                      case EDITOR_ACTIONS.RESET_HEIGHT:
                        setEditorHeight(actionData);
                        break;
                    }
                  }}
                  onKeydown={(event:any) => {
                    if (
                      isDisplayCannedResponses() &&
                      (event.key === KEY_PRESS_CODES.ARROW_UP ||
                        event.key === KEY_PRESS_CODES.ARROW_DOWN ||
                        event.key === KEY_PRESS_CODES.ENTER)
                    ) {
                      event.preventDefault();
                      return;
                    }
                  }}
                  onChange={saveContentInDraft}
                />
              </Content>

              <View
                style={[
                  styles.editorFooter,
                  showEditor ? {} : {display: 'none'},
                ]}
              >
                <View
                  style={styles.editorFooterContent}
                >
                  <View
                    style={styles.editorFooterLeft}
                  >
                    {state.selectedEmailInbox?.type !== 'GMAIL' && (
                      <>
                        <Tooltip title="Attachments (Max Size: 3MB per attachment)">
                          <Upload
                            style={styles.upload}
                            multiple={true}
                            beforeUpload={() => false}
                            maxCount={10}
                            fileList={[]}
                            className="msg-attachment"
                            onChange={(info) => {
                              const tempFileList: UploadFile<any>[] = [
                                ...fileList,
                              ];
                              const totalFileListLength =
                                tempFileList.length + info.fileList.length;
                              if (totalFileListLength > 10) {
                                showMaxAttachmentSizeAlert();
                                return;
                              }
                              (info.fileList || []).forEach((item) => {
                                if (
                                  item.size &&
                                  item.size < MAX_ATTACHMENT_UPLOAD_SIZE
                                ) {
                                  tempFileList.push(item);
                                }
                              });
                              const isFileMoreThanMaxSize = info.fileList.find(
                                (item) =>
                                  item.size &&
                                  item.size > MAX_ATTACHMENT_UPLOAD_SIZE
                              );
                              if (
                                isFileMoreThanMaxSize &&
                                isFileMoreThanMaxSize.uid
                              ) {
                                showMaxAttachmentSizeAlert();
                              }
                              setFileList(tempFileList);
                            }}
                          >
                            <AntIcon
                              name="paperclip"
                              size={18}
                              color={Colors.FoldPixel.GRAY300}
                            />
                          </Upload>
                        </Tooltip>
                        <GetDivider height={15} />
                      </>
                    )}

                    <Tooltip title={isActionsDisabled ? tooltipMessage : "Browse Forms"}>
                      <Pressable
                        onPress={() => {
                          if(isActionsDisabled) return;
                          if (isEnabledMultiTenancy && to.length === 0) {
                            customToast({
                              toastType: ToastType.error,
                              message:
                                'Please select patient to attach form',
                            });
                            return;
                          }
                          setState((prev) => {
                            return {
                              ...prev,
                              isFormDrawerOpen: true,
                            };
                          });
                        }}
                      >
                        <View
                          style={styles.box}
                        >
                          <FormSvg defaultColor={isActionsDisabled ? Colors.FoldPixel.GRAY100 : Colors.FoldPixel.GRAY300} />
                        </View>
                      </Pressable>
                    </Tooltip>

                    <GetDivider height={15} />

                    <Tooltip title="Browse Articles">
                      <Pressable
                        onPress={() => {
                          setState((prev) => {
                            return {
                              ...prev,
                              isArticleDrawerOpen: true,
                            };
                          });
                        }}
                      >
                        <View
                          style={styles.box}
                        >
                          <EducationContent
                            defaultColor={Colors.FoldPixel.GRAY300}
                          />
                        </View>
                      </Pressable>
                    </Tooltip>

                    <GetDivider height={15} />
                    <Tooltip title={isActionsDisabled ? tooltipMessage : "Send Appointment Link"}>
                      <Pressable
                        onPress={() => {
                          if(isActionsDisabled) return;
                          handleOpenSendAppointmentLinkDrawer()}}
                      >
                        <View
                          style={styles.box}
                        >
                          <CalendarSvg
                            defaultColor={isActionsDisabled ? Colors.FoldPixel.GRAY100 :Colors.FoldPixel.GRAY300} //here
                          />
                        </View>
                      </Pressable>
                    </Tooltip>

                    <GetDivider height={15} />

                    <Tooltip title="Browse Media">
                      <Pressable
                        onPress={() => {
                          setState((prev) => {
                            return {
                              ...prev,
                              isAttachMediaDrawerOpen: true,
                            };
                          });
                        }}
                      >
                        <Feather
                          name="image"
                          style={styles.featherIcon}
                        />
                      </Pressable>
                    </Tooltip>

                   {(state?.userSignaturesDefault?.id && !isEmailConversationExist) && <>
                      <GetDivider height={15} />

                      <Tooltip title="Signature">
                        <Pressable
                          style={styles.pressable}
                          onPress={() => {
                            onActionPerformed(COMMON_ACTION_CODES.ITEM_SELECT, state?.userSignaturesDefault)
                          }}
                        >
                          <PreferenceAddSignatureSvg
                            width={34}
                            height={28}
                          />
                        </Pressable>
                      </Tooltip>
                    </>}
                  </View>

                  <View
                    style={styles.editorFooterRight}
                  >
                    {state.isAttachmentLoading ? (
                      <Spinner />
                    ) : fileList.length > 0 &&
                      state.selectedEmailInbox?.type !== 'GMAIL' ? (
                      <Pressable
                        style={styles.attachmentButton}
                        onPress={() => {
                          setState((prev) => {
                            return {
                              ...prev,
                              isFileListDrawerOpen: true,
                            };
                          });
                        }}
                      >
                        <AntIcon name="paperclip" color={'white'} />
                        <Text
                          style={styles.attachmentButtonText}
                        >{`${fileList.length} Attachments`}</Text>
                        <Pressable
                          onPress={() => {
                            setFileList([]);
                          }}
                        >
                          <AntIcon name="close" color={'white'} />
                        </Pressable>
                      </Pressable>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              </View>

              {/* Drawers */}

              {state.isFormDrawerOpen ? (
                <AttachFormDrawer
                  useExactMatchForFormLocations={isEnabledMultiTenancy}
                  externalFormLocationIds={formLocationListBasedOnSelectedContact}
                  conversationData={{} as any}
                  isOpen={state.isFormDrawerOpen}
                  onClose={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isFormDrawerOpen: false,
                      };
                    });
                  }}
                  onFormSelect={(form) => {
                    handleFormSelect(form);
                  }}
                  onMsgSend={() => {
                    //
                  }}
                  privateNote=""
                  isGetOnlyFormData

                />
              ) : (
                <></>
              )}

              {state.isArticleDrawerOpen ? (
                <AttachArticleDrawer
                  conversationData={{} as any}
                  isDrawerVisible={state.isArticleDrawerOpen}
                  onArticleSelect={(link) => {
                    handleArticleSelect(link);
                  }}
                  onClose={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isArticleDrawerOpen: false,
                      };
                    });
                  }}
                  onMsgSend={() => {
                    //
                  }}
                  privateNote=""
                  isOnlySelectArticle
                />
              ) : (
                <></>
              )}

              {state.isAppointmentLinkDrawerOpen ? (
                <AttachAppointmentDrawer
                  isOpen={state.isAppointmentLinkDrawerOpen}
                  singleToLocationGroupId={singleToLocationGroupId}
                  conversationData={{} as any}
                  onClose={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isAppointmentLinkDrawerOpen: false,
                      };
                    });
                  }}
                  onSendMessage={(appointmentMessage: string) => {
                    handleAppointmentMessage(appointmentMessage);
                  }}
                  isGetRawLink
                  contactUuid={to?.[0]?.uuid}
                  contactLocationId={to?.[0]?.contactPracticeLocations?.[0]?.accountLocation?.uuid}
                />
              ) : (
                <></>
              )}

              {state.isAttachMediaDrawerOpen ? (
                <MediaSelectionModal
                  isOpen={state.isAttachMediaDrawerOpen}
                  onClose={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isAttachMediaDrawerOpen: false,
                      };
                    });
                  }}
                  onSelect={async (mediaData) => {
                    handleAttachMedia(mediaData);
                  }}
                />
              ) : (
                <></>
              )}

              {(state?.isSignaturePopoverOpen && state?.userSignaturesDefault?.id) && <AttachSignatureOptionPopover
                onActionPerformed={onActionPerformed}
                userSignatures={state?.userSignatures}
                userDefaultSignature={state?.userSignaturesDefault}
                selectedSignaturePreference={state?.selectedSignaturePreference}
              />}

              {state.isDiscardAlertOpen ? (
                <Modal
                  title="Discard Email?"
                  className="discard-alert"
                  open={state.isDiscardAlertOpen}
                  confirmLoading={state.isDiscardLoading}
                  cancelText="No"
                  okText="Discard"
                  okType="danger"
                  okButtonProps={{
                    style: {
                      borderRadius: '0px',
                    },
                  }}
                  footer={
                    <View
                      style={styles.modalBodyView5}
                    >
                      <Button.Group>
                        <FoldButton
                          nativeProps={{
                            variant: BUTTON_TYPE.PRIMARY,
                            isLoading: state.isSaveAsDraftLoading,
                            onPress() {
                              handleSaveAsDraft();
                            },
                          }}
                          customProps={{
                            btnText: 'Save as draft',
                          }}
                        />
                        <FoldButton
                          nativeProps={{
                            variant: BUTTON_TYPE.SECONDARY,
                            isLoading: state.isDiscardLoading,
                            onPress() {
                              handleDiscard();
                            },
                          }}
                          customProps={{
                            btnText: 'Discard',
                          }}
                        />
                      </Button.Group>
                    </View>
                  }
                  onCancel={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isDiscardAlertOpen: false,
                      };
                    });
                  }}
                >
                  <Text style={{color: Colors.FoldPixel.GRAY250}}>{intl.formatMessage({id: 'emailDiscardAlert'})}</Text>
                </Modal>
              ) : (
                <></>
              )}

              {state.showPatientChangeModal && (
                <PatientChangeAlertModal
                  showModal={state.showPatientChangeModal}
                  onHandleDiscard={onHandlePatientChangeDiscard}
                  onHandleConfirm={onHandlePatientChangeConfirm}
                />
              )}

              {state.isFileListDrawerOpen ? (
                <EmailAttachmentDrawer
                  fileList={fileList}
                  setFileList={(newFileList) => {
                    setFileList(newFileList);
                    if (newFileList.length === 0) {
                      setState((prev) => {
                        return {
                          ...prev,
                          isFileListDrawerOpen: false,
                        };
                      });
                    }
                  }}
                  isOpen={state.isFileListDrawerOpen}
                  onClose={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        isFileListDrawerOpen: false,
                      };
                    });
                  }}
                />
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
      )}

      {state.isAddOrUpdateLeadDrawerOpen ? (
        <AddOrUpdateLead
          personTypeUuid={getContactTypeId('LEAD')}
          personType={'LEAD'}
          singleLeadData={undefined}
          shouldInvokeCallback
          onFormCompleteAction={(code: any, contactId: string) => {
            handleOnNewContactAdd(contactId);
          }}
          handleCloseModal={() => {
            setState((prev) => {
              return {
                ...prev,
                isAddOrUpdateLeadDrawerOpen: false,
              };
            });
          }}
          isShowModal={state.isAddOrUpdateLeadDrawerOpen}
        />
      ) : (
        <></>
      )}
      {(state.selectedTemplate?.id || props.html) &&
      state.sendEmailOptions === SEND_EMAIL_OPTIONS.TEMPLATE ? (
        <View
          style={styles.previewContainer}
        >
          <View
            style={styles.previewHeader}
          >
            <Text
              style={styles.previewHeaderText}
            >
              {intl.formatMessage({id: 'preview'})}
            </Text>
          </View>

          {parse(getPreviewHtml())}
        </View>
      ) : (
        <></>
      )}
    </Drawer>
  );
};

export default EmailDrawerCommonV2;
