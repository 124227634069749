import {Box, Text, VStack} from 'native-base';
import React, {useState, useContext, useCallback} from 'react';
import {getEnabledFormComponents} from '../../../../../utils/capabilityUtils';
import {
  HISTORY_COMPONENT_LIST,
  INTAKE_COMPONENT_LIST,
  VITAL_INTAKE_COMPONENT,
  componentKeys,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {FHForm} from '../../../../RightSideContainer/Forms/FHFormio';
import {
  getAccountUUID,
  getContactProfileAccountLocationUuid,
  getFoldAccessToken,
} from '../../../../../utils/commonUtils';
import {FormContext} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {Skeleton} from 'antd';
import useEHRCapabilities from '../../../../../screens/BusinessStudio/useEHRCapabilities';
import { Colors } from '../../../../../styles';
import { usePersonOmniViewContext } from '../../../PersonOmniView.context';
const PatientReportedView = (props: {
  personData: any;
  onListUpdate?: (dataAvailable: boolean) => void;
}) => {
  const {personData} = props;
  const contextData = useContext(CommonDataContext);
  const {ehrPatientId} = usePersonOmniViewContext();
  const foldAccessToken = getFoldAccessToken();
  const accountUUID = getAccountUUID();
  const contactUUID = personData?.contactUUID;
  const contactIntId = personData?.id;
  const patientId = ehrPatientId || props.personData?.patientId || props.personData?.patientUuid;
  const accountLocationUuid = getContactProfileAccountLocationUuid(props.personData, true);
  const ehrCapabilities = useEHRCapabilities({locationId: accountLocationUuid});
  const [enabledVitalComponents] = useState(
    getEnabledFormComponents(VITAL_INTAKE_COMPONENT, ehrCapabilities)
  );
  const [enabledIntakeComponents] = useState(
    getEnabledFormComponents(INTAKE_COMPONENT_LIST, ehrCapabilities)
  );
  const [enabledHistoryComponents] = useState(
    getEnabledFormComponents(
      HISTORY_COMPONENT_LIST.filter(
        (item) => item.key !== componentKeys.SOCIAL_HISTORY
      ),
      ehrCapabilities
    )
  );

  const [componentState, setComponentState] = useState<{
    dataAvailability: {[index: string]: boolean};
    dataLoading: {[index: string]: boolean};
    areAllComponentsFinishedLoading: boolean;
    allComponentsHaveNoData: boolean;
  }>({
    dataAvailability: {},
    dataLoading: {},
    areAllComponentsFinishedLoading: true,
    allComponentsHaveNoData: false,
  });

  const updateLoadingStatus = useCallback(
    (componentKey: string, isLoading: boolean, isDataAvailable: boolean) => {
      if (componentKey) {
        setComponentState((prev) => {
          const dataAvailability = {
            ...prev.dataAvailability,
            [componentKey]: isDataAvailable,
          };
          const dataLoading = {
            ...prev.dataLoading,
            [componentKey]: isLoading,
          };
          const hasData =
            dataAvailability[componentKeys.CONDITIONS] || // PROBLEMS
            dataAvailability[componentKeys.ALLERGIES] || // ALLERGIES
            dataAvailability[componentKeys.MEDICATIONS] || // MEDICATIONS
            dataAvailability[componentKeys.IMMUNIZATIONS] || // IMMUNIZATIONS
          dataAvailability[componentKeys.SURGICAL_HISTORY] || // SURGICAL_HISTORY
            dataAvailability[componentKeys.FAMILY_HISTORY] || // FAMILY_HISTORY
            // dataAvailability[componentKeys.SOCIAL_HISTORY] || no need
            dataAvailability[componentKeys.VITALS]; // VITALS

          const areComponentsLoading =
            dataLoading[componentKeys.CONDITIONS] || // PROBLEMS
            dataLoading[componentKeys.ALLERGIES] || // ALLERGIES
            dataLoading[componentKeys.MEDICATIONS] || // MEDICATIONS
            dataLoading[componentKeys.IMMUNIZATIONS] || // IMMUNIZATIONS
            dataLoading[componentKeys.SURGICAL_HISTORY] || // SURGICAL_HISTORY
            dataLoading[componentKeys.FAMILY_HISTORY] || // FAMILY_HISTORY
            // dataLoading[componentKeys.SOCIAL_HISTORY]; no need
            dataLoading[componentKeys.VITALS]; // VITALS

          props?.onListUpdate?.(hasData);

          return {
            ...prev,
            dataAvailability: dataAvailability,
            dataLoading: dataLoading,
            areAllComponentsFinishedLoading: !areComponentsLoading,
            allComponentsHaveNoData: !hasData,
          };
        });
      }
    },
    []
  );


  const [intakeOptions] = useState({
    foldAccessToken,
    patientId,
    accountUUID,
    contactId: contactUUID,
    contactIntId: contactIntId,
    backgroundSaveEnabled: true,
    ehrCapabilities,
    accountLocationUuid,
    formContext: FormContext.recentReportedView,
    updateLoadingStatus,
    ehrActivePatient: props.personData?.contactProfile,
  });

  return (
    <Box pb={4}>
      <Text size={'smRegular'} color={Colors.FoldPixel.GRAY400} px={4} pt={4}>
        New Patient Reported Data
      </Text>
      <VStack>
        <div className="custom-patient-reported-form-styles">
          {enabledIntakeComponents.length > 0 && (
            <FHForm
              optionData={intakeOptions}
              components={enabledIntakeComponents}
            />
          )}
          {enabledVitalComponents.length > 0 && (
            <FHForm
              optionData={intakeOptions}
              components={enabledVitalComponents}
            />
          )}
          {enabledHistoryComponents.length > 0 && (
            <FHForm
              optionData={intakeOptions}
              components={enabledHistoryComponents}
            />
          )}
        </div>
      </VStack>
      {componentState.allComponentsHaveNoData && (
        <Text size={'smRegular'} px={4} pt={2} color={Colors.FoldPixel.GRAY300}>
          {'No new data reported by patient'}
        </Text>
      )}
    </Box>
  );
};

export default PatientReportedView;
