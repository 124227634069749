import { useContext, useState } from "react";
import MessagingContactDetailsDrawer from "../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer";
import { COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES } from "../../constants/ActionConst";
import { isPatientDetailViewAllowed } from "../RightSideContainer/UserAccess/UserAccessPermission";
import { useNavigate, } from "react-router-dom";
import { CommonDataContext } from "../../context/CommonDataContext";
import { getViewInEHRLink, redirectToNewTab } from "../SideCar/SidecarUtils";
import { useLazyQuery } from "@apollo/client";
import { GET_PATIENT_ID_BY_CONTACT_IDS } from "../../services/Contacts/ContactsQueries";
import { getEHRUrl } from "../../utils/capabilityUtils";
import { useRestrictedEmployeeProfile } from "../../utils/useRestrictedEmployeeProfile";
import { checkThisEmployeeIsRestricted } from "../../utils/commonUtils";
import { showToast, ToastType } from "../../utils/commonViewUtils";
import { useIntl } from "react-intl";
import { useToast } from "native-base";


export function withMiniContactViewHOC(component: any) {


    const withMiniContactView: any = (props: any) => {
        const [contactId, setContactId] = useState<any>(null);
        const commonData = useContext(CommonDataContext);
        const intl = useIntl();
        const toast = useToast();
        const restrictedEmployeeProfileData = useRestrictedEmployeeProfile();
        const navigate = useNavigate();
        const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed(commonData.currentUserRoles,commonData.userPermissions);
        const [getContactDetails] = useLazyQuery(GET_PATIENT_ID_BY_CONTACT_IDS, {
            fetchPolicy: 'no-cache',
          },
        );

        const checkRestrictedEmployeeProfile = async (
          contactId: string
        ): Promise<{
          isRestricted: boolean;
          contactData?: any;
        }> => {
          try {
            const response = await getContactDetails({
              variables: {
                contactIdList: [contactId],
              },
            });

            const contactData = response?.data?.contacts?.[0];
            const contactPracticeLocations =
              contactData?.contactPracticeLocations;

            if (!contactPracticeLocations || !contactPracticeLocations.length) {
              return {isRestricted: false, contactData};
            }

            const locationIds = contactPracticeLocations.map(
              (location: any) => location?.accountLocation?.uuid
            );

            const isRestrictedEmployeeProfileView =
              await checkThisEmployeeIsRestricted(
                restrictedEmployeeProfileData?.isRestrictedParticularLocationEmployeeEnabled,
                locationIds,
                restrictedEmployeeProfileData?.restrictedLocationIds
              );

            if (isRestrictedEmployeeProfileView) {
              showToast(
                toast,
                intl.formatMessage({
                  id: 'patientProfileAccessDenyMsg',
                }),
                ToastType.info
              );
              return {isRestricted: true, contactData};
            }

            return {isRestricted: false, contactData};
          } catch (error) {
            console.error('Error checking restricted employee profile:', error);
            return {isRestricted: false};
          }
        };        

        const checkRestrictedEmployeeAndNavigate = async (
          contactId: string
        ) => {
          const { isRestricted } = await checkRestrictedEmployeeProfile(contactId);
          return isRestricted;
        };

        const redirectToNewTabForSidecar = async (contactId: string) => {
          try {
            const { isRestricted, contactData } = await checkRestrictedEmployeeProfile(contactId);
    
            if (isRestricted) {
              return;
            }
            
            const patientId = contactData?.patient?.patientId;
            const locationId = contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;        

            if (patientId && locationId) {
              const ehrURL = getEHRUrl(locationId, '', commonData);
              const openInEhrUrl = getViewInEHRLink(patientId, ehrURL);
              if (openInEhrUrl) {
                window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
              } else {
                redirectToNewTab(`/members/patient/${contactId}`);
              }
            } else {
              redirectToNewTab(`/members/patient/${contactId}`);
            }
          } catch (error) {

            redirectToNewTab(`/members/patient/${contactId}`);
          }
        }

        const openContactInWindowOrOpenContactIdDrawer = async (contactId?: string) => {
            if (!contactId) {
                return;
            }

            const isRestrictedEmployeeProfileView =
              await checkRestrictedEmployeeAndNavigate(contactId);
            if (isRestrictedEmployeeProfileView) {
              return;
            }

            if(isPatientDetailViewAllowedConfig){
                if (
                  commonData.sidecarContext?.isSidecar
                ) {
                  redirectToNewTabForSidecar(contactId);
                  return;
                }
                window.open(
                    `/#/members/patient/${contactId}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
            }else{
                setContactId(contactId);
            }
        }

        const navigateOrOpenContactIdDrawer = async (contactId?: string, additionalData?:any) => {
            if (!contactId) {
                return;
            }

            const isRestrictedEmployeeProfileView =
              await checkRestrictedEmployeeAndNavigate(contactId);
            if (isRestrictedEmployeeProfileView) {
              return;
            }

            if(isPatientDetailViewAllowedConfig){
                if (
                  commonData.sidecarContext?.isSidecar
                ) {
                  redirectToNewTabForSidecar(contactId);
                  return;
                }
                navigate(`/members/patient/${contactId}`, additionalData)
            }else{
                setContactId(contactId);
            }
        }

        const navigateOrOpenLeadInWindow = (contactId?: string | number, isNavigate?: boolean) => {
            if (!contactId) {
              return;
            }
            if (commonData.sidecarContext?.isSidecar) {
              redirectToNewTab(`/members/prospect/${contactId}`);
              return;
            }

            if (isNavigate) {
              navigate(`/members/prospect/${contactId}`);
            } else {
              window.open(`/#/members/prospect/${contactId}`, '_blank', 'noopener,noreferrer');
            }
        }

        const openContactDrawer = async (contactId?: string) => {
            if (!contactId) {
                return;
            }

           const isRestrictedEmployeeProfileView =
             await checkRestrictedEmployeeAndNavigate(contactId);
           if (isRestrictedEmployeeProfileView) {
             return;
           }

            setContactId(contactId);
        }
        return (<>
            {
                contactId ? <MessagingContactDetailsDrawer
                    contactData={{
                        id: contactId
                    }}
                    isDrawerVisible={contactId ? true : false}
                    onSideBarActionPerformed={(action?: any, actionData?: any) => {
                        if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
                            setContactId(null);
                            return
                        } else if (action == COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                            // props?.onActionPerformed(action, actionData);
                            setContactId(null);
                            return
                        }
                    }}
                    borderLessView={true}
                /> : null
            }

            <>{component({ ...props, openContactDrawer, navigateOrOpenContactIdDrawer, openContactInWindowOrOpenContactIdDrawer, navigateOrOpenLeadInWindow })}</>
        </>)
    }
    return withMiniContactView
}

