import {Input, Select, Space} from 'antd';
import {cloneDeep, debounce} from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from '../FormComponents/FormComponents';
import {getUniqueKey} from '../CustomFormEngineUtils';
import {
  useFormRendererContext,
} from '../Context/FormRenderer.context';
import Label from '../BaseComponents/Label';
import Description from '../BaseComponents/Description';
import {
  DISPLAY_HAS_ENTERED_VALUE_FOR_FIELDS,
  DISPLAY_OPERATORS_FOR_FIELDS,
  FIELD_TYPES_WITH_RISK_SCORE,
  getOptionsOfComponent,
} from '../../FormBuilderWidget/RiskScoreConfiguration/RiskScoreHelper';
import {CheckboxSchema, ColumnsSchema, ConditionalSchema, ContainerSchema} from '../Schema/ComponentsSchema';
import {
  ConditionalComponent,
  LegacyConditional,
} from './BaseComponentInterface';
import {FormComponents} from '../../../../PublicPages/PublicForm/PublicFormHelper';
import {forEachExtensiveFormComponent} from '../../FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import {useCustomFormBuilderContext} from '../Context/CustomFormBuilder.context';
import InputField from './InputField';
import DropdownField from './DropdownField';
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { v4 as uuidV4 } from 'uuid'

interface FlatComponent {
  label: string;
  value: string;
  type: string;
  options: {label: string; value: string}[];
}

export const ConditionalFormElement: FormElement = {
  type: ConditionalSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    ...cloneDeep(ConditionalSchema),
    key: getUniqueKey(map, ConditionalSchema.key),
    referenceId: uuidV4(),
  }),
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(ConditionalSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    label: ConditionalSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,

};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  return <div>DesignerComponent</div>;
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: LegacyConditional;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const {state, dispatch} = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const {state: builderState} = useCustomFormBuilderContext();
  const [value, setValue] = useState<LegacyConditional>(
    defaultValue || (elementInstance as LegacyConditional) || selectedValue
  );
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: LegacyConditional): Promise<ValidationResult> => {
    try {
      return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: true,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: LegacyConditional) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: result.key, errorMessage: result.errorMessage },
      });
    }, 300),
    [validateField]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: LegacyConditional) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      components={builderState.elements}
      selectedBuilderComponent={builderState.selectedElement}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      onChange={handleChange}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  components,
  selectedBuilderComponent,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: LegacyConditional;
  onChange: (value: LegacyConditional) => void;
  errorMessage?: string;
  components?: FormElementInstance[];
  selectedBuilderComponent?: FormElementInstance | null;
}) {
  const {label, title, validate, description, tooltip} =
    elementInstance as ConditionalComponent;
  const [selectedField, setSelectedField] = useState<FlatComponent>({
    label: '',
    value: defaultValue?.when || '',
    type: '',
    options: [],
  });
  const [conditionalValue, setConditionalValue] = useState<LegacyConditional>(
    defaultValue || (elementInstance as LegacyConditional)
  );
  const showOperators = useMemo(
    () =>
      selectedField?.type &&
      DISPLAY_OPERATORS_FOR_FIELDS.includes(
        selectedField.type as FormComponents
      ),
    [selectedField?.type]
  );
  const showExistsField = useMemo(
    () =>
      selectedField?.type &&
      DISPLAY_HAS_ENTERED_VALUE_FOR_FIELDS.includes(selectedField.type),
    [selectedField?.type]
  );
  const showConditionalDropdownOptions = useMemo(
    () =>
      selectedField?.type &&
      FIELD_TYPES_WITH_RISK_SCORE.includes(selectedField.type),
    [selectedField?.type]
  );
  const operatorOptions = useMemo(
    () => [
      {label: 'Equal', value: 'equal'},
      {label: 'Not Equal', value: 'not_equal'},
      {label: 'Less Than', value: 'less'},
      {label: 'Less Than or Equal', value: 'less_or_equal'},
      {label: 'Greater Than', value: 'greater'},
      {label: 'Greater Than or Equal', value: 'greater_or_equal'},
    ],
    []
  );
  const displayOptions = useMemo(() => {
    return [
      {label: 'Shown', value: 'true'},
      {label: 'Hidden', value: 'false'},
    ]
  }, []);

  const existOptions = useMemo(() => {
    return [
      {label: 'Yes', value: 'true'},
      {label: 'No', value: 'false'},
    ]
  }, []);

  const checkboxOptions = useMemo(() => {
    return [
      {label: 'Checked', value: 'true'},
      {label: 'Unchecked', value: 'false'},
    ]
  }, []);

  const flatComponents = useMemo(() => {
    const values: FlatComponent[] = [];
    forEachExtensiveFormComponent(
      components || [],
      (component: FormElementInstance) => {
        if (
          component.label &&
          component.key &&
          component.key !== selectedBuilderComponent?.key &&
          ![ContainerSchema.type, ColumnsSchema.type].includes(component.type)
        ) {
          values.push({
            label: `${component.label || component.key}`,
            value: component.key,
            type: component.type,
            options: getOptionsOfComponent(component),
          });
        }
      }
    );
    return values;
  }, [components]);

  const componentOptions = useMemo(() => {
    return flatComponents.map((component) => ({
      label: component.label,
      value: component.value,
    }));
  }, [flatComponents]);

  useEffect(() => {
    if (!defaultValue?.when) return;
    const selectedComponent = flatComponents.find(
      (component) => component.value === defaultValue?.when
    );
    setSelectedField({
      label: selectedComponent?.label || '',
      value: conditionalValue.when || '',
      type: selectedComponent?.type || '',
      options: selectedComponent?.type === CheckboxSchema.type ? checkboxOptions : selectedComponent?.options || [],
    });
  }, [flatComponents, defaultValue?.when]);

  const updateConditionalValue = (updates: Partial<LegacyConditional>) => {
    const newValue = {...conditionalValue, ...updates};
    setConditionalValue(newValue);
    onChange(newValue);
  };

  const filterOption = useCallback((input: string, option: any) => {
    return ((option?.label || option?.children) as string)?.toLowerCase()?.includes(input?.toLowerCase() || '');
  }, []);

  return (
    <div className="flex w-full flex-col gap-2 container-field">
      <div className="container-field-header">
        <Label label={title || label || ''} isRequired={validate?.required || false} tooltip={tooltip} />
      </div>
      <div className="flex w-full flex-col padding-4 min-h-64px gap-4">
        <div className="flex w-full flex-col gap-1">
          <Label label="This field should be:" />
          <DropdownField
            defaultValue={`${conditionalValue.show ?? ''}`}
            onChange={(value) => updateConditionalValue({show: value})}
            options={displayOptions}
            testId={`conditional-field-${elementInstance.key}`}
            placeholder="Select an option"
            allowClear
            showSearch
            filterOption={filterOption}
          />
        </div>

        <div className="flex w-full flex-col gap-1">
          <Label label="When the field:" />
          <DropdownField
            showSearch
            allowClear
            filterOption={filterOption}
            defaultValue={selectedField.value}
            onChange={(value) => {
              const selectedComponent = flatComponents.find(
                (component) => component.value === value
              );
              setSelectedField({
                label: selectedComponent?.label || '',
                value,
                type: selectedComponent?.type || '',
                options: selectedComponent?.options || [],
              });
              updateConditionalValue({when: value});
            }}
            options={componentOptions}
          />
        </div>

        {showOperators && (
          <div className="flex w-full flex-col gap-1">
            <Label label="Operator:" />
            <DropdownField
              showSearch
              allowClear
              filterOption={filterOption}
              defaultValue={conditionalValue.operator || ''}
              onChange={(value) => updateConditionalValue({operator: value})}
              options={operatorOptions}
            />
          </div>
        )}

        {showExistsField && (
          <div className="flex w-full flex-col gap-1">
            <Label label="Has value entered:" />
            <DropdownField
              showSearch
              allowClear
              filterOption={filterOption}
              defaultValue={`${conditionalValue.exist ?? ''}`}
              onChange={(value) => updateConditionalValue({exist: value, eq: ''})}
              options={existOptions}
            />
          </div>
        )}

        {(!showExistsField ||
          (showExistsField && conditionalValue.exist === 'true')) && (
            <div className="flex w-full flex-col gap-1">
            <Label label="Has the value:" />
            {showConditionalDropdownOptions ? (
              <DropdownField
                defaultValue={conditionalValue.eq || ''}
                onChange={(value) => updateConditionalValue({eq: value})}
                options={selectedField.options || []}
                testId={`conditional-field-${elementInstance.key}`}
                placeholder="Select an option"
                allowClear
                showSearch
                filterOption={filterOption}
              />
            ) : (
              <InputField
                placeholder="Enter value"
                onChange={(e) => updateConditionalValue({eq: e.target.value})}
                defaultValue={conditionalValue.eq || ''}
              />
            )}
          </div>
        )}
      </div>
      {description && <Description description={description} />}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  return <div>PropertiesComponent</div>;
}
