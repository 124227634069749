import React from 'react';
import './EmptyDroppableView.css';

export default function EmptyDroppableView({ isActive }: { isActive: boolean }) {
  return (
    <div className={`drag-overlay ${isActive ? "drag-over" : ""}`}>
      <div className="drag-overlay__container">
        <p className="drag-overlay__title">Drag and drop components to build your form</p>
      </div>
    </div>
  )
}
