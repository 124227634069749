import {Divider, FlatList, Spinner, Text, Pressable} from 'native-base';
import {View} from 'react-native';
import {Colors} from '../../../../../styles';
import {Drawer} from 'antd';
import {PendingRequestListItem} from '../../../../common/PendingRequestListItem/PendingRequestListItem';
import Stack from '../../../../common/LayoutComponents/Stack';
import {ModalActionTitle} from '../../../../common/ModalActionTitle/ModalActionTitle';
import {useIntl} from 'react-intl';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT} from '../../../../../constants';
import {getFormattedDate} from '../../OtherDetails/OtherDeatilsUtils';
import {
  ACTION_CODES,
  SYNC_STATUS_TYPES,
} from '../../../../common/PendingRequestListItem/PendingRequestLisItemConst';
import {ReconciliationStatus, IReconciliationRequest, IReconciliationRequestGroup, IHIEReconciliationRequest, ReconciliationSource} from './interface';
import {useReconciliationRequests} from './useReconciliationRequests';
import FormLoaderSkeleton from '../../../MiddleContainer/PatientNotes/components/FormLoaderSkeleton';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import {
  getFilteredHieRequests,
  getResourceGroupText,
  getUpdatedMatchedHieRequest,
  updateGroupHieRequests,
} from './HieRequestsUtils';
import {HieMatchDataView} from './HieMatchDataView';
import {getVitalListFromCapability} from '../../../../../utils/capabilityUtils';
import {getAccountUUID} from '../../../../../utils/commonUtils';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import { styles } from '../../OtherDetails/OtherDetailsStyles';

interface PendingHieRequestsProps {
  patientUuid: string;
  patientId: string;
  accountLocationId: string;
  contactUuid?: string;
  contactLocationId?: string;
  onClose: () => void;
}

export const PendingHieRequestsDrawer = (props: PendingHieRequestsProps) => {
  const {patientUuid, accountLocationId, patientId, contactUuid, contactLocationId} = props;
  const {onClose} = props;
  const intl = useIntl();

  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;

  const {
    reconciliationRequestGroups,
    updateRequestStatus,
    loading,
    updateRequestsLoading,
    updateRequestGroupsState,
    handleAcceptAll,
    metaData
  } = useReconciliationRequests({
    patientUuid: patientUuid,
    accountLocationUuid: accountLocationId,
    patientId: patientId,
    contactUuid: contactUuid,
    source: ReconciliationSource.HIE
  });

  const onActionPerform = (hieRequest: IReconciliationRequest, code: string) => {
    switch (code) {
      case ACTION_CODES.ACCEPT:
        updateRequestStatus(hieRequest as IHIEReconciliationRequest, ReconciliationStatus.accepted);
        break;
      case ACTION_CODES.DECLINE:
        updateRequestStatus(hieRequest as IHIEReconciliationRequest, ReconciliationStatus.declined);
        break;
      case ACTION_CODES.SHOW_MATCH_DATA_VIEW:
        updateMatchDataViewVisibility(hieRequest, true);
        break;
      case ACTION_CODES.HIDE_MATCH_DATA_VIEW:
        updateMatchDataViewVisibility(hieRequest, false);
        break;
    }
  };

  const updateMatchDataViewVisibility = (
    hieRequest: IReconciliationRequest,
    isVisible: boolean
  ) => {
    hieRequest.isMatchDataViewVisible = isVisible;
    const updatedHieRequestsGroups = updateGroupHieRequests(
      hieRequest,
      reconciliationRequestGroups
    );
    updateRequestGroupsState(updatedHieRequestsGroups);
  };

  const renderCardListItem = ({
    item,
    index,
  }: {
    item: IReconciliationRequestGroup;
    index: number;
  }) => {
    return (
      <View
        style={{
          borderRadius: 12,
          backgroundColor: Colors.FoldPixel.GRAY50,
          marginTop: index === 0 && !isSidecarContext ? 4 : 12,
          borderWidth: 0.5,
          borderColor: Colors.FoldPixel.GRAY150,
        }}
      >
        <Stack direction="row" style={styles.row}>
          <Text
            fontSize={14}
            fontWeight={'500'}
            marginTop={3}
            paddingLeft={3}
            marginBottom={'10px'}
            color={Colors.FoldPixel.GRAY400}
          >
            {getResourceGroupText(item.resourceType, intl).headerText}
          </Text>
          {getFilteredHieRequests(item.orders)?.length > 1 && (
            <Pressable
              disabled={item.acceptAllLoading}
              onPress={() => {
                handleAcceptAll(item);
              }}
              cursor={item.acceptAllLoading ? 'not-allowed' : 'pointer'}
            >
              <Stack direction="row">
                <Text
                  fontSize={14}
                  fontWeight={'500'}
                  marginTop={3}
                  paddingLeft={3}
                  marginBottom={'10px'}
                  marginRight={3}
                  color={Colors.FoldPixel.PRIMARY300}
                >
                  {'Accept All'}
                </Text>
                {item.acceptAllLoading && <Spinner marginRight={3} />}
              </Stack>
            </Pressable>
          )}
        </Stack>

        <Divider />
        <Stack direction="column" style={styles.listContainer2}>
          {item.orders.map((order) => {
            const display = order.display;

            const loadingObj = updateRequestsLoading.find(
              (loading) => loading.id === order.id
            );

            const syncStatusType =
              order.syncStatus === ReconciliationStatus.accepted
                ? SYNC_STATUS_TYPES.ACCEPTED
                : SYNC_STATUS_TYPES.DECLINED;
            return (
              <PendingRequestListItem
                title={display?.title || ''}
                date={display?.date}
                id={order.id}
                onActionPerform={(id, code) => {
                  onActionPerform(order, code);
                }}
                error={order.error}
                key={`request_list_item_${order.id}`}
                isMatchDataViewVisible={order.isMatchDataViewVisible}
                showError={true}
                showDeleteAction={order.showDeleteAction}
                showUpdateAction={order.showUpdateAction}
                resourceType={order.resourceType}
                isDataNotMatched={order.conflictFields?.length ? true : false}
                syncStatusType={syncStatusType}
                loading={loadingObj}
                subTitle={display?.subTitle}
                syncStatus={order.syncStatus}
                matchToView={
                  <HieMatchDataView
                    headerText={
                      getResourceGroupText(item.resourceType, intl)
                        .matchedWithTitle
                    }
                    onActionPerform={(id, code) => {
                      onActionPerform(order, code);
                    }}
                    source={ReconciliationSource.HIE}
                    hieRequest={order}
                    onContinueClick={(conflictFields, accept?: boolean) => {
                      const updatedMatchedHieRequest =
                        getUpdatedMatchedHieRequest(
                          order as IHIEReconciliationRequest,
                          conflictFields,
                          metaData
                        );
                      updatedMatchedHieRequest.isMatchDataViewVisible = false;
                      const updatedHieRequestsGroups = updateGroupHieRequests(
                        updatedMatchedHieRequest,
                        reconciliationRequestGroups
                      );
                      updateRequestGroupsState(updatedHieRequestsGroups);
                      if (accept) {
                        onActionPerform(updatedMatchedHieRequest, ACTION_CODES.ACCEPT);
                      }
                    }}
                    contactLocationId={contactLocationId}
                  />
                }
                status={display?.status ? capitalizeText(display?.status) : ''}
              />
            );
          })}
        </Stack>
      </View>
    );
  };

  return (
    <Drawer
      open={true}
      width={isSidecarContext ? '100%' : '40%'}
      onClose={onClose}
      bodyStyle={{paddingTop: 0}}
      title={
        <ModalActionTitle
          isHeadNotSticky
          title={'HIE Data'}
          buttonList={[
            {
              onClick: () => {
                onClose();
              },
              show: true,
              id: 1,
              btnText: intl.formatMessage({
                id: 'close',
              }),
              size: 'sm',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
            },
          ]}
        />
      }
    >
      {loading ? (
        <FormLoaderSkeleton />
      ) : (
        <FlatList
          keyExtractor={(item) => item.resourceType}
          data={reconciliationRequestGroups}
          renderItem={renderCardListItem}
        />
      )}
    </Drawer>
  );
};
