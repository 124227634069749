import { Colors } from "../../../../styles/Colors";
import {Box, Divider, HStack, Pressable, Text} from 'native-base';
import { useIntl } from "react-intl";
import { ILeftContainerProps } from "../interfaces";
import { PendingOrders } from "./PendingOrders";
import PatientReportedView from "./PatientReportedView/PatientReportedView";
import { Collapse, Tooltip } from 'antd';
import { RightOutlined } from "@ant-design/icons";
import { tooltipInnerOverlayStyle, tooltipOverlayStyle, tooltipPlacement } from "../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper";
import { isDiagnosticReportEnabled } from "../../../../utils/capabilityUtils";
import { OrderType } from "../../MiddleContainer/Orders/OrdersAndReports/OrderConstants";
import { useMemo, useState } from "react";
import { PendingHieRequestsDrawer } from "./PendingHieRequests/PendingHieRequestsDrawer";
import { useReconciliationRequests } from "./PendingHieRequests/useReconciliationRequests";
import { AvailableHieDataView } from "./AvailableHieDataView";
import { isAccountConfigEnabled } from "../../../../utils/configUtils";
import { CONFIG_CODES } from "../../../../constants/AccountConfigConst";
import { getContactProfileAccountLocationUuid } from "../../../../utils/commonUtils";
import { IHIEReconciliationRequest, ReconciliationSource } from "./PendingHieRequests/interface";
import { ReconcileRequestCountView } from "./PendingHieRequests/ReconcileRequestCountView";
import { usePersonOmniViewContext } from "../../PersonOmniView.context";
import { testID, TestIdentifiers } from "../../../../testUtils";
import { RECONCILIATION_RESOURCE_TYPES } from "./PendingHieRequests/HieRequestsUtils";


export const RecentPatientActivity = (props: ILeftContainerProps) => {
  const intl = useIntl()
  const { Panel } = Collapse;
  const locationId = getContactProfileAccountLocationUuid(props.personData, true);
  const canShowOrderAndReports =
    isDiagnosticReportEnabled(OrderType.LAB, locationId) ||
    isDiagnosticReportEnabled(OrderType.RAD, locationId);
  const RECENT_CLINICAL_EVENT = 'RECENT_CLINICAL_EVENT';
  const isMSOEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED) || false;
  const accountLocationUuid = getContactProfileAccountLocationUuid(props.personData?.contactData, true);
  const {ehrPatientId} = usePersonOmniViewContext();
  const [componentState, setComponentState] = useState<{
    activePanel: string[];
    patientReportedDataAvailable: boolean;
    reportsDataAvailable: boolean;
    showHieRequestsDrawer: boolean;
  }>({
    activePanel: [],
    patientReportedDataAvailable: false,
    reportsDataAvailable: false,
    showHieRequestsDrawer: false
  });
  const resourceTypes = useMemo(() => {
    return RECONCILIATION_RESOURCE_TYPES;
  }, []);

  const {
    loading: HieRequestsLoading,
    pendingRequests,
    fetchReconciliationRequests
  } = useReconciliationRequests({
    patientUuid: props?.personData?.patientUuid,
    accountLocationUuid: accountLocationUuid || props?.personData?.accountLocationUuid || '',
    patientId: props?.personData?.patientId,
    contactUuid: props?.personData?.contactUUID,
    source: ReconciliationSource.HIE
  });

  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE) || false;

  return (
    <Box
      mb={2}
      shadow="0px 4px 8px -2px #10182819, 0px 2px 4px -2px #101828f"
      borderRadius={8}
      backgroundColor={Colors.Custom.ContainerBGColor}
      borderColor={Colors.Custom.BorderColor}
      borderWidth={1}
    >
      <Collapse
        expandIconPosition="end"
        expandIcon={({ isActive }) => {
          const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
          return (
            <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
              <RightOutlined rotate={isActive ? 90 : 0} />
            </Tooltip>
          )
        }}
        onChange={(activeKeys) => {
          setComponentState((prev) => ({...prev, activePanel: activeKeys as string[]}));
        }}
        style={antdStyles.collapse}>
        <Panel forceRender key={RECENT_CLINICAL_EVENT} header={

          <HStack alignItems={'center'} bgColor={Colors.Custom.BackgroundColor} borderTopRadius={16}>
            <Text
              size={'lgMedium'}
              color={Colors.FoldPixel.GRAY300}
              fontWeight={500}
              {...testID(TestIdentifiers.recentClinicalEvents)}
            >
              {intl.formatMessage({ id: 'recentClinicEvents' })}
            </Text>
          </HStack>}>
          {isHIEEnabled && (
            <AvailableHieDataView
              hieRequestsLoading={HieRequestsLoading}
              hieRequests={pendingRequests as IHIEReconciliationRequest[]}
              onReviewClick={() => {
                setComponentState((prev) => ({
                  ...prev,
                  showHieRequestsDrawer: true,
                }));
              }}
            />
          )}
          {isMSOEnabled && (
            <ReconcileRequestCountView
              resourceTypes={resourceTypes}
              patientId={ehrPatientId || props.personData?.patientId || ''}
              contactUuid={props.personData.contactUUID || ''}
              accountLocationId={accountLocationUuid || props.personData.accountLocationUuid || ''}
            />
          )}
          <PatientReportedView
            personData={props.personData}
            onListUpdate={(isDataAvailable) => {
              setComponentState((prev) => ({
                ...prev, patientReportedDataAvailable: isDataAvailable,
                activePanel: prev.reportsDataAvailable || isDataAvailable ? [RECENT_CLINICAL_EVENT] : [],
              }));
            }}
          />
          <Divider />
          {/* <RecentReport personData={props.personData}/> */}
          {canShowOrderAndReports && (
            <PendingOrders
              personData={props.personData}
              unformmatedContactData={props?.unformmatedContactData}
              onListUpdate={(isDataAvailable) => {
                setComponentState((prev) => ({
                  ...prev,
                  reportsDataAvailable: isDataAvailable,
                  activePanel: prev.patientReportedDataAvailable || isDataAvailable ? [RECENT_CLINICAL_EVENT] : [],
                }));
              }}
            />
          )}

        </Panel>
      </Collapse>
      {componentState.showHieRequestsDrawer &&
        <PendingHieRequestsDrawer
          patientId={props.personData?.patientId || ''}
          patientUuid={props.personData.patientUuid || ''}
          accountLocationId={props.personData.accountLocationUuid || ''}
          contactUuid={props.personData.contactUUID}
          onClose={()=> {
            fetchReconciliationRequests(false);
            setComponentState((prev) => ({...prev, showHieRequestsDrawer: false}));
          }}
          contactLocationId={
            props.personData.contactData?.contactPracticeLocations?.[0]
              ?.accountLocation?.uuid
          }
      />}
    </Box>
  );
}

const antdStyles: Record<string, React.CSSProperties> = {
  collapse: {
    borderRadius: 16,
    borderWidth: 0,
    padding: 0,
    background: '#f2f4f7'
  }
}
