import {Select, Tag} from 'antd';
import {FormControl} from 'native-base';
import React, {useMemo} from 'react';
import {View, ViewStyle} from 'react-native';
import {DisplayText} from '../../../../../common/DisplayText/DisplayText';
import {getEHRDisplayNameByCode} from '../../../../../../utils/commonUtils';
import {IFormLocation} from '../AddOrUpdateForm';
import {testID} from '../../../../../../testUtils';
import { Colors } from '../../../../../../styles';
import Label from '../../../CustomFormEngine/BaseComponents/Label';

interface IFormEHRAndLocationSelect {
  selectedEHRCode: string;
  selectedLocationId: IFormLocation[];
  enabledFields: {
    ehr: boolean;
    locations: boolean;
  };
  onChange: (locationIdList: IFormLocation[], ehrCode: string) => void;
  containerStyles?: ViewStyle;
  ehrContainerStyles?: ViewStyle;
  locationContainerStyles?: ViewStyle;
  isEHRDisabled?: boolean;
  ehrList: string[];
  locationList: {
    locationUUID: string;
    practiceLocationName: string;
  }[];
  loading?: {
    ehr: boolean;
    location: boolean;
  };
  showErrors?: boolean;
  nonRemovableLocations?: Set<string>;
  isLocationDisabled?: boolean;
}

const FormEHRAndLocationSelect = (props: IFormEHRAndLocationSelect) => {
  const {selectedEHRCode, selectedLocationId, ehrList, locationList, isLocationDisabled} = props;
  const handleEHRCodeChange = (value: any, option: any | any[]) => {
    if (value) {
      props.onChange(selectedLocationId, value);
    }
  };
  const nonRemovableLocationIds = useMemo(
    () =>
      props.nonRemovableLocations
        ? Array.from(props.nonRemovableLocations)
        : [],
    [props.nonRemovableLocations]
  );

  const handleLocationSelect = (value: string[], option: (any | any)[]) => {
    if (props.nonRemovableLocations) {
      // Check if user is trying to remove a non-removable location
      const isRemovingNonRemovableLocation = nonRemovableLocationIds.some(
        (id) => !value.includes(id)
      );

      if (isRemovingNonRemovableLocation) {
        // Don't call props.onChange if trying to remove a non-removable location
        return;
      }
    }
    props.onChange(
      value.map((locationId: string) => ({locationId})),
      selectedEHRCode
    );
  };

  if (Object.keys(props.enabledFields).length === 0) {
    return <></>;
  }


  const tagRender = (tagProps: any) => {
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const label = tagProps.label;
    const isNonRemovableLocation = props.nonRemovableLocations?.has(
      tagProps.value
    );
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={isNonRemovableLocation ? false : tagProps.closable}
        onClose={isNonRemovableLocation ? undefined : tagProps.onClose}
        className="custom-tag"
      >
        {label}
      </Tag>
    );
  };

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        ...props?.containerStyles,
      }}
    >
      {
        <FormControl
          style={{
            marginRight: 10,
            ...props?.ehrContainerStyles,
          }}
          isInvalid={props.showErrors && selectedEHRCode?.length === 0}
        >
          <div className="flex flex-col gap-1">
            <Label label="Select EHR" isRequired />
            <Select
              {...testID('SelectEHR')}
              onChange={handleEHRCodeChange}
              placeholder="Select EHR"
              value={
                !!selectedEHRCode
                  ? getEHRDisplayNameByCode(selectedEHRCode)
                  : undefined
              }
              disabled={props.isEHRDisabled}
              className="custom-select-box"
            >
              {ehrList?.map((ehr) => (
                <Select.Option
                  key={ehr}
                  value={ehr}
                  {...testID(`SelectEHR-${ehr}`)}
                >
                  {getEHRDisplayNameByCode(ehr)}
                </Select.Option>
              ))}
            </Select>
            {props.showErrors && selectedEHRCode?.length === 0 && (
              <FormControl.ErrorMessage>
                Please select EHR
              </FormControl.ErrorMessage>
            )}
          </div>
        </FormControl>
      }
      <FormControl
        style={{
          maxWidth: 300,
          ...props?.locationContainerStyles,
        }}
        isInvalid={props.showErrors && selectedLocationId?.length === 0}
      >
        <div className="flex flex-col gap-1">
          <Label label="Select Location" isRequired />
          <Select
            allowClear
            tagRender={tagRender}
            {...testID('SelectLocation')}
            mode="multiple"
            placeholder="Select locations"
            onChange={handleLocationSelect}
            disabled={
              isLocationDisabled ||
              !selectedEHRCode
            }
            value={
              !!selectedLocationId?.length
                ? selectedLocationId?.map((location) => location.locationId)
                : undefined
            }
          >
            {locationList.map((location) => (
              <Select.Option
                {...testID(`SelectLocation-${location.practiceLocationName}`)}
                key={location.locationUUID}
                value={location.locationUUID}
                disabled={props.nonRemovableLocations?.has(location.locationUUID)}
              >
                {location.practiceLocationName}
              </Select.Option>
            ))}
          </Select>
          {props.showErrors && selectedLocationId?.length === 0 && (
            <FormControl.ErrorMessage>
              Please select location
            </FormControl.ErrorMessage>
          )}
        </div>
      </FormControl>
    </View>
  );
};

export default FormEHRAndLocationSelect;
