import {Select} from 'antd';
import {Skeleton, View, Text} from 'native-base';
import {default as React, useEffect, useState} from 'react';
import {ISearchFieldProps} from '../../../Interfaces';
import {getPractitionersWithHeaders} from '../../../services/CommonService/AidBoxService';
import {getPatientName} from '../../../utils/commonUtils';

interface IFHIRPractitionerSearch extends ISearchFieldProps {
  disabled: boolean;
  data: any[];
  loading: boolean;
  placeholder?: string;
  className?: string;
}

const FHIRPractitionerSearch = (props: IFHIRPractitionerSearch) => {
  const {value, isShowError, onChange, data, loading, placeholder, className} = props;


  const getDataFromId = (id: any) => {
    const matchedData = data.filter((form) => {
      return form.id === id;
    });
    if (matchedData.length > 0) {
      return {
        id: matchedData[0].id,
        name: matchedData[0].name,
        telecom: matchedData[0].telecom,
      };
    }
  };

  return (
    <>
      {loading && (
        <View>
          <Skeleton rounded="md" />
        </View>
      )}
      {!loading && (
        <Select
          size="large"
          allowClear
          showSearch
          filterOption={(input, data) => data?.title?.toLowerCase()?.includes(input?.toLowerCase())}
          value={value ? value.id : undefined}
          // onSearch={debounce(onSearch, 500)}
          disabled={props.disabled}
          onChange={(value: any[], data: any) => {
            if (data && data.value) {
              onChange(getDataFromId(data.value));
            } else {
              onChange(undefined);
            }
          }}
          placeholder={placeholder || "Search"}
          style={{height: '40px'}}
          className={isShowError && !value ? `pami-search field-error ${className || ''}` : `pami-search ${className || ''}`}
        >
          {data.map((item) => {
            const name = getPatientName(item);
            return (
              <Select.Option key={item.id} value={item.id} title={name}>
                <Text>
                  {name}
                </Text>
              </Select.Option>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default FHIRPractitionerSearch;
