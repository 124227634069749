import {Divider, Stack, Text, View} from 'native-base';
import React, {useContext, useEffect, useState} from 'react';
import {SCORE_TYPE} from '../constants';
import './PopOverRafScore.css';
import {Colors} from '../styles';
import {getHccScoreData, hasValue} from '../utils/commonUtils';
import Feather from 'react-native-vector-icons/Feather';
import {ConditionsAndHCCs} from './ConditionsAndHCCs';
import {IcdConditionsList, IContactScoreReference} from './RafScore/interface';
import {CommonDataContext} from '../context/CommonDataContext';

export interface IPopOver {
  contactScore?: {
    foldScore: number;
    hccV24: number;
    hccV28: number;
    reference: IContactScoreReference;
  }
  contactScoreAudits? : {
    scoreDate : string;
    scoreType : string;
    scoreValue : number;
  }[]
  icdConditionsList?: IcdConditionsList[];
}
interface IComponentState {
  conditionsAndHccsList: any[];
  d2List: any[];
  interactionEffectList: any[];
}

export const RAFScorePopoverContent = (props: IPopOver) => {
  const [componentState, setComponentState] = useState<IComponentState>({
    conditionsAndHccsList: [],
    d2List: [],
    interactionEffectList: [],
  });
  
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const referenceData = props?.contactScore?.reference;

  const { hccScore, hccScoreDiff, hccScoreValue, formattedHccScoreDiff } = getHccScoreData(
    props?.contactScore,
    props?.contactScoreAudits,
    SCORE_TYPE,
    hasValue
  );
  useEffect(() => {
    getFilteredData();
  }, []);

  const getFilteredData = () => {
    const allDataWithValues = [];
    const conditionsAndHccsList: any[] = [];
    const d2List: any[] = [];
    const interactionEffectList: any[] = [];
    const data = referenceData?.hccRiskScoreData?.hccV28RiskScoreData?.output;
    data?.hcc_lst?.forEach((hcc: any) => {
      const key = `CNA_${hcc}`;
      const value = data.details[key];
      if (value !== undefined) {
        allDataWithValues[hcc] = value;
        if (hcc.startsWith('HCC')) {
          conditionsAndHccsList.push({[hcc]: value});
        } else if (hcc === 'D2') {
          d2List.push({[hcc]: value});
        } else {
          interactionEffectList.push({[hcc]: value});
        }
      }
    });
    setComponentState((prev) => ({
      ...prev,
      conditionsAndHccsList: conditionsAndHccsList,
      d2List: d2List,
      interactionEffectList: interactionEffectList,
    }));
  };

  const getConditionsAndHCCsElement = () => {
    return (
      <ConditionsAndHCCs
          contactScore={props?.contactScore}
          conditionsAndHccsList={componentState.conditionsAndHccsList}
          icdConditionsList={props?.icdConditionsList}
        />
    );
  };

  const getRAFScoreDiffElement = () => {
    return hccScore || hccScore == 0 ? (
      <Stack
        direction={'row'}
        alignItems={'center'}
        backgroundColor={Colors.Custom.Gray100}
        borderRadius={4}
        marginLeft={2}
      >
        {hccScoreDiff !== 0 && (
          <Text
            wordBreak={'break-word'}
            size={'smLight'}
            color={Colors.Custom.Gray400}
            fontSize={14}
            alignSelf={'center'}
            margin={1}
          >
            {formattedHccScoreDiff}
          </Text>
        )}
        {hccScoreDiff > 0 && (
          <Feather name="arrow-up" size={15} color={Colors.Custom.Gray400} />
        )}
        {hccScoreDiff < 0 && (
          <Feather name="arrow-down" size={15} color={Colors.Custom.Gray400} />
        )}
      </Stack>
    ) : (
      <></>
    );
  };

  const getDemographicsElement = () => {
    const data = referenceData?.hccRiskScoreData?.hccV28RiskScoreData;
    const genderCode = data?.input?.sex;
    const displayGender = genderCode 
    const displayAgeValue = data?.input?.age;
    const displayAgeScore = data?.output?.risk_score_age;
    const medicaidValue =
      componentState?.d2List?.length > 0 ? componentState.d2List[0].D2 : '-';
    return (
      <View paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        <Text
          color={Colors.Custom.Gray400}
          fontSize={14}
        >{`Demographics`}</Text>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          width="100%"
        >
          <Text
            alignSelf={'flex-start'}
            fontSize={14}
          >{`${displayGender}, age ${displayAgeValue}`}</Text>
          <Text
            alignSelf={'flex-end'}
            color={Colors.Custom.Gray400}
            fontSize={14}
          >{`${displayAgeScore}`}</Text>
        </Stack>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          width="100%"
          paddingBottom={1}
        >
          <Text
            alignSelf={'flex-start'}
            fontSize={14}
          >{`Medicaid eligibility`}</Text>
          <Text
            alignSelf={'flex-end'}
            color={Colors.Custom.Gray400}
            fontSize={14}
          >{`${medicaidValue}`}</Text>
        </Stack>
      </View>
    );
  };

  const getInteractionEffectElement = () => {
    const interactionEffectList = componentState?.interactionEffectList;
    return (
      <View paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        <Text
          color={Colors.Custom.Gray400}
          fontSize={14}
        >{`Interaction Effect`}</Text>
        {interactionEffectList?.map((effect: any, index: number) => {
          const key = Object.keys(effect)[0];
          const displayName = key.replace(/_/g, ' + ');
          const value = effect[key];
          return (
            <Stack
              key={index}
              flexDirection={'row'}
              justifyContent={'space-between'}
              width="100%"
            >
              <Text alignSelf={'flex-start'} fontSize={14}>
                {displayName}
              </Text>
              <Text
                alignSelf={'flex-end'}
                color={Colors.Custom.Gray400}
                fontSize={14}
              >
                {value}
              </Text>
            </Stack>
          );
        })}
      </View>
    );
  };

  const getColorCodeForHCC = (hccScore: number) => {
    if (hccScore >= 0 && hccScore <= 1) {
      return Colors.Custom.SuccessColor;
    }
    if (hccScore > 1 && hccScore <= 2) {
      return Colors.Custom.orange900;
    }
    if (hccScore > 2 && hccScore <= 3) {
      return Colors.Custom.Red100;
    }
  };
  const hccScoreColor = getColorCodeForHCC(hccScore || 0);

  return (
    <Stack flexDirection={'column'} width={'250px'}>
      <Stack flexDirection={'row'} paddingBottom={1} alignItems={'center'} paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        <Text color={Colors.Custom.Gray400} fontSize={14}>
          {`RAF Score: `}{' '}
        </Text>
        <Text color={hccScoreColor} fontSize={14}>
          {`${hccScoreValue}`}
        </Text>
        {getRAFScoreDiffElement()}
      </Stack>
      <Text color={Colors.Custom.Gray400} fontSize={12} paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}>
        {`Score Breakdown`}{' '}
      </Text>
      {getConditionsAndHCCsElement()}
      {getDemographicsElement()}
      {getInteractionEffectElement()}
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        width="100%"
        paddingY={2} paddingX={4} borderBottomWidth={1} borderColor={Colors.Custom.Gray200}
      >
        <Text
          alignSelf={'flex-start'}
          fontWeight={800}
          fontSize={14}
        >{`Total RAF Score`}</Text>
        <Text
          alignSelf={'flex-end'}
          fontSize={14}
          fontWeight={800}
        >{`${hccScoreValue}`}</Text>
      </Stack>
    </Stack>
  );
};
