import { CARE_PROGRAM_TYPE_CODES } from "../../../../constants/MlovConst";

export enum DayOptimizerViewAction {
  SHOW_PATIENT_DETAIL_VIEW = 'SHOW_PATIENT_DETAIL_VIEW',
  CLEAR_VIEW = 'CLEAR_VIEW',
  REDIRECT_PATIENT_VIEW = 'REDIRECT_PATIENT_VIEW',
  CLICK_ON_ALERT = 'CLICK_ON_ALERT',
}

export enum DayOptimizerSelectedViewCode {
  PATIENT_DETAIL_VIEW = 'PATIENT_DETAIL_VIEW',
}

export enum DayOptimizerSelectedColumnCode {
  ALERT = 'ALERT',
}

export const QA_ENVIRONMENT = 'qa.';
export const DAY_OPTIMIZER_TABLE_CODE = 'DAY_OPTIMIZER';
export const DAY_OPTIMIZER_ENTITY_CODE = 'DAY_OPTIMIZER';

export enum DayOptimizerColumnChangeType {
  PRIORITY = 'priority',
  VISIBILITY = 'visibility',
  RESET_DEFAULT = 'reset_default'
}

export enum DayOptimizerColumnCode {
  RAF_SCORE = 'RAF_SCORE',
  PRIORITY = 'PRIORITY',
  NAME = 'NAME',
  ALERTS = 'ALERTS',
  FOLD_ID = 'FOLD_ID',
  LOCATION = 'LOCATION',
  PCP = 'PCP',
  CLIENT_ID = 'CLIENT_ID',
  REASON_FOR_PRIORITISATION = 'REASON_FOR_PRIORITISATION',
  REASONS_QA = 'REASONS_(QA)',
  ACTIONS = 'ACTIONS',
  TAGS = 'TAGS',
  SCORE = 'SCORE'
}

export enum DayOptimizerColumnOrder {
  ASCENDING = 'ascend',
  DESCENDING = 'descend',
  ASC = 'asc',
  DESC = 'desc',
}

export const DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION = {
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_DISCHARGE_DATE_GT_24_HRS: {
    template:
      '{{patientFirstName}} was discharged from {{hospitalName}} from inpatient setup on {{dischargeDateTime}} i.e {{hoursAgo}} hours ago and was diagnosed with {{icdCodes}}.',
    getVariables: (contact: any, carerogramData?: any) => {
      const dischargeDate = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'dischargeDate')?.attributeValue?.valueString;
      const facility = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'facility')?.attributeValue?.valueString;
      const primaryDiagnosisCodes = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'primaryDiagnosisCode')?.attributeValue?.valueString;
      const allIcdCodes = (Array.isArray(primaryDiagnosisCodes) ? primaryDiagnosisCodes : [primaryDiagnosisCodes]).filter(Boolean).join(', ');

      return {
        patientFirstName: contact?.firstName || 'Patient',
        hospitalName: facility || 'Hospital',
        dischargeDateTime: new Date(dischargeDate).toLocaleDateString() || 'Unknown Date',
        hoursAgo: Math.floor((Date.now() - new Date(dischargeDate).getTime()) / (1000 * 60 * 60)) || 'unknown',
        icdCodes: allIcdCodes || 'no diagnoses',
      };
    },
    action: 'Review TCM',
  },
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_NEW_DISCHARGE_DATE_GT_24_HRS: {
    template:
      '{{patientFirstName}} was discharged from {{hospitalName}} from inpatient setup on {{dischargeDateTime}} i.e {{hoursAgo}} hours ago and was diagnosed with {{icdCodes}}.',
    getVariables: (contact: any, carerogramData?: any) => {
      const dischargeDate = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'dischargeDate')?.attributeValue?.valueString;
      const facility = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'facility')?.attributeValue?.valueString;
      const primaryDiagnosisCodes = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'primaryDiagnosisCode')?.attributeValue?.valueString;
      const allIcdCodes = (Array.isArray(primaryDiagnosisCodes) ? primaryDiagnosisCodes : [primaryDiagnosisCodes]).filter(Boolean).join(', ');

      return {
        patientFirstName: contact?.firstName || 'Patient',
        hospitalName: facility || 'Hospital',
        dischargeDateTime: new Date(dischargeDate).toLocaleDateString() || 'Unknown Date',
        hoursAgo: Math.floor((Date.now() - new Date(dischargeDate).getTime()) / (1000 * 60 * 60)) || 'unknown',
        icdCodes: allIcdCodes || 'no diagnoses',
      };
    },
    action: 'Review TCM',
  },
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_IN_PROGRESS_OUTREACH_SUCCESS: {
    template: 'High Complexity TCM: Follow-Up Visit Due.',
    getVariables: () => ({
      programName: 'TCM',
    }),
    action: 'Follow-up',
  },
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_IN_PROGRESS_OUTREACH_FAILED: {
    template: 'Due to unsuccessful last outreach we recommend you to reach out to the patient again.',
    getVariables: () => ({}),
    action: 'Follow-up',
  },
  LAST_N_DAYS_COMMUNICATION_SMS: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'SMS',
    }),
    action: 'Send SMS',
  },
  ACTIVE_CHF_PROGRAM: {
    template: 'Patient has a follow-up scheduled for CHF.',
    getVariables: () => ({
      programName: 'CHF',
    }),
    action: 'Follow-up',
  },
  LAST_N_DAYS_COMMUNICATION_EMAIL: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'Email',
    }),
    action: 'Send Email',
  },
  LAST_N_DAYS_COMMUNICATION_CHAT: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'Chat',
    }),
    action: 'Send Chat',
  },
  LAST_N_DAYS_COMMUNICATION_PHONE: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'Phone',
    }),
    action: 'Call Back',
  },
  AWV_DUE_TODAY: {
    template: 'AWV appointment is due at {{time}}.',
    getVariables: (contact: any, carerogramData?: any) => ({
      time: carerogramData?.startDateTime || 'today',
    }),
    action: 'Initiate AWV',
  },
  ACTIVE_CCM_CONTACT_CARE_PROGRAM_WITH_STATUS_NEW_AND_IN_PROGRESS: {
    template: '{{patientFirstName}} meets CCM eligibility and has consented to enrollment.',
    getVariables: (contact: any, carerogramData?: any) => ({
      patientFirstName: contact?.firstName || 'Patient',
    }),
    action: 'Follow-up',
  }
} as const;

export const DAY_OPTIMIZER_RULE_SCORE_FACTOR_DESCRIPTION_FOR_QA_ENV = {
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_DISCHARGE_DATE_GT_24_HRS: {
    template:
      '{{patientFirstName}} was discharged from {{hospitalName}} from inpatient setup on {{dischargeDateTime}} i.e {{hoursAgo}} hours ago and was diagnosed with {{icdCodes}}.',
    getVariables: (contact: any, carerogramData?: any) => {
      const dischargeDate = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'dischargeDate')?.attributeValue?.valueString;
      const facility = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'facility')?.attributeValue?.valueString;
      const primaryDiagnosisCodes = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'primaryDiagnosisCode')?.attributeValue?.valueString;
      const allIcdCodes = (Array.isArray(primaryDiagnosisCodes) ? primaryDiagnosisCodes : [primaryDiagnosisCodes]).filter(Boolean).join(', ');

      return {
        patientFirstName: contact?.firstName || 'Patient',
        hospitalName: facility || 'Hospital',
        dischargeDateTime: new Date(dischargeDate).toLocaleDateString() || 'Unknown Date',
        hoursAgo: Math.floor((Date.now() - new Date(dischargeDate).getTime()) / (1000 * 60 * 60)) || 'unknown',
        icdCodes: allIcdCodes || 'no diagnoses',
      };
    },
    action: 'Review TCM',
  },
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_NEW_DISCHARGE_DATE_GT_24_HRS: {
    template:
      '{{patientFirstName}} was discharged from {{hospitalName}} from inpatient setup on {{dischargeDateTime}} i.e {{hoursAgo}} hours ago and was diagnosed with {{icdCodes}}.',
    getVariables: (contact: any, carerogramData?: any) => {
      const dischargeDate = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'dischargeDate')?.attributeValue?.valueString;
      const facility = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'facility')?.attributeValue?.valueString;
      const primaryDiagnosisCodes = carerogramData?.additionalAttributes?.find((attr: any) => attr.attributeKey === 'primaryDiagnosisCode')?.attributeValue?.valueString;
      const allIcdCodes = (Array.isArray(primaryDiagnosisCodes) ? primaryDiagnosisCodes : [primaryDiagnosisCodes]).filter(Boolean).join(', ');

      return {
        patientFirstName: contact?.firstName || 'Patient',
        hospitalName: facility || 'Hospital',
        dischargeDateTime: new Date(dischargeDate).toLocaleDateString() || 'Unknown Date',
        hoursAgo: Math.floor((Date.now() - new Date(dischargeDate).getTime()) / (1000 * 60 * 60)) || 'unknown',
        icdCodes: allIcdCodes || 'no diagnoses',
      };
    },
    action: 'Review TCM',
  },
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_IN_PROGRESS_OUTREACH_SUCCESS: {
    template: 'High Complexity TCM: Follow-Up Visit Due',
    getVariables: () => ({
      programName: 'TCM',
    }),
    action: 'Follow-up',
  },
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_IN_PROGRESS_OUTREACH_FAILED: {
    template: 'Due to unsuccessful last outreach we recommend you to reach out to the patient again.',
    getVariables: () => ({}),
    action: 'Follow-up',
  },
  LAST_N_DAYS_COMMUNICATION_SMS: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'SMS',
    }),
    action: 'Send SMS',
  },
  LAST_N_DAYS_COMMUNICATION_EMAIL: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'Email',
    }),
    action: 'Send Email',
  },
  LAST_N_DAYS_COMMUNICATION_CHAT: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'Chat',
    }),
    action: 'Send Chat',
  },
  LAST_N_DAYS_COMMUNICATION_PHONE: {
    template: 'Patient has reached out using {{communicationType}}',
    getVariables: () => ({
      communicationType: 'Phone',
    }),
    action: 'Call Back',
  },
  AWV_DUE_TODAY: {
    template: 'AWV appointment is due at {{time}}.',
    getVariables: (contact: any, carerogramData?: any) => ({
      time: carerogramData?.startDateTime || 'today',
    }),
  },
  ACTIVE_CCM_CONTACT_CARE_PROGRAM_WITH_STATUS_NEW_AND_IN_PROGRESS: {
    template: '{{patientFirstName}}  meets CCM eligibility and has consented to enrollment.',
    getVariables: (contact: any, carerogramData?: any) => ({
      patientFirstName: contact?.firstName || 'Patient',
    }),
    action: 'Follow-up',
  },
  TOP_CHRONIC_CONDITIONS: 'Top chronic conditions (per condition)',
  OTHER_CHRONIC_CONDITIONS: 'Other chronic conditions (per condition)',
  TOTAL_ACTIVE_CONTACT_CARE_PROGRAMS: 'Total active care programs (per care program)',
  TOTAL_INACTIVE_CONTACT_CARE_PROGRAMS: 'Total inactive contact care programs (per care program)',
  TOTAL_ACTIVE_MEDICATION: 'Total active medications (per medication)',
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_DISCHARGE_DATE: 'Active TCM care program with discharge date',
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_SIMPLE_TYPE: 'Active TCM care program of simple type',
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_COMPLEX_TYPE: 'Active TCM care program of complex type',
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_EXPIRES_IN_1_DAY: 'Active TCM care program expiring in 1 day',
  ACTIVE_TCM_CONTACT_CARE_PROGRAM_DISCHARGE_SUMMARY_AVAILABLE: 'Active TCM care program with discharge summary available',
  AWV_DUE_IN_MORE_THAN_60_DAYS: 'AWV due in more than 60 days',
  AWV_DUE_IN_31_TO_60_DAYS: 'AWV due in 31 to 60 days',
  AWV_DUE_IN_1_TO_30_DAYS: 'AWV due in 1 to 30 days',
  AWV_OVER_DUE_IN_10_DAYS: 'AWV overdue by up to 10 days',
  AWV_OVER_DUE_IN_21_TO_30_DAYS: 'AWV overdue by 21 to 30 days',
  AWV_OVER_DUE_IN_31_TO_60_DAYS: 'AWV overdue by 31 to 60 days',
  AWV_OVER_DUE_IN_61_TO_90_DAYS: 'AWV overdue by 61 to 90 days',
  AWV_OVER_DUE_IN_91_TO_120_DAYS: 'AWV overdue by 91 to 120 days',
  AWV_OVER_DUE_IN_MORE_THAN_120_DAYS: 'AWV overdue by more than 120 days',
  AWV_OVER_DUE_IN_11_TO_20_DAYS: 'AWV overdue by 11 to 20 days',
  LAST_N_DAYS_COMMUNICATION: 'Recent communication within the last N days',
  NOT_MET_QUALITY_MEASURE: 'Quality measure not met',
  APPOINTMENT_IN_3_DAYS_WITH_OPEN_FORM_LOG: 'Appointment within 3 days with open form log',
  UPCOMING_AWV_TYPE_IS_IPPE: 'Upcoming AWV type is Initial Preventive Physical Examination (IPPE)',
  UPCOMING_AWV_TYPE_IS_INITIAL_AWV: 'Upcoming AWV type is Initial Annual Wellness Visit',
  UPCOMING_AWV_TYPE_IS_SUBSEQUENT_AWV: 'Upcoming AWV type is Subsequent Annual Wellness Visit',
  IS_HRA_FORM_SUBMITTED: 'Health Risk Assessment (HRA) form submitted',
  NON_AWV_APPOINTMENT_IS_SCHEDULED_TODAY: 'Appointment scheduled for today',
  RED_FLAG_SURVEYS : 'Red flag forms',
  IS_CARE_PROGRAM_CONSENT_AVAILABLE: 'Care program consent available',
  APPOINTMENT_IN_3_DAYS_WITH_OPEN_ORDERS: 'Appointment within 3 days with open orders',
  PATIENT_ENGAGEMENT_SCORE : 'Patient engagement score'
} as const;

/*
  'NOT_MET_QUALITY_MEASURE' - ?
  'APPOINTMENT_IN_3_DAYS_WITH_OPEN_FORM_LOG' - ?
  'NON_AWV_APPOINTMENT_IS_SCHEDULED_TODAY' - ?
*/

export const TCM_FACTORS = [
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_DISCHARGE_DATE',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_SIMPLE_TYPE',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_COMPLEX_TYPE',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_DISCHARGE_DATE_GT_24_HRS',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_EXPIRES_IN_1_DAY',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_NEW_DISCHARGE_DATE_GT_24_HRS',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_IN_PROGRESS_OUTREACH_SUCCESS',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_IN_PROGRESS_OUTREACH_FAILED',
  'ACTIVE_TCM_CONTACT_CARE_PROGRAM_DISCHARGE_SUMMARY_AVAILABLE',
];

export const CHF_FACTORS = [
  'ACTIVE_CHF_PROGRAM',
  'CUSTOM_MESSAGE',
];

export const AWV_FACTORS = [
  'UPCOMING_AWV_TYPE_IS_SUBSEQUENT_AWV',
  'UPCOMING_AWV_TYPE_IS_INITIAL_AWV',
  'UPCOMING_AWV_TYPE_IS_IPPE',
  'AWV_OVER_DUE_IN_11_TO_20_DAYS',
  'AWV_DUE_IN_MORE_THAN_60_DAYS',
  'AWV_DUE_IN_31_TO_60_DAYS',
  'AWV_DUE_IN_1_TO_30_DAYS',
  'AWV_OVER_DUE_IN_10_DAYS',
  'AWV_OVER_DUE_IN_21_TO_30_DAYS',
  'AWV_OVER_DUE_IN_31_TO_60_DAYS',
  'AWV_OVER_DUE_IN_61_TO_90_DAYS',
  'AWV_OVER_DUE_IN_91_TO_120_DAYS',
  'AWV_OVER_DUE_IN_MORE_THAN_120_DAYS',
  'AWV_DUE_TODAY',
];

export const CCM_FACTORS = [
  'ACTIVE_CCM_CONTACT_CARE_PROGRAM_WITH_STATUS_NEW_AND_IN_PROGRESS',
  'TOP_CHRONIC_CONDITIONS',
  'OTHER_CHRONIC_CONDITIONS',
  'TOTAL_ACTIVE_CONTACT_CARE_PROGRAMS',
  'TOTAL_INACTIVE_CONTACT_CARE_PROGRAMS',
  'TOTAL_ACTIVE_MEDICATION',
];

export const HRA_FACTORS = [
  'IS_HRA_FORM_SUBMITTED',
];

export const COMMUNICATION_SMS_FACTORS = [
  'LAST_N_DAYS_COMMUNICATION_SMS',
];

export const COMMUNICATION_EMAIL_FACTORS = [
  'LAST_N_DAYS_COMMUNICATION_EMAIL',
];

export const COMMUNICATION_CHAT_FACTORS = [
  'LAST_N_DAYS_COMMUNICATION_CHAT',
];

export const COMMUNICATION_PHONE_FACTORS = [
  'LAST_N_DAYS_COMMUNICATION_PHONE',
];

export const FACTOR_TO_ACTION_MAP: Record<string, string[]> = {
  chf: CHF_FACTORS,
  awv: AWV_FACTORS,
  tcm: TCM_FACTORS,
  ccm: CCM_FACTORS,
  sms: COMMUNICATION_SMS_FACTORS,
  email: COMMUNICATION_EMAIL_FACTORS,
  chat: COMMUNICATION_CHAT_FACTORS,
  phone: COMMUNICATION_PHONE_FACTORS,
};

export const careProgramTypeArray = [
  CARE_PROGRAM_TYPE_CODES.TCM,
  CARE_PROGRAM_TYPE_CODES.CCM,
  CARE_PROGRAM_TYPE_CODES.AWV,
];

export enum DayOptimizerActionStatus {
  SUCCESS = 'SUCCESS',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}

export enum DAY_OPTIMIZER_EVENTS {
  ON_RIBBON_RESIZE = 'DO_ON_RIBBON_RESIZE',
  REMOVE_PATIENT = 'DO_REMOVE_PATIENT',
  ADD_PATIENT = 'DO_ADD_PATIENT',
  ON_SORT_CHANGE = 'DO_ON_SORT_CHANGE',
}

export enum ADT_EVENT_CODES {
  ADMISSION = 'ADMISSION',
  DISCHARGE = 'DISCHARGE',
  TRANSFER = 'TRANSFER',
}

export enum DO_RIBBON_ACTIONS {
  VIEW_DETAILS = 'VIEW_DETAILS',
  ADD_TO_LIST = 'ADD_TO_LIST',
  MARK_AS_READ = 'MARK_AS_READ',
  REMOVE_PATIENT = 'REMOVE_PATIENT',
  VIEW_EVENT = 'VIEW_EVENT',
}

export enum DO_RIBBON_ACTION_LOADING_STATUS {
  VALIDATION = 'VALIDATION',
  ADD_TO_LIST = 'ADD_TO_LIST',
  REMOVE_PATIENT = 'REMOVE_PATIENT',
  MARK_AS_READ = 'MARK_AS_READ',
}
