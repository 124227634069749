import {
  Button,
  HStack,
  Pressable,
  Text,
  View,
  VStack
} from 'native-base';
import Feather from 'react-native-vector-icons/Feather';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {Colors} from '../../../styles';
import {testID, TestIdentifiers} from '../../../testUtils';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {getFormattedDateString} from '../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {styles} from './ContactNotesViewStyles';
const ContactNoteItem = (props: {
  index: number;
  item: any;
  onNotesListActionPerform: (actionCode: string, actionData?: any) => void;
  onActionPerformed: (actionCode: string, actionData?: any) => void;
  contactData: IContact;
}) => {
  const {item} = props;
  const isMultiTenancyEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const isLocationEnable = isAccountConfigEnabled(CONFIG_CODES.ENABLE_LOCATION_HANDLING);
  return (
    <VStack key={`ContactNotesList_${props.index}`}>
      {props.index !== 0 && (
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: Colors.Custom.Gray200,
          }}
        />
      )}
      <Pressable
        key={props.index}
        accessible={false}
        onPress={() => {
          props.onNotesListActionPerform(
            COMMON_ACTION_CODES.ROW_CLICKED,
            props.item
          );
        }}
        style={{
          marginVertical: 8,
        }}
      >
        <HStack
          style={[styles.displayListItem]}
          flex={1}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <VStack flex={1} justifyContent={'center'}>
            <View display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Text
                isTruncated
                maxW={300}
                size={'smLight'}
                fontWeight={500}
                order={0}
                fontSize={isWeb() ? 14 : 16}
                {...testID(TestIdentifiers.contactNote)}
              >
                {props.item.content.replace(/\n/g, ' ')}
              </Text>
            </View>
            <View
              my={1}
              flexWrap={'wrap'}
              flexDirection={isWeb() ? 'row' : 'column'}
            >
              {props.item?.user?.name && (
                <Text
                  size={'xsMedium'}
                  color={Colors.Custom.Gray400}
                  fontSize={isWeb() ? 12 : 14}
                  {...testID('userName')}
                >
                  {`${props.item?.user?.name},  `}
                </Text>
              )}
              <HStack>
                <Text
                  size={'xsMedium'}
                  color={Colors.Custom.Gray400}
                  fontSize={isWeb() ? 12 : 14}
                  {...testID(TestIdentifiers.lastUpdatedAt)}
                >
                  {'last updated date:  '}
                </Text>
                <Text
                  size={'xsMedium'}
                  color={Colors.Custom.Gray400}
                  style={{fontStyle: 'italic'}}
                  fontSize={isWeb() ? 12 : 14}
                  {...testID(TestIdentifiers.dateInfoMessage)}
                >
                  {getFormattedDateString(props.item.updatedAt + 'Z', 'dob')}
                </Text>
              </HStack>
            </View>
          </VStack>
          <HStack justifyContent={'flex-end'}>
            <Button
              onPress={() => {
                props.onNotesListActionPerform(
                  COMMON_ACTION_CODES.EDIT,
                  props.item
                );
              }}
              accessibilityLabel={TestIdentifiers.editBtn}
              {...testID(TestIdentifiers.editBtn)}
              backgroundColor={'transparent'}
              leftIcon={
                <Feather
                  name="edit-2"
                  size={15}
                  color={Colors.Custom.Gray600}
                />
              }
            />
            {!isMultiTenancyEnabled && !isLocationEnable && (
              <Button
                onPress={() => {
                  props.onActionPerformed(COMMON_ACTION_CODES.CONFIRM, props.item);
                }}
                accessibilityLabel={TestIdentifiers.deleteBtn}
                {...testID(TestIdentifiers.deleteBtn)}
                backgroundColor={'transparent'}
                leftIcon={
                  <Feather
                    name="trash-2"
                    size={15}
                    color={Colors.Custom.Gray600}
                  />
                }
              />
            )}
          </HStack>
        </HStack>
      </Pressable>
    </VStack>
  );
};

export default ContactNoteItem;
