import { FORM_FILE_S3_URL } from '../../../../constants/Configs';
import LocalStorage from '../../../../utils/LocalStorage';

interface FileUploadResponse {
  url: string;
  data?: any;
}

interface FileRequestHeaders {
  'user-uuid'?: string;
  'contact-uuid'?: string;
  'account-uuid'?: string;
  'fold-access-token'?: string;
}

interface ProgressEvent {
  loaded: number;
  total: number;
}

export class FileService {
  public formOptions: any;

  async uploadFile(
    storage: string,
    file: File,
    fileName: string,
    dir?: string,
    progressCallback?: (progress: ProgressEvent) => void,
    abortCallback?: (abort: () => void) => void
  ): Promise<FileUploadResponse> {
    const headers = await this.getHeaders();

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);

    return this.xhrRequest(
      FORM_FILE_S3_URL.UPLOAD,
      'POST',
      headers,
      fileName,
      {},
      formData,
      undefined,
      progressCallback,
      abortCallback
    ).then((response: any) => {
      return {
        storage: 'url',
        fileName,
        url: response.url,
        size: file.size,
        type: file.type,
        data: response.data,
      };
    });
  }

  async downloadFile(fileUrl: string): Promise<FileUploadResponse> {
    const headers = await this.getHeaders();

    return fetch(fileUrl, { headers })
      .then((res) => res.json())
      .then((data) => ({
        url: data.url
      }));
  }

  async getFileSignedURL(
    url: string,
    successCallback: (data: any) => void,
    errorCallback?: () => void,
    customHeaders?: any
  ): Promise<void> {
    const headers = customHeaders && Object.keys(customHeaders).length > 0 ? customHeaders : await this.getHeaders();

    try {
      const response = await fetch(url, { headers });
      const data = await response.json();
      successCallback(data);
    } catch (error) {
      errorCallback?.();
    }
  }

  private async getHeaders() {
    const userDataString = await LocalStorage.getItem('user');
    const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
    const userData = JSON.parse(userDataString);
    const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
    const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;
    const accountUUID =
      userData?.data.accounts.length > 0
        ? userData?.data.accounts[0].uuid || ''
        : '';
    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;
    const headersObj: any = {
      'access-token': responseAccessToken,
      fold_access_token,
      accountUUID,
      'account-uuid': accountUUID,
    };
    if (!fold_access_token && formWidgetAccessToken && formWidgetAccessToken !== 'undefined') {
      headersObj['form-widget-access-token'] = formWidgetAccessToken;
    }
    return headersObj;
  };

  private xhrRequest(
    url: string,
    method: string,
    headers: FileRequestHeaders,
    fileName: string,
    query: Record<string, string> = {},
    data?: FormData | string,
    options?: any,
    progressCallback?: (event: any) => void,
    abortCallback?: (abort: () => void) => void
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const isJson = typeof data === 'string';

      if (progressCallback) {
        xhr.upload.onprogress = progressCallback;
      }

      if (abortCallback) {
        abortCallback(() => xhr.abort());
      }

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          let responseData: any = {};
          try {
            responseData = typeof xhr.response === 'string' ? JSON.parse(xhr.response) : {};
            responseData = responseData?.data || responseData;
          } catch (err) {
            responseData = {};
          }

          const responseUrl = responseData?.id
            ? FORM_FILE_S3_URL.FILE_DETAIL_GET.replace('#ATTACHMENT_ID', responseData.id)
            : `${xhr.responseURL}/${fileName}`;

          resolve({ url: responseUrl, data: responseData });
        } else {
          reject(xhr.response || 'Unable to upload file');
        }
      };

      xhr.onerror = () => reject(xhr);
      xhr.onabort = () => reject(xhr);

      // Build URL with query parameters
      const queryString = Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      const requestUrl = queryString ? `${url}?${queryString}` : url;

      xhr.open(method, requestUrl);

      // Set headers
      if (isJson) {
        xhr.setRequestHeader('Content-Type', 'application/json');
      }
      Object.entries(headers).forEach(([key, value]) => {
        if (value) xhr.setRequestHeader(key, value);
      });

      // Set additional options if provided
      if (options) {
        const parsedOptions = typeof options === 'string' ? JSON.parse(options) : options;
        Object.assign(xhr, parsedOptions);
      }

      xhr.send(data);
    });
  }
}
