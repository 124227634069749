import { TASK_DUE_DATE_CODES, TASK_STATUS } from '../../constants/MlovConst';
import {isWeb} from '../../utils/platformCheckUtils';
import { ITask } from '../common/CareDashboard/CareDashboardInterfaces';
import {EntityType, TaskPanelType} from './TaskEnum';

export const getBadgeName = (tag: EntityType) => {
  switch (tag) {
    case EntityType.MED:
      return 'Medication';
    case EntityType.VITAL:
      return 'Vital';
    case EntityType.LAB_TEST:
      return 'Lab Test';
    case EntityType.FORM:
      return 'Form';
    case EntityType.EXERCISE:
      return 'Exercise';
    case EntityType.CONTENT:
      return 'Content';
    case EntityType.INSTRUCTION:
      return 'Instruction';
    case EntityType.NUTRITION:
      return 'Nutrition';
    case EntityType.IMMUNIZATION:
      return 'Immunization';
    case EntityType.GOAL:
      return 'Goal';
    case EntityType.TASK:
      return 'Task';
    case EntityType.ESCALATION:
      return 'Escalation';
  }
};

export const getBadgeColor = (tag: EntityType) => {
  switch (tag) {
    case EntityType.MED:
      return 'primary.500';
    case EntityType.VITAL:
      return 'primary.500';
    case EntityType.LAB_TEST:
      return 'primary.500';
    case EntityType.FORM:
      return 'primary.500';
    case EntityType.EXERCISE:
      return 'primary.500';
    case EntityType.CONTENT:
      return 'primary.500';
    case EntityType.INSTRUCTION:
      return 'primary.500';
    case EntityType.NUTRITION:
      return 'primary.500';
    case EntityType.IMMUNIZATION:
      return 'primary.500';
    case EntityType.GOAL:
      return 'primary.500';
    case EntityType.TASK:
      return 'primary.500';
    case EntityType.ESCALATION:
      return 'error.500';
  }
};

export const getBackgroundColor = (props: any) => {
  const colorArray = ['#FEDF89', '#E4E7EC', '#FECDD3'];
  const randomNumber = Math.floor(Math.random() * colorArray.length);
  if (
    props?.data?.priority?.value === 'High' &&
    props?.data?.isCompleted !== true
  ) {
    return isWeb() ? '#EC7672' : '#FECDD3';
  } else if (
    props?.data?.priority?.value === 'Medium' &&
    props?.data?.isCompleted !== true
  ) {
    return '#FEDF89';
  } else if (
    props?.data?.priority?.value === 'Low' &&
    props?.data?.isCompleted !== true
  ) {
    return '#E4E7EC';
  } else if (
    props?.data?.priority === null &&
    props?.data?.isCompleted !== true
  ) {
    return colorArray[randomNumber];
  } else {
    return '#6CE9A6';
  }
};


export const isAllowToDeleteTask = (task: ITask) => {
  if (!task) return false;
  const isTaskCompleted = task?.isCompleted || task?.status?.value === 'Completed';
  const hasExternalReference = Boolean(
    task.referenceData?.careJourneyId ||
    task.referenceData?.formId ||
    task.referenceData?.entityType === EntityType.FORM
  );
  const hasExternalOrder = Boolean(task.orders?.[0]?.externalOrderId);
  const isReadOnlyTask = task?.isReadOnly;

  return !(isTaskCompleted || hasExternalReference || hasExternalOrder || isReadOnlyTask);
}

export const getTodayDueTaskDataCount = (totalTaskData: any) => {
  const currentDate = new Date();
  return totalTaskData?.filter(
    (tasks: any) =>
      tasks.status?.code !== TASK_STATUS.COMPLETED &&
      new Date(tasks?.endDateTime).toDateString() === currentDate.toDateString()
  );
};

export const getTodayDueTaskTextLabel = (
  todayDueTaskCount: number,
  totalCount: number,
  recordCode?: string,
  isPoolTableLabel?: boolean,
) => {
  if (todayDueTaskCount > 0 && totalCount > 0 && (isPoolTableLabel || (recordCode && !Object?.values(TASK_DUE_DATE_CODES)?.includes(recordCode)))) {
    if (recordCode && Object.values(TASK_DUE_DATE_CODES)?.includes(recordCode)) {
      return `${todayDueTaskCount}/${totalCount}`
    }
    if (
      recordCode === TASK_STATUS.ACCEPTED ||
      recordCode === TASK_STATUS.MISSED
    ) {
      return `${todayDueTaskCount}/${totalCount}  ${
        todayDueTaskCount > 1 ? 'tasks' : 'task'
      } due today`;
    } else {
      return totalCount || 0;
    }
  } else {
    return `${totalCount || 0} ${totalCount > 1 ? 'tasks' : 'task'}`;
  }
};

export const getPanelTitle = (panelType?:TaskPanelType) => {
  switch (panelType) {
    case TaskPanelType.PATIENT:
      return "Patient's Tasks";
    case TaskPanelType.INTERNAL:
      return 'Internal Tasks';
    default:
      return 'Tasks';
  }
}
