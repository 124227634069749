import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { Radio, Space } from "antd";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import BasicFields from "../../FHFormio/EditFormFields/BasicFields";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { RadioSchema } from "../Schema/ComponentsSchema";
import { RadioComponent } from "../BaseComponents/BaseComponentInterface";
import RadioSvg from "../../../../../assets/Icons/FormBuilder/RadioSvg";
import { RadioChangeEvent } from "antd/es/radio";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { v4 as uuidV4 } from 'uuid';
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";

export const RadioFieldFormElement: FormElement = {
  type: RadioSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(RadioSchema),
    key: getUniqueKey(map, RadioSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(RadioSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: RadioSvg,
    label: RadioSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (formElement: FormElementInstance, currentValue: string, silentCheck?: boolean): ValidationResult => {
  if (formElement.validate?.required && !currentValue) {
    return { isValid: false, errorMessage: `${formElement.label || 'This field'} is required`, key: formElement.key, fieldValue: currentValue };
  }
  return { isValid: true, errorMessage: '', key: formElement.key, fieldValue: currentValue };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (e?: RadioChangeEvent) => {
    const valid = localValidate(elementInstance, e?.target.value || '');
    setErrorMessage(valid.errorMessage);
    setValue(e?.target.value || '');
  }

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        onChange={onChange}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState(defaultValue || selectedValue || elementInstance.defaultValue || '');
  const isMounted = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || '', silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: RadioChangeEvent | undefined) => {
    const value = newValue?.target?.value;
    setValue(value);
    if (!submitValue) return;
    await debouncedValidation(value);
    submitValue(elementInstance.key, value);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (event?: RadioChangeEvent) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const { label, validate, description, tooltip, allowToShare, values = [] } = elementInstance as RadioComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  const radioClick = (event: any) => {
    if (event?.target?.value === defaultValue) {
      onChange(undefined);
    }
  }

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      {isReadOnly && !isPrintForm ? (
        <div className="field-value">{defaultValue ? values.find(opt => opt.value === defaultValue)?.label || defaultValue : '-'}</div>
      ) : (
        <div className="flex flex-col gap-2">
          <Radio.Group
            onChange={onChange}
            value={defaultValue}
            data-testid={`radio-field-${elementInstance.key}`}
            disabled={isReadOnly || false}
          >
            <Space direction="vertical">
              {values.map((option, index) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  data-testid={`radio-option-${option?.label}`}
                  onClick={radioClick}
                >
                  {option.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
        </div>
      )}
      {description && (
        <Description description={description} />
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as RadioComponent;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'oldtextfield',
      input: true,
      key: 'placeholder',
      label: 'Placeholder',
      placeholder: 'Placeholder',
      tooltip: 'The placeholder text that will appear when this field is empty.',
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
    {
      type: 'datagrid',
      input: true,
      // label: 'Values',
      label: 'Options',
      key: 'values',
      tooltip:
        'The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.',
      reorder: true,
      components: [
        {
          label: 'Label',
          key: 'label',
          input: true,
          type: 'oldtextfield',
        },
        {
          label: 'Value',
          key: 'value',
          input: true,
          type: 'oldtextfield',
          allowCalculateOverride: true,
          hidden: true,
          clearOnHide: false,
          calculateValue: {_camelCase: [{var: 'row.label'}]},
          validate: {
            required: true,
          },
        },
      ],
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
