import { Active, defaultDropAnimationSideEffects, DragOverlay, DropAnimation, useDndMonitor } from "@dnd-kit/core";
import React, { useCallback, useState } from "react";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { ElementSource, FormElements } from "../FormComponents/FormComponents";
import { createPortal } from "react-dom";
import { DraggingView } from "../BaseComponents/DraggingView";
import { findElementRecursively } from "../CustomFormEngineUtils";
import SidebarElementDragOverlay from "../BaseComponents/SidebarElementDragOverlay";


function DragOverlayWrapper() {
  const { state } = useCustomFormBuilderContext();
  const [draggedItem, setDraggedItem] = useState<Active | null>(null);

  useDndMonitor({
    onDragStart({ active }) {
      setDraggedItem(active);
    },
    onDragEnd() {
      setDraggedItem(null);
    },
    onDragCancel() {
      setDraggedItem(null);
    },
  });

  const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0.5',
        },
      },
    }),
  };

  const getElement = useCallback(() => {
    const elementId = draggedItem?.data?.current?.elementId;
    if (!elementId) return null;
    return findElementRecursively(state.elements, elementId);
  }, [draggedItem?.data?.current?.elementId, state.elements]);


  if (!draggedItem) return null;

  let node = <div>Form Component</div>;
  const isSidebarElement = draggedItem?.data?.current?.source === ElementSource.sidebar;

  if (isSidebarElement) {
    const label = draggedItem?.data?.current?.title;
    const icon = draggedItem?.data?.current?.icon;
    node = <SidebarElementDragOverlay label={label || 'Form Component'} icon={icon} />;
  }

  const isFormElement = draggedItem?.data?.current?.source === ElementSource.form;

  if (isFormElement) {
    const element = getElement();
    if (element) {
      const designerBtnElement = FormElements[element?.type as keyof typeof FormElements]?.designerBtnElement;
      node = (
        <div className="w-400">
          <DraggingView label={designerBtnElement?.label || 'Form Component'} />
        </div>
      )
    }
  }

  return createPortal(<DragOverlay dropAnimation={dropAnimation}>{node}</DragOverlay>, document.body);
}

export default DragOverlayWrapper;
