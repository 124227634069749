import {Tooltip, notification} from 'antd';
import {Text, useToast} from 'native-base';
import {useCallback, useContext, useEffect, useState} from 'react';
import {View} from 'react-native';
import {CONSENT_ERROR_FOR_SIDE_CAR, DATE_FORMATS, GROUP_MEMBER_TYPE, PERSON_TYPES} from '../../constants';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES, PATIENT_QUICK_PROFILE_PARENT_CODES, QUICK_ACTION_CODES} from '../../constants/ActionConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {getCurrentPatientDeceasedData, getPatientCondition} from '../../services/CommonService/AidBoxService';
import BaseService from '../../services/CommonService/BaseService';
import {Colors} from '../../styles';
import {getAgeValue, getDateStrFromFormat} from '../../utils/DateUtils';
import {getAccountId, getAccountUUID, getPatientName, getSecondaryValue, isString} from '../../utils/commonUtils';
import {ToastType, showToast} from '../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {useContainerDimensions} from '../CustomHooks/ContainerDimensionHook';
import {withMiniContactViewHOC} from '../MiniContactViewHOC';
import {PatientInfoBanner} from '../PatientInfoBanner/PatientInfoBanner';
import {getContactTypeValue} from '../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {TaskPanelType} from '../TaskCard/TaskEnum';
import Stack from '../common/LayoutComponents/Stack';
import {SidecarPersonActions} from './SidecarPersonActions';
import PatientHIEStatusView from '../common/PatientHIEStatus/PatientHIEStatusView';
import { IContactType } from '../common/MemebersView/interface';
import { usePersonOmniViewContext } from '../PersonOmniView/PersonOmniView.context';

interface ISidecarPatientInfoBannerProps {
  contactData: IContact | undefined;
  headerContainerRef?: any
  showAssignJourney?: boolean;
  assignJourneyOptionClicked?: () => void;
  referralOrderOptionClicked?: () => void;
  parentCode?: string;
  handleCreateCarePlanClick?: () => void;
  isCompactView?: boolean;
  showCloseButton?: boolean;
  handleCloseClick?: () => void;
  openContactInWindowOrOpenContactIdDrawer?:(contactId: string) => void;
  taskPanelType?: TaskPanelType
  sideCarDashboardEnabled?: boolean;
  contactType?: IContactType;
  showDetailProfile?: boolean;
  patientHieSyncCall?: boolean;
  onShowDetails?: () => void;
  currentView?: string;
}

const SidecarPatientInfoBannerWrapper = (props: ISidecarPatientInfoBannerProps) => {
  const {contactData, assignJourneyOptionClicked, referralOrderOptionClicked, showAssignJourney, parentCode, showCloseButton} = props;
  const [ICDConditions, setICDConditions] = useState<any[]>([]);
  const [deceasedDate, setDeceasedDate] = useState('');
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const commonData = useContext(CommonDataContext);
  const {ehrPatientId} = usePersonOmniViewContext();
  const isCompactView = commonData?.sidecarContext?.isSidecar || props.isCompactView;
  const isHIEEnabled = isAccountConfigEnabled(CONFIG_CODES.HIE_ENABLE);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const {width} = useContainerDimensions(props?.headerContainerRef);
  const practiceLocationId = contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const patientId = ehrPatientId || contactData?.patient?.patientId;
  const contactId = contactData?.id || '';
  const accountUUID = getAccountUUID()
  const accountId = getAccountId()
  const baseService = BaseService.getSharedInstance().axios;
  const toast = useToast();
  const fetchICDConditions = async () => {
    try {
      const response = await getPatientCondition( contactId, practiceLocationId || '');
      const mappedConditions = response?.data?.entry?.map((entry: any) => {
        const recordedDate = entry?.resource?.meta?.lastUpdated || '';
        return entry?.resource?.code?.coding?.map((coding: any) => ({
          recordedDate: recordedDate,
          code: coding?.code,
          display: coding?.display
        }));
      });
      const flatConditions = mappedConditions?.flat();
      setICDConditions(flatConditions);
    } catch (error) {}
  };

  useEffect(() => {
    fetchICDConditions();
  }, [patientId, practiceLocationId]);

  const getSubtitleText = (contactData: any): JSX.Element => {
    const ageValue = getAgeValue(
      contactData?.person?.birthDate,
      deceasedDate
    );
    return (
      <Stack direction='row'>
        <Tooltip title={ageValue} placement="top" color={Colors.Custom.Gray700}>
          <Text>
            {getAgeValue(
              contactData?.person?.birthDate,
              deceasedDate
            ) &&
              getDateStrFromFormat(
                contactData?.person?.birthDate,
                DATE_FORMATS.DISPLAY_BIRTH_DATE_FORMAT
              )}
          </Text>
        </Tooltip>
        <Text>{contactData?.person?.birthDate && ' | '}</Text>
        <Text>
          {contactData?.contactType?.contactType &&
            getContactTypeValue(contactData.contactType.contactType)}
        </Text>
      </Stack>
    );
  };

  useEffect(()=> {
    getPatientDeceasedData(contactData);
  }, []);

  const getPatientDeceasedData = async (contactData: IContact | undefined) => {
    const deceasedDatePatient = await getCurrentPatientDeceasedData(contactData as IContact);
    setDeceasedDate(deceasedDatePatient);
  };

  const handleEdit = useCallback((actionCode?: string) => {
    if (actionCode === COMMON_ACTION_CODES.CONSENT_WARNING) {
      const message = CONSENT_ERROR_FOR_SIDE_CAR;
      notification.error({
        message,
      });
    }
  }, []);

  const sendResetPasswordInstructions = useCallback(async (contactData: any) => {
    const url = `/crm-nest/auth/resetContactPassword`;
    const resetPasswordData = {
      accountUuid: accountUUID,
      contactUuid: contactData?.uuid,
    };
    const headers = {
      accountId: accountId,
    };
    await baseService
      .post(url, resetPasswordData, {headers: headers})
      .then((response) => {
        if (contactData?.email) {
          showToast(
            toast,
            'Password instruction successfully sent on patient email',
            ToastType.success
          );
        } else if (contactData?.phoneNumber) {
          showToast(
            toast,
            'Password instruction successfully sent on patient phone number',
            ToastType.success
          );
        }
      })
      .catch((error) => {
        showToast(
          toast,
          'Something went wrong',
          ToastType.error
        );

      });
  }, []);

  const onPersonActionPerformed = useCallback(
    (actionCode: string, rawData?: any) => {
      if (actionCode === QUICK_ACTION_CODES.ADD_CARE_JOURNEY) {
        assignJourneyOptionClicked?.();
      }
      if (actionCode === QUICK_ACTION_CODES.ADD_REFERRAL_ORDER) {
        referralOrderOptionClicked?.();
      }
    },
    []
  );

  return (
    <Stack direction="row" style={{flex: 1, justifyContent: 'space-between'}}>
      <PatientInfoBanner
        icdConditionsList={ICDConditions}
        contactActiveStatus={contactData?.isActive}
        bannerType={'headerBanner'}
        isHorizontal={true}
        isSelected={false}
        headerString={
          isString(contactData?.name)
            ? contactData?.name
            : getPatientName(contactData)
        }
        genderCode={
          contactData?.person?.gender?.code ||
          contactData?.person?.sexAtBirth?.code
        }
        userId={contactData?.id || ''}
        userType={
          contactData?.patient?.patientUuid
            ? GROUP_MEMBER_TYPE.PATIENT
            : GROUP_MEMBER_TYPE.CONTACT
        }
        deceasedDate={deceasedDate}
        subtitleElement={getSubtitleText(contactData) || '-'}
        hideConversationTypeImage={true}
        loggedInTime={contactData?.additionalAttributes?.loggedInTime}
        contactData={contactData}
        showDetailProfile={props?.showDetailProfile}
        showEditProfile={false}
        name={
          isString(contactData?.name)
            ? contactData?.name
            : getPatientName(contactData)
        }
        isSidecarView={isSidecarContext}
        isCompactView={props.isCompactView}
        contactType={props?.contactType}
        hideOnlineIndicator={false}
        headerContainerWidth={width ? width - 100 : undefined}
        onPatientProfileIconClick={() => {
          if (props?.contactData?.contactType?.contactType?.code == PERSON_TYPES.CUSTOMER || contactData?.contactType?.contactType?.code === PERSON_TYPES.CUSTOMER) {
            props?.openContactInWindowOrOpenContactIdDrawer?.(contactData?.id?.toString() || '')
          }
        }}
        patientHieSyncCallView={
          props?.patientHieSyncCall && isHIEEnabled ? (
            <PatientHIEStatusView
              patientData={contactData?.patient}
              sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
            ></PatientHIEStatusView>
          ) : (
            <></>
          )
        }
        sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
        shouldShowCloseButton={props.showCloseButton && props?.sideCarDashboardEnabled}
        handleCloseClick={props?.handleCloseClick}
        onShowDetails={() => {
          props?.onShowDetails?.()
        }}
        isShowConsentButton={true}
        currentView={props?.currentView}
        showContactProfilesSwitch={true}
      />
      {
        !props.sideCarDashboardEnabled &&  
      <View style={{alignItems: 'center', marginLeft: 2, marginTop: 8, justifyContent: 'center'}}>
        <SidecarPersonActions
          showPhoneCall={!showOnlyInternalChatInSidecar}
          shouldShowEmail={true}
          showVideoCall={!showOnlyInternalChatInSidecar}
          showSms={true}
          handleCreateCarePlanClick={props?.handleCreateCarePlanClick}
          contactInfo={props?.contactData}
          email={
            props?.contactData?.email ||
            getSecondaryValue(contactData?.personContact, 'email') ||
            ''
          }
          phoneNumber={
            props?.contactData?.phoneNumber ||
            getSecondaryValue(contactData?.personContact, 'phone') ||
            ''
          }
          parentCode={parentCode || PATIENT_QUICK_PROFILE_PARENT_CODES.CALL_LOG}
          handleEdit={handleEdit}
          showAssignJourney={showAssignJourney ?? false}
          onPersonActionPerformed={onPersonActionPerformed}
          shouldShowCloseButton={props.showCloseButton}
          handleCloseClick={props?.handleCloseClick}
          taskPanelType={props?.taskPanelType}
          sendResetPasswordInstructions={sendResetPasswordInstructions}
        />
      </View>
      }
    </Stack>
  );
};

export const SidecarPatientInfoBanner = withMiniContactViewHOC(SidecarPatientInfoBannerWrapper)
