import {useLazyQuery} from '@apollo/client';
import {Drawer, notification, Popover, Tooltip} from 'antd';
import {Divider, HStack, Text, useToast, View} from 'native-base';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Pressable} from 'react-native';
import {
  BUTTON_TYPE,
  CONSENT_CONST,
  getInActiveContactError,
  MLOV_CATEGORY,
  PERSON_TYPES,
  RIGHT_SIDE_CONTAINER_CODE,
} from '../../constants';
import {
  COMMON_ACTION_CODES,
  CONVERSATION_ACTION_CODES,
  PATIENT_QUICK_PROFILE_PARENT_CODES,
  QUICK_ACTION_CODES,
  SIDECAR_ALLOWED_VIEWS,
} from '../../constants/ActionConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {Colors} from '../../styles';
import {
  canCommunicateWithInactivePatient,
  getAccountId,
  getAccountUUID,
  getContactProfileAccountLocationUuid,
  getEhrPatientId,
  getLocationGroupIdFromLocationId,
  getPatientActiveProfileIds,
  getPatientActiveProfiles,
  getSecondaryValue,
  getUserData,
  getUserFullName,
  getUserId,
  getUserUUID,
  isActiveContact,
  isActiveProfileMatchAllowedLocations,
  isConsentGivenByConsentId,
  isConsentRequiredAndGiven,
  isLoginUserBusinessOwner,
  isSideCarEnabledForPatientDashboard,
} from '../../utils/commonUtils';
import {EventBus} from '../../utils/EventBus';
import {getToolTipMessage} from '../../utils/PersonActionsUtils';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import CreateSmsConversationDrawer from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelTabs/CreateSmsConversationDrawer/CreateSmsConversationDrawer';
import {patientNotLoggedInError} from '../RightSideContainer/TeamInbox/Conversations/ConversationContainer/ConversationConst';
import {
  IContact,
  IConversationData,
  INewConversationResponse,
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {SMS_INBOX_ACTION_CODES} from '../RightSideContainer/TeamInbox/Integrations/IntegrationCreate/SmsInboxCreate/SmsInboxConst';
import {
  MAIN_MENU_CODES,
  USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE,
} from '../SideMenuBar/SideBarConst';
import InstantChat from '../common/ChatDrawer/InstantChat';
import MeetingView from '../common/MeetingView/MeetingView';
import OutboundCallSmsView from '../common/OutboundCallSmsView/OutboundCallSmsView';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {EmailDrawerCommonV2} from '../common/EmailDrawerCommonV2';
import InstantChatView from '../common/ChatDrawer/InstantChatView';
import ConversationsQueries from '../../services/Conversations/ConversationsQueries';
import ChatRoundActionSvg from '../common/Svg/PersonActionSvgIcons/ChatRoundActionSvg';

import {isCallsAllowed, USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';
import FoldPermitCan from '../CommonComponents/FoldPermitCan/FoldPermitCan';
import {withMiniContactViewHOC} from '../MiniContactViewHOC';
import {testID, TestIdentifiers} from '../../testUtils';
import MenuHorizontalIcon from '../common/Svg/MenuHorizontalIcon';
import {IConversationChannelList} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/Interfaces';
import {DISPLAY_CONVERSATION_SIDEBAR_ICON} from '../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import {useIntl} from 'react-intl';
import CrossIcon from '../common/Svg/CrossIcon';
import Stack from '../common/LayoutComponents/Stack';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import { TaskPanelType } from '../TaskCard/TaskEnum';
import { ParticipantType } from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { IUserPatientSearchItem } from '../TaskModule/TaskInterfaces';
import ResetPasswordModal from '../common/PersonActions/ResetPasswordModal';
import AutomationWorkflowEmployee from '../RightSideContainer/MembersManagement/AutomationWorkflowEmployee/AutomationWorkflowEmployee';
import ContactSendForm from '../common/ContactSendForm/ContactSendForm';
import { AddOrUpdateLead } from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import PhoneActionSvgV1 from '../common/Svg/PersonActionSvgIcons/PhoneActionSvgV1';
import {getEHRName, getPatientEHRUrl } from '../../utils/capabilityUtils';
import AppointmentBooking from '../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentBooking';
import { PrintContactClinicalProfileSectionSelector } from '../common/PrintContactClinicalProfileDrawer/PrintContactClinicalProfileSectionSelector';
import { AddContactRelationView } from '../common/AddContactRelationView';
import { ToastType, showToast } from '../../utils/commonViewUtils';
import { getContactTypeId, getMlovIdFromCode, getMlovListFromCategory } from '../../utils/mlovUtils';
import SendEdicationContent from '../common/AppDashboard/DayOptimizer/PatientDashboard/PatientListing/SendEducationContent';
import { FlowType } from '../../context/WorkflowContext';
import { getNodeMetadata } from '../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyMetadataService';
import { EDUCATION_CONTENT_NODE_DETAILS } from './constants';
import { setFieldsVisibilityFromPreviousNode } from '../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeHelper';
import DayOptimizer from '../common/AppDashboard/DayOptimizer/DayOptimizer';
import { ModalActionTitle } from '../common/ModalActionTitle/ModalActionTitle';
import { redirectToNewTab } from './SidecarUtils';
import BaseService from '../../services/CommonService/BaseService';
import useLoggedInUserAllowedLocationsByTab from '../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import usePatientProfileActionAccess from '../CustomHooks/usePatientProfileActionAccess';
import { usePersonOmniViewContext } from '../PersonOmniView/PersonOmniView.context';
import {getEhrConfig} from '../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {IMlov} from '../../Interfaces/CommonInterfaces';

interface SidecarPatientProfileActionsProps {
  shouldShowEmail?: boolean;
  email?: any;
  showVideoCall: boolean;
  phoneNumber?: any;
  showSms?: boolean;
  contactInfo?: IContact | undefined;
  showPhoneCall?: boolean;
  handleEdit?: any;
  showAssignJourney: boolean;
  onPersonActionPerformed?: (actionCode: string, rawData?: any) => void;
  handleCreateCarePlanClick?: () => void;
  shouldShowCloseButton?: boolean;
  handleCloseClick?: () => void;
  taskPanelType?: TaskPanelType;
  sendResetPasswordInstructions?: (contactData: any) => void;
  showDetailProfile?: boolean;
  showCharts?: boolean;
  parentCode?: string;
  navigateOrOpenContactIdDrawer?: (contactId: string, additionalData?: any) => void;
  currentView?: string;
  assignJourneyOptionClicked?: () => void;
  referralOrderOptionClicked?: () => void;
}

const SideCarPatientProfileActionsComponent = (props: SidecarPatientProfileActionsProps) => { 
  const {
    shouldShowEmail,
    email,
    showVideoCall,
    phoneNumber,
    showSms,
    contactInfo,
    showPhoneCall,
    handleEdit,
    showAssignJourney,
    onPersonActionPerformed
  } = props;
  const personActionRef: any = useRef();
  const userUUID = getUserUUID();
  const userId = getUserId();
  const intl = useIntl();
  const isCallAllowed = isCallsAllowed();
  const isLoggedIn = contactInfo?.additionalAttributes?.loggedInTime;
  const [selectedView, setSelectedView] = useState<string>('');
  const isPatientMessagingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.PATIENT_MESSAGING_ENABLED
  );
  const [typeNodes, setTypeNodes] = React.useState<any>();
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(
    CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR
  );
  

  const [visibleActions, setVisibleActions] = useState<IConversationChannelList[]>([]);
  const [overflowActions, setOverflowActions] = useState<IConversationChannelList[]>([]);
  const actionsContainerRef = useRef<HTMLDivElement>(null);

  const toast = useToast();

  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);

  const [stateData, setStateData] = useState({
    visible: false,
    preferredChannel: '',
    openDrawerCode: '',
    selectedConversation: {} as IConversationData,
    moreActionPopup: false,
    showResetPasswordModal: false,
    showAutomationDrawer: false,
    showSendFormDrawer: false,
    showEditPatientDrawer: false,
    showAppointmentBookingDrawer: false,
    showPrintClinicalProfileDrawer: false,
    showAddRelativesDrawer: false,
    showAddFamilyMembersDrawer: false,
    showSendPatientEducationDrawer: false,
    showDayOptimizerDrawer: false,
    showChatWithPatientDrawer: false,
    emailConsentError: false,
    smsConsentError: false,
    callConsentError: false
  });
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData.sidecarContext?.isSidecar;
  const contactActiveStatus = isActiveContact(contactInfo);
  const formattedContactData = getFormDataFromLeadData(
    contactInfo || {},
    commonData
  );
  const locationId = contactInfo?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
  const locationGroupId =
  locationId &&
  getLocationGroupIdFromLocationId(
    commonData?.accountLocationListWithEHR,
    locationId
  );
  const currentEHR: any = getEHRName('', locationGroupId);
  const ehrConfig = getEhrConfig(currentEHR);
  const isAthenaEHR = ehrConfig.isAthena
  const patientUuid = props?.contactInfo?.uuid;
  const consentMlovList: IMlov[] = getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.CONSENT) || [];
  const callConsentId = getMlovIdFromCode(
    consentMlovList,
    CONSENT_CONST.consentToCall
  );
  const isCallConsentGivenFlag = isConsentGivenByConsentId(contactInfo?.contactConsents, callConsentId);
  const emailConsentId = getMlovIdFromCode(
    consentMlovList,
    CONSENT_CONST.consentToEmail
  );
  const isEmailConsentGivenFlag = isConsentGivenByConsentId(contactInfo?.contactConsents, emailConsentId);
  const smsConsentId = getMlovIdFromCode(
    consentMlovList,
    CONSENT_CONST.consentToText
  );
  const isSmsConsentGivenFlag = isConsentGivenByConsentId(contactInfo?.contactConsents, smsConsentId);
  const consentCheck = isConsentRequiredAndGiven(contactInfo) && !isAthenaEHR;
  const userFullName = getUserFullName();
  const currentUserData = getUserData();
  const accountUUID = getAccountUUID()
  const accountId = getAccountId()
  const baseService = BaseService.getSharedInstance().axios;
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const {checkPatientProfileActionAccess} = usePatientProfileActionAccess();
  const isActionsDisabled = checkPatientProfileActionAccess(contactInfo);
  const tooltipMessage = !isActionsDisabled ? '' :  intl.formatMessage({id :'inactiveLocationPatientActionMsg'});

  const [GetConversationByConversationId] =
    useLazyQuery<INewConversationResponse>(
      ConversationsQueries.GetConversationByConversationId,
      {
        fetchPolicy: 'no-cache',
      }
    );

  const handleOnCreateNewEmailConversation = async (conversationId: string) => {
    if (!conversationId) {
      return;
    }
    try {
      const response = await GetConversationByConversationId({
        variables: {
          conversationId: Number(conversationId),
          loginUserIntId: userId,
          loginUserUuid: userUUID,
        },
      });
      const conversations = response.data?.conversations || [];
      if (conversations.length > 0) {
        const selectedConversationData = conversations[0];
        setStateData((prev) => {
          return {
            ...prev,
            selectedConversation: selectedConversationData,
          };
        });
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER);
      }
    } catch (error) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedConversationData: {},
        };
      });
    }
  };

  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };

  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: contactInfo ?? {fromNumber: phoneNumber},
          fromNumber: rowData.fromNumber,
          toNumber: phoneNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      default:
        break;
    }
  };
  const isPatientLoggedIn = contactInfo?.additionalAttributes?.loggedInTime
    ? true
    : false;

  const shouldShowChat = () => {
    if (isSidecarContext && showOnlyInternalChatInSidecar) {
      return false;
    }
    if (
      USER_ROLE_SIDE_BAR_CODES_PERMISSION_WISE.USER.includes(
        MAIN_MENU_CODES.INBOX
      )
    ) {
      return (
        contactInfo?.patient?.id &&
        contactInfo?.uuid &&
        isLoggedIn &&
        isPatientMessagingEnabled
      );
    } else {
      return false;
    }
  };

  const canStartAction = (actionCode: string) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.START_SMS:
        return showSms;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        return shouldShowEmail;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        return showVideoCall;
      case QUICK_ACTION_CODES.ADD_CARE_JOURNEY:
        return showAssignJourney;
      case QUICK_ACTION_CODES.ADD_REFERRAL_ORDER:
          return true;
      case QUICK_ACTION_CODES.RESET_PASSWORD:
        return true;
      case QUICK_ACTION_CODES.SEND_ASSESMENT:
        return true;
      case QUICK_ACTION_CODES.RUN_AUTOMATION:
        return true;
      case QUICK_ACTION_CODES.SEND_PATIENT_EDUCATION:
        return true;
      case QUICK_ACTION_CODES.EDIT_PATIENT:
        return true;
      case QUICK_ACTION_CODES.DAY_OPTIMIZER:
        return true;
      case QUICK_ACTION_CODES.ADD_RELATIVES:
        return true;
      default:
        return true;
    }
  };

  const performAction = (actionCode: string) => {
    switch (actionCode) {
      case CONVERSATION_ACTION_CODES.START_SMS:
        if (canCommunicateWithInactivePatient(contactInfo)) {
          if (consentCheck || (isAthenaEHR && isSmsConsentGivenFlag)) {
            if (phoneNumber) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER);
            } else {
              handleEdit?.();
              setStateData((prev) => {
                return {
                  ...prev,
                  showEditPatientDrawer: true,
                };
              });
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
            setStateData((prev) => {
              return {
                ...prev,
                showEditPatientDrawer: true,
                smsConsentError: true,
              };
            });
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        if (canCommunicateWithInactivePatient(contactInfo)) {
          if (consentCheck || (isAthenaEHR && isEmailConsentGivenFlag)) {
            if (email) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP);
            } else {
              handleEdit?.();
              setStateData((prev) => {
                return {
                  ...prev,
                  showEditPatientDrawer: true,
                };
              });
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
            setStateData((prev) => {
              return {
                ...prev,
                showEditPatientDrawer: true,
                emailConsentError: true,
              };
            });
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case CONVERSATION_ACTION_CODES.START_VIDEO_MEET:
        if (contactActiveStatus) {
          if (consentCheck || (isAthenaEHR && isCallConsentGivenFlag)) {
            if (email || phoneNumber) {
              setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW);
            } else {
              handleEdit?.();
              setStateData((prev) => {
                return {
                  ...prev,
                  showEditPatientDrawer: true,
                };
              });
            }
          } else {
            handleEdit?.(COMMON_ACTION_CODES.CONSENT_WARNING);
            setStateData((prev) => {
              return {
                ...prev,
                showEditPatientDrawer: true,
                callConsentError: true
              };
            });
          }
        } else {
          const message = getInActiveContactError(contactInfo);
          notification.error({
            message,
          });
        }
        break;
      case QUICK_ACTION_CODES.ADD_CARE_JOURNEY:
        handleMoreActionVisibleChange(false);
        props?.assignJourneyOptionClicked?.();
        onPersonActionPerformed?.(QUICK_ACTION_CODES.ADD_CARE_JOURNEY);
        break;
      case QUICK_ACTION_CODES.ADD_REFERRAL_ORDER:
        handleMoreActionVisibleChange(false);
        props?.referralOrderOptionClicked?.();
        onPersonActionPerformed?.(QUICK_ACTION_CODES.ADD_REFERRAL_ORDER);
        break;
      case QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER:
        handleMoreActionVisibleChange(false);
        props?.referralOrderOptionClicked?.();
        onPersonActionPerformed?.(QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER);
        break;
      case QUICK_ACTION_CODES.RESET_PASSWORD:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showResetPasswordModal: true}));
        break;
      case QUICK_ACTION_CODES.SEND_ASSESMENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showSendFormDrawer: true}));
        break;
      case QUICK_ACTION_CODES.RUN_AUTOMATION:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showAutomationDrawer: true}));
        break;
      case QUICK_ACTION_CODES.EDIT_PATIENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showEditPatientDrawer: true}));
        break;
      case QUICK_ACTION_CODES.DAY_OPTIMIZER:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showDayOptimizerDrawer: true}));
        break;
      case QUICK_ACTION_CODES.CREATE_CARE_PLAN:
        handleMoreActionVisibleChange(false);
        props?.handleCreateCarePlanClick && props?.handleCreateCarePlanClick();
        break;
      case QUICK_ACTION_CODES.ADD_NEW_TASK:
        handleMoreActionVisibleChange(false);
        setSelectedView(RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK);
        break;
      case QUICK_ACTION_CODES.VIEW_IN_EHR:
        handleMoreActionVisibleChange(false);
        openInEHR();
        break;
      case QUICK_ACTION_CODES.SCHEDULE_APPOINTMENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showAppointmentBookingDrawer: true}));
        break;
      case QUICK_ACTION_CODES.ADD_RELATIVES:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showAddRelativesDrawer: true}));
        break;
      case QUICK_ACTION_CODES.CLINICAL_PROFILE:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showPrintClinicalProfileDrawer: true}));
        break;
      case QUICK_ACTION_CODES.SEND_PATIENT_EDUCATION:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showSendPatientEducationDrawer: true}));
        break;
      case CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT:
        handleMoreActionVisibleChange(false);
        setStateData(prev => ({...prev, showChatWithPatientDrawer: true}));
        break;
      default:
        break;
    }
  };

const getToolTip = (code: string, defaultMessage: string) => {
    switch (code) {
      case CONVERSATION_ACTION_CODES.START_SMS:
        return props?.phoneNumber ? defaultMessage : 'Phone Number not set, please add phone number to take any action'
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        return props?.email ? defaultMessage : 'Email not set, please add email to take any action'
      case CONVERSATION_ACTION_CODES.START_EMAIL:
        return (props?.email || props?.phoneNumber) ? defaultMessage : 'Email or Phone Number not set, please add email or phone Number to take any action'
      case CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT:
        return isPatientLoggedIn ? !contactActiveStatus ? getInActiveContactError(contactInfo) : 'Chat with Patient' : patientNotLoggedInError
      case COMMON_ACTION_CODES.CALL:
        return !contactActiveStatus ? getInActiveContactError(contactInfo): getToolTipMessage('CALL', phoneNumber, email, props.contactInfo as IContact)
      default:
        return defaultMessage
    }
  }

  const getActionDividerLine = (mt?: number) => {
    return (
      <Divider
        orientation={'vertical'}
        alignSelf={'center'}
        style={{
          height: 20,
          width: 1,
          marginTop: mt || -16,
          backgroundColor: Colors.Custom.Gray200,
        }}
      />
    );
  };

  const getAssignee = (): IUserPatientSearchItem => {
    const isPatientPanel = props?.taskPanelType === TaskPanelType.PATIENT;
    const patientName = props?.contactInfo?.name || '';
    return {
      value: isPatientPanel ? patientUuid : userUUID,
      label: isPatientPanel ? patientName : userFullName,
      key: isPatientPanel ? patientUuid : userUUID,
      type: isPatientPanel ? ParticipantType.patient : ParticipantType.staff,
      details: isPatientPanel ? props?.contactInfo : currentUserData,
    };
  };

  useEffect(() => {
    getNodeMetaData();
  }, []);

  const getNodeList = useCallback((
    taskType: typeof EDUCATION_CONTENT_NODE_DETAILS,
    allTaskType: any
  ) => {
    const tasktypeData: any = [];
    if (allTaskType.hasOwnProperty(taskType.type)) {
      const taskObject: any = {};
      taskObject[taskType.type] = allTaskType[taskType.type];
      const filteredUserInputFieldList = taskObject[
        taskType.type
      ].userInputFieldList.filter((item: any) =>
        taskType.userInputFieldListKeys.includes(item.key)
      );
      taskObject[taskType.type].userInputFieldList = [];
      taskObject[taskType.type].userInputFieldList.push(
        ...filteredUserInputFieldList
      );
      tasktypeData.push(taskObject);
    }
    return tasktypeData;
  }, []);

  const getNodeMetaData = useCallback(() => {
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const typeNodes = getNodeList(EDUCATION_CONTENT_NODE_DETAILS, nodeMap);
        const nodeType = 'CareJourneyContentV3';
        if (nodeType) {
          let typeData: any;
          if (typeNodes) {
            typeData = typeNodes.find((obj: any) => obj[nodeType]);
          }
          const userInputFieldList = typeData[nodeType].userInputFieldList;
          const { inputFields: updatedInputFieldList } =
            setFieldsVisibilityFromPreviousNode(
              userInputFieldList,
              nodeMap,
              undefined,
              FlowType.careJourney
            );

          const userInputFieldListCopy = updatedInputFieldList.map(
            (userInputField: any) => {
              return {
                ...userInputField,
                value:
                  userInputField?.value == undefined
                    ? userInputField?.defaultValue
                    : userInputField?.value,
              };
            }
          );
          typeData[nodeType].userInputFieldList = userInputFieldListCopy;
          setTypeNodes(typeData[nodeType]);
        }
      },
      () => {
        setTypeNodes([]);
      }
    );
  }, [getNodeList]);

  const getNewTaskMember = () => {
    const data = {
      value: formattedContactData?.contactUUID,
      label: formattedContactData?.name,
      key: formattedContactData?.contactUUID,
      type: formattedContactData.contactType?.code === PERSON_TYPES.LEAD ? ParticipantType.leads : ParticipantType.patient,
      contactData: formattedContactData?.contactData,
      data: formattedContactData?.contactData,
    };
    return data;
  }

  const getDefaultMember = () => {
    const patientName = props?.contactInfo?.name || '';
    return {
      value:  patientUuid,
      label: patientName,
      key:  patientUuid,
      type: ParticipantType.patient,
      details: props?.contactInfo,
    };
  }
  const showChartsAction = props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.CONTACT_OMNI_VIEW &&
    props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING &&
    props?.contactInfo?.patient?.id &&
    props?.contactInfo?.uuid && 
    !(props?.showCharts === false);

  const isActionCreateReferral = props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.CONTACT_OMNI_VIEW &&
  props?.parentCode !== PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING && isEFaxMessagingEnabled

  const {ehrPatientId: contextEhrPatientId} = usePersonOmniViewContext();
  const ehrPatientId = contextEhrPatientId || getEhrPatientId(contactInfo);
  const accountLocationUuid = getContactProfileAccountLocationUuid(contactInfo);
  const isCentralProfileSelected = accountLocationUuid === accountUUID || false;
  
  const openInEhrUrl = getPatientEHRUrl(ehrPatientId, '', locationGroupId);
  const showViewInEHRBtn = !isSidecarContext && (!!openInEhrUrl || window?.location?.host === 'demo.sandbox.foldhealth.io') && !isCentralProfileSelected;
  const openInEHR = () => {
    window.open(openInEhrUrl, '_blank', 'noopener,noreferrer');
  };

  const navigateTo = (actionCode: string, contactId: string) => {
    if(isSidecarContext) {
      actionCode = actionCode === 'CREATE_CHART' ? 'notes' : actionCode === 'REFERRAL_ORDER' ? 'referral' : actionCode;
      redirectToNewTab(`/members/patient/${contactId}/${actionCode}`)
    } else {
      props?.navigateOrOpenContactIdDrawer?.(contactId,{
        state: {actionType:actionCode},
      } );
    }
  }

  const sendResetPasswordInstructions = async (contactData: any) => {
    const url = `/crm-nest/auth/resetContactPassword`;
    const resetPasswordData = {
      accountUuid: accountUUID,
      contactUuid: contactData?.uuid,
    };
    const headers = {
      accountId: accountId,
    };
    await baseService
      .post(url, resetPasswordData, {headers: headers})
      .then((response) => {
        if (contactData?.email) {
          showToast(
            toast,
            'Password instruction successfully sent on patient email',
            ToastType.success
          );
        } else if (contactData?.phoneNumber) {
          showToast(
            toast,
            'Password instruction successfully sent on patient phone number',
            ToastType.success
          );
        }
      })
      .catch((error) => {
        showToast(
          toast,
          'Something went wrong',
          ToastType.error
        );

      });
  };
  const contactTypeCode = props?.contactInfo?.contactType?.contactType?.code;

  const PATIENT_ACTION_LIST: IConversationChannelList[] = [
    {
      key: QUICK_ACTION_CODES.VIEW_IN_EHR,
      title: 'EHR',
      path: '',
      description: 'View in EHR',
      isMainAction: true,
      disabled: false,
      isDeleted: !showViewInEHRBtn || contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.CREATE_CHART,
      title: 'Charts',
      path: '',
      description: 'Create Chart',
      isMainAction: true,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: !showChartsAction || contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR]
    },
    {
      key: CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT,
      title: 'Chat',
      path: '',
      description: 'Chat with Patient',
      isMainAction: true,
      disabled: isMsoFlagEnabled ? (isActionsDisabled || !isPatientLoggedIn) : !isPatientLoggedIn,
      isDeleted: contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: COMMON_ACTION_CODES.CALL,
      title: 'Call',
      path: '',
      description: 'Call Patient',
      isMainAction: true,
      disabled: isMsoFlagEnabled ? (isActionsDisabled || (showPhoneCall && isCallAllowed)) : !(showPhoneCall && isCallAllowed),
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      title: 'Meet',
      path: '',
      description: 'Start Meeting',
      isMainAction: true,
      disabled: isMsoFlagEnabled ? (isActionsDisabled || !email && !phoneNumber) : !email && !phoneNumber,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.SCHEDULE_APPOINTMENT,
      title: 'Schedule',
      path: '',
      description:  isActionsDisabled ? tooltipMessage : 'Schedule Appointment',
      isMainAction: true,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: CONVERSATION_ACTION_CODES.START_SMS,
      title: 'SMS',
      path: '',
      description: 'Send SMS',
      isMainAction: true,
      disabled: !phoneNumber,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: CONVERSATION_ACTION_CODES.START_EMAIL,
      title: 'Send Email', 
      path: '',
      description: 'Send Email',
      isMainAction: true,
      disabled: !email,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.ADD_CARE_JOURNEY,
      title: 'Assign Journey',
      path: '',
      description: 'Assign Journey',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR]
    },
    {
      key: QUICK_ACTION_CODES.SEND_ASSESMENT,
      title: 'Send Assessment',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.SEND_PATIENT_EDUCATION,
      title: 'Send Education',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.ADD_NEW_TASK,
      title: 'Add Task',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER,
      title: 'Create Referral',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: !isActionCreateReferral || contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.RUN_AUTOMATION,
      title: 'Run Automation',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.DAY_OPTIMIZER,
      title: 'Check Day Optimizer',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.SIDECAR]
    },
    {
      key: QUICK_ACTION_CODES.ADD_RELATIVES,
      title: 'Add Relatives',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.CLINICAL_PROFILE,
      title: 'Print Clinical Profile',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.SIDECAR, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.EDIT_PATIENT,
      title: 'Edit Details',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: false,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE]
    },
    {
      key: QUICK_ACTION_CODES.RESET_PASSWORD,
      title: 'Reset Password',
      path: '',
      isMainAction: false,
      disabled: isMsoFlagEnabled ? (isActionsDisabled ) : false,
      isDeleted: contactTypeCode !== PERSON_TYPES.CUSTOMER,
      allowedViews : [SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER, SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE, SIDECAR_ALLOWED_VIEWS.SIDECAR]
    },
  ];
  const activeProfileIds = getPatientActiveProfileIds(contactInfo?.contactProfiles || [])

  const checkAllowedViews = (action: IConversationChannelList) => {
    return action.allowedViews?.includes(props?.currentView || '') && action?.isDeleted == false;
  }

  const calculateVisibleActions = useCallback(() => {
    if (!actionsContainerRef.current) return;

    const containerWidth = actionsContainerRef.current.offsetWidth;
    const actionWidth = 70;
    const maxVisibleActions = Math.floor((containerWidth - 100) / actionWidth);

    const mainActions = PATIENT_ACTION_LIST.filter(
      action => action.isMainAction && !action.isDeleted && checkAllowedViews(action)
    );
  
    const otherActions = PATIENT_ACTION_LIST.filter(
      action => !action.isMainAction && !action.isDeleted && checkAllowedViews(action)
    );
  
    if (maxVisibleActions >= mainActions?.length) {
      setVisibleActions(mainActions);
      setOverflowActions(otherActions);
    } else {
      setVisibleActions(mainActions.slice(0, maxVisibleActions));
      setOverflowActions([
        ...mainActions.slice(maxVisibleActions),
        ...otherActions
      ]);
    }
  }, [PATIENT_ACTION_LIST, checkAllowedViews]);

  useEffect(() => {
    const handleResize = () => {
      calculateVisibleActions();
    };

    window.addEventListener('resize', handleResize);
    calculateVisibleActions();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const CallButton = () => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
        <OutboundCallSmsView
          showOnlyCallNumbers={true}
          isHideSms={true}
          visible={stateData.visible}
          selectedContactData={contactInfo}
          content={
            <View height={'30px'} style={{ alignItems: 'center', flex: 1 }}>
              <Tooltip
                title={
                  !contactActiveStatus
                    ? getInActiveContactError(contactInfo)
                    : getToolTipMessage(
                      'CALL',
                      phoneNumber,
                      email,
                      props.contactInfo as IContact
                    )
                }
              >
                <Pressable
                  onPress={() => {
                    if (contactActiveStatus) {
                      if (consentCheck || (isAthenaEHR && isCallConsentGivenFlag)) {
                        if (phoneNumber) {
                          setStateData((prev) => {
                            return {
                              ...prev,
                              visible: true,
                            };
                          });
                        } else {
                          handleEdit?.();
                        }
                      } else {
                        handleEdit?.(
                          COMMON_ACTION_CODES.CONSENT_WARNING
                        );
                        setStateData((prev) => {
                          return {
                            ...prev,
                            showEditPatientDrawer: true,
                            callConsentError: true,
                          };
                        });
                      }
                    } else {
                      const message =
                        getInActiveContactError(contactInfo);
                      notification.error({
                        message,
                      });
                    }
                  }}
                  style={{
                    alignItems: 'center',
                  }}
                >
                  <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ marginBottom: 4 }}>
                      <PhoneActionSvgV1
                        width={16}
                        height={16}
                        isActive={phoneNumber ? true : false}
                      />
                    </View>
                    <Text isTruncated style={{ color: phoneNumber ? Colors.FoldPixel.GRAY250 : Colors.FoldPixel.GRAY150, fontSize: 12, lineHeight: 14.4 }} {...testID(TestIdentifiers.callBtn)}>Call</Text>
                  </View>
                </Pressable>
              </Tooltip>
            </View>
          }
          handleAction={(actionCode: string, rawData?: any) => {
            handleActions(actionCode, rawData);
          }}
          onClose={() => {
            setStateData((prev) => {
              return {
                ...prev,
                visible: false,
              };
            });
          }}
        />
      </View>
    );
  };

  return (
    <View style={{ width: '100%' }}>
      <HStack
        ref={actionsContainerRef}
        justifyContent={'space-between'}
        alignItems={'center'} 
        paddingLeft={'12px'}
        paddingRight={'12px'}
        paddingTop={'8px'}
        paddingBottom={'8px'}
        style={{ width: '100%', flex: 1}}
      >
        <HStack 
          flex={1}
          justifyContent={'flex-start'} 
          alignItems={'center'} 
          space={2}
          style={{
            padding: '8px 12px'
          }}
        >
          {visibleActions.map((action, index, array) => (
            <React.Fragment key={action.key}>
              {action.key === COMMON_ACTION_CODES.CALL ? (
                <CallButton />
              ) : (
                <View 
                  height={'30px'} 
                  style={{ 
                    alignItems: 'center', 
                    width: action.title.length * 8, 
                    flex: 1 
                  }}
                >
                  <Tooltip 
                    title={getToolTip(
                      action?.key, 
                      action?.description || action?.title
                    )}
                  >
                    <Pressable 
                      onPress={() => {
                        if(action?.disabled){
                          return false;
                        }
                        if (action.key === QUICK_ACTION_CODES.CREATE_CHART && contactInfo?.id) {
                          navigateTo('CREATE_CHART', contactInfo?.id?.toString());
                        } else {
                          performAction(action.key)
                        }
                      }}
                      style={{ alignItems: 'center', width: '100%' }}
                    >
                      <View 
                        style={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center', 
                          justifyContent: 'center' 
                        }}
                        {...testID(`${action.title}Btn`)}
                      >
                        <View style={{ marginBottom: 4 }}>
                          {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                            action.key,
                            action.disabled ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray500,
                            16
                          )}
                        </View>
                        <Text 
                          isTruncated 
                          style={{ 
                            color: action.disabled ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY250, 
                            fontSize: 12, 
                            lineHeight: 14.4 
                          }}
                        >
                          {action.title}
                        </Text>
                      </View>
                    </Pressable>
                  </Tooltip>
                </View>
              )}
              {index < array.length - 1 && getActionDividerLine(0)}
            </React.Fragment>
          ))}

          {overflowActions?.length > 0 && (
          <Popover
            overlayInnerStyle={{ padding: 0, borderRadius: 12 }}
            overlayStyle={{ padding: 0 }}
            overlay={{ padding: 0 }}
            style={{ padding: 0, borderRadius: 12 }}
            placement={'bottomLeft'}
            trigger="click"
            open={stateData.moreActionPopup}
            onOpenChange={handleMoreActionVisibleChange}
            content={
              <View style={{ width: 180, flex: 1 }}>
                {overflowActions?.map((tabItem: IConversationChannelList) => (
                    <FoldPermitCan
                      key={tabItem.key}
                      resource={MAIN_MENU_CODES.INBOX}
                        action={USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code}
                        component={
                          canStartAction(tabItem.key) ? (
                            <Pressable
                              onPress={() => {
                                if(tabItem?.disabled){
                                  return false;
                                }
                                if (tabItem.key === QUICK_ACTION_CODES.CREATE_REFERRAL_ORDER && contactInfo?.id && props?.currentView == 'patientProfile') {
                                  navigateTo('REFERRAL_ORDER', contactInfo?.id?.toString());
                                } else {
                                  performAction(tabItem.key);
                                }
                              }}
                              style={{
                                padding: 8,
                                backgroundColor: 'white',
                                width: '100%'
                              }}
                              {...testID(tabItem.code || '')}
                            >
                              <HStack flex={1} alignItems="center">
                                <View
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignSelf: 'center',
                                    height: 16,
                                    width: 16,
                                    marginRight: 8
                                  }}
                                  {...testID('image')}
                                >
                                  {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                                    tabItem?.key,
                                    tabItem?.disabled ? Colors.FoldPixel.GRAY150 : Colors.Custom.Gray500
                                  )}
                                </View>
                                <Text
                                  size={'smRegular'}
                                  flex={1}
                                  style={{
                                    color: tabItem?.disabled ? Colors.FoldPixel.GRAY150 : Colors.FoldPixel.GRAY400
                                  }}
                                  {...testID(tabItem.title || '')}
                                >
                                  {intl.formatMessage({ id: tabItem.title })}
                                </Text>
                              </HStack>
                            </Pressable>
                          ) : (
                            <></>
                          )
                        }
                      />
                    ))}
              </View>
            }
          >
            <Pressable
              onPress={() => {
                setStateData((prev) => ({
                    ...prev,
                    moreActionPopup: true,
                  }));
              }}
              style={{ 
                marginRight: props?.shouldShowCloseButton ? 0 : 8,
                alignItems: 'center',
                justifyContent: 'center',
                height: 40,
                width: 32
              }}
              {...testID('MoreBtn')}
            >
              <Tooltip title={'More'}>
                <MenuHorizontalIcon width={20} height={20} />
              </Tooltip>
            </Pressable>
          </Popover>
        )}

          {props?.shouldShowCloseButton &&
            <Stack direction='row' style={{ flex: 1 }}>
              {getActionDividerLine()}
              <Pressable
                onPress={props.handleCloseClick}
                style={{ 
                  marginRight: 4, 
                  marginLeft: 4,
                  height: 44,
                  width: 32,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                {...testID('CloseBtnClick')}
              >
                <CrossIcon size={32} />
              </Pressable>
            </Stack>
          }

        {selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_MEETING_VIEW && (
            <MeetingView
              personData={formattedContactData}
              onPersonActionPerformed={(actionCode: any) => {
                if (actionCode == COMMON_ACTION_CODES.CANCEL) {
                  setSelectedView('');
                }
              }}
            />
          )}
          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP && (
            <EmailDrawerCommonV2
              isOpen={
                selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_MAIL_POPUP
              }
              onClose={() => {
                setSelectedView('');
              }}
              onEmailSent={({msgData}) => {
                setSelectedView('');
                handleOnCreateNewEmailConversation(msgData?.conversationId);
              }}
              contactIds={contactInfo?.id ? [contactInfo?.id] : []}
            />
          )}
          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CONTACT_SMS_DRAWER && (
            <CreateSmsConversationDrawer
              isDrawerVisible={true}
              selectedInbox={{} as any}
              onCreateSmsConversationActionPerformed={(
                actionCode: string,
                actionData: any
              ) => {
                if (
                  actionCode == SMS_INBOX_ACTION_CODES.DRAWER_CLOSE &&
                  !actionData
                ) {
                  setSelectedView('');
                } else {
                  setSelectedView(
                    RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER
                  );
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedConversation:
                        actionData?.selectedInboxConversationData
                          ?.conversations?.[0],
                    };
                  });
                }
              }}
              selectedPatient={contactInfo}
            />
          )}
          {stateData.showChatWithPatientDrawer &&
            contactInfo?.uuid &&
            contactInfo?.patient?.id && (
              <InstantChat
                contactData={contactInfo}
                contactUuid={contactInfo?.uuid}
                isDrawerVisible={
                  stateData.showChatWithPatientDrawer
                }
                onActionPerformed={(actionCode) => {
                  if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                    setStateData((prev) => {
                      return {
                        ...prev,
                        showChatWithPatientDrawer: false,
                      };
                    });
                  }
                }}
              />
            )}

          {selectedView ===
            RIGHT_SIDE_CONTAINER_CODE.SHOW_CONVERSATION_DRAWER && (
            <InstantChatView
              selectedConversation={stateData.selectedConversation}
              contactData={{} as any}
              isLoading={false}
              isDrawerVisible={true}
              isInstantChatView={true}
              instantChatFromNotifications={true}
              onActionPerformed={(actionCode: any, actionData: any) => {
                if (actionCode === COMMON_ACTION_CODES.CLOSE_MODAL) {
                  setSelectedView('');
                } else if (
                  actionCode === CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT
                ) {
                  setStateData((prev) => {
                    return {
                      ...prev,
                      selectedConversation: actionData,
                    };
                  });
                }
              }}
            />
          )}

          {selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK &&
          <AddOrUpdateTask
            assignee={getAssignee()}
            restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
            isVisible={selectedView === RIGHT_SIDE_CONTAINER_CODE.CREATE_TASK}
            onComplete={(data: any) => {
              setSelectedView('');
            }}
            onCancel={() => {
              setSelectedView('');
            }}
            member={getDefaultMember()}
          />}

          {stateData.showAddRelativesDrawer && (
            <AddContactRelationView
              contactData={contactInfo?.patient}
              contactId={contactInfo?.id}
              onFormCompleteAction={(actionCode: string) => {
                if (actionCode === COMMON_ACTION_CODES.EDIT) {
                  setStateData(prev => ({...prev, showAddFamilyMembersDrawer: true}));
                } else if(actionCode === COMMON_ACTION_CODES.CANCEL){
                  setStateData(prev => ({...prev, showAddRelativesDrawer: false}));
                } else {
                  setStateData(prev => ({...prev, showAddFamilyMembersDrawer: false, showAddRelativesDrawer: true}));
                }
              }}
              onRelativeAdded={() => {
                showToast(
                  toast,
                  intl.formatMessage({ id: 'relativeAddedSuccessfully' }),
                  ToastType.success,
                  3000
                );
              }}
              isSideCarContext={isSidecarContext}
            />
          )}
          {stateData.showAddFamilyMembersDrawer && (
            <AddOrUpdateLead
              isShowAddContactTypeOption={true}
              personTypeUuid={getContactTypeId('CUSTOMER')}
              personType={'CUSTOMER'}
              singleLeadData={undefined}
              onFormCompleteAction={() => {
                setStateData(prev => ({...prev, showAddFamilyMembersDrawer: false}));
              }}
            />
          )}
          {stateData.showResetPasswordModal && (
            <ResetPasswordModal
              isOpen={stateData.showResetPasswordModal}
              onClose={() => {
                setStateData(prev => ({ ...prev, showResetPasswordModal: false }));
              }}
              contactInfo={contactInfo}
              onConfirm={() => {
                sendResetPasswordInstructions(contactInfo);
                setStateData(prev => ({ ...prev, showResetPasswordModal: false }));
              }}
            />
          )}
          {stateData.showAppointmentBookingDrawer && (
            <AppointmentBooking
              defaultParticipants={[
                {
                  label: formattedContactData?.name || '',
                  key: formattedContactData?.uuid || '',
                  value: formattedContactData?.uuid || '',
                  type: ParticipantType.patient,
                },
              ]}
              isVisible={true}
              onComplete={() => {
                setStateData(prev => ({ ...prev, showAppointmentBookingDrawer: false }));
              }}
              onCancel={() => {
                setStateData(prev => ({ ...prev, showAppointmentBookingDrawer: false }));
              }}
              defaultProfileId={activeProfileIds?.[0]}
            />
          )}
          {stateData.showAutomationDrawer && (
            <Drawer
              width={isSidecarContext ? '100%' : 700}
              visible={true}
              closeIcon={null}
              closable
              onClose={() => {
                setStateData(prev => ({ ...prev, showAutomationDrawer: false }));
              }}
              title={<></>}
              maskClosable={true}
            >
              <AutomationWorkflowEmployee
                type="patient"
                initData={{ selectedItems: [contactInfo] }}
                onModalClose={() => {
                  setStateData(prev => ({ ...prev, showAutomationDrawer: false }));
                }}
              />
            </Drawer>
          )}
          {stateData.showSendFormDrawer && (
            <ContactSendForm
              isVisible={stateData.showSendFormDrawer}
              assignmentData={{
                patientId: formattedContactData.patientUuid || '',
                contactId: formattedContactData.contactUUID || '',
                assignedById: userUUID,
                patientEmail: formattedContactData.email || '',
                patientFirstName: formattedContactData.firstName || '',
                patientContactUUID: formattedContactData.contactUUID || '',
                contactTypeCode: formattedContactData?.contactType?.code || '',
              }}
              onActionComplete={() => {
                setStateData(prev => ({ ...prev, showSendFormDrawer: false }));
              }}
              onClose={() => {
                setStateData(prev => ({ ...prev, showSendFormDrawer: false }));
              }}
              onCancelAction={() => {
                setStateData(prev => ({ ...prev, showSendFormDrawer: false }));
              }}
            />
          )}
          {stateData.showSendPatientEducationDrawer && (
            <SendEdicationContent
              typeNodes={typeNodes}
              contactId={contactInfo?.uuid || ''}
              onClose={() => {
                setStateData(prev => ({ ...prev, showSendPatientEducationDrawer: false }));
              }}
              isSidecarContext={isSidecarContext}
            />
          )}
          {stateData.showEditPatientDrawer && (
            <AddOrUpdateLead
              singleLeadData={{
                ...contactInfo,
                id: contactInfo?.id,
                emailConsentError: stateData.emailConsentError ?? false,
                smsConsentError: stateData.smsConsentError ?? false,
                callConsentError: stateData.callConsentError ?? false
              }}
              onFormCompleteAction={(actionCode: string, data: any) => {
                if (actionCode === COMMON_ACTION_CODES.ADDED_OR_UPDATED) {
                   showToast(
                     toast,
                     intl.formatMessage({id: 'patientDetailsUpdatedSuccessfully'}),
                     ToastType.success,
                     3000
                   );
                   if (isAthenaEHR) {
                    onPersonActionPerformed?.(actionCode, data);
                   }
                }
                setStateData(prev => ({
                  ...prev,
                  showEditPatientDrawer: false,
                  emailConsentError: false,
                  smsConsentError: false,
                  callConsentError: false
                }));
              }}
              personType={contactInfo?.contactType?.contactType?.code}
              personTypeUuid={contactInfo?.contactType?.contactType?.id}
            />
          )}
          {stateData.showPrintClinicalProfileDrawer &&
            <PrintContactClinicalProfileSectionSelector
              formattedContactData={formattedContactData}
              isOpen={stateData.showPrintClinicalProfileDrawer}
              onClose={() => {
                setStateData(prev => ({ ...prev, showPrintClinicalProfileDrawer: false }));
              }}
              isSidecarContext={isSidecarContext}
            />
          }

          {stateData.showDayOptimizerDrawer && (
            <Drawer
              width={isSidecarContext ? '100%' : '50%'}
              visible={true}
              closeIcon={null}
              closable
              onClose={() => {
                setStateData(prev => ({ ...prev, showDayOptimizerDrawer: false }));
              }}
              title={
                <ModalActionTitle
                title={'Day Optimizer'}
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: 'cancel',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      setStateData(prev => ({ ...prev, showDayOptimizerDrawer: false }));
                    },
                  }
                ]}
              />
              }
              maskClosable={true}
            >
            <DayOptimizer />
            </Drawer>
          )}
        </HStack>
      </HStack>
    </View>
  );
};

export const SidecarPatientProfileActions = withMiniContactViewHOC(
  SideCarPatientProfileActionsComponent
);