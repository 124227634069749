import {useEffect, useRef, useState} from 'react';
import {toArray} from 'lodash';
import {useLazyQuery} from '@apollo/client';
import {USER_ROLE_CODES} from '../../../constants/MlovConst';
import InboxQueries from '../../../services/Inbox/InboxQueries';
import {
  CUSTOM_USER_SEARCH_MODE,
  IAccountUserSearchResp,
  ICustomUserSearchProps,
  IInternalUsers,
  IInternalUsersState,
} from './interface';
import {CustomUserSearchView} from './CustomUserSearchView';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {
  filterLoggedInUserFromInternalUser,
  getSelectedUsersUUIDs,
  getUsersRoleCode,
} from './CustomUserSearchUtils';
import {getAllowedUserPracticeLocationUuids, getUserUUID} from '../../../utils/commonUtils';
import {USER_SEARCH_TYPE} from '../../RightSideContainer/constants';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../utils/configUtils';

export const CustomUserSearch = (props: ICustomUserSearchProps) => {
  const userUuid = getUserUUID();
  const locationUuidRef = useRef<string[]>();
  const isCSAndWorkFlowUserEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_CS_AND_WORKFLOW_USER_ENABLED);
  const [internalUserStateData, setInternalUserStateData] = useState({
    internalUsersOptions: [],
    internalUsersData: [],
    isLoading: false,
    selectedUsersUUIDs: [],
    selectedUsersList: [],
  } as IInternalUsersState);

  const selectedRoleTypesRef = useRef({
    roles: [],
    searchByUserRole: false
  } as {
    roles : string[],
    searchByUserRole: boolean
  });

  const [GetInternalUsersWithMultipleRoleCodes] = useLazyQuery(
    InboxQueries.GetInternalUsersWithMultipleRoleCodes,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInternalUsersByUserRoles] = useLazyQuery(
    InboxQueries.GetInternalUsersByUserRoles,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInitialInternalUsers] = useLazyQuery(
    InboxQueries.GetInitialInternalUsers,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInitialInternalUsersByUserRoles] = useLazyQuery(
    InboxQueries.GetInitialInternalUsersByUserRoles,
    {
      fetchPolicy: 'no-cache',
    }
  );


  const [getInternalUsersByLocationUuid] = useLazyQuery(
    InboxQueries.GetInternalUsersByLocationUuid,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInternalUsersByLocationUuidAndUserRoles] = useLazyQuery(
    InboxQueries.GetInternalUsersByLocationUuidAndUserRoles,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInitialInternalUsersByLocationUuid] = useLazyQuery(
    InboxQueries.GetInitialInternalUsersByLocationUuid,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getInitialInternalUsersByLocationUuidAndUserRoles] = useLazyQuery(
    InboxQueries.GetInitialInternalUsersByLocationUuidAndUserRoles,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [GetInternalUserWithBusinessOwnerByLocationUuid] = useLazyQuery(
    InboxQueries.GetInternalUserWithBusinessOwnerByLocationUuid,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );

  const removeDuplicatesUsers = (users: IInternalUsers[]): IInternalUsers[] => {
    const uniqueUuids = new Set();
    if (users?.length) {
      return users?.filter((item: IInternalUsers) => {
        const uuid = item?.user?.uuid;
        if (!uniqueUuids.has(uuid)) {
          uniqueUuids.add(uuid);
          return true;
        }
        return false;
      });
    }
    return [];
  };

  const getLocationUuids = () => {
    if (
      props.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED &&
      props?.selectedLocationUuids &&
      props?.selectedLocationUuids?.length
    ) {
      return props?.selectedLocationUuids || [];
    } else if (
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_SELECTED_PATIENT_BASED
    ) {
      return props?.selectedPatientLocationUuid;
    }
    return locationUuidRef.current;
  };

  useEffect(() => {
    setInternalUserStateData((prev) => {
      return {
        ...prev,
        internalUsersData: removeDuplicatesUsers([
          ...prev.internalUsersData,
          ...props?.selectedUsersData,
        ]),
        internalUsersOptions: removeDuplicatesUsers([
          ...prev.internalUsersOptions,
          ...props?.selectedUsersData,
        ]),
        selectedUsersUUIDs: getSelectedUsersUUIDs(props.selectedUsersData),
        selectedUsersList: props?.selectedUsersData
      };
    });
  }, [props.selectedUsersData]);

  const getInitialUsersByUserSearchType = async () => {
    if (
      props?.userSearchType ===
      USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER
    ) {
      const searchedInternalUsers =
        await GetInternalUserWithBusinessOwnerByLocationUuid({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
            locationUuids: getLocationUuids(),
            searchString: `%%`,
          },
        });
      return searchedInternalUsers;
    } else if (
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED ||
      props.userSearchType ===
        USER_SEARCH_TYPE.FILTERED_CURRENT_USER_LOCATION_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_SELECTED_PATIENT_BASED
    ) {
      let searchedInternalUsers:any;
      if (selectedRoleTypesRef?.current?.searchByUserRole) {
        searchedInternalUsers = await getInitialInternalUsersByLocationUuidAndUserRoles(
          {
            variables: {
              roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
              limit: 10,
              locationUuids: getLocationUuids(),
              roles: selectedRoleTypesRef?.current?.roles || [],
            },
          }
        );
      } else {
        searchedInternalUsers = await getInitialInternalUsersByLocationUuid(
          {
            variables: {
              roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
              limit: 10,
              locationUuids: getLocationUuids(),
            },
          }
        );
      }
      return searchedInternalUsers;
    } else {
      let searchedInternalUsers:any;
      if (selectedRoleTypesRef?.current?.searchByUserRole) {
        searchedInternalUsers = await getInitialInternalUsersByUserRoles({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
            roles: selectedRoleTypesRef?.current?.roles || [],
          },
        });
      } else {
        searchedInternalUsers = await getInitialInternalUsers({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
          },
        });
      }
      return searchedInternalUsers;
    }
  };

  const getInitialUsers = async () => {
    if (
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER ||
      props.userSearchType ===
        USER_SEARCH_TYPE.FILTERED_CURRENT_USER_LOCATION_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
    ) {
      setInternalUserStateData((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
    } else if (
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_SELECTED_PATIENT_BASED
    ) {
      locationUuidRef.current = [props.selectedPatientLocationUuid || ''];
    }

    const searchedInternalUsers = await getInitialUsersByUserSearchType();
    if (
      props.userSearchType === USER_SEARCH_TYPE.FILTERED_CURRENT_USER_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER ||
      props.userSearchType ===
        USER_SEARCH_TYPE.CURRENT_SELECTED_PATIENT_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
    ) {
      let internalUsers = searchedInternalUsers?.data?.accountUsers;
      if (props?.filterLoggedInUser) {
        internalUsers = filterLoggedInUserFromInternalUser(
          searchedInternalUsers?.data?.accountUsers,
          userUuid
        );
      }
      if (internalUsers && internalUsers?.length) {
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            internalUsersData: removeDuplicatesUsers([
              ...prev.internalUsersData,
              ...internalUsers,
            ]),
            internalUsersOptions: removeDuplicatesUsers([
              ...prev.internalUsersData,
              ...internalUsers,
            ]),
          };
        });
      }
    } else {
      const users = searchedInternalUsers?.data?.accountUsers;
      if (users && users?.length) {
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            internalUsersData: removeDuplicatesUsers([
              ...prev.internalUsersData,
              ...users,
            ]),
            internalUsersOptions: removeDuplicatesUsers([
              ...prev.internalUsersData,
              ...users,
            ]),
          };
        });
      } else {
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            internalUsersData: [],
            internalUsersOptions: [],
          };
        });
      }
    }
    props?.onActionPerformed &&
      props?.onActionPerformed(COMMON_ACTION_CODES.LOADING_COMPLETE);
    setInternalUserStateData((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
  };

  useEffect(() => {
    selectedRoleTypesRef.current = {
      roles: props?.filterRoleTypes || [],
      searchByUserRole: !!props?.filterRoleTypes
    };
    if (selectedRoleTypesRef.current.searchByUserRole) {
      if (selectedRoleTypesRef.current.roles.length === 0) {
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            internalUsersOptions: [],
            internalUsersData: [],
          };
        });
      } else {
        locationUuidRef.current = allowedUserPracticeLocationUuids;
        getInitialUsers();
      }
    }
  }, [props?.filterRoleTypes?.length]);

  useEffect(() => {
    locationUuidRef.current = allowedUserPracticeLocationUuids;
    getInitialUsers();
  }, [
    props?.selectedLocationUuids?.length,
    props?.selectedPatientLocationUuid,
  ]);

  const getInternalUsersBySearchType = async (searchString: string) => {
    if (
      props?.userSearchType ===
      USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED_WITH_BUSINESS_OWNER
    ) {
      const searchedInternalUsers =
        await GetInternalUserWithBusinessOwnerByLocationUuid({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
            locationUuids: getLocationUuids(),
            searchString: `%${searchString}%`,
          },
        });
      return searchedInternalUsers;
    } else if (
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_USER_LOCATION_BASED ||
      props.userSearchType ===
        USER_SEARCH_TYPE.FILTERED_CURRENT_USER_LOCATION_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED ||
      props.userSearchType === USER_SEARCH_TYPE.CURRENT_SELECTED_PATIENT_BASED
    ) {
      let searchedInternalUsers: {data: IAccountUserSearchResp};
      if (selectedRoleTypesRef?.current?.searchByUserRole) {
        searchedInternalUsers = await getInternalUsersByLocationUuidAndUserRoles({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
            searchString: `%${searchString}%`,
            locationUuids: getLocationUuids(),
            roles: selectedRoleTypesRef?.current?.roles || [],
          },
        });
      } else {
        searchedInternalUsers = await getInternalUsersByLocationUuid({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
            searchString: `%${searchString}%`,
            locationUuids: getLocationUuids(),
          },
        });
      }
      return searchedInternalUsers;
    } else {
      let searchedInternalUsers: {data: IAccountUserSearchResp};
      if (selectedRoleTypesRef?.current?.searchByUserRole) {
        searchedInternalUsers = await getInternalUsersByUserRoles({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
            searchString: `%${searchString}%`,
            roles: selectedRoleTypesRef?.current?.roles || [],
          },
        });
      } else {
        searchedInternalUsers = await GetInternalUsersWithMultipleRoleCodes({
          variables: {
            roleCodes: getUsersRoleCode(isCSAndWorkFlowUserEnabled),
            limit: 10,
            searchString: `%${searchString}%`,
          },
        });
      }
      return searchedInternalUsers;
    }
  };

  const searchInternalUser = async (searchString: string) => {
    searchString = searchString.trim();
    if (searchString.length > 1) {
      setInternalUserStateData((prev) => {
        return {
          ...prev,
          internalUsersOptions: [],
          isLoading: true,
        };
      });
      const searchedInternalUsers = await getInternalUsersBySearchType(
        searchString
      );
      let internalUsers = searchedInternalUsers?.data?.accountUsers || [];
      if (
        props.userSearchType ===
          USER_SEARCH_TYPE.FILTERED_CURRENT_USER_LOCATION_BASED ||
        props.userSearchType === USER_SEARCH_TYPE.FILTERED_CURRENT_USER_BASED ||
        props.userSearchType ===
          USER_SEARCH_TYPE.CURRENT_SELECTED_PATIENT_BASED ||
        props.userSearchType === USER_SEARCH_TYPE.SELECTED_LOCATION_BASED
      ) {
        if (props?.filterLoggedInUser) {
          internalUsers = filterLoggedInUserFromInternalUser(
            internalUsers,
            userUuid
          );
        }
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            internalUsersOptions: internalUsers,
            isLoading: false,
          };
        });
      } else {
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            internalUsersOptions: internalUsers,
            isLoading: false,
          };
        });
      }
    } else {
      setInternalUserStateData((prev) => {
        return {
          ...prev,
          internalUsersOptions: internalUserStateData?.internalUsersData,
          isLoading: false,
          searchValue: searchString,
        };
      });
    }
  };

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.SEARCH:
        searchInternalUser(actionData);
        break;

      case COMMON_ACTION_CODES.USER_CHANGE:
        const selectedInternalUsers = toArray(actionData.options).map(
          (option): IInternalUsers => {
            return {
              userId: option?.id,
              user: {
                userRoles: option?.label?.props?.userRoles,
                name: option?.label?.props?.name,
                uuid: option?.value,
                id: option?.id,
              },
            };
          }
        );
        props.onChange(actionData?.values, selectedInternalUsers);
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            selectedUsersUUIDs: actionData?.values,
            selectedUsersList: selectedInternalUsers,
            internalUsersData: removeDuplicatesUsers([
              ...selectedInternalUsers,
              ...prev.internalUsersData,
            ]),
          };
        });
        break;

      case COMMON_ACTION_CODES.USER_REMOVE:
        const selectedUUIDs = internalUserStateData?.selectedUsersUUIDs?.filter(
          (uuid) => {
            return uuid !== actionData;
          }
        );
        const selectedOptions =
          internalUserStateData?.internalUsersData?.filter((user) => {
            return selectedUUIDs.includes(user?.user?.uuid);
          });
        props?.onChange(selectedUUIDs, selectedOptions);
        setInternalUserStateData((prev) => {
          return {
            ...prev,
            selectedUsersUUIDs: selectedUUIDs,
          };
        });
        break;

      default:
        break;
    }
  };

  return (
    <CustomUserSearchView
      autoClearSearchValue={props?.autoClearSearchValue}
      mode={props?.mode ? props?.mode : CUSTOM_USER_SEARCH_MODE.MULTIPLE_SELECT}
      allowClear={props?.allowClear || false}
      formControlLabelStyles={props?.formControlLabelStyles || {}}
      extraTitleStyles={props?.extraTitleStyles || {}}
      hideTooltip={props?.hideTooltip || false}
      titleSize={props?.titleSize || 'smRegular'}
      isRequired={props.isRequired}
      onActionPerformed={onActionPerformed}
      userSelectRef={props.userSelectRef}
      placeholder={props.placeholder}
      isDisabled={props.isDisabled}
      label={props.label}
      className={props?.className || ''}
      disabledUsers={props.disabledUsers}
      selectedUsersUUIDs={internalUserStateData.selectedUsersUUIDs}
      selectedUsersList={internalUserStateData.selectedUsersList}
      internalUsersOptions={internalUserStateData.internalUsersOptions}
      isLoading={internalUserStateData.isLoading}
      customLabel={props?.customLabel}
    />
  );
};
