import {Skeleton, View} from 'native-base';
import React, {useEffect, useState} from 'react';
import {GetOneTimeList, GetSubscriptionList} from '../../../services/Stripe/StripeServices';
import {Colors} from '../../../styles';
import { isWeb } from '../../../utils/platformCheckUtils';
import {DisplayText} from '../DisplayText/DisplayText';
import {IMembershipData} from '../MemberShip/interface';
import MembershipCard from '../MemberShip/MembershipCard';
import {NoSupportingMemberSvg} from '../Svg';
import SubscriptionIcon from '../Svg/SubscriptionIcon';

interface ISubscriptionView {
  contactUuid: string;
  isDefaultClose?: boolean;
  style?: any;
  stripeCusId?: string;
  sideCarDashboardEnabled?: boolean;
}

const SubscriptionView = (props: ISubscriptionView) => {
  const {contactUuid, stripeCusId} = props;
  const [stateData, setStateData] = useState({
    membershipCardList: [] as IMembershipData[],
    loading: false,
    oneTimeCardList: [] as any
  });
  const getSubscriptionList = async () => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const subscriptionResp = await GetSubscriptionList(contactUuid);
    let oneTimeResp = [] as any
    if (stripeCusId) {
      const getOneTimeResp = await GetOneTimeList(stripeCusId)
      if (getOneTimeResp?.data?.data?.length) {
        oneTimeResp = getOneTimeResp?.data?.data;
      }
    }

    if (subscriptionResp?.data?.data.length > 0) {
      const memberShipDetails: IMembershipData[] = subscriptionResp?.data?.data;
      setStateData((prev) => {
        return {
          ...prev,
          membershipCardList: oneTimeResp?.length ? [...memberShipDetails,...oneTimeResp] : memberShipDetails,
          loading: false,
        };
      });
    } else {
      if (oneTimeResp?.length) {
        setStateData((prev) => {
          return {
            ...prev,
            membershipCardList: oneTimeResp,
            loading: false,
          };
        });
      } else {
        setStateData((prev) => {
          return {
            ...prev,
            membershipCardList: [] as IMembershipData[],
            loading: false,
          };
        });
      }
    }
  };

  useEffect(() => {
    getSubscriptionList();
  }, [props?.contactUuid]);

  const renderContent = () => {
    return (
      <View
        style={[
          {backgroundColor: '#ffffff', marginHorizontal: props?.sideCarDashboardEnabled ? 0 : 24, borderRadius: 8,
          // @ts-expect-error: Let's ignore a compile error like this unreachable code
          boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
          paddingHorizontal: props?.sideCarDashboardEnabled ? 0 : 24,
          borderColor: Colors.FoldPixel.GRAY100,
          borderWidth: 1,

        },
        ]}
      >
        {stateData.loading ? (
          <View padding={3}>
            <Skeleton.Text lines={3} />
          </View>
        ) : stateData?.membershipCardList &&
          stateData?.membershipCardList.length > 0 ? (
          stateData?.membershipCardList?.map(
            (cardItem: IMembershipData, index: number) => {
              return (
                <MembershipCard
                  isLast={stateData?.membershipCardList.length == index + 1}
                  activeMembership={cardItem}
                  key={index}
                />
              );
            }
          )
        ) : (
          <NoSupportingMemberSvg titleId={'noSubscription'} />
        )}
      </View>
    );
  };

  return (
    <>
      {/* <CommonCollapse
        isDefaultClose={props.isDefaultClose}
        textLocalId="subscriptions"
        btnList={[]}
        style={props.style ? props.style : {marginBottom: 10}}
        header={
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{marginRight: 10}}>
              <TaskIconOther />
            </View>
            <DisplayText
              size={'lgMedium'}
              extraStyles={{color: Colors.Custom.Gray900}}
              textLocalId={'subscriptions'}
            />
          </View>
        }
        showArrow={false}
      >
      </CommonCollapse> */}
      <View
        style={[
          {
            backgroundColor: props?.sideCarDashboardEnabled ? Colors.FoldPixel.GRAY50 : Colors.Custom.Gray50,
            shadowColor: `rgba(16, 24, 40, 0.06)`,
            shadowRadius: 1,
            shadowOffset: {
              width: 1,
              height: 1,
            },
            shadowOpacity: 0.1,
          },
        ]}
      >
        <View paddingY={6} paddingX={props.sideCarDashboardEnabled ? 0 : 24}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 4
              }}
            >
              <DisplayText
                size={'lgMedium'}
                textLocalId={'subscriptions'}
                extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
                testID={'subscriptions'}
              />
            </View>
          </View>
        </View>
        {renderContent()}
      </View>
    </>
  );
};
export default SubscriptionView;
