import { HStack, InfoOutlineIcon, Text, View, VStack } from 'native-base';
import { PATIENT_CARE_JOURNEY_STATUS_CODE } from '../../../../../constants/MlovConst';
import { Colors } from '../../../../../styles';
import { getDisplayDateFormatted } from '../../../../../utils/DateUtils';
import CreateNew from '../../../../common/Svg/CreateNew';
import { IPatientActionsViewProps } from './PatientJourneyInterface';
import { Popover, Tooltip } from 'antd';
import { GOAL_STATUS_CODES } from '../../../../../constants';
import GoalNoteSvg from '../../../../common/Svg/GoalNoteSvg';
import CrossCircleSvg from '../../../../common/Svg/CrossCircleSvg';
import { JOURNEY_START_TYPE_VALUES } from '../../../Sales/ProductsAndServices/Products/ProductDetailView/ProductDetailViewSidebar/RightSideContainer/ProductDetailViewForm/Forms/FormConst';
import LockTooltipIcon from '../../../../common/LockTooltipIcon';
import { useIntl } from 'react-intl';

export function PatientCareJourneyTitleCell(props: IPatientActionsViewProps) {
  const intl = useIntl();
  function getJourneyFutureStatusChangeEventText() {
    let resultText = '';
    const patientCareJourney = props.record;
    if (!patientCareJourney) {
      return resultText;
    }

    const completeDateTimeDisplayText = patientCareJourney.completedDateTime ? getDisplayDateFormatted(patientCareJourney.completedDateTime) : undefined;
    const stopDateTimeDisplayText = patientCareJourney.stopDateTime ? getDisplayDateFormatted(patientCareJourney.stopDateTime) : undefined;
    const restartDateTimeDisplayText = patientCareJourney.restartDateTime ? getDisplayDateFormatted(patientCareJourney.restartDateTime) : undefined;

    const journeyCompleteText = completeDateTimeDisplayText ? `Journey will complete on ${completeDateTimeDisplayText}` : undefined;
    const journeyStopText = stopDateTimeDisplayText ? `Journey will stop on ${stopDateTimeDisplayText}` : undefined;
    const journeyRestartText = restartDateTimeDisplayText ? `Journey will restart on ${restartDateTimeDisplayText}` : undefined;

    switch (patientCareJourney.statusCode) {
      case PATIENT_CARE_JOURNEY_STATUS_CODE.ASSIGNED:
        break;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.ACTIVE:
        if (journeyStopText) {
          resultText += journeyStopText;
        }
        if (journeyCompleteText) {
          resultText += journeyCompleteText;
        }
        break;
      case PATIENT_CARE_JOURNEY_STATUS_CODE.PAUSED:
        if (journeyStopText || journeyRestartText) {
          resultText += journeyStopText || journeyRestartText;
        }
        break;
    }

    return resultText || '';
  }

  const journeyFutureStatusChangeEventText = getJourneyFutureStatusChangeEventText();

  return (
    <VStack space={0.5}>
      <Text color={Colors.Custom.Gray400} fontSize={12}>{props?.record?.startType?.value || JOURNEY_START_TYPE_VALUES.ROLLING}</Text>
      <HStack>
      <Tooltip
          title={props?.record?.title || props?.record?.careJourney?.title}
        >
          <Text
            textAlign={'left'}
            numberOfLines={1}
            fontSize={14}
            fontWeight={'medium'}
            color={Colors.FoldPixel.GRAY400}
          >
            {props?.record?.title || props?.record?.careJourney?.title}
          </Text>
        </Tooltip>
        {!props?.isAccessToLocation ? (
          <LockTooltipIcon
            label={intl.formatMessage({ id: 'assignJourneyLock' })}
            userMarginLeft={true}
            />
        ) : null}
      </HStack>
      {
        journeyFutureStatusChangeEventText &&
        <View>
          {/* <HStack space={1}> */}
              {/* <InfoOutlineIcon style={{ color: Colors.info[700] as string, marginTop: "2px" }} size="xs" /> */}
              {/* <Text style={{ color: Colors.info[700] as string }} fontSize={'xs'} >{journeyFutureStatusChangeEventText}</Text> */}
            {/* </HStack> */}
            <Text style={{ color: Colors.info[700] as string }} fontSize={'xs'} >{journeyFutureStatusChangeEventText}</Text>
        </View>
      }
      {props?.record?.goalStatusCode &&
        <HStack alignItems='center'>
          {props?.record.goalStatusCode === GOAL_STATUS_CODES.ACHIEVED ?
            <CreateNew
              customColor={Colors.Custom.SuccessColor}
              width={"14"}
              height={"14"}
            /> : <CrossCircleSvg />
          }
          <Text
            style={{ marginHorizontal: 5, color: Colors.Custom.textBlackColor }}
            fontSize={12}
            fontWeight={500}>
            {props?.record.goalStatusCode === GOAL_STATUS_CODES.ACHIEVED ? 'Goals Achieved' : 'Goals Not Achieved'}
          </Text>
          {props?.record?.note && (
            <Popover
              overlayInnerStyle={{
                bottom: 3,
                borderRadius: 16,
                padding: 0,
              }}
              content={
                <div>
                  <div dangerouslySetInnerHTML={{ __html: props?.record?.note }} />
                </div>
              }
              trigger={"hover"}
              placement={'bottom'}
            >
              <div>
                <GoalNoteSvg />
              </div>
            </Popover>
          )
          }
        </HStack>
      }
    </VStack>
  );
}
