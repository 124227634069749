import React from 'react';
import { useCustomFormBuilderContext } from '../Context/CustomFormBuilder.context';
import { CustomFormBuilderActionTypes } from '../CustomFormEngineInterfaces';
import { useDndMonitor, useDroppable } from '@dnd-kit/core';
import ElementWrapper from './ElementWrapper';
import { ElementsType } from '../FormComponents/FormComponents';
import { FormElements } from '../FormComponents/FormComponents';
import { v4 as uuidV4 } from 'uuid';
import { ColumnsComponent, ContainerComponent } from '../BaseComponents/BaseComponentInterface';
import EmptyDroppableView from '../BaseComponents/EmptyDroppableView';
import { findElementIndexAndParentRecursively } from '../CustomFormEngineUtils';

export const CustomBuilderDropArea: React.FC = () => {
  const { state, dispatch } = useCustomFormBuilderContext();
  const { selectedElement, elements, uniqueKeysMap } = state;

  const droppable = useDroppable({
    id: "custom-form-builder-drop-area",
    data: {
      isBuilderDropArea: true,
    },
  });

  useDndMonitor({
    onDragEnd({active, over}) {
      if (!active || !over) return;

      const isDroppingOverFormElementTopHalf =
        over?.data?.current?.isFormElementTopHalf;
      const isDroppingOverFormElementBottomHalf =
        over?.data?.current?.isFormElementBottomHalf;
      const isDroppingOverContainer = over?.data?.current?.isDroppableContainer;
      const isDroppableColumn = over?.data?.current?.isDroppableColumn;
      const type = active.data?.current?.type;
      const component = active.data?.current?.component;

      const overElementId = over?.data?.current?.elementId;
      const activeId = active.data?.current?.elementId;
      const isDroppingOverMainDropArea = over.data?.current?.isBuilderDropArea;


      const activeElementDetails = activeId
        ? findElementIndexAndParentRecursively(elements, activeId)
        : null;
      const overElementDetails = overElementId
        ? findElementIndexAndParentRecursively(elements, overElementId)
        : null;

      const activeElement = !activeId
        ? component
        // If custom component is available then clone it
         ? FormElements[component.type as ElementsType].clone(uuidV4(), component, uniqueKeysMap)
         // If custom component is not available then create new one
         : FormElements[type as ElementsType].construct(uuidV4(), uniqueKeysMap)
        : activeElementDetails?.element;


      // Remove existing element if it exists
      if (activeId) {
        dispatch?.({
          type: CustomFormBuilderActionTypes.REMOVE_ELEMENT,
          payload: {
            element: activeElement,
            resetSelectedElement: false,
            parentElement: activeElementDetails?.parent,
          },
        });
      }

      // If adding a new element to the designer drop area
      if (isDroppingOverMainDropArea && !overElementId) {
        dispatch?.({
          type: CustomFormBuilderActionTypes.ADD_ELEMENT,
          payload: {
            index: elements.length,
            element: activeElement,
            defaultSelect: true,
          },
        });
        return;
      }

      if (!overElementDetails) return;

      if (isDroppableColumn) {
        const overElementComponent = overElementDetails.element as ColumnsComponent;
        const columnIndex = over?.data?.current?.columnIndex;
        const column = overElementComponent.columns[columnIndex];
        dispatch?.({
          type: CustomFormBuilderActionTypes.ADD_ELEMENT,
          payload: {
            index: column.components.length,
            element: activeElement,
            defaultSelect: true,
            parentElement: column,
          },
        });
      } else if (isDroppingOverContainer) {
        const overElementComponent =
          overElementDetails.element as ContainerComponent;
        dispatch?.({
          type: CustomFormBuilderActionTypes.ADD_ELEMENT,
          payload: {
            index: overElementComponent.components?.length || 0,
            element: activeElement,
            defaultSelect: true,
            parentElement: overElementDetails.element,
          },
        });
      } else if (
        isDroppingOverFormElementTopHalf ||
        isDroppingOverFormElementBottomHalf
      ) {
        let indexForNewElement = overElementDetails.index;
        if (isDroppingOverFormElementBottomHalf) {
          indexForNewElement = overElementDetails.index + 1;
        }
        dispatch?.({
          type: CustomFormBuilderActionTypes.ADD_ELEMENT,
          payload: {
            index: indexForNewElement,
            element: activeElement,
            defaultSelect: true,
            parentElement: overElementDetails.parent,
          },
        });
      }
    },
  });

  return (
    <div
      className="w-full padding-4 overflow-y-auto custom-form-builder common-form-container"
      id="custom-form-builder-drop-area"
      onClick={() => {
        if (selectedElement) dispatch?.({ type: CustomFormBuilderActionTypes.SELECT_ELEMENT, payload: null });
      }}
    >
      <div
        ref={droppable.setNodeRef}
        className={"m-auto flex h-full flex-1 flex-grow flex-col items-center justify-start overflow-y-auto rounded-xl" + (droppable.isOver ? " ring-2 ring-primary/20" : "")}
      >
        {elements.length === 0 && (
          <EmptyDroppableView isActive={droppable.isOver} />
        )}
        {elements.length > 0 && (
          <div className="flex w-full flex-col p-4">
            {elements.map((element) => (
              <ElementWrapper key={element.id} element={element} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
