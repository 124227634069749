import { useQuery } from '@apollo/client';
import { Select as AntSelect, Checkbox, DatePicker, Drawer, Form, notification, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
// import { foldAutomationSvg } from '../../../../../common/Svg/NewWorkflowTableViewSvg/NewWorkflowTableViewSvg';
import {getEnvVariable} from '../../../../../../constants/BaseUrlConst'
import {getEhrConfig} from '../../../../../../utils/capabilityUtils'
import {
  Flex,
  FormControl,
  HStack, Icon, Input,
  Modal,
  Pressable,
  Text,
  VStack,
  View,
  useMediaQuery,
} from 'native-base';
import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ColorValue, Dimensions, TouchableWithoutFeedback } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  IPAD_MINI_WIDTH,
  IPAD_WIDTH
} from '../../../../../../constants';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { FlowType } from '../../../../../../context/WorkflowContext';
import { getNaturalLanguageParse } from '../../../../../../services/Billing/BillingServices';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import { ITimezone } from '../../../../../../services/Location/interfaces';
import { Colors } from '../../../../../../styles';
import { getAccountUUID, getEhrConfigObjectByLocations, isEnableCareProgram, isStringArrayElementDiff } from '../../../../../../utils/commonUtils';
import { getMlovIdFromCode } from '../../../../../../utils/mlovUtils';
import CircleIconView, {
  NodeCategory
} from '../../../../../common/CircleIconView/CircleIconView';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { TimezoneSelect } from '../../../../../common/TimezoneSelect/TimezoneSelect';
import TitleSubtitleView from '../../../../../common/TitleSubtitleView/TitleSubtitleView';
import { getCreateWorkflowUrlFromFlowType, getMatchingNode, getWorkflowListPathByFlowType } from '../../../workflowUtils';
import { WorkflowTag } from '../../AddOrUpdateWorkflow/WorkflowTag';
import { phoneTreeAutoSaveInDraft } from '../WorkflowUtils';
import { reactStyles, workflowTriggerStyle as styles, workflowTriggerStyle } from './../../Styles';
import './../../Styles.css';
import { IFrequencyData } from './../IWorkflow';
import { IWorkflowTrigger } from './interfaces';
import { runFoldAutomationSvg } from '../../../../../common/Svg/NewWorkflowTableViewSvg/NewWorkflowTableViewSvg';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import WorkflowLocationSelect from './WorkflowLocationSelect';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import Stack from '../../../../../common/LayoutComponents/Stack';
import { InputStyle } from '../../../../Contacts/Leads/LeadView/LeadViewStyle';
import { getWorkflowsByName } from '../WorkflowApi';
import { debounce } from 'lodash';
import FoldButtonV2, { ButtonType } from '../../../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import NewCloseSvg from '../../../../../common/Svg/NewCloseSvg';
import { testID } from '../../../../../../testUtils';

const { Option } = AntSelect;
const { width, height } = Dimensions.get('window');
const window: any = global.window;
const SpeechRecognition = window?.SpeechRecognition || window?.webkitSpeechRecognition;
const microphone = SpeechRecognition ? new SpeechRecognition() : { isNotSupported: true };

microphone.continuous = true;
microphone.interimResults = true;
microphone.lang = "en-US";

const NewWorkflowTrigger = (props: IWorkflowTrigger) => {
  const [showAlertData, setShowAlertData] = useState({ isShow: false, message: 'Error' });
  const accountUUID = getAccountUUID();
  const [defaultTimezone, setDefaultTimezone] = useState<string | undefined>(props.timezone)
  const getTimezoneOfAccountAndLocationsQuery = useQuery(
    UserPracticeLocationQueries.GetAccountLevelTimezone,
    {
      variables: {
        accountUuid: accountUUID
      },
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
        let defaultTimezone = props.timezone || 'America/New_York'
        if (res?.account_timezones?.length && res?.account_timezones[0]?.timezone?.timezone) {
          defaultTimezone = (res?.account_timezones[0]?.timezone?.timezone)
        }
        setDefaultTimezone(defaultTimezone)
      },
    }
  );
  const [isTagListEdited, setIsTagListEdited] = useState(false);
  const setIsTriggerScreenValid = props.setIsTriggerScreenValid;
  const frequency = props.frequency;
  const triggerTypeMap = props.triggerTypeMap;
  const triggerNodeList = props.triggerNodeList || [];
  const setFrequency = props.setFrequency;
  const weekDay = props.weekDay;
  const setWeekDay = props.setWeekDay;
  const date = props.date;
  const setDate = props.setDate;
  const timeFormat = 'HH:mm';
  const time = props.time
    ? moment(props.time, timeFormat)
    : moment('06:00', timeFormat);
  const setTime = props.setTime;
  const setTimezone = props.setTimezone;
  const timezone = props.timezone;
  const triggerType = props.triggerType;
  const setTriggerType = props.setTriggerType;
  const workflowName = props.workflowName;
  const setStepMachineName = props.setStepMachineName;
  const [isShowErrorColor, setIsShowErrorColor] = useState(props.isShowErrorColor);
  const triggerFrequencyList = props.triggerFrequencyList;
  const [showModal, setShowModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const flowType = props?.flowType || '';
  const commonData = useContext(CommonDataContext);
  const mlovData = commonData.CLOUD_TELEPHONY_MLOV;
  const IvrUsageTypeList = mlovData['IvrUsageType'] || [];
  const inboundIvrTypeId = getMlovIdFromCode(IvrUsageTypeList, 'INCOMING_CALLS');
  const [isSaved, setIsSaved] = useState(false);
  const triggerFrequencyCode: { [key: string]: IFrequencyData } = {};
  const navigate = useNavigate();
  const intl = useIntl()
  triggerFrequencyList.forEach((frequencyData) => {
    triggerFrequencyCode[frequencyData.value] = frequencyData;
  });
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED) && false;
  const [selectedLocations, setSelectedLocations] = useState(props?.locationIds || []);
  const ehrFlagsObject = getEhrConfigObjectByLocations()

  const filteredTriggerNodes = (node: any): boolean => {
    if (node.isDeprecated && props.triggerCondition !== node.type) {
      return false;
    }
  //   if (node?.flagList) {
  //     const isInvalid = (node?.flagList || [])?.some((flag: any) => {
  //     const keypath = flag?.key.split('.')
  //     if (keypath.length > 0 && keypath[0] === 'CommonDataContext') {
  //       let value: any = commonData
  //       keypath.some((key: any, index: number) => {
  //         if (index != 0) {
  //           value = value[key]
  //           if (value === undefined) {
  //             return true;
  //           }
  //         }
  //       })
  //       return flag.valueList?.indexOf(value) == -1;
  //     }
  //   })
  //   return !isInvalid;
  // }
  return true;
};

  // triggerType="A_SPECIFIC_TIME"
  const filterConditionList = triggerNodeList.filter((node) => {
    return filteredTriggerNodes(node);
  });
  const conditionList = filterConditionList.map((node) => {
    return {
      key: node.type + triggerType,
      value: node.type,
      label: node.displayName,
      iconInfo: node?.iconInfo,
      description: node.description,
      allowedFrequencyList: node.allowedFrequencyList,
    };
  });
  const validExistingCondition = conditionList.find((node) => {
    return node.value === props.triggerCondition;
  });
  const [triggerCondition, setTriggerCondition] = useState(
    validExistingCondition ? validExistingCondition : undefined
  );


  const currentTriggerFrequencyList =
    (triggerCondition && triggerCondition?.allowedFrequencyList?.length)
      ? triggerFrequencyList?.filter((frequency) => (triggerCondition.allowedFrequencyList?.indexOf(frequency.value) !== -1))
      : triggerFrequencyList;

  const [componentState, setComponentState] = useState({
    isNaturalLanguage: false,
    naturalLanguage: '',
    isRecording: false,
    isSaveDraft: phoneTreeAutoSaveInDraft,
  });



  const getTriggerFormValidation = () => {
    if (workflowName && componentState.isNaturalLanguage && props.workflowDescription) {
      return true;
    }
    if (!triggerCondition?.value) {
      return false;
    }
    if (
      (triggerTypeMap[triggerType].isFrequencyAllowed && !frequency) ||
      !workflowName
    ) {
      return false;
    }
    if(isMsoEnabled && !(selectedLocations.length)){
      return false
    }
    if (triggerTypeMap[triggerType].isFrequencyAllowed) {
      const frequencyData = triggerFrequencyCode[frequency];
      if (frequencyData.isDateRequired && !date) {
        return false;
      }
      if (frequencyData.isTimeRequired && !time) {
        return false;
      }
      if (frequencyData.isDayRequired && !weekDay) {
        return false;
      }
    }
    return true;
  };

  const isValid = getTriggerFormValidation();
  setIsTriggerScreenValid(isValid);
  const pageSize = 1000;
  const totalPage = Math.ceil(conditionList.length / pageSize);
  const getConditionSelectListDrawer = () => {
    const [isConditionListDrawerVisible, setisConditionListDrawerVisible] = useState(false)
    const toggleConditionListDrawer = () => {
      setisConditionListDrawerVisible(!isConditionListDrawerVisible)
    }
  }
  const checkWorkflowNameExists = async (value: string) => {
    const workflows = await getWorkflowsByName(value) as {id: string, workflowMasterId?: string, name: string}[];
    if (props?.workflowId) {
      const workflow = workflows.find((workflow) => workflow.workflowMasterId === props.workflowId);
      if (workflow) {
        setIsNextButtonDisabled(false);
      } else {
        setIsNextButtonDisabled(workflows.length > 0);
      }
    } else {
      setIsNextButtonDisabled(workflows.length > 0);
    }
  }

  const debouncedCheckWorkflowName = useCallback(
    debounce((value: string) => {
      checkWorkflowNameExists(value);
    }, 500),
    []
  );
  const setName = async (value: string) => {
    setStepMachineName(value);
    debouncedCheckWorkflowName(value);
  }
  const GetConditionSelectBox = (props: { setSelectedConditionLabel: (text: string) =>  void }) => {
    const [searchText, setSearchText] = useState('')
    return (
      <View>
        <HStack justifyItems={'center'} >
          <Text
            style={[
              styles.workflowNameTextLabel,
              {
                textAlign: 'left',
                width: '100%',
                color: Colors.FoldPixel.GRAY400,
                fontSize: 20,
                fontWeight: '500'
              },
            ]}
          >
            {flowType === FlowType.ivr ? 'On call first action' : 'Run automation, when'}
          </Text>
        </HStack>
        <HStack>
          {triggerNodeList.length > 10 ?
            <Input value={searchText} onChangeText={(text) => {
              setSearchText(text)
            }} collapsable={true} marginLeft={2} width={'95%'} type={'text'} height={'40px'} placeholder='Search Triggers' borderRadius={'8px'}>
            </Input> : ''
          }
        </HStack>
        <View
          style={styles.view1}
        >
          {conditionList
            .filter((d, index) => {
              if (searchText) {
                return (d.label || '').toLowerCase()?.indexOf(searchText.toLowerCase()) != -1
              }
              return true
            })
            .map((data, index) => {
              return (

                <Pressable

                  onPress={() => {
                    setTriggerCondition(data || '');
                    const triggerTypeIs = filterConditionList.filter((el) => el.type === data?.value);
                    setTriggerType(triggerTypeIs[0].triggerType);
                    if (triggerTypeIs[0].defaultTriggerTime !== undefined) {
                      setTime(triggerTypeIs[0].defaultTriggerTime);
                    }
                    if (triggerTypeIs[0].defaultTriggerFrequency !== undefined) {
                      setFrequency(triggerTypeIs[0].defaultTriggerFrequency);
                    }

                    props?.setSelectedConditionLabel(data.label);
                    setIsConditionListDrawerVisible(!isConditionListDrawerVisible)

                  }}
                  style={{
                    borderWidth: 0.5,
                    borderColor: data.value == triggerCondition?.value ? Colors.FoldPixel.PRIMARY300 : Colors.FoldPixel.GRAY300,
                    backgroundColor: 'white',
                    borderRadius: 4,
                    width: '95%',
                    marginLeft: '2%',
                    marginVertical:'1%',
                    maxHeight: '64px'
                  }}
                  key={index + 'triggers'}
                >
                  <div className={data.value == triggerCondition?.value ? 'custom-input-box-shadow custom-gradient' : 'custom-input-box-shadow' }>
                  <View flex={10} flexDirection={'row'} padding={'16px'}>
                    <View
                      flex={1}
                      style={styles.alignSelfCenter}
                    >
                      <View alignItems={'center'} justifyContent={'center'} justifyItems={'center'}>
                        <CircleIconView
                          iconInfo={data?.iconInfo}
                          nodeCategory={NodeCategory.moment}
                          borderColor={Colors.FoldPixel.TRANSPARENT}
                          backgroundColor={Colors.FoldPixel.TRANSPARENT}
                          iconColor={Colors.FoldPixel.GRAY300}
                          size='32px'
                        ></CircleIconView>
                      </View>
                    </View>
                    <View flex={9} alignSelf={'center'}>
                      <Text
                        size={'smMedium'}
                        style={styles.text5}
                        color={Colors.FoldPixel.GRAY400}
                      >
                        {data.label}
                      </Text>

                      {
                        data?.description && (
                          <Text
                            paddingLeft={'16px'}
                            size={'smNormal'}
                            color={data.value == triggerCondition?.value ? 'white' : '#000000'}
                            style={styles.justifyContentCenter}
                          >
                            {data?.description || ' '}
                          </Text>
                        )}
                    </View>
                  </View>
                  </div>
                </Pressable>
              );
            })}
        </View>
      </View>
    );
  };

  const getStepMachineNameElement = () => {
    return (

      <VStack style={styles.justifyCenter}>
        <Input
          _hover={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS}}
          padding={'8px'}
          fontSize={'14px'}
          fontWeight={'400'}
          borderRadius={'4px'}
          _focus={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS, backgroundColor:'#F9FAFB'}}
          style={[styles.workflowNameInput, {width:'100%'}]}
          borderColor={
            isShowErrorColor && !workflowName ? 'red.400' : Colors.FoldPixel.GRAY200
          }
          borderWidth={isShowErrorColor?1:.5}
          multiline
          numberOfLines={1}
          placeholder={props?.flowType === 'IVR' ? 'Add phone tree title here' : "Add automation title here"}
          placeholderTextColor={InputStyle.placeholderTextColor}
          value={workflowName}
          onChangeText={setName}
          {...testID('workflow-title-field')}
        />
        {workflowName.length > 0 && isNextButtonDisabled && (
          <Text color="red.400" fontSize="12px" mt={1} style={{textAlign:'left'}}>
            Name already exists
          </Text>
        )}
      </VStack>
    );
  };


  const GetDescriptionComponent = () =>(
    <Input
      _hover={{ borderColor:Colors.FoldPixel.PRIMARY_FOCUS}}
      padding={'8px'}
      fontSize={'14px'}
      fontWeight={'300'}
      fontStyle={'normal'}
      borderRadius={'4px'}
      color={Colors.Custom.Gray600}
      alignSelf={'stretch'}
      _focus={{ borderColor: Colors.FoldPixel.PRIMARY_FOCUS, backgroundColor: '#F9FAFB' }}
      style={[styles.workflowNameInput]}
      borderWidth={.5}
      borderColor={Colors.FoldPixel.GRAY200}
      isFullWidth={true}
      placeholder={intl.formatMessage({id: props?.flowType === 'IVR' ? 'phoneTreeDescription': 'workflowDescription'})}
      placeholderTextColor={InputStyle.placeholderTextColor}
      value={props.workflowDescription}
      lineHeight={4}
      numberOfLines={5}
      multiline
      onChangeText={(newValue: string) => {
        // if (componentState.isRecording) {
        //   setComponentState((prev: any) => {
        //     return { ...prev, isRecording: false };
        //   });
        // }
        props.setWorkflowDescription((oldValue: string) => {
          return newValue;
        });
      } } />
    )

  const convertAndSetDate = (value: moment.Moment | null | undefined) => {
    const dateFormat = triggerFrequencyCode[frequency]?.dateFormat;
    let newDate = '';
    if (value) {
      newDate = value.format(dateFormat);
    }
    if (newDate != props.date) {
      setDate(newDate || '');
    }
  };



  const getDateElement = () => {
    const frequencyData = triggerFrequencyCode[frequency];
    if (!frequencyData || !frequencyData.isDateRequired) {
      return;
    }
    const dateFormat = triggerFrequencyCode[frequency].dateFormat;
    let currentDate: Moment | undefined = moment(date, dateFormat);
    if (currentDate) {
      currentDate = currentDate.isValid() ? currentDate : undefined;
    }
    convertAndSetDate(currentDate);
    const borderColor =
      isShowErrorColor && !currentDate ? 'red.600' : '#ddd5d5';
    return (
      <Flex mt={15} height="container" margin="0">
        <Text size={'smRegular'} style={[styles.workflowNameTextLabel]}>Start Date</Text>
        <View  borderColor={borderColor}>
          <DatePicker
            style={{
              color: borderColor,
              borderRadius:4
            }}
            defaultValue={currentDate}
            disabled={!frequency}
            format={dateFormat}
            className="widht-500"
            onChange={(value) => {
              convertAndSetDate(value);
            }}
          ></DatePicker>
        </View>
      </Flex>
    );
  };
  const convertAndSetTime = (value: moment.Moment | null | undefined) => {
    let newTime = '';
    if (value) {
      newTime = value.format(timeFormat);
    }
    if (newTime != props.time) {
      setTime(newTime || '');
    }
  };

  const getDateTimeElement = () => {
    const frequencyData = triggerFrequencyCode[frequency];
    if (!frequencyData || !frequencyData.isTimeRequired) {
      return;
    }
    let currentTime = time ? moment(time, timeFormat) : undefined;
    if (currentTime) {
      currentTime = currentTime.isValid() ? currentTime : undefined;
    }
    convertAndSetTime(currentTime);
    const borderColor =
      isShowErrorColor && !currentTime ? 'red.600' : '#ddd5d5';
    return (
      <Flex mt={15} style={styles.fullWidth} height="container" mb="5">
        <HStack flex={1}>
          <VStack flex={1}>
            <Text size={'smRegular'} style={[styles.workflowNameTextLabel]}>Start Time</Text>
            <View borderColor={borderColor} borderWidth="0">
              <DatePicker.TimePicker
                format={timeFormat}
                defaultValue={currentTime}
                disabled={!frequency}
                className="widht-100"
                onChange={(value) => {
                  convertAndSetTime(value);
                }}
              ></DatePicker.TimePicker>
            </View>
          </VStack>
          {defaultTimezone &&
            <VStack flex={1} marginLeft={5}>
              <Text size={'smRegular'} style={[styles.workflowNameTextLabel]}>Timezone</Text>
              <TimezoneSelect
                selectedTimezoneValue={timezone || defaultTimezone}
                className="appointment-timezone"
                showLabel={false}
                showSelectBorder={true}
                selectColor="transparent"
                isRequired={true}
                onChange={(timezone?: ITimezone) => {
                  setTimezone(timezone?.timezone);
                }}
              />
            </VStack>}
        </HStack>
      </Flex>
    );
  };

  const setDefaultWeekDay = () => {
    setWeekDay('Monday');
    return weekDay;
  };

  const getDayElement = () => {
    const frequencyData = triggerFrequencyCode[frequency];
    if (!frequencyData || !frequencyData.isDayRequired) {
      return <></>;
    }
    return (
      <Flex
        height="container"
        margin="0"
        style={styles.dayElement}
      >
        <Text style={[styles.workflowNameTextLabel, { fontSize: 12, opacity: .3, letterSpacing: 1.2 , paddingLeft:6}]}>Day</Text>
        <AntSelect
          allowClear={false}
          onClear={() => {
            setWeekDay('');
          }}
          value={weekDay ? weekDay : setDefaultWeekDay()}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            setWeekDay(itemValue || '');
          }}
        >
          {[
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ].map((value: any) => {
            return (
              <Option value={value} key={value} title={value}>{value}</Option>
            );
          })}

        </AntSelect>
      </Flex>
    );
  };

  const getTagListElement = () => {
    return <></>;
    let tagList: Array<string> =
      isTagListEdited || props.workflowId
        ? props.tagList.map((value) => {
          return value;
        })
        : [];
    if (props.workflowId) {
      tagList = props.tagList;
    } else if (!isTagListEdited) {
      tagList = [];
      if (triggerCondition) {
        let triggerConditionValue = '';
        conditionList.some((data) => {
          if (data.value === triggerCondition?.value) {
            triggerConditionValue = data.label;
          }
        });
        tagList.push(triggerConditionValue);
      }
      if (props.triggerType) {
        tagList.push(triggerTypeMap[props.triggerType].displayName);
      }
    }
    if (isStringArrayElementDiff(tagList, props.tagList)) {
      props.setTagList(tagList);
    }
    return (
      <Flex style={styles.conditionView} width={340}>
        <span>Tags</span>
        <WorkflowTag
          isAddNew={true}
          placeholder="Add Tag"
          tagList={tagList}
          setTagList={(newTagList: Array<string>) => {
            setIsTagListEdited(true);
            props.setTagList(newTagList);
          }}
        ></WorkflowTag>
      </Flex>
    );
  };

  const getDefaultFrequency = () => {
    let frequency = '';
    currentTriggerFrequencyList?.map((data) => {
      if (data.value === 'ON_NEED') {
        frequency = data.value;
        setFrequency(data.value || '');
      }
    });
    if (!frequency) {
      frequency = currentTriggerFrequencyList?.[0]?.value;
      setFrequency(frequency || '');
    }
    return frequency;
  };

  const getFrequencyElement = () => {
    return (
      <Flex
        style={[styles.conditionView]}
        mx={0}
        height="container"
      >
        <Text
          size={'smRegular'}
          style={
            [styles.workflowNameTextLabel,
            {
              lineHeight:16.8,
              }]
            }>
              {'Run Automation'}
          </Text>
        <AntSelect
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.colorGray300}
            />
          }
          allowClear={false}
          onClear={() => {
            setFrequency('');
          }}
          style={reactStyles.borderColorGray200}
          dropdownStyle={reactStyles.dropdownStyles}
          value={frequency ? frequency : getDefaultFrequency()}
          showSearch={true}
          filterOption={(input, option: any) => {
            return (
              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(itemValue) => {
            setFrequency(itemValue || '');
          }}
        >
          {currentTriggerFrequencyList.map((data: any) => {
            return (
              <Option value={data.value} key={data.value} title={data.displayName}>{data.displayName}</Option>
            );
          })}

        </AntSelect>
      </Flex>
    );
  };



  const getTriggerNodeFromParseResult = (parseResultList: any) => {
    if (!parseResultList || !parseResultList.length) {
      return []
    }
    return getMatchingNode(parseResultList[0], triggerNodeList, ['entity'])
  }

  const [isLoading, setIsLoading] = useState(false)
  const handleSave = () => {
    if (componentState.isNaturalLanguage) {
      setIsLoading(true);
      getNaturalLanguageParse(props.workflowDescription || '').then((resultList: any) => {
        resultList = resultList || [];
        const nodeList = getTriggerNodeFromParseResult(resultList)
        const naturalLanguageParseNodeList = resultList.filter((data: any, index: any) => { return !!index })
        if (nodeList && nodeList.length > 0) {
          setTriggerType(nodeList[0].triggerType);
          setTriggerCondition(nodeList[0]?.type || '');
          props.setTriggerCondition(nodeList[0]?.type, naturalLanguageParseNodeList, componentState.isSaveDraft);
          setShowModal(false);
          setIsSaved(true);
        } else {
          notification.error({
            message: 'Please use manual trigger to create your automation or try again later with natural language'
          });

          setComponentState((prev: any) => {
            return { ...prev, isNaturalLanguage: false };
          })
        }
      }).finally(() => {
        setIsLoading(false);
      }).catch(() => {
        notification.error({
          message: 'Please use manual trigger to create your automation or try again later with natural language'
        });
      })
    } else {
        isValid ? setShowModal(false) : null;
        isValid ? setIsSaved(true) : null;
        if (isValid) {
          props.setTriggerCondition(triggerCondition?.value, [], componentState.isSaveDraft);
        }
    }
    setIsShowErrorColor(true)
  };
  const startRecordController = (isRecording: boolean) => {
    if (isRecording && !microphone.isNotSupported) {
      microphone.start();
      microphone.onend = () => {
        microphone.start();
      };
    } else {
      microphone.stop();
      microphone.onend = () => {
        // microphone.onend
      };
    }

    microphone.onstart = () => {
      // microphone.onstart
    };

    microphone.onresult = (event: any) => {
      const recordingResult = Array.from(event.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join("");

      // setNote(recordingResult);
      props.setWorkflowDescription(props.workflowDescription + ' ' + recordingResult);
      microphone.onerror = (event: any) => {
        // microphone.onerror
      };
    };
  };

  useEffect(() => {
    startRecordController(componentState.isRecording && showModal);
    return () => {
      startRecordController(false)
    }
  }, [componentState.isRecording, showModal]);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const getCloseAndContinue = () => {
    return (
        <HStack justifyContent='flex-end' alignContent="center" alignItems='center' space={'8px'} paddingX={'20px'}>
          <FoldButtonV2
            label="Next"
            buttonType={ButtonType.primary}
            isDisabled={isNextButtonDisabled}
            onPress={() => {
              if (!isNextButtonDisabled) {
                handleSave();
              }
            }}
          />

          <View alignSelf={'center'} style={styles.view}>
          </View>

          {<Pressable
            onPress={() => {
              if(flowType === FlowType.ivr){
                navigate('/admin/call/ivr')
              }
              else {
                const navigationPath = getWorkflowListPathByFlowType(FlowType.all);
                navigate(navigationPath)
              }
            }}
            disabled={false}
          >
            <NewCloseSvg />
          </Pressable>}
        </HStack>
    );
  };

  const renderTabs = () => {
    return (
      <HStack
        style={styles.hStackStyle}
      >
        <View
          style={styles.view3}
        >
          <Text size={'smMedium'} color={Colors.primary[500]}>
            Automation Library
          </Text>
        </View>
        <View>
          <Text
            size={'smNormal'}
            style={styles.text1}
            color={Colors.primary[500]}
          >
            Saved Workflows
          </Text>
        </View>
      </HStack>
    );
  };

  const renderTabTypes = () => {
    return (
      <View style={styles.view4}>
        {[
          'Basic Information',
          'Medical History',
          'Medical History',
          'Basic Information',
          'Personal Details',
          'Medical History',
          'Personal Details',
          'Medical History',
        ].map((type) => {
          return (
            <View
              style={styles.view5}
            >
              <Text size={'smNormal'} color={Colors.secondary[500]}>
                {type}
              </Text>
            </View>
          );
        })}
      </View>
    );
  };

  const [isConditionListDrawerVisible, setIsConditionListDrawerVisible] = useState(false)
  const [routeSearchParams, setRouteSearchParams] = useSearchParams();
  const CreateTriggerView = () => {
    const currentEnv = getEnvVariable();
    const includeDBTrigger =  currentEnv == 'qa' || currentEnv == 'dev';
    const [ehrConfig, setEhrConfig] = useState(getEhrConfig('', ''))

    useEffect(() => {
      const fetchData = () => {
        setEhrConfig(ehrFlagsObject);
      };
      if (isMsoEnabled) {
        fetchData();
      }
    }, []);

    const [selectedConditionLabel, setSelectedConditionLabel] = useState('')
    const toggleConditionListDrawer = () => {
      setIsConditionListDrawerVisible(!isConditionListDrawerVisible);
    }

    const handleFlowTypeChange = (value: string) => {
      const isValidFlowType = (value: string | FlowType): value is FlowType => {
        return Object.values(FlowType).includes(value as FlowType);
      };

      if (isValidFlowType(value)) {
        const navigationPath = getCreateWorkflowUrlFromFlowType(value);
        navigate(navigationPath);
      } else {
        const navigationPath = getWorkflowListPathByFlowType(FlowType.all);
        navigate(navigationPath);
      }
    };

    const [customBorderColor, setCustomBorderColor] = useState('')
    const [isHoverIn, setIsHoverIn] = useState(false)

    useEffect(()=>{
      if (isShowErrorColor && !selectedConditionLabel){
        setCustomBorderColor('#FF4D4E')
      } else if (isConditionListDrawerVisible)  {
        setCustomBorderColor(Colors.FoldPixel.PRIMARY_FOCUS)
      } else if(isHoverIn) {
        setCustomBorderColor(Colors.FoldPixel.PRIMARY_FOCUS)
      } else {
        setCustomBorderColor(Colors.FoldPixel.GRAY200)
      }

    }, [isShowErrorColor, selectedConditionLabel, isHoverIn, isConditionListDrawerVisible ])

    const getListForAthena = (): Array<{value: string, label: string}> => {
      if(ehrConfig.isAthena) {
        return [
          { value: 'ORDER', label: 'Order'},
          { value: 'PATIENT_CASE', label: 'Patient Case' },
          { value: 'PATIENT_REFERRAL', label: 'Patient Referral' },
        ];
      }
      return [];
    }

    const getListForCareProgramAutomation = (): Array<{ value: string, label: string }> => {
      if (isEnableCareProgram(commonData.userSettings)) {
        return [
          { value: FlowType.CARE_PLAN, label: 'Care Plan' }
        ];
      }
      return [];
    }

    const getInfoMessage =
      props?.flowType === 'IVR'
        ? intl.formatMessage({id: 'phoneTreeAllowedLocationsInfo'})
        : intl.formatMessage({id: 'automationsAllowedLocationsInfo'});

    return (
      <div className='custom-new-workflow-trigger' >
      <View gap={'32px'}>
        <HStack flex={1} height={'100%'} width={'100%'} space={'24px'}>

          <VStack flex={isConditionListDrawerVisible?0.5: 1} space={'24px'}>
          {
              props?.flowType === 'IVR' ? <></>:
            <VStack space={'4px'}>
                <View paddingX={'6px'}>
                  <Text size={'smRegular'} style={styles.workflowSubtitle}>
                      {'Automation Type'}
                  </Text>
                </View>

              <View>

                <AntSelect
                  value={routeSearchParams.get('flowType')}
                  style={{
                    width: '100%',
                    borderRadius:'px',
                    borderWidth:0.5
                  }}
                  onSelect={handleFlowTypeChange}
                  dropdownStyle={{ background: 'white', padding:'8px' , fontSize:'14px'}}
                  disabled = {!(props?.workflowId === undefined) || (props?.flowType === 'IVR') || props?.isDisabledEdit}
                  suffixIcon={
                    <CaretDownOutlined
                      style={reactStyles.colorGray300}
                    />
                  }

                  options={
                    [
                      { value: 'PATIENTS', label: 'Patients/Leads Automation'},
                      { value: 'COMMUNICATION', label: 'Communication' },
                      { value: 'SCHEDULING', label: 'Appointments' },
                      { value: 'FORM', label: 'Forms' },
                      { value: 'JOURNEYS', label: 'Journeys' },
                      { value: 'TASK', label: 'Tasks' },
                      { value: 'LAB', label: 'Labs' },
                      ...( (ehrConfig.isAthena || ehrConfig.isFold) ? [{ label: 'Visit-Note', value: FlowType.note }] : []),
                      { value: 'MICRO_AUTOMATION', label: 'Micro Automation'},
                      ...(includeDBTrigger ?
                        [
                          { value: 'DB_TRIGGER', label: 'Fold Objects'}
                        ] : []),
                      { value: 'IMAGING_REPORT', label: 'Imaging' },
                      { value: 'VITALS', label: 'Vitals' },
                      { value: 'OTHER', label: 'Other' },
                      ...getListForAthena(),
                      ...getListForCareProgramAutomation(),
                    ]
                  }
                />

              </View>
            </VStack >
          }

          <FormControl gap={6}>
            <VStack space={1}>
              <FormControl.Label padding={0} margin={0} alignItems={'center'}>
                <Text size={'smRegular'} style={styles.workflowSubtitle} paddingX={1.5}>
                  {props?.flowType == 'IVR' ? 'Phone Tree Info' : 'Automation Info'}
                </Text>
                <span className="required-dot"></span>
              </FormControl.Label>
              <View>
                {getStepMachineNameElement()}
              </View>
              <View>
                {GetDescriptionComponent()}
              </View>
            </VStack>

            {props.flowType === FlowType.ivr && (
              <VStack>
                <HStack  width={'100%'}>
                  <HStack marginY={2.4}>
                    <Text size={'smRegular'} style={styles.workflowSubtitle}>
                      Phone Tree Type :{' '}
                    </Text>
                  </HStack>
                  <View marginLeft={2} flex={1} width={'50%'}>
                    <AntSelect
                      disabled={!!props?.workflowId}
                      placeholder={'Select Phone Tree Type'}
                      value={props.phoneTreeType || inboundIvrTypeId}
                      onChange={(itemValue: any) => {
                        props.setPhoneTreeType(itemValue);
                      }}
                      style={reactStyles.multiSelectStyle}
                    >
                      {IvrUsageTypeList?.map((flow: any) => {
                        return (
                          <Option key={flow.id} value={flow.id}>
                            {flow.value || ''}
                          </Option>
                        );
                      })}
                    </AntSelect>
                  </View>
                </HStack>
                {phoneTreeAutoSaveInDraft &&
                  <Checkbox
                    defaultChecked={true}
                    onChange={() => {
                      setComponentState((prev: any) => {
                        return { ...prev, isSaveDraft: !prev.isSaveDraft };
                      });
                    }}
                  >
                    Auto save in draft
                  </Checkbox>
                }
              </VStack>
            )}
            </FormControl>

              {isMsoEnabled &&
              <FormControl>
              <Form.Item validateStatus={(isShowErrorColor && !selectedLocations.length) ? 'error' : ''} style={reactStyles.noSpace}>
              <VStack space={1}>
                  <FormControl.Label padding={0} margin={0} alignItems={'center'}>
                    <Text size={'smRegular'} style={workflowTriggerStyle.workflowSubtitle} paddingX={1.5}>Select Locations</Text>
                    <span className="required-dot"></span>
                      <Tooltip
                        title={getInfoMessage}
                        placement={'top'}
                        destroyTooltipOnHide={true}>
                          <Icon
                            mt={1}
                            mx={2}
                            as={AntIcon}
                            color={Colors.Custom.Gray500}
                            name="infocirlceo"
                            size="smMedium"
                          />
                      </Tooltip>
                  </FormControl.Label>
                  <WorkflowLocationSelect
                    isShowErrorColor={isShowErrorColor}
                    onChangeEhrCode={props?.onChangeEhrCode}
                    ehrCode={props?.ehrCode}
                    onChangeLocationIds={props?.onChangeLocationIds}
                    locationIds={selectedLocations}
                    setSelectedLocations={setSelectedLocations}
                    flowType={props?.flowType || ''}
                  />
                  {
                  <Stack direction='row' style={styles.stackStyle}>
                    <Icon
                      mt={1}
                      mx={2}
                      as={AntIcon}
                      color={Colors.Custom.ErrorColor}
                      name="infocirlceo"
                      size="smMedium"
                    />
                    <Text color={Colors.Custom.Gray500}>
                      {intl.formatMessage({ id: flowType === FlowType.ivr ? 'editPhoneTreeLocationWarning' : 'editWorkflowLocationWarning' })}

                    </Text>
                  </Stack>
                  }
              </VStack>
              </Form.Item>
              </FormControl>
              }

            <VStack>

              <View>
                {triggerTypeMap[triggerType]?.isFrequencyAllowed ? (
                  <>
                    {getFrequencyElement()}
                    {frequency ? (
                      <>
                        {getDayElement()}
                        {getDateElement()}
                        {getDateTimeElement()}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {isValid && getTagListElement()}

              </View>
            </VStack>

            <FormControl>
            <VStack space={'4px'}>
                <FormControl.Label margin={0} padding={0} alignItems={'center'}>
                <Text size={'smRegular'} style={styles.workflowSubtitle} paddingX={1.5}>
                  {'Select Trigger'}
                </Text>
                <span className="required-dot"></span>
                </FormControl.Label>
              <Pressable
                onPress={toggleConditionListDrawer}
                disabled= {!(props?.workflowId===undefined) || props?.isDisabledEdit}
                onHoverIn={()=>{setIsHoverIn(true)}}
                onHoverOut={() => { setIsHoverIn(false) }}
              >
                <div className={'trigger-select'}>
                  <View
                    cursor={(props?.isDisabledEdit) ? 'not-allowed': 'default' }
                    flex={10}
                    flexDirection={'row'}
                    gap={'8px'}
                    borderRadius={'4px'}
                    style={{
                      borderColor:   customBorderColor,
                      borderWidth: (isShowErrorColor && !selectedConditionLabel) ? 1 :  0.5,
                      backgroundColor:  triggerCondition?.label ? (isConditionListDrawerVisible ? 'white' :  '#F9F5FF') : 'white',
                      width: '100%',
                      padding:'8px',
                    }}
                  >

                    <View
                      flex={1}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      {runFoldAutomationSvg()}
                    </View>

                    <View
                      alignSelf={'center'}
                      flex={8}
                    >
                        {triggerCondition ? (
                          <Text
                            fontSize={'14px'}
                            color={Colors.FoldPixel.GRAY400}
                          >
                            {triggerCondition?.label}
                          </Text>
                        ) :
                          <Text
                            size={'smRegular'}
                            color={Colors.FoldPixel.GRAY250}
                            fontWeight={400}
                          >
                            {'Select trigger condition'}
                          </Text>
                      }

                    </View>
                    {
                      !props.workflowId && (
                      <View
                        flex={1}
                        alignItems={'flex-end'}
                        justifyContent={'center'}
                      >
                        <CaretRightOutlined style={reactStyles.colorGray300} size={12}/>
                      </View>)
                    }
                  </View>

                  </div>
            </Pressable>
            </VStack>
            </FormControl>
          </VStack>

          {/* right side drawer vstack */}
          <VStack flex={isConditionListDrawerVisible?0.5:0}>
            {isConditionListDrawerVisible && (
              <GetConditionSelectBox setSelectedConditionLabel={setSelectedConditionLabel} />
            )}
          </VStack>
        </HStack>
      </View>
      </div>
    )
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(props?.workflowId === undefined);
  useEffect(() => {
    if (isSaved) {
      setShowModal(false)

    }
    if (!showModal) {
      searchParams.set('triggerState', 'saved')
      setSearchParams(searchParams);
    }
  }, [isSaved, showModal]);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    { maxWidth: IPAD_WIDTH },
    { maxWidth: IPAD_MINI_WIDTH },
  ]);

  const maxDrawerWidth = 990;
  const minDrawerWidth = 500
  const drawerWidth =
    isIPadMiniScreen || isIPadScreen ? width * 0.7 : width * 0.4;

  const conditionDrawerWidth = isConditionListDrawerVisible
    ? Math.min(drawerWidth * 2, maxDrawerWidth)
    : Math.min(drawerWidth, minDrawerWidth);

  const ModalHeader =
    <HStack height={'40px'} width={'100%'} maxWidth={'990px'} color={'000000'} justifyContent={'space-between'} alignItems={'center'}>
      <HStack alignItems={'start'} >
        <TitleSubtitleView
          isTitleSubtitleOldView={true}
          containerStyle={{ marginHorizontal: 0, marginTop: 0 }}
          titleLabelId={
            flowType === FlowType.ivr ?
              (
                (props?.workflowId === undefined) ?
                  'Create Phone Tree' : 'Update Phone Tree')
              : (
                (props?.workflowId === undefined) ?
                  'Create Automation' : 'Update Automation')
          }
          subtitleLabelId=""
          isHideCommonButton={true}
          titleStyle={{fontSize: 24}}
        />
      </HStack>
      <HStack >
        {getCloseAndContinue()}
      </HStack>
    </HStack>

  return (
    <>
      <Drawer
        open={showModal}
        onClose={() => (isValid ? setShowModal(false) : null)}
        width={conditionDrawerWidth}
        title={ModalHeader}
      >
        {CreateTriggerView()}
      </Drawer>
    </>
  );
};

export default NewWorkflowTrigger;
