import React from 'react';
import {Path, Svg} from 'react-native-svg';

const PatientCommunicationSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        fill="#F5F0FF"
      />
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        stroke="#D7C0FF"
        strokeWidth="0.5"
      />
      <Path
        d="M21 18.0848C21.0554 18.0618 21.1103 18.0378 21.1648 18.0129C21.4665 17.8751 21.8051 17.8235 22.1255 17.9093L22.5223 18.0154C23.3275 18.2308 24.0642 17.4942 23.8488 16.6889L23.7426 16.2922C23.6568 15.9718 23.7084 15.6332 23.8463 15.3314C24.159 14.6467 24.3333 13.8854 24.3333 13.0833C24.3333 10.0918 21.9083 7.66667 18.9167 7.66667C17.5206 7.66667 16.2478 8.19483 15.2874 9.06224C14.6986 9.59396 14.2272 10.2532 13.9167 10.9963M14.3333 24.3333C18.0152 24.3333 21 21.3486 21 17.6667C21 13.9848 18.0152 11 14.3333 11C10.6514 11 7.66667 13.9848 7.66667 17.6667C7.66667 18.6963 7.90007 19.6713 8.31683 20.5418C8.46007 20.8411 8.51037 21.1801 8.42462 21.5005L8.15126 22.5223C7.93579 23.3275 8.67251 24.0642 9.47779 23.8488L10.4995 23.5753C10.8199 23.4897 11.1589 23.5399 11.4581 23.6832C12.3287 24.0999 13.3037 24.3333 14.3333 24.3333Z"
        stroke="#8C5AE2"
      />
    </Svg>
  );
};

export default React.memo(PatientCommunicationSvg);
