import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  Checkbox as AntdCheckbox,
  Calendar,
  Collapse,
  Divider,
  Drawer,
  Input,
  Popover,
  Spin,
  Table,
  notification
} from 'antd';
import {
  HStack,
  Divider as NativeBaseDivider,
  Input as NativeBaseInput,
  Pressable,
  Skeleton,
  Spinner,
  Text,
  Tooltip,
  useMediaQuery,
  VStack
} from 'native-base';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dimensions, View, ViewStyle} from 'react-native';
import NoteLinkIcon from '../../assets/Icons/NoteLinkIcon';
import {ALERT_FILTER_KEYS, DATE_FORMATS, IPAD_MINI_WIDTH, IPAD_WIDTH, TASK_TYPES} from '../../constants';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {FHIR_RESOURCE} from '../../constants/FhirConstant';
import {
  ALERTS_GAPS_STATUS_CODES,
  CARE_GAP_COUNT_STATUS_CODES,
  DIAGNOSIS_GAPS_CATEGORY_CODES,
  MLOV_CATEGORY,
  TASK_ALERT_CATEGORY_CODES,
  TASK_ALERT_CUSTOM_CATEGORY_CODES,
  TASK_PRIORITY_CODES,
  TASK_STATUS,
  TASK_STATUS_CODES,
  USER_PREFERENCES_TYPE_CODES
} from '../../constants/MlovConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {FlowType} from '../../context/WorkflowContext';
import {IConditionDetail, IDeclinedPatientConditonInput, ILoginUserData, IMlov} from '../../Interfaces';
import {SEND_EDUCATION_CONTENT} from '../../services/CareJourney/CareJourneyQueries';
import {getDocumentsReferenceWithFilters, getPatientDiagnosisGap} from '../../services/CommonService/AidBoxService';
import TaskQueries, {
  CREATE_AUDIT_LOG_FOR_ALERT_ACTION, GET_TASK_ALERTS_TYPES, GET_TASK_ALERT_PREFERENCE_REASONS
} from '../../services/Task/TaskQueries';
import {LOCAL_STORAGE_KEYS, SIDECAR_EVENT_CODES} from '../../sidecar/common/constants';
import {broadcastEventToSidecarActionsScreen} from '../../sidecar/common/SideCardCommonUtils';
import {Colors} from '../../styles/Colors';
import {getResourceAbilities} from '../../utils/capabilityUtils';
import {filterUsersDataUsingLocationGroupId, getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, getContactProfileAccountLocationUuid, getLocationGroupIdFromLocationId, getPatientActiveProfileLocationIds, getPatientActiveProfiles, getUserFullName, getUserUUID, isAthenaSandboxTenant, isDiagnosisGapDismissed, isMultiTenancyEnabled} from '../../utils/commonUtils';
import {getCareDashboardDateRange, getDateObject, getDateStrFromFormat, getEndOfDay, getFormattedDate, getMomentObjectWithDateStringAndFormat, getNextCalendarDate, getPreviousCalendarDate, isBeforeOrSameDate} from '../../utils/DateUtils';
import {EventBus} from '../../utils/EventBus';
import {
  getMlovCodeFromId,
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovObjectFromId
} from '../../utils/mlovUtils';
import {NOTES_EVENTS, NOTES_MODULE_CODE} from '../common/AddNoteView/NotesConst';
import AddOrUpdateTask from '../common/AddTask/AddOrUpdateTask';
import {manageAttachmentsListData} from '../common/AddTask/AddTaskUtils';
import {AppointmentType} from '../common/CalendarWidget/BookingWorkflows/AppointmentTypeSelection/AppointmentTypeEnum';
import BookingWorkflow from '../common/CalendarWidget/BookingWorkflows/BookingWorkflow';
import {CalendarView} from '../common/CalendarWidget/CalendarWidgetEnums';
import {ParticipantType} from '../common/CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import {ITask, ITasksAttachments} from '../common/CareDashboard/CareDashboardInterfaces';
import {FilterWrapperComponent} from '../common/CareDashboard/CareDashboardTopBar/FilterWrapperComponent';
import ContactSendForm from '../common/ContactSendForm/ContactSendForm';
import Stack from '../common/LayoutComponents/Stack';
import ClearActionIcon from '../common/Svg/ClearActionIcon';
import PlusSvg from '../common/Svg/PlusSvg';
import AddTaskSvg from '../common/Svg/SideCarSvg/AddTaskSvg';
import AssessmentSvg from '../common/Svg/SideCarSvg/AssessmentSvg';
import BackArrowSvg from '../common/Svg/SideCarSvg/BackArrowSvg';
import ClosedCollapseSvg from '../common/Svg/SideCarSvg/ClosedCollpaseSvg';
import DismissedAlertsSvg from '../common/Svg/SideCarSvg/DismissedAlertsSvg';
import DismissIconSvg from '../common/Svg/SideCarSvg/DismissIconSvg';
import OpenedCollapseSvg from '../common/Svg/SideCarSvg/OpenedCollapseSvg';
import PatientEducationSvg from '../common/Svg/SideCarSvg/PatientEducationSvg';
import PendingAlertsSvg from '../common/Svg/SideCarSvg/PendingAlertsSvg';
import ResolvedAlertsSvg from '../common/Svg/SideCarSvg/ResolvedAlertsSvg';
import ScheduleAppointmentSvg from '../common/Svg/SideCarSvg/ScheduleAppointmentSvg';
import SnoozedAlertsSvg from '../common/Svg/SideCarSvg/SnoozedAlertsSvg';
import SuggestedActionsSvg from '../common/Svg/SideCarSvg/SuggestedActionsSvg';
import ThreeDotsSvg from '../common/Svg/SideCarSvg/ThreeDotsSvg';
import useGetBatchedAccountUsers from '../CustomHooks/useGetBatchedAccountUsers';
import FoldButtonV2, {ButtonType} from '../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import {addProfileStatusToNotes, getEhrConfig, enrichNotesWithProfileIds, getNotesContactProfileIds} from '../PersonOmniView/MiddleContainer/CareTimeline/CareTimelineUtils';
import {INotesFormattedDataProps, IPersonData} from '../PersonOmniView/MiddleContainer/interfaces';
import {DocStatus, NoteApiResponse} from '../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import {getFormattedNoteForCareTimeline} from '../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {FORM_CATEGORY_TYPES} from '../RightSideContainer/Forms/FormsConstants';
import {getNodeMetadata} from '../RightSideContainer/Journeys/AddOrUpdateJourney/JourneyMetadataService';
import {IContact} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {setFieldsVisibilityFromPreviousNode} from '../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeHelper';
import {
  ICondition,
  IUserInputField
} from '../RightSideContainer/Workflow/FlowComponent/StateNodes/FlowNodeInterface';
import SideMenu from '../RightSideContainer/Workflow/FlowComponent/StateNodes/SideMenu';
import AlertActionReasonView from './AlertActionReasonView';
import AttachmentsList from './components/AttachmentsList';
import SidecarConfirmationPopup from './components/SidecarConfirmationPopup';
import {
  ALERTS_ACTIONS_MENU, ALERT_ACTION_CODES,
  ALERT_CATEGORY_CODES_MAPPED, CATEGORY_CODES_OF_ALERT_ACTIONS, EDUCATION_CONTENT_NODE_DETAILS,
  NODE_INPUT_KEYS
} from './constants';
import {
  IAlertActionReasons,
  IAlertsViewActionsState,
  IAlertsViewComponentState,
  IDiagnosisGap,
  ITaskAlertsType,
} from './interfaces';
import useFetchAlerts, {ITaskAlert} from './SideBarCustomHooks/useFetchAlerts';
import './SideCar.css';
import {SidecarAddNotesDrawer} from './SidecarAddNotesDrawer';
import {
  getFilteredActionsMenuForAlerts,
  getSeverityNamesFromPriorityCodes
} from './SidecarUtils';
import SidePagination from './SidePagination';
import SnoozeView from './SnoozeView';

import MedicationPilll from '../../assets/Icons/MedicationPilll';
import {evnName} from '../../constants/BaseUrlConst';
import FeatureFlags from '../../constants/FeatureFlags.enums';
import {ReasonForPrioritization} from '../PersonOmniView/LeftContainer/ReasonForPrioritization/ReasonForPrioritization';
import {useUserPreferences} from '../TaskModule/CustomHooks/useUserPreferences';
import MultiSelectFilterView, {ISelectedFilter} from './MultiSelectFilterView';
import {AddMedicationAthenaButtonFinderData} from './SideCarRPAActions/RPAConfigJson';
import {useEhrContextFinder} from './SideCarRPAActions/useEhrContextFinder';

import {debounce} from 'lodash';
import CrossIconSvg from '../common/Svg/CrossIconSvg';
import SearchIconSvg from '../common/Svg/SearchMessageAllSvg/SearchIconSvg';
import EyeOpenSvg from '../common/Svg/WidgetsSvg/EyeOpenSvg';
import { getActivePatientProfile, checkLocationAccess } from '../common/ContactProfile/commonUtils';
import { isAccountConfigEnabled } from '../../utils/configUtils';
import { CONFIG_CODES } from '../../constants/AccountConfigConst';
import { USER_ACCESS_PERMISSION } from '../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../SideMenuBar/SideBarConst';
import LocalStorage from '../../utils/LocalStorage';
import {AutomationWorkflowEmployee} from '../RightSideContainer/MembersManagement/AutomationWorkflowEmployee';
import ZapSvg from '../../assets/svg/ZapSvg';
import { ImagingOrderDataAthena } from './SideCarRPAActions/RPAAutoFillData';
import useContactProfileStatus from '../common/ContactProfile/useContactProfileStatus';
import LockTooltipIcon from '../common/LockTooltipIcon';
import useLoggedInUserAllowedLocationsByTab from '../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import { EXTENSION_URLS } from '../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { GetLocationGroupIdsByPatientIds } from '../../services/Lead/LeadQueries';
import { usePersonOmniViewContext } from '../PersonOmniView/PersonOmniView.context';

const {Column} = Table;
const {Panel} = Collapse;

const EVENT_ACTION_TYPES = {
  IMAGING_ORDER_ADDED: 'IMAGING_ORDER_ADDED',
  DIAGNOSIS_ADDED: 'DIAGNOSIS_ADDED'
}

interface IAlertsViewProps {
  showFiltersTabInAlerts: boolean;
  onFilterApplied: (filterCount: number, isResetFilter?: boolean) => void;
  searchText: string;
  onPendingCountChange: (count: number) => void;
  contactId?: string;
  formattedContactData?: any;
  contactData?: IContact;
  isActionableDrawer?: boolean;
  openCareGapDetail?:(alert: ITaskAlert)=> void;
  openDiagnosisGapDetail?:(alert: IDiagnosisGap)=> void;
  onAlertAdd?: (type: string) => void;
  filterStyle?: ViewStyle,
  isCompactView?: boolean;
  personData?: IPersonData;
  toShowOnlyDiagnosisGaps?: boolean;
  reasonForPrioritization?: string[];
  editCareGapAlert?: (alert: any, type?: string) => void;
  reasonForPrioritizationQA?: string[];
}

const AlertsView = (props: IAlertsViewProps) => {
  const showCategorizedAlerts = false;
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const mlovData = useContext(CommonDataContext);
  const {ehrPatientId} = usePersonOmniViewContext();
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const { fetchContactProfileStatus } = useContactProfileStatus({isMultiTenancyEnabled});
  const userData = mlovData.userData || ({} as ILoginUserData);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.CONSUMER);
  const intl = useIntl();
  const userUuid = getUserUUID();
  const contactLocationUuid = getContactProfileAccountLocationUuid(props.contactData);
  const locationGroupId = getLocationGroupIdFromLocationId(
    mlovData?.accountLocationListWithEHR,
    contactLocationUuid
  );
  const userPreferencesTypeId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.USER_PREFERENCES_TYPE,
    USER_PREFERENCES_TYPE_CODES.USER_ALERTS_FILTERS
  );

   const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );
  const activecontactProfiles = getPatientActiveProfiles(props?.formattedContactData?.contactProfiles || []);
  const activeContactProfileLocationIds = activecontactProfiles ? getPatientActiveProfileLocationIds(activecontactProfiles) : [];

  const showManualAddGaps = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.SHOW_MANUAL_ADD_GAPS);
  const isSideCarContext = !!mlovData.sidecarContext?.isSidecar;
  const isSidecarPatientContext = mlovData?.sidecarContext?.isPatient;
  const isCompactView = isSideCarContext || props.isCompactView;
  const { width: screenWidth } = Dimensions.get('window');
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);
  const patientLocationId = props?.formattedContactData?.accountLocationUuid;
  const allowedLocationIdP360Notes = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.P360_NOTES);
  const commonContextData = useContext(CommonDataContext);
  const accountLocationListWithEHR = commonContextData?.accountLocationListWithEHR || [];
  const locationGroupIdListByPatientContext = accountLocationListWithEHR
  ?.filter((item) => item.uuid === patientLocationId) 
  .map((item) => item.locationGroupId) 

  const finalWidth = isIPadScreen || isIPadMiniScreen ? screenWidth / 1.5 : screenWidth / 2.5;
  const taskPriorityMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_PRIORITY
    );
    const taskAlertPreferenceReasonsMlovs = getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_PREFERENCE_REASONS
    )
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const careGapCountStatusMlovsIds = taskStatusMlov
    .filter((item) => CARE_GAP_COUNT_STATUS_CODES.includes(item.code))
    .map((mlov) => mlov.id);
  const taskAlertDisplayCategoryMlovs = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
  )?.sort((a, b) => {
    if (a.code === 'pending') {
      return -1;
    }
    if (b.code === 'pending') {
      return 1;
    }
    return 0;
  });
  const taskTypeActionsMlovList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_TYPE_ACTIONS
  );

  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    contactLocationUuid
  );
  const elationFormData = {
    categories: resourceAbilities?.allowedCategories,
    noteComponents: resourceAbilities?.allowedNoteComponents,
  };

  const ehrCapabilities = mlovData.ehrCapabilities || [];


  const currentEHR = ehrCapabilities?.length
    ? ehrCapabilities?.[0]?.ehrName
    : '';
  const ehrConfig = getEhrConfig(currentEHR);

  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;

  const initialFilters = {
    selectedPriorityCodes: [] as string[],
    selectedTaskAlertDisplayCodes: [] as string[],
    formData: {
      selectedPriorityList: [] as IMlov[],
      selectedTaskAlertTypeList: [] as ITaskAlertsType[],
    },
  };

  const {
    userList: accountUserData,
  } = useGetBatchedAccountUsers();
  if (ehrConfig.isAthena) {
    const {contextFinderState} = useEhrContextFinder({
      contextUiConfig: AddMedicationAthenaButtonFinderData,
      locationGroupId: locationGroupId,
    });
  }
  const { getUserPreferences, saveUserPreferences, isLoading } = useUserPreferences();

  const accountUserList = filterUsersDataUsingLocationGroupId(accountUserData, locationGroupId)

  const [componentState, setComponentState] =
    useState<IAlertsViewComponentState>({
      pendingAlertsPage: 1,
      snoozedAlertsPage: 1,
      resolvedAlertsPage: 1,
      dismissedAlertsPage: 1,
      careGapPage: 1,
      pageSize: 5,
      search: '',
      filtersInitialData: {
        priorityTypeList: taskPriorityMlovs?.filter((item) => item.code !== TASK_PRIORITY_CODES.LOW),
        taskAlertTypeCategoriesList: [],
      },
      filters: initialFilters,
      pendingAlertsPrioritySort: 'default',
      snoozedAlertsPrioritySort: 'default',
      resolvedAlertsPrioritySort: 'default',
      dismissedAlertsPrioritySort: 'default',
      showSearch: false,
      activePanels: showCategorizedAlerts ? [TASK_ALERT_CATEGORY_CODES.PENDING] : [
        TASK_ALERT_CATEGORY_CODES.PENDING,
        TASK_ALERT_CATEGORY_CODES.DISMISSED,
        TASK_ALERT_CATEGORY_CODES.RESOLVED,
        TASK_ALERT_CATEGORY_CODES.SNOOZED
      ],
      pendingAlerts: [] as ITaskAlert[],
      snoozedAlerts: [] as ITaskAlert[],
      resolvedAlerts: [] as ITaskAlert[],
      dismissedAlerts: [] as ITaskAlert[],
      caregapsAlerts: [] as ITaskAlert[],
      diagnosisGapsAlerts: [] as IDiagnosisGap[],
      masterDiagnosisGapsAlerts: [] as IDiagnosisGap[],
      pendingLoading: false,
      snoozedLoading: false,
      resolvedLoading: false,
      dismissedLoading: false,
      caregapsLoading: false,
      diagnosisGapsLoading: false,
      caregapsTotalCount: 0,
      careGapPendingCount: 0,
      pendingTotalCount: 0,
      snoozedTotalCount: 0,
      resolvedTotalCount: 0,
      dismissedTotalCount: 0,
      diagnosisGapsTotalCount: 0,
      masterDiagnosisGapsTotalCount: 0,
      pendingCountLoading: false,
      snoozedCountLoading: false,
      dismissedCountLoading: false,
      resolvedCountLoading: false,
      hccGroupList: [],
      patientICDConditions: [],
      diagnosisGapLoading: false,
      alertPreferencesId: undefined,
      preferencesJson: {}
    });

    const [actionEventState, setActionEventState] = useState<{
      recordId: string,
      actionType: keyof typeof EVENT_ACTION_TYPES
    }[]>([]);

  const [statusFilter, setStatusFilter] = useState<{
    alertFilters: ISelectedFilter[],
    careGapFilters: ISelectedFilter[],
    diagnosisGapFilters: ISelectedFilter[],
  }>({
    alertFilters: [],
    careGapFilters: [],
    diagnosisGapFilters: [],
  });

  const eventBus = EventBus.getEventBusInstance();
  const diagnosisGapCodes = useMemo(() => [DIAGNOSIS_GAPS_CATEGORY_CODES.DIAGNOSIS_GAPS], []);

  const [actionsState, setActionsState] = useState<IAlertsViewActionsState>({
    showDismissAlertReasonView: false,
    showResolveAlertReasonView: false,
    showAddTaskDrawer: false,
    showAppointmentDrawer: false,
    showContactSendForm: false,
    showEducationalContentDrawer: false,
    showAddNotesDrawer: false,
    alertActionReasons: [],
    showSnoozeView: false,
    showDatePicker: false,
    showUpdateNotesDrawer: false,
    showRunAutomationDrawer: false,
  });
  const [attachmentState, setAttachmentState] = useState<{showAttachmentDrawer: boolean, attachments: ITasksAttachments[]}>({
    showAttachmentDrawer: false,
    attachments: []
  });

  const [typeNodes, setTypeNodes] = useState<any>();
  const [selectedDateForSnooze, setSelectedDateForSnooze] = useState<string>(getNextCalendarDate(CalendarView.day, new Date()).toISOString());
  const [isOpenActionsPopover, setIsOpenActionsPopover] = useState<
    Record<string, boolean>
  >({});

  const [onHoverType, setOnHoverType] = useState<Record<string, boolean>>({});
  const [expandedDescriptions, setExpandedDescriptions] = useState<
    Record<string, boolean>
  >({});
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleDescription = (id: string) => {
    setExpandedDescriptions((prevState: any) => ({
      ...prevState,
      [id]: !prevState?.[id],
    }));
  };
  const needsTruncation = (text: string) => {
    return text?.length && text?.length > 100;
  };

  const getNewTaskMember = () => {
    return {
      value: props?.formattedContactData?.contactUUID,
      label: props?.formattedContactData?.name,
      key: props?.formattedContactData?.contactUUID,
      type: ParticipantType.patient,
      contactData: props?.formattedContactData?.contactData,
      data: props?.formattedContactData?.contactData,
    };
  };

  const {fetchData, handleAlertStatusChange, loaders} = useFetchAlerts({
    contactId: props.contactId,
  });

  const [getLocationGroupIds] = useLazyQuery(GetLocationGroupIdsByPatientIds, {
    fetchPolicy: 'no-cache',
  });

  const {data: taskAlertTypes, loading: loadingTaskAlertTypes} = useQuery<{
    getTaskTypes: {taskTypes: ITaskAlertsType[]};
  }>(GET_TASK_ALERTS_TYPES, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    variables: {
      params: {
        isAlert: true,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const alertsData = data?.getTaskTypes?.taskTypes;
      setComponentState((prev) => ({
        ...prev,
        filtersInitialData: {
          ...prev.filtersInitialData,
          taskAlertTypeCategoriesList: alertsData,
        },
      }));
    },
    onError: (error: any) => {

    },
  });

  const [updateTaskStatus] = useMutation(
    TaskQueries.UPDATE_TASK_STATUS_AND_REFERENCE,
    {
      context: { service: CARESTUDIO_APOLLO_CONTEXT },
    }
  );

  const [createAuditLogForAlertAction] = useMutation(CREATE_AUDIT_LOG_FOR_ALERT_ACTION, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    onCompleted: (data) => {
      //
    },
    onError: (error) => {
      //
    }
  });

  const fetchAlertPreferences = async () => {
    try {
      const preferences = await getUserPreferences({
        userId: userUuid,
        userPreferencesTypeId: userPreferencesTypeId,
      });

      if (preferences?.length) {
        const preferencesJson = JSON.parse(preferences[0]?.preferencesJson || '{}');
        const careGapFilters = preferencesJson[ALERT_FILTER_KEYS.CARE_GAP_FILTERS] || [];
        const diagnosisGapFilters = preferencesJson[ALERT_FILTER_KEYS.DIAGNOSIS_GAP_FILTERS] || [];
        const alertFilters = preferencesJson[ALERT_FILTER_KEYS.ALERT_FILTERS] || [];
        setStatusFilter((prev) => ({
          ...prev,
          careGapFilters: careGapFilters,
          diagnosisGapFilters: diagnosisGapFilters,
          alertFilters: alertFilters,
        }));
        setComponentState((prev) => ({
          ...prev,
          alertPreferencesId: preferences.id,
          preferencesJson: preferencesJson,
        }));
      }
    } catch{
    }
  };

  const saveAlertPreferences = async (keyName: string, updatedFormData: any) => {
    const preferencesJson = componentState?.preferencesJson || {};
    preferencesJson[keyName] = updatedFormData;
    const stringifiedUpdatedFormData = JSON.stringify(preferencesJson);
    try {
      const response = await saveUserPreferences({
        userId: userUuid,
        userPreferencesTypeId: userPreferencesTypeId,
        preferencesJson: stringifiedUpdatedFormData,
        id: componentState.alertPreferencesId,
      });
      setComponentState((prev) => ({
        ...prev,
        alertPreferencesId: response?.id ?? undefined,
        preferencesJson: preferencesJson,
      }));
    } catch {
    }
  };

  const logAuditAction = (
    actionCode: string,
    auditData: {
    formLogIds?: string[],
    appointmentId?: string,
    taskId?: string,
    documentId?: string,
    noteId?: string,
    docStatus?: string,
    data?: any
  }, selectedTaskAlertForAction?: ITaskAlert) => {
    const taskAlert = selectedTaskAlertForAction || actionsState.selectedTaskAlertForAction;
    const actionId = getMlovIdFromCode(taskTypeActionsMlovList, actionCode);
    setActionsState((prev) => ({
      ...prev,
      selectedTaskAlertForAction: undefined,
    }));
    createAuditLogForAlertAction({
      variables: {
        params:
        {
          taskId: taskAlert?.id,
          taskTypeActionId: actionId,
          auditData: auditData,
        }
      }
    })
  }

  const [sendPatientEducation, {loading: sendPatientEducationLoading}] =
    useMutation(SEND_EDUCATION_CONTENT, {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (data) => {
        if (actionsState?.selectedTaskAlertForAction) {
          logAuditAction(
            ALERT_ACTION_CODES.SEND_PATIENT_EDUCATION,
            { documentId: data?.sendEducationContentToContact?.documentId },
            actionsState?.selectedTaskAlertForAction,)
        }
        setActionsState((prev) => ({
          ...prev,
          showEducationalContentDrawer: false,
          selectedTaskAlertForAction: undefined,
        }));
        notification.success({
          message: intl.formatMessage({id: 'contentSentSuccessfully'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
      onError: (error: any) => {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
    });

  const [getAlertActionReasons, {loading: loadingAlertActionReasons}] =
    useLazyQuery<{
      getTaskAlertDisplayPreferenceReasonConfigs: {
        configs: IAlertActionReasons[];
      };
    }>(GET_TASK_ALERT_PREFERENCE_REASONS, {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const alertActionReasonsData =
          data?.getTaskAlertDisplayPreferenceReasonConfigs?.configs;
        setActionsState((prev) => ({
          ...prev,
          alertActionReasons: alertActionReasonsData,
        }));
      },
      onError: (error: any) => {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      },
    });

  useEffect(() => {
    const stateFieldsToChange = {
      pendingAlertsPage: 1,
      snoozedAlertsPage: 1,
      resolvedAlertsPage: 1,
      dismissedAlertsPage: 1,
    };
    const filterCodes = statusFilter.alertFilters.map((filter) => filter.code);
    const activePanels = filterCodes?.length ? filterCodes : componentState.activePanels;
    fetchAlerts(activePanels, stateFieldsToChange, true);
    fetchCareGaps();
  }, [searchText,loadingTaskAlertTypes]);

  useEffect(()=> {
    fetchCareGaps();
  },[componentState?.careGapPage])

  useEffect(() => {
    const openDiagnosisGapsCount = componentState.diagnosisGapsAlerts.reduce((count, diagnosisGap) => {
      const hasOpenCondition = (diagnosisGap?.status === TASK_STATUS_CODES.OPEN) || diagnosisGap?.conditions?.some((condition) =>
        condition?.diagnosisGapStatus &&
        condition.diagnosisGapStatus !== ALERTS_GAPS_STATUS_CODES.CLOSED
      );
      return count + (hasOpenCondition ? 1 : 0);
    }, 0);
    props.onPendingCountChange(componentState.pendingTotalCount + componentState.caregapsTotalCount + openDiagnosisGapsCount);
  }, [componentState.pendingTotalCount, componentState.careGapPendingCount, componentState.diagnosisGapsTotalCount]);

  useEffect(() => {
    const filterCodes = statusFilter.alertFilters.map((filter) => filter.code);
    const defaultPanels = [
      TASK_ALERT_CATEGORY_CODES.PENDING,
      TASK_ALERT_CATEGORY_CODES.SNOOZED,
      TASK_ALERT_CATEGORY_CODES.RESOLVED,
      TASK_ALERT_CATEGORY_CODES.DISMISSED
    ];
    const activePanels = filterCodes.length ? filterCodes : defaultPanels;
    setComponentState((prev) => ({
      ...prev,
      activePanels
    }));

    fetchAlerts(activePanels);
  }, [statusFilter.alertFilters])

  useEffect(() => {
    fetchDiagnosisGapsData();
  }, [statusFilter.diagnosisGapFilters, loadingTaskAlertTypes])

  useEffect(() => {
    fetchCareGaps();
  }, [statusFilter.careGapFilters])

  useEffect(() => {
    fetchAlertPreferences();
  }, []);

  useEffect(() => {
    getNodeMetaData();
    fetchDiagnosisGapsData();
    eventBus.addEventListener(NOTES_EVENTS.OPEN_NOTE_DETAIL, onOpenNoteDetails, { moduleCode: NOTES_MODULE_CODE });
    const eventBus1 = EventBus.getEventBusInstance();
    eventBus1.addEventListener(SIDECAR_EVENT_CODES.CHECK_ELEMENTS_CALLBACK, foundElementListener);

    return () => {
      eventBus.removeEventListenerByEventName(
        NOTES_EVENTS.OPEN_NOTE_DETAIL,
        onOpenNoteDetails,
        {moduleCode: NOTES_MODULE_CODE}
      );
      eventBus1.removeEventListenerByEventName(SIDECAR_EVENT_CODES.CHECK_ELEMENTS_CALLBACK, foundElementListener);
    };
  }, []);

  const updateCareGapStatus = async (recordId: string) => {
    try {
      if (!recordId) {
        return;
      }
      setActionEventState((prev) => {
        const list = prev;
        list.push({
          recordId,
          actionType: EVENT_ACTION_TYPES.IMAGING_ORDER_ADDED as keyof typeof EVENT_ACTION_TYPES,
        })
        return [...list];
      });
    } catch (error) {
      //
    }
  }

  const callUpdateTaskStatus = async (recordId: string, statusId: string) => {
    try {

      await updateTaskStatus({
        variables: {
          params: {
            id: recordId,
            data: {
              statusId,
            },
          },
        },
      });
      setActionEventState((prev) => prev.filter((event) => event.recordId !== recordId));
      fetchCareGaps();
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    actionEventState.forEach((event) => {
      switch(event.actionType) {
        case EVENT_ACTION_TYPES.IMAGING_ORDER_ADDED:
          const statusId = getMlovIdFromCode(taskStatusMlov, TASK_STATUS_CODES.PENDING_EXTERNAL_ACTION);
          callUpdateTaskStatus(event.recordId, statusId);
          break;

        case EVENT_ACTION_TYPES.DIAGNOSIS_ADDED:
          fetchDiagnosisGapsData();
          break;
      }
    });
  }, [actionEventState])

  const foundElementListener = useCallback((eventData) => {
    if (eventData?.elementData  === 'SUCCESS_ELEMENT_FOUND') {
       updateCareGapStatus(eventData?.recordId)
    } else {
      console.error('Element not found');
    }
  }, []);
  const filterDIagnosoBySearchText = (searchText: string) => {
    if (!searchText) {
      setComponentState((prev) => ({
        ...prev,
        diagnosisGapsAlerts: componentState.masterDiagnosisGapsAlerts || [],
        diagnosisGapsTotalCount: componentState.masterDiagnosisGapsTotalCount || 0,
      }));
      return;
    }

    const filteredDiagnosisGaps = componentState.masterDiagnosisGapsAlerts?.filter((item) => {
      return (
        item.hccCode.toLowerCase().includes(searchText.toLowerCase()) ||
        item.hccDescription.toLowerCase().includes(searchText.toLowerCase()) ||
        (item.conditions && item.conditions.some((condition: any) =>
          condition.name.toLowerCase().includes(searchText.toLowerCase())
        ))
      );
    });

    setComponentState((prev) => ({
      ...prev,
      diagnosisGapsAlerts: filteredDiagnosisGaps || [],
      diagnosisGapsTotalCount: filteredDiagnosisGaps?.length || 0,
    }));
  };

  const filterByStatusDiagnosisGaps = (
    masterDiagnosisGapsAlerts: IDiagnosisGap[] ,
    statusCodes: string[]
  ) => {
    const diagnosisGaps = masterDiagnosisGapsAlerts.length ? masterDiagnosisGapsAlerts : componentState.masterDiagnosisGapsAlerts;
    const filteredDiagnosisGaps = diagnosisGaps?.filter((gap) =>
      gap.conditions?.some((condition) => {
        if (condition.diagnosisGapStatus === 'closed') {
           return statusCodes.includes('rejected');
        }
        return statusCodes.includes(condition?.diagnosisGapStatus || '');
      })
    );
    setComponentState((prev) => ({
      ...prev,
      diagnosisGapsAlerts: filteredDiagnosisGaps || [],
      diagnosisGapsTotalCount: filteredDiagnosisGaps?.length || 0,
      masterDiagnosisGapsAlerts: masterDiagnosisGapsAlerts || [],
      masterDiagnosisGapsTotalCount: masterDiagnosisGapsAlerts?.length || 0,
      diagnosisGapsLoading: false,
    }));
  };

  useEffect(() => {
    filterDIagnosoBySearchText(searchText);
  }, [searchText]);

  const fetchAlerts = async (
    statusCodes: string[],
    stateFieldsToUpdate?: {[key: string]: any},
    getAlertCountOfAllCategories?: boolean,
    filters?: IAlertsViewComponentState['filters']
  ) => {
    if (loadingTaskAlertTypes || isLoading) {
      return
    }
    if (!!stateFieldsToUpdate) {
      setComponentState((prev) => ({
        ...prev,
        ...stateFieldsToUpdate,
      }));
    }
    if (!showCategorizedAlerts) {
      setComponentState((prev) => ({
        ...prev,
        pendingTotalCount: 0,
        snoozedTotalCount: 0,
        resolvedTotalCount: 0,
        dismissedTotalCount: 0,
        pendingAlerts: [],
        snoozedAlerts: [],
        resolvedAlerts: [],
        dismissedAlerts: []
      }));
    }
    const taskTypeIds: string[] = []
    taskAlertTypes?.getTaskTypes?.taskTypes.forEach(alertType => {
      if (alertType.code !== TASK_TYPES.CARE_GAPS && alertType.code !== TASK_TYPES.DIAGNOSIS_GAPS) {
        taskTypeIds.push(alertType.id)
      }
    })
    const paramsArray = statusCodes.map((statusCode) => {
      const params = {
        page: componentState.careGapPage,
        pageSize: componentState.pageSize,
        search: componentState.search,
        filters: filters ?? componentState.filters,
        prioritySort: '',
        statusCodes: [statusCode],
        taskTypeIds: taskTypeIds
      };

      let stateKeyToUpdate = '';
      let loadingKeyToUpdate = '';
      let totalCountKeyToUpdate = '';
      let countLoadingKeyToUpdate = '';

      switch (statusCode) {
        case TASK_ALERT_CATEGORY_CODES.PENDING:
          setComponentState((prev) => ({
            ...prev,
            pendingLoading: true,
            pendingCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.pendingAlertsPage ||
            componentState.pendingAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.pendingAlertsPrioritySort ||
            componentState.pendingAlertsPrioritySort;
          stateKeyToUpdate = 'pendingAlerts';
          loadingKeyToUpdate = 'pendingLoading';
          totalCountKeyToUpdate = 'pendingTotalCount';
          countLoadingKeyToUpdate = 'pendingCountLoading';
          break;
        case TASK_ALERT_CATEGORY_CODES.SNOOZED:
          setComponentState((prev) => ({
            ...prev,
            snoozedLoading: true,
            snoozedCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.snoozedAlertsPage ||
            componentState.snoozedAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.snoozedAlertsPrioritySort ||
            componentState.snoozedAlertsPrioritySort;
          stateKeyToUpdate = 'snoozedAlerts';
          loadingKeyToUpdate = 'snoozedLoading';
          totalCountKeyToUpdate = 'snoozedTotalCount';
          countLoadingKeyToUpdate = 'snoozedCountLoading';
          break;
        case TASK_ALERT_CATEGORY_CODES.RESOLVED:
          setComponentState((prev) => ({
            ...prev,
            resolvedLoading: true,
            resolvedCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.resolvedAlertsPage ||
            componentState.resolvedAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.resolvedAlertsPrioritySort ||
            componentState.resolvedAlertsPrioritySort;
          stateKeyToUpdate = 'resolvedAlerts';
          loadingKeyToUpdate = 'resolvedLoading';
          totalCountKeyToUpdate = 'resolvedTotalCount';
          countLoadingKeyToUpdate = 'resolvedCountLoading';
          break;
        case TASK_ALERT_CATEGORY_CODES.DISMISSED:
          setComponentState((prev) => ({
            ...prev,
            dismissedLoading: true,
            dismissedCountLoading: true,
          }));
          params.page =
            stateFieldsToUpdate?.dismissedAlertsPage ||
            componentState.dismissedAlertsPage;
          params.prioritySort =
            stateFieldsToUpdate?.dismissedAlertsPrioritySort ||
            componentState.dismissedAlertsPrioritySort;
          stateKeyToUpdate = 'dismissedAlerts';
          loadingKeyToUpdate = 'dismissedLoading';
          totalCountKeyToUpdate = 'dismissedTotalCount';
          countLoadingKeyToUpdate = 'dismissedCountLoading';
          break;
        default:
          throw new Error(`Unknown statusCode: ${statusCode}`);
      }

      return {
        params,
        stateKeyToUpdate,
        loadingKeyToUpdate,
        totalCountKeyToUpdate,
        countLoadingKeyToUpdate,
      };
    });
    // Kept this code for future reference if count on all categories is needed
    const allCodes = Object.values(TASK_ALERT_CATEGORY_CODES);
    const codesForAggregateQuery = getAlertCountOfAllCategories
      ? allCodes.filter(
          (code) =>
            !statusCodes?.includes(code) &&
            code === TASK_ALERT_CATEGORY_CODES.PENDING
            // This is to get count for pending alerts tab when it is collapsed but count is needed. When count for all categories needs to be shown, this condition of pending can be removed
        )
      : undefined;
    codesForAggregateQuery?.forEach((displayCategoryCode) => {
      const countLoadingKeyToUpdate = `${displayCategoryCode.toLowerCase()}CountLoading`;
      setComponentState((prev) => ({
        ...prev,
        [countLoadingKeyToUpdate]: true,
      }));
    });
    const paramsForAggregateQuery = {
      filters: filters ?? componentState.filters,
    };
    const fetchedAlertDataArray = await fetchData(
      searchText,
      paramsArray,
      codesForAggregateQuery,
      paramsForAggregateQuery
    );
    fetchedAlertDataArray.forEach((fetchedAlertData, index) => {
      if (paramsArray?.length > 0 && index <= paramsArray?.length - 1) {
        const {
          stateKeyToUpdate,
          loadingKeyToUpdate,
          totalCountKeyToUpdate,
          countLoadingKeyToUpdate,
        } = paramsArray[index];
                setComponentState((prev) => ({
          ...prev,
          [stateKeyToUpdate]: fetchedAlertData.data || [],
          [loadingKeyToUpdate]: false,
          [totalCountKeyToUpdate]: fetchedAlertData.totalCount,
          [countLoadingKeyToUpdate]: false,
        }));
      } else if (!!codesForAggregateQuery) {
        const displayCategoryCode =
          codesForAggregateQuery[index - paramsArray?.length || 0];
        const totalCountKeyToUpdate = `${displayCategoryCode.toLowerCase()}TotalCount`;
        const countLoadingKeyToUpdate = `${displayCategoryCode.toLowerCase()}CountLoading`;
        const loadingKeyToUpdate = `${displayCategoryCode.toLowerCase()}Loading`;
        setComponentState((prev) => ({
          ...prev,
          [loadingKeyToUpdate]: false,
          [totalCountKeyToUpdate]: fetchedAlertData.totalCount,
          [countLoadingKeyToUpdate]: false,
        }));
      }
    });
    return fetchedAlertDataArray;
  };

  const getNodeMetaData = () => {
    getNodeMetadata(
      FlowType.careJourney,
      (response) => {
        const nodeMap = response?.nodeMasterData?.nodeMap;
        const typeNodes = getNodeList(
          EDUCATION_CONTENT_NODE_DETAILS,
          nodeMap
        );
        const nodeType = 'CareJourneyContentV3';
        if (nodeType) {
          let typeData: any;
          if (typeNodes) {
            typeData = typeNodes.find((obj: any) => obj[nodeType]);
          }
          const userInputFieldList = typeData[nodeType].userInputFieldList;
          const {inputFields: updatedInputFieldList} =
            setFieldsVisibilityFromPreviousNode(
              userInputFieldList,
              nodeMap,
              undefined,
              FlowType.careJourney
            );

          const userInputFieldListCopy = updatedInputFieldList.map(
            (userInputField: IUserInputField) => {
              return {
                ...userInputField,
                value:
                  userInputField.value == undefined
                    ? userInputField.defaultValue
                    : userInputField.value,
              };
            }
          );
          typeData[nodeType].userInputFieldList = userInputFieldListCopy;
          setTypeNodes(typeData[nodeType]);
        }
      },
      () => {
        notification.error({
          message: intl.formatMessage({id: 'apiErrorMsg'}),
          duration: 3.0,
          placement: 'topRight',
        });
      }
    );
  };

  const getNodeList = (
    taskType: typeof EDUCATION_CONTENT_NODE_DETAILS,
    allTaskType: any
  ) => {
    const tasktypeData: any = [];
    if (allTaskType.hasOwnProperty(taskType.type)) {
      const taskObject: any = {};
      taskObject[taskType.type] = allTaskType[taskType.type];
      const filteredUserInputFieldList = taskObject[
        taskType.type
      ].userInputFieldList.filter((item: any) =>
        taskType.userInputFieldListKeys.includes(item.key)
      );
      taskObject[taskType.type].userInputFieldList = [];
      taskObject[taskType.type].userInputFieldList.push(
        ...filteredUserInputFieldList
      );
      tasktypeData.push(taskObject);
    }
    return tasktypeData;
  };

  const handleFilterChange = (isResetFilter?: boolean) => {
    let filterCount = 0;
    if (!isResetFilter) {
      if (componentState.filters.formData.selectedPriorityList.length > 0) {
        filterCount += 1;
      }
      if (componentState.filters.formData.selectedTaskAlertTypeList.length) {
        filterCount += 1;
      }
    }
    props.onFilterApplied(filterCount, isResetFilter);
    const stateFieldsToChange = {
      pendingAlertsPage: 1,
      snoozedAlertsPage: 1,
      resolvedAlertsPage: 1,
      dismissedAlertsPage: 1,
    };
    const statusCodes = [];
    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.PENDING)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.PENDING);
    }
    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.SNOOZED)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.SNOOZED);
    }

    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.RESOLVED)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.RESOLVED);
    }
    if (
      componentState?.activePanels.includes(TASK_ALERT_CATEGORY_CODES.DISMISSED)
    ) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.DISMISSED);
    }
    const filterStatusCodes = statusFilter?.alertFilters?.length > 0
      ? statusCodes.filter((code) =>
          statusFilter.alertFilters.some((alertFilter) => alertFilter.code === code)
        )
      : statusCodes;
    fetchAlerts(
      filterStatusCodes,
      stateFieldsToChange,
      true,
      isResetFilter ? initialFilters : undefined
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComponentState((prev) => ({
      ...prev,
      search: event?.target?.value,
      page: 1,
    }));
    fetchAlerts([
      TASK_ALERT_CATEGORY_CODES.PENDING,
      TASK_ALERT_CATEGORY_CODES.SNOOZED,
      TASK_ALERT_CATEGORY_CODES.RESOLVED,
      TASK_ALERT_CATEGORY_CODES.DISMISSED,
    ]);
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    displayCategoryCode: string
  ) => {
    const sortOrder =
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : 'default';
    const stateKeyToUpdate = displayCategoryCode ? `${displayCategoryCode.toLowerCase()}AlertsPrioritySort` : 'totalAlertsPrioritySort';
    const stateFieldsToChange = {
      [stateKeyToUpdate]: sortOrder,
    };
    fetchAlerts(displayCategoryCode ? [displayCategoryCode] : [
      TASK_ALERT_CATEGORY_CODES.PENDING,
      TASK_ALERT_CATEGORY_CODES.SNOOZED,
      TASK_ALERT_CATEGORY_CODES.RESOLVED,
      TASK_ALERT_CATEGORY_CODES.DISMISSED
    ], stateFieldsToChange);
  };

  const handlePaginationChange = (
    displayCategoryCode: string,
    newPage: number,
    newPageSize: number
  ) => {
    const stateKeyToUpdate = displayCategoryCode ? `${displayCategoryCode.toLowerCase()}AlertsPage` : 'totalAlertsPage';
    const stateFieldsToUpdate = {
      [stateKeyToUpdate]: newPage,
      pageSize: newPageSize,
    };
    fetchAlerts(displayCategoryCode ? [displayCategoryCode] : [
      TASK_ALERT_CATEGORY_CODES.PENDING,
      TASK_ALERT_CATEGORY_CODES.SNOOZED,
      TASK_ALERT_CATEGORY_CODES.RESOLVED,
      TASK_ALERT_CATEGORY_CODES.DISMISSED
    ], stateFieldsToUpdate);
  };

  const handlePanelChange = (key: string | string[]) => {
    const newActivePanels = Array.isArray(key) ? key : [key];
    const stateFieldsToUpdate = {activePanels: newActivePanels};
    const newlyAddedPanels = newActivePanels?.filter(
      (code) => !componentState?.activePanels?.includes(code)
    );
    const statusCodes = [];

    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.PENDING)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.PENDING);
    }
    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.SNOOZED)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.SNOOZED);
    }
    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.RESOLVED)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.RESOLVED);
    }
    if (newlyAddedPanels?.includes(TASK_ALERT_CATEGORY_CODES.DISMISSED)) {
      statusCodes.push(TASK_ALERT_CATEGORY_CODES.DISMISSED);
    }
    setComponentState((prev) => ({
      ...prev,
      ...stateFieldsToUpdate,
    }));
    if (statusCodes.length > 0) {
      fetchAlerts(statusCodes);
    }
  };
  const fetchCareGaps = async () => {
    if (loadingTaskAlertTypes || isLoading) {
      return
    }
    setComponentState((prev)=> {
      return {
        ...prev,
        caregapsLoading: true,
      }
    })
    const taskTypeIds: string[] = []
    taskAlertTypes?.getTaskTypes?.taskTypes.forEach(alertType => {
      if (alertType.code === TASK_TYPES.CARE_GAPS) {
        taskTypeIds.push(alertType.id)
      }
    })
    const careGapFilterCodes = statusFilter.careGapFilters.map((filter) => filter.code);
    type TaskAlertCategoryCode = typeof TASK_ALERT_CATEGORY_CODES[keyof typeof TASK_ALERT_CATEGORY_CODES];
    const validCategoryCodes = Object.values(TASK_ALERT_CATEGORY_CODES);
    const matchingCodes = careGapFilterCodes?.filter((code): code is TaskAlertCategoryCode =>
      validCategoryCodes.includes(code as TaskAlertCategoryCode)
    );
    const statusIds = careGapFilterCodes?.length
      ? careGapFilterCodes
          .map((code) => getMlovIdFromCode(taskStatusMlov, code))
          .filter((id): id is string => !!id)
      : [];
    const params = {
      page: componentState.careGapPage,
      pageSize: componentState.pageSize,
      search: componentState.search,
      prioritySort: '',
      taskTypeIds: taskTypeIds,
      statusCodes: matchingCodes?.length ? matchingCodes : validCategoryCodes,
      statusIds: statusIds,
      alertType: TASK_TYPES.CARE_GAPS,
      isFilterApplied: matchingCodes?.length > 0
    };
    const paramsArray = [
      {
        params: params,
        stateKeyToUpdate: 'caregapsAlerts',
        loadingKeyToUpdate: 'caregapsLoading',
        totalCountKeyToUpdate: 'caregapsTotalCount'
      }
    ]
    try {
      setComponentState((prev) => ({...prev, caregapsLoading: true}));
      const searchTextTitle = props.searchText || searchText;
      const fetchedAlertDataArray = await fetchData(
        searchTextTitle,
        paramsArray,
        []
      );
      fetchedAlertDataArray.forEach((fetchedAlertData, index) => {
        if (paramsArray?.length > 0 && index <= paramsArray?.length - 1) {
          const {stateKeyToUpdate, loadingKeyToUpdate, totalCountKeyToUpdate} =
            paramsArray[index];
          const careGapPendingCount = fetchedAlertData?.data?.filter(
            (alert: ITaskAlert) => careGapCountStatusMlovsIds?.includes(alert?.statusId || '')
          )?.length;
          setComponentState((prev) => ({
            ...prev,
            [stateKeyToUpdate]: fetchedAlertData.data || [],
            [loadingKeyToUpdate]: false,
            [totalCountKeyToUpdate]: fetchedAlertData.totalCount,
            careGapPendingCount: careGapPendingCount
          }));
        }
      });
    } catch (error) {
      setComponentState((prev) => ({...prev, caregapsLoading: false}));
    }
  }
  const getComponentStateForCode = (code: string) => {
    const lowerCode = code.toLowerCase();
    return {
      totalCount: componentState[
        `${lowerCode}TotalCount` as keyof IAlertsViewComponentState
      ] as number,
      alerts: componentState[
        `${lowerCode}Alerts` as keyof IAlertsViewComponentState
      ] as ITaskAlert[],
      loading: componentState[
        `${lowerCode}Loading` as keyof IAlertsViewComponentState
      ] as boolean,
    };
  };
  const handleCareGapDetail = (record: ITaskAlert) =>{
    props.openCareGapDetail?.(record);
  }
  const createOrder = (args: {name: string}) => {
    const {name} = args;
    broadcastEventToSidecarActionsScreen(
      '',
      SIDECAR_EVENT_CODES.ADD_MEDICATION,
      {
        ordersName: name,
      }
    );
  };

  const getMedicationName = (alert: ITaskAlert) => {
    return alert?.additionalAttributes?.orderDetails.type === 'MED'
      ? alert?.additionalAttributes?.orderDetails?.name
      : '';
  };
  const handleDiagnosisGapDetail = (record: IDiagnosisGap) =>{
    props.openDiagnosisGapDetail?.(record);
  }

  const handleVisibleChange = (visible: any, recordId: string) => {
    setSelectedDateForSnooze(getNextCalendarDate(CalendarView.day, new Date()).toISOString());
    setActionsState((prev) => ({
      ...prev,
      showSnoozeView: false,
      showDatePicker: false,
    }));
    setIsOpenActionsPopover({[recordId]: visible});
  };

  const getAlertCountView = (loading: boolean, alertCount: number) => {
    if(isAthenaSandboxTenant()){
      return <></>
    }
    return (
      loading ? (
        <Stack
          direction={'row'}
          style={{
            marginBottom: 2,
            justifyContent: 'center',
          }}
        >
          <Skeleton.Text
            alignContent={'center'}
            width={10}
            justifyContent={'center'}
            alignItems={'center'}
            lines={0.5}
            marginTop={1}
          ></Skeleton.Text>
        </Stack>
      ) : (
        <Stack
          direction={'row'}
          style={{
            backgroundColor: Colors.FoldPixel?.GRAY50,
            borderRadius: 4,
            borderWidth: 0.5,
            borderColor: Colors.FoldPixel?.GRAY100,
          }}
        >
          <Text
            style={{
              color: Colors.FoldPixel?.GRAY300,
              fontWeight: '400',
              fontSize: 14,
              paddingHorizontal: 4,
              paddingVertical: 2,
            }}
          >
            {alertCount}
          </Text>
        </Stack>
      )
    )
  }

  const renderTable = (
    alerts: any,
    loading: boolean,
    totalCount: number,
    displayCategoryCode: string
  ) => {
    return (
      <Table
        className={showCategorizedAlerts ? "alertsTable" : "alertsTable care-gap"}
        dataSource={alerts}
        rowKey="id"
        pagination={false}
        loading={{
          spinning: loading,
          indicator: (
            <View style={{marginTop: 10}}>
              <Spin size={'default'} />
            </View>
          ),
        }}
        onChange={(pagination: any, filters: any, sorter: any) =>
          handleTableChange(pagination, filters, sorter, displayCategoryCode)
        }
        locale={{
          emptyText: intl.formatMessage({id: 'noAlerts'}),
        }}
      >
        {/* <Column
          title={
            <Text
              size={'xsNormal'}
              style={{
                color: Colors?.FoldPixel?.GRAY250
              }}
            >
              S
            </Text>
          }
          dataIndex="severity"
          key="severity"
          sorter
          width={'5%'}
          render={(text, record: ITaskAlert) => {
            const priorityCode = getMlovCodeFromId(
              taskPriorityMlovs,
              record?.priorityId
            );
            const severityValue = getSeverityNamesFromPriorityCodes(priorityCode)
            return (
              <Tooltip title={severityValue}>
                <View style={{ alignItems: 'center' }}>
                  <AlertSeverityIcon priorityCode={priorityCode} />
                </View>
              </Tooltip>
            );
          }}
        /> */}
        <Column
          title={
            <Text
              size={'xsNormal'}
              style={{
                color: Colors?.FoldPixel?.GRAY250
              }}
            >
              Detail
            </Text>
          }
          dataIndex="title"
          key="title"
          width={showManualAddGaps ? '60%' : '50%'}
          render={(text, record: ITaskAlert) => {
            const displayCategoryCode = getMlovCodeFromId(taskAlertDisplayCategoryMlovs, record.taskDisplayCategoryId);
            const displayCategoryId = getMlovIdFromCode(taskAlertDisplayCategoryMlovs, displayCategoryCode);
            const needsTruncationForDescription = needsTruncation(
              record?.description
            );
            const reasonObject = record?.taskAlertDisplayPreferences?.find((alertPreference) => {
              const isResolvedCategory = displayCategoryCode === TASK_ALERT_CATEGORY_CODES.RESOLVED;
              const matchesCategory = alertPreference.taskAlertDisplayCategoryId === displayCategoryId;
              if (isResolvedCategory) {
                return matchesCategory;
              }
              return alertPreference.userId === userUuid && matchesCategory;
            });
            const reasonMlov = getMlovObjectFromId(
              reasonObject?.reasonId ||'',
              taskAlertPreferenceReasonsMlovs
            );
            const reasonText = reasonMlov?.value;
            const reasonDisplayTitle = displayCategoryCode === TASK_ALERT_CATEGORY_CODES.RESOLVED ? 'Reason for Resolution: ' : 'Reason for Dismissal: '
            const noteToDisplay = reasonObject?.note;

            const activePatientProfile = getActivePatientProfile(props?.contactData?.contactProfiles || []);
            const taskLocation = record?.taskLocations?.[0]?.locationId;
            const isAlertOfActiveLocation = checkLocationAccess({
              locationUuids: [taskLocation || ""],
              allowedLocationUuids: [activePatientProfile?.accountLocationUuid || ""],
              isMultiTenancyEnabled,
            });
            const haveAccessToActivePatientProfile = checkLocationAccess({
              locationUuids: [activePatientProfile?.accountLocationUuid || ""],
              allowedLocationUuids: allowedUserAccountLocationUuids,
              isMultiTenancyEnabled,
            });
            const haveAccess = isMultiTenancyEnabled ? haveAccessToActivePatientProfile && isAlertOfActiveLocation : true;
            return (
              <Stack
                direction={'row'}
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction={'column'} space={2} style={{flex: 1}}>
                  <Pressable onPress={() => {
                    haveAccess && props?.editCareGapAlert?.(record, TASK_TYPES.CLINICAL_FINDINGS)
                  }}>
                    <Text
                      size={'smRegular'}
                      style={{
                        color: !haveAccess ? Colors?.FoldPixel?.GRAY300 :
                          displayCategoryCode ===
                          TASK_ALERT_CATEGORY_CODES.RESOLVED
                            ? Colors.Custom.AlertsDescriptionColor
                            : Colors?.FoldPixel?.GRAY400,
                        fontWeight: '400',
                        ...(isSideCarContext && {width: screenWidth * 0.4}),
                        fontSize: 14,
                        ...(displayCategoryCode ===
                          TASK_ALERT_CATEGORY_CODES.RESOLVED && {
                          textDecorationLine: 'line-through',
                        }),
                      }}
                    >
                      {!haveAccess ? (
                        <LockTooltipIcon name="Alert" />
                      ) : null}
                      {record.title}
                    </Text>
                  </Pressable>
                  {
                    record?.attachments?.length > 0 && (
                      <Pressable onPress={() => setAttachmentState((prev) => ({
                        ...prev,
                        showAttachmentDrawer: true,
                        attachments: record?.attachments,
                      }))}

                      style={{
                        maxWidth: 'fit-content',
                      }}
                      >
                        <Stack direction={'row'} space={8} style={{alignItems: 'center', maxWidth: 'fit-content'}}>
                          <Text
                            style={{
                              color: Colors?.FoldPixel?.PRIMARY300,
                              fontWeight: '400',
                              fontSize: 12,
                            }}
                          >
                            {'View attachments'}
                            <ClosedCollapseSvg customStrokeColor={Colors?.FoldPixel?.PRIMARY300} />
                          </Text>
                        </Stack>
                      </Pressable>
                    )
                  }
                  {!!record?.description && (
                    <Text
                      size={'xsNormal'}
                      style={{
                        color: Colors?.Custom?.AlertsDescriptionColor,
                        fontWeight: '400',
                        fontSize: 12,
                        ...(isSideCarContext && {width: screenWidth * 0.4}),
                      }}
                    >
                      {needsTruncationForDescription &&
                        !expandedDescriptions[record.id]
                        ? `${record.description.slice(0, 100)}...`
                        : record.description}
                      {
                        needsTruncationForDescription && ' '
                        // added empty space to show before 'show more' and 'show less'
                      }
                      {needsTruncationForDescription && (
                        <Text
                          style={{
                            color: Colors.FoldPixel.PRIMARY300,
                            textDecorationLine: 'underline',
                          }}
                          selectable={true}
                          onPress={() => toggleDescription(record.id)}
                        >
                          {expandedDescriptions?.[record.id]
                            ? 'Show less'
                            : 'Show more'}
                        </Text>
                      )}
                    </Text>
                  )}
                  { (!!reasonText || !!noteToDisplay) &&
                    <View style={{ borderRadius: 4, backgroundColor: Colors.FoldPixel?.GRAY50, padding: 4, maxWidth: 'fit-content' }}>
                        {!!reasonText &&
                          <Stack direction={'row'}
                          >
                            {<Text
                              style={{
                                color: Colors?.FoldPixel?.GRAY300,
                                fontWeight: '500',
                                fontSize: 12,
                              }}>
                              {
                                reasonDisplayTitle
                              }
                            </Text>
                            }
                            <Text
                              style={{
                                color: Colors?.FoldPixel?.GRAY300,
                                fontWeight: '400',
                                fontSize: 12,
                              }}
                            >
                              {reasonText}
                            </Text>

                          </Stack>
                        }
                      {!!noteToDisplay &&
                        <Stack direction={'row'}
                        >
                          {<Text
                            style={{
                              color: Colors?.FoldPixel?.GRAY300,
                              fontWeight: '500',
                              fontSize: 12,
                            }}>
                            Note:{' '}
                          </Text>
                          }
                          <Text
                            style={{
                              color: Colors?.FoldPixel?.GRAY300,
                              fontWeight: '400',
                              fontSize: 12,
                            }}
                          >
                            {noteToDisplay}
                          </Text>
                        </Stack>
                        }
                    </View>
                  }
                </Stack>
              </Stack>
            );
          }}
        />
        <Column
          title={
            <Text
              size={'xsNormal'}
              style={{
                color: Colors?.FoldPixel?.GRAY250
              }}
            >
              Status
            </Text>
          }
          dataIndex="type"
          key="type"
          width={'30%'}
          render={(text, record: ITaskAlert) => {
            const status = taskStatusMlov?.find(
              (status) => status.id === record.statusId
            )
            const renderStatus = status?.code === TASK_STATUS.COMPLETED
              ? 'Resolved'
              : status?.code === TASK_STATUS.OPEN
                ? 'Pending'
                : status?.value;
            return (
              <Stack direction={'row'} style={{paddingRight: 4}}>
                {loadingTaskAlertTypes ? (
                  <Skeleton.Text
                    alignContent={'center'}
                    width={'auto'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    lines={1}
                    marginTop={1}
                  ></Skeleton.Text>

                ) : (
                  <Pressable
                    cursor={'default'}
                    style={{
                      // backgroundColor: onHoverType?.[record.id]
                      //   ? Colors.FoldPixel.PRIMARY100
                      //   : Colors.FoldPixel?.GRAY50,
                      maxWidth: '80%',
                      height: 'fit-content',
                      // borderRadius: 4,
                      // ...(onHoverType?.[record.id] && {
                      //   borderWidth: 1,
                      //   borderColor: Colors.FoldPixel.GRAY50,
                      // }),
                    }}
                    // {...(isSideCarContext ?
                    //   {
                    //     onHoverIn: () => setOnHoverType({[record.id]: true}),
                    //     onHoverOut: () => setOnHoverType({})
                    //   } :
                    //   {}
                    // )}
                  >
                       <Text
                         size={'smRegular'}
                         style={{
                           color:
                             status?.code === TASK_STATUS.MISSED
                               ? Colors.FoldPixel.STATUS_ERROR
                               : Colors.FoldPixel.GRAY300,
                           fontWeight: '400',
                           fontSize: 12,
                           paddingHorizontal: 4,
                           paddingVertical: 2,
                         }}
                       >
                         {renderStatus}
                       </Text>
                  </Pressable>
                )}
                {/* <View style={{flex: 1}} /> */}

              </Stack>
            );
          }}
        />
        <Column
          title={
            <Text
              size={'xsNormal'}
              style={{
                color: Colors?.FoldPixel?.GRAY250
              }}
            >
              Action
            </Text>
          }
            dataIndex ='action'
            key='action'
            align={'right'}
            width={showManualAddGaps ? '10%' : '20%'}
            render= {(action:any, record:any) => {
              const taskLocation = record?.taskLocations?.[0]?.locationId;
              const activePatientProfile = getActivePatientProfile(props?.contactData?.contactProfiles || []);
              const isAlertOfActiveLocation = checkLocationAccess({
                locationUuids: [taskLocation || ""],
                allowedLocationUuids: [activePatientProfile?.accountLocationUuid || ""],
                isMultiTenancyEnabled,
              });
              const haveAccessToActivePatientProfile = checkLocationAccess({
                locationUuids: [activePatientProfile?.accountLocationUuid || ""],
                allowedLocationUuids: allowedUserAccountLocationUuids,
                isMultiTenancyEnabled,
              });
              const haveAccess = isMultiTenancyEnabled ? haveAccessToActivePatientProfile && isAlertOfActiveLocation : true;
              return (
                <Stack
                  direction={'row'}
                  style={{alignItems: 'center', justifyContent: 'flex-end'}}
                >
                  <Popover
                    overlayClassName="alerts-action-popover"
                    key={record?.id}
                    overlayInnerStyle={{padding: -8, borderRadius: 16}}
                    overlayStyle={{padding: 0}}
                    overlay={{padding: 0}}
                    style={{padding: 0, borderRadius: 16}}
                    placement={'left'}
                    trigger="click"
                    open={!!isOpenActionsPopover?.[record?.id]}
                    onOpenChange={(visible: boolean) =>
                      handleVisibleChange(visible, record?.id)
                    }
                    content={() => popoverContent(record)}
                  >
                    <Pressable
                      cursor={loadingTaskAlertTypes || loaders.statusChangeloading || !haveAccess ? 'not-allowed' : 'pointer'}
                      disabled={loadingTaskAlertTypes || loaders.statusChangeloading || !haveAccess}
                      onPress={() => {
                        handleVisibleChange(
                          !isOpenActionsPopover?.[record?.id],
                          record?.id
                        );
                      }}
                      _hover={{
                        backgroundColor: Colors.FoldPixel.GRAY50,
                      }}
                    >
                      {
                        record?.id === actionsState.selectedTaskAlertForAction?.id && loaders.statusChangeloading ? <Spinner />
                          :
                          <ThreeDotsSvg
                            customStrokeColor={ !haveAccess ? Colors?.FoldPixel?.GRAY150 :
                              loadingTaskAlertTypes
                                ? Colors?.FoldPixel?.GRAY150
                                : undefined
                            }
                          />
                      }
                    </Pressable>
                  </Popover>
                </Stack>
              )
            }}
        />
      </Table>
    );
  };
  const renderCareGapTable = (
    alerts: any,
    loading: boolean,
    totalCount: number,
    displayCategoryCode: string
  ) => {
    return (
      <Table
        className="alertsTable care-gap"
        dataSource={alerts}
        rowKey="id"
        pagination={false}
        loading={{
          spinning: loading,
          indicator: (
            <View style={{marginTop: 10}}>
              <Spin size={'default'} />
            </View>
          ),
        }}
        onChange={(pagination: any, filters: any, sorter: any) =>
          handleTableChange(pagination, filters, sorter, displayCategoryCode)
        }
        locale={{
          emptyText: intl.formatMessage({id: 'noAlerts'}),
        }}
      >
        <Column
          title={
            <Text
              size={'xsNormal'}
              style={{
                color: Colors?.FoldPixel?.GRAY250
              }}
            >
              Measure
            </Text>
          }
          dataIndex="title"
          key="title"
          width={showManualAddGaps ? '60%' : '50%'}
          render={(text, record: ITaskAlert) => {
            record.description = record.description?.replace(/<[^>]*>/g, '');
            const displayCategoryCode = getMlovCodeFromId(taskAlertDisplayCategoryMlovs, record.taskDisplayCategoryId);
            const needsTruncationForDescription = needsTruncation(
              record?.description
            );
            const evalutionDate = record.startDateTime ? getDateStrFromFormat(record.startDateTime,DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT) : ''
            
            const activePatientProfile = getActivePatientProfile(props?.contactData?.contactProfiles || []);
            const taskLocation = record?.taskLocations?.[0]?.locationId;
            const isAlertOfActiveLocation = checkLocationAccess({
              locationUuids: [taskLocation || ""],
              allowedLocationUuids: [activePatientProfile?.accountLocationUuid || ""],
              isMultiTenancyEnabled,
            });
            const haveAccessToActivePatientProfile = checkLocationAccess({
              locationUuids: [activePatientProfile?.accountLocationUuid || ""],
              allowedLocationUuids: allowedUserAccountLocationUuids,
              isMultiTenancyEnabled,
            });
            const haveAccess = isMultiTenancyEnabled ? haveAccessToActivePatientProfile && isAlertOfActiveLocation : true;
            return (
              <Pressable
                testID={'careCapDetailButton'}
                onPress={() => haveAccess && handleCareGapDetail(record)}>
              <Stack
                direction={'row'}
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Stack direction={'column'} space={2} style={{flex: 1}}>
                  <Text
                    size={'smRegular'}
                    style={{
                      color: !haveAccess ? Colors?.FoldPixel?.GRAY300 :
                        displayCategoryCode ===
                        TASK_ALERT_CATEGORY_CODES.RESOLVED
                          ? Colors.Custom.AlertsDescriptionColor
                          : Colors?.FoldPixel?.GRAY400,
                      ...(displayCategoryCode ===
                        TASK_ALERT_CATEGORY_CODES.RESOLVED && {
                        textDecorationLine: 'line-through',
                      }),
                    }}
                  >
                    {!haveAccess ? (
                        <LockTooltipIcon name="Alert" />
                      ) : null}
                    {record.title}
                  </Text>
                  {/* {!!record?.description && (
                    <Text
                      size={'xsNormal'}
                      style={{
                        color: Colors?.Custom?.AlertsDescriptionColor
                      }}
                    >
                      {needsTruncationForDescription &&
                        !expandedDescriptions[record.id]
                        ? `${record.description.slice(0, 100)}...`
                        : record.description}
                      {
                        needsTruncationForDescription && ' '
                        // added empty space to show before 'show more' and 'show less'
                      }
                      {needsTruncationForDescription && (
                        <Text
                          style={{
                            color: Colors.FoldPixel.PRIMARY300,
                            textDecorationLine: 'underline',
                          }}
                          selectable={true}
                          onPress={() => toggleDescription(record.id)}
                        >
                          {expandedDescriptions?.[record.id]
                            ? 'Show less'
                            : 'Show more'}
                        </Text>
                      )}
                    </Text>
                  )} */}
                  {evalutionDate && (
                    <View style={{marginVertical: 1}}>
                      <Text
                        style={{
                          color: Colors.FoldPixel.GRAY300,
                          fontSize: 12,
                          fontWeight: '400'
                        }}
                      >
                        Evaluation Date: {evalutionDate}
                      </Text>
                    </View>
                  )}
                  {
                    record?.attachments?.length > 0 && (
                      <Pressable onPress={() => setAttachmentState((prev) => ({
                        ...prev,
                        showAttachmentDrawer: true,
                        attachments: record?.attachments,
                      }))}

                      style={{
                        maxWidth: 'fit-content',
                      }}
                      >
                        <Stack direction={'row'} space={8} style={{alignItems: 'center', maxWidth: 'fit-content'}}>
                          <Text
                            style={{
                              color: Colors?.FoldPixel?.PRIMARY300,
                              fontWeight: '400',
                              fontSize: 12,
                            }}
                          >
                            {'View Evidence'}
                            <ClosedCollapseSvg customStrokeColor={Colors?.FoldPixel?.PRIMARY300} />
                          </Text>
                        </Stack>
                      </Pressable>
                    )
                  }
                </Stack>
              </Stack>
            </Pressable>
            );
          }}
        />
        <Column
          title={
            <Text
              size={'xsNormal'}
              style={{
                color: Colors?.FoldPixel?.GRAY250
              }}
            >
              Status
            </Text>
          }
          dataIndex="type"
          key="type"
          width={'30%'}
          render={(text, record: ITaskAlert) => {
            const status = taskStatusMlov?.find(
              (status) => status.id === record.statusId
            )
            const renderStatus = status?.code === TASK_STATUS.COMPLETED
              ? 'Resolved'
                : status?.value || '';
            return (
              <Stack direction={'row'} style={{paddingRight: 4}}>
                {loadingTaskAlertTypes ? (
                  <Skeleton.Text
                    alignContent={'center'}
                    width={'auto'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    lines={1}
                    marginTop={1}
                  ></Skeleton.Text>
                ) : (
                  <Pressable
                    cursor={'default'}
                    style={{
                      // backgroundColor: onHoverType?.[record.id]
                      //   ? Colors.FoldPixel.PRIMARY100
                      //   : Colors.FoldPixel?.GRAY50,
                      // maxWidth: '80%',
                      // borderRadius: 4,
                      // ...(onHoverType?.[record.id] && {
                      //   borderWidth: 1,
                      //   borderColor: Colors.FoldPixel.GRAY50,
                      // }),
                    }}
                    // {...(isSideCarContext ?
                    //   {
                    //     onHoverIn: () => setOnHoverType({[record.id]: true}),
                    //     onHoverOut: () => setOnHoverType({})
                    //   } :
                    //   {}
                    // )}
                  >
                    {
                      <Text
                        size={'smRegular'}
                        style={{
                          color:
                            status?.code === TASK_STATUS.MISSED
                              ? Colors.FoldPixel.STATUS_ERROR
                              : Colors.FoldPixel.GRAY300,
                          fontWeight: '400',
                          fontSize: 12,
                          paddingHorizontal: 4,
                          paddingVertical: 2,
                        }}
                      >
                        {renderStatus}
                      </Text>
                    }
                  </Pressable>
                )}
              </Stack>
            );
          }}
        />
        <Column
          title={
            <Text
              size={'xsNormal'}
              style={{
                color: Colors?.FoldPixel?.GRAY250
              }}
            >
              Action
            </Text>}
          dataIndex="action"
          key="action"
          align={'right'}
          width= {showManualAddGaps ? '10%' : '20%'}
          render={(text, record: ITaskAlert) => {
            const taskLocation = record?.taskLocations?.[0]?.locationId;
            const activePatientProfile = getActivePatientProfile(props?.contactData?.contactProfiles || []);
            const isAlertOfActiveLocation = checkLocationAccess({
              locationUuids: [taskLocation || ""],
              allowedLocationUuids: [activePatientProfile?.accountLocationUuid || ""],
              isMultiTenancyEnabled,
            });
            const haveAccessToActivePatientProfile = checkLocationAccess({
              locationUuids: [activePatientProfile?.accountLocationUuid || ""],
              allowedLocationUuids: allowedUserAccountLocationUuids,
              isMultiTenancyEnabled,
            });
            const haveAccess = isMultiTenancyEnabled ? haveAccessToActivePatientProfile && isAlertOfActiveLocation : true;
            return (
              <Stack direction={'row'}  style={{paddingRight: 4, alignItems: 'center'}}>
                <View style={{flex: 1}} />
                <Stack
                  direction={'row'}
                  style={{alignItems: 'center', justifyContent: 'flex-end'}}
                >
                  <Popover
                    overlayClassName="alerts-action-popover"
                    key={record?.id}
                    overlayInnerStyle={{padding: -8, borderRadius: 16}}
                    overlayStyle={{padding: 0}}
                    overlay={{padding: 0}}
                    style={{padding: 0, borderRadius: 16}}
                    placement={'left'}
                    trigger="click"
                    open={!!isOpenActionsPopover?.[record?.id]}
                    onOpenChange={(visible: boolean) =>
                      handleVisibleChange(visible, record?.id)
                    }
                    content={() => popoverContent(record)}
                  >
                    <Pressable
                      cursor={
                        loadingTaskAlertTypes || loaders.statusChangeloading || !haveAccess
                          ? 'not-allowed'
                          : 'pointer'
                      }
                      disabled={
                        loadingTaskAlertTypes || loaders.statusChangeloading || !haveAccess
                      }
                      onPress={() => {
                        handleVisibleChange(
                          !isOpenActionsPopover?.[record?.id],
                          record?.id
                        );
                      }}
                      _hover={{
                        backgroundColor: Colors.FoldPixel.GRAY50,
                      }}
                    >
                      {record?.id ===
                        actionsState.selectedTaskAlertForAction?.id &&
                      loaders.statusChangeloading ? (
                        <Spinner />
                      ) : (
                        <ThreeDotsSvg
                          customStrokeColor={ !haveAccess ? Colors?.FoldPixel?.GRAY150 :
                            loadingTaskAlertTypes
                              ? Colors?.FoldPixel?.GRAY150
                              : undefined
                          }
                        />
                      )}
                    </Pressable>
                  </Popover>
                </Stack>
              </Stack>
            );
          }}
        />
      </Table>
    );
  };

  const getActionIcon = (action: typeof ALERTS_ACTIONS_MENU[0]) => {
    switch (action.code) {
      case ALERT_ACTION_CODES.SUGGESTED_ACTION:
        return <SuggestedActionsSvg />;
      case ALERT_ACTION_CODES.CREATE_TASK:
        return <AddTaskSvg />;
      case ALERT_ACTION_CODES.SCHEDULE_APPOINTMENT:
        return <ScheduleAppointmentSvg />;
      case ALERT_ACTION_CODES.DISMISS:
        return <DismissIconSvg />;
      case ALERT_ACTION_CODES.RESOLVE:
        return <ResolvedAlertsSvg />;
      case ALERT_ACTION_CODES.SNOOZE:
        return <SnoozedAlertsSvg />;
      case ALERT_ACTION_CODES.SEND_PATIENT_EDUCATION:
        return <PatientEducationSvg />;
      case ALERT_ACTION_CODES.ADD_NOTE:
        return <NoteLinkIcon />;
      case ALERT_ACTION_CODES.SEND_ASSESSMENT:
        return <AssessmentSvg />;
      case ALERT_ACTION_CODES.ADD_TO_NOTE_IN_EHR:
        return <NoteLinkIcon />;
      case ALERT_ACTION_CODES.CREATE_ORDER:
        return <MedicationPilll />;
      case ALERT_ACTION_CODES.MOVE_TO_PENDING:
        return <PendingAlertsSvg />;
      case ALERT_ACTION_CODES.ADD_IMAGING_ORDER:
        return <NoteLinkIcon />;
      case ALERT_ACTION_CODES.REVIEW_DIAGNOSIS_GAP:
        return <EyeOpenSvg width={16} height={16} />;
      case ALERT_ACTION_CODES.RUN_AUTOMATION:
        return <ZapSvg size={16}  />;
    }
    return <></>;
  };

  const onActionClick = async (actionCode: string, record: ITaskAlert) => {
    let displayCategoryId = '';
    setActionsState((prev) => ({
      ...prev,
      selectedTaskAlertForAction: record as ITaskAlert,
    }));
    if(actionCode !== ALERT_ACTION_CODES.SNOOZE && actionCode !== ALERT_ACTION_CODES.ADD_NOTE) {
      setIsOpenActionsPopover({});
    }
    switch (actionCode) {
      case ALERT_ACTION_CODES.SNOOZE:
        setActionsState((prev) => ({
          ...prev,
          showSnoozeView: true,
        }));
        break;
      case ALERT_ACTION_CODES.DISMISS:
      case ALERT_ACTION_CODES.RESOLVE:
        displayCategoryId = getMlovIdFromCode(
          taskAlertDisplayCategoryMlovs,
          ALERT_CATEGORY_CODES_MAPPED[actionCode]
        );
        setActionsState((prev) => ({
          ...prev,
          showDismissAlertReasonView: actionCode === ALERT_ACTION_CODES.DISMISS,
          showResolveAlertReasonView: actionCode === ALERT_ACTION_CODES.RESOLVE,
        }));
        await getAlertActionReasons({
          variables: {
            params: {
              taskAlertDisplayPreferenceIds: [displayCategoryId],
              taskTypeIds: [record.taskTypeId],
            },
          },
        });
        break;
      case ALERT_ACTION_CODES.CREATE_TASK:
        setActionsState((prev) => ({
          ...prev,
          showAddTaskDrawer: true,
        }));
        break;
      case ALERT_ACTION_CODES.RUN_AUTOMATION:
        setActionsState((prev) => ({
          ...prev,
          showRunAutomationDrawer: true,
        }));
        break;
      case ALERT_ACTION_CODES.SCHEDULE_APPOINTMENT:
        setActionsState((prev) => ({
          ...prev,
          showAppointmentDrawer: true,
        }));
        break;
      case ALERT_ACTION_CODES.SEND_ASSESSMENT:
        setActionsState((prev) => ({
          ...prev,
          showContactSendForm: true,
        }));
        break;
      case ALERT_ACTION_CODES.SEND_PATIENT_EDUCATION:
        setActionsState((prev) => ({
          ...prev,
          showEducationalContentDrawer: true,
        }));
        break;
      case ALERT_ACTION_CODES.ADD_NOTE:
        setComponentState((prev) => ({
          ...prev,
          todayInProgressNote: undefined,
        }));
        defaultNoteCreationHandler(record.id);
        break;
      case ALERT_ACTION_CODES.ADD_TO_NOTE_IN_EHR:
        addToNoteInEhrHandler(record?.description);
        break;
      case ALERT_ACTION_CODES.ADD_IMAGING_ORDER:
          addImagingOrderInEhrHandler(record);
        break;
      case ALERT_ACTION_CODES.CREATE_ORDER:
        const medicationName = getMedicationName(record)
        createOrder({name: medicationName})
        break;
      case ALERT_ACTION_CODES.REVIEW_DIAGNOSIS_GAP:
        handleDiagnosisGapDetail(record as unknown as IDiagnosisGap);
        break;
      case ALERT_ACTION_CODES.MOVE_TO_PENDING:
        onSaveOfAlertActionReason(undefined, undefined, TASK_ALERT_CATEGORY_CODES.PENDING, undefined, record)
        break;
    }
  };

  const onOpenNoteDetails = (data: any) => {
    if (!data?.noteData) {
      return;
    }
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: data?.noteData ? [{resource: data?.noteData}] : [],
      ehrConfig,
      loginUserId: userUuid,
      contextData: mlovData,
      accountUserList: accountUserList || [],
      elationFormData: elationFormData,
      additionalFlags:{
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
        isMultiTenancyEnabled: isMultiTenancyEnabled,
      }
    };
    const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
    setActionsState((prev) => ({
      ...prev,
      showUpdateNotesDrawer: true,
      loadingActionCode: undefined,
      loadingAlertId: undefined
    }));
    setComponentState((prev) => ({
      ...prev,
      todayInProgressNote: patientNotes?.[0]
    }));
  }

  const enrichNotesWithLocationGroupIds = async (notes: NoteApiResponse, patientIds: string[]) => {
    if (!patientIds || patientIds.length === 0) {
      return notes;
    }
  
    const locationResponse = await getLocationGroupIds({
      variables: { patientIds },
    });
  
    const resourceLocationGroupIdsMapWithPatientId: { [key: string]: string[] } = {};
    locationResponse?.data?.contactPatients?.forEach((patient: any) => {
      const patientId = patient?.patientId;
      const locationGroupIds = patient.contact?.contactPracticeLocations?.map((location: any) => location.accountLocation.locationGroup.id) || [];
      resourceLocationGroupIdsMapWithPatientId[patientId] = locationGroupIds;
    });
  
    const enrichedNotes = notes.entry.map(note => {
      const patientReference = note?.resource?.subject?.reference;
      if (patientReference) {
        const patientId = patientReference.split('/')[1];
        return {
          ...note,
          locationGroupId: resourceLocationGroupIdsMapWithPatientId[patientId] || [],
        };
      }
      return note;
    });
  
    return {
      ...notes,
      entry: enrichedNotes,
    };
  };

  const enrichPatientNotesWithProfileStatus = async (notes: NoteApiResponse) => { 
    if (isMultiTenancyEnabled) {
      const patientIds: string[] = [];
      const profileIds = getNotesContactProfileIds(notes);
      const hasMissingProfileId = notes.entry.some(note => {
        const profileIdExtension = note.resource.extension?.find(ext => ext.url === EXTENSION_URLS.profileId);
        return !profileIdExtension || !profileIdExtension.valueString;
      });

      if (hasMissingProfileId) {
        notes.entry.forEach(note => {
          const profileIdExtension = note.resource.extension?.find(ext => ext.url === EXTENSION_URLS.profileId);
          if (!profileIdExtension) {
            const patientReference = note?.resource?.subject?.reference;
            if (patientReference) {
              const patientId = patientReference.split('/')[1]; 
              patientIds.push(patientId);
            }
          }
        });
      }
      const  profileStatusData  = await fetchContactProfileStatus({ profileIds: profileIds });
      const noteWithLocationGroupIds = await enrichNotesWithLocationGroupIds(notes,patientIds)
      const note = await enrichNotesWithProfileIds(noteWithLocationGroupIds)

      return addProfileStatusToNotes(note, profileStatusData, locationGroupIdListByPatientContext, allowedLocationIds, activeContactProfileLocationIds );
    }
    return notes;
  };

  const defaultNoteCreationHandler = async (recordId: string) => {
    try {
      setActionsState((prev) => ({
        ...prev,
        loadingActionCode: ALERT_ACTION_CODES.ADD_NOTE,
        loadingAlertId: recordId
      }));
      const dateRange = getCareDashboardDateRange();
      let notesData;
      if(isMultiTenancyEnabled){
        const documentResponse = await getDocumentsReferenceWithFilters ({
          contactUuid: props?.formattedContactData?.contactData?.id,
          isPatient360: true,
          accountLocationIds: allowedLocationIdP360Notes,
          filters: {createdOnStartDate: dateRange.startDate},
          encounterId: '',
          pagination:undefined,
          foldSource: foldVisitNoteWithEncountersEnabled,
          locationId: patientLocationId,
          isMultitenancyEnabled: isMultiTenancyEnabled,
        }
      )
      const enrichedNotes = enrichNotesWithProfileIds(documentResponse?.data);
      const enrichedNotesData = await enrichPatientNotesWithProfileStatus(enrichedNotes);
      notesData = enrichedNotesData ;
     }
     else {
      const documentResponse = await getDocumentsReferenceWithFilters({
        patientId: props?.formattedContactData?.contactData?.patient?.patientId,
        filters:{createdOnStartDate: dateRange.startDate},
        encounterId: '',
        pagination: undefined,
        foldSource: foldVisitNoteWithEncountersEnabled,
      })
      notesData = documentResponse?.data;
    }
      const formatResponseParam: INotesFormattedDataProps = {
        noteResponse: notesData?.entry || [],
        ehrConfig,
        loginUserId: userUuid,
        contextData: mlovData,
        accountUserList: accountUserList || [],
        elationFormData: elationFormData,
        additionalFlags:{
          foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
          isMultiTenancyEnabled: isMultiTenancyEnabled
        }
      };
      const patientNotes = getFormattedNoteForCareTimeline(formatResponseParam);
      const todayPendingNote = (patientNotes || []).find(note => {
        return note?.status === DocStatus.PRELIMINARY;
      });
      if (todayPendingNote?.resourceId) {
        setActionsState((prev) => ({
          ...prev,
          showInProgressAddNotesPopup: true,
          loadingActionCode: undefined,
          loadingAlertId: undefined
        }));
        setComponentState((prev) => ({
          ...prev,
          todayInProgressNote: todayPendingNote
        }));
      } else {
        setActionsState((prev) => ({
          ...prev,
          showAddNotesDrawer: true,
          loadingActionCode: undefined,
          loadingAlertId: undefined
        }));
      }
      setIsOpenActionsPopover({});
    } catch(err) {
      setActionsState((prev) => ({
        ...prev,
        showAddNotesDrawer: true,
        loadingActionCode: undefined,
        loadingAlertId: undefined
      }));
      setIsOpenActionsPopover({});
    }
  };

  const addToNoteInEhrHandler = (description: string) => {
    broadcastEventToSidecarActionsScreen(
      '',
      SIDECAR_EVENT_CODES.ADD_TO_NOTE_IN_EHR,
      {internalNote: description}
    );
  }

  const addImagingOrderInEhrHandler = (record: ITaskAlert) => {
    if (ehrConfig.isAthena) {
       //hardcore data
       broadcastEventToSidecarActionsScreen(
        '',
        SIDECAR_EVENT_CODES.ADD_IMAGING_ORDER,
        {
          imagingName: ImagingOrderDataAthena.imagingName,
          recordId: record?.id || '',
        }
      );
    } else {
      //hardcore data
      broadcastEventToSidecarActionsScreen(
        '',
        SIDECAR_EVENT_CODES.ADD_IMAGING_ORDER,
        {
          imagingName: 'Mammogram',
          clinicalReason: 'Preventative care - Screening for breast cancer',
          recordId: record?.id || '',
        }
      );
    }
  };

  const popoverContent = (record: any, action?: string, actionCode?: string) => {
    const displayCategoryCode = getMlovCodeFromId(taskAlertDisplayCategoryMlovs, record.taskDisplayCategoryId);
    const taskTypeActionIds = taskAlertTypes?.getTaskTypes?.taskTypes
      ?.find(alertType => alertType.id === record.taskTypeId)
      ?.taskTypeActionConfigs
      ?.map(config => config?.taskTypeActionId);
    const alertTaskTypeActionIds = record?.taskActionConfigs
      ?.map((config: any) => config?.taskTypeActionId)
      ?.filter((id: string): id is string => id !== undefined) || [];

    const actionIdsToShowForAlerts = alertTaskTypeActionIds.length > 0
      ? alertTaskTypeActionIds
      : taskTypeActionIds || [];

    const filteredActionsMenu = getFilteredActionsMenuForAlerts(
      displayCategoryCode,
      taskTypeActionsMlovList,
      actionIdsToShowForAlerts,
      ehrConfig.isAthena,
      actionCode === ALERT_ACTION_CODES.REVIEW_DIAGNOSIS_GAP,
      isSidecarPatientContext
    )?.map(menuAction => {
      if (menuAction.categoryCode === CATEGORY_CODES_OF_ALERT_ACTIONS.DIAGNOSIS_GAP_ACTIONS) {
        return {
          ...menuAction,
          value: action || menuAction?.value
        };
      }
      return menuAction;
    });

    const renderActions = () => (
      <Stack direction="column" style={{ padding: 0, borderRadius: 16 }}>
        {filteredActionsMenu.map((action, index: number) => {
          const actionIcon = getActionIcon(action);
          const isDismissAction = action.code === ALERT_ACTION_CODES.DISMISS;
          const isDiagnosisGapAction = action.categoryCode === CATEGORY_CODES_OF_ALERT_ACTIONS.DIAGNOSIS_GAP_ACTIONS;

          return (
            <React.Fragment key={index}>
              <Pressable
                style={{ padding: 4, borderRadius: 4 }}
                onPress={() => onActionClick(action.code, record)}
                _hover={{
                  backgroundColor: isDismissAction
                    ? Colors.Custom.Red100
                    : Colors.FoldPixel.GRAY50
                }}
              >
                <Stack direction="row" style={{ padding: 4, justifyContent: 'space-between' }} space={4}>
                  <Stack direction="row" style={{ alignItems: 'center' }} space={4}>
                    {actionIcon}
                    <Text
                      style={{
                        color: isDismissAction
                          ? Colors.FoldPixel.STATUS_ERROR
                          : Colors.FoldPixel.GRAY400,
                        fontSize: 14,
                        fontWeight: '400'
                      }}
                    >
                      {action.value}
                    </Text>
                  </Stack>
                  {actionsState.loadingAlertId === record.id && actionsState.loadingActionCode === action.code && <Spinner/> }
                </Stack>
              </Pressable>
              {(!!filteredActionsMenu[index + 1] && filteredActionsMenu[index + 1]?.categoryCode !== action.categoryCode) || isDiagnosisGapAction ? (
                <Divider style={{ marginTop: 8, marginBottom: 8 }} />
              ) : null}
            </React.Fragment>
          );
        })}
      </Stack>
    );

    return actionsState?.showDatePicker ? (
      <View>
        <Pressable
          style={{
            padding: 4,
            borderRadius: 4,
          }}
          onPress={() => setActionsState(prev => ({
            ...prev,
            showDatePicker: false,
          }))}
          _hover={{
            backgroundColor: Colors.FoldPixel.GRAY50
          }}
        >
          <Stack
            direction={'row'}
            space={2}
            style={{ padding: 4, alignItems: 'center' }}
          >
            <BackArrowSvg />
            <Text
              style={{
                color: Colors.FoldPixel.GRAY400,
                fontSize: 14,
                fontWeight: '400',
                lineHeight: 16.8,
                letterSpacing: 0.5,
              }}
            >
              Back to options
            </Text>
          </Stack>
        </Pressable>
        <Calendar
          value={getMomentObjectWithDateStringAndFormat(
            getDateStrFromFormat(selectedDateForSnooze, DATE_FORMATS.API_DATE_FORMAT),
            DATE_FORMATS.API_DATE_FORMAT
          )}
          style={{ maxWidth: 'min-content' }}
          fullscreen={false}
          disabledDate={(current) => isBeforeOrSameDate(current.toDate(), new Date())}
          onChange={(momentDate) => {
            setSelectedDateForSnooze(momentDate.toDate()?.toISOString())
          }}
        />
        <Pressable
          onPress={() => {
            const dateObject = getDateObject(selectedDateForSnooze);
            handleSelectSnoozeOption(getPreviousCalendarDate(CalendarView.day, dateObject)?.toISOString())
          }}
          disabled={!selectedDateForSnooze}
          style={{ backgroundColor: Colors?.FoldPixel?.PRIMARY300, borderRadius: 4, alignItems: 'center', paddingVertical: 7.5 }}>
          <Text style={{ color: Colors?.Custom?.BackgroundColor, fontWeight: '500', fontSize: 14 }}>
            {intl.formatMessage({ id: 'apply' })}
          </Text>
        </Pressable>
      </View>
    ) : actionsState?.showSnoozeView ? (
      <SnoozeView
        onSelectSnoozeOption={handleSelectSnoozeOption}
        onDatePickerOpen={() => setActionsState(prev => ({
          ...prev,
          showDatePicker: true
        }))}
        onBackClick={() => handleVisibleChange(true, actionsState?.selectedTaskAlertForAction?.id || '')}
      />
    ) : (
      renderActions()
    );
  };

  const getAlertsHeaderIcon = (displayCategoryCode: string) => {
    switch (displayCategoryCode) {
      case TASK_ALERT_CATEGORY_CODES.PENDING:
        return <PendingAlertsSvg />;
      case TASK_ALERT_CATEGORY_CODES.SNOOZED:
        return <SnoozedAlertsSvg />;
      case TASK_ALERT_CATEGORY_CODES.RESOLVED:
        return <ResolvedAlertsSvg />;
      case TASK_ALERT_CATEGORY_CODES.DISMISSED:
        return <DismissedAlertsSvg />;
      default:
        return <></>;
    }
  };

  const getHeaderForCollapse = (
    textToDisplay: string,
    alertCount: number,
    isActive: boolean,
    displayCategoryCode: string
  ) => {
    const totalCountForDisplayCategory = componentState[
      `${displayCategoryCode.toLowerCase()}TotalCount` as keyof IAlertsViewComponentState
    ] as number;
    const pageFordisplayCategory = componentState[
      `${displayCategoryCode.toLowerCase()}AlertsPage` as keyof IAlertsViewComponentState
    ] as number;
    return (
      <Stack
        direction={'row'}
        style={{
          alignItems: 'center',
          backgroundColor: Colors?.Custom?.BackgroundColor,
        }}
        space={8}
      >
        {getAlertsHeaderIcon(displayCategoryCode)}
        <Text
          style={{
            fontWeight: '500',
            fontSize: 14,
            color: Colors.FoldPixel.GRAY300,
            lineHeight: 14.4,
            letterSpacing: 0.5,
            fontStyle: 'normal',
            borderRadius: 4,
          }}
        >
          {textToDisplay}
        </Text>
        {isActive ? <OpenedCollapseSvg /> : <ClosedCollapseSvg />}
        {displayCategoryCode === TASK_ALERT_CATEGORY_CODES.PENDING && (
          getAlertCountView(
            componentState[`${displayCategoryCode.toLowerCase()}CountLoading` as keyof IAlertsViewComponentState],
            alertCount
          )
        )}
        <View style={{flex: 1}} />
        {isActive && totalCountForDisplayCategory && (
          <SidePagination
            page={pageFordisplayCategory}
            pageSize={componentState.pageSize}
            totalCount={totalCountForDisplayCategory}
            handleChange={(newPage: number, pageSize: number) =>
              handlePaginationChange(displayCategoryCode, newPage, pageSize)
            }
          />
        )}
      </Stack>
    );
  };

  const onStatusChangeCompleted = (displayCategoryStatusCode?: string) => {
    let toastToDisplay = actionsState?.showDismissAlertReasonView
      ? 'alertDismissed'
      : 'alertResolved';
    if (displayCategoryStatusCode === TASK_ALERT_CATEGORY_CODES.SNOOZED) {
      toastToDisplay = 'alertSnoozed'
    }
    if (displayCategoryStatusCode === TASK_ALERT_CATEGORY_CODES.PENDING) {
      toastToDisplay = 'alertMovedToPending'
    }
    setActionsState((prev) => ({
      ...prev,
      showDismissAlertReasonView: false,
      showResolveAlertReasonView: false,
      selectedTaskAlertForAction: undefined,
    }));
    notification.success({
      message: intl.formatMessage({id: `${toastToDisplay}`}),
      duration: 3.0,
      placement: 'topRight',
    });
    fetchAlerts(componentState.activePanels, undefined, true);
  };

  const onSaveOfAlertActionReason = (
    selectedReason?: IAlertActionReasons,
    note?: string,
    displayStatusCode?: string,
    snoozeValidTill?: string,
    selectedTaskAlertForAction?: ITaskAlert
  ) => {
    let displayCategoryStatusCode = '';
    if (actionsState.showDismissAlertReasonView) {
      displayCategoryStatusCode = TASK_ALERT_CATEGORY_CODES.DISMISSED;
    } else if (actionsState.showResolveAlertReasonView) {
      displayCategoryStatusCode = TASK_ALERT_CATEGORY_CODES.RESOLVED;
    }
    if(!!displayStatusCode) {
      displayCategoryStatusCode = displayStatusCode;
    }
    const currentDisplayPreferenceID =
      actionsState?.selectedTaskAlertForAction?.taskDisplayCategoryId;
    handleAlertStatusChange(
      !!selectedTaskAlertForAction ? selectedTaskAlertForAction : actionsState?.selectedTaskAlertForAction,
      displayCategoryStatusCode,
      onStatusChangeCompleted,
      selectedReason,
      note,
      snoozeValidTill,
    );
  };

  const handleSelectSnoozeOption = (dateTime: string) => {
    setSelectedDateForSnooze(getNextCalendarDate(CalendarView.day, new Date()).toISOString());
    const formattedDate = getFormattedDate(getEndOfDay(dateTime), DATE_FORMATS.API_DATE_FORMAT);
    setActionsState((prev) => ({
      ...prev,
      showDatePicker: false,
    }));
    setIsOpenActionsPopover({});
    onSaveOfAlertActionReason(undefined, undefined, TASK_ALERT_CATEGORY_CODES.SNOOZED, formattedDate)
  };

  const handleSendOfEducationContent = ({
    userInputFieldMap,
    conditionList,
  }: {
    userInputFieldMap: {out: {userInputFieldList: IUserInputField[]}};
    conditionList: {
      [key: string]: ICondition[];
    }[];
  }) => {
    const paramsForEducationContent = {
      contactId: props?.formattedContactData?.contactData?.uuid,
      articleId: null,
      communicationMode: '',
      taskPriorityId: '',
      taskPatientFacingTitle: '',
      emailTemplateId: null,
      emailTemplateCategoryCode: '',
      emailTemplateSubject: '',
      smsBody: '',
    };

    userInputFieldMap?.out?.userInputFieldList.forEach((field) => {
      switch (field.key) {
        case NODE_INPUT_KEYS.EDUCATION_CONTENT:
          paramsForEducationContent.articleId = field.value.id;
          break;
        case NODE_INPUT_KEYS.PRIORITY:
          paramsForEducationContent.taskPriorityId = getMlovIdFromCode(
            taskPriorityMlovs,
            field.value
          );
          break;
        case NODE_INPUT_KEYS.PATIENT_FACING_TITLE:
          paramsForEducationContent.taskPatientFacingTitle = field.value;
          break;
        case NODE_INPUT_KEYS.COMMUNICATION_VIA_NODE:
          paramsForEducationContent.communicationMode = field.value;
          break;
        case NODE_INPUT_KEYS.EMAIL_TEMPLATE:
          paramsForEducationContent.emailTemplateId =
            field?.value?.template?.id || '';
          paramsForEducationContent.emailTemplateCategoryCode =
            field?.value?.category?.code || '';
          paramsForEducationContent.emailTemplateSubject =
            field?.value?.subject || '';
          paramsForEducationContent.smsBody = field.value.sms?.body || '';
          break;
        default:
          break;
      }
    });
    sendPatientEducation({
      variables: {params: paramsForEducationContent},
    });
  };

  const getFilterCount = () => {
    const filterCount =
      componentState.filters.selectedPriorityCodes?.length +
      componentState.filters.selectedTaskAlertDisplayCodes?.length;
    return filterCount;
  };

  const onSearch = (value: string) => {
    setSearchText(value);
  }

  const renderSearchView = () => {
    return (
    <Stack
    direction={'row'}
    style={{
      alignItems: 'center',
      marginBottom: 12
    }}
  >
    <NativeBaseInput
      placeholder={'Search tasks by title or description'}
      onChangeText={debounce(onSearch, 500)}
      width={'100%'}
      height={'36px'}
      leftElement={
        <View style={{marginLeft: 8}}>
          <SearchIconSvg
            size={18}
            customStrokeColor={Colors.FoldPixel.GRAY200}
          />
        </View>
      }
      placeholderTextColor={Colors.FoldPixel.GRAY300}
      backgroundColor={Colors.Custom.White}
      rightElement={
        <Pressable
          style={{
            height: 30,
            width: 40,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => {
            setShowSearchBar(false);
            setSearchText('');
          }}
        >
          <CrossIconSvg
            customStrokeColor={Colors.FoldPixel.GRAY300}
            size={20}
          />
        </Pressable>
      }
    />
    </Stack>
  )
  }

  const renderFilterView = () => {
    if (showSearchBar) {
      return renderSearchView();
    }
    return (
    <Stack
      direction="row"
      style={{
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        ...(props?.isActionableDrawer ? {
          marginBottom: isSideCarContext ? 16 : 8,
          paddingVertical: props.filterStyle?.paddingHorizontal ? props.filterStyle?.paddingHorizontal : 8,
        } : {
          paddingVertical: 8,
          marginBottom: 12
        })
      }}
    >
  <View style={{
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: 16
  }}>
    <FilterWrapperComponent
      marginBottom={0}
      textWeight={400}
        onClose={() => {
          handleFilterChange();
        }}
        label={'Alert Type'}
        value={componentState?.filters?.formData?.selectedTaskAlertTypeList?.map(
          (item) => item.value
        )}
      contentMinHeight={0}
      contentStyle={{
        paddingVertical: 7.5,
        paddingHorizontal: 8,
        width: 'fit-content',
      }}
      maxWidth={'90%'}
      noJustify={true}
      filterTextStyle={{
        fontSize: 14,
        marginRight: 2,
        fontWeight: '500',
        color: Colors.FoldPixel.GRAY300,
        lineHeight: 14.4,
        letterSpacing: 0.5,
        fontStyle: 'normal',
        borderRadius: 4,
      }}
      filterValueTextStyle={{
        fontSize: 14,
        marginRight: 2,
        fontWeight: '500',
        color: Colors.FoldPixel.GRAY400,
        lineHeight: 14.4,
        letterSpacing: 0.5,
        fontStyle: 'normal',
        borderRadius: 4,
      }}
      {...(props?.isActionableDrawer ? {dropdownMarginLeft: 0} : {})}
      >
        {loadingTaskAlertTypes && <Spin />}
        {!loadingTaskAlertTypes &&
          (componentState.filtersInitialData.taskAlertTypeCategoriesList
            .length ? (
        <View>
              {componentState.filtersInitialData.taskAlertTypeCategoriesList.map(
                (mlov, index) => {
                  if ([TASK_TYPES.CARE_GAPS, TASK_TYPES.DIAGNOSIS_GAPS].includes(mlov.code)){
                    return <></>;
                  }
                  return (
                    <AntdCheckbox
                      key={mlov.id}
                      value={mlov.code}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        alignItems: 'center',
                      }}
                      checked={componentState.filters.selectedTaskAlertDisplayCodes?.includes(
                        mlov.code
                      )}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setComponentState((prev) => {
                          const selectedTaskAlertDisplayCodes =
                            prev.filters?.selectedTaskAlertDisplayCodes || [];
                          if (isChecked) {
                            if (!selectedTaskAlertDisplayCodes.includes(mlov.code)) {
                              selectedTaskAlertDisplayCodes.push(mlov.code);
                            }
                          } else {
                            if (selectedTaskAlertDisplayCodes.includes(mlov.code)) {
                              selectedTaskAlertDisplayCodes.splice(
                                selectedTaskAlertDisplayCodes.indexOf(mlov.code),
                                1
                              );
                            }
                          }
                          const selectedTaskAlertTypeList: ITaskAlertsType[] = [];
                          selectedTaskAlertDisplayCodes?.length &&
                            componentState.filtersInitialData?.taskAlertTypeCategoriesList?.forEach?.(
                              (status) => {
                                if (
                                  selectedTaskAlertDisplayCodes.indexOf?.(
                                    status.code
                                  ) !== -1
                                ) {
                                  selectedTaskAlertTypeList.push(status);
                                }
                              }
                            );

                          return {
                            ...prev,
                            filters: {
                              ...prev.filters,
                              selectedTaskAlertDisplayCodes: [
                                ...selectedTaskAlertDisplayCodes,
                              ],
                              formData: {
                                ...prev.filters.formData,
                                selectedTaskAlertTypeList: [
                                  ...selectedTaskAlertTypeList,
                                ],
                              },
                            },
                          };
                        });
                      }}
                    >
                      <Text
                        style={{marginLeft: 8, color: Colors.FoldPixel.GRAY400}}
                      >
                        {mlov.value}
                      </Text>
                    </AntdCheckbox>
                  );
                }
              )}
            </View>
          ) : undefined)}
      </FilterWrapperComponent>
      <FilterWrapperComponent
        marginBottom={0}
        textWeight={400}
        contentMinHeight={0}
        onClose={() => {
          handleFilterChange();
        }}
        noJustify={true}
        label={'Severity'}
        value={
          getSeverityNamesFromPriorityCodes(componentState?.filters?.formData?.selectedPriorityList?.map(
            (item) => item.code
          ))}
        contentStyle={{
          paddingVertical: 7.5,
          paddingHorizontal: 8,
        }}
        filterTextStyle={{
          fontSize: 14,
          marginRight: 2,
          fontWeight: '500',
          color: Colors.FoldPixel.GRAY300,
          lineHeight: 14.4,
          letterSpacing: 0.5,
          fontStyle: 'normal',
          borderRadius: 4,
        }}
        filterValueTextStyle={{
          fontSize: 14,
          marginRight: 2,
          fontWeight: '500',
          color: Colors.FoldPixel.GRAY400,
          lineHeight: 14.4,
          letterSpacing: 0.5,
          fontStyle: 'normal',
          borderRadius: 4,
        }}
      >
        {componentState.filtersInitialData.priorityTypeList.length ? (
          <View>
            {componentState.filtersInitialData.priorityTypeList.map((mlov, index) => {
              return (
                <AntdCheckbox
                  key={mlov.id}
                  value={mlov.code}
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    alignItems: 'center',
                    marginLeft: index === 0 ? 10 : undefined
                  }}
                  checked={componentState.filters.selectedPriorityCodes?.includes(mlov.code)}
                  onChange={(event) => {
                    const isChecked = event.target.checked;
                    setComponentState((prev) => {
                      const selectedPriorityCodes = prev.filters?.selectedPriorityCodes || [];

                      if (isChecked) {
                        if (!selectedPriorityCodes.includes(mlov.code)) {
                          selectedPriorityCodes.push(mlov.code);
                        }
                      } else {
                        if (selectedPriorityCodes.includes(mlov.code)) {
                          selectedPriorityCodes.splice(
                            selectedPriorityCodes.indexOf(mlov.code),
                            1
                          );
                        }
                      }

                      const selectedPriorityList: IMlov[] = [];
                      selectedPriorityCodes?.length &&
                        componentState.filtersInitialData?.priorityTypeList?.forEach?.((status) => {
                          if (selectedPriorityCodes.indexOf?.(status.code) !== -1) {
                            selectedPriorityList.push(status);
                          }
                        });

                      return {
                        ...prev,
                        filters: {
                          ...prev.filters,
                          selectedPriorityCodes: [...selectedPriorityCodes],
                          formData: {
                            ...prev.filters.formData,
                            selectedPriorityList: [...selectedPriorityList],
                          },
                        },
                      };
                    });
                  }}
                >
                  <Text style={{marginLeft: 8, color: Colors.FoldPixel.GRAY400}}>
                    {getSeverityNamesFromPriorityCodes(mlov.code)}
                  </Text>
                </AntdCheckbox>
              );
            })}
          </View>
        ) : undefined}
      </FilterWrapperComponent>
      </View>
      <Stack
        direction="row"
        style={{
          alignItems: 'center',
          marginLeft: 'auto'
        }}
      >
      {getFilterCount() > 0 && (
        <Pressable
          onPress={() => {
            setComponentState((prev) => ({
              ...prev,
              filters: initialFilters,
            }));
            handleFilterChange(true);
          }}
          style={{
            marginRight: 12,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ClearActionIcon />
          <Text style={{marginLeft: 6, color: Colors.Custom.mainPrimaryPurple}}>
            Clear All
          </Text>
        </Pressable>
      )}

      <Pressable
        onPress={() => {
          setShowSearchBar(true);
        }}
      >
        <SearchIconSvg
          customStrokeColor={Colors.FoldPixel.GRAY300}
          strokeWidth={1}
        />
      </Pressable>
    </Stack>
    </Stack>)
  }

  const getNewTaskAssignee = () => {
    const userUUID = getUserUUID();
    return {
      value: getUserUUID(),
      label: getUserFullName(),
      key: userUUID,
      type: ParticipantType.staff,
      details: userData,
    };
  }

  const getComponentStateForTotal = () => {
    const result: {
      totalCount: number,
      alerts: ITaskAlert[],
      loading: boolean
    } = {
      totalCount: 0,
      alerts: [],
      loading: false
    }
    taskAlertDisplayCategoryMlovs?.map(({code}) => {
      const {totalCount, alerts, loading} = getComponentStateForCode(code);
      const displayCategoryId = getMlovIdFromCode(taskAlertDisplayCategoryMlovs, code);
      result.totalCount += totalCount;
      alerts.forEach(item => {item.taskDisplayCategoryId = displayCategoryId});
      result.alerts = [...result.alerts, ...alerts];
      result.loading = result.loading || loading;
    });
    return result;
  }

  // Temporary change for demo, single API should be called instead of calling multiple APIs
  const totalData = getComponentStateForTotal();

  const getTitleView = (title: string, addNew?: {type: string} ) => {
    return (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text
          style={{
            color: Colors.FoldPixel.GRAY300,
            fontSize: 14,
            fontWeight: '500',
            marginLeft: 8,
            marginBottom: 8,
          }}
        >
          {title}
        </Text>
      </View>
    );
  };
  const renderAlertsView = (
    <>
      {/* <View style={{marginBottom: 12, marginTop: 12}}>
        <ReasonForPrioritization/>
      </View> */}
      {componentState.showSearch && (
        <Input.Search
          placeholder="Search alerts"
          value={componentState.search}
          onChange={handleSearchChange}
          enterButton
        />
      )}

      {renderFilterView()}
      {props?.reasonForPrioritization && props?.reasonForPrioritization?.length > 0 && (
        <>
          {getTitleView(intl.formatMessage({id: 'reasonForPrioritization'}))}
          <ReasonForPrioritization reasonForPrioritization={props?.reasonForPrioritization}/>
        </>
      )}
      <Stack direction='row' style={{alignItems: 'flex-end', justifyContent: 'space-between', paddingBottom: 8, paddingTop: 8}}>
      {getTitleView('Notifications')}
        <HStack style={{alignItems: 'center'}}>
        <MultiSelectFilterView
          label='Status'
          selectedValues={statusFilter.alertFilters}
          disabled={totalData.loading}
          listItems={[
            {code: TASK_ALERT_CATEGORY_CODES.PENDING, value: 'Pending'},
            {code: TASK_ALERT_CATEGORY_CODES.DISMISSED, value: 'Dismissed'},
            {code: TASK_ALERT_CATEGORY_CODES.RESOLVED, value: 'Resolved'},
            {code: TASK_ALERT_CATEGORY_CODES.SNOOZED, value: 'Snoozed'}
          ]}
          onChange={(value, isChecked) => {
            const finalFilterValue = isChecked ? [...statusFilter.alertFilters, value] : statusFilter.alertFilters.filter((filter) => filter.code !== value.code);
            saveAlertPreferences(ALERT_FILTER_KEYS.ALERT_FILTERS, finalFilterValue);
            setStatusFilter((prev) => ({
              ...prev,
              alertFilters: finalFilterValue,
            }));
          }}
        />
        {showManualAddGaps && (
          <>
            <View
              style={{
                width: 1,
                height: 20,
                backgroundColor: Colors.Custom.Gray200,
                marginHorizontal: 12,
              }}
            />
            <Pressable
              onPress={() => {
                props?.onAlertAdd?.(TASK_TYPES.CLINICAL_FINDINGS);
              }}
            >
              <PlusSvg
                height={16}
                width={16}
                defaultColor={Colors.FoldPixel.GRAY300}
              />
            </Pressable>
          </>
        )}
        </HStack>
      </Stack>
      {showCategorizedAlerts && (<View style={{
        minHeight: 170
      }}><Collapse
        activeKey={componentState.activePanels}
        onChange={handlePanelChange}
        className="alerts-view"
      >
        {taskAlertDisplayCategoryMlovs?.map(({code, value}) => {
          const {totalCount, alerts, loading} = getComponentStateForCode(code);
          if (code === TASK_ALERT_CATEGORY_CODES.SNOOZED) {
            value = 'On Hold';
          }

          return (
            <>
            <Panel
              header={getHeaderForCollapse(
                value,
                totalCount,
                componentState.activePanels?.includes(code.toLowerCase()),
                code
              )}
                key={code.toLowerCase()}
                showArrow={false}
              >
                {renderTable(alerts, loading, totalCount, code)}
              </Panel>
              {!componentState.activePanels?.includes(code.toLowerCase()) && <NativeBaseDivider />}
            </>
          );
        })}
      </Collapse></View>)}
      {!showCategorizedAlerts && renderTable(totalData.alerts, totalData.loading, totalData.totalCount, '')}
      {/* just for qa envirement to check all required DO reasons */}
      {props?.reasonForPrioritizationQA && evnName() === 'qa.' && (
        <View style={{marginTop: 12}}>
          {getTitleView(intl.formatMessage({id: 'reasonForPrioritizationForQA'}))}
          <ReasonForPrioritization reasonForPrioritization={props?.reasonForPrioritizationQA}/>
        </View>
      )}
    </>
  );
  const renderCareGapView = (
    <View style={{marginTop: 12}}>
      <Stack direction='row' style={{alignItems: 'flex-end', justifyContent: 'space-between', paddingBottom: 8}}>
        {getTitleView('Care gaps')}
        <HStack style={{alignItems: 'center'}}>
        <MultiSelectFilterView
          label='Status'
          selectedValues={statusFilter.careGapFilters}
          disabled={componentState.caregapsLoading}
          listItems={[
            {code: 'open', value: 'Open'},
            {code: 'Pending Provider Action', value: 'Pending Provider Action'},
            {code: 'Pending External Action', value: 'Pending External Action'},
            {code: 'closed', value: 'Closed'},
            {code: TASK_ALERT_CATEGORY_CODES.DISMISSED, value: 'Dismissed'},
            {code: TASK_ALERT_CATEGORY_CODES.RESOLVED, value: 'Resolved'},
            {code: TASK_ALERT_CATEGORY_CODES.SNOOZED, value: 'Snoozed'}
          ]}
          onChange={(value, isChecked) => {
            const finalFilterValue = isChecked ? [...statusFilter.careGapFilters, value] : statusFilter.careGapFilters.filter((filter) => filter.code !== value.code);
            saveAlertPreferences(ALERT_FILTER_KEYS.CARE_GAP_FILTERS, finalFilterValue);
            setStatusFilter((prev) => ({
              ...prev,
              careGapFilters: finalFilterValue,
            }));
          }}
        />
        {showManualAddGaps && (
          <>
            <View
              style={{
                width: 1,
                height: 20,
                backgroundColor: Colors.Custom.Gray200,
                marginHorizontal: 12,
            }}
          ></View>
          <Pressable onPress={() => {
            props?.onAlertAdd?.(TASK_TYPES.CARE_GAPS);
          }}>
            <PlusSvg height={16} width={16} defaultColor={Colors.FoldPixel.GRAY300} />
            </Pressable>
          </>
        )}
        </HStack>
        {/* {componentState?.caregapsTotalCount > 0 && <View>
          <SidePagination
            page={componentState?.careGapPage}
            pageSize={componentState?.pageSize}
            totalCount={componentState?.caregapsTotalCount}
            handleChange={(newPage: number, pageSize: number) => {
              setComponentState((prev)=> {
                return {
                  ...prev,
                  careGapPage: newPage,
                }
              })
            }}
          />
        </View>} */}
      </Stack>
        {[TASK_ALERT_CUSTOM_CATEGORY_CODES.CARE_GAPS]?.map((code) => {
          const {totalCount, alerts, loading} = getComponentStateForCode(code);
          return (
            renderCareGapTable(alerts, loading, totalCount, code)
          );
      })}
    </View>
  );

  const fetchDiagnosisGapsFromIntegration = async () => {
    try {
      if (componentState.diagnosisGapsLoading) {
        return;
      }
      setComponentState((prev) => {
        return {
          ...prev,
          diagnosisGapsLoading: true
        };
      });
      const { contactData, personData } = props;
      const practiceLocationId = contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid || personData?.accountLocationUuid;
      const patientId = ehrPatientId || contactData?.patient?.patientId || personData?.patientId;
      const contactId = contactData?.id || '';
      const response = await getPatientDiagnosisGap(patientId, practiceLocationId || '');
      const data: IDeclinedPatientConditonInput[] = response?.data || [];
      let transformedDiagnosisGaps: IDiagnosisGap[] = data.map((gap: IDeclinedPatientConditonInput, index: number) => ({
        id: index.toString(),
        hccDescription: gap.hccCodeGroupData.description,
        hccCode: gap.hccCodeGroupData.codeGroup,
        hccScore: gap.hccCodeGroupData.codeGroupScore.toString(),
        conditions: gap.conditionDetails.map((detail: IConditionDetail) => ({
          name: detail.name,
          source: detail.source,
          icd10Code: detail.icd10Code,
          recordedDate: detail.onsetDate,
          recordedBy: detail.recordedBy,
          status: detail.status,
          resourceId: detail.resourceId,
          diagnosisGapStatus: detail.diagnosisGapStatus
        }))
      }))
      const savedDiagnosisGaps = JSON.parse(await LocalStorage.getItem(LOCAL_STORAGE_KEYS.ACCEPTED_DIAGNOSIS_GAPS).catch(() => null)) || [];
      if (savedDiagnosisGaps?.length) {
        transformedDiagnosisGaps = transformedDiagnosisGaps.filter((gap) =>
          !savedDiagnosisGaps.includes(gap.hccCode)
        );
      }
      if(statusFilter.diagnosisGapFilters.length) {
        const statusCodes: string[] = statusFilter?.diagnosisGapFilters?.map((filter) => filter.code) || [];
        filterByStatusDiagnosisGaps(transformedDiagnosisGaps, statusCodes);
        return
      }
      setComponentState((prev) => ({
        ...prev,
        diagnosisGapsLoading: false,
        diagnosisGapsAlerts: transformedDiagnosisGaps ?? [],
        masterDiagnosisGapsAlerts: transformedDiagnosisGaps ?? [],
        diagnosisGapsTotalCount: transformedDiagnosisGaps?.length ?? 0,
        masterDiagnosisGapsTotalCount: transformedDiagnosisGaps?.length ?? 0
      }));
    } catch (error) {
      setComponentState((prev) => {
        return {
          ...prev,
          diagnosisGapsLoading: false,
          diagnosisGapsAlerts: [],
          diagnosisGapsTotalCount: 0
        };
      });
    }
  };

  const renderDiagnosisGapsTable = (
    diagnosisGapsAlerts: any,
    loading: boolean,
  ) => {
    const data = diagnosisGapsAlerts;
    const columns = [
      {
        title:
          <Text
            size={'xsNormal'}
            style={{
              color: Colors?.FoldPixel?.GRAY250
            }}
          >
            Title
          </Text>
        ,
        dataIndex: 'title',
        key: 'title',
        width: showManualAddGaps ? '60%' : '50%',
        render: (text:any, record:any) => {
          const activePatientProfile = getActivePatientProfile(props?.contactData?.contactProfiles || []);
          const haveAccessToActivePatientProfile = checkLocationAccess({
            locationUuids: [activePatientProfile?.accountLocationUuid || ""],
            allowedLocationUuids: allowedUserAccountLocationUuids,
            isMultiTenancyEnabled,
          });
          
          return (
            <Pressable onPress={() => {
              if (showManualAddGaps) {
                props?.editCareGapAlert?.(record, TASK_TYPES.DIAGNOSIS_GAPS);
              }
            }}>
            <VStack>
              <Text size={'smRegular'} style={{ color: !haveAccessToActivePatientProfile ? Colors?.FoldPixel?.GRAY300 : Colors.FoldPixel.GRAY400, paddingBottom: 3 }}>
                {!haveAccessToActivePatientProfile ? (
                  <LockTooltipIcon name="Alert" />
                ) : null}
                {record.title}
                {record.hccDescription}
              </Text>
              <HStack space={2} alignItems="center">
                <HStack>
                  <Text size={'xsNormal'} style={{ color: Colors.FoldPixel.GRAY250 }}>HCC: </Text>
                  <View
                    style={{
                      paddingHorizontal: 4,
                      borderRadius: 4,
                      backgroundColor: Colors.FoldPixel.GRAY100,
                    }}
                  >
                    <Text size={'xsNormal'} bgColor={Colors.FoldPixel.GRAY50} style={{ color: Colors.FoldPixel.GRAY300 }}>
                      {record.hccCode}
                    </Text>
                  </View>
                </HStack>
                {/* <Text size={'xsNormal'} style={{ color: Colors.FoldPixel.GRAY250 }}>
                  RAF: <Text size={'smRegular'} style={{ color: Colors.FoldPixel.STATUS_IN_PROGRESS}}>{record.hccScore}</Text>
                </Text> */}
              </HStack>
            </VStack>
            </Pressable>
          )
        }
      },
      {
        title:
          <Text
            size={'xsNormal'}
            style={{
              color: Colors?.FoldPixel?.GRAY250
            }}
          >
            Status
          </Text>
        ,
        dataIndex: 'status',
        key: 'status',
        width: '30%',
        render: (text:any, record:any) => {
          // const isStatusActive = record.conditions.some((condition:any) => condition.status === 'Active');
          const isStatusActive = record.status !== 'rejected';
          const isDismissed = isDiagnosisGapDismissed(record) && !showManualAddGaps;
          return (
          <Text size={'smRegular'} style={{
            color: Colors.FoldPixel?.GRAY250,
            paddingHorizontal: 4,
            paddingVertical: 2,
          }}
          >
            { isDismissed ? 'Dismissed' : isStatusActive ? 'Open': ''}
          </Text>
        )}
      },
      {
        title:
          <Text
            size={'xsNormal'}
            style={{
              color: Colors?.FoldPixel?.GRAY250,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            Action
          </Text>
        ,
        dataIndex: 'action',
        key: 'action',
        width: showManualAddGaps ? '10%' : '20%',
        render: (action:any, record:any) => {
          const activePatientProfile = getActivePatientProfile(props?.contactData?.contactProfiles || []);
          const haveAccessToActivePatientProfile = checkLocationAccess({
            locationUuids: [activePatientProfile?.accountLocationUuid || ""],
            allowedLocationUuids: allowedUserAccountLocationUuids,
            isMultiTenancyEnabled,
          });
          const isDismissed = !haveAccessToActivePatientProfile || isDiagnosisGapDismissed(record) && !showManualAddGaps;
          const diagnosisGapAction = isDismissed ? 'View' : 'Review';
          return (
          <Stack direction={'row'} style={{paddingRight: 4, justifyContent: 'flex-end'}}>
            {/* {loading? (
              <Skeleton.Text
                alignContent={'center'}
                width={'auto'}
                justifyContent={'center'}
                alignItems={'center'}
                lines={1}
                marginTop={1}
              ></Skeleton.Text>
            ) : (
              <FoldButton
                customProps={{
                  btnText: 'Review',
                }}
                nativeProps={{
                  onPress:() => {
                    handleDiagnosisGapDetail(record);
                  },
                  borderRadius: 4,
                  borderWidth: 0.5,
                  borderColor: Colors.Custom.Primary200,
                  isLoading: loading,
                  paddingX: 6,
                  backgroundColor: Colors.Custom.PrimaryColor100,
                  _text: {
                    fontSize: 12,
                    color: Colors.Custom.Primary300,
                  },
                  _hover: {
                    backgroundColor: Colors.Custom.Primary300,
                    _text: {
                      color: Colors.Custom.PrimaryColor100,
                    },
                  },
                }}
              />
            )} */}
            {loading? (
              <Skeleton.Text
                alignContent={'center'}
                width={'auto'}
                justifyContent={'center'}
                alignItems={'center'}
                lines={1}
                marginTop={1}
              ></Skeleton.Text>
            ) : showManualAddGaps ? (
              <Stack direction={'row'}  style={{paddingRight: 4, alignItems: 'center'}}>
              <View style={{flex: 1}} />
              <Stack
                direction={'row'}
                style={{alignItems: 'center', justifyContent: 'flex-end'}}
              >
                <Popover
                  overlayClassName="alerts-action-popover"
                  key={record?.id}
                  overlayInnerStyle={{padding: -8, borderRadius: 16}}
                  overlayStyle={{padding: 0}}
                  overlay={{padding: 0}}
                  style={{padding: 0, borderRadius: 16}}
                  placement={'left'}
                  trigger="click"
                  open={!!isOpenActionsPopover?.[record?.id]}
                  onOpenChange={(visible: boolean) =>
                    handleVisibleChange(visible, record?.id)
                  }
                  content={() => popoverContent(record, diagnosisGapAction, ALERT_ACTION_CODES.REVIEW_DIAGNOSIS_GAP)}
                >
                  <Pressable
                    cursor={
                      loadingTaskAlertTypes || loaders.statusChangeloading
                        ? 'not-allowed'
                        : 'pointer'
                    }
                    disabled={
                      loadingTaskAlertTypes || loaders.statusChangeloading
                    }
                    onPress={() => {
                      handleVisibleChange(
                        !isOpenActionsPopover?.[record?.id],
                        record?.id
                      );
                    }}
                    _hover={{
                      backgroundColor: Colors.FoldPixel.GRAY50,
                    }}
                  >
                    {record?.id ===
                      actionsState.selectedTaskAlertForAction?.id &&
                    loaders.statusChangeloading ? (
                      <Spinner />
                    ) : (
                      <ThreeDotsSvg
                        customStrokeColor={
                          loadingTaskAlertTypes
                            ? Colors?.FoldPixel?.GRAY150
                            : undefined
                        }
                      />
                    )}
                  </Pressable>
                </Popover>
              </Stack>
            </Stack>
            ) : (
              <FoldButtonV2
                height={15}
                width={50}
                key={diagnosisGapAction}
                label={diagnosisGapAction}
                buttonType={ButtonType.tertiary}
                onPress={() => {
                  handleDiagnosisGapDetail(record);
                }}
              />
            )}
          </Stack>
        )
      }
      }
    ];
    return (
      <Table
        className="alertsTable care-gap"
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey="id"
        loading={{
          spinning: loading,
          indicator: (
            <View style={{marginTop: 10}}>
              <Spin size={'default'} />
            </View>
          ),
        }}
        locale={{
          emptyText: intl.formatMessage({id: 'noDiagnosisGaps'}),
        }}
      />
    );
  };

  // const renderDiagnosisGapView = (
  //   <View style={{marginTop: 12}}>
  //     { !props?.toShowOnlyDiagnosisGaps && getTitleView('Diagnosis Gaps')}
  //     {diagnosisGapCodes.map((code) => {
  //       return (
  //         renderDiagnosisGapsTable(
  //           componentState.diagnosisGapsAlerts,
  //           componentState.diagnosisGapsLoading
  //         )
  //       );
  //     })}
  //   </View>
  // );

  const fetchDiagnosisGapsData = async () => {
    try {
      if (componentState.diagnosisGapsLoading || loadingTaskAlertTypes || isLoading) {
        return;
      }
      if(showManualAddGaps) {
        await fetchDiagnosisGapsAlerts();
      } else {
        await fetchDiagnosisGapsFromIntegration();
      }
      // setComponentState((prev) => {
      //   return {
      //     ...prev,
      //     diagnosisGapsLoading: true
      //   };
      // });
      // const { contactData, personData } = props;
      // const practiceLocationId = contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid || personData?.accountLocationUuid;
      // const patientId = contactData?.patient?.patientId || personData?.patientId;
      // const response = await getPatientDiagnosisGap(patientId, practiceLocationId || '');
      // const data: IDeclinedPatientConditonInput[] = response?.data || [];
      // const transformedDiagnosisGaps: IDiagnosisGap[] = data.map((gap: IDeclinedPatientConditonInput, index: number) => ({
      //   id: index.toString(),
      //   hccDescription: gap.hccCodeGroupData.description,
      //   hccCode: gap.hccCodeGroupData.codeGroup,
      //   hccScore: gap.hccCodeGroupData.codeGroupScore.toString(),
      //   conditions: gap.conditionDetails.map((detail: IConditionDetail) => ({
      //     name: detail.name,
      //     source: detail.source,
      //     icd10Code: detail.icd10Code,
      //     recordedDate: detail.onsetDate,
      //     recordedBy: detail.recordedBy,
      //     status: detail.status,
      //     resourceId: detail.resourceId
      //   }))
      // }));

      // setComponentState((prev) => ({
      //   ...prev,
      //   diagnosisGapsLoading: false,
      //   diagnosisGapsAlerts: transformedDiagnosisGaps,
      //   diagnosisGapsTotalCount: transformedDiagnosisGaps.length
      // }));
    } catch (error) {
      setComponentState((prev) => {
        return {
          ...prev,
          diagnosisGapsLoading: false,
          diagnosisGapsAlerts: [],
          diagnosisGapsTotalCount: 0
        };
      });
    }
  };

  async function fetchDiagnosisGapsAlerts() {
    const taskTypeIds: string[] = []
    taskAlertTypes?.getTaskTypes?.taskTypes.forEach(alertType => {
      if (alertType.code === TASK_TYPES.DIAGNOSIS_GAPS) {
        taskTypeIds.push(alertType.id)
      }
    })
    const diagnosisGapFilterCodes = statusFilter.diagnosisGapFilters.map((filter) => filter.code);
    type TaskAlertCategoryCode = typeof TASK_ALERT_CATEGORY_CODES[keyof typeof TASK_ALERT_CATEGORY_CODES];
    const validCategoryCodes = Object.values(TASK_ALERT_CATEGORY_CODES);
    const matchingCodes = diagnosisGapFilterCodes?.filter((code): code is TaskAlertCategoryCode =>
      validCategoryCodes.includes(code as TaskAlertCategoryCode)
    );
    const selectedStatuses = diagnosisGapFilterCodes?.length ? diagnosisGapFilterCodes : [TASK_STATUS.ACCEPTED, TASK_STATUS.REJECTED, TASK_STATUS.OPEN]
    const statusIds = selectedStatuses.map((code) => getMlovIdFromCode(taskStatusMlov, code))
      .filter((id): id is string => !!id);
    const params = {
      page: 1,
      search: componentState.search,
      prioritySort: '',
      taskTypeIds: taskTypeIds,
      statusCodes: [],
      statusIds: statusIds,
    };

    const paramsArray = [
      {
        params: params,
        stateKeyToUpdate: 'diagnosisGapsAlerts',
        loadingKeyToUpdate: 'diagnosisGapsLoading',
        totalCountKeyToUpdate: 'diagnosisGapsTotalCount'
      }
    ];
    try {
      setComponentState((prev) => ({...prev, diagnosisGapsLoading: true}));
      const fetchedAlertDataArray = await fetchData(
        props.searchText,
        paramsArray,
        []
      );
      for (let index = 0; index < fetchedAlertDataArray.length; index++) {
        const fetchedAlertData = fetchedAlertDataArray[index];
        const taskById = fetchedAlertData.data.reduce((acc: any, task: ITask) => {
          acc[task.id] = task;
          task.subTasks?.forEach((subTask: any) => {
            acc[subTask.id] = subTask;
          });
          return acc;
        }, {});
        const taskStatus = taskStatusMlov.find((status) => status.id === fetchedAlertData.data?.[0]?.statusId);
        const tasksWithSubtasks = fetchedAlertData?.data?.filter?.((task: any) => task.subTasks?.length);


        if (paramsArray?.length > 0 && index <= paramsArray?.length - 1) {
          const diagnosisGapFilterCodes: string[] = []//statusFilter.diagnosisGapFilters.map((filter) => filter.code);
          const acceptedDiagnosisGaps: string[] = [];//await getDemoDataFromLocalStorage('acceptedDiagnosisGaps');
          const transformedDiagnosisGaps: IDiagnosisGap[] = tasksWithSubtasks.map((task: any, index: number) => ({
            id: task?.id,
            hccDescription: task?.description,
            hccCode: task?.title,
            status: task?.status?.code || taskStatus?.code,
            taskTypeId: task?.taskTypeId,
            // hccScore: gap.hccCodeGroupData.codeGroupScore.toString(),
            conditions: task.subTasks.map((subTask: any) => {
              const subTaskDetails = taskById[subTask?.id];
              return {
                id: subTask?.id,
                name: subTaskDetails?.description || task?.description,
                // source: detail.source,
                icd10Code: subTaskDetails?.title || task?.title,
                // recordedDate: detail.onsetDate,
                // recordedBy: detail.recordedBy,
                status: subTaskDetails?.status?.code || task?.status?.code,
                // resourceId: detail.resourceId,
                diagnosisGapStatus: subTaskDetails?.status?.code || task?.status?.code,
              };
            })
          })).filter((gap: any) => gap.conditions?.some((condition: any) => {
            const isVisible = condition?.diagnosisGapStatus && diagnosisGapFilterCodes.length ? diagnosisGapFilterCodes.includes(condition?.diagnosisGapStatus) : true;
            const isAccepted = acceptedDiagnosisGaps.length && condition?.resourceId ? acceptedDiagnosisGaps.includes(condition?.resourceId) : false;
            return isVisible && !isAccepted;
          }));
          if(statusFilter.diagnosisGapFilters.length) {
            const statusCodes: string[] = statusFilter?.diagnosisGapFilters?.map((filter) => filter.code) || [];
            filterByStatusDiagnosisGaps(transformedDiagnosisGaps, statusCodes);
            return
          }
          setComponentState((prev) => ({
            ...prev,
            diagnosisGapsLoading: false,
            diagnosisGapsAlerts: transformedDiagnosisGaps,
            diagnosisGapsTotalCount: transformedDiagnosisGaps.length,
            masterDiagnosisGapsAlerts: transformedDiagnosisGaps ?? [],
            masterDiagnosisGapsTotalCount: transformedDiagnosisGaps?.length ?? 0
          }));
        }
      }
    } catch (error) {
      setComponentState((prev) => ({...prev, diagnosisGapsLoading: false}));
    }
  }

  // const renderDiagnosisGapsTable = (
  //   diagnosisGapsAlerts: any,
  //   loading: boolean,
  // ) => {
  //   const data = diagnosisGapsAlerts;
  //   const columns = [
  //     {
  //       title:
  //         <Text
  //           style={{
  //             color: Colors?.FoldPixel?.GRAY300,
  //             fontWeight: '500',
  //             fontSize: 14,
  //           }}
  //         >
  //           Title
  //         </Text>
  //       ,
  //       dataIndex: 'title',
  //       key: 'title',
  //       width: '50%',
  //       render: (text:any, record:any) => (
  //         <VStack>
  //           <Text style={{ fontSize: 14, fontWeight: '400', color: Colors.FoldPixel.GRAY400, paddingBottom: 3 }}>{record.hccDescription}</Text>
  //           <HStack space={2} alignItems="center">
  //             <HStack>
  //               <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.FoldPixel.GRAY200 }}>HCC: </Text>
  //               <View
  //                 style={{
  //                   paddingHorizontal: 4,
  //                   borderRadius: 4,
  //                   backgroundColor: Colors.FoldPixel.GRAY100,
  //                 }}
  //               >
  //                 <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.FoldPixel.GRAY200 }}>
  //                   {record.hccCode}
  //                 </Text>
  //               </View>
  //             </HStack>
  //             {/* <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.FoldPixel.GRAY200 }}>
  //               RAF: <Text style={{ color: Colors.FoldPixel.STATUS_IN_PROGRESS}}>{record.hccScore}</Text>
  //             </Text> */}
  //           </HStack>
  //           {/* <View
  //             style={{
  //               flexDirection: 'row',
  //               marginTop: 4,
  //             }}
  //           >
  //             <DocumentMedicineSvg />
  //             <Text style={{ fontSize: 12, fontWeight: '400', color: Colors.Custom.Primary300}}>HIE</Text>
  //           </View> */}
  //         </VStack>
  //       )
  //     },
  //     {
  //       title:
  //         <Text
  //           style={{
  //             color: Colors?.FoldPixel?.GRAY300,
  //             fontWeight: '500',
  //             fontSize: 14,
  //           }}
  //         >
  //           Status
  //         </Text>
  //       ,
  //       dataIndex: 'status',
  //       key: 'status',
  //       width: '35%',
  //       render: (text:any, record: any) => {
  //         const isStatusActive = record.status !== 'rejected';
  //         return (
  //         <Text style={{
  //           color: Colors.FoldPixel?.GRAY300,
  //           fontWeight: '400',
  //           fontSize: 12,
  //           paddingHorizontal: 4,
  //           paddingVertical: 2,
  //         }}
  //         >
  //           {isStatusActive ? 'Open' : 'Dismissed'}
  //         </Text>
  //       )}
  //     },
  //     {
  //       title: <></>,
  //       dataIndex: 'action',
  //       key: 'action',
  //       width: '15%',
  //       render: (action:any, record:any) => (
  //         <Stack direction={'row'} style={{paddingRight: 4}}>
  //           {loading? (
  //             <Skeleton.Text
  //               alignContent={'center'}
  //               width={'auto'}
  //               justifyContent={'center'}
  //               alignItems={'center'}
  //               lines={1}
  //               marginTop={1}
  //             ></Skeleton.Text>
  //           ) : (
  //             <FoldButtonV2
  //               height={15}
  //               key={'review'}
  //               label={'Review'}
  //               buttonType={ButtonType.tertiary}
  //               onPress={() => {
  //                 handleDiagnosisGapDetail(record);
  //               }}
  //             />
  //           )}
  //         </Stack>
  //       )
  //     }
  //   ];
  //   return (
  //     <Table
  //       className="alertsTable care-gap"
  //       dataSource={data}
  //       columns={columns}
  //       pagination={false}
  //       rowKey="id"
  //       loading={{
  //         spinning: loading,
  //         indicator: (
  //           <View style={{marginTop: 10}}>
  //             <Spin size={'default'} />
  //           </View>
  //         ),
  //       }}
  //       locale={{
  //         emptyText: intl.formatMessage({id: 'noDiagnosisGaps'}),
  //       }}
  //     />
  //   );
  // };

  const renderDiagnosisGapView = (
    <View style={{marginTop: 8}}>
      <Stack direction='row' style={{alignItems: 'center', marginBottom: 12, display: 'flex', justifyContent: 'space-between'}}>
      { !props?.toShowOnlyDiagnosisGaps && getTitleView('Diagnosis gaps')}
        <HStack style={{alignItems: 'center'}}>
          <MultiSelectFilterView
            label='Status'
            selectedValues={statusFilter.diagnosisGapFilters}
            disabled={componentState.diagnosisGapsLoading}
            listItems={[
              {code: 'open', value: 'Open'},
              // {code: 'Pending Provider Action', value: 'Pending Provider Action'},
              // {code: 'Pending External Action', value: 'Pending External Action'},
              {code: 'rejected', value: 'Dismissed'},
            ]}
            onChange={(value, isChecked) => {
              const diagnosisGapFilterValue = isChecked ? [...statusFilter.diagnosisGapFilters, value] : statusFilter.diagnosisGapFilters.filter((filter) => filter.code !== value.code);
              saveAlertPreferences(ALERT_FILTER_KEYS.DIAGNOSIS_GAP_FILTERS, diagnosisGapFilterValue);
              setStatusFilter((prev) => ({
                ...prev,
                diagnosisGapFilters: diagnosisGapFilterValue,
              }));
            }}
          />
          {showManualAddGaps &&
          <>
          <View
            style={{
              width: 1,
              height: 20,
              backgroundColor: Colors.Custom.Gray200,
              marginHorizontal: 12,
            }}
          ></View>
          <Pressable onPress={() => {
            props?.onAlertAdd?.(TASK_TYPES.DIAGNOSIS_GAPS);
          }}>
            <PlusSvg height={16} width={16} defaultColor={Colors.FoldPixel.GRAY300} />
          </Pressable>
          </>}
        </HStack>
      </Stack>
      {diagnosisGapCodes.map((code) => {
        return (
          renderDiagnosisGapsTable(
            componentState.diagnosisGapsAlerts,
            componentState.diagnosisGapsLoading
          )
        );
      })}
    </View>
  );

  return (
    props?.toShowOnlyDiagnosisGaps ? renderDiagnosisGapView : <>
      {actionsState.showDismissAlertReasonView ||
      actionsState.showResolveAlertReasonView ? (
        <AlertActionReasonView
          reasonsList={actionsState.alertActionReasons}
          reasonsLoading={loadingAlertActionReasons}
          onSave={onSaveOfAlertActionReason}
          onCancelView={() => {
            setActionsState((prev) => ({
              ...prev,
              showDismissAlertReasonView: false,
              showResolveAlertReasonView: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          drawerTitle={
            actionsState.showDismissAlertReasonView
              ? 'dismissAlert'
              : 'markAsResolved'
          }
          saveLoading={loaders.statusChangeloading}
          drawerSubTitleTextId={
            actionsState.showDismissAlertReasonView
              ? 'selectReasonToDismissALert'
              : 'selectReasonToResolveALert'
          }
        />
      ) : (
        renderAlertsView
      )}
      {renderCareGapView}
      {renderDiagnosisGapView}
      {actionsState.showAddTaskDrawer && (
        <AddOrUpdateTask
          saveBtnText={'Save'}
          successMessage={'taskAddedMsg'}
          member={getNewTaskMember() as any}
          assignee={getNewTaskAssignee() as any}
          isVisible={actionsState.showAddTaskDrawer}
          onComplete={(data) => {
            if (actionsState?.selectedTaskAlertForAction) {
              logAuditAction(
                ALERT_ACTION_CODES.CREATE_TASK,
                { taskId: data?.addOrUpdateTask?.id },
                actionsState?.selectedTaskAlertForAction,
              )
            }
            setActionsState((prev) => ({
              ...prev,
              showAddTaskDrawer: false,
            }));
          }}
          onCancel={() => {
            setActionsState((prev) => ({
              ...prev,
              showAddTaskDrawer: false,
            }));
          }}
          restrictPatientOrLeadSelectionTo={getNewTaskMember() as any}
        />
      )}
      {actionsState.showAppointmentDrawer && (
        <BookingWorkflow
          user={{
            name: props?.formattedContactData?.contactData?.name || '',
            uuid: props?.formattedContactData?.contactData?.uuid || '',
          }}
          disablePatientProspectSearch={true}
          isOpen={true}
          appointmentType={AppointmentType.bookAppointment}
          defaultParticipants={[
            {
              label: props?.formattedContactData?.contactData?.name || '',
              key: props?.formattedContactData?.contactData?.uuid || '',
              value: props?.formattedContactData?.contactData?.uuid || '',
              type: ParticipantType.patient,
            },
          ]}
          onAppointmentBooked={(appointmentId: any) => {
            logAuditAction(
              ALERT_ACTION_CODES.SCHEDULE_APPOINTMENT,
              { appointmentId: appointmentId },
              actionsState?.selectedTaskAlertForAction,
            )
          }}
          onClose={(data?: any) => {
            setActionsState((prev) => ({
              ...prev,
              showAppointmentDrawer: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          onCancel={() => {
            setActionsState((prev) => ({
              ...prev,
              showAppointmentDrawer: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
        />
      )}
      {actionsState.showContactSendForm && (
        <ContactSendForm
          isVisible={true}
          assignmentData={{
            patientId:
              props?.formattedContactData?.contactData?.patient?.patientId,
            contactId: props?.formattedContactData?.contactData?.uuid,
            assignedById: userUuid,
            patientEmail: props?.formattedContactData?.contactData?.email,
            patientFirstName: props?.formattedContactData?.contactData?.name,
            patientContactUUID: props?.formattedContactData?.contactData?.uuid,
            contactTypeCode:
              props?.formattedContactData?.contactType?.code || '',
          }}
          onActionComplete={(formIds?: string[]) => {
            if (!!formIds && formIds?.length > 0) {
              logAuditAction(
                ALERT_ACTION_CODES.SEND_ASSESSMENT,
                { formLogIds: [...new Set(formIds)] },
                actionsState?.selectedTaskAlertForAction,
              )
            }
            setActionsState((prev) => ({
              ...prev,
              showContactSendForm: false,
            }));
            notification.success({
              message: intl.formatMessage({id: 'formSentSuccessully'}),
              duration: 3.0,
              placement: 'topRight',
            });
          }}
          onClose={() => {
            setActionsState((prev) => ({
              ...prev,
              showContactSendForm: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          categoryCodesToSearchForms={[
            FORM_CATEGORY_TYPES.ASSESSMENT_FORM_CATEGORY,
          ]}
          showButtonsInTopBar={true}
          onCancelAction={() => {
            setActionsState((prev) => ({
              ...prev,
              showContactSendForm: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
        />
      )}
      {actionsState.showEducationalContentDrawer && (
        <SideMenu
          hideSideMenuTopBar={true}
          userInputFieldMap={{
            out: {
              userInputFieldList: typeNodes?.userInputFieldList,
            },
          }}
          nodeType={typeNodes || ''}
          isOpen={true}
          title={(typeNodes && typeNodes.displayName) || ''}
          onClose={() => {
            setActionsState((prev) => ({
              ...prev,
              showEducationalContentDrawer: false,
              selectedTaskAlertForAction: undefined,
            }));
          }}
          onSave={handleSendOfEducationContent}
          isContinueButtonLoading={sendPatientEducationLoading}
          primaryButtonText={'Send'}
          encloseSideMenuInDrawer={true}
          {...(!isSideCarContext ? {width: '40%'} : {})}
        />
      )}
      {
        attachmentState.showAttachmentDrawer &&
        <AttachmentsList onCancel={() => setAttachmentState((prev) => ({
          ...prev,
          showAttachmentDrawer: false,
          attachments: []
        }))}
          contactData={props?.contactData}
          attachments={manageAttachmentsListData(attachmentState.attachments || [])}
        />
      }
      {actionsState.showRunAutomationDrawer && (
        <Drawer
          width={
            isSideCarContext ? '100%' : finalWidth
          }
          visible={true}
          closeIcon={null}
          closable
          className={isSideCarContext ? 'custom-task-drawer-sidecar automation-drawer' : ''}
          onClose={() => {
            setActionsState((prev) => ({
              ...prev,
              showRunAutomationDrawer: false,
            }));
          }}
          maskClosable={true}
          mask={isSideCarContext ? false : true}
        >
          <AutomationWorkflowEmployee
            type="patient"
            initData={{selectedItems: [props?.contactData] }}
            onClose={() => {
              setActionsState((prev) => ({
                ...prev,
                showRunAutomationDrawer: false,
              }));
            }}
            onModalClose={(data?: any) => {
              setActionsState((prev) => ({
                ...prev,
                showRunAutomationDrawer: false,
              }));
            }}
          />
        </Drawer>
      )}
      {(actionsState.showAddNotesDrawer || actionsState.showUpdateNotesDrawer) && props.contactData && (
        <SidecarAddNotesDrawer
          accountUserList={accountUserList || []}
          contactData={props?.contactData}
          onClose={()=> {
            setActionsState((prev) => ({
              ...prev,
              showAddNotesDrawer: false,
              showUpdateNotesDrawer: false
            }));
          }}
          onSaveNote={(noteData)=> {
            if (actionsState?.selectedTaskAlertForAction && noteData?.id) {
              logAuditAction(
                ALERT_ACTION_CODES.ADD_NOTE,
                { noteId:noteData?.id, docStatus: noteData?.docStatus },
                actionsState?.selectedTaskAlertForAction,)
            }
            setActionsState((prev) => ({
              ...prev,
              showAddNotesDrawer: false,
              showUpdateNotesDrawer: false
            }));
          }}
          isNewNote={actionsState.showAddNotesDrawer}
          noteData={componentState.todayInProgressNote}
          formattedContactData={props.formattedContactData}
        />
      )}
       {actionsState.showInProgressAddNotesPopup && (
        <SidecarConfirmationPopup
          header={'Create new note?'}
          onClose={()=> {
            setActionsState((prev) => ({
              ...prev,
              showInProgressAddNotesPopup: false,
              showAddNotesDrawer: false
            }));
          }}
          message={intl.formatMessage({id: 'todayInprogressNoteMsg'})}
          buttonActions={[
            {
              btnText: 'createNewButtonLabel',
              buttonType: ButtonType.secondary,
              onClick: () => {
                setActionsState((prev) => ({
                  ...prev,
                  showInProgressAddNotesPopup: false,
                  showAddNotesDrawer: true
                }));
              },
            },
            {
              btnText: 'openProgressNoteLabel',
              buttonType: ButtonType.primary,
              onClick: () => {
                setActionsState((prev) => ({
                  ...prev,
                  showInProgressAddNotesPopup: false,
                  showUpdateNotesDrawer: true
                }));
              },
            },
          ]}
        />
      )}
    </>
  );
};

export default AlertsView;
