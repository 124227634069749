import { Observation } from 'fhir/r4';
import {
  BLOOD_PRESSURE_LOINC,
  DIASTOLIC_BLOOD_PRESSURE_LOINC,
  DISPLAY_DATE_FORMAT,
  HEIGHT_LOINC,
  SYSTOLIC_BLOOD_PRESSURE_LOINC,
} from '../../../../../../../constants';
import {IEhrCapability, IVitalData} from '../../../../../../../Interfaces';
import {getMomentObj} from '../../../../../../../utils/DateUtils';
import {
  cmToFeetAndInches,
  convertVitalValues,
  inchesToFeetAndInches,
  isInchesUnit,
} from '../../../../../../../utils/vitalUnitConversions';
import { getDisplay, getFHIRTemplate, getVitalConfig, isVitalValueAvailable, Vital, VitalInputType } from '../../../../../../../utils/VitalUtils';
import {IAddOrUpdateObservationList, IObservation} from '../interfaces';
import { FHIR_CODE_SYSTEMS, FHIR_SYSTEM_CODE_TYPES } from '../../../../../../../constants/FhirConstant';
import { IFormatReconciliationMetaData, IReconciliationFormattedData } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { EXTENSION_URLS } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';

export const getRecordDataFormatted = (listData: any[], vitalList: IVitalData[]): IObservation[] => {
  const ehrFormattedRecords: IObservation[] = []
  listData.map((item: any) => {
    const record = item?.resource;
    if (record?.code?.coding?.[0]?.code) {
      const vitalConfig = getVitalConfig(
        record.code.coding[0].code as Vital,
        vitalList
      );
      if (isVitalValueAvailable(record) && vitalConfig) {
        setVitalValuePrecision(record, vitalList);
        ehrFormattedRecords.push(record);
      }
    }
  });
  return ehrFormattedRecords;
};

export const sortOldRecords = (oldRecords: IObservation[]) => {
  const sortedItems = oldRecords.sort((observation1, observation2) => {
    const documentDataData1 = observation1?.extension?.find?.((ext: any) => ext?.url === EXTENSION_URLS.documentDate);
    const documentData1 = documentDataData1?.valueString || '';

    const documentDataData2 = observation2?.extension?.find?.((ext: any) => ext?.url === EXTENSION_URLS.documentDate);
    const documentData2 = documentDataData2?.valueString || '';

    const recordDate1 = documentData1 || observation1.effectiveDateTime || observation1.issued;
    const recordDate2 = documentData2 || observation2.effectiveDateTime || observation2.issued;
    if (recordDate1 && recordDate2) {
      const date1 = getMomentObj(recordDate1);
      const date2 = getMomentObj(recordDate2);
      return date2 > date1 ? 1 : -1;
    }
    return 0;
  });

  return sortedItems;
}

export const getOldRecordsData = (
  oldRecords: IObservation[],
  vitalData?: IVitalData
) => {

  const matchedData: IObservation[] = [];
  oldRecords.forEach((entry) => {
    if (
      entry.code?.coding?.length &&
      (entry.code.coding[0].display === vitalData?.display ||
        entry.code.coding[0].code === vitalData?.loinc)
    ) {
      matchedData.push(entry);
    }
  });

  const sortedItems = sortOldRecords(matchedData);

  if (sortedItems.length) {
    return sortedItems[0];
  }
};

export const getLastRecordText = (
  oldRecords: IObservation[],
  vitalData?: IVitalData,
  returnSeparateComponents?: boolean
) => {
  const latestRecord = getOldRecordsData(oldRecords, vitalData);
  if (latestRecord) {
    return getFormattedVitalValue(latestRecord, vitalData, returnSeparateComponents);
  }
};

export const getFormattedVitalValue = (record: IObservation, vitalData?: IVitalData, returnSeparateComponents?: boolean, isGraph?: boolean) => {
  let value = record.valueQuantity?.value || record.valueString;
    let unit = record.valueQuantity?.unit || vitalData?.unit;
    if (vitalData?.loinc == Vital.bloodPressure) {
      if (record.component?.length) {
        const systolicData = record.component?.find(
          (item) =>
            item.code?.coding?.length &&
            item.code.coding[0].code === SYSTOLIC_BLOOD_PRESSURE_LOINC
        );
        const diastolicData = record.component?.find(
          (item) =>
            item.code?.coding?.length &&
            item.code.coding[0].code === DIASTOLIC_BLOOD_PRESSURE_LOINC
        );
        const systolicValue = systolicData?.valueQuantity?.value || '-';
        const diastolicValue = diastolicData?.valueQuantity?.value || '-';
        value = `${systolicValue}/${diastolicValue}`;
      } else if (value) {
        value = `${value}`;
      }
    } else if (vitalData?.loinc === HEIGHT_LOINC && value) {
      const floatValue = parseFloat(`${value}`);
      if (isInchesUnit(unit)) {
        const {feet, inches} = inchesToFeetAndInches(floatValue);
        value = isGraph ? formatHeightValue(feet,inches) : `${feet.toFixed(0)} ft ${inches.toFixed(0)} in`;
      } else {
        const {feet, inches} = cmToFeetAndInches(floatValue);
        value = isGraph ? formatHeightValue(feet,inches) : `${feet.toFixed(0)} ft ${inches.toFixed(0)} in`;
      }
      unit = '';
    } else if (value && vitalData?.displayUnit && unit && vitalData?.displayUnit !== unit) {
      const floatValue = parseFloat(`${value}`);
      const convertedValue = convertVitalValues(floatValue, unit, vitalData?.displayUnit, vitalData?.allowedDecimalPlaces || 0);
      value = `${convertedValue}`;
      unit = vitalData?.displayUnit;
    }
    let recordedDate = '';

    const documentDataData = record?.extension?.find?.((ext: any) => ext?.url === EXTENSION_URLS.documentDate);
    const documentData = documentDataData?.valueString || '';
    if (documentData) {
      recordedDate = getMomentObj(documentData).format(
        DISPLAY_DATE_FORMAT
      );
    } else if (record.effectiveDateTime) {
      recordedDate = getMomentObj(record.effectiveDateTime).format(
        DISPLAY_DATE_FORMAT
      );
    } else if (record.issued) {
      recordedDate = getMomentObj(record.issued).format(
        DISPLAY_DATE_FORMAT
      );
    }
    if (value) {
      let text = isGraph ? parseFloat(`${value}`) : `Recorded ${value}`;
      if(isGraph){
        return text
      }
      if (unit) {
        text += ` ${unit}`;
      }
      if (recordedDate) {
        text += `\non ${recordedDate}`;
      }
      if (returnSeparateComponents) {
        return {
          value: unit ? `${value} ${unit}` : value,
          valueText: value,
          unit: unit,
          recordedDate,
        };
      }
      return text;
    }
}

const formatHeightValue = (feet: number, inches: number) => {
  let convertedInches = 0
  if(inches){
    convertedInches = inches / 12
    return (feet + convertedInches).toFixed(2)
  }
  return feet
}

export const getMaxAllowedDecimals = (
  vital: Vital,
  vitalData: IVitalData[]
): number => {
  const vitalConfig = getVitalConfig(vital, vitalData);
  return vitalConfig?.allowedDecimalPlaces || 0;
};

export const setVitalValuePrecision = (
  record: IObservation,
  vitalData: IVitalData[]
) => {
  if (record.code?.coding?.length && record.code.coding[0].code) {
    const vitalConfig = getVitalConfig(
      record.code.coding[0].code as Vital,
      vitalData
    );
    const isNumericInput = vitalConfig?.inputType !== VitalInputType.choice && vitalConfig?.inputType !== VitalInputType.date;

    if (record.component?.length) {
      const systolicData = record.component?.find(
        (item) =>
          item.code?.coding?.length &&
          item.code.coding[0].code === SYSTOLIC_BLOOD_PRESSURE_LOINC
      );
      const diastolicData = record.component?.find(
        (item) =>
          item.code?.coding?.length &&
          item.code.coding[0].code === DIASTOLIC_BLOOD_PRESSURE_LOINC
      );
      if (systolicData?.valueQuantity?.value && isNumericInput) {
        systolicData.valueQuantity.value = updateValueToPrecision(
          systolicData.valueQuantity.value,
          vitalConfig?.allowedDecimalPlaces || 0
        );
      }
      if (diastolicData?.valueQuantity?.value && isNumericInput) {
        diastolicData.valueQuantity.value = updateValueToPrecision(
          diastolicData.valueQuantity.value,
          vitalConfig?.allowedDecimalPlaces || 0
        );
      }
    } else if (record.valueQuantity?.value && isNumericInput) {
      record.valueQuantity.value = updateValueToPrecision(
        record.valueQuantity?.value,
        vitalConfig?.allowedDecimalPlaces || 0
      );
    } else if (record.valueString && vitalConfig && isNumericInput) {
      record.valueString = updateValueToPrecision(
        record.valueString,
        vitalConfig?.allowedDecimalPlaces || 0
      );
    }

  }
};

export const updateValueToPrecision = (
  value: string | number,
  decimal: number
): string => {
  let strValue = `${value}`;
  if (!isNaN(parseFloat(strValue))) {
    // parse string to float then apply decimal precision
    strValue = parseFloat(strValue).toFixed(decimal);
    // reapply parseFloat to remove unnecessary trailing zeroes added due to toFixed method
    return `${parseFloat(strValue)}`;
  }
  return strValue;
};


export const getLastActivityRecord = (activityData:any) => {
  const response:{type: string; data: any}[] = [];
  activityData.forEach((activity:{
    type:string,
    entry:any[];
  }) => {
    const data = activity.entry.sort((a, b) =>
      b?.timestamp < a?.timestamp ? -1 : b?.timestamp > a?.timestamp ? 1 : 0
    );
    response.push({
      type:activity.type,
      data:data?.[0]
    })
  })
  return response;
}


export const covertValueQuantity = (
  vitalConfig: IVitalData,
  observation: Observation
) => {
  const observationUnit = observation.valueQuantity?.code || observation.valueQuantity?.unit;
  if (
    observationUnit && vitalConfig.unit &&
    observationUnit !== vitalConfig.unit
  ) {
    const value = convertVitalValues(observation.valueQuantity?.value, observationUnit, vitalConfig.unit, vitalConfig.allowedDecimalPlaces || 2);
    return {
      value: value,
      unit: vitalConfig.unit
    }
  }

  return {
    value: observation.valueQuantity?.value,
    unit: vitalConfig.unit
  };
};

export const formatReconciliationObservationData = (
  resource: any,
  metaData: IFormatReconciliationMetaData
): IReconciliationFormattedData | undefined => {
  const vitalResource: Observation = resource;
  const loincCodeObj = vitalResource?.code?.coding?.filter(
    (codeObj) => codeObj.system === FHIR_CODE_SYSTEMS.LOINC
  )?.[0];
  const enabledVital = metaData.enabledVitalList.find(
    (enabledVitalObj) => enabledVitalObj.loinc === loincCodeObj?.code && !enabledVitalObj.isHidden
  );

  if (enabledVital) {
    resource.valueQuantity = covertValueQuantity(enabledVital, resource);
    resource.code.coding = [
      {
        system: FHIR_CODE_SYSTEMS.LOINC,
        code: enabledVital.loinc,
        display: enabledVital.display,
      },
    ];

    const formattedVitalValue = getFormattedVitalValue(
      {...vitalResource, loinc: enabledVital.loinc},
      enabledVital,
      true
    ) as {
      valueText: string;
      unit?: string;
      recordedDate: string;
      value: string;
    };
    const title = getDisplay(
      enabledVital?.loinc as Vital,
      metaData.enabledVitalList
    );
    const titleWithValue = `${title} : ${
      formattedVitalValue.value ? formattedVitalValue.value : '-'
    }`;

    const display = {
      title: titleWithValue,
      date:
        vitalResource.effectiveDateTime || vitalResource.effectivePeriod?.start,
    };

    return {
      display,
      resourceData: resource,
    };
  }
  return undefined;
};

const getReconciliationFhirTemplate = (resource: Observation, metaData: IFormatReconciliationMetaData) => {

  let dateObj;
  if(resource.effectiveDateTime){
    if(metaData.ehrConfig?.isAthena){
      dateObj = {
        issued: resource.effectiveDateTime,
      }
    }
    else {
      dateObj = {
        effectiveDateTime: resource.effectiveDateTime,
      }
    }
  }

  return {
    resourceType: 'Observation',
    subject: {
      reference: `Patient/${metaData.patientId}`,
    },
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'Vital Signs',
          },
        ],
      },
    ],
    code: resource.code,
    ...(dateObj && dateObj),
    ...(resource.valueQuantity && {
      valueQuantity: resource.valueQuantity
    }),
    ...(resource.valueString && {
      valueString: resource.valueString
    }),
    ...(resource.component && {
      component: resource.component
    })
  };
}


export const formatHieObservationRequestObj = (resources: any, metaData: any, isMultiple: boolean) => {

  let formattedResources = [];
  if (isMultiple) {
    formattedResources = resources.map((resource: any) => {
      return {resource: getReconciliationFhirTemplate(resource, metaData)};
    });
  } else {
    formattedResources = [{resource: getReconciliationFhirTemplate(resources, metaData)}];
  }

  return {
    resourceType: 'Bundle',
    type: 'searchset',
    total: formattedResources.length,
    entry: formattedResources,
  };
};

