import {
  IContact,
  IContactProfile,
} from '../RightSideContainer/TeamInbox/Conversations/interfaces';

export interface PersonOmniViewState {
  formattedContactData: any;
  contactData: IContact;
  ehrPatientId: string;
}

export interface PersonOmniViewPayload {
  formattedContactData?: any;
  contactData?: IContact;
  selectedProfile?: IContactProfile;
  ehrPatientId?: string;
}

export interface PersonOmniViewAction {
  type: string;
  payload: PersonOmniViewPayload;
}

export enum PersonOmniViewActionType {
  SET_CONTACT_DATA = 'SET_CONTACT_DATA',
  UPDATE_CONTACT_DATA = 'UPDATE_CONTACT_DATA',
}

export const personOmniViewReducer = (
  state: PersonOmniViewState,
  action: PersonOmniViewAction
): PersonOmniViewState => {
  switch (action.type) {
    case PersonOmniViewActionType.SET_CONTACT_DATA:
      return {
        ...state,
        ...(action.payload.formattedContactData && {
          formattedContactData: action.payload.formattedContactData,
        }),
        ...(action.payload.contactData && {
          contactData: action.payload.contactData,
        }),
        ...(action.payload.ehrPatientId && {
          ehrPatientId: action.payload.ehrPatientId,
        }),
      };
    case PersonOmniViewActionType.UPDATE_CONTACT_DATA:
      return {
        ...state,
        ...(action.payload.formattedContactData && {
          formattedContactData: action.payload.formattedContactData,
        }),
        ...(action.payload.contactData && {
          contactData: action.payload.contactData,
        }),
      };
    default:
      return state;
  }
};
