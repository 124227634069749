import React from "react";
import { getAllowedUserAccountLocationUuids, getBooleanFeatureFlag } from "../../../utils/commonUtils";
import { CommonDataContext } from "../../../context/CommonDataContext";
import FeatureFlags from "../../../constants/FeatureFlags.enums";
import { isAccountConfigEnabled } from "../../../utils/configUtils";
import { CONFIG_CODES } from "../../../constants/AccountConfigConst";
import { USER_ACCESS_PERMISSION } from "../../RightSideContainer/UserAccess/UserAccessPermission";
import { usePersonOmniViewContext } from "../../PersonOmniView/PersonOmniView.context";
import { checkLocationAccess, getActivePatientProfile } from "./commonUtils";

const useLocationAccess = (props: {
  locationUuids: string[],
  usePatientLocationsFromOminiContext?: boolean,
  useCustomAllowedLocations?: string[],
  userAccessMainMenuCode?: string
}) => {
  const { locationUuids, usePatientLocationsFromOminiContext, useCustomAllowedLocations, userAccessMainMenuCode } = props;
  const commonContextData = React.useContext(CommonDataContext);
  let allowedLocations: string[] = [];
  if (useCustomAllowedLocations && useCustomAllowedLocations.length > 0) {
    allowedLocations = useCustomAllowedLocations;
  } else if (usePatientLocationsFromOminiContext) {
    const {data: contactData} = usePersonOmniViewContext();
    const activePatientProfile = getActivePatientProfile(contactData?.contactProfiles || []);
    allowedLocations = [activePatientProfile?.accountLocationUuid || ""];
  } else {
    allowedLocations = getAllowedUserAccountLocationUuids(
      USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
      userAccessMainMenuCode || ""
    );
  }
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonContextData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const haveAccessToLocation = checkLocationAccess({
    locationUuids,
    isMultiTenancyEnabled,
    allowedLocationUuids: allowedLocations
  });

  return {
    haveAccessToLocation,
    isMultiTenancyEnabled
  };
}

export default useLocationAccess;