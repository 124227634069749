import { DatePicker, Drawer, Select, Tooltip } from 'antd';
import { HStack, Icon, Pressable, Text, VStack, View } from 'native-base';
import { useEffect, useState } from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { useSearchParams } from 'react-router-dom';
import { BUTTON_TYPE, DATE_FORMATS } from '../../../../../constants';
import { Colors } from '../../../../../styles';
import { getDateStrFromFormat, getMomentObj } from '../../../../../utils/DateUtils';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import GetContactPhotoAndNameByContactId from '../WorkflowTableView/Helper/GetContactPhotoAndNameByContactId';
import Feather from 'react-native-vector-icons/Feather';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';
import { getAccountLocationsWithEhrCode } from '../../../../../services/CommonService/IntegrationService';
import { ILocationObject } from './WorkflowTriggerView/interfaces';
import { reactStyles, styles } from '../../workflowStyles';




const WorkflowAuditFilter = (props: {setSearchString?:any, isShowSmsStatus?:boolean, isAddUniqueEmailStatusFilter?:boolean,isShowEmailStatus?:boolean,isDateMandatory?:boolean,defaultDayRange?:number,contactList: any , isShowLocationFilter?: boolean, intersectedLocationList?: string[], ehrCode?: string, contactId?: any}) => {
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED) && false;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenModel, setOpenModal] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const getDefaultDateRange = (fromDate:any, toDate:any)=>{
    let fromDateDefault = fromDate;
    let toDateDefault = toDate;
    if(props.defaultDayRange != undefined){
      if(!fromDateDefault){
          if(toDateDefault){
            const date = new Date(toDateDefault);
            date.setDate(date.getDate() - props.defaultDayRange);
            fromDateDefault = date.toISOString();
          } else {
            const date = new Date();
            date.setDate(date.getDate() - props.defaultDayRange);
            fromDateDefault = date.toISOString();
          }
      }
      if(!toDateDefault){
        const date = new Date(fromDateDefault);
        date.setDate(date.getDate() + props.defaultDayRange);
        toDateDefault = date.toISOString();
      }

    }
    return  {
      fromDate:fromDateDefault, toDate:toDateDefault
    }
  }
  const defaultDateRange = getDefaultDateRange(searchParams.get('fromDate'), searchParams.get('toDate') )
  const [selectedLocations, setSelectedLocations] = useState<any>(searchParams.get('auditFilterLocationIds') || undefined)
  const [contactId, setContactId] = useState(searchParams.get('contactId') || props?.contactId);
  const [emailStatus, setEmailStatus] =  useState(searchParams.get('emailStatus'));
  const [smsStatus, setSmsStatus] =  useState(searchParams.get('smsStatus'));
  const [fromDate, setFromDate] = useState(searchParams.get('fromDate') ? searchParams.get('fromDate') : defaultDateRange.fromDate);
  const [toDate, setToDate] = useState(searchParams.get('toDate') ? searchParams.get('toDate') : defaultDateRange.toDate)
  const [fromDateTemp, setFromDateTemp] = useState(fromDate);
  const [toDateTemp, setToDateTemp] = useState(toDate)
  const emailStatusList = [{display:'Clicked', code:'clicked'},{display:'Opened', code:'opened'},{display:'Delivered', code:'delivered'},
  {display:'Failed', code:'failed'},{display:'Sent', code:'sent'}, {display:'Unsubscribed', code:'unsubscribed'}]
  if(props.isAddUniqueEmailStatusFilter) {
    emailStatusList.push({display:'Unique Clicked', code:'uniqueClicked'})
    emailStatusList.push({display:'Unique Opened', code:'uniqueOpened'})
  }
  const smsStatusList = [{display:'Undelivered', code:'undelivered'},{display:'Sent', code:'sent'},{display:'Failed', code:'failed'},{display:'Delivered', code:'delivered'},]

  const [locationOptions, setLocationOptionList] = useState([])

  useEffect(() => {
    if (isMsoEnabled) {
      const fetchData = async () => {
        getAccountLocationsWithEhrCode()
          .then((accountLocations: any) => {
            const filteredLocations: any = []
            if (accountLocations?.length) {
              const locations = accountLocations?.map((location: ILocationObject) => ({
                uuid: location?.uuid,
                name: location?.practiceLocation?.name,
                ehrCode: location?.ehrInfo?.ehrCode
              }));
              locations.forEach((location: any) => {
                if (props?.intersectedLocationList?.includes(location?.uuid)) {
                  filteredLocations.push(location)
                }
              })
              setLocationOptionList(filteredLocations)
            }
          })
      }
      fetchData()
    }
  }, [])

  useEffect(()=>{
    if(props.isDateMandatory &&(!fromDate || !toDate)){
      return
    }
    if (fromDate) {
      searchParams.set('fromDate', fromDate);
    } else {
      searchParams.delete('fromDate');
    }
    if (toDate) {
      searchParams.set('toDate', toDate);
    } else {
      searchParams.delete('toDate');
    }
    setSearchParams(searchParams);
  }, [ fromDate, toDate])

  let filterCount = 0;
  if (contactId) {
    filterCount = filterCount + 1;
  }
  if (fromDate) {
    filterCount = filterCount + 1;
  }
  if (toDate) {
    filterCount = filterCount + 1;
  }
  if(emailStatus && searchParams.get('emailStatus')){
    filterCount = filterCount + 1;
  }
  if(smsStatus && searchParams.get('smsStatus')){
    filterCount = filterCount + 1;
  }
  if( selectedLocations && searchParams.get('auditFilterLocationIds')){
    filterCount = filterCount + 1;
  }

  const onSubmit = () => {
    if(props.isDateMandatory && (!fromDateTemp || !toDateTemp) ){
      setIsShowError(true);
      return
    }
    const defaultDateRange = getDefaultDateRange(fromDateTemp, toDateTemp)
    setIsShowError(false);
    setFromDate(defaultDateRange.fromDate)
    setToDate(defaultDateRange.toDate)
    if (defaultDateRange.fromDate) {
      searchParams.set('fromDate', defaultDateRange.fromDate);
    } else {
      searchParams.delete('fromDate');
    }
    if (defaultDateRange.toDate) {
      searchParams.set('toDate', defaultDateRange.toDate);
    } else {
      searchParams.delete('toDate');
    }
    if (contactId) {
      searchParams.set('contactId', contactId);
    } else {
      searchParams.delete('contactId');
    }
    if (smsStatus) {
      searchParams.set('smsStatus', smsStatus);
    } else {
      searchParams.delete('smsStatus');
    }
    if (emailStatus) {
      searchParams.set('emailStatus', emailStatus);
    } else {
      searchParams.delete('emailStatus');
    }
    if (selectedLocations) {
      searchParams.set('auditFilterLocationIds', selectedLocations.toString());
    } else {
      searchParams.delete('auditFilterLocationIds')
    }
    setSearchParams(searchParams);
    setOpenModal(false);
  }

  const selectedFilter: any = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 2,
    paddingRight: 15,
    paddingBottom: 2,
    paddingLeft: 15,
    backgroundColor: '#F2F4F7',
    borderRadius: 16,
  }

  const handleChange = (value: any) => {
    setSelectedLocations(value);
  };

  return (
    <HStack flex={1}>
      <HStack flex={9} justifyContent={'flex-start'}>
        {contactId && <View style={selectedFilter} marginLeft={5}>
          <HStack space={2} justifyContent="space-between">
            <Text>Member: <GetContactPhotoAndNameByContactId isHideIcon={true} contactId={parseInt(contactId)}></GetContactPhotoAndNameByContactId></Text>
            <Pressable
              onPress={() => {

                setSearchParams((searchParams) => {
                  searchParams.delete('contactId');
                  return searchParams;
                });
                setContactId(null)

              }}
              style={styles.marginTop4}
            >
              <Icon
                name="close"
                as={AntIcon}
                size="3"
                width={10}
                height={10}
              // color={Colors.secondary['800']}
              />
            </Pressable>
          </HStack>
        </View>}
        <HStack >

        <Pressable
          onPress={() => {
            setOpenModal(true);
          }}
          marginTop={0}
          marginLeft={4}
        >
          <HStack
            space={1}
            paddingX={filterCount ? 2 : 2.5}
            paddingY={2.5}
            borderRadius={'50%'}
            backgroundColor={Colors.FoldPixel.TRANSPARENT}
            alignItems={'center'}
          >
            <Tooltip title="Filter">
              <Feather
                name="filter"
                size={15}
                color={Colors.Custom.Gray600}
              />
            </Tooltip>
            {filterCount ? (
              <Text color={Colors.secondary['800']}>
                {filterCount ? filterCount : ''}
              </Text>
            ) : null}
          </HStack>
        </Pressable>
        { <Drawer
          open={isOpenModel}
          width={400}
          onClose={() => {
            setFromDateTemp(fromDate);
            setToDateTemp(toDate);
            setOpenModal(false);
          }}
          closable={true}
          title={
            <>
              <ModalActionTitle
                title={'filters'}
                titleColor={''}
                onActionClick={(code: string) => {
                  if (code === 'cancel'){ 
                    setFromDateTemp(fromDate);
                    setToDateTemp(toDate);
                    setOpenModal(false);
                  }
                }}
                // actionButtons={[{code: 'cancel', name: 'Cancel'}]}
                buttonList={[
                  {
                    show: true,
                    id: 1,
                    btnText: 'close',
                    textColor: Colors.Custom.mainSecondaryBrown,
                    variant: BUTTON_TYPE.SECONDARY,
                    isTransBtn: false,
                    onClick: () => {
                      setFromDateTemp(fromDate);
                      setToDateTemp(toDate);
                      setOpenModal(false)
                    },
                  },
                  {
                    show: true,
                    id: 2,
                    btnText: 'Apply',
                    textColor: Colors.Custom.mainPrimaryPurple,
                    variant: BUTTON_TYPE.PRIMARY,
                    isTransBtn: false,
                    onClick: () => {
                      onSubmit();
                    },
                  },
                ]}
              />
            </>
          }
        >
          <VStack height={2}>
            { (!!props.contactList?.length || props.setSearchString ) && <VStack marginBottom={5} >
              <HStack>
                <Text marginBottom={2} fontWeight={300} paddingRight={5} fontSize={16} flex={2} >
                  {'Filter By Member:'}
                </Text>
              </HStack>
              <HStack flex={1}>

                <Select
                  size='large'
                  showSearch
                  allowClear
                  filterOption={false}
                  value={contactId}
                  onSearch={props.setSearchString}
                  onChange={(value: any, data: any) => {
                    if (value) {
                      setContactId(value)

                    } else {
                      setContactId('')
                    }
                  }}
                  placeholder="Filter by Member "
                  style={reactStyles.fullWidthHeight36}

                >
                  {props.contactList.map((contact: any, index: number) => {
                    return (
                      <Select.Option key={`${contact.id}_${index}`} name={contact.name} value={contact.id}>
                        {contact.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </HStack>
            </VStack>}
            
            

            <VStack marginBottom={5}>
              <Text marginBottom={2} fontWeight={300} paddingRight={5} fontSize={16} flex={2} marginTop={1}>
                From Date:
              </Text>
              <DatePicker
                style={{
                  borderColor: isShowError && !fromDateTemp ? Colors.Custom.ErrorColor : Colors.Custom.Gray300,
                  borderRadius: 8,
                  fontWeight: 400,
                  fontSize: 16,
                }}
                value={fromDateTemp ? getMomentObj(fromDateTemp) : undefined}
                format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                onChange={(dates: any, formatStrings: string) => {
                  if (formatStrings && formatStrings.length > 1) {
                    setFromDateTemp(dates.startOf('day').toISOString());
                  } else {
                    setFromDateTemp(undefined);
                  }
                }}
              />
            </VStack>

            <VStack marginBottom={5}>
              <Text marginBottom={2} fontWeight={300} paddingRight={5} fontSize={16} flex={2} marginTop={1}>
                To Date:
              </Text>
              <DatePicker
                style={{
                  borderColor: isShowError && !toDateTemp ? Colors.Custom.ErrorColor : Colors.Custom.Gray300,
                  borderRadius: 8,
                  fontWeight: 400,
                  fontSize: 16,
                }}
                status={isShowError && !toDateTemp ? 'error' : ''}
                value={toDateTemp ? getMomentObj(toDateTemp) : undefined}
                format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
                onChange={(dates: any, formatStrings: string) => {
                  if (formatStrings && formatStrings.length > 1) {
                    setToDateTemp(dates.endOf('day').toISOString());
                  } else {
                    setToDateTemp(undefined);
                  }
                }}
              />
            </VStack>

            <VStack marginBottom={5}>
            { props?.isShowEmailStatus && <VStack marginBottom={5} >
              <HStack>
                <Text marginBottom={2} fontWeight={300} paddingRight={5} fontSize={16} flex={2} >
                  {'Email Status:'}
                </Text>
              </HStack>
              <HStack flex={1}>

                <Select
                  size='large'
                  showSearch
                  allowClear
                  filterOption={(input, option) => {
                    return (
                      (option?.display?.toLowerCase() || '').indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={emailStatus}
                  onChange={(code: any, data: any) => {
                    if (code) {
                      setEmailStatus(code)

                    } else {
                      setEmailStatus(null)
                    }
                  }}
                  placeholder="Filter by Email Status"
                  style={reactStyles.fullWidthHeight36}

                >
                  {emailStatusList.map((emailStatusData: any, index: number) => {
                    return (
                      <Select.Option key={`${emailStatusData.code}_${index}`} display={emailStatusData.display} value={emailStatusData.code}>
                        {emailStatusData.display}
                      </Select.Option>
                    );
                  })}
                </Select>
              </HStack>
            </VStack>}
            </VStack>
            <VStack marginBottom={5}>
            { props?.isShowSmsStatus && <VStack marginBottom={5} >
              <HStack>
                <Text marginBottom={2} fontWeight={300} paddingRight={5} fontSize={16} flex={2} >
                  {'SMS Status:'}
                </Text>
              </HStack>
              <HStack flex={1}>

                <Select
                  size='large'
                  showSearch
                  allowClear
                  filterOption={(input, option) => {
                    return (
                      (option?.display?.toLowerCase() || '').indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  value={smsStatus}
                  onChange={(code: any, data: any) => {
                    if (code) {
                      setSmsStatus(code)

                    } else {
                      setSmsStatus('')
                    }
                  }}
                  placeholder="Filter by SMS Status"
                  style={reactStyles.fullWidthHeight36}

                >
                  {smsStatusList.map((emailStatusData: any, index: number) => {
                    return (
                      <Select.Option key={`${emailStatusData.code}_${index}`} display={emailStatusData.display} value={emailStatusData.code}>
                        {emailStatusData.display}
                      </Select.Option>
                    );
                  })}
                </Select>
              </HStack>
            </VStack>}
            </VStack>

            <VStack>
              {
                isMsoEnabled &&
                <VStack>
                  <Text marginBottom={2} fontWeight={300} paddingRight={5} fontSize={16} flex={2}>Select Locations:</Text>
                  <Select
                    allowClear
                    mode='multiple'
                    value={selectedLocations}
                    placeholder='Select locations'
                    onChange={handleChange}
                    filterOption={(input, option: any) => {
                      return (
                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {locationOptions?.map((location: any) => (
                      <Select.Option key={location?.uuid} value={location?.uuid}>
                        {location?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </VStack>
              }
            </VStack>
          </VStack>

          

        </Drawer>}
      </HStack>

        {fromDate && <View style={selectedFilter} marginLeft={5}>
          <HStack space={2} justifyContent="space-between">
            <Text>From Date: {getDateStrFromFormat(
              fromDate,
              `${DATE_FORMATS.DISPLAY_DATE_FORMAT}`
            )}</Text>
            <Pressable
              onPress={() => {

                setSearchParams((searchParams) => {
                  searchParams.delete('fromDate');
                  return searchParams;
                });
                setFromDate(undefined)

              }}
              style={styles.marginTop4}
            >
             {!props.isDateMandatory && <Icon
                name="close"
                as={AntIcon}
                size="3"
                width={10}
                height={10}
              // color={Colors.secondary['800']}
              />}
            </Pressable>
          </HStack>
        </View>}
        {toDate && <View style={selectedFilter} marginLeft={5}>
          <HStack space={2} justifyContent="space-between">
            <Text>To Date: {getDateStrFromFormat(
              toDate,
              `${DATE_FORMATS.DISPLAY_DATE_FORMAT}`
            )}</Text>
            <Pressable
              onPress={() => {

                setSearchParams((searchParams) => {
                  searchParams.delete('toDate');
                  return searchParams;
                });
                setToDate(undefined);
              }}
              style={styles.marginTop4}
            >
              {!props.isDateMandatory && <Icon
                name="close"
                as={AntIcon}
                size="3"
                width={10}
                height={10}
              // color={Colors.secondary['800']}
              />}
            </Pressable>
          </HStack>
        </View>}
      </HStack>

      

    </HStack>

  )
}

export default WorkflowAuditFilter;
