import { Component } from "../BaseComponents/BaseComponentInterface";
import { ConditionalFormElement } from "../BaseComponents/ConditionalFields";
import AllergiesFieldFormElement from "../Components/AllergiesField";
import { CheckboxFieldFormElement } from "../Components/CheckboxField";
import { ChiefComplaintFieldFormElement } from "../Components/ChiefComplaintField";
import { ColorPickerFieldFormElement } from "../Components/ColorPickerField";
import { ColumnFieldFormElement } from "../Components/ColumnField";
import { ContainerFieldFormElement } from "../Components/ContainerField";
import { CurrencyFieldFormElement } from "../Components/CurrencyField";
import { CustomSelectFieldFormElement } from "../Components/CustomSelectField";
import { DataGridFieldFormElement } from "../Components/DataGridField";
import { DateFieldFormElement } from "../Components/DateField";
import { EmailFieldFormElement } from "../Components/EmailField";
import EmergencyContactFieldFormElement from "../Components/EmergencyContactField";
import { FileFieldFormElement } from "../Components/FileField";
import { ImageFieldFormElement } from "../Components/ImageField";
import MedicationFieldFormElement from "../Components/MedicationField";
import { MultiSelectCheckBoxesFieldFormElement } from "../Components/MultiSelectCheckBoxesField";
import { NumberFieldFormElement } from "../Components/NumberField";
import { ParagraphFieldFormElement } from "../Components/ParagraphField";
import { PatientAddressFieldFormElement } from "../Components/PatientAddressField";
import { PatientCommunicationFieldFormElement } from "../Components/PatientCommunicationField";
import { PatientDemographicsFieldFormElement } from "../Components/PatientDemographicsField";
import { PhoneNumberFieldFormElement } from "../Components/PhoneNumberField";
import { RadioFieldFormElement } from "../Components/RadioField";
import { RatingFieldFormElement } from "../Components/RatingField";
import { SelectBoxesFieldFormElement } from "../Components/SelectBoxesField";
import { SelectFieldFormElement } from "../Components/SelectField";
import { SignatureFieldFormElement } from "../Components/SignatureField";
import { TableFieldFormElement } from "../Components/TableField";
import { TextAreaFormElement } from "../Components/TextArea";
import { TextFieldFormElement } from "../Components/TextField";
import VitalsFieldFormElement from "../Components/VitalsField";
import ConditionFieldFormElement from "../Components/ConditionField";
import ImmunizationFieldFormElement from "../Components/ImmunizationField";
import SocialHistoryFieldFormElement from "../Components/SocialHistoryField";
import FamilyHistoryFieldFormElement from "../Components/FamilyHistoryField";
import SurgicalHistoryFieldFormElement from "../Components/SurgicalHistoryField";
import DiagnosisFieldFormElement from "../Components/DiagnosisField";
import PastMedicalHistoryFieldFormElement from "../Components/PastMedicalHistoryField";
import SubjectiveComplaintFieldFormElement from "../Components/SubjectiveComplaintField";
import ObjectiveFindingsFieldFormElement from "../Components/ObjectiveFindingsField";
import AssessmentFieldFormElement from "../Components/AssessmentField";
import PlanFieldFormElement from "../Components/PlanField";
import { InterventionFieldFormElement } from "../Components/InterventionField";
import ConsentAndPreferredCommunicationFieldFormElement from "../Components/ConsentAndPreferredCommunicationField";
import BarriersFieldFormElement from "../Components/BarriersField";
import GoalsFieldFormElement from "../Components/GoalsField";
import NoteStatusFieldFormElement from "../Components/NoteStatusField";
import NoteSubStatusFieldFormElement from "../Components/NoteSubStatusField";
import DischargeDetailFieldFormElement from "../Components/DischargeDetailField";
import CareProgramAppointmentFieldFormElement from "../Components/CareProgramAppointmentField";
import { ClinicalParametersFieldFormElement } from "../Components/ClinicalParametersField";
import AwvHistoryFieldFormElement from "../Components/AwvHistoryField";
import PatientInformationVerificationFieldFormElement from "../Components/PatientInformationVerificationField";
import PatientIdentifierFieldFormElement from "../Components/PatientIdentifierField";
import HomeMonitoringViewFieldFormElement from "../Components/HomeMonitoringViewField";

export type ElementsType =
| 'oldtextfield'
| 'textfield'
| 'textarea'
| 'email'
| 'checkbox'
| 'radio'
| 'selectboxes'
| 'select'
| 'phoneNumber'
| 'date'
| 'datetime'
| 'paragraph'
| 'image'
| 'number'
| 'currency'
| 'file'
| 'rating'
| 'signature'
| 'customsurvey'
| 'customTable'
| 'columns'
| 'panel'
| 'chiefComplaint'
| 'patientDemographics'
| 'patientCommunication'
| 'patientAddress'
| 'emergencyContact'
| 'vitals'
| 'medications'
| 'allergies'
| 'conditions'
| 'immunizations'
| 'socialHistory'
| 'familyHistory'
| 'surgicalHistory'
| 'subjectiveComplaint'
| 'objectiveFindings'
| 'assessment'
| 'plan'
| 'diagnosis'
| 'pastMedicalHistory'
| 'consentAndPreferredCommunication'
| 'noteStatus'
| 'noteSubStatus'
| 'barriers'
| 'goals'
| 'intervention'
| 'dischargeDetails'
| 'careProgramAppointments'
| 'awvHistory'
| 'clinicalParameters'
| 'patientInformationVerification'
| 'patientIdentifier'
| 'conditional'
| 'datagrid'
| 'colorPicker'
| 'customSelect'
| 'checkBoxes'
| 'homeMonitoringView'

export type SubmitFunction = (key: string, value: any) => void;

export type FormElement = {
  type: ElementsType;
  construct: (id: string, map: Map<string, boolean>) => FormElementInstance;
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => FormElementInstance;
  designerBtnElement: {
    icon?: React.ElementType;
    label: string;
  };
  designerComponent: React.FC<{ elementInstance: FormElementInstance }>;
  formComponent: React.FC<{
    elementInstance: FormElementInstance;
    submitValue?: SubmitFunction;
    isInvalid?: boolean;
    defaultValue?: any;
    isReadOnly?: boolean;
    onRegisterValidation: (key: string, validate: ValidationFunction) => void;
  }>;
  propertiesComponent: React.FC<{ elementInstance: FormElementInstance }>;
};

export type FormElementInstance = Component;

type FormElementsType = {
  [key in ElementsType]: FormElement;
};

export enum ElementSource {
  sidebar = 'sidebar',
  form = 'form',
}

export interface ValidationResult {
  isValid: boolean;
  errorMessage: string;
  key: string;
  fieldValue?: any;
}

export interface ValidationFunction {
  (value: any, silentCheck?: boolean): Promise<ValidationResult>;
}

export interface BatchValidationResult {
  isValid: boolean;
  results: ValidationResult[];
  errors: Record<string, string>;
}

export const FormElements: FormElementsType = {
  // Basic components
  oldtextfield: TextFieldFormElement,
  textfield: TextFieldFormElement,
  textarea: TextAreaFormElement,
  email: EmailFieldFormElement,
  checkbox: CheckboxFieldFormElement,
  radio: RadioFieldFormElement,
  datagrid: DataGridFieldFormElement,
  selectboxes: SelectBoxesFieldFormElement,
  select: SelectFieldFormElement,
  phoneNumber: PhoneNumberFieldFormElement,
  date: DateFieldFormElement,
  datetime: DateFieldFormElement,
  paragraph: ParagraphFieldFormElement,
  image: ImageFieldFormElement,
  number: NumberFieldFormElement,
  currency: CurrencyFieldFormElement,
  file: FileFieldFormElement,
  colorPicker: ColorPickerFieldFormElement,
  rating: RatingFieldFormElement,
  customSelect: CustomSelectFieldFormElement,
  signature: SignatureFieldFormElement,
  customsurvey: TableFieldFormElement,
  customTable: TextFieldFormElement,
  columns: ColumnFieldFormElement,
  panel: ContainerFieldFormElement,
  checkBoxes: MultiSelectCheckBoxesFieldFormElement,
  conditional: ConditionalFormElement,

  // Health Components
  chiefComplaint: ChiefComplaintFieldFormElement,
  patientDemographics: PatientDemographicsFieldFormElement,
  patientCommunication: PatientCommunicationFieldFormElement,
  patientAddress: PatientAddressFieldFormElement,
  emergencyContact: EmergencyContactFieldFormElement,
  vitals: VitalsFieldFormElement,
  medications: MedicationFieldFormElement ,
  allergies: AllergiesFieldFormElement,
  conditions: ConditionFieldFormElement,
  immunizations: ImmunizationFieldFormElement,
  socialHistory: SocialHistoryFieldFormElement,
  familyHistory: FamilyHistoryFieldFormElement,
  surgicalHistory: SurgicalHistoryFieldFormElement,
  pastMedicalHistory: PastMedicalHistoryFieldFormElement,
  diagnosis: DiagnosisFieldFormElement,
  subjectiveComplaint: SubjectiveComplaintFieldFormElement,
  objectiveFindings: ObjectiveFindingsFieldFormElement,
  assessment: AssessmentFieldFormElement,
  plan: PlanFieldFormElement,
  consentAndPreferredCommunication: ConsentAndPreferredCommunicationFieldFormElement,
  barriers: BarriersFieldFormElement,
  goals: GoalsFieldFormElement,
  intervention: InterventionFieldFormElement,
  noteStatus: NoteStatusFieldFormElement,
  noteSubStatus: NoteSubStatusFieldFormElement,
  dischargeDetails: DischargeDetailFieldFormElement,
  careProgramAppointments: CareProgramAppointmentFieldFormElement,
  awvHistory: AwvHistoryFieldFormElement,
  clinicalParameters: ClinicalParametersFieldFormElement,
  patientInformationVerification: PatientInformationVerificationFieldFormElement,
  patientIdentifier: PatientIdentifierFieldFormElement,
  homeMonitoringView: HomeMonitoringViewFieldFormElement,
};
