import {InputNumber} from 'antd';
import React from 'react';

interface NumberFieldProps {
  min?: string;
  max?: string;
  defaultValue?: string;
  onChange: (value: string | null) => void;
  addonBefore?: string;
  addonAfter?: string;
  errorMessage?: string;
  placeholder?: string;
  formatter?: (value: string | undefined, info: {
    userTyping: boolean;
    input: string;
}) => string;
  parser?: (displayValue: string | undefined) => string;
  precision?: number;
  decimalSeparator?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  controls?: boolean;
  disabled?: boolean;
}

const InputNumberField = ({
  min,
  max,
  defaultValue,
  onChange,
  addonBefore,
  addonAfter,
  errorMessage,
  placeholder,
  formatter,
  parser,
  precision,
  decimalSeparator,
  onKeyDown,
  controls,
  disabled,
}: NumberFieldProps) => {
  return (
    <div className="flex flex-col gap-1 w-full">
      <InputNumber
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={onChange}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        status={errorMessage ? 'error' : undefined}
        className={errorMessage ? 'error-input-field' : 'form-input-field'}
        placeholder={placeholder}
        formatter={formatter}
        parser={parser}
        precision={precision}
        decimalSeparator={decimalSeparator}
        onKeyDown={onKeyDown}
        controls={controls}
        disabled={disabled}
      />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default React.memo(InputNumberField);
