import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { RatingSchema } from "../Schema/ComponentsSchema";
import { RatingComponent } from "../BaseComponents/BaseComponentInterface";
import Rating from "../../FHFormio/CustomComponents/Rating/Rating";
import { RATING_ELEMENTS, RatingType } from "../../FHFormio/CustomComponents/Rating/Helper";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import { v4 as uuidV4 } from 'uuid';
import RatingSvg from "../../../../../assets/Icons/FormBuilder/RatingSvg";

export const RatingFieldFormElement: FormElement = {
  type: RatingSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(RatingSchema),
    key: getUniqueKey(map, RatingSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(RatingSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: RatingSvg,
    label: RatingSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, value: string, silentCheck?: boolean): ValidationResult => {
  if (elementInstance.validate?.required && !value) {
    return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: value };
  }
  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isInitialMount = useRef(true);
  const validationRef = useRef<any>(undefined);
  const onChange = useCallback(async (value: string) => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const result = await validationRef.current?.(value, false);
    setErrorMessage(result.message);
    setValue(value);
  }, []);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={errorMessage}
      onChange={onChange}
      validateRef={validationRef}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState(defaultValue || selectedValue || elementInstance.defaultValue || '');
  const isMounted = useRef(true);
  const validationRef = useRef<any>(undefined);
  const isInitialMount = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      const result = await validationRef.current?.(valueToValidate || '', silentCheck);
      return {
        isValid: result.isValid ?? true,
        errorMessage: result.message,
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: string) => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={debounce(handleChange, 500)}
      validateRef={validationRef}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
  validateRef,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
  validateRef?: React.MutableRefObject<any>;
}) {
  const { label, validate, description, tooltip, allowToShare } = elementInstance as RatingComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      <Rating
        component={elementInstance}
        disabled={isReadOnly || false}
        options={{}}
        validateRef={validateRef}
        setValueRef={{}}
        value={defaultValue || ''}
        onChange={debounce(onChange, 500)}
      />
      {description && (
        <Description description={description} />
      )}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance as RatingComponent;
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    ...(isAllowShare ? ShareWithPatientFields : []),
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    {
      type: 'customSelect',
      input: true,
      label: 'Rating Elements',
      key: 'ratingType',
      dataSrc: 'values',
      defaultValue: RatingType.star,
      data: {
        values: RATING_ELEMENTS,
      },
    },
    {
      type: 'checkbox',
      key: 'showRatingValue',
      label: 'Show rating scale',
      input: true,
      defaultValue: true,
      customConditional: function customConditional(context: any) {
        return context?.data?.ratingType !== 'nps';
      },
      customVisibility: (element: Record<string, any>) => {
        return element?.ratingType !== 'nps';
      },
    },
    {
      type: 'select',
      input: true,
      label: 'Rating Scale',
      key: 'ratingScale',
      dataSrc: 'values',
      defaultValue: '10',
      clearOnHide: false,
      data: {
        values: [
          {label: '1', value: 1},
          {label: '2', value: 2},
          {label: '3', value: 3},
          {label: '4', value: 4},
          {label: '5', value: 5},
          {label: '6', value: 6},
          {label: '7', value: 7},
          {label: '8', value: 8},
          {label: '9', value: 9},
          {label: '10', value: 10},
        ],
      },
      customConditional: function customConditional(context: any) {
        return context?.data?.ratingType !== 'nps';
      },
      customVisibility: (element: Record<string, any>) => {
        return element?.ratingType !== 'nps';
      },
    },
    {
      type: 'select',
      input: true,
      label: 'Values',
      key: 'values',
      hidden: true,
      clearOnHide: false,
    },
    {
      type: 'checkbox',
      key: 'usePlainColor',
      label: 'Remove Colors',
      input: true,
      defaultValue: false,
      clearOnHide: false,
      customConditional: function customConditional(context: any) {
        return context?.data?.ratingType === 'nps';
      },
      customVisibility: (element: Record<string, any>) => {
        return element?.ratingType === 'nps';
      },
    },
    {
      type: 'colorPicker',
      key: 'fillColor',
      label: 'Fill Color',
      input: true,
      clearOnHide: false,
      customConditional: function customConditional(context: any) {
        if (context?.data?.ratingType === 'nps') {
          return context?.data?.usePlainColor;
        }
        return true;
      },
      customVisibility: (element: Record<string, any>) => {
        return element?.ratingType === 'nps' ? element?.usePlainColor : true;
      },
    },
    {
      type: 'oldtextfield',
      key: 'leftKey',
      label: 'Left Key',
      input: true,
      defaultValue: 'Not likely at all',
      clearOnHide: false,
      customConditional: function customConditional(context: any) {
        return context?.data?.ratingType === 'nps';
      },
      customVisibility: (element: Record<string, any>) => {
        return element?.ratingType === 'nps';
      },
    },
    {
      type: 'oldtextfield',
      key: 'rightKey',
      label: 'Right Key',
      input: true,
      clearOnHide: false,
      defaultValue: 'Extremely likely',
      customConditional: function customConditional(context: any) {
        return context?.data?.ratingType === 'nps';
      },
      customVisibility: (element: Record<string, any>) => {
        return element?.ratingType === 'nps';
      },
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
