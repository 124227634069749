import {HStack, Input, View, Text} from 'native-base';
import {IAddSubTask, ISubTasks} from '../interfaces';
import {Colors} from '../../../../styles/Colors';
import {Button, Checkbox, DatePicker, Modal, Popover, Select, Tooltip} from 'antd';
import CalendarSvg from '../../Svg/SideMenu/CalendarSvg';
import DeleteButtonSvg from '../../Svg/DeleteButtonSvg';
import AddUserSvg from '../../Svg/AddUserSvg';
import {ActivityIndicator, Pressable, StyleSheet} from 'react-native';
import {ADD_SUBTASK_CONST} from '../AddTaskUtils';
import {useContext, useEffect, useState} from 'react';
import './CustomDatePicker.css';
import {BUTTON_TYPE, DATE_FORMATS, GROUP_MEMBER_TYPE, TASK_TYPES} from '../../../../constants';
import {
  getCurrentTimeZone,
  getDateStrFromFormat,
  getDateToMomentISOString,
  getMomentObj,
  getMomentObjFromFormat,
  getStartOfDayOfTimezone,
} from '../../../../utils/DateUtils';
import UserAutoComplete from '../../CalendarWidget/UserAutoComplete/UserAutoComplete';
import {IUserSearch} from '../../../PersonOmniView/RightContainer/CareTeamView/interfaces';
import {IUserPatientSearchItem} from '../../CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native';
import PatientSearchAndSelect from '../../PatientSearch/PatientSearchAndSelect';
import {IAccountData} from '../../../RightSideContainer/Contacts/TeamMembers/EditAccount/interface';
import CalendarPlanSvg from '../../Svg/SideMenu/CalendarPlanSvg';
import UserSvg from '../../Svg/UserSvg';
import { FoldButton } from '../../../CommonComponents/FoldButton/FoldButton';
import { useIntl } from 'react-intl';
import { IStateForHandlingSubTaskAssigneeChange } from '../../CareDashboard/CareDashboardInterfaces';
import { DisplayCardAvatar } from '../../DisplayCard/DisplayCardAvatar';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import FeatureFlags from '../../../../constants/FeatureFlags.enums';
import { getBooleanFeatureFlag } from '../../../../utils/commonUtils';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import UserAddSvg from '../../../../assets/svg/UserAddSvg';
import PAMISearch, {PAMISearchType} from '../../PAMISearch/PAMISearch';
import {IFormCommonData} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import {IConversationChannelList} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/Interfaces';
import FoldPermitCan from '../../../CommonComponents/FoldPermitCan/FoldPermitCan';
import {USER_ACCESS_PERMISSION} from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import {QUICK_ACTION_CODES} from '../../../../constants/ActionConst';
import {testID} from '../../../../testUtils/Utils';
import {DISPLAY_CONVERSATION_SIDEBAR_ICON} from '../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationSidebarUtils';
import MenuHorizontalIcon from '../../Svg/MenuHorizontalIcon';
import {DIAGNOSIS_ACTION_CODES} from '../../../../constants/ActionConst';
import AddOrUpdateEvidenceView from './components/AddOrUpdateEvidenceView';
import { InputStyle } from '../../../RightSideContainer/Contacts/Leads/LeadView/LeadViewStyle';
import { TestIdentifiers } from '../../../../testUtils';

const AddSubTasks = (props: IAddSubTask) => {
  const {
    subTaskItem,
    isDisable,
    handleSubTaskChange,
    isEmployer,
    taskPoolData,
    accountDetails,
    value,
    employerId,
    subTaskLoading,
    isEditTask,
    emptyTaskPoolField,
    task,
    alertType,
    hideDeleteIcon,
    taskAccessUsers
  } = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openSubTaskAssignee, setOpenSubTaskAssignee] = useState(false);
  const [subTaskTitle, setSubTaskTitle] = useState(subTaskItem?.title || '');
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const [stateData, setStateData] = useState({
    moreActionPopup: false,
    showEvidenceView: false,
  });
  const handleInputFocus = () => {
    setIsFocused(true);
  };

  useEffect(()=> {
    if (!props?.subTaskLoading) {
      setLoading(props?.subTaskLoading || false);
    }
  }, [subTaskLoading]);

  useEffect(() => setSubTaskTitle(subTaskItem?.title || ''),[subTaskItem?.title]);
  const [stateForHandlingSubTaskAssigneeChange, setStateForHandlingSubTaskAssigneeChange] = useState<IStateForHandlingSubTaskAssigneeChange>({
    showAlertOfResetTaskPool: false,
  })

  const GetDivider = (props: {height?: number}) => {
    return (
      <View
        style={[styles.divider, { height: props.height || 16 }]}
        backgroundColor={Colors.Custom.Gray400}
      />
    );
  };

  const handleSubTaskAssigneeChange = (selectedUser: IUserPatientSearchItem, closeModal: boolean) => {
    const updateAssignee = {
      ...subTaskItem,
      assignee: selectedUser,
    };
    handleSubTaskChange?.(
      ADD_SUBTASK_CONST.CHANGE_ASSIGNEE,
      updateAssignee
    );
    setOpenSubTaskAssignee(false);
    if (closeModal) {
      setStateForHandlingSubTaskAssigneeChange((prev) => {
        return {
          ...prev,
          showAlertOfResetTaskPool: false,
        }
      })
    }
  }
  
  const handleMoreActionVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        moreActionPopup: visible,
      };
    });
  };
  const performAction = (actionCode: string) => {
    switch (actionCode) {
      case QUICK_ACTION_CODES.ADD_EVIDENCE:
        setStateData((prev) => {
          return {
            ...prev,
            showEvidenceView: true,
          }
        })
        break;
      case QUICK_ACTION_CODES.DELETE:
        setLoading(true);
        handleSubTaskChange?.(ADD_SUBTASK_CONST.DELETE, subTaskItem);
        break;
    }
  }

  const handleSave = (value: string) => {
    const updateSubTask = {
      ...subTaskItem,
      taskDiagnosisGapDetail: {
        ...subTaskItem.taskDiagnosisGapDetail,
        evidenceDescription: value,
      },
    };
    handleSubTaskChange?.(ADD_SUBTASK_CONST.EVIDENCE, updateSubTask);
    setStateData((prev) => {
      return {
        ...prev,
        showEvidenceView: false,
      }
    })
  }
  const handleCancel = () => {
    setStateData((prev) => {
      return {
        ...prev,
        showEvidenceView: false,
      }
    })
  }
  const renderSubTaskAssigneeTo = (subTaskItem: ISubTasks) => (
    <>
      <View style={styles.fullWidth}>
        {isEmployer ? (
          <UserAutoComplete
            labelText={'assignee'}
            selectedValue={subTaskItem.assignee as IUserSearch}
            excludeOtherEmployers={true}
            onChange={(selectedUser) => {
              if (subTaskItem?.id) {
                setLoading(true);
              }
              const updateAssignee = {
                ...subTaskItem,
                assignee: selectedUser as IUserPatientSearchItem,
              };
              handleSubTaskChange?.(
                ADD_SUBTASK_CONST.CHANGE_ASSIGNEE,
                updateAssignee
              );
              setOpenSubTaskAssignee(false);
            }}
            usersData={isEmployer ? taskPoolData?.accountUsers : []}
            allowClear={false}
          />
        ) : (
          <PatientSearchAndSelect
            userUuids={taskAccessUsers}
            resourceType={MAIN_MENU_CODES.TASKS}
            isRequired={true}
            showUserPendingTasksCount={true}
            labelColor={Colors.Custom.Gray500}
            showCurrentUserAtTop={taskPoolData?.selectedPool?.id ? false : true}
            isInvalid={props?.isInvalid}
            showError={props?.isInvalid}
            label={'Assign to'}
            isProspect={true}
            disableLeadsSearch
            disablePatientSearch
            showEmail
            showErrorBorder={true}
            placeholder="Search Staff or Member"
            value={subTaskItem.assignee}
            poolSelected={value?.taskPool?.key ? true : false}
            selectedPoolUsers={taskPoolData?.selectedPoolUsers}
            defaultAssigneeInSelectedPool={taskPoolData?.defaultAssignee}
            onChange={(participants: any, data: any) => {
              const contactIdValue =
                participants?.label?.props?.contactData?.uuid ||
                participants?.value;
              const contactIdKey = contactIdValue || participants?.key;
              const selectedUser = {
                key: contactIdKey,
                value: contactIdValue,
                label: participants?.label?.props?.contactData?.name,
                type: data?.type,
                details: participants?.label?.props?.contactData,
              }
              const isSelectedUserInSelectedTaskPool = taskPoolData?.selectedPool?.userPoolUsers?.some((user: any) => user.userId === contactIdKey)
              if (!taskPoolData?.selectedPool || !selectedUser?.key || isSelectedUserInSelectedTaskPool) {
                if (subTaskItem?.id && isEditTask  && task?.assigneeTypeCode !== 'CONTACT') {
                  setLoading(true);
                }
                handleSubTaskAssigneeChange(selectedUser as IUserPatientSearchItem, false)
              }
              else {
                setStateForHandlingSubTaskAssigneeChange((prev) => {
                  return {
                    ...prev,
                    showAlertOfResetTaskPool: true,
                    temporarySelectedUser: selectedUser
                  }
                })
              }
            }}
            employerId={employerId}
            allowClear={false}
            taskAccessUsers={taskAccessUsers}
          />
        )}
      </View>
    </>
  );
  const isAssigned = !!subTaskItem?.assignee?.value?.length;
  const renderAssigneeAvatar = () => {
    if (!isAssigned) {
      return ( <View
        style={[
          styles.unassignedAvatar,
          props?.isInvalid ? styles.unassignedAvatarInvalid : null,
        ]}
        backgroundColor={props?.isInvalid ? Colors.Custom.White : Colors.Custom.Gray100}
        >
        <UserAddSvg color={props?.isInvalid ? Colors.FoldPixel.STATUS_ERROR : Colors.FoldPixel.GRAY300} />
      </View>)
    }

    return (
      <View
        paddingY={0.5}
        style={styles.assignedAvatar}
      >
        <DisplayCardAvatar
          avatarStyle={{
            avatarSize: '7',
            textStyle: {
              fontSize: 12,
              fontWeight: '500',
            },
          }}
          isLetterAvatarShow={true}
          userData={{
            userType: GROUP_MEMBER_TYPE.USER,
            userId: subTaskItem?.assignee?.key || -1,
            userName: subTaskItem.assignee?.label,
          }}
          hideOnlineIndicator
        />
      </View>
    );
  }

  return (
    <HStack marginTop={2}>
      <View
        key={subTaskItem?.id || subTaskItem?.tempId}
        style={styles.container}
      >
        {(!isDisable && alertType !== TASK_TYPES.DIAGNOSIS_GAPS ) && (
          <View marginRight={2} marginLeft={2}>
            <Checkbox
              disabled={isDisable || false}
              defaultChecked={subTaskItem.isChecked}
              checked={subTaskItem.isChecked}
              data-testId='checkBox'
              key={subTaskItem.id}
              className="copy-checkbox"
              onChange={(item) => {
                if (subTaskItem?.id) {
                  setLoading(true);
                }
                handleSubTaskChange?.(
                  ADD_SUBTASK_CONST.MARK_COMPLETE,
                  subTaskItem
                );
              }}
            />
          </View>
        )}
        {alertType === TASK_TYPES.DIAGNOSIS_GAPS &&
        <View flex={1} style={{flexDirection: 'column'}}>
        <HStack space={2} flex={1} justifyContent="flex-start" alignItems="center" padding={2}>
          <View marginLeft={2} marginTop={1} style={{flex: 1}}>
            <PAMISearch
              isRequired={true}
              placeholder="Search Diagnosis"
              searchType={PAMISearchType.diagnosis}
              value={ alertType && subTaskItem?.title?.trim()?.length > 0 ?  {
                text: subTaskItem?.title,
                coding: [{
                  code: subTaskItem?.taskDiagnosisGapDetail?.diagnosisCode,
                  system: subTaskItem?.taskDiagnosisGapDetail?.diagnosisSystemCode,
                }],
              }: undefined}
              renderCompleteValues={true}
              isShowError={false}
              onChange={(selectedValue) => {
                if (subTaskItem?.id || isEditTask && task?.assigneeTypeCode !== 'CONTACT') {
                  setLoading(true);
                }
                const diagnosisCoding = selectedValue?.coding?.[0];
                const updateTitle = {
                  ...subTaskItem,
                  title: selectedValue.text,
                  taskDiagnosisGapDetail: {
                    ...subTaskItem.taskDiagnosisGapDetail,
                    diagnosisCode: diagnosisCoding?.code,
                    diagnosisSystemCode: diagnosisCoding?.system,
                    hccCategoryId: value?.hccCategoryId
                  }
                };
                handleSubTaskChange?.(
                  ADD_SUBTASK_CONST.UPDATE_TITLE,
                  updateTitle
                );
              }}
              showAdditionalDetails={true}
              locationId={contextData.locationId}
              displayInline={true}
              hideIcdText={true}
              showIcdCodeInBrackets={true}
            />
          </View>
          {/* Deleted Task Actions View */}
          <Popover
            overlayInnerStyle={{ padding: 0, borderRadius: 12 }}
            overlayStyle={{ padding: 0 }}
            overlay={{ padding: 0 }}
            style={{ padding: 0, borderRadius: 12 }}
            placement="bottomLeft"
            trigger="click"
            visible={stateData.moreActionPopup}
            onVisibleChange={handleMoreActionVisibleChange}
            content={
              <View width={180}>
                {DIAGNOSIS_ACTION_CODES.map((tabItem: IConversationChannelList, index) => (
                  <Pressable
                    key={`SideCarActionList_${index}_${tabItem.key}`}
                    onPress={() => {
                      performAction(tabItem.key)
                    }}
                    style={{
                      padding: 8,
                      backgroundColor: 'white',
                    }}
                    {...testID(tabItem.code || '')}
                  >
                    <HStack flex={1}>
                      <View
                        justifyItems="center"
                        alignItems="center"
                        alignSelf="center"
                        justifySelf="center"
                        height="16px"
                        width="16px"
                        {...testID('image')}
                      >
                        {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                          tabItem?.key,
                          Colors.Custom.Gray500
                        )}
                      </View>
                      <Text
                        flex={0.9}
                        style={{
                          color: tabItem?.key === QUICK_ACTION_CODES.DELETE ? Colors.FoldPixel.STATUS_ERROR : Colors.Custom.Gray500,
                          paddingStart: 8,
                        }}
                        {...testID(tabItem.title || '')}
                      >
                        {intl.formatMessage({ id: tabItem.title })}
                      </Text>
                    </HStack>
                  </Pressable>
                ))}
              </View>
            }
          >
            <Pressable
              onPress={() => {
                setStateData((prev) => ({
                  ...prev,
                  moreActionPopup: true,
                }));
              }}
              style={{justifyContent: 'center', height: 40 }}
              {...testID('MoreBtnClick')}
            >
              <Tooltip title="More">
                <MenuHorizontalIcon width={24} height={24} />
              </Tooltip>
            </Pressable>
          </Popover>
          {/* <Pressable disabled={loading} onPress={() => {
            setLoading(true);
            handleSubTaskChange?.(ADD_SUBTASK_CONST.DELETE, subTaskItem);
          }}>
            {loading ?
              <ActivityIndicator color={Colors.Custom.PrimaryColor} />
              :
              <DeleteButtonSvg customStrokeColor={Colors.Custom.Gray400} />
            }
          </Pressable> */}
          </HStack>
          {stateData.showEvidenceView && (
            <AddOrUpdateEvidenceView
              value={subTaskItem?.taskDiagnosisGapDetail?.evidenceDescription || ''}
              handleSave={handleSave}
              fileList={subTaskItem?.attachments}
              handleCancel={handleCancel}
              handleChange={(data)=> {
                const updateTask = {
                  ...subTaskItem,
                  attachments: data
                };
                handleSubTaskChange?.(
                  ADD_SUBTASK_CONST.UPDATE_TITLE,
                  updateTask
                );
              }}
            />
          )}
        </View>
        }
       {alertType !== TASK_TYPES.DIAGNOSIS_GAPS && <Input
          key={subTaskItem?.id || subTaskItem?.tempId}
          flex={1}
          alignContent={'center'}
          isDisabled={isDisable || false}
          multiline
          borderWidth={0}
          placeholderTextColor={InputStyle.placeholderTextColor}
          color={Colors.FoldPixel.GRAY400}
          bgColor={'white'}
          borderRadius={4}
          borderColor={Colors.Custom.alertsDescriptionColor}
          height={44}
          paddingLeft={isDisable ? 0 : 'none'}
          placeholder={'Enter Task Name'}
          fontWeight={400}
          fontSize={InputStyle.placeHolderFontsize}
          value={subTaskTitle}
          {...testID(TestIdentifiers.subTask)}
          onChangeText={(value) => {
            setSubTaskTitle(value);
          }}
          onFocus={handleInputFocus}
          onBlur={(event) => {
              if (subTaskTitle?.length && subTaskItem.title !== subTaskTitle) {
                if (subTaskItem?.id || isEditTask && task?.assigneeTypeCode !== 'CONTACT') {
                  setLoading(true);
                }
                const updateTitle = {
                  ...subTaskItem,
                  title: subTaskTitle,
                };
                handleSubTaskChange?.(
                  ADD_SUBTASK_CONST.UPDATE_TITLE,
                  updateTitle
                );
              }
              setIsFocused(false);
          }}
          // InputRightElement={<>
          //   {subTaskItem?.status?.value && subTaskItem?.status?.value === 'Missed' && <View backgroundColor={ Colors.Custom.Gray100} borderRadius={12} px={2} py={0.5}>
          //     <Text color={Colors.Custom.Gray700}>{subTaskItem?.status?.value}</Text>
          //   </View>}
          // </>}
        />}
        <HStack
          marginRight={3}
          marginLeft={2}
          justifyContent={'flex-end'}
          alignItems={'center'}
          display={alertType === TASK_TYPES.DIAGNOSIS_GAPS || alertType === TASK_TYPES.CARE_GAPS ? 'none' : 'flex'}
        >
          <Popover
            overlayInnerStyle={{
              bottom: 3,
              borderRadius: 12,
              padding: 0,
              width: 450,
            }}
            content={renderSubTaskAssigneeTo(subTaskItem)}
            trigger="click"
            placement="bottomLeft"
            visible={openSubTaskAssignee}
            onVisibleChange={() => {
              setOpenSubTaskAssignee(false);
            }}
            overlayStyle={{borderRadius: 8}}
          >
            <Pressable
              disabled={isDisable || false}
              onPress={() => {
                setOpenSubTaskAssignee(!openSubTaskAssignee);
              }}
              style={styles.assigneeButton}
            >
              {renderAssigneeAvatar()}
            </Pressable>
          </Popover>

          {alertType !== TASK_TYPES.DIAGNOSIS_GAPS && <Pressable
            style={styles.datePickerButton}
            disabled={isDisable || false}
            onPress={() => {
              setOpenDatePicker(true);
            }}
          >
            <HStack
              backgroundColor={
                subTaskItem?.endDateTime?.length
                  ? Colors.Custom.Gray100
                  : undefined
              }
              borderColor={subTaskItem?.endDateTime?.length ? Colors.FoldPixel.GRAY200: undefined}
              borderRadius={subTaskItem?.endDateTime?.length ? 4 : undefined}
              paddingLeft={subTaskItem?.endDateTime?.length ? 2 : undefined}
              paddingRight={subTaskItem?.endDateTime?.length ? 2 : undefined}
              paddingTop={subTaskItem?.endDateTime?.length ? 1 : undefined}
              paddingBottom={subTaskItem?.endDateTime?.length ? 1 : undefined}
              borderWidth={.5}
            >
              {subTaskItem?.endDateTime?.length && (
                <Text color={Colors.FoldPixel.GRAY300} {...testID(TestIdentifiers.subTaskDueDate)}>
                  {getDateStrFromFormat(
                    subTaskItem?.endDateTime,
                    DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
                  )}
                </Text>
              )}
            </HStack>
          </Pressable>}
          {!isDisable && !hideDeleteIcon && (
            <>
              <GetDivider />
              <Pressable
                disabled={loading}
                onPress={() => {
                  setLoading(true);
                  handleSubTaskChange?.(ADD_SUBTASK_CONST.DELETE, subTaskItem);
                }}
                {...testID(TestIdentifiers.deleteBtn)}
              >
                <View pl={1} size={18}>
                  {loading ?
                  <ActivityIndicator color={Colors.Custom.PrimaryColor} />
                  :
                  <DeleteButtonSvg customStrokeColor={Colors.Custom.Gray400} />
                  }
                </View>
              </Pressable>
            </>
          )}
        </HStack>
      </View>
      {openDatePicker && (
        <DatePicker
          disabledDate={(() => {
            const currentDate =
              value?.dueDate ?
                getMomentObjFromFormat(value.dueDate, DATE_FORMATS.DISPLAY_DATE_FORMAT).toDate()
                : new Date();
            const endOfCurrentDate = getStartOfDayOfTimezone(currentDate, getCurrentTimeZone()).endOf('day').valueOf();
            return (date) => {
              return !!(date?.valueOf() && date.valueOf() > endOfCurrentDate);
            }
          })()}
          open={openDatePicker}
          className="my-class"
          format={DATE_FORMATS.DISPLAY_DATE_FORMAT}
          value={
            getMomentObj(subTaskItem?.endDateTime || '')?.isValid()
              ? getMomentObj(subTaskItem.endDateTime || '')
              : undefined
          }
          onOpenChange={() => {
            setOpenDatePicker(false);
          }}
          onChange={(date: any, formatStrings: string) => {
            if (formatStrings && formatStrings.length > 1) {
              if (subTaskItem?.id) {
                setLoading(true);
              }
              setOpenDatePicker(false);
              const updatedItem = {
                ...subTaskItem,
                endDateTime: getMomentObjFromFormat(formatStrings, DATE_FORMATS.DISPLAY_DATE_FORMAT).endOf('day').toISOString(),
              };
              handleSubTaskChange?.(ADD_SUBTASK_CONST.DUE_DATE, updatedItem);
            }
          }}
        />
      )}
      <Modal
        title="Change Task Pool" open={stateForHandlingSubTaskAssigneeChange.showAlertOfResetTaskPool}
        onCancel={() => {
          setStateForHandlingSubTaskAssigneeChange((prev) => {
            return {
              ...prev,
              showAlertOfResetTaskPool: false,
            }
          })
        }}
        footer={null}
        bodyStyle={{ paddingTop: 12 }}
      >
        <View>
          <Text size={'mdMedium'} >{`Subtask assignee chosen is not part of ${taskPoolData?.selectedPool?.label}. Going ahead will reset the task pool. Continue?`}</Text>
          <Button.Group style={{ marginTop: 12 }}>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                style: {margin: 3},
                onPress: () => {
                  setStateForHandlingSubTaskAssigneeChange((prev) => {
                    return {
                      ...prev,
                      showAlertOfResetTaskPool: false,
                    }
                  })
                },
              }}
              customProps={{
                btnText: 'No',
                withRightBorder: false,
              }}
            ></FoldButton>
            <FoldButton
              nativeProps={{
                variant: BUTTON_TYPE.PRIMARY,
                style: {margin: 3},
                onPress: () => {
                  if (stateForHandlingSubTaskAssigneeChange.temporarySelectedUser) {
                    if (isEditTask && task?.assigneeTypeCode !== 'CONTACT') {
                      setLoading(true)
                    }
                    const closeModal = true
                    handleSubTaskAssigneeChange(stateForHandlingSubTaskAssigneeChange.temporarySelectedUser, closeModal)
                    emptyTaskPoolField?.()
                  }
                },
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'Yes',
                }),
                withRightBorder: false,
              }}
            ></FoldButton>
          </Button.Group>
        </View>
      </Modal>
    </HStack>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    borderColor: Colors.Custom.Gray200,
    borderRadius: 8,
    borderWidth: 1,
  },
  divider: {
    width: 1.5,
    marginHorizontal: 8,
  },
  fullWidth: {
    width: '100%',
  },
  unassignedAvatar: {
    width: 28,
    height: 29,
    borderRadius: 4,
    // backgroundColor: Colors.Custom.Gray100,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderWidth: 0.5,
    borderColor: Colors.FoldPixel.GRAY200,
    marginHorizontal: 4,
  },
  unassignedAvatarInvalid: {
    // backgroundColor: Colors.Custom.White,
    borderColor: Colors.FoldPixel.STATUS_ERROR,
  },
  assignedAvatar: {
    marginHorizontal: 4,
  },
  assigneeButton: {
    alignSelf: 'stretch',
  },
  datePickerButton: {
    marginLeft: 2,
  },
});

export default AddSubTasks;
