import {Text, Stack} from 'native-base';
import React from 'react';
import {DISPLAY_DATE_FORMAT} from '../../../../constants/StringConst';
import {Colors} from '../../../../styles/Colors';
import {
  getCurrentTimeZoneAbbr,
  getDateStrFromFormat,
  isSameDay,
} from '../../../../utils/DateUtils';
import { isWeb } from '../../../../utils/platformCheckUtils';
import {ICondition} from '../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/interfaces';
import {IPatientNoteCardProps} from '../interfaces';
import {getDocumentStatusLabel} from '../PatientNotes/components/DocumentationViewHelper';
import {DocStatus} from '../PatientNotes/interfaces';
import { FormComponents } from '../../../PublicPages/PublicForm/PublicFormHelper';
import { checkIfComponentIsVisible } from '../../../RightSideContainer/Forms/FHFormio/Builder/CustomWebBuilder';

export const NoteExtraComponents = {
  CHIEF_COMPLAINT: 'chiefComplaint',
  DIAGNOSIS: 'diagnosis',
};
export const NO_DIAGNOSIS_LABEL = 'No diagnosis available';

export const renderPatientNoteDateAndStatus = (
  params: IPatientNoteCardProps,
  intl: any
) => {
  const {
    signedDate,
    signedByUserId,
    status,
    createdDate,
    formName,
    description,
  } = params;
  const isUnlockDocumentRef = signedDate && signedByUserId ? true : false;
  let statusId = DocStatus.PRELIMINARY;
  if (status == DocStatus.AMENDED) {
    statusId = DocStatus.AMENDED;
  } else if (status === DocStatus.FINAL) {
    statusId = DocStatus.FINAL;
  }
  const statusIntlId = getDocumentStatusLabel(statusId, isUnlockDocumentRef);
  const displayDate = getDateStrFromFormat(createdDate, DISPLAY_DATE_FORMAT);
  return (
    <Stack direction={'row'} my={0.5} alignItems="center">
      <Text color={Colors.Custom.Gray500} fontSize={14}>
        {`Last updated ${displayDate}`}
      </Text>
      {!!description && (
        <>
          <Text color={Colors.Custom.Gray500}> • </Text>
          <Text color={Colors.Custom.Gray500} fontSize={ isWeb() ? 14 : 12}>
            {`${formName}`}
          </Text>
        </>
      )}
      {displayDate?.length > 0 && statusIntlId?.length > 0 && (
        <Text color={Colors.Custom.Gray500}> • </Text>
      )}
      {statusIntlId.length > 0 ? (
        <Text
          color={
            status === DocStatus.PRELIMINARY
              ? Colors.Custom.ErrorColor
              : Colors.Custom.Gray500
          }
          fontSize={isWeb() ? 14 : 12}>
          {intl.formatMessage({id: statusIntlId})}
        </Text>
      ) : null}
    </Stack>
  );
};

export const renderPatientNoteCardHeadLine = (
  text: JSX.Element,
): JSX.Element => {
  return (
    <Stack direction={'row'} my={0.5} alignItems="center">
      <Text color={Colors.Custom.Gray900} fontSize={16} fontWeight="semibold">
        {text}
      </Text>
    </Stack>
  );
};

export const renderPatientNoteDiagnosis = (
  params: IPatientNoteCardProps,
): string => {
  if (!params?.formContent || !params?.formContent?.length) {
    return NO_DIAGNOSIS_LABEL;
  }
  const component = params?.formContent?.find(
    (component: any) => component.key === NoteExtraComponents.DIAGNOSIS,
  );
  if (component) {
    const value: string = JSON.parse(component?.data)
      ?.conditions.map(
        (item: ICondition) => item?.condition?.text || item?.name,
      )
      .join(', ');
    return value.length > 0 ? value : NO_DIAGNOSIS_LABEL;
  }
  return NO_DIAGNOSIS_LABEL;
};

export const isOnlyChiefComplaintExist = (
  params: IPatientNoteCardProps,
): boolean => {
  if (!params?.formContent || !params?.formContent?.length) {
    return false;
  }
  const chiefComplaint = params?.formContent?.find(
    (component: any) => component.key === NoteExtraComponents.CHIEF_COMPLAINT,
  );
  const diagnosis = params?.formContent?.find(
    (component: any) => component.key === NoteExtraComponents.DIAGNOSIS,
  );
  const diagnosisData = !!diagnosis && JSON.parse(diagnosis?.data);
  return chiefComplaint && !diagnosisData?.conditions?.length ? true : false;
};

export const renderPatientNoteChiefComplaint = (
  params: IPatientNoteCardProps,
  isExpanded: boolean,
  showMoreHandler: (noteExtraField: string) => void,
): JSX.Element => {
  if (!params?.formContent || !params?.formContent?.length) {
    return <></>;
  }
  const formContent = params?.formContent;
  const component = formContent?.find(
    (component: any) => component.key === NoteExtraComponents.CHIEF_COMPLAINT,
  );
  if (component) {
    const value = JSON.parse(component.data)?.chiefComplaint?.displayName;
    return !!value ? (
      <Text my={0.5} color={Colors.Custom.Gray500} fontSize={14}>
        <Text>Chief Complaint: </Text>
        {isExpanded ? value : value.substring(0, 90)}
        <Text
          color={Colors.Custom.PurpleColor}
          onPress={() => showMoreHandler(NoteExtraComponents.CHIEF_COMPLAINT)}>
          {value.length > 90 && (isExpanded ? ' - Show less' : ' + Show more')}
        </Text>
      </Text>
    ) : (
      <></>
    );
  }
  return <></>;
};

export const renderPatientNoteAuthoredAndSignedBy = (
  params: IPatientNoteCardProps,
) => {
  const {
    signedDate,
    signedByUserId,
    status,
    createdDate,
    createdByUserName,
    authorUuid,
    signedByUserName,
  } = params;
  const isAlreadySignedNote = signedDate && signedByUserId;
  const isAuthorAndSignedBySame = authorUuid === signedByUserId;
  const isDateSame =
    signedDate && createdDate ? isSameDay(signedDate, createdDate) : false;

  const authoredDateStr = createdDate
    ? getDateStrFromFormat(createdDate || '', DISPLAY_DATE_FORMAT)
    : '';
  const signedDateStr = signedDate
    ? getDateStrFromFormat(signedDate, DISPLAY_DATE_FORMAT)
    : '';
  const timezoneAbbr = getCurrentTimeZoneAbbr();
  if (!isAlreadySignedNote && status === DocStatus.PRELIMINARY) {
    return (
      <Text my={0.5} color={Colors.Custom.Gray500} fontSize={14}>
        {createdByUserName && authoredDateStr
          ? `Created by: ${createdByUserName} ${
              createdDate ? `at ${authoredDateStr} (${timezoneAbbr})` : ''
            }`
          : createdByUserName
          ? `Created by: ${createdByUserName}`
          : authoredDateStr
          ? `Created at: ${authoredDateStr} (${timezoneAbbr})`
          : ''}
      </Text>
    );
  }
  if (
    status === DocStatus.FINAL ||
    isAlreadySignedNote ||
    status === DocStatus.AMENDED
  ) {
    const combineDisplayString = [];
    if (createdByUserName && authoredDateStr) {
      combineDisplayString.push(
        `Created by: ${createdByUserName} ${
          createdDate ? `at ${authoredDateStr} (${timezoneAbbr})` : ''
        }`,
      );
    }
    if (signedByUserName && signedDateStr) {
      combineDisplayString.push(
        `Signed by: ${signedByUserName} ${
          signedDate ? `at ${signedDateStr} (${timezoneAbbr})` : ''
        }`,
      );
    }

    if (isAuthorAndSignedBySame) {
      return isDateSame ? (
        <Text my={0.5} fontSize={14} color={Colors.Custom.Gray500}>
          {`Created and signed by: ${createdByUserName} ${
            createdDate ? `at ${authoredDateStr} (${timezoneAbbr})` : ''
          }`}
        </Text>
      ) : (
        <Stack direction={'row'} alignItems="center">
          <Text my={0.5} fontSize={14} color={Colors.Custom.Gray500}>
            {combineDisplayString.join(' • ')}
          </Text>
        </Stack>
      );
    } else {
      return (
        <Stack direction={'row'} alignItems="center">
          <Text my={0.5} fontSize={14} color={Colors.Custom.Gray500}>
            {combineDisplayString.join(' • ')}
          </Text>
        </Stack>
      );
    }
  }
};

const getComponentsForVersion = (
  components: any[],
  resourceKeys: string[]
): any[] => {
  if (!components || components?.length === 0) {
    return [];
  }

  components?.forEach((component: any) => {
    const {key, components: innerComponents, columns} = component;

    if (
      key &&
      !resourceKeys.includes(key) &&
      !key.includes(FormComponents.PANEL) &&
      !key.includes(FormComponents.COLUMNS)
    ) {
      component.hidden = true;
    } else if (innerComponents || columns) {
      getComponentsForVersion(innerComponents || columns, resourceKeys);
    }
  });

  return components;
};

export const getPreviousVersionFormDataByResponse = (
  currentFormData: any,
  formResponse: any
) => {
  const resourceKeys = formResponse?.content?.map(
    (item: any) => item?.attachment?.extension?.[0]?.valueString
  );
  if (!currentFormData?.form) {
    return currentFormData;
  }
  currentFormData.form.components = getComponentsForVersion(currentFormData?.form?.components, resourceKeys);
  return currentFormData;
};

export const getComponentsForShareablePrint = (
  components: any[],
): any[] => {
  if (!components || components.length === 0) {
    return [];
  }

  return components.filter((component) => {
    if (component.key && component.allowToShare === false) {
      return false;
    }

    if(component?.components?.length > 0) {
      component.components = getComponentsForShareablePrint(component.components);
    }

    return true;
  });
};

export const getVisibleComponents = (
  components: any[],
  selectedFormData: any
): any[] => {
  if (!components || components.length === 0) {
    return [];
  }

  return components.filter((component) => {
    const isVisible = checkIfComponentIsVisible(component, selectedFormData);
    if (!isVisible) {
      return false;
    }

    if (component?.columns?.length > 0) {
      component.columns = getVisibleComponents(component.columns, selectedFormData);
      if (component.columns.length === 0) {
        return false;
      }
    }

    if (component?.components?.length > 0) {
      component.components = getVisibleComponents(component.components, selectedFormData);
      if (component.components.length === 0) {
        return false;
      }
    }

    return true;
  });
};
