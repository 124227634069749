import React, {useState} from 'react';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
} from '../FormComponents/FormComponents';
import {getUniqueKey} from '../CustomFormEngineUtils';
import {HomeMonitoringViewSchema} from '../Schema/ComponentsSchema';
import {cloneDeep} from 'lodash';
import HomeMonitoringView from '../../FHFormio/CustomComponents/HomeMonitoringView/HomeMonitoringView';
import { useFormRendererContext } from '../Context/FormRenderer.context';
import { v4 as uuidV4 } from 'uuid';

export const HomeMonitoringViewFieldFormElement: FormElement = {
  type: HomeMonitoringViewSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(HomeMonitoringViewSchema),
    key: getUniqueKey(map, HomeMonitoringViewSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(HomeMonitoringViewSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    label: HomeMonitoringViewSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  disabled,
  validationRef,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  disabled?: boolean;
  validationRef?: React.MutableRefObject<any>;
}) {
  return (
    <div className="flex flex-col gap-1 w-full">
      <HomeMonitoringView
        component={{
          ...elementInstance,
          selectedValue: defaultValue,
        }}
        disabled={disabled || false}
        options={{}}
        validateRef={{}}
        onChange={onChange}
      />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

function DesignerComponent({elementInstance}: {elementInstance: FormElementInstance}) {
  return <></>;
}

function FormComponent({
  elementInstance,
  submitValue,
  isInvalid,
  defaultValue,
  isReadOnly,
  validationRef,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  isInvalid?: boolean;
  defaultValue?: string;
  isReadOnly?: boolean;
  validationRef?: React.MutableRefObject<any>;
  onRegisterValidation?: (key: string, validation: ValidationFunction) => void;
}) {
  const {state} = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<string | undefined>(defaultValue || selectedValue);

  const handleChange = (value: string) => {
    setValue(value);
  };

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
      errorMessage={isInvalid ? 'This field is required' : ''}
      disabled={isReadOnly}
      validationRef={validationRef}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  return <></>;
}

export default HomeMonitoringViewFieldFormElement;
