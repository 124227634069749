import {Button, HStack, Spacer, Text, VStack, Select, View} from 'native-base';
import {IReconciliationConflictField, IReconciliationRequest, ReconciliationSource} from './interface';
import {DisplayText} from '../../../../common/DisplayText/DisplayText';
import PAMISearch, {
  PAMISearchType,
} from '../../../../common/PAMISearch/PAMISearch';
import {BUTTON_TYPE, DISPLAY_DATE_FORMAT} from '../../../../../constants';
import {useIntl} from 'react-intl';
import {FHIRConflictFields} from './HieRequestsUtils';
import {useCallback, useMemo, useState} from 'react';
import {DatePicker} from 'antd';
import {
  getMomentObjectWithDateStringAndFormat,
  isFutureDate,
} from '../../../../../utils/DateUtils';
import CustomTextAreaField, {
  CustomTextInputMode,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/TextAreaComponent/CustomTextAreaField';
import {debounce} from 'lodash';
import Stack from '../../../../common/LayoutComponents/Stack';
import {Colors} from '../../../../../styles';
import {styles} from './PendingHieRequestsStyles';
import {ACTION_CODES} from '../../../../common/PendingRequestListItem/PendingRequestLisItemConst';
import './PendingHieRequests.css';
import FHIRPractitionerSearch from '../../../../common/FHIRPractitionerSearch/FHIRPractitionerSearch';
import Label from '../../../../RightSideContainer/Forms/CustomFormEngine/BaseComponents/Label';
import DropdownField from '../../../../RightSideContainer/Forms/CustomFormEngine/BaseComponents/DropdownField';
import { sliceIntoChunks } from '../../../../RightSideContainer/Analytics/EmployerAnalytics/EmployerUtils/printUtils';
import AllergyReactionEdit from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Allergies/AllergyReactionEdit/AllergyReactionEdit';
import { InputType } from '../../../../../utils/capabilityUtils';
import { IAllergy, IAllergyReaction } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Allergies/interfaces';

interface HieMatchDataViewProps {
  hieRequest: IReconciliationRequest;
  onContinueClick: (conflictFields: IReconciliationConflictField[], accept?: boolean) => void;
  headerText: string;
  onActionPerform: (id: string, code: string) => void;
  contactLocationId?: string
  source: ReconciliationSource
}

const antdStyles: Record<string, React.CSSProperties> = {
  datePicker: {
    height: 40,
    borderRadius: 8,
    color: Colors.Custom.black,
  }
}

export const HieMatchDataView = (props: HieMatchDataViewProps) => {
  const intl = useIntl();
  const {hieRequest, onContinueClick, onActionPerform, contactLocationId} = props;

  const [componentState, setComponentState] = useState({
    conflictFields: hieRequest.conflictFields || [],
    showError: false,
  });

  const updateValueByType = (field: IReconciliationConflictField, value: any) => {
    setComponentState((prev) => {
      const updatedInvalidFields = prev.conflictFields?.map((conflictField) => {
        if (conflictField.field === field.field) {
          conflictField.value = value;
        }
        return conflictField;
      });
      return {
        ...prev,
        conflictFields: updatedInvalidFields,
      };
    });
  };

  const onSave = () => {
    const invalidFields = componentState.conflictFields?.filter((field) => {
      if (field.inputType === FHIRConflictFields.allergyReaction) {
        return !field.value.length || field.value.some((reaction: IAllergyReaction) => !reaction.manifestation?.display);
      }
      return field.isRequired && !field.value;
    });
    if (invalidFields?.length) {
      setComponentState((prev) => ({
        ...prev,
        showError: true,
      }));
    } else {
      onContinueClick(componentState.conflictFields || [], true);
    }
  }

  const renderCodeView = (conflictFields: IReconciliationConflictField[]) => {
    const invalidCodeField = conflictFields?.find(
      (conflictField) => conflictField.inputType === FHIRConflictFields.code
    );
    let name = invalidCodeField?.value?.text;
    if (!name && invalidCodeField?.value?.coding?.length) {
      name = invalidCodeField?.value?.coding[0].display;
    }
    if (invalidCodeField && !name) {
      return (
        <Stack space={4} direction="column">
          <Label label={invalidCodeField?.label || ''} isRequired={invalidCodeField?.isRequired} />
          <PAMISearch
            showAdditionalDetails={false}
            enablePaginationOnScroll
            searchType={
              invalidCodeField?.extraData?.searchType || PAMISearchType.problem
            }
            placeholder={invalidCodeField?.placeholder || 'Search Problem'}
            isShowError={componentState.showError && !!invalidCodeField?.isRequired && !invalidCodeField?.value}
            onChange={(value) => {
              updateValueByType(invalidCodeField, value);
            }}
            locationId={contactLocationId}
          />
        </Stack>
      );
    } else if (name) {
      return (
        <VStack>
          <Text fontSize={14} fontWeight={'500'} marginBottom={2}>
            {name}
          </Text>
        </VStack>
      );
    }
  };

  const renderNoteView = (conflictFields: IReconciliationConflictField[]) => {
    const invalidNoteField = conflictFields?.find(
      (conflictField) =>
        conflictField.inputType === FHIRConflictFields.textArea
    );
    if (invalidNoteField) {
      return (
        <Stack space={4} direction="column">
          <Label label={invalidNoteField?.label || ''} isRequired={invalidNoteField?.isRequired} />
          <CustomTextAreaField
            showError={componentState.showError && invalidNoteField?.isRequired && !invalidNoteField?.value}
            placeholder={'Note'}
            defaultValue={invalidNoteField.value}
            macroTemplatesEnabled={true}
            onValueChange={debounce((text: string) => {
              updateValueByType(invalidNoteField, text);
            }, 500)}
            additionalProps={{
              totalLines: 2,
              flex: 1,
              isReadOnly: false,
              borderRadius: 8,
            }}
          />
        </Stack>
      );
    }
  };

  const renderAllergyReactionView = (conflictFields: IReconciliationConflictField[]) => {
    const conflictField = conflictFields?.find(
      (conflictField) =>
        conflictField.inputType === FHIRConflictFields.allergyReaction
    );
    if (conflictField) {
      const isShowAddReactionButton = showAddReactionBtn(conflictField.value, conflictField?.extraData?.disableMultiple || false);
      return (
        <Stack space={4} direction="column">
          <Label label={conflictField?.label || ''} isRequired={conflictField?.isRequired} />
          <AllergyReactionEdit
            isReconciliation={true}
            reactions={conflictField.value || []}
            disableMultiple={conflictField?.extraData?.disableMultiple}
            addNewReactionEnabled={conflictField?.extraData?.inputType == InputType.openChoice}
            reactionInputType={conflictField?.extraData?.inputType}
            severityList={conflictField?.extraData?.severityList}
            isDisabled={false}
            isRequired={!!conflictField?.isRequired}
            onChange={(reactions) => {
              updateValueByType(conflictField, reactions);
            }}
          />
          {isShowAddReactionButton && (
            <Button
              rounded="3xl"
              size="sm"
              variant="link"
              alignSelf="flex-start"
              onPress={() => {
                conflictField.value.push({});
                updateValueByType(conflictField, conflictField.value);
              }}
            >
              {`+ New Reaction`}
            </Button>
          )}
        </Stack>
      );
    }
  };

  const showAddReactionBtn = (item: IAllergy, disableMultiple: boolean) => {
    const isShowAddReactionButton = disableMultiple
      ? item?.reactions?.length !== 1
      : !isAnyReactionEmpty(item.reactions);
    return isShowAddReactionButton;
  }

  const isAnyReactionEmpty = (reactions: any) => {
    return reactions?.some((reaction: any) => {
      if (!reaction?.manifestation) {
        return true;
      }
    });
  };

  const getMandatoryDetailsTextVisibility = () => {
    const invalidNoteField = componentState.conflictFields?.find(
      (conflictField) =>
        conflictField.inputType === FHIRConflictFields.textArea
    );

    return getHorizontalInvalidFields()?.length || invalidNoteField;
  };

  const renderHorizontalFields = (conflictField: IReconciliationConflictField) => {
    if (conflictField.inputType === FHIRConflictFields.date) {
      const onsetDateFormat = conflictField?.extraData?.dateFormat;
      const isOnlyYearFormat =
        conflictField?.extraData?.dateFormat === 'YYYY';
      const label = intl.formatMessage({
        id: isOnlyYearFormat ? 'sinceYear' : 'sinceDate',
      });

      return (
        <Stack direction="column" space={4} style={styles.flex1} key={`conflict-field-${conflictField.label}`}  >
          <Label label={conflictField?.label || ''} isRequired={conflictField?.isRequired} />
          <DatePicker
            className='custom-date-picker'
            picker={isOnlyYearFormat ? 'year' : 'date'}
            disabled={false}
            placeholder={label}
            format={isOnlyYearFormat ? onsetDateFormat : DISPLAY_DATE_FORMAT}
            status={componentState.showError && conflictField?.isRequired && !conflictField?.value ? 'error' : ''}
            disabledDate={(current: any) => {
              return current && isFutureDate(current);
            }}
            value={
              conflictField.value
                ? getMomentObjectWithDateStringAndFormat(
                    conflictField.value,
                    onsetDateFormat || ''
                  )
                : undefined
            }
            style={antdStyles.datePicker}
            onChange={(value) => {
              const onSetDateTime = onsetDateFormat
                ? value?.format(onsetDateFormat)
                : value?.toISOString();
              updateValueByType(conflictField, onSetDateTime);
            }}
          />
        </Stack>
      );
    }

    if (conflictField.inputType === FHIRConflictFields.select) {
      const selectList = conflictField?.extraData?.statusList || [];
      const formattedSelectList = selectList.map((option: any) => ({
        label: option.display,
        value: option.code,
      }));

      return (
        <Stack direction="column" space={4} style={styles.flex1}>
          <Label label={conflictField?.label || ''} isRequired={conflictField?.isRequired} />
          <DropdownField
            options={formattedSelectList}
            placeholder={conflictField.label || 'Select Status'}
            defaultValue={conflictField.value}
            isInvalid={componentState.showError && conflictField?.isRequired && !conflictField?.value}
            onChange={(value: string) => {
              updateValueByType(conflictField, value);
            }}
          />
        </Stack>
      );
    }

    if (conflictField.inputType === FHIRConflictFields.input) {
      return (
        <Stack direction="column" space={4} style={styles.flex1}>
          <Label label={conflictField?.label || ''} isRequired={conflictField?.isRequired} />
          <CustomTextAreaField
            showError={componentState.showError && conflictField?.isRequired && !conflictField?.value}
            inputMode={CustomTextInputMode.textfield}
            placeholder={conflictField.placeholder}
            defaultValue={conflictField.value}
            macroTemplatesEnabled={true}
            onValueChange={debounce((text: string) => {
              updateValueByType(conflictField, text);
            }, 500)}
            additionalProps={{
              totalLines: 1,
              flex: 1,
              isReadOnly: false,
              borderWidth: 0.5,
              borderColor: '#8A94A8',
              borderRadius: 4,
              height: 8,
            }}
          />
        </Stack>
      );
    }

    if (conflictField.inputType === FHIRConflictFields.practitioner) {
      return (
        <Stack direction="column" space={4} style={styles.flex1}>
          <Label label={conflictField?.label || ''} isRequired={conflictField?.isRequired} />
          <FHIRPractitionerSearch
            value={conflictField.value}
            data={conflictField.extraData?.practitionerData || []}
            loading={false}
            placeholder={conflictField.placeholder}
            isShowError={componentState.showError && !!conflictField?.isRequired && !conflictField?.value}
            disabled={false}
            className={'custom-select-box'}
            onChange={(value) => {
              updateValueByType(conflictField, value);
            }}
          />
        </Stack>
      );
    }
  };

  const getHorizontalInvalidFields = useCallback(() => {
    return componentState.conflictFields.filter(
      (invalidField) =>
        invalidField.inputType !== FHIRConflictFields.textArea &&
        invalidField.inputType !== FHIRConflictFields.code
    ).sort((fieldA, fieldB) => (!fieldA.isRequired && fieldB.isRequired) || (fieldA.sequence && fieldB.sequence && fieldA.sequence > fieldB.sequence) ? 1 : -1);
  }, [componentState.conflictFields]);

  const chunkedFields = useMemo(() =>
    sliceIntoChunks(getHorizontalInvalidFields() || [], 2),
    [getHorizontalInvalidFields]
  );

  return (
    <Stack
      space={4}
      direction="column"
      style={styles.container}
    >
      <Stack direction="column" space={8}>
        {renderCodeView(componentState.conflictFields)}
        {chunkedFields.map((fieldPair, index) => (
          <Stack
            key={`conflict-fields-row-${index}}`}
            direction="row"
            space={12}
          >
            {fieldPair.map(renderHorizontalFields)}
          </Stack>
        ))}
        {renderAllergyReactionView(componentState.conflictFields)}
        {renderNoteView(componentState.conflictFields)}
      </Stack>

      <Stack direction="row">
        <Spacer />
        <Button.Group space={2} marginTop={2}>
          <Button
            variant="unstyled"
            style={styles.saveButton}
            onPress={onSave}
          >
            <DisplayText
              textLocalId={'saveAndAccept'}
              extraStyles={{
                color: Colors.Custom.White,
                fontSize: 14,
              }}
            />
          </Button>
          <Button
            variant="unstyled"
            style={styles.cancelButton}
            onPress={() => {
              onActionPerform?.(
                hieRequest.id,
                ACTION_CODES.HIDE_MATCH_DATA_VIEW
              );
            }}
          >
            <DisplayText
              textLocalId="cancel"
              extraStyles={{
                color: Colors.FoldPixel.GRAY300,
                fontSize: 14,
              }}
            />
          </Button>
        </Button.Group>
      </Stack>
    </Stack>
  );
};

HieMatchDataView.displayName = 'HieMatchDataView';
