import {Menu, Popover} from 'antd';
import Picker from 'emoji-picker-react';
import {
  FormControl,
  HStack,
  IconButton,
  Pressable,
  Text,
  TextArea,
  Tooltip,
  VStack,
  View,
} from 'native-base';
import {useEffect, useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors} from '../../../../../styles';
import {getAccountMergeTagData, getCustomAttributeMergeTags} from '../../ContentManagementUtils';
import {AddOrUpdateSMSTemplatesBodyProps} from '../interfaces';
import {SMSPreview} from '../SMSPreview';
import {
  getMenuItems,
  getParseBody,
  getSelectedMergeTag,
  insertAtIndex,
} from '../SMSTemplatesUtils';
import {styles} from './AddOrUpdateSMSTemplatesStyles';
import {SMS_BODY_MAX_LENGTH} from '../../ContentManagementConsts';
import {useIntl} from 'react-intl';
import {ATTACHMENT_TYPE, TASK_ATTACHMENT_SOURCE} from '../../../../../constants';
import AttachmentSvg from '../../../../../assets/Icons/AttachmentSvg';
import MediaSelectionModal from '../../PatientEducation/MediaSelectionModal/MediaSelectionModal';
import {IMediaLibraryData} from '../../MediaLibrary/interfaces';
import {getMediaTypeFromMime} from '../../MediaLibrary/MediaLibraryUtils';
import {getMediaPreviewElementForList} from '../../MediaLibrary/MediaLibraryTable/Helper';
import EmailAttachmentDrawer from '../../../../common/EmailDrawerCommonV2/EmailAttachmentDrawer';
import {UploadFile} from 'antd/es/upload';
import {convertCMSFileToUploadFile} from '../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {useToast} from '../../../../Toast/ToastProvider';
import {showToast, ToastType} from '../../../../../utils/commonViewUtils';
const {ErrorMessage, Label, HelperText} = FormControl;

export enum SMSBodyViewCode {
  Edit = 'Edit',
  Preview = 'Preview',
}

const AddOrUpdateSMSTemplatesBody = (
  props: AddOrUpdateSMSTemplatesBodyProps
) => {
  const {
    smsTemplateData,
    errors,
    setSmsTemplateData,
    isHideLabel,
    isInputDisabled,
    useString,
    onChangeBody,
    onAddOrUpdateAttachment,
  } = props;
  const [cursorPosition, setCursorPosition] = useState(0);
  const hasJourneyInHash = () => {
    return window.location.hash.includes('journey');
  };
  const intl = useIntl();
  const showAttachment = isAccountConfigEnabled(CONFIG_CODES.ALLOW_ATTACHMENT_IN_SMS) && 
    props.showAttachment && 
    !hasJourneyInHash();
  const [state, setState] = useState({
    viewCode: SMSBodyViewCode.Edit,
    attachmentType: '',
    previewElements: smsTemplateData?.attachments ?? [] as any,
    isFileListDrawerOpen: false,
  });
  const toast = useToast();
  const [customAttributeMergeTags, setCustomAttributeMergeTags] = useState<
  Record<string, string>
>({});  

  const handleViewCodeChange = (code?: string) => {
    if (code === ATTACHMENT_TYPE.IMAGE) {
      if(state.previewElements.length > 9){
        showToast(
          toast,
          intl.formatMessage({id: 'maxAttachmentLimitReached'}),
          ToastType.info,
          3000,
          true
        );
        return
      }
      setState((prev) => {
        return {
          ...prev,
          attachmentType: ATTACHMENT_TYPE.IMAGE,
        };
      });
    } else if (state.viewCode === SMSBodyViewCode.Edit) {
      setState((prev) => {
        return {
          ...prev,
          viewCode: SMSBodyViewCode.Preview,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          viewCode: SMSBodyViewCode.Edit,
        };
      });
    }
  };

  const accountMergeTags = getAccountMergeTagData({hideLogo: true});

  const getMergeTags = () => {
    if (smsTemplateData?.category) {
      return {
        ...smsTemplateData?.category?.mergeTags,
        global: accountMergeTags,
        customAttribute: customAttributeMergeTags,
      };
    }
    return {
      global: accountMergeTags,
      customAttribute: customAttributeMergeTags,
    };
  };

  const insertMergeTags = (mergeTagString: string, index: number) => {
    const body = smsTemplateData?.body || '';
    const insertedBody = insertAtIndex(body, index, mergeTagString);
    if (insertedBody.length > SMS_BODY_MAX_LENGTH) {
      return;
    }
    if (useString && onChangeBody) {
      onChangeBody(insertedBody);
    } else {
      setSmsTemplateData((prev: any) => {
        return {
          ...prev,
          body: insertedBody,
          attachments: state.previewElements,
        };
      });
    }
  };

  const onMenuItemSelected = (info: any) => {
    const mergeTagString = getSelectedMergeTag(info.keyPath);
    insertMergeTags(mergeTagString, cursorPosition);
  };

  const popoverContent = () => {
    return (
      <Menu
        items={getMenuItems(getMergeTags())}
        selectable={false}
        onClick={(info) => {
          onMenuItemSelected(info);
        }}
      />
    );
  };

  const emojiPopoverContent = () => {
    return (
      <Picker
        onEmojiClick={(event, data) => {
          insertMergeTags(data.emoji, cursorPosition);
        }}
      />
    );
  };
  const handleAttachMedia = async (mediaData: IMediaLibraryData) => {
    if (!mediaData.url) {
      return;
    }
    const type = getMediaTypeFromMime(mediaData.mime || '');
    const url = mediaData.thumbnail || mediaData.url || '';
    const fomaterMediaData = await convertCMSFileToUploadFile(mediaData)
    const previewElement = {...fomaterMediaData,url, id: mediaData.id || '', type: mediaData.type}
    setState((prev) => {
      const newPreviewElements = [...prev.previewElements, previewElement]
      if(onAddOrUpdateAttachment){
        onAddOrUpdateAttachment(newPreviewElements)
      }
      return {
        ...prev,
        attachmentType: '',
        previewElements: newPreviewElements,
      };
    });
  };

  const getAndSetCustomAttributeMergeTags = async () => {
    const customAttributeMergeTags = await getCustomAttributeMergeTags();
    setCustomAttributeMergeTags(customAttributeMergeTags);
  };

  useEffect(() => {
    getAndSetCustomAttributeMergeTags();
  }, []);

  return (
    <View mt={0} mx={0} flex={1}>
      <FormControl
        style={styles.formContainer}
        isRequired
        isInvalid={errors?.body}
      >
        <HStack style={styles.mergeTagPopoverView}>
          {!isHideLabel && <Label>Body</Label>}
          <Popover content={popoverContent} trigger={'hover'}>
            <IconButton
              _hover={{
                backgroundColor: Colors.primary['100'],
              }}
              backgroundColor={Colors.primary['100']}
              style={{
                marginBottom: 5,
                marginLeft: 4,
              }}
              icon={
                <MaterialCommunityIcon
                  name="code-json"
                  size={16}
                  color="black"
                />
              }
            />
          </Popover>
          <Popover content={emojiPopoverContent} trigger={'hover'} style={{}}>
            <IconButton
              _hover={{
                backgroundColor: Colors.primary['100'],
              }}
              style={{
                marginBottom: 5,
                marginLeft: 4,
              }}
              backgroundColor={Colors.primary['100']}
              icon={<AntIcon name="smileo" size={16} color="black" />}
            />
          </Popover>
          {showAttachment && (
            <Popover content={intl.formatMessage({id: 'maxSize5mb'})} trigger={'hover'} style={{}}>
              <IconButton
                _hover={{
                  backgroundColor: Colors.primary['100'],
                }}
              style={{
                marginBottom: 5,
                marginLeft: 4,
              }}
              backgroundColor={Colors.primary['100']}
              onPress={() => {
                handleViewCodeChange(ATTACHMENT_TYPE.IMAGE);
              }}
              icon={<AttachmentSvg strokeColor={'#000'} />}
              />
            </Popover>
          )}
          <Tooltip
            label={state.viewCode === SMSBodyViewCode.Edit ? 'Preview' : 'Edit'}
            placement="top"
          >
            <IconButton
              _hover={{
                backgroundColor: Colors.primary['100'],
              }}
              style={{
                marginBottom: 5,
                marginLeft: 4,
              }}
              backgroundColor={Colors.primary['100']}
              onPress={() => {
                handleViewCodeChange();
              }}
              icon={<AntIcon name="laptop" size={16} color={'black'} />}
            />
          </Tooltip>
        </HStack>

        {state.viewCode === SMSBodyViewCode.Edit && (
          <VStack>
            <TextArea
              placeholder='Enter SMS Body'
              placeholderTextColor={props.isAutomationView ? Colors.FoldPixel.GRAY250 : undefined}
              backgroundColor={props.isAutomationView ? 'white' : undefined}
              borderWidth={props.isAutomationView ? 0.5 : 1}
              isDisabled={isInputDisabled || false}
              size={'md'}
              flex={1}
              maxLength={SMS_BODY_MAX_LENGTH}
              onSelectionChange={(e) => {
                setCursorPosition(e.nativeEvent.selection.start);
              }}
              value={smsTemplateData?.body || ''}
              onChangeText={(body) => {
                if (body?.trim().length == 0) {
                  setSmsTemplateData((prev: any) => {
                    return {
                      ...prev,
                      body: undefined,
                      attachments: state.previewElements || [],
                    };
                  });
                } else {
                  if (useString && onChangeBody) {
                    onChangeBody(body);
                    if(onAddOrUpdateAttachment){
                      onAddOrUpdateAttachment(state.previewElements || [])
                    }
                  } else {
                    setSmsTemplateData((prev: any) => {
                      return {
                        ...prev,
                        body: body,
                        template: {
                          ...prev?.template,
                          id: null
                        },
                        smsBodyUpdatedAt : new Date(),
                        attachments: state.previewElements || [],
                      };
                    });
                  }
                }
              }}
            />
            {errors?.body && (
              <ErrorMessage
                _text={{
                  fontSize: 'xs',
                  color: 'error.500',
                  fontWeight: 500,
                }}
              >
                {errors?.body}
              </ErrorMessage>
            )}
            {state.previewElements?.length > 0 && showAttachment && (
              <View style={styles.editorFooterRight}>
                <Pressable
                  style={styles.attachmentButton}
                  onPress={() => {
                    setState((prev) => ({
                      ...prev,
                      isFileListDrawerOpen: true
                    }));
                  }}
                >
                  <AntIcon name="paperclip" color={'white'} />
                  <Text style={styles.attachmentButtonText}>
                    {`${state.previewElements?.length} Attachments`}
                  </Text>
                  <Pressable
                    onPress={() => {
                      setState((prev) => ({
                        ...prev,
                        previewElements: []
                      }));
                    }}
                  >
                    <AntIcon name="close" color={'white'} />
                  </Pressable>
                </Pressable>
              </View>
            )}
            <HelperText
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  color: Colors.muted[500],
                  fontSize: 12,
                  fontWeight: '400',
                  lineHeight: 18,
                }}
              >
                {intl.formatMessage({id: 'smsLengthInfoText'})}
              </Text>
              <Text
                style={{
                  color: Colors.muted[500],
                  fontSize: 12,
                  fontWeight: '400',
                  lineHeight: 18,
                }}
              >
                {`${
                  (smsTemplateData?.body || '').length
                }/${SMS_BODY_MAX_LENGTH}`}
              </Text>
            </HelperText>
          </VStack>
        )}
      </FormControl>

      {state.viewCode === SMSBodyViewCode.Preview && (
        <SMSPreview
          bodyText={
            getParseBody({
              bodyString: smsTemplateData?.body || '',
              mergeTags: getMergeTags(),
            }) || 'Body'
          }
        />
      )}
       {state.attachmentType === ATTACHMENT_TYPE.IMAGE && (
         <MediaSelectionModal
          supportedFormatString={intl.formatMessage({id: 'smsSupportedMediaText'})}
           isOpen={state.attachmentType === ATTACHMENT_TYPE.IMAGE}
           filterKey={'image'}
           isFromSmsTemplate={true}
           filterMediaType={['image']}
           isSms={true}
           onClose={() => {
             setState((prev) => {
               return {
                 ...prev,
                 attachmentType: '',
               };
             });
           }}
           onSelect={async (mediaData) => {
             handleAttachMedia(mediaData);
           }}
         />
       )}
       {state.isFileListDrawerOpen ? (
         <EmailAttachmentDrawer
           fileList={state.previewElements as UploadFile[]}
           setFileList={(newFileList: any) => {}}
           onRemove={(newFileList: any) => {
            setState((prev) => {
              return {
                ...prev,
                isFileListDrawerOpen: newFileList.length === 0 ? false : prev.isFileListDrawerOpen,
                previewElements: newFileList
              };
            });
           }}
           isOpen={state.isFileListDrawerOpen}
           onClose={() => {
             setState((prev) => {
               return {
                 ...prev,
                 isFileListDrawerOpen: false,
               };
             });
           }}
         />
       ) : (
         <></>
       )}
    </View>
  );
};

export default AddOrUpdateSMSTemplatesBody;
