import React from 'react';
import Svg, {Path} from 'react-native-svg';
import { ICommonSvgProps } from './interfaces';

const HistorySvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.size || "32"}
      height={props?.size || "32"}
      viewBox="0 0 32 32"
      fill="none"
    >
      <Path
        d="M16 15.9999H15.5V16.207L15.6464 16.3535L16 15.9999ZM16.5 11.9999C16.5 11.7238 16.2761 11.4999 16 11.4999C15.7239 11.4999 15.5 11.7238 15.5 11.9999H16.5ZM18.1464 18.8535C18.3417 19.0488 18.6583 19.0488 18.8536 18.8535C19.0488 18.6582 19.0488 18.3416 18.8536 18.1464L18.1464 18.8535ZM22.3319 9.6681L21.9783 10.0217L22.3319 9.6681ZM9.60414 9.60414L9.25059 9.25059H9.25059L9.60414 9.60414ZM9.6681 22.3319L10.0217 21.9783L9.6681 22.3319ZM22.3959 22.3959L22.7494 22.7494V22.7494L22.3959 22.3959ZM8.33776 10.8705L7.83777 10.873C7.83914 11.1472 8.06107 11.3691 8.33525 11.3705L8.33776 10.8705ZM10.8808 11.3833C11.1569 11.3847 11.3819 11.162 11.3833 10.8858C11.3847 10.6097 11.162 10.3847 10.8858 10.3833L10.8808 11.3833ZM8.82496 8.32246C8.82357 8.04632 8.59859 7.82359 8.32246 7.82498C8.04632 7.82636 7.82359 8.05134 7.82497 8.32748L8.82496 8.32246ZM7.57892 14.751C7.61642 14.4774 7.42504 14.2252 7.15145 14.1877C6.87787 14.1502 6.62569 14.3416 6.58819 14.6152L7.57892 14.751ZM16.5 15.9999V11.9999H15.5V15.9999H16.5ZM15.6464 16.3535L18.1464 18.8535L18.8536 18.1464L16.3536 15.6464L15.6464 16.3535ZM22.6855 9.31455C18.9612 5.59031 12.9462 5.55497 9.25059 9.25059L9.95769 9.95769C13.2561 6.65929 18.6379 6.68122 21.9783 10.0217L22.6855 9.31455ZM9.31455 22.6855C13.0388 26.4097 19.0538 26.445 22.7494 22.7494L22.0423 22.0423C18.7439 25.3407 13.3621 25.3188 10.0217 21.9783L9.31455 22.6855ZM22.7494 22.7494C26.445 19.0538 26.4097 13.0388 22.6855 9.31455L21.9783 10.0217C25.3188 13.3621 25.3407 18.7439 22.0423 22.0423L22.7494 22.7494ZM9.25059 9.25059L7.98421 10.517L8.69131 11.2241L9.95769 9.95769L9.25059 9.25059ZM8.33525 11.3705L10.8808 11.3833L10.8858 10.3833L8.34027 10.3705L8.33525 11.3705ZM8.83775 10.868L8.82496 8.32246L7.82497 8.32748L7.83777 10.873L8.83775 10.868ZM6.58819 14.6152C6.197 17.4691 7.10791 20.4788 9.31455 22.6855L10.0217 21.9783C8.04386 20.0006 7.22871 17.306 7.57892 14.751L6.58819 14.6152Z"
        fill={props?.customStrokeColor || "#6F7A90"}
      />
    </Svg>
  );
};

export default React.memo(HistorySvg);
