import React from 'react';
import './DraggingView.css';

interface DraggingViewProps {
  label: string;
}

export function DraggingView({ label }: DraggingViewProps) {
  return (
    <div className="dragging-overlay">
      <div className="dragging-overlay__container">
        <p className="dragging-overlay__label">{label}</p>
      </div>
    </div>
  );
}
