import {CaretDownOutlined} from '@ant-design/icons';
import {Select, Skeleton, Spin} from 'antd';
import {View, Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import BaseService from '../../../services/CommonService/BaseService';
import {Colors} from '../../../styles/Colors';
import {IOnCallSchedule, IScheduleSearchProps, ISearchScheduleOnSelectProps} from './interface';
import {useIntl} from 'react-intl';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';
import {getAccountId, getAllowedUserPracticeLocationUuids, isLoggedInUserWorkFlowOrCustomerSuccess, isLoginUserBusinessOwner} from '../../../utils/commonUtils';
import {USER_ACCESS_PERMISSION} from '../UserAccess/UserAccessPermission';
import {MAIN_MENU_CODES} from '../../SideMenuBar/SideBarConst';
import {useLazyQuery} from '@apollo/client';
import {IUsersResponse} from '../CallConfiguration/interface';
import {UserQueries} from '../../../services';

const OnCallScheduleSearch = (props: IScheduleSearchProps) => {
  const intl = useIntl();
  const axios = BaseService.getSharedInstance().axios;
  const [stateData, setStateData] = useState({
    scheduleList: [] as IOnCallSchedule[],
    loading: false as boolean,
    selectedValue: '' as string,
  } as {
    scheduleList: IOnCallSchedule[];
    loading: boolean;
    selectedValue: string;
  });
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);

  const isAllowedAllLocation = isLoginUserBusinessOwner() || isLoggedInUserWorkFlowOrCustomerSuccess();
  const isLocationHandlingEnabled = !isAllowedAllLocation && isMsoEnabled;
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.ADMIN_PANEL_WINDOW.code,
    MAIN_MENU_CODES.CALL
  );
  const accountId = getAccountId();

  const [getUserLocationBasedAccountUserList] = useLazyQuery<IUsersResponse>(
    UserQueries.GET_USER_LOCATION_BASED_ACCOUNT_USER_LIST
  );

  const getSchedules = async () => {
    try {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const res = await axios.get(`/cloud-telephony/api/v1/on-call-schedule`);
      if (res?.data?.length) {
        const onCallScheduleList: IOnCallSchedule[] = res?.data;
        let onCallScheduleFilteredList: IOnCallSchedule[] = [];
        if (props?.selectedPhoneTreeType) {
          onCallScheduleFilteredList = onCallScheduleList?.filter?.((listItem) => {
            return listItem?.phoneTreeTypeId === props?.selectedPhoneTreeType
          })
        }
        if (isLocationHandlingEnabled) {
          const userDataList = await getUserLocationBasedAccountUserList({
            variables: {
              accountId: accountId,
              locationUuids: allowedUserPracticeLocationUuids,
            },
          });
          const usersUuids = userDataList?.data?.users?.map((user)=> user?.uuid) || [];
          onCallScheduleFilteredList = onCallScheduleFilteredList?.reduce<IOnCallSchedule[]>((acc, listItem) => {
            const userIds = listItem?.onCallScheduleDurations?.[0]?.onCallScheduleUsers?.map(user => user?.userId) || [];
            if (props?.value === listItem?.id || usersUuids?.some(userId => userIds?.includes(userId || ''))) {
              acc.push({
                ...listItem,
                isDisabled: props?.value === listItem?.id && !usersUuids.some(userId => userIds.includes(userId || '')),
              });
            }
            return acc;
          }, []);
        }
        setStateData((prev) => {
          return {
            ...prev,
            scheduleList: onCallScheduleFilteredList,
          };
        });
      }
    } catch (error) {
    } finally {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const getScheduleName = () => {
    const schedule = stateData?.scheduleList?.find((schedule) => {
      return stateData?.selectedValue === schedule.id;
    });

    const scheduleName = schedule?.scheduleName || '';
    return scheduleName;
  };

  useEffect(() => {
    if (props.value) {
      setStateData((prev) => {
        return {
          ...prev,
          selectedValue: props.value,
        };
      });
    }
  }, [props.value]);

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <View marginBottom={1}>
      {stateData.loading ? (
        <Skeleton.Button
          active={true}
          size={'default'}
          shape={'default'}
          block={true}
        />
      ) : (
        <Select
          className={props?.isShowError && !props?.value ? 'field-error' : ''}
          dropdownMatchSelectWidth={3}
          size="large"
          allowClear
          suffixIcon={
            <CaretDownOutlined
              style={reactStyles.icon}
            />
          }
          filterOption={true}
          onClear={() => {
            setStateData((prev) => {
              return {
                ...prev,
                selectedValue: '',
              };
            });
            props.onSelect(null);
          }}
          showSearch={true}
          value={getScheduleName()}
          onChange={(_value: string, data: any) => {
            if (data.key && data.value) {
              const scheduleData = stateData.scheduleList?.find?.(
                (scheduleListItem) => {
                  return scheduleListItem?.id === data.key;
                }
              );
              if (scheduleData) {
                const selectedSchedule: ISearchScheduleOnSelectProps = {
                  scheduleId: data.key,
                  scheduleData: scheduleData,
                };
                props.onSelect(selectedSchedule);
                return;
              }
            }
            props.onSelect(null);
          }}
          placeholder={intl.formatMessage({id: 'searchSchedule'})}
          notFoundContent={
            stateData.loading ? (
              <div style={reactStyles.loading}>
                <Spin size="small" />
              </div>
            ) : (
              <Text color={Colors.FoldPixel.GRAY200}>{intl.formatMessage({id: 'noDataFound'})}</Text>
            )
          }
          style={reactStyles.select}
          loading={stateData.loading}
        >
          {stateData?.scheduleList?.map((schedule) => {
            return (
              <Select.Option disabled={schedule?.isDisabled} key={schedule.id} value={schedule.scheduleName}>
                {schedule.scheduleName}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </View>
  );
};

const reactStyles: Record<string, React.CSSProperties> = {
  icon: {
    color: Colors.FoldPixel.GRAY300,
  },
  loading: {
    textAlign: 'center'
  },
  select: {
    height: '36px'
  }
}

export default React.memo(OnCallScheduleSearch);
