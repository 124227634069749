import React from "react";
import { ICommonSvgProps } from "../interfaces";
import Svg, { Path } from "react-native-svg";

const EditActionBtnSvg = (props: ICommonSvgProps) =>{
  return (
    <Svg
      width={props?.size || "24"}
      height={props?.size || "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <Path
        stroke={props?.customStrokeColor || '#D0D5DD'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 20h9M16.5 3.5a2.121 2.121 0 113 3L7 19l-4 1 1-4L16.5 3.5z"
      />
    </Svg>
  );
}

export default EditActionBtnSvg;
