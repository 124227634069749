import React from 'react';
import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';

const InfoCircleV2Svg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props.size || '16'}
      height={props.size || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <G clip-path="url(#clip0_1027_4992)">
        <Path
          d="M7.99992 11.3334V7.33337M7.99992 5.66671V5.33337M14.6666 8.00004C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8.00004C1.33325 4.31814 4.31802 1.33337 7.99992 1.33337C11.6818 1.33337 14.6666 4.31814 14.6666 8.00004Z"
          stroke={props.customStrokeColor || '#6F7A90'}
          stroke-linecap="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1027_4992">
          <Rect
            width={props.size || '16'}
            height={props.size || '16'}
            fill="white"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(InfoCircleV2Svg);
