import { SEARCH_TYPES } from '../../constants/FhirConstant';
import { INTEGRATION_BASE_URL } from '../../constants/BaseUrlConst';
import BaseService from './BaseService';
import LocalStorage from '../../utils/LocalStorage';
import CommonService from './CommonService';
import {ValueSet} from 'fhir/r4';

const commonService = CommonService.getCommonServiceInstance();
const integrationService = commonService.integrationService;

const getHeaders = async () => {
    const userDataString = await LocalStorage.getItem('user');
    const formWidgetDataString = await LocalStorage.getItem('formWidgetData');
    const userData = JSON.parse(userDataString);
    const formWidgetData = formWidgetDataString ? JSON.parse(formWidgetDataString) : '';
    const formWidgetAccessToken = formWidgetData?.formWidgetAccessToken;
    const accountUUID =
        userData?.data.accounts.length > 0
            ? userData?.data.accounts[0].uuid || ''
            : '';
    const responseAccessToken = userData?.accessToken || '';
    const fold_access_token = userData?.fold_access_token;
    const headersObj: any = {
        'access-token': responseAccessToken,
        fold_access_token,
        accountUUID,
    };
    if (!fold_access_token && formWidgetAccessToken && formWidgetAccessToken !== 'undefined') {
        headersObj['form-widget-access-token'] = formWidgetAccessToken;
    }
    return headersObj;
};

export const getEhrList = async (locationId?: string) => {
    const { axios } = BaseService.getSharedInstance();
    const headers = await getHeaders();
    const path = `${INTEGRATION_BASE_URL}/api/codes?searchType=${SEARCH_TYPES.EHR}${locationId ? `&location=${locationId}` : ``}`;
    return axios.get(path, { headers: headers });
};

export const getAccountLocationsWithEhrCode = async (): Promise<any> => {
  const url = 'api/FoldLocations';
  try {
    const resp = await integrationService.get(url, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return resp?.data?.accountLocations || [];
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getPatientEncounterDetailsWithParams = async (params: string) => {
  const headers = await getHeaders();
  const url = `api/google-healthcare/fhir/Encounter?${params}&_sort=-date&_count=1`;
  try {
    const resp = await integrationService.get(url, {
      headers: headers
    });
    return resp?.data || [];
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const getImagingReportLabels = async ({
  searchString,
  limit = 10,
  offset = 0,
}: {
  searchString: string;
  limit?: number;
  offset?: number;
}) => {
  const url = `api/Codes`;
  const headers = await getHeaders();
  try {
    const response = await integrationService.get(url, {
      headers,
      params: {
        searchString,
        limit,
        offset,
        searchType: SEARCH_TYPES.IMAGING_REPORT_LABEL,
      },
    });
    return response?.data as ValueSet;
  } catch (e) {
    return null;
  }
};
