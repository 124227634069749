import {HStack, Text, View, useToast} from 'native-base';
import React, {useCallback, useEffect, useState} from 'react';
import {Colors} from '../../../styles';
import StickyNotesSvg from '../Svg/StickyNotesSvg';
import {TouchableOpacity, ActivityIndicator} from 'react-native';
import StickyNotesApis, {
  CreateStickyNotes,
  UpdateStickyNotes,
} from '../../../services/StickyNotes/StickyNotesApis';
import {getAccountUUID, getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, getLocationGroupIdFromLocationsUsingUuid, mapAndGetLocationGroupIds} from '../../../utils/commonUtils';
import {useLazyQuery} from '@apollo/client';
import {ToastType, showToast} from '../../../utils/commonViewUtils';
import {useIntl} from 'react-intl';
import {IStickyNoteActionView} from './interfaces';
import { AddNoteView } from '../AddNoteView';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { EventBus } from '../../../utils/EventBus';
import { CUSTOM_COMMON_EVENT_CODES } from '../../../constants/WebSocketConst';
import { TestIdentifiers, testID } from '../../../testUtils';
import {getStickyNoteMessageViewStyles} from './StickyNoteMessageViewStyles';
import { CommonDataContext } from '../../../context/CommonDataContext';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { getActivePatientProfile } from '../ContactProfile/commonUtils';

const StickyNoteMessageView = (props: IStickyNoteActionView) => {
  const accountUuid = getAccountUUID();
  const toast = useToast();
  const intl = useIntl();
  const [stateData, setStateData] = useState({
    loading: false,
    bottomPosition: 0,
    isEditing: false,
    stickyText: '',
    stickyNoteData: {} as any,
    maxTextLength: 0,
  });
  const getLocationGroupIdFronAccountLocationId = (locationUuid?: string) => {
    if (!locationUuid) {
      return '';
    }
    return getLocationGroupIdFromLocationsUsingUuid(locationUuid, commonData?.accountLocationListWithEHR)
  }
  const commonData = React.useContext(CommonDataContext);
  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CONSUMER
  );
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );
  const isMultiTenancyEnabled = getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedUserAccountP360LocationGroupIds = mapAndGetLocationGroupIds(allowedUserAccountP360LocationUuids, commonData?.accountLocationListWithEHR);
  const contactProfiles = props?.contactData?.contactProfiles || [];
  const activePatientProfile = getActivePatientProfile(contactProfiles || []);
  const activeContactProfileLocationGroupId = getLocationGroupIdFronAccountLocationId(activePatientProfile?.accountLocationUuid);
  const allowedUserAccountLocationGroupIds = mapAndGetLocationGroupIds(allowedUserAccountLocationUuids, commonData?.accountLocationListWithEHR);
  const haveAccessToActivePatientsLocationGroup = isMultiTenancyEnabled ? allowedUserAccountLocationGroupIds?.includes(activeContactProfileLocationGroupId) : true;
  const [getStickyNote] = useLazyQuery(StickyNotesApis.GetStickyNotes);
  useEffect(() => {
    const containerWidth = (props.headerContainerRef?.current?.clientWidth - 80) || 0;
    if (containerWidth > 0) {
      let newMaxLength = stateData?.stickyText?.length;
      if (containerWidth < stateData?.stickyText?.length * 7.8) {
        newMaxLength = Math.floor(containerWidth / 7.8);
      }
      setStateData((prev) => {
        return {
          ...prev,
          maxTextLength: newMaxLength,
        };
      });
    }
  }, [props.headerContainerRef, props?.customStyle?.width, stateData.stickyText, props?.isSideDetailVisible]);

  const toggleEditing = () => {
    setStateData((prev) => {
      return {
        ...prev,
        isEditing: !prev.isEditing,
      };
    });
  };

  const handleTextChange = (newText: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        stickyText: newText,
      };
    });
  };

  useEffect(() => {
    setStateData((prev) => {
      return {
        ...prev,
        bottomPosition: props.headerContainerRef?.current?.offsetHeight,
        stickyNoteData: props?.stickyNoteData || {},
        stickyText: props?.stickyNoteData?.contactNote?.content.replace(/\n/g, '') || '',
      };
    });
    // if (!props?.stickyNoteData) {
    //   getStickyNotes();
    // }
  }, [props?.stickyNoteData?.noteUuid]);


  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      CUSTOM_COMMON_EVENT_CODES.UPDATE_STICKY_NOTES,
      onUpdateStickyNotes
    );
    return () => {
      eventBus.removeEventListener(onUpdateStickyNotes);
    };
  }, []);

  const onUpdateStickyNotes = useCallback(
    (data: any) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });
      const note = data?.noteData;
      setStateData((prev) => {
        return {
          ...prev,
          stickyNoteData: note,
          stickyText: note?.contactNote?.content.replace(/\n/g, ''),
          loading: false,
        };
      });
    },
    [props?.conversationId]
  );

  const getStickyNotes = async (sendBroadcast?: boolean) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });

    const response = await getStickyNote({
      variables: {
        stickyNotesCondition: {
          contactUuid: {
            _eq: props.contactData?.uuid || ''
          },
          accountUuid: {
            _eq: accountUuid
          },
          ...(isMultiTenancyEnabled && {
            locationGroupId: {
              _in: allowedUserAccountP360LocationGroupIds,
            },
          }),
        }
      },
      fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    }).catch((err) => {
      setStateData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    });
    if (response?.data?.stickyNotes?.length) {
      const stickyNotesList = response?.data?.stickyNotes || [];
      const stickyNoteOfActiveLocation = stickyNotesList?.find((stickyNote: any) => {
        return stickyNote?.locationGroupId === activeContactProfileLocationGroupId && stickyNote?.ehrPatientId === activePatientProfile?.ehrPatientId;
      });
      const stickyNote = stickyNoteOfActiveLocation ? stickyNoteOfActiveLocation : stickyNotesList?.[0];
      if (sendBroadcast) {
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(
          CUSTOM_COMMON_EVENT_CODES.UPDATE_STICKY_NOTES,
          {
            noteData: stickyNote,
          }
        );
      }
      setStateData((prev) => {
        return {
          ...prev,
          stickyNoteData: stickyNote,
          stickyText: stickyNote?.contactNote?.content.replace(/\n/g, ''),
          loading: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          stickyNoteData: {},
          loading: false,
        };
      });
    }
  };

  const addOrUpdateStickyNotes = async (stickyText: string) => {
    setStateData((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let noteResponseData;
    if (stateData?.stickyNoteData?.noteUuid) {
      noteResponseData = await UpdateStickyNotes({
        contactId: props.contactData.id,
        contactUuid: props.contactData?.uuid || '',
        note: stickyText,
        noteUuid: stateData.stickyNoteData.noteUuid,
        locationGroupId: isMultiTenancyEnabled ? activeContactProfileLocationGroupId : undefined,
        ehrPatientId: isMultiTenancyEnabled ? activePatientProfile?.ehrPatientId : undefined,
      }).catch((err) => {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
      } else {
        noteResponseData = await CreateStickyNotes({
          contactId: props.contactData.id,
          contactUuid: props.contactData?.uuid || '',
          note: stickyText,
          locationGroupId: isMultiTenancyEnabled ? activeContactProfileLocationGroupId : undefined,
          ehrPatientId: isMultiTenancyEnabled ? activePatientProfile?.ehrPatientId : undefined
        }).catch((err) => {
        showToast(
          toast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
        setStateData((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
    }

    if (noteResponseData?.data?.contactInfo) {
      if (!stateData?.stickyNoteData?.noteUuid) {
        getStickyNotes(true);
      } else {
        stateData.stickyNoteData.contactNote.content = stickyText;
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent(
          CUSTOM_COMMON_EVENT_CODES.UPDATE_STICKY_NOTES,
          {
            noteData: stateData.stickyNoteData,
          }
        );
      }
      showToast(
        toast,
        intl.formatMessage({id: 'stickyNotesUpdated'}),
        ToastType.success
      );
    }
    setStateData((prev) => {
      return {
        ...prev,
        loading: false,
      };
    });
  };

  const onActionPerformed = (actionCode: string, actionData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.AddNoteAction:
      case COMMON_ACTION_CODES.EditNoteAction:
        handleTextChange(actionData.content)
        addOrUpdateStickyNotes(actionData.content);
        toggleEditing();
        break;
      default:
        toggleEditing();
        break;
    }
  };

  const styles = React.useMemo(
    () =>
      getStickyNoteMessageViewStyles({
        height: props?.customStyle?.height,
        backgroundColor: props?.customStyle?.backgroundColor,
      }),
    [props?.customStyle?.height, props?.customStyle?.backgroundColor]
  );  

  // hiding the sticky note ribbon if there is no sticky note in past user accessible location groups of patient 
  // and also do not have access to active patient location group to create new note.
  if (!stateData?.loading && !stateData?.stickyNoteData?.noteUuid && !haveAccessToActivePatientsLocationGroup) {
    return <></>
  }

  return (
    <View style={styles.container}>
      {stateData.loading ? (
        <View
          flex={1}
          width={'100%'}
          justifyContent={'center'}
          justifyItems={'center'}
          {...testID(TestIdentifiers.lazyLoading)}
        >
          <ActivityIndicator color={Colors.Custom.PrimaryColor} />
        </View>
      ) : (
        <>
          <HStack space={2} alignItems={'center'}>
            <View
              style={styles.stickNoteSvgContainer}
              {...testID('image')}
            >
              <StickyNotesSvg customColor={Colors.Custom.GRAY350} />
            </View>
            <TouchableOpacity onPress={toggleEditing}>
              <Text
                size={'smMedium'}
                fontSize={14}
                fontWeight={400}
                color={props?.customStyle?.textColor || Colors.Custom.GRAY350}
                {...testID(stateData.stickyText?.length ? 'StickyText' : 'AddStickyNotesText')}
              >
                {(stateData.stickyText?.length &&
                  (stateData?.stickyText?.length <= stateData?.maxTextLength
                    ? stateData?.stickyText
                    : `${stateData?.stickyText?.slice(
                        0,
                        stateData?.maxTextLength
                      )}... `)) ||
                  intl.formatMessage({id: props?.stickyNoteLoading ? 'loadingText' : 'addStickyNotesText'})}

                {stateData.stickyText?.length &&
                stateData.stickyText?.length > stateData.maxTextLength ? (
                  <TouchableOpacity onPress={toggleEditing}>
                    <Text {...testID('ReadMore')} style={styles.readMoreText}>
                      {'Read more'}
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <></>
                )}
              </Text>
            </TouchableOpacity>
          </HStack>
        </>
      )}
      {stateData.isEditing && (
        <AddNoteView
          isStickyNote={true}
          selectedData={stateData.stickyNoteData}
          contactId={props.contactData?.id}
          onFormActionPerformed={(actionCode: string, actionData: any) => {
            onActionPerformed(actionCode, actionData);
          }}
        />
      )}
    </View>
  );
};

export default React.memo(StickyNoteMessageView);
