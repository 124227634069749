import { Menu, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Liquid } from 'liquidjs';
import { Button, HStack, useToast, View, VStack } from 'native-base';
import { useEffect, useRef, useState } from 'react';
import ReactAce from 'react-ace/lib/ace';
import AntIcon from 'react-native-vector-icons/AntDesign';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { Colors } from '../../../../../styles';
import { navigateToOtherScreen } from '../../../../../utils/commonUtils';
import { showPhiWarningToast, showToast, ToastType } from '../../../../../utils/commonViewUtils';
import { TableTopBar } from '../../../../common/TableTopBar';
import { CMSLoading } from '../../CMSLoading';
import { ContentTypes, IMPORT_ZIP_WARNING } from '../../ContentManagementConsts';
import {
  createTemplate,
  getAccountMergeTagData,
  getCategoryId,
  getTemplateById,
  getTemplateCategories,
  getTemplateCategoryList,
  updateTemplate,
  uploadZipFile
} from '../../ContentManagementUtils';
import { IMediaLibraryData } from '../../MediaLibrary/interfaces';
import { OverrideModal } from '../../OverrideModal';
import { MediaSelectionModal } from '../../PatientEducation/MediaSelectionModal';
import EmailEditorTopBar from '../EmailEditorTopBar';
import { EmailPreview } from '../EmailPreview';
import {
  emailTemplateValidation,
  getEmailTemplateFormattedObj,
  getFormattedSingleEmailTemplate
} from '../EmailTemplatesUtils';
import { IEmailTemplateData, ITemplateCategory } from '../interfaces';
import EmailHtmlEditor from './EmailHtmlEditor';
import { styles } from './EmailHtmlEditorStyles';
import ImageFinder from './ImageFinder';
import ConfirmOnExitOrReload from '../../../../common/ConfirmExitWindow/ConfirmOnExitOrReload';
import { useIntl } from 'react-intl';

export interface IEditorState {
  viewCode: 'UPLOAD_VIEW' | 'EDITOR_VIEW';
  categoryList: ITemplateCategory[];
  zipUploading: boolean;
  zipFileList: UploadFile<any>[];
  template: IEmailTemplateData;
  initialLoading: boolean;
  overrideModal: boolean;
  mediaSelectionModal: boolean;
  imgFinderModal: boolean;
  currentImg: string;
  allImages: string[];
}

const errorTag = `<h2> Close unclosed variables</h2>`;

const EmailHtmlEditorMainView = (props:{id?:string, isOpenInDrawer?:boolean, onClose?:any, onUpdate?:any}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const [editorState, setEditorState] = useState<IEditorState>({
    viewCode: 'EDITOR_VIEW',
    categoryList: [] as ITemplateCategory[],
    zipUploading: false,
    template: {} as IEmailTemplateData,
    initialLoading: true,
    overrideModal: false,
    mediaSelectionModal: false,
    zipFileList: [] as UploadFile<any>[],
    imgFinderModal: false,
    currentImg: '',
    allImages: [] as string[],
  });

  const ref = useRef<ReactAce>(null);
  const editor = ref.current?.editor;

  const [errors, setErrors] = useState<any>({});

  const toast = useToast();

  const {id} = useParams();
  const templateId = props.id || id;
  const [searchParams] = useSearchParams();
  const isDuplicate = !!searchParams.get('isDuplicate');

  const accountMergeTags = getAccountMergeTagData();

  const hStackMinHight = 70;

  const isObject = (val: any) =>
    val && typeof val === 'object' && !Array.isArray(val);

  useEffect(() => {
    setInitialLoading(true);
    if (isUnZipOption()) {
      setViewCode('UPLOAD_VIEW');
    }

    if (templateId) {
      getTemplateById(ContentTypes.emails.path, templateId)
        .then((data) => getFormattedSingleEmailTemplate(data))
        .then((singleTemplate) => {
          setEditorState((prev) => {
            return {
              ...prev,
              template: singleTemplate,
              htmlString: singleTemplate.templateHtml as string,
            };
          });
          setInitialLoading(false);
        })
        .catch((error) => {
          setInitialLoading(false);

        });
    }

    getTemplateCategories()
      .then((data) => getTemplateCategoryList(data))
      .then((categoryList) => {
        setInitialLoading(false);
        setEditorState((prev) => ({...prev, categoryList: categoryList}));
      });
  }, []);

  const setInitialLoading = (loading: boolean) => {
    setEditorState((prev) => {
      return {
        ...prev,
        initialLoading: loading,
      };
    });
  };

  const setViewCode = (code: 'EDITOR_VIEW' | 'UPLOAD_VIEW') => {
    setEditorState((prev) => {
      return {
        ...prev,
        viewCode: code,
      };
    });
  };

  const setHtmlString = (htmlString: string) => {
    setEditorState((prev) => {
      return {
        ...prev,
        template: {
          ...prev.template,
          templateHtml: htmlString,
        },
      };
    });
  };

  const setOverrideModal = (overrideModal: boolean) => {
    setEditorState((prev) => {
      return {
        ...prev,
        overrideModal: overrideModal,
      };
    });
  };

  const setMediaSelectionModal = (isOpen: boolean) => {
    setEditorState((prev) => {
      return {
        ...prev,
        mediaSelectionModal: isOpen,
      };
    });
  };

  const setZipUploading = (isUploading: boolean) => {
    setEditorState((prev) => {
      return {
        ...prev,
        zipUploading: isUploading,
      };
    });
  };

  const setImageFinderModal = (isOpen: boolean) => {
    setEditorState((prev) => {
      return {
        ...prev,
        imgFinderModal: isOpen,
      };
    });
  };

  const setCurrentImg = (img: string) => {
    setEditorState((prev) => {
      return {
        ...prev,
        currentImg: img,
      };
    });
  };

  const setAllImages = (imgArray: string[]) => {
    setEditorState((prev) => {
      return {
        ...prev,
        allImages: imgArray,
      };
    });
  };

  // API handlers
  const handleZipFileSubmit = () => {
    const {errors, validate} = emailTemplateValidation(editorState.template);
    setErrors(errors);
    if (validate) {
      setZipUploading(true);
      const formData = getFormData();
      uploadZipFile(formData)
        .then((response) => {
          setZipUploading(false);
          zipUploadSuccessHandler(response);
        })
        .catch((error) => {
          setZipUploading(false);
          errorHandler(error);
        });
    }
  };

  const handleSubmit = () => {
    const template = getTemplateForSubmit();

    const {errors, validate} = emailTemplateValidation(template);
    setErrors(errors);
    if (validate) {
      const emailTemplateFormattedObj = getEmailTemplateFormattedObj(
        template,
        editorState.categoryList
      );
      showToast(toast, getUpdateModeTexts().toastText, ToastType.success);
      if (templateId && !isDuplicate) {
        handleUpdateTemplate(emailTemplateFormattedObj);
      } else {
        handleCreateTemplate(emailTemplateFormattedObj);
      }
    }
  };

  const handleOverrideDefault = () => {
    const template = getTemplateForSubmit();

    const emailTemplateFormattedObj = getEmailTemplateFormattedObj(
      template,
      editorState.categoryList,
      true
    );
    showToast(toast, getUpdateModeTexts().toastText, ToastType.success);

    if (templateId) {
      handleUpdateTemplate(emailTemplateFormattedObj);
    } else {
      handleCreateTemplate(emailTemplateFormattedObj);
    }

    setOverrideModal(false);
  };

  const handleOverrideZipDefault = () => {
    const formData = getFormData();
    formData.append('forceDefault', 'true');
    showToast(toast, getUpdateModeTexts().toastText, ToastType.success);
    uploadZipFile(formData)
      .then((response) => {
        setZipUploading(false);
        zipUploadSuccessHandler(response);
      })
      .catch((error) => {

        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
      });
  };

  const handleUpdateTemplate = async (templateData: any) => {
    try {
      const response = await updateTemplate(
        ContentTypes.emails.path,
        templateId,
        templateData
      );
      if (response && !props.isOpenInDrawer) {
        navigateToOtherScreen(navigate, '/admin/contentManagement/emailTemplates');
      } else if(props.isOpenInDrawer && props.onUpdate){
        props.onUpdate(response);
      }

    } catch (error: any) {
      errorHandler(error);
    }
  };

  const handleCreateTemplate = async (templateData: any) => {
    try {
      const response = await createTemplate(
        ContentTypes.emails.path,
        templateData
      );
      if (response && !props.isOpenInDrawer) {
        navigateToOtherScreen(navigate, '/admin/contentManagement/emailTemplates');
      } else if(props.isOpenInDrawer && props.onUpdate){
        props.onUpdate(response);
      }
    } catch (error: any) {
      errorHandler(error);
    }
  };

  const errorHandler = (error: any) => {

    if (
      error.response?.status === 400 &&
      error.response.data?.error === 'Default Template Already Present'
    ) {
      setOverrideModal(true);
    } else {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
  };

  const zipUploadSuccessHandler = (response: any) => {
    const id = response.data?.data?.data?.id;
    const path = `/admin/contentManagement/emailTemplates/html/${id}`;
    navigate(path);
    setInitialLoading(true);
    const singleTemplate = getFormattedSingleEmailTemplate(response.data?.data);
    setEditorState((prev) => {
      return {
        ...prev,
        template: singleTemplate,
        htmlString: singleTemplate.templateHtml as string,
        initialLoading: false,
        viewCode: 'EDITOR_VIEW',
        zipUploading: false,
      };
    });
  };

  const isUnZipOption = (): boolean => {
    return (location?.state as any)?.unZipOption || false;
  };

  const isUploadIconVisible = () => {
    return editorState.zipFileList.length === 0;
  };

  const getTemplateForSubmit = (): IEmailTemplateData => {
    const bodyJson = {
      isHTML: true,
    };
    return {
      ...editorState.template,
      templateData: {
        ...editorState.template.templateData,
        content: bodyJson,
      },
    };
  };

  const getUpdateModeTexts = (): {
    title: string;
    btnText: string;
    toastText: string;
  } => {
    if (templateId && !isDuplicate) {
      return {
        title: 'updateTemplate',
        btnText: 'update',
        toastText: 'Updating...',
      };
    }
    return {
      title: 'createTemplate',
      btnText: 'save',
      toastText: 'Saving...',
    };
  };

  const getMergeTagsForSelectedCategory = () => {
    const category = editorState.template.templateCategory;
    return editorState.categoryList.find((item) => item.name === category)
      ?.mergeTags;
  };

  const getMergeTags = () => {
    return {...getMergeTagsForSelectedCategory(), global: accountMergeTags};
  };

  const getParsedHtml = () => {
    const html = (editorState.template.templateHtml as string) || '';
    const mergeTags = getMergeTags();
    const engine = new Liquid();

    try {
      const tpl = engine.parse(html);
      return engine.renderSync(tpl, mergeTags);
    } catch (error:any) {
      return error?.message || errorTag;
    }
  };

  const getSelectedMergeTag = (keyPath: string[]) => {
    return `{{${keyPath.reverse().join('.')}}}`;
  };

  const getImageTags = (args: {
    imageUrl: string;
    height?: number | null;
    width?: number | null;
  }) => {
    const {imageUrl, height = 150, width = 150} = args;
    return `<img src="${imageUrl}" height="${height}px" width="${width}px" />`;
  };

  const getMenuItems = (object: any): any => {
    return Object.keys(object).map((item: any) => {
      if (isObject(object[item])) {
        return {
          label: item,
          key: item,
          children: getMenuItems(object[item]),
        };
      } else {
        return {
          label: item,
          key: item,
        };
      }
    });
  };

  const getFormData = (): FormData => {
    const formData = new FormData();
    const bodyJson = {
      isHTML: true,
    };
    const categoryId = getCategoryId(
      editorState.template.templateCategory,
      editorState.categoryList
    );

    editorState.zipFileList.forEach((file) => {
      formData.append('files', file.originFileObj as any);
    });
    formData.append('name', editorState.template.templateName);
    formData.append('category', categoryId?.toString() as string);
    formData.append(
      'isDefault',
      editorState.template?.templateIsDefault?.toString()
    );
    formData.append('subject', editorState.template.templateData.subject);
    formData.append('bodyJson', JSON.stringify(bodyJson));

    return formData;
  };

  const getImages = () => {
    const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
    const images = [];
    let img;
    while ((img = imgRex.exec(editorState.template.templateHtml || ''))) {
      images.push(img[1]);
    }
    return images;
  };

  const onHeaderBackClick = () => {
    if (!props.isOpenInDrawer) {
      navigate('/admin/contentManagement/emailTemplates');
    } else if(props.isOpenInDrawer && props.onClose){
      props.onClose();
    }

  };

  const onMenuItemSelected = (info: any) => {
    const mergeTagString = getSelectedMergeTag(info.keyPath);
    insertElement(mergeTagString);
  };

  const onImageSelected = (mediaData: IMediaLibraryData) => {
    const imgTag = getImageTags({
      imageUrl: mediaData.url as string,
      height: mediaData.imageHeight,
      width: mediaData.imageWidth,
    });
    insertElement(imgTag);
    setMediaSelectionModal(false);
  };

  // editor related functions
  const insertTextAtCurrentPosition = (text: string) => {
    const currentPosition = editor?.getCursorPosition();
    const cursorPosition = {
      row: currentPosition?.row as number,
      column: currentPosition?.column as number,
    };
    editor?.session.insert(cursorPosition, text);
  };

  const focusInEditor = () => {
    editor?.focus();
  };

  const replaceSelectedText = (text: string) => {
    editor?.session.replace(editor.selection.getRange(), text);
  };

  const insertElement = (element: string) => {
    if (editor?.getSelectedText()) {
      replaceSelectedText(element);
    } else {
      insertTextAtCurrentPosition(element);
    }
    focusInEditor();
  };

  const replaceTextInHtml = (toReplace: string, toReplaceWith: string) => {
    const currentHtmlString = editorState.template.templateHtml;
    const replacedHtmlString = currentHtmlString?.replace(
      toReplace,
      toReplaceWith
    );
    setHtmlString(replacedHtmlString || '');
  };

  return (
  <ConfirmOnExitOrReload>
    <VStack style={styles.mainContainer}>
      {/* { !props.isOpenInDrawer && <View style={styles.titleContainer}>
       <TitleSubtitleView
          titleLabelId={getUpdateModeTexts().title}
          subtitle="Here you can create new email templates"
          showBackButton={true}
          onBackClick={onHeaderBackClick}
        />
      </View>
      } */}

      <View style={styles.container}>
        <View style={{flex: 1}}>
          {editorState.initialLoading ? (
            <CMSLoading />
          ) : (
            <VStack style={{flex: 1}}>
              <HStack
                // flex={0.08}
                style={{
                  backgroundColor: Colors.Custom.Gray100,
                  paddingHorizontal: 16,
                }}
              >
                <EmailEditorTopBar
                  editorState={editorState}
                  errors={errors}
                  onCancel={() => {
                    if (!props.isOpenInDrawer) {
                      navigateToOtherScreen(navigate, '/admin/contentManagement/emailTemplates');
                    } else if(props.isOpenInDrawer && props.onClose){
                      props.onClose();
                    }

                  }}
                  onSubmit={() => {
                    handleSubmit();
                  }}
                  setEditorState={setEditorState}
                  showSubmitBtn={!isUnZipOption()}
                  submitBtnText={getUpdateModeTexts().btnText}
                />
              </HStack>

              {editorState.viewCode === 'EDITOR_VIEW' && (
                <View style={{padding: 16, flex: 0.92}}>
                  <HStack
                    space={4}
                    style={{
                      height: '100%',
                      borderWidth: 1,
                      borderColor: Colors.Custom.BorderColor,
                      borderRadius: 8,
                      padding: 8,
                      backgroundColor: '#fff',
                    }}
                  >
                    <VStack
                      style={[
                        styles.editorContainer,
                        {
                          backgroundColor: '#fff',
                        },
                      ]}
                    >
                      <View style={{flex: 0.07, backgroundColor: '#fff'}}>
                        <TableTopBar
                          hStackMinHight={hStackMinHight}
                          title="Editor"
                          hideSearchBar
                          buttonList={[
                            {
                              btnText: 'addImages',
                              btnClick() {
                                setMediaSelectionModal(true);
                              },
                              leftIcon: (
                                <AntIcon
                                  name="picture"
                                  size={16}
                                  color={Colors.Custom.mainPrimaryPurple}
                                />
                              ),
                              size: 'sm',
                              textColor: 'white',
                              colorScheme: 'primary',
                              variant: 'PRIMARY',
                            },
                            {
                              btnText: 'replaceImages',
                              btnClick() {
                                setAllImages(getImages());
                                setImageFinderModal(true);
                              },
                              leftIcon: (
                                <AntIcon
                                  name="picture"
                                  size={16}
                                  color={Colors.Custom.mainPrimaryPurple}
                                />
                              ),
                              size: 'sm',
                              textColor: 'white',
                              variant: '',
                            },
                            {
                              btnText: 'addVariables',
                              btnClick() {
                              },
                              id: 2,
                              leftIcon: (
                                <MaterialCommunityIcon
                                  name="code-json"
                                  size={16}
                                  color={Colors.Custom.mainPrimaryPurple}
                                />
                              ),
                              size: 'sm',
                              textColor: 'white',
                              colorScheme: 'primary',
                              variant: '',
                              // dropDownType: 'MENU_DROPDOWN',
                              backgroundColor: Colors.primary['500'],
                              content: (
                                <>
                                  <Menu
                                    items={getMenuItems(getMergeTags())}
                                    selectable={false}
                                    onClick={(info) => {
                                      onMenuItemSelected(info);
                                    }}
                                  />
                                </>
                              ),
                            },
                          ]}
                        />
                      </View>
                      <View style={{flex: 0.93}}>
                        <EmailHtmlEditor
                          ref={ref}
                          htmlString={editorState.template.templateHtml || ''}
                          onChangeHtml={(htmlString) => {
                            setHtmlString(htmlString);
                          }}
                        />
                      </View>
                    </VStack>
                    <VStack
                      style={[
                        styles.previewContainer,
                        {backgroundColor: '#fff'},
                      ]}
                    >
                      <View style={{flex: 0.07}}>
                        <TableTopBar
                          title="Preview"
                          hStackMinHight={hStackMinHight}
                          hideSearchBar
                        />
                      </View>
                      <View style={{flex: 0.93}} padding={5}>
                        <EmailPreview htmlString={getParsedHtml() || ''} />
                      </View>
                    </VStack>
                  </HStack>
                </View>
              )}

              {editorState.viewCode === 'UPLOAD_VIEW' && (
                <VStack style={[styles.uploadViewContainer, {padding: 16}]}>
                  {showPhiWarningToast(IMPORT_ZIP_WARNING, '#F1F4F7')}
                  <Upload
                    onPreview={(file) => {}}
                    beforeUpload={() => false}
                    accept={'.zip'}
                    onChange={(info) => {
                      if (info.fileList.length !== 0) {
                        setEditorState((prev) => {
                          return {
                            ...prev,
                            zipFileList: [...info.fileList],
                          };
                        });
                      }
                    }}
                    maxCount={1}
                    onRemove={(file) => {
                      setEditorState((prev) => {
                        const index = prev.zipFileList.indexOf(file);
                        const newFileList = prev.zipFileList.slice();
                        newFileList.splice(index, 1);
                        return {
                          ...prev,
                          zipFileList: newFileList,
                        };
                      });
                    }}
                  >
                    {isUploadIconVisible() && (
                      <AntIcon name="clouduploado" size={100} />
                    )}
                  </Upload>

                  <Button
                    isLoading={editorState.zipUploading}
                    isDisabled={editorState.zipFileList.length === 0}
                    onPress={() => {
                      handleZipFileSubmit();
                    }}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    Submit
                  </Button>
                </VStack>
              )}
            </VStack>
          )}
        </View>

        <OverrideModal
          isOpen={editorState.overrideModal}
          onClose={() => {
            setOverrideModal(false);
          }}
          textContent={{
            headerText: 'Override Default Template',
            message: intl.formatMessage({
              id: 'overrideDefaultTemplateMessage',
            }),
          }}
          onConfirm={() => {
            if (isUnZipOption()) {
              handleOverrideZipDefault();
            } else {
              handleOverrideDefault();
            }
          }}
        />

        <MediaSelectionModal
          isOpen={editorState.mediaSelectionModal}
          filterKey="image"
          onClose={() => setMediaSelectionModal(false)}
          onSelect={(mediaData) => {
            onImageSelected(mediaData);
          }}
        />

        <ImageFinder
          imgArray={editorState.allImages}
          isOpen={editorState.imgFinderModal}
          onClose={() => setImageFinderModal(false)}
          onImgClick={(imgString) => {
            setCurrentImg(imgString);
          }}
          onNewImgClick={(newImgString) => {
            replaceTextInHtml(editorState.currentImg, newImgString);
            setCurrentImg('');
          }}
        />
      </View>
    </VStack>
  </ConfirmOnExitOrReload>
  );
};

export default EmailHtmlEditorMainView;
