import { Heading, Text } from 'native-base';
import { FormattedMessage } from 'react-intl';
import { isWeb } from '../../../utils/platformCheckUtils';
import { TEXT_TYPE } from './DisplayTextConst';
import { IDisplayText } from './interface';
import React from 'react';
import { testID, TestIdentifiers } from '../../../testUtils';

export const DisplayText = (props: IDisplayText) => {
  const {textLocalId, extraStyles} = props;

  const textType = props.textType || TEXT_TYPE.TEXT;

  const getDisplayText = (): JSX.Element => {
    try{
    const textSize = props.size ? props.size : isWeb() ? 'smMedium' : 'sm';

    let displayTextElem = (
      <Text size={textSize} style={extraStyles || {}} {...testID(props.testID || '')}>
        <FormattedMessage id={textLocalId} />
      </Text>
    );

    switch (textType) {
      case TEXT_TYPE.TEXT: {
        const textSize = props.size ? props.size : isWeb() ? 'smMedium' : 'sm';
        displayTextElem = (
          <Text  size={textSize} style={extraStyles || {}} {...testID(props.testID || TestIdentifiers.customText)}>
            <FormattedMessage id={textLocalId} />
          </Text>
        );
        break;
      }
      case TEXT_TYPE.HEADING: {
        const textSize = props.size ? props.size : isWeb() ? 'lgMedium' : 'lg';
        displayTextElem = (
          <Heading
            size={textSize}
            style={[extraStyles || {}]}
            {...testID(props.testID || '')}
          >
            <FormattedMessage id={textLocalId} />
          </Heading>
        );
        break;
      }
    }
    return displayTextElem;
  }catch(exception){
    return <></>
  }
  };

  return <>{textLocalId ? getDisplayText() : <></>}</>;
};
