export enum CareProgramReasonCodes {
  OTHER = 'other',
}

export enum CareManagementThreeDotsMenuType {
  CLOSE_PROGRAM = 'closeProgram',
}

export enum CareProgramStepCustomCode {
  TCM_CLINICAL_PARAMETERS = 'TCM_CLINICAL_PARAMETERS',
}
