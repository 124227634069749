import React from 'react';
import {IMAGING_REPORT} from '../../../../../constants';
import { IDocument } from '../../../../../services/Document/DocumentInterfaces';
import { ALLOWED_MIME_TYPES_FOR_IMAGING_REPORT } from '../../../../common/DocumentViewer/DocumentViewerHelper';
import {EXTENSION_URLS} from '../../../MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { IReportAndResourceMap } from '../../RecentActivity/OrdersInterface';
import { Extension } from 'fhir/r4';

interface ImagingReportPostJSON {
  resourceType: string;
  subject: {
    reference: string;
  };
  category: Array<{
    coding: Array<{
      system: string;
      code: string;
    }>;
  }>;
  code: {
    text: string;
  };
  extension?: Extension[];
  presentedForm: Array<{
    data: string;
    contentType: string;
  }>;
}

interface ImagingReportParams {
  base64Data: string;
  contentType: string;
  captionName: string;
  patientId: string;
  labelId?: string;
}

export const getImagingReportCreatePostJSON = (
  params: ImagingReportParams
): ImagingReportPostJSON => {
  const {base64Data, contentType, captionName, patientId, labelId} = params;
  return {
    resourceType: IMAGING_REPORT.DIAGNOSTIC_REPORT,
    subject: {
      reference: `Patient/${patientId}`,
    },
    category: [
      {
        coding: [
          {
            system: IMAGING_REPORT.SYSTEM_ATHENA,
            code: IMAGING_REPORT.IMAGING_RESULT,
          },
        ],
      },
    ],
    extension: labelId
      ? [
          {
            url: EXTENSION_URLS.documentTypeId,
            valueInteger: parseInt(labelId),
          },
        ]
      : [],
    code: {text: captionName},
    presentedForm: [
      {
        data: base64Data,
        contentType: contentType,
      },
    ],
  };
};

export const sortReports = (
  reports: (IReportAndResourceMap | IDocument)[],
  desc: boolean | undefined,
  sortBy: string
) => {
  let sortedArray: any[] = [];
  switch (sortBy) {
    case 'date':
      sortedArray = reports.sort((itemA: any, itemB: any) => {
        const date1 = itemA.effectiveDateTime || itemA.updatedOn;
        const date2 = itemB.effectiveDateTime || itemB.updatedOn;
        return !desc
          ? new Date(date1).getTime() - new Date(date2).getTime()
          : new Date(date2).getTime() - new Date(date1).getTime();
      });
      break;
    case 'name':
      sortedArray =reports.sort((itemA: any, itemB: any) => {
        const keyA = itemA.display || itemA.caption;
        const keyB = itemB.display || itemB.caption;
        const comparison = keyA.localeCompare(keyB);
        return !desc ? comparison : comparison * -1;
      });
      break;
    default:
      return reports;
  }
  return sortedArray;
};

export const isUnsupportedImagingFilesType = (files:any) => {
  const isUnsupportedImagingFileType = files.some((file: any) => {
    return (
      file?.fileType &&
      !ALLOWED_MIME_TYPES_FOR_IMAGING_REPORT.includes(file?.fileType)
    );
  });
  return isUnsupportedImagingFileType;
}; 

