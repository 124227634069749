import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IFormCommonData, IFormComponentProps } from '../CustomWrapper/CustomWrapper'
import { IImageState } from './interfaces';
import { IFormValidationOutput } from '../CustomWrapper/interfaces';
import { HStack, Icon, View, Text, Skeleton, Pressable } from 'native-base';
import { Progress, Upload } from 'antd';
import AntIcon from 'react-native-vector-icons/AntDesign';
import { Colors } from '../../../../../../styles';
import { UploadFile } from 'antd/es/upload/interface';
import './ImageStyles.css';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import Feather from 'react-native-vector-icons/Feather';
import { styles } from '../../../Analytics/style';
import { componentKeys } from '../CustomWrapper/CustomComponentHelper';

const Image = (props: IFormComponentProps) => {
  const { component, disabled, fileService, isBuilderMode } = props;
  const imageURL = component?.file?.[0]?.url || props.value;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const isBuilder = isBuilderMode || contextData.isBuilderMode;

  const [componentState, setComponentState] = useState<IImageState>({
    showErrors: false,
    isLoading: false,
    signed: undefined,
    fileList: [],
    progress: 0,
    value: imageURL,
  });

  const setValue = useCallback((value: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        value: value,
      };
    });
  }, []);

  const validateData = (currentData: string): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (props.component?.validate?.required) {
      return { isValid: !!currentData, message: !currentData ? `${component.label} is required` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.setValueRef.current = setValue;
  props.validateRef.current = validateData;

  useEffect(() => {
    // Handle remove case from right side config
    if (imageURL) {
        contextData?.updateLoadingStatus?.(componentKeys.IMAGE, componentState?.signed ? false : true, true);
        fileService.getFileSignedURL(
          imageURL,
          (data: any) => {
            setComponentState((prev) => ({...prev, signed: data.url}));
          },
          () => {
            contextData?.updateLoadingStatus?.(componentKeys.IMAGE, false, true);
          },
          {
            ...(contextData?.headers ?? {}),
            ...(contextData?.proxyHeaders ?? {}),
          }
        );
    } else {
      setComponentState((prev) => ({...prev, signed: undefined}));
      contextData?.updateLoadingStatus?.(componentKeys.IMAGE, false, true);
    }
  }, [imageURL])

  async function uploadFile(files: UploadFile<any>[]) {
    if (!files?.[0]?.originFileObj) {
      return;
    }
    try {
      setComponentState((prev) => ({...prev, isLoading: true}));
      const response = await fileService.uploadFile('base64', files[0].originFileObj, files[0].name, '', (progressEvent: any) => {
        const percent = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        setComponentState((prev) => ({...prev, progress: percent}));
        if (percent === 100) {
          setTimeout(
            () => setComponentState((prev) => ({...prev, progress: 0})),
            2000
          );
        }
      }, undefined, {}, 'file');
      response.originalName = response.fileName;
      component.file = [response];
      setComponentState((prev) => ({...prev, isLoading: false, value: response.url}));
      props.onChange(response.url);
    } catch (error) {
      setComponentState((prev) => ({...prev, isLoading: false}));
    }
  }

  return (
    <View>
      {(!imageURL && isBuilder) && (
        <Upload
          key={component.id}
          disabled={disabled || componentState.isLoading}
          maxCount={1}
          listType="picture-card"
          className="form-image-component"
          beforeUpload={() => false}
          onChange={(info) => {
            const tempFileList = [...info.fileList];
            setComponentState((prev) => ({
              ...prev,
              fileList: tempFileList,
            }));
            uploadFile(tempFileList);
          }}
          onRemove={(file) => {
            setComponentState((prev) => {
              const prevFileList = prev.fileList;
              const fileIndex = prevFileList.indexOf(file);
              const newFileList = prevFileList.slice();
              newFileList.splice(fileIndex, 1);
              return {
                ...prev,
                fileList: newFileList,
              };
            });
          }}
          fileList={componentState.fileList}
          accept={'image/*'}
        >
          {!componentState.fileList.length && (
              <HStack
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Icon
                  as={AntIcon}
                  name="upload"
                  size={19}
                />
                <Text
                  style={styles.marginLeft10}
                >
                  {'Upload Image'}
                </Text>
              </HStack>
            )}
        </Upload>
      )}
      {(!!imageURL && !componentState.signed) && (
        <Skeleton
          width={'100%'}
          height={200}
          borderRadius={8}
         />
      )}
      {!!componentState.signed && (
        <div className={isBuilder ? "form-image-container" : ''}>
          <img
            src={componentState.signed}
            width={component.scale || '100%'}
            height={'auto'}
            style={{
              display: 'block',
              ...(component.align === 'center' && { margin: 'auto' }),
              ...(component.align === 'right' && { marginLeft: 'auto' }),
            }}
            onLoad={() => {
              contextData?.updateLoadingStatus?.(componentKeys.IMAGE, false, true);
            }}
          />
          {isBuilder && (
            <div className="overlay">
              <div>
                <Pressable onPress={() => {
                  component.file = [];
                  setComponentState((prev) => ({...prev, fileList: [], signed: undefined, value: ''}));
                  props.onChange(undefined);
                }}>
                  <HStack space={2}>
                    <Feather name="trash" color={'white'} size={14} />
                    <Text color={'white'} fontWeight={600}>Remove</Text>
                  </HStack>
                </Pressable>
              </div>
            </div>
          )}
        </div>
      )}
      {componentState.progress > 0 ? (
        <Progress percent={componentState.progress} strokeColor={Colors.primary[300]} />
      ) : null}
    </View>
  );
}

export default Image;
