import React from 'react';
import Svg, {Path} from 'react-native-svg';
import {ICommonSvgProps} from '../interfaces';

const MailSquareAvatar = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.width ? props?.width : '32'}
      height={props?.height ? props?.height : '33'}
      fill="none"
      viewBox="0 0 32 33"
    >
      <Path
        fill={props.customBgColor || "#F5F0FF"}
        d="M.25 16.5c0-3.778 0-6.587.29-8.745.29-2.15.863-3.618 1.98-4.735 1.117-1.117 2.584-1.69 4.735-1.98C9.413.75 12.222.75 16 .75c3.778 0 6.587 0 8.745.29 2.15.29 3.618.863 4.735 1.98 1.117 1.117 1.69 2.584 1.98 4.735.29 2.158.29 4.967.29 8.745 0 3.778 0 6.587-.29 8.745-.29 2.15-.863 3.618-1.98 4.735-1.117 1.117-2.584 1.69-4.735 1.98-2.158.29-4.967.29-8.745.29-3.778 0-6.587 0-8.745-.29-2.15-.29-3.618-.863-4.735-1.98C1.403 28.863.83 27.396.54 25.245.25 23.087.25 20.278.25 16.5z"
      ></Path>
      <Path
        stroke={props.borderColor || "#D7C0FF"}
        strokeWidth="0.5"
        d="M.25 16.5c0-3.778 0-6.587.29-8.745.29-2.15.863-3.618 1.98-4.735 1.117-1.117 2.584-1.69 4.735-1.98C9.413.75 12.222.75 16 .75c3.778 0 6.587 0 8.745.29 2.15.29 3.618.863 4.735 1.98 1.117 1.117 1.69 2.584 1.98 4.735.29 2.158.29 4.967.29 8.745 0 3.778 0 6.587-.29 8.745-.29 2.15-.863 3.618-1.98 4.735-1.117 1.117-2.584 1.69-4.735 1.98-2.158.29-4.967.29-8.745.29-3.778 0-6.587 0-8.745-.29-2.15-.29-3.618-.863-4.735-1.98C1.403 28.863.83 27.396.54 25.245.25 23.087.25 20.278.25 16.5z"
      ></Path>
      <Path
        stroke={props.customStrokeColor || "#8C5AE2"}
        strokeLinecap="round"
        d="M11 13.17l1.799 1.498c1.53 1.276 2.296 1.914 3.201 1.914.905 0 1.67-.638 3.2-1.914L21 13.17m-6.667 10h3.334c3.142 0 4.714 0 5.69-.976s.976-2.548.976-5.69c0-3.143 0-4.714-.976-5.69-.976-.977-2.548-.977-5.69-.977h-3.334c-3.142 0-4.714 0-5.69.976-.976.977-.976 2.548-.976 5.69 0 3.143 0 4.715.976 5.691s2.548.976 5.69.976z"
      ></Path>
    </Svg>
  );
};

export default React.memo(MailSquareAvatar);
