import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { Checkbox, Space } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import BasicFields from "../../FHFormio/EditFormFields/BasicFields";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { SelectBoxesSchema } from "../Schema/ComponentsSchema";
import { SelectBoxesComponent } from "../BaseComponents/BaseComponentInterface";
import MultiSelectSvg from "../../../../../assets/Icons/FormBuilder/MultiSelectSvg";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { v4 as uuidV4 } from 'uuid';
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import { CommonDataContext } from "../../../../../context/CommonDataContext";

export const SelectBoxesFieldFormElement: FormElement = {
  type: SelectBoxesSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(SelectBoxesSchema),
    key: getUniqueKey(map, SelectBoxesSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(SelectBoxesSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: MultiSelectSvg,
    label: SelectBoxesSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, value?: {[index: string]: boolean}, silentCheck?: boolean): ValidationResult => {
  if (elementInstance.validate?.required && (!value || Object.keys(value).length === 0)) {
    return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: value };
  }
  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState<{[index: string]: boolean}>({});
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (checkedValues: {[index: string]: boolean}) => {
    const valid = localValidate(elementInstance, checkedValues);
    setErrorMessage(valid.errorMessage);
    setValue(checkedValues);
  }

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        onChange={onChange}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: {[index: string]: boolean};
  isReadOnly?: boolean;
  onRegisterValidation?: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<{[index: string]: boolean}>(defaultValue || selectedValue || elementInstance.defaultValue || []);
  const isMounted = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: {[index: string]: boolean}, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate, silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: {[index: string]: boolean}) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: {[index: string]: boolean}) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: {[index: string]: boolean};
  onChange: (checkedValues: {[index: string]: boolean}) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const { label, validate, description, tooltip, allowToShare, values = [] } = elementInstance as SelectBoxesComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  const handleChange = useCallback((checkedValues: CheckboxValueType[]) => {
    // Convert array of selected values to object format
    const objectValue = values.reduce((acc, { value }) => ({
      ...acc,
      [value]: checkedValues.includes(value)
    }), {});
    onChange(objectValue);
  }, [onChange, values]);

  // Convert object format back to array for Checkbox.Group
  const arrayValue = useMemo(() => defaultValue
    ? Object.entries(defaultValue)
        .filter(([_, isChecked]) => isChecked)
        .map(([value]) => value)
    : [], [defaultValue]);

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      {isReadOnly && !isPrintForm ? (
        <div className="field-value">{arrayValue?.map((value) => values.find((option) => option.value === value)?.label).join(', ') || '-'}</div>
      ) : (
        <div className="flex flex-col gap-2">
          <Checkbox.Group
            onChange={handleChange}
            value={arrayValue}
            data-testid={`selectboxes-field-${elementInstance.key}`}
            disabled={isReadOnly || false}
          >
            <Space direction="vertical">
              {values.map((option, index) => (
                <Checkbox
                  key={option.value}
                  value={option.value}
                  data-testid={`selectboxes-option-${option?.label}`}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
        </div>
      )}
      {description && (
        <Description description={description} />
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as SelectBoxesComponent;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'oldtextfield',
      input: true,
      key: 'placeholder',
      label: 'Placeholder',
      placeholder: 'Placeholder',
      tooltip: 'The placeholder text that will appear when this field is empty.',
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
    {
      type: 'datagrid',
      input: true,
      label: 'Options',
      key: 'values',
      tooltip:
        'The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.',
      weight: 10,
      reorder: true,
      values: [
        {label: 'Option 1', value: 'option1'},
        {label: 'Option 2', value: 'option2'},
      ],
      components: [
        {
          label: 'Label',
          key: 'label',
          input: true,
          type: 'oldtextfield',
        },
        {
          label: 'Value',
          key: 'value',
          input: true,
          type: 'oldtextfield',
          allowCalculateOverride: true,
          calculateValue: 'value = _.camelCase(row.label);',
          hidden: true,
          clearOnHide: false,
          validate: {
            required: true,
          },
        },
      ],
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
