import { View, Pressable } from 'react-native'
import React, { useState } from 'react'
import { HStack, VStack, Text, Divider } from 'native-base';
import { Popover } from 'antd';
import { InterventionStatus, statusConfig } from '../Intervention/AddOrUpdateIntervention/InterventionConstants';
import { Colors } from '../../../../../../styles';
import { CaretDownOutlined } from '@ant-design/icons';
import Feather from 'react-native-vector-icons/Feather';
import ThreeDotsSvg from '../../../../../common/Svg/ThreeDotsSvg';
import { CARE_PLAN_ITEM_STATUS_CODES } from '../../../../../../constants/MlovConst';
import { MEASURE_CRITERIA_STATUS } from '../../../../Contracts/HelperFiles/const';

export enum CarePlanAction {
  STATUS_CHANGE = 'status_change',
  DELETE = 'delete',
  EDIT = 'edit',
}

const CarePlanActionView = (props: {
  uniqueId: string;
  selectedStatusCode: string;
  showStatusChange: boolean;
  showDelete: boolean;
  showEdit: boolean;
  isDisabled: boolean;
  statusOptions: { label: string; value: string, code: string }[];
  forceReadonly?: boolean;
  onActionPerformed: (action: CarePlanAction, uniqueId: string, data?: any) => void;
}) => {
  const { showStatusChange, showDelete, showEdit, uniqueId, isDisabled : disabled, statusOptions, selectedStatusCode, onActionPerformed } = props;
  const [openStatusPopoverId, setOpenStatusPopoverId] = useState<string | null>(null);
  const [openActionPopoverId, setOpenActionPopoverId] = useState<string | null>(null);
  const getStatusLabel = (option:any) => {
    return option.code === CARE_PLAN_ITEM_STATUS_CODES.COMPLETED 
      ? MEASURE_CRITERIA_STATUS.COMPLETE 
      : option.label;
  };
  const isDisabled = disabled || props?.forceReadonly || false;

  const updatedStatusOptions = statusOptions
    .filter(option => 
      option.code !== selectedStatusCode && 
      option.code !== CARE_PLAN_ITEM_STATUS_CODES.OVERDUE
    )
    .map(option => ({ ...option, label: getStatusLabel(option) }));


  return (
    <HStack alignItems={'center'} display={'flex-end'} space={1}>
      {showStatusChange && <Popover
        showArrow={false}
        open={openStatusPopoverId === uniqueId}
        onOpenChange={(visible) => {
          if (isDisabled) {
            return;
          }
          setOpenStatusPopoverId(visible ? uniqueId : null);
        }}
        content={
          <VStack space={2} style={{ padding: '2px', minWidth: 110 }}>
            {updatedStatusOptions.map((option) => (
              <Pressable
                key={option.value}
                onPress={() => {
                  onActionPerformed(CarePlanAction.STATUS_CHANGE, uniqueId, option.value);
                  setOpenStatusPopoverId(null);
                }}
                style={{
                  padding: '8px 16px',
                }}
                >
                <Text
                  color={Colors.Custom.Gray500}
                  fontSize='sm'
                >
                  {option.label}
                </Text>
              </Pressable>
            ))}
          </VStack>
        }
        trigger="click"
        placement="bottom"
      >
        <Pressable
          onPress={() => setOpenStatusPopoverId(uniqueId)}
          disabled={isDisabled}
        >
          <HStack
            style={[
              {
                paddingLeft: 12,
                paddingRight: 4,
                paddingVertical: 3,
                borderRadius: 4,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                minWidth: 120,
              },
              {
                backgroundColor: selectedStatusCode ?
                  statusConfig[selectedStatusCode as keyof typeof statusConfig]?.backgroundColor :
                  statusConfig[InterventionStatus.NOT_STARTED]?.backgroundColor,
                minWidth: isDisabled ? 100 : 120,
              }
            ]}
          >
            <Text
              style={{
                color: selectedStatusCode ?
                  statusConfig[selectedStatusCode as keyof typeof statusConfig]?.textColor :
                  statusConfig[InterventionStatus.NOT_STARTED]?.textColor
              }}
            >
              {selectedStatusCode ?
                statusConfig[selectedStatusCode as keyof typeof statusConfig]?.label :
                statusConfig[InterventionStatus.NOT_STARTED]?.label
              }
            </Text>
            {!isDisabled && (
              <View
                style={{
                  width: 0.5,
                  height: 18,
                  marginLeft: 12,
                  marginRight: 4,
                  backgroundColor: selectedStatusCode ?
                    statusConfig[selectedStatusCode as keyof typeof statusConfig]?.textColor :
                    statusConfig[InterventionStatus.NOT_STARTED]?.textColor
                }}
              />
            )}
            {!isDisabled && (
              <CaretDownOutlined
              style={{
                color: selectedStatusCode ?
                  statusConfig[selectedStatusCode as keyof typeof statusConfig]?.textColor :
                  statusConfig[InterventionStatus.NOT_STARTED]?.textColor
              }}
              />
            )}
          </HStack>
        </Pressable>
      </Popover>}
      {(!props?.forceReadonly && (showDelete || showEdit) && showStatusChange) && <View style={{
        width: 1,
        height: 20,
        marginHorizontal: 4,
        backgroundColor: Colors.FoldPixel.GRAY150
      }}/>}
      {(!props?.forceReadonly && (showDelete || showEdit)) && <Popover
        showArrow={false}
        open={openActionPopoverId === uniqueId}
        onOpenChange={(visible) => {
          setOpenActionPopoverId(visible ? uniqueId : null);
        }}
        content={
          <VStack space={2} style={{ padding: '8px 0', minWidth: 180 }}>
            {showEdit && <Pressable
              disabled={!showEdit}
              onPress={() => {
                onActionPerformed(CarePlanAction.EDIT, uniqueId);
                setOpenActionPopoverId(null);
              }}
              style={{
                padding: '8px 16px',
                opacity: !showEdit ? 0.5 : 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Feather
                name="edit-2"
                color={!showEdit ? Colors.Custom.Gray300 : Colors.Custom.Gray500}
                size={16}
                style={{ marginRight: 8 }}
              />
              <Text color={Colors.Custom.Gray700}>Edit</Text>
            </Pressable>}

            {showDelete && showEdit && <Divider style={{ margin: '4px 0' }} />}

            <Pressable
              disabled={!showDelete}
              onPress={() => {
                onActionPerformed(CarePlanAction.DELETE, uniqueId);
                setOpenActionPopoverId(null);
              }}
              style={{
                padding: '8px 16px',
                opacity: !showDelete ? 0.5 : 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Feather
                name="trash-2"
                color={!showDelete ? Colors.Custom.Gray300 : Colors.Custom.ErrorColor}
                size={16}
                style={{ marginRight: 8 }}
              />
              <Text color={Colors.Custom.ErrorColor}>Delete</Text>
            </Pressable>
          </VStack>
        }
        trigger="click"
        placement="bottomRight"
      >
        <Pressable
          onPress={() => setOpenActionPopoverId(uniqueId)}
        >
          <ThreeDotsSvg
            color={Colors.Custom.Gray500}
            style={{
              cursor: 'pointer',
              width: 20,
              height: 20
            }}
          />
        </Pressable>
      </Popover>}
    </HStack>
  )
}

export default CarePlanActionView
