import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from '../FormComponents/FormComponents';
import Label from '../BaseComponents/Label';
import {getUniqueKey} from '../CustomFormEngineUtils';
import {SubjectiveComplaintSchema} from '../Schema/ComponentsSchema';
import {cloneDeep, debounce} from 'lodash';
import Description from '../BaseComponents/Description';
import {useFormRendererContext} from '../Context/FormRenderer.context';
import {FormRendererActionType} from '../Context/FormRendererReducer';
import {CustomFormBuilderActionTypes} from '../CustomFormEngineInterfaces';
import {useCustomFormBuilderContext} from '../Context/CustomFormBuilder.context';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import {usePropertiesFormRenderer} from '../Hooks/usePropertiesFormRenderer';
import {FormRenderer} from '../FormRenderer';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import TextAreaComponent from '../../FHFormio/CustomComponents/TextAreaComponent/TextAreaComponent';
import QuillConfig from '../../FHFormio/Builder/QuillConfig';
import SearchableComponentFields from '../../FHFormio/EditFormFields/SearchableComponentFields';
import MacroTemplateField from '../../FHFormio/EditFormFields/MacroTemplateField';
import { v4 as uuidV4 } from 'uuid';
import SubjectiveComplaintSvg from '../../../../../assets/Icons/FormBuilder/SubjectiveComplaintSvg';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { IFormCommonData } from '../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper';

export const SubjectiveComplaintFieldFormElement: FormElement = {
  type: SubjectiveComplaintSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(SubjectiveComplaintSchema),
    key: getUniqueKey(map, SubjectiveComplaintSchema.key),
  }),
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(SubjectiveComplaintSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: SubjectiveComplaintSvg,
    label: SubjectiveComplaintSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  isReadOnly,
  validationRef,
}: {
  errorMessage?: string;
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (value: string) => void;
  isReadOnly?: boolean;
  validationRef?: any;
}) {
  const {label, validate, description, tooltip, allowToShare} = elementInstance;
  const isAllowToShare = allowToShare != undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;
  return (
    <div
      className={classNames(
        'flex w-full flex-col gap-1',
        isReadOnly ? (isAllowToShare ? 'page-break' : 'disallow-to-share') : ''
      )}
    >
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      {isReadOnly && !isPrintForm ? (
        <div className="field-value">{ReactHtmlParser(defaultValue || '-')}</div>
      ) : (
        <TextAreaComponent
          component={elementInstance}
          disabled={isReadOnly || false}
          options={{}}
          validateRef={validationRef || {}}
          setValueRef={{}}
          value={defaultValue || ''}
          onChange={debounce(onChange, 500)}
        />
      )}
      {description && <Description description={description} />}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isInitialMount = useRef(true);
  const validationRef = useRef<any>(null);

  const onChange = useCallback(async (newValue: string) => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    setValue(newValue);
    const valid = await validationRef.current?.(newValue);
    setErrorMessage(valid.message);
  }, [elementInstance]);

  return (
    <div className="flex w-full flex-col ">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        onChange={onChange}
        validationRef={validationRef}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation?: (key: string, validate: ValidationFunction) => void;
}) {
  const {state, dispatch} = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState(defaultValue || selectedValue || elementInstance.defaultValue || '');
  const isMounted = useRef(true);
  const isInitialMount = useRef(true);
  const validationRef = useRef<any>(null);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      const result = await validationRef.current?.(valueToValidate || '', silentCheck);
      return {
        isValid: result.isValid ?? true,
        errorMessage: result.message,
        key: elementInstance.key,
        fieldValue: valueToValidate,
      };
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate,
      };
    }
  }, [elementInstance]);

  const debouncedValidation = useMemo(
    () =>
      debounce(async (valueToValidate: string) => {
        if (!isMounted.current) return;
        const result = await validateField(valueToValidate);
        dispatch({
          type: FormRendererActionType.SET_INVALID_FIELDS,
          payload: {
            key: elementInstance.key,
            errorMessage: result.errorMessage,
          },
        });
        return result;
      }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(
    async (newValue: string) => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }
      setValue(newValue);
      if (!submitValue) return;
      await debouncedValidation(newValue);
      submitValue(elementInstance.key, newValue);
    },
    [debouncedValidation, elementInstance.key, submitValue]
  );

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      validationRef={validationRef}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const {state, dispatch, userSettings } = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);

  const formElements = useMemo(
    () => [
      {
        type: 'oldtextfield',
        key: 'label',
        label: 'Label',
        input: true,
        validate: {
          required: true,
        },
      },
      {
        type: 'checkbox',
        key: 'validate.required',
        label: 'Is this field required?',
        input: true,
      },
      ...SearchableComponentFields,
      ...MacroTemplateField,
      {
        type: 'oldtextfield',
        input: true,
        key: 'placeholder',
        label: 'Placeholder',
        placeholder: 'Placeholder',
        tooltip: 'The placeholder text that will appear when this field is empty.',
      },
      {
        type: 'textarea',
        input: true,
        key: 'description',
        label: 'Description',
        placeholder: 'Description for this field.',
        tooltip: 'The description is text that will appear below the input field.',
        editor: 'quill',
        wysiwyg: QuillConfig,
      },
      ...(isAllowShare ? ShareWithPatientFields : []),
      {
        type: 'select',
        input: true,
        key: 'editor',
        label: 'Editor',
        tooltip: 'Select the type of WYSIWYG editor to use for this text area.',
        dataSrc: 'values',
        data: {
          values: [
            {label: 'Plain Text Editor', value: 'plain'},
            {label: 'Rich Text Editor', value: 'quill'},
          ],
        },
      },
      ...NewConditionalFields,
      ...KeyField,
    ], [isAllowShare]);

  const {formData, formattedFormData, components, handleFormDataChange} =
    usePropertiesFormRenderer({
      initialValues: elementInstance as Record<string, any>,
      components: formElements,
    });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: {updatedElement: formData, builderComponents: state.elements},
    });
  }, [formData, elementInstance]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default SubjectiveComplaintFieldFormElement;
