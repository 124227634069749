import {useDroppable} from '@dnd-kit/core';
import React, {useCallback, useMemo} from 'react';
import {FormElementInstance, FormElements} from '../FormComponents/FormComponents';
import { DraggingView } from '../BaseComponents/DraggingView';
import classNames from 'classnames';
import { ColumnsSchema, ContainerSchema } from '../Schema/ComponentsSchema';

export const ComponentDroppableOverlay = ({
  element,
  isDragging,
  children,
  isLastElement,
}: {
  element: FormElementInstance;
  isDragging: boolean;
  children: React.ReactNode;
  isLastElement?: boolean;
}) => {
  const dropZoneConfig = useMemo(
    () => ({
      top: {
        id: `${element.id}-top`,
        data: {
          type: element.type,
          elementId: element.id,
          isFormElementTopHalf: true,
        },
      },
      bottom: {
        id: `${element.id}-bottom`,
        data: {
          type: element.type,
          elementId: element.id,
          isFormElementBottomHalf: true,
        },
      },
    }),
    [element.id, element.type]
  );

  const topHalf = useDroppable(dropZoneConfig.top);
  const bottomHalf = useDroppable(dropZoneConfig.bottom);

  const renderActiveElement = useCallback(() => {
    const activeElementType = topHalf.active?.data?.current?.type;
    const activeElementTypeBottom = bottomHalf.active?.data?.current?.type;
    if (!activeElementType && !activeElementTypeBottom) return null;
    const designerBtnElement = FormElements[(activeElementType || activeElementTypeBottom) as keyof typeof FormElements]?.designerBtnElement;

    return (
      <div className="opacity-30">
        <DraggingView label={designerBtnElement?.label || 'Form Component'} />
      </div>
    );
  }, [topHalf.active?.data?.current, bottomHalf.active?.data?.current]);

  const isLayoutComponent = useMemo(() => [ContainerSchema.type, ColumnsSchema.type].includes(element.type), [element.type]);

  return (
    <>
      <div
        ref={topHalf.setNodeRef}
        className={classNames([
          "absolute w-full rounded-t-md",
          "h-32px"
        ])}
      />
      <div
        ref={bottomHalf.setNodeRef}
        className={classNames([
          "absolute bottom-0 w-full rounded-b-md",
          isLastElement ? "h-1/3" : "h-32px",
        ])}
      />
      {topHalf.isOver && (
        <div className={isDragging ? 'opacity-30' : ''}>
          {renderActiveElement()}
        </div>
      )}
      {children}
      {bottomHalf.isOver && (
        <div className={isDragging ? 'opacity-30' : ''}>
          {renderActiveElement()}
        </div>
      )}
    </>
  );
};
