


import { useLazyQuery } from "@apollo/client";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CARESTUDIO_APOLLO_CONTEXT } from "../../../../constants/Configs";
import { TASK_EVENTS, TASK_MODULE_CODE } from "../CareDashboardConstants";
import { EventBus } from "../../../../utils/EventBus";
import { ITask } from "../CareDashboardInterfaces";
import useDebounceEvent from "./useDebounceEvent";
import { MLOV_CATEGORY, TASK_DUE_DATE_CODES } from "../../../../constants/MlovConst";
import { getCurrentTimeZone } from "../../../../utils/DateUtils";
import { getMlovId } from "../../../../utils/mlovUtils";
import { CommonDataContext } from "../../../../context/CommonDataContext";
import { GET_TASK_COUNTS } from "../../../../services/Task/TaskQueries";
import { usePermissions } from "../../../CustomHooks/usePermissions";
import { USER_ACCESS_PERMISSION } from "../../../RightSideContainer/UserAccess/UserAccessPermission";
import { MAIN_MENU_CODES } from "../../../SideMenuBar/SideBarConst";
import { getBooleanFeatureFlag } from "../../../../utils/commonUtils";
import FeatureFlags from "../../../../constants/FeatureFlags.enums";
import { cloneDeep } from "lodash";
import { TaskViewFrom } from "../CareDashboardTopBar/interfaces";
import { getLocationIdsParamForView } from "./TaskCustomHookHelper";

interface ICountParams {
  code: string;
  filters: any;
  skipCall?: boolean;
  skipTodayCountCall?: boolean;
  disableViewCategoriesCountRefresh?: boolean;
}

export interface ITaskCount {
  code: string;
  count: number;
}

interface IUseTaskCountManagerInterface {
  params: ICountParams[];
  isFetchTodaysCount?: boolean;
  isSideAction?: boolean;
  viewFrom?: TaskViewFrom;
  isP360PatientProfile?: boolean;
}

export interface IUseTaskCountManagerReturn {
  taskCount: ITaskCount[];
  loading: boolean;
  refreshCount: () => void;
  todaysTaskCount: ITaskCount[];
  loadingFilterCodes: string[];
}

const useTaskCountManager = (args: IUseTaskCountManagerInterface): IUseTaskCountManagerReturn => {
  // Global constants
  const eventBus = EventBus.getEventBusInstance();

  // States
  const [taskCount, setTaskCount] = useState<ITaskCount[]>([]);
  const [todaysTaskCount, setTodaysTaskCount] = useState<ITaskCount[]>([]);
  const mlovData = useContext(CommonDataContext);
  const isSidecar = args.isSideAction || args.viewFrom === TaskViewFrom.sidecar;
  const {pushEvent} = useDebounceEvent({
    onDebounce: () => {
      refreshCount();
    }
  });
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const { accountLocationListWithEHR } = mlovData;
  const { check } = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);
  const permissionConfigP360 = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.P360_TASKS);

  const [loadingFilterCodes, setLoadingFilterCodes] = useState<ICountParams['code'][]>([]);

  const primaryLocationIds = accountLocationListWithEHR?.filter((location) => {
    return permissionConfig?.allowedLocationIds?.includes(location?.uuid)
  })?.map((location) => location?.uuid) || [];

  const secondaryLocationIds = accountLocationListWithEHR?.filter((location) => {
    return permissionConfigP360?.allowedLocationIds?.includes(location?.uuid) &&
           !primaryLocationIds.includes(location?.uuid)
  })?.map((location) => location?.uuid) || [];

  const allAllowedLocationIds = [...primaryLocationIds, ...secondaryLocationIds];

  const [getTaskCount, {loading: getTaskCountLoading}] = useLazyQuery(GET_TASK_COUNTS, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
    onCompleted: (apiResponse) => {
      const filterAggregate = apiResponse.getTaskAggregateByFilters?.filterAggregate;

      const normalCountAggregate = filterAggregate.filter((countItem: { filterKey: string, aggregate: number }) => {
        return !countItem?.filterKey.endsWith('/todays-count-filter');
      });

      const countMap: ITaskCount[] = normalCountAggregate?.map((countItem: {
        filterKey: string, aggregate: number
      }, index: number) => ({
        code: countItem?.filterKey,
        count: countItem?.aggregate || 0,
      }));
      const skippedCallCountMap: ITaskCount[] = args?.params
        .filter(countParams => countParams.skipCall)
        .map(countParams => ({
          code: countParams.code,
          count: 0,
        }));
      const finalCountMap = [...countMap, ...skippedCallCountMap];

      setTaskCount(finalCountMap);

      if (args.isFetchTodaysCount) {
        const todaysCountAggregate = filterAggregate.filter((countItem: { filterKey: string, aggregate: number }) => {
          return countItem?.filterKey.endsWith('/todays-count-filter');
        });

        const todaysCountMap: ITaskCount[] = todaysCountAggregate?.map((countItem: {
          filterKey: string, aggregate: number
        }, index: number) => ({
          code: countItem?.filterKey?.split?.('/todays-count-filter')?.[0],
          count: countItem?.aggregate || 0,
        }));

        setTodaysTaskCount(todaysCountMap);
      }
    }
  });

  // Lifecycle methods
  useEffect(() => {
    if (args?.params?.length && !args.params[0].disableViewCategoriesCountRefresh) {
      refreshCount();
    }
  }, [args.params]);

  useEffect(() => {
    eventBus.addEventListener(TASK_EVENTS.TASK_DELETED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.SUB_TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.SUB_TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.TASK_CATEGORY_CHANGED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    eventBus.addEventListener(TASK_EVENTS.REFRESH_TASKS, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });

    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_DELETED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.SUB_TASK_ADDED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.SUB_TASK_UPDATED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.TASK_CATEGORY_CHANGED, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
      eventBus.removeEventListenerByEventName(TASK_EVENTS.REFRESH_TASKS, onTaskAddOrUpdate, { moduleCode: TASK_MODULE_CODE });
    }
  }, []);

  // Public methods
  const refreshCount = () => {
    fetchTaskCountByParams();
  };

  // Event listeners
  const onTaskAddOrUpdate = useCallback((data: {task: ITask, code: string, data?:{new?:any}}) => {
    if (isSidecar) {
      if (data?.data?.new?.assigneeId || data?.data?.new?.statusId) {
        pushEvent();
      }
    } else {
      pushEvent();
    }
  }, [args.params.length]);

  const fetchTaskCountByParams = async () => {
    try {
      const paramList = cloneDeep(args.params);
      let requestPayload: { filterKey: ICountParams['code'], filters: ICountParams['filters'] }[] = [];
      const tempLoadingFilterCodes: ICountParams['code'][] = [];
      paramList.forEach((countParams) => {
        delete countParams.filters.orderBy;
        if (!countParams.skipCall) {
          requestPayload.push({
            filterKey: countParams.code,
            filters: {
              ...countParams.filters,
              ...(args.isP360PatientProfile !== undefined && {isP360PatientProfile: args.isP360PatientProfile})
            }
          });
          tempLoadingFilterCodes.push(countParams.code);
        }
      });
      setLoadingFilterCodes(tempLoadingFilterCodes);

      if (args.isFetchTodaysCount) {
        const todayStatusUuid = getMlovId(
          mlovData.CARE_STUDIO_MLOV,
          MLOV_CATEGORY.TASK_DUE_DATE_CATEGORY,
          TASK_DUE_DATE_CODES.TODAY
        );

        requestPayload = requestPayload.concat(
          paramList.map(countParams => ({
            filterKey: `${countParams?.code}/todays-count-filter`,
            filters: {
              ...countParams.filters,
              ...(args.isP360PatientProfile !== undefined && {isP360PatientProfile: args.isP360PatientProfile}),
              startDateTime: undefined,
              endDateTime: undefined,
              timezone: getCurrentTimeZone(),
              taskDueDateCategoryIds: [todayStatusUuid]
            }
          }))
        );
      }
      getTaskCount({
        variables: { params: { filterList: requestPayload } },
      });
    } finally {}
  };


  return { taskCount, loading: getTaskCountLoading, refreshCount, todaysTaskCount, loadingFilterCodes };
}

export default useTaskCountManager;

