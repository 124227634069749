import React from 'react';
import {useLazyQuery} from '@apollo/client';
import {debounce} from 'lodash';
import {
  Badge,
  Divider,
  Input,
  ScrollView,
  Spinner,
  useToast,
  VStack
} from 'native-base';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {Pressable, useWindowDimensions, View} from 'react-native';
import {getInActiveContactError, MLOV_CATEGORY, PARENT_CODE, PERSON_TYPES, SMALL_WINDOW_1500, TASK_TYPES} from '../../constants';
import {CONFIG_CODES} from '../../constants/AccountConfigConst';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../constants/Configs';
import {CARE_GAP_COUNT_STATUS_CODES, TASK_ALERT_CATEGORY_CODES, TASK_STATUS_CODES} from '../../constants/MlovConst';
import {CommonDataContext} from '../../context/CommonDataContext';
import {FormsQueries, LeadQueries} from '../../services';
import {getActivePatientProfile, getCurrentPatientDeceasedData} from '../../services/CommonService/AidBoxService';
import {GET_AGGREGATE_TASK_COUNT, GET_TASK_ALERT_DETAIL} from '../../services/Task/TaskQueries';
import {Colors} from '../../styles';
import {getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, getCarePlanProps, getPatientActiveProfiles, getSecondaryValue, getUserUUID, isActiveContact, isActiveProfileMatchAllowedLocations, isEnableCareProgram, isLoginUserBusinessOwner, isMemberStatusEnabled, showAlertsInSideCar, showHomeMonitoringInSideCar, showSummaryInSideCar} from '../../utils/commonUtils';
import {showToast, ToastType} from '../../utils/commonViewUtils';
import {isAccountConfigEnabled} from '../../utils/configUtils';
import {
  getCareStudioMlovListByCategoryCode,
  getMlovIdFromCode,
  getMlovListFromCategory
} from '../../utils/mlovUtils';
import {getFormDataFromLeadData} from '../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {formatFormsResponse} from '../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/ClinicalAssessmentHelper';
import {IFormResponse} from '../RightSideContainer/Forms/FHFormio/CustomComponents/ClinicalAssessment/interfaces';
import {IContact, IConversationData} from '../RightSideContainer/TeamInbox/Conversations/interfaces';
import {ITask} from '../common/CareDashboard/CareDashboardInterfaces';
import Stack from '../common/LayoutComponents/Stack';
import SearchIconSvg from '../common/Svg/SearchMessageAllSvg/SearchIconSvg';
import FilterIconSvgForAlerts from '../common/Svg/SideCarSvg/FilterIconSvgForAlerts';
import AddOrUpdateAlert from './AddOrUpdateAlert';
import AlertsView from './AlertsView';
import CareGapDetailView from './CareGapDetailView';
import DiagnosisGapDetailView from './DiagnosisGapDetailView';
import {ITaskAlert} from './SideBarCustomHooks/useFetchAlerts';
import SidecarAssignCareJourney from './SidecarAssignCareJourney';
import {SidecarPatientInfoBanner} from './SidecarPatientInfoBanner';
import {SidecarTitle} from './SidecarTitle';
import InsightsCareBoardView from './components/InsightsCareBoardView';
import InsightsCreateReferral from './components/InsightsCreateReferral';
import InsightsReferralOrdersView from './components/InsightsReferralOrdersView';
import InsightsSummaryView from './components/InsightsSummaryView';
import {ASSESSMENT_FORM_CATEGORY, SIDE_BAR_CARE_MENU_CODES} from './constants';
import {IDiagnosisGap} from './interfaces';

import {notification} from 'antd';
import {useNavigate} from 'react-router-dom';
import {COMMON_ACTION_CODES, PATIENT_QUICK_PROFILE_PARENT_CODES, SIDECAR_ALLOWED_VIEWS} from '../../constants/ActionConst';
import FeatureFlags from '../../constants/FeatureFlags.enums';
import {EventBus} from '../../utils/EventBus';
import {LeftContainerMainView} from '../PersonOmniView/LeftContainer/LeftContainerMainView';
import ContactDetailView from '../PersonOmniView/LeftContainer/OtherDetails/ContactDetailView';
import {TagDataListView} from '../PersonOmniView/LeftContainer/OtherDetails/TagDataListView';
import CarePlan from '../PersonOmniView/MiddleContainer/CarePlan/CarePlan';
import {ICarePlanProps} from '../PersonOmniView/MiddleContainer/CarePlan/interfaces';
import {IContactType, IPersonData} from '../PersonOmniView/PersonHeaderBar/interfaces';
import CareManagement from '../RightSideContainer/CareManagementView/CareManagement';
import ContactTimelineView from '../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ContactTimelineView/ContactTimelineView';
import {isPatientDetailViewAllowed, USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';
import {TaskPanelType} from '../TaskCard/TaskEnum';
import {ITaskFilter} from '../TaskModule/TaskInterfaces';
import {getUserTasksDefaultParams} from '../TaskModule/TaskModuleHelper';
import CustomTabsView from '../common/CareDashboard/CareDashboardTopBar/CustomTabsView';
import {ITabsList, TaskViewFrom} from '../common/CareDashboard/CareDashboardTopBar/interfaces';
import useTaskCountManager from '../common/CareDashboard/CustomHook/useTaskCountManager';
import CrossIconSvg from '../common/Svg/CrossIconSvg';
import {CONVERSATION_CONTACT_TYPES_ARRAY, taskCountCategory} from './SideCarConst';
import {MessageUnreadCount} from './SideCarHelpers/MessageUnreadCount';
import {SidecarDrawerLoadingSkeleton} from './SidecarDrawerLoadingSkeleton';
import SidecarPatientChatManager from './SidecarPatientChatManager';
import {SidecarPatientProfileActions} from './SidecarPatientProfileActions';
import {redirectToNewTab} from './SidecarUtils';
import InsightsPatientNotesView from './components/InsightsPatientNotesView';
import InsightsTasksView from './components/InsightsTasksView';
import {styles} from './styles';
import { MAIN_MENU_CODES } from '../SideMenuBar/SideBarConst';
import {DayOptimizerSelectedColumnCode} from '../common/AppDashboard/DayOptimizer/DayOptimizerConstants';
import useLoggedInUserAllowedLocationsByTab from '../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import PersonOmniViewProvider, { usePersonOmniViewContext } from '../PersonOmniView/PersonOmniView.context';
import { usePersonOmniView } from '../PersonOmniView/CustomHooks/usePersonOmniView';
import { PersonOmniViewActionType } from '../PersonOmniView/PersonOmniViewReducer';
interface ISidecarDrawerProps {
  onClose: () => void;
  isInsightsDrawerVisible?: boolean;
  onActionClick?: (action: string) => void;
  contactId: string;
  selectedAction: string;
  isFoldView?: boolean;
  reasonForPrioritization?: string[];
  reasonForPrioritizationQA?: string[];
  sideCarDashboardEnabled?: boolean;
  containerHeight?: number;
  eligiblePrograms?: string[];
  conversationData?: IConversationData;
  parentCode?: string;
  showDetailProfile?: boolean;
  contactType?: any;
  showCharts?: boolean;
  patientProfile?: boolean;
  patientHieSyncCall?: boolean;
  hideAlertFilterAndSearch?: boolean;
  showEditProfileOnPatientInfoBanner?: boolean;
  selectedColumnCode?: DayOptimizerSelectedColumnCode;
  isP360PatientProfile?: boolean;
}

export interface ISidecarInsightsDrawerState {
  forms: IFormResponse[];
  showFiltersTabInAlerts: boolean;
  alertsFilterCount: number;
  alertsCount: number;
  showSearchBar: boolean;
  searchText: string;
  contactData?: IContact;
  contactType?: IContactType;
  formattedContactData?: IContact | any;
  isLoading?: boolean;
  selectedTab: string;
  showAssignCareJourney: boolean;
  showReferralOrders: boolean;
  showCreateOrEditReferral: boolean;
  referralData: any;
  selectedCareGapDetail?:  ITaskAlert;
  taskPanelType: TaskPanelType
  showAddOrUpdateAlert: boolean;
  alertType?: string;
  task?: ITask;
  selectedDiagnosisGapDetail?: IDiagnosisGap;
  taskCountCategories: {key: string, code: string, name: string, filters: ITaskFilter}[];
  messageCount: number;
}

enum IInsightsTabs {
  SUMMARY = 'summary',
  DASHBOARD = 'dashboard',
  ALERTS = 'alerts',
  REFERRAL = 'referral',
  REFERRALADD = 'referralAdd',
  CHAT = 'chat',
  TASKS = 'tasks',
  PATIENT_NOTES = 'patientNotes',
  CARE_MANAGEMENT = 'careManagement',
  INSIGHTS = 'insights',
  ACTIVITY = 'activity',
  PROFILE = 'profile'
}

export const SidecarInsightsDrawer = (props: ISidecarDrawerProps) => {
  const isCompactView = props?.parentCode === PATIENT_QUICK_PROFILE_PARENT_CODES.MESSAGING;
  const {contactId} = props;
  const intl = useIntl();
  const mlovData = useContext(CommonDataContext);
  const {data: contextContactData, formattedData: contextFormattedData, ehrPatientId: contextEhrPatientId, dispatch} = usePersonOmniViewContext();
  const {contactData, formattedContactData, ehrPatientId, loading, dispatch: sideCarInsightsDispatch, fetchAllData} = usePersonOmniView({contactId});
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );
  const allowedUserAccountP360LocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.P360_CONSUMER
  );
  const isCareProgramEnabled = isEnableCareProgram(mlovData.userSettings);
  const isEFaxMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_E_FAX_COMMUNICATION);
  const isReferralEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_REFERRAL_ENABLED)
  const memberStatusEnabled = isMemberStatusEnabled();
  const showSummary = showSummaryInSideCar();
  const showHomeMonitoring = showHomeMonitoringInSideCar();
  const showAlerts = showAlertsInSideCar();
  const toast = useToast();
  const userUuid = getUserUUID();
  const headerContainerRef: any = useRef();
  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;
  const [isNavigatingToCarePlan, setIsNavigatingToCarePlan] = useState(false);
  const currentUserUUID = getUserUUID();
  const {height} = useWindowDimensions();
  const currentUserId = mlovData?.userData?.id;
  const { width } = useWindowDimensions();
  const isSmallScreen = width < SMALL_WINDOW_1500;
  const showOnlyInternalChatInSidecar = isAccountConfigEnabled(CONFIG_CODES.SHOW_ONLY_INTERNAL_CHAT_IN_SIDECAR);
  const [renderedTabs, setRenderedTabs] = useState<string[]>([]);
  const [tabs, setTabs] = useState<ITabsList[]>([]);
  const [loadingTabs, setLoadingTabs] = useState<string[]>([]);
  const showManualAddGaps = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.SHOW_MANUAL_ADD_GAPS);

  const getCurrentView = () =>
    props.showEditProfileOnPatientInfoBanner || props?.patientProfile
      ? SIDECAR_ALLOWED_VIEWS.PATIENT_PROFILE
      : props?.isFoldView
      ? SIDECAR_ALLOWED_VIEWS.DAY_OPTIMIZER
      : isSidecarContext
      ? SIDECAR_ALLOWED_VIEWS.SIDECAR
      : SIDECAR_ALLOWED_VIEWS.PATIENT_QUICK_VIEW;

  const isPatientDetailViewAllowedConfig = isPatientDetailViewAllowed()

  const loadingTimeoutRef = useRef<NodeJS.Timeout>();

  const getDefaultSelectedTab = (contactTypeCode?: string) => {
    const currentContactType = contactTypeCode || props?.contactType?.code;
    if (currentContactType && (currentContactType !== PERSON_TYPES.CUSTOMER)) {
      return IInsightsTabs.TASKS;
    }
    if (props.selectedColumnCode == DayOptimizerSelectedColumnCode.ALERT) {
      return IInsightsTabs.ALERTS;
    }
    if(showAlerts) {
      return IInsightsTabs.ALERTS;
    }
    if(props.sideCarDashboardEnabled && isCareProgramEnabled) {
      return IInsightsTabs.INSIGHTS;
    }
    if(props.sideCarDashboardEnabled && isCareProgramEnabled) {
      return IInsightsTabs.INSIGHTS;
    }

    if(showSummary) {
      return IInsightsTabs.SUMMARY;
    }
    if(showHomeMonitoring) {
      return IInsightsTabs.DASHBOARD;
    }
    return IInsightsTabs.TASKS;
  }
  const navigate = useNavigate();

  const [componentState, setComponentState] =
    useState<ISidecarInsightsDrawerState>({
      forms: [],
      isLoading: true,
      selectedTab: getDefaultSelectedTab(contextContactData?.contactType?.contactType?.code),
      showFiltersTabInAlerts: false,
      alertsFilterCount: 0,
      alertsCount: 0,
      showSearchBar: false,
      searchText: '',
      showAssignCareJourney: false,
      showCreateOrEditReferral: false,
      showReferralOrders: false,
      selectedCareGapDetail: {} as ITaskAlert,
      showAddOrUpdateAlert: false,
      alertType: '',
      task: {} as ITask,
      selectedDiagnosisGapDetail: {} as IDiagnosisGap,
      referralData: {},
      taskPanelType: TaskPanelType.INTERNAL,
      taskCountCategories: [],
      messageCount: 0
    });

  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.CONSUMER);
  const activecontactProfiles = getPatientActiveProfiles(contactData?.contactProfiles || []);
  const hasActiveProfile = isActiveProfileMatchAllowedLocations(activecontactProfiles || [], allowedLocationIds)
  const isActionsDisabled = !isLoginUserBusinessOwner(mlovData) && isMultiTenancyEnabled ? !hasActiveProfile : false;

  const { taskCount } = useTaskCountManager({
    params: componentState.taskCountCategories,
    isFetchTodaysCount: false,
    viewFrom: TaskViewFrom.sidecar,
  });

  const taskCountNumber = taskCount?.length ? taskCount[0]?.count : 0;

  const carePlanProps = getCarePlanProps(formattedContactData, contactData, setIsNavigatingToCarePlan, props?.isFoldView);

  const taskAlertDisplayCategoryMlovs =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_ALERT_DISPLAY_CATEGORIES
    ) || [];

  const isSidecarPatientContext = mlovData?.sidecarContext?.isPatient;
  const [GetContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });


  const taskStatusMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_STATUS)
  const taskStatusListForFilter = taskStatusMlovs?.filter((mlov)=> {
    return [TASK_STATUS_CODES.MISSED,TASK_STATUS_CODES.ACCEPTED].includes(mlov.code)
  })
  const taskPriorityMlovs = getCareStudioMlovListByCategoryCode(MLOV_CATEGORY.TASK_PRIORITY);
  const careGapCountStatusMlovsIds = taskStatusMlovs
    .filter((item) => CARE_GAP_COUNT_STATUS_CODES.includes(item.code))
    .map((mlov) => mlov.id);

  const [getPatientForms] = useLazyQuery(
    FormsQueries.GET_FORM_RESPONSES_SCORE_BY_FORM_CATEGORY,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  const [getAlertsCount, {loading: alertsCountLoading}] = useLazyQuery(
    GET_AGGREGATE_TASK_COUNT,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      variables: {
        params: {
          assigneeIds: [userUuid],
          includeOnlyAlerts: true,
          contactIds: [contactData?.uuid],
          taskAlertDisplayCategoryIds: getMlovIdFromCode(
            taskAlertDisplayCategoryMlovs,
            TASK_ALERT_CATEGORY_CODES.PENDING
          ),
          ...(
            isMultiTenancyEnabled ? {
              accountLocationIds: allowedUserAccountP360LocationUuids
            } : {}
          ),
          statusIds: careGapCountStatusMlovsIds
        },
      },
      onCompleted: (response) => {
        setComponentState((prev) => {
          return {
            ...prev,
            alertsCount: response?.getTasks?.aggregate?.total || 0,
          };
        });
      },
    }
  );
  const [getAlertsDetail, {loading: alertDetailLoading}] = useLazyQuery(
    GET_TASK_ALERT_DETAIL,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
      onCompleted: (response) => {
        const task = response?.getTasks?.tasks?.[0];
        setComponentState((prev) => {
          return {
            ...prev,
            showAddOrUpdateAlert: true,
            task: task
          };
        });
      },
    }
  );

  useEffect(()=> {
    if(contactData?.uuid) {
      const categories = taskCountCategory.map((item: any) => {
      const params = getUserTasksDefaultParams({
        selectedTab: '',
        topBarData: {
          selectedStatusList: taskStatusListForFilter,
          selectedPriorityList: taskPriorityMlovs,
          selectedContactUuid: [{value: contactData?.uuid}],
        } as any,
        taskStatusList: taskStatusListForFilter,
        taskPriorityList: taskPriorityMlovs,
        defaultUserId: currentUserUUID,
        allUsersPool: [],
        searchString: '',
        boardType: null as any,
        categoryCode: item.code
      });
      return {
        key: item.code,
        code: item.code,
        name: item.name,
        filters: params
      }
      });


      setComponentState((prev)=> {
        return {
          ...prev,
          taskCountCategories: categories
        }
      })
    }

    const contactIdNumber = Number(contactId) || 0;

    if(!contactIdNumber){
      return;
    }

    const eventBus = EventBus.getEventBusInstance();
    const messageUnreadCountInstance = new MessageUnreadCount({
      conversationTypesArray: CONVERSATION_CONTACT_TYPES_ARRAY,
      eventBus: eventBus,
      userUuid: currentUserUUID,
      contactId: contactIdNumber,
      userId: currentUserId,
      updateMessageCountCallback: updateMessageCountCallback,
      selectedConversationUuid: ""
    });

    const fetchAndSetMessageCount = async () => {
      await messageUnreadCountInstance.fetchInitialMessageCountForPatientContext();
      setComponentState((prev) => ({
        ...prev,
        messageCount: messageUnreadCountInstance.getMessageCount(),
      }));
    };

    fetchAndSetMessageCount();
    messageUnreadCountInstance.addEventListener();
  },[contactData?.uuid]);

  const updateMessageCountCallback = (newCount: number) => {
    setComponentState((prev) => ({
      ...prev,
      messageCount: newCount,
    }));
  };

  useEffect(() => {
    getContactData(contactId);
  }, [props.contactId]);

  useEffect(() => {
    setComponentState((prev) => {
      return {
        ...prev,
        selectedTab: getDefaultSelectedTab(contactData?.contactType?.contactType?.code)
      };
    });
  }, [props.selectedColumnCode]);

  useEffect(() => {
    if(contactData?.uuid) {
      updateRenderedTabs(getDefaultSelectedTab(contactData?.contactType?.contactType?.code));
      setTabs(insightTabs || []);
    }
  },[contactData?.uuid]);

  useEffect(() => {
    setUpdatedTabsCount();
  }, [taskCountNumber, componentState?.alertsCount, componentState?.messageCount]);

  const setUpdatedTabsCount = () => {
    const countMapping = {
      [IInsightsTabs.TASKS]: taskCountNumber,
      [IInsightsTabs.ALERTS]: componentState?.alertsCount,
      [IInsightsTabs.CHAT]: componentState?.messageCount
    };

    const countTooltipMapping = {
      [IInsightsTabs.ALERTS]: `${componentState?.alertsCount} pending alerts`,
    }

    const updatedTabs = tabs.map(item => ({
      ...item,
      count: countMapping[item.key as keyof typeof countMapping] ?? item.count,
      countTooltip: countTooltipMapping[item.key as keyof typeof countTooltipMapping] ?? item.countTooltip
    }));   ``
    setTabs(updatedTabs || []);
  }

  const getContactData = async (contactId: string) => {
    if (!contactId) {
      return;
    }

    setComponentState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    try {
      let contactResponseData =
        contextContactData?.id?.toString() === contactId
          ? contextContactData
          : ({} as IContact);
      if (
        sideCarInsightsDispatch &&
        contactResponseData?.id &&
        contextEhrPatientId
      ) {
        sideCarInsightsDispatch({
          type: PersonOmniViewActionType.SET_CONTACT_DATA,
          payload: {
            contactData: contactResponseData,
            formattedContactData: contextFormattedData,
            ehrPatientId: contextEhrPatientId,
          },
        });
      } else {
        contactResponseData = await fetchAllData();
      }

      if (contactResponseData?.id) {
        setComponentState((prev) => ({
          ...prev,
          isLoading: false,
          selectedTab: getDefaultSelectedTab(contactResponseData?.contactType?.contactType?.code || contextContactData?.contactType?.contactType?.code)
        }));
        getAlertsCount();
        getCurrentPatientDeceasedData(contactResponseData);
        getPatientFormsData(contactResponseData?.uuid);
      }
    } catch (error) {
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };

  const handleCreateCarePlanClick = useCallback(() => {
    if (isSidecarContext) {
      const path = `/members/patient/${formattedContactData?.id}/careManagement/all`
      redirectToNewTab(path);
    } else {
      setIsNavigatingToCarePlan(true);
    }
  }, [formattedContactData?.id]);

  const getPatientFormsData = async (contactId: string) => {
    try {
      const response = await getPatientForms({
        variables: {
          contactId: contactId,
          category: ASSESSMENT_FORM_CATEGORY,
        },
      });
      const forms = formatFormsResponse(response?.data?.forms);
      setComponentState((prev) => {
        return {
          ...prev,
          forms,
        };
      });
    } catch (error) {}
  };
  const onAlertAdd = (type: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        showAddOrUpdateAlert: true,
        alertType: type
      };
    });
  }

  const editCareGapAlert = async (alert: any, taskType?: string) => {
    await getAlertsDetail({
      variables: {
        params: {
          ids: [alert?.id],
          includeAlerts: true
        }
      }
    })
    setComponentState((prev) => {
      return {
        ...prev,
        alertType: taskType
      };
    });
  }

  const openCareGapDetail = (alert: ITaskAlert) => {
    if (alert?.referenceData?.executionId || !showManualAddGaps) {
      setComponentState(prev => {
        return {
          ...prev,
          selectedCareGapDetail: alert
        }
      });
    } else {
      editCareGapAlert(alert, TASK_TYPES.CARE_GAPS)
    }
  };

  const groupMemberData = {
    groupUserId: contactData?.uuid,
    groupUserTypeId:
      formattedContactData?.contactType?.contactTypeId,
  };

  const canShowChatOption = () => {
    if (!isSidecarPatientContext) {
      return true;
    }
    return isSidecarPatientContext && !showOnlyInternalChatInSidecar;
  }

  const insightTabs = useMemo(() => {
    const items: ITabsList[] = [];
    const contactTypeCode = contactData?.contactType?.contactType?.code || props?.contactType?.code;

    if (showAlerts && contactTypeCode === PERSON_TYPES.CUSTOMER) {
      items.push({
        key: IInsightsTabs.ALERTS,
        title: intl.formatMessage({id: 'alerts'}),
        countLoading: alertsCountLoading,
        count: componentState?.alertsCount,
        countTooltip: `${componentState?.alertsCount} pending alerts`,
        containerElement: (
          <View style={{paddingHorizontal: 12, paddingVertical: 12}}>
            <AlertsView
              onAlertAdd={onAlertAdd}
              editCareGapAlert={editCareGapAlert}
              openCareGapDetail={openCareGapDetail}
              reasonForPrioritization={props?.reasonForPrioritization}
              reasonForPrioritizationQA={props?.reasonForPrioritizationQA}
              openDiagnosisGapDetail={(diagnosisGap) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    selectedDiagnosisGapDetail: diagnosisGap,
                  };
                });
              }}
              contactId={contactData?.uuid}
              showFiltersTabInAlerts={componentState?.showFiltersTabInAlerts}
              onFilterApplied={(
                filterCount: number,
                isResetFilter?: boolean
              ) => {
                setComponentState((prev) => ({
                  ...prev,
                  alertsFilterCount: filterCount,
                  ...(isResetFilter && {showFiltersTabInAlerts: false}),
                }));
              }}
              searchText={componentState.searchText}
              formattedContactData={formattedContactData}
              contactData={contactData}
              isCompactView={props.isFoldView}
              onPendingCountChange={(count: number) => {
                setComponentState((prev) => {
                  return {
                    ...prev,
                    alertsCount: count,
                  };
                });
              }}
            />
          </View>
        ),
      });
    }

    if (props.sideCarDashboardEnabled && isCareProgramEnabled && contactTypeCode === PERSON_TYPES.CUSTOMER) {
      items.push({
        key: IInsightsTabs.INSIGHTS,
        title: intl.formatMessage({ id: 'insights' }),
        containerElement: (
          <VStack>
            <LeftContainerMainView
              unformmatedContactData={contactData}
              personType={PERSON_TYPES.CUSTOMER}
              personData={formattedContactData as unknown as IPersonData}
              currentView={props.showEditProfileOnPatientInfoBanner ? 'patientProfile' : props?.isFoldView ? 'dayOptimizer' : isSidecarContext ? 'sidecar' : 'patientListing'}
              sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
            />
          </VStack>
        ),
      });
    }

    const taskCountNumber = taskCount?.length ? taskCount[0]?.count : 0
    if(true) {
      items.push({
        key: IInsightsTabs.TASKS,
        title: intl.formatMessage({id: 'tasks'}),
        count: taskCountNumber,
        containerElement: (
          <InsightsTasksView
            contactData={contactData}
            formattedContactData={formattedContactData}
            onTaskPanelChanged={(taskPanelType) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  taskPanelType: taskPanelType,
                };
              });
            }}
            currentView={getCurrentView()}
          />
        ),
      });
    }


    if (showHomeMonitoring && contactTypeCode === PERSON_TYPES.CUSTOMER) {
      items.push({
        key: IInsightsTabs.DASHBOARD,
        title: intl.formatMessage({id: 'dashboard'}),
        containerElement: (
          <InsightsCareBoardView
            contactData={contactData}
            formattedContactData={formattedContactData}
            isSidecarContext={isSidecarContext}
          />
        ),
      });
    }

    if (canShowChatOption()) {
      items.push({
        key: IInsightsTabs.CHAT,
        title: intl.formatMessage({id: 'communication'}),
        count: componentState?.messageCount,
        containerElement: (
          <View style={{height: height - (props.isFoldView ? 280 : 220)}}>
            <SidecarPatientChatManager
              isDefaultClose={false}
              groupMemberData={groupMemberData as any}
              parentCode={PARENT_CODE.MESSAGING_DETAIL_PANEL}
              style={{marginBottom: 10, borderWidth: 0}}
              shouldDisableLimit={true}
              contactData={contactData}
              conversationData={props?.conversationData}
            />
          </View>
        ),
      });
    }

    if (isCareProgramEnabled && (isSidecarContext || props.isFoldView || !props?.showEditProfileOnPatientInfoBanner) && contactTypeCode === PERSON_TYPES.CUSTOMER) {
      items.push({
        key: IInsightsTabs.PATIENT_NOTES,
        title: intl.formatMessage({id: 'patientNotes'}),
        containerElement: (
          <InsightsPatientNotesView
            contactData={contactData}
            formattedContactData={formattedContactData}
            onTaskPanelChanged={(taskPanelType) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  taskPanelType: taskPanelType,
                };
              });
            }}
          />
        ),
      });
    }


    if (isEFaxMessagingEnabled && (isSidecarContext || props.isFoldView) && contactTypeCode === PERSON_TYPES.CUSTOMER) {
      items.push({
        key: IInsightsTabs.REFERRAL,
        title: intl.formatMessage({id: 'referral'}),
        containerElement: (
          <InsightsReferralOrdersView
            formattedContactData={formattedContactData}
            contactData={contactData}
            createReferralClicked={(val: any) => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showCreateOrEditReferral: true,
                  referralData: val,
                };
              });
            }}
            onBack={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  selectedTab: IInsightsTabs.REFERRAL,
                  showReferralOrders: false,
                };
              });
            }}
            heightOffsetForContainer={props.isFoldView && props?.sideCarDashboardEnabled ? 450 : props.isFoldView ? 325  : isSidecarContext ? 364 : 0}
          />
        ),
      });
    }

    if (isCareProgramEnabled && contactData && (isSidecarContext || props.isFoldView || !props?.showEditProfileOnPatientInfoBanner) && contactTypeCode === PERSON_TYPES.CUSTOMER) {
      items.push({
        key: IInsightsTabs.CARE_MANAGEMENT,
        title: intl.formatMessage({id: 'careManagement'}),
        forceReloadOnEveryTabOpen: true,
        containerElement: (
          <CareManagement
            contactId={contactData?.id}
            contactUuid={
              contactData?.uuid
            }
            eligiblePrograms={props?.eligiblePrograms}
            isCompactView
          />
        ),
      });
    }
    if (showSummary) {
      items.push({
        key: IInsightsTabs.SUMMARY,
        title: intl.formatMessage({id: 'summary'}),
        containerElement: (
          <InsightsSummaryView
            forms={componentState.forms}
            contactData={contactData}
            formattedContactData={formattedContactData}
            sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
            assignJourneyOptionClicked={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showAssignCareJourney: true,
                };
              });
            }}
            isCompactView={isCompactView}
            conversationData={props?.conversationData}
            currentView={props.showEditProfileOnPatientInfoBanner ? 'patientProfile' : props?.isFoldView ? 'dayOptimizer' : isSidecarContext ? 'sidecar' : 'patientListing'}
          />
        ),
      });
    }
    if (props?.sideCarDashboardEnabled) {
      items.push({
        key: IInsightsTabs.ACTIVITY,
        title: intl.formatMessage({id: 'activity'}),
        containerElement: (
          <ContactTimelineView contactData={contactData as IContact} />
        ),
      });
    }

    if (props?.sideCarDashboardEnabled) {
      items.push({
        key: IInsightsTabs.PROFILE,
        title: intl.formatMessage({ id: 'profile' }),
        containerElement: (
          <View>
            <ContactDetailView
              hintExtraDetail={null}
              isDefaultClose={false}
              contactData={contactData}
              onlyShowInnerContent={true}
              handleEmail={() => {}}
              onActionPerformed={() => {}}
              handleCloudCall={() => {}}
              handleEdit={() => {}}
              navigate={() => {}}
              contactType={formattedContactData?.contactType?.contactType}
            />
          </View>
        ),
      });
    }

    return items;
  }, [
    contactData?.uuid,
    componentState?.showFiltersTabInAlerts,
    componentState.forms?.length,
    componentState.searchText,
    componentState.alertsCount,
    componentState.messageCount,
    taskCountNumber,
    componentState.alertsFilterCount,
    ehrPatientId,
    componentState.isLoading
  ]);
  const careGapDetailView = () => {
    return (
      <CareGapDetailView
        editCareGapAlert={(alert: any) => editCareGapAlert(alert, TASK_TYPES.CARE_GAPS)}
        contactUuid={contactData?.uuid || formattedContactData?.uuid || ''}
        onBackClick={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedCareGapDetail: {} as ITaskAlert,
            };
          });
          setTabReLoading(IInsightsTabs.ALERTS, true);
        }}
        careGapDetail={componentState.selectedCareGapDetail || {} as ITaskAlert}
      />
    );
  };
  const addOrUpdateAlert = () => {
    return <AddOrUpdateAlert
    alertType={componentState.alertType}
    formattedContactData={formattedContactData}
    task={componentState.task}
    onBackClick={() => {
      setTabReLoading(IInsightsTabs.ALERTS, true);
      setComponentState((prev) => {
        return {
          ...prev,
          showAddOrUpdateAlert: false,
          task: {} as ITask
        };
      });
    }} />
  }

  const diagnosisGapDetailView = () => {
    return (
      <DiagnosisGapDetailView
        onClose={() => {
          setComponentState((prev) => {
            return {
              ...prev,
              selectedDiagnosisGapDetail: {} as IDiagnosisGap,
            };
          });
          setTabReLoading(IInsightsTabs.ALERTS, true);
        }}
        contactData={contactData}
        diagnosisGapDetail={componentState.selectedDiagnosisGapDetail || {} as IDiagnosisGap}
      />
    )
  }

  const hideAlertFilterAndSearch = props.hideAlertFilterAndSearch || false;
  const showFilterIcon =
    componentState?.selectedTab === SIDE_BAR_CARE_MENU_CODES.ALERTS && !hideAlertFilterAndSearch;
  const showSearchIcon =
    componentState?.selectedTab === SIDE_BAR_CARE_MENU_CODES.ALERTS && !hideAlertFilterAndSearch;

  const getBottomPadding = () => {
    if(componentState.selectedTab === IInsightsTabs.CHAT) {
      return 0;
    }
    return 20;
  }

  const getPaddingLeft = () => {
    const excludedTabs = [
      SIDE_BAR_CARE_MENU_CODES.ALERTS,
      SIDE_BAR_CARE_MENU_CODES.TASKS,
      SIDE_BAR_CARE_MENU_CODES.REFERRAL,
      SIDE_BAR_CARE_MENU_CODES.CHAT
    ];

    return excludedTabs.includes(componentState.selectedTab) ? 0 : 6;
  };

  const updateRenderedTabs = (code: string, updateTabIndex?: boolean) => {
    const isRenderedTabPresent = renderedTabs.find(item => item === code);
    const updatedRenderedTabs = renderedTabs;
    if(!isRenderedTabPresent) {
      const updatedTab = insightTabs.find(item => item.key === code);
      if(updatedTab) {
        updatedRenderedTabs.push(code);
        setRenderedTabs(updatedRenderedTabs);
      }
    }

    if(updateTabIndex) {
      const tabIndex = tabs.findIndex(item => item.key === code);
      if(tabIndex !== -1 && updateTabIndex) {
        const toIndex = tabs?.length > 2 ? 3 : 2;
        const updatedTabs = moveTabs(tabs, tabIndex, toIndex);
        setTabs(updatedTabs);
      }
    }
  }

  const isTabContentViewVisible = (code: string) => {
    if(isDetailViewVisible()) {
      return false;
    }
    return code === componentState.selectedTab ? true : false;
  }

  const getDetailViewHeight = () => {
    return height - (props.isFoldView ? 280 : 140);
  }

  const isDetailViewVisible = () => {
    return componentState.showAssignCareJourney || componentState.showCreateOrEditReferral || componentState.selectedCareGapDetail?.id || componentState.selectedDiagnosisGapDetail?.id || isNavigatingToCarePlan || componentState.showAddOrUpdateAlert;
  }

  const setTabReLoading = (code: string, isLoading: boolean) => {
    if (loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
    }

    updateTabLoading(code, isLoading);

    loadingTimeoutRef.current = setTimeout(() => {
      updateTabLoading(code, false);
    }, 500);
  }

  const updateTabLoading = (code: string, isLoading: boolean) => {
    if(isLoading) {
      setLoadingTabs((prev) => {
       return [...prev, code];
      });
    }
    else {
      setLoadingTabs((prev) => {
        return prev.filter(item => item !== code);
      });
    }
  }

  useEffect(() => {
    // Cleanup function
    return () => {
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };
  }, []);

  const getDetailView = () => {
    return (
      <ScrollView contentContainerStyle={styles.height100p} style={styles.scrollViewStyles}>
        {componentState.showAssignCareJourney && (
          <SidecarAssignCareJourney
            contactData={contactData}
            onComplete={(record: any) => {
              setComponentState((prev) => ({
                ...prev,
                selectedTab: IInsightsTabs.SUMMARY,
                showAssignCareJourney: false,
              }));
              setTabReLoading(IInsightsTabs.SUMMARY, true);
            }}
            onBack={() => {
              setComponentState((prev) => ({
                ...prev,
                showAssignCareJourney: false,
              }));
            }}
          />
        )}
        {componentState.showCreateOrEditReferral && (
          <InsightsCreateReferral
            contactData={contactData}
            formattedContactData={formattedContactData}
            preview={componentState.referralData?.preview}
            note={componentState?.referralData?.note}
            receiverDetails={componentState?.referralData?.receiverDetails}
            documents={componentState?.referralData?.documents}
            reasonForReferral={componentState?.referralData?.reasonForReferral}
            isEdit={componentState?.referralData?.isEdit}
            details={componentState?.referralData?.details}
            inboxId={componentState?.referralData?.inboxId}
            setCreateReferralFormVisible={componentState?.referralData?.setCreateReferralFormVisible}
            onBack={() => {
              componentState.referralData.onBack();
              setComponentState((prev) => ({
                ...prev,
                showCreateOrEditReferral: false,
              }));
              setTabReLoading(IInsightsTabs.REFERRAL, true);
            }}
            onComplete={() => {
              componentState.referralData.onBack();
              setComponentState((prev) => ({
                ...prev,
                selectedTab: IInsightsTabs.REFERRAL,
                showCreateOrEditReferral: false,
              }));
              setTabReLoading(IInsightsTabs.REFERRAL, true);
            }}
            heightOffsetForContainer={props.isFoldView && props?.sideCarDashboardEnabled ? 450 : props.isFoldView ? 325  : isSidecarContext ? 364 : 0}
          />
        )}
        {isNavigatingToCarePlan &&(
            <View style={{flex: 1}}>
              <CarePlan {...(carePlanProps as ICarePlanProps)} />
            </View>
        )}
        {componentState.selectedCareGapDetail?.id && !componentState.showAddOrUpdateAlert && careGapDetailView()}
        {componentState.selectedDiagnosisGapDetail?.id && !componentState.showAddOrUpdateAlert && diagnosisGapDetailView()}
        {componentState.showAddOrUpdateAlert && addOrUpdateAlert()}
      </ScrollView>
    );
  };

  const onSearch = (value: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        searchText: value,
      };
    });
  }

  const moveTabs = (tabs: ITabsList[], fromIndex: number, toIndex: number) => {
    const itemRemoved = tabs.splice(fromIndex, 1)
    tabs.splice(toIndex, 0, itemRemoved[0])
    return tabs;
  }
  const onPersonActionPerformed = (actionCode: string, data: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADDED_OR_UPDATED:
        setComponentState((prev) => ({
          ...prev,
          contactData: prev.contactData ? {
            ...prev.contactData,
            contactConsents: data?.updateData?.contactConsents
          } : undefined
        }));
        break;
      default:
        break;
    }
  }
  return (
    <>
      {componentState.isLoading || loading ? (
          <SidecarDrawerLoadingSkeleton handleCloseClick={props?.onClose} shouldShowCloseButton={props?.isFoldView} />
      ) : (
        <PersonOmniViewProvider value={{
            formattedData: formattedContactData || {} as IPersonData,
            data: contactData || {} as IContact,
            ehrPatientId: ehrPatientId,
            dispatch: dispatch || sideCarInsightsDispatch,
        }}>
        <Stack direction="column" style={{flex: 1}}>
          {isSidecarPatientContext && (
            <SidecarTitle
              onClose={props?.onClose}
              patientUuid={contactData?.uuid}
              titleId="insights"
              paddingVertical={6}
              showCreateNewButton={true}
              contactInfo={contactData}
              isActionsDisabled={isActionsDisabled}
            />
          )}
          <Stack
            direction="row"
            style={{
              justifyContent: 'space-between',
              paddingLeft: 16,
              paddingTop: props?.sideCarDashboardEnabled ? 0 : 16,
            }}
          >
            <Stack
              direction="row"
              style={{
                width: '40%',
                flex: 1,
                paddingBottom: 16,
              }}
            >
              {contactData && (
                <View ref={headerContainerRef} style={{flex: 1}}>
                  <SidecarPatientInfoBanner
                    sideCarDashboardEnabled={props?.sideCarDashboardEnabled}
                    handleCreateCarePlanClick={handleCreateCarePlanClick}
                    contactData={contactData}
                    headerContainerRef={headerContainerRef}
                    showAssignJourney={true}
                    assignJourneyOptionClicked={() => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showAssignCareJourney: true,
                        };
                      });
                    }}
                    referralOrderOptionClicked={() => {
                      setComponentState((prev) => {
                        return {
                          ...prev,
                          showReferralOrders: true,
                          selectedTab: IInsightsTabs.REFERRAL,
                        };
                      });
                      updateRenderedTabs(IInsightsTabs.REFERRAL, true);
                    }}
                    showDetailProfile={props?.showDetailProfile}
                    showCloseButton={props?.isFoldView}
                    handleCloseClick={props?.onClose}
                    isCompactView={props?.isFoldView || props?.sideCarDashboardEnabled}
                    taskPanelType={componentState?.taskPanelType}
                    contactType={props?.contactType}
                    patientHieSyncCall={props?.patientHieSyncCall}
                    onShowDetails={() => {
                      const currentStatus = isActiveContact(contactData)
                      if (!currentStatus) {
                        const message = getInActiveContactError(contactData)
                        notification.error({
                          message,
                        });
                        return
                      }
                      if (
                        isPatientDetailViewAllowedConfig && contactData?.contactType?.contactType?.code == PERSON_TYPES.CUSTOMER
                      ) {
                        navigate(`/members/patient/${contactData?.id}`);
                      } else {
                        navigate(`/members/prospect/${contactData?.id}`, {
                          state: { contactData: contactData },
                        });
                      }
                    }}
                    currentView={getCurrentView()}
                  />
                </View>
              )}
            </Stack>
          </Stack>
          <Divider />
          {props?.sideCarDashboardEnabled && <TagDataListView
            taggableType={'Contact'}
            contactData={contactData}
            conversationData={props?.conversationData}
            sideCarDashboardEnabled={props.sideCarDashboardEnabled}
            showMemberStatus={memberStatusEnabled}
            isSidecarContext={isSidecarContext}
            currentView={getCurrentView()}
          />}
           <Divider />
          {props?.sideCarDashboardEnabled &&
          <SidecarPatientProfileActions
            taggableType={'Contact'}
            contactInfo={contactData}
            onPersonActionPerformed={onPersonActionPerformed}
            conversationData={props?.conversationData}
            sideCarDashboardEnabled={props.sideCarDashboardEnabled}
            showMemberStatus={memberStatusEnabled}
            phoneNumber={
              contactData?.phoneNumber ||
              getSecondaryValue(contactData?.personContact, 'phone') ||
              ''
            }
            email={
              contactData?.email ||
              getSecondaryValue(contactData?.personContact, 'email') ||
              ''
            }
            showPhoneCall={!showOnlyInternalChatInSidecar}
            showVideoCall={!showOnlyInternalChatInSidecar}
            showSms={true}
            shouldShowEmail={true}
            showDetailProfile={props?.showDetailProfile}
            showCharts={props?.showCharts}
            parentCode={props?.parentCode}
            currentView={getCurrentView()}
            showAssignJourney={true}
            assignJourneyOptionClicked={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showAssignCareJourney: true,
                };
              });
            }}
            referralOrderOptionClicked={() => {
              setComponentState((prev) => {
                return {
                  ...prev,
                  showReferralOrders: true,
                  selectedTab: IInsightsTabs.REFERRAL,
                };
              });
              updateRenderedTabs(IInsightsTabs.REFERRAL, true);
            }}
          />}
          <Divider/>
          <>
            {isDetailViewVisible() ? (
              <></>
            ) : componentState.showSearchBar ? (
              <Stack
                direction={'row'}
                style={{
                  alignItems: 'center',
                  // marginVertical: 4,
                  marginHorizontal: 12,
                }}
              >
                <Input
                  placeholder={'Search tasks by title or description'}
                  onChangeText={debounce(onSearch, 500)}
                  width={'100%'}
                  height={'36px'}
                  leftElement={
                    <View style={{marginLeft: 8}}>
                      <SearchIconSvg
                        size={18}
                        customStrokeColor={Colors.FoldPixel.GRAY200}
                      />
                    </View>
                  }
                  placeholderTextColor={Colors.FoldPixel.GRAY300}
                  backgroundColor={Colors.Custom.White}
                  rightElement={
                    <Pressable
                      style={{
                        height: 30,
                        width: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        setComponentState((prev) => ({
                          ...prev,
                          showSearchBar: false,
                          searchText: '',
                        }));
                      }}
                    >
                      <CrossIconSvg
                        customStrokeColor={Colors.FoldPixel.GRAY300}
                        size={20}
                      />
                    </Pressable>
                  }
                />
              </Stack>
            ) : (
              insightTabs?.length > 1 && (
                <>
                    <Stack
                      direction={'row'}
                      style={{
                        alignItems: 'center',
                        marginVertical: 0,
                        marginLeft: 0,
                        paddingRight: props?.conversationData?.id ? 0 : 16,
                        borderBottomWidth: 0.5,
                        borderBottomColor: Colors.FoldPixel.GRAY150,
                      }}
                    >
                      {tabs?.length > 0 && (
                        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                        <CustomTabsView
                          innerWidth={isSidecarContext ? 700 : isSmallScreen ? 500 : 700}
                          canNavigate={false}
                          tabsList={tabs}
                          isDisabled={false}
                          isCompactView={true}
                          minScreenSupportWidth={1800}
                          isAddMaxTab={true}
                          tabListWrapperStyle={{
                            marginHorizontal: 0,
                            paddingHorizontal: 8,
                          }}
                          selectedTabKey={componentState.selectedTab}
                          numberOfElements={4}
                          leftOffset={0}
                          onTabClick={(
                            tabItem,
                            index?: number,
                            lastIndexInVisibleList?: number
                          ) => {
                            const code = tabItem.key;
                            if (code === IInsightsTabs.ALERTS) {
                              getAlertsCount();
                            }
                            setComponentState((prev) => {
                              return {
                                ...prev,
                                selectedTab: code,
                                alertsFilterCount: 0,
                                searchText: '',
                                taskPanelType: TaskPanelType.INTERNAL,
                              };
                            });
                            if (
                              lastIndexInVisibleList ||
                              lastIndexInVisibleList === 0
                            ) {
                              const tabIndex = tabs.findIndex(item => item.key === code);
                              const lastTabs = moveTabs(tabs, tabIndex, lastIndexInVisibleList);
                              setTabs(lastTabs);
                            }
                            updateRenderedTabs(code);
                          }}
                        />
                        </ScrollView>
                      )}

                      <View style={{flex: 1}} />
                      {componentState.selectedTab ===
                        SIDE_BAR_CARE_MENU_CODES.ALERTS && false && (
                        <>
                          {showFilterIcon && (
                            <>
                              <Pressable
                                onPress={() => {
                                  setComponentState((prev) => {
                                    return {
                                      ...prev,
                                      showFiltersTabInAlerts:
                                        !prev.showFiltersTabInAlerts,
                                    };
                                  });
                                }}
                              >
                                {
                                  <Stack
                                    direction={'column'}
                                    style={{position: 'relative'}}
                                  >
                                    {componentState.alertsFilterCount > 0 && (
                                      <>
                                        {
                                          <Badge
                                            mb={-2}
                                            position={'absolute'}
                                            top={-4}
                                            right={-10}
                                            mt={
                                              componentState.alertsFilterCount > 0
                                                ? -2
                                                : 0
                                            }
                                            zIndex={100}
                                            variant="solid"
                                            backgroundColor={
                                              Colors.Custom?.badgeColorOrange
                                            }
                                            alignSelf="flex-end"
                                            size={'smMedium'}
                                            style={{
                                              paddingVertical: 1,
                                              paddingHorizontal: 4,
                                            }}
                                            _text={{
                                              fontSize: 9,
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {componentState.alertsFilterCount}
                                          </Badge>
                                        }
                                      </>
                                    )}
                                    <FilterIconSvgForAlerts />
                                  </Stack>
                                }
                              </Pressable>
                              <View
                                style={{
                                  width: 1,
                                  height: 20,
                                  backgroundColor: Colors.Custom.Gray200,
                                  marginHorizontal: 12,
                                }}
                              ></View>
                            </>
                          )}
                          {showSearchIcon && (
                            <Pressable
                              onPress={() => {
                                setComponentState((prev) => ({
                                  ...prev,
                                  showSearchBar: true,
                                }));
                              }}
                            >
                              <SearchIconSvg
                                customStrokeColor={Colors.FoldPixel.GRAY300}
                                strokeWidth={1}
                              />
                            </Pressable>
                          )}
                        </>
                      )}
                    </Stack>
                    {componentState?.showFiltersTabInAlerts && <Divider />}
                </>
              )
            )}
            {insightTabs
              ?.filter((item) => renderedTabs.includes(item.key))
              ?.map((item) => {
                const isLoading = loadingTabs.includes(item.key);
                const reloadEveryTime = item.forceReloadOnEveryTabOpen || false;
                return (
                  <ScrollView
                    key={item.key}
                    style={{
                      display: isTabContentViewVisible(item.key)
                        ? 'flex'
                        : 'none',
                      backgroundColor: Colors.FoldPixel.GRAY50,
                      ...(componentState.selectedTab !== 'referral' && {
                        paddingBottom: 60,
                      }),
                      paddingBottom: props?.isFoldView
                        ? getBottomPadding()
                        : 60,
                      paddingLeft: getPaddingLeft(),
                      height: props?.isFoldView ? 400 : props.sideCarDashboardEnabled && props.containerHeight ? props.containerHeight : undefined,
                    }}
                    scrollEnabled={
                      componentState.selectedTab !==
                      SIDE_BAR_CARE_MENU_CODES.TASKS
                    }
                  >
                    {isLoading ? (
                      <View
                        style={{
                          height: getDetailViewHeight(),
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Spinner size={'lg'} />
                      </View>
                    ) : (
                      !reloadEveryTime ? item.containerElement : isTabContentViewVisible(item.key) ? (
                        item.containerElement
                      ) : null
                    )}
                  </ScrollView>
                );
              })}
            {isDetailViewVisible() && getDetailView()}
          </>
        </Stack>
        </PersonOmniViewProvider>
      )}
    </>
  );
};


