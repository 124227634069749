import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Pressable, View} from 'react-native';
import {Text} from 'native-base';
import {MLOV_CATEGORY} from '../../../constants/MlovConst';
import {
  getMlovCodeFromId,
  getMlovListFromCategory,
  getMlovValueFromId,
} from '../../../utils/mlovUtils';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {usePersonOmniViewContext} from '../../PersonOmniView/PersonOmniView.context';
import CPStatusBadge from '../ContactCareProgram/ContactCareProgramView/components/CPStatusBadge';
import Stack from '../../common/LayoutComponents/Stack';
import {IContactCareProgramCard} from './interface';
import {Checkbox, Divider, Progress, Tooltip} from 'antd';
import {useIntl} from 'react-intl';
import {
  calculateProgressPercentage,
  getCardMainTitle,
  getLastUpdateText,
  getSubTitleText,
} from './CareManagementUtils';
import {antStyles, styles} from './CareManageMentViewStyles';
import {Colors} from '../../../styles';
import CareProgramMoreActionView from './CareProgramMoreActionView';
import useCareProgramStepStatus from '../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStepStatus';
import {useCareProgramStatus} from '../ContactCareProgram/ContactCareProgramView/hooks/useCareProgramStatus';
import {useContactCareProgramCardAdditionalData} from './CareManagementViewHooks/useContactCareProgramCardAdditionalData';
import {DATE_FORMATS} from '../../../constants/StringConst';
import moment from 'moment';
import AppointmentDetail from '../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';
import Feather from 'react-native-vector-icons/Feather';
import { testID } from '../../../testUtils/Utils';
import { TestIdentifiers } from '../../../testUtils';
import { CARE_MANAGEMENT_CATEGORY_CODES } from '../CareManagementBilling/CareManagementConstants';
import { redirectToNewTab } from '../../SideCar/SidecarUtils';

const ContactCareProgramCard = (props: IContactCareProgramCard) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {code: activeTabCode} = useParams();
  const commonContextData = useContext(CommonDataContext);
  const intl = useIntl();
  const [state, setState] = useState({
    isLoading: false,
    assigneeUserData: {} as any,
    careProgramActivityLogs: {} as any,
    showAppointmentDetailDrawer: false,
  });

  const careProgramTypesList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.CARE_PROGRAM_TYPES
    ) || [];

  const {careProgramStepStatus} = useCareProgramStepStatus();
  const {careProgramStatus, careProgramStatusList} = useCareProgramStatus();
  const {getContactCareProgramAdditionalData, haveAccessToCareProgramAccountLocation, isMultiTenancyEnabled} =
    useContactCareProgramCardAdditionalData({contactCareProgram: props?.contactCareProgram});

  const forceReadonly = !haveAccessToCareProgramAccountLocation;

  const progressPercentage = useMemo(() => calculateProgressPercentage(
    careProgramStepStatus,
    props?.contactCareProgram?.stepsLog
  ), [props?.contactCareProgram?.stepsLog]);

  const {data: contactData} = usePersonOmniViewContext();

  const careProgramTypeCode = getMlovCodeFromId(
    careProgramTypesList,
    props?.contactCareProgram?.payerCareProgram?.careProgramTypeId
  );
  const careProgramDisplayValue = getMlovValueFromId(
    careProgramTypesList,
    props?.contactCareProgram?.payerCareProgram?.careProgramTypeId
  );
  const careProgramStatusCode = getMlovCodeFromId(
    careProgramStatusList,
    props?.contactCareProgram?.statusId
  );
    
  const checkCompletedCareProgramFunction = () => {
    const completedStatus = [
      careProgramStatus?.completed,
      careProgramStatus.autoClosed,
      careProgramStatus.closed,
    ].includes(props?.contactCareProgram?.statusId)

    if (isMultiTenancyEnabled && !completedStatus) {
      return forceReadonly;
    }
    
    return completedStatus;
  }

  const checkCompletedCareProgram = checkCompletedCareProgramFunction();

  const handeSelectCareProgram = (id: string) => {
    let path = pathname;
    if (props?.isCompactView) {
      path = `/members/patient/${props?.contactId}/careManagement`;
    }

    let pathLink;

    if (activeTabCode) {
      pathLink = (`${path}/${id}`);
    } else {
      pathLink = (`${path}/all/${id}`);
    }
    if (props?.isSidecarContext) {
      redirectToNewTab(pathLink);
    } else if (props?.isCompactView) {
      window.open(`${origin}/#${pathLink}`, '_blank', 'noopener,noreferrer');
    } else {
      navigate(pathLink)
    }
  };

  const handleAppointmentDetailDrawerClose = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        showAppointmentDetailDrawer: visible,
      };
    });
  };

  const getInitData = async () => {
    try {
      setState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      const response = await getContactCareProgramAdditionalData(
        props?.contactCareProgram?.assigneeId,
        props?.contactCareProgram?.id,
        careProgramStatusCode
      );
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          assigneeUserData: response?.user,
          careProgramActivityLogs: response?.careProgramActivityLogs,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <View style={styles.card}>
      <View style={styles.columnFlex}>
        <View style={styles.rowFlex}>
          <View style={styles.cardDescriptionContainer}>
            <Stack direction="row">
              {/* remove bulk action for now */}
              {/* {[
                careProgramStatus?.inProgress,
                careProgramStatus?.newStatus,
                careProgramStatus?.paused,
              ]?.includes(props?.contactCareProgram?.statusId) &&
                props?.enableBulkSelection && (
                  <Checkbox
                    checked={props?.checked}
                    style={antStyles.cardCheckbox}
                    onChange={(value) => {
                      props?.onCheckBoxChange(
                        value?.target?.checked,
                        props?.contactCareProgram?.id
                      );
                    }}
                  />
                )} */}
                <Tooltip title={`${progressPercentage}%`}>
                  <Progress
                    type="circle"
                percent={progressPercentage}
                width={16}
                style={antStyles.progressContainer}
                showInfo={false}
                strokeWidth={10}
                    strokeColor={Colors.Custom.SuccessColor}
                  />
                </Tooltip>
              <Text size={'smRegular'} style={styles.cardTitle} numberOfLines={1} {...testID(careProgramTypeCode)}>
                {getCardMainTitle(careProgramTypeCode, careProgramDisplayValue)}
              </Text>
              <CPStatusBadge statusCode={careProgramStatusCode} />
            </Stack>
            <View style={styles.rowFlexAlignCenter}>
              <Tooltip
                title={getSubTitleText(
                  state?.assigneeUserData,
                  props?.contactCareProgram,
                  state?.assigneeUserData
                )}
                placement="bottom"
              >
                <Text size={'xsNormal'} style={styles.cardSubtitle} numberOfLines={1} {...testID(TestIdentifiers.relatedProgramInfo)}>
                  {props?.patientAdditionalDataLoading || state?.isLoading
                    ? intl.formatMessage({id: 'loadingText'})
                    : getSubTitleText(
                        props?.pcpUserData,
                        props?.contactCareProgram,
                        state?.assigneeUserData
                      )}
                </Text>
              </Tooltip>
              {props?.patientAdditionalDataLoading || state?.isLoading ? (
                <></>
              ) : props?.contactCareProgram?.statusReasonMlov ? (
                <Tooltip
                  title={props?.contactCareProgram?.statusReasonMlov?.value}
                  placement="bottom"
                >
                  <Feather
                    name="info"
                    size={12}
                    color={Colors.FoldPixel.GRAY300}
                    style={styles.ml4}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </View>
          </View>
          <Stack direction="row" style={styles.centerAlign}>
            <Pressable
              style={({pressed}) =>
                pressed ? styles.buttonHover : styles.button
              }
              onPress={() =>
                handeSelectCareProgram(props?.contactCareProgram?.id)
              }
            >
              <Text style={styles.buttonText} {...testID(checkCompletedCareProgram? `View${careProgramTypeCode}`: `Review${careProgramTypeCode}`)}>
                {checkCompletedCareProgram
                  ? `View ${careProgramTypeCode}`
                  : `Review ${careProgramTypeCode}`}
              </Text>
            </Pressable>
            {(
              props?.sectionKey !== CARE_MANAGEMENT_CATEGORY_CODES.COMPLETED && 
              !forceReadonly
            ) && (
              <>
                <Divider
                  type="vertical"
                  orientationMargin={8}
                  style={antStyles.dividerStyle}
                />

                <View style={styles.menuHorizontalIcon}>
                  <CareProgramMoreActionView
                    contactCareProgramId={props?.contactCareProgram?.id}
                    careProgramTypeCode={careProgramTypeCode}
                    careProgramAssigneeId={state?.assigneeUserData?.uuid}
                    contactId={contactData?.uuid || props?.contactUuid || ''}
                    careProgramStatusCode={careProgramStatusCode}
                    onCareProgramAssigneeChange={(value) => {
                      setState((prev) => {
                        return {
                          ...prev,
                          assigneeUserData: value,
                        };
                      });
                    }}
                    onActionCloseCareProgram={() => {
                      props?.onActionCloseCareProgram();
                    }}
                    forceReadonly={forceReadonly}
                  />
                </View>
              </>
            )}
          </Stack>
        </View>

        {(careProgramStatus?.inProgress === props?.contactCareProgram?.statusId ||
          careProgramStatus?.paused === props?.contactCareProgram?.statusId) &&
          (state?.careProgramActivityLogs?.timestamp ||
            props?.pcpFutureAppointmentData?.id) && (
            <View style={styles.columnFlex}>
              <Divider
                type="horizontal"
                orientationMargin={0}
                style={antStyles.horizontalCardDivider}
              />
              <Stack direction="row">
                {state?.careProgramActivityLogs && (
                  <Text size={'xsNormal'} style={styles.cardSubtitle} {...testID(TestIdentifiers.lastUpdate)}>
                    {getLastUpdateText(
                      state?.careProgramActivityLogs,
                      props?.pcpFutureAppointmentData
                    )}
                  </Text>
                )}
                {props?.pcpFutureAppointmentData?.startDateTime && (
                  <Pressable
                    onPress={() =>
                      handleAppointmentDetailDrawerClose(
                        !state?.showAppointmentDetailDrawer
                      )
                    }
                  >
                    <Text size={'xsNormal'} style={styles.cardSubtitle}>
                      {`Next PCP Appointment: ${moment(
                        props?.pcpFutureAppointmentData?.startDateTime
                      ).format(DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`}
                    </Text>
                  </Pressable>
                )}
              </Stack>
            </View>
          )}
      </View>
      {state?.showAppointmentDetailDrawer && (
        <AppointmentDetail
          event={{detail: props?.pcpFutureAppointmentData}}
          isVisible={true}
          onClose={() => handleAppointmentDetailDrawerClose(false)}
        />
      )}
    </View>
  );
};

export default React.memo(ContactCareProgramCard);
