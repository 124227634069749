import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {cloneDeep, debounce} from 'lodash';
import classNames from 'classnames';
import {Progress, Spin, Upload, UploadFile, message} from 'antd';
import {DeleteOutlined, EyeOutlined} from '@ant-design/icons';
import {getUniqueKey, parseFileSize} from '../CustomFormEngineUtils';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from '../FormComponents/FormComponents';
import Label from '../BaseComponents/Label';
import {FormRenderer} from '../FormRenderer';
import BasicFields from '../../FHFormio/EditFormFields/BasicFields';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import {useCustomFormBuilderContext} from '../Context/CustomFormBuilder.context';
import {usePropertiesFormRenderer} from '../Hooks/usePropertiesFormRenderer';
import {
  useFormRendererContext,
} from '../Context/FormRenderer.context';
import { FormRendererActionType } from '../Context/FormRendererReducer';
import {CustomFormBuilderActionTypes} from '../CustomFormEngineInterfaces';
import Description from '../BaseComponents/Description';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import {FileSchema} from '../Schema/ComponentsSchema';
import {FileComponent} from '../BaseComponents/BaseComponentInterface';
import {FileService} from '../FIleService';
import {Pressable} from 'react-native';
import FileUploadSvg from '../../../../../assets/Icons/FormBuilder/FileUploadSvg';
import QuillConfig from '../../FHFormio/Builder/QuillConfig';
import SearchableComponentFields from '../../FHFormio/EditFormFields/SearchableComponentFields';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import { v4 as uuidV4 } from 'uuid';

const {Dragger} = Upload;
const DEFAULT_MAX_SIZE = '5MB';

interface FileInfo {
  url: string;
  name: string;
  size: number;
  type: string;
}

interface FileWithProgress extends FileInfo {
  status: 'uploading' | 'done' | 'error' | 'downloading';
  percent?: number;
  originFileObj?: File;
}

export const FileFieldFormElement: FormElement = {
  type: FileSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    ...cloneDeep(FileSchema),
    key: getUniqueKey(map, FileSchema.key),
    referenceId: uuidV4(),
  }),
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(FileSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: FileUploadSvg,
    label: FileSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, value: FileInfo[], silentCheck?: boolean): ValidationResult => {
  const element = elementInstance as FileComponent;

  if (element.validate?.required && !value?.length) {
    return {isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: value};
  }

  // Validate file pattern
  if (element.validate?.filePattern) {
    const patterns = element.validate.filePattern
      .split(',')
      .map((p) => p.trim());
    const isValidType = value.every((file) => {
      return patterns.some((pattern) => {
        if (pattern.includes('/*')) {
          const mainType = pattern.split('/')[0];
          return file.type.startsWith(mainType);
        }
        return pattern === file.type;
      });
    });
    if (!isValidType) {
      return {isValid: false, errorMessage: 'Invalid file type', key: elementInstance.key, fieldValue: value};
    }
  }

  // Validate file size
  if (element.validate?.fileMaxSize) {
    const maxSize = parseFileSize(element.validate.fileMaxSize);
    const isValidSize = value.every((file) => file.size <= maxSize);
    if (!isValidSize) {
      return {
        isValid: false,
        errorMessage: `File size should not exceed ${element.validate.fileMaxSize}`,
        key: elementInstance.key,
        fieldValue: value,
      };
    }
  }

  return {isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value};
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const fileService = new FileService();
  const [fileList, setFileList] = useState<FileInfo[]>([]);

  const handleChange = async (files: FileInfo[]) => {
    setFileList(files);
  };

  return (
    <ComponentView
      defaultValue={fileList}
      elementInstance={elementInstance}
      onChange={handleChange}
      fileService={fileService}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: FileInfo[];
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const {state, dispatch} = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [fileList, setFileList] = useState<FileInfo[]>(defaultValue || selectedValue || []);
  const fileService = new FileService();
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: FileInfo[], silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || [], silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: FileInfo[]) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

  // Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: FileInfo[]) => {
    setFileList(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={fileList}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
      fileService={fileService}
    />
  );
}

function FileTableRow({
  file,
  onDownload,
  onRemove,
  isReadOnly,
}: {
  file: FileWithProgress;
  isReadOnly: boolean;
  onDownload: (file: FileInfo) => void;
  onRemove: (file: FileWithProgress) => void;
}) {
  return (
    <tr className="file-table-row">
      <td className="file-name-cell">
        <span
          className={classNames(
            'file-name-text',
            file.status === 'done'
              ? 'text-blue-600 hover:text-blue-800 cursor-pointer'
              : 'text-gray-600',
            isReadOnly ? 'field-value' : ''
          )}
          onClick={() => file.status === 'done' && onDownload(file)}
        >
          {file.name}
        </span>
        {file.status === 'uploading' && (
          <div className="progress-container">
            <Progress percent={file.percent} showInfo={false} />
            <span className="progress-text">{file.percent}%</span>
          </div>
        )}
        {file.status === 'error' && (
          <span className="error-text">Upload failed</span>
        )}
      </td>
      <td className="file-size-cell">{(file.size / 1024).toFixed(2)} KB</td>
      <td className="file-actions-cell">
        {file.status !== 'downloading' && (
          <Pressable
            onPress={() => onDownload(file)}
            disabled={file.status !== 'done'}
            style={{marginRight: 16}}
          >
            <EyeOutlined title="View file" />
          </Pressable>
        )}
        {file.status === 'downloading' && (
          <Spin size="small" style={{marginRight: 16}} />
        )}
        {!isReadOnly && (
          <Pressable
            onPress={() => onRemove(file)}
            disabled={file.status !== 'done'}
          >
            <DeleteOutlined title="Delete file" />
          </Pressable>
        )}
      </td>
    </tr>
  );
}

function ComponentView({
  elementInstance,
  defaultValue = [],
  errorMessage,
  onChange,
  isReadOnly,
  fileService,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: FileInfo[];
  onChange: (files: FileInfo[]) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
  fileService?: FileService;
}) {
  const {label, validate, description, tooltip, allowToShare} =
    elementInstance as FileComponent;
  const isAllowToShare =
    allowToShare !== undefined && allowToShare === false ? false : true;
  const [fileList, setFileList] = useState<FileWithProgress[]>([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (mountedRef.current && defaultValue?.length) {
      setFileList(
        defaultValue.map((file) => ({
          ...file,
          status: 'done',
          percent: 100,
        }))
      );
    }
  }, [defaultValue]);

  const safeSetFileList = useCallback(
    (updater: (prev: FileWithProgress[]) => FileWithProgress[]) => {
      if (mountedRef.current) {
        setFileList(updater);
      }
    },
    []
  );

  const handleUpload = async (file: File) => {
    if (!fileService || !mountedRef.current) return false;

    // Check for duplicate file
    if (fileList.some((f) => f.name === file.name)) {
      message.error(`File "${file.name}" already exists`);
      return false;
    }

    const newFile: FileWithProgress = {
      name: file.name,
      size: file.size,
      type: file.type,
      url: '',
      status: 'uploading',
      percent: 0,
      originFileObj: file,
    };

    safeSetFileList((prev) => [...prev, newFile]);

    try {
      const result = await fileService.uploadFile(
        'url',
        file,
        file.name,
        undefined,
        (progressEvent) => {
          if (mountedRef.current) {
            const percent = progressEvent.loaded
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;

            safeSetFileList((prev) =>
              prev.map((f) => (f.name === file.name ? {...f, percent} : f))
            );
          }
        }
      );

      if (mountedRef.current) {
        safeSetFileList((prev) => {
          const updatedList = prev.map((f) =>
            f.name === file.name
              ? {
                  ...f,
                  url: result.url,
                  percent: 100,
                  status: 'done' as const,
                }
              : f
          );
          onChange(updatedList);
          return updatedList;
        });
      }

      return false;
    } catch (error) {
      if (mountedRef.current) {
        safeSetFileList((prev) =>
          prev.map((f) =>
            f.name === file.name ? {...f, status: 'error', percent: 0} : f
          )
        );
        message.error(`Upload failed: ${file.name}`);
      }
      return false;
    }
  };

  const handleDownload = async (file: FileInfo) => {
    if (!fileService || !mountedRef.current) return;

    try {
      setFileList((prev) =>
        prev.map((f) =>
          f.name === file.name ? {...f, status: 'downloading' as const} : f
        )
      );
      const result = await fileService.downloadFile(file.url);
      if (mountedRef.current) {
        window.open(result.url, '_blank', 'noopener,noreferrer');
      }
      setFileList((prev) =>
        prev.map((f) => (f.name === file.name ? {...f, status: 'done'} : f))
      );
    } catch (error) {
      if (mountedRef.current) {
        message.error('Download failed');
      }
      setFileList((prev) =>
        prev.map((f) => (f.name === file.name ? {...f, status: 'done'} : f))
      );
    }
  };

  const handleRemove = (file: FileWithProgress) => {
    if (!mountedRef.current) return;
    safeSetFileList((prev) => prev.filter((f) => f.name !== file.name));
    const remainingFiles = fileList.filter(
      (f) => f.name !== file.name && f.status === 'done'
    );
    onChange(remainingFiles);
  };

  return (
    <div
      className={classNames(
        'flex w-full flex-col gap-1',
        isReadOnly ? (isAllowToShare ? 'page-break' : 'disallow-to-share') : ''
      )}
    >
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />

      <div className="w-full">
        {!isReadOnly && fileList.length < (validate?.maxCount || 1) && (
          <Dragger
            multiple={true}
            fileList={fileList as unknown as UploadFile<any>[]}
            beforeUpload={(file, files) => {
              // Handle multiple files case
              if (files && files.length > 1) {
                const maxCount = validate?.maxCount || 1;
                const maxSizeInBytes = parseFileSize(
                  validate?.fileMaxSize || DEFAULT_MAX_SIZE
                );

                // Check if adding these files would exceed maxCount
                if (fileList.length + files.length > maxCount) {
                  // Only show error on the first file
                  if (file === files[0]) {
                    message.error(
                      `You can only upload up to ${maxCount} file${
                        maxCount > 1 ? 's' : ''
                      }`
                    );
                  }
                  return false;
                }

                // Validate size for all files
                const oversizedFiles = files.filter(
                  (f) => f.size > maxSizeInBytes
                );
                if (oversizedFiles.length > 0) {
                  // Only show error on the first file
                  if (file === files[0]) {
                    message.error(
                      `Files must be smaller than ${
                        validate?.fileMaxSize || DEFAULT_MAX_SIZE
                      }`
                    );
                  }
                  return false;
                }
              }

              // Single file validation
              const maxSizeInBytes = parseFileSize(
                validate?.fileMaxSize || DEFAULT_MAX_SIZE
              );
              if (file.size > maxSizeInBytes) {
                message.error(
                  `File must be smaller than ${
                    validate?.fileMaxSize || DEFAULT_MAX_SIZE
                  }`
                );
                return false;
              }

              const maxCount = validate?.maxCount || 1;
              if (fileList.length >= maxCount) {
                message.error(
                  `You can only upload up to ${maxCount} file${
                    maxCount > 1 ? 's' : ''
                  }`
                );
                return false;
              }

              return handleUpload(file);
            }}
            showUploadList={false}
            accept={validate?.filePattern}
            maxCount={5}
            className="file-upload-dragger"
          >
            <p className="ant-upload-text">Drop files to attach, or browse</p>
            <p className="ant-upload-hint">
              {validate?.filePattern &&
                `Allowed types: ${validate.filePattern}`}
              {validate?.fileMaxSize && ` Max size: ${validate.fileMaxSize}`}
              {validate?.maxCount && ` Max count: ${validate.maxCount}`}
            </p>
          </Dragger>
        )}

        {fileList.length > 0 && (
          <div className="file-table-container">
            <table className="file-table">
              <thead>
                <tr>
                  <th className="file-table-header">Name</th>
                  <th className="file-table-header">Size</th>
                  <th className="file-table-header">Actions</th>
                </tr>
              </thead>
              <tbody>
                {fileList.map((file, index) => (
                  <FileTableRow
                    key={`${index}-${file.name}`}
                    isReadOnly={isReadOnly || false}
                    file={file}
                    onDownload={handleDownload}
                    onRemove={handleRemove}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
        {isReadOnly && !fileList.length && (
          <div className="no-files-attached">
            No files attached
          </div>
        )}
      </div>

      {description && <Description description={description} />}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const {state, dispatch, userSettings} = useCustomFormBuilderContext();
  const element = elementInstance as FileComponent;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
    {
      type: 'oldtextfield',
      input: true,
      key: 'validate.filePattern',
      label: 'File Pattern',
      placeholder: 'image/*,application/pdf',
      tooltip: 'Allowed file types (e.g., image/*,application/pdf)',
      defaultValue: 'image/*,application/pdf',
    },
    {
      type: 'oldtextfield',
      input: true,
      key: 'validate.fileMaxSize',
      label: 'Maximum File Size',
      placeholder: '10MB',
      tooltip: 'Maximum file size (e.g., 10MB)',
      defaultValue: '10MB',
    },
    {
      type: 'number',
      input: true,
      key: 'validate.maxCount',
      label: 'Maximum File Count',
      placeholder: '5',
      tooltip: 'Maximum number of files (e.g., 5)',
      defaultValue: 5,
      validate: {
        min: 1,
        max: 10,
      },
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const {formData, formattedFormData, components, handleFormDataChange} =
    usePropertiesFormRenderer({
      initialValues: element as Record<string, any>,
      components: formElements,
    });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: {updatedElement: formData, builderComponents: state.elements},
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
