import {useContext, useState, useReducer} from 'react';
import {
  PersonOmniViewActionType,
  personOmniViewReducer,
  PersonOmniViewState,
  PersonOmniViewAction,
} from '../PersonOmniViewReducer';
import {
  IContact,
  IContactProfile,
} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {CommonDataContext} from '../../../context/CommonDataContext';
import {ToastType} from '../../../utils/commonViewUtils';
import {useLazyQuery} from '@apollo/client';
import {LeadQueries} from '../../../services';
import {showToast} from '../../../utils/commonViewUtils';
import {useToast} from 'native-base';
import {getFormDataFromLeadData} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import {useGetDefaultContactProfile} from './useGetDefaultContactProfile';
import {getBooleanFeatureFlag} from '../../../utils/commonUtils';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import {isAccountConfigEnabled} from '../../../utils/configUtils';
import {CONFIG_CODES} from '../../../constants/AccountConfigConst';

const initialState: PersonOmniViewState = {
  formattedContactData: {} as any,
  contactData: {} as IContact,
  ehrPatientId: '',
};

export const usePersonOmniView = (params: {contactId: string | undefined}) => {
  const mlovData = useContext(CommonDataContext);
  const toast = useToast();
  const [componentState, dispatch] = useReducer<
    React.Reducer<PersonOmniViewState, PersonOmniViewAction>
  >(personOmniViewReducer, initialState);

  const [getContact] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });

  const isMultiTenancyEnabled =
    getBooleanFeatureFlag(
      mlovData.userSettings,
      FeatureFlags.IS_MULTI_TENANCY_ENABLED
    ) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);

  const {getContactProfilesByUserAllowedLocationIds} =
    useGetDefaultContactProfile();
  const [loading, setLoading] = useState(false);

  const wrappedDispatch = (action: PersonOmniViewAction) => {
    if (action.type === PersonOmniViewActionType.UPDATE_CONTACT_DATA) {
      fetchAllData(action?.payload?.selectedProfile);
    } else {
      dispatch(action);
    }
  };

  const fetchAllData = async (selectedProfileData?: IContactProfile) => {
    try {
      setLoading(true);
      const contactResponse = await getContact({variables: {id: params.contactId}});

      const contactData = contactResponse.data?.contact || {};
      const {contactProfiles, defaultContactProfile} =
        getContactProfilesByUserAllowedLocationIds(
          contactData?.contactProfiles,
          contactData?.uuid
        );

      const selectedProfile = selectedProfileData?.ehrPatientId
        ? selectedProfileData
        : defaultContactProfile;

      const updatedContactData = {
        ...contactData,
        contactProfiles: contactProfiles,
        contactProfile: selectedProfile,
      };
      const formattedContactData = getFormDataFromLeadData(
        updatedContactData,
        mlovData
      );
      const ehrPatientId = isMultiTenancyEnabled
        ? selectedProfile?.ehrPatientId
        : updatedContactData?.patient?.patientId;

      dispatch({
        type: PersonOmniViewActionType.SET_CONTACT_DATA,
        payload: {
          formattedContactData,
          contactData: updatedContactData,
          ehrPatientId,
        },
      });
      setLoading(false);
      return updatedContactData;
    } catch (error) {
      setLoading(false);
      showToast(toast, 'Error fetching data', ToastType.error);
    }
  };

  return {
    contactData: componentState.contactData,
    formattedContactData: componentState.formattedContactData,
    ehrPatientId: componentState.ehrPatientId,
    dispatch: wrappedDispatch,
    fetchAllData,
    loading,
    componentState,
  };
};
