import {useLazyQuery} from '@apollo/client';
import {Center, Pressable, Skeleton, Text, useToast, View} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Platform} from 'react-native';
import {RelativeContactList} from '.';
import {CONTACT_RELATION_VIEW, PERSON_TYPES} from '../../../constants';
import {COMMON_ACTION_CODES} from '../../../constants/ActionConst';
import {CommonDataContext} from '../../../context/CommonDataContext';
import ContactRelation from '../../../services/ContactRelation/ContactRelation';
import {IContactRelations} from '../../../services/ContactRelation/interface';
import {Colors} from '../../../styles';
import {getContactTypeId, getMlovId} from '../../../utils/mlovUtils';
import {isWeb} from '../../../utils/platformCheckUtils';
import {AddOrUpdateLead} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateLead';
import {CommonCollapse} from '../../RightSideContainer/Contacts/TeamMembers/LeftTeamContainer';
import {IContact} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {AddContactRelationView} from '../AddContactRelationView';
import {AddActionView} from '../Buttons/AddActionView';
import {DisplayText} from '../DisplayText/DisplayText';
import RelationWithContactsList from '../RelationWithContacts/RelationWithContactsList';
import AddSvgIcon from '../Svg/AddSvgIcon';
import FamilyMember from '../Svg/FamilyMember';
import TaskIconOther from '../Svg/TaskIconOther';
import {styles} from './ContactRelationViewStyles';
import { getBooleanFeatureFlag, getContactProfileAccountLocationUuid, isEmployerRole } from '../../../utils/commonUtils';
import { getPatientById } from '../../../services/CommonService/AidBoxService';
import { Patient } from 'fhir/r4';
import { checkAbilityAccess, checkAccountConfigAbilityAccess, getOrgAbilites } from '../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../constants/FhirConstant';
import { ADD_UPDATE_EVENTS } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { EventBus } from '../../../utils/EventBus';
import { sortContactRelations } from './ContactRelationUtils';
import { testID, TestIdentifiers } from '../../../testUtils';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { showAddorUpdate } from '../../RightSideContainer/Forms/FHFormio/CustomComponents/CustomComponentUtils';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { getActivePatientProfile as getActivePatientProfileFromContactProfiles } from '../ContactProfile/commonUtils';
import useLocationAccess from '../ContactProfile/useLocationAccess';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { usePersonOmniViewContext } from '../../PersonOmniView/PersonOmniView.context';
interface ContactRelationProps {
  contactData?: IContact;
  contactId?: number;
  style?: any;
  isDefaultClose?: boolean;
  onAddOrUpdatePress?: (type: string) => void;
  navigation?: any
  isCompactView?: boolean;
  sideCarDashboardEnabled?: boolean;
}

const ContactRelationView = (props: ContactRelationProps) => {
  const mlovData = useContext(CommonDataContext);
  const intl = useIntl();
  const toast = useToast();
  const {ehrPatientId} = usePersonOmniViewContext();
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isSidecarContext = mlovData?.sidecarContext?.isSidecar;
  const [selectedView, setSelectedView] = useState(
    CONTACT_RELATION_VIEW.CONTACT_LIST_VIEW
  );
  const [componentState, setComponentState] = useState<{
    contactRelations: IContactRelations[],
    isLoading: boolean,
    patientDetails?: Patient,
  }>({
    contactRelations: [],
    isLoading: true,
    patientDetails: undefined,
  });
  const contactData = props.contactData;
  const contactId = props.contactId || contactData?.id;
  const contactType = contactData?.contactType?.contactType?.code;
  const isCustomerContact = contactType === PERSON_TYPES.CUSTOMER;
  const patientId = ehrPatientId || contactData?.contactProfile?.ehrPatientId || contactData?.patient?.patientId || contactData?.patient?.patientUuid;
  const accountLocationUuid = getContactProfileAccountLocationUuid(contactData);
  const activePatientProfile = getActivePatientProfileFromContactProfiles(props?.contactData?.contactProfiles || []);
  const {haveAccessToLocation} = useLocationAccess({
    locationUuids: [activePatientProfile?.accountLocationUuid || ""],
    userAccessMainMenuCode: MAIN_MENU_CODES.CONSUMER
  });
  const isShowAddOrUpdate = isMultiTenancyEnabled && isCustomerContact ? haveAccessToLocation : true;
  const customTooltipLabel = isShowAddOrUpdate
    ? 'Add Patient'
    : intl.formatMessage({id: 'familyAndCareGiverLocationChangeError'});
  const isEmployer = isEmployerRole();
  const [getRelativeContact] = useLazyQuery<{
    contactRelations: IContactRelations[];
  }>(ContactRelation.GetRelativeContact, {
    fetchPolicy: 'no-cache',//FETCH_POLICY_IS_CHANGED_FROM_NETWORK_TO_NOCACHE
    variables: {
      contactId: contactId,
    },
  });
  // const contactTypeUuid = getContactTypeId('CUSTOMER');

  const canCreatePatient = checkAccountConfigAbilityAccess(FHIR_RESOURCE.PATIENT, 'canCreate')?.isEnabled;

  const contactTypeUuid = canCreatePatient ?  getContactTypeId('CUSTOMER') : getContactTypeId('VISITOR');

  const onViewChangeActionPerformed = (actionCode: string): any => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.ADD:
        setSelectedView(CONTACT_RELATION_VIEW.ADD_RELATION);
        if (props.onAddOrUpdatePress) {
          props.onAddOrUpdatePress(CONTACT_RELATION_VIEW.ADD_UPDATE_CONTACT);
        }
        break;
      case COMMON_ACTION_CODES.CANCEL:
        setSelectedView(CONTACT_RELATION_VIEW.CONTACT_LIST_VIEW);
        getContactRelatedContacts();
        break;
      case COMMON_ACTION_CODES.EDIT:
        setSelectedView(CONTACT_RELATION_VIEW.ADD_UPDATE_CONTACT);
        break;
      case COMMON_ACTION_CODES.DELETED:
        getContactRelatedContacts();
        break;
    }
  };
  const getContactRelatedContacts = async () => {
    const promiseList: Promise<any>[] = [];
    promiseList.push(getRelativeContact());
    if (patientId) {
      promiseList.push(getPatientById(patientId, accountLocationUuid));
    }
    const result = await Promise.all(promiseList);
    const relatedContactListResp = result?.[0];
    if (patientId && result?.[1]?.data) {
      setComponentState((prev) => {
        return {
          ...prev,
          patientDetails: result?.[1]?.data,
        };
      });
    }

    if (relatedContactListResp?.data?.contactRelations.length) {
        const contactRelations = (relatedContactListResp?.data
          ?.contactRelations || []) as IContactRelations[];
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
          contactRelations: sortContactRelations(contactRelations) || [],
        };
      });
    } else {
      setComponentState((prev) => {
        return {
          ...prev,
          isLoading: false,
          contactRelations: [],
        };
      });
    }
  };

  useEffect(() => {
    getContactRelatedContacts();
  }, [contactId]);


  const onEventReceived = useCallback((data) => {
    if (data === ADD_UPDATE_EVENTS.EMERGENCY_CONTACT) {
      getContactRelatedContacts();
    }
  }, []);

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      ADD_UPDATE_EVENTS.EMERGENCY_CONTACT,
      onEventReceived
    );
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  const renderContent = () => {
    return (
      <View
        style={[
          {
            backgroundColor: '#ffffff',
            marginHorizontal: props?.sideCarDashboardEnabled ? 0 : 24,
            borderRadius: 8,
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.06)`,
            paddingHorizontal: 16,
          },
        ]}
      >
        {componentState.isLoading ? (
          <View padding={3}>
            <Skeleton.Text lines={3} />
          </View>
        ) : (
          <RelativeContactList
            contactId={contactId}
            contactData={contactData}
            onAddMembersClick={() => {
              onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
            }}
            navigation={props.navigation}
            contactRelations={componentState.contactRelations}
            patientDetails={componentState.patientDetails}
            onActionPerformedOnContactRelative={(actionCode: any) => {
              onViewChangeActionPerformed(actionCode);
            }}
            isCompactView={props?.isCompactView}
            isReadOnly={!isShowAddOrUpdate}
          />
        )}
      </View>
    );
  };
  const getLeadViewFromSelectedCode = () => {
    switch (selectedView) {
      case CONTACT_RELATION_VIEW.CONTACT_LIST_VIEW:
      case CONTACT_RELATION_VIEW.ADD_RELATION:
      case CONTACT_RELATION_VIEW.ADD_RELATION_CONTACT:
      case CONTACT_RELATION_VIEW.ADD_UPDATE_CONTACT:
        return (
          <>
            <View
              style={{
                backgroundColor: props?.sideCarDashboardEnabled ? Colors.FoldPixel.GRAY50 : Colors.Custom.Gray50
              }}
            >
              <View style={{paddingVertical : 16, paddingHorizontal : props?.sideCarDashboardEnabled ? 0 : 16}}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 9,
                    }}
                  >
                    <DisplayText
                      size={'lgMedium'}
                      textLocalId={'familyMembers'}
                      extraStyles={{color: Colors.FoldPixel.GRAY300 || ''}}
                      testID={'familyMembers'}
                    />
                  </View>
                  {!isEmployer && (
                    <Tooltip
                      title={customTooltipLabel}
                      placement="bottom"
                      showArrow={false}
                      overlayStyle={{paddingTop: '3px'}}
                      overlayInnerStyle={{
                        textAlign: 'center',
                        padding: '3px 7px',
                        borderRadius: '3.5px',
                      }}
                    >
                      <Pressable
                        isDisabled={isWeb() ? !isShowAddOrUpdate : false}
                        onPress={() => {
                          if (!isShowAddOrUpdate) {
                            showToast(
                              toast,
                              intl.formatMessage({id: 'actionunavailableDueToPatientLocationChange'}),
                              ToastType.info
                            );
                            return;
                          }
                          isShowAddOrUpdate && onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
                        }}
                        {...testID(TestIdentifiers.addContactBtn)}
                      >
                        <AddActionView disabled={!isShowAddOrUpdate} />
                      </Pressable>
                    </Tooltip>
                  )}
                </View>
              </View>
              {renderContent()}
              <View flex={1} style={{paddingTop: 2, paddingBottom: 2}}></View>
            </View>
            {Platform.OS === 'web' && (
              <>
                {selectedView === CONTACT_RELATION_VIEW.ADD_UPDATE_CONTACT && (
                  <AddOrUpdateLead
                    isShowAddContactTypeOption={true}
                    personTypeUuid={contactTypeUuid}
                    personType={canCreatePatient ? 'CUSTOMER' : 'VISITOR'}
                    singleLeadData={undefined}
                    onFormCompleteAction={() => {
                      onViewChangeActionPerformed(COMMON_ACTION_CODES.ADD);
                    }}
                  />
                )}
              </>
            )}
            {(selectedView === CONTACT_RELATION_VIEW.ADD_RELATION ||
              selectedView === CONTACT_RELATION_VIEW.ADD_UPDATE_CONTACT) &&
              isWeb() && (
                <AddContactRelationView
                  contactData={contactData}
                  contactId={contactId}
                  contactRelations={componentState.contactRelations}
                  onFormCompleteAction={onViewChangeActionPerformed}
                  isSidecarContext={isSidecarContext}
                />
              )}
          </>
        );
    }
  };

  return <>{getLeadViewFromSelectedCode()}</>;
};
export default ContactRelationView;
