import {Divider, Popover} from 'antd';
import React from 'react';
import MenuHorizontalIcon from '../../common/Svg/MenuHorizontalIcon';
import {Pressable, StyleSheet} from 'react-native';
import {useIntl} from 'react-intl';
import AssignActionSvg from '../../common/Svg/AssignActionSvg';
import {Colors} from '../../../styles/Colors';
import {Spinner, Text} from 'native-base';
import Stack from '../../common/LayoutComponents/Stack';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {antStyles} from './CareManageMentViewStyles';
import {ICareProgramMoreActionView} from './interface';
import CareProgramAssignmentView from '../ContactCareProgram/ContactCareProgramView/components/CareProgramAssignmentView';
import {View} from 'react-native';
import CareManagementThreeDotsMenu from '../ContactCareProgram/ContactCareProgramView/CareManagementThreeDotsMenu';
import { CareManagementThreeDotsMenuType } from '../ContactCareProgram/ContactCareProgramView/consts';
import { canUpdateCareProgramStatus } from '../ContactCareProgram/utils';
import { useCadence } from '../ContactCareProgram/ContactCareProgramView/components/Cadence/useCadence';
import CustomModal from '../../common/CustomConfirmationModal/CustomModal';
import { testID, TestIdentifiers } from '../../../testUtils';

const CareProgramMoreActionView = (props: ICareProgramMoreActionView) => {
  const intl = useIntl();
  const [state, setState] = React.useState({
    popoverVisible: false,
    showConfirmationModal: false,
    closeReason: '',
    showTextArea: false,
    showCancelCareProgramWarning: false
  });

  const {
    isUpcomingCareProgramAppointmentsExist,
    isCareProgramAppointmentsLoading
  } = useCadence({contactUuid: props?.contactId});

  const handlePopoverVisibleChange = (visible: boolean) => {
    setState((prev) => {
      return {
        ...prev,
        popoverVisible: visible,
      };
    });
  };

  const handleCloseCareProgram = async () => {
    props?.onActionCloseCareProgram();
  };


  const checkUpcomingCareProgramAppointments = async () => {
    const isUpacomingAppointmentExist = await isUpcomingCareProgramAppointmentsExist(props.contactCareProgramId);
    if (isUpacomingAppointmentExist) {
      setState((prevState) => ({
        ...prevState,
        showCancelCareProgramWarning: true,
      }));
    }
    else {
      setState((prevState) => ({
        ...prevState,
        showCancelCareProgramWarning: false,
        showConfirmationModal: true,
      }));
    }
  }

  const popoverContent = (
    <Stack direction="column">
      <CareProgramAssignmentView
        contactCareProgramId={props?.contactCareProgramId}
        contactId={props?.contactId}
        onAssigneeActionPerformed={() => handlePopoverVisibleChange(false)}
        parentPopoverVisible={state.popoverVisible}
        onCareProgramAssigneeChange={props?.onCareProgramAssigneeChange}
        closeParentPopover={() => handlePopoverVisibleChange(false)}
        popoverContent={
          <Stack direction="row" style={styles.popoverContent}>
            <AssignActionSvg
              customStrokeColor={Colors.Custom.Gray500}
              width={16}
              height={16}
            />
            <Text
              size={'smRegular'}
              color={Colors.FoldPixel.GRAY400}
              style={styles.popoverContentText}
              {...testID(props?.careProgramAssigneeId ? TestIdentifiers.updateAssignee : TestIdentifiers.assignTo)}
            >
              {intl.formatMessage({id: props?.careProgramAssigneeId ? 'updateAssignee' : 'assignTo'})}
            </Text>
          </Stack>
        }
      />
      { (!props?.forceReadonly && canUpdateCareProgramStatus(props.careProgramStatusCode)) && (
        <>
          <Divider
            type="horizontal"
            orientationMargin={0}
            style={antStyles.horizontalDivider}
          />
          <Pressable
            onPress={() => {
                checkUpcomingCareProgramAppointments();
                setState((prev) => {
                  return {
                    ...prev,
                    popoverVisible: false,
                  };
                })
              }
            }
          >
            <Stack direction="row" style={styles.popoverContent}>
              <AntIcon
                name="close"
                color={Colors.Custom.crossIconColor}
                size={12}
              />
              <Text
                size={'smRegular'}
                color={Colors.Custom.crossIconColor}
                style={styles.popoverContentText}
                {...testID(TestIdentifiers.endProgram)}
              >
                {intl.formatMessage({id: 'endProgram'})}
              </Text>
              {isCareProgramAppointmentsLoading && <Spinner size="sm" />}
            </Stack>
          </Pressable>
        </>
      )}
    </Stack>
  );

  return (
    <View>
      <Popover
        overlayClassName="contact-popover"
        visible={state.popoverVisible}
        trigger="click"
        onVisibleChange={() => handlePopoverVisibleChange(false)}
        content={popoverContent}
        placement={'bottomRight'}
        overlayInnerStyle={{
          padding: 8,
          borderRadius: 8,
        }}
        align={{
          offset: [5, -10],
        }}
      >
        <Pressable
          onPress={() => handlePopoverVisibleChange(!state.popoverVisible)}
        >
          <MenuHorizontalIcon width={20} height={20} />
        </Pressable>
      </Popover>
      {state.showConfirmationModal && (
        <>
          <CareManagementThreeDotsMenu
            careProgramTypeCode={props.careProgramTypeCode}
            contactId={props.contactId}
            careProgramId={props.contactCareProgramId}
            hidePopoverContainer={true}
            activeDrawerCode={CareManagementThreeDotsMenuType.CLOSE_PROGRAM}
            onClose={() => {
              setState((prev) => {
                return {
                  ...prev,
                  showConfirmationModal: false,
                };
              })
            }}
            onSuccess={(action) => {
              if (action === CareManagementThreeDotsMenuType.CLOSE_PROGRAM) {
                handleCloseCareProgram();
              }
            }}
          />
        </>
      )}

      <CustomModal
        width={'450px'}
        isOpen={state.showCancelCareProgramWarning}
        headerText={intl.formatMessage({id: 'upcomingAppointmentsExisit'})}
        subtitle={intl.formatMessage({id: 'cancelCareProgramWarningMsg'})}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            showCancelCareProgramWarning: false,
          }));
        }}
        customActionBtnContainerStyle={{
          justifyContent: 'flex-end',
          marginRight: 12
        }}
        customTextHeaderStyle={{
          marginBottom: 8
        }}
        onAction={() => {
          setState((prevState) => ({
            ...prevState,
            showCancelCareProgramWarning: false,
          }));
        }}
        customOkBtnOnPress={() => {
          setState((prevState) => ({
            ...prevState,
            showCancelCareProgramWarning: false,
            showConfirmationModal: true
          }));
        }}
        customOkBtnText={intl.formatMessage({id: 'proceed'})}
        customActionBtnText={intl.formatMessage({id: 'cancel'})}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  divider: {
    borderWidth: 1,
    borderColor: Colors.Custom.Gray200,
  },
  popoverContent: {
    paddingVertical: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  popoverContentText: {
    fontSize: 14,
    fontWeight: '500',
    paddingLeft: 4,
  },
  confirmationModalContent: {
    marginTop: 2,
  },
  confirmationModalRadioGroup: {
    marginTop: 16,
  },
  confirmationModalText: {
    color: Colors.FoldPixel.GRAY250,
    fontSize: 14,
    lineHeight: 16.8,
    fontWeight: '400',
    textAlign: 'center',
  },
});

export default React.memo(CareProgramMoreActionView);
