import React from 'react';
import {Path, Svg} from 'react-native-svg';

const RatingSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        fill="#F5F0FF"
      />
      <Path
        d="M0.25 16C0.25 12.2217 0.250531 9.41312 0.540664 7.25514C0.82983 5.10435 1.40254 3.6373 2.51992 2.51992C3.6373 1.40254 5.10435 0.82983 7.25514 0.540664C9.41312 0.250531 12.2217 0.25 16 0.25C19.7783 0.25 22.5869 0.250531 24.7449 0.540664C26.8957 0.82983 28.3627 1.40254 29.4801 2.51992C30.5975 3.6373 31.1702 5.10435 31.4593 7.25514C31.7495 9.41312 31.75 12.2217 31.75 16C31.75 19.7783 31.7495 22.5869 31.4593 24.7449C31.1702 26.8957 30.5975 28.3627 29.4801 29.4801C28.3627 30.5975 26.8957 31.1702 24.7449 31.4593C22.5869 31.7495 19.7783 31.75 16 31.75C12.2217 31.75 9.41312 31.7495 7.25514 31.4593C5.10435 31.1702 3.6373 30.5975 2.51992 29.4801C1.40254 28.3627 0.82983 26.8957 0.540664 24.7449C0.250531 22.5869 0.25 19.7783 0.25 16Z"
        stroke="#D7C0FF"
        strokeWidth="0.5"
      />
      <Path
        d="M13.6276 10.507C14.6832 8.61346 15.2109 7.66669 16 7.66669C16.789 7.66669 17.3168 8.61346 18.3724 10.507L18.6454 10.9969C18.9454 11.535 19.0954 11.804 19.3292 11.9815C19.563 12.159 19.8543 12.2249 20.4368 12.3567L20.967 12.4767C23.0168 12.9405 24.0416 13.1724 24.2855 13.9565C24.5293 14.7406 23.8306 15.5576 22.4332 17.1916L22.0717 17.6144C21.6746 18.0787 21.4761 18.3109 21.3868 18.5981C21.2975 18.8853 21.3275 19.1951 21.3875 19.8146L21.4422 20.3787C21.6534 22.5589 21.7591 23.6489 21.1207 24.1335C20.4823 24.6181 19.5228 24.1763 17.6036 23.2927L17.1071 23.0641C16.5617 22.8129 16.289 22.6874 16 22.6874C15.7109 22.6874 15.4383 22.8129 14.8929 23.0641L14.3964 23.2927C12.4772 24.1763 11.5176 24.6181 10.8793 24.1335C10.2409 23.6489 10.3466 22.5589 10.5578 20.3787L10.6125 19.8146C10.6725 19.1951 10.7025 18.8853 10.6132 18.5981C10.5239 18.3109 10.3253 18.0787 9.92826 17.6144L9.56674 17.1916C8.16936 15.5576 7.47068 14.7406 7.71451 13.9565C7.95834 13.1724 8.9832 12.9405 11.0329 12.4767L11.5632 12.3567C12.1457 12.2249 12.4369 12.159 12.6708 11.9815C12.9046 11.804 13.0546 11.535 13.3545 10.9969L13.6276 10.507Z"
        stroke="#8C5AE2"
      />
    </Svg>
  );
};

export default React.memo(RatingSvg);
