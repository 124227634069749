import {Box, Pressable, Text, View, VStack, HStack} from 'native-base';
import {Colors} from '../../../../styles';
import CircleIconView, {
  NodeCategory,
} from '../../../common/CircleIconView/CircleIconView';
import {IIconInfo} from '../FlowComponent/StateNodes/FlowNodeInterface';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { styles } from '../workflowStyles';
import { ViewStyle } from 'react-native';

const LibNodeCard = (props: {
  title: string;
  description?: string;
  iconInfo?: IIconInfo;
  nodeCategory?: string;
  onClick?: () => void;
  style?: ViewStyle;
  iconElement?: React.ReactNode;
}) => {
  return (
    <Pressable
      onPress={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      flex={1}
    >
      <View
        flex={1}
        style={[styles.cardView, props.style]}
        backgroundColor="white"
      >
        <HStack alignItems="center" flex={1}>
          <View style={styles.marginRight10}>
            {Boolean(props.iconElement) ? (
              props.iconElement
            ) : (
              <CircleIconView
                iconInfo={props.iconInfo}
                nodeCategory={props.nodeCategory || NodeCategory.moment}
                backgroundColor={Colors.primary[100]}
                borderColor={Colors.primary[100]}
                iconColor={Colors.primary[900]}
              />
            )}
          </View>
          <VStack flex={1}>
            <Text color={Colors.FoldPixel.GRAY400}>{props.title}</Text>
            <Text color="gray.400">{props.description}</Text>
          </VStack>
          <MaterialIcons
            name={'drag-indicator'}
            size={22}
            color={Colors.Custom.Gray500}
          />
        </HStack>
      </View>
    </Pressable>
  );
};

export default LibNodeCard;
