import {useLazyQuery} from '@apollo/client';
import {Popover, Tooltip, notification} from 'antd';
import {HStack, useToast, View, Text} from 'native-base';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {getInActiveContactError, GROUP_MEMBER_TYPE} from '../../../../../constants';
import {CONFIG_CODES} from '../../../../../constants/AccountConfigConst';
import {COMMON_ACTION_CODES, CONVERSATION_ACTION_CODES} from '../../../../../constants/ActionConst';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import {Colors} from '../../../../../styles/Colors';
import {getAccountId, getPatientActiveProfiles, getUserData, getUserUUID, isActiveContact, isActiveProfileMatchAllowedLocations, isConsentRequiredAndGiven, isContactConsentRequired, isEmployerRole} from '../../../../../utils/commonUtils';
import {isAccountConfigEnabled} from '../../../../../utils/configUtils';
import {EventBus} from '../../../../../utils/EventBus';
import OutboundCallSmsView from '../../../../common/OutboundCallSmsView/OutboundCallSmsView';
import {CHANNEL_TYPE, CHANNEL_TYPE_CODE} from '../ConversationConst';
import { IConversationData } from '../interfaces';
import { isBroadCastGroup, isInternalChat, isMentionTab } from '../MessagingWindow/MessagingUtils';
import {BROADCAST_MORE_OPTION_LIST, DISPLAY_CONVERSATION_MORE_OPTION_LIST, DISPLAY_CONVERSATION_MORE_OPTION_LIST_WITH_CONTAINER, DISPLAY_CONVERSATION_SIDEBAR_ICON, getFinalConversationList, INTERNAL_CONVERSATION_MORE_OPTION_LIST} from './ConversationSidebarUtils';
import {IConversationChannelList} from './Interfaces';
import {isCallsAllowed, isPatientDetailViewAllowed } from '../../../UserAccess/UserAccessPermission';
import EmployeeQueries from '../../../../../services/Employee/EmployeeQueries';
import ConversationActionView from '../ConversationActionView/ConversationActionView';
import { testID } from '../../../../../testUtils';
import AssignActionSvg from '../../../../common/Svg/AssignActionSvg';
import { getCurrentEHR } from '../../../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import { broadcastEventToSidecarActionsScreen } from '../../../../../sidecar/common/SideCardCommonUtils';
import { SIDECAR_EVENT_CODES } from '../../../../../sidecar/common/constants';
import { EHRName } from '../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { ElementConfig } from '../../../../SideCar/SideCarRPAActions/interfaces';
import { AddMedicationAthenaButtonFinderData, AddMedicationElationButtonFinderData } from '../../../../SideCar/SideCarRPAActions/RPAConfigJson';
import {getActionEventAndResourceCodeByOption} from '../../../Contacts/ContactsUtils';
import {usePermissions} from '../../../../CustomHooks/usePermissions';
import { Pressable, StyleSheet } from 'react-native';
import './CreateAllConversationPopup.css';
import { DisplayText } from '../../../../common/DisplayText/DisplayText';
import BaseService from '../../../../../services/CommonService/BaseService';
import { ICreateGroupBodyParams, ISearchExistingGroupBodyParams } from '../../../../common/CreateChannelView/interface';
import { GROUP_TYPE_CODES, MLOV_CATEGORY } from '../../../../../constants/MlovConst';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { showToast, ToastType } from '../../../../../utils/commonViewUtils';
import useLoggedInUserAllowedLocationsByTab from '../../../../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import { MAIN_MENU_CODES } from '../../../../SideMenuBar/SideBarConst';

const CreateAllConversationPopup = (props: {
  openCount: number;
  selectedTabCode: string;
  selectedGroupCode?: string;
  isDetailsContainerVisible?: boolean,
  conversation?: IConversationData,
  onCreateGroupPopupActionPerformed: (
    actionCode: string,
    actionData?: any
  ) => void;
  displayTabCodes?: IConversationChannelList[];
  contactData?: any;
  isCompactView?: boolean;
  selectedInboxTypeCode?: string;
  getAssigneeName?: () => void;
  assignUserName?: string;
  isShowFinallyAssignee?: boolean
  isSingleContactPresentInSMSOrEmailInbox?: boolean
  isInstantChatView?: boolean;
  assignedUserData?: any
  accessToViewPatientProfile?: boolean
  onActionPerformed?: (code: string , data?: any) => void;
  isConversationActive?: boolean
}) => {
  const createGroupToast = useToast();
  const intl = useIntl();
  const accountId = getAccountId();
  const userUuid = getUserUUID();
  const commonData = React.useContext(CommonDataContext);
  const isPatientSidecarContext = commonData.sidecarContext?.isPatient;
  const locationId = props?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid || '';
  const currentEHR = getCurrentEHR(locationId, '');
  const isAthena = currentEHR === EHRName.ATHENA;
  const isElation = currentEHR === EHRName.ELATION;
  const currentUserData = getUserData();
  const isConsentRequired = isContactConsentRequired();
  const isMSOEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING);
  const consentCheck = isConsentRequiredAndGiven(props?.contactData);
  const allowedLocationIds = useLoggedInUserAllowedLocationsByTab(MAIN_MENU_CODES.INBOX);
  const activecontactProfiles = getPatientActiveProfiles(props?.contactData?.contactProfiles || []);
  const hasActiveProfile = isActiveProfileMatchAllowedLocations(activecontactProfiles || [], allowedLocationIds)
  const isActionsDisabled = isMSOEnabled ? !hasActiveProfile : false;
  const {check} = usePermissions()
  let displayTabCodeList = isBroadCastGroup(props?.conversation || ({} as any)) ?
  BROADCAST_MORE_OPTION_LIST : (
    isInternalChat(props?.conversation || ({} as any))
    ? INTERNAL_CONVERSATION_MORE_OPTION_LIST
    : props?.isCompactView || isPatientSidecarContext ? DISPLAY_CONVERSATION_MORE_OPTION_LIST_WITH_CONTAINER : DISPLAY_CONVERSATION_MORE_OPTION_LIST
    ) as IConversationChannelList[];
    const [createGroupState, setCreateGroupState] = useState({
      selectedGroupCode: '',
      isLoading: false,
    });
  const [stateData, setStateData] = useState({
    visible: false,
    isConsentGiven: true,
    assignUserListPopup: false,
    assignUserName: '',
  })
  const {openCount, onCreateGroupPopupActionPerformed, contactData, accessToViewPatientProfile} = props;
  const contactActiveStatus = isActiveContact(contactData);
  const patientDetailsAllowed =  isPatientDetailViewAllowed();
  const isPatientMessagingEnabled = isAccountConfigEnabled(CONFIG_CODES.PATIENT_MESSAGING_ENABLED);
  const isCallAllowed = isCallsAllowed();
  if (!accessToViewPatientProfile) {
    const restrictedActionList = [
      CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST,
      CONVERSATION_ACTION_CODES.START_GROUP_CHAT,
      CONVERSATION_ACTION_CODES.START_VIDEO_MEET,
      CONVERSATION_ACTION_CODES.CREATE_APPOINTMENT
    ]
    displayTabCodeList = displayTabCodeList?.filter((item)=> {
      return !restrictedActionList?.includes(item.key)
    })
  }

  const [getEmployeeConsent] = useLazyQuery(
    EmployeeQueries.getEmployeeConsent,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    setCreateGroupState((prev) => {
      return {
        ...prev,
        selectedGroupCode: props?.selectedGroupCode || '',
      };
    });
    setStateData((prev) => {
      return {
        ...prev,
        assignUserName: props?.assignUserName || '',
      }
    })
  }, [openCount]);

  useEffect(()=> {
    if (props?.contactData?.uuid) {
      if (!props?.contactData?.hasOwnProperty('contactConsents')) {
        checkForConsent(props?.contactData);
      } else if (!consentCheck) {
        setStateData(prev => {
          return {
            ...prev,
            isConsentGiven: false,
          }
        })
      }
    }
  }, [props?.contactData]);

  useEffect(()=> {
    if (isPatientSidecarContext) {
      //check if element found
      let elementStep: ElementConfig = {} as ElementConfig;
      if (isAthena) {
        elementStep = AddMedicationAthenaButtonFinderData;
      } else if (isElation) {
        elementStep = AddMedicationElationButtonFinderData;
      }

      broadcastEventToSidecarActionsScreen(
        '',
        SIDECAR_EVENT_CODES.CHECK_ELEMENTS,
        elementStep
      );
    }
  }, []);

  const checkForConsent = async (contactData: any) => {
    if (!isConsentRequired) {
      setStateData((prev) => ({
        ...prev,
        isConsentGiven: true,
      }));
      return;
    }

    const checkPatientConsent = await getEmployeeConsent({
      variables: {
        contactUuid: contactData?.uuid,
      },
    });
    if (!checkPatientConsent?.data?.contactConsents.length) {
      setStateData((prev) => {
        return {
          ...prev,
          isConsentGiven: false,
        };
      });
    } else {
      setStateData((prev) => {
        return {
          ...prev,
          isConsentGiven: true,
        };
      });
    }
  };

  const isScheduledSendEnabled = isAccountConfigEnabled(CONFIG_CODES.ENABLE_SCHEDULE_MESSAGE);
  if (!isScheduledSendEnabled) {
    displayTabCodeList = displayTabCodeList?.filter((item)=> {
      return item.key !== CONVERSATION_ACTION_CODES.SCHEDULE_MESSAGE_LIST
    })
  }
  if (!patientDetailsAllowed) {
    displayTabCodeList = displayTabCodeList?.filter((item)=> {
      return item.key !== CONVERSATION_ACTION_CODES.CHART;
    })
  }

  if (
    (props.conversation?.conversationInbox?.channelType !==
      CHANNEL_TYPE.CHANNEL_TWILIO_SMS &&
      props.conversation?.conversationInbox?.channelType !==
        CHANNEL_TYPE.CHANNEL_EMAIL) ||
    props?.isSingleContactPresentInSMSOrEmailInbox ||
    isMentionTab(props?.selectedInboxTypeCode || '')
  ) {
    displayTabCodeList = displayTabCodeList?.filter((item) => {
      return item.key !== CONVERSATION_ACTION_CODES.UPDATE_TO_CONTACT;
    });
  }

  const [displayTabList, setDisplayTabList] = useState(displayTabCodeList);

  const foundElementListener = useCallback((eventData) => {
    if (eventData?.elementData  === 'ELEMENT_FOUND') {
      const tabList = [
        ...displayTabCodeList,
        {
          key: CONVERSATION_ACTION_CODES.ADD_MEDICATION_IN_EHR,
          title: 'Add Medication',
          path: '',
        },
      ];
      setDisplayTabList(tabList);
    } else {
      //show toast error
      console.error('Element not found');
    }
  }, [displayTabCodeList]);

  const getChatWithPatientElem = () => {
    return (
      <Pressable
        onPress={() => {
          if (contactData?.uuid) {
            findExistingGroupWithSamePatient(contactData);
          } else {
            setCreateGroupState((prev) => {
              return {
                ...prev,
                selectedGroupCode: CONVERSATION_ACTION_CODES.CHAT_WITH_PATIENT,
              };
            });
          }
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId={'chatWithPatient'} />
        </HStack>
      </Pressable>
    );
  };

  const getStartGroupChatElem = () => {
    return (
      <Pressable
        onPress={() => {
          onCreateGroupPopupActionPerformed(
            CONVERSATION_ACTION_CODES.START_GROUP_CHAT
          );
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId="startGroupChat" />
        </HStack>
      </Pressable>
    );
  };

  const getChatWithInternalUsersElem = () => {
    return (
      <Pressable
        onPress={() => {
          onCreateGroupPopupActionPerformed(
            CONVERSATION_ACTION_CODES.CHAT_WITH_INTERNAL_USERS
          );
        }}
      >
        <HStack p={4}>
          <DisplayText textLocalId={isEmployerRole() ? 'newChat' : "newInternalChat"} />
        </HStack>
      </Pressable>
    );
  };

  const findExistingGroupWithSamePatient = async (patientData: any) => {
    setCreateGroupState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const baseService = BaseService.getSharedInstance().axios;
    const searchGroupWithExistUserUrl = `/crm-nest/account/${accountId}/search-group-with-exist-user`;
    const bodyData: ISearchExistingGroupBodyParams = {
      groupTypeCode: GROUP_TYPE_CODES.PRIVATE,
      userIds: [userUuid],
      contactIds: [patientData?.uuid || ''],
    };
    const groupListInfo = await baseService
      .post(searchGroupWithExistUserUrl, bodyData, {
        headers: {
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        setCreateGroupState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      });
    if (groupListInfo?.data?.length) {
      onCreateGroupPopupActionPerformed(
        CONVERSATION_ACTION_CODES.ON_PATIENT_SELECTION,
        groupListInfo?.data?.[0]
      );
      setCreateGroupState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      createGroupConversation(patientData);
    }
  };

  const createGroupConversation = async (patientData: any) => {
    const groupMemberTypeList =
      getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.GroupMemberType) ||
      [];
    const userTypeId = getMlovIdFromCode(
      groupMemberTypeList,
      GROUP_MEMBER_TYPE.USER
    );
    const baseService = BaseService.getSharedInstance().axios;
    const createGroupUrl = `/crm-nest/account/${accountId}/group`;

    const bodyData: ICreateGroupBodyParams = {
      groupType: GROUP_TYPE_CODES.PRIVATE,
      groupName: '',
      groupData: {
        userIds: [userUuid],
        contactId: patientData.uuid,
        familyMemberIds: [],
      },
    };
    const groupInfo = await baseService
      .post(createGroupUrl, bodyData, {
        headers: {
          ['user-type-id']: userTypeId,
          api_access_token: currentUserData.access_token,
        },
      })
      .catch(() => {
        showToast(
          createGroupToast,
          intl.formatMessage({id: 'apiErrorMsg'}),
          ToastType.error
        );
        setCreateGroupState((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      });

    if (groupInfo?.data?.uuid) {
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'groupCreatedMsg'}),
        ToastType.success
      );
      groupInfo.data.conversationUuid = groupInfo?.data?.uuid;
      onCreateGroupPopupActionPerformed(
        CONVERSATION_ACTION_CODES.ON_PATIENT_SELECTION,
        groupInfo.data
      );
      setCreateGroupState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      setCreateGroupState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      showToast(
        createGroupToast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  };
  const handleActions = (actionCode: string, rowData?: any) => {
    switch (actionCode) {
      case COMMON_ACTION_CODES.CLOSE_MODAL:
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
      case COMMON_ACTION_CODES.CALL:
        const eventBus = EventBus.getEventBusInstance();
        eventBus.broadcastEvent('CLOUD_CALL', {
          contactData: contactData ? {...contactData,fromNumber: contactData?.phoneNumber || ''} : {},
          fromNumber: rowData.fromNumber,
          toNumber: contactData?.phoneNumber,
        });
        setStateData((prev) => {
          return {
            ...prev,
            visible: false,
          };
        });
        break;
    }
  };
  const handleAssignVisibleChange = (visible: any) => {
    setStateData((prev) => {
      return {
        ...prev,
        assignUserListPopup: visible,
      };
    });
  };
  return (
    <>

      {getFinalConversationList(
        displayTabList,
        props?.isDetailsContainerVisible,
        props?.conversation,
        isPatientMessagingEnabled,
        props?.selectedInboxTypeCode,
        props?.isShowFinallyAssignee,
        props?.assignUserName,
        props?.isInstantChatView,
        isPatientSidecarContext,
        isActionsDisabled,
        isMSOEnabled,
        props?.isConversationActive
      ).map((tabItem: IConversationChannelList, index) => {
        const eventAndResource = getActionEventAndResourceCodeByOption(tabItem.key);
        const isPermissionAllowed = check(eventAndResource?.actionEvent || '', eventAndResource?.resourceCode || '','')
        if (eventAndResource?.resourceCode && !isPermissionAllowed) {
          return
        }
        if (tabItem.key === CHANNEL_TYPE_CODE.CALL_LOGS && !isCallAllowed) {
          return
        }
        return ![
          CHANNEL_TYPE_CODE.CALL_LOGS,
          CONVERSATION_ACTION_CODES.ASSIGN,
          CONVERSATION_ACTION_CODES.ASSIGNED,
        ].includes(tabItem.key) ? (
           <div className='pressable pressable-container'
             key={`CreateConversation_${index}`}
             onClick={() => {
               onCreateGroupPopupActionPerformed(tabItem.key, contactData);
             }}
             {...testID(tabItem.code || '')}
           >
             <HStack flex={1}>
               <View
                 justifyItems={'center'}
                 alignItems={'center'}
                 alignSelf={'center'}
                 justifySelf={'center'}
                 height={'16px'}
                 width={'16px'}
                 {...testID('image')}
               >
                 {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                   tabItem?.key,
                   Colors.FoldPixel.GRAY400
                 )}
               </View>
               <Text
                 size={'smRegular'}
                 flex={0.9}
                 style={styles.tabItemText}
                 {...testID(tabItem.title || '')}
               >
                 {intl.formatMessage({id: tabItem.title})}
               </Text>
             </HStack>
           </div>
         ) : tabItem.key === CHANNEL_TYPE_CODE.CALL_LOGS ? (
           <OutboundCallSmsView
             isHideSms={true}
             showOnlyCallNumbers={true}
             visible={stateData.visible}
             selectedContactData={contactData}
             content={
               <Pressable
                 onPress={() => {
                  if (!isCallAllowed) {
                    return;
                  }
                   if (stateData.isConsentGiven) {
                     if (contactActiveStatus) {
                       if (contactData?.phoneNumber) {
                         setStateData((prev) => {
                           return {
                             ...prev,
                             visible: true,
                           };
                         });
                       } else {
                         onCreateGroupPopupActionPerformed(
                           COMMON_ACTION_CODES.EDIT,
                           contactData
                         );
                       }
                     } else {
                       const message = getInActiveContactError(contactData);
                       notification.error({
                         message,
                       });
                     }
                   } else {
                    const newContactData = {...contactData, showConsentError: true};
                    onCreateGroupPopupActionPerformed(
                      COMMON_ACTION_CODES.EDIT,
                      newContactData
                    );
                   }
                 }}
                 style={styles.callPopover}
                 {...testID('OutboundCallSmsViewClick')}
               >
                 <HStack flex={1}>
                   <View
                     justifyItems={'center'}
                     alignItems={'center'}
                     alignSelf={'center'}
                     justifySelf={'center'}
                     height={'20px'}
                     width={'20px'}
                     {...testID('image')}
                   >
                     {DISPLAY_CONVERSATION_SIDEBAR_ICON(
                       tabItem.key,
                       Colors.Custom.Gray500
                     )}
                   </View>
                   <Text
                     size={'smRegular'}
                     flex={0.9}
                     style={styles.tabItemText}
                     {...testID(tabItem.title || '')}
                   >
                     {intl.formatMessage({id: tabItem.title})}
                   </Text>
                 </HStack>
               </Pressable>
             }
             handleAction={(actionCode: string, rawData?: any) => {
               handleActions(actionCode, rawData);
             }}
           />
         ) : (
           <Popover
             key={`more-button-assigned-${props?.conversation?.id}`}
             overlayInnerStyle={reactStyles.popover}
             overlayStyle={reactStyles.padding0}
             overlay={reactStyles.padding0}
             style={reactStyles.popover}
             placement={'left'}
             trigger="click"
             visible={stateData.assignUserListPopup}
             onVisibleChange={handleAssignVisibleChange}
             content={
               <View width={300}>
                 <ConversationActionView
                   conversation={props.conversation}
                   selectedInboxTypeCode={props?.selectedInboxTypeCode}
                   style={styles.assignUserListPopup}
                   onSelectUser={(user: any) => {
                     if (props?.getAssigneeName) {
                       props?.getAssigneeName();
                     }
                     onCreateGroupPopupActionPerformed(tabItem.key);
                     setStateData((prev) => {
                       return {
                         ...prev,
                         assignUserListPopup: false,
                         assignUserName: user?.name || '',
                       };
                     });
                   }}
                   assignedUserData={props?.assignedUserData}
                   onActionPerformed={props?.onActionPerformed}
                 />
               </View>
             }
           >
             <Tooltip
               title={
                 props?.assignUserName?.length
                   ? props?.assignUserName
                   : 'Unassigned'
               }
               placement="right"
             >
               <div className='pressable pressable-container'
                 key={`CreateConversation_${index}`}
                 onClick={() => {
                   setStateData((prev) => {
                     return {
                       ...prev,
                       assignUserListPopup: !prev.assignUserListPopup,
                     };
                   });
                 }}
                 {...testID('AssignBtnClick')}
               >
                 <HStack flex={1}>
                   <View
                     justifyItems={'center'}
                     alignItems={'center'}
                     alignSelf={'center'}
                     justifySelf={'center'}
                     height={'16px'}
                     width={'16px'}
                     {...testID('image')}
                   >
                     <AssignActionSvg
                       customStrokeColor={
                         props?.assignUserName && props?.assignUserName.length
                           ? Colors.Custom.SuccessColor
                           : Colors.Custom.Gray500
                       }
                       width={21}
                       height={20}
                     />
                   </View>
                   <Text
                     flex={0.9}
                     style={[
                       styles.tabItemText,
                       {
                         color:
                           props?.assignUserName && props?.assignUserName.length
                             ? Colors.Custom.SuccessColor
                             : Colors.Custom.Gray500,
                       },
                     ]}
                     {...testID(tabItem.title)}
                   >
                     {intl.formatMessage({id: tabItem.title})}
                   </Text>
                 </HStack>
               </div>
             </Tooltip>
           </Popover>
         );
      })}
    </>
  );
};

const reactStyles: Record<string, React.CSSProperties> = {
  pressableContainer: {
    padding: 8,
    backgroundColor: 'white',
  },
  padding0: {
    padding: 0,
  },
  popover: {
    padding: 0,
    borderRadius: 12,
  }
}

const styles = StyleSheet.create({
  tabItemText: {
    color: Colors.FoldPixel.GRAY400,
    paddingStart: 8,
  },
  assignUserListPopup: {
    marginBottom: 10
  },
  callPopover: {
    padding: 8,
    backgroundColor: 'white',
  }
});

export default CreateAllConversationPopup;
