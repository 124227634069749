import React, {useCallback, useContext, useMemo} from 'react';
import './Styles/Common.css';
import {FlatList} from 'react-native';
import {
  FormElements,
  FormElementInstance,
} from './FormComponents/FormComponents';
import {
  FormRendererProvider,
  useFormRendererContext,
} from './Context/FormRenderer.context';
import { FormRendererActionType } from './Context/FormRendererReducer';
import FoldButtonV2, { ButtonType } from '../../../PersonOmniView/MiddleContainer/CarePlan/components/FoldButtonV2';
import { testID } from '../../../../testUtils';
import FormElementWrapper from './CustomFormBuilder/FormElementWrapper';
import { IFormCommonData } from '../FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import { CommonDataContext } from '../../../../context/CommonDataContext';

interface FormRendererProps {
  components: FormElementInstance[];
  onFormDataChange?: (formData: Record<string, string>) => void;
  defaultValues?: Record<string, string>;
  isReadOnly?: boolean;
  builderComponents?: FormElementInstance[];
  showSubmitButton?: boolean;
  onSubmit?: (formData: {data: Record<string, string>}) => void;
  formReference?: React.RefObject<{
    validateForm: () => Promise<{ isValid: boolean; }>;
  }>;
  onDefaultDataSet?: (defaultValues: Record<string, string>) => void;
  hideErrorMessages?: boolean;
}

const FormRendererContent = ({
  onFormDataChange,
  isReadOnly = false,
  builderComponents,
  showSubmitButton = false,
  formReference,
  onSubmit,
}: Omit<FormRendererProps, 'components'>) => {
  const {
    state,
    dispatch,
    onRegisterValidation,
    validateForm,
    isValidating,
    validationProgress
  } = useFormRendererContext();

  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  // Expose validateForm method via ref
  React.useImperativeHandle(
    formReference,
    () => ({
      validateForm,
    }),
    [validateForm]
  );

  const handleFieldChange = useCallback(
    (key: string, value: string) => {
      dispatch({
        type: FormRendererActionType.UPDATE_FIELD_VALUE,
        payload: {key, value, builderComponents},
      });
      onFormDataChange?.({...state.filledDataMap, [key]: value});
    },
    [dispatch, onFormDataChange, state.filledDataMap]
  );

  const handleSubmit = useCallback(async () => {
    const validationResult = await validateForm();
    if (validationResult.isValid) {
      onSubmit?.({data: state.filledDataMap});
    }
  }, [validateForm, onSubmit, state.filledDataMap]);

  const renderFormComponent = useCallback(
    ({item: element}) => {
      const FormComponent =
        FormElements[element.type as keyof typeof FormElements]?.formComponent;
      if (!FormComponent) return null;
      return (
        <FormElementWrapper element={element}>
          <FormComponent
            key={`form-renderer-${element.id}-${element.type}`}
            elementInstance={element}
            submitValue={!isReadOnly ? handleFieldChange : undefined}
            isReadOnly={isReadOnly}
            defaultValue={state.filledDataMap[element.key]}
            onRegisterValidation={onRegisterValidation}
          />
        </FormElementWrapper>
      );
    },
    [handleFieldChange, state.filledDataMap, isReadOnly]
  );

  const readOnlyListProps = useMemo(
    () => ({
      initialNumToRender: 100,
    }),
    []
  );
  const listProps = useMemo(
    () => ({
      removeClippedSubviews: true,
      maxToRenderPerBatch: 20,
      windowSize: 100,
      initialNumToRender: 20,
    }),
    []
  );

  const isSafariOrWebView = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return (
      /^((?!chrome|android).)*safari/i.test(userAgent) ||
      userAgent.includes('webview') ||
      // iOS WebView
      /iphone|ipad|ipod/.test(userAgent) ||
      // Android WebView
      /wv/.test(userAgent)
    );
  }, []);

  return (
    <div className="form-renderer common-form-container">
      <FlatList
        data={state.filteredComponents}
        renderItem={renderFormComponent}
        keyExtractor={(item) => `form-renderer-${item.id}-${item.type}`}
        {...(isReadOnly || isPrintForm || isSafariOrWebView ? readOnlyListProps : listProps)}
      />
      {!isReadOnly && showSubmitButton && (
        <div className="padding-top-16 flex justify-center">
          <FoldButtonV2
            label={isValidating ? `Validating... ${validationProgress}%` : 'Submit'}
            onPress={handleSubmit}
            isDisabled={isValidating}
            buttonType={ButtonType.primary}
            isLoading={state.isSubmitting}
            {...testID('form-submit-button')}
          />
        </div>
      )}
      {showSubmitButton && state.isSubmitClicked &&
        (Object.keys(state.invalidFields).length > 0 &&
        Object.values(state.invalidFields).some(value => value)
      ) && (
        <div className="error-message flex justify-center">
          {'Please fill all mandatory fields'}
        </div>
      )}
    </div>
  );
};

export const FormRenderer = (props: FormRendererProps) => {
  return (
    <FormRendererProvider
      components={props.components}
      defaultValues={props.defaultValues || {}}
      isReadOnly={props.isReadOnly || false}
      builderComponents={props.builderComponents}
      onDefaultDataSet={props.onDefaultDataSet}
      hideErrorMessages={props.hideErrorMessages}
    >
      <FormRendererContent {...props} />
    </FormRendererProvider>
  );
};
