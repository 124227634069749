import React, { useContext } from 'react';
import {IFormCommonData, IFormComponentProps} from '../../CustomWrapper/CustomWrapper';
import CareProgramAppointmentView from './CareProgramAppointmentView';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';

const CareProgramAppointmentWrapper: React.FC<IFormComponentProps> = (props) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const contactData = {
    uuid: props?.options?.contactUuid || contextData?.contactDetails?.contactUuid,
    name: props?.options?.contactName || contextData?.contactDetails?.contactName,
  }
  return (
    <>
      <CareProgramAppointmentView
        onChange={props?.onChange}
        validateRef={props?.validateRef}
        component={props?.component}
        contactData={contactData}
        handleUpdateCareProgram={props?.options?.handleUpdateCareProgram}
        disabled={props?.disabled}
      />
    </>
  );
};

export default CareProgramAppointmentWrapper;
