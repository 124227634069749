import { FlatList, View } from 'react-native'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FormContext, FormViewType, IFormCommonData, IFormComponentProps } from '../../CustomWrapper/CustomWrapper'
import { useIntl } from 'react-intl';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { CommonDataContext } from '../../../../../../../context/CommonDataContext';
import { GoalOperator, GoalViewType, IGoal, IGoalType } from './interface';
import { FormError } from '../../CustomWrapper/CustomComponentHelper';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { HStack, VStack, Text, FormControl, Input, Spacer, Pressable, Divider } from 'native-base';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import { cloneDeep } from 'lodash';
import {v4 as uuidv4} from 'uuid';
import DetailPreview from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../../../styles';
import Stack from '../../../../../../common/LayoutComponents/Stack';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import { GOAL_ENTITY_TYPE_CODES, GOAL_OPERATOR_LIST } from './GoalConstants';
import { DetailViewCollapse } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import { FoldButton } from '../../../../../../CommonComponents/FoldButton/FoldButton';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../../../../constants';
import GoalTemplateSearch from '../GoalTemplateSearch/GoalTemplateSearch';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../../constants/Configs';
import { MlovQueries } from '../../../../../../../services';
import { IEhrCapability, IMlov, IVitalData } from '../../../../../../../Interfaces';
import { getMlovById, getMlovIdFromCode, getMlovListFromCategory, getMlovObjectFromId, getMlovValueFromId } from '../../../../../../../utils/mlovUtils';
import GoalTargetView from './GoalTargetView';
import { getGoalByUniqueId, getGoalProgressBarColor, getGoalTargetText, getGoalUniqueId, getIndexByUniqueId, isGoalsEditing, removeFromEditView, updateViewTypeByUniqueId } from './GoalUtils';
import NoGoalsSvg from '../../../../../../common/Svg/CarePlanSvgs/NoGoalsSvg';
import CarePlanGoalQueries from '../../../../../../../services/CarePlanGoalQueries';
import GoalStatusView from './GoalStatusView';
import { ToastType, showToast } from '../../../../../../../utils/commonViewUtils';
import { useToast } from '../../../../../../Toast/ToastProvider';
import ContactRuleListView from '../../ContactRules/AddOrUpdateContactRules/ContactRuleListView';
import { CARE_PLAN_ITEM_STATUS_CODES, RULE_ENTITY_TYPE_CODE } from '../../../../../../../constants/MlovConst';
import { getRuleType } from '../../ContactRules/AddOrUpdateContactRules/ContactRulesHelper';
import { Drawer, Progress } from 'antd';
import WorkflowAuditGraph from '../../../../../Workflow/Workflow/AddOrUpdateWorkflow/WorkflowAuditGraph';
import { useSearchParams } from 'react-router-dom';
import GoalInfoView from '../../../../../../common/CarePlanGoalProgress/GoalInfoView';
import { styles } from '../../../../formStyle';
import CustomConfirmationModal from '../../../../../../common/CustomConfirmationModal/CustomConfirmationModal';
import TrashSvg from '../../../../../../common/Svg/TrashSvg';
import CarePlanActionView, { CarePlanAction } from '../CarePlanActionView';
import ActivityGoalSvg from '../../../../../../common/Svg/CarePlanIcons/ActivityGoalSvg';
import OtherGoalSvg from '../../../../../../common/Svg/CarePlanIcons/OtherGoalSvg';
import LabGoalSvg from '../../../../../../common/Svg/CarePlanIcons/LabGoalSvg';
import AssessmentGoalSvg from '../../../../../../common/Svg/CarePlanIcons/AssessmentGoalSvg';
import VitalGoalSvg from '../../../../../../common/Svg/CarePlanIcons/VitalGoalSvg';
import { isEnableGoalProgress, isEnableRuleAlert } from '../../../../../../../utils/commonUtils';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';
import { FHIR_RESOURCE } from '../../../../../../../constants/FhirConstant';
export interface IAddOrUpdateGoalValue {
  goals: IGoal[];
}

interface IAddOrUpdateGoalState {
  showErrors: boolean;
  loading: boolean;
  formError?: FormError;
  editModalCollapse: boolean;
  selectedGoal?: IGoal;
  mlovMap: Map<string, IMlov[]>;
  goalsLoading: string[];
  goalsWithError: string[];
  workflowMasterId: string;
  showWorkflowAuditView: boolean;
  deleteGoalConfirmation: {
    isOpen: boolean;
    goal: IGoal | undefined;
  };
}

const AddOrUpdateGoals = (props: IFormComponentProps) => {
  const componentRef = useRef();
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useContainerDimensions(componentRef);
  const [isMobileScreen] = [width <= 480];
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const componentView = props.options.componentView || contextData.componentView;
  const isWidgetView = componentView === FormViewType.widget;
  const contactUuid = props.options.contactId || props.options.contactUuid || contextData.contactDetails?.contactUuid || contextData.contactId;
  const patientId = props.options.patientId || contextData.patientId;
  const locationId = contextData.locationId;
  const mlovData = useContext(CommonDataContext);
  const forceReadonly = props?.options?.forceReadonly
  const durationUnits =
    getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_DURATION);
  const goalStatusList = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PLAN_ITEM_STATUS);
  const enableGoalProgress = isEnableGoalProgress(contextData.userSettings);
  const enableRuleAlert = isEnableRuleAlert(contextData.userSettings);

  const carePlanDetailsStartDateTime = props?.options?.carePlanDetailsStartDateTime || contextData.carePlanDetailsStartDateTime;

  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const [componentValue, setComponentValue] = useState<IAddOrUpdateGoalValue>(
    props.component?.selectedValue ||
    props.component?.defaultComponentValue ||
    { goals: [] });
  const [componentState, setComponentState] = useState<IAddOrUpdateGoalState>({
    selectedGoal: undefined,
    showErrors: false,
    loading: false,
    formError: contextData.formError,
    editModalCollapse: false,
    mlovMap: new Map(),
    goalsLoading: [],
    goalsWithError: [],
    workflowMasterId: '',
    showWorkflowAuditView: false,
    deleteGoalConfirmation: {
      isOpen: false,
      goal: undefined
    }
  });

  const toast = useToast();

  const goalEntityTypeList = componentState.mlovMap.get(MLOV_CATEGORY.GOAL_TARGET_ENTITY_TYPE) || [];
  const goalStatusTypeList = componentState.mlovMap.get(MLOV_CATEGORY.GOAL_STATUS) || [];
  const carePlanCategoryList = componentState.mlovMap.get(MLOV_CATEGORY.CARE_PLAN_CATEGORY) || [];
  const ruleEntityTypesList = componentState.mlovMap.get(MLOV_CATEGORY.RULE_ENTITY_TYPE) || [];


  const RULE_ENTITY_TYPE_IDS = {
    CARE_PLAN_GOAL: getMlovIdFromCode(ruleEntityTypesList, RULE_ENTITY_TYPE_CODE.CARE_PLAN_GOAL),
  };
  const GOAL_ENTITY_TYPE_IDS = {
    VITAL: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.VITAL),
    ACTIVITY: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.ACTIVITY),
    LAB_ANALYTE: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.LAB_ANALYTE),
    SOCIAL: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.SOCIAL),
    FORM: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.FORM),
    OTHER: getMlovIdFromCode(goalEntityTypeList, GOAL_ENTITY_TYPE_CODES.OTHER),
  }
  const measurableEntityTypeIds = [
    GOAL_ENTITY_TYPE_IDS.ACTIVITY,
    GOAL_ENTITY_TYPE_IDS.LAB_ANALYTE,
    GOAL_ENTITY_TYPE_IDS.VITAL,
    GOAL_ENTITY_TYPE_IDS.FORM
  ];
  const capabilities = useMemo(() => contextData.ehrCapabilities.find((ehr: IEhrCapability) => ehr.resourceName === FHIR_RESOURCE.OBSERVATION), [contextData.ehrCapabilities]);

  // APIs
  const [getAccountMlovList] = useLazyQuery(MlovQueries.GET_ACCOUNT_MLOVS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
    context: {
      headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [addOrUpdateTemplate] = useMutation(CarePlanGoalQueries.ADD_OR_UPDATE_GOAL_TEMPLATES, {
    fetchPolicy: 'no-cache',
    context: {
      Headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  })

  const [updateContactGoalStatus] = useMutation(CarePlanGoalQueries.ADD_OR_UPDATE_CONTACT_GOAL_STATUS, {
    fetchPolicy: 'no-cache',
    context: {
      Headers: contextData.headers,
      service: CARESTUDIO_APOLLO_CONTEXT,
    }
  })

  const validateData = (currentData: IAddOrUpdateGoalValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    setComponentState((prev) => ({...prev, showErrors: true}));
    const isValid = true;
    return { isValid: isValid, message: !isValid ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isDisabled = () => {
    return !props.component?.allowToEdit || false;
  };

  const getExistingData = (patientId: string, skipLoader?: boolean) => {
    if(!skipLoader){
      setComponentState((prev) => ({...prev, loading: true}));
    }
    // call goals api
  };

  const isCarePlanPublished = (): boolean => {
    return !!carePlanDetailsStartDateTime;
  }

  const fetchMetaData = async () => {
    try {
      const tenantId = contextData?.tenantId;
      if (!tenantId) {
        return;
      }
      const mlovData = await getAccountMlovList({
        variables: {
          categories: [MLOV_CATEGORY.GOAL_TARGET_ENTITY_TYPE, MLOV_CATEGORY.GOAL_STATUS, MLOV_CATEGORY.CARE_PLAN_CATEGORY, MLOV_CATEGORY.RULE_ENTITY_TYPE],
        },
      });
      const entityTypes: IMlov[] = (mlovData?.data?.accountMlovsDeprecated || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.GOAL_TARGET_ENTITY_TYPE && (!mlov.tenantId || mlov.tenantId === tenantId));
      const goalStatusTypes: IMlov[] = (mlovData?.data?.accountMlovsDeprecated || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.GOAL_STATUS && (!mlov.tenantId || mlov.tenantId === tenantId));
      const carePlanCategoryTypes: IMlov[] = (mlovData?.data?.accountMlovsDeprecated || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.CARE_PLAN_CATEGORY && (!mlov.tenantId || mlov.tenantId === tenantId));
      const ruleEntityTypes: IMlov[] = (mlovData?.data?.accountMlovsDeprecated || []).filter((mlov: any) => mlov?.category?.category === MLOV_CATEGORY.RULE_ENTITY_TYPE && (!mlov.tenantId || mlov.tenantId === tenantId));

      setComponentState((prev)=>{
        const map = prev.mlovMap;
        map.set(MLOV_CATEGORY.GOAL_TARGET_ENTITY_TYPE, entityTypes);
        map.set(MLOV_CATEGORY.GOAL_STATUS, goalStatusTypes);
        map.set(MLOV_CATEGORY.CARE_PLAN_CATEGORY, carePlanCategoryTypes);
        map.set(MLOV_CATEGORY.RULE_ENTITY_TYPE, ruleEntityTypes);
        return {
          ...prev,
          mlovMap: map,
        }
      });
    } catch (error) {

      setComponentState((prev) => ({
        ...prev,
        formError: FormError.configurationDataAPIFail,
      }));
    }
  };

  const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
    return (
      <Pressable
        width={'100%'}
        onPress={(event) => event.stopPropagation()}
      >
        <View
          style={[{ flex: 1 }, !isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}]}
        >
          <GoalTemplateSearch
            addNewOptionEnabled={true}
            clearOnSelection
            filterGoals={getFilteredGoals()}
            disabled={isGoalsEditing(componentValue.goals)}
            additionalHeaders={contextData.headers}
            placeholder="Search and add new goal"
            isShowError={false}
            onChange={(value: IGoal) => {
              if (value) {
                addNewGoal(value);
              }
            }}
          />
        </View>
      </Pressable>
    );
  };

  const initData = async () => {
    const patientId = contextData?.patientId;
    await fetchMetaData();
     // if fields are not editable the latest data should be prefilled
    if (patientId && !isPatientNote && (isDisabled() || !props.component.selectedValue)) {
      // setComponentState((prev) => ({...prev, loading: true}));
      // getExistingData(patientId);
    } else {
      setComponentState((prev) => ({...prev, loading: false}));
    }
  }

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    const value = cloneDeep(componentValue);
    if (contextData.isBuilderMode) {
      props.component.defaultComponentValue = value;
    }
    if (!isGoalsEditing(componentValue.goals)) {
      props.onChange(value);
    }
  }, [componentValue]);

  props.validateRef.current = validateData;

  const getPreviewData = (): {
    id: string;
    key: string;
    value: string;
  }[] => {
    const previewData: {
      id: string;
      key: string;
      value: string;
    }[] = [];
    const data = ['firstName', 'lastName', 'email', 'phoneNumber', 'relationType'];
    data.forEach((item) => {
      const value = componentValue[
        item as keyof IAddOrUpdateGoalValue
      ] as any;
      const previewItem = {
        key: `${intl.formatMessage({id: item})}`,
        value: `${item === 'relationType' ? value?.code : value}`,
        id: uuidv4(),
      };
      if (value) previewData.push(previewItem);
    });
    return previewData;
  };
  if (isPreviewMode) {
    const data = getPreviewData();
    return (
      <>
        {!componentState.loading && !componentState.formError && (
          <>
            {data.length > 0 && (
              <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
              <DetailPreview titleLocalId={props.component.label}>
                <FlatList
                  data={data}
                  initialNumToRender={data?.length}
                  keyExtractor={(item) => item.id}
                  renderItem={({item}) => (
                    <div className="page-break">
                      <HStack key={item.id} flex={1}>
                        <Text flex={3}>{item.key}</Text>
                        <Text flex={7}>
                          {item.value}
                        </Text>
                      </HStack>
                    </div>
                  )}
                />
              </DetailPreview>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  const addNewGoal = (prefillData: IGoal) => {
    const uniqueId = uuidv4();
    const defaultEntityTypeId = GOAL_ENTITY_TYPE_IDS.OTHER;
    if(prefillData.id){
      delete prefillData.id;
    }
    prefillData.isFirstEdit = true;
    prefillData.viewType = GoalViewType.edit;
    if (!prefillData.templateId) {
      prefillData.temporaryTemplateId = uniqueId;
    }
    if (!prefillData.goalTargets.length) {
      prefillData.templateId
      prefillData.goalTargets = [
        {
          entityTypeId: defaultEntityTypeId,
          title: '',
          measure: {
            targetType: 'text',
            targetValue: {
              value: '',
            },
            unit: prefillData.unit,
            frequency: {
              code: prefillData?.goalTargets?.[0]?.measure?.frequency?.code || '',
            }
          },
        }
      ];
    }
    setComponentValue((prev) => ({
      ...prev,
      goals: [
        prefillData,
        ...prev.goals,
      ]
      }
    ));
    setComponentState((prev)=> ({
      ...prev,
      selectedGoal: cloneDeep(prefillData)
    }));
  }


  const isValidGoal = (goal: IGoal) => {
    const goalTargetsValid = goal.goalTargets.every((goalTarget) => {
      const isMeasurableEntity = measurableEntityTypeIds.includes(goalTarget.entityTypeId || '');
      let isValid = true;
      const isValidDuration = !!goal.duration && !!goal.durationUnitId;
      const isValidFrequency = goalTarget?.entityTypeId !== GOAL_ENTITY_TYPE_IDS.OTHER && !!goalTarget.measure?.frequency?.code;
      if (isMeasurableEntity) {
        isValid = (!!goalTarget.entityCode || !!goalTarget.formId) && !!goalTarget.title && isValidDuration && isValidFrequency;
        if (goalTarget.measure?.targetValue) {
          if (goalTarget.measure?.operator === GoalOperator.between) {
            isValid = isValid &&
            !!goalTarget.measure?.operator &&
            !!(goalTarget.measure?.targetValue.high || goalTarget.measure?.targetValue.high === 0) &&
            !!(goalTarget.measure?.targetValue.low || goalTarget.measure?.targetValue.low === 0);
          } else {
            isValid = isValid &&
            !!goalTarget.measure?.operator &&
            !!(goalTarget.measure?.targetValue.value || goalTarget.measure?.targetValue.value === 0);
          }
        }
      }
      return !!goalTarget.entityTypeId && isValid;
    })
    return !!goal.title && goal.goalTargets.length > 0 && goalTargetsValid;
  }

  const onSaveGoal = async (goal: IGoal) => {
    const uniqueId = getGoalUniqueId(goal) || '';

    if (!isValidGoal(goal)) {
      setComponentState((prev) => {
        return {
          ...prev,
          goalsWithError: prev.goalsWithError.concat([uniqueId]),
        }
      });
      return;
    } else {
      setComponentState((prev) => {
        return {
          ...prev,
          goalsWithError: prev.goalsWithError.filter(item => item !== uniqueId),
        }
      });
    }
    delete goal.isFirstEdit;
    try {
      if (!goal.templateId || (goal.templateId && contextData.isBuilderMode)) {
        setComponentState((prev) => {
          return {
            ...prev,
            goalsLoading: prev.goalsLoading.concat([uniqueId]),
          }
        });
        const entityTypeId = goal.goalTargets?.[0]?.entityTypeId;
        const goalCopy = cloneDeep(goal);
        delete goalCopy.viewType;
        delete goalCopy.temporaryTemplateId;
        delete goalCopy.templateId;
        const response = await addOrUpdateTemplate({
          variables: {
            params: [{
              ...(goal.templateId && { id: goal.templateId }),
              ...(entityTypeId && { entityTypeId: entityTypeId }),
              title: goal.title,
              template: goalCopy,
            }]
          }
        });
        const templateId = response?.data?.addOrUpdateGoalTemplates?.[0]?.id;
        if (templateId) {
          setComponentValue((prev) => {
            const prevGoals = prev.goals;
            const index = getIndexByUniqueId(prev.goals, goal);
            prevGoals[index].templateId = templateId;
            return {
              ...prev,
              goals: prevGoals,
            }
          });
        }
        setComponentValue((prev) => {
          const updatedGoals = updateViewTypeByUniqueId(prev.goals, goal, GoalViewType.list);
          return {
            ...prev,
            goals: updatedGoals,
          }
        });
        setComponentState((prev) => {
          return {
            ...prev,
            goalsLoading: prev.goalsLoading.filter(item => item !== uniqueId),
          }
        });
      }
      else {
        setComponentValue((prev) => {
          const updatedGoals = updateViewTypeByUniqueId(prev.goals, goal, GoalViewType.list);
          return {
            ...prev,
            goals: updatedGoals,
          }
        });
      }
    } catch (error) {

      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
      setComponentState((prev) => {
        return {
          ...prev,
          goalsLoading: prev.goalsLoading.filter(item => item !== uniqueId),
        }
      });
    }
  }


  const onUpdateGoalStatus = async (goal: IGoal, statusId: string) => {
    setComponentState((prev) => {
      return {
        ...prev,
        goalsLoading: prev.goalsLoading.concat([goal.id || '']),
      }
    });
    try {
        const response = await updateContactGoalStatus({
          variables: {
            params: {
              id: goal.id,
              statusId: statusId,
              title: goal.title,
              contactId: contactUuid,
            },
          },
        });

        if (response) {
          setComponentValue((prev) => {
            const updatedGoals = prev.goals?.map((prevGoal) => {
              if (prevGoal.id === goal.id) {
                return goal;
              }
              return prevGoal;
            });
            return {
              ...prev,
              goals: updatedGoals,
            };
          });
        }
        setComponentState((prev) => {
          return {
            ...prev,
            goalsLoading: prev.goalsLoading.filter(item => item !== goal.id),
          }
        });
    } catch (error) {

      toast({
        message: intl.formatMessage({
          id: 'apiErrorMsg',
        }),
        toastType: ToastType.error,
      });
      setComponentState((prev) => {
        return {
          ...prev,
          goalsLoading: prev.goalsLoading.filter(item => item !== goal.id),
        }
      });
    }
  }

  const renderContactRules = (goal: IGoal, isReadOnly: boolean) => {
    return (
      <ContactRuleListView
        ruleType={getRuleType(goal, GOAL_ENTITY_TYPE_IDS)}
        defaultRules={goal.contactRules}
        metadata={{
          fetchRules: false,
          contactId: contactUuid,
          isTemplateContext: contextData.isBuilderMode,
          entityId: goal.id || '',
          entityTypeId: RULE_ENTITY_TYPE_IDS.CARE_PLAN_GOAL,
          allowedOperations: {
            create: !isReadOnly,
            update: !isReadOnly,
            delete: !isReadOnly,
          },
          additionalHeaders: contextData.headers,
        }}
        onChange={(rules) => {
          setComponentValue((prev) => {
            const prevGoals = prev.goals;
            const index = getIndexByUniqueId(prev.goals, goal);
            prevGoals[index].contactRules = [...rules];
            return {
              ...prev,
              goals: prevGoals,
            }
          });
        }}
        isCarePlanPublished={isCarePlanPublished()}
        onOpenWorkflowAuditView={(data) => {
          setComponentState((prev) => ({
            ...prev,
            workflowMasterId: data.workflowMasterId,
            showWorkflowAuditView: true,
          }));
          setSearchParams({
            isExecutionLog: 'true',
          });
        }}
      />
    )
  }

  const handleDeleteConfirmation = (goal: IGoal) => {
    setComponentState(prev => ({
      ...prev,
      deleteGoalConfirmation: {
        isOpen: true,
        goal
      }
    }));
  };

  const handleDeleteCancel = () => {
    setComponentState(prev => ({
      ...prev,
      deleteGoalConfirmation: {
        isOpen: false,
        goal: undefined
      }
    }));
  };

  const handleDeleteConfirm = () => {
    const goal = componentState.deleteGoalConfirmation.goal;
    if (!goal) return;

    if (goal.id) {
      goal.isDeleted = true;
      const index = componentValue.goals.findIndex(
        (item) => item.id === goal.id
      );
      componentValue.goals[index] = goal;
      setComponentValue((prev) => ({
        ...prev,
        goals: [...componentValue.goals],
      }));
    } else {
      const index = getIndexByUniqueId(componentValue.goals, goal);
      componentValue.goals.splice(index, 1);
      setComponentValue((prev) => ({
        ...prev,
        goals: [...componentValue.goals],
      }));
    }

    handleDeleteCancel();
  };

  const renderGoalEditView = (goal: IGoal, index: number) => {
    const uniqueId = getGoalUniqueId(goal) || '';
    const isGoalLoading = componentState.goalsLoading.includes(uniqueId);
    const isGoalError = componentState.goalsWithError.includes(uniqueId);
    goal.durationUnitId = goal.durationUnitId ?? durationUnits.find(item => item.code === 'MONTH')?.id;
    return (
      <Stack direction='column' space={12} style={styles.padding12}>
        <Stack
          space={16}
          direction={isMobileScreen ? 'column' : 'row'}
          style={styles.flex1}
        >
          <FormControl
            isInvalid={isGoalError && !goal.title}
            isDisabled={false}
            flex={1}
          >
            <FormControl.Label
              isRequired={true}
            >
              <DisplayText size={'smRegular'} extraStyles={{color: Colors.FoldPixel.GRAY250}} textLocalId="title" />
            </FormControl.Label>
            <Input
              _focus={{borderColor: '#D0D5DD'}}
              value={goal.title}
              isReadOnly={props.disabled || isGoalLoading}
              {...testID(TestIdentifiers.goalTitle)}
              onChangeText={(text: string) => {
                setComponentValue((prev) => {
                  const prevGoals = prev.goals;
                  const index = getIndexByUniqueId(prev.goals, goal);
                  prevGoals[index].title = text;
                  return {
                    ...prev,
                    goals: prevGoals,
                  }
                });
              }}
            />
          </FormControl>
        </Stack>
        {goal.goalTargets.map((goalTarget, goalTargetIndex) => {
          return (
            <GoalTargetView
              vitals={getVitalList()}
              key={`GoalTarget_${index}`}
              goalTarget={goalTarget}
              disabled={props.disabled || isGoalLoading}
              showErrors={isGoalError}
              duration={goal.duration}
              durationUnitId={goal.durationUnitId}
              onDurationChange={(duration: number, durationUnitId: string) => {
                setComponentValue((prev) => {
                  const prevGoals = prev.goals;
                  const index = getIndexByUniqueId(prev.goals, goal);
                  prevGoals[index].duration = duration;
                  prevGoals[index].durationUnitId = durationUnitId;
                  return {
                    ...prev,
                    goals: prevGoals,
                  }
                });
              }}
              metaData={{
                goalEntityTypeIds: GOAL_ENTITY_TYPE_IDS,
                patientContactUuid: contactUuid,
                patientId: patientId,
                locationId: locationId || ''
              }}
              onChange={(goalTarget) => {
                setComponentValue((prev) => {
                  const prevGoals = prev.goals;
                  const index = getIndexByUniqueId(prev.goals, goal);
                  prevGoals[index].goalTargets[goalTargetIndex] = goalTarget;
                  return {
                    ...prev,
                    goals: prevGoals,
                  }
                });
              }}
              goalTypeHandleObject={{
                goalType: IGoalType.RELATIVE,
                onGoalTypeChange: (item: IGoalType) => {
                  setComponentValue((prev) => {
                    const prevGoals = prev.goals;
                    const index = getIndexByUniqueId(prev.goals, goal);
                    prevGoals[index].goalType = item;
                    return {
                      ...prev,
                      goals: prevGoals,
                    }
                  });
                }
              }}
            />
          );
        })}
        {enableRuleAlert && renderContactRules(goal, false)}
        <Stack direction='row' style={styles.justifyContentFlexEnd}>
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.SECONDARY,
              style: { margin: 3 },
              onPress: () => {
                setComponentValue((prev) => {
                  const updatedGoals = removeFromEditView(prev.goals, componentState.selectedGoal || goal);
                  return {
                    ...prev,
                    goals: updatedGoals,
                  }
                });
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'cancel',
              }),
              withRightBorder: false,
            }}
          />
          <FoldButton
            nativeProps={{
              variant: BUTTON_TYPE.PRIMARY,
              style: { margin: 3 },
              isLoading: isGoalLoading,
              isDisabled: isGoalLoading || props.disabled,
              onPress: () => {
                onSaveGoal(goal);
              },
            }}
            customProps={{
              btnText: intl.formatMessage({
                id: 'save',
              }),
              withRightBorder: false,
            }}
          />
        </Stack>
      </Stack>
    );
  }

  const getVitalList = (): IVitalData[] => {
    const vitals = capabilities?.abilities?.allowedVitalList || [];
    return vitals.filter((item: IVitalData) => !item.isHidden);
  };

  const onActionPerformed = (action: CarePlanAction, uniqueId: string, data?: any) => {
    const goal = componentValue.goals.find(goal => getGoalUniqueId(goal) === uniqueId);
    if (!goal) return;

    switch (action) {
      case CarePlanAction.STATUS_CHANGE:
        const newStatusId = data || '';
        const updatedGoal = {
          ...goal,
          statusId: newStatusId,
          status: getMlovObjectFromId(newStatusId, goalStatusList)
        }
        onUpdateGoalStatus(updatedGoal, newStatusId)
        break;

      case CarePlanAction.EDIT:
        let selectedGoal: IGoal | undefined;
        setComponentValue((prev) => {
          const updatedGoals = updateViewTypeByUniqueId(prev.goals, goal,  GoalViewType.edit);
          selectedGoal = getGoalByUniqueId(prev.goals, goal);
          return {
            ...prev,
            goals: updatedGoals,
          }
        });

        setComponentState((prev) => ({
          ...prev,
          selectedGoal: selectedGoal
            ? cloneDeep(selectedGoal)
            : undefined,
        }));
        break;

      case CarePlanAction.DELETE:
        handleDeleteConfirmation(goal)
        break;
    }
  }

  const getGoalIcon = (goal: IGoal) => {
   switch(goal.goalTargets?.[0]?.entityTypeId) {
    case GOAL_ENTITY_TYPE_IDS.VITAL:
      return <VitalGoalSvg size={18} />;
    case GOAL_ENTITY_TYPE_IDS.ACTIVITY:
      return <ActivityGoalSvg size={18} />;
    case GOAL_ENTITY_TYPE_IDS.LAB_ANALYTE:
      return <LabGoalSvg size={18} />;
    case  GOAL_ENTITY_TYPE_IDS.FORM:
      return <AssessmentGoalSvg size={18} />;
    default:
      return <OtherGoalSvg size={18} />;
   }
  }

  const renderGoalListView = (goal: IGoal) => {
    const categoryName =
      isWidgetView && goal.categoryId
        ? getMlovValueFromId(carePlanCategoryList, goal.categoryId)
        : '';
    const isEditing = isGoalsEditing(componentValue.goals);
    const goalProgress = goal.goalProgress || 0;
    return (
      <Stack direction="row" style={styles.padding12AlignItemsCenter}>
        <View style={{marginRight: 16}}>
          {getGoalIcon(goal)}
        </View>
        <Stack direction='column' space={4} style={styles.flex1}>
          <Text size={'smMedium'} style={styles.fontSize14FontWeight500ColorGray600}>{goal.title}</Text>
          <Stack direction='column'>
            {goal.goalTargets.length > 0 &&
              goal.goalTargets.map((goalTarget) => {
                const finalText = getGoalTargetText(goalTarget, goal, {
                  GOAL_ENTITY_TYPE_IDS,
                  vitals: getVitalList(),
                  durationUnits: durationUnits,
                });
                if (!finalText) {
                  return <></>;
                }
                return <Text size={'smRegular'} style={styles.fontSize14ColorGray400}>
                  {finalText}
                </Text>
              })}
          </Stack>
          {/* {categoryName ? (
            <Text
              style={styles.text5}
            >
              {categoryName}
            </Text>
          ) : (
            <></>
          )} */}
          {enableRuleAlert && renderContactRules(goal, true)}
        </Stack>
        <Stack direction='row' space={4} style={styles.alignItemsCenter}>
          {enableGoalProgress && (
            goalProgress >= 0 && goalProgress < 100 ? (
              <>
                <Progress
                  percent={goalProgress}
                  strokeColor={getGoalProgressBarColor(goalProgress)}
                  style={{
                    height: 24,
                    width: 180,
                  }}
                />
              <View style={styles.viewStyle2} />
              </>
            ) : (
              <>
                <GoalInfoView goalProgress={goalProgress} />
                <View style={styles.viewStyle2} />
              </>
            )
          )}
          <CarePlanActionView
            uniqueId={getGoalUniqueId(goal) || ''}
            selectedStatusCode={goal.status?.code || ''}
            showStatusChange={true}
            showDelete={!isWidgetView}
            showEdit={!isWidgetView && goal.status?.code !== CARE_PLAN_ITEM_STATUS_CODES.COMPLETED}
            isDisabled={isEditing || !isCarePlanPublished() || !isWidgetView || goal.status?.code === CARE_PLAN_ITEM_STATUS_CODES.COMPLETED}
            statusOptions={(goalStatusList || []).map(item => ({ label: item.value, value: item.id, code: item.code }))}
            onActionPerformed={onActionPerformed}
            forceReadonly={forceReadonly}
          />
        </Stack>
        {/* {isWidgetView && (
          <GoalStatusView
            disabled={false}
            goal={goal}
            isGoalLoading={componentState.goalsLoading.includes(goal.id || '')}
            goalStatusList={goalStatusTypeList}
            onChange={(statusId, statusCode) => {
              const updatedGoal = {
                ...goal,
                statusId: statusId,
                status: {
                  code: statusCode
                }
              }
              onUpdateGoalStatus(updatedGoal, statusId)
            }}
          />
        )} */}

       {/* {!isWidgetView && <Stack direction='row' space={4}>
        <Pressable
            disabled={isEditing}
            onPress={() => {
              let selectedGoal: IGoal | undefined;
              setComponentValue((prev) => {
                const updatedGoals = updateViewTypeByUniqueId(prev.goals, goal,  GoalViewType.edit);
                selectedGoal = getGoalByUniqueId(prev.goals, goal);
                return {
                  ...prev,
                  goals: updatedGoals,
                }
              });

              setComponentState((prev) => ({
                ...prev,
                selectedGoal: selectedGoal
                  ? cloneDeep(selectedGoal)
                  : undefined,
              }));
            }}
          >
            <Feather name='edit-2' color={isEditing? Colors.Custom.Gray300 : Colors.Custom.Gray500} size={18} />
          </Pressable>
          <View style={styles.viewStyle2} />
          <Pressable
            disabled={isEditing}
            onPress={() => handleDeleteConfirmation(goal)}
          >
            <Feather name='trash-2' color={isEditing? Colors.Custom.Gray300 : Colors.Custom.Gray500} size={18} />
          </Pressable>
       </Stack>} */}
      </Stack>
    );
  }

  const getFilteredGoals = () => {
    return componentValue.goals.filter(goal => !goal.isDeleted);
  }

  const onCloseWorkflowAuditView = () => {
    setComponentState((prev) => ({
      ...prev,
      showWorkflowAuditView: false,
      workflowMasterId: '',
    }));
    setSearchParams({});
  }

  return (
    <div className="page-break">
      <VStack ref={componentRef}>
        {!componentState.loading && componentState.formError && (
          <FormComponentError error={componentState.formError} />
        )}
        <DetailViewCollapse
          btnList={[]}
          hideArrow
          collapsible='disabled'
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <Stack direction="column">
            {componentState.loading && (
              <Stack direction="column" style={styles.padding12}>
                <FormComponentLoader />
              </Stack>
            )}
            {getFilteredGoals().length === 0 && (
              <VStack paddingBottom={5} alignItems={'center'} padding={3}>
                <NoGoalsSvg titleId="noGoalList" />
                <HStack space={2} alignItems="center">
                  <Spacer />

                  {(!isWidgetView && !forceReadonly) && getSearchComponent()}
                </HStack>
              </VStack>
            )}
            {(getFilteredGoals().length > 0 && !isWidgetView && !forceReadonly) && (
              <HStack
                width={'100%'}
                space={2}
                alignItems="center"
                paddingX={3}
                paddingTop={3}
              >
                <View style={styles.flex1}>{getSearchComponent(true)}</View>
              </HStack>
            )}
            {getFilteredGoals().length > 0 &&
              !componentState.loading &&
              !componentState.formError && (
                <VStack
                  flex={1}
                  // padding={isWidgetView ? 0 : 3}
                  paddingTop={(isWidgetView || forceReadonly) ? 0 : 3}>
                  <Stack
                    direction="column"
                    style={{
                      borderColor: Colors.Custom.Gray300,
                      borderTopWidth: (isWidgetView || forceReadonly) ? 0 : 0.5,
                      // borderRadius: 8,
                      overflow: 'hidden',
                    }}
                  >
                    {getFilteredGoals().map((goal, index) => {
                      return (
                        <Stack direction="column" key={`Goal_${index}`}>
                          {goal.viewType === GoalViewType.edit &&
                            renderGoalEditView(goal, index)}
                          {goal.viewType === GoalViewType.list &&
                            renderGoalListView(goal)}
                          {index !== getFilteredGoals().length - 1 && (
                            <Divider />
                          )}
                        </Stack>
                      );
                    })}
                  </Stack>
                </VStack>
              )}
          </Stack>
        </DetailViewCollapse>
      </VStack>
      {componentState?.showWorkflowAuditView && (
        <Drawer
          open={componentState?.showWorkflowAuditView}
          footer={false}
          width={'60%'}
          closeIcon={null}
          closable={false}
          maskClosable={true}
          destroyOnClose
          onClose={onCloseWorkflowAuditView}
        >
          <WorkflowAuditGraph
            workflowMasterId={componentState.workflowMasterId}
            contactId={props?.options?.foldContactId || contextData.foldContactId}
            openInDrawer={true}
            onCloseWorkflowAuditView={onCloseWorkflowAuditView}
          />
        </Drawer>
      )}
      <CustomConfirmationModal
        isOpen={componentState.deleteGoalConfirmation.isOpen}
        headerText="deleteGoalConfirmation"
        message="deleteGoalConfirmationMessage"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        customIcon={<TrashSvg />}
      />
    </div>
  );
}

export default AddOrUpdateGoals
