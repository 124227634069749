import React, {useContext, useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {
  GET_SINGLE_USER,
  SEARCH_USERS_BY_NAME_AND_ROLE_CODES,
} from '../../../../../services/User/UserQueries';
import {
  CARE_TEAM,
  CARE_TEAM_MEMBER_TYPE,
  MLOV_CATEGORY,
} from '../../../../../constants/MlovConst';
import {getProviderSearchWhereCondition} from './utils';
import {debounce} from 'lodash';
import {SearchBar} from '../../../../common/SearchBar';
import {
  Divider,
  FlatList,
  Pressable,
  Skeleton,
  Text,
  VStack,
  View,
  useToast,
} from 'native-base';
import Stack from '../../../../common/LayoutComponents/Stack';
import {FoldButton} from '../../../../CommonComponents/FoldButton/FoldButton';
import {BUTTON_TYPE, GROUP_MEMBER_TYPE} from '../../../../../constants';
import {IUser} from '../../../../../Interfaces';
import {DisplayCardAvatar} from '../../../../common/DisplayCard/DisplayCardAvatar';
import {isWeb} from '../../../../../utils/platformCheckUtils';
import {Colors} from '../../../../../styles';
import {NoDataFound} from '../../../../common/NoDataFound';
import {useIntl} from 'react-intl';
import {GET_CARE_TEAM_MEMBER_TYPE} from '../../../../../services/CareTeam/CareTeamQueries';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../constants/Configs';
import {
  getMlovIdFromCode,
  getMlovListFromCategory,
} from '../../../../../utils/mlovUtils';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import { ToastType, showToast } from '../../../../../utils/commonViewUtils';
import { ICareTeamMemberType } from '../../../../common/MemebersView/interface';
import {getBooleanFeatureFlag, getUserRolesNameList} from '../../../../../utils/commonUtils';
import {IUserRoleMap} from '../../../../RightSideContainer/Contacts/TeamMembers/interfaces';
import { StyleSheet } from 'react-native';
import { ICareProgramAssignee } from '../interfaces';
import { testID } from '../../../../../testUtils/Utils';
import { usePersonOmniViewContext } from '../../../PersonOmniView.context';
import FeatureFlags from '../../../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../constants/AccountConfigConst';

interface IProviderSelectForReviewProps {
  onChange?: (value: string) => void;
  onCareProgramAssigneeChange?: (value: ICareProgramAssignee) => void;
  selectedProviderId: string;
  onCancel?: () => void;
  contactId: string;
  titleString?: string;
  hideCancelButton?: boolean;
  hidePCPUser?: boolean;
  showAllRolesOfUsers?: boolean;
  flatlistMaxHeight?: number;
  flatlistMinHeight?: number;
  resetSearchTextOnPopoverOpen?: boolean;
}

const ProviderSelectForReview = (props: IProviderSelectForReviewProps) => {
  const intl = useIntl();
  const {onChange, onCancel, contactId} = props;
  const [users, setUsers] = useState<IUser[]>([]);
  const [pcpUser, setPcpUser] = useState<IUser | undefined>(undefined);
  const [componentLoading, setComponentLoading] = useState(false);
  const toast = useToast();
  const {data: contactData} = usePersonOmniViewContext();
  const mlovData = useContext(CommonDataContext);
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const commonContextData = useContext(CommonDataContext);

  const [getUsersByRoles, {loading: searchLoading}] = useLazyQuery(
    SEARCH_USERS_BY_NAME_AND_ROLE_CODES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setUsers(data?.users || []);
      },
    }
  );

  const [getCareTeamMemberByMemberType] = useLazyQuery(
    GET_CARE_TEAM_MEMBER_TYPE,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  const careTeamMlovList =
    getMlovListFromCategory(
      commonContextData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.PRIMARY_CARE_TEAM
    ) || [];
  const primaryCareTeamTypeId = getMlovIdFromCode(careTeamMlovList, CARE_TEAM.CODE);

  const fetchInitData = async () => {
    try {
      setComponentLoading(true);
      const careTeamMemberTypeResponse = await getCareTeamMemberByMemberType({
        variables: {
          contactIds: contactId,
          careTeamTypeId: primaryCareTeamTypeId,
        },
      });
      const userUUID =
        careTeamMemberTypeResponse?.data?.careTeams[0]?.careTeamMemberType?.find(
          (careTeamMember: ICareTeamMemberType) => {
            return (
              careTeamMember?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP
            );
          }
        )?.userId;

      const promiseList: Promise<any>[] = [];
      promiseList.push(
        getUsersByRoles({
          variables: {
            where: getProviderSearchWhereCondition('', userUUID, false, contactData?.contactPracticeLocations, isMultiTenancyEnabled),
          },
        })
      );

      if (userUUID) {
        promiseList.push(
          getSingleUserById({
            variables: {
              userId: userUUID,
            },
          })
        );
      }

      const [userByRolesResponse, pcpUserDataResponse] =
        await Promise.all(promiseList);

      if (userUUID) {
        const pcpUser = pcpUserDataResponse?.data?.users?.[0];
        setPcpUser(pcpUser);
      }

      const users = userByRolesResponse?.data?.users;
      setUsers(users);
      setComponentLoading(false);
    } catch (error) {
      onCancel && onCancel();
      setComponentLoading(false);
      showToast(
        toast,
        intl.formatMessage({id: 'errorMsg'}),
        ToastType.error
      );
    }
  };

  useEffect(() => {
    if (props?.showAllRolesOfUsers) {
      fetchUsers('', '')
    } else {
      fetchInitData();
    }
  }, []);

  const fetchUsers = (value: string, uuid: string | undefined) => {
    getUsersByRoles({
      variables: {
        where: getProviderSearchWhereCondition(value, uuid, props?.showAllRolesOfUsers, contactData?.contactPracticeLocations, isMultiTenancyEnabled),
      },
    });
  };

  const renderUserItem = ({item}: {item: IUser}) => {
    const roleString = getUserRolesNameList(item.userRoles as IUserRoleMap[]).join(', ');
    return (
      <Pressable
        onPress={() => {
          onChange && onChange(item?.uuid || '');
          if (props?.onCareProgramAssigneeChange) {
            props?.onCareProgramAssigneeChange({uuid: item?.uuid, name: item?.name});
          }
        }}
        flexDirection="row"
        style={styles.userItemContainer}
      >
        <DisplayCardAvatar
          avatarStyle={{
            width: 36,
            height: 36,
            disableTop: true,
            textStyle: {
              fontSize: 16,
            },
          }}
          userData={{
            userType: GROUP_MEMBER_TYPE.USER,
            userId: item?.userId || '',
            name: item?.name,
            userName: item?.name,
            imgSrc: '',
          }}
          isLetterAvatarShow
        />
        <Stack direction="column" style={styles.userInfoContainer}>
          <Text size={'dtNormal'} color={Colors.FoldPixel.GRAY400} fontWeight={'400'} {...testID(item?.name)}>
            {item?.name}
          </Text>
          <Text
            size={'xsNormal'}
            color={Colors.FoldPixel.GRAY250}
            maxW={'90%'}
            isTruncated
          >
            {roleString}
          </Text>
        </Stack>
      </Pressable>
    );
  };

  const renderMainListView = () => {
    if (searchLoading) {
      return (
        <View style={styles.skeletonContainer} height={props?.flatlistMinHeight ? props?.flatlistMinHeight - 23 : undefined}>
          <Stack direction="column" space={4}>
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
            <Skeleton.Text lines={2} />
          </Stack>
        </View>
      );
    }

    return (
      <View maxH={props?.flatlistMaxHeight || 400} minH={props?.flatlistMinHeight} overflow="scroll">
        <FlatList
          mt={2}
          maxH={props?.flatlistMaxHeight || 400}
          data={users}
          keyExtractor={(item) => item?.uuid || ''}
          ListEmptyComponent={
            <View m={10}>
              <NoDataFound displayString="noUsersFound" />
            </View>
          }
          renderItem={renderUserItem}
          scrollEnabled={true}
          nestedScrollEnabled={true}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text fontSize={16} color={Colors.Custom.Gray500} mb={2}>
        {intl.formatMessage({id: props?.titleString || 'selectProviderForReview'})}
      </Text>
      <SearchBar
        placeholderText="Search Users"
        resetSearchTextOnPopoverOpen={props?.resetSearchTextOnPopoverOpen}
        onChange={debounce((value) => fetchUsers(value, pcpUser?.uuid), 200)}
      />
      {componentLoading ? (
        <Stack direction="column" space={4} style={styles.loadingContainer}>
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={2} />
          <Skeleton.Text lines={2} />
        </Stack>
      ) : (
        <VStack mt={2} flex={1}>
          {!!pcpUser && !props?.hidePCPUser && (
            <>
              <View mb={2}>
              <Text color={Colors.Custom.Gray500} fontWeight={'400'}>
                {'PRIMARY CARE PHYSICIAN'}
              </Text>
              {renderUserItem({item: pcpUser})}
              <Divider />
              </View>
              <Text color={Colors.Custom.Gray500} fontWeight={'400'}>
                {'OTHER PROVIDERS'}
              </Text>
            </>
          )}
            {renderMainListView()}
        </VStack>
      )}
      {!props?.hideCancelButton && (
        <View marginLeft={4} style={styles.buttonContainer}>
          <Stack direction="row" space={4} style={styles.buttonStack}>
            <FoldButton
              customProps={{
                btnText: 'Cancel',
              }}
              nativeProps={{
                variant: BUTTON_TYPE.SECONDARY,
                onPress: onCancel,
              }}
            />
          </Stack>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxHeight: '65vh',
    overflow: 'hidden'
  },
  userItemContainer: {
    marginVertical: 8,
  },
  avatarStyle: {
    width: 36,
    height: 36,
    disableTop: true,
    textStyle: {
      fontSize: 16,
    },
  },
  userInfoContainer: {
    marginLeft: isWeb() ? 10 : 8,
    width: isWeb() ? '80%' : '100%',
  },
  skeletonContainer: {
    marginVertical: 12,
  },
  loadingContainer: {
    marginTop: 4,
  },
  buttonContainer: {
    minHeight: 50,
  },
  buttonStack: {
    justifyContent: 'flex-end',
  },
});

export default ProviderSelectForReview;
