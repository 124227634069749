import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { Checkbox } from "antd";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { CheckboxSchema } from "../Schema/ComponentsSchema";
import { TextFieldComponent } from "../BaseComponents/BaseComponentInterface";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ConsentSvg from "../../../../../assets/Icons/FormBuilder/ConsentSvg";
import { v4 as uuidV4 } from 'uuid';
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";

export const CheckboxFieldFormElement: FormElement = {
  type: CheckboxSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(CheckboxSchema),
    key: getUniqueKey(map, CheckboxSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(CheckboxSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: ConsentSvg,
    label: CheckboxSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, value: boolean, silentCheck?: boolean): ValidationResult => {
  if (elementInstance.validate?.required && !value) {
    return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: value };
  }
  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (e: CheckboxChangeEvent) => {
    const result = localValidate(elementInstance, e.target.checked);
    setErrorMessage(result.errorMessage);
    setValue(e.target.checked);
  }

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        onChange={onChange}
        errorMessage={errorMessage}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: boolean;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const element = elementInstance as TextFieldComponent;
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState((selectedValue || defaultValue) ?? (element.defaultValue || false));
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: boolean, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || false, silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: boolean) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: CheckboxChangeEvent) => {
    const checked = newValue.target.checked;
    setValue(checked);
    if (!submitValue) return;
    await debouncedValidation(checked);
    submitValue(elementInstance.key, checked);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: boolean;
  onChange: (event: CheckboxChangeEvent) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const { label, validate, description, tooltip, allowToShare } = elementInstance;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      {isReadOnly && !isPrintForm ? (
        <>
          <Label label={label || ''} tooltip={tooltip} isReadOnly={isReadOnly} />
          <div className="field-value">{defaultValue ? 'Yes' : 'No'}</div>
        </>
      ) : (
        <div className="flex items-start gap-2">
          <Checkbox
            checked={defaultValue}
            onChange={onChange}
            data-testid={`checkbox-field-${elementInstance.key}`}
            disabled={isReadOnly || false}
          >
            <div className="flex flex-col gap-1">
              <Label
                label={label || ''}
                isRequired={validate?.required || false}
                tooltip={tooltip}
                isReadOnly={isReadOnly}
              />
              {errorMessage && (
                <span className="error-message">{errorMessage}</span>
              )}
            </div>
          </Checkbox>
        </div>
      )}
      {description && (
        <Description description={description} />
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as TextFieldComponent;
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip:
        'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...NewConditionalFields,
    ...KeyField,
    ...(isAllowShare ? ShareWithPatientFields : []),
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
