import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import BasicFields from "../../FHFormio/EditFormFields/BasicFields";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { CustomSelectSchema } from "../Schema/ComponentsSchema";
import Select from "../../FHFormio/CustomComponents/Select/Select";
import { SelectComponent } from "../BaseComponents/BaseComponentInterface";
import { v4 as uuidV4 } from 'uuid';

export const CustomSelectFieldFormElement: FormElement = {
  type: CustomSelectSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(CustomSelectSchema),
    key: getUniqueKey(map, CustomSelectSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(CustomSelectSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    label: CustomSelectSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,

};

const localValidate = (elementInstance: FormElementInstance, currentValue: string, silentCheck?: boolean): ValidationResult => {
  if (!currentValue) {
    if (elementInstance.validate?.required) {
      return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: currentValue };
    }
  }
  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: currentValue };
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (value: string) => {
    const valid = localValidate(elementInstance, value);
    setErrorMessage(valid.errorMessage);
    setValue(value);
  };

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={errorMessage}
      onChange={onChange}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState(defaultValue || selectedValue || elementInstance.defaultValue || '');
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: string | null, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || '', silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: true,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string | null) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: result.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: string | null) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

function ComponentView({
  elementInstance,
  defaultValue,
  errorMessage,
  onChange,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
}) {
  const { label, validate, description, tooltip, allowToShare, placeholder, data } = elementInstance as SelectComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      <Select
        component={{
          ...elementInstance,
          showSearch: true,
          allowClear: false,
          placeholder: placeholder || 'Select an option',
          data: {
            values: data?.values || []
          }
        }}
        disabled={isReadOnly || false}
        options={{}}
        validateRef={{}}
        setValueRef={{}}
        value={defaultValue}
        onChange={debounce(onChange, 500)}
      />
      {description && (
        <Description description={description} />
      )}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch } = useCustomFormBuilderContext();
  const element = elementInstance;
  const formElements = useMemo(() => [
    ...BasicFields,
    {
      type: 'datagrid',
      input: true,
      label: 'Options',
      key: 'data.values',
      tooltip: 'The select options that can be picked for this field.',
      reorder: true,
      components: [
        {
          label: 'Label',
          key: 'label',
          input: true,
          type: 'oldtextfield',
        },
        {
          label: 'Value',
          key: 'value',
          input: true,
          type: 'oldtextfield',
        }
      ],
    },
    ...NewConditionalFields,
    ...KeyField,
  ], []);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
