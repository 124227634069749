export default [
    {
        type: 'checkbox',
        key: 'allowToShare',
        label: 'Share with patient',
        input: true,
        defaultValue: true,
        customConditional: function customConditional(context: any) {
          return isAllowShareFormComponentWithPatient(context.options?.userSettings);
        },
    },
];

export const isAllowShareFormComponentWithPatient = (userSettings: any) => {
    return (
      userSettings['allow_share_form_component_with_patient']?.value === 'True' || false
    );
};
