import {Collapse,Divider,Drawer,Empty,Tooltip} from 'antd';
import {HStack,Spacer,Text} from 'native-base';
import {useEffect,useState} from 'react';

import {RightOutlined} from '@ant-design/icons';
import {useIntl} from 'react-intl';
import {View} from 'react-native';
import {BUTTON_TYPE,DATE_FORMATS} from '../../../../../../constants';
import {getLabReports} from '../../../../../../services/CommonService/OrderService';
import {Colors} from '../../../../../../styles';
import {
  getCurrentTimeZone,
  getDateStrFromFormatWithTimezone,
} from '../../../../../../utils/DateUtils';
import {Vital} from '../../../../../../utils/VitalUtils';
import {getEhrConfig} from '../../../../../../utils/capabilityUtils';
import {
  CINICAL_SECTIONS_FILTER_TAB_CODES,
  CINICAL_SECTIONS_VIEW_TABS,
  CLINICAL_SECTION_VIEW_TYPES
} from '../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionConstants';
import {ClinicalSectionViewToggle} from '../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionViewToggle';
import {FormLastSyncView} from '../../../../../common/FormLastSyncView/FormLastSyncView';
import Stack from '../../../../../common/LayoutComponents/Stack';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import PamiIconSvg from '../../../../../common/Svg/PamiIconSvg';
import {reactStyles,styles} from '../../../Analytics/style';
import {tooltipInnerOverlayStyle} from '../CustomWrapper/CustomComponentHelper';
import FormComponentLoader from '../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {LabReportsGraph} from './LabReportsGraph';
import {
  formatLabReportsData,
  getLastAddedGroup,
  getReportName,
  getReportValueAndUnit,
} from './LabReportsHelper';

interface ILabReportsState {
  seletedFilterTab?: string;
  selectedViewType?: string;
  relevantVitals?: string[];
  loading: boolean;
  collapsed: boolean;
  activePanels: string[];
  labReportsGroups: any[];
  isFirstSync?: boolean;
  lastUpdated?: string;
}

interface ILabReportProps {
  relevantCodes: string[];
  patientId?: string;
  contactId?: string;
  accountLocationId: string;
  ccmDate: string;
  isSideCar?: boolean;
  goals?: any;
  isHeaderView?: boolean;
}

const LAB_REPORT_PANEL_KEY = 'lab-reports';

const LabReports = (props: ILabReportProps) => {
  const isSidecar = props?.isSideCar || false;
  const isHeaderView = props?.isHeaderView;
  const [componentState, setComponentState] = useState<ILabReportsState>({
    loading: true,
    collapsed: true,
    labReportsGroups: [],
    relevantVitals: [Vital.bloodPressure, Vital.bmi],
    activePanels: [],
    selectedViewType: isHeaderView ? CLINICAL_SECTION_VIEW_TYPES.LIST : CLINICAL_SECTION_VIEW_TYPES.GRAPH,
    seletedFilterTab: CINICAL_SECTIONS_FILTER_TAB_CODES.ALL,
    isFirstSync: true,
    lastUpdated: '',
  });

  const intl = useIntl();

  const patientId = props.patientId;
  const contactId = props.contactId;
  const accountLocationId = props.accountLocationId;
  const ehrConfig = getEhrConfig(accountLocationId, '');

  const {Panel} = Collapse;

  useEffect(() => {
    getLabReportsData();
  }, [componentState.seletedFilterTab]);

  const getLabReportsData = () => {
    const queryParams = {
      ...(componentState.seletedFilterTab ===
        CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT && {
        code: props.relevantCodes.toString(),
      }),
    };

    setComponentState((prev) => ({
      ...prev,
      loading: true,
    }));

    getLabReports(patientId || '', accountLocationId, queryParams)
      .then((response) => {
        const reports = response?.data?.entry;
        const formattedLabreportsData = formatLabReportsData(reports);
        const lastSynced = response?.data?.meta?.lastUpdated;

        setComponentState((prev) => ({
          ...prev,
          labReportsGroups: formattedLabreportsData || [],
          loading: false,
          activePanels: formattedLabreportsData?.length > 0 ? [LAB_REPORT_PANEL_KEY] : prev.activePanels,
          lastUpdated: lastSynced,
        }));
      })
      .catch((error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  };

  const getDetailView = (isCompact: boolean, isGraph: boolean) => {
    if (!isSidecar && isCompact) {
      return (
        <Stack direction="column">
          {!componentState.loading &&
            componentState.labReportsGroups.length > 0 && (
              <View>
                {(componentState.labReportsGroups || []).map(
                  (reportGroup, index) => {
                    const key = `${reportGroup.code}_${index}`;
                    const lastReport = getLastAddedGroup(reportGroup);
                    if (!lastReport) {
                      return <></>;
                    }
                    return (
                      <Stack direction="row" key={key} style={styles.marginTop16}>
                        <Text size={'smMedium'} color={Colors.FoldPixel.GRAY400}>
                          {getReportName(lastReport)}
                        </Text>
                        <Spacer />
                        <Stack direction="column">
                          <Text size="smSemibold">
                            {getReportValueAndUnit(lastReport)}
                          </Text>

                          {!!lastReport.effectiveDateTime && (
                            <Text color="gray.500" size="xsLight">
                              {`Issued on: ${getDateStrFromFormatWithTimezone(
                                lastReport.effectiveDateTime || '',
                                getCurrentTimeZone(),
                                DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT
                              )}`}
                            </Text>
                          )}
                        </Stack>
                      </Stack>
                    );
                  }
                )}
              </View>
            )}
        </Stack>
      );
    } else if (isSidecar || isGraph) {
      return (
        <>
          <View>
            <LabReportsGraph
              ccmDate={props.ccmDate || ''}
              labReportsGroups={componentState.labReportsGroups}
              goals={props?.goals}
            />
          </View>
        </>
      );
    }
  };

  const getCollapsedView = () => {
    return (
      <View style={styles.collapsedViewContainer}>
        <Stack
          direction="row"
          style={styles.collapsedViewHeader}
        >
          <View style={styles.collapsedViewHeaderContent}>
            <PamiIconSvg />
            <Text
              style={styles.collapsedViewHeaderText}
            >
              {intl.formatMessage({id: 'labResults'})}
            </Text>
          </View>
        </Stack>
        <Divider style={{margin: 0}} />
        <View style={styles.collapsedViewContent}>
          {componentState.loading && (
            <View style={styles.loaderContainer}>
              <FormComponentLoader />
            </View>
          )}
          {!componentState?.loading &&
            componentState?.labReportsGroups?.length === 0 && (
              <Empty />
            )
          }

          {!componentState.loading &&
            getDetailView(
              false,
              true
            )
          }
        </View>
      </View>
    );
  };

  return (
    isSidecar ? (
      getCollapsedView()
    ) : (<>
      <Collapse
        activeKey={componentState.activePanels}
        onChange={(activePanels) => {
          setComponentState((prev) => ({
            ...prev,
            activePanels: activePanels as string[],
          }));
        }}
        expandIconPosition="end"
        expandIcon={({isActive}) => {
          const tooltipText = isActive
            ? intl.formatMessage({id: 'collapse'})
            : intl.formatMessage({id: 'expand'});
          return (
            <Tooltip
              title={tooltipText}
              placement="bottom"
              showArrow={false}
              overlayStyle={{paddingTop: '12px'}}
              overlayInnerStyle={tooltipInnerOverlayStyle}
            >
              <RightOutlined rotate={isActive ? 90 : 0} />
            </Tooltip>
          );
        }}
        style={reactStyles.collapseStylePtV16}
      >
        <Panel
          forceRender
          key={LAB_REPORT_PANEL_KEY}
          header={
            <HStack paddingRight={4} justifyContent="space-between">
              <HStack alignItems={'center'} space={2} marginTop={0}>
                <PamiIconSvg />
                <Text
                  color={Colors.FoldPixel.GRAY400}
                  size={'lgMedium'}
                >
                  {intl.formatMessage({id: 'labTrends'})}
                </Text>
              </HStack>
              <ClinicalSectionViewToggle
                tabs={CINICAL_SECTIONS_VIEW_TABS}
                selectedTabCode={componentState.selectedViewType}
                isHeaderView={props?.isHeaderView}
                onTabClick={(tab: string) => {
                  setComponentState((prev) => ({
                    ...prev,
                    selectedViewType: tab,
                  }));
                }}
              />
            </HStack>
          }
        >
          <Stack
            direction={'row'}
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              marginTop: 8,
              marginRight: 8,
            }}
          >
            {isHeaderView ? null : (
              <ClinicalSectionViewToggle
                tabs={CINICAL_SECTIONS_VIEW_TABS}
                selectedTabCode={componentState.selectedViewType}
                onTabClick={(tab: string) => {
                  setComponentState((prev) => ({
                    ...prev,
                    selectedViewType: tab,
                  }));
                }}
              />
            )}
          </Stack>
          <Stack direction="column" style={{paddingHorizontal: 16, paddingBottom: 16}}>
            {componentState.loading && (
              <View style={styles.marginTop16}>
                <FormComponentLoader />
              </View>
            )}

            {!componentState?.loading &&
              componentState?.labReportsGroups?.length === 0 && <Empty />}

            {!componentState.loading &&
              getDetailView(
                true, false
              )}
          </Stack>
        </Panel>
      </Collapse>
      <Drawer
        mask={isSidecar ? false : true}
        width={isSidecar ? '100%' : '35%'}
        destroyOnClose
        closable
        open={!isSidecar && componentState?.selectedViewType === CLINICAL_SECTION_VIEW_TYPES.GRAPH}
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            selectedViewType: CLINICAL_SECTION_VIEW_TYPES.LIST,
          }));
        }}
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={intl.formatMessage({id: 'labTrends'})}
            buttonList={[
              {
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    selectedViewType: CLINICAL_SECTION_VIEW_TYPES.LIST,
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: isSidecar ? 'back' : 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
        }
      >
        {componentState.loading && (
          <View style={styles.marginTop16}>
            <FormComponentLoader />
          </View>
        )}

        {!componentState?.loading &&
          componentState?.labReportsGroups?.length === 0 && <Empty />}

        {!componentState.loading &&
          getDetailView(
            false,
            !isSidecar &&
              componentState?.selectedViewType ===
                CLINICAL_SECTION_VIEW_TYPES.GRAPH
          )}
      </Drawer>
    </>)
  );
};

export default LabReports;
