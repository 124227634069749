import { Select as AntSelect } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IFormComponentProps } from '../CustomWrapper/CustomWrapper'
import { ISelectState } from './interfaces';
import { IFormValidationOutput } from '../CustomWrapper/interfaces';
import { HStack, Text } from 'native-base';
import './SelectStyles.css';
import { CaretDownOutlined } from '@ant-design/icons';
import { Colors } from '../../../../../../styles/Colors';

const Select = (props: IFormComponentProps) => {
  const { component, disabled, value } = props;

  const [componentState, setComponentState] = useState<ISelectState>({
    value: value || undefined,
    showErrors: false,
  });

  const setValue = useCallback((value: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        value: value,
      };
    });
  }, []);

  const suffixIcon = useMemo(() => (<CaretDownOutlined
    style={{
      color: Colors.FoldPixel.GRAY300,
    }}
  />), [])

  const validateData = (currentData: string): IFormValidationOutput => {
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (props.component?.validate?.required) {
      return { isValid: !!currentData, message: !currentData ? `${component.label} is required` : '' };
    }
    return {isValid: true, message: ''};
  };

  props.setValueRef.current = setValue;
  props.validateRef.current = validateData;

  useEffect(() => {
    props.onChange(componentState.value);
  }, [componentState.value]);

  return (
    <AntSelect
      size="large"
      showSearch={component.showSearch}
      allowClear={component.allowClear}
      disabled={disabled}
      filterOption={(input: string, option: any) => {
        return (option?.children || '').toLowerCase().includes(input.toLowerCase());
      }}
      value={componentState.value}
      onChange={(value: any[], data: any) => {
        setComponentState((prev) => ({...prev, value: data?.value}))
      }}
      placeholder={component.placeholder}
      className={componentState.showErrors && !component.value ? 'field-error form-custom-select custom-select-box' : 'form-custom-select custom-select-box'}
      suffixIcon={suffixIcon}
    >
      {(component.data?.values || []).map((item: any, index: number) => {
        return (
          <AntSelect.Option
            key={`${item.value}_${index}`}
            value={item.value}
          >
            {
            item.customIcon ? (
              <HStack alignItems={'center'} space={2}>
                {item.customIcon}
                <Text>{item.label}</Text>
              </HStack>
            ) : item.label
            }
          </AntSelect.Option>
        );
      })}
    </AntSelect>
  )
}

export default Select
