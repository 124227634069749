import {IKeyOperation} from '../../../../../../../Interfaces';
import {
  IAddOrUpdateImmunizationParams,
  IImmunization,
  IImmunizationComponentValue,
} from '../interfaces';
import {ImmunizationFields} from '../../CustomComponentUtils';
import {FHIR_RESOURCE} from '../../../../../../../constants/FhirConstant';
import {
  IFormatReconciliationMetaData,
  IReconciliationFormattedData,
  IReconciliationConflictField,
  ReconciliationSource,
} from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import {Immunization} from 'fhir/r4';
import {FHIRConflictFields} from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';
import {
  getTextFromCoding,
  getValidCoding,
} from '../../../../../Integration/FhirUtils';
import {PAMISearchType} from '../../../../../../common/PAMISearch/PAMISearch';
import {getEHRCapability} from '../../../../../../../utils/commonUtils';
import {CapabilityResource} from '../../CustomWrapper/CustomComponentHelper';
import {InputType, getFieldCapabilities} from '../../../../../../../utils/capabilityUtils';
import {getDateStrFromFormat, getFormattedDate, getMomentObj} from '../../../../../../../utils/DateUtils';
import { DISPLAY_DATE_FORMAT } from '../../../../../../../constants/StringConst';

export const getRecordListFormatted = (list: any[]) => {
  const entries = [];
  for (const el of list || []) {
    entries.push(el.resource);
  }
  const formattedData = getRecordDataFormatted(entries);
  return sortItems(formattedData);
};

export const getRecordDataFormatted = (listData: any[]): IImmunization[] => {
  const tempFormattedList: IImmunization[] = [];
  listData.map((item: any) => {
    if (item?.vaccineCode) {
      const status = item.status ? item.status : 'Not Completed';
      const tempObj: IImmunization = {
        id: item?.id,
        occurrenceDateTime:
          item?.occurrence?.dateTime || item.occurrenceDateTime,
        immunization: item?.vaccineCode,
        name: getImmunizationName(item?.vaccineCode),
        statusCode: status,
        practitioner: getImmunizationPractitioner(item),
        primarySource: item.primarySource || false,
        recordedDate: item?.recordedDate,
        doseQuantity: item?.doseQuantity?.value,
        doseQuantityUnit: item?.doseQuantity?.unit,
        uniqueId: item?.id,
        isFreeTextRecord: !item?.vaccineCode?.coding?.length,
        meta: item?.meta,
        status: status,
        route: item?.route?.coding?.[0]?.code,
        site: item?.site?.coding?.[0]?.code
      };
      tempFormattedList.push(tempObj);
    }
  });
  return tempFormattedList;
};

export const getSampleData = (): IImmunizationComponentValue => {
  return {
    immunizations: [
      {
        id: '34234324',
        name: 'Sample Immunization',
        occurrenceDateTime: '2020-12-14',
        immunization: {
          text: 'Sample Immunization',
        },
        statusCode: 'active',
        doseQuantity: '10',
        doseQuantityUnit: 'ml',
        primarySource: true,
        practitioner: {
            resourceType: "Practitioner",
            id: "Test Doctor",
            name:
            [
                {
                    given:
                    [
                        "Test Doctor"
                    ],
                    family: "Doctor"
                }
            ],
        },
        uniqueId: '1234',
        isFreeTextRecord: false,
        status: 'completed',
      }
    ]
  }
};

export const sortItems = (records: IImmunization[]) => {
  return records.sort((a, b) => {
    const statusA = a.statusCode || '';
    const statusB = b.statusCode || '';
    if (statusA !== statusB) {
      return statusA.toLowerCase().localeCompare(statusB.toLowerCase());
    }
    return (
      new Date(
        b.recordedDate || b.occurrenceDateTime || new Date()
      ).getTime() -
      new Date(
        a.recordedDate || a.occurrenceDateTime || new Date()
      ).getTime()
    );
  });
};

export const getImmunizationPractitioner = (item: any) => {
  for (const actor of item?.performer ?? []) {
    const actorType = actor?.actor?.reference?.length ? actor.actor.reference.split('/') : undefined
    if (actorType && actorType?.[0] === FHIR_RESOURCE.PRACTITIONER) {
      return {
        id: actorType[1]
      };
    }
  }
  return {
    id: undefined,
  };
};

export const getImmunizationName = (item: any) => {
  let name = item?.text;
  if (!name && item?.coding?.length) {
    name = item.coding[0].display;
  }
  return name;
};

export const isInvalid = (
  field: ImmunizationFields,
  item: IImmunization,
  submitTriggered: boolean,
  fields?: IKeyOperation
) => {
  if (field !== ImmunizationFields.immunization && !item.primarySource && fields?.vaccineCode?.hideFieldsForFreeText) {
    return false;
  }
  switch (field) {
    case ImmunizationFields.occurrenceDateTime:
      if (
        (fields?.occurrenceDateTime?.isRequired || false) &&
        submitTriggered
      ) {
        return !item.occurrenceDateTime;
      }
      return false;
    case ImmunizationFields.status:
      if ((fields?.status?.isRequired || false) && submitTriggered) {
        return !item.status;
      }
      return false;
    case ImmunizationFields.performer:
      if ((fields?.performer?.isRequired || false) && submitTriggered) {
        return !item.practitioner?.id;
      }
      return false;

    case ImmunizationFields.doseQuantity:
      if ((fields?.doseQuantity?.isRequired || false) && submitTriggered) {
        return !item.doseQuantity;
      }
      return false;

    case ImmunizationFields.doseQuantityUnit:
      if ((fields?.doseQuantityUnit?.isRequired || false) && submitTriggered) {
        return !item.doseQuantityUnit;
      }
      return false;

    case ImmunizationFields.route:
      if ((fields?.route?.isRequired || false) && submitTriggered) {
        return !item.route;
      }
      return false;

    case ImmunizationFields.site:
      if ((fields?.site?.isRequired || false) && submitTriggered) {
        return !item.site;
      }
      return false;

    default:
      return false;
  }
};

export const getRoute = (immunization: IImmunization, fieldCapabilities: any) => {
  const routeObj = fieldCapabilities?.route?.possibleValues?.find((route: any) => route.code === immunization.route);
  return routeObj ? {coding: [routeObj]} : undefined;
}

export const getSite = (immunization: IImmunization, fieldCapabilities: any) => {
  const siteObj = fieldCapabilities?.site?.possibleValues?.find((site: any) => site.code === immunization.site);
  return siteObj ? {coding: [siteObj]} : undefined;
}

export const getFHIRTemplate = (params: IAddOrUpdateImmunizationParams) => {
  return {
    resourceType: 'Immunization',
    ...(params.id && {id: params.id}),
    occurrenceDateTime: params.occurrenceDateTime,
    vaccineCode: params.immunization,
    status: params?.status,
    primarySource: params.primarySource || false,
    patient: {
      reference: `Patient/${params.patientId}`,
    },
    ...(params.practitioner?.id && {
      performer: [
        {
          actor: {
            reference: `Practitioner/${params.practitioner.id}`,
          },
        },
      ],
    }),
    ...(params.doseQuantity && {
      doseQuantity: {
        value: parseFloat(params.doseQuantity),
        ...(params.doseQuantityUnit && {unit: params.doseQuantityUnit}),
      },
    }),
    ...(params?.meta && { meta: params?.meta }),
    ...(params?.extension && { meta: params?.extension }),
    ...(params?.route && { route: params?.route }),
    ...(params?.site && { site: params?.site }),
  };
};

export const formatReconciliationImmunizationData = (
  resource: any,
  metaData: IFormatReconciliationMetaData
): IReconciliationFormattedData | undefined => {
  const immunizationResource: Immunization = resource;

  const capabilities = getEHRCapability(metaData.ehrCapabilities, [
    CapabilityResource.immunization,
  ]);

  const fieldCapabilities = getFieldCapabilities(capabilities);
  const date = immunizationResource.occurrenceDateTime || immunizationResource.occurrenceString;
  const formattedDate = date ? getFormattedDate(date, DISPLAY_DATE_FORMAT) : '';
  const status = fieldCapabilities.status?.possibleValues?.find(
    (value: any) => value.code == immunizationResource.status
  )?.display;
  let doseQuantity = immunizationResource.doseQuantity?.value ? `${immunizationResource.doseQuantity?.value}` : '';
  const doseQuantityUnit = immunizationResource.doseQuantity?.unit;
  if (doseQuantity && doseQuantityUnit){
    doseQuantity = `${doseQuantity} ${doseQuantityUnit}`;
  }

  const display = {
    title: getHieImmunizationCodes(resource, fieldCapabilities?.vaccineCode?.allowedCodeSystems)?.text || '',
    status: [formattedDate, status, doseQuantity].filter(Boolean).join(' • '),
    date: immunizationResource.status,
  };

  const validatedData = validateAndFormatImunizationHieResource(
    (metaData?.targetResourceData || resource) as Immunization,
    fieldCapabilities,
    metaData,
  );

  return {
    display,
    resourceData: validatedData.resource,
    conflictFields: validatedData.conflictFields,
  };
};

const getOccurrenceDateTime = (immunizationResource: Immunization) => {
  return immunizationResource.occurrenceDateTime || immunizationResource.occurrenceString;
};

const getValidPerformerId = (immunizationResource: Immunization, practitionerData: any[]) => {
  const practitionerRef = immunizationResource.performer?.[0].actor.reference;
  const practitionerId = practitionerRef?.replace('Practitioner/', '');

  if(practitionerId){
    const practitionerExist = practitionerData?.find((practitioner)=> practitioner.id === practitionerId);
    if(!practitionerExist){
      return;
    }
  }
  return practitionerId;
};

const getHieImmunizationCodes = (
  resource: Immunization,
  allowedCodeSystems?: string[]
) => {
  const codeObj = resource.vaccineCode;
  const coding = getValidCoding(codeObj, allowedCodeSystems);
  const text = getTextFromCoding(coding) || codeObj?.text;
  return {coding: coding, text: text};
};

export const getImmunizationInvalidCodeField = (isConflict?: boolean, value?: any) => {
  return {
    field: ImmunizationFields.vaccineCode,
    inputType: FHIRConflictFields.code,
    invalid: !value,
    label: 'Immunization',
    placeholder: 'Search Immunization',
    isConflict: isConflict,
    value: value,
    isRequired: true,
    extraData: {
      searchType: PAMISearchType.immunization,
    },
  }
}

const getHieStatus = (
  resource: Immunization,
  possibleValues?: { code: string; display: string }[]
) => {
  const statusCode = resource?.status;
  return possibleValues?.find(
    (possibleValue) => possibleValue.code === statusCode?.toLowerCase()
  );
};

const getResourceDate = (
  resource: Immunization,
  dateFormat?: string | undefined
) => {
  const date = resource.occurrenceDateTime || resource.occurrenceString;

  if (date) {
    const onsetDate = dateFormat
      ? getDateStrFromFormat(date, dateFormat)
      : getMomentObj(date || new Date()).toISOString();

    return onsetDate;
  }
};

export const validateAndFormatImunizationHieResource = (
  resource: Immunization,
  fields: any,
  metaData: IFormatReconciliationMetaData
) => {
  const conflictFields: IReconciliationConflictField[] = [];

  Object.keys(fields).forEach(function (key, index) {
    const field = ImmunizationFields[key as keyof typeof ImmunizationFields];
    if (!fields[key as keyof typeof fields]?.isHidden) {
      switch (field) {
        case ImmunizationFields.vaccineCode:
          {
            const immunizationCodes = metaData.source === ReconciliationSource.MSO
              ? resource.vaccineCode
              : getHieImmunizationCodes(
                  resource,
                  fields?.vaccineCode?.allowedCodeSystems
                );

            resource.vaccineCode = immunizationCodes;
            conflictFields.push(
              getImmunizationInvalidCodeField(false, resource?.vaccineCode)
            );
          }
          break;

        case ImmunizationFields.performer:
          {
            const practitionerId = getValidPerformerId(
              resource,
              metaData.practitionerData || []
            );
            const isMissing = !practitionerId && fields?.performer?.isRequired;

            conflictFields.push({
              inputType: FHIRConflictFields.practitioner,
              field: ImmunizationFields.performer,
              invalid: isMissing || false,
              value: practitionerId,
              label: 'Practitioner',
              isRequired: fields?.performer?.isRequired || false,
              extraData: {
                practitionerData: metaData.practitionerData,
              },
            });
          }
          break;

        case ImmunizationFields.occurrenceDateTime:
          {
            const dateFormat = fields?.occurrenceDateTime?.format;
            const occurrenceDateTime = getResourceDate(resource, dateFormat);
            const isMissing =
              !occurrenceDateTime && fields?.occurrenceDateTime?.isRequired;

            const formattedOccurenceDateTime = occurrenceDateTime
              ? getMomentObj(occurrenceDateTime).toISOString()
              : undefined;
            if (!isMissing && formattedOccurenceDateTime) {
              resource.occurrenceDateTime = formattedOccurenceDateTime;
            }
            conflictFields.push({
              inputType: FHIRConflictFields.date,
              field: ImmunizationFields.occurrenceDateTime,
              invalid: isMissing || false,
              value: formattedOccurenceDateTime,
              label: 'Date Administered',
              isRequired: fields?.occurrenceDateTime?.isRequired || false,
              extraData: {
                dateFormat: dateFormat,
              },
            });
          }
          break;

        case ImmunizationFields.status:
          {
            const status = getHieStatus(
              resource,
              fields?.status?.possibleValues
            );
            const isMissing = !status?.code && fields?.status?.isRequired;
            if (!isMissing && status) {
              resource.status = status?.code as
                | 'completed'
                | 'entered-in-error'
                | 'not-done';
            }
            conflictFields.push({
              field: ImmunizationFields.status,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: status?.code,
              label: 'Status',
              isRequired: fields?.status?.isRequired || false,
              extraData: {
                statusList: fields?.status?.possibleValues || [],
              },
            });
          }
          break;
        case ImmunizationFields.doseQuantity:
          {
            const doseQuantity = resource.doseQuantity?.value;
            const isMissing = !doseQuantity && fields?.doseQuantity?.isRequired;

            conflictFields.push({
              inputType: FHIRConflictFields.input,
              field: ImmunizationFields.doseQuantity,
              placeholder: 'Dose Quantity',
              invalid: isMissing || false,
              value: doseQuantity,
              label: 'Dose Quantity',
              isRequired: fields?.doseQuantity?.isRequired || false,
              sequence: 1,
              extraData: {
                doseQuantityUnit: resource.doseQuantity?.unit,
              },
            });
          }
          break;

        case ImmunizationFields.doseQuantityUnit:
          {
            const doseQuantityUnit = resource.doseQuantity?.unit;
            const isMissing =
              !doseQuantityUnit && fields?.doseQuantityUnit?.isRequired;

            conflictFields.push({
              inputType: FHIRConflictFields.input,
              field: ImmunizationFields.doseQuantityUnit,
              placeholder: 'Dose Quantity Unit',
              invalid: isMissing || false,
              value: doseQuantityUnit,
              label: 'Dose Quantity Unit',
              isRequired: fields?.doseQuantityUnit?.isRequired || false,
              sequence: 2,
              extraData: {
                doseQuantityUnit: resource.doseQuantity?.unit,
              },
            });
          }
          break;
          case ImmunizationFields.route:
            {
              const route = getHieStatus(
                resource,
                fields?.route?.possibleValues
              );
              const isMissing = !route?.code && fields?.route?.isRequired;
              if (!isMissing && route) {
                resource.route = {coding: [route]};
              }
              conflictFields.push({
                field: ImmunizationFields.route,
                inputType: FHIRConflictFields.select,
                invalid: isMissing || false,
                value: route?.code,
                label: 'Route',
                isRequired: fields?.route?.isRequired || false,
                extraData: {
                  routeList: fields?.route?.possibleValues || [],
                },
              });
            }
          break;

        case ImmunizationFields.site:
          {
            const site = getHieStatus(
              resource,
              fields?.site?.possibleValues
            );
            const isMissing = !site?.code && fields?.site?.isRequired;
            if (!isMissing && site) {
              resource.site = {coding: [site]};
            }
            conflictFields.push({
              field: ImmunizationFields.site,
              inputType: FHIRConflictFields.select,
              invalid: isMissing || false,
              value: site?.code,
              label: 'Site',
              isRequired: fields?.site?.isRequired || false,
              extraData: {
                siteList: fields?.site?.possibleValues || [],
              },
            });
          }
          break;
      }
    }
  });

  return {
    conflictFields: conflictFields,
    resource: resource,
  };
};

export const updateHieImmunizationMatchedData = (
  resource: Immunization,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  const updatedResource = updateHieImmunizationData(resource, conflictFields, metaData);
  return formatReconciliationImmunizationData(updatedResource, metaData);
};

export const updateHieImmunizationData = (
  resource: Immunization,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  conflictFields.map((conflictField) => {
    if (conflictField.value) {
      switch (conflictField.field) {
        case ImmunizationFields.vaccineCode:
          resource.vaccineCode = conflictField.value;
          break;

        case ImmunizationFields.occurrenceDateTime:
          resource.occurrenceDateTime = conflictField.value;
          break;
        case ImmunizationFields.status:
          resource.status = conflictField.value;
          break;

        case ImmunizationFields.route:
          {
            const capabilities = getEHRCapability(metaData.ehrCapabilities, [
              CapabilityResource.immunization,
            ]);
            const fieldCapabilities = getFieldCapabilities(capabilities);
            const route = fieldCapabilities?.route?.possibleValues?.find(
              (route: any) => route.code === conflictField.value
            );
            resource.route = route ? {coding: [route]} : undefined;
          }
          break;

        case ImmunizationFields.site:
          {
            const capabilities = getEHRCapability(metaData.ehrCapabilities, [
              CapabilityResource.immunization,
            ]);
            const fieldCapabilities = getFieldCapabilities(capabilities);
            const site = fieldCapabilities?.site?.possibleValues?.find(
              (site: any) => site.code === conflictField.value
            );
            resource.site = site ? {coding: [site]} : undefined;
          }
          break;

        case ImmunizationFields.doseQuantity:
          resource.doseQuantity = {
            ...resource.doseQuantity,
            value: conflictField.value,
          };
          break;
        case ImmunizationFields.doseQuantityUnit:
          resource.doseQuantity = {
            ...resource.doseQuantity,
            unit: conflictField.value,
          };
          break;
        case ImmunizationFields.performer:
          resource.performer = [
            {
              actor: {
                reference: `Practitioner/${conflictField?.value.id}`,
              },
            },
          ];
          break;
      }
    }
    return conflictField;
  });
  return resource;
}

export const formatHieImmunizationResourceObj = (
  resource: Immunization,
  metaData: IFormatReconciliationMetaData
) => {
  const occurrenceDateTime =
    resource.occurrenceDateTime || resource.occurrenceString;

  return {
    resourceType: FHIR_RESOURCE.IMMUNIZATION,
    occurrenceDateTime: occurrenceDateTime,
    vaccineCode: resource.vaccineCode,
    primarySource: true,
    patient: {
      reference: `Patient/${metaData.patientId}`,
    },
    ...(resource.performer && {
      performer: resource.performer,
    }),
    ...(resource.doseQuantity && {
      doseQuantity: resource.doseQuantity,
    }),
    ...(resource?.meta && {meta: resource?.meta}),
    ...(resource?.extension && {meta: resource?.extension}),
  };
};
