export default [
  {
    type: 'checkbox',
    key: 'isSearchable',
    label: 'Make this component reusable',
    tooltip:
      'If ticked this component will be saved as template and will be available under custom templates.',
    input: true,
  },
  {
    type: 'oldtextfield',
    key: 'name',
    label: 'Reusable component name',
    tooltip: 'This component will be saved as the name added here',
    input: true,
    allowCalculateOverride: true,
    calculateValue: 'value = data.label;',
    validate: {
      required: true,
    },
    conditional: {
      json: {'===': [{var: 'data.isSearchable'}, true]},
    },
    customVisibility: (element: Record<string, any>) => {
      const isSearchable = element?.isSearchable;
      if (isSearchable) {
        element.name = element.label;
      }
      return isSearchable;
    }
  },
  // {
  //   type: 'select',
  //   key: 'resourceTypeId',
  //   label: 'Reusable component resource',
  //   tooltip: 'This component will be attached to the resource added here',
  //   input: true,
  //   dataSrc: 'custom',
  //   valueProperty: 'value',
  //   data: {
  //     custom() {
  //       const values: any[] = [];
  //       const data = localStorage.getItem(MLOV_CATEGORY.FORM_RESOURCE);
  //       const formResourceList = JSON.parse(data || '[]');
  //       if (formResourceList && formResourceList.length > 0) {
  //         formResourceList.forEach((mlov: any) => {
  //           values.push({
  //             label: mlov.value,
  //             value: mlov.id,
  //           });
  //         });
  //       }
  //       return values;
  //     },
  //   },
  //   conditional: {
  //     json: {'===': [{var: 'data.isSearchable'}, true]},
  //   },
  // },
];
