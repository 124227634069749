import {getUniqueKey} from '../CustomFormEngineUtils';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  FormElements,
  SubmitFunction,
  ValidationFunction,
} from '../FormComponents/FormComponents';
import React, {useMemo, useCallback, useEffect, useContext} from 'react';
import Label from '../BaseComponents/Label';
import {FormRenderer} from '../FormRenderer';
import {ContainerComponent} from '../BaseComponents/BaseComponentInterface';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import {useCustomFormBuilderContext} from '../Context/CustomFormBuilder.context';
import {ContainerSchema} from '../Schema/ComponentsSchema';
import {useDroppable} from '@dnd-kit/core';
import ElementWrapper from '../CustomFormBuilder/ElementWrapper';
import {cloneDeep} from 'lodash';
import {v4 as uuidV4} from 'uuid';
import EmptyDroppableView from '../BaseComponents/EmptyDroppableView';
import {StyleSheet, FlatList, type ListRenderItem} from 'react-native';
import {memo} from 'react';
import {usePropertiesFormRenderer} from '../Hooks/usePropertiesFormRenderer';
import {CustomFormBuilderActionTypes} from '../CustomFormEngineInterfaces';
import Description from '../BaseComponents/Description';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import SearchableComponentFields from '../../FHFormio/EditFormFields/SearchableComponentFields';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import classNames from 'classnames';
import { useFormRendererContext } from '../Context/FormRenderer.context';
import ContainerSvg from '../../../../../assets/Icons/FormBuilder/ContainerSvg';
import FormElementWrapper from '../CustomFormBuilder/FormElementWrapper';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { IFormCommonData } from '../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper';

export const ContainerFieldFormElement: FormElement = {
  type: ContainerSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(ContainerSchema),
    key: getUniqueKey(map, ContainerSchema.key),
  }),
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance) as ContainerComponent;
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    clonedInstance.components = clonedInstance.components.map((component) => {
      return FormElements[component.type as ElementsType].clone(
        uuidV4(),
        component,
        map
      );
    });
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(ContainerSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: ContainerSvg,
    label: ContainerSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const containerElement = elementInstance as ContainerComponent;
  const dropZoneId = `drop-zone-${elementInstance.id}`;
  const dropZoneConfig = useMemo(
    () => ({
      id: dropZoneId,
      data: {
        type: elementInstance.type,
        elementId: elementInstance.id,
        isDroppableContainer: true,
      },
    }),
    [elementInstance.id, elementInstance.type]
  );

  const container = useDroppable(dropZoneConfig);
  const components = containerElement.components || [];

  return (
    <div className="flex w-full flex-col container-field">
      <div className="container-field-header">
        <Label
          label={containerElement.title || ''}
          isRequired={containerElement.validate?.required || false}
          tooltip={containerElement.tooltip}
        />
      </div>
      <div id={dropZoneId} ref={container.setNodeRef} className="container-field-body">
        {components.length === 0 && (
          <div className="flex w-full flex-col padding-4">
            <EmptyDroppableView isActive={container.isOver} />
          </div>
        )}
        {components.length > 0 && (
          <div className="flex w-full flex-col padding-4 min-h-64px">
            <div className="nested-container padding-4 min-h-64px">
              {components.map((element, componentIndex) => (
                <ElementWrapper key={element.id} element={element} isLastElement={componentIndex === components.length - 1} />
              ))}
            </div>
          </div>
        )}
        {containerElement.description && (
          <div className="padding-4">
            <Description description={containerElement.description} />
          </div>
        )}
      </div>
    </div>
  );
}

const FormItemComponent = memo(
  ({
    element,
    submitValue,
    isInvalid,
    defaultValue,
    isReadOnly,
    onRegisterValidation,
  }: {
    element: FormElementInstance;
    submitValue?: SubmitFunction;
    isInvalid?: boolean;
    defaultValue?: string;
    isReadOnly?: boolean;
    onRegisterValidation: (key: string, validate: ValidationFunction) => void;
  }) => {
    const FormComponent =
      FormElements[element.type as keyof typeof FormElements]?.formComponent;
    if (!FormComponent) return null;
    return (
      <FormElementWrapper element={element}>
        <FormComponent
          key={element.id}
          elementInstance={element}
          submitValue={submitValue}
          isInvalid={isInvalid}
          defaultValue={defaultValue}
          isReadOnly={isReadOnly}
          onRegisterValidation={onRegisterValidation}
        />
      </FormElementWrapper>
    );
  }
);

function FormComponent({
  elementInstance,
  submitValue,
  isInvalid,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  isInvalid?: boolean;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const {state} = useFormRendererContext();
  const element = elementInstance as ContainerComponent;
  const components = element.components || [];
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  const renderItem: ListRenderItem<FormElementInstance> = useCallback(
    ({item}) => (
      <FormItemComponent
        element={item}
        submitValue={submitValue}
        isInvalid={isInvalid}
        defaultValue={state.filledDataMap[item.key]}
        isReadOnly={isReadOnly}
        onRegisterValidation={onRegisterValidation}
      />
    ),
    [submitValue, isInvalid, state.filledDataMap]
  );

  const keyExtractor = useCallback((item: FormElementInstance) => `${item.id}-${isReadOnly ? 'readonly' : 'editable'}`, [isReadOnly]);
  const isAllowToShare =
    elementInstance?.allowToShare != undefined &&
    elementInstance?.allowToShare === false
      ? false
      : true;

  const listProps = useMemo(
    () => ({
      contentContainerStyle: styles.contentContainer,
      removeClippedSubviews: true,
      maxToRenderPerBatch: 20,
      windowSize: 100,
      initialNumToRender: 20,
      testID: 'container-field-list',
    }),
    []
  );

  const readOnlyListProps = useMemo(
    () => ({
      contentContainerStyle: styles.contentContainer,
      initialNumToRender: 100,
      testID: 'container-field-list',
    }),
    []
  );

  const isSafariOrWebView = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return (
      /^((?!chrome|android).)*safari/i.test(userAgent) ||
      userAgent.includes('webview') ||
      // iOS WebView
      /iphone|ipad|ipod/.test(userAgent) ||
      // Android WebView
      /wv/.test(userAgent)
    );
  }, []);

  return (
    <div
      className={classNames(
        'flex w-full flex-col container-field',
        isReadOnly || isPrintForm ? (isAllowToShare ? '' : 'disallow-to-share') : ''
      )}
    >
      <div className="container-field-header">
        <Label
          label={elementInstance.title || ''}
          isRequired={elementInstance.validate?.required || false}
          tooltip={elementInstance.tooltip}
          isReadOnly={isReadOnly}
        />
      </div>
      <div className="flex w-full flex-col padding-4 min-h-64px">
        {components.length > 0 && (
          <FlatList
            data={components}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            {...(isReadOnly || isPrintForm || isSafariOrWebView ? readOnlyListProps : listProps)}
          />
        )}
        {element.description && (
          <div className="padding-4">
            <Description description={element.description} />
          </div>
        )}
      </div>
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const {state, dispatch, userSettings} = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance as ContainerComponent;
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      input: true,
      placeholder: 'Panel Title',
      label: 'Title',
      key: 'title',
      tooltip: 'The title text that appears in the header of this panel.',
    },
    ...SearchableComponentFields,
    ...(isAllowShare ? ShareWithPatientFields : []),
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowShare]);

  const {formData, formattedFormData, components, handleFormDataChange} =
    usePropertiesFormRenderer({
      initialValues: element as Record<string, any>,
      components: formElements,
    });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: {updatedElement: formData, builderComponents: state.elements},
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        builderComponents={state.elements}
        components={components}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  header: {
    marginBottom: 8,
  },
  contentContainer: {
    padding: 16,
  },
  componentWrapper: {
    marginBottom: 16,
  },
});

FormComponent.displayName = 'FormComponent';
FormItemComponent.displayName = 'FormItemComponent';
