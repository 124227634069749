import React from 'react';
import { ILocation, IUser } from '../../../../Interfaces';
import { CalendarSlot } from '../CalendarWidgetInterfaces';
import {IParticipantSearch} from '../ParticipantAutoComplete/ParticipantInterfaces';
import {AppointmentType} from './AppointmentTypeSelection/AppointmentTypeEnum';
import AppointmentBooking from './Booking/AppointmentBooking/AppointmentBooking';
import GroupAppointmentBooking from './Booking/AppointmentBooking/GroupAppointmentBooking';
import BlockTimeBooking from './Booking/BlockTimeBooking/BlockTimeBooking';
import ServiceBooking from './Booking/ServiceBooking/ServiceBooking';

const BookingWorkflow = (props: {
  isOpen: boolean;
  appointmentType: AppointmentType;
  onClose: (data?: any) => void;
  onCancel: () => void;
  slotSelected?: CalendarSlot;
  event?: any;
  defaultParticipants?: IParticipantSearch[];
  user?: {
    uuid: string;
    name: string;
  };
  onStatusChange?: () => void;
  bookAppointmentMeta?: {
    selectedUser: IUser,
    selectedLocation: ILocation,
  };
  disAllowToRedirectAvailableSlot?: boolean,
  disablePatientProspectSearch?: boolean;
  onAppointmentBooked?: (appointmentId: any) => void;
  defaultProfileId?: string;
}) => {
  return (
    <>
      {props.appointmentType === AppointmentType.groupAppointment &&
        props.slotSelected && (
          <GroupAppointmentBooking
            isVisible={props.isOpen}
            slotSelected={props.slotSelected}
            onComplete={(data?: any) => {
              props.onClose(data);
            }}
            onCancel={() => {
              props.onCancel();
            }}
          />
        )}
      {(props.appointmentType === AppointmentType.bookAppointment ||
        props.appointmentType === AppointmentType.groupSessionAppointment) && (
          <AppointmentBooking
            bookAppointmentMeta={props.bookAppointmentMeta}
            defaultParticipants={props.defaultParticipants}
            isVisible={props.isOpen}
            slotSelected={props.slotSelected}
            onComplete={(data?: any) => {
              props.onClose(data);
            }}
            onCancel={() => {
              props.onCancel();
            }}
            isGroupAppointmentEnable= {
              props.appointmentType === AppointmentType.groupSessionAppointment
                ? true
                : false
            }
            disAllowToRedirectAvailableSlot={props?.disAllowToRedirectAvailableSlot || false}
            disablePatientProspectSearch={props?.disablePatientProspectSearch}
            onAppointmentBooked={props?.onAppointmentBooked}
            defaultProfileId={props?.defaultProfileId}
          />
        )}
      {props.appointmentType === AppointmentType.blockTime &&
        props.slotSelected && (
          <BlockTimeBooking
            userUUID={props?.bookAppointmentMeta?.selectedUser.uuid}
            slotSelected={props.slotSelected}
            isVisible={props.isOpen}
            onComplete={(data?: any) => {
              props.onClose(data);
            }}
            onCancel={() => {
              props.onCancel();
            }}
          />
        )}
      {props.appointmentType === AppointmentType.service && (
        <ServiceBooking
          isVisible={props.isOpen}
          onCancel={() => {
            props.onCancel();
          }}
          onComplete={(data?: any) => {
            props.onClose(data);
          }}
        />
      )}
    </>
  );
};

export default BookingWorkflow;
