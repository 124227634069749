import {View, Image} from 'native-base';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {Colors} from '../../../styles';
import { isWeb } from '../../../utils/platformCheckUtils';
import React from 'react';
import { testID } from '../../../testUtils';

export interface IAddActionView {
  extraStyles?: {[index: string]: string | number | undefined};
  iconSize?: number;
  disabled?: boolean;
}

export const AddActionView = (props: IAddActionView) => {
  return (
    <>{isWeb() ? (<View
      style={[
        {
          width: 36,
          height: 36,
          borderRadius: 18,
          justifyContent: 'center',
          alignItems: 'center',   
        },
        props.extraStyles || {},
      ]}
    >
      <AntIcon
        name="plus"
        style={{color: props.disabled ? Colors.FoldPixel.GRAY200 : Colors.Custom.foldIconColor}}
        size={props.iconSize || 20}
      />
    </View>) : (
      <Image
      size={'36px'}
      source={require('../../../assets/images/PNGImages/TabsPngIcons/add-false.png')}
      alt='PlusIcon'
      color={props.disabled ? Colors.FoldPixel.GRAY200 : Colors.Custom.mainPrimaryPurple}
    />
    )}</>
  );
};
