import * as React from 'react';
import {
  EHRWiseLocations,
  IAgent,
  IAssociatedAccounts,
  IConfiguration,
  IEhrCapability,
  ILoginUserData,
  IMlovByCategory,
  IUserMainDataObj,
  IUserPermission,
  IUserRole,
  IUserRoleCategory,
  IUserRoleCode,
  UserActionMasterData,
} from '../Interfaces';
import {IUserSettingsByCode} from '../services/UserSettings/interfaces';
import {IOnlineUserData, IUserPracticeLocations} from './interfaces';
import { CommonDataAction } from '../components/CommonDataProvider/interface';
import { ISidecarContext, ITaskAlertsType } from '../components/SideCar/interfaces';
import {IUserAccountLocationList} from '../components/common/ProfileView/interface';
import {IPermitCaslAbility} from '../screens/MainScreen/interface';
import {ISipNumberList, IUserList} from '../components/RightSideContainer/TeamInbox/CallLogs/interface';
import { IContactProfile } from '../components/RightSideContainer/TeamInbox/Conversations/interfaces';
export interface ICommonData {
  userPermissions: IUserPermission[];
  userSettings: IUserSettingsByCode;
  currentUserRoles: IUserRoleCode[];
  MLOV: IMlovByCategory;
  CARE_STUDIO_MLOV: IMlovByCategory;
  CLOUD_TELEPHONY_MLOV: IMlovByCategory;
  themeConfig?: unknown;
  accountSubdomain?: unknown;
  accountThemeConfig?: unknown;
  userData?: ILoginUserData;
  userRoles?: IUserRole[];
  userRoleCategories?: IUserRoleCategory[];
  userMainDataObj?: IUserMainDataObj;
  ehrCapabilities?: any;
  onlinePresenceUsersData?: IOnlineUserData;
  userActionMasterData?: UserActionMasterData[],
  accountConfigData?: {[index: string]: IConfiguration};
  defaultContactTypes?: any;
  updateCommonData?: () => void;
  mobileAppDimension: {
    height: number;
    width: number;
  };
  agents?: IAgent[];
  dispatch?: (data: {type: CommonDataAction; payload: any}) => void;
  sidecarContext?: ISidecarContext;
  expiry?: string;
  associatedAccounts?: IAssociatedAccounts[]
  userAccounts?: IUserAccountLocationList[]
  userPreferenceSetting?: any
  accountEhrList?: string[]
  accountLocationListWithEHR?: EHRWiseLocations[]
  ability?:  IPermitCaslAbility;
  ehrCapabilityLocationMap?: Map<string, IEhrCapability[]>;
  loggedInUserLocationDetails?: {
    accountLocationIds: string[];
    locationGroupIds: string[];
    locationGroupList: {locationGroupId: string; accountLocationUuid: string}[];
    allLocationGroupList?: {locationGroupId: string; accountLocationUuid: string}[];
  };
  accountConfigCapabilities?: IEhrCapability[];
  userPracticeLocations?: IUserPracticeLocations[];
  userAccountLocationUuids?: string[];
  accountUserList?: IUserList[],
  sipNumberList?: ISipNumberList[],
  popGroupCapabilities?: IEhrCapability[],
  taskAlertTypes?: ITaskAlertsType[],
  activePatientProfile?: IContactProfile
}
const updateCommonData = () => {
  // update data
};
const defaultCommonData: ICommonData = {
  MLOV: {},
  userPermissions: [],
  userSettings: {},
  currentUserRoles: [],
  CARE_STUDIO_MLOV: {},
  CLOUD_TELEPHONY_MLOV: {},
  themeConfig: {},
  accountThemeConfig: {},
  userData: {} as ILoginUserData,
  userRoles: [],
  userRoleCategories: [],
  userMainDataObj: {} as IUserMainDataObj,
  onlinePresenceUsersData: {} as any,
  updateCommonData: updateCommonData,
  userActionMasterData: [] as UserActionMasterData[],
  accountConfigData: {} as {[index: string]: IConfiguration},
  mobileAppDimension: {
    height: 0,
    width: 0,
  },
  agents: [],
  associatedAccounts: [] as IAssociatedAccounts[],
  userAccounts: [] as IUserAccountLocationList[],
  userPracticeLocations: [] as IUserPracticeLocations[],
  userAccountLocationUuids: [] as string[],
  activePatientProfile: {} as any
};

defaultCommonData?.updateCommonData?.bind(defaultCommonData);
export const CommonDataContext = React.createContext(defaultCommonData);
