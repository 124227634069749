import {Tooltip, notification} from 'antd';
import {Box, Pressable, Skeleton, View} from 'native-base';
import React from 'react';
import {useIntl} from 'react-intl';
import {Text, TextInput} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles';
import PlusIcon from '../Svg/PlusSvg';
import UnityAISvg from '../Svg/UnityAISvg';
import {getPromptSuggestions, getSuggestedResponse} from './GPTPromptAPI';
import {
  DEFAULT_SUGGESTION_COUNT,
  DEFAULT_TITLE,
  VIEW_CODES,
} from './GPTPromptConst';
import {styles} from './GPTPromptStyles';
import {
  IGptPromptProps,
  IGptPromptRef,
  ISuggestionResult,
  IViewCodes,
} from './interfaces';
import {Viewer} from '@toast-ui/react-editor';
import {NewFeaturePopUp} from '../NewFeaturePopUp';
import { testID } from '../../../testUtils';

const GPTPrompt = React.forwardRef<IGptPromptRef, IGptPromptProps>(
  (props, ref) => {
    const [state, setState] = React.useState({
      result: [] as ISuggestionResult[],
      viewCode: VIEW_CODES.INITIAL as IViewCodes,
      isGenerating: false,
      prompt: '',
      selectedIdx: 0,
      isConversationContext: !!(props.conversationUuid && props.messageUuid),
      isShowLoadMore: false,
    });
    const intl = useIntl();
    const inputRef = React.useRef<HTMLTextAreaElement>(null);
    const viewerRef = React.useRef<Viewer>(null);
    const composeSuggestionAbortControllerRef = React.useRef<AbortController>(
      new AbortController()
    );
    const replySuggestionAbortControllerRef = React.useRef<AbortController>(
      new AbortController()
    );

    const getSuggestionCount = () => {
      return props.numOfSuggestions || DEFAULT_SUGGESTION_COUNT;
    };

    const getResultByIdx = (idx: number) => {
      const selected = state.result[idx];
      return selected;
    };

    const setResultTextByIdx = (idx: number, isHideText: boolean) => {
      const result = getResultByIdx(idx);
      const inst = viewerRef.current?.getInstance();
      if (isHideText) {
        const lines = selectLinesFromText(result.text);
        inst?.setMarkdown(lines);
      } else {
        inst?.setMarkdown(result.text || '');
      }
    };

    const handleDiscard = () => {
      setState((prev) => {
        return {
          ...prev,
          viewCode: VIEW_CODES.INITIAL,
          prompt: '',
          isConversationContext: !!(
            props.conversationUuid && props.messageUuid
          ),
        };
      });
    };

    const getTitle = () => {
      if (state.isConversationContext) {
        return 'replyEmailWithUnityAI';
      }
      return DEFAULT_TITLE;
    };

    const handleSelect = () => {
      const selected = getResultByIdx(state.selectedIdx);
      setState((prev) => {
        return {
          ...prev,
          viewCode: VIEW_CODES.SELECTED,
        };
      });
      props.onSelect(selected?.text);
    };

    const selectLinesFromText = (text = '', num = 10) => {
      const lines = text.split('\n');
      const firstFourLines = lines.slice(0, num).join('\n');
      return firstFourLines;
    };

    const generateResponse = async (prompt: string) => {
      if (!prompt) {
        setState((prev) => {
          return {
            ...prev,
            result: [],
            isGenerating: false,
            selectedIdx: 0,
            viewCode: VIEW_CODES.PROMPT_INPUT,
            isConversationContext: false,
          };
        });
      }
      setState((prev) => {
        return {
          ...prev,
          isGenerating: true,
          result: [],
        };
      });
      try {
        const response = await getPromptSuggestions({
          prompt: prompt,
          numOfSuggestions: getSuggestionCount(),
          config: {
            signal: composeSuggestionAbortControllerRef.current.signal,
          },
        });
        const result = response.result;
        setState((prev) => {
          return {
            ...prev,
            result: result,
            isGenerating: false,
            selectedIdx: 0,
            isConversationContext: false,
            isShowLoadMore: true,
          };
        });
      } catch (error: any) {
        if (error.message && error.message.toLowerCase() === 'canceled') {
          return;
        }
        notification.error({
          message: 'Something went wrong while generating the response',
          placement: 'topRight',
        });
        setState((prev) => {
          return {
            ...prev,
            result: [],
            isGenerating: false,
            selectedIdx: 0,
            viewCode: VIEW_CODES.PROMPT_INPUT,
            isConversationContext: false,
          };
        });
      }
    };

    const generateSuggestedResponse = async () => {
      setState((prev) => {
        return {
          ...prev,
          isGenerating: true,
          result: [],
        };
      });
      try {
        const response = await getSuggestedResponse({
          conversationUuid: props.conversationUuid,
          messageUuid: props.messageUuid,
          config: {
            signal: replySuggestionAbortControllerRef.current.signal,
          },
        });
        const result = response.result || [];
        setState((prev) => {
          return {
            ...prev,
            result: result,
            isGenerating: false,
            selectedIdx: 0,
            isConversationContext: true,
            isShowLoadMore: true,
          };
        });
      } catch (error: any) {
        if (error.message && error.message.toLowerCase() === 'canceled') {
          return;
        }
        notification.error({
          message: 'Something went wrong while generating the response',
          placement: 'topRight',
        });
        setState((prev) => {
          return {
            ...prev,
            result: [],
            isGenerating: false,
            selectedIdx: 0,
            viewCode: VIEW_CODES.PROMPT_INPUT,
          };
        });
      }
    };

    const getElementByViewCode = () => {
      switch (state.viewCode) {
        case 'INITIAL':
          return getInitialView();
        case 'PROMPT_INPUT':
          return getPromptInputView();
        case 'GENERATED':
          return getGeneratedView();
        case 'SELECTED':
          return getSelectedView();
        default:
          return getInitialView();
      }
    };

    const getInitialView = () => {
      return (
        <Box
          bg={{
            linearGradient: {
              colors: ['#D2EBEF', '#F5E4FE'],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        >
          <Pressable
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 4,
              padding: 8,
            }}
            onPress={() => {
              if (state.isConversationContext) {
                setState((prev) => {
                  return {
                    ...prev,
                    viewCode: VIEW_CODES.GENERATED,
                  };
                });
                generateSuggestedResponse();
              } else {
                setState((prev) => {
                  return {
                    ...prev,
                    viewCode: VIEW_CODES.PROMPT_INPUT,
                  };
                });
              }
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View>
                <UnityAISvg />
              </View>
              <View
                style={{
                  marginLeft: 8,
                }}
              >
                <Text style={styles.title}>
                  {intl.formatMessage({id: getTitle()})}
                </Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Feather
                name="plus"
                color={Colors.FoldPixel.PRIMARY300}
                size={16}
                style={{
                  marginRight: 4,
                }}
              />
              <Text
                style={{
                  color: Colors.FoldPixel.PRIMARY300,
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: 16.8,
                }}
                {...testID(state.isConversationContext
                  ? 'Suggest Responses'
                  : 'Generate Email')}
              >
                {state.isConversationContext
                  ? 'Suggest Responses'
                  : 'Generate Email'}
              </Text>
            </View>
          </Pressable>
        </Box>
      );
    };

    const getPromptInputView = () => {
      return (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 4,
            padding: 8,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View>
              <UnityAISvg />
            </View>
            <View
              style={{
                marginLeft: 8,
                flexGrow: 1,
              }}
            >
              <TextInput
                ref={inputRef as any}
                multiline
                placeholder="Type your prompt here"
                placeholderTextColor={Colors.FoldPixel.GRAY200}
                value={state.prompt}
                style={{
                  textAlignVertical: 'top',
                }}
                onChangeText={(text) => {
                  if (inputRef.current) {
                    inputRef.current.style.height = '0px';
                    const scrollHeight = inputRef.current?.scrollHeight;
                    inputRef.current.style.height = scrollHeight + 'px';
                  }
                  setState((prev) => {
                    return {
                      ...prev,
                      prompt: text,
                    };
                  });
                }}
              />
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 8,
            }}
          >
            <Pressable
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onPress={() => {
                if (!state.prompt.trim().length) {
                  return;
                }
                setState((prev) => {
                  return {
                    ...prev,
                    viewCode: VIEW_CODES.GENERATED,
                  };
                });
                generateResponse(state.prompt);
              }}
            >
              <PlusIcon
                height={16}
                width={16}
                defaultColor={
                  state.prompt.trim().length === 0
                    ? Colors.FoldPixel.GRAY200
                    : Colors.FoldPixel.PRIMARY300
                }
              />
              <Text
                style={[
                  styles.title,
                  {
                    color:
                      state.prompt.trim().length === 0
                        ? Colors.FoldPixel.GRAY200
                        : Colors.FoldPixel.PRIMARY300,
                    marginLeft: 8,
                  },
                ]}
              >
                {'Generate'}
              </Text>
            </Pressable>
            <Pressable
              onPress={() => {
                handleDiscard();
              }}
            >
              <Tooltip title="Discard">
                <Feather
                  name="trash"
                  color={Colors.FoldPixel.GRAY300}
                  size={16}
                />
              </Tooltip>
            </Pressable>
          </View>
        </View>
      );
    };

    const getGeneratedView = () => {
      return (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 8,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <View>
                <UnityAISvg />
              </View>
              <View style={[{marginLeft: 8}]}>
                <Text style={[styles.title, {color: Colors.FoldPixel.GRAY200}]}>
                  {state.isGenerating
                    ? 'AI is writing...'
                    : state.isConversationContext
                    ? 'Suggested Response'
                    : 'Generated Response'}
                </Text>
              </View>
            </View>

            {state.result.length === 0 ? (
              <></>
            ) : (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Pressable
                  onPress={() => {
                    const currentIdx = state.selectedIdx;
                    if (currentIdx === 0) {
                      return;
                    }
                    setResultTextByIdx(currentIdx - 1, state.isShowLoadMore);
                    setState((prev) => {
                      return {
                        ...prev,
                        selectedIdx: currentIdx - 1,
                      };
                    });
                  }}
                >
                  <Feather
                    name="chevron-left"
                    color={Colors.FoldPixel.GRAY300}
                    size={16}
                  />
                </Pressable>
                <Text
                  style={[
                    styles.title,
                    {
                      marginHorizontal: 4,
                    },
                  ]}
                >{`${state.selectedIdx + 1}/${state.result.length}`}</Text>
                <Pressable
                  onPress={() => {
                    const currentIdx = state.selectedIdx;
                    if (currentIdx === state.result.length - 1) {
                      return;
                    }
                    setResultTextByIdx(currentIdx + 1, state.isShowLoadMore);
                    setState((prev) => {
                      return {
                        ...prev,
                        selectedIdx: currentIdx + 1,
                      };
                    });
                  }}
                >
                  <Feather
                    name="chevron-right"
                    color={Colors.FoldPixel.GRAY300}
                    size={16}
                  />
                </Pressable>
              </View>
            )}
          </View>
          <View
            style={{
              marginTop: 8,
            }}
          >
            <Text style={[styles.title, {color: Colors.FoldPixel.GRAY300}]}>
              {state.prompt}
            </Text>
            <View style={styles.resultContainer}>
              {state.isGenerating ? (
                <Skeleton.Text lines={4} />
              ) : (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Viewer
                    linkAttributes={{
                      target: '_blank',
                    }}
                    extendedAutolinks={true}
                    initialValue={
                      state.isShowLoadMore
                        ? selectLinesFromText(
                            getResultByIdx(state.selectedIdx)?.text
                          )
                        : getResultByIdx(state.selectedIdx)?.text || ''
                    }
                    ref={viewerRef}
                  />
                  {state.isShowLoadMore ? (
                    <Pressable
                      onPress={() => {
                        setResultTextByIdx(state.selectedIdx, false);
                        setState((prev) => {
                          return {
                            ...prev,
                            isShowLoadMore: false,
                          };
                        });
                      }}
                    >
                      <Text
                        style={[
                          styles.title,
                          {
                            color: Colors.FoldPixel.PRIMARY300,
                          },
                        ]}
                      >
                        Read more
                      </Text>
                    </Pressable>
                  ) : (
                    <Pressable
                      onPress={() => {
                        setResultTextByIdx(state.selectedIdx, true);
                        setState((prev) => {
                          return {
                            ...prev,
                            isShowLoadMore: true,
                          };
                        });
                      }}
                    >
                      <Text
                        style={[
                          styles.title,
                          {
                            color: Colors.FoldPixel.PRIMARY300,
                          },
                        ]}
                      >
                        Read less
                      </Text>
                    </Pressable>
                  )}
                </View>
              )}
            </View>
          </View>

          {/* buttons */}

          {state.result.length === 0 ? (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: 8,
              }}
            >
              <Tooltip title="Discard" placement="top">
                <Pressable
                  onPress={() => {
                    handleDiscard();
                  }}
                >
                  <Feather
                    name="trash"
                    color={Colors.FoldPixel.GRAY300}
                    size={16}
                  />
                </Pressable>
              </Tooltip>
            </View>
          ) : (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 8,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Pressable
                  onPress={() => {
                    handleSelect();
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Feather
                    name="plus"
                    color={Colors.FoldPixel.PRIMARY300}
                    size={16}
                    style={{
                      marginRight: 4,
                    }}
                  />
                  <Text
                    style={[
                      styles.title,
                      {
                        color: Colors.FoldPixel.PRIMARY300,
                      },
                    ]}
                  >
                    {'Select'}
                  </Text>
                </Pressable>
                <Divider
                  height={15}
                  backgroundColor={Colors.FoldPixel.GRAY150}
                  width={0.5}
                />

                <Pressable
                  onPress={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        viewCode: VIEW_CODES.PROMPT_INPUT,
                        result: [],
                      };
                    });
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Feather
                    name="edit-2"
                    color={Colors.FoldPixel.PRIMARY300}
                    size={15}
                    style={{
                      marginRight: 4,
                    }}
                  />
                  <Text
                    style={[
                      styles.title,
                      {
                        color: Colors.FoldPixel.PRIMARY300,
                      },
                    ]}
                  >
                    {state.isConversationContext
                      ? 'Enter Prompt'
                      : 'Edit Prompt'}
                  </Text>
                </Pressable>
              </View>
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Tooltip title="Generate new" placement="top">
                  <Pressable
                    onPress={() => {
                      if (state.isConversationContext) {
                        generateSuggestedResponse();
                      } else {
                        generateResponse(state.prompt);
                      }
                    }}
                  >
                    <Feather
                      name="refresh-ccw"
                      color={Colors.FoldPixel.GRAY300}
                      size={16}
                    />
                  </Pressable>
                </Tooltip>

                <Divider
                  height={15}
                  backgroundColor={Colors.FoldPixel.GRAY150}
                  width={0.5}
                />

                <Tooltip title="Discard" placement="top">
                  <Pressable
                    onPress={() => {
                      handleDiscard();
                    }}
                  >
                    <Feather
                      name="trash"
                      color={Colors.FoldPixel.GRAY300}
                      size={16}
                    />
                  </Pressable>
                </Tooltip>
              </View>
            </View>
          )}
        </View>
      );
    };

    const getSelectedView = () => {
      return (
        <Box
          bg={{
            linearGradient: {
              colors: ['#D2EBEF', '#F5E4FE'],
              start: [0, 0],
              end: [1, 0],
            },
          }}
        >
          <Pressable
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRadius: 4,
              padding: 8,
            }}
            onPress={() => {
              if (state.isConversationContext) {
                setState((prev) => {
                  return {
                    ...prev,
                    viewCode: VIEW_CODES.GENERATED,
                  };
                });
                generateSuggestedResponse();
              } else {
                setState((prev) => {
                  return {
                    ...prev,
                    viewCode: VIEW_CODES.PROMPT_INPUT,
                    result: [],
                    prompt: '',
                    isConversationContext: !!(
                      props.conversationUuid && props.messageUuid
                    ),
                  };
                });
              }
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View>
                <UnityAISvg />
              </View>
              <View
                style={{
                  marginLeft: 8,
                }}
              >
                <Text style={styles.title}>{`Generate new response`}</Text>
              </View>
            </View>
            <Pressable
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              onPress={() => {
                setResultTextByIdx(0, true);
                setState((prev) => {
                  return {
                    ...prev,
                    viewCode: VIEW_CODES.GENERATED,
                    isShowLoadMore: true,
                  };
                });
              }}
            >
              <Feather
                name="eye"
                color={Colors.FoldPixel.PRIMARY300}
                size={16}
                style={{
                  marginRight: 4,
                }}
              />
              <Text
                style={{
                  color: Colors.FoldPixel.PRIMARY300,
                  fontSize: 14,
                  fontWeight: '400',
                  lineHeight: 16.8,
                }}
              >
                {'View Previous Responses'}
              </Text>
            </Pressable>
          </Pressable>
        </Box>
      );
    };

    const Divider = (props: {
      height?: number;
      width?: number;
      marginHorizontal?: number;
      backgroundColor?: string;
    }) => {
      return (
        <View
          style={{
            width: props.width || 1.5,
            height: props.height || 16,
            marginHorizontal: props.marginHorizontal || 8,
            backgroundColor: props.backgroundColor || Colors.Custom.Gray400,
          }}
        />
      );
    };

    React.useImperativeHandle(ref, () => ({
      generateResponse(text) {
        if (!text || text?.trim()?.length === 0) {
          return;
        }
        setState((prev) => {
          return {
            ...prev,
            prompt: text,
            viewCode: VIEW_CODES.GENERATED,
          };
        });
        generateResponse(text);
      },
    }));

    React.useEffect(() => {
      composeSuggestionAbortControllerRef.current = new AbortController();
      replySuggestionAbortControllerRef.current = new AbortController();
      return () => {
        composeSuggestionAbortControllerRef.current.abort();
        replySuggestionAbortControllerRef.current.abort();
      };
    }, []);

    return (
      <NewFeaturePopUp
        Icon={<UnityAISvg height={24} width={24} />}
        code="EMAIL_WITH_UNITY"
        title="Introducing Compose/Reply with AI"
        subtitle="Now you can compose or reply your emails with the help of AI"
      >
        <View style={styles.container}>{getElementByViewCode()}</View>
      </NewFeaturePopUp>
    );
  }
);

export default GPTPrompt;
