import React from 'react';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../../styles';
import {getBookingErrorTextMessage} from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/BookAppointmentHelper';
import {IBookingWidgetData} from '../AppointmentBookingWidget';
import {useIntl} from 'react-intl';
import Stack from '../../../common/LayoutComponents/Stack';
import {Text} from 'react-native';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  stackStyle: {
    backgroundColor: Colors.Custom.infoBannerBgColor,
    borderRadius: 4,
    padding: 4,
    margin: 12,
  },
  text: {
    marginLeft: 2,
    fontWeight: "200",
  }
});

const BookingDataMsg = (props: {bookingData?: IBookingWidgetData}) => {
  const {bookingData} = props;
  const intl = useIntl();
  return (
    <Stack
      direction='row'
      style={styles.stackStyle}
    >
      <Feather name="info" size={20} color={Colors.Custom.infoColor} />
      <Text style={styles.text}>
        {getBookingErrorTextMessage(bookingData?.appointmentType, intl)}
      </Text>
    </Stack>
  );
};

export default React.memo(BookingDataMsg);
