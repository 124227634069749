import {USER_ACCESS_PERMISSION} from '../../../../UserAccess/UserAccessPermission';
import {usePermissions} from '../../../../../CustomHooks/usePermissions';
import {MAIN_MENU_CODES} from '../../../../../SideMenuBar/SideBarConst';
import {getAllowedUserPracticeLocationUuids} from '../../../../../../utils/commonUtils';
import {CONFIG_CODES} from '../../../../../../constants/AccountConfigConst';
import {isAccountConfigEnabled} from '../../../../../../utils/configUtils';
import {GROUP_TYPE_CODES} from '../../../../../../constants/MlovConst';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {IConversationData} from '../../interfaces';
import {isGroupConversation} from '../MessagingUtils';

export const useCheckConversationLocationAccess = (params: {
  selectedConversation: IConversationData;
}) => {
  const {selectedConversation} = params;

  const conversationPracticeLocationUuid =
    selectedConversation?.practiceLocationUuid;
  const isGroupConversations = isGroupConversation(selectedConversation);
  const isActiveConversation = selectedConversation?.isActive;
  const groupTypeCode =
    selectedConversation?.groupConversation?.groupType?.code;

  const contactActiveStatus =
    selectedConversation?.conversationContact?.isActive || false;
  const contactProfileActiveStatus = selectedConversation?.contactProfile?.[0]?.isActive !== undefined
    ? selectedConversation?.contactProfile?.[0]?.isActive
    : true;

  const commonData = useContext(CommonDataContext);

  const isCommunicationLocationHandlingEnabled = isAccountConfigEnabled(
    CONFIG_CODES.ENABLE_COMMUNICATION_LOCATION_HANDLING
  );

  const {check} = usePermissions();

  const conversationActiveStatus =
    isActiveConversation !== undefined ? isActiveConversation : true;
  const permissionWiseLocationData = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER,
    ''
  );
  const allowedUserPracticeLocationUuids = getAllowedUserPracticeLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.INBOX,
    commonData
  );

  const canAccessConversation = () => {
    if (
      !isCommunicationLocationHandlingEnabled ||
      groupTypeCode !== GROUP_TYPE_CODES.PRIVATE
    ) {
      return true;
    }
    let isPatientOfUserLocation = false;
    if (
      permissionWiseLocationData?.hasAccess !== true ||
      !contactActiveStatus ||
      !contactProfileActiveStatus
    ) {
      return false;
    }
    if (conversationPracticeLocationUuid && !!isGroupConversations) {
      isPatientOfUserLocation =
        allowedUserPracticeLocationUuids?.includes(
          conversationPracticeLocationUuid
        ) && conversationActiveStatus;
    } else {
      isPatientOfUserLocation = true;
    }
    return isPatientOfUserLocation;
  };

  return {
    canAccessConversation,
  };
};
