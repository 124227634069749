import { IEhrCapability } from "../../../../Interfaces";
import { IUserSettingsByCode } from "../../../../services/UserSettings/interfaces";
import { Resource } from "../../../PersonOmniView/MiddleContainer/PersonDetailsView/Questionnaire/interfaces";
import { FormElementInstance } from "./FormComponents/FormComponents";

export enum CustomFormBuilderActionTypes {
  UPDATE_ELEMENTS = 'UPDATE_ELEMENTS',
  SELECT_ELEMENT = 'SELECT_ELEMENT',
  ADD_ELEMENT = 'ADD_ELEMENT',
  REMOVE_ELEMENT = 'REMOVE_ELEMENT',
  CLONE_ELEMENT = 'CLONE_ELEMENT',
  UPDATE_ELEMENT = 'UPDATE_ELEMENT',
  DRAG_AND_DROP_DISABLED = 'DRAG_AND_DROP_DISABLED',
}

export interface ICustomFormBuilderReducerAction {
  type: CustomFormBuilderActionTypes;
  payload: any;
}

export interface ICustomFormLibraryElement {
  code: string;
  label: string;
  description: string;
  components: FormElementInstance[];
}

export type LibraryElement = Omit<FormElementInstance, 'id'>;

export interface ICustomFormBuilderState {
  elements: FormElementInstance[];
  selectedElement: FormElementInstance | null;
  uniqueKeysMap: Map<string, boolean>;
  isDragAndDropDisabled: boolean;
}


export interface IUseCustomFormBuilderProps {
  ehrCapabilities: IEhrCapability[];
  selectedCategory?: {id: string, code: string};
  defaultComponents?: FormElementInstance[];
  questionnaireList?: Resource[];
  userSettings?: IUserSettingsByCode;
  onSchemaChange?: (schema: { components: FormElementInstance[] }) => void;
}

export interface ICustomFormBuilderProps extends IUseCustomFormBuilderProps {
  reusableComponents?: {component: LibraryElement}[];
}


export interface IUseFormSidebarBuilderProps {
  ehrCapabilities: IEhrCapability[];
  selectedCategory?: {id: string, code: string};
  reusableComponents?: {component: LibraryElement}[];
}
