import { useLazyQuery, useMutation } from '@apollo/client';
import { notification } from 'antd';
import { useToast } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ASSIGNEE_TYPE, DATE_FORMATS, DISPLAY_DATE_FORMAT, EVENT_NAMES, IS_SAVE_CLICK, SHOW_COMMENT_ERROR } from '../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import {
  CARE_PROGRAM_STEP_TYPE,
  LABEL_TYPE_CODES,
  MLOV_CATEGORY,
  ORDERED_TASK_PRIORITY_CODES_ASC,
  TASK_PRIORITY_CODES,
  TASK_STATUS,
  USER_ROLE_CODES
} from '../../../constants/MlovConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { LeadQueries, TaskQueries } from '../../../services';
import CareStudioService from '../../../services/CommonService/CareStudioService';
import TaskPoolQueries from '../../../services/TaskPool/TaskPoolQueries';
import { GET_USER_FOR_TASKS } from '../../../services/User/UserQueries';
import {
  getDateStrFromFormat,
  getDateToMomentISOString,
  getEndOfDay,
  getFormattedDate,
  getNormalTaskDates,
  isBeforeDate,
} from '../../../utils/DateUtils';
import { EventBus } from '../../../utils/EventBus';
import {
  filterWorkflowUser,
  getAccountUUID,
  getBooleanFeatureFlag,
  getPatientActiveProfileIds,
  getUserActionObjectFromActionCode,
  getUserData,
  getUserName,
  getUserUUID,
  isAllowedAllLocationToUser,
} from '../../../utils/commonUtils';
import { ToastType, showToast } from '../../../utils/commonViewUtils';
import {
  getMlovId,
  getMlovListFromCategory,
} from '../../../utils/mlovUtils';
import { isWeb } from '../../../utils/platformCheckUtils';
import { insertUserAction } from '../../RightSideContainer/Workflow/Workflow/AddOrUpdateWorkflow/WorkflowApi';
import { BottomViewAction } from '../../TaskCard/TaskCardHelper';
import { ParticipantType } from '../CalendarWidget/ParticipantAutoComplete/ParticipantEnum';
import { ILabelTask, ITask } from '../CareDashboard/CareDashboardInterfaces';
import { isAllowMarkAsComplete, isAllowToEditTask } from '../CareDashboard/CareDashboardTable/CareDashboardTableHelper';
import {
  getCompletedTaskStatusId,
  getDefaultTaskStatusId,
} from '../CareDashboard/CareDashboardUtils/CareDashboardUtils';
import { IUserPatientSearchItem } from '../CustomComponents/CustomUserPatientSearch/CustomUserPatientSearch.native';
import ReadOnlyTaskView from '../ReadOnlyTaskView/ReadOnlyTaskView';
import AddOrUpdateTaskView from './AddEditTaskView/AddOrUpdateTaskView';
import { ADD_COMMENT_CONST, ADD_SUBTASK_CONST, getCommentsVariables, getFormattedLabelsData, getSelectedAccountLocations, getSingleSubTasksVariables, getSubTasksVariables, manageAttachmentsListData, SOURCE_MAP } from './AddTaskUtils';
import { IAddOrUpdateTaskState, IAddOrUpdateTasks, ISubTasks, ISubTasksRes, ITaskCommentsList, ITaskPool, IUserPoolLocation } from './interfaces';
import { TASK_EVENTS } from '../CareDashboard/CareDashboardConstants';
import useTaskActionManager from '../CareDashboard/CustomHook/useTaskActionManager';
import { getEhrConfig, getResourceAbilities } from '../../../utils/capabilityUtils';
import { FHIR_RESOURCE } from '../../../constants/FhirConstant';
import { getDocumentRefByReferenceId } from '../../../services/CommonService/OrderService';
import { DocStatus, NoteEntry } from '../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { INotesFormattedDataProps, IPatientNoteCardProps } from '../../PersonOmniView/MiddleContainer/interfaces';
import { getFormattedNoteForCareTimeline } from '../../PersonOmniView/MiddleContainer/PatientNotes/PatientNotesHelper';
import {getFormDataFromLeadData} from '../../RightSideContainer/Contacts/Leads/LeadView/AddOrUpdateLead/AddOrUpdateUtils';
import { usePermissions } from '../../CustomHooks/usePermissions';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { isAllMandatoryFieldsFilled } from './CommonUtilsForWebAndNative';
import {
  CaptureTransaction,
  TRANSACTION_NAMES,
} from '../../../utils/CaptureTransaction';
import { GET_CARE_PROGRAM_BY_CARE_PLAN_ID } from '../../../services/CarePlan/CarePlanQueries';
import { redirectToNewTab } from '../../SideCar/SidecarUtils';
import useContactProfile from '../ContactProfile/useContactProfile';
import { useTaskManageAccess } from '../../TaskModule/CustomHooks/useTaskManageAccess';

const AddOrUpdateTask = (props: IAddOrUpdateTasks) => {
  const {
    navigation,
    task,
    assignee,
    isVisible,
    saveBtnText,
    successMessage,
    extraData,
    member,
    onComplete,
    onCancel,
    onMarkAsComplete,
    fetchAllTypeTask,
  } = props;
  const eventBus = EventBus.getEventBusInstance();
  const editTask: ITask | undefined = task;
  const defaultAssignee: IUserPatientSearchItem | undefined = assignee;
  const defaultMember: IUserPatientSearchItem | undefined = member;
  const isEditTask = !!editTask?.id;
  const toast = useToast();
  const intl = useIntl();
  const currentUserId = getUserUUID();
  const currentUserData = getUserData();
  const accountUuid = getAccountUUID();
  const mlovData = React.useContext(CommonDataContext);
  const {accountLocationListWithEHR} = mlovData;
  const isMultiTenancyEnabled = getBooleanFeatureFlag(mlovData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const isSidecar = !!mlovData.sidecarContext?.isSidecar;
  const {check} = usePermissions();
  const permissionConfig = check(USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code, MAIN_MENU_CODES.TASKS);
  const allowedAccountLocations = accountLocationListWithEHR?.filter((location) => {
    const restrictAccountLocationId = props?.restrictPatientOrLeadSelectionTo?.contactData?.contactPracticeLocations?.[0]?.accountLocation?.uuid;
    const allowedLocation = permissionConfig?.allowedLocationIds?.includes(location?.uuid);
    return props?.restrictPatientOrLeadSelectionTo && restrictAccountLocationId ? restrictAccountLocationId === location?.uuid : allowedLocation;
    // for leads having no location, we are showing all locations of user
  })?.map((location) => {
    return {
      ...location,
      uuid: location?.uuid,
      name: location?.practiceLocation?.name,
    }
  }) || [];
  const allowedAccountLocationIds =  allowedAccountLocations?.map((location) => location?.uuid) || [];

  const {getAllPatientContactProfiles} = useContactProfile({})
  const mlovList = getMlovListFromCategory(
    mlovData.MLOV,
    MLOV_CATEGORY.LABEL_TYPE
  );
  const timeOutRefs = useRef<NodeJS.Timeout[]>([])
  const labelMlov = mlovList?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  })?.[0];
  const isAllowedAllLocation = isAllowedAllLocationToUser(mlovData.currentUserRoles)
  const careStudioInstance = CareStudioService.getCareStudioServiceInstance();
  const captureTransactionInst = CaptureTransaction.getInstance();
  const fileUploadService = careStudioInstance.fileUploadService;
  const mediaUploadService = careStudioInstance.mediaUploadService;
  // is task created from care journey or form
  const currentUserName = getUserName();
  const userName = extraData?.userName?.length ? extraData?.userName == 'You' ? currentUserName : extraData?.userName  : '';
  const titleText  = userName.length ? `${userName} message sent on ${getFormattedDate(extraData?.dateTime || '','MM/DD/YYYY')}` : '';
  const isCareJourneyOrFormTask =
    props?.task?.referenceData?.careJourneyId ||
    props?.task?.referenceData?.formId || props?.task?.referenceData?.entityType == 'FORM';
  const isCarePlanInterventionTask = (
    props?.task?.referenceData?.entityType == 'FORM' ||
    props?.task?.referenceData?.entityType == 'CONTENT' ||
    props?.task?.referenceData?.entityType == 'VITAL'
  );
  const isSidecarContext = !!mlovData?.sidecarContext?.isSidecar;
  const {getLoggedInUserTaskAccessUsers} = useTaskManageAccess();
  // is task created by current loggedin user
  const isTaskCreatedByCurrentUser =
    isAllowedAllLocation ||
    (props?.task?.createdBy && currentUserId === props?.task?.createdBy);
   // is task completed
  const isTaskCompleted = props?.task?.isCompleted;

  // any one can edit task
  const canEditTask = isAllowToEditTask(task);
   // if task not completed any one can mark it as complete
  const canMarkAsComplete = isAllowMarkAsComplete(task);

  const allAssignedCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'all_assigned'
  );
  const dooTodayCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'do_today'
  );
  const doTomorrowCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'do_tomorrow'
  );
  const doLaterCategoryId = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    'TaskDisplayCategory',
    'do_later'
  );
  let taskPriorityMlov = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.TASK_PRIORITY,
    false
  );
  taskPriorityMlov = taskPriorityMlov?.sort((currentMlov, nextMlov) => {
    const currentMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      currentMlov.code
    );
    const nextMlovScore = ORDERED_TASK_PRIORITY_CODES_ASC.indexOf(
      nextMlov.code
    );
    return currentMlovScore - nextMlovScore;
  });
  const defaultTaskPriority = taskPriorityMlov?.find((mlov) => {
    const code = editTask?.priority?.code || TASK_PRIORITY_CODES.MEDIUM;
    if (mlov.code === code) return true;
  });
  const taskStatusMlov =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.TASK_STATUS
    ) || [];
  const defaultTaskStatus = taskStatusMlov?.find((mlov) => {
    const code = editTask?.taskStatus?.code || TASK_STATUS.ACCEPTED;
    if (mlov.code === code) return true;
  });
  const defaultStatusId = getDefaultTaskStatusId(taskStatusMlov);
  const completedStatusId = getCompletedTaskStatusId(taskStatusMlov);
  const userActionObject = getUserActionObjectFromActionCode('ADD_NEW_TASK');
  const level = userActionObject.level || '';
  const onShowToast = (message: string, type: ToastType, additionalData?: any) => {
    notification.destroy();
    const notificationMethod = type === ToastType.info ?
      notification.info :
      (type === ToastType.error ? notification.error : notification.success);
    notificationMethod({
      message,
      duration: additionalData?.duration || 3.0,
      placement: 'top',
      style: additionalData?.style,
      onClick: () => {
        notification.destroy();
        additionalData?.onClick?.();
      }
    });
  }

  const getAssigneeType = (assigneeTypeCode: string | undefined) => {
    switch (assigneeTypeCode) {
      case ASSIGNEE_TYPE.USER:
        return ParticipantType.staff;
      case ASSIGNEE_TYPE.CONTACT:
        return ParticipantType.patient;
      default:
        return ParticipantType.leads;
    }}

  const getDefaultAssignee = (): IAddOrUpdateTaskState['assignee'] => {
    if (!editTask?.id && extraData?.taskPool?.value) {
      return undefined;
    }
    if (defaultAssignee) {
      return defaultAssignee;
    } else if (editTask?.assigneeUser?.id) {
      return {
        value: editTask.assigneeUser.uuid,
        label: editTask.assigneeUser.name,
        key: editTask.assigneeUser.uuid,
        type: editTask.assigneeTypeCode === 'USER'
          ? ParticipantType.staff
          : editTask.assigneeUser.patient?.patientUuid
          ? ParticipantType.patient
          : ParticipantType.leads,
        details: editTask.assigneeUser,
      };
    }
    return {
      value: currentUserId,
      label: currentUserData.name,
      key: currentUserId,
      type: ParticipantType.staff,
      details: currentUserData,
    };
  };

  const getDefaultMember = () => {
    if(!!editTask?.id && editTask?.contact){
      return {
        value: editTask.contact.uuid,
        label: editTask.contact.name,
        key: editTask.contact.uuid,
        type: editTask.contact.patient?.patientUuid
          ? ParticipantType.patient
          : ParticipantType.leads,
        details: editTask.contact,
      }
    } else {
      return defaultMember || undefined;
    }
  }

  const getDefaultAssignedBy = () => {
    if (editTask?.assignedBy?.id) {
      return {
        value: editTask.assignedBy.uuid,
        label: editTask.assignedBy.name,
        key: editTask.assignedBy.uuid,
        type: ParticipantType.staff,
        details: editTask.assignedBy,
      };
    }
    return {
      value: currentUserId,
      label: currentUserData.name,
      key: currentUserId,
      type: ParticipantType.staff,
      details: currentUserData,
    };
  };

  const getDefaultTaskCategory = () => {
    if(extraData?.category?.key){
      return extraData?.category?.key;
    }
    const assignee = getDefaultAssignee();
    return editTask?.taskDisplayCategoryId === allAssignedCategoryId
      ? 'All Assigned'
      : editTask?.taskDisplayCategoryId === dooTodayCategoryId
      ? 'Do Today'
      : editTask?.taskDisplayCategoryId === doTomorrowCategoryId
      ? 'Do Tomorrow'
      : editTask?.taskDisplayCategoryId === doLaterCategoryId
      ? 'Do Later'
      : assignee?.type == ParticipantType.leads ||
        assignee?.type == ParticipantType.patient
      ? undefined
      : 'All Assigned';
  };


  const getInitalData = () => {
    const labelsData = editTask?.labels?.map((item) => {
      return {
        ...item,
        labelId: item?.labelId || item?.uuid
      };
    }) || [] as ILabelTask[];
    const initialState = {
      selectedAccountLocations: getSelectedAccountLocations({
        allowedAccountLocations,
        isMultiTenancyEnabled,
        editTask,
      }),
      title: titleText?.length ? titleText :  editTask?.title || '',
      description: editTask?.description || extraData?.description || '',
      userId: extraData?.userId,
      userName: extraData?.userName,
      dateTime: extraData?.dateTime,
      assignee: getDefaultAssignee(),
      assignedBy: getDefaultAssignedBy(),
      isImportant: false,
      showErrors: false,
      showCommentError: false,
      showEditCommentError: false,
      isSaveClick: false,
      priority: {
        key: defaultTaskPriority?.id || '',
        value: defaultTaskPriority?.code || '',
        label: defaultTaskPriority?.value || '',
      },
      taskStatus: {
        key: defaultTaskStatus?.id || '',
        value: defaultTaskStatus?.code || '',
        label: defaultTaskStatus?. value || '',
      },
      dueDate: getDateStrFromFormat(
        editTask?.endDateTime || new Date(),
        DISPLAY_DATE_FORMAT
      ),
      workflowList: editTask?.referenceData?.workflowList || [],
      taskPool: {
        key: editTask?.userPoolId || '',
        value: editTask?.userPoolId || '',
        label: editTask?.userPool?.name || '',
      },
      customer: getDefaultMember(),
      taskDisplayCategoryId: getDefaultTaskCategory(),
      userPoolSelect: [],
      accountUsers: [],
      poolData: [],
      isMemberSelected: false,
      taskPools: [],
      loading: true,
      fileList: editTask?.attachments,
      taskLabels: labelsData,
      selectedLabels: undefined,
      subTaskChanged: false,
      isFormValid: false,
      isNeedToScrollDown: false,
      linkedCarePlanLoading: false,
    };

    if(extraData?.taskPool){
      initialState.taskPool = {
        key: extraData.taskPool.key || '',
        value: extraData.taskPool.value || '',
        label: extraData.taskPool.label || '',
      }
    }
        return initialState;
  }
  const [formmatedContactData, setFormmatedContactData] = useState<any | undefined>(undefined);
  const [componentState, setComponentState] = useState<IAddOrUpdateTaskState>(getInitalData());
  const resetComponentState = () => {
    setComponentState(getInitalData());
  };

  const ehrConfig = getEhrConfig(
    componentState?.selectedAccountLocations?.[0]?.uuid,
    ''
  );

  const {handleTaskActions} = useTaskActionManager({
    showToast: onShowToast,
    locationUuid: componentState.selectedAccountLocations?.[0]?.uuid,
  });

  // ehrConfig and visitNote configuration use for get visit note data
  const resourceAbilities = getResourceAbilities(
    FHIR_RESOURCE.DOCUMENT_REFERENCE,
    '',
    componentState?.selectedAccountLocations?.[0]?.uuid
  );
  const foldVisitNoteWithEncountersEnabled = resourceAbilities?.foldVisitNoteEnabled || false;


  const [createOrUpdateTask] = useMutation(TaskQueries.ADD_OR_UPDATE_TASK,{
    onCompleted:() => {
      eventBus.broadcastEvent(EVENT_NAMES.REFRESH_TASK, {
        assigneeId: componentState?.assignee?.value,
        memberContextId: componentState?.selectedContact?.uuid,
      });
    }
  });
  const [createSubTaskByParentTaskId] = useMutation(TaskQueries.ADD_SUB_TASK_BY_PARENT_ID,{
    onCompleted:() => {
      eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {
        assigneeId: componentState?.assignee?.value,
        memberContextId: componentState?.selectedContact?.uuid,
      });
    }
  });
  const [updateSubTask] = useMutation(TaskQueries.UPDATE_SUB_TASK,{
    onCompleted:(data) => {
      eventBus.broadcastEvent(TASK_EVENTS.SUB_TASK_UPDATED, { task: data?.updateTask});
      eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {
        assigneeId: componentState?.assignee?.value,
        memberContextId: componentState?.selectedContact?.uuid,
      });
    }
  });
  const [ getSubTaskByIds ] = useLazyQuery(TaskQueries.GET_SUB_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const [ getContactData ] = useLazyQuery(LeadQueries.GetContact, {
    fetchPolicy: 'no-cache',
  });
  const [getContactsByUuid] = useLazyQuery(LeadQueries.getContactDetailsByUuid, {
    fetchPolicy: 'no-cache',
  });

  const [getCareProgramByCarePlanId] = useLazyQuery(GET_CARE_PROGRAM_BY_CARE_PLAN_ID, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const [showLinkedCarePlan, setShowLinkedCarePlan] = useState(false);
  const sendMarkAsCompleteEvent = () => {
    const statusMlov = taskStatusMlov.filter((item) => item.id === completedStatusId)?.[0];
    eventBus.broadcastEvent(TASK_EVENTS.TASK_UPDATED, {
      task: {
        ...task,
        statusId: completedStatusId,
        isCompleted: true,
        status: {
          id: statusMlov?.id || '',
          code: statusMlov?.code || '',
          value: statusMlov?.value || '',
        },
      },
    });
  }

  const onTaskMarkAsComplete = async (task: ITask) => {
    setComponentState((prev) => ({...prev, loading: true}));
    await handleTaskActions(task, BottomViewAction.markAsComplete, {});
    sendMarkAsCompleteEvent();
    setComponentState((prev) => ({...prev, loading: false}));
    props?.fetchAllTypeTask?.();
  }

  const isValid = (subTasks?: ISubTasks[]) => {
    const updatedSubTasks = subTasks || componentState?.subTasks;
    return (
      componentState.title.trim().length > 0 &&
      componentState.priority &&
      componentState.assignedBy &&
      componentState.assignee?.value &&
      componentState?.dueDate &&
      !updatedSubTasks?.some((item) =>
      item?.endDateTime &&
      isBeforeDate(componentState?.dueDate,item?.endDateTime))&&
      !updatedSubTasks?.some((subTask: ISubTasks) => !subTask?.assignee?.key && subTask?.title?.trim()?.length > 0)
    );
  };

  const captureAddOrUpdateTaskTransaction = () => {
    const identifier = task?.id || 'new_task';
    captureTransactionInst.initiateTransaction({
      name: TRANSACTION_NAMES.TASK_ADD_OR_UPDATE,
      identifier: identifier,
    });
    captureTransactionInst.finishTransaction(
      TRANSACTION_NAMES.TASK_ADD_OR_UPDATE,
      identifier
    );
  }

  const createAttachments = async () => {
    const formData = new FormData();
    let files = [
      ...componentState?.fileList || [],
    ];
    files = files.filter(file =>
      (file?.source && !file?.externalAttachmentId) ||
      (!file?.source && !file?.id)
  );

    if (!files?.length) {
      return [];
    }
    const mediaFiles: any[] | undefined = [];
    files.forEach((file: any) => {
      if (file?.source) {
        mediaFiles.push({
          externalAttachmentId: file?.id,
          name: file?.name,
          isPreviewAvailable: true,
          source: file?.source,
          key: '',
          type: file?.type
        });
      } else {
        formData.append('files', file.originFileObj);
      }
    });
    try {
      const promises = [];

      if (formData && formData?.has('files')) {
        const resPromise = fileUploadService.post('', formData, {
          onUploadProgress: (progressEvent) => {
            const percent = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setComponentState((prev) => ({ ...prev, progress: percent }));
            if (percent === 100) {
              const timeoutId = setTimeout(
                () => setComponentState((prev) => ({ ...prev, progress: 0 })),
                3000
              );
              timeOutRefs.current.push(timeoutId);
            }
          },
        });
        promises.push(resPromise);
      }
      if (mediaFiles?.length) {
        const externalResPromise = mediaUploadService.post(
          '',
          { params: mediaFiles },
          {}
        );
        promises.push(externalResPromise);
      }
      const results = await Promise.all(promises);
      const attachmentIds = results?.flatMap(result => result?.data?.ids);

      return attachmentIds || [];
    }
    catch (err) {
      setComponentState((prev) => ({...prev, isLoading: false}));
      showToast(
        toast,
        intl.formatMessage({id: 'apiErrorMsg'}),
        ToastType.error
      );
    }
  }

  const onAddTask = async (data?:IAddOrUpdateTaskState) => {
    setComponentState((prev) => ({
      ...prev,
      isSaveClick: true,
    }))
    let taskData = componentState;
    if(data){
        taskData = data;
    }
    setComponentState((prev) => ({...prev, showErrors: true}));
    if (!!componentState.localCommentText) {
      setComponentState((prev) => ({
        ...prev,
        showCommentError: true,
        isNeedToScrollDown: !componentState.isNeedToScrollDown
      }))
      return;
    }
    if (!!componentState.localEditCommentText){
      setComponentState((prev) => ({
        ...prev,
        showEditCommentError: true,
        isNeedToScrollDown: !componentState.isNeedToScrollDown
      }))
      return;
    }

    if (isValid()) {
      if (componentState.fileList?.length) {
        const invalidFile = componentState.fileList.find(file => !(file as any)?.id && (file?.size / (1000 * 1000) > 5));
        if (invalidFile) {
          notification.error({
            message: intl.formatMessage({
              id: 'patientTaskUploadDocumentSupportAllFiles',
            }),
            duration: 2.0,
            placement: 'top'
          });
          return;
        }
      }
      setComponentState((prev) => ({...prev, loading: true}));
      captureAddOrUpdateTaskTransaction();
      const {startDate, endDate} = getNormalTaskDates(taskData.dueDate);
        let attachmentsData = (componentState?.fileList || [])?.filter(file =>
          (file?.source && file?.externalAttachmentId) ||
          (!file?.source && file?.id)
      );
        attachmentsData = attachmentsData?.map((item: any) => {
          if (item?.id) {
            return {
              isDeleted: item?.isDeleted || false,
              attachmentId: item.attachmentId,
              id: item.id,
            };
          } else {
            return item;
          }
        });
      if (componentState.fileList?.length) {
        const attachmentIds = await createAttachments();
        attachmentIds?.map((attachmentId: string) => {
          attachmentsData.push({
              attachmentId: attachmentId,
          })
        });
      }

      const labels =
        getFormattedLabelsData(
          componentState?.taskLabels || [],
          isEditTask,
          componentState?.selectedLabels,
        ) || ([] as any[]);

      let addTaskVariables: any = {
        title: taskData.title,
        description: taskData.description,
        assigneeId: taskData.assignee?.value || '',
        assignedById: taskData.assignedBy?.value || '',
        startDateTime: startDate,
        endDateTime: endDate,
        statusId: task?.statusId ?? defaultStatusId,
        priorityId: taskData.priority?.key,
        isEscalated:
        taskData.isImportant ||
        taskData.priority?.value !== TASK_PRIORITY_CODES.LOW,
        contactId: taskData.customer?.value || props?.restrictPatientOrLeadSelectionTo?.key || null,
        userPoolId: taskData.taskPool?.value || null,
        id: editTask?.id || undefined,
        attachments: attachmentsData,
        labels: labels,
        referenceData: { ...editTask?.referenceData, workflowList :  taskData?.workflowList || [] },
      };
      if (!editTask?.id || editTask?.assigneeTypeCode === 'CONTACT') {
        const subTasksVariables = getSubTasksVariables({taskData, taskStatusMlov});
        addTaskVariables = {
          ...addTaskVariables,
          subTasks: subTasksVariables,
        }
      }
      let contactActiveProfileIds = undefined;
      try{
        if(addTaskVariables.contactId){
          const profileResponse = await getAllPatientContactProfiles({contactUuid: addTaskVariables.contactId});
          contactActiveProfileIds = getPatientActiveProfileIds(profileResponse?.contactProfiles || []);
        }
      } catch (error){
      }
      if(!editTask?.id){
        const commentsVariables = getCommentsVariables(taskData);
        addTaskVariables = {
          ...addTaskVariables,
          comments: commentsVariables,
        }
      }

      createOrUpdateTask({
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          data: addTaskVariables,
        },
        onCompleted: async (data) => {
          if (!isEditTask) {
            insertUserAction({
              userId: currentUserId,
              actionCode: 'ADD_NEW_TASK',
              status: 'COMPLETED',
              level: level,
            })
              .catch(() => {

              });
            if (isWeb()) {
              const response: any = await onComplete?.(
                data,
                taskData.taskDisplayCategoryId
              );
              if (!response || response?.isTaskVisible) {
                notification.destroy();
                notification.success({
                  message: intl.formatMessage({
                    id: (successMessage || 'taskAddedMsg'),
                  }),
                  duration: 3.0,
                  placement:'top'
                });
              }

            }
          } else {
            onComplete?.(data, taskData.taskDisplayCategoryId);
          }

          const newTask = {
            ...data?.addOrUpdateTask,
            assigneeId: componentState.assignee?.value,
            assigneeUser: {
              uuid: componentState.assignee?.value,
              name: componentState.assignee?.label,
              assigneeType: componentState.assignee?.type,
              id: componentState?.assignee?.details?.id,
              ...componentState.assignee
            },
          };
          eventBus.broadcastEvent(isEditTask ? TASK_EVENTS.TASK_UPDATED : TASK_EVENTS.TASK_ADDED, {task: newTask});
          if (isWeb()) {
            resetComponentState();
          } else {
            navigation.pop();
          }
          setComponentState((prev) => ({...prev, loading: false}));
          if (isEditTask) {
            notification.success({
              message: intl.formatMessage({
                id: isEditTask ? 'taskUpdatedMsg' : (successMessage || 'taskAddedMsg'),
              }),
              duration: 3.0,
              placement: 'top'
            });
          }
          eventBus.broadcastEvent(EVENT_NAMES.NEW_TASK_ADDED, {assigneeId: taskData?.assignee?.value, memberContextId: taskData?.selectedContact?.uuid});
        },
        onError: () => {
          setComponentState((prev) => ({...prev, loading: false}));
          notification.error({
            message: intl.formatMessage({
              id: 'apiErrorMsg',
            }),
            duration: 3.0,
            placement: 'top'
          });
        },
      });
    } else {
      const message = intl.formatMessage({id: 'mandatoryFieldError'});
      const dateCompareMessage = intl.formatMessage({id: 'TaskSubtaskDueDateError'});
      const compareDate = componentState?.subTasks?.some((item) => item?.endDateTime && isBeforeDate(componentState?.dueDate,item?.endDateTime))
      if(isWeb()){
        notification.destroy()
        !isAllMandatoryFieldsFilled(componentState) && notification.error({
          message,
          placement: 'top'
       })
       compareDate && notification.error({
        message: dateCompareMessage,
        placement: 'top'
      })
      }
      else {
        !isAllMandatoryFieldsFilled(componentState) && showToast(toast, message, ToastType.error);
       compareDate && showToast(toast, dateCompareMessage, ToastType.error);
      }
    }
  };

  const showUserSpecificFields = () => {
    return componentState.assignee?.type === ParticipantType.staff;
  };


  //mount onSave in navigation state so that it can be called from header button
  useEffect(() => {
    !isWeb() &&
      navigation?.setParams?.({
        onSubmit: onAddTask,
      });
      return () => {
        timeOutRefs.current.forEach((timeoutId) => {
          clearTimeout(timeoutId);
        });
      }
  }, []);


  const [getTaskPools] = useLazyQuery(TaskPoolQueries.GetTaskPool, {
    context: { service: CARESTUDIO_APOLLO_CONTEXT },
    fetchPolicy: 'no-cache',
  });

  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });

  const [getAccountUsers] = useLazyQuery(GET_USER_FOR_TASKS, {
    fetchPolicy: 'no-cache',
  });

  const [getLinkNoteWithTask] = useLazyQuery(TaskQueries.GET_TASK_LINK_NOTE, {
    fetchPolicy: 'no-cache',
    context: {
      service: CARESTUDIO_APOLLO_CONTEXT,
    },
  });

  const getCarePlanLinkedWithTask = getLinkNoteWithTask;

  const getUserFormattedList = (users: any[]) => {
    const filterUsers = filterWorkflowUser(users || [], currentUserId);
    return (filterUsers || []).map((user: any) => {
      return {
        ...user,
        userName: user.name,
        userId: user.uuid,
        email: user.email || '',
        accountUsers: user?.accountUsers || [],
      };
    });
  };

  const getPatientNoteViewByResourceData = async (resourceId: any, accountUserList: any[], elationFormData: any, formmatedContactData: any) => {
    let locationUuid =  (formmatedContactData || props.personData)?.accountLocationUuid;

    if (isMultiTenancyEnabled && !locationUuid) {
      locationUuid = componentState?.selectedAccountLocations?.[0]?.uuid
    }

    const response = await getDocumentRefByReferenceId(`${resourceId}`, foldVisitNoteWithEncountersEnabled, locationUuid);
    const noteEntry: NoteEntry = { resource: response?.data } as NoteEntry;
    const formatResponseParam: INotesFormattedDataProps = {
      noteResponse: [noteEntry],
      ehrConfig,
      loginUserId: currentUserData?.uuid,
      contextData: mlovData,
      accountUserList:  accountUserList || [],
      elationFormData: elationFormData,
      additionalFlags:{
        foldVisitNoteEnabled: foldVisitNoteWithEncountersEnabled,
        isMultiTenancyEnabled: isMultiTenancyEnabled,
      }
    };
    return getFormattedNoteForCareTimeline(formatResponseParam);
  }

  const fetchInitialTaskDetail = async () => {
    try {
      const taskPoolTypeId = getMlovId(mlovData.CARE_STUDIO_MLOV, 'UserPoolType', 'task_user_pool');
      const taskPoolParams = { userPoolTypeId: taskPoolTypeId, userUuids: [currentUserId] };
      const accountUserParams = { accountUUID: accountUuid, roleCodes: [USER_ROLE_CODES.EMPLOYER] };
      let labelParams: {labelIds?: string[],labelTypeId?: string } = {};
      if (editTask?.labels?.length && editTask?.labels?.length > 0 && !editTask?.labels?.[0]?.title) {
        labelParams = {
          labelIds: editTask?.labels?.map((label) => label?.labelId || '')?.filter((labelId) => !!labelId),
          labelTypeId: labelMlov.id,
        };
      }

      const promiseList = [
        getTaskPools({
          variables: {
            params: {
              ...taskPoolParams
            },
          }
        }),
        getAccountUsers({ variables: accountUserParams }),
        getLoggedInUserTaskAccessUsers()
      ];
      if (labelParams?.labelIds) {
        promiseList?.push(
          getLabelsData({
            variables: labelParams,
          })
        )
      } else {
        // dummy promise to avoid error
        promiseList.push(Promise.resolve({data: {labels: []}}) as any);
      };
      if (isSidecar) {
        promiseList.push(getContactData({ variables: { id: editTask?.contact?.id}}))
      } else if (!editTask?.contact?.name && editTask?.contactId) {
        promiseList.push(getContactsByUuid({variables: {contactUuidList: [editTask?.contactId]}}))
      } else {
        promiseList.push(Promise.resolve({data: {contact: {}}}) as any);
      }
      const apiResponse = await Promise.all(promiseList);
      const taskPools = apiResponse[0]?.data?.getUserPools?.userPools || [];
      const accountUserResp = apiResponse[1]?.data?.users;
      const loggedInUserTaskAccessUsers = (apiResponse[2]?.data || [])?.filter((id: any) => id !== null);
      const accountUsers = getUserFormattedList(accountUserResp || []);
      const labelsDataRes = apiResponse?.[2]?.data?.labels || [];
      const contactData = apiResponse[3]?.data?.contact || apiResponse?.[3]?.data?.contacts?.[0] || {};
      const formmatedContactData = isSidecar || !editTask?.contact?.name ? getFormDataFromLeadData(contactData, mlovData) : undefined;
      const updatedLabels = labelsDataRes?.map((labelData: ILabelTask) => {
        const matchedLabel = editTask?.labels?.find((labelArg: ILabelTask) => labelArg?.labelId === labelData?.uuid);
        if (matchedLabel) {
          labelData.id = matchedLabel?.id;
          labelData.labelId = matchedLabel?.labelId;
        }
        return labelData;
      });


      const isTaskLinkWithNote = editTask?.resourceMap && editTask.resourceMap?.notes || false;
      const isCarePlanContextTask = editTask?.resourceMap && editTask.resourceMap?.carePlan || false;

      let linkPatientNote: IPatientNoteCardProps = {} as IPatientNoteCardProps;
      if (isTaskLinkWithNote) {
        const taskNoteResourceMapParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.NOTES };
        const resourceMapResponse = await getLinkNoteWithTask({ variables: taskNoteResourceMapParam });
        const resourceMappings = resourceMapResponse?.data?.resourceMappings || [];
        const noteId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;
        if (noteId) {
          const notesResponse: IPatientNoteCardProps[]  = await getPatientNoteViewByResourceData(noteId, accountUsers, {}, formmatedContactData);
          linkPatientNote = notesResponse?.length ? notesResponse?.[0] : {} as IPatientNoteCardProps;
        }
      }
      let linkedCarePlanId: string | undefined = undefined;
      if (isCarePlanContextTask) {
        const carePlanMappingParam = { resourceId: editTask?.id, sourceTypeCode: SOURCE_MAP.CARE_PLAN };
        const carePlanResourceMapResponse = await getCarePlanLinkedWithTask({ variables: carePlanMappingParam });
        const resourceMappings = carePlanResourceMapResponse?.data?.resourceMappings || [];
        linkedCarePlanId = resourceMappings?.length ? resourceMappings?.[0]?.sourceId : undefined;;
      }
      setFormmatedContactData(formmatedContactData);
      const taskPoolLocations = taskPools?.find((pool: ITaskPool['selectedPool']
      ) => {
        return pool?.id === componentState?.taskPool?.key
      }
      );
      const taskpoolLocationIdsSet = new Set(taskPoolLocations?.userPoolLocations?.map((location: IUserPoolLocation) => location?.locationId));
      const locationToSelect = allowedAccountLocations?.find((location) => {
        return taskpoolLocationIdsSet.has(location?.uuid)
      })
      setComponentState(prev => {
        return {
          ...prev,
          loading: false,
          accountUsers,
          taskPools: componentState?.selectedAccountLocations?.[0]?.uuid ? taskPools?.filter((taskPool: any) => {
            return taskPool?.userPoolLocations?.find((location: any) => location?.locationId === componentState?.selectedAccountLocations?.[0]?.uuid)
            }) : taskPools,
          labelsLoading: false,
          ...(updatedLabels?.length > 0 && { taskLabels: updatedLabels }),
          taskAccessUsers: loggedInUserTaskAccessUsers,
          linkPatientNote: {...linkPatientNote, contactId: editTask?.contact?.id},
          linkedCarePlanId: linkedCarePlanId,
          ...(!!locationToSelect && !componentState.selectedAccountLocations?.length  && { selectedAccountLocations: [locationToSelect] }),
        }
      });
    } catch (error) {
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        labelsLoading: false,
      }));
    }
  }

  useEffect(() => {
    if (!componentState.accountUsers?.length || !componentState?.taskPools?.length) {
      setComponentState((prev) => ({
        ...prev,
        loading: true,
        ...(editTask?.labels?.length &&
          editTask?.labels?.length > 0 &&
          !editTask?.labels?.[0]?.title && {
            labelsLoading: true,
          }),
      }));
      fetchInitialTaskDetail();
    }
  }, []);

  useEffect(() => {
    const valid = !!isValid();
    setComponentState((prev) => ({
      ...prev,
      isFormValid: valid,
    }));
  }, [componentState.title, componentState.priority, componentState.assignedBy, componentState.assignee, componentState.dueDate, componentState.selectedAccountLocations]);


 const taskConfig = {
   isEditTask,
   canEditTask,
   isCareJourneyOrFormTask,
   isTaskCreatedByCurrentUser,
   canMarkAsComplete,
   isTaskCompleted,
 };

 useEffect(() => {
  const labelsData =
    editTask?.labels?.map((item) => {
      return {
        ...item,
        labelId: item?.labelId || item?.uuid
      };
    }) || ([] as ILabelTask[]);
      setComponentState((prev) => ({
        labelsLoading: prev?.labelsLoading,
        title: titleText?.length ? titleText : editTask?.title || '',
        description: editTask?.description || extraData?.description || '',
        userId: extraData?.userId,
        userName: extraData?.userName,
        dateTime: extraData?.dateTime,
        assignee: getDefaultAssignee(),
        assignedBy: getDefaultAssignedBy(),
        isImportant: false,
        showErrors: false,
        priority: {
          key: defaultTaskPriority?.id || '',
          value: defaultTaskPriority?.code || '',
          label: defaultTaskPriority?.value || '',
        },
        dueDate: getDateStrFromFormat(
          editTask?.endDateTime || new Date(),
          DISPLAY_DATE_FORMAT
        ),
        workflowList: editTask?.referenceData?.workflowList || [],
        taskPool: extraData?.taskPool || {
          key: editTask?.userPoolId || '',
          value: editTask?.userPoolId || '',
          label: editTask?.userPool?.name || '',
        },
        customer: getDefaultMember(),
        taskDisplayCategoryId: getDefaultTaskCategory(),
        accountUsers: [],
        poolData: [],
        isMemberSelected: false,
        selectedContact: editTask?.contact,
        fileList: editTask?.attachments,
        taskLabels: labelsData,
        selectedLabels: undefined,
        selectedAccountLocations: getSelectedAccountLocations({allowedAccountLocations,isMultiTenancyEnabled,editTask}),
      }))
      if (isEditTask && props.task?.id && props.task?.subTasks?.length) {
        getSubTask(props.task);
      }
 }, [props.task?.id, props.assignee?.value])

 const getSubTask = async (task: ITask) => {
   const subTasksId = task.subTasks?.map((item) => item.id.toString());
   setComponentState((prev) => ({...prev, loading: true}));
   const response = await getSubTaskByIds({
     variables: {
       ids: subTasksId,
     },
   });
   if (response?.data?.getTasks?.tasks?.length) {
     const subTaskRes = response.data.getTasks.tasks;
     const updatedItems = subTaskRes.map((item: ISubTasksRes) => ({
       ...item,
       isChecked: item.statusId === completedStatusId ? true : false,
       assignee: {
        value: item?.assigneeUser?.uuid,
        label: item?.assigneeUser?.name,
        key: item?.assigneeUser?.uuid,
        details: item?.assigneeUser,
       },
     }));
     setComponentState((prev) => {
       return {
         ...prev,
         subTasks: updatedItems,
       };
     });
   }
   setComponentState((prev) => ({...prev, loading: false}));
 };

 if (isTaskCompleted || isCareJourneyOrFormTask || isCarePlanInterventionTask || (task?.isReadOnly)) {
  return (
    <ReadOnlyTaskView
      value={componentState}
      taskConfig={taskConfig}
      isVisible={isVisible || false}
      personData={formmatedContactData || props.personData}
      loading={componentState.loading === true || componentState?.loading == undefined}
      task={task || ({} as ITask)}
      onCancel={() => {
        onCancel?.();
        resetComponentState();
      }}
      onStatusChangeComplete={() => {
        fetchAllTypeTask?.();
      }}
      labelsLoading={componentState?.labelsLoading}
      linkPatientNote={componentState.linkPatientNote}
      onNoteRedirect={props?.onNoteRedirect}
      accountUsers={componentState.accountUsers}
    />
  );
}

  const checkIfAllSubTasksAreCompleted = () => {
    if (!componentState.subTasks || componentState.subTasks?.length === 0) {
      return true;
    }
    return componentState.subTasks?.every(
      (subTask) => subTask.statusId === completedStatusId
    );
  }

  const handleMarkAsComplete = async (task:ITask,action:BottomViewAction) => {
    if (componentState?.linkPatientNote?.resourceId) {
      const noteStatus = componentState?.linkPatientNote?.status;
      if (noteStatus === DocStatus.PRELIMINARY) {
        notification.warning({
          message: intl.formatMessage({
            id: 'completeTaskLinkNoteMessage',
          }),
          duration: 3.0,
          placement: 'top'
        });
        return;
      }
    }
    setComponentState((prev) => ({...prev, markAsCompleteLoading: true}));
    if (checkIfAllSubTasksAreCompleted()) {
      if (onMarkAsComplete) {
        onMarkAsComplete?.(task, action);
      } else {
        onTaskMarkAsComplete(task);
      }
    } else {
      notification.error({
        message: intl.formatMessage({id: 'subTaskPendingMsg'}),
        duration: 3,
        placement: 'top'
      })
      setComponentState((prev) => ({...prev, markAsCompleteLoading: false}));
    }
  }

  const setSubTasksDataState = (data: ISubTasks[]) => {
    setComponentState((prev) => {
      const updatedSubTasks =  [...(prev.subTasks || []), ...data]
      const valid = !!isValid(updatedSubTasks);
      return {
        ...prev,
        subTasks: [...(prev.subTasks || []), ...data],
        isFormValid: valid,
      };
    });
  };

  const handleDeleteSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const newArry = componentState.subTasks?.filter(
      (item) => (item?.id || item.tempId) !== itemId
    );

      if (data?.id?.length) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: data?.id,
              data: {
                isDeleted: true,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {

          notification.success({
            message: intl.formatMessage({
              id: 'subtaskDeletedSuccess',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
        eventBus.broadcastEvent(TASK_EVENTS.SUB_TASK_DELETED, {task: data});
      }
    setComponentState((prev) => {
      const valid = !!isValid(newArry);
      return {
        ...prev,
        subTasks: newArry,
        subTaskLoading: false,
        isFormValid: valid,
      };
    });
  };

  const handleMarkCompleteSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {
      const tempSubData = componentState.subTasks || [];
      const checkStatus = !tempSubData?.[itemIndex]?.isChecked;

      if (data?.id?.length && data?.assignee?.key) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        let taskStatusId = '';
        if (checkStatus) {
          taskStatusId = completedStatusId;
        } else {
          taskStatusId = defaultStatusId;
        }
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: data?.id,
              data: {
                statusId: taskStatusId,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          const updatedItem:ISubTasks = {
            ...tempSubData[itemIndex],
            isChecked: checkStatus,
            statusId: checkStatus ? completedStatusId : defaultStatusId
          };
          tempSubData[itemIndex] = updatedItem;
          setComponentState((prev) => {
            const valid = !!isValid(tempSubData);
            return {
              ...prev,
              subTasks: tempSubData,
              isFormValid: valid,
            };
          });
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskStatusUpdated',
            }),
            duration: 3.0,
            placement: 'top'
          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: 'apiErrorMsg',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      }

      setComponentState((prev) => {
        return {
          ...prev,
          subTaskLoading: false,
        };
      });
    }
  };

  const handleUpdateTitleSubtask = async (subTaskData: ISubTasks) => {
    if (!subTaskData?.title?.length) {
      return;
    }
    const itemId = subTaskData?.id || subTaskData.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {

      const tempSubData = componentState.subTasks || [];
      tempSubData[itemIndex].title = subTaskData.title;
      if (subTaskData?.id?.length && isEditTask && (editTask?.assigneeTypeCode !== 'CONTACT') && subTaskData?.assignee?.key) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: subTaskData?.id,
              data: {
                title: subTaskData.title,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskTitleUpdated',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      } else if (!subTaskData?.id && isEditTask && (editTask?.assigneeTypeCode !== 'CONTACT') && subTaskData?.assignee?.key) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        const taskData = componentState;
        const subTasksVariables = getSingleSubTasksVariables(
          taskData,
          subTaskData,
          taskStatusMlov
        );
        const newSubTaskFinalVar = {
          ...subTasksVariables?.[0],
          parentId: editTask.id,
        };

        const newSubTaskRes = await createSubTaskByParentTaskId({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            data: newSubTaskFinalVar,
          },
        });
        const newSubTaskData = newSubTaskRes?.data?.addOrUpdateTask;
        const updateSubTask = {
          ...subTaskData,
          id: newSubTaskData.id,
          isChecked: newSubTaskData.statusId === completedStatusId ? true : false,
        };
        tempSubData[itemIndex] = updateSubTask;
        setComponentState((prev) => {
          const valid = !!isValid(tempSubData);
          return {
            ...prev,
            subTasks: tempSubData,
            isFormValid: valid,
          };
        });
        eventBus.broadcastEvent(subTaskData.id ? TASK_EVENTS.SUB_TASK_UPDATED : TASK_EVENTS.SUB_TASK_ADDED, {task: subTasksVariables});
        if (newSubTaskData?.id) {
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskNewAdded',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      } else {
        tempSubData[itemIndex] = subTaskData;
        setComponentState((prev) => {
          const valid = !!isValid(tempSubData);
          return {
            ...prev,
            subTasks: tempSubData,
            isFormValid: valid,
          };
        });
      }
      setComponentState((prev) => {
        return {
          ...prev,
          subTaskLoading: false,
        };
      });
    }
  };

  const handleUpdateChangeAssigneeSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {
      const tempSubData = componentState.subTasks || [];
      tempSubData[itemIndex] = data;
      setComponentState((prev) => {
        const valid = !!isValid(tempSubData);
        return {
          ...prev,
          subTasks: tempSubData,
          isFormValid: valid,
        };
      });
      if (data?.id?.length && data?.assignee?.key) {
        const timeoutId = setTimeout(() => {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        }, 10);
        timeOutRefs.current.push(timeoutId);
        //call api
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: data?.id,
              data: {
                assigneeId: data?.assignee?.value,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskAssignedUpdated',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      }
      const timeoutId = setTimeout(() => {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: false,
          };
        });
        }, 10);
      timeOutRefs.current.push(timeoutId);
    }
  };

  const handleUpdateDueDateSubtask = async (data: ISubTasks) => {
    const itemId = data?.id || data.tempId;
    const itemIndex =
      componentState.subTasks?.findIndex(
        (item) => (item?.id || item.tempId) === itemId
      ) || 0;
    if (itemIndex !== -1) {
      const tempSubData = componentState.subTasks || [];
      tempSubData[itemIndex] = data;
      setComponentState((prev) => {
        const valid = !!isValid(tempSubData);
        return {
          ...prev,
          subTasks: tempSubData,
          isFormValid: valid,
        };
      });

      if (data?.id?.length) {
        setComponentState((prev) => {
          return {
            ...prev,
            subTaskLoading: true,
          };
        });
        //call api
        const endDateTime = getEndOfDay(data?.endDateTime)
        const endDate = getDateToMomentISOString(endDateTime);
        const updateStatus = await updateSubTask({
          context: {service: CARESTUDIO_APOLLO_CONTEXT},
          variables: {
            params: {
              id: data?.id,
              data: {
                endDateTime: endDate,
              },
            },
          },
        });
        if (updateStatus?.data?.updateTask?.id) {
          notification.success({
            message: intl.formatMessage({
              id: 'subtaskDueDateUpdated',
            }),
            duration: 3.0,
            placement: 'top'
          });
        }
      }

      setComponentState((prev) => {
        return {
          ...prev,
          subTaskLoading: false,
        };
      });
    }
  };

  const handleAddLocalComment = (comment : ITaskCommentsList) => {
    setComponentState((prev) => {
      return {
        ...prev,
        commentsList:  [...[comment], ...(prev.commentsList || [])],
      };
    });
  }

  const handleDeleteLocalComment = (comment : ITaskCommentsList) => {
    setComponentState((prev) => {
      return {
        ...prev,
        commentsList: prev.commentsList?.filter((prevComment)=> prevComment.tempId!==comment.tempId),
      };
    });
  }

  const handleSubTaskChange = (actionCode: string, actionData: any) => {
    setComponentState((prev) => {
      return {
        ...prev,
        subTaskChanged: true
      }
    })
    switch(actionCode) {
      case ADD_SUBTASK_CONST.ADD_NEW:
        setSubTasksDataState(actionData)
        break;
        case ADD_SUBTASK_CONST.DELETE:
          handleDeleteSubtask(actionData)
        break;
        case ADD_SUBTASK_CONST.MARK_COMPLETE:
          handleMarkCompleteSubtask(actionData)
        break;
        case ADD_SUBTASK_CONST.UPDATE_TITLE:
          handleUpdateTitleSubtask(actionData)
        break;
        case ADD_SUBTASK_CONST.DUE_DATE:
          handleUpdateDueDateSubtask(actionData)
        break;
        case ADD_SUBTASK_CONST.CHANGE_ASSIGNEE:
          handleUpdateChangeAssigneeSubtask(actionData)
        break;
      default:
        return;
    }
  };

  const handleCommentChange = (actionCode: string, comment: ITaskCommentsList) => {
    switch(actionCode) {
      case ADD_COMMENT_CONST.ADD_NEW:
        handleAddLocalComment(comment)
        break;
        case ADD_COMMENT_CONST.DELETE:
        handleDeleteLocalComment(comment)
        break;
      default:
        return;
    }
  };

  const onCommentChange = (comment: string,isEditView: boolean) => {
    setComponentState((prev) => ({
      ...prev,
      isNeedToScrollDown: false
    }))
    if(isEditView){
      setComponentState((prev) => ({
        ...prev,
        localEditCommentText: comment
       }))
    } else {
      setComponentState((prev) => ({
        ...prev,
        localCommentText: comment
      }))
    }
  }

  const onCommentButtonClick = () => {
    setComponentState((prev) => ({
      ...prev,
      showCommentError: false,
      localCommentText: '',
    }))
  }

  const handleCommentErrorAction = (key: string, isEditComment: boolean) => {
    if(key === SHOW_COMMENT_ERROR){
      if (isEditComment) {
        setComponentState((prev) => ({
          ...prev,
          showEditCommentError: false
        }))
      }
      else {
        setComponentState((prev) => ({
          ...prev,
          showCommentError: false
        }))
      }
    }
    if(key === IS_SAVE_CLICK){
      setComponentState((prev) => ({
        ...prev,
        isSaveClick: false
       }))
    }
  }

  const getCareProgramData = async () => {
    if (componentState?.linkedCarePlanId) {
      try {
        setComponentState((prev) => ({
          ...prev,
          linkedCarePlanLoading: true
        }))
        const careProgramResponse = await getCareProgramByCarePlanId({
          variables: {
            id: componentState?.linkedCarePlanId,
            stepType: CARE_PROGRAM_STEP_TYPE.CARE_PLAN,
          },
        });

        setComponentState((prev) => ({
          ...prev,
          linkedCarePlanLoading: false
        }))
        const carePlans = careProgramResponse?.data?.contactCarePlans
        if (carePlans?.length) {
          const carePlan = carePlans?.[0];
          let careProgramId = null;
          let careProgramStepId = null;
          if(carePlan?.contactCareProgramId){
            const filteredCareProgram = carePlan?.contactCarePrograms?.filter((careProgram: any) => careProgram?.id === carePlan?.contactCareProgramId);
             careProgramId = filteredCareProgram?.[0]?.id;
             careProgramStepId = filteredCareProgram?.[0]?.contactCareProgramSteps?.[0]?.id;
          } else {
            careProgramId = carePlan?.contactCarePrograms?.[0]?.id;
            careProgramStepId = carePlan?.contactCarePrograms?.[0]?.contactCareProgramSteps?.[0]?.id;
          }
          const path = `/members/patient/${task?.contact?.id}/careManagement/all/${careProgramId}/step/${careProgramStepId}`;
          if (isSidecarContext) {
            redirectToNewTab(path);
          } else {
            window.open(`/#${path}`, '_blank', 'noopener,noreferrer');
          }
        }
      } catch (error) {
        setComponentState((prev) => ({
          ...prev,
          linkedCarePlanLoading: false
        }))
      }
    }
  };

    return (
      <AddOrUpdateTaskView
        taskAccessUsers={componentState.taskAccessUsers}
        carePlanProps={{
          accountLocationUuid: (formmatedContactData || props.personData)?.accountLocationUuid || '',
          contactUuid: editTask?.contact?.uuid,
          contactName: (formmatedContactData || props?.personData)?.name,
          contactId: (formmatedContactData || props?.personData)?.id,
          personData: formmatedContactData || props?.personData,
          onClose: (isAdded: boolean) => {
            // close the task drawer
            if (isAdded) {
              sendMarkAsCompleteEvent();
              props.onCancel?.();
            } else {
              setShowLinkedCarePlan(false);
            }
          },
        }}
        showLinkedCarePlan={showLinkedCarePlan}
        onChangeCarePlanVisible={(isVisible: boolean) => setShowLinkedCarePlan(isVisible)}
        showCommentError={componentState.showCommentError}
        showEditCommentError={componentState.showEditCommentError}
        isSaveClick={componentState.isSaveClick}
        isNeedToScrollDown={componentState.isNeedToScrollDown}
        handleCommentErrorAction={handleCommentErrorAction}
        onCommentButtonClick={onCommentButtonClick}
        defaultAssignee={defaultAssignee}
        loading={componentState.loading === true || componentState?.loading == undefined}
        task={task}
        taskConfig={isWeb() ? taskConfig : {}}
        isVisible={isVisible || false}
        navigation={navigation}
        value={componentState}
        contactName={formmatedContactData?.name}
        taskPriorityMlov={taskPriorityMlov}
        isFormValid={componentState.isFormValid}
        onCancel={() => {
          onCancel?.(componentState?.subTasks, componentState?.subTaskChanged);
          isWeb() && resetComponentState();
        }}
        onAddTask={() => onAddTask()}
        saveBtnText={saveBtnText}
        onMarkAsComplete={(task: any, data: any) => handleMarkAsComplete?.(task, data)}
        showUserSpecificFields={() => showUserSpecificFields()}
        handleChange={(data: IAddOrUpdateTaskState) => {
          setComponentState({
            ...data,
          });
          if (!isWeb()) {
            navigation?.setParams?.({
              currentTask: data,
              onSubmit: onAddTask,
            });
          }
        }}
        comments={componentState.commentsList}
        handleCommentChange={handleCommentChange}
        onCommentChange={onCommentChange}
        taskPools={componentState.taskPools}
        accountUsers={componentState.accountUsers}
        taskLabels={componentState.taskLabels}
        handleSubTaskChange={handleSubTaskChange}
        subTasks={componentState.subTasks}
        subTaskLoading={componentState.subTaskLoading}
        fileList={manageAttachmentsListData(componentState?.fileList || [])}
        fetchAllTypeTask={fetchAllTypeTask}
        restrictPatientOrLeadSelectionTo={props?.restrictPatientOrLeadSelectionTo}
        labelsLoading={componentState?.labelsLoading}
        linkPatientNote={componentState.linkPatientNote}
        onNoteRedirect={() => {
          if (props?.onNoteRedirect && typeof props?.onNoteRedirect == 'function') {
            props?.onNoteRedirect();
          }
        }}
        linkedCarePlanId={componentState.linkedCarePlanId}
        allowedAccountLocations={allowedAccountLocations}
        linkedCarePlanLoading={componentState.linkedCarePlanLoading}
        onRedirectToCareProgram={() => {
          getCareProgramData();
        }}
      />
    );
};

export default AddOrUpdateTask;
