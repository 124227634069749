import { DefaultContext } from '@apollo/client';
import React from 'react';
import { ITemplateCategory } from '../components/RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { IUserPracticeLocation } from '../services/Location/interfaces';
import { VitalInputType } from '../utils/VitalUtils';
import { InputType } from '../utils/capabilityUtils';
import { ILoginSSOAccountProps } from '../components/Login/interface';
import { IDisplayText } from '../components/common/DisplayText/interface';
import { IAppointmentType } from '../components/RightSideContainer/AccountSettings/AppointmentTypes/Interfaces';
import { AllowedWearableDataCategories } from '../components/RightSideContainer/WearableDataIngest/WearableDataConstants';
import { ITask } from '../components/common/CareDashboard/CareDashboardInterfaces';
import { SelectProps } from 'antd/lib/select';
import {IEmailRecipient} from '../components/common/EmailDrawerCommonV2/interfaces';
import { ReconcileStatusCodes } from '../components/PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/interface';
import { Resource } from 'fhir/r4';

export interface IMlovCategory {
  category: string;
  id: string;
}

export interface IMlov {
  categoryId: string;
  code: string;
  id: string;
  value: string;
  showAll?: boolean;
  isDeleted?: boolean;
  mlovCategory?: IMlovCategory;
  tenantId?: string;
  sequenceNumber?: number;
  description?: string;
}

export interface IInterventionMlovs {
  id: string;
  value: string;
}

export interface IMlovResponse {
  mlovs: IMlov[];
  mlovCategories: IMlovCategory[];
  accountMlovsDeprecated?: IMlov[];
}

export interface IMlovByCategory {
  [index: string]: IMlov[];
}

export interface IUser {
  id: string;
  name: string;
  uuid: string;
  accountUsers?: {
    employerId: string | undefined;
    isActive?: boolean;
    externalUserId?: string;
  }[];
  externalUserMap?: {externalUserId: string, locationGroupId: string}[];
  isLocal?: boolean;
  userPracticeLocations?: IUserPracticeLocation[];
  practiceLocationIds?: string[];
  userRoles?: {
    userRole?: {
      customRoleCode?: string;
      roleName?: string;
      id?:string;
      userRole?: {
        id: string;
        code: string;
        value: string;
      };
    };
  }[];
  userId?: string;
  externalUserId?: string;
  memberType?: string[];
  email?: string;
}

export interface IAutoComplete {
  name?: string;
  onAutoCompleteChange: (
    selectedId: any,
    abbr?: string,
    selectedCity?: any
  ) => void;
  isTabDisabled?: boolean;
  isInvalid?: boolean;
  apolloContext?: DefaultContext;
  errors?: any;
  errorText?: string;
  flex?: string | number;
  style?: any;
  onValueChange?: (value: string) => void;
  showAdd?: boolean;
  customStyles?: React.CSSProperties;
  isRequired?: boolean;
  noDataLabel?: string;
  dataList?:ITemplateCategory[];
  changeBackground?: boolean;
  sendZipCodeChange?: boolean;
}

export interface IPersonContact {
  id: string;
  value: string;
  typeId: string;
}

export interface IOmniViewSections {
  displayString: string;
  code: string;
}

export interface INetworkFilter {
  displayString: string;
  code: string;
}

export interface ICheckboxGroupProps {
  label: string;
  value: string;
}

export interface IUserPermission {
  actionCode: string;
  entityCode: string;
  id: string;
  userRoleCode: string;
  permissionCode: string;
  createdAt: string;
}

export interface ILoginUserData {
  access_token: string;
  account_id: number;
  available_name: string;
  avatar_url: string;
  confirmed: boolean;
  display_name?: any;
  message_signature?: any;
  email: string;
  id: number;
  inviter_id?: any;
  name: string;
  provider: string;
  pubsub_token: string;
  role: string;
  uid: string;
  uuid: string;
  accounts: IUserAccount[];
  fold_access_token?: string;
  employerId?: string;
}

export interface IUserAccount {
  id: number;
  name: string;
  active_at?: any;
  role: string;
  availability: string;
  availability_status: string;
  auto_offline: boolean;
  uuid: string;
}

export interface IUserRoleCode {
  code: string;
  value: string;
}

export interface IUserRole {
  id: string;
  userId: number;
  userRole: {
    roleId: string;
  };
  userRoleId: string;
  accountId: number;
  isDeleted: boolean;
  createdBy?: any;
  updatedBy?: any;
  createdAt: string;
  updatedAt: string;
  accountLocationId?: string;
}

export interface IUserRoleCategoryResp {
  userRoleCategories: IUserRoleCategory[];
  accountRoles?: IUserRoleCategory[];
}
export interface IAccountRolesResp {
  accountRoles: IUserRoleCategory[];
}

export interface IUserRoleCategory {
  id: string;
  roleCategoryId: string;
  roleId: string;
  isDeleted: boolean;
  roleName?: string;
  customRoleCode?: string;
}

export interface IUserMainDataObj {
  data: ILoginUserData;
  client: string;
  accessToken: string;
  spreeAccessToken: string;
  spreeRefreshToken: string;
  spreeApiKey: string;
  spreeTenant: string;
  userRole?: IUserRole[];
  practiceLocations: IPracticeLocationObj[];
  isMasterAccount?: boolean;
}
export interface IPracticeLocationObj {
  practiceLocation: IPracticeLocation;
  uuid: string;
  userUuid: string;
}

export interface IPracticeLocation {
  id: number;
  uuid: string;
  addressLine1: string;
  addressLine2: string;
  practiceZipcode: IPracticeZipcode;
  practiceCity: IPracticeAddress;
  practiceCountry: IPracticeAddress;
  practiceState: IPracticeAddress;
  practiceTimezone: IPracticeTimezone;
}

export interface IPracticeTimezone {
  timezone: string;
}

export interface IPracticeAddress {
  name: string;
}

export interface IPracticeZipcode {
  code: string;
}

export interface ISearchFieldProps {
  value?: any;
  allowClearAppointmentType?: boolean;
  isHideUser?:boolean;
  isShowAllAppointmentType?:boolean,
  isAllowEdit?:boolean;
  isHideInternalLabel?:boolean;
  optional?:boolean;
  userInputFieldList?: any;
  defaultTemplateCategory?: string;
  isHideDefaultInput?: boolean;
  isHideTemplateCategory?: boolean;
  isShowError: boolean;
  onChange: (value: any, isTextEdit?:boolean) => void;
  onClear?: () => void;
  isReturnCategory?: boolean;
  allowUserMultiSelect?: boolean;
  numberOfLines?: number;
  showLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
  customPlaceHolder?: string;
  isDisabled?: boolean;
  isFormListTagPresent?: boolean;
  customStyles?: any;
  customLabel?:string;
  fetchInitailEmployers?: boolean;
  renderTag?: boolean;
  skipEmailTemplate?: boolean;
  isAutomationView?:boolean;
  labelStyles?: IDisplayText['extraStyles'];
  showFrequency?:boolean;
  contactId?: number;
  filterLocationIds?: string[];
  locationId?: string;
  placeHolder?: string;
  extraSelectProps?: SelectProps,
  userSearchType?: string;
  locationIds?: string[];
  popGroupAndPracticeLocationFilter?: boolean
  useExactMatchForFormLocations?: boolean;
  assignJourneyToSelectedMembers?: boolean;
  accountLocationUUID?: string;
  singleToLocationGroupId?: string;
  activeContactLocationId?: string;
  showAttachment?:boolean;
}

export interface ILocation {
  id: string;
  name: string;
  uuid: string;
}

export interface ICalendarWidgetMetaState {
  loading: boolean;
  accountUsers: IUser[];
  accountLocations: ILocation[];
  userLocationMap: Map<string, ILocation[]>;
  locationUserMap: Map<string, IUser[]>;
  selectedLocation?: ILocation;
  appointmentTypes?: IAppointmentType[];
}
export interface IEhrCapability {
  ehrName?: string | null;
  resourceName?: string;
  workflow?: string;
  allowedOperations?: any;
  isApplicable?: boolean;
  operation?: number;
  abilities?: IAbility;
  accountId?: string;
  isEnabled?: boolean;
  id?: string;
  locationGroupId?: string;
}

export interface IALlowedCategory {
  allowedFields?: string[];
  allowedFieldGroup?: any;
  category: string;
  code: string;
  display: string;
  foldDisplay: string;
  system: string;
  readOnly?: boolean;
}

export interface IAllowedNoteComponent {
  code: string;
  display: string;
  componentType?: string;
}

export interface IAbility {
  allowedCategories?: IALlowedCategory[],
  allowedNoteComponents?: IAllowedNoteComponent[],
  allowedOperations?: IAllowedOperation;
  keyAllowedOperations?: IKeyOperation;
  createPatientUsingIntegration?: boolean;
  openEhrUrl?: string;
  allowedVitalList?: IVitalData[];
  allowedWearableList?: IAllowedWearableData[];
  url?: string;
  openInHint?: boolean;
  isEnabled?: boolean;
  supportingInfo?: ISupportingInfo;
  allowedDocumentType?: AllowedDocumentType[];
  showMrn?: boolean;
  showInIntegrationAdmin?: boolean;
  formConfigs?: {
    canUpdateDemographics?: boolean;
  };
  canDurationChange?: boolean;
  isRequiredLocationOnVirtualAppointment?: boolean;
  canReviewResult?: boolean;
  isCloseAthenaDiagnosticReportEnabled?: boolean;
  defaultDiagnosticReportLabelId?: string;
  enabledTypes?: string[];
  isVbcTenant?: boolean;
  foldVisitNoteEnabled?: boolean,
  usePagination?: boolean
  capabilities? :IEhrCapability[],
  id?: string,
  canUseContainedQuestionnaire?: boolean,
  conditionalFields?: {
    generalPractitioner?: {
      isHidden?: boolean;
    }
  }

}

export interface IVitalData {
  loinc: string;
  range?: { min: number; max: number }[];
  unit?: string;
  display?: string;
  foldDisplay?: string;
  allowedDecimalPlaces?: number;
  inputType?: VitalInputType;
  isHidden?: boolean;
  possibleValues?: PossibleValue[];
  format?: string;
  displayUnit?: string;
}

export interface IAllowedWearableData {
  code: string;
  display?: string;
  enabled?: boolean;
  category?: AllowedWearableDataCategories;
}

export interface IKeyOperation {
  [key: string]: IAllowedOperation;
}

export interface IAllowedOperation {
  type?: string;
  inputType?: InputType;
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  canRead?: boolean;
  isEnabled?: boolean;
  isRequired?: boolean;
  foldDisplay?: string;
  format?: string;
  defaultValue?: string;
  isHidden?: boolean;
  label?: string;
  isPrimary?: boolean;
  possibleValues?: PossibleValue[];
  transformValues?: PossibleValue[];
  disableMultiple?: boolean;
  maxCharLimit?: number;
  hideFieldsForFreeText?: boolean;
  isSingleEmail?: boolean;
  disableDeletePCP?: boolean;
  extensionUrl?: string;
  isMultiple?: boolean;
  max?: number;
  min?: number;
  allowedCodeSystems?: string[];
  location?: any;
}

export interface PossibleValue {
  id?: string;
  code: string;
  display: string;
}

export interface ISupportingInfo {
  displaySetting: DisplaySetting[];
}

export interface DisplaySetting {
  type: string;
  group: string;
  allowed: string[];
}

export interface AllowedDocumentType {
  code: string;
  system: string;
  display: string;
}

export interface UserActionMasterData {
  actionCode: string;
  display: string;
  id: string;
  isDeleted: boolean;
  level: string;
  mainTabCode?: string;
  sideMenuCode?: string;
}

export interface IConfiguration {
  id: string;
  code: string;
  defaultValue: string;
  value?: string;
  accountConfigurations: IAccountConfiguration[];
}

export interface IAccountConfiguration {
  id: string;
  configurationUuid: string;
  value: string;
  accountUuid: string;
  userUuid?: any;
  accountLocationUuid?: any;
  isDeleted: boolean;
}

export interface ITableFilter {
  text: string
  value: string
}

export interface IAgent {
  id: number;
  account_id: number;
  availability_status: string;
  auto_offline: boolean;
  confirmed: boolean;
  email: string;
  available_name: string;
  name: string;
  role: string;
  thumbnail: string;
}

export interface ISsoErrorInterface {
  onSsoLogin: (userName?: string) => void;
  currentSession?: string;
  ssoLoginData?: {
    userEmail?: string,
    account?: ILoginSSOAccountProps
  };
}
export interface IInfoOnMessageWindowHeader {
  SHOW_PRONOUN_NAME: boolean;
  SHOW_CHOSEN_NAME: boolean;
  SHOW_PCP: boolean;
  ADD_STICKY_NOTE: boolean;
}


export interface IExecutionLog {
  id: string;
  statusId: string;
  resourceId: string;
}
export interface ITaskCategoryData {
  name: string;
  key: number;
  code: string;
  value: string;
}

export interface ICopayDetail {
  type: string;
  amount: number;
}

export interface IAssociatedAccounts {
  id: number;
  uuid: string;
  name: string;
}

export interface ITaskDetailConfig {
  show: boolean;
  task?: ITask;
  activeTaskId?: string;
  activeTask?: ITask;
  showCareJourneyTask?: boolean;
  prefillData?: {
    taskPool?: {
      key: string;
      value: string;
      label: string;
    }
  }
}

export interface IContactDetails {
  locationId: string;
}

export interface EHRWiseLocations {
  id: number;
  uuid: string;
  locationUuid: string;
  practiceLocation: {
    id: number;
    name: string;
    uuid: string;
  };
  locationGroupId?: string;
  locationGroup: any | null;
  ehrInfo: {
    ehrCode: string;
  };
}

export interface ILocationGroupList {
  locationGroupId: string,
  accountLocationUuid: string
}
export interface IAllowedUserPracticeLocationUuidsAndName {
  practiceLocationId: string;
  practiceLocationName: string;
  uuid: string;
}

export interface IRuleContactProps {
  ruleId: string;
  formatResponseHandler?: (contacts?: IRuleContact[]) => void;
  filter?: IContactRuleFilter,
}

export interface IContactRuleFilter {
  isActive?: boolean;
}

export interface IRuleContact {
  id: string;
  uuid: string;
  isActive: boolean;
  contactPracticeLocations: {
    practiceLocation: {
      name: string;
      practiceCity: {
        name: string;
      }
    }
  }[];
  phoneNumber: string;
  name: string;
  email: string;
  person: {
    firstName: string;
    birthDate: string;
    chosenName: string;
    middleName: string;
    lastName: string;
    gender: {
      value: string;
      code: string;
    }
    sexAtBirth: {
      value: string;
      code: string;
    }
  }
  patient: {
    patientId: string;
  }
  personAddress: {
    line1: string;
    line2: string;
    cities: {
      name: string;
    }
    zipcodes: {
      code: string;
    }
    states: {
      name: string;
      abbr: string;
    }
  }[];
}

export interface IFormattedContact {
  ehrId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  birthDate: string;
  gender: string;
  sexAtBirth: string;
  location: string;
  address: string;
  zipcode: string;
  foldStatus?: string;
}
export interface ILoggedInUserLocationDetails {
  accountLocationIds: string[];
  locationGroupIds: string[];
  locationGroupList: {locationGroupId: string; accountLocationUuid: string}[];
}

interface PermittedForSingleObjectInput {
  resourceCode?: string;
  accountLocationId?: string;
}

interface PermittedForInput {
  anyOf?: PermittedForSingleObjectInput[];
  allOf?: PermittedForSingleObjectInput[];
}

interface ExternalUserIdsByLocationGroupIdInput {
  externalUserId?: string;
  locationGroupId: string;
}

export interface ISearchUserQueryInput {
  ids?: number[];
  uuids?: string[];
  name?: string;
  email?: string;
  accountLocationIds?: string[];
  permittedFor?: PermittedForInput;
  userRoleCodes?: string[];
  userRoleCodesNotIn?: string[];
  externalUserIds?: string[];
  externalUserIdsNotIn?: string[];
  externalUserIdsByLocationGroupId?: ExternalUserIdsByLocationGroupIdInput[];
  hasExternalUserId?: boolean;
  hasExternalUserIdForLocationGroupIds?: string[];
  isActive?: boolean;
  limit: number;
  offset?: number;
  orderBy?: any;
  uuidsNotIn?: string[];
}

export interface IHCCCodeGroupData {
  description: string;
  codeGroup: string;
  codeGroupScore: string;
  declinedReason?: string[];
  declinedReasonNote?: string;
}

export interface IConditionDetail {
  name: string;
  source: string;
  icd10Code: string;
  onsetDate: string;
  recordedBy: string;
  status: string;
  resourceId: string;
  conditionUniqueKey?: string;
  diagnosisGapStatus?: string;
}

export interface IDeclinedPatientConditonInput {
  hccCodeGroupData: IHCCCodeGroupData;
  conditionDetails: IConditionDetail[];
}

export enum ReconciliationGroupBy {
  statusCode = 'statusCode',
  resourceType = 'resourceType'
}

export interface IReconciliationRequestCountFilters {
  contactId?: string;
  targetPatientId?: string;
  statusCodes?: ReconcileStatusCodes[];
  resourceTypes?: string[];
}

export interface IReconciliationRequestCountParams {
  filters: IReconciliationRequestCountFilters;
  groupBy?: ReconciliationGroupBy;
}

export interface IReconciliationRequestParams {
  contactId?: string;
  targetPatientId?: string;
  statusCodes?: ReconcileStatusCodes[];
  resourceTypes?: string[];
  limit?: number;
  offset?: number;
}

export interface IUpdateReconciliationRequestParams {
  id: string;
  statusCode: string;
  eventSource: string;
  resource?: Resource;
  targetActionCode?: string;
}
