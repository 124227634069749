import React from 'react';
import {CommonDataContext} from '../../context/CommonDataContext';
import {usePermissions} from './usePermissions';
import {USER_ACCESS_PERMISSION} from '../RightSideContainer/UserAccess/UserAccessPermission';

const useLoggedInUserAllowedLocationsByTab = (tabCode: string) => {

  const {check} = usePermissions();
  const permissionConfig = check(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    tabCode
  );

  return permissionConfig?.allowedLocationIds;
};

export default useLoggedInUserAllowedLocationsByTab;
