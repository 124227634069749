import React from "react";
import LibNodeCard from "../../../Workflow/LibNodeCard/LibNodeCard";
import DefaultComponentSvg from "../../../../../assets/Icons/FormBuilder/DefaultComponentSvg";

function SidebarElementDragOverlay({
  label,
  icon,
}: {
  label: string,
  icon?: React.ElementType,
}) {
  const LibIcon = icon || DefaultComponentSvg;

  return (
    <div className="flex w-full library-element-card">
      <LibNodeCard
        title={label || ''}
        style={{
          paddingHorizontal: 8,
          paddingVertical: 8
        }}
        iconElement={<LibIcon />}
      />
    </div>
  );
}

export default React.memo(SidebarElementDragOverlay);
