import Svg, {G, Path, Defs, ClipPath, Rect} from 'react-native-svg';
import {ICommonSvgProps} from './interfaces';
import React from 'react';

const LockKeyHoleSvg = (props: ICommonSvgProps) => {
  return (
    <Svg
      width={props?.size || '10'}
      height={props?.size || '10'}
      viewBox="0 0 10 10"
      fill="none"
    >
      <G clip-path="url(#clip0_1208_6167)">
        <Path
          d="M2.50016 4.16659V3.33325C2.50016 1.95254 3.61945 0.833252 5.00016 0.833252C6.38087 0.833252 7.50016 1.95254 7.50016 3.33325V4.16659M3.3335 9.16659H6.66683C7.84534 9.16659 8.4346 9.16659 8.80071 8.80047C9.16683 8.43435 9.16683 7.8451 9.16683 6.66659C9.16683 5.48807 9.16683 4.89882 8.80071 4.5327C8.4346 4.16659 7.84534 4.16659 6.66683 4.16659H3.3335C2.15498 4.16659 1.56573 4.16659 1.19961 4.5327C0.833496 4.89882 0.833496 5.48807 0.833496 6.66659C0.833496 7.8451 0.833496 8.43435 1.19961 8.80047C1.56573 9.16659 2.15498 9.16659 3.3335 9.16659ZM5.8335 6.66659C5.8335 7.12682 5.4604 7.49992 5.00016 7.49992C4.53993 7.49992 4.16683 7.12682 4.16683 6.66659C4.16683 6.20635 4.53993 5.83325 5.00016 5.83325C5.4604 5.83325 5.8335 6.20635 5.8335 6.66659Z"
          stroke={props?.customStrokeColor || '#6F7A90'}
          stroke-width="0.8"
          stroke-linecap="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1208_6167">
          <Rect
            width={props?.size || '10'}
            height={props?.size || '10'}
            fill="white"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default React.memo(LockKeyHoleSvg);
