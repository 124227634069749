import { useLazyQuery } from '@apollo/client';
import { useToast } from 'native-base';
import {useState} from 'react';
import { SORT_BY, SORT_ORDER } from '../../../constants';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import {getDiagnosticReportByPatientId} from '../../../services/CommonService/OrderService';
import { IDocument } from '../../../services/Document/DocumentInterfaces';
import { GET_WHOLE_PATIENT_DOCUMENTS } from '../../../services/Document/DocumentQueries';
import { showToast, ToastType } from '../../../utils/commonViewUtils';
import { IPatientDocumentSelectedFilters } from '../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/FilterPatientDocumentViewDrawer';
import { sortReports } from '../../PersonOmniView/LeftContainer/OtherDetails/PatientDocuments/UploadDocumentUtils';
import { IReportAndResourceMap} from '../../PersonOmniView/LeftContainer/RecentActivity/OrdersInterface';
import {getDiagnosticReportResponseFormatted} from '../../PersonOmniView/LeftContainer/RecentActivity/RecentReport/ReportUtils';
import { IJourneyDetails } from '../../PersonOmniView/MiddleContainer/Documents/DocumentUtils';
import { ReportCategory } from '../../PersonOmniView/MiddleContainer/Orders/OrdersAndReports/OrderUtils';
import { IMediaLibraryData } from '../../RightSideContainer/ContentManagement/MediaLibrary/interfaces';

const LIMIT = 100;
type SortingState = 'none' | 'asc' | 'desc';
interface IUseReportsOptions {
  patientId: string;
  contactUuid: string;
  accountLocationUuid: string;
  isPagination?: boolean;
  canShowMarkAsReview?: boolean;
  type?: string;
  ehr?: string;
  context?: string;
};
interface ImagingReportParams {
  currentPageOnUI: number;
  searchString?: string;
  sort: {
    by:  SORT_BY;
    order: SORT_ORDER;
  };
  interactiveData?: boolean;
};
interface AllDocParams {
  currentPageOnUI: number;
  sort: {
    by:  SORT_BY;
    order: SORT_ORDER;
  };
  searchString?: string;
  interactiveData?: boolean;
};

type IComponentState = {
  selectedDocument?: IDocument;
  documentList: (IReportAndResourceMap | IDocument)[] ;
  isAddDocumentModalOpen?: boolean;
  isFilterModalOpen?: boolean;
  isDeleteConfirmationOpen?: boolean;
  isLoading?: boolean;
  deleteDocumentLoading: { [index: string]: boolean }
  isFileLoading?: any;
  isPreviewVisible?: boolean;
  previewTitle?: string;
  previewObj?: { url: string; type: string; fileMediaData: IMediaLibraryData };
  previewImage?: any;
  searchString?: string;
  selectedFilters?: IPatientDocumentSelectedFilters;
  aggregateDocumentCount?: number;
  nameSort: SortingState;
  dateSort: SortingState;
  careJourneyIdsHavingDocuments?: string[];
  careJourneyDetailsHavingDocuments?: IJourneyDetails[],
  filteredCareJourneyDetailsHavingDocuments: IJourneyDetails[];
  selectedJourney?: IJourneyDetails;
  isEFaxDrawerOpen?: boolean;
  isEmailDrawerOpen?: boolean;
  base64DataUploadedDoc?: string;
  isImagingReportDesc?: boolean;
  isImagingReportAscByName?: boolean;
  isAllDocDescByDate?: boolean;
  isAllDocAscByName?: boolean;
  isAllDocDesc?: boolean;
  allDocMergedSortedList: (IReportAndResourceMap | IDocument)[];
  imagingAllReports?: (IReportAndResourceMap | IDocument)[];
  actionSheet?: boolean;
};

const useReports = ({ patientId,
  contactUuid,
  accountLocationUuid,
  isPagination,
  canShowMarkAsReview,
  type,
  ehr,
  context,
}: IUseReportsOptions
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useToast();
  const itemsPerPage = 10;
  const [paginationState, setPaginationState] = useState({
    pageSize: 10,
    isLoading: false,
    offset: 0,
  });
  const [componentState, setComponentState] = useState<IComponentState>({
    documentList: [],
    isAddDocumentModalOpen: false,
    isFilterModalOpen: false,
    isDeleteConfirmationOpen: false,
    isLoading: false,
    deleteDocumentLoading: {},
    isFileLoading: {},
    isPreviewVisible: false,
    previewTitle: undefined,
    searchString: '',
    aggregateDocumentCount: 0,
    nameSort: 'none',
    dateSort: 'none',
    careJourneyIdsHavingDocuments: [],
    careJourneyDetailsHavingDocuments: [],
    filteredCareJourneyDetailsHavingDocuments: [],
    isEFaxDrawerOpen: false,
    isEmailDrawerOpen: false,
    base64DataUploadedDoc: '',
    isImagingReportDesc: false,
    isImagingReportAscByName: false,
    isAllDocDescByDate: false,
    isAllDocAscByName: false,
    isAllDocDesc: false,
    allDocMergedSortedList: [],
    imagingAllReports: [],
  });

  const [getContactAllDocumentsQuery, allDocumentsQuery] = useLazyQuery(
    GET_WHOLE_PATIENT_DOCUMENTS,
    {
      fetchPolicy: 'no-cache',
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (response: any) => {
        setLoading(false);
        setPaginationState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      },
      onError: (error) => {
        setLoading(false);
        setPaginationState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
      },
    }
  );

  const getAllDocuments = async () => {
    let documentsList: (IReportAndResourceMap | IDocument)[] = [];
    if (contactUuid) {
      setLoading(true);
      await getContactAllDocumentsQuery({
        variables: {
          subjectId: contactUuid,
        },
      }).then((response) => {
        documentsList = response?.data?.documents;
      });
    }
    return documentsList;
  };


  const fetchReports = async (interactiveData?: boolean) => {
    setLoading(true);
    setError(null);
    let total = LIMIT;
    let offset = 0;
    let count = 0;
    let runNext = true;
    const fetchedReports: IReportAndResourceMap[] = [];

    while (total > count && runNext) {
      try {
        runNext = false;
        const response = await getDiagnosticReportByPatientId(
          patientId || '',
          accountLocationUuid,
          isPagination && {_count: LIMIT, _offset: offset},
          type,
          interactiveData
        );
        if (response?.data) {
          count += response?.data?.entry?.length;
          const isEmpty = response?.data?.entry?.length === 0;
          total = response?.data?.total;
          offset += LIMIT;

          const formattedResponse = getDiagnosticReportResponseFormatted(
            response,
            canShowMarkAsReview,
            type !==  ReportCategory.IMAGING
          );
          const reports = formattedResponse.reports as IReportAndResourceMap[];
          if (reports) {
            fetchedReports.push(...reports);
          }
          if (isPagination && !isEmpty) {
            runNext = true;
          }
        }
      } catch (err: any) {
        total = 0;
        runNext = false;
        setError(err);

      }
    }
    setLoading(false);
    return fetchedReports;
  };
  const fetchAndHandleDataForImagingReport = async ({
    currentPageOnUI,
    sort,
    searchString,
    interactiveData
  }: ImagingReportParams) => {
    let sortedList: (IReportAndResourceMap | IDocument)[] | undefined;
    setPaginationState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    setLoading(true);
    if (currentPageOnUI === 1) { //for page size equal to  1 , fetched data again
      setCurrentPage(1);
      setPaginationState((prev) => ({...prev, offset: 0, pageSize: 10}))
      const imagingReport = await fetchReports(interactiveData);
      const filteredReports =
        searchString && searchString !== ''
          ? imagingReport.filter((report: any) => {
              const title = report?.display || '';
              return title.toLowerCase().includes(searchString.toLowerCase());
            })
          : imagingReport;
      if (sort.by === SORT_BY.NAME) {
        sortedList = sortReports(
          filteredReports,
          (sort.order === SORT_ORDER.DESC),
          SORT_BY.NAME
        );
        setComponentState((prev) => ({
          ...prev,
          isImagingReportAscByName: !componentState.isImagingReportAscByName,
        }));
      } else {
        sortedList = sortReports(
          filteredReports,
          sort.order === SORT_ORDER.DESC,
          SORT_BY.DATE
        );
        setComponentState((prev) => ({
          ...prev,
          isImagingReportDesc: !componentState.isImagingReportDesc,
        }));
      }
      if (sortedList) {
        setComponentState((prev) => ({
          ...prev,
          imagingAllReports: filteredReports,
          documentList: sortedList!.slice(0, itemsPerPage),
          aggregateDocumentCount: filteredReports.length,
        }));
      }
    } else {
      //for page size more than 1 , worked on already fetched data
      if (componentState?.imagingAllReports) {
        const documentList = [
          ...componentState.documentList,
          ...componentState?.imagingAllReports.slice(
            (currentPageOnUI - 1) * itemsPerPage,
            currentPageOnUI * itemsPerPage
          ),
        ];
        setTimeout(() => {
          setComponentState((prev) => {
            return {
              ...prev,
              documentList: documentList,
            };
          });
          setLoading(false);
        }, 500);
      }
    }
    let filteredJourneys: undefined | IJourneyDetails[] = undefined;
    if (searchString) {
      if (componentState?.selectedJourney) {
        filteredJourneys = [];
      } else
        filteredJourneys =
          componentState?.careJourneyDetailsHavingDocuments?.filter(
            (journey: any) => {
              return journey.title
                ?.toLocaleLowerCase()
                ?.includes(searchString?.toLocaleLowerCase());
            }
          );
    } else filteredJourneys = componentState?.careJourneyDetailsHavingDocuments;
    setComponentState((prev) => {
      return {
        ...prev,
        ...(filteredJourneys && {
          filteredCareJourneyDetailsHavingDocuments: filteredJourneys,
        }),
        isLoading: loading,
      };
    });
    setTimeout(() => {
      setPaginationState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }, 500);
    if (error) {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
    setLoading(false);
  };

  const fetchAndHandleDataForAllDoc = async ({
    currentPageOnUI,
    sort,
    searchString,
    interactiveData
  }: AllDocParams) => {
    let sortedMergedReport: (IReportAndResourceMap | IDocument)[] | undefined;
    setPaginationState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    setLoading(true);
    if (currentPageOnUI === 1) {
      setCurrentPage(1);
      setPaginationState((prev) => ({...prev, offset: 0, pageSize: 10}))
      const imagingReport = await fetchReports(interactiveData);
      const docOtherThanImagingReport = await getAllDocuments();
      const mergedReports = [...imagingReport, ...docOtherThanImagingReport];
      if (sort.by === SORT_BY.NAME) {
        sortedMergedReport = sortReports(mergedReports, (sort.order === SORT_ORDER.DESC), SORT_BY.NAME);
        setComponentState((prev) => ({
          ...prev,
          isAllDocAscByName: !componentState.isAllDocAscByName,
        }));
      } else {
        sortedMergedReport = sortReports(mergedReports, sort.order === SORT_ORDER.DESC, SORT_BY.DATE);
        setComponentState((prev) => ({
          ...prev,
          isAllDocDescByDate: !componentState.isAllDocDescByDate,
        }));
      }
      if(sortedMergedReport){
        const filteredReports =
        searchString && searchString !== ''
          ? sortedMergedReport.filter((report: any) => {
              const title = report.display || report.caption || '';
              return title.toLowerCase().includes(searchString.toLowerCase());
            })
          : sortedMergedReport;
      setComponentState((prev) => ({
        ...prev,
        allDocMergedSortedList: filteredReports,
        documentList: filteredReports.slice(0, itemsPerPage),
        aggregateDocumentCount: filteredReports.length,
        isLoading: false,
        isAllDocDesc: true,
      }));
      }
    } else {
      const documentList = [
        ...componentState.documentList,
        ...componentState.allDocMergedSortedList.slice(
          (currentPageOnUI - 1) * itemsPerPage,
          currentPageOnUI * itemsPerPage
        ),
      ];
      setTimeout(() => {
        setComponentState((prev) => ({
          ...prev,
          documentList: documentList,
        }));
        setLoading(false);
      }, 500);
    }
    let filteredJourneys: undefined | IJourneyDetails[] = undefined;
    if (searchString) {
      if (componentState?.selectedJourney) {
        filteredJourneys = [];
      } else
        filteredJourneys =
          componentState?.careJourneyDetailsHavingDocuments?.filter(
            (journey: any) => {
              return journey.title
                ?.toLocaleLowerCase()
                ?.includes(searchString?.toLocaleLowerCase());
            }
          );
    } else filteredJourneys = componentState?.careJourneyDetailsHavingDocuments;
    setComponentState((prev) => {
      return {
        ...prev,
        ...(filteredJourneys && {
          filteredCareJourneyDetailsHavingDocuments: filteredJourneys,
        }),
      };
    });
    setTimeout(() => {
      setPaginationState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }, 500);
    if (error) {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
    }
    setLoading(false);
  };

  const handlePaginationState: React.Dispatch<
    React.SetStateAction<{
      offset: number;
      pageSize: number;
      isLoading: boolean;
    }>
  > = (data) => {
    setPaginationState(data);
  };

  const handleComponentState: React.Dispatch<
    React.SetStateAction<IComponentState>
  > = (data) => {
    setComponentState(data);
  };

  const handleSetCurrentPage: React.Dispatch<React.SetStateAction<number>> = (
    data
  ) => {
    setCurrentPage(data);
  };

  return {
    fetchReports,
    loading,
    error,
    fetchAndHandleDataForImagingReport,
    fetchAndHandleDataForAllDoc,
    paginationState,
    setPaginationState: handlePaginationState,
    componentState,
    setComponentState: handleComponentState,
    currentPage,
    setCurrentPage: handleSetCurrentPage
  };
};

export default useReports;
