import React from 'react';
import { Input } from 'antd';

const InputField = ({
  prefix,
  suffix,
  errorMessage,
  placeholder,
  onChange,
  defaultValue,
  isInvalid,
  autoFocus,
  type,
  disabled,
}: {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  errorMessage?: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  isInvalid?: boolean;
  autoFocus?: boolean;
  type?: string;
  disabled?: boolean;
}) => {
  const formatNumberValue = (value: string) => {
    // Remove all non-numeric characters except decimal point
    let cleanedValue = value.replace(/[^0-9.]/g, '');

    // Remove decimal point if it's the first character
    if (cleanedValue.startsWith('.')) {
      cleanedValue = cleanedValue.substring(1);
    }

    // Keep only the first decimal point
    const parts = cleanedValue.split('.');
    if (parts.length > 1) {
      cleanedValue = `${parts[0]}.${parts[1]}`;
    }

    return cleanedValue;
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (type === 'number') {
      event.preventDefault();
      const pastedData = event.clipboardData.getData('text');
      const cleanedValue = formatNumberValue(pastedData);

      const input = event.target as HTMLInputElement;
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
      nativeInputValueSetter?.call(input, cleanedValue);

      const nativeEvent = new InputEvent('input', { bubbles: true });
      input.dispatchEvent(nativeEvent);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number') {
      // Prevent decimal point as first character
      if (event.key === '.' && !event.currentTarget.value) {
        event.preventDefault();
        return;
      }

      // Allow: backspace, delete, tab, escape, enter, decimal point, arrows
      if (
        [
          'Backspace',
          'Delete',
          'Tab',
          'Escape',
          'Enter',
          'ArrowLeft',
          'ArrowRight',
          'Home',
          'End',
          '.',
        ].includes(event.key) ||
        ((event.ctrlKey || event.metaKey) && ['a', 'c', 'v', 'x'].includes(event.key))
      ) {
        // Prevent multiple decimal points
        if (event.key === '.' && event.currentTarget.value.includes('.')) {
          event.preventDefault();
        }
        return;
      }

      // Prevent input if not a number
      if (!/^[0-9]$/.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      const cleanedValue = formatNumberValue(event.target.value);
      event.target.value = cleanedValue;
      onChange(event);
    } else {
      onChange(event);
    }
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <Input
        autoFocus={autoFocus}
        addonBefore={prefix}
        addonAfter={suffix}
        status={errorMessage || isInvalid ? "error" : undefined}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        defaultValue={defaultValue}
        className="form-input-field"
        autoComplete="off"
        type="text"
        disabled={disabled}
        onPaste={handlePaste}
      />
      {Boolean(errorMessage) && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default React.memo(InputField);
