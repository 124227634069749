import moment from 'moment';
import {Box, HStack, Pressable, Spinner, Text, Tooltip, useToast, View, VStack} from 'native-base';
import React, { useContext, useState, useMemo } from 'react';
import { ATTACHMENT_TYPE, DATE_FORMATS } from '../../../../../../constants';
import { Colors } from '../../../../../../styles/Colors';
import MissedCallSvg from '../../../../Svg/ConversationAllSvg/MissedCallSvg';
import { isJsonString } from '../../../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingUtils';
import { MESSAGE_CUSTOM_CARD_KEY } from '../../../../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MessagingCustomCard/CustomCardConst';
import Feather from 'react-native-vector-icons/Feather';
import { isWeb } from '../../../../../../utils/platformCheckUtils';
import { getCallIcons, getCallIconsColors, getCallStatus, isShowCallTrail, parseTwilioRecordingUrl } from '../../../../../RightSideContainer/TeamInbox/CallLogs/CallLogsUtils';
import VoiceRecordPlaySvg from '../../../../Svg/SideMenu/VoiceRecordPlaySvg';
import TranscriptionsView from '../../../../../RightSideContainer/TeamInbox/Transcriptions/TranscriptionsView';
import ReactPlayer from 'react-player';
import { IMessageContentParse, IMissedCallLogsProps } from './interface';
import { styles } from '../../../../ActionMsg/ActionMsgStyle';
import TransitionIconSvg from '../../../../Svg/ConversationAllSvg/TransitionIconSvg';
import VideoPlayerModal from '../../../../VideoPlayerModal/VideoPlayerModal';
import { CallTranscriptionModal } from '../../../../../RightSideContainer/TeamInbox/CallLogs/CallLogUtils';
import { getCallActionMessageTime, getCallMessageContainerPaddingConfig, getDurationInFormat, getFlexAlignType, isMissedCallMessage } from '../../MessageBoxUtils';
import { getSignedRecordingApi } from '../../../../../RightSideContainer/TeamInbox/Conversations/ConversationChannelNew/ConversationAPIService';
import { ActivityIndicator, FlexAlignType, StyleSheet } from 'react-native';
import { useIntl } from 'react-intl';
import { ITwilioSidData } from '../../../../../RightSideContainer/TeamInbox/CallLogs/interface';
import { TwilioRecordingUrlPrefix } from '../../../../../../constants/Configs';
import { showToast, ToastType } from '../../../../../../utils/commonViewUtils';
import { AssignCommunicationTypeButton, MessageCommunicationTypes } from '../../../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes';
import { ICommunicationType } from '../../../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes/interfaces';
import { getCommunicationTypesByMessageUuids } from '../../../../../RightSideContainer/TeamInbox/Conversations/CommunicationTypes/CommunicationTypesUtils';
import { CallTrailButton } from '../../../../../RightSideContainer/CallTrail';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { testID, TestIdentifiers } from '../../../../../../testUtils';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {CallDirections, TWILIO_CALL_STATUS} from '../../../../../RightSideContainer/TeamInbox/CallLogs/CallLogsConst';

const MissedCallActionMessage = (props: IMissedCallLogsProps) => {
    const {
        conversationMessage,
        dateStr,
        contactInfo,
        selectedConversation,
        item,
        onClinicalNotesOpen,
        onRemoveCommunicationType,
        hideRecordingAndTrails
    } = props;
    const toast = useToast();
    const intl = useIntl();
    const isCallAndTranscriptionEnable = isAccountConfigEnabled(CONFIG_CODES.SHOW_CALL_RECORDING_AND_TRANSCRIPTION);
    const [state, setState] = useState({
      recordingStart: false,
      transcriptionOpen: false,
      isLoading: false,
      recordingSignedUrl: '' as string,
      communicationTypes:
        item?.communicationTypes || ([] as ICommunicationType[]),
      loading: false
    });
    const infoSeparator = isWeb() ? ' • ' : '\n'

const commonData = useContext(CommonDataContext);
const userList = commonData?.accountUserList;
const sipNumberList = commonData?.sipNumberList;
const isSidecarContext = commonData?.sidecarContext?.isSidecar;
const content: IMessageContentParse = isJsonString(conversationMessage?.content || '') ? JSON.parse(conversationMessage?.content || '') : {}
  const duration = () => {
    if (content?.resourceData?.duration?.length > 0 && Number(content?.resourceData?.duration) > 0) {
      return ' • ' + getDurationInFormat(content?.resourceData);
    }
    return '';
  }

  const showCallTrail = isShowCallTrail(content.resourceData);

  const getBgColor = (resourceCode: string) => {
    switch (resourceCode) {
      case MESSAGE_CUSTOM_CARD_KEY.INCOMING_COMPLETED_CALL_CARD:
        return Colors.Custom.OutgoingCompletedBgColor
      case MESSAGE_CUSTOM_CARD_KEY.OUTGOING_COMPLETED_CALL_CARD:
        return Colors.Custom.Gray100
      case MESSAGE_CUSTOM_CARD_KEY.INCOMING_MISSED_CALL_CARD:
        return Colors.Custom.MissedCallBg
      case MESSAGE_CUSTOM_CARD_KEY.OUTGOING_MISSED_CALL_CARD:
        return Colors.Custom.Gray100
    }
  }

  const fetchSignedRecordingUrl = async (existingRecordingUrl: string) => {
    setState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const transcriptionParams: ITwilioSidData =
      parseTwilioRecordingUrl(existingRecordingUrl);
    if (!transcriptionParams.accountSid && !transcriptionParams.recordingSid) {
        showErrorMsg();
      return;
    }
    const recordingRes = await getSignedRecordingApi(transcriptionParams).catch(
      (err) => {
        showErrorMsg();
      }
    );
    if (recordingRes?.data?.url) {
      state.recordingSignedUrl = recordingRes?.data?.url;
      setState((prev) => {
        return {
          ...prev,
          isLoading: false,
          recordingStart: true,
          recordingSignedUrl: recordingRes?.data?.url,
        };
      });
    }
  };

  const showErrorMsg = () => {
    showToast(
      toast,
      intl.formatMessage({id: 'apiErrorMsg'}),
      ToastType.error
    );
    setState((prev) => {
      return {
        ...prev,
        isLoading: false,
        recordingStart: false,
      };
    });
  }

  const showAssignTypeButton = !isMissedCallMessage(content?.resourceCode);

  const fetchCommunicationTypesByMessageUuid = async () => {
    setState(prev => {
      return {
        ...prev,
        loading: true
      }
    })
    const messageUuid = props.item && props.item?.uuid || '';
    const response = await getCommunicationTypesByMessageUuids([messageUuid]);
    if (!response.data) {
      return;
    }
    const communicationTypes = response.data.map(
      (item) => item?.communicationType
    );
    setState((prev) => {
      return {
        ...prev,
        communicationTypes,
        loading: false
      };
    });
  }

  const paddingConfig = getCallMessageContainerPaddingConfig(
    content?.resourceCode
  );

  const isShowTranscription = () => {
    let isShowTranscription = false;
    if (content?.resourceCode ===
      MESSAGE_CUSTOM_CARD_KEY.INCOMING_MISSED_CALL_CARD && !content?.resourceData?.recording?.length) {
        isShowTranscription = false;
    } else if (
      (content?.resourceCode ===
        MESSAGE_CUSTOM_CARD_KEY.INCOMING_MISSED_CALL_CARD &&
        content?.resourceData?.recording?.length) ||
      (isCallAndTranscriptionEnable && content?.resourceData?.callrecord)
    ) {
      isShowTranscription = true;
    }
    return isShowTranscription;
  }

const onVoiceRecordPlayBtnClick = () => {
  const callRecordingUrl =
  content?.resourceData?.recording || content?.resourceData?.callrecord || '';
  if (
    callRecordingUrl?.includes(
      TwilioRecordingUrlPrefix
    )
  ) {
    state.recordingSignedUrl = callRecordingUrl;
    setState((prev) => {
      return {
        ...prev,
        recordingStart: true,
        recordingSignedUrl: callRecordingUrl
      };
    });
  } else {
    fetchSignedRecordingUrl(
      callRecordingUrl
    );
  }
};

const onPressTransitionBtn = () => {
  setState((prev) => {
    return {
      ...prev,
      transcriptionOpen: !state.transcriptionOpen,
    };
  });
};

const onTranscriptionsViewClose = () => {
  setState((prev) => {
    return {
      ...prev,
      transcriptionOpen: false,
    };
  });
};

const onCallTranscriptionModalClose = () => {
  setState((prev) => {
    return {
      ...prev,
      transcriptionOpen: false,
    };
  });
};

const onReactPlayerEnded = () => {
  setState((prev) => {
    return {
      ...prev,
      recordingStart: false,
    };
  });
};

const onVideoPlayerModalActionPerformed = () => {
  setState((prev) => {
    return {
      ...prev,
      recordingStart: false,
    };
  });
};

const onRemoveCommunicationTypePerformed = (id: string) => {
  if (onRemoveCommunicationType) {
    onRemoveCommunicationType(id);
  }
}

const getCallStatusAndCallerName = () => {
  const userName = userList?.find((user) => user?.uuid === content.resourceData?.userUUID)?.name;
  const phoneName = sipNumberList?.find((sip) => sip?.id === content.resourceData?.sipPhoneId)?.phoneName;
  const isIncoming = content.resourceData?.direction == CallDirections.Incoming && content?.resourceData?.status == TWILIO_CALL_STATUS.COMPLETED;
  const inOutgoing = content.resourceData?.direction === CallDirections.Outgoing;
  if ((userName || phoneName) && (isIncoming || inOutgoing)) {
    return userName || phoneName;
  }
  return getCallStatus(content?.resourceData);
}

  const containerStyle = useMemo(() => {
    return {
      alignItems: getFlexAlignType(content) as FlexAlignType,
    };
  }, [content]);

return (
  <View style={containerStyle}>
    {state.communicationTypes?.length > 0 && (
      <MessageCommunicationTypes
        isMessageBox
        types={state.communicationTypes}
        messageUuid={item?.uuid || ''}
        onRemoveCommunicationType={onRemoveCommunicationTypePerformed}
      />
    )}

    <HStack
      style={[
        styles.actionMsgBox,
        {borderRadius: 6, ...paddingConfig, alignItems: 'center'},
      ]}
      backgroundColor={getBgColor(content?.resourceCode)}
      height={isWeb() ? 30 : 'auto'}
    >
      <VStack justifyContent={'flex-end'}>
        <HStack style={componentStyles.hStack} width={isWeb() ? 'auto' : 300}>
          <View
            height={'16px'}
            width={'16px'}
            justifyContent={'center'}
            alignItems={'center'}
            {...(!isWeb() && {flex: 0.6})}
            {...testID('image')}
          >
            {content.resourceCode ===
            MESSAGE_CUSTOM_CARD_KEY.INCOMING_MISSED_CALL_CARD ? (
              <MissedCallSvg
                customStrokeColor={Colors.Custom.MissedCallIconColor}
              />
            ) : (
              <Feather
                size={16}
                name={getCallIcons(content?.resourceData)}
                color={getCallIconsColors(content?.resourceData)}
              />
            )}
          </View>
          <Text
            width={isWeb() ? 'auto' : '240'}
            size={'smRegular'}
            paddingLeft={3}
            fontSize={isWeb() ? 14 : 12}
            fontWeight={400}
            color={Colors.FoldPixel.GRAY400}
            alignContent={'center'}
            textAlign={'left'}
            {...(!isWeb() && {flex: 9})}
            {...testID('MissedCallActionMessage')}
          >
            {getCallStatusAndCallerName() +
              infoSeparator +
              content.resourceData?.from +
              ' • ' +
              getCallActionMessageTime(content?.resourceData?.dateTime || dateStr || '') +
              duration()}
          </Text>
          <HStack
              marginLeft={isWeb() ? 2 : 0}
              {...(!isWeb() && {flex: 1})}
              justifyContent={'flex-end'}
              alignItems={'center'}
              opacity={hideRecordingAndTrails ? 0.5 : 1}
              cursor={hideRecordingAndTrails ? 'not-allowed' : 'default'}
            >
              {isShowTranscription() && (
                <Tooltip
                  hasArrow
                  label={`${intl.formatMessage({id: 'recording'})}`}
                  placement="top"
                >
                  <Pressable disabled={hideRecordingAndTrails} onPress={onVoiceRecordPlayBtnClick} {...testID(TestIdentifiers.voiceRecordPlayBtn)}>
                    <VoiceRecordPlaySvg
                      height={'16px'}
                      width={'16px'}
                      customStrokeColor={Colors.Custom.Gray500}
                    />
                  </Pressable>
                </Tooltip>
              )}
              {isShowTranscription() && (
                <Tooltip
                  hasArrow
                  label={`${intl.formatMessage({id: 'transcription'})}`}
                  placement="top"
                >
                  <Pressable
                    disabled={hideRecordingAndTrails}
                    onPress={onPressTransitionBtn}
                    style={componentStyles.transitionBtn}
                    {...testID(TestIdentifiers.transitionBtn)}
                  >
                    <TransitionIconSvg
                      height={'16px'}
                      width={'16px'}
                      customStrokeColor={Colors.Custom.Gray500}
                    />
                  </Pressable>
                </Tooltip>
              )}
              {content?.resourceData?.id && showCallTrail ? (
                <CallTrailButton callId={content.resourceData.id} height={15} width={15} size={5} hideRecordingAndTrails={hideRecordingAndTrails}/>
              ) : (
                <></>
              )}
            </HStack>
        </HStack>
      </VStack>
    </HStack>

    {showAssignTypeButton && (
      <View>
        {state.loading ? (
          <Spinner {...testID(TestIdentifiers.lazyLoading)}/>
        ) : (
          <AssignCommunicationTypeButton
            contactUuid={contactInfo.contactData.uuid || ''}
            messageIdUuid={item?.uuid || ''}
            onSave={fetchCommunicationTypesByMessageUuid}
            navigation={props.navigation}
          />
        )}
      </View>
    )}
    {state.transcriptionOpen && (
      <>
        {isWeb() ? (
          <TranscriptionsView
            callLogEntry={content?.resourceData}
            isVisible={state.transcriptionOpen}
            contactInfo={contactInfo}
            onClose={onTranscriptionsViewClose}
            conversationMessage={conversationMessage}
            selectedConversation={selectedConversation}
            onClinicalNotesOpen={onClinicalNotesOpen}
          />
        ) : (
          <CallTranscriptionModal
            isOpen={state.transcriptionOpen}
            onClose={onCallTranscriptionModalClose}
            callLogEntry={content?.resourceData || ''}
          />
        )}
      </>
    )}
    {state.recordingSignedUrl.length > 0 && state.recordingStart && (
      <>
        {state.isLoading ? (
          <ActivityIndicator color={Colors.Custom.PrimaryColor} {...testID(TestIdentifiers.lazyLoading)}/>
        ) : isWeb() ? (
          <ReactPlayer
            playing={true}
            width={300}
            height={50}
            controls={true}
            url={state.recordingSignedUrl}
            onEnded={onReactPlayerEnded}
            config={{
              file: {
                attributes: {
                  controlsList: isSidecarContext ? 'noplaybackrate' : '' // Only disable playback speed in sidecar context
                }
              }
            }}
          />
        ) : (
          <VideoPlayerModal
            modalTitle={'voiceMail'}
            srcUrl={state.recordingSignedUrl}
            height={200}
            filetype={ATTACHMENT_TYPE.AUDIO}
            scrollEnabled={false}
            removeAudioFileName = {true}
            onActionPerformed={onVideoPlayerModalActionPerformed}
          />
        )}
      </>
    )}
  </View>
);
};

const componentStyles = StyleSheet.create({
  hStack: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  transitionBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4
  }
});

export default MissedCallActionMessage;