import {DATE_FORMATS} from '../../../constants';
import {
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  APPOINTMENT_STATUS_CODES,
  CARE_PROGRAM_STEP_TYPE,
  CARE_PROGRAM_TYPE_CODES,
  CARE_TEAM_MEMBER_TYPE,
  CONTACT_CARE_PROGRAM_STATUS_CODES,
  CONTACT_CARE_PROGRAM_STATUS_VALUES,
  MLOV_CATEGORY,
} from '../../../constants/MlovConst';
import {IMlov} from '../../../Interfaces';
import BaseService from '../../../services/CommonService/BaseService';
import {
  getDateStrFromFormat,
  getEndOfDay,
  getFormattedDate,
  getStartOfDay,
} from '../../../utils/DateUtils';
import {getDiffInMonths} from '../../../utils/DateUtils';
import {getMlovCodeFromId, getMlovListFromCategory, getMlovValueFromId, getMlovIdFromCode} from '../../../utils/mlovUtils';
import {
  ADDITIONAL_DATA_CODE,
  AWV_TYPE_CODES,
  CARE_MANAGEMENT_CATEGORY_CODES,
  CARE_MANAGEMENT_PROGRAM_CODES,
  CARE_PROGRAM_VIEW,
  CareProgramDateType,
} from '../CareManagementBilling/CareManagementConstants';
import { getRecordListFormatted } from '../Forms/FHFormio/CustomComponents/Allergies/AddOrUpdateAllergies/AddOrUpdateAllergyHelper';
import { IKeyPointData } from './CareManagementViewHooks/useCareProgramAdditionalData';
import {
  ICareProgramDateFilter,
  ICareProgramStepStatus,
  IDropdownOption,
  IEnrollInCareProgram,
  IFormatKeyPointDataParams,
  IFutureAppointmentData,
} from './interface';

export const getWhereConditionForCareProgram = (
  contactUuid: string,
  careProgramTypeId: string[],
  assigneeId?: string,
  statusId?: string | string[],
  selectedDateFilter?: ICareProgramDateFilter,
  locationUuids?: string[],
) => {
  const programStartDateFromDate =
    selectedDateFilter?.programStartDate?.[CareProgramDateType.FROM_DATE] &&
    getStartOfDay(
      selectedDateFilter?.programStartDate?.[CareProgramDateType.FROM_DATE]
    );
  const programStartDateToDate =
    selectedDateFilter?.programStartDate?.[CareProgramDateType.TO_DATE] &&
    getEndOfDay(
      selectedDateFilter?.programStartDate?.[CareProgramDateType.TO_DATE]
    );

  const programEndDateFromDate =
    selectedDateFilter?.programEndDate?.[CareProgramDateType.FROM_DATE] &&
    getStartOfDay(
      selectedDateFilter?.programEndDate?.[CareProgramDateType.FROM_DATE]
    );
  const programEndDateToDate =
    selectedDateFilter?.programEndDate?.[CareProgramDateType.TO_DATE] &&
    getEndOfDay(
      selectedDateFilter?.programEndDate?.[CareProgramDateType.TO_DATE]
    );

  const getStatusCondition = (status: string | string[]) => {
    if (typeof status === 'string') {
      return { _eq: status };
    }
    return { _in: status };
  };

  const dateFilters = [];

  if (programStartDateFromDate && programStartDateToDate) {
    dateFilters.push({
      startDateTime: {
        _gte: programStartDateFromDate,
        _lte: programStartDateToDate
      }
    });
  }

  if (programEndDateFromDate && programEndDateToDate) {
    dateFilters.push({
      endDateTime: {
        _gte: programEndDateFromDate,
        _lte: programEndDateToDate
      }
    });
  }

  const careProgramWhereCondition = {
    contactId: {
      _eq: contactUuid,
    },
    isDeleted: {
      _eq: false,
    },
    ...(assigneeId && {
      assigneeId: {
        _eq: assigneeId,
      },
    }),
    ...(statusId && {
      statusId: getStatusCondition(statusId),
    }),
    ...((locationUuids && locationUuids?.length) && {
      accountLocationId: {
        _in: locationUuids,
      },
    }),
    ...(dateFilters.length > 0 && {
      _and: dateFilters
    }),
    ...(careProgramTypeId.length > 0 && {
      payerCareProgram: {
        careProgramTypeId: {
          _in: careProgramTypeId,
        },
      },
    }),
  };
  return careProgramWhereCondition;
};

export const getSectionTitle = (statusCode: string) => {
  switch (statusCode) {
    case CONTACT_CARE_PROGRAM_STATUS_CODES.IN_PROGRESS:
    case CONTACT_CARE_PROGRAM_STATUS_CODES.FOLLOW_UP:
    case CONTACT_CARE_PROGRAM_STATUS_CODES.PCP_REVIEW:
    case CONTACT_CARE_PROGRAM_STATUS_CODES.ASSIGNED_TO_PCP:
      return CARE_MANAGEMENT_CATEGORY_CODES.ACTIVE;

    case CONTACT_CARE_PROGRAM_STATUS_CODES.PAUSED:
      return CARE_MANAGEMENT_CATEGORY_CODES.PAUSED;

    case CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED:
    case CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED:
    case CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED:
      return CARE_MANAGEMENT_CATEGORY_CODES.COMPLETED;

    case CONTACT_CARE_PROGRAM_STATUS_CODES.NEW:
      return CARE_MANAGEMENT_CATEGORY_CODES.NEW;
  }
};

export const getGroupedCareProgramData = (
  careProgramList: any[],
  careProgramStatusList: IMlov[]
) => {
  const updatedData: any = [];
  const sectionOrder = {
    [CARE_MANAGEMENT_CATEGORY_CODES.NEW]: 1,
    [CARE_MANAGEMENT_CATEGORY_CODES.ACTIVE]: 2,
    [CARE_MANAGEMENT_CATEGORY_CODES.PAUSED]: 3,
    [CARE_MANAGEMENT_CATEGORY_CODES.COMPLETED]: 4,
  };
  careProgramList?.forEach((item: any) => {
    const statusCode = getMlovCodeFromId(careProgramStatusList, item?.statusId);
    const sectionTitle = getSectionTitle(statusCode);
    const key = sectionTitle;
    const formattedData = {
      key: key,
      data: [item],
    };
    if (updatedData.length > 0) {
      const index = updatedData.findIndex((data: any) => data.key === key);
      if (index !== -1) {
        updatedData[index].data = [...updatedData[index].data, item];
      } else {
        updatedData.push(formattedData);
      }
    } else {
      updatedData.push(formattedData);
    }
  });
  updatedData.sort((a: any, b: any) => {
    const orderA = sectionOrder[a?.key] || Number.MAX_VALUE;
    const orderB = sectionOrder[b?.key] || Number.MAX_VALUE;
    return orderA - orderB;
  });

  return updatedData;
};

export const enrollContactCareProgram = async (data: IEnrollInCareProgram) => {
  const bodyData = {
    params: {
      careProgramTypeId: data?.careProgramTypeId,
      contactId: data?.contactId,
      eligibilityDateTime: data?.eligibilityDateTime,
      membershipYear: data?.membershipYear,
      sourceId: data?.sourceId,
      skipEligibilityCheck: true,
      skipCarePlanCreate: data?.skipCarePlanCreate || false,
      sourceTypeId: data?.sourceTypeId
    },
  };
  const baseService = BaseService.getSharedInstance().axios;
  const careProgramUrl = `carestudio-nest/contactCareProgram/assign`;
  const careProgramResponse = await baseService.post(careProgramUrl, bodyData);
  return careProgramResponse?.data;
};
export const syncDocumentToEhr = async (data: {
  careProgramStepId: string,
  contactCareProgramId: string,
  allowSyncToEhr: boolean
}) => {
  const bodyData = {
    params: data
  };
  const baseService = BaseService.getSharedInstance().axios;
  const careProgramUrl = `carestudio-nest/contactCareProgram/syncDocumentToEhr`;
  const careProgramResponse = await baseService.post(careProgramUrl, bodyData);
  return careProgramResponse?.data;
};

export const getCardMainTitle = (careProgramTypeCode: string, value: string) => {
  switch (careProgramTypeCode) {
    case CARE_PROGRAM_TYPE_CODES.CCM:
      return 'Chronic Care Management (CCM)';
    case CARE_PROGRAM_TYPE_CODES.TCM:
      return 'Transitional Care Management (TCM)';
    case CARE_PROGRAM_TYPE_CODES.ECM:
      return 'Enhanced Care Management (ECM)';
    case CARE_PROGRAM_TYPE_CODES.AWV:
      return 'Annual Wellness Visit (AWV)';
    default:
      return value;
  }
};

export const calculateProgressPercentage = (
  careProgramStepStatus: ICareProgramStepStatus,
  contactCareProgramStepsLog: any[]
) => {
  const applicableSteps = contactCareProgramStepsLog?.filter((item: any) => item?.careProgramStep?.careProgramStepType?.code !== CARE_PROGRAM_STEP_TYPE.CUSTOM);
  const totalSteps = applicableSteps?.length;
  let aggregateProgress = 0;
  applicableSteps?.forEach((item: any) => {
    switch (item?.careProgramStepStatusId) {
      case careProgramStepStatus?.done:
      case careProgramStepStatus?.skip:
      case careProgramStepStatus?.reject:
      case careProgramStepStatus?.declined:
        aggregateProgress += 1;
        break;
      default:
        aggregateProgress += 0;
        break;
    }
  });
  return Math.round((aggregateProgress / totalSteps) * 100);
};

export const getLastUpdateText = (
  careProgramActivityLogs: any,
  pcpFutureAppointmentData?: IFutureAppointmentData
) => {
  return `Last Update: ${getFormattedDate(
    careProgramActivityLogs?.timestamp,
    DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT
  )} ${pcpFutureAppointmentData?.id ? ' • ' : ''}`;
};

export const getSubTitleText = (
  pcpUserData: any,
  contactCareProgram: any,
  assigneeUserData: any
) => {
  const details: string[] = [];

  if (contactCareProgram?.startDateTime) {
    details.push(
      `Start Date: ${getFormattedDate(contactCareProgram?.startDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`
    );
  }

  if (contactCareProgram?.endDateTime) {
    details.push(
      `End Date: ${getFormattedDate(contactCareProgram?.endDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`
    );
  }
  if (pcpUserData?.userData?.name) {
    details.push(`PCP: ${pcpUserData?.userData?.name}`);
  }

  if (assigneeUserData?.name) {
    details.push(`Assigned to: ${assigneeUserData?.name}`);
  }

  return details.join(' • ');
};

export const getCareProgramStatusDisplay = (status: string, date: string, updatedByName: string): string => {
  const statusDisplayMap = {
    [CONTACT_CARE_PROGRAM_STATUS_CODES.CLOSED]: 'Closed',
    [CONTACT_CARE_PROGRAM_STATUS_CODES.AUTO_CLOSED]: 'Auto Closed',
    [CONTACT_CARE_PROGRAM_STATUS_CODES.COMPLETED]: 'Completed',
    'default': 'Last updated'
  };

  return `  ${statusDisplayMap[status] || statusDisplayMap.default} : ${ getDateStrFromFormat(date,DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)} (${updatedByName})`;
};

export  const formatKeyPointData = (params: IFormatKeyPointDataParams): IKeyPointData | null => {
  const { dataCode, data, careProgramStatusList, userMap, loggedInUserId, isAllowedToTakeActions } = params;

  if ([
    ADDITIONAL_DATA_CODE.LAST_UPDATED,
    ADDITIONAL_DATA_CODE.CLOSED,
    ADDITIONAL_DATA_CODE.AUTO_CLOSED,
    ADDITIONAL_DATA_CODE.COMPLETED
  ].includes(dataCode as ADDITIONAL_DATA_CODE)) {
    const updatedByUserId = data?.performedById;
    const isLoggedInUser = updatedByUserId === loggedInUserId;
    const updatedByName = isLoggedInUser ? 'You' : (userMap?.get(updatedByUserId) || 'Automation');
    const statusId = data?.data?.newData?.statusId;
    const statusCode = getMlovCodeFromId(careProgramStatusList, statusId as string);
    const date = data?.timestamp;

    return {
      display: getCareProgramStatusDisplay(statusCode, date, updatedByName),
      code: statusCode ? dataCode : ADDITIONAL_DATA_CODE.LAST_UPDATED,
      referenceData: data,
      view: CARE_PROGRAM_VIEW.READ_ONLY
    };
  }

  const dataCodeHandlers: Partial<Record<ADDITIONAL_DATA_CODE, () => IKeyPointData | null>> = {
    [ADDITIONAL_DATA_CODE.PATIENT_CONSENT]: () => {
      return {
        display: `  Patient Consent : ${data?.hasConsent ? 'Yes' : 'No'}`,
        code: ADDITIONAL_DATA_CODE.PATIENT_CONSENT,
        referenceData: data,
        view: CARE_PROGRAM_VIEW.POPOVER
      };
    },

    [ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS]: () => {
      const chronicConditions = data?.resources?.reduce((acc: any[], resource: any) => (
        [...acc, ...getRecordListFormatted(resource.conditions?.entry || [])]
      ), []) || [];
      if(chronicConditions.length > 0) {
        return {
          display: `  ${chronicConditions.length} Chronic Condition${chronicConditions.length !== 1 ? 's' : ''}`,
        code: ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS,
        referenceData: chronicConditions,
        view: CARE_PROGRAM_VIEW.POPOVER
        };
      }
      return null;
    },

    [ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER]: () => {
      const pcpAndCareManagerData = data?.careTeams?.[0];
      const pcpData = pcpAndCareManagerData?.careTeamMemberType?.find((careTeamMemberType: any) => careTeamMemberType?.memberType?.code === CARE_TEAM_MEMBER_TYPE.PCP);
      return pcpAndCareManagerData ? {
        display: '',
        code: ADDITIONAL_DATA_CODE.PCP_AND_CARE_MANAGER,
        referenceData: pcpData,
        view: CARE_PROGRAM_VIEW.READ_ONLY
      } : null;
    },

    [ADDITIONAL_DATA_CODE.CARE_MANAGER]: () => {
      const careManagerDataS = data?.careTeams?.[0];
      const careManagerData = careManagerDataS?.careTeamMemberType?.find((careTeamMemberType: any) => careTeamMemberType?.memberType?.code === CARE_TEAM_MEMBER_TYPE.CARE_MANAGER);
      return careManagerData ? {
        display: '',
        code: ADDITIONAL_DATA_CODE.CARE_MANAGER,
        referenceData: careManagerData,
        view: CARE_PROGRAM_VIEW.READ_ONLY
      } : null;
    },

    [ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT]: () => {
      const appointmentData = data?.appointments?.[0];
      return appointmentData ? {
        display: `  Next PCP Appointment : ${getDateStrFromFormat(appointmentData.startDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`,
        code: ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT,
        referenceData: appointmentData,
        view: CARE_PROGRAM_VIEW.PRESSABLE
      } : null;
    },

    [ADDITIONAL_DATA_CODE.COMPLEXITY]: () => {
      return data ? {
        display: `  Complexity: ${data || ''}`,
        code: ADDITIONAL_DATA_CODE.COMPLEXITY,
        referenceData: data,
        view: CARE_PROGRAM_VIEW.DROPDOWN
      } : null;
    },

    [ADDITIONAL_DATA_CODE.AWV_TYPE]: () => {
      return data ? {
      display: `  AWV Type: ${data || ''}`,
      code: ADDITIONAL_DATA_CODE.AWV_TYPE,
      referenceData: data,
        view: CARE_PROGRAM_VIEW.DROPDOWN
      } : null;
    },

    [ADDITIONAL_DATA_CODE.DISCHARGE_DATE]: () => {
      return data ? {
      display: `  Discharge Date: ${data || ''}`,
      code: ADDITIONAL_DATA_CODE.DISCHARGE_DATE,
      referenceData: data,
        view: CARE_PROGRAM_VIEW.READ_ONLY
      } : null;
    },

    [ADDITIONAL_DATA_CODE.HOSPITAL_NAME]: () => {
      return data ? {
      display: `  Hospital Name: ${data || ''}`,
      code: ADDITIONAL_DATA_CODE.HOSPITAL_NAME,
      referenceData: data,
        view: CARE_PROGRAM_VIEW.READ_ONLY
      } : null;
    },

    [ADDITIONAL_DATA_CODE.DAY_LAPSED]: () => {
      return data ? {
      display: `  Day Lapsed: ${data || ''}`,
      code: ADDITIONAL_DATA_CODE.DAY_LAPSED,
      referenceData: data,
        view: CARE_PROGRAM_VIEW.READ_ONLY
      } : null;
    },

    [ADDITIONAL_DATA_CODE.CLOSED_DATE]: () => {
      return data ? {
        display: `${data || ''}`,
        code: ADDITIONAL_DATA_CODE.CLOSED_DATE,
        referenceData: data,
        view: CARE_PROGRAM_VIEW.READ_ONLY
      } : null;
    },

    [ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE]: () => {
      const appointmentData = data?.appointments?.[0];
      return appointmentData ? {
        display: `  AWV Appointment: ${getDateStrFromFormat(appointmentData.startDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`,
        code: ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE,
        referenceData: appointmentData,
        view: CARE_PROGRAM_VIEW.PRESSABLE
      } : null;
    },

    [ADDITIONAL_DATA_CODE.CADENCE_DATE]: () => {
      const cadenceData = data;
      return {
        display: `  Upcoming Schedule ${cadenceData?.appointments?.[0]?.startDateTime ? `: ${getDateStrFromFormat(cadenceData?.appointments?.[0]?.startDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}` : ''}`,
        code: ADDITIONAL_DATA_CODE.CADENCE_DATE,
        referenceData: cadenceData,
        view: CARE_PROGRAM_VIEW.POPOVER
      }
    },
    [ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT_WITH_CONDITION]: () => {
      return data ? {
        display: `${getDateStrFromFormat(data?.appointments?.[0]?.startDateTime, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT)}`,
        code: ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT_WITH_CONDITION,
        referenceData: data,
        view: CARE_PROGRAM_VIEW.PRESSABLE
      } : null;
    },
    [ADDITIONAL_DATA_CODE.QMB_FLAG]: () => {
      return {
        display: ` QMB Flag: ${data}`,
        code: ADDITIONAL_DATA_CODE.QMB_FLAG,
        referenceData: data,
        view: CARE_PROGRAM_VIEW.DROPDOWN
      };
    },
    [ADDITIONAL_DATA_CODE.MANAGED_CHRONICS]: () => {
      return {
        display: ` ${Array.isArray(data) ? data.length : 0} Managed Conditions`,
        code: ADDITIONAL_DATA_CODE.MANAGED_CHRONICS,
        referenceData: data,
        view: CARE_PROGRAM_VIEW.POPOVER
      };
    }
  };

  return dataCodeHandlers[dataCode as ADDITIONAL_DATA_CODE]?.() || null;
};

export const getComplexityValue = (code: string, mlov: any) => {
  const complexityList = getMlovListFromCategory(mlov, MLOV_CATEGORY.CARE_PROGRAM_COMPLEXITY) || [];
  const complexityItem = complexityList?.find(item => item.code === code);
  return complexityItem?.value || code;
};

export const getAwvTypeValue = (code: string, mlov: any) => {
  const awvTypeList = getMlovListFromCategory(mlov, MLOV_CATEGORY.CARE_PROGRAM_AWV_TYPE) || [];
  const awvTypeItem = awvTypeList?.find(item => item.id === code);
  const awvTypeCode = awvTypeItem?.code;
  return AWV_TYPE_CODES[awvTypeCode as keyof typeof AWV_TYPE_CODES];
};

export const getAdditionalDataCodes = (careProgramTypeCode: string | undefined) => {
  switch (careProgramTypeCode) {
    case CARE_PROGRAM_TYPE_CODES.CCM:
      return [ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS, ADDITIONAL_DATA_CODE.MANAGED_CHRONICS, ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT, ADDITIONAL_DATA_CODE.PATIENT_CONSENT, ADDITIONAL_DATA_CODE.LAST_UPDATED, ADDITIONAL_DATA_CODE.COMPLEXITY, ADDITIONAL_DATA_CODE.CADENCE_DATE];
    case CARE_PROGRAM_TYPE_CODES.TCM:
      return [ADDITIONAL_DATA_CODE.DISCHARGE_DATE, ADDITIONAL_DATA_CODE.CLOSED_DATE, ADDITIONAL_DATA_CODE.DAY_LAPSED, ADDITIONAL_DATA_CODE.LAST_UPDATED, ADDITIONAL_DATA_CODE.COMPLEXITY, ADDITIONAL_DATA_CODE.HOSPITAL_NAME, ADDITIONAL_DATA_CODE.CADENCE_DATE,ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT];
    case CARE_PROGRAM_TYPE_CODES.AWV:
      return [ADDITIONAL_DATA_CODE.AWV_TYPE, ADDITIONAL_DATA_CODE.LAST_UPDATED,ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT, ADDITIONAL_DATA_CODE.AWV_APPOINTMENT_DATE, ADDITIONAL_DATA_CODE.CADENCE_DATE];
    case CARE_PROGRAM_TYPE_CODES.ECM:
    case CARE_PROGRAM_TYPE_CODES.CHF:
      return [ADDITIONAL_DATA_CODE.LAST_UPDATED, ADDITIONAL_DATA_CODE.PATIENT_CONSENT, ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT];
    case CARE_PROGRAM_TYPE_CODES.APCM:
      return [ADDITIONAL_DATA_CODE.CHRONIC_CONDITIONS, ADDITIONAL_DATA_CODE.MANAGED_CHRONICS, ADDITIONAL_DATA_CODE.QMB_FLAG, ADDITIONAL_DATA_CODE.PATIENT_CONSENT, ADDITIONAL_DATA_CODE.LAST_UPDATED, ADDITIONAL_DATA_CODE.CADENCE_DATE, ADDITIONAL_DATA_CODE.FUTURE_APPOINTMENT];
  }
}

export const MANAGED_CHRONICS_OPTIONS = {
  PRIMARY_CHRONIC_CONDITIONS: [
    {
      label: 'Alzheimer\'s Disease',
      value: 'alzheimersDisease'
    },
    {
      label: 'Asthma',
      value: 'asthma'
    },
    {
      label: 'Atrial Fibrillation and Flutter',
      value: 'atrialFibrillationAndFlutter'
    },
    {
      label: 'Cancer, Breast',
      value: 'cancerBreast'
    },
    {
      label: 'Cancer, Colorectal',
      value: 'cancerColorectal'
    },
    {
      label: 'Cancer, Prostate',
      value: 'cancerProstate'
    },
    {
      label: 'Chronic Kidney Disease',
      value: 'chronicKidneyDisease'
    },
    {
      label: 'COPD',
      value: 'copd'
    },
    {
      label: 'Depression, Bipolar, or Other Depressive Mood Disorders',
      value: 'depressionBipolarOrOtherDepressiveMoodDisorders'
    },
    {
      label: 'Diabetes',
      value: 'diabetes'
    },
    {
      label: 'Heart Failure and Non-Ischemic Heart Disease',
      value: 'heartFailureAndNonIschemicHeartDisease'
    },
    {
      label: 'Hyperlipidemia',
      value: 'hyperlipidemia'
    },
    {
      label: 'Hypertension',
      value: 'hypertension'
    },
    {
      label: 'Parkinson\'s Disease and Secondary Parkinsonism',
      value: 'parkinsonsDiseasAndSecondaryParkinsonism'
    },
    {
      label: 'Rheumatoid Arthritis/Osteoarthritis',
      value: 'rheumatoidArthritisOsteoarthritis'
    },
    {
      label: 'Stroke/Transient Ischemic Attack',
      value: 'strokeTransientIschemicAttack'
    },
    {
      label: 'Thyroid - Hypothyroidism',
      value: 'thyroidHypothyroidism'
    }
  ],
  OTHERS: [
    {
      label: 'Acute Myocardial Infarction',
      value: 'acuteMyocardialInfarction'
    },
    {
      label: 'Anemia',
      value: 'anemia'
    },
    {
      label: 'Benign Prostatic Hyperplasia',
      value: 'benignProstaticHyperplasia'
    },
    {
      label: 'Cancer, Endometrial',
      value: 'cancerEndometrial'
    },
    {
      label: 'Cancer, Lung',
      value: 'cancerLung'
    },
    {
      label: 'Cancer, Urologic (Kidney, Renal Pelvis, and Ureter)',
      value: 'cancerUrologic'
    },
    {
      label: 'Cataract',
      value: 'cataract'
    },
    {
      label: 'Glaucoma',
      value: 'glaucoma'
    },
    {
      label: 'Hip/Pelvic Fracture',
      value: 'hipPelvicFracture'
    },
    {
      label: 'Ischemic Heart Disease',
      value: 'ischemicHeartDisease'
    },
    {
      label: 'Non-Alzheimer\'s Dementia',
      value: 'nonAlzheimersDementia'
    },
    {
      label: 'Osteoporosis (With or Without Pathological Fracture)',
      value: 'osteoporosis'
    },
    {
      label: 'Pneumonia (All-cause)',
      value: 'pneumonia'
    },
    {
      label: 'Ankylosing Spondylitis',
      value: 'ankylosingSpondylitis'
    },
    {
      label: 'Anxiety Disorders',
      value: 'anxietyDisorders'
    },
    {
      label: 'Cancer - Others',
      value: 'cancerOthers'
    },
    {
      label: 'Celiac Disease',
      value: 'celiacDisease'
    },
    {
      label: 'Chronic Fatigue Syndrome',
      value: 'chronicFatigueSyndrome'
    },
    {
      label: 'Chronic Migraine',
      value: 'chronicMigraine'
    },
    {
      label: 'Chronic Pain',
      value: 'chronicPain'
    },
    {
      label: 'Coronary Artery Disease',
      value: 'coronaryArteryDisease'
    },
    {
      label: 'Eczema',
      value: 'eczema'
    },
    {
      label: 'Epilepsy',
      value: 'epilepsy'
    },
    {
      label: 'Gout',
      value: 'gout'
    },
    {
      label: 'HIV/AIDS',
      value: 'hivAids'
    },
    {
      label: 'Inflammatory Bowel Disease',
      value: 'inflammatoryBowelDisease'
    },
    {
      label: 'Interstitial Lung Disease',
      value: 'interstitialLungDisease'
    },
    {
      label: 'Irritable Bowel Syndrome',
      value: 'irritableBowelSyndrome'
    },
    {
      label: 'Liver Diseases',
      value: 'liverDiseases'
    },
    {
      label: 'Lupus',
      value: 'lupus'
    },
    {
      label: 'Multiple Sclerosis',
      value: 'multipleSclerosis'
    },
    {
      label: 'Obesity',
      value: 'obesity'
    },
    {
      label: 'Peripheral Artery Disease',
      value: 'peripheralArteryDisease'
    },
    {
      label: 'Polycystic Ovary Syndrome',
      value: 'polycysticOvarySyndrome'
    },
    {
      label: 'Post-Traumatic Stress Disorder',
      value: 'postTraumaticStressDisorder'
    },
    {
      label: 'Psoriasis',
      value: 'psoriasis'
    },
    {
      label: 'Sickle Cell Disease',
      value: 'sickleCellDisease'
    },
    {
      label: 'Sleep Apnea',
      value: 'sleepApnea'
    },
    {
      label: 'Thyroid - Hyperthyroidism',
      value: 'thyroidHyperthyroidism'
    },
    {
      label: 'Other',
      value: 'other'
    }
  ]
}

export const getDropDownOptions = (
  code: string,
  complexityOptions: IDropdownOption[],
  awvTypeOptions: IDropdownOption[]
) => {
  if(code === ADDITIONAL_DATA_CODE.COMPLEXITY) {
    return complexityOptions;
  } else if(code === ADDITIONAL_DATA_CODE.AWV_TYPE) {
    return awvTypeOptions;
  } else if(code === ADDITIONAL_DATA_CODE.QMB_FLAG) {
    return [{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}];
  }
  return [];
}
export const getCareProgramTypeIds = (
  activeTabCode: string,
  careProgramTypesList: IMlov[]
) => {
  const careProgramTypeCodes = [
    CARE_PROGRAM_TYPE_CODES.CCM,
    CARE_PROGRAM_TYPE_CODES.TCM,
    CARE_PROGRAM_TYPE_CODES.AWV,
  ];
  const careProgramTypeIds = [];
  if (activeTabCode?.toLowerCase() !== CARE_MANAGEMENT_PROGRAM_CODES.ALL) {
    const careProgramTypeId = getMlovIdFromCode(
      careProgramTypesList,
      activeTabCode.toUpperCase()
    );
    careProgramTypeIds.push(careProgramTypeId);
  }
  /*
  In case of ALL do not send careProgramTypeIds as filter
  else {
    careProgramTypeIds.push(
      ...careProgramTypeCodes.map((careProgramType) =>
        getMlovIdFromCode(careProgramTypesList, careProgramType)
      )
    );
  }*/
  return careProgramTypeIds;
};

export const getWhereConditionForUpcomingAppointmentData = (
  pcpUserId: string,
  contactUuid: string,
  appointmentStatusList: IMlov[],
  appointmentStartDate?: string,
  appointmentEndDate?: string,
  locationTypeCode?: string,
  visitTypeCode?: string
) => {
  const appointmentStatusCodes = [
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.DECLINED,
  ];
  const appointmentStatusIds = [] as string[];
  appointmentStatusList?.forEach((status: any) => {
    if (!appointmentStatusCodes?.includes(status?.code)) {
      appointmentStatusIds.push(status?.id);
    }
  });

  const startDate = appointmentStartDate || new Date();
  const endDate = appointmentEndDate ? new Date(appointmentEndDate) : new Date(startDate);
  !appointmentEndDate && endDate.setDate(endDate.getDate() + 30);

  const upcomingAppointmentWhereCondition: any = {
    statusId: {
      _in: appointmentStatusIds,
    },
    isDeleted: {
      _eq: false
    },
    startDateTime: {
      _gt: startDate,
    },
    endDateTime: {
      _lt: endDate,
    },
    ...(locationTypeCode && {
      locationType: {
        code: {
          _eq: locationTypeCode
        }
      }
    }),
    ...(visitTypeCode && {
      appointmentType: {
        isDeleted: {
          _eq: false,
        },
        ...(visitTypeCode === CARE_PROGRAM_TYPE_CODES.AWV ? {

          visitType: {
            code: {
              _eq: visitTypeCode,
            },
          },
        }: {
          _or: [
            {
              visitType: {
                code: {
                  _eq: visitTypeCode,
                },
              },
            },
            {
              visitTypeId: {
                _is_null: true
              }
            }
          ]
        })
      },
    }),
    _and: [
      {
        participants: {
          contactId: {
            _eq: contactUuid,
          },
          participantType: {
            code: {
              _eq: APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT,
            },
          },
          isDeleted: {
            _eq: false
          }
        },
      },
      {
        participants: {
          userId: {
            _eq: pcpUserId,
          },
          participantType: {
            code: {
              _eq: APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER,
            },
          },
          isDeleted: {
            _eq: false
          }
        },
      },
    ],
  };
  return upcomingAppointmentWhereCondition;
};


export const getWhereConditionForFutureAppointmentWithMember = (
  contactUuid: string,
  appointmentStatusList: IMlov[],
  appointmentStartDate?: string
) => {
  const appointmentStatusCodes = [
    APPOINTMENT_STATUS_CODES.CANCELLED,
    APPOINTMENT_STATUS_CODES.DECLINED,
  ];
  const appointmentStatusIds = [] as string[];
  appointmentStatusList?.forEach((status: any) => {
    if (!appointmentStatusCodes?.includes(status?.code)) {
      appointmentStatusIds.push(status?.id);
    }
  });

  const startDate = appointmentStartDate || new Date();

  const upcomingAppointmentWhereCondition: any = {
    statusId: {
      _in: appointmentStatusIds,
    },
    isDeleted: {
      _eq: false,
    },
    startDateTime: {
      _gt: startDate,
    },
    _and: [
      {
        participants: {
          contactId: {
            _eq: contactUuid,
          },
          participantType: {
            code: {
              _eq: APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT,
            },
          },
          isDeleted: {
            _eq: false,
          },
        },
      },
    ],
  };
  return upcomingAppointmentWhereCondition;
};


