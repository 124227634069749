import {View} from 'native-base';
import LockKeyHoleSvg from './Svg/LockKeyHoleSvg';
import {Colors} from '../../styles/Colors';
import {useIntl} from 'react-intl';
import {StyleProp, ViewStyle} from 'react-native';
import React from 'react';
import {replaceHashValueToString} from '../../utils/commonUtils';
import {Tooltip} from 'antd';

const LockTooltipIcon = (props: {
  name?: string;
  label?: string;
  userMarginLeft?: boolean;
  customBadgeStyles?: StyleProp<ViewStyle>;
  customTooltipWidth?: number;
  customIconSize?: number;
}) => {
  const intl = useIntl();

  const label = props?.label
    ? intl.formatMessage({id: props.label})
    : replaceHashValueToString(
        {name: props.name || ''},
        intl.formatMessage({id: 'onlyViewAccessMsg2'})
      );

  return (
    <Tooltip
      title={label}
      placement={'top'}
      style={{
        maxWidth: props?.customTooltipWidth || 300,
      }}
      overlayInnerStyle={{
        textAlign: 'center',
      }}
      showArrow={true}
    >
      <View
        style={{
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Colors.FoldPixel.GRAY50,
          borderRadius: 4,
          padding: 4,
          marginLeft: props?.userMarginLeft ? 4 : 0,
          marginRight: props?.userMarginLeft ? 0 : 4,
        }}
      >
        <LockKeyHoleSvg size={props?.customIconSize || 10} />
      </View>
    </Tooltip>
  );
};

export default React.memo(LockTooltipIcon);
