import React, {useEffect, useState} from 'react';
import {View, Text, TouchableOpacity, Pressable} from 'react-native';
import {Popover} from 'antd';
import Feather from 'react-native-vector-icons/Feather';
import {Colors} from '../../../styles';
import {
  IContact,
  IContactProfile,
} from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import {usePersonOmniViewContext} from '../PersonOmniView.context';
import {PersonOmniViewActionType} from '../PersonOmniViewReducer';
import {antDStyles, styles} from './PatientContactProfileStyle';
import {usePatientContactProfileHook} from './CustomHook/usePatientContactProfileHook';

const PatientContactProfilesView = (props: {
  contactData: IContact;
  onProfileChange?: (profile: IContactProfile) => void;
  isOpen?: boolean;
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const {data: contactContextData, dispatch} = usePersonOmniViewContext();
  const contactData = contactContextData?.id
    ? contactContextData
    : props.contactData;
  const [state, setState] = useState<{
    contactProfiles: IContactProfile[];
    selectedProfile: IContactProfile | undefined;
    loading: boolean;
  }>({
    contactProfiles: [],
    selectedProfile: undefined,
    loading: true,
  });

  const {handleInitialData} = usePatientContactProfileHook();

  const handleInitData = async () => {
    try {
      setState((prev) => {
        return {
          ...prev,
          loading: true,
        };
      });

      const {contactProfiles, selectedProfile} = await handleInitialData(
        contactData
      );
      setState((prev) => {
        return {
          ...prev,
          contactProfiles: contactProfiles,
          selectedProfile: selectedProfile,
          loading: false,
        };
      });
    } catch (error) {
      setState((prev) => {
        return {
          ...prev,
          contactProfiles: [],
          loading: false,
        };
      });
    }
  };

  const handleProfileChange = (profile: IContactProfile) => {
    if (profile?.ehrPatientId === state.selectedProfile?.ehrPatientId) {
      handleVisibleChange(false);
      return;
    }
    setState((prev) => {
      return {
        ...prev,
        selectedProfile: profile,
      };
    });
    if (dispatch) {
      dispatch({
        type: PersonOmniViewActionType.UPDATE_CONTACT_DATA,
        payload: {
          selectedProfile: profile,
        },
      });
    } else {
      props?.onProfileChange && props?.onProfileChange(profile);
    }
    handleVisibleChange(false);
  };

  useEffect(() => {
    handleInitData();
  }, [contactData?.uuid]);

  const handleVisibleChange = (visible: boolean) => {
    setIsPopoverVisible(visible);
  };

  const content = (
    <View style={styles.popoverContent}>
      {state.contactProfiles.map((profile) => (
        <Pressable
          key={profile.id}
          style={styles.profileItem}
          onPress={() => handleProfileChange(profile)}
        >
          <Feather name="map-pin" size={14} color={Colors.Custom.Gray500} />
          <Text style={styles.locationText}>
            {profile?.accountLocation?.practiceLocation?.name}
          </Text>
        </Pressable>
      ))}
    </View>
  );

  return (
    <View>
      {state.contactProfiles.length > 0 && (
        <Popover
          content={content}
          overlayClassName="contact-popover"
          trigger="click"
          open={isPopoverVisible}
          onOpenChange={handleVisibleChange}
          placement="bottomLeft"
          showArrow
          overlayInnerStyle={antDStyles.overlayInnerStyle}
        >
          <TouchableOpacity
            style={styles.profileButton}
            onPress={() => setIsPopoverVisible(!isPopoverVisible)}
          >
            <Text
              style={styles.profileTitle}
              numberOfLines={2}
              ellipsizeMode="tail"
            >
              {state.selectedProfile?.accountLocation?.practiceLocation?.name}
              {state.selectedProfile?.ehrPatientId !== contactData?.uuid && 
                `: ${state.selectedProfile?.ehrPatientId}`
              }
            </Text>
          </TouchableOpacity>
        </Popover>
      )}
    </View>
  );
};

export default React.memo(PatientContactProfilesView);
