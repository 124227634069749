import { useContactCareProgramContext } from '../ContactCareProgram.context';
import { useCareProgramStatus } from './useCareProgramStatus';

export const useCareProgramEditability = () => {
  const { careProgramStatus } = useCareProgramStatus();
  const {state} = useContactCareProgramContext();
  const {contactCareProgramDetails, userMap} = state;
  const forceReadonly = state?.forceReadonly;

  const isAllowedToTakeActions = !forceReadonly ? [
    careProgramStatus.inProgress,
    careProgramStatus.newStatus,
    careProgramStatus.assignedToPcp,
    careProgramStatus.followUp,
    careProgramStatus.pcpReview
  ].includes(contactCareProgramDetails?.statusId as string) : false;

  return {
    isAllowedToTakeActions
  };
};
