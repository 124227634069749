import { v4 as uuidV4 } from 'uuid';
import { FormElementInstance } from './FormComponents/FormComponents';
import { ICustomFormLibraryElement, LibraryElement } from './CustomFormEngineInterfaces';
import { TextFieldSchema, TextAreaSchema, EmailSchema, PhoneNumberSchema, NumberSchema, DateSchema, SelectSchema, RadioSchema, CheckboxSchema, FileSchema, ImageSchema, RatingSchema, CustomSurveySchema, CustomTableSchema, ContainerSchema, SelectBoxesSchema, ParagraphSchema, CurrencySchema, SignatureSchema, ColumnsSchema, ChiefComplaintSchema, PatientDemographicsSchema, PatientCommunicationSchema, PatientAddressSchema, EmergencyContactSchema, VitalsSchema, MedicationsSchema, AllergiesSchema, ConditionsSchema, ImmunizationsSchema, SocialHistorySchema, FamilyHistorySchema, AssessmentSchema, SurgicalHistorySchema, SubjectiveComplaintSchema, ObjectiveFindingsSchema, PlanSchema, DiagnosisSchema, ConsentAndPreferredCommunicationSchema, PastMedicalHistorySchema, NoteSubStatusSchema, NoteStatusSchema, BarriersSchema, PatientInformationVerificationSchema, ClinicalParametersSchema, CareProgramAppointmentsSchema, DischargeDetailsSchema, InterventionSchema, GoalsSchema, AWVHistorySchema } from './Schema/ComponentsSchema';
import { IEhrCapability } from '../../../../Interfaces/CommonInterfaces';
import { FORM_CATEGORY_TYPES } from '../FormsConstants';
import { cloneDeep } from 'lodash';
import { forEachExtensiveFormComponent } from '../FormBuilderWidget/AddOrUpdateForm/AddOrUpdateFormHelper';
import { componentKeys } from '../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { CARE_PLAN_FORM_COMPONENTS, HEALTH_COMPONENT_CAPABILITY_MAP } from '../FHFormio/Builder/SupportedComponents';
import { ColumnsComponent, ContainerComponent, SingleColumnComponent } from './BaseComponents/BaseComponentInterface';
import { checkIfComponentIsVisible } from '../FHFormio/Builder/CustomWebBuilder';
import { RatingType, RATING_ELEMENTS } from '../FHFormio/CustomComponents/Rating/Helper';

export const NESTED_PARENT_KEYS = ['validate', 'data', 'datePicker'];

export function getUniqueKey(map: Map<string, boolean>, base: string) {
  let newKey = base;
  const keys = newKey.split('_');
  if (map.get(newKey) || keys.length === 1) {
    newKey = `${keys[0]}_${uuidV4()}`;
  }
  return newKey;
}


export const getLibraryElements = (customComponents: {component: LibraryElement}[], metadata: {
  ehrCapabilities: IEhrCapability[],
  selectedCategory?: {id: string, code: string},
  isCareProgramEnabled: boolean
}): ICustomFormLibraryElement[] => {
  const { ehrCapabilities, selectedCategory, isCareProgramEnabled } = metadata;
  const isCarePlanCategory = selectedCategory?.code === FORM_CATEGORY_TYPES.CARE_PLAN;
  const defaultComponents = getDefaultBasicAndHealthComponents();
  const basicComponents = defaultComponents.basic;
  const healthComponents = defaultComponents.health;

  // Health Components
  const filteredHealthComponents = healthComponents.filter((component: LibraryElement) => {
    if (
      component.type === componentKeys.MEDICATIONS &&
      isCarePlanCategory
    ) {
      return true;
    }
    const capabilityKey = HEALTH_COMPONENT_CAPABILITY_MAP[component.type];
    if (capabilityKey) {
      const capabilityData = ehrCapabilities.find(
        (capability) => capability.resourceName === capabilityKey
      );
      if (
        [
          componentKeys.PATIENT_DEMOGRAPHICS,
          componentKeys.PATIENT_COMMUNICATION,
          componentKeys.EMERGENCY_CONTACT,
          componentKeys.PATIENT_ADDRESS,
        ].includes(component.type)
      ) {
        if (
          capabilityData?.abilities?.formConfigs?.canUpdateDemographics &&
          capabilityData?.isEnabled
        ) {
          return true;
        }
      } else if (
        (capabilityData?.abilities?.allowedOperations?.canCreate ||
        capabilityData?.abilities?.allowedOperations?.canUpdate) &&
        capabilityData?.isEnabled
      ) {
        return true;
      }
    } else {
      const componentKey = CARE_PLAN_FORM_COMPONENTS[component.type];
      if (componentKey)  {
        if (isCarePlanCategory) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  });

  // Custom Components
  const customComponentsCopy = cloneDeep(customComponents);
  forEachExtensiveFormComponent(customComponentsCopy, (component: FormElementInstance) => {
    component.id = uuidV4();
    component.referenceId = undefined;
    component.componentId = undefined;
    component.formComponentId = undefined;
  });
  const filteredComponents = customComponentsCopy.map((component: any) => {
    return component.component;
  });

  return [
    {
      code: 'HEALTH_COMPONENTS',
      label: 'Health Components',
      description: 'healthComponentsInfo',
      components: filteredHealthComponents,
    },
    {
      code: 'BASIC_COMPONENTS',
      label: 'Basic',
      description: '',
      components: basicComponents,
    },
    {
      code: 'CUSTOM_COMPONENTS',
      label: 'Custom',
      description: '',
      components: filteredComponents,
    },
  ];
}


export const getDefaultBasicAndHealthComponents = (): {basic: LibraryElement[], health: LibraryElement[]} => {
  return {
    basic: [
      TextFieldSchema,
      TextAreaSchema,
      EmailSchema,
      CheckboxSchema,
      RadioSchema,
      SelectBoxesSchema,
      SelectSchema,
      PhoneNumberSchema,
      DateSchema,
      ParagraphSchema,
      ImageSchema,
      NumberSchema,
      CurrencySchema,
      FileSchema,
      RatingSchema,
      SignatureSchema,
      CustomSurveySchema,
      // CustomTableSchema,
      ColumnsSchema,
      ContainerSchema,
    ],
    health: [
      ChiefComplaintSchema,
      PatientDemographicsSchema,
      PatientCommunicationSchema,
      PatientAddressSchema,
      EmergencyContactSchema,
      VitalsSchema,
      MedicationsSchema,
      AllergiesSchema,
      ConditionsSchema,
      ImmunizationsSchema,
      SocialHistorySchema,
      FamilyHistorySchema,
      SurgicalHistorySchema,
      SubjectiveComplaintSchema,
      ObjectiveFindingsSchema,
      AssessmentSchema,
      PlanSchema,
      DiagnosisSchema,
      PastMedicalHistorySchema,
      ConsentAndPreferredCommunicationSchema,
      NoteSubStatusSchema,
      NoteStatusSchema,
      BarriersSchema,
      GoalsSchema,
      InterventionSchema,
      DischargeDetailsSchema,
      CareProgramAppointmentsSchema,
      AWVHistorySchema,
      ClinicalParametersSchema,
      PatientInformationVerificationSchema
    ],
  }
}

export const findElementRecursively = (elements: FormElementInstance[], targetId: string): FormElementInstance | null => {
  for (const element of elements) {
    if (element.id === targetId) {
      return element;
    }
    if ((element as ColumnsComponent).columns && (element as ColumnsComponent).columns.length > 0) {
      for (const column of (element as ColumnsComponent).columns) {
        if (column.id === targetId) {
          return column;
        }
        const found = findElementRecursively(column.components, targetId);
        if (found) return found;
      }
    }
    if ((element as ContainerComponent).components?.length) {
      const found = findElementRecursively((element as ContainerComponent).components, targetId);
      if (found) return found;
    }
  }
  return null;
};

export const findElementAndParentRecursively = (
  elements: FormElementInstance[],
  targetId: string,
  parent: FormElementInstance | null = null
): {
  element: FormElementInstance;
  parent: FormElementInstance | null;
} | null => {
  for (const element of elements) {
    if (element.id === targetId) {
      return { element, parent };
    }

    // Check columns
    if ((element as ColumnsComponent).columns?.length > 0) {
      for (const column of (element as ColumnsComponent).columns) {
        if (column.id === targetId) {
          return { element: column, parent: element };
        }
        const found = findElementAndParentRecursively(column.components, targetId, element);
        if (found) return found;
      }
    }

    // Check nested components
    if ((element as ContainerComponent).components?.length) {
      const found = findElementAndParentRecursively((element as ContainerComponent).components, targetId, element);
      if (found) return found;
    }
  }
  return null;
};

export const findElementIndexAndParentRecursively = (
  elements: FormElementInstance[],
  targetId: string,
  parent: FormElementInstance | null = null
): {
  element: FormElementInstance;
  parent: FormElementInstance | null;
  index: number;
} | null => {
  // Check direct children
  const directIndex = elements.findIndex(element => element.id === targetId);
  if (directIndex !== -1) {
    return {
      element: elements[directIndex],
      parent,
      index: directIndex
    };
  }

  // Recursively check each element
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    // Check columns
    if ((element as ColumnsComponent).columns?.length > 0) {
      for (let colIndex = 0; colIndex < (element as ColumnsComponent).columns.length; colIndex++) {
        const column = (element as ColumnsComponent).columns[colIndex];
        if (column.id === targetId) {
          return {
            element: column,
            parent: element,
            index: colIndex
          };
        }
        const found = findElementIndexAndParentRecursively(column.components, targetId, column);
        if (found) return found;
      }
    }

    // Check nested components
    if ((element as ContainerComponent).components?.length) {
      const found = findElementIndexAndParentRecursively(
        (element as ContainerComponent).components,
        targetId,
        element
      );
      if (found) return found;
    }
  }

  return null;
};

export const getAllElementKeys = (elements: FormElementInstance[]): Map<string, boolean> => {
  const keys: Map<string, boolean> = new Map();

  const extractKeys = (elements: FormElementInstance[]) => {
    for (const element of elements) {
      // Add the key if it exists
      if (element.key) {
        keys.set(element.key, true);
      }

      // Check columns
      if ((element as ColumnsComponent).columns?.length > 0) {
        for (const column of (element as ColumnsComponent).columns) {
          if (column.key) {
            keys.set(column.key, true);
          }
          extractKeys(column.components);
        }
      }

      // Check nested components
      if ((element as ContainerComponent).components?.length) {
        extractKeys((element as ContainerComponent).components);
      }
    }
  };

  extractKeys(elements);
  return keys;
};

export const getBuilderVisibleComponents = (
  components: FormElementInstance[],
  selectedFormData: Record<string, any>,
  builderComponents?: FormElementInstance[]
): FormElementInstance[] => {
  if (!components || components.length === 0) {
    return [];
  }

  return components.filter((component) => {
    if (component.hidden) {
      return false;
    }

    if (component.customVisibility) {
      const isVisible = component.customVisibility(selectedFormData, {
        components,
        builderComponents,
      });
      if (!isVisible) {
        return false;
      }
    }

    const isVisible = checkIfComponentIsVisible(component, selectedFormData);
    if (!isVisible) {
      return false;
    }

    if ((component as ColumnsComponent).columns?.length > 0) {
      (component as ColumnsComponent).columns = getBuilderVisibleComponents(
        (component as ColumnsComponent).columns,
        selectedFormData,
        builderComponents
      ) as SingleColumnComponent[];
      if ((component as ColumnsComponent).columns.length === 0) {
        return false;
      }
    }

    if ((component as ContainerComponent).components?.length > 0) {
      (component as ContainerComponent).components =
        getBuilderVisibleComponents(
          (component as ContainerComponent).components,
          selectedFormData,
          builderComponents
        );
      if ((component as ContainerComponent).components.length === 0) {
        return false;
      }
    }

    return true;
  });
};

export const convertDotNotationToObject = (dotNotation: string, value: any): any => {
  const keys = dotNotation.split('.');
  const result: any = {};
  let current = result;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    current[key] = i === keys.length - 1 ? value : {};
    current = current[key];
  }

  return result;
};

export const convertDotNotationToObjectForProperties = (data: Record<string, any>): Record<string, any> => {
  const result = Object.entries(data).reduce((acc, [key, value]) => {
    const parts = key.split('.');
    const rootKey = parts[0];

    if (!rootKey) return acc;

    // Handle non-dotted keys
    if (!key.includes('.')) {
      acc[rootKey] = value;
      return acc;
    }

    // Handle special keys
    if (NESTED_PARENT_KEYS.includes(parts[0])) {
      acc[parts[0]] = acc[parts[0]] || {};
      acc[parts[0]][parts[1]] = value;
      return acc;
    }

    // Handle other nested properties
    const nestedObject = convertDotNotationToObject(key, value);
    acc[rootKey] = {
      ...(acc[rootKey] || {}),
      ...nestedObject[rootKey],
    };

    return acc;
  }, {} as Record<string, any>);
  if (data.type === 'columns' && result.numberOfColumns) {
    result.columns = Array.from({length: Number(result.numberOfColumns)}, (_, index) => ({
      size: 'md',
      width: 6,
      components: result.columns?.[index]?.components ||[],
    }));
  }
  if (result.type === 'rating') {
    result.values = getDefaultValues(result);
  }
  if (result.type === 'checkbox' && !result.values) {
    result.values = getDefaultValues(result);
  }
  return result;
};

export const convertObjectToDotNotation = (obj: Record<string, any>, components: (Omit<FormElementInstance, 'id'> & {id?: string})[]): Record<string, any> => {
  const result: Record<string, any> = {};
  if (obj.type === 'columns') {
    if (!obj.columns.length) {
      obj.columns = [{size: 'md', width: 6, components: []}, {size: 'md', width: 6, components: []}];
    }
    obj.numberOfColumns = String(obj.columns.length);
  }
  const mergedInitialValues = mergeInitialValuesAnsComponents(obj, components);

  for (const key in mergedInitialValues) {
    if (Object.prototype.hasOwnProperty.call(mergedInitialValues, key)) {
      const value = mergedInitialValues[key];
      const newKey = key;

      if (NESTED_PARENT_KEYS.includes(key) && typeof value === 'object') {
        // Handle validation properties separately
        for (const validateKey in value) {
          if (Object.prototype.hasOwnProperty.call(value, validateKey)) {
            result[`${newKey}.${validateKey}`] = value[validateKey];
          }
        }
      } else {
        result[newKey] = value;
      }
    }
  }

  return result;
};

export function parseFileSize(size: string): number {
  const units = {
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  };
  const match = size.match(/^(\d+)(KB|MB|GB)$/);
  if (!match) return 0;
  const [, num, unit] = match;
  return Number(num) * units[unit as keyof typeof units];
}

export const mergeInitialValuesAnsComponents = (
  initialValues: Record<string, any>,
  components: (Omit<FormElementInstance, 'id'> & {id?: string})[]
) => {
  const mergedValues = {...initialValues};
  components.forEach((component) => {
    if (component.key && component.defaultValue && !mergedValues.hasOwnProperty(component.key)) {
      mergedValues[component.key] = component.defaultValue;
    }
  });
  return mergedValues;
};

export const setInitialValues = (obj: Record<string, any>, components: (Omit<FormElementInstance, 'id'> & {id?: string})[]) => {
  const mergedInitialValues = mergeInitialValuesAnsComponents(obj, components);
  if ((mergedInitialValues.type === 'rating' || mergedInitialValues.type === 'checkbox') && !mergedInitialValues.values) {
    mergedInitialValues.values = getDefaultValues(mergedInitialValues);
  }
  return mergedInitialValues;
}

export const getDefaultValues = (obj: Record<string, any>) => {
  switch (obj.type) {
    case 'checkbox':
      return [
        {
        label: 'Checked',
        value: true,
      },
      {
        label: 'Unchecked',
        value: false,
        },
      ];
    case 'rating':
      const isNPSScale = obj?.ratingType === RatingType.nps;
      const scale = isNPSScale ? 11 : (obj?.ratingScale || 10);
      const list = [...Array(scale).keys()];
      const existingOptions = obj?.values || [];
      const suffix = RATING_ELEMENTS.find(item => item.value === obj?.ratingType)?.label;
      const finalList = list.map((item) => {
        const value = isNPSScale ? item : (item + 1);
        const option = existingOptions.find((item: any) => `${item.value}` === `${value}`);
          return {
            ...option,
            ...((!option?.riskScore && option?.riskScore !== 0) && { riskScore: value }),
            ...((!option?.groupRiskScore && option?.groupRiskScore !== 0) && { groupRiskScore: value }),
            label: `${value}${suffix ? ` ${suffix}` : ''}`,
            value: `${value}`,
          }
      });
      return finalList;
    default:
      return [];
  }
}

export const convertObjectToArray = (selectedItems: Record<string | number, boolean>): any[] => {
  if (!selectedItems || Object.keys(selectedItems).length === 0) return [];

  return Object.entries(selectedItems)
    .reduce<any[]>((acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    }, []);
};
