import React, { useEffect } from 'react';
import {useIntl} from 'react-intl';
import {
  IFormCommonData,
  IFormComponentProps,
} from '../CustomWrapper/CustomWrapper';
import {useContext} from 'react';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {Collapse, Drawer, Tooltip, Popover, Button} from 'antd';
import {Divider, FlatList, HStack, Text, VStack, View} from 'native-base';
import {
  FormError,
  componentKeys,
  tooltipInnerOverlayStyle,
} from '../CustomWrapper/CustomComponentHelper';
import {RightOutlined} from '@ant-design/icons';
import PamiIconSvg from '../../../../../common/Svg/PamiIconSvg';
import FormComponentLoader from '../CustomWrapper/FormComponentLoader/FormComponentLoader';
import FormComponentError from '../CustomWrapper/FormComponentError/FormComponentError';
import {
  getLastRecordedDataMapKey,
  getLastRecordedDate,
  getLatestValue,
  getUnitForExternalVital,
  getFilteredGraphList,
  checkWearableSettingExist,
  getIconBasedOnVital
} from './utils';
import {VitalGraphData, DataType, GraphDataOperation} from './interface'
import {ListRenderItem, Pressable} from 'react-native';
import {Colors} from '../../../../../../styles';
import TrendView from './components/TrendView';
import {BUTTON_TYPE} from '../../../../../../constants';
import {ModalActionTitle} from '../../../../../common/ModalActionTitle/ModalActionTitle';
import Feather from 'react-native-vector-icons/Feather';
import useHomeMonitoring from './useHomeMonitoring';
import HomeMonitoringDrillDownView from './components/HomeMonitoringDrillDownView';
import {EmptyStateSvg} from '../../../../../common/Svg';
import Stack from '../../../../../common/LayoutComponents/Stack';
import { HomeMonitoringSettingsView } from './HomeMonitoringSettingsView';
import SettingsIconSvg from '../../../../../common/Svg/SettingsIconSvg';
import { FoldButton } from '../../../../../CommonComponents/FoldButton/FoldButton';
import { IWearableDataFilterParams, IWearableSetting, IWearableSettingListItem } from './interface';
import { ClinicalSectionFilterTabs } from '../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionFilterTabs/ClinicalSectionFilterTabs';
import { CINICAL_SECTIONS_FILTER_TAB_CODES, CLINICAL_SECTION_VIEW_TYPES, HOME_MONITORING_FILTER_TABS } from '../../../../../PatientOmniView/Sections/ClinicalSection/ClinicalSectionConstants';
import { NoPinnedWidgetView } from './NoPinnedWidgetView';
import { NoDataFoundView } from './components/NoDataFoundView';
import CancelButtonSvg from '../../../../../common/Svg/PersonActionSvgIcons/CancelButtonSvg';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CreateOrUpdateUserPreference, GetUserPreferencesByTypeId } from '../../../../../../services/UserPreferences/UserPreferencesQueries';
import { getMlovId } from '../../../../../../utils/mlovUtils';
import PinnedWearableIntroSvg from '../../../../../common/Svg/PinnedWearableIntroSvg';
import { reactStyles, styles } from '../../../formStyle';
import { getAccountId } from '../../../../../../utils/commonUtils';
const {Panel} = Collapse;
import './HomeMonitoringView.css'
import { antdStyles } from '../../../../Contacts/TeamMembers/InviteUserDrawer/InviteUserDrawerStyles';

interface ISideCareHomeMonitoringViewProps {
  component: {
    label: string;
  };
  patientId: string;
  locationId: string;
  defaultCollapse: boolean;
  ccmDate?: string;
  contactId: string;
  isSidecar?: boolean;
  goals?: any;
  contactIntId?: string | number;
}

const HomeMonitoringView = (
  props: ISideCareHomeMonitoringViewProps | IFormComponentProps
) => {
  const intl = useIntl();
  const formContextData = useContext(CommonDataContext) as IFormCommonData;
  const componentProps = props as ISideCareHomeMonitoringViewProps;
  const patientId = componentProps.patientId || formContextData.patientId;
  const locationId = componentProps.locationId || formContextData.locationId;
  const defaultCollapse =
    componentProps.defaultCollapse || formContextData.defaultCollapse;
  const ccmDate = componentProps.ccmDate || formContextData.ccmDate;
  const contactId = componentProps.contactId || formContextData.contactId;
  const isSidecarContext = componentProps.isSidecar || false;
  const CARE_STUDIO_MLOV = formContextData.CARE_STUDIO_MLOV;
  const userData = formContextData.userData

  const userPreferencesTypeId = getMlovId(CARE_STUDIO_MLOV, 'UserPreferencesType', 'user_home_monitoring');
  const userUuid = userData?.uuid;
  const accountId = getAccountId();

  const {componentRef, componentState, onChangeComponentState, onSaveSettingsClick, allowedWearableList} =
    useHomeMonitoring({
      isTrendsView: true,
      patientId,
      locationId,
      defaultCollapse,
      ccmDate,
      accountId: accountId,
      contactId: componentProps.contactIntId
    });

  const bioMarkers = componentState.graphList.filter(
    (item) => item.dataType === DataType.VITAL && item.graphData.length > 0
  );

  const activity = componentState.graphList.filter(
    (item) => item.dataType === DataType.ACTIVITY && item.graphData.length > 0
  );

  const sumAllData = (data: any) => {
    return data.reduce((acc: number, curr: any) => {
      return acc + curr.y;
    }, 0);
  };

  const [addUpdateUserFilterPreferenceAPI] = useMutation(
    CreateOrUpdateUserPreference,
    {context: {service: CARESTUDIO_APOLLO_CONTEXT}}
  );

  const [getUserTaskPreferencesByTypeId] = useLazyQuery(
    GetUserPreferencesByTypeId,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(()=> {
    getUserPreferences();
  }, [])

  const getUserPreferences = async () => {
    onChangeComponentState({
      preferenceLoading: true,
    });
    try {
      const userPreferenceResponse = await getUserTaskPreferencesByTypeId({
        variables: {
          userId: userUuid,
          userPreferencesTypeId: userPreferencesTypeId,
        },
      });
      const data = userPreferenceResponse?.data;
      if (
        data?.userPreferences?.length > 0 &&
        data?.userPreferences?.[0]?.preferencesJson
      ) {
        const preferenceData = data?.userPreferences?.[0];
        const pref = preferenceData?.preferencesJson
          ? JSON.parse(preferenceData?.preferencesJson)
          : {};

        if (pref.hasOwnProperty('homeMonitoringIntroTooltip') && pref.homeMonitoringIntroTooltip === false) {
          onChangeComponentState({
            preferenceLoading: false,
            showPinnedIntro: false
          });
        } else {
          onChangeComponentState({
            preferenceLoading: false,
            showPinnedIntro: true
          });
        }
      }
      else {
        onChangeComponentState({
          showPinnedIntro: true,
          preferenceLoading: false,
        });
      }
    } catch (error) {
      onChangeComponentState({
        preferenceLoading: false,
      });
    }
  }

  const renderItem = (
    params: {
      item: VitalGraphData;
    },
    isLastItem: boolean
  ) => {
    const isLowEvent =
      params.item.dataOperation === GraphDataOperation.LOW_EVENT;
    const key = getLastRecordedDataMapKey({
      code: params.item.code,
      dataOperation: params.item.dataOperation,
    });
    const lastRecordedData = componentState.lastRecordedDateMap.get(key);
    const highlightValue = isLowEvent
      ? sumAllData(params.item.graphData)
      : lastRecordedData?.value === 'NaN'
      ? '--'
      : lastRecordedData?.value;
    const unit = isLowEvent
      ? `times`
      : getUnitForExternalVital(
          params?.item?.code,
          params.item.dataType === DataType.ACTIVITY
        );
    const lastRecorded = params.item.graphData.length
      ? lastRecordedData?.date
      : undefined;
    const Icon = getIconBasedOnVital(params?.item?.code);
    const title = params.item.title;
    const valueType = isLowEvent ? 'This week' : 'Latest';
    const isGraphDataAvailable = params?.item?.graphData?.length > 0;

    return (
      <HStack
        p={2}
        flex={1}
        borderWidth={0.5}
        mb={2}
        rounded={'lg'}
        borderColor={Colors.Custom.Gray200}
        alignItems={'center'}
        mr={isSidecarContext ? 1 : 0}
        maxW={isSidecarContext && isLastItem ? '49.5%' : '100%'}
      >
        <VStack flex={isGraphDataAvailable ? 0.6 : 1}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY300} selectable={false}>
            <Icon strokeColor={isGraphDataAvailable ? Colors.Custom.Primary300 : Colors.Custom.Gray400}/>
            {` ${title}`}
            {isLowEvent && isGraphDataAvailable && (
              <Tooltip
                title={
                  'Blood glucose levels below 70 mg/dl qualify as low glucose events.'
                }
                placement="top"
                showArrow={false}
                overlayStyle={{paddingTop: '12px'}}
                overlayInnerStyle={tooltipInnerOverlayStyle}
              >
                <Feather
                  name="info"
                  style={styles.marginLeft2}
                  color={Colors.Custom.Gray900}
                />
              </Tooltip>
            )}
          </Text>
          {!isGraphDataAvailable && (
            <Text
              size={'xsNormal'}
              color={Colors.FoldPixel.GRAY250}
              marginTop={1}
              selectable={false}
            >
              {'No Recorded Data for last 7 days'}
            </Text>
          )}
          <VStack>
           {isGraphDataAvailable && <VStack>
              <Text color={Colors.Custom.Gray400} fontSize={12}>
                {valueType}
              </Text>
              <Text>
                {!!highlightValue && (
                  <Text
                    color={Colors.Custom.Gray900}
                    selectable={false}
                    fontSize={18}
                  >
                    {highlightValue}
                    <Text
                      color={Colors.Custom.Gray500}
                      fontSize={12}
                      paddingLeft={2}
                      paddingTop={2}
                    >
                      {unit}
                    </Text>
                  </Text>
                )}
              </Text>
              {!!lastRecorded && (
                <Text color={Colors.Custom.Gray400} fontSize={12}>
                  {`${intl.formatMessage({
                    id: 'lastRecordedOn',
                  })}: ${lastRecorded}`}{' '}
                  {params.item.source ? ` • ${params.item.source} ` : ''}
                </Text>
              )}
            </VStack>
          }
          </VStack>
        </VStack>
        {isGraphDataAvailable && <TrendView vitalGraph={params.item} />}
      </HStack>
    );
  };

  const getPinnedTabView = () => {

    const isPinnedIntroVisible =
      componentState.showPinnedIntro && !componentState.loading && componentState.activePanels.includes(componentKeys.HOME_MONITORING);

    return (
      <Stack direction="row" style={styles.stackStyles}>
        <View>
          <ClinicalSectionFilterTabs
            tabs={HOME_MONITORING_FILTER_TABS}
            selectedTabCode={componentState.selectedFilterTab}
            onTabClick={(tab: string) => {
              onChangeComponentState({
                selectedFilterTab: tab,
                showSettingsView: false,
                updatedWearableSetting: componentState.wearableSetting
              });
            }}
          />
        </View>
        <>
          {componentState.showSettingsView ? (
            <FoldButton
              nativeProps={{
                borderRadius: 4,
                isLoading: componentState.showSavingIndicator,
                height: 30,
                width: componentState.showSavingIndicator ? '80px' : '60px',
                background: Colors.Custom.Primary300,
                onPress: () => {
                  onSaveSettingsClick();
                },
                colorScheme: BUTTON_TYPE.PRIMARY,
              }}
              customProps={{
                btnText: intl.formatMessage({
                  id: 'save',
                }),
                withRightBorder: false,
              }}
            />
          ) : (
            <Stack direction='row' style={styles.positionRelative}>
              <Popover
                overlayInnerStyle={{padding: 0, borderRadius: 12, marginTop: 4}}
                open={isPinnedIntroVisible}
                trigger='click'
                content={
                  <Stack direction='column' style={styles.flex1}>
                    <Stack direction='row' style={styles.justifyContentFlexEndFlex1}>
                      <Pressable onPress={()=> {
                         onChangeComponentState({
                          showPinnedIntro: false,
                        });
                        updatePinnedWidgetPreference();
                      }}>
                        <CancelButtonSvg />
                    </Pressable>

                    </Stack>
                    <PinnedWearableIntroSvg/>
                    <View justifyContent={"center"} alignItems={"center"} marginBottom={1} marginTop={1}>
                      <Text>Pin your widgets from here</Text>
                    </View>
                  </Stack>
                }
              placement="bottomLeft"
            >
              <Pressable
                style={styles.width40AlignItemsCenter}
                onPress={() => {
                  onChangeComponentState({
                    showSettingsView: true,
                  });
                }}
              >
                <SettingsIconSvg />
              </Pressable>
              </Popover>
            </Stack>
          )}
        </>
      </Stack>
    );
  };

  const updatePinnedWidgetPreference = async () => {
    const preferenceData = {homeMonitoringIntroTooltip: false}
    const addUpdateFilterPreferenceObject: any = {
      userId: userUuid,
      userPreferencesTypeId: userPreferencesTypeId,
      preferencesJson: JSON.stringify(preferenceData),
      preferencesModelVersion: 'v1',
    };
     addUpdateUserFilterPreferenceAPI({
      variables: {
        object: addUpdateFilterPreferenceObject,
      }
    });
  }

  const renderListView = () => {

    if (
      componentState.selectedFilterTab ===
        CINICAL_SECTIONS_FILTER_TAB_CODES.RELEVANT &&
      !checkWearableSettingExist(componentState.wearableSetting, allowedWearableList)
    ) {
      return <NoPinnedWidgetView/>
    }

    const filterParams: IWearableDataFilterParams = {
      graphList: componentState.graphList,
      filterDataType: DataType.VITAL,
      selectedFilterTab: componentState.selectedFilterTab,
      wearableSetting: componentState.wearableSetting,
      filterEmptyGraphData: false
    }
    const bioMarkers = getFilteredGraphList(filterParams)

    const activity = getFilteredGraphList({...filterParams, filterDataType: DataType.ACTIVITY})

    if (componentState.loading || componentState.pinnedWearableConfigLoading) {
      return (
        <View padding={4}>
          <FormComponentLoader />
        </View>
      );
    }

    if(bioMarkers.length === 0 && activity.length === 0) {
      return (
        <NoDataFoundView/>
      );
    }

    return (
      <>
        {bioMarkers.length > 0 && (
          <View
            style={styles.paddingHorizontal8}
          >
            <Text
              color={Colors.FoldPixel.GRAY400}
              size={'xsMedium'}
              fontWeight={400}
              paddingY={2}
            >
              {intl.formatMessage({id: 'biomarkers'}).toUpperCase()}
            </Text>
            <FlatList
              numColumns={isSidecarContext ? 2 : 1}
              data={bioMarkers}
              ListEmptyComponent={<NoDataFoundView/>}
              renderItem={(params) =>
                renderItem(
                  params,
                  params.index === bioMarkers.length - 1
                )
              }
            />
          </View>
        )}
        {activity.length > 0 && (
          <View
            style={styles.paddingHorizontal8PaddingTop2}
          >
            <Text
              color={Colors.FoldPixel.GRAY400}
              size={'xsMedium'}
              fontWeight={400}
              paddingY={2}
            >
              {intl.formatMessage({id: 'activity'}).toUpperCase()}
            </Text>
            <FlatList
              numColumns={isSidecarContext ? 2 : 1}
              data={activity}
              ListEmptyComponent={<NoDataFoundView/>}
              renderItem={(params) =>
                renderItem(
                  params,
                  params.index === activity.length - 1
                )
              }
            />
          </View>
        )}
      </>
    );
  }


  const getDetailView = (isGraph?: boolean) => {
    if (isGraph) {
      return (
        <HomeMonitoringDrillDownView
          patientId={patientId}
          locationId={locationId}
          ccmDate={ccmDate}
          contactId={contactId}
          goals={componentProps?.goals}
          contactIntId={componentProps?.contactIntId}
        />
      );
    }

    return (
      <Stack direction="column">
        {getPinnedTabView()}
        {componentState?.showSettingsView ? (
          <HomeMonitoringSettingsView
            existingWearableSetting={componentState.updatedWearableSetting}
            onListUpdate={(wearableSetting: IWearableSetting)=> {
              onChangeComponentState({
                updatedWearableSetting: wearableSetting,
              });
            }}
          />
        ) : (
          renderListView()
        )}
      </Stack>
    );
  }


  const getCollapsedView = (children: JSX.Element) => {
    if (isSidecarContext) {
      return (
        <View
          style={styles.collapsedView}
          backgroundColor={'white'}
        >
          <Stack
            direction="row"
            style={styles.stack}
          >
            <View flex={1} flexDir={'row'} alignItems={'center'}>
              <PamiIconSvg />
              <Text
                color={'#0e0333'}
                fontSize={20}
                fontWeight={600}
                fontFamily={'Manrope-Bold'}
                marginLeft={3}
              >
                {intl.formatMessage({id: 'homeMonitoring'})}
              </Text>
            </View>
          </Stack>
          <Divider />
          <View padding={5}>
            {children}
          </View>

        </View>
      );
    }

    return (
      <Collapse
        activeKey={
          isSidecarContext
            ? [componentKeys.HOME_MONITORING]
            : componentState.activePanels
        }
        onChange={(activePanels) => {
          onChangeComponentState({activePanels: activePanels as string[]});
        }}
        expandIconPosition="end"
        expandIcon={({isActive}) => {
          const tooltipText = isActive
            ? intl.formatMessage({id: 'collapse'})
            : intl.formatMessage({id: 'expand'});
          return (
            <Tooltip
              title={tooltipText}
              placement="bottom"
              showArrow={false}
              overlayStyle={{paddingTop: '12px'}}
              overlayInnerStyle={tooltipInnerOverlayStyle}
            >
              <RightOutlined rotate={isActive ? 90 : 0} />
            </Tooltip>
          );
        }}        
        className='home-monitoring-collapse'
        style={antdStyles.collapse}
      >
        <Panel
          forceRender
          key={componentKeys.HOME_MONITORING}
          header={
            <HStack
              paddingRight={4}
              justifyContent="space-between"
              alignContent={'center'}
            >
              <HStack alignItems={'center'} space={2} marginTop={0}>
                <Text size={'lgMedium'}
                      color={Colors.FoldPixel.GRAY300}
                      fontWeight={500}>
                  {props.component.label}
                </Text>
              </HStack>
              <Pressable
                style={styles.pressable}
                onPress={() => {
                  onChangeComponentState({showGraphs: true});
                }}
              >
                <Feather
                  name="bar-chart-2"
                  size={20}
                  color={Colors.Custom.foldIconColor}
                />
              </Pressable>
            </HStack>
          }
        >
          {children}
        </Panel>
      </Collapse>
    );
  };

  return (
    <View ref={componentRef}>
      {getCollapsedView(
        <>
          {componentState.loading && (
            <View padding={4}>
              <FormComponentLoader />
            </View>
          )}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.customError && (
            <FormComponentError
              error={FormError.custom}
              customError={componentState.customError}
            />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(isSidecarContext)}
        </>
      )}
      {!isSidecarContext && componentState.showGraphs && (
        <Drawer
          mask={isSidecarContext ? false : true}
          width={isSidecarContext ? '100%' : '35%'}
          destroyOnClose
          closable
          open={componentState.showGraphs}
          onClose={() => {
            onChangeComponentState({showGraphs: false});
          }}
          title={
            <ModalActionTitle
              isHeadNotSticky
              title={props.component.label}
              buttonList={[
                {
                  onClick: () => {
                    onChangeComponentState({
                      showGraphs: false,
                    });
                  },
                  show: true,
                  id: 1,
                  btnText: intl.formatMessage({
                    id: isSidecarContext ? 'back' : 'close',
                  }),
                  size: 'sm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                },
              ]}
            />
          }
        >
          {getDetailView(true)}
        </Drawer>
      )}
    </View>
  );
};

export default HomeMonitoringView;
