import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'antd';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import InputField from '../../BaseComponents/InputField';
import { debounce } from 'lodash';

interface SortableRowProps {
  id: string;
  components: Array<{
    label: string;
    key: string;
    input: boolean;
    type: string;
    width?: string | number;
    validation?: {
      required?: boolean;
      pattern?: RegExp;
      message?: string;
    };
  }>;
  rowData: Record<string, string>;
  onDelete: () => void;
  onChange: (key: string, value: string) => void;
  testID?: string;
}

export function SortableRow({
  id,
  components,
  rowData,
  onDelete,
  onChange,
  testID = 'sortable-row'
}: SortableRowProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex items-center gap-2 bg-white p-2 rounded border"
      data-testid={testID}
    >
      <div {...attributes} {...listeners} className="cursor-move">
        <MenuOutlined />
      </div>
      <div className="flex-1 flex gap-4">
        {components.map((component, index) => (
          <div
            key={component.key}
            className="flex-1"
            style={{ width: component.width || 'auto' }}
          >
            {component.input ? (
              <InputField
                autoFocus={index === components.length - 1 && !rowData[component.key]}
                placeholder={component.label}
                key={component.key}
                defaultValue={rowData[component.key] || ''}
                onChange={debounce((event) => onChange(component.key, event.target.value), 500)}
              />
            ) : (
              <div
                className="w-full p-1"
                data-testid={`${testID}-text-${component.key}`}
              >
                {rowData[component.key] || ''}
              </div>
            )}
          </div>
        ))}
      </div>
      <Button
        type="text"
        icon={<DeleteOutlined />}
        onClick={onDelete}
        danger
        data-testid={`${testID}-delete`}
      />
    </div>
  );
}
