import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import { IEhrCapability } from '../../../../../Interfaces';
import {ICON_LIB} from '../../../../common/CircleIconView/CircleIconView';
import {CARE_PLAN_FORM_COMPONENTS, HEALTH_COMPONENT_CAPABILITY_MAP} from '../../FHFormio/Builder/SupportedComponents';
import {IFormMetaData} from '../RiskScoreConfiguration/RiskScoreConfiguration';
import {FIELD_TYPES_WITH_RISK_SCORE} from '../RiskScoreConfiguration/RiskScoreHelper';
import { componentKeys } from '../../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import {IFormLocation} from './AddOrUpdateForm';

export const forEachFormComponent = (
  components: any[],
  callback: (component: any) => void
) => {
  components.forEach((component: any) => {
    const currentComponent = component.component || component;
    callback(currentComponent);
    if (currentComponent.columns && currentComponent.columns.length > 0) {
      currentComponent.columns.forEach((column: any) => {
        forEachFormComponent(column.components, callback);
      });
    }
    if (currentComponent.components && currentComponent.components.length > 0) {
      forEachFormComponent(currentComponent.components, callback);
    }
  });
};

export const forEachExtensiveFormComponent = (
  components: any[],
  callback: (component: any) => void
) => {
  components?.forEach((component: any) => {
    const currentComponent = component.component || component;
    callback(currentComponent);
    if (currentComponent.columns && currentComponent.columns.length > 0) {
      currentComponent.columns.forEach((column: any) => {
        callback(column);
        forEachExtensiveFormComponent(column.components, callback);
      });
    }
    if (currentComponent.components && currentComponent.components.length > 0) {
      forEachExtensiveFormComponent(currentComponent.components, callback);
    }
  });
};

export const getFlatListedFormComponents = (components: any[]): any[] => {
  let flatMap: any[] = [];
  components.forEach((component: any) => {
    const currentComponent = component.component || component;
    flatMap.push(currentComponent);
    if (currentComponent.columns && currentComponent.columns.length > 0) {
      currentComponent.columns.forEach((column: any) => {
        flatMap.push(column);
        const columnComponents = getFlatListedFormComponents(column.components);
        flatMap = [...flatMap, ...columnComponents];
      });
    }
    if (currentComponent.components && currentComponent.components.length > 0) {
      const panelComponents = getFlatListedFormComponents(
        currentComponent.components
      );
      flatMap = [...flatMap, ...panelComponents];
    }
  });
  return flatMap;
};

export function addCustomComponentsInBuilderConfig(
  components: any[],
  builderConfig: any
): any {
  const customComponentObject: any = {};
  const customComponents = _.cloneDeep(components);
  // If component already has an id then new id is not generated again
  // If id is not removed then if the same component is added multiple times, it throws an error of same id
  forEachExtensiveFormComponent(customComponents, (component) => {
    component.id = undefined;
    component.referenceId = undefined;
    component.componentId = undefined;
    component.formComponentId = undefined;
  });
  const healthComponentsObject = builderConfig.healthComponents.components;
  const tempIds: string[] = [];
  customComponents.forEach((component: any) => {
    const key = component.name + '_' + component.id;
    component.component.isSearchable = false;

    const componentInfo = {
      title: component?.component?.name
        ? component.component.name
        : component.name,
      key,
      icon: 'puzzle-piece',
      schema: component.component,
      iconLib: ICON_LIB.FontAwesomeIcon,
    };
    if (tempIds.includes(component.id)) {
      healthComponentsObject[key] = componentInfo;
    } else {
      customComponentObject[key] = componentInfo;
    }
  });

  builderConfig.customComponents = {
    title: 'Custom',
    weight: 2,
    components: customComponentObject,
  };
  builderConfig.healthComponents = {
    title: 'Health components',
    weight: 0,
    components: healthComponentsObject,
  };

  return builderConfig;
}

export const removeDuplicateComponents = (
  components: any[],
  componentIds: Map<string,boolean>
): string[] => {
  return components.filter((field) => {
    const uniqueId = field.id;
    if (componentIds.has(uniqueId)) {
      return false;
    }
    componentIds.set(field.id, true);
    if (field.columns && field.columns.length > 0) {
      field.columns.forEach((column: any) => {
        if (column.components?.length) {
          column.components = removeDuplicateComponents(column.components, componentIds);
        }
      });
    }
    if (field.components && field.components.length > 0) {
      field.components = removeDuplicateComponents(field.components, componentIds);
    }
    return true;
  });
};

export const getUpdateComponentsAfterChange = (components: any[]) => {
  updateComponentsAfterChange(components);
};

const updateComponentsAfterChange = (components: any[]) => {
  components.forEach((field) => {
    if (!field.componentId && !field.referenceId) {
      field.referenceId = uuidv4();
    }
    if (field.columns && field.columns.length > 0) {
      field.columns.forEach((column: any) => {
        updateComponentsAfterChange(column.components);
      });
    }
    if (field.components && field.components.length > 0) {
      updateComponentsAfterChange(field.components);
    }
  });
};

export const getUpdateMetadataAfterChange = (
  components: any[],
  formMetadata: IFormMetaData
): IFormMetaData => {
  const componentIds = getRiskScoreComponentIds(
    components,
    FIELD_TYPES_WITH_RISK_SCORE
  );
  if (formMetadata?.metadata && formMetadata.metadata.length > 0) {
    for (const metadata of formMetadata.metadata) {
      metadata.fields = metadata.fields.filter((field) => {
        if (field.componentId) {
          return componentIds.includes(field.componentId);
        }
        return true;
      });
    }

    formMetadata.metadata = formMetadata.metadata?.filter((metadata) => {
      if (metadata?.fields?.length) {
        return true;
      }
    });
  }
  return formMetadata;
};

const getRiskScoreComponentIds = (
  components: any[],
  types: string[]
): string[] => {
  const fieldsWithRiskScore: string[] = [];
  components.forEach((field) => {
    if (types.includes(field.type)) {
      if (!field.componentId && !field.referenceId) {
        field.referenceId = uuidv4();
      }
      const fieldId = field.componentId || field.referenceId;
      (field.values || field.data?.values)?.forEach((option: any) => {
        if (!option.riskScore) {
          option.riskScore = 0;
        }
        if (!option?.groupRiskScore) {
          option.groupRiskScore = 0;
        }
      });
      fieldsWithRiskScore.push(fieldId);
    }
    if (field.columns && field.columns.length > 0) {
      field.columns.forEach((column: any) => {
        const fields = getRiskScoreComponentIds(column.components, types);
        fieldsWithRiskScore.push(...fields);
      });
    }
    if (field.components && field.components.length > 0) {
      const fields = getRiskScoreComponentIds(field.components, types);
      fieldsWithRiskScore.push(...fields);
    }
  });
  return fieldsWithRiskScore;
};

export function updateBuilderComponentsBasedOnCapabilities(
  builderConfig: any,
  ehrCapabilities: IEhrCapability[],
  config: {
    isCareProgramEnabled: boolean;
    isCarePlanCategorySelected: boolean;
    isDisplayPatientInformationComponent: boolean;
  }
): any {
  const healthComponentsObject = builderConfig.healthComponents.components;
  const updateHealthComponents: any = {};

  Object.keys(healthComponentsObject).forEach((key) => {
    const component = healthComponentsObject[key];

    if (
      key === componentKeys.MEDICATIONS &&
      config.isCarePlanCategorySelected
    ) {
      updateHealthComponents[key] = component;
      return;
    }

    const capabilityKey = HEALTH_COMPONENT_CAPABILITY_MAP[key];
    if (capabilityKey) {
      const capabilityData = ehrCapabilities.find(
        (capability) => capability.resourceName === capabilityKey
      );
      if (
        [
          'patientDemographics',
          'patientCommunication',
          'emergencyContact',
          'patientAddress',
        ].includes(key)
      ) {
        if (
          capabilityData?.abilities?.formConfigs?.canUpdateDemographics &&
          capabilityData?.isEnabled
        ) {
          updateHealthComponents[key] = component;
        }
      } else if (
        (capabilityData?.abilities?.allowedOperations?.canCreate ||
        capabilityData?.abilities?.allowedOperations?.canUpdate) &&
        capabilityData?.isEnabled
      ) {
        updateHealthComponents[key] = component;
      }
    } else {
      const componentKey = CARE_PLAN_FORM_COMPONENTS[key];
      if (componentKey)  {
        if (!!config.isCareProgramEnabled && !!config.isCarePlanCategorySelected) {
          updateHealthComponents[key] = component;
        }
      } else if (key === 'patientInformationVerification') {
        if (config.isDisplayPatientInformationComponent) {
          updateHealthComponents[key] = component;
        }
      } else {
        updateHealthComponents[key] = component;
      }
    }
  });

  builderConfig.healthComponents = {
    title: 'Health components',
    weight: 0,
    components: updateHealthComponents,
  };

  return builderConfig;
}

export const isSubmitButtonComponent = (component: any): boolean => {
  return component.key.includes('submit') && component.type === 'button';
}


export const processFormLocations = (existingLocations: IFormLocation[], newLocations: IFormLocation[]) => {
  const finalFormLocations: IFormLocation[] = [];

  const newLocationIdsMap = new Map<string, boolean>();
  newLocations.forEach((location) => {
    newLocationIdsMap.set(location.locationId, true);
  });



  // Add new locations
  finalFormLocations.push(...newLocations);

  // Remove existing locations which are not in the new list with isDeleted set to true
  existingLocations.forEach((location) => {
    if (!newLocationIdsMap.has(location.locationId)) {
      finalFormLocations.push({
        locationId: location.locationId,
        isDeleted: true,
        id: location.id,
      });
    }
  });

  return finalFormLocations;
};
export const isConditionalComponentRender = (component: any, components: any[]): boolean => {
  const requiredComponentValue = component.conditional?.eq;
  const requiredComponentKey = component.conditional?.when;
  const findComponent = (components || []).find((component: any) => component.key === requiredComponentKey);
  const selectedValue = findComponent?.selectedValue || '';
  return requiredComponentValue === selectedValue;
}
export const getFlatListedNonHiddenFormComponents = (components: any[], flatListComponents: any[], flatMap: any[]): any[] => {
  (components || []).forEach((component: any) => {
    const conditional = component?.conditional;
    let isComponentExistInDom = false;
    if (!!conditional?.eq && !!conditional.when) {
      isComponentExistInDom = isConditionalComponentRender(component, flatListComponents);
    } else {
      isComponentExistInDom = !component?.hidden;
    }
    if (isComponentExistInDom) {
      flatMap.push(component);
      if ((component.columns && component.columns.length > 0) || (component.components && component.components.length > 0)) {
        getFlatListedNonHiddenFormComponents(component.columns || component.components, flatListComponents, flatMap);
      }
    }
  });
  // TODO: remove this after fixing the issue with conditional components
  // components.forEach((component: any) => {
  //   const currentComponent = component.component || component;
  //   const conditional = currentComponent?.conditional;
  //   let isComponentExistInDom = false;
  //   if (!!conditional?.eq && !!conditional.when) {
  //     isComponentExistInDom = isConditionalComponentRender(currentComponent, flatListComponents);
  //   } else {
  //     isComponentExistInDom = !currentComponent?.hidden;
  //   }
  //   if (isComponentExistInDom) {
  //     flatMap.push(currentComponent);
  //     if (currentComponent.columns && currentComponent.columns.length > 0) {
  //       currentComponent.columns.forEach((column: any) => {
  //         flatMap.push(column);
  //         const columnComponents = getFlatListedFormComponents(column.components);
  //         flatMap = [...flatMap, ...columnComponents];
  //       });
  //     }
  //     if (currentComponent.components && currentComponent.components.length > 0) {
  //       const panelComponents = getFlatListedFormComponents(
  //         currentComponent.components
  //       );
  //       flatMap = [...flatMap, ...panelComponents];
  //     }
  //   }
  // });
  return flatMap;
};
