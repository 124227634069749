import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ElementsType, FormElement, FormElementInstance, SubmitFunction, ValidationFunction, ValidationResult } from '../FormComponents/FormComponents';
import { getUniqueKey } from '../CustomFormEngineUtils';
import { VitalsSchema } from '../Schema/ComponentsSchema';
import { cloneDeep, debounce } from 'lodash';
import Description from '../BaseComponents/Description';
import AddOrUpdateVitals from '../../FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitals';
import { useFormRendererContext } from '../Context/FormRenderer.context';
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from '../CustomFormEngineInterfaces';
import { useCustomFormBuilderContext } from '../Context/CustomFormBuilder.context';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import QuillConfig from '../../FHFormio/Builder/QuillConfig';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import { usePropertiesFormRenderer } from '../Hooks/usePropertiesFormRenderer';
import { FormRenderer } from '../FormRenderer';
import { CapabilityResource } from '../../FHFormio/CustomComponents/CustomWrapper/CustomComponentHelper';
import { IEhrCapability } from '../../../../../Interfaces';
import { getDisplay } from '../../../../../utils/VitalUtils';
import { getEnabledVitals } from '../../../../../utils/VitalUtils';
import { IVitalsComponentValue } from '../../FHFormio/CustomComponents/Vitals/interfaces';
import VitalsSvg from '../../../../../assets/Icons/FormBuilder/VitalsSvg';
import { v4 as uuidV4 } from 'uuid';

export const VitalsFieldFormElement: FormElement = {
  type: VitalsSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(VitalsSchema),
    key: getUniqueKey(map, VitalsSchema.key),
  }),
  designerBtnElement: {
    icon: VitalsSvg,
    label: VitalsSchema.label || '',
  },
  propertiesComponent: PropertiesComponent,
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(VitalsSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
};

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  disabled,
  validationRef,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: IVitalsComponentValue;
  onChange: (value: IVitalsComponentValue) => void;
  errorMessage?: string;
  disabled?: boolean;
  validationRef?: React.MutableRefObject<any>;
}) {
  const { label, validate, description, tooltip, hideLabel } = elementInstance;

  return (
    <div className="flex flex-col gap-1 w-full">
      {/* {!hideLabel && (
        <Label
          label={label || ''}
          isRequired={validate?.required}
          tooltip={tooltip}
          isReadOnly={disabled}
        />
      )} */}
      <AddOrUpdateVitals
        component={{
          ...elementInstance,
          selectedValue: defaultValue,
        }}
        disabled={disabled || false}
        options={{}}
        validateRef={validationRef || {}}
        setValueRef={{}}
        value={defaultValue}
        onChange={onChange}
      />
      {description && <Description description={description} />}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const [value, setValue] = useState<IVitalsComponentValue>();
  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={setValue}
    />
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: IVitalsComponentValue;
  isReadOnly?: boolean;
  onRegisterValidation?: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<IVitalsComponentValue | undefined>(defaultValue || selectedValue);
  const validationRef = useRef<any>(undefined);
  const isMounted = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(async (valueToValidate?: IVitalsComponentValue, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      const result = await validationRef.current?.(valueToValidate, silentCheck);
      return {
        isValid: result?.isValid ?? true,
        errorMessage: result?.message || '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key]);

  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: IVitalsComponentValue) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: IVitalsComponentValue) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      disabled={isReadOnly}
      validationRef={validationRef}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, ehrCapabilities, userSettings } = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance;

  const options = useMemo(() => {
    const capabilities: IEhrCapability[] = ehrCapabilities || [];
    const capability = capabilities.find(
      (capability) =>
        capability.resourceName == CapabilityResource.observation
    );
    let vitals = capability?.abilities?.allowedVitalList || [];
    vitals = vitals.filter((vital) => !vital.isHidden);
    const enabledVitals = getEnabledVitals(capability);
    const finalList = enabledVitals.map((vital) => {
      return {
        label: getDisplay(vital, vitals),
        value: vital,
      };
    });
    return finalList;
  }, [ehrCapabilities]);

  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    ...(isAllowShare ? ShareWithPatientFields : []),
    {
      type: 'checkBoxes',
      key: 'enabledVitals',
      label: 'Select Vitals Fields',
      input: true,
      validate: {
        required: true,
      },
      dataSrc: 'values',
      data: {
        values: options,
      },
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [options, isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData, element]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default VitalsFieldFormElement;
