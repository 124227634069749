import React from "react";
import { DiagnosticReport, MedicationRequest, RequestGroup, ServiceRequest, Specimen } from "fhir/r4";
import { Divider, HStack, Text, VStack } from 'native-base';
import { DATE_FORMATS } from "../../../../../../constants";
import { getDateStrFromFormat } from "../../../../../../utils/DateUtils";
import { EXTENSION_URLS } from "../../../PatientNotes/components/AddOrUpdateTemplate/constant";
import { getExtensionValue, getMedicationOrderName, getMedicationStatusDisplay, getOrderDateTime, getPrescriptionStatusDisplay, getStatusDisplay } from "../OrderUtils";
import { REPORT_TYPE, getDocumentData, getEffectiveDateOfReport, getInternalNote, getPatientNote, getReportObservationDateTime } from "../../../../LeftContainer/RecentActivity/RecentReport/ReportUtils";
import { IReportAndResourceMap } from "../../../../LeftContainer/RecentActivity/OrdersInterface";
import { View, StyleSheet } from "react-native";
import { Colors } from "../../../../../../styles";
import Stack from "../../../../../common/LayoutComponents/Stack";
import { FHIR_RESOURCE } from "../../../../../../constants/FhirConstant";
import { SOURCE_TYPES } from "../../../../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/FeedConst";
import { getImagingReportLabelsFromExtension } from "../../../../../RightSideContainer/Integration/FhirUtils";

const styles = StyleSheet.create({
  orderContainer: {
    borderRadius: 8,
    borderWidth: 0.5,
    borderColor: Colors.Custom.Gray400,
  },
  detailsContainer: {
    paddingHorizontal: 12,
    paddingTop: 4,
    paddingBottom: 12,
  },
  headerContainer: {
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 4,
  },
  individualOrderContainer: {
    padding: 3,
  },
  individualOrdersContainer: {
    marginBottom: 4,
  },
  reportButtonSeparator: {
    width: 1,
    height: 20,
    backgroundColor: Colors.Custom.Gray200,
    marginHorizontal: 8,
  },
});

export const getOrderDisplayItems = (params: {
  order?: RequestGroup;
  report?: DiagnosticReport;
  action?: any;
  prescription?: MedicationRequest;
  documentTitle?: string;
}): JSX.Element => {
  const isSingleOrder = params.action || (params.order?.action?.length || 0) === 1 || !params.order;
  const headerElement = isSingleOrder ? getHeaderElement(params) : <></>;
  const individualOrdersDetails = isSingleOrder ? <></> : getIndividualOrderDetails(params);
  const details = getDetails({...params, isSingleOrder: isSingleOrder});
  return (
    <VStack
      space={2}
      style={styles.orderContainer}
    >
      {headerElement}
      <Divider backgroundColor={Colors.Custom.Gray400} height={'0.5px'}/>
      {individualOrdersDetails}
      <VStack
        space={1}
        style={styles.detailsContainer}
      >
        {details.map((data) => {
          return (
            <HStack key={data.key} alignItems="baseline">
              <Text flex={1} fontSize="xs" color={'gray.500'}>{data.key}</Text>
              <Text flex={3} fontSize="sm" fontWeight={'medium'}>{data.value}</Text>
            </HStack>
          );
        })}
      </VStack>
    </VStack>
  );
}

export const getHieReportName = (resourceData: any) => {
  let reportName = '';
  if (resourceData.resourceType === FHIR_RESOURCE.DIAGNOSTIC_REPORT && resourceData?.source == SOURCE_TYPES.HIE) {
    const coding = resourceData?.code?.coding || [];
    coding.some((itemCode: any) => {
      if (!itemCode?.code || itemCode?.code !== 'UNK' && itemCode?.display) {
        reportName = itemCode?.display;
      }
    });
  }
  return reportName;
}

const getHeaderElement = (params: {
  order?: RequestGroup;
  report?: any;
  action?: any;
  prescription?: MedicationRequest;
  documentTitle?: string;
}) => {
  const {order, report, action, prescription} = params;
  let orderName = '';
  if (prescription) {
    orderName = getMedicationOrderName(prescription);
  }
  if (action?.resource?.display) {
    orderName = action.resource.display;
  }
  if (!orderName && order?.action?.length) {
    const orders: string[] = [];
    order.action.forEach((item) => item.resource?.display && orders.push(item.resource.display));
    orderName = orders.join(', ');
  }
  if (!orderName && report?.code) {
    if (report?.code?.coding?.length) {
      if (report?.source == SOURCE_TYPES.HIE) {
        orderName = getHieReportName(report);
      }
      if (!orderName) {
        orderName = report?.code?.coding[0]?.display || '';
      }
    }
    if (!orderName) {
      orderName = report?.code?.text || '';
    }
    if (!orderName) {
      orderName = params?.documentTitle || '';
    }
  }
  return (
    <View style={styles.headerContainer}>
      <Text fontSize="lg" fontWeight={'bold'}>
        {orderName}
      </Text>
    </View>
  );
}

const getDetails = (params: {
  order?: RequestGroup;
  report?: DiagnosticReport;
  action?: any;
  prescription?: MedicationRequest;
  isSingleOrder: boolean;
}): {key: string, value: string}[] => {
  if (params.prescription) {
    return getMedDetails(params.prescription)
  } else {
    return getLabRadDetails(params);
  }
}

const getIndividualOrderDetails = (params: {
  order?: RequestGroup;
  report?: DiagnosticReport;
  action?: any;
  prescription?: MedicationRequest;
}): JSX.Element => {
  if (params.prescription) {
    return <></>;
  } else {
    const actions = params.action ? [params.action] : params.order?.action || [];
    const ordersView: JSX.Element[] = [];
    actions.forEach((action) => {
      const details = getIndividualLabRadDetails({order: params.order, action: action});
      const view = (
        <VStack space={1} padding={3}>
          <Text fontSize='lg' fontWeight={'bold'}>{action.resource?.display}</Text>
          {details.map((data) => {
            return (
              <HStack key={data.key} alignItems="baseline">
                <Text flex={1} fontSize="xs" color={'gray.500'}>{data.key}</Text>
                <Text flex={3} fontSize="sm" fontWeight={'medium'}>{data.value}</Text>
              </HStack>
            )
          })}
        </VStack>
      )
      ordersView.push(view);
    });
    return (
      <VStack space={4} marginBottom={4}>
        {ordersView.map((view) => {
          return view;
        })}
        <Divider />
      </VStack>
    );
  }
}

const getMedDetails = (order: MedicationRequest) => {
  const details: {key: string, value: string}[] = [];
  const enteredBy = order?.requester?.display;

  if (order.dispenseRequest) {
    const dispenseRequest = order.dispenseRequest;
    if (dispenseRequest.quantity?.value) {
      let dispenseDisplay = `${dispenseRequest.quantity.value}`
      if (dispenseRequest.quantity?.unit) {
        dispenseDisplay += ` ${dispenseRequest.quantity.unit}`
      }
      details.push({key: 'Dispense', value: dispenseDisplay});
    }
    if (dispenseRequest.expectedSupplyDuration?.value) {
      details.push({key: 'Supply', value: `${dispenseRequest.expectedSupplyDuration?.value} day(s)`});
    }
    if (dispenseRequest.numberOfRepeatsAllowed) {
      details.push({key: 'Refills', value: `${dispenseRequest.numberOfRepeatsAllowed}`});
    }

    if (dispenseRequest.validityPeriod?.start) {
      const date = getDateStrFromFormat(dispenseRequest.validityPeriod.start, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
      details.push({key: 'Effective from', value: date});
    }
  }

  if (order.dosageInstruction?.length) {
    const notes: string[] = [];
    order.dosageInstruction.forEach((instruction) => {
      if (instruction.text) {
        notes.push(instruction.text);
      }
    })
    details.push({key: 'Sig', value: notes.join('\n')});
  }

  const status = getExtensionValue(order, EXTENSION_URLS.externalStatus) || order.status;
  if (status) {
    details.push({key: 'Prescription Status', value: getPrescriptionStatusDisplay(status)});
  }
  const medStatus = getExtensionValue(order, EXTENSION_URLS.medicationStatus) || order.status;
  if (medStatus) {
    details.push({key: 'Medications Status', value: getMedicationStatusDisplay(medStatus)});
  }

  if (enteredBy) {
    let display = enteredBy;
    if (order.authoredOn) {
      const date = getDateStrFromFormat(order.authoredOn, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
      display += ` on ${date}`
    }
    details.push({key: 'Prescribed By', value: display});
  } else if (order.authoredOn) {
    const date = getDateStrFromFormat(order.authoredOn, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
    details.push({key: 'Prescribed On', value: date});
  }

  if (order.note?.length) {
    const note = order.note.map((note) => note.text).join('\n');
    details.push({key: 'Note', value: note});
  }

  const note = getExtensionValue(order, EXTENSION_URLS.pharmacyNote);
  if (note) {
    details.push({key: 'Pharmacy Note', value: note});
  }

  return details;
}

const getLabRadDetails = (params: {
  order?: RequestGroup;
  report?: DiagnosticReport;
  action?: any;
  isSingleOrder: boolean;
}) => {
  const {order, report, isSingleOrder} = params;
  let details: {key: string, value: string}[] = [];
  const actions = params.action ? [params.action] : (order?.action || []);
  if(report?.id) {
    details.push({key: 'Report Id', value: report?.id});
  }
  if (order?.status) {
    details.push({key: 'Order Status', value: getStatusDisplay(order.status)});
  }
  if (report?.status) {
    details.push({key: 'Report Status', value: getStatusDisplay(report.status)});
  }
  if (order?.priority) {
    details.push({key: 'Priority', value: getStatusDisplay(order.priority)});
  }
  if (report) {
    const reportDetails = getReportExtensionDetails(report);
    details = details.concat(reportDetails);
  }
  if (order && isSingleOrder) {
    const questionnaireDetails = getQuestionnaireDetails(order, actions);
    details = details.concat(questionnaireDetails);
  }
  if (order && isSingleOrder) {
    const individualOrderNote = getContainedNotes(order, actions);
    if (individualOrderNote) {
      details.push({key: 'Test Notes', value: individualOrderNote});
    }
  }
  if (order?.note?.length) {
    const notes = order?.note?.map((item) => item.text).join(', ');
    details.push({key: 'Order Notes', value: notes});
  }
  if (order) {
    const orderDate = getOrderDateTime(order);
    if (order?.author?.display) {
      let orderedBy = order.author.display;
      if (orderDate) {
        orderedBy += '  on  ' + orderDate;
      }
      details.push({key: 'Ordered By', value: orderedBy});
    } else if (orderDate) {
      details.push({key: 'Ordered On', value: orderDate});
    }
  }
  if (report) {
    const issuedDate = getEffectiveDateOfReport(report);
    if (issuedDate) {
      const reportDate = getDateStrFromFormat(issuedDate, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
      details.push({key: 'Report Issued On', value: reportDate});
    }
  }
  if (order?.subject?.display) {
    details.push({key: 'Ordered For', value: order.subject.display});
  }
  if (order) {
    const specimenDetails = getSpecimenDetails(order);
    if (specimenDetails.length) {
      details = details.concat(specimenDetails);
    }

    const facilityDetails = getFacilityDetails(order);
    if (facilityDetails.length) {
      details = details.concat(facilityDetails);
    }
  }
  if (order?.reasonCode?.length) {
    const diagnosisItems = order.reasonCode.map((reasonCode) => reasonCode.text || reasonCode.coding?.[0]?.display);
    const diagnosis = diagnosisItems.filter((item) => !!item).join(', ');
    details.push({key: 'Diagnosis', value: diagnosis});
  }
  const imagingReportLabel = getImagingReportLabelsFromExtension(
    report?.extension
  );
  if (imagingReportLabel) {
    details.push({key: 'Report Label', value: imagingReportLabel});
  }
  return details;
}

const getIndividualLabRadDetails = (params: {
  action: any;
  order?: RequestGroup;
}) => {
  const {order} = params;
  let details: {key: string, value: string}[] = [];
  const action = params.action;
  if (order) {
    const containedPriority = getContainedPriority(order, action);
    if (containedPriority) {
      details.push({key: 'Priority', value: getStatusDisplay(containedPriority)});
    }
  }
  if (order) {
    const questionnaireDetails = getQuestionnaireDetails(order, [action]);
    details = details.concat(questionnaireDetails);
  }
  if (order) {
    const individualOrderNote = getContainedNotes(order, [action]);
    if (individualOrderNote) {
      details.push({key: 'Test Notes', value: individualOrderNote});
    }
  }
  return details;
}

export const getContainedPriority = (data: RequestGroup, action: any) => {
  if (!action) {
    return '';
  }
  const id = action?.resource?.reference;
  if (id) {
    const serviceRequestId = id.split('/')[1];
    const serviceRequestData: any = data.contained?.find((item: any) => item.id === serviceRequestId);
    return serviceRequestData?.priority || '';
  }
  return '';
}

export const getContainedNotes = (data: RequestGroup, actions: any[]) => {
  if (!actions.length) {
    return '';
  }
  const finalNote: string[] = [];
  actions.forEach((action) => {
    const id = action?.resource?.reference;
    if (id) {
      const serviceRequestId = id.split('/')[1];
      const noteList = data.contained?.map((item: any) => {
        if(item.id === serviceRequestId && item.note){
          return  item.note?.map((note: any) => note.text);
        }
      });
      const note = noteList?.filter((item) => !!item).join(', ');
      if (note) {
        finalNote.push(note);
      }
    }
  })
  return finalNote.join('\n');
}

export const getQuestionnaireDetails = (data: RequestGroup, actions: any[]) => {
  if (!actions.length) {
    return [];
  }
  const details: {key: string, value: string}[] = [];
  actions.forEach((action) => {
    const id = action?.resource?.reference;
    if (id) {
      const serviceRequestId = id.split('/')[1];
      const serviceRequest = data.contained?.find((item) => item.id === serviceRequestId) as ServiceRequest;
      if (serviceRequest?.contained?.length) {
        const questionnaire: any = serviceRequest.contained.find((item) => item.resourceType === 'QuestionnaireResponse');
        if (questionnaire?.item?.length) {
          questionnaire.item.forEach((question: any) => {
            const answer = question.answer?.[0];
            const value = answer?.valueString || answer?.valueCoding?.display || '-';
            details.push({ key: question.text, value: value});
          })
        }
      }
    }
  });
  return details;
}

export const getSpecimenDetails = (order: RequestGroup) => {
  const statusExtension = order.extension?.find((item) => item.url === EXTENSION_URLS.specimen);
  const details: {key: string, value: string}[] = [];
  if (statusExtension?.valueReference?.reference) {
    const referenceValue = statusExtension?.valueReference?.reference.replace('#', '');
    const findContained: Specimen = (order?.contained || []).find(contained => {
      return contained?.id === referenceValue;
    }) as Specimen;

    const collectionDate = findContained?.collection?.collectedDateTime;
    if (findContained?.subject?.display) {
      let collectedBy = findContained.subject.display;
      if (collectionDate) {
        const collectedOn = getDateStrFromFormat(collectionDate, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
        collectedBy += '  on  ' + collectedOn;
      }
      details.push({key: 'Specimen Collected By', value: collectedBy});
    } else if (collectionDate) {
      const collectedOn = getDateStrFromFormat(collectionDate, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT);
      details.push({key: 'Specimen Collected On', value: collectedOn});
    }
  }
  return details;
}

export const getFacilityDetails = (order?: RequestGroup, report?: DiagnosticReport) => {
  const extension = order?.extension?.find((item) => item.url === EXTENSION_URLS.performer || item.url === EXTENSION_URLS.requestGroupPerformer);
  if (extension?.valueReference?.display) {
    return [{key: 'Facility', value: extension.valueReference.display}];
  }
  if (report?.performer?.length && report?.performer?.[0]?.display) {
    return [{key: 'Facility', value: report?.performer?.[0]?.display}];
  }
  return [];
}

export const getReportExtensionDetails = (report: DiagnosticReport) => {
  const details: {key: string, value: string}[] = [];
  const priority = report?.extension?.find((item) => item.url === EXTENSION_URLS.diagnosticReportingPriority);
  if (priority?.valueCodeableConcept) {
    const value = priority.valueCodeableConcept.text || priority.valueCodeableConcept.coding?.[0]?.display || '-';
    details.push({key: 'Reporting Priority', value: value});
  }

  const interpretation = report?.extension?.find((item) => item.url === EXTENSION_URLS.diagnosticInterpretation);
  if (interpretation?.valueCodeableConcept) {
    const value = interpretation.valueCodeableConcept.text || interpretation.valueCodeableConcept.coding?.[0]?.display || '-';
    details.push({key: 'Interpretation', value: value});
  } else if (report?.conclusion) {
    details.push({key: 'Interpretation', value: report?.conclusion});
  }
  const reportObservationDateTime = getReportObservationDateTime(
    report?.extension
  );
  if (reportObservationDateTime) {
    const observationDateTimeToDisplay = getDateStrFromFormat(reportObservationDateTime, DATE_FORMATS.DIAGNOSTIC_REPORT_DATE_FORMAT)
    details.push({
      key: 'Observation Date Time',
      value: observationDateTimeToDisplay,
    });
  }
  const internalNote = getInternalNote(report?.extension);
  if (internalNote) {
    details.push({
      key: 'Internal Note',
      value: internalNote,
    });
  }
  const patientNote = getPatientNote(report?.extension);
  if (patientNote) {
    details.push({
      key: 'Patient Note',
      value: patientNote,
    });
  }

  const documentData = getDocumentData(report?.extension);
  if (documentData) {
    details.push({
      key: 'Document Data',
      value: documentData,
    });
  }

  return details;
}


export const renderReportButtonForImaging = (
  report: IReportAndResourceMap,
  key: string,
  getReportButton: (
    report: IReportAndResourceMap,
    reportType: string,
    key: string
  ) => JSX.Element
): JSX.Element => {
  switch (report?.type) {
    case REPORT_TYPE.BOTH:
      return (
        <Stack direction="row">
          {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key)}
          <View style={styles.reportButtonSeparator}></View>
          {getReportButton(report, REPORT_TYPE.RESULT, key)}
        </Stack>
      );
    case REPORT_TYPE.MEDIA_REPORT:
      return (
        <Stack direction="row">
          {getReportButton(report, REPORT_TYPE.MEDIA_REPORT, key)}
        </Stack>
      );
    case REPORT_TYPE.RESULT:
      return (
        <Stack direction="row">
          {getReportButton(report, REPORT_TYPE.RESULT, key)}
        </Stack>
      );
    case REPORT_TYPE.FOLD_MEDIA_REPORT:
      return (
        <Stack direction="row">
          {getReportButton(report, REPORT_TYPE.FOLD_MEDIA_REPORT, key)}
        </Stack>
      );
  }
  return <></>;
};
