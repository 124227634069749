import {StyleSheet} from 'react-native';
import { Colors } from '../../../../../styles/Colors';

export const styles = StyleSheet.create({
  titleContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formContainer: {
    flex: 1,
    marginBottom: 12,
  },
  labelContainer: {
    marginBottom: 4,
    marginTop: 4,
  },
  editorFooterRight: { 
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 10
  },
  attachmentButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.Custom.mainPrimaryPurple,
    padding: 4,
    borderRadius: 6,
  },
  attachmentButtonText: {
    color: 'white',
    marginHorizontal: 4,
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14.4,
  },
  defaultOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mergeTagPopoverView: {
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  readonlyLineContainer:{marginTop: 10},
  readPillContainer: {
    backgroundColor: Colors.Custom.Gray300,
    padding: 2.5,
    paddingHorizontal: 5,
    marginRight: 5,
    borderRadius: 5,
    marginTop:10
  },
  readonlyContainer: {paddingHorizontal: 24, paddingBottom: 24},
  templateCard: {
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 6,
    shadowOpacity: 0.26,
    elevation: 8,
    backgroundColor: 'white',
    padding: 12,
    borderRadius: 12,
  },
});
