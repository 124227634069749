import {FHIR_RESOURCE} from '../../../../../constants/FhirConstant';
import {
  formatConditionsRequestResourceObj,
  formatReconciliationConditionData,
  getConditionCodeInvalidField,
  updateHieConditionData,
  updateHieConditionMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Conditions/AddOrUpdateConditions/AddOrUpdateConditionsHelper';
import {
  ReconciliationStatus,
  IFormatReconciliationMetaData,
  IReconciliationDisplay,
  IReconciliationRequest,
  IReconciliationRequestGroup,
  IReconciliationRequestResponse,
  IReconciliationConflictField,
  IHIEReconciliationRequest,
  ReconciliationSource,
  ReconciliationActionCode,
  IMSOReconciliationRequest,
  IMSOReconciliationRequestResponse,
} from './interface';
import {
  formatReconciliationSurgicalHistoryData,
  formatSurgicalHistoryRequestResourceObj,
  getSurgicalInvalidCodeField,
  updateHieSurgicalHistoryMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/SurgicalHistory/AddOrUpdateSurgicalHistory/AddOrUpdateSurgicalHistoryHelper';
import {
  formatReconciliationImmunizationData,
  formatHieImmunizationResourceObj,
  getImmunizationInvalidCodeField,
  updateHieImmunizationMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Immunizations/AddOrUpdateImmunizations/AddOrUpdateImmunizationsHelper';
import {
  formatAllergyRequestResourceObj,
  formatReconciliationAllergyData,
  getAllergyCodeInvalidField,
  updateHieAllergyMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Allergies/AddOrUpdateAllergies/AddOrUpdateAllergyHelper';
import {
  formatReconciliationFamilyHistoryData,
  formatHieFamilyHistoryResourceObj,
  getFamilyMemberInvalidCodeField,
  updateHieFamilyHistoryMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/FamilyHistory/AddOrUpdateFamilyHistory/AddOrUpdateFamilyHistoryHelper';
import {
  formatReconciliationObservationData,
  formatHieObservationRequestObj,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Vitals/AddOrUpdateVitals/AddOrUpdateVitalsHelper';
import {IntlShape} from 'react-intl';
import {getMomentObj} from '../../../../../utils/DateUtils';
import {
  formatHieMedicationResourceObj,
  formatReconciliationMedicationStatementData,
  getMedicationInvalidCodeField,
  updateHieMedicationStatementMatchedData,
} from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/Medications/AddOrUpdateMedications/AddOrUpdateMedicationsHelper';
import { AllergyIntolerance, Condition, FamilyMemberHistory, Immunization, MedicationStatement, Procedure, Resource } from 'fhir/r4';
import { formatReconciliationPastMedicalHistoryData } from '../../../../RightSideContainer/Forms/FHFormio/CustomComponents/PastMedicalHistory/AddOrUpdatePastMedicalHistory/AddOrUpdatePastMedicalHistoryHelper';
import { getEHRCapability } from '../../../../../utils/commonUtils';

export enum FHIRConflictFields {
  code,
  date,
  select,
  textArea,
  input,
  practitioner,
  allergyReaction,
}

export const resourcesSortingOrderList = [
  FHIR_RESOURCE.OBSERVATION,
  FHIR_RESOURCE.CONDITION,
  FHIR_RESOURCE.ALLERGY,
  FHIR_RESOURCE.MEDICATION_STATEMENT,
  FHIR_RESOURCE.IMMUNIZATION,
  FHIR_RESOURCE.PROCEDURE,
  FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
];

export const RECONCILIATION_RESOURCE_TYPES = [
  FHIR_RESOURCE.CONDITION,
  FHIR_RESOURCE.ALLERGY,
  FHIR_RESOURCE.MEDICATION_STATEMENT,
  FHIR_RESOURCE.IMMUNIZATION,
  FHIR_RESOURCE.PROCEDURE,
  FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
  FHIR_RESOURCE.PATIENT,
  FHIR_RESOURCE.PAST_MEDICAL_HISTORY,
  FHIR_RESOURCE.OBSERVATION,
];

export const DEFAULT_RECONCILIATION_PAGE_SIZE = 25;

export const formatHieDataResponse = (
  responseData: IReconciliationRequestResponse[],
  metaData: IFormatReconciliationMetaData
) => {
  const formattedHieData: IHIEReconciliationRequest[] = [];

  responseData.map((response) => {
    let data = response.data;

    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {

      }
    }

    const hieDisplay = getHieFormattedData(data, metaData);

    if (hieDisplay) {

      const hieRequest: IHIEReconciliationRequest = {
        resourceData: hieDisplay.resourceData || data,
        id: response.id,
        resourceType: response.display_type,
        syncDate: response.time_stamp,
        syncStatus: response.sync_status,
        display: hieDisplay.display,
        conflictFields: hieDisplay.conflictFields,
        actionCode: ReconciliationActionCode.add,
        showDeleteAction: false,
        showUpdateAction: true,
      };

      formattedHieData.push(hieRequest);
    }
  });

  const sortedData = formattedHieData?.sort((hieA, hieB) => {
    const recordDate1 = hieA.syncDate;
    const recordDate2 = hieB.syncDate;
    if (recordDate1 && recordDate2) {
      const date1 = getMomentObj(recordDate1);
      const date2 = getMomentObj(recordDate2);
      return date2 > date1 ? 1 : -1;
    }
    return 0;
  });

  return sortedData;
};

export const getSourceInfo = (actionCode: ReconciliationActionCode, locationGroupName?: string) => {
  let action = '';
  switch (actionCode) {
    case ReconciliationActionCode.add:
      action = 'Added';
      break;
    case ReconciliationActionCode.delete:
      action = 'Deleted';
      break;
    case ReconciliationActionCode.update:
      action = 'Updated';
      break;
    default:
      action = 'Added';
      break;
  }
  return locationGroupName ? `${action} in ${locationGroupName}` : action;
}

export const formatReconciliationDataResponse = (
  responseData: IMSOReconciliationRequestResponse[],
  metaData: IFormatReconciliationMetaData
) => {
  const formattedHieData: IMSOReconciliationRequest[] = [];

  responseData.map((request) => {
    if (request.sourceResourceData) {
      const updatedMetaData = {
        ...metaData,
        targetResourceData: request.targetResourceData as Resource,
      };
      const displayData = getHieFormattedData(
        request.sourceResourceData,
        updatedMetaData
      );
      if (displayData) {
        const capabilities = getEHRCapability(
          metaData.localEhrCapabilities?.length ? metaData.localEhrCapabilities : metaData.ehrCapabilities,
          [request.resourceType]
        );
        const canDelete = capabilities?.abilities?.allowedOperations?.canDelete;
        const canUpdate = capabilities?.abilities?.allowedOperations?.canUpdate || capabilities?.abilities?.allowedOperations?.canCreate;
        const locationGroupName =
          metaData?.locationGroupPatientIdMap?.[`${request.sourcePatientId}`];
        const data: IMSOReconciliationRequest = {
          ...request,
          conflictFields: displayData.conflictFields,
          display: displayData.display,
          actionCode: (request.sourceActionCode ||
            ReconciliationActionCode.add) as ReconciliationActionCode,
          showDeleteAction:
            request.sourceActionCode === ReconciliationActionCode.delete &&
            !!request.targetResourceId &&
            !!canDelete,
          showUpdateAction: !!canUpdate,
          sourceInfo: getSourceInfo(
            (request.sourceActionCode ||
              ReconciliationActionCode.add) as ReconciliationActionCode,
            locationGroupName,
          ),
          // For observation, we are not showing the data in the UI as it cannot be modified, so directly accept API will be called
          ...(request.resourceType === FHIR_RESOURCE.OBSERVATION && { modifiedResource: request.sourceResourceData }),
        };
        formattedHieData.push(data);
      }
    }
  });

  return formattedHieData;
};

export const getUpdatedMatchedHieRequest = (
  request: IReconciliationRequest,
  conflictFields: IReconciliationConflictField[],
  metaData: IFormatReconciliationMetaData
) => {
  let formattedData;
  let resourceData;
  if (metaData?.source === ReconciliationSource.MSO) {
    resourceData = (request as IMSOReconciliationRequest).sourceResourceData;
  } else if (metaData?.source === ReconciliationSource.HIE) {
    resourceData = (request as IHIEReconciliationRequest).resourceData;
  }

  switch (request.resourceType) {
    case FHIR_RESOURCE.CONDITION:
      formattedData = updateHieConditionMatchedData(
        resourceData as Condition,
        conflictFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.ALLERGY:
      formattedData = updateHieAllergyMatchedData(
        resourceData as AllergyIntolerance,
        conflictFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.IMMUNIZATION:
      formattedData = updateHieImmunizationMatchedData(
        resourceData as Immunization,
        conflictFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.PROCEDURE:
      formattedData = updateHieSurgicalHistoryMatchedData(
        resourceData as Procedure,
        conflictFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      formattedData = updateHieFamilyHistoryMatchedData(
        resourceData as FamilyMemberHistory,
        conflictFields || [],
        metaData
      );
      break;
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      formattedData = updateHieMedicationStatementMatchedData(
        resourceData as MedicationStatement,
        conflictFields || [],
        metaData
      );
      break;
  }

  if(formattedData?.conflictFields?.length === 0){
    request.error = undefined;
  }

  request.conflictFields = formattedData?.conflictFields;
  const newResourceData = formattedData?.resourceData;
  if (metaData?.source === ReconciliationSource.MSO) {
    (request as IMSOReconciliationRequest).modifiedResource = newResourceData;
  } else {
    (request as IHIEReconciliationRequest).resourceData = newResourceData;
  }
  request.display = formattedData?.display;
  return request;
};

export const groupHieRequests = (
  hieRequests: IReconciliationRequest[],
  existingGroups: IReconciliationRequestGroup[]
): IReconciliationRequestGroup[] => {
  const data = hieRequests.reduce((b: IReconciliationRequestGroup[], a) => {
    const ind = b.findIndex((e: any) => e.resourceType === a.resourceType);
    if (ind > -1) {
      b[ind].orders.push(a);
    } else {
      b.push({
        resourceType: a.resourceType,
        orders: [a],
        offset: 0,
        pageSize: DEFAULT_RECONCILIATION_PAGE_SIZE,
      });
    }
    return b;
  }, existingGroups);

  return data
    ?.sort((groupA: IReconciliationRequestGroup, groupB: IReconciliationRequestGroup) => {
      return (
        resourcesSortingOrderList.indexOf(groupA.resourceType) -
        resourcesSortingOrderList.indexOf(groupB.resourceType)
      );
    })
    ?.map((group: IReconciliationRequestGroup) => {
      group.orders = sortGroupOrdersByDate(group.orders);
      return group;
    });
};

export const sortGroupOrdersByDate = (hieRequests: IReconciliationRequest[]) => {
  return hieRequests?.sort((hieA, hieB) => {
    const recordDate1 = hieA.display?.date || new Date();
    const recordDate2 = hieB.display?.date || new Date();
    if (recordDate1 && recordDate2) {
      const date1 = getMomentObj(recordDate1);
      const date2 = getMomentObj(recordDate2);
      return date2 > date1 ? 1 : -1;
    }
    return 0;
  });
};

export const getUpdateGroupHieRequestsStatus = (
  hieRequests: IReconciliationRequest[],
  reconciliationRequestGroups: IReconciliationRequestGroup[],
  status: ReconciliationStatus
) => {
  const hieRequestIds = hieRequests.map((hieRequest) => hieRequest.id);
  return reconciliationRequestGroups.map((hieGroupOrder: IReconciliationRequestGroup) => {
    hieGroupOrder.orders = hieGroupOrder.orders.map((order) => {
      if (hieRequestIds.includes(order.id)) {
        order.syncStatus = status;
      }
      return order;
    });
    return hieGroupOrder;
  });
};

export const getUpdatedErrorGroupHieRequests = (
  hieRequests: IReconciliationRequest[],
  reconciliationRequestGroups: IReconciliationRequestGroup[]
) => {
  return reconciliationRequestGroups.map((hieGroupOrder: IReconciliationRequestGroup) => {
    hieGroupOrder.orders = hieGroupOrder.orders.map((order) => {
      const errorObj = hieRequests.find(
        (hieRequest) => hieRequest.id === order.id
      );

      if (errorObj && errorObj.error) {
        order.error = errorObj.error;
      }

      if (errorObj?.conflictFields?.length) {
        order.conflictFields = errorObj?.conflictFields;
      }

      return order;
    });
    hieGroupOrder.acceptAllLoading = false;
    return hieGroupOrder;
  });
};

export const updateHieGroupInArr = (
  reconciliationRequestGroups: IReconciliationRequestGroup[],
  hieRequestGroup: IReconciliationRequestGroup,
  updatedObj: any
) => {
  return reconciliationRequestGroups.map((hieRequestGroupObj: IReconciliationRequestGroup) => {
    if (hieRequestGroupObj.resourceType === hieRequestGroup.resourceType) {
      return {...hieRequestGroupObj, ...updatedObj};
    }
    return hieRequestGroupObj;
  });
};

export const updateGroupHieRequests = (
  hieRequest: IReconciliationRequest,
  reconciliationRequestGroups: IReconciliationRequestGroup[]
) => {
  return reconciliationRequestGroups.map((hieGroupOrder: IReconciliationRequestGroup) => {
    hieGroupOrder.orders = hieGroupOrder.orders.map((order) => {
      if (order.id === hieRequest.id) {
        return hieRequest;
      }
      return order;
    });
    return hieGroupOrder;
  });
};

export const formatFhirRequestObj = (
  resourceType: string,
  resource: any,
  metaData: IFormatReconciliationMetaData,
  isMultiple: boolean
) => {
  switch (resourceType) {
    case FHIR_RESOURCE.CONDITION:
      return formatConditionsRequestResourceObj(resource, metaData);
    case FHIR_RESOURCE.IMMUNIZATION:
      return formatHieImmunizationResourceObj(resource, metaData);
    case FHIR_RESOURCE.ALLERGY:
      const allergyData = formatAllergyRequestResourceObj(resource, metaData);
      return allergyData;
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      const familymemberFhirData = formatHieFamilyHistoryResourceObj(
        resource,
        metaData
      );
      return familymemberFhirData;
    case FHIR_RESOURCE.OBSERVATION:
      return formatHieObservationRequestObj(resource, metaData, isMultiple);
    case FHIR_RESOURCE.PROCEDURE:
      const procedureData = formatSurgicalHistoryRequestResourceObj(
        resource,
        metaData
      );
      return procedureData;
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      return formatHieMedicationResourceObj(resource, metaData);
  }

  return resource;
};

export const getHieFormattedData = (
  resource: any,
  metaData: IFormatReconciliationMetaData
):
  | {
      display: IReconciliationDisplay;
      resourceData: any;
      conflictFields?: IReconciliationConflictField[];
    }
  | undefined => {
  switch (resource.resourceType) {
    case FHIR_RESOURCE.CONDITION:
      return formatReconciliationConditionData(resource, metaData);

    case FHIR_RESOURCE.OBSERVATION:
      return formatReconciliationObservationData(resource, metaData);

    case FHIR_RESOURCE.ALLERGY:
      return formatReconciliationAllergyData(resource, metaData);

    case FHIR_RESOURCE.IMMUNIZATION:
      return formatReconciliationImmunizationData(resource, metaData);

    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      return formatReconciliationFamilyHistoryData(resource, metaData);

    case FHIR_RESOURCE.PROCEDURE:
      return formatReconciliationSurgicalHistoryData(resource, metaData);

    case FHIR_RESOURCE.PAST_MEDICAL_HISTORY:
      return formatReconciliationPastMedicalHistoryData(resource, metaData);

    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      // For HIE Medication Statement, we are not showing the data in the UI as it cannot be added or edited for elation
      // if (metaData?.ehrConfig?.isElation && metaData?.source !== ReconciliationSource.MSO) {
      //   return;
      // }
      return formatReconciliationMedicationStatementData(resource, metaData);
  }
};

export const getResourceGroupText = (resourceType: string, intl: IntlShape) => {
  const textObj = {
    headerText: intl.formatMessage({id: 'allergies'}),
    matchedWithTitle: intl.formatMessage({id: 'matchToData'}),
  };
  switch (resourceType) {
    case FHIR_RESOURCE.ALLERGY:
      textObj.headerText = intl.formatMessage({id: 'allergies'});
      break;
    case FHIR_RESOURCE.CONDITION:
      textObj.headerText = intl.formatMessage({id: 'problems'});
      break;
    case FHIR_RESOURCE.PROCEDURE:
      textObj.headerText = intl.formatMessage({id: 'surgicalHistory'});
      break;
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      textObj.headerText = intl.formatMessage({id: 'familyHistory'});
      break;
    case FHIR_RESOURCE.OBSERVATION:
      textObj.headerText = intl.formatMessage({id: 'vitals'});
      break;
    case FHIR_RESOURCE.IMMUNIZATION:
      textObj.headerText = intl.formatMessage({id: 'immunizations'});
      break;
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      textObj.headerText = intl.formatMessage({id: 'medications'});
      break;
  }
  return textObj;
};

export const getFilteredHieRequests = (hieRequests: IReconciliationRequest[]) => {
  return hieRequests.filter((hieRequest) => !hieRequest.syncStatus);
};

export const getInValidCodeObj = (resourceType: string) => {
  switch (resourceType) {
    case FHIR_RESOURCE.CONDITION:
      return getConditionCodeInvalidField();
    case FHIR_RESOURCE.ALLERGY:
      return getAllergyCodeInvalidField();
    case FHIR_RESOURCE.MEDICATION_STATEMENT:
      return getMedicationInvalidCodeField();
    case FHIR_RESOURCE.IMMUNIZATION:
      return getImmunizationInvalidCodeField();
    case FHIR_RESOURCE.FAMILY_MEMBER_HISTORY:
      return getFamilyMemberInvalidCodeField();
    case FHIR_RESOURCE.PROCEDURE:
      return getSurgicalInvalidCodeField();
  }
};
