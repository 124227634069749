import React from 'react';
import {View} from 'native-base';
import {GestureHandlerRootView, Swipeable} from 'react-native-gesture-handler';
import {testID} from '../../../../../../testUtils';
import {getUserUUID} from '../../../../../../utils/commonUtils';
import {isWeb} from '../../../../../../utils/platformCheckUtils';
import UnreadMsg from '../../../../../common/UnreadMsg/UnreadMsg';
import UnreadMsgV2 from '../../../../../common/UnreadMsg/UnreadMsgV2';
import {CHANNEL_TYPE_CODE} from '../../ConversationConst';
import {
  checkIsLoggedInUserConversationMember,
  convertMsgObjectToReplyMsgObject,
} from '../MessagingUtils';
import RenderMsgItem, {
  IRenderMsgItemProps,
} from '../RenderMsgItem/RenderMsgItem';
import {SwipeActions} from '../SwipeActions';
import {StyleSheet} from 'react-native';
export interface IRenderMsgListProps extends IRenderMsgItemProps {
  row: any[];
  closeRow: (index: number) => void;
}

const RenderMsgList = (props: IRenderMsgListProps) => {
  const selectedConversation = props.selectedConversation;
  const selectedInboxTypeCode = props.selectedInboxTypeCode;
  const item = props.item;

  const userUuid = getUserUUID();
  const isLoggedInUserGroupMember = checkIsLoggedInUserConversationMember(
    selectedConversation,
    userUuid
  );

  return (
    <View pb={3} style={styles.container}>
      {selectedConversation?.unreadMessages?.[0]?.id === item?.id &&
      isLoggedInUserGroupMember &&
      selectedConversation?.unreadMsgCount &&
      selectedConversation?.unreadMsgCount > 0 ? (
        isWeb() ? (
          <UnreadMsgV2 />
        ) : (
          <UnreadMsg unreadMsgCount={selectedConversation?.unreadMsgCount} />
        )
      ) : (
        <></>
      )}
      {props.onReplyCallback &&
      selectedInboxTypeCode === CHANNEL_TYPE_CODE.CHANNEL_WEB_WIDGET &&
      item.messageType !== 2 ? (
        <GestureHandlerRootView>
          <Swipeable
            ref={(ref) => (props.row[props.index] = ref)}
            friction={2}
            leftThreshold={40}
            renderLeftActions={() => <SwipeActions />}
            onSwipeableWillOpen={() => {
              props.closeRow(props.index);
              if (props.onReplyCallback) {
                props.onReplyCallback(convertMsgObjectToReplyMsgObject(item));
              }
            }}
            {...testID('SwipeableMessagesView')}
          >
            <RenderMsgItem
              channelData={
                selectedConversation?.conversationInbox?.channelEmail
              }
              groupMemberId={props.groupMemberId}
              handleCopyClick={props.handleCopyClick}
              index={props.index}
              item={item}
              lastNonInternalMessageInDisplayList={
                props.lastNonInternalMessageInDisplayList
              }
              lastSeenData={props.lastSeenData}
              onRedirectToMentionChange={props.onRedirectToMentionChange}
              onReplyCallback={props.onReplyCallback}
              prevMessage={props.prevMessage}
              redirectToMention={props.redirectToMention}
              scrollToMessage={props.scrollToMessage}
              searchMessage={props.searchMessage}
              selectedConversation={selectedConversation}
              clickEventId={props.clickEventId}
              clickMessageUuid={props.clickMessageUuid}
              groupMemberDataLoading={props.groupMemberDataLoading}
              isCopyModeStateOnCode={props.isCopyModeStateOnCode}
              isReadOnlyView={props.isReadOnlyView}
              messageAction={props.messageAction}
              navigation={props.navigation}
              onActionPerformed={props.onActionPerformed}
              onAddCommunicationType={props.onAddCommunicationType}
              onMessageAction={props.onMessageAction}
              onViewTaskAction={props.onViewTaskAction}
              resetClickedMessageUuid={props.resetClickedMessageUuid}
              selectedInboxTypeCode={props.selectedInboxTypeCode}
              showModal={props.showModal}
              isLoggedInUserGroupMember={isLoggedInUserGroupMember}
              hideRecordingAndTrails={props?.hideRecordingAndTrails}
            />
          </Swipeable>
        </GestureHandlerRootView>
      ) : (
        <RenderMsgItem
          channelData={selectedConversation?.conversationInbox?.channelEmail}
          groupMemberId={props.groupMemberId}
          handleCopyClick={props.handleCopyClick}
          index={props.index}
          item={item}
          lastNonInternalMessageInDisplayList={
            props.lastNonInternalMessageInDisplayList
          }
          lastSeenData={props.lastSeenData}
          onRedirectToMentionChange={props.onRedirectToMentionChange}
          onReplyCallback={props.onReplyCallback}
          prevMessage={props.prevMessage}
          redirectToMention={props.redirectToMention}
          scrollToMessage={props.scrollToMessage}
          searchMessage={props.searchMessage}
          selectedConversation={selectedConversation}
          clickEventId={props.clickEventId}
          clickMessageUuid={props.clickMessageUuid}
          groupMemberDataLoading={props.groupMemberDataLoading}
          isCopyModeStateOnCode={props.isCopyModeStateOnCode}
          isReadOnlyView={props.isReadOnlyView}
          messageAction={props.messageAction}
          navigation={props.navigation}
          onActionPerformed={props.onActionPerformed}
          onAddCommunicationType={props.onAddCommunicationType}
          onMessageAction={props.onMessageAction}
          onViewTaskAction={props.onViewTaskAction}
          resetClickedMessageUuid={props.resetClickedMessageUuid}
          selectedInboxTypeCode={props.selectedInboxTypeCode}
          showModal={props.showModal}
          lastEmailMessageUuid={props?.lastEmailMessageUuid}
          isLoggedInUserGroupMember={isLoggedInUserGroupMember}
          hideRecordingAndTrails={props?.hideRecordingAndTrails}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 15,
  },
});

export default React.memo(RenderMsgList);
