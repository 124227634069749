import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  HStack,
  Icon,
  View,
  VStack,
  Text,
  Pressable,
  Spacer,
  FormControl,
  Select,
  IconButton,
  useMediaQuery,
  Input,
  Badge,
  TextArea,
  Divider,
  FlatList,
  Spinner,
} from 'native-base';
import { Collapse, Tooltip } from 'antd';
import PAMISearch, {
  PAMISearchType,
} from '../../../../../../common/PAMISearch/PAMISearch';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {
  IAddOrUpdateAllergiesState,
  IAllergy,
  IAllergyComponentValue,
} from '../interfaces';
import {CONDITION_CLINICAL_STATUS_CODE} from '../../../../../../../constants/MlovConst';
import {
  getDateStrFromFormat,
  getDisplayMonths,
  getMomentObj,
  getMomentObjectWithDateStringAndFormat,
  getMomentObjFromFormat,
  isFutureDate,
} from '../../../../../../../utils/DateUtils';
import Feather from 'react-native-vector-icons/Feather';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  fhirAddOrUpdate,
  getResourceContentWithHeaders,
  acceptIgnorePatientReportedRecords,
  deleteResource,
} from '../../../../../../../services/CommonService/AidBoxService';
import {
  AllergySeverity,
  getAllergyName,
  getRecordListFormatted,
  getFHIRTemplate,
  isInvalid,
  getSampleData,
  getAllergySeverityDisplayName,
} from './AddOrUpdateAllergyHelper';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {IEhrCapability, IKeyOperation} from '../../../../../../../Interfaces';
import {FHIR_RESOURCE, PatientRecordActions, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
  getNKDADetails,
  getSectionNoteDetails,
  getHeaderViewAllButton,
} from '../../CustomWrapper/CustomComponentHelper';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import {v4 as uuidv4} from 'uuid';
import {Colors} from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import CustomDetailTableView, {
  ICustomDetailColumn,
} from '../../CustomWrapper/CustomDetailTableView/CustomDetailTableView';
import {DatePicker, Drawer, Popover, notification} from 'antd';
import {getStatusElementByStatus} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailTables/DetailTableUtils';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {DISPLAY_DATE_FORMAT, BUTTON_TYPE, HTTP_ERROR_CODE} from '../../../../../../../constants';
import {NoAllergiesSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import AllergyIconSvg from '../../../../../../common/Svg/AllergyIconSvg';
import CompactListViewMoreDetails from '../../CustomWrapper/CompactListView/CompactListViewMoreDetails';
import {capitalize, cloneDeep, debounce} from 'lodash';
import AllergyReactionEdit from '../AllergyReactionEdit/AllergyReactionEdit';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { AllergyFields, filterAndSkipDuplicates, showAddorUpdate } from '../../CustomComponentUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import { SUPPORTED_EVENT_CODE } from '../../../../../../../constants/WebSocketConst';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { EHRName } from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { RightOutlined } from '@ant-design/icons';
import SectionLevelDetails from '../../CustomWrapper/SectionLevelDetails/SectionLevelDetails';
import { IDeleteConfirmation, IFormValidationOutput } from '../../CustomWrapper/interfaces';
import PatientIntakeDeleteModal from '../../../../../../common/PatientIntakeDeleteModal/PatientIntakeDeleteModal';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import useBulkActionPatientReportedRecords from '../../../../../../CustomHooks/useBulkActionPatientReportedRecords';
import PatientIntakeConfirm from '../../../../../../common/PatientIntakeConfirm/PatientIntakeConfirm';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { getAcceptedAndIgnoredRecordNames } from '../../../../../../common/PatientIntakeConfirm/utils';
import { renderSectionBulletList } from '../../../../../../common/CommonUtils/CommonUtils';
import CustomTextAreaField from '../../TextAreaComponent/CustomTextAreaField';
import { reactStyles } from '../../../../Analytics/style';
import { isSideCarWindow } from '../../../../../../../constants/BaseUrlConst';
import {FormLastSyncView} from '../../../../../../common/FormLastSyncView/FormLastSyncView';
import {formatExecutionDate} from '../../../../../Contracts/HelperFiles/utils';
import { isAccountConfigEnabled } from '../../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../../constants/AccountConfigConst';
import FeatureFlags from '../../../../../../../constants/FeatureFlags.enums';
import { getBooleanFeatureFlag } from '../../../../../../../utils/commonUtils';
import {formatSyncDate} from '../../../../../Contracts/HelperFiles/utils';
import { testID, TestIdentifiers } from '../../../../../../../testUtils';
import { ReconcileRequestCountView } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/ReconcileRequestCountView';
import { RECONCILIATION_RESOURCE_TYPES } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';

const AddOrUpdateAllergies = (props: IFormComponentProps) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isSidecarContext = isSideCarWindow()
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const isSmallerDesktopScreen = isCompactView ? drawerDetailWidth <= 620 : width <= 620;
  const isNotesScreenWidth = isPatientNote &&  width <= 680
  // Temporary condition
  const capabilities = useMemo(() => contextData.ehrCapabilities.find((ehr: IEhrCapability) => ehr.resourceName === FHIR_RESOURCE.ALLERGY), [contextData.ehrCapabilities]);
  const isAthena = capabilities?.ehrName === EHRName.ATHENA;
  const [componentValue, setComponentValue] = useState<IAllergyComponentValue>(
    props.component?.selectedValue || {allergies: []}
  );
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const isMultiTenancyEnabled = getBooleanFeatureFlag(contextData?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const ehrActivePatient = contextData.ehrActivePatient || props.options?.ehrActivePatient;
  const isShowAddOrUpdate = isCompactView ? showAddorUpdate(isMultiTenancyEnabled,isCompactView ,props?.options, contextData ,ehrActivePatient) : true;
  const patientId = isMultiTenancyEnabled ? ehrActivePatient?.ehrPatientId :  contextData.patientId ;
  const locationId = isMultiTenancyEnabled ? ehrActivePatient?.accountLocationUuid :  contextData.locationId ;
  const bulkAction = useBulkActionPatientReportedRecords();
  const [componentState, setComponentState] =
    useState<IAddOrUpdateAllergiesState>({
      loading: false,
      formError: contextData.formError,
      collapsed: contextData.defaultCollapse,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      patientReported: [],
      sectionNote: '',
      nkda: false,
      activePanels: [],
      isFirstSync: true,
      lastUpdated: '',
    });
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<IDeleteConfirmation>({
  modalVisible:false,
    selectedEntry: undefined,
  });
  const cancelDeletePrompt = () =>
    setDeleteConfirmation((prev) => ({
      ...prev,
      modalVisible: false,
      selectedEntry: undefined,
    }));
  const { Panel } = Collapse;

  const getFieldCapabilities = (): IKeyOperation | undefined => {
    return capabilities?.abilities?.keyAllowedOperations;
  };

  const onsetDateFormat = getFieldCapabilities()?.onsetDateTime?.format;
  const resourceTypes = useMemo(() => {
    return RECONCILIATION_RESOURCE_TYPES.includes(FHIR_RESOURCE.ALLERGY) ? [FHIR_RESOURCE.ALLERGY] : [];
  }, []);

  const validateData = (currentData: IAllergyComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return { isValid: true, message: '' };
    }
    const hasMissingData = currentData?.allergies.some((item) => {
      return isInvalidRecord(item);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (item: IAllergy) => {
    const config = getFieldCapabilities();
    return (
      isInvalid(AllergyFields.clinicalStatus, item, true, config) ||
      isInvalid(AllergyFields.onSetDateTime, item, true, config) ||
      isInvalid(AllergyFields.reaction, item, true, config) ||
      isInvalid(AllergyFields.criticality, item, true, config) ||
      isInvalid(AllergyFields.note, item, true, config) ||
      isInvalid(AllergyFields.type, item, true, config)
    );
  };

  const getReactionTypeList = () => {
    return getFieldCapabilities()?.type?.possibleValues || [];
  };

  const getClinicalStatusList = () => {
    return getFieldCapabilities()?.clinicalStatus?.possibleValues || [];
  };

  const getSeverityStatusList = () => {
    return getFieldCapabilities()?.severity?.possibleValues || [];
  };

  const getCriticalityList = () => {
    return getFieldCapabilities()?.criticality?.possibleValues || [];
  };

  const getClinicalStatusFromCode = (code: string) => {
    const list = getClinicalStatusList();
    return list.find((status: any) => {
      return status.code === code;
    });
  };

  const canAddNew = (): boolean => {
     // GLOBAL CHECK FROM EHR SETTINGS
     if(isPatientProfile && organizationCapability?.canCreate === false){
      return false
    }
    return (
      contextData.isBuilderMode ||
      ((capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };

  const canUpdate = (): boolean => {
    if(isPatientProfile && organizationCapability?.canUpdate === false){
      return false
    }
    return (
      (capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled &&
      !isPatientForm
    );
  };
  const canDelete = (): boolean => {
     // GLOBAL CHECK FROM EHR SETTINGS
     if(isPatientProfile && organizationCapability?.canDelete === false){
      return false
    }
    return (
      capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };

  const onDetailChange = (data: IAllergy, index: number) => {
    onRecordChange(data);
    setComponentValue((prev) => {
      const list = prev.allergies;
      list[index] = data;
      return {
        ...prev,
        allergies: list,
      };
    });
  };
  const removeFromLocal = (uniqueId?: string) => {
    const index = componentValue.allergies.findIndex(
      (item) => item?.uniqueId === uniqueId
    );
    componentValue.allergies.splice(index, 1);
    setComponentValue((prev) => ({
      ...prev,
      allergies: [...componentValue.allergies],
    }));
  };
  const onDelete = (data: IAllergy, index: number, isLocalDelete: boolean) => {
    if (!isLocalDelete && data.id) {
      deleteResource(
        FHIR_RESOURCE.ALLERGY,
        `${data.id}`,
        !contextData.hasLoggedInContext,
        contextData.headers,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          if (response?.success) {
           cancelDeletePrompt();
           removeFromLocal(data?.uniqueId);
           broadcastUpdateEvent();
          }
        },
        (error) => {

          cancelDeletePrompt();
          setComponentState((prev) => ({
            ...prev,
            loading: false,
            showSavingIndicator: false,
            formError: FormError.existingDataAPIFail,
          }));
        }
      );
    } else {
      removeFromLocal(data?.uniqueId);
      cancelDeletePrompt();
    }
  };

  const addNewItem = (item: any) => {
    const date = onsetDateFormat
      ? getDateStrFromFormat(new Date(), onsetDateFormat)
      : getMomentObj(new Date()).toISOString();
    const data: IAllergy = {
      allergy: item,
      name: getAllergyName(item),
      clinicalStatus: getClinicalStatusFromCode(
        getFieldCapabilities()?.clinicalStatus?.defaultValue || CONDITION_CLINICAL_STATUS_CODE.ACTIVE
      ),
      onSetDateTime: date,
      uniqueId: uuidv4(),
      isFreeTextRecord: false,
      showNote: getFieldCapabilities()?.note?.isRequired,
    };
    componentValue.allergies.splice(0, 0, data);
    onRecordChange(data);
    setComponentValue({
      allergies: [...componentValue.allergies],
    });
  };

  const onRecordChange = (record: IAllergy) => {
    if (contextData.backgroundSaveEnabled) {
      addOrUpdateRecord(record);
    }
  };

  const isFieldDisabled = (item: IAllergy) => {
    return props.disabled || (!canUpdate() && !!item.id);
  };

  const isInvalidFieldData = (field: AllergyFields, item: IAllergy) => {
    return isInvalid(field, item, true, getFieldCapabilities());
  };

  const isOpenChoice = () => {
    const fields = getFieldCapabilities();
    return fields?.code?.inputType == InputType.openChoice;
  }

  const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
    return (
      <Pressable
        width={'100%'}
        paddingRight={fullWidth ? 6 : 0}
        onPress={(event) => event.stopPropagation()}
      >
        <View
          flex={1}
          style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
        >
          <PAMISearch
            addNewOptionEnabled={isPatientForm || contextData.isBuilderMode || isOpenChoice()}
            clearOnSelection
            searchType={PAMISearchType.allergy}
            additionalHeaders={contextData.headers}
            placeholder="Search and add new allergy"
            isShowError={false}
            onChange={(value) => {
              if (value) {
                addNewItem(value);
              }
            }}
            locationId={contextData.locationId}
          />
        </View>
      </Pressable>
    );
  };

  const getHeaderRightView = (isCompact: boolean, readonlyAddBtn?: boolean): JSX.Element | undefined => {
    if (!canAddNew()) {
      if (isCompact) {
        return getHeaderViewAllButton(() => {
          if (isAthena) {
            getExistingData({patientId: contextData.patientId || '', getInteractiveData: true});
          }
          setComponentState((prev) => ({...prev, editModalVisible: true, isFirstSync: false}));
        }, 'View all');
      } else {
        return;
      }
    }

    const customTooltip = readonlyAddBtn ? intl.formatMessage({id: 'pamiLocationChangeError'}) : undefined;

    if (isCompact && !!organizationCapability?.canCreate) {
      return getHeaderButtons(() => {
        if (isAthena) {
          readonlyAddBtn ? null : getExistingData({patientId: contextData.patientId || '', getInteractiveData: true});
        }
        readonlyAddBtn ? null : setComponentState((prev) => ({...prev, editModalVisible: true, isFirstSync: false}));
      }, customTooltip, undefined, readonlyAddBtn);
    } else if (!isMobileScreen) {
      return (
        <HStack space={2} alignItems="center">
          {componentState.showSavingIndicator && <SectionLoadingIndicator />}
        </HStack>
      );
    }
  };

  const renderLastSyncView = (hideSync?: boolean) => {
    return (<>
      {isAthena ? <View>
        <View marginBottom={hideSync ? 4 : 0}>
          <FormLastSyncView
            onFetchData={()=> {
              getExistingData({patientId: contextData.patientId || '', getInteractiveData: true});
              setComponentState((prev)=> {
                return {
                  ...prev,
                  isFirstSync: false
                }
              })
            }}
            isFirstSync={componentState?.isFirstSync}
            isLoading={componentState?.loading}
            lastUpdated={componentState?.lastUpdated}
            hideSync={hideSync ? hideSync : !componentState?.activePanels?.includes("allergies")}
          />
        </View>
      </View> : <></>}
      </>)
   }
  const isFieldEnabled = (field: AllergyFields) => {
    if (props.component?.enabledFields) {
      return props.component?.enabledFields[field];
    }
    return IsFieldVisible(field);
  };

  const IsFieldVisible = (field: AllergyFields) => {
    const config = getFieldCapabilities();
    let isDisplayField = false;
    switch(field) {
      case AllergyFields.code:
        if (config && config['code']) {
          isDisplayField = config['code']?.isHidden ? false : true;
        }
        break;
      case AllergyFields.clinicalStatus:
        if (config && config['clinicalStatus']) {
          isDisplayField = config['clinicalStatus']?.isHidden ? false : true;
        }
        break;
      case AllergyFields.criticality:
        if (config && config['criticality']) {
          isDisplayField = config['criticality']?.isHidden ? false : true;
        }
        break;
      case AllergyFields.note:
        if (config && config['note']) {
          isDisplayField = config['note']?.isHidden ? false : true;
        }
        break;
      case AllergyFields.onSetDateTime:
        if (config && config['onsetDateTime']) {
          isDisplayField = config['onsetDateTime']?.isHidden ? false : true;
        }
        break;
      case AllergyFields.reaction:
        if (config && config['reaction']) {
          isDisplayField = config['reaction']?.isHidden ? false : true;
        }
        break;
      case AllergyFields.type:
        if (config && config['type']) {
          isDisplayField = config['type']?.isHidden ? false : true;
        }
        break;
    }
    return isDisplayField;
  };
  const canShowDeleteBtn = (itemId?: string): boolean => {
    if(isPatientNote){
      return true;
    }
    if (isPatientForm) {
      return !itemId;
    }
    return canDelete() || contextData.isBuilderMode;
  };
  const invokeDeleteConfirmation = (item:IAllergy,index:number) => {
    setDeleteConfirmation(prev => ({
      ...prev,
      modalVisible: true,
      selectedEntry: {
        item: item,
        index: index
      }
    }))
   }

  const getDeleteFunction = (item: IAllergy, index: number) => {
    const isLocalDelete =
      (isPatientForm && !item.id) ||
      contextData.isBuilderMode ||
      !contextData.backgroundSaveEnabled;

    if (isLocalDelete) {
      onDelete(item, index, true);
    } else if (canDelete()) {
      invokeDeleteConfirmation(item,index);
    }
  };
  const handleShowReactionInput = (id?: string) => {
    //find the record with id and add empty object in reaction array and toggle flag showReaction
    const index = componentValue.allergies.findIndex(
      (item) => item.uniqueId === id
    );
    if (index > -1) {
      componentValue.allergies[index]?.reactions
        ? componentValue.allergies[index]?.reactions?.push({})
        : (componentValue.allergies[index].reactions = [{}]);
      componentValue.allergies[index].showAddReaction = true;
      setComponentValue({
        allergies: [...componentValue.allergies],
      });
    }
  };
  const handleShowNoteClick = (id: string) => {
    //find the record with id and toogle showNote flag as true
    const record = componentValue.allergies.find((item) => item.uniqueId === id);
    if (record) {
      record.showNote = !record.showNote;
      setComponentValue((prev) => ({
        ...prev,
        allergies: [...componentValue.allergies],
      }));
    }
 }
 //function to check any object in reactions array is empty
 const isAnyReactionEmpty = (reactions: any) => {
  return reactions?.some((reaction: any) => {
    if (!reaction?.manifestation) {
      return true;
    }
  });
};
const showAddReactionBtn = (item:IAllergy) => {
  const isShowAddReactionButton = getFieldCapabilities()?.reaction?.disableMultiple
    ? item?.reactions?.length !== 1
    : !isAnyReactionEmpty(item.reactions);

  if (isPatientForm) {
    return !item.id && isShowAddReactionButton;
  }

  return isShowAddReactionButton;
}
  const getColumns = (): ICustomDetailColumn[] => {
    const fields = getFieldCapabilities();
    const columns: ICustomDetailColumn[] = [
      {
        title: intl.formatMessage({id: 'name'}),
        flex: isAthena ?  1.2 : 2,
        render: (item: IAllergy) => {
          return <Text color={Colors.FoldPixel.GRAY400}>{item.name}</Text>;
        },
      },
    ];
    if (isFieldEnabled(AllergyFields.onSetDateTime)) {
      const isOnlyYearFormat = onsetDateFormat === 'YYYY';
      const label = intl.formatMessage({
        id: isOnlyYearFormat ? 'sinceYear' : 'sinceDate',
      });
      columns.push({
        title: label,
        flex: 1.8,
        render: (item: IAllergy, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(
            AllergyFields.onSetDateTime,
            item
          );
          return (
            <DatePicker
              picker={isOnlyYearFormat ? 'year' : 'date'}
              disabled={isDisabled}
              placeholder={label}
              format={isOnlyYearFormat ? onsetDateFormat : DISPLAY_DATE_FORMAT}
              disabledDate={(current: any) => {
                return current && isFutureDate(current);
              }}
              value={
                item.onSetDateTime
                  ? getMomentObjectWithDateStringAndFormat(
                      item.onSetDateTime,
                      onsetDateFormat || ''
                    )
                  : undefined
              }
              style={reactStyles.height40px}
              className={isInvalid && !isDisabled ? 'field-error' : ''}
              onChange={(value) => {
                item.onSetDateTime = onsetDateFormat
                  ? value?.format(onsetDateFormat)
                  : value?.toISOString();
                onDetailChange(item, index);
              }}
            />
          );
        },
      });
    }
    if (isFieldEnabled(AllergyFields.clinicalStatus)) {
      const statusList = getClinicalStatusList();
      columns.push({
        title: intl.formatMessage({id: 'status'}),
        flex: isAthena ? 0.8 : 1.5,
        render: (item: IAllergy, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(
            AllergyFields.clinicalStatus,
            item
          );
          const canUpdateStatus = (getFieldCapabilities()?.clinicalStatus?.canUpdate || false);
          const statusValue = statusList.find((status: any) => {
            return status.code === item.clinicalStatus?.code;
          })
          if (!canUpdateStatus && isAthena) {
            return <Text>{statusValue?.display || ''}</Text>;
          }
          return (
            <FormControl isInvalid={isInvalid && !isDisabled}>
              <Select
                placeholder={intl.formatMessage({id: 'status'})}
                selectedValue={item.clinicalStatus?.code}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  item.clinicalStatus = statusList.find((status: any) => {
                    return status.code === value;
                  });
                  onDetailChange(item, index);
                }}
                h={'100%'}
              >
                {statusList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    if (isFieldEnabled(AllergyFields.type)) {
      const statusList = getReactionTypeList();
      columns.push({
        title: intl.formatMessage({id: 'reactionType'}),
        flex: 1.5,
        render: (item: IAllergy, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(
            AllergyFields.type,
            item
          );
          return (
            <FormControl isInvalid={isInvalid && !isDisabled}>
              <Select
                placeholder={intl.formatMessage({id: 'reactionType'})}
                selectedValue={item.type}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  item.type = value;
                  onDetailChange(item, index);
                }}
                h={'100%'}
              >
                {statusList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    // if (isFieldEnabled(AllergyFields.reaction)) {
    //   const fields = getFieldCapabilities();
    //   const disableMultiple = fields?.reaction?.disableMultiple || false
    //   const label = intl.formatMessage({id: disableMultiple ? 'reaction' : 'reactions'});
    //   const severityList = getSeverityStatusList();
    //   columns.push({
    //     title: label,
    //     flex: 3,
    //     render: (item: IAllergy, index: number) => {
    //       const isDisabled = isFieldDisabled(item);
    //       return (
    //         <


    //           reactions={item.reactions || [{}]}
    //           disableMultiple={disableMultiple}
    //           reactionInputType={fields?.reaction?.inputType}
    //           severityList={severityList}
    //           isDisabled={isDisabled}
    //           isRequired={fields?.reaction.isRequired || false}
    //           onChange={(reactions) => {
    //             item.reactions = reactions;
    //             onDetailChange(item, index);
    //           }}
    //         />
    //       );
    //     },
    //   });
    // }
    if (isFieldEnabled(AllergyFields.criticality)) {
      const label = intl.formatMessage({id: 'criticality'});
      const criticalityList = getCriticalityList();
      columns.push({
        title: label,
        flex: isAthena? 0.8 : 1,
        render: (item: IAllergy, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const isInvalid = isInvalidFieldData(AllergyFields.criticality, item);
          return (
            <FormControl isInvalid={isInvalid && !isDisabled}>
              <Select
                placeholder={label}
                selectedValue={item.criticality}
                isDisabled={isDisabled}
                onValueChange={(value: string) => {
                  item.criticality = value;
                  onDetailChange(item, index);
                }}
              >
                {criticalityList.map((option: any, index: number) => {
                  return (
                    <Select.Item
                      key={`${option.id}_${index}`}
                      value={option.code}
                      label={option.display}
                    />
                  );
                })}
              </Select>
            </FormControl>
          );
        },
      });
    }
    // if (isFieldEnabled(AllergyFields.note)) {
    //   const label = intl.formatMessage({id: 'notes'});
    //   columns.push({
    //     title: label,
    //     flex: 1,
    //     render: (item: IAllergy, index: number) => {
    //       const isDisabled = isFieldDisabled(item);
    //       const isInvalid = isInvalidFieldData(AllergyFields.note, item);
    //       return (
    //         <FormControl isInvalid={isInvalid && !isDisabled}>
    //           <Input
    //             placeholder={label}
    //             isReadOnly={isDisabled}
    //             defaultValue={item.note}
    //             onChangeText={debounce((text: string) => {
    //               item.note = text;
    //               onDetailChange(item, index);
    //             }, 500)}
    //           />
    //         </FormControl>
    //       );
    //     },
    //   });
    // }
    const actionsAvailable =  (canUpdate() || canShowDeleteBtn()) && (isFieldEnabled(AllergyFields.note) || isFieldEnabled(AllergyFields.reaction) || canShowDeleteBtn());
    if (!props.disabled && actionsAvailable) {
      columns.push({
        title: intl.formatMessage({id: 'actions'}),
        flex: 1.5,
        render: (item: IAllergy, index: number) => {
          const isDisabled = isFieldDisabled(item);
          const bgColor = isDisabled ? Colors.Custom.Gray50 : Colors.Custom.Base50;
          const accentColor = isDisabled ? Colors.Custom.Gray500 : Colors.Custom.PurpleColor;
          const borderColor = isDisabled ? Colors.Custom.Gray50 : Colors.Custom.ActionBtnBorder;
          return (
            <HStack
              alignItems={isNotesScreenWidth? 'flex-end' : isSmallerDesktopScreen ? 'flex-end' : 'center'}
              justifyContent={'flex-end'}
              h="100%"
              flexDirection={
                isSmallerDesktopScreen && !isMobileScreen && !isNotesScreenWidth? 'column' : 'row'
              }
            >
              <HStack>
                {isFieldEnabled(AllergyFields.note) && !item?.showNote && (
                  <Pressable
                    flexDirection="row"
                    alignItems="center"
                    onPress={() => handleShowNoteClick(item?.uniqueId || '')}
                    borderWidth={0.3}
                    backgroundColor={bgColor}
                    borderColor={borderColor}
                    px={2}
                    py={1}
                    rounded="2xl"
                    shadow={2}
                    disabled={isDisabled}
                    mr={isSmallerDesktopScreen && !isMobileScreen && ! isNotesScreenWidth ? 0 : 2}
                  >
                    <Feather
                      name="plus"
                      size={14}
                      color={accentColor}
                    />
                    <Text fontWeight="bold" color={accentColor}>
                      Note
                    </Text>
                  </Pressable>
                )}
                {showAddReactionBtn(item) &&
                  isFieldEnabled(AllergyFields.reaction) && (
                    <Pressable
                      flexDirection="row"
                      alignItems="center"
                      onPress={() =>
                        handleShowReactionInput(item.uniqueId || '')
                      }
                      borderWidth={0.3}
                      backgroundColor={bgColor}
                      borderColor={borderColor}
                      px={2}
                      py={1}
                      rounded="2xl"
                      shadow={2}
                      disabled={isDisabled}
                      mr={isSmallerDesktopScreen && !isMobileScreen && !isNotesScreenWidth ? 0 : 2}
                    >
                      <Feather
                        name="plus"
                        size={14}
                        color={accentColor}
                      />
                      <Text fontWeight="bold" color={accentColor}>
                        Reaction
                      </Text>
                    </Pressable>
                  )}
              </HStack>
              {isMobileScreen && <Spacer />}
              {canShowDeleteBtn(item.id) ? (
                <Pressable
                  onPress={() => getDeleteFunction(item, index)}
                  borderWidth={0.3}
                  backgroundColor={Colors.Custom.Base50}
                  borderColor={Colors.Custom.ActionBtnBorder}
                  px={2}
                  py={1}
                  rounded="full"
                  shadow={2}
                  mt={isSmallerDesktopScreen ? 1 : 0}
                  {...testID(TestIdentifiers.deleteBtn)}
                >
                  <Feather
                    name={
                      canDelete() || contextData.isBuilderMode ? 'trash-2' : 'x'
                    }
                    size={20}
                    color={Colors.Custom.PurpleColor}
                  />
                </Pressable>
               ) : null}
            </HStack>
          );
        },
      });
    }
    return columns;
  };
  const renderAdditionalView = (item: IAllergy, index: number) => {
      const fields = getFieldCapabilities();
      const disableMultiple = fields?.reaction?.disableMultiple || false;
      const label = intl.formatMessage({id: 'singleNote'});
      const severityList = getSeverityStatusList();
      const isDisabled = isFieldDisabled(item);
      const isInvalidNote = isInvalidFieldData(AllergyFields.note, item);
      return (
        <HStack justifySelf="stretch" my={1}>
          <VStack flex={1}>
              {isFieldEnabled(AllergyFields.reaction) && (<AllergyReactionEdit
                reactions={item.reactions || []}
                disableMultiple={disableMultiple}
                addNewReactionEnabled={fields?.reaction?.inputType == InputType.openChoice}
                reactionInputType={fields?.reaction?.inputType}
                severityList={severityList}
                isDisabled={isDisabled}
                isRequired={!!fields?.reaction?.isRequired}
                onChange={(reactions) => {
                  item.reactions = reactions;
                  onDetailChange(item, index);
                }}
              />)}
            {item.showNote && (
              <FormControl isInvalid={isInvalidNote && !isDisabled}>
                <HStack justifyContent={'flex-start'} mt={2}>
                  <Text mr={3} ml={1} fontSize='sm' color={Colors.Custom.Gray500} flex={1.8}>
                    {label}
                  </Text>
                  <CustomTextAreaField
                    placeholder={label}
                    defaultValue={item.note}
                    macroTemplatesEnabled={!isPatientForm}
                    flex={8.2}
                    onValueChange={debounce((text: string) => {
                      item.note = text;
                      onDetailChange(item, index);
                    }, 500)}
                    additionalProps={{
                      totalLines: 2,
                      flex: 1,
                      isReadOnly: isDisabled,
                    }}
                  />
                </HStack>
              </FormControl>
            )}
          </VStack>
        </HStack>
      );
  }
  const getBadgeColorScheme = (severity: AllergySeverity) => {
    switch (severity) {
      case AllergySeverity.mild:
        return {color: 'success.600', backgroundColor: 'success.100'};
      case AllergySeverity.moderate:
        return {color: 'info.600', backgroundColor: 'info.100'};
      case AllergySeverity.severe:
        return {color: 'danger.600', backgroundColor: 'danger.100'};
    }
  };

  const getSeverityValueFromCode = (code: string) => {
    const list = getSeverityStatusList();
    return list.find((data) => data.code === code)?.display;
  };

  const getSectionAdditionalView = (isCompactView: boolean, showSync?: boolean) => {
    const fieldCapabilities = getFieldCapabilities();
    if (fieldCapabilities?.sectionNote) {
      return (
        <VStack paddingX={isCompactView ? 4 : 0} paddingTop={isCompactView ? 3 : 1} paddingBottom={isCompactView ? 2 : 0}>
          {showSync && renderLastSyncView(true)}
          <SectionLevelDetails
            organizationCapabilites={organizationCapability}
            patientId={contextData.patientId || ''}
            contactId={contextData?.contactIntId || ''}
            accountLocationId={contextData.locationId}
            fieldCapabilities={fieldCapabilities}
            resource={FHIR_RESOURCE.ALLERGY}
            nkda={componentState.nkda}
            nkdaMessage={componentValue.allergies.length ? 'Ensure this list does not include any drug allergies.' : undefined}
            note={componentState.sectionNote || ''}
            onChange={(text, nkda) => setComponentState(prev => ({...prev, sectionNote: text, nkda: nkda || false}))}
          />
        </VStack>
      );
    }
    return;
  }

  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue.allergies.length && !isCompact && !isPatientReported) {
      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          <NoAllergiesSvg titleId="noAllergyItems" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }

    if (isCompact) {
      return (
        <>
        <CompactListViewMoreDetails
          hasPatientReportedRecords={componentState.patientReported.length > 0}
          onViewPatientReportedClick={() => {
            setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
          }}
          noDataTextLabel={'noActiveAllergyItems'}
          noDataComponent={<NoAllergiesSvg titleId="noActiveAllergyItems" />}
          currentStatusValue={'active'}
          showStatusValue={'inactive'}
          viewDataText={'Inactive Allergies'}
          dataSource={componentValue.allergies}
          showMoreMaxCount={contextData.showMoreMaxCount}
          onViewMoreClick={() => {
            if (isAthena) {
              getExistingData({patientId: contextData.patientId || '', getInteractiveData: true});
            }
            setComponentState((prev) => ({...prev, editModalVisible: true, isFirstSync: false}));
          }}
          rowViewForItem={(item: IAllergy) => {
            let displayDate: JSX.Element | undefined;
            let displayStatus: JSX.Element | undefined;
            let displayDateItem = '';

            if (item.onSetDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.onSetDateTime,
                onsetDateFormat || ''
              );

              /**
               * Difference in Months
               */
              displayDateItem = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );

              displayDate = (
                <Text size={'smMedium'} color={Colors.Custom['Gray500']}>
                  {displayDateItem}
                </Text>
              );
            }

            if (item.clinicalStatus?.code) {
              displayStatus = getStatusElementByStatus(
                item.clinicalStatus.code
              );
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <Text color={Colors.FoldPixel.GRAY400}>
                    {!displayDateItem
                      ? `${item.name}`
                      : `${item.name} (${displayDateItem})`}
                  </Text>
                  <Spacer />
                  {/* {displayStatus} */}
                </HStack>
                {item.reactions && item.reactions.length > 0 && (
                  <VStack space={2}>
                    {item.reactions.map((reaction, index) => {
                      const colorData = getBadgeColorScheme(
                        reaction.severity as AllergySeverity
                      );
                      return (
                        <HStack space={2} key={index} marginLeft={2}>
                          <Text color={Colors.Custom['Gray500']}>
                            {reaction.manifestation?.display || ''}
                          </Text>
                          {reaction.severity && colorData && (
                            <Badge
                              variant="subtle"
                              _text={{color: colorData.color, paddingX: 2}}
                              backgroundColor={colorData.backgroundColor}
                              height={6}
                              rounded="3xl"
                            >
                              {getSeverityValueFromCode(reaction.severity)}
                            </Badge>
                          )}
                        </HStack>
                      );
                    })}
                  </VStack>
                )}
                {/* {displayDate} */}
              </VStack>
            );
          }}
        />
        {isMultiTenancyEnabled && isCompactView && resourceTypes.length > 0 && (
          <ReconcileRequestCountView
            resourceTypes={resourceTypes}
            patientId={patientId}
            contactUuid={contextData.contactId}
            accountLocationId={locationId}
          />
        )}
        {getSectionAdditionalView(true)}
        </>
      );
    } else if (isPatientReported) {
      return (
        <PatientReportedTableView
          dataSource={componentState.patientReported}
          rowViewForItem={(item: IAllergy, index: number) => {
            const displayStatus = item.clinicalStatus?.display || item.clinicalStatus?.code;
            let displayDateItem = '';

            if (item.onSetDateTime) {
              const date = getMomentObjectWithDateStringAndFormat(
                item.onSetDateTime,
                onsetDateFormat || ''
              );
              /**
               * Difference in Months
               */
              displayDateItem = getDisplayMonths(
                getMomentObjFromFormat(date, DISPLAY_DATE_FORMAT),
                getMomentObjFromFormat(new Date(), DISPLAY_DATE_FORMAT)
              );
            }

            return (
              <VStack key={item.id}>
                <HStack space={1} alignItems="start">
                  <VStack space={1} flex={1}>
                    <Text color={Colors.Custom['Gray900']} width="full">
                      {!displayDateItem
                        ? `${item.name}`
                        : `${item.name} (${displayDateItem})`}
                    </Text>
                    {displayStatus && (
                      <Text color={Colors.Custom['Gray500']}>
                        {displayStatus}
                      </Text>
                    )}
                  </VStack>
                  {organizationCapability?.canCreate && (
                    <HStack space={2}>
                      {item.isFreeTextRecord && (
                        <Popover
                          key={item.id || index}
                          overlayInnerStyle={{
                            bottom: 3,
                            borderRadius: 16,
                            padding: 0,
                          }}
                          content={
                            <View minWidth={300} maxW={400} padding={2}>
                              <VStack space={8}>
                                <Text size="xlMedium">Match to Allergy</Text>
                                <VStack space={1}>
                                  <DisplayText
                                    textLocalId={'allergy'}
                                    size="smMedium"
                                  />
                                  <PAMISearch
                                    searchType={PAMISearchType.allergy}
                                    additionalHeaders={contextData.headers}
                                    placeholder="Search Allergy"
                                    isShowError={false}
                                    onChange={(value) => {
                                      setComponentState((prev) => ({
                                        ...prev,
                                        selectedItemToMap: value,
                                      }));
                                    }}
                                    locationId={contextData.locationId}
                                  />
                                </VStack>
                                <HStack>
                                  <Spacer />
                                  <Button.Group space={2}>
                                    <FoldButton
                                      nativeProps={{
                                        variant: BUTTON_TYPE.PRIMARY,
                                        onPress: () => {
                                          setComponentState((prev) => {
                                            const list = prev.patientReported;
                                            const index = list.findIndex(
                                              (value) =>
                                                value.uniqueId === item.uniqueId
                                            );
                                            if (index > -1) {
                                              list[index].allergy =
                                                componentState.selectedItemToMap;
                                              list[index].name = getAllergyName(
                                                componentState.selectedItemToMap
                                              );
                                              list[index].isFreeTextRecord = false;
                                            }
                                            return {
                                              ...prev,
                                              patientReported: list,
                                              selectedItemToMap: undefined,
                                              selectedPatientReportedRecord:
                                                undefined,
                                            };
                                          });
                                        },
                                        isDisabled:
                                          !componentState.selectedItemToMap,
                                      }}
                                      customProps={{
                                        btnText: intl.formatMessage({
                                          id: 'continue',
                                        }),
                                        withRightBorder: false,
                                      }}
                                    ></FoldButton>
                                  </Button.Group>
                                </HStack>
                              </VStack>
                            </View>
                          }
                          title=""
                          trigger="click"
                          visible={
                            componentState.selectedPatientReportedRecord ==
                            item.id
                          }
                          onVisibleChange={(visible) => {
                            setComponentState((prev) => ({
                              ...prev,
                              selectedPatientReportedRecord: visible
                                ? item.id
                                : undefined,
                            }));
                          }}
                          placement={'bottom'}
                        >
                          <CustomButton
                            title="Match to Allergy"
                            isDisabled={componentState.showSavingIndicator}
                            onPress={() => {
                              setComponentState((prev) => ({
                                ...prev,
                                selectedPatientReportedRecord: item.id,
                              }));
                            }}
                          />
                        </Popover>
                      )}
                      <CustomButton
                        title="Accept"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          const isCurrentFreeText =
                            !item.allergy?.coding?.length;
                          if (isCurrentFreeText && !isOpenChoice()) {
                            notification.error({
                              message:
                                'This free text allergy must be matched to database before it can be added to EHR',
                            });
                          } else {
                            handlePatientReportedActions(
                              PatientRecordActions.accept,
                              item
                            );
                          }
                        }}
                      />
                      <CustomButton
                        title="Discard"
                        isDisabled={componentState.showSavingIndicator}
                        onPress={() => {
                          handlePatientReportedActions(
                            PatientRecordActions.deny,
                            item
                          );
                        }}
                      />
                    </HStack>
                  )}
                </HStack>
              </VStack>
            );
          }}
        />
      );
    }
    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}
          <CustomDetailTableView
            dataSource={componentValue.allergies}
            columns={getColumns()}
            isPatientNote={isPatientNote}
            isBuilderMode={contextData.isBuilderMode}
            renderAdditionalView={renderAdditionalView}
          />
        </VStack>
      </VStack>
    );
  };

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
    error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
      ? intl.formatMessage({
          id: RESOURCE_BLOCK_MESSAGE_ID,
        })
      : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && { customError: errorMessage }),
      ...(!errorMessage && { nonBlockingFormError: FormError.addUpdateFail }),
    }));
  }

  const addOrUpdateRecord = (record: IAllergy) => {
    const clonedData = cloneDeep(record);
    if (clonedData.reactions) {
      clonedData.reactions = clonedData.reactions?.filter(
        (reaction) => !!reaction.manifestation
      );
    }
    if (!isInvalidRecord(clonedData)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        ...clonedData,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.ALLERGY,
        clonedData.id,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          let keepShowingSavingIndicator = false;
          if (!clonedData.id) {
            keepShowingSavingIndicator = true;
            getExistingData({patientId: contextData.patientId || '', skipLoader: true});
          }
          if (response?.data?.id) {
            updateDataFromAPIToList(response.data, clonedData);
          }
          setComponentState((prev) => ({
            ...prev,
            ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
            nonBlockingFormError: undefined,
          }));
        },
        (error) => {
          onAddOrUpdateError(error);
          // If new record is failed which is not saved before then only remove from local list
          if (!record.id) {
            removeFromLocal(record.uniqueId);
          }
        }
      );
    }
  };

  const handleSubmitAllAction = async () => {
    await bulkAction.handleBulkAction({
       resource: FHIR_RESOURCE.ALLERGY,
       action: PatientRecordActions.accept,
       getFilteredRecords: () => {
         const structuredRecords = componentState.patientReported.filter(
           (record) => !record.isFreeTextRecord
         );
         const FHIRformatedRecords = structuredRecords.map((record) =>
           getFHIRTemplate({
             ...record,
             patientId: contextData.patientId || '',
             tenantId: contextData.tenantId || '',
           })
         );
         return FHIRformatedRecords;
       },
       patientId: contextData.patientId || '',
       accountLocationUuid: contextData.locationId || '',
       onComplete: (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          getExistingData({patientId: contextData.patientId || '', skipLoader: true});
        }
       },
       onError: onAddOrUpdateError
     });
   };

  const handlePatientReportedActions = (action: PatientRecordActions, record: IAllergy) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
      tenantId: contextData.tenantId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.ALLERGY,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        // NOTE: This is done because when we send event from `Recent Clinical Event` the component view is not same as the current view. And is same when send event from PAMI component as well to handle this, we are sending the view type from the event itself in case of PAMI component so that the api call is made.
        const viewType = isRecentReported ? undefined : FormViewType.compact;
        broadcastUpdateEvent(viewType);
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // Here we only remove the specific Accepted entry so that it doesnot un-match the other matched records.
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList(response?.data, record, action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
        }));
      },
      onAddOrUpdateError
    );
  }

  const updateDataFromAPIToList = (data: any, record: IAllergy) => {
    if (record.id) return;

    setComponentValue((prev) => {
      const list = prev.allergies;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].id = data.id;
      }
      return {
        ...prev,
        allergies: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: IAllergy, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      setComponentValue((prev) => {
        const list = prev.allergies;
        record.id = data.id;
        list.splice(0, 0, record);
        return {
          ...prev,
          allergies: list,
        };
      });
    }
  };

  const getExistingData = (params: {patientId: string, skipLoader?: boolean, manualImport?: boolean, getInteractiveData?: boolean}) => {
    const { patientId, skipLoader, manualImport, getInteractiveData } = params;
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    const fetchInteractiveData = getInteractiveData || (isAthena && (isPatientNote || isPatientForm));
    getResourceContentWithHeaders(
      FHIR_RESOURCE.ALLERGY,
      `patient=${patientId}${isRecentReported ? `&patientReportedOnly=true` : ''}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response,getFieldCapabilities(), manualImport);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          formError: FormError.existingDataAPIFail,
        }));
      },
      locationId,
      !isPatientForm,
      fetchInteractiveData
    );
  };

  const handleExistingDataResponse = (response: any,fields?:IKeyOperation, manualImport?: boolean) => {
    if (isPatientForm) {
      if (response?.entry) {
        const formattedData = getRecordListFormatted(response.entry, fields);
        const sectionNote = getSectionNoteDetails(response.meta?.extension || []).sectionNote;
        const lastSynced = response?.meta?.lastUpdated;
        const nkda = getNKDADetails(response.meta?.extension || []);
        const newRecordsAddedInForm = props.component?.selectedValue?.allergies || [];
        setComponentValue((prev) => ({
          ...prev,
          allergies: [...newRecordsAddedInForm, ...formattedData],
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.ALLERGIES, { allergies: [ ...formattedData ] });

        setComponentState((prev) => ({
          ...prev,
          sectionNote: sectionNote,
          nkda: nkda,
          loading: false,
          showSavingIndicator: false,
          activePanels: formattedData?.length ? [componentKeys.ALLERGIES] : [],
          formError: !response?.entry
            ? FormError.existingDataAPIFail
            : prev.formError,
          lastUpdated: lastSynced,
        }));
      }
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      const lastSynced = response?.resource?.data?.meta?.lastUpdated;
      const nkda = getNKDADetails(response?.resource?.data?.meta?.extension || []);
      let patientReportedFormattedRecords: IAllergy[] = [];
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: IAllergy[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(patientReportedRecords,fields);
        }
        if (ehrRecords?.length) {
          ehrFormattedRecords = getRecordListFormatted(ehrRecords,fields);
          if (manualImport) {
            ehrFormattedRecords = filterAndSkipDuplicates<IAllergy>(
              componentValue.allergies,
              ehrFormattedRecords
            );
          };
        }
        setComponentValue((prev) => ({
          ...prev,
          allergies: [...ehrFormattedRecords]
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.ALLERGIES, { allergies: [ ...ehrFormattedRecords ] });
      }
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        nkda: nkda,
        showSavingIndicator: false,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.ALLERGIES] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
        lastUpdated: lastSynced,
      }));
    }
  }

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.ALLERGY,
      viewType || contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && patientId && !isPatientNote) {
        getExistingData({patientId: patientId, skipLoader: true});
      }
    },
    [contextData]
  );

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData({patientId: patientId, skipLoader: true, manualImport: true});
    }
  }

  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.ALLERGIES, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentValue.allergies.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.ALLERGIES, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    if (contextData.isBuilderMode) {
      setComponentValue(getSampleData());
      return;
    }
    if (patientId && !isPatientNote && (!props.component?.selectedValue || (isPatientForm && !props.disabled))) {
      getExistingData({patientId});
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(ADD_UPDATE_EVENTS.ALLERGY, onEventReceived);
    eventBus.addEventListener(SUPPORTED_EVENT_CODE.ORDER_CREATED, onEventReceived);
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    let list = cloneDeep(componentValue.allergies);
    if (!canUpdate()) {
      list = list.filter((item) => {
        return !item.id;
      });
      list.forEach((allergy) => {
        if (allergy.reactions) {
          allergy.reactions = allergy.reactions.filter((reaction) => !!reaction.manifestation);
        }
      })
    }
    props.onChange({allergies: list, total: componentValue.allergies.length});
  }, [componentValue]);

  const {acceptRecord, ignoredRecords} =
              getAcceptedAndIgnoredRecordNames(componentState.patientReported);

  props.validateRef.current = validateData;
  if (isPreviewMode) {
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!componentState.loading && !componentState.formError && (
          <>
            {componentValue.allergies.length > 0 && (
            <div className={` ${isAllowToShare ? '' : 'disallow-to-share'}`}>
            <DetailPreview titleLocalId={props.component.label}>
              <FlatList
                data={componentValue.allergies}
                keyExtractor={(item) => item.id || JSON.stringify(item)}
                renderItem={({item}) => (
                  <div className='page-break'>
                    <VStack my={1}>
                      <VStack>
                        <Text fontWeight={600}>{item.name}</Text>
                        <div style={reactStyles.displayFlex}>
                          <Text>
                            {item.onSetDateTime &&
                              `${intl.formatMessage({
                                id: onsetDateFormat === 'YYYY' ? 'sinceYear' : 'sinceDate',
                              })}: ${getDateStrFromFormat(
                                item.onSetDateTime,
                                onsetDateFormat || DISPLAY_DATE_FORMAT
                              )}`}
                          </Text>
                          {item.clinicalStatus && item.onSetDateTime && (
                            <Text> &#8226; </Text>
                          )}
                          <Text>
                            {item.clinicalStatus &&
                              `${intl.formatMessage({
                                id: 'status',
                              })}: ${capitalize(item.clinicalStatus.display || item.clinicalStatus.code || '')}`}
                          </Text>
                      </div>
                      </VStack>
                      <VStack>
                        {item.reactions && item.reactions.length > 0 && (
                          <>
                            {item.reactions.map((reaction) => {
                              return (
                                <div style={reactStyles.displayFlex}>
                                  {
                                    getAllergySeverityDisplayName(reaction.severity as AllergySeverity) &&
                                    <Text
                                    >
                                      {`Severity: ${getAllergySeverityDisplayName(
                                        reaction.severity as AllergySeverity
                                      )}`}
                                    </Text>
                                  }

                                  {
                                    getAllergySeverityDisplayName(reaction.severity as AllergySeverity) && reaction.manifestation?.display &&
                                    <Text> &#8226; </Text>
                                  }
                                  {
                                    reaction.manifestation?.display &&
                                    <Text>
                                      {`Reaction: ${reaction.manifestation?.display || ''}`}
                                    </Text>
                                  }
                                </div>
                              );
                            })}
                          </>
                        )}
                      </VStack>
                      {item.showNote && item.note && (
                        <Text>{`Note: ${item.note}`}</Text>
                      )}
                    </VStack>
                  </div>
                )}
              />
            </DetailPreview>
            </div>
            )}
          </>
        )}
      </>
    );
  }
  return (
    <View ref={componentRef}>
      {isCompactView ? (
        <VStack mx={isAthena? 3 : 0} background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end"
            expandIcon={({ isActive }) => {
            const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
            return (
              <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <RightOutlined rotate={isActive ? 90 : 0} />
              </Tooltip>
              )}}
          style={isAthena ? reactStyles.collapseStyleSync : reactStyles.collapseStyle}>
            <Panel forceRender key={componentKeys.ALLERGIES} header={
              <HStack
                alignItems={'center'}
                marginRight={4}
                justifyContent="space-between"
              >
                <HStack alignItems={'center'} space={2}>
                  <View>
                    <Text
                      color={Colors.FoldPixel.GRAY300}
                      size={'mdNormal'}
                      {...testID(props.component.leftSidebarLabel || props.component.label)}
                    >
                      {props.component.leftSidebarLabel || props.component.label}
                    </Text>
                    {renderLastSyncView()}
                  </View>
                </HStack>
                <View>{getHeaderRightView(isCompactView, !isShowAddOrUpdate)}</View>
              </HStack>}>
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.customError && (
                <FormComponentError error={FormError.custom} customError={componentState.customError} />
              )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView, false)}
              {!isAthena && <Divider />}
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <div className='page-break'>
        {isRecentReported ? (
          <RecentReportedViewHeader
            loading={componentState.loading}
            noData={!componentState.patientReported.length}
            title={props.component.label}
            buttonOnPress={() => {
              setComponentState((prev) => ({
                ...prev,
                showPatientReportedRecords: true,
              }));
            }}
          />) :
        <DetailViewCollapse
          btnList={[{
            icon: importFromChartLoading ? (
              <Spinner mr={1.5} />
            ) : (
              <Feather
                name="download"
                size={20}
                color={Colors.Custom.mainPrimaryPurple}
              />
            ),
            onIconBtnClick: fetchImportData,
            label: intl.formatMessage({id: 'importFromChart'}),
            tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
            isCustomButton: true,
            hide: !(isPatientNote && !isPreviewMode),
            disabled: importFromChartLoading,
          }]}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerRightView={getHeaderRightView(isCompactView)}
          headerStyle={{marginTop: 3}}
          extraTextStyles={isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}}
          extraPanelStyles={isPatientNote ? { borderColor:'#D0D5DD', ...CUSTOM_COMPONENT_STYLE} : {}}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {canAddNew() && isMobileScreen && componentValue.allergies.length > 0 && (
              <VStack marginY={4} space={2} flex={1} marginX={2}>
                {getSearchComponent()}
                {componentState.showSavingIndicator && (
                  <SectionLoadingIndicator />
                )}
              </VStack>
            )}
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(isCompactView, false)}
          </VStack>
        </DetailViewCollapse>
        }
        </div>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Allergies'}
            buttonList={[
              {
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    editModalVisible: false,
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
            />
          {getSectionAdditionalView(false, true)}
          </>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({...prev, editModalVisible: false}));
        }}
        open={componentState.editModalVisible}
        closable
        width={isSidecarContext ? '100%' : '60%'}
      >
        <DetailViewCollapse
          btnList={[]}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {canAddNew() && isMobileScreen && componentValue.allergies.length > 0 && (
              <VStack marginY={4} space={2} flex={1} marginX={2}>
                {getSearchComponent()}
                {componentState.showSavingIndicator && (
                  <SectionLoadingIndicator />
                )}
              </VStack>
            )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Allergies'}
            buttonList={[
              {
                isDisabled: componentState.showSavingIndicator,
                isLoading: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
              {
                isDisabled: componentState.showSavingIndicator || bulkAction.loading,
                isLoading: bulkAction.loading,
                onClick: () => bulkAction.handleConfirmation(true),
                show: organizationCapability?.canCreate && componentState.patientReported.filter(item => !item.isFreeTextRecord).length > 0,
                id: 2,
                btnText: intl.formatMessage({
                  id: 'acceptAll'
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator || bulkAction.loading
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: BUTTON_TYPE.PRIMARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        visible={componentState.showPatientReportedRecords}
        closable
        width={isSidecarContext ? '100%' : '50%'}
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
      {deleteConfirmation.modalVisible && (
       <PatientIntakeDeleteModal
        type={'Allergy'}
        visible={deleteConfirmation.modalVisible}
        onCancel={cancelDeletePrompt}
        onDelete={() => {
          onDelete(
            deleteConfirmation.selectedEntry?.item,
            deleteConfirmation.selectedEntry?.index as number,
            false
          );
        }}
        />
      )}
       {bulkAction.confirm && (
        <PatientIntakeConfirm
          title='Accept Patient Reported Data'
          onOk={() => handleSubmitAllAction()}
          onCancel={() => bulkAction.handleConfirmation(false)}
          acceptCount={acceptRecord.length}
          renderRecords={() => {
            return (
              <>
                {ignoredRecords.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following data reported by patient is unstructured and has
                      not been matched to a structured record. These will not be
                      updated in the EHR.
                    </Text>
                    {renderSectionBulletList(
                      `Unmatched records (${ignoredRecords.length} / ${componentState.patientReported.length})`,
                      ignoredRecords,
                      undefined,
                      {
                        backgroundColor: Colors.Custom.Red100,
                        borderColor: Colors.Custom.ErrorColor,
                      }
                    )}
                  </View>
                )}
                {acceptRecord.length > 0 && (
                  <View>
                    <Text mt={2.5}>Following records will be updated in the EHR.</Text>
                    {renderSectionBulletList(
                      `Matched records (${acceptRecord.length} / ${componentState.patientReported.length}) `,
                      acceptRecord
                    )}
                  </View>
                )}
              </>
            );
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdateAllergies;
