import React, { useState } from 'react';
import { FormElementInstance } from '../FormComponents/FormComponents';
import { v4 as uuidV4 } from 'uuid';
import { convertDotNotationToObjectForProperties, convertObjectToDotNotation, setInitialValues } from '../CustomFormEngineUtils';

interface UsePropertiesFormRendererProps {
  components: (Omit<FormElementInstance, 'id'> & { id?: string })[];
  initialValues?: Record<string, any>;
}

export const usePropertiesFormRenderer = ({
  components,
  initialValues = {},
}: UsePropertiesFormRendererProps) => {
  // States
  const [formData, setFormData] = useState<Record<string, any>>(setInitialValues(initialValues, components));
  const [formattedFormData, setFormattedFormData] = useState<Record<string, any>>(convertObjectToDotNotation(initialValues, components));
  const [updatedComponents] = useState<FormElementInstance[]>(components.map(component => ({
    ...component,
    id: component.id || uuidV4(),
  })));

  const handleFormDataChange = (data: Record<string, any>) => {
    const convertedFormData = convertDotNotationToObjectForProperties(data);
    setFormData(convertedFormData);
    setFormattedFormData(data);
  };

  return {
    formData,
    formattedFormData,
    components: updatedComponents,
    handleFormDataChange,
  };
};
