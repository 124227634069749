import React, { useMemo, useEffect } from "react";
import { FormElementInstance } from "../FormComponents/FormComponents";
import { ElementsType } from "../FormComponents/FormComponents";
import { FormElement } from "../FormComponents/FormComponents";
import { NoteStatusSchema } from "../Schema/ComponentsSchema";
import { getUniqueKey } from "../CustomFormEngineUtils";
import { cloneDeep } from "lodash";
import { SelectFieldFormElement } from "./SelectField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import { FormRenderer } from "../FormRenderer";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { SelectComponent } from "../BaseComponents/BaseComponentInterface";
import DropdownSvg from "../../../../../assets/Icons/FormBuilder/DropdownSvg";
import { v4 as uuidV4 } from 'uuid';

export const NoteStatusFieldFormElement: FormElement = {
  type: NoteStatusSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(NoteStatusSchema),
    key: getUniqueKey(map, NoteStatusSchema.key),
  }),
  clone: (
    id: string,
    instance: FormElementInstance,
    map: Map<string, boolean>
  ) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(NoteStatusSchema),
      ...clonedInstance,
      id,
      key,
    };
  },
  designerBtnElement: {
    icon: DropdownSvg,
    label: NoteStatusSchema.label || '',
  },
  designerComponent: SelectFieldFormElement.designerComponent,
  formComponent: SelectFieldFormElement.formComponent,
  propertiesComponent: PropertiesComponent,
};

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance as SelectComponent;
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    ...(isAllowShare ? ShareWithPatientFields : []),
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip:
        'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default NoteStatusFieldFormElement;
