import { View, Text, Pressable } from 'react-native'
import React, { useMemo, useState } from 'react'
import { Popover } from 'antd';
import Stack from '../../../../common/LayoutComponents/Stack';
import { Colors } from '../../../../../styles/Colors';
import { CaretDownOutlined } from '@ant-design/icons';
import { antdStyles } from './RiskScoreStyles';

interface IScoreFormulaSelectionProps {
  isDisabled?: boolean;
  value?: string;
  options: {
    label: string;
    value: string;
  }[];
  onActionPerformed?: (value: string) => void;
}

const ScoreFormulaSelection = (props: IScoreFormulaSelectionProps) => {
  const { isDisabled, value, options, onActionPerformed } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || options?.[0]?.value);
  const selectedOption = useMemo(() => options.find((option) => option.value === selectedValue), [options, selectedValue]);

  return (
    <Popover
      overlayClassName='scoreFormulaSelectionPopover'
      overlayInnerStyle={antdStyles.popoverStyleContent}
      overlayStyle={antdStyles.popoverStyleOverlay}
      overlay={antdStyles.popoverStyleOverlay}
      style={antdStyles.popoverStyle}
      placement={'bottomLeft'}
      trigger="click"
      showArrow={false}
      open={isPopoverOpen}
      onOpenChange={(visible) => {
        setIsPopoverOpen(visible);
      }}
      content={
        <Stack direction="column" space={12} style={{ padding: '2px', minWidth: 110 }}>
          {options.map((option) => (
            <Pressable
              key={option.value}
              onPress={() => {
                setIsPopoverOpen(false);
                setSelectedValue(option.value);
                onActionPerformed?.(option.value);
              }}
              style={{
                padding: '8px 16px',
              }}
              >
              <Text
                style={{
                  color: Colors.FoldPixel.GRAY400,
                  fontSize: 14,
                }}
              >
                {option.label}
              </Text>
            </Pressable>
          ))}
        </Stack>
      }
    >
      <Pressable
        onPress={() => setIsPopoverOpen(true)}
        disabled={isDisabled}
      >
        <Stack
          direction="row"
          style={
            {
              paddingLeft: 12,
              paddingRight: 4,
              paddingVertical: 3,
              borderRadius: 4,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(249, 245, 255)',
            }
          }
        >
          <Text
            style={{
              color: Colors.FoldPixel.PRIMARY300,
            }}
          >
            {`ƒx = ${selectedOption?.label}`}
          </Text>
          <View
            style={{
              width: 0.5,
              height: 12,
              marginLeft: 8,
              marginRight: 4,
              backgroundColor: Colors.FoldPixel.PRIMARY300,
            }}
          />
          {!isDisabled && (
            <CaretDownOutlined
              style={{
                fontSize: 12,
                color: Colors.FoldPixel.PRIMARY300,
              }}
            />
          )}
        </Stack>
      </Pressable>
    </Popover>
  )
}

export default ScoreFormulaSelection
