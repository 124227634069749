import {CheckIcon, HStack, Pressable, Text, VStack, View} from 'native-base';
import React, {useState} from 'react';
import AntIcon from 'react-native-vector-icons/AntDesign';
import {isWeb} from '../../../utils/platformCheckUtils';
import {ContactAutoComplete} from '../ContactRelationView';
import {DisplayText} from '../DisplayText/DisplayText';
import {ModalActionAntSelect} from '../ModalActionCommonComponent/ModalActionAntSelect';
import {ModalActionSelect} from '../ModalActionCommonComponent/ModalActionSelect';
import {styles} from './AddContactRelationViewStyles';
import { Checkbox, Tooltip } from 'antd';
import Stack from '../LayoutComponents/Stack';
import { cloneDeep } from 'lodash';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../styles/Colors';
import { testID, TestIdentifiers } from '../../../testUtils';

export interface IContactRelation {
  key: number,
  relationContactId: string,
  relationId: string,
  isCaregiver: boolean
}

const ContactRelationInput = (props: any) => {
  const [count, setCount] = useState(2);
  const [dataSource, setDataSource] = useState<any>([
    {
      key: 1,
      relationContactId: '',
      relationId: '',
      isCaregiver: false
    },
  ]);

  const addContact = () => {
    const newData: any = {
      key: count,
      relationContactId: '',
      relationId: '',
    };
    setCount((prv) => prv + 1);
    setDataSource([...dataSource, newData]);
    props.getInputValue(dataSource);
  };
  const deleteContact = (sItem: any) => {
    if (dataSource.length === 1) return;

    const newStageArray = dataSource.filter((item: any) => {
      return sItem.key !== item.key;
    });
    setDataSource(newStageArray);
    props.getInputValue(newStageArray);
  };


  const updateContactCareGiver = (itemIndex: number, isChecked: boolean) => {
    const updatedRelatedContactList = cloneDeep(dataSource) || [];
    if (!updatedRelatedContactList?.length) {
      return;
    }
    if (isChecked) {
      (updatedRelatedContactList || []).forEach((updatedRelatedContact: IContactRelation, index: number) => {
        if (index != itemIndex) {
          updatedRelatedContact.isCaregiver = false;
        } else {
          updatedRelatedContact.isCaregiver = true;
        }
      });
    } else {
      updatedRelatedContactList[itemIndex].isCaregiver = false;
    }
    setDataSource(updatedRelatedContactList);
    props.getInputValue(updatedRelatedContactList);
  };

  return (
    <>
      <HStack flex={1} alignItems={'center'} space={4}>
        <VStack flex={1}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} style={styles.heading}>Contact</Text>
        </VStack>
        <VStack width={220}>
          <Text size={'smRegular'} color={Colors.FoldPixel.GRAY250} style={styles.heading}>Relation</Text>
        </VStack>
        <VStack width={160}></VStack>
      </HStack>
      {dataSource.length > 0 ? (
        dataSource.map((stageItem: any, index: any) => {
          return (
            <HStack
              key={stageItem.key}
              flex={1}
              style={styles.singleItem}
              space={4}
            >
              <View flex={1}>
                <ContactAutoComplete
                  contactId={props.contactId}
                  contactData={dataSource}
                  accountLocationUuid={props?.accountLocationUuid}
                  onFormCompleteAction={props.onFormCompleteAction}
                  index={index}
                  onAutoCompleteChange={(action: any, data: any) => {
                    stageItem.relationContactId = parseInt(data?.key || '');
                    const newArr = dataSource;
                    dataSource[index].relationContactId =
                      stageItem.relationContactId;
                    dataSource[index].name = data?.name;
                    // newArr[index].key = stageItem.key;
                    setDataSource(newArr);
                    props.getInputValue(dataSource);
                  }}
                />
              </View>
              <ModalActionAntSelect
                showSearch={true}
                selectedValue={stageItem.relationTypeId}
                accessibilityLabel="Select Relation"
                placeholder="Select Relation"
                endIcon={<CheckIcon size="5" />}
                onChange={(itemValue: any) => {
                  stageItem.relationId = itemValue;
                  const newArr = dataSource;
                  dataSource[index].relationId = stageItem.relationId;
                  setDataSource(newArr);
                  props.getInputValue(dataSource);
                }}
                data={props?.personRelation}
                optionProps={{
                  key: 'id',
                  label: 'value',
                  value: 'id',
                }}
                customStyle={{flex: 1, width: 220}}
                filterOption={(input: string, option: any) => {
                  return (option?.children || '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
              <Stack
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
                direction="row"
              >
                <Checkbox
                 checked={stageItem.isCaregiver}
                 data-testid={'markCareGiver'}
                  onChange={(e)=> {
                    updateContactCareGiver(index, e?.target?.checked);
                  }}>
                    <Text size={'smRegular'}>Mark as caregiver </Text>
                    <Tooltip title={`Only one care giver can be added for a patient`}>
                      <Feather
                        name="info"
                        style={{
                          fontSize: 16,
                          color: Colors.Custom.Gray500,
                          }}
                          {...testID(TestIdentifiers.info)}
                        />
                  </Tooltip>
                </Checkbox>
                <Pressable
                  onPress={() => {
                    deleteContact(stageItem);
                  }}
                  {...testID(TestIdentifiers.closeBtn)}
                >
                  <AntIcon name="close" />
                </Pressable>
              </Stack>
            </HStack>
          );
        })
      ) : (
        <Text> ''</Text>
      )}
      {isWeb() && (
        <Pressable
          onPress={() => {
            addContact();
          }}
        >
          <DisplayText
            extraStyles={styles.addMoreStyle}
            textLocalId={'addMore'}
            testID={'addMore'}
          />
        </Pressable>
      )}
    </>
  );
};

export default ContactRelationInput;
