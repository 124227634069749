import { useMutation } from '@apollo/client';
import { Drawer } from 'antd';
import { Spinner, Text, TextArea, useMediaQuery, View } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { BUTTON_TYPE, IPAD_MINI_WIDTH, IPAD_WIDTH } from '../../../constants';
import { COMMON_ACTION_CODES } from '../../../constants/ActionConst';
import { NoteQueries } from '../../../services';
import { Colors } from '../../../styles';
import { getAccountId, getAllowedUserAccountLocationUuids, getBooleanFeatureFlag, getLocationGroupIdFromLocationsUsingUuid, getUserId, mapAndGetLocationGroupIds } from '../../../utils/commonUtils';
import { MdEditor } from '../../RightSideContainer/TeamInbox/Conversations/MessagingWindow/MdEditor/MdEditor';
import { ModalActionTitle } from '../ModalActionTitle/ModalActionTitle';
import StickyNotesVersion from '../StickNotes/StickyNoteVersions';
import { styles } from './AddNoteViewStyles';
import { useIntl } from 'react-intl';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../constants/WebSocketConst';
import { localBroadcastEvent } from '../../../utils/CustomEventHandler';
import { MAX_CONTACT_NOTE_CHAR_LIMIT } from './NotesConst';
import { CommonDataContext } from '../../../context/CommonDataContext';
import debounce from 'lodash/debounce';
import useContactProfile from '../ContactProfile/useContactProfile';
import { IContact, IContactProfile } from '../../RightSideContainer/TeamInbox/Conversations/interfaces';
import FeatureFlags from '../../../constants/FeatureFlags.enums';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { checkLocationAccess, getActivePatientProfile } from '../ContactProfile/commonUtils';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import { USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { IStickyNotes } from '../../PersonOmniView/LeftContainer/OtherDetails/interfaces';

const AddNoteView = (props: {
  isStickyNote?: boolean,
  titleText?: string,
  contactId?: string | number,
  contactData?: IContact,
  selectedData?: IStickyNotes,
  errorText?: string,
  onFormActionPerformed: (actionCode: string, actionData: any) => void,
}) => {
  const {isStickyNote, contactData} = props;
  const intl = useIntl();
  const commonData = React.useContext(CommonDataContext);
  const isLocationEnable = isAccountConfigEnabled(CONFIG_CODES.ENABLE_LOCATION_HANDLING);
  const isMultiTenancyEnabled = (getBooleanFeatureFlag(commonData.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED) || isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)) && isLocationEnable;
  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );
  const getLocationGroupIdFronAccountLocationId = (locationUuid?: string) => {
    if (!locationUuid) {
      return '';
    }
    return getLocationGroupIdFromLocationsUsingUuid(locationUuid, commonData?.accountLocationListWithEHR)
  }

  const [showModal, setShowModal] = useState(true);
  const [contactProfileData, setContactProfileData] = useState<{
    contactProfile: IContactProfile[], 
    isLoading: boolean
    activeProfileUuid?: IContactProfile,
    haveAccessToActiveProfile?: boolean;
    isNoteOfActiveLocation?: boolean;
    isReadOnly?: boolean;
  }>({
    contactProfile: [], 
    isLoading: isMultiTenancyEnabled,
    haveAccessToActiveProfile: true,
    isReadOnly: false,
  })
  const [richTextValue, setRichTextValue] = useState('');
  const [addNoteState, setAddNoteState] = useState({
    error: {
      invalid: '',
    },
    loading: false,
  });
  const contactId = props.contactId;

  const accountId = getAccountId();
  const userId = getUserId();
  const [createNote] = useMutation(NoteQueries.CreateNoteByContact);
  const [createNoteWithLocation] = useMutation(NoteQueries.CreateNoteWithLocation);
  const [updateNote] = useMutation(NoteQueries.UPDATE_NOTE);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const onCloseModal = () => {
    setShowModal(false);
    props.onFormActionPerformed(COMMON_ACTION_CODES.CANCEL, {});
  };
  const {getAllPatientContactProfiles} = useContactProfile({});

  const getContactProfileLocations = async () => {
    try {
      setContactProfileData((prev) => {
        return {
          ...prev,
          isLoading: true
        }
      })
      let contactProfileList: IContactProfile[];
      if (contactData) {
        contactProfileList = contactData?.contactProfiles || [];
      } else {
        const response = await getAllPatientContactProfiles({
          contactId
        });
        contactProfileList = response?.contactProfiles || [];
      }
      const activePatientProfile = getActivePatientProfile(contactProfileList || []);
      const activeContactProfileLocationGroupId = getLocationGroupIdFronAccountLocationId(activePatientProfile?.accountLocationUuid);
      const allowedUserAccountLocationGroupIds = mapAndGetLocationGroupIds(allowedUserAccountLocationUuids, commonData?.accountLocationListWithEHR);
      const haveAccessToActivePatientsLocationGroup = isMultiTenancyEnabled ? allowedUserAccountLocationGroupIds?.includes(activeContactProfileLocationGroupId) : true;

      let isNoteOfActiveLocation = true;
      if (props?.selectedData?.locationGroupId) {
        isNoteOfActiveLocation = activeContactProfileLocationGroupId === props?.selectedData?.locationGroupId;
      }

      let isReadOnly = !haveAccessToActivePatientsLocationGroup || !isNoteOfActiveLocation;
      if (props?.selectedData?.id) {
        isReadOnly = isReadOnly || props?.selectedData?.ehrPatientId !== activePatientProfile?.ehrPatientId;
      }
      
      setContactProfileData((prev) => {
        return {
          contactProfile: contactProfileList,
          isLoading: false,
          activeProfileUuid: activePatientProfile as IContactProfile,
          isNoteOfActiveLocation: isNoteOfActiveLocation,
          haveAccessToActiveProfile: haveAccessToActivePatientsLocationGroup,
          isReadOnly: isMultiTenancyEnabled ? isReadOnly : false,
        }
      })
    } catch {
      setContactProfileData((prev) => {
        return {
          ...prev,
          isLoading: false
        }
      })
    }
  }

  const createContactNote = async () => {
    const responseData = await createNote({
      variables: {
        accountId: accountId,
        contactId: contactId,
        content: richTextValue,
        userId: userId,
      },
    });
    
    if (responseData?.data?.createNote?.id) {
      props.onFormActionPerformed(COMMON_ACTION_CODES.COMPLETED, {});
      localBroadcastEvent(CONVERSATION_LOCAL_EVENT_CODES.ADD_CONTACT_NOTE, {
        contactNoteData: responseData?.data
      });
    } else {
      props.onFormActionPerformed(COMMON_ACTION_CODES.FAILED, {});
      setAddNoteState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  const updateContactNote = async () => {
    const responseData = await updateNote({
      variables: {
        accountId: accountId,
        content: richTextValue,
        id: props.selectedData?.id,
      },
    });
    if (responseData?.data?.updateNote?.id) {
      props.onFormActionPerformed(COMMON_ACTION_CODES.COMPLETED, {});
    } else {
      setAddNoteState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };

  const handleSendMessage = () => {
    setAddNoteState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    if (richTextValue.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
      if (props.selectedData && props.selectedData?.id) {
        if (
          props.selectedData &&
          props.selectedData?.content === richTextValue
        ) {
          setAddNoteState((prev) => {
            return {
              ...prev,
              loading: false,
              error: {
                invalid: intl.formatMessage({id: 'updateSomeContent'}),
              },
            };
          });
        } else {
          updateContactNote();
        }
      } else {
        createContactNote();
      }
    } else {
      setAddNoteState((prev) => {
        return {
          ...prev,
          error: {
            invalid: intl.formatMessage({id: 'enterSomeContent'}),
          },
        };
      });
      setAddNoteState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
  };
  const handleAddStickNote = () => {
    if (
      !props.selectedData?.contactNote?.content?.length &&
      !richTextValue.replace(/<(.|\n)*?>/g, '').trim().length
    ) {
      setAddNoteState((prev) => {
        return {
          ...prev,
          error: {
            invalid: intl.formatMessage({id: 'enterSomeContent'}),
          },
        };
      });
      return;
    }
    if (
      props.selectedData?.contactNote?.content?.length &&
      richTextValue === props.selectedData?.contactNote?.content
    ) {
      setAddNoteState((prev) => {
        return {
          ...prev,
          error: {
            invalid: intl.formatMessage({id: 'updateTheStickyNote'}),
          },
        };
      });
      return;
    }
    setAddNoteState((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
      if (props.selectedData && props.selectedData?.noteUuid) {
        props.onFormActionPerformed(COMMON_ACTION_CODES.EditNoteAction, {
          content: richTextValue,
        });
        setAddNoteState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      } else {
        props.onFormActionPerformed(COMMON_ACTION_CODES.AddNoteAction, {
          content: richTextValue,
        });
        setAddNoteState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      }

      setAddNoteState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
  };

  useEffect(() => {
    isMultiTenancyEnabled && getContactProfileLocations?.();

    if (isStickyNote) {
      if (
        props.selectedData &&
        (props.selectedData?.noteUuid ||
          props.selectedData?.contactNote?.content)
      ) {
        setRichTextValue(props.selectedData?.contactNote?.content || '');
      } else {
        setRichTextValue('');
      }
    } else {
      if (
        props.selectedData &&
        (props.selectedData?.id || props.selectedData?.content)
      ) {
        setRichTextValue(props.selectedData?.content || '');
      } else {
        setRichTextValue('');
      }
    }
  }, []);

  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const handledButtonDisabled = () => {
    if (props.selectedData?.contactNote?.content === richTextValue) {
      return true;
    }
    if (
      props.selectedData?.contactNote?.content?.length !== 0 &&
      richTextValue?.length === 0
    ) {
      //This condition is for we are giving option to clear sticky note as it is not mandatory
      return false;
    } else {
      if (richTextValue && !props?.errorText?.length) {
        return false;
      } else {
        return true;
      }
    }
  };
  const buttonTextUpdateAdd =
    props.selectedData?.id || props.selectedData?.noteUuid ? 'Update' : 'Add';

  const {width} = Dimensions.get('window');

  const finalWidth = isSidecarContext
    ? '100%'
    : isIPadMiniScreen || isIPadScreen
    ? width / 2
    : width / 3;
  const getHeaderText =
    props.selectedData &&
    (props.selectedData?.id || props.selectedData?.noteUuid)
      ? isStickyNote
        ? 'upDateStickyNote'
        : 'upDateNote'
      : isStickyNote
      ? 'addStickNote'
      : 'addContactNote';

  const onSubmit = () => {
    if (isStickyNote) {
      handleAddStickNote();
    } else {
      handleSendMessage();
    }
  };

  const getInputElement = () => {
    if (contactProfileData?.isReadOnly) { 
      return null;
    }
    return (
      isStickyNote ? (
        <TextArea
          defaultValue=""
          variant={'outline'}
          value={richTextValue}
          style={styles.textArea}
          placeholder={'Enter notes...'}
          nativeID={'stickyNotes'}
          height={176}
          maxH={176}
          width={'99%'}
          autoComplete="off"
          onChangeText={(plainString) => {
            if(plainString.length > MAX_CONTACT_NOTE_CHAR_LIMIT){
              setAddNoteState((prev) => {
                return {
                  ...prev,
                  error: {
                    invalid: intl.formatMessage({id: 'maxContactNoteError'})
                  },
                };
              });
            }
            else {
              setRichTextValue(plainString);
              setAddNoteState((prev) => {
                return {
                  ...prev,
                  error: {
                    invalid:
                      props.selectedData?.contactNote?.content === plainString
                        ? intl.formatMessage({id: 'updateTheStickyNote'})
                        : '',
                  },
                };
              });
            }

          }}
        />
      ) : (
        <MdEditor
          valueStr={richTextValue}
          isEnterToSubmit={true}
          onRichTextAction={() => {
            handleSendMessage();
          }}
          onChangesValue={(value) => {
            if(value.length > MAX_CONTACT_NOTE_CHAR_LIMIT){
              setAddNoteState((prev) => {
                return {
                  ...prev,
                  error: {
                    invalid: intl.formatMessage({id: 'maxContactNoteError'})
                  },
                };
              });
            }
            else {
              setRichTextValue(value);
              if(addNoteState.error?.invalid){
                setAddNoteState((prev) => {
                  return {
                    ...prev,
                    error: {
                      invalid: ''
                    },
                  };
                });
              }
            }
          }}
        />
      )
    )
  }

  const isLoading = addNoteState.loading || contactProfileData?.isLoading;

  const getTitle = () => {
    if (isLoading) {
      return ""
    }
    if (!contactProfileData?.isReadOnly) {
        return getHeaderText;
    } else {
        return "stickyNotes";
    }
  }

  return (
    <Drawer
      visible={showModal}
      width={finalWidth}
      onClose={onCloseModal}
      closable={false}
      mask={isSidecarContext ? false : true}
      title={
        <ModalActionTitle
          title={getTitle()}
          titleColor={''}
          titleSize={24}
          buttonList={isLoading ? [] : [
            {
              show: true,
              id: 1,
              btnText: isSidecarContext ? 'back' : contactProfileData?.isReadOnly ? 'close' : 'cancel',
              textColor: Colors.Custom.mainSecondaryBrown,
              variant: BUTTON_TYPE.SECONDARY,
              isTransBtn: false,
              onClick: onCloseModal,
            },
            {
              show: !contactProfileData?.isReadOnly,
              id: 2,
              btnText: buttonTextUpdateAdd,
              isDisabled: handledButtonDisabled() || addNoteState?.loading,
              textColor: Colors.Custom.mainPrimaryPurple,
              variant: BUTTON_TYPE.PRIMARY,
              isTransBtn: false,
              onClick: onSubmit,
            },
          ]}
        />
      }
    >
      {isLoading ? (
        <Spinner size="lg" style={styles.spinnerStyle} />
      ) : (
        <>
          <View mx={0} flex={1}>
            {getInputElement()}
            {(addNoteState.error.invalid)&& (
              <Text
                size={'sMedium'}
                style={styles.errorText}
                color={Colors.danger['500']}
              >
                {addNoteState.error.invalid}
              </Text>
            )}
          </View>
          {isStickyNote ? (
            <View style={styles.versionText}>
              <StickyNotesVersion
                selectedNote={props.selectedData}
                showSelectedNote={contactProfileData?.isReadOnly}
                noteUuid={props.selectedData?.noteUuid || ''} 
              />{' '}
            </View>
          ) : (
            <></>
          )}
        </>
      )}
    </Drawer>
  );
};

export default AddNoteView;
