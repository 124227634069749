import React from 'react';
import {ICommonSvgProps} from '../interfaces';
import {isWeb} from '../../../../utils/platformCheckUtils';
import {Image} from 'native-base';
import Svg, {Path} from 'react-native-svg';

const CriteriaMatchSvg = (props: ICommonSvgProps) => {
  return (
    <Svg width="16" height="15" viewBox="0 0 16 15" fill="none">
      <Path d="M6.02026 7.47976C5.825 7.2845 5.50842 7.2845 5.31315 7.47976C5.11789 7.67502 5.11789 7.9916 5.31315 8.18687L6.02026 7.47976ZM7.00004 9.16665L6.64649 9.5202C6.84175 9.71546 7.15833 9.71546 7.35359 9.5202L7.00004 9.16665ZM10.6869 6.18687C10.8822 5.9916 10.8822 5.67502 10.6869 5.47976C10.4917 5.2845 10.1751 5.2845 9.97982 5.47976L10.6869 6.18687ZM14.1667 7.49998C14.1667 10.9057 11.4058 13.6666 8.00004 13.6666V14.6666C11.9581 14.6666 15.1667 11.458 15.1667 7.49998H14.1667ZM8.00004 13.6666C4.59428 13.6666 1.83337 10.9057 1.83337 7.49998H0.833374C0.833374 11.458 4.042 14.6666 8.00004 14.6666V13.6666ZM1.83337 7.49998C1.83337 4.09422 4.59428 1.33331 8.00004 1.33331V0.333313C4.042 0.333313 0.833374 3.54194 0.833374 7.49998H1.83337ZM8.00004 1.33331C11.4058 1.33331 14.1667 4.09422 14.1667 7.49998H15.1667C15.1667 3.54194 11.9581 0.333313 8.00004 0.333313V1.33331ZM5.31315 8.18687L6.64649 9.5202L7.35359 8.81309L6.02026 7.47976L5.31315 8.18687ZM7.35359 9.5202L10.6869 6.18687L9.97982 5.47976L6.64649 8.81309L7.35359 9.5202Z" fill="#12B76A"/>
    </Svg>
  )
};

export default CriteriaMatchSvg;
