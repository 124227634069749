import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SocialHistorySvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M.25 16c0-3.778 0-6.587.29-8.745.29-2.15.863-3.618 1.98-4.735C3.637 1.403 5.104.83 7.255.54 9.413.25 12.222.25 16 .25c3.778 0 6.587 0 8.745.29 2.15.29 3.618.863 4.735 1.98 1.117 1.117 1.69 2.584 1.98 4.735.29 2.158.29 4.967.29 8.745 0 3.778 0 6.587-.29 8.745-.29 2.15-.863 3.618-1.98 4.735-1.117 1.117-2.584 1.69-4.735 1.98-2.158.29-4.967.29-8.745.29-3.778 0-6.587 0-8.745-.29-2.15-.29-3.618-.863-4.735-1.98C1.403 28.363.83 26.896.54 24.745.25 22.587.25 19.778.25 16z"
        fill="#F5F0FF"
      />
      <Path
        d="M.25 16c0-3.778 0-6.587.29-8.745.29-2.15.863-3.618 1.98-4.735C3.637 1.403 5.104.83 7.255.54 9.413.25 12.222.25 16 .25c3.778 0 6.587 0 8.745.29 2.15.29 3.618.863 4.735 1.98 1.117 1.117 1.69 2.584 1.98 4.735.29 2.158.29 4.967.29 8.745 0 3.778 0 6.587-.29 8.745-.29 2.15-.863 3.618-1.98 4.735-1.117 1.117-2.584 1.69-4.735 1.98-2.158.29-4.967.29-8.745.29-3.778 0-6.587 0-8.745-.29-2.15-.29-3.618-.863-4.735-1.98C1.403 28.363.83 26.896.54 24.745.25 22.587.25 19.778.25 16z"
        stroke="#D7C0FF"
        strokeWidth={0.5}
      />
      <Path
        d="M19.365 8.5c1.365 0 2.488 1.15 2.488 2.567 0 1.416-1.115 2.558-2.488 2.558m3.83 7.158c1.138-.516 1.138-1.383 1.138-2.358 0-1.433-1.817-2.633-4.193-2.85M17.361 11c0 1.842-1.446 3.333-3.231 3.333-1.786 0-3.232-1.491-3.232-3.333s1.446-3.333 3.232-3.333c1.785 0 3.231 1.491 3.231 3.333zm3.232 9.583c0 2.075 0 3.75-6.463 3.75s-6.463-1.675-6.463-3.75 2.892-3.75 6.463-3.75c3.57 0 6.463 1.675 6.463 3.75z"
        stroke="#8C5AE2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default React.memo(SocialHistorySvg);
