import React, { useContext, useEffect, useMemo, useRef } from "react";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  FormElements,
  SubmitFunction,
  ValidationFunction,
} from "../FormComponents/FormComponents";
import { FormRenderer } from "../FormRenderer";
import { ColumnsComponent } from "../BaseComponents/BaseComponentInterface";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { ColumnsSchema } from "../Schema/ComponentsSchema";
import { useDroppable } from "@dnd-kit/core";
import ElementWrapper from "../CustomFormBuilder/ElementWrapper";
import { cloneDeep } from "lodash";
import { v4 as uuidV4 } from 'uuid';
import EmptyDroppableView from "../BaseComponents/EmptyDroppableView";
import { testID } from "../../../../../testUtils/Utils";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { useMediaQuery } from "native-base";
import { IPAD_MINI_WIDTH, IPAD_WIDTH } from "../../../../../constants";
import classNames from "classnames";
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";
import ColumnSvg from "../../../../../assets/Icons/FormBuilder/ColumnSvg";
import FormElementWrapper from "../CustomFormBuilder/FormElementWrapper";
import { useContainerDimensions } from "../../../../CustomHooks/ContainerDimensionHook";

export const ColumnFieldFormElement: FormElement = {
  type: ColumnsSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(ColumnsSchema),
    key: getUniqueKey(map, ColumnsSchema.key),
    columns: [
      { size: 'md', width: 6, components: [] },
      { size: 'md', width: 6, components: [] },
    ]
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance) as ColumnsComponent;
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    clonedInstance.columns = clonedInstance.columns.map(column => ({
      ...column,
      components: column.components.map(component =>
        FormElements[component.type as ElementsType].clone(uuidV4(), component, map)
      )
    }));
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(ColumnsSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: ColumnSvg,
    label: ColumnsSchema.label || 'Columns',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const ColumnDroppableField: React.FC<{
  elementInstance: FormElementInstance;
  index: number;
  column: {
    size: string;
    width: number;
    components: FormElementInstance[];
  };
}> = ({elementInstance, index, column}) => {
  const dropZoneId = `drop-zone-${elementInstance.id}-column-${index}`;
  const dropZoneConfig = useMemo(() => ({
    id: dropZoneId,
    data: {
      type: elementInstance.type,
      elementId: elementInstance.id,
      columnIndex: index,
      isDroppableColumn: true,
    },
  }), [elementInstance.id, elementInstance.type, index]);

  const container = useDroppable(dropZoneConfig);

  return (
    <div
      key={index}
      className={`flex-1 column-container`}
      style={{ flex: column.width }}
      {...testID(`column-${index}`)}
    >
      <div
        id={dropZoneId}
        ref={container.setNodeRef}
        className="min-h-64px"
      >
        {column.components.length === 0 ? (
          <div className="flex w-full">
            <EmptyDroppableView isActive={container.isOver} />
          </div>
        ) : (
          <div className="nested-column padding-4">
            {column.components.map((element, componentIndex) => (
              <ElementWrapper key={element.id} element={element} isLastElement={componentIndex === column.components.length - 1} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const columnElement = elementInstance as ColumnsComponent;

  const componentRef = useRef();
  const { width, resetDimension } = useContainerDimensions(componentRef);
  const { state } = useCustomFormBuilderContext();
  const isMobileScreen = width <= 480;

  useEffect(() => {
    resetDimension();
  }, [state.selectedElement]);

  return (
    <div
      className="flex w-full flex-col column-field"
      {...testID('builder-column-field')}
      ref={componentRef as unknown as React.RefObject<HTMLDivElement>}
    >
      <div className={classNames(["flex gap-4", isMobileScreen ? "flex-col" : "flex-row"])}>
        {columnElement.columns.map((column, index) => {
          return (
            <ColumnDroppableField
              key={`${elementInstance.id}-column-${index}`}
              elementInstance={elementInstance}
              index={index}
              column={column}
            />
          );
        })}
      </div>
      {columnElement.description && (
        <div className="padding-4">
          <Description description={columnElement.description} />
        </div>
      )}
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  isInvalid,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  isInvalid?: boolean;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const {state} = useFormRendererContext();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const element = elementInstance as ColumnsComponent;
  const [isIPadScreen, isIPadMiniScreen] = useMediaQuery([
    {maxWidth: IPAD_WIDTH},
    {maxWidth: IPAD_MINI_WIDTH},
  ]);

  const isSmallScreen = contextData.isMobileView || isIPadMiniScreen || isIPadScreen;

  return (
    <div
      className="flex w-full flex-col column-field"
      {...testID('form-column-field')}
    >
      <div className={classNames(["flex", isSmallScreen ? "flex-col gap-2" : "flex-row gap-4"])}>
        {element.columns.map((column, index) => (
          <div
            key={`${elementInstance.id}-column-${index}-${isReadOnly ? 'readonly' : 'editable'}`}
            className={"flex-1 column-container"}
            style={{ flex: column.width }}
            {...testID(`column-form-${index}`)}
          >
            {column.components.map((element, index) => {
              const NestedFormComponent = FormElements[element.type as keyof typeof FormElements]?.formComponent;
              if (!NestedFormComponent) return null;
              return (
                <FormElementWrapper key={`${element.id}-${isReadOnly ? 'readonly' : 'editable'}`} element={element} hideBottomPadding={index === column.components?.length - 1}>
                  <NestedFormComponent
                    elementInstance={element}
                    submitValue={submitValue}
                    isReadOnly={isReadOnly}
                    defaultValue={state.filledDataMap[element.key]}
                    onRegisterValidation={onRegisterValidation}
                  />
                </FormElementWrapper>
              );
            })}
          </div>
        ))}
      </div>
      {element.description && (
        <div className="padding-4">
          <Description description={element.description} />
        </div>
      )}
    </div>
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const {state, dispatch, userSettings} = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance as ColumnsComponent;
  const formElements: (Omit<FormElementInstance, 'id'> & { id?: string })[] = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      validate: {
        required: true,
      },
    },
    ...SearchableComponentFields,
    {
      type: 'select',
      key: 'numberOfColumns',
      label: 'Number of Columns',
      defaultValue: '2',
      data: {
        values: [
          {label: '2', value: '2'},
          {label: '3', value: '3'},
        ],
      },
    },
    ...(isAllowShare ? ShareWithPatientFields : []),
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: {updatedElement: formData, builderComponents: state.elements},
    });
  }, [formData]);

  return (
    <div {...testID('column-field-properties')}>
      <FormRenderer
        builderComponents={state.elements}
        components={components}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
