import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  HStack,
  View,
  VStack,
  Text,
  Select,
  Pressable,
  Spacer,
  Divider,
  FlatList,
  Spinner,
  Stack,
} from 'native-base';
import { Collapse, Tooltip } from 'antd';
import {
  IAddOrUpdateFamilyHistoryState,
  IFamilyHistory,
  IFamilyHistoryComponentValue,
} from '../interfaces';
import {useIntl} from 'react-intl';
import {CommonDataContext} from '../../../../../../../context/CommonDataContext';
import {
  FormContext,
  FormViewType,
  IFormCommonData,
  IFormComponentProps,
} from '../../CustomWrapper/CustomWrapper';
import {
  fhirAddOrUpdate,
  getResourceContentWithHeaders,
  acceptIgnorePatientReportedRecords,
  deleteResource,
} from '../../../../../../../services/CommonService/AidBoxService';
import FormComponentLoader from '../../CustomWrapper/FormComponentLoader/FormComponentLoader';
import {
  FamilyHXFields,
  getConditionName,
  getRecordListFormatted,
  getFHIRTemplate,
  getGroupedHXByRelation,
  isInvalid,
  getSampleData,
  getAdditionalDetails,
} from './AddOrUpdateFamilyHistoryHelper';
import FamilyHistoryTable from '../FamilyHistoryTable/FamilyHistoryTable';
import {v4 as uuidv4} from 'uuid';
import {FHIR_RESOURCE, PatientRecordActions, RESOURCE_BLOCK_MESSAGE_ID} from '../../../../../../../constants/FhirConstant';
import FormComponentError from '../../CustomWrapper/FormComponentError/FormComponentError';
import Feather from 'react-native-vector-icons/Feather';
import {
  ADD_UPDATE_EVENTS,
  componentKeys,
  FormError,
  getHeaderButtons,
  tooltipInnerOverlayStyle,
  tooltipOverlayStyle,
  tooltipPlacement,
  getSectionNoteDetails,
  getHeaderViewAllButton,
  CapabilityResource,
} from '../../CustomWrapper/CustomComponentHelper';
import {Colors} from '../../../../../../../styles';
import SectionLoadingIndicator from '../../CustomWrapper/SectionLoadingIndicator/SectionLoadingIndicator';
import {DetailViewCollapse} from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailViewCollapse';
import {Drawer, Popover, notification} from 'antd';
import {EmptyStateSvg} from '../../../../../../common/Svg';
import {EventBus} from '../../../../../../../utils/EventBus';
import CustomButton from '../../CustomWrapper/CustomButton/CustomButton';
import { DisplayText } from '../../../../../../common/DisplayText/DisplayText';
import ModalActionBtn from '../../../../../../common/ModalActionBtn/ModalActionBtn';
import PatientReportedTableView from '../../CustomWrapper/PatientReportedTableView/PatientReportedTableView';
import PAMISearch, { PAMISearchType } from '../../../../../../common/PAMISearch/PAMISearch';
import { BUTTON_TYPE, HTTP_ERROR_CODE } from '../../../../../../../constants';
import { InputType } from '../../../../../../../utils/capabilityUtils';
import { useContainerDimensions } from '../../../../../../CustomHooks/ContainerDimensionHook';
import { cloneDeep } from 'lodash';
import { ModalActionTitle } from '../../../../../../common/ModalActionTitle/ModalActionTitle';
import { CUSTOM_COMPONENT_STYLE } from '../../constants';
import {FoldButton} from '../../../../../../CommonComponents/FoldButton/FoldButton';
import DetailPreview, { ICustomComponentPreviewData } from '../../../../../../PersonOmniView/MiddleContainer/PersonDetailsView/DetailPreview/DetailPreview';
import { RightOutlined } from '@ant-design/icons';
import SectionLevelDetails from '../../CustomWrapper/SectionLevelDetails/SectionLevelDetails';
import { IFormValidationOutput } from '../../CustomWrapper/interfaces';
import { getOrganizationCapabilites } from '../../../../../TeamInbox/Conversations/MessagingContactDetails/ContactDetailsTabs/ActivityTimeLine/ActivityAdditionalInfo/CommonComponent/FormResponse/FormUtils';
import useBulkActionPatientReportedRecords from '../../../../../../CustomHooks/useBulkActionPatientReportedRecords';
import PatientIntakeConfirm from '../../../../../../common/PatientIntakeConfirm/PatientIntakeConfirm';
import RecentReportedViewHeader from '../../../../../../common/RecentReportedViewHeader/RecentReportedViewHeader';
import { getAcceptedAndIgnoredRecordNamesForFamilyHistory } from '../../../../../../common/PatientIntakeConfirm/utils';
import { renderSectionBulletList } from '../../../../../../common/CommonUtils/CommonUtils';
import { filterAndSkipDuplicates, showAddorUpdate } from '../../CustomComponentUtils';
import { reactStyles, styles } from '../../../../Analytics/style';
import { isSideCarWindow } from '../../../../../../../constants/BaseUrlConst';
import { IEhrCapability } from '../../../../../../../Interfaces';
import {EHRName} from '../../../../../../PersonOmniView/MiddleContainer/PatientNotes/interfaces';
import { isAccountConfigEnabled } from '../../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../../constants/AccountConfigConst';
import { getBooleanFeatureFlag } from '../../../../../../../utils/commonUtils';
import FeatureFlags from '../../../../../../../constants/FeatureFlags.enums';
import {FormLastSyncView} from '../../../../../../common/FormLastSyncView/FormLastSyncView';
import {formatSyncDate} from '../../../../../Contracts/HelperFiles/utils';
import { ReconcileRequestCountView } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/ReconcileRequestCountView';
import { RECONCILIATION_RESOURCE_TYPES } from '../../../../../../PersonOmniView/LeftContainer/RecentActivity/PendingHieRequests/HieRequestsUtils';

const AddOrUpdateFamilyHistory = (props: IFormComponentProps) => {
  const intl = useIntl();
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isSidecarContext = isSideCarWindow()
  const organizationCapability = getOrganizationCapabilites(
    contextData.organizationCapability,
    FHIR_RESOURCE.CLINICAL_DATA
  );
  const isPatientForm = contextData.formContext === FormContext.patientForm;
  const isPatientCarePlan = contextData.formContext === FormContext.patientCarePlan;
  const isPatientNote = contextData.formContext === FormContext.patientNotes;
  const isPatientProfile = contextData.formContext === FormContext.patientProfile;
  const isRecentReported = contextData.formContext === FormContext.recentReportedView;
  const openInEhrUrl = contextData.openEhrUrl;
  const isCompactView = contextData.componentView === FormViewType.compact;
  const componentRef = useRef();
  const drawerDetailRef = useRef();
  const bulkAction = useBulkActionPatientReportedRecords();
  const isPreviewMode = contextData.isPreviewMode || false;
  const isAllowToShare = props?.component?.allowToShare != undefined &&  props?.component?.allowToShare === false ? false : true;
  const { width } = useContainerDimensions(componentRef);
  const { width: drawerDetailWidth, resetDimension } = useContainerDimensions(drawerDetailRef);
  const isMobileScreen = isCompactView ? drawerDetailWidth <= 480 : width <= 480;
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED)
  const isMultiTenancyEnabled = getBooleanFeatureFlag(contextData?.userSettings, FeatureFlags.IS_MULTI_TENANCY_ENABLED);
  const ehrActivePatient = contextData.ehrActivePatient || props.options?.ehrActivePatient;
  const isShowAddOrUpdate = isCompactView ? showAddorUpdate(isMultiTenancyEnabled,isCompactView ,props?.options, contextData ,ehrActivePatient) : true;
  const patientId = isMultiTenancyEnabled ? ehrActivePatient?.ehrPatientId : contextData.patientId ;
  const locationId = isMultiTenancyEnabled ? ehrActivePatient?.accountLocationUuid : contextData.locationId ;
  const [componentValue, setComponentValue] =
    useState<IFamilyHistoryComponentValue>(
      props.component?.selectedValue || {history: []}
    );
  const [componentState, setComponentState] =
    useState<IAddOrUpdateFamilyHistoryState>({
      loading: false,
      formError: contextData.formError,
      collapsed: false,
      showSavingIndicator: false,
      editModalVisible: false,
      editModalCollapse: false,
      showPatientReportedRecords: false,
      selectedPatientReportedRecord: undefined,
      selectedItemToMap: undefined,
      patientReported: [],
      hasIncompleteData: false,
      sectionNote: '',
      activePanels: [],
      lastUpdated: '',
    });
  const [importFromChartLoading, setImportFromChartLoading] = useState<boolean>(false);

  const {Panel} = Collapse
  const capabilities = useMemo(() => contextData.ehrCapabilities.find((ehr: IEhrCapability) => ehr.resourceName === CapabilityResource.familyHistory), [contextData.ehrCapabilities]);
  const isAthena = capabilities?.ehrName === EHRName.ATHENA;
  const getFieldCapabilities = () => {
    return capabilities?.abilities?.keyAllowedOperations;
  };
  const resourceTypes = useMemo(() => {
    return RECONCILIATION_RESOURCE_TYPES.includes(FHIR_RESOURCE.FAMILY_MEMBER_HISTORY) ? [FHIR_RESOURCE.FAMILY_MEMBER_HISTORY] : [];
  }, []);

  const validateData = (currentData: IFamilyHistoryComponentValue): IFormValidationOutput => {
    // If no patient id is not found means it's lead and for lead, the section is not applicable. So validation should always return true
    if (componentState.formError === FormError.noPatientIdFound) {
      return {isValid: true, message: ''};
    }
    const hasMissingData = currentData?.history?.some((item) => {
      return isInvalidRecord(item);
    });
    return { isValid: !hasMissingData, message: hasMissingData ? `${props.component.label}: Please fill all the mandatory fields` : '' };
  };

  const isInvalidRecord = (item: IFamilyHistory) => {
    const config = getFieldCapabilities();
    return (
      isInvalid(FamilyHXFields.relationShip, item, true, config) ||
      isInvalid(FamilyHXFields.condition, item, true, config)
    );
  };

  const getRelationshipList = () => {
    return getFieldCapabilities()?.relationship?.possibleValues || [];
  };

  const canAddNew = (): boolean => {
    if(isPatientProfile && organizationCapability?.canCreate === false){
      return false
    }
    return (
      contextData.isBuilderMode ||
      ((capabilities?.abilities?.allowedOperations?.canCreate ||
        false) &&
        !props.disabled)
    );
  };

  const canUpdate = (): boolean => {
    if(isPatientProfile && organizationCapability?.canUpdate === false){
      return false
    }

    return (
      (capabilities?.abilities?.allowedOperations?.canUpdate ||
        false) &&
      !props.disabled &&
      !isPatientForm
    );
  };

  const onDetailChange = (data: IFamilyHistory) => {
    onRecordChange(data);
    const index = componentValue.history.findIndex(
      (item) => item.uniqueId === data.uniqueId
    );
    setComponentValue((prev) => {
      const list = prev.history;
      list[index] = data;
      return {
        ...prev,
        history: list,
      };
    });
  };
  const canDelete = (): boolean => {
    if (isPatientCarePlan) {
      return true;
    }
    if(isPatientProfile && organizationCapability?.canDelete === false){
      return false
    }
    return (
      capabilities?.abilities?.allowedOperations?.canDelete || false
    );
  };
  const removeFromLocal = (data:IFamilyHistory) => {
    const index = componentValue.history.findIndex(
      (item) => item.uniqueId === data.uniqueId
    );
    componentValue.history.splice(index, 1);
    setComponentValue((prev) => ({
      ...prev,
      history: [...componentValue.history],
    }));
  }
  const onDelete = (data: IFamilyHistory,isLocalDelete:boolean) => {
    if (!isLocalDelete && data.id) {
      deleteResource(
        FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
        `${data.id}`,
        !contextData.hasLoggedInContext,
        contextData.headers,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          if (response?.success) {
            removeFromLocal(data);
            broadcastUpdateEvent();
          }
        },
        (error) => {

          setComponentState((prev) => ({
            ...prev,
            loading: false,
            showSavingIndicator: false,
            formError: FormError.existingDataAPIFail,
          }));
        }
      );
    } else {
      removeFromLocal(data);
    }
  };

  const onAddNew = (data: IFamilyHistory) => {
    const isRelationAlreadyAdded = componentValue.history.some((item) => {
      return item.relationship.code === data.relationship.code && !data.condition
    });
    if (isRelationAlreadyAdded) {
      return
    }
    componentValue.history.push(data);
    onRecordChange(data);
    setComponentValue({
      history: [...componentValue.history],
    });
  };

  const onRecordChange = (record: IFamilyHistory) => {
    if (contextData.backgroundSaveEnabled) {
      addOrUpdateRecord(record);
    }
  };
  const canShowDeleteBtn = (itemId?: string): boolean => {
    if(isPatientNote || isPatientCarePlan){
      return true;
    }
    if (isPatientForm) {
      return !itemId;
    }
    return canDelete() || contextData.isBuilderMode;
  };
const getDeleteFunction = (item: IFamilyHistory) => {
  const isLocalDelete =
    (isPatientForm && !item.id) ||
    contextData.isBuilderMode ||
    !contextData.backgroundSaveEnabled;

  if (isLocalDelete) {
    onDelete(item, true);
  } else if (canDelete()) {
    onDelete(item, false);
  }
};

  const getIncompleteDataMessageUI = (isHorizontal: boolean) => {
    return (
      <HStack
        backgroundColor={Colors.Custom.infoBannerBgColor}
        rounded={'lg'}
        p={2}
        m={2}
        w={isHorizontal ? '98%' : undefined}
      >
        <Feather name="info" size={20} color={Colors.FoldPixel.INFO_COLOR} />
        <Stack flexDir={isHorizontal ? 'row' : 'column'} ml={2} flex={1}>
          <Text fontWeight={200}>
            {intl.formatMessage({id: 'familyHxDataNotRetrieved'})}
          </Text>
          {isHorizontal && <Spacer />}
          <Text
            color={Colors.FoldPixel.INFO_COLOR}
            onPress={() => window.open(openInEhrUrl, '_blank', 'noopener,noreferrer')}
          >
            {intl.formatMessage({
              id: 'openInEHR',
            })}
            <Feather
              name="external-link"
              size={18}
              style={styles.marginLeft2}
              color={Colors.FoldPixel.INFO_COLOR}
            />
          </Text>
        </Stack>
      </HStack>
    );
  };
  const renderLastSyncView = (hideSync?: boolean) => {
    return (<>
      {isAthena ? <View>
        <View marginBottom={hideSync ? 4 : 0}>
          <FormLastSyncView
            onFetchData={()=> {
              getExistingData({patientId: contextData.patientId || '', getInteractiveData: true});
              setComponentState((prev)=> {
                return {
                  ...prev,
                  isFirstSync: false
                }
              })
            }}
            isFirstSync={componentState?.isFirstSync}
            isLoading={componentState?.loading}
            lastUpdated={componentState?.lastUpdated}
            hideSync={hideSync ? hideSync : !componentState?.activePanels?.includes("familyHistory")}
          />
        </View>
      </View> : <></>}
      </>)
  }
  const getSearchComponent = (fullWidth?: boolean): JSX.Element | undefined => {
    return (
      <Pressable
        width={'100%'}
        paddingRight={fullWidth ? 6 : 0}
        onPress={(event) => event.stopPropagation()}
      >
        <View
          flex={1}
          style={!isMobileScreen ? {width: fullWidth ? '100%' : 350} : {}}
        >
          <Select
            placeholder={'Add new relation'}
            onValueChange={(value: string) => {
              if (value) {
                const list = getRelationshipList();
                const relation = list.find((status: any) => {
                  return status.code === value;
                });
                if (relation) {
                  const data: IFamilyHistory = {
                    condition: undefined,
                    relationship: relation,
                    uniqueId: uuidv4(),
                    isFreeTextRecord: false,
                  };
                  onAddNew(data);
                }
              }
            }}
          >
            {getRelationshipList().map((option: any, index: number) => {
              return (
                <Select.Item
                  key={`${option.code}_${index}`}
                  value={option.code}
                  label={option.display}
                />
              );
            })}
          </Select>
        </View>
      </Pressable>
    );
  };

  const getHeaderRightView = (isCompact: boolean, readonlyAddBtn?: boolean): JSX.Element | undefined => {
    if (!canAddNew()) {
      if (isCompact) {
        return getHeaderViewAllButton(() => {
          if (isAthena) {
            getExistingData({patientId: contextData.patientId || '', getInteractiveData: true});
          }
          setComponentState((prev) => ({...prev, editModalVisible: true, isFirstSync: false}));
        }, 'View all');
      } else {
        return;
      }
    }

    const customTooltip = readonlyAddBtn ? intl.formatMessage({id: 'historyLocationChangeError'}) : undefined;

    if (isCompact) {
      return getHeaderButtons(() => {
        if (isAthena) {
          readonlyAddBtn ? null : getExistingData({patientId: contextData.patientId || '', getInteractiveData: true});
        }
        readonlyAddBtn ? null : setComponentState((prev) => ({...prev, editModalVisible: true, isFirstSync: false}));
      }, customTooltip, undefined, readonlyAddBtn );
    } else if (!isMobileScreen) {
      return (
        <HStack space={2} alignItems="center">
          <Spacer />
          {componentState.showSavingIndicator && <SectionLoadingIndicator />}
        </HStack>
      );
    }
  };

  const getSectionAdditionalView = (isCompactView: boolean, showSync?: boolean) => {
    const fieldCapabilities = getFieldCapabilities();
    if (fieldCapabilities?.sectionNote) {
      return (
        <VStack paddingTop={isCompactView ? 3 : 1} paddingBottom={isCompactView ? 1 : 0}>
          {showSync && renderLastSyncView(true)}
          <SectionLevelDetails
            organizationCapabilites={organizationCapability}
            patientId={contextData.patientId || ''}
            contactId={contextData?.contactIntId || ''}
            accountLocationId={contextData.locationId}
            fieldCapabilities={fieldCapabilities}
            resource={FHIR_RESOURCE.FAMILY_MEMBER_HISTORY}
            note={componentState.sectionNote || ''}
            onChange={(text) => setComponentState(prev => ({...prev, sectionNote: text}))}
          />
        </VStack>
      );
    }
    return;
  }

  const getDetailView = (isCompact: boolean, isPatientReported: boolean): JSX.Element => {
    if (!componentValue.history.length && !isPatientReported) {
      if (isCompact) {
        return (
          <VStack paddingX={4} space={2} paddingY={2}>
            {componentState.patientReported.length > 0 && (
              <HStack marginBottom={4} marginTop={2}>
                <CustomButton
                  onPress={() => {
                    setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
                  }}
                  title= {'View Patient Reported Records'}
                  />
                <Spacer />
              </HStack>
            )}
            {!componentState.patientReported.length && (
              <View marginLeft={2} marginBottom={1} mt={1}>
                <DisplayText
                  extraStyles={{
                    color: '#667085',
                  }}
                  textLocalId={'noFamilyHistory'}
                  textType="Text"
                />
              </View>
            )}
            {isMultiTenancyEnabled && isCompactView && resourceTypes.length > 0 && (
              <ReconcileRequestCountView
                resourceTypes={resourceTypes}
                patientId={patientId}
                contactUuid={contextData.contactId}
                accountLocationId={locationId}
              />
            )}
            {getSectionAdditionalView(true)}
          </VStack>
        );
      }

      return (
        <VStack paddingBottom={5} alignItems={'center'}>
          {componentState.hasIncompleteData &&
            !isPatientForm &&
            getIncompleteDataMessageUI(true)}
          <EmptyStateSvg titleId="noFamilyHistory" />
          <HStack space={2} alignItems="center">
            <Spacer />
            {componentState.showSavingIndicator && <SectionLoadingIndicator />}
            {canAddNew() && getSearchComponent()}
          </HStack>
        </VStack>
      );
    }

    if (isCompact) {
      const groupedHistoryByRelation = getGroupedHXByRelation(
        componentValue.history
      );

      return (
        <VStack paddingX={4} space={2} paddingY={4} marginBottom={4}>
          {componentState.patientReported.length > 0 && (
            <HStack marginBottom={4} marginTop={2}>
              <CustomButton
                onPress={() => {
                  setComponentState((prev) => ({...prev, showPatientReportedRecords: true}));
                }}
                title= {'View Patient Reported Records'}
                />
              <Spacer />
            </HStack>
          )}
          {groupedHistoryByRelation.map((data, index) => {
            return (
              <VStack space={2} flex={1} key={`${data.relation}_${index}`} marginTop={!data.relation ? 4 : 0}>
                <Text flex={1} color={Colors.FoldPixel.GRAY300} size="smLight">
                  {data.relation}
                </Text>
                <VStack space={3} paddingLeft={!data.relation ? 0 : 4}>
                  {data.list.map((item, innerIndex) => {
                    return (
                      <VStack>
                        <Text color={Colors.FoldPixel.GRAY400} flex={1} key={`${item.id}_${innerIndex}`}>
                          {getConditionName(item)}
                        </Text>
                        {getAdditionalDetails(item, true).length > 0 && (
                          <Text color={Colors.FoldPixel.GRAY400} fontSize={12}>{getAdditionalDetails(item, true)}</Text>
                        )}
                      </VStack>
                    );
                  })}
                </VStack>
              </VStack>
            );
          })}
          {getSectionAdditionalView(true)}
        </VStack>
      );
    } else if (isPatientReported) {
      const groupedHistoryByRelation = getGroupedHXByRelation(
        componentState.patientReported
      );
      return (
        <VStack paddingX={4} space={4} paddingY={2}>
          {groupedHistoryByRelation.map((data, index) => {
            return (
              <VStack flex={1} space={4} key={`${data.relation}_${index}`}>
                <Text flex={1} color="gray.500" size="smLight">
                  {data.relation}
                </Text>
                <VStack paddingLeft="4" space={4}>
                  {data.list.map((item, innerIndex) => {
                    return (
                      <View key={`${item.id}_${innerIndex}`}>
                        <HStack
                          space={1}
                          key={`${item.id}_${innerIndex}`}
                          alignItems="start"
                        >
                          <Text flex={1}>{getConditionName(item)}</Text>
                          {organizationCapability?.canCreate && (
                            <HStack space={2}>
                              {item.isFreeTextRecord && (
                                <Popover
                                  key={item.id || index}
                                  overlayInnerStyle={{
                                    bottom: 3,
                                    borderRadius: 16,
                                    padding: 0,
                                  }}
                                  content={
                                    <View minWidth={300} maxW={400} padding={2}>
                                      <VStack space={8}>
                                        <Text size="xlMedium">
                                          Match to Problem
                                        </Text>
                                        <VStack space={1}>
                                          <DisplayText
                                            textLocalId={'problem'}
                                            size="smMedium"
                                          />
                                          <PAMISearch
                                            searchType={PAMISearchType.problem}
                                            additionalHeaders={
                                              contextData.headers
                                            }
                                            placeholder="Search Problem"
                                            isShowError={false}
                                            onChange={(value) => {
                                              setComponentState((prev) => ({
                                                ...prev,
                                                selectedItemToMap: value,
                                              }));
                                            }}
                                            locationId={contextData.locationId}
                                          />
                                        </VStack>
                                        <HStack>
                                          <Spacer />
                                          <Button.Group space={2}>
                                            <FoldButton
                                              nativeProps={{
                                                variant: BUTTON_TYPE.PRIMARY,
                                                onPress: () => {
                                                  setComponentState((prev) => {
                                                    const list =
                                                      prev.patientReported;
                                                    const index =
                                                      list.findIndex(
                                                        (value) =>
                                                          value.uniqueId ===
                                                          item.uniqueId
                                                      );
                                                    if (index > -1) {
                                                      list[index].condition =
                                                        componentState.selectedItemToMap;
                                                      list[index].isFreeTextRecord = false;
                                                    }
                                                    return {
                                                      ...prev,
                                                      patientReported: list,
                                                      selectedItemToMap:
                                                        undefined,
                                                      selectedPatientReportedRecord:
                                                        undefined,
                                                    };
                                                  });
                                                },
                                                isDisabled:
                                                  !componentState.selectedItemToMap,
                                              }}
                                              customProps={{
                                                btnText: intl.formatMessage({
                                                  id: 'continue',
                                                }),
                                                withRightBorder: false,
                                              }}
                                            ></FoldButton>
                                          </Button.Group>
                                        </HStack>
                                      </VStack>
                                    </View>
                                  }
                                  title=""
                                  trigger="click"
                                  visible={
                                    componentState.selectedPatientReportedRecord ==
                                    item.id
                                  }
                                  onVisibleChange={(visible) => {
                                    setComponentState((prev) => ({
                                      ...prev,
                                      selectedPatientReportedRecord: visible
                                        ? item.id
                                        : undefined,
                                    }));
                                  }}
                                  placement={'bottom'}
                                >
                                  <CustomButton
                                    title="Match to Problem"
                                    isDisabled={
                                      componentState.showSavingIndicator
                                    }
                                    onPress={() => {
                                      setComponentState((prev) => ({
                                        ...prev,
                                        selectedPatientReportedRecord: item.id,
                                      }));
                                    }}
                                  />
                                </Popover>
                              )}
                              {<CustomButton
                                title="Accept"
                                isDisabled={componentState.showSavingIndicator}
                                onPress={() => {
                                  const isCurrentFreeText =
                                    !item.condition?.coding?.length;
                                  if (isCurrentFreeText && !isOpenChoice()) {
                                    notification.error({
                                      message:
                                        'This free text problem must be matched to database before it can be added to EHR',
                                    });
                                  } else {
                                    handlePatientReportedActions(
                                      PatientRecordActions.accept,
                                      item
                                    );
                                  }
                                }}
                              />}
                              {<CustomButton
                                title="Discard"
                                isDisabled={componentState.showSavingIndicator}
                                onPress={() => {
                                  handlePatientReportedActions(
                                    PatientRecordActions.deny,
                                    item
                                  );
                                }}
                              />}
                            </HStack>
                          )}
                        </HStack>
                        {innerIndex !== data.list.length - 1 && (
                          <Divider marginTop={4} />
                        )}
                      </View>
                    );
                  })}
                </VStack>
                <Divider />
              </VStack>
            );
          })}
      </VStack>
      );
    }
    return (
      <VStack flex={1} space={4}>
        <VStack space={2}>
          {canAddNew() && !isMobileScreen && (
            <HStack width={'100%'} margin={3} space={2} alignItems="center">
              {getSearchComponent(true)}
            </HStack>
          )}
          <FamilyHistoryTable
            disabled={props.disabled}
            showEHRMessage={componentState.hasIncompleteData && !isPatientForm}
            openInEhrUrl={openInEhrUrl}
            canUpdate={canUpdate()}
            canCreate={canAddNew()}
            metaData={{
              relationshipList: getRelationshipList(),
              fields: capabilities?.abilities?.keyAllowedOperations,
              allowFreeText: isPatientForm || contextData.isBuilderMode || isOpenChoice(),
            }}
            items={componentValue.history}
            showErrors={true}
            onAddNew={onAddNew}
            onDetailChange={onDetailChange}
            canDelete={() => canDelete()}
            canShowDeleteBtn={(id?:string) => canShowDeleteBtn(id)}
            getDeleteFunction={(item:IFamilyHistory) => getDeleteFunction(item)}
          />
        </VStack>
      </VStack>
    );
  };

  const isOpenChoice = () => {
    const fields = getFieldCapabilities();
    return fields?.condition?.inputType == InputType.openChoice;
  }

  const onAddOrUpdateError = (error: any) => {
    const errorMessage =
      error?.response?.status === HTTP_ERROR_CODE.METHOD_NOT_ALLOWED
        ? intl.formatMessage({
            id: RESOURCE_BLOCK_MESSAGE_ID,
          })
        : error?.response?.data?.message;
    setComponentState((prev) => ({
      ...prev,
      showSavingIndicator: false,
      ...(errorMessage && {customError: errorMessage}),
      ...(!errorMessage && {nonBlockingFormError: FormError.addUpdateFail}),
    }));
  }

  const addOrUpdateRecord = (record: IFamilyHistory) => {
    if (!isInvalidRecord(record)) {
      setComponentState((prev) => ({...prev, showSavingIndicator: true}));
      const fhirData = getFHIRTemplate({
        ...record,
        patientId: contextData.patientId || '',
        tenantId: contextData.tenantId || '',
        freeTextPatientHistoryName: record?.condition?.text || ''
      });
      fhirAddOrUpdate(
        FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
        record.id,
        fhirData,
        {
          patientId: contextData.patientId,
          locationId: contextData.locationId,
        },
        (response) => {
          broadcastUpdateEvent();
          if (response?.data?.id) {
            updateDataFromAPIToList(response.data, record);
          }
          setComponentState((prev) => ({
            ...prev,
            showSavingIndicator: false,
            nonBlockingFormError: undefined,
          }));
        },
        (error) => {
          onAddOrUpdateError(error);
          // If new record is failed which is not saved before then only remove from local list
          if (!record.id) {
            removeFromLocal(record);
          }
        }
      );
    }
  };

  const handleSubmitAllAction = async () => {
    await bulkAction.handleBulkAction({
       resource: FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
       action: PatientRecordActions.accept,
       getFilteredRecords: () => {
         const structuredRecords = componentState.patientReported.filter(
           (record) => !record.isFreeTextRecord
         );
         const FHIRformatedRecords = structuredRecords.map((record) =>
           getFHIRTemplate({
             ...record,
             patientId: contextData.patientId || '',
             tenantId: contextData.tenantId || '',
             freeTextPatientHistoryName: record?.condition?.text || ''
           })
         );
         return FHIRformatedRecords;
       },
       patientId: contextData.patientId || '',
       accountLocationUuid: contextData.locationId || '',
       onComplete: (response) => {
        broadcastUpdateEvent();
        if (response?.data?.id || response?.data?.success) {
          getExistingData({patientId: patientId || '', skipLoader: true});
        }
       },
        onError: onAddOrUpdateError
     });
   };

  const handlePatientReportedActions = (action: PatientRecordActions, record: IFamilyHistory) => {
    setComponentState((prev) => ({...prev, showSavingIndicator: true}));
    const fhirData = getFHIRTemplate({
      ...record,
      patientId: contextData.patientId || '',
      tenantId: contextData.tenantId || '',
    });
    acceptIgnorePatientReportedRecords(
      FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
      action,
      record.id,
      fhirData,
      {
        patientId: contextData.patientId,
        locationId: contextData.locationId,
      },
      (response) => {
        // NOTE: This is done because when we send event from `Recent Clinical Event` the component view is not same as the current view. And is same when send event from PAMI component as well to handle this, we are sending the view type from the event itself in case of PAMI component so that the api call is made.
        const viewType = isRecentReported ? undefined : FormViewType.compact;
        broadcastUpdateEvent(viewType);
        let keepShowingSavingIndicator = false;
        if (response?.data?.id || response?.data?.success) {
          if (action === PatientRecordActions.accept) {
            keepShowingSavingIndicator = true;
            // Here we only remove the specific Accepted entry so that it doesnot un-match the other matched records.
            setComponentState((prev) => ({
              ...prev,
              patientReported: prev.patientReported.filter(
                (item) => item.id !== record.id
              ),
              showSavingIndicator: false
            }));
          } else {
            updatePatientReportedDataFromAPIToList(response?.data, record, action);
          }
        }
        setComponentState((prev) => ({
          ...prev,
          ...(!keepShowingSavingIndicator && {showSavingIndicator: false}),
          nonBlockingFormError: undefined,
        }));
      },
      onAddOrUpdateError
    );
  }

  const updateDataFromAPIToList = (data: any, record: IFamilyHistory) => {
    if (record.id) return;

    setComponentValue((prev) => {
      const list = prev.history;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list[index].id = data.id;
      }
      return {
        ...prev,
        history: list,
      };
    });
  };

  const updatePatientReportedDataFromAPIToList = (data: any, record: IFamilyHistory, action: PatientRecordActions) => {
    setComponentState((prev) => {
      const list = prev.patientReported;
      const index = list.findIndex(
        (value) => value.uniqueId === record.uniqueId
      );
      if (index > -1) {
        list.splice(index, 1);
      }
      return {
        ...prev,
        patientReported: list,
        showPatientReportedRecords: list.length > 0
      };
    });
    if (action === PatientRecordActions.accept && data?.id) {
      setComponentValue((prev) => {
        const list = prev.history;
        record.id = data.id;
        list.splice(0, 0, record);
        return {
          ...prev,
          history: list,
        };
      });
    }
  };

  const getExistingData = (params: {patientId: string, skipLoader?: boolean, manualImport?: boolean, getInteractiveData?: boolean}) => {
    const {patientId, skipLoader, manualImport, getInteractiveData} = params;
    const fetchInteractiveData = getInteractiveData || (isAthena && (isPatientNote || isPatientForm));
    if (!skipLoader) {
      setComponentState((prev) => ({...prev, loading: true}));
    }
    getResourceContentWithHeaders(
      FHIR_RESOURCE.FAMILY_MEMBER_HISTORY,
      `patient=${patientId}${isRecentReported ? `&patientReportedOnly=true` : ''}`,
      !contextData.hasLoggedInContext,
      contextData.headers,
      (response) => {
        handleExistingDataResponse(response, manualImport);
      },
      (error) => {

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          showSavingIndicator: false,
          formError: FormError.existingDataAPIFail,
        }));
      },
      locationId,
      !isPatientForm,
      fetchInteractiveData
    );
  };

  const handleExistingDataResponse = (response: any, manualImport?: boolean) => {
    if (isPatientForm) {
      if (response?.entry) {
        const formattedData = getRecordListFormatted(response.entry);
        const sectionNote = getSectionNoteDetails(response.meta?.extension || []).sectionNote;
        const lastSynced = response?.meta?.lastUpdated;
        const newRecordsAddedInForm = props.component?.selectedValue?.history || [];
        setComponentValue((prev) => ({
          ...prev,
          history: [...newRecordsAddedInForm, ...formattedData.list],
        }));

        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.FAMILY_HISTORY, { history: [ ...formattedData.list ]});

        setComponentState((prev) => ({
          ...prev,
          loading: false,
          sectionNote: sectionNote,
          hasIncompleteData: formattedData.hasIncompleteData,
          showSavingIndicator: false,
          activePanels: formattedData?.list?.length ? [componentKeys.FAMILY_HISTORY] : [],
          formError: !response?.entry
            ? FormError.existingDataAPIFail
            : prev.formError,
          lastUpdated: lastSynced,
        }));
      }
    } else {
      const patientReportedRecords = response?.patientReported?.data?.entry || [];
      const ehrRecords = response?.resource?.data?.entry || [];
      const sectionNote = getSectionNoteDetails(response?.resource?.data?.meta?.extension || []).sectionNote;
      const lastSynced = response?.resource?.data?.meta?.lastUpdated;
      let patientReportedFormattedRecords: IFamilyHistory[] = [];
      let incompleteData = false;
      if (patientReportedRecords || ehrRecords) {
        let ehrFormattedRecords: IFamilyHistory[] = [];
        if (patientReportedRecords?.length) {
          patientReportedFormattedRecords = getRecordListFormatted(patientReportedRecords).list;
        }
        if (ehrRecords?.length) {
          const data = getRecordListFormatted(ehrRecords);
          ehrFormattedRecords = data.list;
          incompleteData = data.hasIncompleteData;

          if (manualImport) {
            ehrFormattedRecords = filterAndSkipDuplicates<IFamilyHistory>(
              componentValue.history,
              ehrFormattedRecords
            );
          };
        }
        setComponentValue((prev) => ({
          ...prev,
          history: [...ehrFormattedRecords]
        }));
        contextData?.updateFormPrefilledByFoldProgress?.(componentKeys.FAMILY_HISTORY, { history: [ ...ehrFormattedRecords ]});
      }
      setImportFromChartLoading(false);
      setComponentState((prev) => ({
        ...prev,
        loading: false,
        sectionNote: sectionNote,
        showSavingIndicator: false,
        hasIncompleteData: incompleteData,
        ...(!patientReportedFormattedRecords.length && {showPatientReportedRecords: false}),
        patientReported: patientReportedFormattedRecords,
        activePanels: ehrRecords?.length || patientReportedFormattedRecords?.length ? [componentKeys.FAMILY_HISTORY] : [],
        formError: !ehrRecords || !patientReportedRecords
          ? FormError.existingDataAPIFail
          : prev.formError,
        lastUpdated: lastSynced,
      }));
    }
  }

  const broadcastUpdateEvent = (viewType?: FormViewType) => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(
      ADD_UPDATE_EVENTS.FAMILY_HISTORY,
      viewType || contextData.componentView
    );
  };

  const onEventReceived = useCallback(
    (data) => {
      if (data != contextData.componentView && contextData?.patientId && !isPatientNote) {
        getExistingData({patientId: patientId, skipLoader: true});
      }
    },
    [contextData]
  );

  const fetchImportData = () => {
    if (isPatientNote && !isPreviewMode && contextData.patientId) {
      setImportFromChartLoading(true);
      getExistingData({patientId: patientId, skipLoader: true, manualImport: true});
    }
  }

  useEffect(() => {
    if (isRecentReported) {
      contextData?.updateLoadingStatus?.(componentKeys.FAMILY_HISTORY, componentState.loading, !!componentState.patientReported.length);
      return;
    }
    const isDataAvailable = !!componentValue.history.length || !!componentState.patientReported.length;
    contextData?.updateLoadingStatus?.(componentKeys.FAMILY_HISTORY, componentState.loading, isDataAvailable);
  }, [componentState.loading]);

  useEffect(() => {
    if (componentState.editModalVisible) {
      resetDimension();
    }
  }, [componentState.editModalVisible]);

  useEffect(() => {
    if (contextData.isBuilderMode) {
      setComponentValue(getSampleData());
      return;
    }
    if (patientId && !isPatientNote && (!props.component?.selectedValue || (isPatientForm && !props.disabled))) {
      getExistingData({patientId});
    }
    const eventBus = EventBus.getEventBusInstance();
    eventBus.addEventListener(
      ADD_UPDATE_EVENTS.FAMILY_HISTORY,
      onEventReceived
    );
    return () => {
      eventBus.removeEventListener(onEventReceived);
    };
  }, []);

  useEffect(() => {
    let list = componentValue.history;
    if (!canUpdate()) {
      list = componentValue.history.filter((item) => {
        return !item.id;
      });
    }
    props.onChange(cloneDeep({history: list, total: componentValue.history.length}));
  }, [componentValue]);

  props.validateRef.current = validateData;


  const groupedHistoryByRelation = getGroupedHXByRelation(
    componentState.patientReported
  );
   const {acceptRecord, ignoredRecords} =
     getAcceptedAndIgnoredRecordNamesForFamilyHistory(
       groupedHistoryByRelation
     );
  const acceptedRecordCount = acceptRecord.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.list.length,
    0
  );
  const ignoreRecordCount = ignoredRecords.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.list.length,
    0
  );

  const getPreviewData = (): ICustomComponentPreviewData[] => {
    const data = getGroupedHXByRelation(
      componentValue.history
    );
    const previewData: ICustomComponentPreviewData[] = [];
    data.forEach((item) => {
      const relation = item.relation;
      const list = item.list.map((item) => getConditionName(item)).join(', ');
      const previewItem: ICustomComponentPreviewData = {
        title: `${relation}: ${list}` || '',
        id: uuidv4(),
      };
      previewData.push(previewItem);
    });
    return previewData;
  };
  if (isPreviewMode) {
    return (
      <>
        {componentState.loading && (
          <View padding={4}>
            <FormComponentLoader />
          </View>
        )}
        {!componentState.loading && !componentState.formError && (
          <>
            {componentValue.history.length > 0 && (
              <div className={`${isAllowToShare ? '' : 'disallow-to-share'}`}>
                <DetailPreview titleLocalId={props.component.label}>
                  <FlatList
                    data={getPreviewData()}
                    initialNumToRender={(getPreviewData() || [])?.length}
                    keyExtractor={(item) => item.id}
                    renderItem={({item}) => (
                      <div className='page-break'>
                        <Text>{item.title}</Text>
                      </div>
                    )}
                  />
                </DetailPreview>
                </div>
            )}
          </>
        )}
      </>
    );
  }
  return (
    <View ref={componentRef}>

      {isCompactView ? (
        <VStack marginTop={isAthena ? 3 : 0} mx={isAthena? 3 : 0} background={Colors.Custom.BackgroundColor}>
          <Collapse
            activeKey={componentState.activePanels}
            onChange={(activePanels) => {
              setComponentState((prev) => ({...prev, activePanels: activePanels as string[]}));
            }}
            expandIconPosition="end"
            expandIcon={({ isActive }) => {
              const tooltipText = isActive ? intl.formatMessage({id: 'collapse'}) : intl.formatMessage({id:'expand'});
              return (
                <Tooltip title={tooltipText} placement={tooltipPlacement} showArrow={false} overlayStyle={tooltipOverlayStyle} overlayInnerStyle={tooltipInnerOverlayStyle}>
                <RightOutlined rotate={isActive ? 90 : 0} />
              </Tooltip>
            )
          }}
            style={isAthena ? reactStyles.collapseStyleSync : reactStyles.collapseStyle3}>
            <Panel forceRender key={componentKeys.FAMILY_HISTORY} header={
              <HStack
                alignItems={'center'}
                marginRight={4}
                justifyContent="space-between"
              >
                <HStack alignItems={'center'} space={2}>
                  <View>
                    <Text
                      size={'mdNormal'}
                      color={Colors.FoldPixel.GRAY300}
                      fontWeight={500}
                    >
                      {props.component.label}
                    </Text>
                    {renderLastSyncView()}
                  </View>
                </HStack>
                <View>{getHeaderRightView(isCompactView, !isShowAddOrUpdate)}</View>
              </HStack>}>
              {
              componentState.hasIncompleteData &&
                !isPatientForm &&
                  getIncompleteDataMessageUI(false)
              }
              {componentState.loading && (
                <View padding={4}>
                  <FormComponentLoader />
                </View>
              )}
              {!componentState.loading && componentState.customError && (
                 <FormComponentError error={FormError.custom} customError={componentState.customError} />
               )}
              {!componentState.loading && componentState.formError && (
                <FormComponentError error={componentState.formError} />
              )}
              {!componentState.loading && componentState.nonBlockingFormError && (
                <FormComponentError error={componentState.nonBlockingFormError} />
              )}
              {!componentState.loading &&
                !componentState.formError &&
                getDetailView(isCompactView, false)}
              {!isAthena && <Divider />}
            </Panel>
          </Collapse>
        </VStack>
      ) : (
        <div className='page-break'>
       { isRecentReported ? (
        <RecentReportedViewHeader
          loading={componentState.loading}
          noData={!componentState.patientReported.length}
          title={props.component.label}
          buttonOnPress={() => {
            setComponentState((prev) => ({
              ...prev,
              showPatientReportedRecords: true,
            }));
          }}
        />
        ) :
        <DetailViewCollapse
          btnList={[
            {
              icon: importFromChartLoading ? (
                <Spinner mr={1.5} />
              ) : (
                <Feather
                  name="download"
                  size={20}
                  color={Colors.Custom.mainPrimaryPurple}
                />
              ),
              onIconBtnClick: fetchImportData,
              label: intl.formatMessage({id: 'importFromChart'}),
              tooltipLabel: intl.formatMessage({id: 'importFromChartOverrideInfo'}),
              isCustomButton: true,
              hide: !(isPatientNote && !isPreviewMode),
              disabled: importFromChartLoading,
            }
          ]}
          textLocalId={props.component.label}
          toggled={!componentState.collapsed}
          headerRightView={getHeaderRightView(isCompactView)}
          headerStyle={{marginTop: 3}}
          extraTextStyles={
            isPatientNote ? {fontWeight: 'bold', fontSize: 16} : {}
          }
          extraPanelStyles={
            isPatientNote
              ? {borderColor: '#D0D5DD', ...CUSTOM_COMPONENT_STYLE}
              : {}
          }
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              collapsed: !prev.collapsed,
            }));
          }}
        >
          <VStack>
            {canAddNew() &&
              isMobileScreen &&
              componentValue.history.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && (
              <View padding={4}>
                <FormComponentLoader />
              </View>
            )}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(isCompactView, false)}
          </VStack>
        </DetailViewCollapse>
        }
        </div>
      )}
      <Drawer
        title={
          <>
          <ModalActionTitle
            isHeadNotSticky
            title={'Family History'}
            buttonList={[
              {
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    editModalVisible: false,
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: 'close',
                }),
                size: 'sm',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
              },
            ]}
          />
          {getSectionAdditionalView(false, true)}
          </>
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({...prev, editModalVisible: false}));
        }}
        open={componentState.editModalVisible}
        closable
        width={isSidecarContext ? '100%' : '50%'}
      >
        <DetailViewCollapse
          btnList={[]}
          textLocalId={props.component.label}
          toggled={!componentState.editModalCollapse}
          headerRightView={getHeaderRightView(false)}
          onToggled={() => {
            setComponentState((prev) => ({
              ...prev,
              editModalCollapse: !prev.editModalCollapse,
            }));
          }}
        >
          <VStack ref={drawerDetailRef}>
            {canAddNew() &&
              isMobileScreen &&
              componentValue.history.length > 0 && (
                <VStack marginY={4} space={2} flex={1} marginX={2}>
                  {getSearchComponent()}
                  {componentState.showSavingIndicator && (
                    <SectionLoadingIndicator />
                  )}
                </VStack>
              )}
            {componentState.loading && <FormComponentLoader />}
            {!componentState.loading && componentState.customError && (
              <FormComponentError error={FormError.custom} customError={componentState.customError} />
            )}
            {!componentState.loading && componentState.formError && (
              <FormComponentError error={componentState.formError} />
            )}
            {!componentState.loading && componentState.nonBlockingFormError && (
              <FormComponentError error={componentState.nonBlockingFormError} />
            )}
            {!componentState.loading &&
              !componentState.formError &&
              getDetailView(false, false)}
          </VStack>
        </DetailViewCollapse>
      </Drawer>
      <Drawer
        title={
          <ModalActionTitle
            isHeadNotSticky
            title={'Patient Reported Family History'}
            buttonList={[
              {
                isDisabled: componentState.showSavingIndicator,
                isLoading: componentState.showSavingIndicator,
                onClick: () => {
                  setComponentState((prev) => ({
                    ...prev,
                    showPatientReportedRecords: false,
                    selectedVitalsForBulkActions:
                      componentState.patientReported.map(
                        (item) => item.id || ''
                      ),
                  }));
                },
                show: true,
                id: 1,
                btnText: intl.formatMessage({
                  id: componentState.showSavingIndicator ? 'Saving' : 'close',
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: componentState.showSavingIndicator
                  ? BUTTON_TYPE.TERTIARY
                  : BUTTON_TYPE.SECONDARY,
              },
              {
                isDisabled: componentState.showSavingIndicator || bulkAction.loading,
                isLoading: bulkAction.loading,
                onClick: () => bulkAction.handleConfirmation(true),
                show: organizationCapability?.canCreate && componentState.patientReported.filter(item => !item.isFreeTextRecord).length > 0,
                id: 2,
                btnText: intl.formatMessage({
                  id: 'acceptAll'
                }),
                size: 'sm',
                textColor: componentState.showSavingIndicator || bulkAction.loading
                  ? Colors.primary[800]
                  : Colors.danger[800],
                variant: BUTTON_TYPE.PRIMARY,
              },
            ]}
          />
        }
        destroyOnClose
        placement="right"
        onClose={() => {
          setComponentState((prev) => ({
            ...prev,
            showPatientReportedRecords: false,
          }));
        }}
        open={componentState.showPatientReportedRecords}
        closable
        width={isSidecarContext ? '100%' : '50%'}
      >
        <VStack>
          {componentState.loading && <FormComponentLoader />}
          {!componentState.loading && componentState.nonBlockingFormError && (
            <FormComponentError error={componentState.nonBlockingFormError} />
          )}
          {!componentState.loading && componentState.customError && (
            <FormComponentError error={FormError.custom} customError={componentState.customError} />
          )}
          {!componentState.loading && componentState.formError && (
            <FormComponentError error={componentState.formError} />
          )}
          {!componentState.loading &&
            !componentState.formError &&
            getDetailView(false, true)}
        </VStack>
      </Drawer>
       {bulkAction.confirm && (
        <PatientIntakeConfirm
          title='Accept Patient Reported Data'
          onOk={() => handleSubmitAllAction()}
          onCancel={() => bulkAction.handleConfirmation(false)}
          acceptCount={acceptedRecordCount}
          renderRecords={() => {
            return (
              <>
                {ignoredRecords.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following data reported by patient is unstructured and has
                      not been matched to a structured record. These will not be
                      updated in the EHR.
                    </Text>
                    <VStack
                      my={1}
                      borderWidth={1}
                      backgroundColor={Colors.Custom.Red100}
                      borderColor={Colors.Custom.ErrorColor}
                      rounded={'md'}
                      p={2}
                    >
                      <Text color={Colors.Custom.Gray700} fontWeight={'bold'}>
                        {`Unmatched records (${ignoreRecordCount}/${componentState.patientReported.length} )`}
                      </Text>
                      {ignoredRecords.map((item) =>
                        renderSectionBulletList(
                          item.relation,
                          item.list,
                          {
                            fontSize: 14,
                            color: Colors.Custom.textBlackColor,
                            fontWeight: 'medium',
                          },
                          {
                            ml: 2,
                            my: 0,
                            borderWidth: 0,
                            rounded: 'none',
                            p: 0,
                          }
                        )
                      )}
                    </VStack>
                  </View>
                )}
                {acceptRecord.length > 0 && (
                  <View>
                    <Text mt={2.5}>
                      Following records will be updated in the EHR.
                    </Text>
                    <VStack
                      my={1}
                      borderWidth={1}
                      borderColor={Colors.Custom.Gray200}
                      rounded={'md'}
                      p={2}
                    >
                      <Text color={Colors.Custom.Gray700} fontWeight={'bold'}>
                        {`Matched records (${acceptedRecordCount}/${componentState.patientReported.length} )`}
                      </Text>
                      {acceptRecord.map((item) =>
                        renderSectionBulletList(
                          item.relation,
                          item.list,
                          {
                            fontSize: 14,
                            color: Colors.Custom.textBlackColor,
                            fontWeight: 'medium',
                          },
                          {
                            ml: 2,
                            my: 0,
                            borderWidth: 0,
                            rounded: 'none',
                            p: 0,
                          }
                        )
                      )}
                    </VStack>
                  </View>
                )}
              </>
            );
          }}
        />
      )}
    </View>
  );
};

export default AddOrUpdateFamilyHistory;
