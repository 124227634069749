import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from '../FormComponents/FormComponents';
import Label from '../BaseComponents/Label';
import {getUniqueKey} from '../CustomFormEngineUtils';
import {cloneDeep, debounce} from 'lodash';
import Description from '../BaseComponents/Description';
import {useFormRendererContext} from '../Context/FormRenderer.context';
import {FormRendererActionType} from '../Context/FormRendererReducer';
import {CustomFormBuilderActionTypes} from '../CustomFormEngineInterfaces';
import {useCustomFormBuilderContext} from '../Context/CustomFormBuilder.context';
import KeyField from '../../FHFormio/EditFormFields/KeyField';
import {usePropertiesFormRenderer} from '../Hooks/usePropertiesFormRenderer';
import {FormRenderer} from '../FormRenderer';
import AddPatientIdentifier, { IPatientIdentifierComponentValue } from '../../FHFormio/CustomComponents/PatientIdentifier/AddPatientIdentifier/AddPatientIdentifier';
import { PatientIdentifierSchema } from '../Schema/ComponentsSchema';
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from '../../FHFormio/EditFormFields/ShareWithPatientFields';
import QuillConfig from '../../FHFormio/Builder/QuillConfig';
import NewConditionalFields from '../BaseComponents/NewConditionalFields';
import { v4 as uuidV4 } from 'uuid';
import SearchableComponentFields from '../../FHFormio/EditFormFields/SearchableComponentFields';

export const PatientIdentifierFieldFormElement: FormElement = {
  type: PatientIdentifierSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(PatientIdentifierSchema),
    key: getUniqueKey(map, PatientIdentifierSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(PatientIdentifierSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    label: PatientIdentifierSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

function ComponentView({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  disabled,
  validationRef,
  isReadOnly,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: IPatientIdentifierComponentValue;
  onChange: (value: IPatientIdentifierComponentValue) => void;
  errorMessage?: string;
  disabled?: boolean;
  validationRef?: React.MutableRefObject<any>;
  isReadOnly?: boolean;
}) {
  const {label, validate, description, tooltip, hideLabel} = elementInstance;

  return (
    <div className="flex flex-col gap-1 w-full">
      {!hideLabel && !isReadOnly && (
        <Label
          label={label || ''}
          isRequired={validate?.required}
          tooltip={tooltip}
          isReadOnly={isReadOnly}
        />
      )}
      <AddPatientIdentifier
        component={{
          ...elementInstance,
          selectedValue: defaultValue,
        }}
        disabled={disabled || false}
        options={{}}
        validateRef={validationRef || {}}
        onChange={onChange}
      />
      {description && <Description description={description} />}
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
}

function DesignerComponent({elementInstance}: {elementInstance: FormElementInstance}) {
  const [value, setValue] = useState<IPatientIdentifierComponentValue>();
  return (
    <div className="flex flex-col gap-2 w-full">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        onChange={setValue}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  isInvalid,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  isInvalid?: boolean;
  defaultValue?: IPatientIdentifierComponentValue;
  isReadOnly?: boolean;
  onRegisterValidation?: (key: string, validate: ValidationFunction) => void;
}) {
  const {state, dispatch} = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<IPatientIdentifierComponentValue | undefined>(defaultValue || selectedValue);
  const validationRef = useRef<any>();
  const isMounted = useRef(true);

// Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const validateField = useCallback(
    async (valueToValidate?: IPatientIdentifierComponentValue, silentCheck?: boolean): Promise<ValidationResult> => {
      try {
        if (!isMounted.current) return {
          isValid: true,
          errorMessage: '',
          key: elementInstance.key,
          fieldValue: valueToValidate
        };
        const result = await validationRef.current?.(valueToValidate, silentCheck);
        return {
          isValid: result?.isValid ?? true,
          errorMessage: result?.message || '',
          key: elementInstance.key,
          fieldValue: valueToValidate,
        };
      } catch (error) {
        console.error(`Validation error for ${elementInstance.key}:`, error);
        return {
          isValid: true,
          errorMessage: 'Validation failed unexpectedly',
          key: elementInstance.key,
          fieldValue: valueToValidate,
        };
      }
    },
    [elementInstance.key]
  );

  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: IPatientIdentifierComponentValue) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: {key: elementInstance.key, errorMessage: result.errorMessage},
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );



  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: IPatientIdentifierComponentValue) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      onChange={handleChange}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      disabled={isReadOnly}
      isReadOnly={isReadOnly}
      validationRef={validationRef}
    />
  );
}

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const {state, dispatch, userSettings} = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);

  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    ...(isAllowShare ? ShareWithPatientFields : []),
    ...SearchableComponentFields,
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip:
        'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...NewConditionalFields,
    ...KeyField
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: elementInstance as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: {updatedElement: formData, builderComponents: state.elements},
    });
  }, [formData, elementInstance]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}

export default PatientIdentifierFieldFormElement;
