import { View } from 'react-native'
import React, { useCallback, useEffect, useState } from 'react'
import { ITaskDisplayConfig, ITaskMetaData, TaskNavigateContext, TaskViewType } from '../TaskInterfaces'
import TaskListView from './ListView/TaskListView';
import TaskKanbanView from './KanbanView/TaskKanbanView';
import { ILabelTask, ITask } from '../../common/CareDashboard/CareDashboardInterfaces';
import { LABEL_TYPE_CODES, MLOV_CATEGORY, TASK_STATUS, TASK_STATUS_CODES } from '../../../constants/MlovConst';
import { EntityType } from '../../TaskCard/TaskEnum';
import { useLazyQuery } from '@apollo/client';
import { TaskQueries } from '../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getMlovListFromCategory } from '../../../utils/mlovUtils';
import { useLocation } from 'react-router-dom';
import {validate as isValidUuid} from 'uuid';
import { getUserUUID, isEmployerRole } from '../../../utils/commonUtils';
import { notification } from 'antd';
import { PERSON_TYPES, RIGHT_SIDE_CONTAINER_CODE, getInActiveContactError } from '../../../constants';
import MessagingContactDetailsDrawer from '../../RightSideContainer/TeamInbox/Conversations/MessagingContactDetails/MessagingContactDetailsDrawer';
import { withMiniContactViewHOC } from '../../MiniContactViewHOC';
import { CONVERSATION_ACTION_CODES } from '../../../constants/ActionConst';
import AddOrUpdateTask from '../../common/AddTask/AddOrUpdateTask';
import { OrderTaskDetails } from '../../common/CareDashboard/CareDashboardKanBan/OrderTaskDetails';
import { getTaskAssignee } from '../TaskModuleHelper';
import { EventBus } from '../../../utils/EventBus';
import { TASK_EVENTS, TASK_MODULE_CODE } from '../../common/CareDashboard/CareDashboardConstants';
import { ITaskDetailConfig } from '../../../Interfaces';
import { ITaskCount } from '../../common/CareDashboard/CustomHook/useTaskCountManager';
import { TaskViewFrom } from '../../common/CareDashboard/CareDashboardTopBar/interfaces';

interface ITaskMainView {
  config: ITaskDisplayConfig;
  metaData: ITaskMetaData;
  onActionPerformed?: (tabCode: any, rawData: any) => void;
  navigateOrOpenContactIdDrawer?: (contactId:string) => void;
  openContactInWindowOrOpenContactIdDrawer?: (contactId:string) => void;
  navigateOrOpenLeadInWindow?: (contactId?:string | number, isNavigate?: boolean)=>void;
  onTaskCountUpdate?: (taskCount: ITaskCount[]) => void;
  viewFrom?: TaskViewFrom;
  isP360PatientProfile?: boolean;
}

const TaskMainView = (props: ITaskMainView) => {
  const {config, metaData, onActionPerformed, onTaskCountUpdate, viewFrom, isP360PatientProfile} = props;
  const eventBus = EventBus.getEventBusInstance();
  const location = useLocation();
  const isEmployer = isEmployerRole();
  const currentUserId = getUserUUID();
  const commonData = React.useContext(CommonDataContext);
  const labels = getMlovListFromCategory(commonData.MLOV, MLOV_CATEGORY.LABEL_TYPE);
  const filteredResult = labels?.filter((item) => {
    return item.code === LABEL_TYPE_CODES.TASK;
  });
  const taskLabelMlov = filteredResult?.[0];

  // States
  const [taskDetail, setTaskDetail] = useState<ITaskDetailConfig>({
    show: false,
    showCareJourneyTask: false,
  });
  const [selectedRowContactData, setSelectedRowContactData]: any = useState({});
  const [selectedViewCode, setSelectedViewCode] = useState('');

  // APIs
  const [ getTaskByIdApi ] = useLazyQuery(TaskQueries.GET_TASK_BY_ID, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });
  const [getLabelsData] = useLazyQuery(TaskQueries.GET_LABELS_BY_IDS, {
    fetchPolicy: 'no-cache',
  });
  const [ getMentionedUsersByCommentIdApi ] = useLazyQuery(TaskQueries.VALIDATE_MENTIONED_USER_IN_TASK_COMMENT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  // Lifecycle methods
  useEffect(() => {
    eventBus.addEventListener(TASK_EVENTS.OPEN_TASK_DETAIL, onTaskDetailEvent, { moduleCode: TASK_MODULE_CODE });
    return () => {
      eventBus.removeEventListenerByEventName(TASK_EVENTS.OPEN_TASK_DETAIL, onTaskDetailEvent, { moduleCode: TASK_MODULE_CODE });
    }
  }, []);

  useEffect(() => {
    if (
      location?.state !== null &&
      isValidUuid(location?.state?.taskId) &&
      isValidUuid(location?.state?.commentId)
    ) {
      getTaskById(location?.state?.taskId, location?.state?.commentId);
    }
  }, [location]);

  // Other methods
  const onTaskDetailEvent = useCallback((data: {task: ITask, taskPool?: {key: string; value: string; label: string;}}) => {
    const task = data?.task;
    const taskPool = data?.taskPool;
    const isCareJourneyTask = isCareJourneyOrderTask(task);
    setTaskDetail((prev) => {
      return {
        ...prev,
        show: !isCareJourneyTask,
        task: task ? {
          ...task,
          isCompleted: task.status?.code === TASK_STATUS_CODES.COMPLETED
        } : undefined,
        showCareJourneyTask: isCareJourneyTask,
        ...(taskPool?.key && { prefillData: { taskPool } }),
      }
    });
  }, []);

  const onTaskDetail = useCallback((task: ITask) => {
    if (task?.parentId) {
      getParentTaskByIdForSubTasks(task?.parentId, task.id, task?.isReadOnly, task?.hasTaskAccess)
    } else {
      const isCareJourneyTask = isCareJourneyOrderTask(task);
      setTaskDetail((prev) => {
        return {
          ...prev,
          show: !isCareJourneyTask,
          task: {
            ...task,
            isCompleted: task.status?.code === TASK_STATUS_CODES.COMPLETED
          },
          showCareJourneyTask: isCareJourneyTask
        }
      });
    }
  }, []);

  const onMemberClick = useCallback((task: ITask) => {
    if (task?.contact?.isActive === false) {
      const message = getInActiveContactError(task?.contact);
      notification.error({
        message,
      });
      return
    }
    const contactType = task?.contact?.contactType?.contactType?.code;
    const contactId = task.patientContactId || task?.contact?.id;

    if (!contactId) {
      return;
    }
    if (isEmployer){
      setSelectedRowContactData(task?.contact)
      setSelectedViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW);
    } else {

      if (contactType == PERSON_TYPES.CUSTOMER) {
        props?.openContactInWindowOrOpenContactIdDrawer?.(contactId);
      } else if (contactType == PERSON_TYPES.LEAD || contactType == PERSON_TYPES.SUPPORTING_MEMBER) {
        props?.navigateOrOpenLeadInWindow?.(contactId);
      }
    }
  }, []);

  const getParentTaskByIdForSubTasks = async (taskId?: string, subTaskId?: string, isReadOnly?: boolean, hasTaskAccess?: boolean) => {
    if (taskId && subTaskId) {
      const response = await getTaskByIdApi({
        variables: {
          id: taskId,
        },
      });
      const task = response?.data?.getTasks?.tasks?.[0];
      if (!task) {
        return;
      }
      const isCareJourneyTask = isCareJourneyOrderTask(task);
      const labelsData = task?.labels?.length > 0 ? await getLabels(task?.labels || []) : [];
      setTaskDetail((prev) => {
        return {
          ...prev,
          show: !isCareJourneyTask,
          task: {
            ...task,
            labels: labelsData,
            isAutoScroll: true,
            scrollTo: 'subtask',
            subTaskIdToScroll: subTaskId,
            isReadOnly: isReadOnly,
            hasTaskAccess: hasTaskAccess,
            isCompleted: task?.status?.code === TASK_STATUS.COMPLETED
          },
          showCareJourneyTask: isCareJourneyTask
        };
      });
    }
  }

  const getLabels = async (labels: ILabelTask[]) => {
    const labelIds = labels?.map((label: ILabelTask) => label?.labelId)
    const getLabelsRes = await getLabelsData({
      variables: {
        labelIds,
        labelTypeId: taskLabelMlov?.id,
      },
    });
    return getLabelsRes?.data?.labels?.map((labelData: ILabelTask) => {
      const matchedLabel = labels?.find((labelArg: ILabelTask) => labelArg?.labelId === labelData?.uuid);
      if (matchedLabel) {
        labelData.id = matchedLabel?.id;
        labelData.labelId = matchedLabel?.labelId;
      }
      return labelData;
    }) || [];
  }

  const isCareJourneyOrderTask = (task: ITask | undefined): boolean => {
    if (task?.referenceData?.careJourneyId && task?.referenceData?.entityType) {
      return (
        task?.referenceData?.entityType === EntityType.MED ||
        task?.referenceData?.entityType === EntityType.LAB_TEST ||
        task?.referenceData?.entityType === EntityType.IMMUNIZATION
      );
    }
    return false;
  };

  const getTaskById = async (taskId: string, commentId: string) => {
    const promiseList = [
       getMentionedUsersByCommentIdApi({
        variables: {
          taskId,
          commentId,
          mentionedUserId: currentUserId,
        },
      }),
       getTaskByIdApi({
        variables: {
          id: taskId,
        },
      })
    ];
    try {
      const apiResponse = await Promise.all(promiseList);
      const mentionsRes = apiResponse[0]?.data?.taskComments || [];
      const taskRes = apiResponse?.[1]?.data?.getTasks?.tasks?.[0];
      if (mentionsRes?.length) {
        taskRes.assignedBy = taskRes.assignedByUser;
        const labelsData = taskRes?.labels?.length > 0 ? await getLabels(taskRes.labels) : []
        setTaskDetail({
          show: true,
          task: {isAutoScroll: true, ...taskRes, labels: labelsData},
          showCareJourneyTask: false,
        });
      }
    } catch (error) {

    }
    location.state = null;
  }

  const resetTaskDetails = useCallback(() => {
    setTaskDetail((prev) => ({
      ...prev,
      show: false,
      task: undefined,
      showCareJourneyTask: false,
    }));
  }, [setTaskDetail]);

  const onAssigneeClick = useCallback(() => {
    const contactType =
      taskDetail.task?.contact?.contactType?.contactType?.code;
    const contactId = taskDetail?.task?.contact?.id;

    if (!contactId) {
      return;
    }
    if (isEmployer) {
      setSelectedRowContactData(taskDetail?.task?.contact);
      setSelectedViewCode(RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW);
    } else if (contactType == 'CUSTOMER') {
      props?.navigateOrOpenContactIdDrawer?.(contactId);
    } else if (contactType == 'LEAD') {
      props?.navigateOrOpenLeadInWindow?.(contactId, true);
    }
  }, [
    taskDetail.task,
    isEmployer,
    setSelectedRowContactData,
    setSelectedViewCode,
    props.navigateOrOpenContactIdDrawer,
    props.navigateOrOpenLeadInWindow,
  ]);

  const onNoteRedirect = useCallback(() => {
    metaData.onNavigate?.(TaskNavigateContext.note, taskDetail.task);
    resetTaskDetails();
  }, [metaData?.onNavigate, taskDetail?.task?.id, resetTaskDetails]);

  const onSideBarActionPerformed = useCallback((action?: any) => {
    if (action == CONVERSATION_ACTION_CODES.DRAWER_CLOSE) {
      setSelectedViewCode('');
      setSelectedRowContactData(undefined);
    }
  }, [setSelectedViewCode, setSelectedRowContactData]);

  return (
    <View>
      {config.viewType === TaskViewType.list && (
        <TaskListView
          config={config}
          metaData={metaData}
          onActionPerformed={onActionPerformed}
          onTaskDetail={onTaskDetail}
          onMemberClick={onMemberClick}
          onTaskCountUpdate={onTaskCountUpdate}
          viewFrom={viewFrom}
          isP360PatientProfile={isP360PatientProfile || false}
        />
      )}
      {config.viewType === TaskViewType.kanban && (
        <TaskKanbanView
          config={config}
          metaData={metaData}
          onActionPerformed={onActionPerformed}
          onTaskDetail={onTaskDetail}
          onMemberClick={onMemberClick}
          viewFrom={viewFrom}
          isP360PatientProfile={isP360PatientProfile || false}
        />
      )}
      {taskDetail.showCareJourneyTask && !!taskDetail.task && (
        <OrderTaskDetails
          task={taskDetail.task}
          isVisible={taskDetail.showCareJourneyTask}
          assignee={getTaskAssignee(taskDetail.task, metaData.masterUsersList)}
          onCancel={resetTaskDetails}
          onAssigneeClick={onAssigneeClick}
          fetchAllTypeTask={resetTaskDetails}
        />
      )}
      {taskDetail.show && (
        <AddOrUpdateTask
          key="editTask"
          task={taskDetail.task}
          isVisible={taskDetail.show}
          onCancel={resetTaskDetails}
          onComplete={resetTaskDetails}
          fetchAllTypeTask={resetTaskDetails}
          restrictPatientOrLeadSelectionTo={metaData.restrictPatientOrLeadSelectionTo}
          extraData={taskDetail.prefillData?.taskPool ? {
            taskPool: taskDetail.prefillData?.taskPool,
          } : undefined}
          personData={metaData.personData}
          onNoteRedirect={onNoteRedirect}
        />
      )}
      {selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW && (
        <MessagingContactDetailsDrawer
          contactData={selectedRowContactData}
          isDrawerVisible={
            selectedViewCode === RIGHT_SIDE_CONTAINER_CODE.CONTACT_DETAILS_VIEW
              ? true
              : false
          }
          contactType={selectedRowContactData?.contactType?.contactType?.code}
          onSideBarActionPerformed={onSideBarActionPerformed}
          borderLessView={true}
        />
      )}
    </View>
  )
}

export default withMiniContactViewHOC(TaskMainView);
