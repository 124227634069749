import {Button, HStack, IAlertProps, Pressable} from 'native-base';
import {Colors} from '../../../../../../styles';
import Feather from 'react-native-vector-icons/Feather';
import CustomButton from './CustomButton/CustomButton';
import PlusIcon from '../../../../../common/Svg/PlusSvg';
import Tooltip from 'antd/es/tooltip';
import { Bundle, Extension } from 'fhir/r4';
import { EXTENSION_URLS } from '../../../../../PersonOmniView/MiddleContainer/PatientNotes/components/AddOrUpdateTemplate/constant';
import { updateSectionDetails } from '../../../../../../services/CommonService/AidBoxService';
import { styles } from '../../../Analytics/style';
import { testID, TestIdentifiers } from '../../../../../../testUtils';

export const FormComponentType = {
  BUTTON: 'button',
  CURRENCY: 'currency',
}

export enum FormError {
  existingDataAPIFail = 'Something went wrong while fetching the previous recorded data.',
  noPatientIdFound = 'This section is not applicable for you.',
  configurationDataAPIFail = 'Something went wrong while fetching the configuration',
  addUpdateFail = 'Something went wrong while saving the record.',
  custom = 'Custom'
}

export enum CapabilityResource {
  diagnosis = 'Diagnosis',
  patient = 'Patient',
  condition = 'Condition',
  allergyIntolerance = 'AllergyIntolerance',
  medicationStatement = 'MedicationStatement',
  immunization = 'Immunization',
  observation = 'Observation',
  procedure = 'Procedure',
  questionnaireResponse = 'QuestionnaireResponse',
  familyHistory = 'FamilyHistory',
  socialHistory = 'SocialHistory',
  careTeam = 'CareTeam',
  appointment = 'Appointment',
  pastMedicalHistory= 'MedicalHistory',
  barrier = 'Barrier',
  wearable ='Wearable',
};

export const componentKeys = {
  VITALS: 'vitals',
  CONDITIONS: 'conditions',
  ALLERGIES: 'allergies',
  MEDICATIONS: 'medications',
  IMMUNIZATIONS: 'immunizations',
  SURGICAL_HISTORY: 'surgicalHistory',
  FAMILY_HISTORY: 'familyHistory',
  SOCIAL_HISTORY: 'socialHistory',
  PATIENT_DEMOGRAPHICS: 'patientDemographics',
  EMERGENCY_CONTACT: 'emergencyContact',
  PATIENT_COMMUNICATION: 'patientCommunication',
  PATIENT_ADDRESS: 'patientAddress',
  CHIEF_COMPLAINT: 'chiefComplaint',
  PAST_MEDICAL_HISTORY: 'pastMedicalHistory',
  BARRIERS: 'barriers',
  INTERVENTION: 'intervention',
  GOALS: 'goals',
  HOME_MONITORING: 'homeMonitoringView',
  DATE: 'date',
  IMAGE: 'image',
};

export const COMPONENT_KEYS = Object.values(componentKeys);

export const getFormErrorAlertType = (
  error: FormError
): IAlertProps['status'] => {
  switch (error) {
    case FormError.existingDataAPIFail:
    case FormError.configurationDataAPIFail:
    case FormError.addUpdateFail:
    case FormError.custom:
      return 'error';
    case FormError.noPatientIdFound:
      return 'info';
  }
};
export interface IFormComponentConfig {
  key: string;
  type: string;
  label: string;
  hideLabel: boolean;
  leftSidebarLabel?: string
}

export const VITAL_INTAKE_COMPONENT: IFormComponentConfig[] = [
  {
    key: componentKeys.VITALS,
    type: 'vitals',
    label: 'Vitals',
    hideLabel: true,
  },
];

export const INTAKE_COMPONENT_LIST: IFormComponentConfig[] = [
  {
    key: componentKeys.CONDITIONS,
    type: 'conditions',
    label: 'Problems',
    leftSidebarLabel: 'Active Problems',
    hideLabel: true,
  },
  {
    key: componentKeys.ALLERGIES,
    type: 'allergies',
    label: 'Allergies',
    leftSidebarLabel: 'Allergies',
    hideLabel: true,
  },
  {
    key: componentKeys.MEDICATIONS,
    type: 'medications',
    label: 'Medications',
    leftSidebarLabel: 'Current Medications',
    hideLabel: true,
  },
  {
    key: componentKeys.IMMUNIZATIONS,
    type: 'immunizations',
    label: 'Immunizations',
    leftSidebarLabel: 'Immunization History',
    hideLabel: true,
  },
];

export const HISTORY_COMPONENT_LIST: IFormComponentConfig[] = [
  {
    key: componentKeys.SURGICAL_HISTORY,
    type: 'surgicalHistory',
    label: 'Surgical History',
    hideLabel: true,
  },
  {
    key: componentKeys.FAMILY_HISTORY,
    type: 'familyHistory',
    label: 'Family History',
    hideLabel: true,
  },
  {
    key: componentKeys.SOCIAL_HISTORY,
    type: 'socialHistory',
    label: 'Social History',
    hideLabel: true,
  },
  {
    key: componentKeys.PAST_MEDICAL_HISTORY,
    type: 'pastMedicalHistory',
    label: 'Medical History',
    hideLabel: true,
  }
];

export const CARE_PLAN_COMPONENT_LIST: IFormComponentConfig[] = [
  {
    key: componentKeys.GOALS,
    type: 'goals',
    label: 'Goals',
    hideLabel: true,
  },
  {
    key: componentKeys.INTERVENTION,
    type: 'intervention',
    label: 'Intervention',
    hideLabel: true,
  },
  {
    key: componentKeys.BARRIERS,
    type: 'barriers',
    label: 'Barriers',
    hideLabel: true,
  },
];

export const CARE_PLAN_GOAL_COMPONENT_LIST: IFormComponentConfig[] = [
  {
    key: componentKeys.GOALS,
    type: 'goals',
    label: 'Goals',
    hideLabel: true,
  },
];

export const ADD_UPDATE_EVENTS = {
  VITAL: 'ADD_OR_UPDATE_VITALS',
  PROBLEM: 'ADD_OR_UPDATE_PROBLEM',
  ALLERGY: 'ADD_OR_UPDATE_ALLERGY',
  MED: 'ADD_OR_UPDATE_MED',
  IMMUNIZATION: 'ADD_OR_UPDATE_IMMUNIZATION',
  SURGICAL_HISTORY: 'ADD_OR_UPDATE_SURGICAL_HISTORY',
  FAMILY_HISTORY: 'ADD_OR_UPDATE_FAMILY_HISTORY',
  SOCIAL_HISTORY: 'ADD_OR_UPDATE_SOCIAL_HISTORY',
  PAST_MEDICAL_HISTORY: 'ADD_OR_UPDATE_PAST_MEDICAL_HISTORY',
  CONTACT_DETAIL_UPDATE: 'CONTACT_DETAIL_UPDATE',
  PATIENT_INFO: 'PATIENT_INFO',
  EMERGENCY_CONTACT: 'EMERGENCY_CONTACT',
  BARRIER: 'BARRIER',
  INTERVENTION: 'INTERVENTION',
  GOALS: 'GOALS',
};

export const getHeaderButtons = (
  onPress: () => void,
  tooltipLabel?: string,
  extraActions?:{
    onPress?: () => void,
    tooltip?:string,
    iconName?:string,
  }[],
  readonlyAddBtn= false
) => {
  const tooltip = tooltipLabel || 'Add / Edit';
  return (
    <HStack>
      {extraActions?.length &&
        extraActions.map((action) => (
          <Tooltip title={action?.tooltip || ''} placement="bottom" key={action.tooltip} showArrow={false} overlayStyle={{ paddingTop: '3px' }} overlayInnerStyle={tooltipInnerOverlayStyle}>
            <Pressable
              mr={2}
              onPress={() => action?.onPress?.()}
              style={styles.pressableStyle1}
              rounded="full"
              {...testID(TestIdentifiers.graphIconBtn)}
            >
              <Feather
                name={action?.iconName || ''}
                size={20}
                color={Colors.Custom.foldIconColor}
              />
            </Pressable>
          </Tooltip>
        ))}
      <Tooltip placement="bottom" title={tooltip} showArrow={false} overlayStyle={{ paddingTop: '1px' }} overlayInnerStyle={tooltipInnerOverlayStyle}>
        <Pressable
          onPress={onPress}
          style={styles.centered}
          rounded="full"
          {...testID(TestIdentifiers.plusIcon)}
        >
          <PlusIcon defaultColor={readonlyAddBtn ? Colors.FoldPixel.GRAY200 : Colors.Custom.foldIconColor} />
        </Pressable>
      </Tooltip>
    </HStack>
  );
};

export const getHeaderViewAllButton = (onPress: () => void, tooltipLabel?: string, title?: string): JSX.Element => {
  return (
    <HStack>
      <Tooltip placement="bottom" title={tooltipLabel || 'View all medications'} showArrow={false} overlayStyle={{ paddingTop: '1px' }} overlayInnerStyle={tooltipInnerOverlayStyle}>
        <CustomButton onPress={onPress} title= {title || `View All`}/>
      </Tooltip>
    </HStack>);
};

export const tooltipOverlayStyle = {
  paddingTop: '5px',
};

export const tooltipInnerOverlayStyle = {
  padding: '3px 7px',
  borderRadius: '3.5px',
  textAlign:'center' as 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit' 
};

export const tooltipPlacement = 'bottom';
export const tooltipShowArrow = false;
export const getSectionNoteDetails = (extensions: Extension[]) => {
  return {
    sectionNote: extensions.find(extension => extension.url === EXTENSION_URLS.sectionNote)?.valueString || '',
    sectionNoteModifiedBy: extensions.find(extension => extension.url === EXTENSION_URLS.noteLastModifiedBy)?.valueString || '',
    sectionNoteModifiedDateTime: extensions.find(extension => extension.url === EXTENSION_URLS.noteLastModifiedDateTime)?.valueDateTime || '',
  }
}

export const getNKDADetails = (extensions: Extension[]): boolean => {
  return extensions.find(extension => extension.url === EXTENSION_URLS.nkda)?.valueBoolean || false;
}

export const addOrUpdateSectionData = async (resource: string, sectionData: {
  note?: string;
  nkda?: boolean;
  patientId: string;
  locationId?: string;
  contactId: string;
}) => {
  const { note, nkda, patientId, locationId , contactId } =  sectionData;
  if (note === undefined && nkda === undefined) {
    return;
  }
  const extensions: Extension[] = [
    {
      "url": "http://xcaliber-fhir/structureDefinition/replace-note",
      "valueBoolean": true,
    }
  ];
  if (note !== undefined) {
    extensions.push({
      url: EXTENSION_URLS.sectionNote,
      valueString: note.trim() ? note.trim() : ' ',
    })
  }
  if (nkda !== undefined) {
    extensions.push({
      url: EXTENSION_URLS.nkda,
      valueBoolean: nkda,
    })
  }
  const fhirTemplate: Bundle = {
    resourceType: 'Bundle',
    type: 'searchset',
    meta: {
      extension: extensions,
    }
  }
  return updateSectionDetails(resource, fhirTemplate, contactId, locationId);
}
