import {Divider, FlatList, Spinner, Text, Pressable} from 'native-base';
import {View} from 'react-native';
import {Colors} from '../../../../../styles';
import {PendingRequestListItem} from '../../../../common/PendingRequestListItem/PendingRequestListItem';
import Stack from '../../../../common/LayoutComponents/Stack';
import {useIntl} from 'react-intl';
import {DISPLAY_DATE_FORMAT, FHIR_RESOURCE} from '../../../../../constants';
import {getFormattedDate} from '../../OtherDetails/OtherDeatilsUtils';
import {
  ACTION_CODES,
  SYNC_STATUS_TYPES,
} from '../../../../common/PendingRequestListItem/PendingRequestLisItemConst';
import {ReconciliationStatus, IReconciliationRequest, IReconciliationRequestGroup, IHIEReconciliationRequest, ReconciliationSource, ReconciliationActionCode, IMSOReconciliationRequest} from './interface';
import {useReconciliationRequests} from './useReconciliationRequests';
import FormLoaderSkeleton from '../../../MiddleContainer/PatientNotes/components/FormLoaderSkeleton';
import {capitalizeText} from '../../../../common/ContactRelationView/ContactRelationUtils';
import {
  getResourceGroupText,
  getUpdatedMatchedHieRequest,
  updateGroupHieRequests,
} from './HieRequestsUtils';
import {HieMatchDataView} from './HieMatchDataView';
import React, {useContext, useEffect, useMemo} from 'react';
import {CommonDataContext} from '../../../../../context/CommonDataContext';
import { styles } from '../../OtherDetails/OtherDetailsStyles';
import { IContactProfile } from '../../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { usePersonOmniViewContext } from '../../../PersonOmniView.context';
import { CENTRAL_PROFILE_NAME } from '../../../../../constants/ConstantValues';

interface IReconciliationRequestsViewProps {
  patientUuid: string;
  patientId: string;
  accountLocationId: string;
  contactUuid?: string;
  contactLocationId?: string;
  resourceTypes: string[];
}

export const ReconciliationRequestsView = (props: IReconciliationRequestsViewProps) => {
  const {patientUuid, accountLocationId, patientId, contactUuid, contactLocationId, resourceTypes} = props;
  const intl = useIntl();

  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const {data: contactData} = usePersonOmniViewContext();

  const locationGroupPatientIdMap = useMemo(() => {
    const locationGroupPatientIdMap = contactData?.contactProfiles?.reduce((acc: Record<string, string>, profile: IContactProfile) => {
      const locationGroup = profile?.accountLocation?.locationGroup?.groupName;
      if (locationGroup && profile?.ehrPatientId){
        acc[`${profile.ehrPatientId}`] = locationGroup;
      }
      return acc;
    }, {} as Record<string, string>) || {};
    locationGroupPatientIdMap[`${contactUuid}`] = CENTRAL_PROFILE_NAME;
    return locationGroupPatientIdMap;
  }, [contactData?.contactProfiles]);

  const {
    reconciliationRequestGroups,
    updateRequestStatus,
    loading,
    updateRequestsLoading,
    updateRequestGroupsState,
    metaData,
    fetchMoreRequests,
    fetchReconciliationRequests,
  } = useReconciliationRequests({
    patientUuid: patientUuid,
    accountLocationUuid: accountLocationId,
    patientId: patientId,
    contactUuid: contactUuid,
    source: ReconciliationSource.MSO,
    resourceTypes: resourceTypes,
    skip: true,
    locationGroupPatientIdMap,
  });

  useEffect(() => {
    fetchReconciliationRequests(true);
  }, []);

  const onActionPerform = (request: IReconciliationRequest, code: string) => {
    switch (code) {
      case ACTION_CODES.ACCEPT:
        updateRequestStatus(request, ReconciliationStatus.accepted);
        break;
      case ACTION_CODES.DECLINE:
        updateRequestStatus(request, ReconciliationStatus.declined);
        break;
      case ACTION_CODES.DELETE:
        updateRequestStatus(request, ReconciliationStatus.deleted);
        break;
      case ACTION_CODES.SHOW_MATCH_DATA_VIEW:
        updateMatchDataViewVisibility(request, true);
        break;
      case ACTION_CODES.HIDE_MATCH_DATA_VIEW:
        updateMatchDataViewVisibility(request, false);
        break;
    }
  };

  const canShowLoadMore = (item: IReconciliationRequestGroup) => {
    return item.orders.length < (item.total || 0);
  }

  const updateMatchDataViewVisibility = (
    hieRequest: IReconciliationRequest,
    isVisible: boolean
  ) => {
    hieRequest.isMatchDataViewVisible = isVisible;
    const updatedHieRequestsGroups = updateGroupHieRequests(
      hieRequest,
      reconciliationRequestGroups
    );
    updateRequestGroupsState(updatedHieRequestsGroups);
  };

  const renderCardListItem = ({
    item,
    index,
  }: {
    item: IReconciliationRequestGroup;
    index: number;
  }) => {
    return (
      <View
        style={{
          borderRadius: 12,
          marginTop: index === 0 && !isSidecarContext ? 4 : 12,
          borderWidth: 0.5,
          borderColor: Colors.FoldPixel.GRAY150,
        }}
      >
        <Stack direction="row" style={styles.row}>
          <Text
            fontSize={14}
            fontWeight={'500'}
            marginTop={3}
            paddingLeft={3}
            marginBottom={'10px'}
            color={Colors.FoldPixel.GRAY400}
          >
            {`${getResourceGroupText(item.resourceType, intl).headerText} (${item.total || 0})`}
          </Text>
        </Stack>

        <Divider />
        <Stack direction="column" style={styles.listContainer2}>
          {item.orders.map((order) => {
            const display = order.display;
            const loadingObj = updateRequestsLoading.find(
              (loading) => loading.id === order.id
            );

            const syncStatusType =
              order.syncStatus === ReconciliationStatus.accepted
                ? SYNC_STATUS_TYPES.ACCEPTED
                : SYNC_STATUS_TYPES.DECLINED;
            return (
              <PendingRequestListItem
                title={display?.title || ''}
                date={display?.date}
                id={order.id}
                actionCode={order.actionCode}
                sourceInfo={order.sourceInfo}
                onActionPerform={(id, code) => {
                  onActionPerform(order, code);
                }}
                error={order.error}
                key={`request_list_item_${order.id}`}
                isMatchDataViewVisible={order.isMatchDataViewVisible}
                showError={true}
                isDataNotMatched={order.conflictFields?.some((field) => field.invalid || field.isConflict) || false}
                syncStatusType={syncStatusType}
                loading={loadingObj}
                subTitle={display?.subTitle}
                syncStatus={order.syncStatus}
                showDeleteAction={order.showDeleteAction}
                showUpdateAction={order.showUpdateAction}
                resourceType={order.resourceType}
                matchToView={
                  <HieMatchDataView
                    headerText={
                      getResourceGroupText(item.resourceType, intl)
                        .matchedWithTitle
                    }
                    onActionPerform={(id, code) => {
                      onActionPerform(order, code);
                    }}
                    hieRequest={order}
                    source={ReconciliationSource.MSO}
                    onContinueClick={(conflictFields, accept) => {
                      const updatedMatchedHieRequest =
                        getUpdatedMatchedHieRequest(
                          order,
                          conflictFields,
                          metaData
                        );
                      updatedMatchedHieRequest.isMatchDataViewVisible = false;
                      const updatedHieRequestsGroups = updateGroupHieRequests(
                        updatedMatchedHieRequest,
                        reconciliationRequestGroups
                      );
                      updateRequestGroupsState(updatedHieRequestsGroups);
                      if (accept) {
                        onActionPerform(updatedMatchedHieRequest, ACTION_CODES.ACCEPT);
                      }
                    }}
                    contactLocationId={contactLocationId}
                  />
                }
                status={display?.status ? capitalizeText(display?.status) : ''}
              />
            );
          })}
          {canShowLoadMore(item) && (
            <Pressable
              disabled={item.pageLoading || item.acceptAllLoading}
              onPress={() => {
                fetchMoreRequests(item.resourceType, false);
              }}
              cursor={
                item.pageLoading || item.acceptAllLoading
                  ? 'not-allowed'
                  : 'pointer'
              }
            >
              <Stack direction="row">
                <Text
                  fontSize={14}
                  fontWeight={'500'}
                  marginTop={3}
                  paddingLeft={3}
                  marginBottom={'10px'}
                  marginRight={3}
                  color={Colors.FoldPixel.PRIMARY300}
                >
                  {'Load More'}
                </Text>
                {item.pageLoading && <Spinner marginRight={3} />}
              </Stack>
            </Pressable>
          )}
        </Stack>
      </View>
    );
  };

  return (
    loading ? (
      <FormLoaderSkeleton />
    ) : (
      <FlatList
        keyExtractor={(item) => item.resourceType}
        data={reconciliationRequestGroups}
        renderItem={renderCardListItem}
      />
    )
  );
};
