import { useContext, useEffect, useMemo, useState } from 'react';
import { useToast } from 'native-base';
import { MLOV_CATEGORY } from '../../../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_CARE_TEAM_V2,
  ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES,
  DELETE_CARE_TEAM_USER, GET_CARE_TEAM_V2
} from '../../../../services/CareTeam/CareTeamQueries';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../constants/Configs';
import { showToast, ToastType } from '../../../../utils/commonViewUtils';
import {
  getMlovIdFromCode,
  getMlovListByCategory,
} from '../../../../utils/mlovUtils';
import {
  CARE_TEAM_MEMBER_TYPE,
} from '../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../context/CommonDataContext';
import {
  GET_SINGLE_USER,
  GET_USERS_BY_RESOURCE_CODES,
  GET_USERS_WITH_IDS,
} from '../../../../services/User/UserQueries';
import {
  getAccountUUID,
  getAgentsList,
  getAllowedUserAccountLocationUuids,
  getLocationNameFromLocationsUsingUuid,
  isEnableCareProgram,
  isMultiTenancyEnabled
} from '../../../../utils/commonUtils';
import { useIntl } from 'react-intl';
import { IUser } from '../../../../Interfaces';
import { IGetContactCareTeam, IUserSearch } from './interfaces';

import { CareTeamAction } from './CareTeamConst';
import { getCurrentEHR } from '../../MiddleContainer/PatientNotes/PatientNotesHelper';
import { EHRName } from '../../MiddleContainer/PatientNotes/interfaces';
import { getUserSearchWhereConditionForCareTeam } from './CareTeamUtils';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { MembersScreenStackParamList } from '../../../../navigation/StackNavigation/MemberScreenStack';
import { isWeb } from '../../../../utils/platformCheckUtils';
import { CONVERSATION_LOCAL_EVENT_CODES } from '../../../../constants/WebSocketConst';
import { localBroadcastEvent } from '../../../../utils/CustomEventHandler';
import { USER_ACCESS_PERMISSION } from '../../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../../SideMenuBar/SideBarConst';
import { checkLocationAccess, getActivePatientProfile } from '../../../common/ContactProfile/commonUtils';
import { IContact, IContactProfile } from '../../../RightSideContainer/TeamInbox/Conversations/interfaces';
import { getActivePatientProfile as fetchActivePatientProfile } from '../../../../services/CommonService/AidBoxService';
import useContactProfile from '../../../common/ContactProfile/useContactProfile';


interface IMemberTypeData {
  id: string;
  userId: string;
}

const initialUserAction = {
  code: '',
  userName: '',
  uuid: '',
};

type CareTeamScreenNavigationProp = NativeStackNavigationProp<MembersScreenStackParamList>

const useCareTeam = ({
  contactUUID,
  navigation,
  contactAccountLocationId,
  contactData
}: {
  contactUUID: string;
  navigation?: CareTeamScreenNavigationProp;
  contactAccountLocationId?: string;
  contactData: IContact;
}) => {
  const intl = useIntl();
  const commonContextData = useContext(CommonDataContext);
  const toast = useToast();
  const currentEHR = getCurrentEHR(contactAccountLocationId, '');
  const isElation = currentEHR === EHRName.ELATION;
  const isEnableMultiTenancy = isMultiTenancyEnabled(commonContextData?.userSettings);

  const {getAllPatientContactProfiles} = useContactProfile({});

  const allowedUserAccountLocationUuids = getAllowedUserAccountLocationUuids(
    USER_ACCESS_PERMISSION.ENTITY.DASHBOARD_WINDOW.code,
    MAIN_MENU_CODES.CONSUMER
  );

  const [contactProfile, setContactProfile] = useState<{
    haveAccessToPatientActiveLocation: boolean;
    selectedPatientProfile: IContactProfile | null;
    isLoading: boolean;
    activeProfile: IContactProfile | null;
    allPatientContactProfiles: IContactProfile[];
  }>({
    haveAccessToPatientActiveLocation: !isEnableMultiTenancy,
    selectedPatientProfile: null,
    isLoading: isEnableMultiTenancy,
    activeProfile: null,
    allPatientContactProfiles: [],
  });

  const [currentTeam, setCurrentTeam] = useState<IUser[]>([]);
  const [userLoading, setUserLoading] = useState(false);
  const [userData, setUserData] = useState<IUser[]>([]);
  const [localPCP, setLocalPCP] = useState<string>('');
  const accountId = getAccountUUID();
  const [selectedValue, setSelectedValue] = useState<IUserSearch | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedUserAndAction, setSelectedUserAndAction] = useState<{
    code: string;
    userName: string;
    uuid: string;
  }>(initialUserAction);

  const [careTeamData, setCareTeamData] = useState<{
    id: string;
    userPoolId: string;
  }>({
    id: '',
    userPoolId: '',
  });

  const contactLocationGroupId = useMemo(() => {
    return commonContextData.accountLocationListWithEHR?.find(
      (location) => location.uuid === contactAccountLocationId
    )?.locationGroupId;
  }, [contactAccountLocationId]);

  const [selectedUserForEdit, setSelectedUserForEdit] = useState<
    string | undefined
  >();
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    modal: false,
    id: '',
    isDeleteLoading: false,
  });
  const isCareProgramEnabled = isEnableCareProgram(
    commonContextData.userSettings
  );
  const [selectedMemberType, setSelectedMemberType] = useState<string>(
    isCareProgramEnabled ? '' : 'other'
  );

  const careProgramRoles = [
    CARE_TEAM_MEMBER_TYPE.CARE_MANAGER,
    CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER,
  ];

  const careTeamMemberTypesList = getMlovListByCategory(
    MLOV_CATEGORY.CARE_TEAM_MEMBER_TYPE
  )?.filter((item) =>
    isCareProgramEnabled ? true : !careProgramRoles.includes(item.code)
  );
  const [roleUpdateLoading, setRoleUpdateLoading] = useState(false);
  const [careTeamMemberIds, setCareTeamMemberIds] = useState<{
    [key: string]: IMemberTypeData | undefined;
  }>({});
  const careTeamMemberTypes = {
    [CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER
    ),
    [CARE_TEAM_MEMBER_TYPE.PCP]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.PCP
    ),
    [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: getMlovIdFromCode(
      careTeamMemberTypesList,
      CARE_TEAM_MEMBER_TYPE.CARE_MANAGER
    ),
  };

  const isAllCareTeamMemberType = (memberType: string) => {
    return !careTeamMemberTypesList.map((item) => item.id).includes(memberType);
  };

  const removeLocalPCP = () => {
    setLocalPCP('');
  };
  const onDeleteConfirmationCancel = () => {
    setDeleteConfirmation((prev) => ({...prev, modal: false, id: '', isDeleteLoading: false}));
  };

  const onLocationFilterChange = (value: string) => {
    setContactProfile((prev) => {
      const selectedPatientProfile = prev?.allPatientContactProfiles?.find?.((profile) => profile?.id === value) || null;
      return {
        ...prev,
        selectedPatientProfile,
      }
    })
  };

  const onClose = () => {
    setLocalPCP('');
    setIsOpen(false);
    setCurrentTeam(
      currentTeam ? currentTeam.filter((member: IUser) => !member.isLocal) : []
    );
    setSelectedMemberType(isCareProgramEnabled ? '' : 'other');
  };
  const headers = {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
  };

  const [searchUsers, {loading: searchUserLoading}] = useLazyQuery(GET_USERS_BY_RESOURCE_CODES);

  const getCareTeamMemberTypeOptions = () => {
    const teamWithMemberTypes = currentTeam
      ?.filter((user) => user?.memberType && user?.memberType?.length > 0)
      .flatMap((item) => item.memberType);

    if (!currentTeam || currentTeam.length === 0) {
      return [
        ...careTeamMemberTypesList,
        {
          id: 'other',
          code: 'other',
          value: 'Care Team Member',
          categoryId: '',
        },
      ];
    }
    let options = careTeamMemberTypesList;
    if (
      teamWithMemberTypes?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]
      )
    ) {
      options = options.filter(
        (item) => item.id !== careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]
      );
    }

    if (
      teamWithMemberTypes?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
      )
    ) {
      options = options.filter(
        (item) =>
          item.id !==
          careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
      );
    }

    if (
      teamWithMemberTypes?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      )
    ) {
      options = options.filter(
        (item) =>
          item.id !== careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      );
    }

    return [
      ...options,
      {
        id: 'other',
        code: 'other',
        value: 'Care Team Member',
        categoryId: '',
      },
    ];
  };

  //USER SEARCH Fn
  const onSearch = async (searchString: string, updatedTeam?:IUser[]) => {
    const response = await searchUsers({
      variables: getUserSearchWhereConditionForCareTeam({
        selectedMemberType: selectedMemberType,
        careTeamMemberTypes,
        searchString,
        currentTeam: updatedTeam || currentTeam,
        contactAccountLocationId: contactAccountLocationId as string,
        isElation,
        contactLocationGroupId: contactLocationGroupId as string,
        isMultiTenanyEnabled: isEnableMultiTenancy
      }),
      fetchPolicy: 'no-cache',
    });
    setUserData(response?.data?.searchUsers?.users || []);
  };

  const [addOrUpdateCareTeamMemberType] = useMutation(
    ADD_OR_UPDATE_CARE_TEAM_MEMBER_TYPES,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },
    }
  );

  // GET SINGLE USER
  const [getSingleUserById] = useLazyQuery(GET_SINGLE_USER, {
    fetchPolicy: 'no-cache',
  });

  // GET USER DETAILS FROM IDS
  const [getUserDetailData] = useLazyQuery(GET_USERS_WITH_IDS, {
    fetchPolicy: 'no-cache',
  });
  // GET ALL CARE TEAM
  const [fetchCareTeam, {loading, refetch}] = useLazyQuery(GET_CARE_TEAM_V2, {
    fetchPolicy: 'no-cache',
    variables: {
      params: {
        contactId: contactUUID,
        contactProfileId: isEnableMultiTenancy ? contactProfile?.selectedPatientProfile?.id || null : null,
      },
    },
    ...headers,
    onCompleted: async (careT: {
      getContactCareTeam: IGetContactCareTeam;
    }) => {
      setCareTeamData((prev) => ({
        ...prev,
        id: careT?.getContactCareTeam?.id,
        userPoolId: careT?.getContactCareTeam?.userPool?.id,
      }));
      const memberTypesData = processCareTeamMemberTypes(
        careT?.getContactCareTeam
      );
      // setCareTeamId(careT?.getContactCareTeam?.id || '');
      getUserDetailData({
        variables: {
          userIds:
            careT?.getContactCareTeam?.userPool?.userPoolUsers?.map(
              (user: any) => user.userId
            ) || [],
          accountId: accountId,
        },
        onCompleted: (data) => {
          if (careT?.getContactCareTeam?.userPool?.userPoolUsers && data?.users) {
            setCurrentTeam(
              careT?.getContactCareTeam?.userPool?.userPoolUsers?.map(
                (teamMember: any) => {
                  const user = data.users.find((user: any) => {
                    return user.uuid === teamMember.userId;
                  });
                  if (user) {
                    const memberTypeId = memberTypesData
                      .filter((member) => member.userId === user.uuid)
                      ?.map((item) => item.careTeamMemberTypeId);
                    return {
                      ...user,
                      ...teamMember,
                      userId: user.id,
                      isLocal: false,
                      memberType: memberTypeId,
                    };
                  }
                  return teamMember;
                }
              )
            );
          }
          setDataLoading(false);
        },
      });
      setDataLoading(false);
    },
    onError: () => {
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
      setDataLoading(false);
    },
  });

  const processCareTeamMemberTypes = (careTeam: IGetContactCareTeam) => {
    const memberTypes = careTeam?.careMemberTypes as {
      careTeamId: string;
      careTeamMemberTypeId: string;
      id: string;
      userId: string;
    }[];
    const data: {[key: string]: IMemberTypeData} = {};
    if (memberTypes) {
      memberTypes.forEach((memberType) => {
        switch (memberType.careTeamMemberTypeId) {
          case careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]:
            data[CARE_TEAM_MEMBER_TYPE.PCP] = {
              id: memberType.id,
              userId: memberType.userId,
            };
            break;
          case careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]:
            data[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER] = {
              id: memberType.id,
              userId: memberType.userId,
            };
            break;
          case careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]:
            data[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER] = {
              id: memberType.id,
              userId: memberType.userId,
            };
            break;
          default:
            break;
        }
        setCareTeamMemberIds(data);
        return memberTypes;
      });
    }
    return memberTypes;
  };

  const addOrUpdateUserOrRoles = (users: IUser[]) => {
    const careMembers: {
      userId: string, 
      careTeamMemberTypes?: {
        careTeamMemberTypeId: string
        isDelete?: boolean
      }[],
    }[] = [];
    const updatePcp = users.find((user) =>
      user.memberType?.includes(careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP])
    );
    if (updatePcp) {
      const variables = {
          userId: updatePcp.uuid,
          careTeamMemberTypes: [{
            careTeamMemberTypeId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]
          }]
      };
      careMembers.push(variables)
      // HERE If no new user pcp is found but there is db id that means we need to delete it
    } else if (careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.PCP]?.userId) {
      const variables = {
          userId: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.PCP]?.userId || '',
          careTeamMemberTypes: [{
            careTeamMemberTypeId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP],
            isDelete: true,
          }]
      };
      variables?.userId && careMembers.push(variables)
      setCareTeamMemberIds((prev) => ({
        ...prev,
        [CARE_TEAM_MEMBER_TYPE.PCP]: undefined,
      }));
    }

    const updateBilling = users.find((user) =>
      user.memberType?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
      )
    );

    if (updateBilling) {
      const variables = {
          userId: updateBilling.uuid,
          careTeamMemberTypes: [{
            careTeamMemberTypeId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]
          }]
      };
      careMembers.push(variables)
      // HERE If no new user BILLING_PRACTITIONER is found but there is db id that means we need to delete it
    } else if (
      careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]?.userId
    ) {
      const variables = {
          userId: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]?.userId || '',
          careTeamMemberTypes: [{
            careTeamMemberTypeId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER],
            isDelete: true,
          }]
      };
      variables?.userId && careMembers.push(variables)
      setCareTeamMemberIds((prev) => ({
        ...prev,
        [CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER]: undefined,
      }));
    }

    const updateCareManager = users.find((user) =>
      user.memberType?.includes(
        careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
      )
    );

    if (updateCareManager) {
      const variables = {
          userId: updateCareManager.uuid,
          careTeamMemberTypes: [{
            careTeamMemberTypeId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]
          }]
      };
      careMembers.push(variables)
      // HERE If no new user CARE_MANAGER is found but there is db id that means we need to delete it
    } else if (
      careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]?.userId
    ) {
      const variables = {
          userId: careTeamMemberIds?.[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]?.userId || '',
          careTeamMemberTypes: [{
            careTeamMemberTypeId: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
            isDelete: true,
          }]
      };
      variables?.userId && careMembers.push(variables)
      setCareTeamMemberIds((prev) => ({
        ...prev,
        [CARE_TEAM_MEMBER_TYPE.CARE_MANAGER]: undefined,
      }));
    }

    // check that every user is added in careMembers or not
    users.forEach((user) => {
      if (!careMembers.find((member) => member.userId === user.uuid)) {
        careMembers.push({
          userId: user.uuid,
        })
      }
    })
    
    return careMembers;
  };

  // ADD CARE TEAM QUERY
  const [addOrUpdateCareTeam, {loading: saveCareTeamLoading}] = useMutation(
    ADD_CARE_TEAM_V2,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      }
    }
  );
  // DELETE CARE TEAM QUERY
  const [deleteCareTeam, {loading: isDeleteLoading}] = useMutation(
    DELETE_CARE_TEAM_USER,
    {
      ...headers,
      onError: () => {
        showToast(
          toast,
          'Something went wrong. Please try again later',
          ToastType.error
        );
        onClose();
      },
    }
  );
  // ON SAVE CLICKED BY USER
  const handleCareTeamSave = () => {
    setRoleUpdateLoading(true);

    const careMembers = addOrUpdateUserOrRoles(currentTeam || []);
    addOrUpdateCareTeam({
      variables: {
        params: {
          contactId: contactUUID,
          contactProfileId: contactProfile?.activeProfile?.id,
          accountLocationId: contactProfile?.activeProfile?.accountLocationUuid,
          careMembers: careMembers,
        },
      },
    }).then(async (data) => {
        setRoleUpdateLoading(false);
        if (data?.data?.addOrUpdateContactCareTeam?.success) {
          onClose();
          showToast(
            toast,
            'Care Team Added Successfully',
            ToastType.success,
            2000
          );
          refetch();
          if (!isWeb()) {
            navigation?.pop();
          } else {
            // if pcp found in careMembers then broadcast the event
            const pcp = careMembers.find((member) => {
              return member.careTeamMemberTypes?.find((type) => {
                return type.careTeamMemberTypeId === careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP]
              })
            });
            if (pcp) {
              localBroadcastEvent(
                CONVERSATION_LOCAL_EVENT_CODES.LOCAL_PCP_USER_CHANGE,
                {
                  userData: pcp,
                  markAsPCP: true,
                  contactUUID: contactUUID,
                }
              );
            }
          }
        }
    }).catch(() => {
      setRoleUpdateLoading(false);
      showToast(
        toast,
        'Something went wrong. Please try again later',
        ToastType.error
      );
      onClose();
    });
  };

  useEffect(() => {
    onSearch('');

    fetchActivePatientProfile(
      contactData?.id || ''
    ).then(async (res) => {
      const contactProfiles:IContactProfile[] = res?.data?.contactProfiles || [];
      let contactProfile = contactProfiles?.[0];
      const allPatientContactProfilesData = await getAllPatientContactProfiles({
        contactId: contactData?.id || '',
        fetchAllProfiles: true,
      })
      let allPatientContactProfiles = allPatientContactProfilesData?.contactProfiles || [];
      allPatientContactProfiles = allPatientContactProfiles.filter((profile, index, self) =>
        index === self.findIndex((t) => t.accountLocationUuid === profile.accountLocationUuid)
      );
      const activeProfile = getActivePatientProfile(allPatientContactProfiles || [])
      const haveAccessToPatientActiveLocation = checkLocationAccess({
        locationUuids: [activeProfile?.accountLocationUuid || ""],
        allowedLocationUuids: allowedUserAccountLocationUuids,
        isMultiTenancyEnabled: isEnableMultiTenancy,
      });

      if (activeProfile) {
        contactProfile = activeProfile || contactProfile;
        allPatientContactProfiles.sort((a, b) => a.id === activeProfile?.id ? -1 : 1);
      }

      setContactProfile((prev) => {
        return {
          ...prev,
          selectedPatientProfile: contactProfile,
          activeProfile,
          allPatientContactProfiles,
          haveAccessToPatientActiveLocation: haveAccessToPatientActiveLocation,
          isLoading: false,
        };
      });
    }).catch(() => {
      setContactProfile((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    });
  }, []);

  useEffect(() => {
    setDataLoading(true);
    if (isEnableMultiTenancy) {
      setCurrentTeam([])
      setLocalPCP('')
      setCareTeamMemberIds({})
      // this is for preventing the api call before the contactProfile data is set
      contactProfile?.selectedPatientProfile?.id && fetchCareTeam();
    } else {
      fetchCareTeam();
    }
  }, [contactUUID, contactProfile?.selectedPatientProfile?.id]);

  useEffect(() => {
    if (saveCareTeamLoading && !isWeb()) {
      toast.closeAll();
      showToast(
        toast,
        intl.formatMessage({id: 'savingCareTeam'}),
        ToastType.info
      );
    }
  }, [saveCareTeamLoading]);

  // ON DELETE CLICKED
  const handleDelete = async (id: string, isLocal: boolean) => {
    if (isLocal) {
      const updatedTeam = currentTeam?.filter((item: IUser) => item.id !== id);
      setCurrentTeam(updatedTeam);
      removeLocalPCP();
      onSearch('',updatedTeam);
    } else {
      const userUUID = currentTeam?.find(
        (member) => member.id === deleteConfirmation.id
      )?.uuid;
      if (!userUUID) {
        return;
      }
      setDeleteConfirmation((prev) => ({ ...prev, isDeleteLoading: true }));
        const userToBeDeleted = currentTeam?.find(
          (user) => user.id === deleteConfirmation.id
        );

        await addOrUpdateCareTeam({
          variables: {
            params: {
              contactId: contactUUID,
              contactProfileId: contactProfile?.activeProfile?.id,
              accountLocationId: contactProfile?.activeProfile?.accountLocationUuid,
              careMembers: [{
                userId: userToBeDeleted?.uuid,
                isDelete: true,
              }]
            }
          },
        });

        if (
          userToBeDeleted?.memberType &&
          userToBeDeleted?.memberType?.length > 0
        ) {
          const newCareTeamIds = {...careTeamMemberIds};
          userToBeDeleted?.memberType?.forEach(async (memberType: string) => {
            const code = careTeamMemberTypesList.find(
              (item) => item.id === memberType
            )?.code;
            if (code) {
              newCareTeamIds[code] = undefined;
            }
          });
          setCareTeamMemberIds(newCareTeamIds);
        }
        refetch();
        setDeleteConfirmation((prev) => ({ ...prev, modal: false, id: '', isDeleteLoading: false }));
        showToast(
          toast,
          'Care Team Member Deleted Successfully',
          ToastType.success
        );
      // }
    }
  };
  // ON USER ADD MEMBER FROM SEARCH
  const handleAddCareTeam = (value: string) => {
    setUserLoading(true);
    getSingleUserById({
      variables: {
        userId: value,
      },
      onCompleted: (data) => {
        let updatedTeam: IUser[] = [];
        setCurrentTeam((prev) => {
          updatedTeam = [
            {
              ...data.users[0],
              userId: data.users[0]?.id,
              isLocal: true,
              memberType: isAllCareTeamMemberType(selectedMemberType)
                ? undefined
                : [selectedMemberType],
            },
            ...(prev || []),
          ];
          return updatedTeam;
        });
        setSelectedValue(undefined);
        setUserLoading(false);
        setSelectedMemberType(isCareProgramEnabled ? '' : 'other');
        onSearch('', updatedTeam);
      },
      onError: () => {
        setUserLoading(false);
      },
    });
  };

  const onActionPressed = async (action: CareTeamAction, member: IUser) => {
    switch (action) {
      case CareTeamAction.delete:
        if (member?.isLocal) {
          handleDelete(member.id, true);
        } else {
          setSelectedUserForEdit(undefined);
          setDeleteConfirmation((prev) => ({ ...prev, modal: true, id: member.id, isDeleteLoading: false }));
        }
        break;
      case CareTeamAction.markAsPCP:
        const response = await searchUsers({
          variables: getUserSearchWhereConditionForCareTeam({
            selectedMemberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP],
            careTeamMemberTypes,
            searchString: '',
            currentTeam: currentTeam || [],
            contactAccountLocationId: contactAccountLocationId as string,
            isElation,
            contactLocationGroupId: contactLocationGroupId as string,
            isMultiTenanyEnabled: isEnableMultiTenancy,
            uuids: [member.uuid],
            limit: 1,
            excludeCareTeamFilter: true
          }),
          fetchPolicy: 'no-cache',
        });
        const isUserFetched = response?.data?.searchUsers?.users?.length > 0;
        if (isUserFetched) {
          onChangeMemberType({
            memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP],
            user: member,
            isMark: true,
          });
        } else {
          showToast(
            toast,
            'User cannot be added as PCP',
            ToastType.error
          );
        }
        break;
      case CareTeamAction.unMarkAsPCP:
        onChangeMemberType({
          memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP],
          user: member,
          isMark: false,
        });
        break;
      case CareTeamAction.markAsCareManager:
        onChangeMemberType({
          memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
          user: member,
          isMark: true,
        });
        break;
      case CareTeamAction.unMarkAsCareManager:
        onChangeMemberType({
          memberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.CARE_MANAGER],
          user: member,
          isMark: false,
        });
        break;
      case CareTeamAction.markAsBilling: {
        const billingResponse = await searchUsers({
          variables: getUserSearchWhereConditionForCareTeam({
            selectedMemberType: careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER],
            careTeamMemberTypes,
            searchString: '',
            currentTeam: currentTeam || [],
            contactAccountLocationId: contactAccountLocationId as string,
            isElation,
            contactLocationGroupId: contactLocationGroupId as string,
            isMultiTenanyEnabled: isEnableMultiTenancy,
            uuids: [member.uuid],
            limit: 1,
            excludeCareTeamFilter: true
          }),
          fetchPolicy: 'no-cache',
        });
        const isUserFetched = billingResponse?.data?.searchUsers?.users?.length > 0;
        if (isUserFetched) {
          onChangeMemberType({
            memberType:
              careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER],
          user: member,
          isMark: true,
        });
        } else {
          showToast(
            toast,
            'User cannot be added as Billing Practitioner',
            ToastType.error
          );
        }
        break;
      }
      case CareTeamAction.unMarkAsBilling:
        onChangeMemberType({
          memberType:
            careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.BILLING_PRACTITIONER],
          user: member,
          isMark: false,
        });
    }
  };

  const agentsData = getAgentsList();

  const getUserList = (team: IUser[]) => {
    return (
      team?.map((user) => {
        const matchUser = agentsData?.find((agent: any) => {
          if (user?.userId && user?.userId === agent?.id) {
            return agent;
          } else if (user?.id && user?.id === agent?.id) {
            return agent;
          }
        });
        if (matchUser) return {...user, agent: matchUser};
        else return user;
      }) || []
    );
  };

  const getFilteredUserListByCareTeam = (team: IUser[]) => {
    const userList = getUserList(team);
    const existingUuids = currentTeam?.map(user => user.uuid) || []
    return userList.filter(user => !existingUuids.includes(user.uuid))
  }
  const onChangeMemberType = async (params: {
    memberType: string;
    user: IUser;
    isMark: boolean;
    isDirectUpdate?: boolean;
  }) => {
    const {memberType, user, isMark} = params;
    const {uuid} = user;

    const isUserWithSameRoleExist = currentTeam?.find((userListItem) => {
      return (
        userListItem.memberType?.includes(memberType) &&
        userListItem.uuid !== uuid
      );
    });

    if (!user.isLocal) {
      const updateTypeCode =
        careTeamMemberTypesList.find((type) => type.id === memberType)?.code ||
        '';
      const params = isMark
        ? {
            contactId: contactUUID,
            contactProfileId: contactProfile?.activeProfile?.id,
            accountLocationId: contactProfile?.activeProfile?.accountLocationUuid,
            careMembers: [
              {
                userId: uuid,
                careTeamMemberTypes: [{
                  careTeamMemberTypeId: memberType,
                }]
              }
            ]
          }
        : {
            contactId: contactUUID,
            contactProfileId: contactProfile?.activeProfile?.id,
            accountLocationId: contactProfile?.activeProfile?.accountLocationUuid,
            careMembers: [
              {
                userId: uuid,
                careTeamMemberTypes: [{
                  careTeamMemberTypeId: memberType,
                  isDelete: true,
                }]
              }
            ],
          };
      const response = await addOrUpdateCareTeam({
        variables: {
          params: params
        },
      });

      if (response?.data?.addOrUpdateContactCareTeam?.success) {
        if (
          memberType === careTeamMemberTypes[CARE_TEAM_MEMBER_TYPE.PCP] &&
          isWeb()
        ) {
          localBroadcastEvent(
            CONVERSATION_LOCAL_EVENT_CODES.LOCAL_PCP_USER_CHANGE,
            {
              userData: user,
              markAsPCP: isMark,
              contactUUID: contactUUID,
            }
          );
        }
        setCareTeamMemberIds((prev) => ({
          ...prev,
          [updateTypeCode]: {
            id: response?.data?.addOrUpdateCareTeamMemberTypes?.id,
            userId: uuid as string,
          },
        }));
      }
    }

    const newList = currentTeam?.map((user) =>
      user.uuid === isUserWithSameRoleExist?.uuid
        ? {
            ...user,
            memberType: user.memberType?.filter(
              (memberTypeItem) => memberTypeItem !== memberType
            ),
          }
        : user
    );
    setCurrentTeam(
      newList?.map((user) =>
        user.uuid === uuid
          ? {
              ...user,
              memberType: isMark
                ? [...((user?.memberType as string[]) || []), memberType]
                : user.memberType?.filter((item) => item !== memberType),
            }
          : user
      )
    );
    setSelectedUserForEdit(undefined);
  };

  const getLocationNameByUuid = (uuid?: string) => {
    if (!uuid) {
      return '';
    }
    return getLocationNameFromLocationsUsingUuid(uuid, commonContextData?.accountLocationListWithEHR)
  }
  return {
    careTeamMemberTypes,
    selectedUserForEdit,
    isElation,
    mainContentLoading: loading || contactProfile.isLoading,
    currentTeam,
    careTeamMemberTypesList,
    selectedUserAndAction,
    initialUserAction,
    deleteConfirmation,
    isOpen,
    saveCareTeamLoading: saveCareTeamLoading || roleUpdateLoading,
    selectedMemberType,
    drawerListLoading: loading || isDeleteLoading,
    selectedValue,
    userLoading,
    searchUserLoading,
    userData,
    agentsData,
    dataLoading,
    isCareProgramEnabled,
    haveAccessToPatientActiveLocation: contactProfile.haveAccessToPatientActiveLocation,
    isEnableMultiTenancy,
    contactProfile,
    handleSelectUserForEdit: (id: string) => setSelectedUserForEdit(id),
    onActionPressed,
    getUserList,
    getFilteredUserListByCareTeam,
    onDeleteConfirmationCancel,
    handleDelete,
    onClose,
    handleDrawer: (value: boolean) => setIsOpen(value),
    handleCareTeamSave,
    getCareTeamMemberTypeOptions,
    onSelectMemberType: (type: string) => {
      setSelectedMemberType(type);
      setUserData([]);
    },
    setUserData,
    onSearch,
    handleUserSelect: (value: IUserSearch, data: any) => {
      setSelectedValue(data);
      handleAddCareTeam(value.value);
    },
    handleDeleteConfirmation: (id: string) =>
      setDeleteConfirmation((prev) => ({ ...prev, modal: true, id: id, isDeleteLoading: false })),

    fetchCareTeam,
    onLocationFilterChange,
    getLocationNameByUuid,
  };
};

export default useCareTeam;
