import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import { FormRenderer } from "../FormRenderer";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { ImageSchema } from "../Schema/ComponentsSchema";
import { ImageComponent } from "../BaseComponents/BaseComponentInterface";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import Image from "../../FHFormio/CustomComponents/Image/Image";
import { FileService } from "../FIleService";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import ImageSvg from "../../../../../assets/Icons/FormBuilder/ImageSvg";
import { v4 as uuidV4 } from 'uuid';

export const ALLOWED_MIME_TYPES_FOR_IMAGES = [
  'image/jpg',
  'image/png',
  'image/jpeg',
];

export const ImageFieldFormElement: FormElement = {
  type: ImageSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(ImageSchema),
    key: getUniqueKey(map, ImageSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(ImageSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: ImageSvg,
    label: ImageSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

const localValidate = (elementInstance: FormElementInstance, value: string, silentCheck?: boolean): ValidationResult => {
  if (elementInstance.validate?.required && !value) {
    return { isValid: false, errorMessage: `${elementInstance.label || 'This field'} is required`, key: elementInstance.key, fieldValue: value };
  }
  return { isValid: true, errorMessage: '', key: elementInstance.key, fieldValue: value };
}

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = useCallback((imageUrl: string) => {
    const valid = localValidate(elementInstance, imageUrl);
    setErrorMessage(valid.errorMessage);
    setValue(imageUrl);
  }, [elementInstance]);

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        isBuilderMode={true}
        onChange={onChange}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation,
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState<string>(defaultValue || selectedValue || '');
  const isMounted = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      if (!isMounted.current) return {
        isValid: true,
        errorMessage: '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
      return localValidate(elementInstance, valueToValidate || '', silentCheck);
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: string) => {
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
    />
  );
}

const ComponentView = ({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  isReadOnly,
  isBuilderMode,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange?: (value: string) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
  isBuilderMode?: boolean;
}) => {
  const fileService = new FileService();
  const { description, allowToShare } = elementInstance as ImageComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;

  const onChangeHandler = useCallback((value: string) => {
    onChange?.(value);
  }, []);

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
       <Image
        component={elementInstance}
        options={{}}
        disabled={false}
        validateRef={{}}
        onChange={onChangeHandler}
        value={defaultValue}
        setValueRef={{}}
        fileService={fileService}
        isBuilderMode={isBuilderMode}
      />
      {description && (
        <Description description={description} />
      )}
      {errorMessage && (
        <span className="error-message">{errorMessage}</span>
      )}
    </div>
  );
};

ComponentView.displayName = 'ImageComponentView';

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const isAllowShare = isAllowShareFormComponentWithPatient(userSettings);
  const element = elementInstance as ImageComponent;

  const formElements = useMemo(() => [
    {
      label: 'Upload Image',
      tableView: false,
      storage: 'base64',
      webcam: false,
      fileTypes: [],
      key: 'file',
      type: 'file',
      input: true,
      multiple: false,
      hidden: true,
      clearOnHide: false,
      filePattern: 'image/*',
    },
    {
      type: 'select',
      input: true,
      key: 'align',
      label: 'Align Image',
      tooltip:
        'Image will be aligned as per selected option. Default value is center',
      defaultValue: 'center',
      dataSrc: 'values',
      data: {
        values: [
          {label: 'Center', value: 'center'},
          {label: 'Left', value: 'left'},
          {label: 'Right', value: 'right'},
        ],
      },
    },
    {
      type: 'select',
      input: true,
      key: 'scale',
      label: 'Scale Image',
      tooltip:
        'Image will be scaled as per the selected value. Default value is 33%.',
      defaultValue: '33%',
      dataSrc: 'values',
      data: {
        values: [
          {label: '10%', value: '10%'},
          {label: '25%', value: '25%'},
          {label: '33%', value: '33%'},
          {label: '50%', value: '50%'},
          {label: '75%', value: '75%'},
          {label: '100%', value: '100%'},
        ],
      },
    },
    ...SearchableComponentFields,
    ...NewConditionalFields,
    ...(isAllowShare ? ShareWithPatientFields : []),
    ...KeyField,
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      hidden: true,
      clearOnHide: false,
    },
    {
      label: 'ImageURL',
      key: 'imageURL',
      type: 'hidden',
      hidden: true,
      clearOnHide: false,
      input: true,
    },
  ], [isAllowShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
