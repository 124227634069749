import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, debounce } from "lodash";
import classNames from "classnames";
import { getUniqueKey } from "../CustomFormEngineUtils";
import {
  ElementsType,
  FormElement,
  FormElementInstance,
  SubmitFunction,
  ValidationFunction,
  ValidationResult,
} from "../FormComponents/FormComponents";
import Label from "../BaseComponents/Label";
import { FormRenderer } from "../FormRenderer";
import BasicFields from "../../FHFormio/EditFormFields/BasicFields";
import KeyField from "../../FHFormio/EditFormFields/KeyField";
import { useCustomFormBuilderContext } from "../Context/CustomFormBuilder.context";
import { usePropertiesFormRenderer } from "../Hooks/usePropertiesFormRenderer";
import { useFormRendererContext } from "../Context/FormRenderer.context";
import { FormRendererActionType } from '../Context/FormRendererReducer';
import { CustomFormBuilderActionTypes } from "../CustomFormEngineInterfaces";
import Description from "../BaseComponents/Description";
import NewConditionalFields from "../BaseComponents/NewConditionalFields";
import { DateSchema } from "../Schema/ComponentsSchema";
import { Component } from "../BaseComponents/BaseComponentInterface";
import DatePicker from "../../FHFormio/CustomComponents/DatePicker/DatePicker";
import { getDateStrFromFormat } from "../../../../../utils/DateUtils";
import { DATE_FORMATS, DISPLAY_DATE_FORMAT } from "../../../../../constants/StringConst";
import DateComponent from "../../FHFormio/CustomComponents/Date/Date";
import DateSvg from "../../../../../assets/Icons/FormBuilder/DateSvg";
import QuillConfig from "../../FHFormio/Builder/QuillConfig";
import SearchableComponentFields from "../../FHFormio/EditFormFields/SearchableComponentFields";
import ShareWithPatientFields, { isAllowShareFormComponentWithPatient } from "../../FHFormio/EditFormFields/ShareWithPatientFields";
import { v4 as uuidV4 } from 'uuid';
import { CommonDataContext } from "../../../../../context/CommonDataContext";
import { IFormCommonData } from "../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper";

export const DateFieldFormElement: FormElement = {
  type: DateSchema.type as ElementsType,
  construct: (id: string, map: Map<string, boolean>) => ({
    id,
    referenceId: uuidV4(),
    ...cloneDeep(DateSchema),
    key: getUniqueKey(map, DateSchema.key),
  }),
  clone: (id: string, instance: FormElementInstance, map: Map<string, boolean>) => {
    const clonedInstance = cloneDeep(instance);
    clonedInstance.referenceId = uuidV4();
    clonedInstance.componentId = undefined;
    clonedInstance.formComponentId = undefined;
    const key = getUniqueKey(map, clonedInstance.key);
    map.set(key, true);
    return {
      ...cloneDeep(DateSchema),
      ...clonedInstance,
      id,
      key,
    }
  },
  designerBtnElement: {
    icon: DateSvg,
    label: DateSchema.label || '',
  },
  designerComponent: DesignerComponent,
  formComponent: FormComponent,
  propertiesComponent: PropertiesComponent,
};

function DesignerComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isInitialMount = useRef(true);
  const validationRef = useRef<any>(undefined);

  const handleChange = useCallback(async (selectedDate: string) => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const result = await validationRef.current?.(selectedDate, false);
    setErrorMessage(result.message);
    setValue(selectedDate);
  }, [elementInstance]);

  return (
    <div className="flex w-full flex-col">
      <ComponentView
        elementInstance={elementInstance}
        defaultValue={value}
        errorMessage={errorMessage}
        onChange={handleChange}
        validationRef={validationRef}
      />
    </div>
  );
}

function FormComponent({
  elementInstance,
  submitValue,
  defaultValue,
  isReadOnly,
  onRegisterValidation
}: {
  elementInstance: FormElementInstance;
  submitValue?: SubmitFunction;
  defaultValue?: string;
  isReadOnly?: boolean;
  onRegisterValidation: (key: string, validate: ValidationFunction) => void;
}) {
  const { state, dispatch } = useFormRendererContext();
  const selectedValue = elementInstance.selectedValue;
  const [value, setValue] = useState(defaultValue || selectedValue || '');
  const validationRef = useRef<any>(undefined);
  const isMounted = useRef(true);
  const isInitialMount = useRef(true);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Core validation logic
  const validateField = useCallback(async (valueToValidate?: string, silentCheck?: boolean): Promise<ValidationResult> => {
    try {
      const result = await validationRef.current?.(valueToValidate, silentCheck);
      return {
        isValid: result?.isValid ?? true,
        errorMessage: result?.message || '',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    } catch (error) {
      console.error(`Validation error for ${elementInstance.key}:`, error);
      return {
        isValid: false,
        errorMessage: 'Validation failed unexpectedly',
        key: elementInstance.key,
        fieldValue: valueToValidate
      };
    }
  }, [elementInstance.key, elementInstance.validate?.required]);

  // Debounced validation with cleanup
  const debouncedValidation = useMemo(
    () => debounce(async (valueToValidate: string) => {
      if (!isMounted.current) return;
      const result = await validateField(valueToValidate);
      dispatch({
        type: FormRendererActionType.SET_INVALID_FIELDS,
        payload: { key: elementInstance.key, errorMessage: result.errorMessage },
      });
      return result;
    }, 300),
    [validateField, elementInstance.key]
  );

// Separate cleanup effect that only runs on unmount
  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    onRegisterValidation?.(elementInstance.key, validateField);
  }, [elementInstance.key, validateField, onRegisterValidation]);

  const handleChange = useCallback(async (newValue: string) => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    setValue(newValue);
    if (!submitValue) return;
    await debouncedValidation(newValue);
    submitValue(elementInstance.key, newValue);
  }, [debouncedValidation, elementInstance.key, submitValue]);

  return (
    <ComponentView
      elementInstance={elementInstance}
      defaultValue={value}
      errorMessage={state.hideErrorMessages ? '' : (state.invalidFields[elementInstance.key] || '')}
      isReadOnly={isReadOnly}
      onChange={handleChange}
      validationRef={validationRef}
    />
  );
}

const ComponentView = ({
  elementInstance,
  defaultValue,
  onChange,
  errorMessage,
  isReadOnly,
  validationRef,
}: {
  elementInstance: FormElementInstance;
  defaultValue?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isReadOnly?: boolean;
  validationRef?: React.MutableRefObject<any>;
}) => {
  const { label, validate, placeholder, description, tooltip, allowToShare, minDate, maxDate } = elementInstance as DateComponent;
  const isAllowToShare = allowToShare !== undefined && allowToShare === false ? false : true;
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPrintForm = contextData?.isPrintForm;

  return (
    <div className={classNames(
      "flex w-full flex-col gap-1",
      isReadOnly ? isAllowToShare ? 'page-break' : 'disallow-to-share' : ''
    )}>
      <Label
        label={label || ''}
        isRequired={validate?.required || false}
        tooltip={tooltip}
        isReadOnly={isReadOnly}
      />
      {isReadOnly && !isPrintForm ? (
        <div className="field-value">{defaultValue ? getDateStrFromFormat(defaultValue, DATE_FORMATS.FORM_DEFAULT_DATE_FORMAT) : '-'}</div>
      ) : (
        <DatePicker
          component={{
            ...elementInstance,
            selectedValue: defaultValue,
            placeholder: placeholder || 'Select date',
            minDate,
            maxDate,
          }}
          disabled={isReadOnly || false}
          options={{}}
          validateRef={validationRef || {}}
          setValueRef={{}}
          value={defaultValue}
          onChange={onChange}
        />
      )}
      {description && (
        <Description description={description} />
      )}
      {errorMessage && (
        <span className="error-message">{errorMessage}</span>
      )}
    </div>
  );
};

ComponentView.displayName = 'DateComponentView';

function PropertiesComponent({
  elementInstance,
}: {
  elementInstance: FormElementInstance;
}) {
  const { state, dispatch, userSettings } = useCustomFormBuilderContext();
  const element = elementInstance as Component;
  const isAllowToShare = isAllowShareFormComponentWithPatient(userSettings);
  const formElements = useMemo(() => [
    {
      type: 'oldtextfield',
      key: 'label',
      label: 'Label',
      input: true,
      validate: {
        required: true,
      },
    },
    {
      type: 'checkbox',
      key: 'validate.required',
      label: 'Is this field required?',
      input: true,
    },
    ...SearchableComponentFields,
    {
      type: 'oldtextfield',
      input: true,
      key: 'placeholder',
      label: 'Placeholder',
      placeholder: 'Placeholder',
      tooltip: 'The placeholder text that will appear when this field is empty.',
    },
    {
      type: 'textarea',
      input: true,
      key: 'description',
      label: 'Description',
      placeholder: 'Description for this field.',
      tooltip: 'The description is text that will appear below the input field.',
      editor: 'quill',
      wysiwyg: QuillConfig,
    },
    ...(isAllowToShare ? ShareWithPatientFields : []),
    {
      type: 'datetime',
      input: true,
      enableTime: false,
      key: 'minDate',
      skipMerge: true,
      label: 'Minimum Date',
      format: DISPLAY_DATE_FORMAT,
      tooltip: 'The minimum date that can be picked.',
    },
    {
      type: 'datetime',
      input: true,
      enableTime: false,
      key: 'maxDate',
      skipMerge: true,
      label: 'Maximum Date',
      format: DISPLAY_DATE_FORMAT,
      tooltip: 'The maximum date that can be picked.',
    },
    ...NewConditionalFields,
    ...KeyField,
  ], [isAllowToShare]);

  const {
    formData,
    formattedFormData,
    components,
    handleFormDataChange,
  } = usePropertiesFormRenderer({
    initialValues: element as Record<string, any>,
    components: formElements
  });

  useEffect(() => {
    dispatch?.({
      type: CustomFormBuilderActionTypes.UPDATE_ELEMENT,
      payload: { updatedElement: formData, builderComponents: state.elements },
    });
  }, [formData, element]);

  return (
    <div>
      <FormRenderer
        components={components}
        builderComponents={state.elements}
        defaultValues={formattedFormData}
        onFormDataChange={handleFormDataChange}
      />
    </div>
  );
}
