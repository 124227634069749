import {Divider, Spinner, Text} from 'native-base';
import {Pressable, ViewStyle} from 'react-native';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {StyleSheet} from 'react-native';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { Colors } from '../../../../../styles';
import Stack from '../../../../common/LayoutComponents/Stack';
import { useReconciliationRequests } from './useReconciliationRequests';
import { ReconciliationSource } from './interface';
import { ModalActionTitle } from '../../../../common/ModalActionTitle/ModalActionTitle';
import { BUTTON_TYPE } from '../../../../../constants';
import { Drawer } from 'antd';
import { useIntl } from 'react-intl';
import { ReconciliationRequestsView } from './ReconciliationRequestsView';

interface ReconcileRequestCountViewProps {
  resourceTypes: string[];
  patientId: string;
  accountLocationId: string;
  contactUuid?: string;
}

export const ReconcileRequestCountView = (props: ReconcileRequestCountViewProps) => {
  const {resourceTypes, patientId, accountLocationId, contactUuid} = props;
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const intl = useIntl();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { countMap, loading, fetchRequestCount } = useReconciliationRequests({
    resourceTypes,
    accountLocationUuid: accountLocationId,
    patientUuid: patientId,
    patientId,
    source: ReconciliationSource.MSO,
    skip: true,
    contactUuid: contactUuid
  });

  useEffect(() => {
    fetchRequestCount(resourceTypes, true);
  }, [resourceTypes]);

  const totalCount = useMemo(() => {
    return Object.values(countMap).reduce((acc, curr) => acc + curr, 0);
  }, [countMap]);

  const onReconcileClick = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
    fetchRequestCount(resourceTypes, true);
  };

  const stackStyles = React.useMemo<ViewStyle>(() => {
    return {
      justifyContent: 'space-between',
      paddingHorizontal: isSidecarContext ? 10 : 16,
      alignItems: 'center',
      marginTop: isSidecarContext ? 6 : 8,
      marginBottom: isSidecarContext ? 8 : 0,
    };
  }, [isSidecarContext]);

  return (
    <Stack direction="column">
      {totalCount > 0 && !loading && (<Stack
        direction="row"
        style={stackStyles}
      >
        <Stack direction="column">
          <Text
            my={isSidecarContext ? 0 : 2}
            size={'smRegular'}
            color={Colors.FoldPixel.GRAY400}
          >
            {`${totalCount} unsynced items from network`}
          </Text>
        </Stack>

        {totalCount > 0 && !loading ? (
          <Pressable onPress={onReconcileClick}>
            <Text fontSize={14} color={Colors.FoldPixel.PRIMARY300}>
              {'Reconcile'}
            </Text>
          </Pressable>
        ) : (
          <></>
        )}
      </Stack>
      )}
      {!isSidecarContext && totalCount > 0 && <Divider marginTop={4} />}
      {openDrawer && (
        <Drawer
          open={true}
          width={isSidecarContext ? '100%' : '40%'}
          onClose={onClose}
          bodyStyle={{paddingTop: 0}}
          title={
            <ModalActionTitle
              isHeadNotSticky
              title={'Network Data'}
              buttonList={[
                {
                  onClick: onClose,
                  show: true,
                  id: 1,
                  btnText: intl.formatMessage({
                    id: 'close',
                  }),
                  size: 'sm',
                  textColor: Colors.Custom.mainSecondaryBrown,
                  variant: BUTTON_TYPE.SECONDARY,
                },
              ]}
            />
          }
        >
          <ReconciliationRequestsView
            patientUuid={patientId}
            patientId={patientId}
            accountLocationId={accountLocationId}
            contactUuid={contactUuid}
            resourceTypes={resourceTypes}
          />
        </Drawer>
      )}
    </Stack>
  );
};

const styles = StyleSheet.create({
  newDataTag: {
    backgroundColor: Colors.Custom.PrimaryColor100,
    color: Colors.Custom.Primary300,
    fontSize: 12,
    marginLeft: 8,
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
    fontWeight: '500',
  },
});
