import parse from 'html-react-parser';
import { Liquid } from 'liquidjs';
import {
  Pressable,
  Spacer,
  VStack,
  View,
  Stack,
  Spinner
} from 'native-base';
import { useContext, useEffect, useState } from 'react';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { getAccountUUID, getFormURL } from '../../../utils/commonUtils';
import EmailTemplateCreatorEditorRightPanel from '../../RightSideContainer/ContentManagement/CampaignManagement/EmailOrSmsCreatorForm/EmailTemplateCreatorEditorRightPanel';
import { ContentTypes } from '../../RightSideContainer/ContentManagement/ContentManagementConsts';
import {
  getAccountMergeTagData,
  getAppLinkMergeTagData,
  getTemplates
} from '../../RightSideContainer/ContentManagement/ContentManagementUtils';
import { ITemplateCategory } from '../../RightSideContainer/ContentManagement/EmailTemplates/interfaces';
import { CommonDataContext } from '../../../context/CommonDataContext';
import { getUserPermissionByEntityAndActionCode, USER_ACCESS_PERMISSION } from '../../RightSideContainer/UserAccess/UserAccessPermission';
import { MAIN_MENU_CODES } from '../../SideMenuBar/SideBarConst';
import {Text} from 'react-native';
import { Colors } from '../../../styles/Colors';
import { useIntl } from 'react-intl';
import RedirectIconSvg from '../Svg/SideCarSvg/RedirectIconSvg';
import RefreshIconSvg from '../Svg/SideCarSvg/RefreshIconSvg';
import EditPenSvg from '../Svg/SideCarSvg/EditPenSvg';
import LocalStorage from '../../../utils/LocalStorage';
import { redirectToNewTab } from '../../SideCar/SidecarUtils';
import { testID, TestIdentifiers } from '../../../testUtils';



const DisplayTemplateById = (props: {
  mergeTags?: Array<any>;
  hideEdit?: boolean;
  templateId: number;
  educationContent?: any;
  formList?: Array<any>;
  form?: any;
  message?: string;
  isGeneralTextPresent?:any;
  setIsFormListTagPresent?:any;
  setIsAppointmentLinkTagPresent?:any;
  templateCategories: ITemplateCategory[];
  onApplyChanges?: (data: any) => void;
}) => {
  const intl = useIntl();

  const accountUuid = getAccountUUID();
  if (!props?.templateId) {
    return <></>;
  }
  const [accountSubdomain, setAccountSubdomain] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [templateLoading, setTemplateLoading] = useState(false);
  const [shouldUpdateEmailTemplate, setShouldUpdateEmailTemplate] = useState(false);
  const searchTemplateList = (callback?: any) => {
    const path = `${ContentTypes.emails.path}/${props.templateId}`;
    getTemplates(path).then((response) => {
      callback ? callback(response.data) : undefined;
    });
  };
  const getAccountdetails = async () => {
    const accountVar = await LocalStorage.getItem('account');
    const subDomain = JSON.parse(accountVar).subDomain;
    setAccountSubdomain(subDomain);
  }
  useEffect(() => {
    getAccountdetails();
  }, [])

  const hideEdit = props?.hideEdit!==undefined? props.hideEdit : true;

  const executeTemplateLogic = (updateTemplateSubject?: boolean) => {
    if (props.templateId) {
      searchTemplateList((data: any) => {
        setSelectedTemplate(data);
        if(props.isGeneralTextPresent){
        props.isGeneralTextPresent(data.attributes.bodyHtml.indexOf('{{general.text}}') != -1)
        }
        if(props.setIsFormListTagPresent){
          props.setIsFormListTagPresent(data.attributes.bodyHtml.search('/{%\s*for\s+form\s+in\s+formList\s*%}/') != -1)
        }
        if(props.setIsAppointmentLinkTagPresent){
          props.setIsAppointmentLinkTagPresent(data.attributes.bodyHtml.search('/{{\s*appointment\.link\s*}}/') != -1)
        }
        if(updateTemplateSubject){
          props?.onApplyChanges?.(data);
        }
        setTemplateLoading(false);
      });
    }
  }

  useEffect(() => {
    executeTemplateLogic();
  }, [props.templateId]);

  // account specific merge tags
  const commonData = useContext(CommonDataContext);
  const isSidecarContext = commonData?.sidecarContext?.isSidecar;
  const accountMergeTags = getAccountMergeTagData();
  const appLinkMergeTags = getAppLinkMergeTagData(commonData);



  const getMergeTags = (category: string, categories: ITemplateCategory[]) => {

    const mergeTagsByCategory = categories.find(
      (item) => item.name === category
    )?.mergeTags;
    return { ...mergeTagsByCategory, global: accountMergeTags , ...appLinkMergeTags};
  };

  const getPreviewHtml = (): string => {
    const engine = new Liquid();
    const mergeTags: Record<string, any> = {
      general: {
        text: props.message,
      },
    };
    mergeTags['patient'] = {
      firstName: '',
    };
    if (props.form) {
      const formLink = getFormURL(props.form?.id, '', accountUuid);
      mergeTags['form'] = {
        link: formLink,
      };
    }

    if (props.message) {
      mergeTags['general'] = {
        text: props.message,
      };
    }

    if (props.educationContent) {
      mergeTags['article'] = props.educationContent;
    }

    if (props.formList) {
      const formList: any = [];
      (props.formList || []).forEach((form: any) => {
        const formLink = getFormURL(form?.id, '', accountUuid);
        formList.push({
          link: formLink,
          name: form?.name,
        });
      });
      mergeTags['formList'] = formList;
    }
    const tpl = engine.parse(selectedTemplate?.attributes?.bodyHtml);

    if(props.mergeTags){
      const mergeTags:any = {...props.mergeTags};
      mergeTags.patientapp =  mergeTags.patientapp || {};
      mergeTags.patientapp.user = '*******';
      mergeTags.patientapp.password = '*******';
      return engine.renderSync(tpl, {...mergeTags, ...appLinkMergeTags});
    } else {
      const selectedTemplateCategory = selectedTemplate?.attributes?.category?.data?.attributes?.name;
      const finalMergeTag = { ...getMergeTags(selectedTemplateCategory, (props?.templateCategories || [])), ...mergeTags };
      return engine.renderSync(tpl, finalMergeTag);
    }
    // const finalMergeTag = { ...getMergeTags(selectedTemplate?.attributes?.name, (props?.templateCategories || [])), ...mergeTags };
    // const tpl = engine.parse(selectedTemplate?.attributes?.bodyHtml);
    // return engine.renderSync(tpl, finalMergeTag);
  };
  const onPressOfEditTemplate = () => {
    if (isSidecarContext) {
      redirectToNewTab(`/admin/contentManagement/emailTemplates/editor/${selectedTemplate.id}`);
    } else {
      window.open(`${accountSubdomain}#/admin/contentManagement/emailTemplates/editor/${selectedTemplate.id}`, '_blank', 'noopener,noreferrer')
    }
  }
  return (
    <>
      {' '}
      {selectedTemplate && (
        <VStack space={6} marginTop={5}>

          <VStack space={2}>
            <View>
              {isSidecarContext && !hideEdit ?
                <Stack direction={'row'} space={2} style={{ alignItems: 'center', justifyContent: 'flex-end', marginRight: 12 }}>
                  <Pressable onPress={() => {
                    setTemplateLoading(true);
                    executeTemplateLogic(true)
                  }}
                    disabled={templateLoading}
                  >
                    <Stack direction={'row'} space={2}>
                      {templateLoading ? <Spinner /> : <RefreshIconSvg />}
                      <Text style={{ color: Colors.FoldPixel.PRIMARY300 }}>{intl.formatMessage({ id: 'applyChanges' })}</Text>
                    </Stack>
                  </Pressable>
                  <View
                    style={{
                      width: 1,
                      height: 20,
                      backgroundColor: Colors.Custom.Gray200,
                      marginHorizontal: 20,
                    }}
                  ></View>
                  <Pressable onPress={onPressOfEditTemplate}>
                    <Stack direction={'row'} space={2}>
                      <EditPenSvg />
                      <Text style={{ color: Colors.FoldPixel.PRIMARY300 }}>{intl.formatMessage({ id: 'editInFold' })}</Text>
                      <RedirectIconSvg />
                    </Stack>
                  </Pressable>
                </Stack>
                : (!isSidecarContext && !hideEdit) ? <View
                  padding={5}

                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%'


                  }}
                >
                  <Pressable
                    style={{
                      backgroundColor: '#FFECD5',
                      borderRadius: 50,
                      padding: 8,
                      marginRight: 10
                    }}
                    onPress={() => {
                      setShouldUpdateEmailTemplate(true);
                    }}
                >
                  <AntDesign
                    name="edit"
                    color={'#8C210F'}
                    size={20}
                    {...testID(TestIdentifiers.editBtn)}
                  />
                  </Pressable>
                </View> : <></>
                }
              {parse(getPreviewHtml())}
            </View>
            <Spacer />
          </VStack>
          {shouldUpdateEmailTemplate && (
            <EmailTemplateCreatorEditorRightPanel
              id={selectedTemplate.id}
              isHtml={selectedTemplate?.attributes?.bodyJson?.isHTML}
              isTemplateCreate={false}
              isVisible={true}
              onCancel={() => {
                setShouldUpdateEmailTemplate(false);
                //getOrSetEmailTemplates();
              }}
              shouldHideDefault={true}
              shouldHideTitle={true}
              preSelectedCategory={'Campaign'}
              shouldDisableChangeCategory={true}
              customOnSubmitFn={(resp: any) => {
                if (resp?.data?.id) {
                  setShouldUpdateEmailTemplate(false);
                  searchTemplateList((data: any) => {
                    setSelectedTemplate(data);
                  });
                }
                executeTemplateLogic(true)
              }}
            />
          )}
        </VStack>
      )}{' '}
    </>
  );
};


export default DisplayTemplateById;
