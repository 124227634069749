import {useContext, useMemo, useState, useEffect} from 'react';
import {usePersonOmniViewContext} from '../../../../PersonOmniView/PersonOmniView.context';
import {useContactCareProgramContext} from '../ContactCareProgram.context';
import {useCareProgramStatus} from '../hooks/useCareProgramStatus';
import {CARE_PROGRAM_STEP_TYPE} from '../../../../common/MemebersView/constant';
import {useMainContentContext} from './MainContentView.context';
import useCareProgramStepStatus from '../hooks/useCareProgramStepStatus';
import {
  CareProgramActiveDrawer,
  StepAction,
  useCareProgramStepActions,
} from '../hooks/useCareProgramStepActions';
import {ContactCareProgramReducerAction} from '../reducer';
import {getContentHeaderActionList} from './ContentHeader.utils';
import {AttributeKey, ContactCareProgramComponentStatus, IContactCareProgram, IContactCareProgramStep} from '../../interface';
import {IStepsLog} from '../../../../../services/ContactCareProgram/interface';
import {useSelectedStepInCareProgram} from './components/hooks/useSelectedStepInCareProgram';
import { useCareProgramEditability } from '../hooks/useCareProgramEditability';
import { EventBus } from '../../../../../utils/EventBus';
import { CARE_PROGRAM_EVENTS } from '../../useCPEventhandler';
import { CareProgramStatusAction } from '../hooks/useCareProgramStatusAction';
import { useLazyQuery } from '@apollo/client';
import { GET_CONTACT_CARE_PROGRAM_DETAILS } from '../../../../../services/ContactCareProgram/ContactCareProgram';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../constants/Configs';
import { ToastType } from '../../../../../utils/commonViewUtils';
import { useCustomToast } from '../../../../Toast/ToastProvider';
import { CARE_PROGRAM_EVENT_CODES, OUTREACH_STEP_TYPES } from '../../../Contacts/Leads/LeadView/LeadTableView/Helper/CareProgramConst';
import { GET_FORM_LOG } from '../../../../../services/Forms/FormsQueries';
import { getMlovIdFromCode, getMlovListFromCategory } from '../../../../../utils/mlovUtils';
import { FORM_STATUS_CODE, MLOV_CATEGORY } from '../../../../../constants/MlovConst';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { checkIfPrevFormAcceptedOrDeclined, getAdditionalAttributeFromStep, getFormDetails } from '../ContactCareProgramView.utils';


export const useCareProgramContentHeaderAction = () => {
  const {state, dispatch, onUpdateCareProgramStatus} = useContactCareProgramContext();
  const {contactCareProgramDetails, userMap, formRef} = state;
  const {careProgramStatus} = useCareProgramStatus();
  const {formattedData: formattedContactData} = usePersonOmniViewContext();
  const {isAllowedToTakeActions} = useCareProgramEditability();
  const {selectedStep: activeStep} = useSelectedStepInCareProgram();
  const additionalAttribute = activeStep?.careProgramStepAdditionalAttributes;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [activeDrawer, setActiveDrawer] = useState<string | null>(null);
  const [isPrevFormInstanceExists, setIsPrevFormInstanceExists] = useState(false);
  const [isAllowToSubmitForm, setIsAllowToSubmitForm] = useState(true);
  const activeStepCode = activeStep?.careProgramStepType?.code;
  const isBillingStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.BILLING;
  const isAssessmentStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.ASSESSMENT;
  const isOutreachStep = activeStepCode && OUTREACH_STEP_TYPES.includes(activeStepCode);
  const isReferralStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.REFERRAL;
  const isCarePlanStep = activeStepCode === CARE_PROGRAM_STEP_TYPE.CARE_PLAN;
  const {mainContentContextState} = useMainContentContext();
  const toast = useCustomToast();
  const title = activeStep?.title;
  const {careProgramStepStatus, careProgramStepStatusList} = useCareProgramStepStatus();
  const mlovData = useContext(CommonDataContext);

  const formStatusMlovList = getMlovListFromCategory(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.FORM_STATUS
  );

  const submittedStatusId = getMlovIdFromCode(
    formStatusMlovList,
    FORM_STATUS_CODE.SUBMITTED
  );

  const isPreviousFormInstanceDisabled =
    activeStep?.careProgramStepAdditionalAttributes
      ? getAdditionalAttributeFromStep(
          activeStep?.careProgramStepAdditionalAttributes,
          AttributeKey.PREVIOUS_FORM_INSTANCE_DISABLED
        )
      : false;

  const selectedStepLog = useMemo(
    () =>
      state.contactCareProgramDetails?.stepsLog.find(
        (stepLog) => stepLog.careProgramStepId === state.selectedStepId
      ),
    [state.contactCareProgramDetails?.stepsLog, state.selectedStepId]
  );

  const isStepCompleted =
    selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.done;

  const isSkippedStep =
    selectedStepLog?.careProgramStepStatusId === careProgramStepStatus?.skip;
  const isCareProgramStopped = [
    careProgramStatus.autoClosed,
    careProgramStatus.completed,
    careProgramStatus.closed,
    careProgramStatus.paused,
  ].includes(state?.contactCareProgramDetails?.statusId as string);

  const {
    handleContentAction,
    isLoading,
    dropdownActionList,
    isModalOpen,
    closeModal,
    totalMinutes,
    setIsLoading
  } = useCareProgramStepActions({
    formRef,
    dispatch,
    selectedStepId: state.selectedStepId as string,
    selectedStepStatus: selectedStepLog?.careProgramStepStatusId as string,
    selectedStepCode: activeStepCode as string,
    extraParams: {
      contactId: formattedContactData?.contactUUID as string,
      patientId: formattedContactData?.patientId as string,
    },
    additionalAttributes: additionalAttribute || [],
    formResponseForAssessmentStep: mainContentContextState.formResponseForAssessmentStep,
  });


  const handleTimeLog = () => {
    const eventBus = EventBus.getEventBusInstance();
    eventBus.broadcastEvent(CARE_PROGRAM_EVENTS.OPEN_TIME_LOGGING_DRAWER, {
      contactCareProgramId: contactCareProgramDetails?.id,
    });
    closeModal();
  };

  const handleOnError = () => {
    toast({
      message: 'Failed to fetch contact care program details',
      toastType: ToastType.error,
    });
    dispatch?.({
      type: ContactCareProgramReducerAction.SET_STATUS,
      payload: ContactCareProgramComponentStatus.ERROR,
    });
  };

  const [getContactCareProgramDetails, {
    loading: isLoadingContactCareProgramDetails,
    refetch
  }] = useLazyQuery(
    GET_CONTACT_CARE_PROGRAM_DETAILS,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
      },

      onError: handleOnError,
    }
  );

  const [getPrevFormSubmittedByContact] = useLazyQuery(GET_FORM_LOG, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const handleHeaderStatusActions = async (action: StepAction) => {
    let newStepLogStatusId;
    if(!!contactCareProgramDetails && !!state.selectedStepId) {
       newStepLogStatusId = await handleContentAction(action, {
        careProgramDetails: contactCareProgramDetails,
        stepId: state.selectedStepId as string,
        isAllowToSubmitForm: isAllowToSubmitForm,
      });
    }
    if (newStepLogStatusId) {
      let careProgramDetail = contactCareProgramDetails;
      if (action === StepAction.EDIT) {
        const response = await getContactCareProgramDetails({
         variables: {
          id: contactCareProgramDetails?.id,
         },
         fetchPolicy: 'no-cache',
         });
        careProgramDetail = response?.data?.contactCarePrograms?.[0] as IContactCareProgram;
      }
      dispatch?.({
        type: ContactCareProgramReducerAction.UPDATE_STEP_STATUS,
        payload: {
          newStepLogStatusId,
          id: state.selectedStepId,
          skipMoveToNextStep: action === StepAction.EDIT || isCarePlanStep ||
            ([StepAction.SEND_CLAIM, StepAction.REVIEW].includes(action) &&
            isBillingStep),
          statusesToCalculateNextStep: action === StepAction.EDIT ? [] : [
            careProgramStepStatus.in_progress,
            careProgramStepStatus.to_do,
          ],
          contactCareProgramDetails: careProgramDetail,
        },
      });
    }
  };

  const handleHeaderDropdownActions = async (action: StepAction) => {
    setIsDropdownVisible(false);
    if (action === StepAction.DELETE_CONFIRMATION) {
      setActiveDrawer(CareProgramActiveDrawer.DELETE_CONFIRMATION);
      return;
    }
    await handleContentAction(action, {
      careProgramDetails: contactCareProgramDetails,
      stepId: state.selectedStepId as string,
    });
  };

  const closeActiveDrawer = () => {
    setActiveDrawer(null);
  };

  const isDropdownLoading = useMemo(
    () => dropdownActionList.some((action) => isLoading === action.action),
    [dropdownActionList, isLoading]
  );

  const isDropdownDisabled =
    isDropdownLoading || mainContentContextState.headerSavingIndicator;
  const isActionDisabled = Boolean(
    isLoading || mainContentContextState.headerSavingIndicator
  );

  const isCareProgramStatusCompleted = !isAllowedToTakeActions;
  const isStepSkippedOrRejected = [careProgramStepStatus.skip, careProgramStepStatus.rejected].includes(selectedStepLog?.careProgramStepStatusId as string);

  const hideLastEditedByInSubStep = useMemo(() => {
    return activeStep?.careProgramStepAdditionalAttributes?.find(
      attribute => attribute?.attributeKey === AttributeKey.HIDE_LAST_EDITED_BY_IN_SUB_STEP
    )?.attributeValue?.valueBoolean || false;
  }, [activeStep?.careProgramStepAdditionalAttributes]);

  const actionList = getContentHeaderActionList({
    activeStep: activeStep as IContactCareProgramStep,
    selectedStepLog: selectedStepLog as IStepsLog,
    selectedStepId: state.selectedStepId as string,
    careProgramStepStatus,
    activeStepCode: activeStepCode as string,
    careProgramStatusId: contactCareProgramDetails?.statusId as string,
    careProgramTypeCode: contactCareProgramDetails?.payerCareProgram?.careProgramType?.code as string,
    careProgramStatus: careProgramStatus,
    isPrevFormInstanceExists: isPrevFormInstanceExists,
    forceReadonly: state.forceReadonly,
  });

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownVisible(visible);
  };

  const updateCareProgramStatus = async () => {
    if (contactCareProgramDetails?.statusId === careProgramStatus.newStatus) {
      await onUpdateCareProgramStatus(CareProgramStatusAction.IN_PROGRESS);
    }
  }

  const checkIfFetchPreviousFormLog = () => {
    if (isStepCompleted || isSkippedStep || isPreviousFormInstanceDisabled || isCareProgramStopped) {
      return false;
    }

    const isPrevFormAcceptedOrDeclined = activeStep?.careProgramStepAdditionalAttributes
      ? checkIfPrevFormAcceptedOrDeclined(activeStep?.careProgramStepAdditionalAttributes)
      : false;

    return !isPrevFormAcceptedOrDeclined;

  };

  const checkPrevFormInstanceExists = async () => {
    if (!checkIfFetchPreviousFormLog()) {
      setIsPrevFormInstanceExists(false);
      return;
    }
    setIsLoading(StepAction.REVIEW);
    try {
      const {formId, formLogId} = getFormDetails(
        state.contactCareProgramDetails as IContactCareProgram,
        state.selectedStepId as string
      );
      if (!formId || !formLogId) {
        setIsLoading(undefined);
        setIsPrevFormInstanceExists(false);
        return;
      }
      const response = await getPrevFormSubmittedByContact({
        variables: {
          where: {
            contactId: {_eq: formattedContactData?.contactUUID},
            formId: {_eq: formId},
            id: {
              _nin: [formLogId],
            },
            statusId: {_eq: submittedStatusId},
          },
        },
      });
      const formLog = response?.data?.formLogs?.[0];
      const formResponse = formLog?.formResponse?.formResponse || {};
      const previousFormLogIsExisting = Boolean(
        formLog?.formResponse &&
        formResponse &&
        Object.keys(formResponse)?.length > 0
      );
      setIsPrevFormInstanceExists(previousFormLogIsExisting);
      setIsLoading(undefined);
    } catch {
      setIsLoading(undefined);
    }
  };

  const handleAcceptPreviousFormInstanceAction = () => {
    setIsPrevFormInstanceExists(false);
  }

  const handleAllowToSubmitForm = (data: {
    actionData: boolean;
    syncCode: string;
  }) => {
    if (isAssessmentStep) {
      setIsAllowToSubmitForm(data?.actionData);
    }
  };

  useEffect(() => {
    const eventBus = EventBus.getEventBusInstance();
    const handleReviewWithAppointment = (data: {appointmentId: string}) => {
      handleHeaderStatusActions(StepAction.REVIEW);
    };
    eventBus.addEventListener(
      CARE_PROGRAM_EVENT_CODES.REVIEW_APPOINTMENT,
      handleReviewWithAppointment
    );

    return () => {
      eventBus.removeEventListener(handleReviewWithAppointment);
    };
  }, [handleHeaderStatusActions]);

  return {
    actionList,
    isDropdownLoading,
    isDropdownDisabled,
    isActionDisabled,
    title,
    dropdownActionList: dropdownActionList,
    activeStep,
    mainContentContextState,
    isLoading,
    isDropdownVisible,
    userMap,
    isPrevFormInstanceExists,
    checkPrevFormInstanceExists,
    handleAcceptPreviousFormInstanceAction,
    handleHeaderStatusActions,
    handleHeaderDropdownActions,
    handleDropdownVisibleChange,
    handleAllowToSubmitForm,
    stepStatusCode: careProgramStepStatusList?.find(status => status.id === selectedStepLog?.careProgramStepStatusId)?.code,
    showSubtitleMessage: isAssessmentStep || isOutreachStep || isReferralStep,
    isModalOpen,
    closeModal,
    handleTimeLog,
    totalMinutes,
    updateCareProgramStatus,
    activeDrawer,
    closeActiveDrawer,
    hideLastEditedByInSubStep
  };
};
