import { BellOutlined, CaretRightOutlined, ExclamationCircleOutlined, MailOutlined } from '@ant-design/icons';
import { Drawer, Table, Timeline } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IconButton, Pressable, Spinner, Text, View } from 'native-base';
import { useContext, useEffect, useState } from 'react';
import { SmsIcon } from '../../../../../../assets/images/Avatar/SmsIcon';
import { BUTTON_TYPE, MLOV_CATEGORY } from '../../../../../../constants';
import { Colors } from '../../../../../../styles';
import { getFormattedDate } from '../../../../../../utils/DateUtils';
import { replacePlaceHolder } from '../../../../../../utils/smsUtils';
import { DisplayText } from '../../../../../common/DisplayText/DisplayText';
import { ModalActionTitle } from '../../../../../common/ModalActionTitle/ModalActionTitle';
import DisplayTemplateById from '../../../../../common/TemplateSearch/DisplayTemplateById';
import { TeamMemberProfile } from '../../../../Contacts/TeamMembers/TeamMembersTable/TeamMemberProfile';
import { getServiceLogsByWorkflowExecutionID } from '../../AddOrUpdateWorkflow/WorkflowApi'
import { ContentTypes } from '../../../../ContentManagement/ContentManagementConsts';
import { getTemplateById } from '../../../../ContentManagement/ContentManagementUtils';
import { FlowType, MailgunErrorReason } from '../../../../../../context/WorkflowContext';
import TagsColumnView from '../../../../Contacts/TeamMembers/TeamMembersTable/TagsColumnView';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import { reactStyles, styles } from '../../../workflowStyles';
import { BillList, CloseSquare, DocumentMedicine, LabelTag, UserChecked, CustomAutomationIcon, AUTOMATION_ICONS} from '../../../../../common/CircleIconView/CustomAutomationIcon';
import { useIntl } from 'react-intl';
import { getMlovListFromCategory } from '../../../../../../utils/mlovUtils';
import { CommonDataContext } from '../../../../../../context/CommonDataContext';
import { TagDisplayProps } from '../Interfaces';
import { NavigateButton } from './NavigateButton';
import { useLazyQuery } from '@apollo/client';
import { AppointmentQueries } from '../../../../../../services';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../../../../constants/Configs';
import { getAccountUUID } from '../../../../../../utils/commonUtils';
import AppointmentDetail from '../../../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/AppointmentDetail';

export const COMMUNICATION_LOG_TYPE = {
  SMS_SEND: 'SMS_SEND',
  EMAIL: 'EMAIL',
  PUSH_NOTIFICATION_SEND: 'PUSH_NOTIFICATION_SEND',
  EMAIL_SEND: 'EMAIL_SEND',
  CARE_PLAN_ASSIGN: 'CARE_PLAN_ASSIGN',
  BILLING_NODE: 'BILLING_NODE',
  AUTO_CLOSE_PROGRAM: 'AUTO_CLOSE_PROGRAM',
  CARE_PROGRAM_ASSIGN: 'CARE_PROGRAM_ASSIGN',
  TAGS_ADD_OR_DELETE: 'TAGS_ADD_OR_DELETE',
  APPOINTMENT_BOOKING: 'APPOINTMENT_BOOKING'


} as const;

export const WORKFLOW_COMMUNICATION_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

const ANALYTIC_TAG_EVENT_CODE = {
  failed: 'failed',
  succeeded: 'succeeded'
}
export const getElapsedTime = (createdOn:string) => {
  const oldDate = new Date(createdOn);
  const currentDate = new Date();
  const timeElapsed: number = (currentDate.getTime() - oldDate.getTime())/(60*60*1000);
  return timeElapsed
}

export const checkWorkflowCommunicationStatus = (item: any) => {
  const defaultErrorMessage = "Temporary Service Disruption: We're experiencing heavy demand, please retry in a moment";
  let errorMessage : string
  let serviceResponse: any;
  let isInProgress = false;
  try {
    const isServiceResponse = item?.metadata?.serviceResponse ? JSON.parse(item?.metadata?.serviceResponse) : null;
    const error = item?.metadata?.error ? JSON.parse(item?.metadata?.error) : null;
    if(error && error.message) {
      errorMessage = JSON.parse(
        item?.metadata?.error || '{}'
      )?.message;
    }else if(isServiceResponse && isServiceResponse?.message){
      errorMessage = isServiceResponse?.message
    } else {
      errorMessage = defaultErrorMessage;
    }
  } catch {
    errorMessage = defaultErrorMessage;
  }

  let isError =
    item?.logType == COMMUNICATION_LOG_TYPE.EMAIL_SEND
      ? (item?.emailAnalyticTag?.logs &&
          !item?.emailAnalyticTag?.logs?.length) ||
        item?.emailAnalyticTag?.logs?.some(
          (log: {eventCode: string; body: any}) => {
            if (log?.eventCode === ANALYTIC_TAG_EVENT_CODE.failed) {
              const reason =
                MailgunErrorReason[log?.body?.['event-data']?.reason];
              errorMessage =
                reason ||
                log.body?.['event-data']?.['delivery-status']?.message ||
                errorMessage ||
                '';
              return true;
            }
          }
        ) ||
        item?.status == WORKFLOW_COMMUNICATION_STATUS.ERROR
      : item?.status == WORKFLOW_COMMUNICATION_STATUS.ERROR;

  if (item?.status === WORKFLOW_COMMUNICATION_STATUS.IN_PROGRESS) {
    if (getElapsedTime(item?.createdOn) > 24) {
      isError = true;
    } else {
      isError = false;
      isInProgress = true;
    }
  }

  const metaData = item?.metadata?.requestData;
  try {
    serviceResponse = JSON.parse(item?.metadata?.serviceResponse || '{}');
  } catch {
    serviceResponse = defaultErrorMessage
  }
  errorMessage =
    isError && !errorMessage ? serviceResponse?.message || '' : errorMessage;

  const workflowCommunicationStatus = isError?
   WORKFLOW_COMMUNICATION_STATUS.ERROR:
    isInProgress?
    WORKFLOW_COMMUNICATION_STATUS.IN_PROGRESS:
    WORKFLOW_COMMUNICATION_STATUS.SUCCESS

  return {
    workflowCommunicationStatus: workflowCommunicationStatus,
    reason: errorMessage
  }
};

export const DisplaySmsOrNotificationBody = (props: {
  logType: string;
  templateId?: string;
  body?: string;
  mergeTag?: any;
}) => {
  const [body, setBody] = useState(props.body || '');

  const fetchBodyByTemplateId = async () => {
    const path =
      props.logType === COMMUNICATION_LOG_TYPE.SMS_SEND
        ? ContentTypes.textMessages.path
        : ContentTypes.pushNotifications.path;
    const response = await getTemplateById(path, props.templateId);
    setBody(response?.data?.attributes?.body || '-');
  };
  useEffect(() => {
    if (props.body && props.body != body) {
      setBody(body);
    } else if (!body && props.templateId) {
      fetchBodyByTemplateId();
    }
  }, [props.body, props.templateId]);
  return (
    body ?
      <View> {replacePlaceHolder(body, props.mergeTag || {})} </View> :
      <Text> Template not found </Text>
  )
};

export const ShowAppointmentInfo = (props: {appointmentId: string}) => {
  const [appointmentDetails, setAppointmentDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const accountUUID = getAccountUUID();
  const [getAppointmentData] = useLazyQuery(
    AppointmentQueries.GET_APPOINTMENT_BY_ID,
    {
      variables: {appointmentId: props.appointmentId},
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        headers: {
          // 'x-hasura-admin-secret': props.params.accessToken,
          'account-uuid': accountUUID,
        },
      },
      onCompleted: (data) => {
        setAppointmentDetails(data?.appointments?.[0]);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    }
  );

  const fetchAppointmentDetails = async () => {
    setLoading(true);
    getAppointmentData();
  };

  return (
    <View>
      {loading ? (
        <Spinner size="sm" />
      ) : (
        <Pressable onPress={fetchAppointmentDetails}>
          <Text
            style={{
              color: Colors.Custom.Blue700,
              textDecorationLine: 'underline',
            }}
          >
            View Appointment Details
          </Text>
        </Pressable>
      )}

      {appointmentDetails && (
        <View style={{marginTop: 8}}>
          <AppointmentDetail
            event={{detail: appointmentDetails}}
            isLoading={false}
            isVisible={true}
            onStatusChange={() => {}}
            onClose={() => {
              setAppointmentDetails(undefined);
            }}
          />
        </View>
      )}
    </View>
  );
};

const TableColumn = ({ value, align}: any)=> {
  return (
        <View >
            <Text size={'smRegular'} style={{
              color: Colors.FoldPixel.GRAY250,
              lineHeight: 24,
              textAlign: align || 'left'

            }}>{value}</Text>
        </View>
    )
}

export const getColumns = (
  // actionFn: (actionCode: string, record: any) => {}
): ColumnsType<any> => {
  return [
    {
      title: (
        <DisplayText
          size={'xsMedium'}
          extraStyles={{color: Colors.Custom.Gray500, marginLeft: 8}}
          textLocalId="name"
        />
      ),
      dataIndex: 'userName',
      key: 'userName',
      fixed: 'left',
      width: '20%',
      render: (userName: string) => {
        return <View>--</View>;
      },
    },

  ];
};

const getUniqueServiceLogs = (logs: any[]): any[] => {
  const uniqueLogs: any[] = [];
  const logIds = new Set();
  logs.forEach((log) => {
    if (!logIds.has(log.id)) {
      logIds.add(log.id);
      uniqueLogs.push(log);
    }
  });
  return uniqueLogs;
}

export const ShowWorkflowExecutionCommunication = (props: { isShow: boolean, onClose: any, workflowExecutionId: string, isLoading: boolean, setIsLoading: any, itemId?: string }) => {
  const [serviceLogs, setServiceLogs] = useState<any[]>([]);
  useEffect(() => {
    loadServiceLogs();
  }, [props.workflowExecutionId, props?.itemId])

  const loadServiceLogs = async () => {
    props.setIsLoading(true);
    try {
      const response = await getServiceLogsByWorkflowExecutionID(props.workflowExecutionId, props.itemId);
      if (response.serviceLogs) {
        const uniqueLogs = getUniqueServiceLogs(response.serviceLogs)
        setServiceLogs(uniqueLogs)
      }
      props.setIsLoading(false);
    } catch (error) {
      props.setIsLoading(false);
    }
  }

  return (<>{props.isLoading ? <></> : <ActionModal isShow={props.isShow} onClose={props.onClose} userDetail={{}} data={serviceLogs}/>}</>);
}

export const ActionModal = ({ isShow, onClose, data, userDetail }: any)=> {
  const commonData = useContext(CommonDataContext)
  const careProgramTypes = getMlovListFromCategory(commonData.CARE_STUDIO_MLOV, MLOV_CATEGORY.CARE_PROGRAM_TYPES)
  const intl = useIntl()

  const getCareProgramType = (careProgramTypeId: string) => {
    const program = careProgramTypes?.find((program: any) => program?.id === careProgramTypeId);
    if (program) {
      return program.value;
    }
    return null;
  };

  const getTimelineIcons =(logType: string, color : string)=> {
    if(logType === 'SMS_SEND'){
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
      >
        <SmsIcon color={color}/>
        </View>
      );
    } else if(logType === 'EMAIL_SEND' || logType === 'EMAIL'){
        return (
          <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
      >
        <MailOutlined style={{color: color}}/>
        </View>
        )
    } else if (logType === COMMUNICATION_LOG_TYPE.CARE_PLAN_ASSIGN) {
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
        >
          <DocumentMedicine iconColor={color} size='20' />
        </View>
      )
    } else if (logType === COMMUNICATION_LOG_TYPE.APPOINTMENT_BOOKING) {
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
        >
          <CustomAutomationIcon name={AUTOMATION_ICONS.APPOINTMENT_LINK} iconColor={color} size='20' />
        </View>
      )
    } else if (logType === COMMUNICATION_LOG_TYPE.BILLING_NODE) {
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
        >
          <BillList iconColor={color} size='20' />
        </View>
      )
    } else if (logType === COMMUNICATION_LOG_TYPE.AUTO_CLOSE_PROGRAM) {
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
        >
          <CloseSquare iconColor={color} size='20' />
        </View>
      )
    }
    else if (logType === COMMUNICATION_LOG_TYPE.TAGS_ADD_OR_DELETE) {
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
        >
          <LabelTag iconColor={color} size='20' />
        </View>
      )
    } else if (logType == COMMUNICATION_LOG_TYPE.CARE_PROGRAM_ASSIGN){
      return (
        <View
          borderWidth={1}
          borderColor={Colors.Custom.Gray500}
          borderRadius={'50%'}
          padding={1}
        >
          <UserChecked iconColor={color} size='20' />
        </View>
      )
    } else {
      return (
        <View
        borderWidth={1}
        borderColor={Colors.Custom.Gray500}
        padding={1}
        borderRadius={'50%'}>
      <BellOutlined style={{color: color}}/>
      </View>
      )
    }
  }
  return (
    <>
      <Drawer
        open={isShow}
        onClose={() => {
          onClose();
        }}
        width={'50vw'}
        title={
          <ModalActionTitle
            // titleNode={<View><TeamMemberProfile fullName={userDetail.name} phone={userDetail.phoneNumber}/></View>}
            titleColor={''}
            buttonList={[
              {
                show: true,
                id: 1,
                btnText: 'Close',
                textColor: Colors.Custom.mainSecondaryBrown,
                variant: BUTTON_TYPE.SECONDARY,
                isTransBtn: false,
                onClick: () => {
                  onClose();
                },
              },
            ]}
          />
        }
      >
        <View padding={0} paddingTop={0} flex={1} flexDirection={'column'}>
          <View>
            <Text
              size={'mdMedium'}
              style={styles.text2}
            >
              Automation
            </Text>
          </View>
          {data.length > 0 && (
            <Timeline style={reactStyles.marginLeft3em} className="workflow-timeline">
              {data
                ?.filter((item: any) => {
                  return [
                    COMMUNICATION_LOG_TYPE.EMAIL,
                    COMMUNICATION_LOG_TYPE.SMS_SEND,
                    COMMUNICATION_LOG_TYPE.PUSH_NOTIFICATION_SEND,
                    COMMUNICATION_LOG_TYPE.EMAIL_SEND,
                    COMMUNICATION_LOG_TYPE.CARE_PLAN_ASSIGN,
                    COMMUNICATION_LOG_TYPE.BILLING_NODE,
                    COMMUNICATION_LOG_TYPE.AUTO_CLOSE_PROGRAM,
                    COMMUNICATION_LOG_TYPE.TAGS_ADD_OR_DELETE,
                    COMMUNICATION_LOG_TYPE.CARE_PROGRAM_ASSIGN,
                    COMMUNICATION_LOG_TYPE.APPOINTMENT_BOOKING,
                  ].includes(item?.logType);
                })
                .map((item: any) => {
                    const metaData = item?.metadata?.requestData;
                    const status = checkWorkflowCommunicationStatus(item)
                    let serviceResponse;
                    const iconColor = status?.workflowCommunicationStatus===WORKFLOW_COMMUNICATION_STATUS.ERROR
                    ? Colors.Custom.ErrorColor
                    : Colors.Custom.SuccessColor;
                    const errorMessage = status?.reason
                    try{
                      serviceResponse = JSON.parse(item?.metadata?.serviceResponse || '{}');
                    } catch {
                      serviceResponse = errorMessage
                    }

                    const toEmail = serviceResponse?.recipientList?.[0]?.to || metaData?.to?.[0] || metaData?.email;
                    const carePlanData = item?.metadata?.carePlanData;
                    const appointmentBookData = item?.metadata?.appointmentBookData;
                    const careProgramData = item?.metadata?.careProgramData;
                    const autoCloseProgramId = careProgramData?.contactCareProgramId
                    const careProgramId  = careProgramData?.careProgramId
                    const careProgramValue = getCareProgramType(careProgramData?.careProgramTypeId)
                    const tagData = item?.metadata?.tagData;
                    return (
                    <>
                      <Timeline.Item
                        dot={
                          <View marginBottom={4}>
                            {getFormattedDate(item?.createdOn, 'MMM YYYY')}
                          </View>
                        }
                        color={Colors.FoldPixel.GRAY300}
                        style={reactStyles.timelineItemStyle}
                      >
                        <span style={reactStyles.colorTransparent}>T</span>
                      </Timeline.Item>
                      <Timeline.Item
                        dot={getTimelineIcons(item.logType, iconColor)}
                        color={Colors.Custom.Gray400}
                      >
                        <View
                          flex={1}
                          flexDirection={'column'}
                          style={{padding: '2em', paddingTop: '0 !important'}}
                        >
                          <span
                            style={reactStyles.text1}
                          >
                            {getFormattedDate(item?.createdOn, 'llll')}
                          </span>
                          <span>{metaData?.title || ''}</span>
                          <span style={reactStyles.colorError}>
                            {status?.workflowCommunicationStatus===WORKFLOW_COMMUNICATION_STATUS.ERROR ? errorMessage || '' : ''}
                          </span>
                          {[
                            COMMUNICATION_LOG_TYPE.PUSH_NOTIFICATION_SEND,
                            COMMUNICATION_LOG_TYPE.SMS_SEND,
                          ].includes(item?.logType) && (
                            <span>
                              <DisplaySmsOrNotificationBody
                                logType={item?.logType}
                                templateId={
                                  metaData?.smsTemplateId ||
                                  metaData?.templateId ||
                                  metaData?.template
                                }
                                body={metaData?.smsBody || metaData?.body}
                                mergeTag={
                                  metaData?.mergeTag ||
                                  metaData?.mergeTags ||
                                  metaData?.data
                                }
                              />{' '}
                            </span>
                          )}
                          {[
                            COMMUNICATION_LOG_TYPE.EMAIL_SEND,
                            COMMUNICATION_LOG_TYPE.EMAIL,
                          ].includes(item?.logType) && (
                            <>
                              <span>{toEmail || ''}</span>
                              <span>
                                {replacePlaceHolder(
                                  metaData?.subject,
                                  metaData?.mergeTag ||
                                    metaData?.mergeTags ||
                                    metaData?.data
                                )}
                              </span>
                              {metaData?.template && (
                                <DisplayTemplateById
                                  key={metaData?.template}
                                  hideEdit
                                  templateId={metaData?.template}
                                  templateCategories={[]}
                                  mergeTags={
                                    metaData?.mergeTag ||
                                    metaData?.mergeTags ||
                                    metaData?.data
                                  }
                                />
                              )}
                            </>
                          )}
                          {[COMMUNICATION_LOG_TYPE.CARE_PLAN_ASSIGN].includes(item?.logType) && carePlanData?.carePlanId &&
                            <>
                              {intl.formatMessage({ id: 'workflowCarePlanAssigned' })}
                              <NavigateButton
                                navigateLink={`/members/patient/${carePlanData?.contactId}/carePlan`}
                                displayText={intl.formatMessage({ id: 'viewCarePlan' })}
                                data={carePlanData?.contactId}
                                suffixIcon={<CaretRightOutlined style={reactStyles.caretRightOutlined} />}
                                newWindow={true}
                              />
                            </>
                          }
                          {[COMMUNICATION_LOG_TYPE.BILLING_NODE].includes(item?.logType) && (status?.workflowCommunicationStatus != WORKFLOW_COMMUNICATION_STATUS.ERROR) &&
                            <>
                              {intl.formatMessage({ id: 'workflowBillingSuccess' })}
                            </>
                          }
                          {[COMMUNICATION_LOG_TYPE.APPOINTMENT_BOOKING].includes(item?.logType) && (status?.workflowCommunicationStatus != WORKFLOW_COMMUNICATION_STATUS.ERROR) &&
                            <>
                              {intl.formatMessage({ id: 'workflowAppointmentBookSuccess' })}
                              { appointmentBookData?.createBookedAppointment?.id ? <ShowAppointmentInfo appointmentId={appointmentBookData?.createBookedAppointment?.id}></ShowAppointmentInfo> : <></>}
                            </>
                          }
                          {[COMMUNICATION_LOG_TYPE.AUTO_CLOSE_PROGRAM].includes(item?.logType) && (status?.workflowCommunicationStatus != WORKFLOW_COMMUNICATION_STATUS.ERROR) && autoCloseProgramId &&
                            <>
                              {intl.formatMessage({ id: 'autoCloseProgramSuccess' })}
                              <NavigateButton
                                navigateLink={`/members/patient/${careProgramData?.contactId}/careManagement/all/${autoCloseProgramId}`}
                                displayText={intl.formatMessage({ id: 'viewCareProgram' })}
                                data={careProgramData?.contactId}
                                suffixIcon={<CaretRightOutlined style={reactStyles.caretRightOutlined} />}
                                newWindow={true}
                              />
                            </>
                          }
                          {[COMMUNICATION_LOG_TYPE.TAGS_ADD_OR_DELETE].includes(item?.logType) && (status?.workflowCommunicationStatus != WORKFLOW_COMMUNICATION_STATUS.ERROR) && tagData &&
                            <TagDisplay tagData={tagData} />
                          }
                          {[COMMUNICATION_LOG_TYPE.CARE_PROGRAM_ASSIGN].includes(item?.logType) && (status?.workflowCommunicationStatus != WORKFLOW_COMMUNICATION_STATUS.ERROR) && careProgramData?.careProgramId &&
                            <>
                              {intl.formatMessage({ id: 'careProgramAssignSuccess' })}
                              {careProgramValue}
                              <NavigateButton
                                navigateLink={`/members/patient/${careProgramData?.contactId}/careManagement/all/${careProgramId}`}
                                displayText={intl.formatMessage({ id: 'viewCareProgram' })}
                                data={careProgramData?.contactId}
                                suffixIcon={<CaretRightOutlined style={reactStyles.caretRightOutlined} />}
                                newWindow={true}
                              />
                            </>
                          }
                        </View>
                      </Timeline.Item>
                    </>
                  );
                })}
            </Timeline>
          )}
          {data.length == 0 && (
            <Text>No Communication Occured with this patient</Text>
          )}
        </View>
      </Drawer>
    </>
  );
}



const TagDisplay = ({ tagData }: TagDisplayProps) => {
  const { deletedTags = [], newlyAddedTags = [] } = tagData;

  const addedTagsSentence = newlyAddedTags?.length
    ? `Newly added tags: ${newlyAddedTags.map((tag) => tag?.title).join(', ')}.` : ``

  const deletedTagsSentence = deletedTags?.length
    ? `Deleted tags: ${deletedTags.map((tag) => tag?.title).join(', ')}.` : ``

  return (
    <p>
      {addedTagsSentence} {deletedTagsSentence}
    </p>
  );
};


export  const WorflowStatusTable = ({ column, allTask, contacts, userWorksFlows,activeTab,activeTabCount, currentPage, onPageChange, pageSize }: any)=> {
  const [ isShow, setIsShow] = useState(false);
  const [ currentData, setCurrentData ] = useState<any[]>([]);
  const [ userDetail, setUserDetail] = useState({})
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED) && false;
  const columns = [
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Name'
        />
      ),
      key: 'resourceLevelLog',
      width: '30%',
      render: (data: any) => {
        const flowType = data?.workflow?.flowType;
        const resourceLevelLog = data?.resourceLevelLog;
        let resource = resourceLevelLog.filter((item: any) => {
          if (flowType === FlowType.task && item.resourceType === 'TASK') {
            return true;
          } else if (
            flowType !== FlowType.task &&
            item.resourceType === 'CONTACT'
          ) {
            return true;
          }
        });
        if (!resource?.length) {
          resource = resourceLevelLog.filter((item: any) => {
            return (
              item.resourceType === 'TASK' || item.resourceType === 'CONTACT'
            );
          });
        }
        const id = resource?.[0]?.resourceId;
        const resourceIds = resource?.map((item: any) => item?.resourceId);
        const getAssigneeName = (allTask: any, resourceIds: string[]) => {
          for (const id of resourceIds) {
            if (allTask?.[id]?.assigneeUser?.name) {
              return allTask?.[id]?.assigneeUser?.name;
            }
          }
          return '';
        };
        const assigneeName = getAssigneeName(allTask, resourceIds);
        const name = contacts?.[id]?.name || assigneeName;
        const isUserDeleted = contacts?.[id]?.is_deleted
        return <TeamMemberProfile fullName={name} phone={contacts?.[id]?.phoneNumber} isUserDeleted={isUserDeleted}/>;
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Date and Time'
        />
      ),
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (createdOn: string) => {


        return <TableColumn value={getFormattedDate(createdOn, 'MM/DD/YYYY, h:mm a')}/>
      },
      width: '25%',
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Email'
        />
      ),
      dataIndex: 'emailLog',
      key: 'emailLog',
      width: '9%',
      render: (emailLog: any) => {


        return <TableColumn value={emailLog?.aggregate?.count}/>
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='SMS'
        />
      ),
      dataIndex: 'smsLog',
      key: 'smsLog',
      width: '9%',
      render: (smsLog: any) => {


        return <TableColumn value={smsLog?.aggregate?.count} />
      },
    },
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Notification'
        />
      ),
      dataIndex: 'notificationLog',
      key: 'notificationLog',
      width: '15%',
      render: (notificationLog: any) => {


        return <TableColumn value={notificationLog?.aggregate?.count}/>
      },
    },
    ...(isMsoEnabled && false ? [{
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Locations'
        />
      ),
      dataIndex: 'locationNames',
      key: 'locationNames',
      width: '15%',
      render: (locationNames: string[], record: any) => {
        return <TableColumn value={
          <TagsColumnView
            color={Colors.FoldPixel.GRAY300}
            bgColor={Colors.FoldPixel.GRAY50}
            labelData={locationNames || []}
          />
        } />
      },
    }] : []),
    {
      title: (
        <DisplayText
          size={'thMedium'}
          extraStyles={{color: Colors.FoldPixel.GRAY300}}
          textLocalId='Actions'
        />
      ),
      dataIndex: 'serviceLog',
      key: 'serviceLog',
      width: '12%',
        render: (serviceLog: any, rowData: any) => {
        const resource = rowData?.resourceLevelLog.filter((item: any)=> item.resourceType === 'CONTACT' || item.resourceType === 'TASK');
        const id = resource?.[0]?.resourceId;
        const user =  contacts?.[id] || allTask?.[id]?.assigneeUser;
        const uniqueLogs = getUniqueServiceLogs(serviceLog)

          return (

            <View flex={1} justifyContent={'center'} flexDirection={'row'}>
            <IconButton
              icon={<ExclamationCircleOutlined />}
              onPress={() => {
                setIsShow(true)
                setCurrentData(uniqueLogs)
                setUserDetail(user)
              }}
              />
          </View>
          );
        },

    },
  ];

  const columnForFailed = [
    {
      title: 'Members Name',
      dataIndex: 'resourceLevelLog',
      key: 'resourceLevelLog',
      // width: '20%',
      render: (resourceLevelLog: any) => {
        const resource = resourceLevelLog.filter((item: any)=> item.resourceType === 'CONTACT');
        const id = resource?.[0]?.resourceId;

        return <TeamMemberProfile fullName={contacts?.[id]?.name} phone={contacts?.[id]?.phoneNumber}/>;
      },
    },
    {
      title: 'Failing Reason',
      dataIndex: 'serviceLog',
      key: 'serviceLog',
      render: (serviceLog: any) => {
        let error ;
        {serviceLog.map((item: any)=>{
          error = item?.metadata?.error;
        })}

        return <TableColumn value={error || 'Error while fetching data'}/>
      },
    },
  ];

  if(isShow && currentData?.length >0){
    return <ActionModal isShow={isShow} onClose={()=> setIsShow(false)} data={currentData} userDetail={userDetail}/>
  }

  userWorksFlows = userWorksFlows?.[0] ? userWorksFlows : []

    return (
        <View width={'100%'} >

           <Table
            dataSource={userWorksFlows}
            className='workflow-table'
            columns={ column?.[0] ? column : activeTab === 'failed' ? columnForFailed: columns}
            scroll={{x:'100%', y:500}}
            pagination={{

            pageSize: pageSize,
            current: currentPage,
            total: activeTabCount,
            pageSizeOptions:['5', '10', '15', '20'],
            onChange: (page, pageSize) => {

            onPageChange(page, pageSize)
          },
        }}/>
        </View>
    )
}


