import {gql} from '@apollo/client';

export const GET_FEEDS = gql`
  query GET_ALL_FEEDS(
    $feedDataWhereCondition: feed_data_bool_exp!
    $limit: Int
    $offset: Int
  ) {
    feed_data(
      limit: $limit
      offset: $offset
      order_by: {time_stamp: desc}
      where: $feedDataWhereCondition
    ) {
      id
      tenant_id
      locationId
      patient_id
      group_id
      schema_type
      source
      sync_status
      time_stamp
      display_type
      data
      meta_data
      search_tags
      description
    }
  }
`;


export const GetAllContactFeeds = gql`
  query GET_ALL_FEEDS(
      $limit: Int!
      $offset: Int!
      $feedDataWhereCondition: feed_data_bool_exp!
    ) {
    feed_data(
      limit: $limit
      offset: $offset
      order_by: {time_stamp: desc}
      where: $feedDataWhereCondition
    ) {
      id
      tenant_id
      resourceType
      categoryId
      patient_id
      group_id
      schema_type
      source
      time_stamp
      display_type
      data
      meta_data
      search_tags
      description
    }
  }
`;


export const UPDATE_FEED_STATUS = gql`
  mutation updateFeedStatus($ids: [uuid!]!, $syncStatus: String) {
    updateFeedsData(
      where: {id: {_in: $ids}}
      _set: {sync_status: $syncStatus}
    ) {
      affected_rows
    }
  }
`;


export default {
  GET_FEEDS,
  GetAllContactFeeds,
  UPDATE_FEED_STATUS
};
