import { useApolloClient, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import LeadQueries from "../../../services/Lead/LeadQueries";
import { IContactProfileStatus } from "./contactProfileInterfaces";
import { CARESTUDIO_PROXY_TO_CRM_CONTEXT } from "../../../constants/Configs";

const useContactProfileStatus = (args: {
  profileId?: string;
  profileIds?: string[];
  headers?: {[index: string]: any};
  isMultiTenancyEnabled?: boolean;
}) => {
  const [profilesData, setProfilesData] = useState<IContactProfileStatus[]>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [profileLoading, setProfileLoading] = useState<boolean>(false);

  const [getContactProfileStatus] = useLazyQuery(
    LeadQueries.GetContactProfileById,
    {
      fetchPolicy: 'no-cache',
      context: {
        service: CARESTUDIO_PROXY_TO_CRM_CONTEXT,
        headers: args.headers,
      },
    },
  );

  const fetchContactProfileStatus = async (props: {profileId?: string, profileIds?: string[]}) => {
    setProfileLoading(true);
    const {profileId, profileIds} = props;
    const queryParams = {
      ...(profileId && {
        id: {
          _eq: profileId,
        },
      }),
      ...(!!profileIds && {
        id: {
          _in: profileIds,
        },
      })
    }

    try {
      const response = await getContactProfileStatus({
        variables: {params: queryParams},
      })
      const profileData = response?.data?.contactProfiles;
      setProfilesData(profileData);
      setIsActive(profileData?.[0]?.isActive);
      return profileData;
    } catch (error) {
      return null;
    } finally {
      setProfileLoading(false);
    }
  };

  const fetchContactActiveProfile = async (props: { contactId: string }) => {
    setProfileLoading(true);
    const { contactId } = props;
    const queryParams = {
      ...(contactId && {
        id: {
          _eq: contactId,
        },
      }),
      isActive: {
        _eq: true,
      },
    }

    try {
      const response = await getContactProfileStatus({
        variables: {params: queryParams},
      })
      const profileData = response?.data?.contactProfiles;
      setProfilesData(profileData);
      setIsActive(profileData?.[0]?.isActive);
      return profileData;
    } catch (error) {
      return null;
    } finally {
      setProfileLoading(false);
    }
  };

  useEffect(() => {
    if (args?.profileId || args?.profileIds) {
      fetchContactProfileStatus({profileId: args.profileId, profileIds: args.profileIds});
    }
  }, [args?.profileId, args?.profileIds?.length]);

  return {
    profilesData,
    isActive: args?.isMultiTenancyEnabled ? isActive : true,
    profileLoading,
    fetchContactProfileStatus,
    fetchContactActiveProfile,
  };
};

export default useContactProfileStatus;
