export const ACTION_CODES = {
  ACCEPT: 'ACCEPT',
  DECLINE: 'DECLINE',
  DELETE: 'DELETE',
  SHOW_MATCH_DATA_VIEW: 'SHOW_MATCH_DATA',
  HIDE_MATCH_DATA_VIEW: 'HIDE_MATCH_DATA_VIEW'
};

export const LOADING_TYPES = {
  ACCEPT: 'accepted',
  DECLINE: 'declined',
};

export const SYNC_STATUS_TYPES = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
};
