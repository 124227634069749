import React, { useEffect, useMemo, useState } from 'react'
import { VStack, View, useMediaQuery } from 'native-base';
import { useIntl } from 'react-intl';
import ReactDevicePreview from 'react-device-preview';
import './WebPreview.scss';
import Feather from 'react-native-vector-icons/Feather';
import { Colors } from '../../../../../styles';
import { Dimensions, Pressable } from 'react-native';

export const PREVIEW_TYPE = {
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  PHONE: 'PHONE',
};

const WebPreview = (props: {
  children: JSX.Element,
  onScreenTypeChange?: (type: string) => void}
) => {
  const intl = useIntl();
  const [screenType, setScreenType] = useState(PREVIEW_TYPE.DESKTOP);
  const screenHeight = Dimensions.get('window').height;
  const [isSmallScreen] = useMediaQuery({maxWidth: 1512});
  const tabs = useMemo(() => [
    {
      label: intl.formatMessage({id: 'desktop'}),
      value: PREVIEW_TYPE.DESKTOP,
      icon: <Feather name="monitor" size={16} color={screenType === PREVIEW_TYPE.DESKTOP ? 'white' : Colors.FoldPixel.GRAY300} />,
    },
    {
      label: intl.formatMessage({id: 'tablet'}),
      value: PREVIEW_TYPE.TABLET,
      icon: <Feather name="tablet" size={16} color={screenType === PREVIEW_TYPE.TABLET ? 'white' : Colors.FoldPixel.GRAY300} />,
    },
    {
      label: intl.formatMessage({id: 'phone'}),
      value: PREVIEW_TYPE.PHONE,
      icon: <Feather name="smartphone" size={16} color={screenType === PREVIEW_TYPE.PHONE ? 'white' : Colors.FoldPixel.GRAY300} />,
    },
  ], [screenType]);

  const getDeviceName = () => {
    switch (screenType) {
      case PREVIEW_TYPE.DESKTOP: return 'macbookpro';
      case PREVIEW_TYPE.TABLET: return 'ipadmini';
      case PREVIEW_TYPE.PHONE: return 'iphonex';
    }
    return 'macbookpro';
  }
  const getDeviceScale= () => {
    switch (screenType) {
      case PREVIEW_TYPE.DESKTOP: return isSmallScreen ? '0.65' : '0.9';
      case PREVIEW_TYPE.TABLET: return isSmallScreen ? '0.5' : '0.7';
      case PREVIEW_TYPE.PHONE: return isSmallScreen ? '0.55' : '0.8';
    }
    return '0.8';
  }
  const getLeftMargin= () => {
    switch (screenType) {
      case PREVIEW_TYPE.DESKTOP: return isSmallScreen ? '32%' : '5%';
      case PREVIEW_TYPE.TABLET: return isSmallScreen ? '32%' : '12%';
      case PREVIEW_TYPE.PHONE: return isSmallScreen ? '24%' : '5%';
    }
    return '0.8';
  }

  useEffect(() => {
    props.onScreenTypeChange?.(screenType);
  }, []);

  return (
    <VStack
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      paddingY={2}
    >
      <View marginLeft={getLeftMargin()} marginTop={'2%'} maxHeight={screenHeight - 238}>
        <ReactDevicePreview
          device={getDeviceName()}
          scale={getDeviceScale()}
          >
          <View marginTop={screenType === PREVIEW_TYPE.PHONE ? 6 : 0} minHeight={screenHeight - 400}>
            <div className={screenType === PREVIEW_TYPE.PHONE ? 'fh-phone-preview' : ''}>
                {props.children}
            </div>
          </View>
        </ReactDevicePreview>
      </View>
      <div className='flex flex-row items-center gap-4'>
        {tabs.map((tab: any) => (
          <Pressable key={tab.value} onPress={() => {
            setScreenType(tab.value);
            props.onScreenTypeChange?.(tab.value);
          }}>
            <div style={{backgroundColor: screenType === tab.value ? Colors.Custom.mainPrimaryPurple : '#fff', padding: 10, borderRadius: 18, boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)', width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {tab.icon}
            </div>
          </Pressable>
        ))}
      </div>
    </VStack>
  )
}

export default WebPreview
