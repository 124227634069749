import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { FormElementInstance } from '../FormComponents/FormComponents';
import { Colors } from '../../../../../styles';
import Feather from 'react-native-vector-icons/Feather';
import { CommonDataContext } from '../../../../../context/CommonDataContext';
import { FormContext, IFormCommonData } from '../../FHFormio/CustomComponents/CustomWrapper/CustomWrapper';
import classNames from 'classnames';

export const FormElementWrapper: React.FC<{
  children: React.ReactNode;
  element: FormElementInstance;
  hideBottomPadding?: boolean;
}> = ({ children, element, hideBottomPadding }) => {
  const contextData = useContext(CommonDataContext) as IFormCommonData;
  const isPreviewMode = contextData?.isPrintForm || contextData?.isPreviewMode;
  const isRecentReportedView = contextData?.formContext === FormContext.recentReportedView;
  const [hasComponentData, setHasComponentData] = useState(true);
  // Store the original function reference
  const originalUpdateLoadingStatusRef = useRef(contextData?.updateLoadingStatus);
  const hideBtmPadding = hideBottomPadding || !hasComponentData || isRecentReportedView;

  const handleDelete = useCallback(() => {
    contextData?.onDeleteClick?.(element);
  }, [contextData?.onDeleteClick, element]);

  // Create a stable wrapper function reference
  const wrappedUpdateLoadingStatus = useCallback((componentKey: string, loading: boolean, hasData: boolean) => {
    if (componentKey === element.key) {
      setHasComponentData(hasData);
    }
    // Call the original function if it exists
    originalUpdateLoadingStatusRef.current?.(componentKey, loading, hasData);
  }, [element.key]);

  useEffect(() => {
    if (!contextData?.updateLoadingStatus) return;

    // Store the current original function
    originalUpdateLoadingStatusRef.current = contextData.updateLoadingStatus;
    // Set our wrapped version
    contextData.updateLoadingStatus = wrappedUpdateLoadingStatus;

    return () => {
      // Only restore if our wrapper is still the current handler
      if (contextData.updateLoadingStatus === wrappedUpdateLoadingStatus) {
        contextData.updateLoadingStatus = originalUpdateLoadingStatusRef.current;
      }
    };
  }, [wrappedUpdateLoadingStatus]);

  return (
    <div
      className={classNames(
        hideBtmPadding ? "" : "padding-bottom-16",
        isPreviewMode && "preview-mode"
      )}
      data-testid={`form-element-wrapper-${element.key}`}
    >
      {contextData?.showDeleteButton && (
        <div className="element-actions">
          <button
            onClick={handleDelete}
            className="element-action-button"
            data-testid={`delete-button-${element.key}`}
          >
            <Feather
              name="trash-2"
              color={Colors.Custom.ErrorColor}
              size={16}
            />
          </button>
        </div>
      )}
      {children}
    </div>
  );
};

export default FormElementWrapper;

