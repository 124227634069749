import {Pressable, Text, View} from "react-native"
import {Divider, Image} from 'native-base';
import {Colors} from "../../../styles"
import RefreshSvg from "../Svg/RefreshSvg"
import CriteriaMatchSvg from "../Svg/MeasureCriteriaSvg/CriteriaMatchSvg";
import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {formatSyncDate} from "../../RightSideContainer/Contracts/HelperFiles/utils";

export const FormLastSyncView = (props: {
  onFetchData?: ()=> void,
  isFirstSync?: boolean,
  isLoading?: boolean,
  lastUpdated?: string,
  hideSync?: boolean,
})=> {
  const [stateData, setStateData] = useState({
    lastUpdated:  "-"
  });
  const refreshSyncInterval = 300000;

  const updateLastSync = () => {
    const updatedDate = props?.lastUpdated ? formatSyncDate(props?.lastUpdated) : "-";
    setStateData((prev) => {
      return {
        ...prev,
        lastUpdated: updatedDate
      }
    })
  }
  useEffect(() => {
    updateLastSync();
    const interval = setInterval(() => {
      updateLastSync()
    }, refreshSyncInterval);
    return () => clearInterval(interval);
  }, [props?.lastUpdated]);


  const showSubmittedTimer = 2000;
  const intl = useIntl();
  const {onFetchData, isFirstSync, isLoading, hideSync} = props;
  const [showSubmitted, setShowSubmitted] = useState(false);
  useEffect(()=>{
    setShowSubmitted(true);
    const timerId = setTimeout(() => {
      setShowSubmitted(false);
    }, showSubmittedTimer);

    return () => {
      clearTimeout(timerId);
    };
  },[isFirstSync, isLoading])

  return (
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
    }}>
      {!isLoading && <View style={{
        flexDirection: 'row',
      }}>
        <Text style={{
          fontSize: 12,
          lineHeight: 14.4,
          fontWeight: '400',
          color: Colors.FoldPixel.GRAY300
        }}>{`Last Sync: ${stateData?.lastUpdated || '-'}`}</Text>
      </View>}
      {!isLoading && !hideSync &&  <Divider color={Colors.FoldPixel.GRAY150} orientation="vertical" h="12px" marginX={2} />}
      {(isFirstSync || isLoading) && <Pressable
        style={{
          flexDirection: 'row'
        }}
        onPress={() => {
          onFetchData?.();
        }}
        >
        {(hideSync ? isLoading : true) && <View style={{
          marginRight: 2,
          alignItems: 'center',
          alignSelf: 'center'
        }}>
          {isLoading ? <Image
          alignItems={'center'}
          alignSelf={'center'}
          size={5}
          color={Colors.FoldPixel.GRAY300}
          source={require('../../../assets/gifs/sync.gif')}
          alt="image"
          key={Math.random()}
        /> : <RefreshSvg
            customStrokeColor={Colors.FoldPixel.GRAY300}
            isLoading={isLoading}
          />}
        </View>}
        {isLoading && <Text style={{
          fontSize: 12,
          lineHeight: 14.4,
          fontWeight: '400',
          color: Colors.FoldPixel.GRAY300,
          alignItems: 'center',
          alignSelf: 'center'
        }}>{intl.formatMessage({id: hideSync ? 'fetchingLatestUpdates' : 'syncing'})}</Text>}
       </Pressable>}
      {(!isFirstSync && !isLoading && !hideSync) && <>
        { showSubmitted ?
          <View
            style={{
              flexDirection: 'row'
            }}
            >
            <View style={{
              marginRight: 4,
              alignItems: 'center',
              alignSelf: 'center'
            }}>
              <CriteriaMatchSvg/>
            </View>
            <Text style={{
              fontSize: 12,
              lineHeight: 14.4,
              fontWeight: '400',
              color: Colors.success[500]
            }}>{'Updated'}</Text>
          </View> : <Pressable
            onPress={() => {
              onFetchData?.();
            }}>
            <View style={{
              marginRight: 4,
              alignItems: 'center',
              alignSelf: 'center'
            }}>
              <RefreshSvg
                customStrokeColor={Colors.FoldPixel.GRAY300}
                isLoading={isLoading}
              />
            </View>
          </Pressable>
        }
      </>}
    </View>
  )
}