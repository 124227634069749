import {gql} from '@apollo/client';

export const CreateContactOutreachActivity = gql`
  mutation addOrUpdateContactOutreachActivity(
    $data: AddOrUpdateContactOutreachActivityInput!
  ) {
    addOrUpdateContactOutreachActivity(params: $data) {
      id
      outreachStepId
    }
  }
`;

export const DeleteContactOutreachActivity = gql`
  mutation deleteContactOutreachActivity(
    $data: DeleteContactOutreachActivityInput!
  ) {
    deleteContactOutreachActivity(params: $data) {
      id
    }
  }
`;

export const GetContactOutreachActivities = gql`
    query GetContactOutreachActivities(
    $whereCondition:  contactOutreachActivity_bool_exp!
    $limit: Int!
    $offset: Int!
  ) {
    ContactOutreachActivities(
      where: {
        _and: [
          $whereCondition,
          { isDeleted: { _eq: false } }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: {outreachDateTime: desc}
    ) {
      id
      note
      statusId
      typeId
      resourceType
      resourceId
      communicationModeId
      outcomeId
      note
      outreachDateTime
      careProgramTypeId
      contactId
      createdBy
      updatedBy
      updatedOn
      reference
      performedBy
      additionalAttributes {
        attributeKey
        attributeValue
      }
    }
     aggregateContactOutreachActivity(
      where: $whereCondition
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getContactOutreachActivityCount = gql`
  query ($contactId: uuid, $careProgramTypeId: uuid) {
    aggregateContactOutreachActivity(
      where: {
        contactId: {_eq: $contactId}
        careProgramTypeId: {_eq: $careProgramTypeId}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GetBillableActivityEntities = gql`
  query GetContactOutreachBillingDetails(
    $contactCareProgramId: uuid!
    $outreachActivityIds: [uuid!]
  ) {
    contactCareProgramBillableActivityEntities(
      where: {
        isDeleted: {_eq: false}
        resourceId: {_in: $outreachActivityIds}
        billableActivity: {
          isDeleted: {_eq: false}
          contactCareProgramId: {_eq: $contactCareProgramId}
        }
      }
    ) {
      id
      resourceId
      billableActivity {
        statusId
      }
    }
  }
`;


export const GetContactOutreachActivitiesByIds = gql`
  query GetContactOutreachActivitiesByIds(
    $outreachActivityIds: [uuid!]
  ) {
    ContactOutreachActivities(
      where: {
        isDeleted: { _eq: false },
        id: { _in: $outreachActivityIds }
      }
    ) {
      id
      note
      statusId
      typeId
      resourceType
      resourceId
      communicationModeId
      outcomeId
      note
      outreachDateTime
      careProgramTypeId
      contactId
      createdBy
      updatedBy
      updatedOn
      reference
    }
  }
`;
