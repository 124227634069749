import React from 'react';
import {IContactPracticeLocations} from '../../../../services/Location/interfaces';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {HStack, Text, VStack} from 'native-base';
import {GROUP_MEMBER_TYPE} from '../../../../constants';
import {Colors} from '../../../../styles';
import {IBookingWidgetData} from '../AppointmentBookingWidget';

interface InviteeContactProps {
  inviteeContact: IBookingWidgetData['inviteeContact'];
  isMobile?: boolean;
}

const InviteeContact = (props: InviteeContactProps) => {
  const {inviteeContact, isMobile} = props;
  if (!inviteeContact) return null;
  return (
    <HStack
      space={2}
      alignItems="center"
      borderWidth={1}
      borderColor={Colors.Custom.BorderColor}
      rounded="lg"
      padding={2}
    >
      <DisplayCardAvatar
        avatarStyle={{
          avatarSize: '12',
        }}
        isLetterAvatarShow
        userData={{
          userId: inviteeContact?.id,
          userType: GROUP_MEMBER_TYPE.CONTACT,
          userName: inviteeContact.name,
        }}
      />
      <VStack flex={1}>
        <Text size={'smMedium'} style={{fontSize: isMobile ? 18 : 14}} color={Colors.Custom.Gray900}>
          {inviteeContact.name}
        </Text>
        <Text size={'smMedium'} style={{fontSize: isMobile ? 16 : 14}} color={Colors.Custom.Gray500} maxW={'100%'} isTruncated>
          {inviteeContact.email}
        </Text>
      </VStack>
    </HStack>
  );
};

export default React.memo(InviteeContact);
