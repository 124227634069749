
import { useLazyQuery } from '@apollo/client';
import {Select, Spin} from 'antd';
import {Text} from 'native-base'
import { useContext, useEffect, useMemo, useState } from 'react';
import { CONFIG_CODES } from '../../../constants/AccountConfigConst';
import { CARESTUDIO_APOLLO_CONTEXT } from '../../../constants/Configs';
import { MLOV_CODES } from '../../../constants/MlovConst';
import { WorkflowContext } from '../../../context/WorkflowContext';
import ScheduleEventQueries from '../../../services/ScheduleEvent/ScheduleEventQueries';
import { isAccountConfigEnabled } from '../../../utils/configUtils';
import { CommonDataContext } from '../../../context/CommonDataContext';

export const MultiSelectAppointmentTypeSearch = (props: {
  appointmentTypeIds?: any;
  isShowError?: boolean;
  onChange: (actionData?: any, extraData?: any) => void;
}) => {

  const {appointmentTypeIds, isShowError} = props;
  const [appointmentTypeList, setAppointmentTypeList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const isMsoEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const workflowContext = useContext(WorkflowContext);
  const locationIdListAutomation = workflowContext?.locationIds || [];
  const commonData = useContext(CommonDataContext);
  const accountLocationListWithEHR = commonData?.accountLocationListWithEHR || [];
  const locationGroupIdListAutomation = useMemo(() => {
    return accountLocationListWithEHR
      ?.filter((item) => locationIdListAutomation.includes(item.uuid) || true)
      .map((item) => item.locationGroupId)
      .filter(
        (locationGroupId, index, self) =>
          locationGroupId && self.indexOf(locationGroupId) === index
      );
  }, [accountLocationListWithEHR, locationIdListAutomation]);
  const [ getAppointmentTypes ] = useLazyQuery(ScheduleEventQueries.GET_ONE_TO_ONE_AND_GROUP_SESSION_APPOINTMENT_TYPES, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    fetchPolicy: 'no-cache',
  });

  const initiateComponent = async () => {
    try {
      const params ={
        searchString: `%%`,
        categoryCodes: [MLOV_CODES.ONE_ON_ONE_APPOINTMENT, MLOV_CODES.GROUP_SESSION],
      };
      const appointmentType = await getAppointmentTypes({
        variables: params,
      });
      const appointmentTypeList = appointmentType?.data?.appointmentTypes;
      const filterApptTypeListBasedOnAutomationLocation = appointmentTypeList?.filter((item: any) => {
        return locationGroupIdListAutomation?.includes(item?.locationGroupId);
      });
      const apptTypeList = isMsoEnabled ? filterApptTypeListBasedOnAutomationLocation : appointmentTypeList;
      setAppointmentTypeList(apptTypeList);
      setLoading(false);
    } catch(error) {
      setAppointmentTypeList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    initiateComponent();
  }, []);

  return (
    <Select
      size="large"
      mode="multiple"
      showSearch={false}
      status={
        isShowError && !(appointmentTypeIds && appointmentTypeIds?.length) ? 'error' : ''
      }
      allowClear
      filterOption={false}
      value={appointmentTypeIds || undefined}
      onChange={(value: any) => {
        if (value && value.length > 0) {
          const appointmentEventName = value?.map((appointmentTypeId: any) => {
            const appointmentType = appointmentTypeList?.find(appointmentType => {
              return appointmentType?.id == appointmentTypeId;
            });
            return appointmentType?.eventName;
          });
          props.onChange(value, appointmentEventName);
        } else {
          props.onChange(undefined);
        }
      }}
      placeholder="Select Appointment Type"
      loading={isLoading}
      notFoundContent={isLoading ? <Spin size="large" /> : <Text>No Data Found</Text>}
      style={{width: '100%'}}>
      {appointmentTypeList?.map((item: any) => {
        return (
          <Select.Option key={item.id} value={item.id}>
            {item.eventName}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default MultiSelectAppointmentTypeSearch;
