import React, {useEffect, useMemo} from 'react';
import {IBookingWidgetData} from '../AppointmentBookingWidget';
import SlotsGroup from '../../../common/CalendarWidget/BookingWorkflows/Booking/AppointmentBooking/SlotsGroup';
import {
  DATE_FORMATS,
  GROUP_MEMBER_TYPE,
  SCREEN_CONTEXT,
} from '../../../../constants';
import {Divider, FlatList, Heading, HStack, Text, View, VStack} from 'native-base';
import {ISlot} from '../../../common/CalendarWidget/BookingWorkflows/BookAppointment/Interfaces';
import Stack from '../../../common/LayoutComponents/Stack';
import {Collapse, Skeleton} from 'antd';
import {Colors} from '../../../../styles';
import {getMomentObj} from '../../../../utils/DateUtils';
import {DisplayCardAvatar} from '../../../common/DisplayCard/DisplayCardAvatar';
import {IUser} from '../../../../Interfaces';
import { useIntl } from 'react-intl';

interface IWidgetDateAndSlotView {
  bookingData: IBookingWidgetData;
  selectedTimezone: string;
  shortLocationName: string;
  slotsLoading: boolean;
  handleSlotSelection: (slot: ISlot | any, user: IUser) => void;
  calendarHeaderTextElem: () => JSX.Element;
  renderTimeSlotErrors: () => JSX.Element;
}

const WidgetDateAndSlotView = (props: IWidgetDateAndSlotView) => {
  const {
    bookingData,
    selectedTimezone,
    slotsLoading,
    shortLocationName,
    handleSlotSelection,
    calendarHeaderTextElem,
    renderTimeSlotErrors,
  } = props;
  const intl = useIntl();
  const dummyArray = useMemo(() => Array.from({length: 15}, (v, k) => k), []);
  const isMobile = useMemo(
    () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    []
  );
  const {userWiseSlotMap, userList, userUuidListBasedOnSelectedLocation} = bookingData;
  const dateKey = getMomentObj(bookingData.selectedDate).format(
    DATE_FORMATS.DISPLAY_DATE_FORMAT
  );
  const shouldShowProviders = !bookingData.slotLoading &&
    !bookingData.slotError &&
    userWiseSlotMap.size > 0 &&
    userUuidListBasedOnSelectedLocation?.length > 0;

  const getSortedUserUuidList = () => {
    const sortedUserUuidList = userUuidListBasedOnSelectedLocation
      .map((uuid) => {
        const userSlotMap = userWiseSlotMap?.get(uuid) || {};
        const slotsForSelectedDate = userSlotMap[dateKey] || [];
        const user = bookingData.userList.find((user) => user.uuid === uuid) as IUser;
        return {
          user,
          slotCount: slotsForSelectedDate.length,
          slotsForSelectedDate,
        };
      })
      .sort((a, b) => b.slotCount - a.slotCount);
    return sortedUserUuidList;
  };

  return (
    <VStack
      flex={['none', 'none', 1]}
      space={2}
      alignItems="stretch"
      justifyContent={'flex-start'}
      paddingY={2}
      maxH={400}
    >
      <HStack paddingX={1} alignItems={'center'} justifyContent={'center'}>
        {calendarHeaderTextElem()}
      </HStack>
      <View
        overflowY={'scroll'} 
        height={isMobile ? 'auto' : 335}
        borderTopWidth={1}
        borderWidth={isMobile ? 1 : 0}
        borderColor={isMobile ? Colors.Custom.BorderColor : undefined}
        borderTopColor={Colors.FoldPixel.GRAY100}
      >
        <View>
          {!bookingData.slotError &&
            bookingData.slotList.length > 0 &&
            shortLocationName && (
              <Text
                fontSize={12}
                color={Colors.FoldPixel.GRAY300}
              >{`Showing slots for ${shortLocationName}`}</Text>
            )}
        </View>

        {slotsLoading && (
          <Stack
            direction="row"
            style={{
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            {dummyArray.map((item, index) => (
              <Skeleton.Button
                active
                size="small"
                style={{width: 80, margin: 4}}
              />
            ))}
          </Stack>
        )}

        <div className="slots">
          {bookingData.slotLoading && (
            <Stack
              direction="row"
              style={{
                width: '100%',
                flexWrap: 'wrap',
              }}
            >
              {dummyArray.map((item, index) => (
                <Skeleton.Button
                  active
                  size="small"
                  style={{width: 80, margin: 4}}
                />
              ))}
            </Stack>
          )}
          <VStack maxH={'100%'} >
            {shouldShowProviders && (
              <VStack>
                <Text
                  fontSize={14}
                  color={Colors.FoldPixel.GRAY200}
                  textAlign={'left'}
                  margin={1}
                  marginLeft={2}
                >
                  {intl.formatMessage({id: 'availableProviders'}).toUpperCase()}
                </Text>
                <Divider />
                <Collapse
                  accordion
                  className="custom-collapse"
                  style={{width: '100%'}}
                  expandIconPosition="end"
                >
                  {getSortedUserUuidList()?.map(({user, slotCount, slotsForSelectedDate}) => {
                    return (
                      <Collapse.Panel
                        key={user.uuid}
                        collapsible={slotCount > 0 ? undefined : 'disabled'}
                        header={
                          <HStack alignItems={'center'} display={'flex'} minHeight={isMobile ? 10 : 8} px={2} paddingLeft={isMobile ? 4 : 0} paddingRight={isMobile ? 4 : 0} >
                            <DisplayCardAvatar
                              avatarStyle={{
                                avatarSize: isMobile ? '10' : '8',
                                textStyle: {fontSize: isMobile ? 16 : 14},
                              }}
                              isLetterAvatarShow
                              userData={{
                                userId: user?.id as string,
                                userType: GROUP_MEMBER_TYPE.USER,
                                userName: user?.name,
                              }}
                            />
                            <Text
                              fontSize={isMobile ? 18 : 14}
                              ml={2}
                              color={Colors.FoldPixel.GRAY400}
                              flexWrap={'wrap'}
                            >
                              {user.name}
                            </Text>
                          </HStack>
                        }
                        extra={
                          <View
                            backgroundColor={
                              slotCount > 0
                                  ? Colors.FoldPixel.STATUS_LIGHT_SUCCESS
                                  : Colors.FoldPixel.GRAY50
                            }
                            paddingX={1}
                            paddingY={0.5}
                            borderRadius={4}
                            borderColor={
                              slotCount > 0
                                  ? Colors.FoldPixel.STATUS_DARK_SUCCESS
                                  : Colors.FoldPixel.GRAY100
                            }
                            borderWidth={0.5}
                            width={75}
                            overflow={'hidden'}
                            alignItems={'center'}
                            justifyContent={'center'}
                          >
                            <Text
                              fontSize={isMobile ? 16 : 14}
                              color={
                                slotCount > 0
                                  ? Colors.FoldPixel.STATUS_DARK_SUCCESS
                                  : Colors.FoldPixel.GRAY250
                              }
                            >
                              {slotCount > 0
                                ? `${slotCount} Slots`
                                : 'No Slots'}
                            </Text>
                          </View>
                        }
                      >
                        {slotCount > 0 ? (
                          <SlotsGroup
                            skipGrouping
                            selectedSlotDate={bookingData.selectedDate}
                            selectedSlot={bookingData.slot}
                            selectedTimezone={selectedTimezone}
                            userSlots={slotsForSelectedDate}
                            handleSlotSelection={(slot) =>
                              handleSlotSelection(slot, user)
                            }
                            screenContext={SCREEN_CONTEXT.APPOINTMENT_WIDGET}
                            isAppointmentWidgetView={true}
                          />
                        ) : (
                          <Text
                            p={2}
                            fontSize={14}
                            color={Colors.FoldPixel.GRAY400}
                          >
                            No slots available
                          </Text>
                        )}
                      </Collapse.Panel>
                    );
                  })}
                </Collapse>
              </VStack>
            )}
          </VStack>
          {!bookingData.slotLoading && <HStack px={2} justifyContent='center'>{renderTimeSlotErrors()}</HStack>}
        </div>
      </View>
    </VStack>
  );
};

export default WidgetDateAndSlotView;
