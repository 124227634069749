import { DEFAULT_COLOR } from "../../FHFormio/CustomComponents/ColorPicker/Helper";
import { DEFAULT_RATING_SCALE, RatingType } from "../../FHFormio/CustomComponents/Rating/Helper";
import { ColumnsComponent, Component, ConditionalComponent, ContainerComponent, CustomTableComponent, FileComponent, ImageComponent, NumberComponent, RadioComponent, RatingComponent, SelectBoxesComponent, SelectComponent, SurveyComponent, TextAreaComponent, DataGridComponent, ParagraphComponent, CurrencyComponent, SignatureComponent } from "../BaseComponents/BaseComponentInterface";


// Basic Components
export const TextFieldSchema: Omit<Component, 'id'> = {
  label: 'Small text',
  type: 'textfield',
  key: 'textfield',
  validate: {
    required: false,
  },
};

export const TextAreaSchema: Omit<TextAreaComponent, 'id'> = {
  label: 'Large text',
  type: 'textarea',
  key: 'textarea',
  rows: 3,
  wysiwyg: false,
  editor: '',
  fixedSize: false,
  inputFormat: 'html',
  validate: {
    required: false,
  },
};

export const EmailSchema: Omit<Component, 'id'> = {
  label: 'Email',
  type: 'email',
  key: 'email',
  validate: {
    required: false,
    pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
  },
};

export const CheckboxSchema: Omit<Component, 'id'> = {
  label: 'Consent',
  type: 'checkbox',
  key: 'checkbox',
  validate: {
    required: false,
  },
};

export const RadioSchema: Omit<RadioComponent, 'id'> = {
  label: 'Single select',
  type: 'radio',
  key: 'radio',
  validate: {
    required: false,
  },
  values: [],
};

export const SelectBoxesSchema: Omit<SelectBoxesComponent, 'id'> = {
  label: 'Multi select',
  type: 'selectboxes',
  key: 'selectboxes',
  validate: {
    required: false,
  },
  values: [],
};

export const SelectSchema: Omit<SelectComponent, 'id'> = {
  label: 'Dropdown',
  type: 'select',
  key: 'select',
  validate: {
    required: false,
  },
  data: {
    values: [],
  },
};

export const PhoneNumberSchema: Omit<Component, 'id'> = {
  label: 'Phone number',
  type: 'phoneNumber',
  key: 'phoneNumber',
  inputMask: "(999) 999-9999",
  validate: {
    required: false,
  },
};

export const DateSchema: Omit<Component, 'id'> = {
  label: 'Date',
  type: 'date',
  key: 'date',
  validate: {
    required: false,
  },
};

export const ParagraphSchema: Omit<ParagraphComponent, 'id'> = {
  label: 'Paragraph',
  type: 'paragraph',
  key: 'paragraph',
  content: 'Paragraph',
};

export const ImageSchema: Omit<ImageComponent, 'id'> = {
  label: 'Image',
  type: 'image',
  key: 'image',
  scale: '33%',
  align: 'center',
};

export const NumberSchema: Omit<NumberComponent, 'id'> = {
  label: 'Number',
  type: 'number',
  key: 'number',
  validate: {
    required: false,
    min: undefined,
    max: undefined,
  },
};

export const CurrencySchema: Omit<CurrencyComponent, 'id'> = {
  label: 'Currency',
  type: 'currency',
  key: 'currency',
  currency: 'USD',
  validate: {
    required: false,
    min: 0,
  },
};

export const ColorPickerSchema: Omit<Component, 'id'> = {
  label: 'Color Picker',
  type: 'colorPicker',
  key: 'colorPicker',
  validate: {
    required: false,
  },
};

export const FileSchema: Omit<FileComponent, 'id'> = {
  label: 'File upload',
  type: 'file',
  key: 'file',
  validate: {
    required: false,
    filePattern: 'image/*,application/pdf',
    fileMinSize: '1KB',
    fileMaxSize: '10MB',
    maxCount: 1,
  },
};

export const RatingSchema: Omit<RatingComponent, 'id'> = {
  label: 'Rating',
  type: 'rating',
  key: 'rating',
  validate: {
    required: false,
  },
  ratingType: RatingType.star,
  ratingScale: DEFAULT_RATING_SCALE,
  showRatingValue: true,
  leftKey: 'Not likely at all',
  rightKey: 'Extremely likely',
  usePlainColor: false,
  fillColor: DEFAULT_COLOR,
};

export const SignatureSchema: Omit<SignatureComponent, 'id'> = {
  label: 'Signature',
  type: 'signature',
  key: 'signature',
  footer: 'Sign above',
  penColor: DEFAULT_COLOR,
  backgroundColor: DEFAULT_COLOR,
  validate: {
    required: false,
  },
};

export const CustomSurveySchema: Omit<SurveyComponent, 'id'> = {
  label: 'Table',
  type: 'customsurvey',
  key: 'customsurvey',
  questions: [],
  values: [],
  allowMultiSelection: false,
  questionHeader: 'Questions',
};

export const CustomTableSchema: Omit<CustomTableComponent, 'id'> = {
  label: 'Custom Table',
  type: 'customTable',
  key: 'customTable',
  rows: [],
  columns: [],
};

// Layout Components
export const ColumnsSchema: Omit<ColumnsComponent, 'id'> = {
  label: 'Multi column',
  type: 'columns',
  key: 'columns',
  columns: [],
};

export const ContainerSchema: Omit<ContainerComponent, 'id'> = {
  title: 'Container',
  label: 'Container',
  type: 'panel',
  key: 'panel',
  components: [],
};

// Health Components
export const ChiefComplaintSchema: Omit<Component, 'id'> = {
  label: 'Chief Complaint',
  type: 'chiefComplaint',
  key: 'chiefComplaint',
  hideLabel: false,
  validate: {
    required: false,
  },
};

export const PatientDemographicsSchema: Omit<Component, 'id'> = {
  label: 'Patient Demographics',
  type: 'patientDemographics',
  key: 'patientDemographics',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const PatientCommunicationSchema: Omit<Component, 'id'> = {
  label: 'Patient Communication',
  type: 'patientCommunication',
  key: 'patientCommunication',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const PatientAddressSchema: Omit<Component, 'id'> = {
  label: 'Patient Address',
  type: 'patientAddress',
  key: 'patientAddress',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const EmergencyContactSchema: Omit<Component, 'id'> = {
  type: 'emergencyContact',
  label: 'Emergency Contact',
  key: 'emergencyContact',
  validate: {
    required: false,
  },
  hideLabel: true,
};

export const VitalsSchema: Omit<Component, 'id'> = {
  label: 'Vitals',
  type: 'vitals',
  key: 'vitals',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const MedicationsSchema: Omit<Component, 'id'> = {
  label: 'Medications',
  type: 'medications',
  key: 'medications',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const AllergiesSchema: Omit<Component, 'id'> = {
  label: 'Allergies',
  type: 'allergies',
  key: 'allergies',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const ConditionsSchema: Omit<Component, 'id'> = {
  label: 'Conditions',
  type: 'conditions',
  key: 'conditions',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const ImmunizationsSchema: Omit<Component, 'id'> = {
  label: 'Immunizations',
  type: 'immunizations',
  key: 'immunizations',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const SocialHistorySchema: Omit<Component, 'id'> = {
  label: 'Social History',
  type: 'socialHistory',
  key: 'socialHistory',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const FamilyHistorySchema: Omit<Component, 'id'> = {
  label: 'Family History',
  type: 'familyHistory',
  key: 'familyHistory',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const SurgicalHistorySchema: Omit<Component, 'id'> = {
  label: 'Surgical History',
  type: 'surgicalHistory',
  key: 'surgicalHistory',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const SubjectiveComplaintSchema: Omit<Component, 'id'> = {
  label: 'Subjective Complaint',
  type: 'subjectiveComplaint',
  key: 'subjectiveComplaint',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const ObjectiveFindingsSchema: Omit<Component, 'id'> = {
  label: 'Objective Findings',
  type: 'objectiveFindings',
  key: 'objectiveFindings',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const AssessmentSchema: Omit<Component, 'id'> = {
  label: 'Assessment',
  type: 'assessment',
  key: 'assessment',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const PlanSchema: Omit<Component, 'id'> = {
  label: 'Plan',
  type: 'plan',
  key: 'plan',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const DiagnosisSchema: Omit<Component, 'id'> = {
  label: 'Diagnosis',
  type: 'diagnosis',
  key: 'diagnosis',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const PastMedicalHistorySchema: Omit<Component, 'id'> = {
  label: 'Past Medical History',
  type: 'pastMedicalHistory',
  key: 'pastMedicalHistory',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const ConsentAndPreferredCommunicationSchema: Omit<Component, 'id'> = {
  label: 'Member Consent',
  type: 'consentAndPreferredCommunication',
  key: 'consentAndPreferredCommunication',
  validate: {
    required: false,
  },
};

export const NoteStatusSchema: Omit<SelectComponent, 'id'> = {
  label: 'Note Status',
  type: 'noteStatus',
  key: 'noteStatus',
  data: {
    values: [
      { label: 'Open', value: 'Open'},
      { label: 'In Progress', value: 'In Progress'},
      { label: 'Closed', value: 'Closed'}
    ]
  },
  validate: {
    required: false,
  },
};

export const NoteSubStatusSchema: Omit<SelectComponent, 'id'> = {
  label: 'Note Sub Status',
  type: 'noteSubStatus',
  key: 'noteSubStatus',
  data: {
    values: [
      {label: 'Prior Authorization Follow Up', value: 'Prior Authorization Follow Up'},
      {label: 'EMO Requested', value: 'EMO Requested'},
      {label: 'Coordinator', value: 'Coordinator'},
      {label: 'Nurse Reassignment', value: 'Nurse Reassignment'},
      {label: 'Transition of Care', value: 'Transition of Care'},
      {label: 'EMO Review', value: 'EMO Review'},
      {label: '1st Member Outreach', value: '1st Member Outreach'},
      {label: '2nd Member Outreach', value: '2nd Member Outreach'},
      {label: '3rd Member Outreach', value: '3rd Member Outreach'},
      {label: 'Medical Records Request', value: 'Medical Records Request'},
      {label: 'Infusion Setup', value: 'Infusion Setup'},
      {label: 'Risk Surveillance', value: 'Risk Surveillance'},
      {label: 'DPC - Med Records Request', value: 'DPC - Med Records Request'},
      {label: 'Awaiting Physician Call Back', value: 'Awaiting Physician Call Back'},
      {label: 'Awaiting Member Call Back', value: 'Awaiting Member Call Back'},
      {label: 'SCA Creation', value: 'SCA Creation'},
      {label: 'Regenerative Medicine', value: 'Regenerative Medicine'},
      {label: 'Ventegra - TruDate Outreach', value: 'Ventegra - TruDate Outreach'},
      {label: 'Missed Opportunity', value: 'Missed Opportunity'},
      {label: 'Transition of Care', value: 'Transition of Care'},
      {label: 'Proactive', value: 'Proactive'},
      {label: 'Surgery Outcome', value: 'Surgery Outcome'},
      {label: 'Appt Outcome', value: 'Appt Outcome'}
    ]
  },
  validate: {
    required: false,
  },
};

export const BarriersSchema: Omit<Component, 'id'> = {
  label: 'Barriers',
  type: 'barriers',
  key: 'barriers',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const GoalsSchema: Omit<Component, 'id'> = {
  label: 'Goals',
  type: 'goals',
  key: 'goals',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const InterventionSchema: Omit<Component, 'id'> = {
  label: 'Intervention',
  type: 'intervention',
  key: 'intervention',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const DischargeDetailsSchema: Omit<Component, 'id'> = {
  label: 'Discharge Details',
  type: 'dischargeDetails',
  key: 'dischargeDetails',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const CareProgramAppointmentsSchema: Omit<Component, 'id'> = {
  label: 'Care Program Appointments',
  type: 'careProgramAppointments',
  key: 'careProgramAppointments',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const AWVHistorySchema: Omit<Component, 'id'> = {
  label: 'AWV History',
  type: 'awvHistory',
  key: 'awvHistory',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const ClinicalParametersSchema: Omit<Component, 'id'> = {
  label: 'Clinical Parameters',
  type: 'clinicalParameters',
  key: 'clinicalParameters',
  hideLabel: true,
  validate: {
    required: false,
  },
};

export const PatientInformationVerificationSchema: Omit<Component, 'id'> = {
  label: 'Patient Information Verification',
  type: 'patientInformationVerification',
  key: 'patientInformationVerification',
  validate: {
    required: false,
  },
};

export const PatientIdentifierSchema: Omit<Component, 'id'> = {
  label: 'Patient Identifier',
  type: 'patientIdentifier',
  key: 'patientIdentifier',
  validate: {
    required: false,
  },
};

export const ConditionalSchema: Omit<ConditionalComponent, 'id'> = {
  type: 'conditional',
  label: 'Conditional',
  key: 'conditional',
  show: 'true',
  when: '',
  operator: 'equal',
  eq: '',
  exist: '',
  validate: {
    required: false,
  },
};

export const DataGridSchema: Omit<DataGridComponent, 'id'> = {
  label: 'Data Grid',
  type: 'datagrid',
  key: 'datagrid',
  validate: {
    required: false,
  },
  components: [],
};

export const CustomSelectSchema: Omit<SelectComponent, 'id'> = {
  label: 'Custom Select',
  type: 'customSelect',
  key: 'customSelect',
  validate: {
    required: false,
  },
  data: {
    values: [],
  },
};

export const MultiSelectCheckBoxesSchema: Omit<Component, 'id'> = {
  label: 'Multi Select Check Boxes',
  type: 'checkBoxes',
  key: 'checkBoxes',
  hideLabel: false,
  validate: {
    required: false,
  },
};

export const HomeMonitoringViewSchema: Omit<Component, 'id'> = {
  label: 'Home Monitoring',
  type: 'homeMonitoringView',
  key: 'homeMonitoringView',
  validate: {
    required: false,
  },
};
