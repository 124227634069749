import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  HStack,
  Text,
  View,
  VStack,
  Box,
  useToast,
  ScrollView,
  Pressable,
  Divider,
  Skeleton,
  Center,
} from 'native-base';
import {AppointmentQueries} from '../../../../../../services';
import {ApolloError, useLazyQuery, useMutation} from '@apollo/client';
import {CARESTUDIO_APOLLO_CONTEXT} from '../../../../../../constants/Configs';
import {
  getMlovId,
  getMlovIdFromCode,
  getMlovListFromCategory,
  getMlovValueFromCode,
  getMlovValueFromId,
} from '../../../../../../utils/mlovUtils';
import {
  APPOINTMENT_PARTICIPANT_STATUS_CODES,
  APPOINTMENT_PARTICIPANT_TYPE_CODES,
  APPOINTMENT_STATUS_CODES,
  LOCATION_TYPE_CODES,
  MLOV_CATEGORY,
  MLOV_CODES,
} from '../../../../../../constants/MlovConst';
import {CommonDataContext} from '../../../../../../context/CommonDataContext';
import {
  getCompletePracticeLocation,
  getDataForAppointmentStatusUpdate,
  getLocationCode,
  getPrimaryAndSecondaryContacts,
  getPrimaryContactFromAppointment,
  getPrimaryContactName,
  getUserNameFromAppointment,
} from './AppointmentBookingHelper';
import {DisplayCardAvatar} from '../../../../DisplayCard/DisplayCardAvatar';
import {Colors} from '../../../../../../styles';
import {
  IAppointmentDetailState,
  IParticipantData,
  ISlot,
} from './AppointmentBookingIntefaces';
import ContactsQueries from '../../../../../../services/Contacts/ContactsQueries';
import {
  getContactUuid,
  getGenderTextByGenderCode,
  getPatientActiveProfileLocationIds,
  getPatientActiveProfiles,
  isResourceLocationActive,
  getUserUUID,
  isActiveProfileMatchAllowedLocations,
} from '../../../../../../utils/commonUtils';
import {getAgeFromDate} from '../../../../../PersonOmniView/LeftContainer/OtherDetails/OtherDeatilsUtils';
import {
  getMomentObjFromFormat,
} from '../../../../../../utils/DateUtils';
import {DATE_FORMATS, GROUP_MEMBER_TYPE} from '../../../../../../constants';
import {useIntl} from 'react-intl';
import FHAlertDialog from '../../../../FHAlertDialog/FHAlertDialog.native';
import LoadingSpinner from '../../../../Loader/LoadingSpinner';
import DateAndTime from '../../../../Appointments/DateAndTime';
import {StyleSheet} from 'react-native';
import AppointmentRsvpNative from './AppointmentRsvpNative';
import { BookAppointmentScreenAction } from '../../BookAppointment/Interfaces';
import { IAppointmentDetail } from '../../../CalendarWidgetInterfaces';
import { ILocation, IMlov } from '../../../../../../Interfaces';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import UserPracticeLocationQueries from '../../../../../../services/Location/UserPracticeLocationQueries';
import { ToastType, showToast } from '../../../../../../utils/commonViewUtils';
import { TestIdentifiers, testID } from '../../../../../../testUtils';
import { useUserSetting } from '../../../../../CustomHooks/useUserSetting';
import { isAccountConfigEnabled } from '../../../../../../utils/configUtils';
import { CONFIG_CODES } from '../../../../../../constants/AccountConfigConst';
import useLoggedInUserAllowedLocationsByTab from '../../../../../CustomHooks/useLoggedInUserAllowedLocationsByTab';
import { MAIN_MENU_CODES } from '../../../../../SideMenuBar/SideBarConst';

interface IParticipantStatusOption {
  value: string;
  label: string;
  key: string;
}

interface IAppointmentRSVPState {
  selectedParticipantStatus?: IParticipantStatusOption[];
  declinedReason?: string;
  selectedProposedDate: string;
  selectedProposedTimeSlot?: ISlot;
  availableSlots?: ISlot[];
  isSlotLoading?: boolean;
  showFormErrors?: boolean;
  isLoading?: boolean;
}

const AppointmentDetailCardOption = (props: {
  appointment: IAppointmentDetail;
  onStatusChange: () => void;
  onJoinMeeting: () => void;
  onBookAppointment: (arg: any) => void;
  // participant: any;
  careStudioMlovData: any;
  headers?: {[index: string]: string};
  onSendResponseSuccess: (setDetailState: any) => void;
  navigation: any;
  accountLocations?:{uuid: string; practiceLocation: ILocation}[]
  contactUUID?: string;
  isActionDisabled?: boolean;
  contactLocationId?: string;
}) => {
  const {appointment, careStudioMlovData, onSendResponseSuccess, navigation, isActionDisabled} =
    props;
  const isRecurrentAppointment = appointment?.isRecurrentAppointment && !!appointment?.seriesId;
  const mlovData = useContext(CommonDataContext);
  const [participant, setParticipant] = useState();
  const [showMore, setShowMore] = useState(false)
  const [ numOfLines, setNumOfLines ] = useState(0);
  const isMsoFlagEnabled = isAccountConfigEnabled(CONFIG_CODES.IS_MSO_ENABLED);
  const activecontactProfiles = getPatientActiveProfiles(props?.appointment?.contactProfiles || []);
  const activeLocationIds = getPatientActiveProfileLocationIds(activecontactProfiles || [] || []);
  const commonContextData = useContext(CommonDataContext);
  const accountLocationListWithEHR = commonContextData?.accountLocationListWithEHR || [];
  const hasResourceLocationActiveByLocationId = isResourceLocationActive(activeLocationIds || [], props?.appointment?.accountLocationId || '');
  const locationGroupIdListByPatientContext = accountLocationListWithEHR
  ?.filter((item) => item.uuid === props?.contactLocationId) 
  .map((item) => item.locationGroupId) 
  .filter((locationGroupId, index, self) => locationGroupId && self.indexOf(locationGroupId) === index);
  const hasResourceLocationActiveByLocationGroupId = isResourceLocationActive(locationGroupIdListByPatientContext || [],  props?.appointment?.locationGroupId|| '');
  const hasResourceLocationActive = ( props?.appointment?.accountLocationId !== null) ? hasResourceLocationActiveByLocationId : hasResourceLocationActiveByLocationGroupId ;
  const getIsActionDisabled = () => {
    if(isMsoFlagEnabled){
      if(props?.isActionDisabled !== undefined){
        return props?.isActionDisabled;
      }
      else{
        return !hasResourceLocationActive;
      }
    }
    else{
      return false
    } 
  }
  const isActionsDisabled = getIsActionDisabled() ;


  const ACCEPTED_PARTICIPANT_CODE = getMlovId(
    mlovData.CARE_STUDIO_MLOV,
    MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
    APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
  );

  const defaultStateRSVP: IAppointmentRSVPState = {
    selectedParticipantStatus: [],
    selectedProposedDate: props?.appointment?.startDateTime as string,
  };

  const defaultState: IAppointmentDetailState = {
    primary: undefined,
    otherParticipants: [],
    detail: props.appointment?.detail,
    appointmentStatusId: undefined,
    isReschedule: false,
    location: undefined,
  };

  const intl = useIntl();
  const [showLoader, setShowLoader] = useState(false);
  const isGroupAppointment =
    props.appointment?.appointmentType?.category?.code ===
    MLOV_CODES.GROUP_APPOINTMENT;

  const isGroupSessionAppointment =
  props.appointment?.appointmentType?.category?.code ===
    MLOV_CODES.GROUP_SESSION;
  const isBlockedInterval = props.appointment?.isBlockedInterval || false;
  const scheduleLocationTypeList = getMlovListFromCategory(mlovData.CARE_STUDIO_MLOV, MLOV_CATEGORY.SCHEDULE_LOCATION_TYPE) || [];
  const isVirtual = getLocationCode(props.appointment, scheduleLocationTypeList) === LOCATION_TYPE_CODES.VIRTUAL;
  const disableBlockTime = useUserSetting('disable_block_time_scheduling');
  const [appointmentRSVPState, setAppointmentRSVPState] =
    useState<IAppointmentRSVPState>(defaultStateRSVP);

  const [detailState, setDetailState] =
    useState<IAppointmentDetailState>(defaultState);

  const [cancelAppointmentConfirmation, setCancelAppointmentConfirmation] =
    useState<{
      visible: boolean;
      header?: string;
      message?: string;
    }>({
      visible: false,
      header: '',
      message: '',
    });

  const getAppointmentTypeHeading = () => {
    if(isBlockedInterval){
      return props?.appointment?.name;
    }
    const appointmentTypeHeading = isGroupAppointment
    ? 'Group'
    : isVirtual
    ? 'Virtual'
    : '1 X 1';
    return `${appointmentTypeHeading}${isGroupSessionAppointment ? "(Group Event)" : ""}`;
  }

  const [getAppointmentLocation, locationAPIQuery] = useLazyQuery(
    UserPracticeLocationQueries.GET_ACCOUNT_LOCATIONS_DETAIL,
    {
      fetchPolicy: 'no-cache',
    }
  );

  let primaryContactName = '';

  if (props.appointment?.participants.length) {
    for (let i = 0; i < props.appointment?.participants.length; i++) {
      primaryContactName = getPrimaryContactName(props?.appointment);
    }
  }

  const toast = useToast();

  function showErrorPopup() {
    showToast(toast, intl.formatMessage({id: 'apiErrorMsg'}), ToastType.error);
  }

  const headers: {[index: string]: string} = {};

  // if (props.headers?.accessToken)
  //   headers['x-hasura-admin-secret'] = props.headers.accessToken;

  if (props.headers?.accountId)
    headers['account-uuid'] = props.headers.accountId;

  const [updateParticipantStatus] = useMutation(
    AppointmentQueries.UPDATE_PARTICIPANT_STATUS,
    {
      context: {
        service: CARESTUDIO_APOLLO_CONTEXT,
        headers: {...headers},
      },
    }
  );

  const appointmentStatusList =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_STATUS
    ) || [];

  const APPOINTMENT_STATUS_IDS = {
    pending: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PENDING
    ),
    proposed: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.PROPOSED
    ),
    booked: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.BOOKED
    ),
    decline: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.DECLINED
    ),
    checkedIn: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_IN
    ),
    checkedOut: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CHECKED_OUT
    ),
    cancelled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.CANCELLED
    ),
    rescheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.RESCHEDULED
    ),
    scheduled: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.SCHEDULED
    ),
    noShow: getMlovIdFromCode(
      appointmentStatusList,
      APPOINTMENT_STATUS_CODES.NO_SHOW
    ),
  };

  const appointmentParticipantType =
    getMlovListFromCategory(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_TYPE
    ) || [];
  const APPOINTMENT_PARTICIPANT_TYPE_IDS = {
    patient: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PATIENT
    ),
    primaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.PRIMARY_USER
    ),
    secondaryUser: getMlovIdFromCode(
      appointmentParticipantType,
      APPOINTMENT_PARTICIPANT_TYPE_CODES.SECONDARY_USER
    ),
  };

  const getAppointmentStatusColor = () => {
    let statusColor = Colors.primary[700];
    if (props.appointment?.statusId)
      switch (props.appointment?.statusId) {
        case APPOINTMENT_STATUS_IDS.checkedIn:
        case APPOINTMENT_STATUS_IDS.scheduled:
          statusColor = Colors.success[500];
          break;
        case APPOINTMENT_STATUS_IDS.pending:
        case APPOINTMENT_STATUS_IDS.proposed:
          statusColor = Colors.warning[400];
          break;
        case APPOINTMENT_STATUS_IDS.decline:
        case APPOINTMENT_STATUS_IDS.cancelled:
        case APPOINTMENT_STATUS_IDS.noShow:
          statusColor = Colors.danger[600];
          break;
      }
    return statusColor;
  };

  const onCheckInClicked = () => {
    if (props.appointment?.id) {
      setShowLoader(true);
      const appointment = props.appointment;
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.checkedIn
      );
      bookAppointment({
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          data: data,
        },
      });
      // updateAppointmentStatus({
      //   variables: {
      //     appointmentId: props.appointment?.id,
      //     statusId: APPOINTMENT_STATUS_IDS.checkedIn,
      //   },
      // });
    }
  };

  const onCheckoutClicked = () => {
    if (props.appointment?.id) {
      setShowLoader(true);
      const appointment = props.appointment;
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.checkedOut
      );
      bookAppointment({
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          data: data,
        },
      });
      // updateAppointmentStatus({
      //   variables: {
      //     appointmentId: props.appointment?.id,
      //     statusId: APPOINTMENT_STATUS_IDS.checkedOut,
      //   },
      // });
    }
  };

  const onCancelAppointmentClicked = () => {
    setCancelAppointmentConfirmation({
      visible: true,
      header: intl.formatMessage({
        id: 'cancelAppointment',
      }),
      message: intl.formatMessage({
        id: 'cancelAppointmentConfirmationMsg',
      }),
    });
  };

  const onCheckedOutClicked = () => {
    if (props.appointment) {
      setShowLoader(true);
      const appointment = props.appointment;
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.checkedOut
      );
      bookAppointment({
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          data: data,
        },
      });
    }
  };

  const onCancelAppointment = () => {
    if (props.appointment) {
      setShowLoader(true);
      const appointment = props.appointment;
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.cancelled
      );
      bookAppointment({
        fetchPolicy: 'no-cache',
        context: {service: CARESTUDIO_APOLLO_CONTEXT},
        variables: {
          data: data,
        },
      });
    }
    setCancelAppointmentConfirmation({
      visible: false,
    });
  };

  const onUnblockAppointment = () => {
    if (props.appointment) {
      const data = getDataForAppointmentStatusUpdate(
        appointment,
        APPOINTMENT_STATUS_IDS.cancelled,
        true
      );
      bookAppointment({
        variables: {
          data: data,
        },
        onCompleted: () => {
          props.onStatusChange();
        },
        onError: (error) => {
          showErrorPopup()
        },
      });
    }
  };

  const [getContactDetails] = useLazyQuery(
    ContactsQueries.GET_CONTACT_BY_UUID,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const currentUserUuid = getUserUUID();

  useEffect(() => {
    let participants: any = undefined;

    if (props?.appointment) {
      const uniqueParticipantIds: any = [];

      if (props.appointment?.participants?.length) {
        props.appointment.participants = props.appointment?.participants.filter((participant: any) => {
          const uniqueId = participant?.contact?.uuid || participant?.user?.uuid;
          if (uniqueId) {
            if (uniqueParticipantIds.includes(uniqueId)) {
              return false;
            }
            uniqueParticipantIds.push(uniqueId);
            return true;
          } else {
            return true;
          }
        });
      }
      participants = getPrimaryContactFromAppointment(props?.appointment);
    }


    if (props?.appointment?.participants) {
      const loggedInParticipant = props.appointment.participants.find(
        (participant: IParticipantData) => {
          if (
            participant?.user?.uuid &&
            participant.user.uuid === currentUserUuid
          )
            return true;
        }
      );

      if (loggedInParticipant) {
        const participantFound = props.appointment?.participants?.find(
          (participant: any) => participant?.id === loggedInParticipant.id
        );
        setDetailState((prevState) => ({
          ...prevState,
          loggedInParticipantId: loggedInParticipant.id,
          participant: participantFound
        }));
      }
    }
    setDetailState((prevState) => ({
      ...prevState,
      primary: participants?.primary,
      otherParticipants: participants?.otherParticipants || [],
      detail: props?.appointment,
      appointmentStatusId: props.appointment?.statusId,
      isReschedule: false,
      location: undefined,
    }));

    if (participants && participants.primary && participants.primary.uuid) {
      getContactDetails({
        variables: {
          contactId: participants.primary.uuid,
        },
      })
        .then((response) => {
          if (
            response?.data?.contacts?.length > 0 &&
            response.data.contacts[0].person
          ) {
            const contactDetail = response.data.contacts[0];
            setDetailState((prev) => {
              if (prev.primary) {
                prev.primary.contactTypeValue =
                  contactDetail.contactType?.contactType?.code === 'CUSTOMER'
                    ? 'Patient'
                    : 'Prospect';
                if (contactDetail.person.birthDate) {
                  prev.primary.birthDate = contactDetail.person.birthDate;
                  prev.primary.age = getAgeFromDate(
                    getMomentObjFromFormat(
                      prev.primary.birthDate,
                      DATE_FORMATS.DISPLAY_DATE_FORMAT
                    ).toDate()
                  );
                }
                const genderValue = getGenderTextByGenderCode(contactDetail);
                if (genderValue) {
                  prev.primary.gender = genderValue;
                }
              }
              return {...prev, primary: prev.primary};
            });
          }
        })
        .catch((error) => {

        });
    }

    if (appointment?.accountLocationId) {
      getAppointmentLocation({
        variables: {
          accountLocationIds: [appointment.accountLocationId],
        },
      })
        .then((response) => {
          if (response?.data?.accountLocations?.length) {
            const accountPracticeLocation = response?.data?.accountLocations[0];
            setDetailState((prev) => ({ ...prev, accountPracticeLocation }));
          }
        })
        .catch((error: ApolloError) => {

        });
    }

  }, [props?.appointment]);

  const [bookAppointment, {loading:bookAppointmentLoading}] = useMutation(AppointmentQueries.BOOK_APPOINTMENT, {
    context: {service: CARESTUDIO_APOLLO_CONTEXT},
    onCompleted: () => {
      setShowLoader(false);
      props.onStatusChange();
    },
    onError: (error) => {
      setShowLoader(false);

    },
  });

  const [updateAppointmentStatus] = useMutation(
    AppointmentQueries.UPDATE_APPOINTMENT_STATUS,
    {
      context: {service: CARESTUDIO_APOLLO_CONTEXT},
      onCompleted: (data) => {
        setShowLoader(false);
        if (data && data.updateAppointment && data.updateAppointment.id) {
          props.onStatusChange();
        }
      },
      onError: (error) => {
        setShowLoader(false);

      },
    }
  );

  const canShowReschdule = () => {
    if(isGroupSessionAppointment){
      return false
    }
    const canShowCancelReschdule =
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.decline &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.checkedIn &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.checkedOut &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.noShow &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.cancelled &&
      !isBlockedInterval &&
      !isGroupAppointment &&
      // hide edit buttton when appointment is RSVP enabled and status is pending and current user/provider is not the initiator
    !(
      detailState.detail?.isRsvpEnabled &&
      detailState.appointmentStatusId === APPOINTMENT_STATUS_IDS.pending &&
      !detailState.isInitiator
    );
    return canShowCancelReschdule;
  };


  const canShowCancel = () => {
    const canShowCancel =
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.decline &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.checkedIn &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.checkedOut &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.noShow &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.cancelled &&
      !isBlockedInterval &&
      !isGroupAppointment;
    return canShowCancel;
  }



  const canShowCheckIn = () => {
    if(isGroupSessionAppointment){
      return false;
    }
    const appointmentDraftStatusIds = [
      APPOINTMENT_STATUS_IDS.proposed,
      APPOINTMENT_STATUS_IDS.decline,
    ];
    const showCheckInPatient =
      !isBlockedInterval &&
      detailState.appointmentStatusId &&
      appointmentDraftStatusIds.indexOf(detailState.appointmentStatusId) ===
        -1 &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.checkedIn &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.checkedOut &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.noShow &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.cancelled &&
      detailState.primary &&
      detailState.primary.contactType &&
      detailState.primary.patient &&
      detailState.primary.patient.patientUuid;
    return showCheckInPatient;
  };

  const canShowCheckOut = () => {
    const appointmentDraftStatusIds = [
      APPOINTMENT_STATUS_IDS.proposed,
      APPOINTMENT_STATUS_IDS.decline,
    ];
    const showCheckoutPatient =
      !isBlockedInterval &&
      detailState.appointmentStatusId &&
      appointmentDraftStatusIds.indexOf(detailState.appointmentStatusId) ===
        -1 &&
      detailState.appointmentStatusId === APPOINTMENT_STATUS_IDS.checkedIn &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.noShow &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.cancelled &&
      detailState.primary &&
      detailState.primary.contactType &&
      detailState.primary.patient &&
      detailState.primary.patient.patientUuid;
    return showCheckoutPatient;
  }
  const checkMeetingId = (): boolean => {
    const meetingId = props?.appointment?.reference?.meetingInfo?.id;
    if (isGroupSessionAppointment) {
      return getCurrentParticipant();
    }
    return !!meetingId;
  };
  const getCurrentParticipant = (): boolean => {

    const currentParticipant = props?.appointment?.participants.find(
      (participant: IParticipantData) =>
        participant?.user?.uuid && participant.user.uuid === currentUserUuid
    );
    return !!currentParticipant;
  };

  const canShowJoinMeeting = () => {
    const isGroupAppointment =
      props.appointment?.appointmentType?.category?.code ===
      MLOV_CODES.GROUP_APPOINTMENT;
    const isBlockedInterval = props.appointment?.isBlockedInterval || false;
    const isValidMeetingUser = props.appointment?.participants.some((participant: any) => {
      return participant?.user?.id && currentUserUuid && currentUserUuid === participant.user.uuid;
    });
    const canShowJoinMeeting =
      isVirtual &&
      isValidMeetingUser &&
      detailState.appointmentStatusId &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.decline &&
      detailState.appointmentStatusId !== APPOINTMENT_STATUS_IDS.cancelled &&
      !isBlockedInterval &&
      !isGroupAppointment &&
      checkMeetingId()
    return canShowJoinMeeting;
  };

  const canUnblock = () => !disableBlockTime && isBlockedInterval && !detailState?.detail?.id?.startsWith('placeholderAppointment');

  const providerUser = (props?.appointment?.participants || []).find(
    (participant: any) => {
      if (participant?.user?.uuid) return true;
    }
  );

  const providerUserUuid = providerUser?.user?.uuid || undefined;
  const appointmentStatusId = detailState.appointmentStatusId;

  const proposedTime = props?.appointment?.proposedTime;
  const declinedReason = props?.appointment?.declinedReason;

  // const participantName =
  //   props.participant?.contact?.name || props.participant.user?.name;

  // const participantId = getParticipantUniqueId(props.participant);



  const APPOINTMENT_PARTICIPANT_STATUS_LIST: any = {
    accepted: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
    ),
    declined: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
    ),
    need_action: getMlovId(
      mlovData.CARE_STUDIO_MLOV,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    ),
  };

  const participantStatusList =
    getMlovListFromCategory(
      props.careStudioMlovData,
      MLOV_CATEGORY.APPOINTMENT_PARTICIPANT_STATUS
    ) || [];

  const isAppointmentStatusPending =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.pending;

  const isAppointmentStatusDecline =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.decline;

  const isAppointmentStatusProposed =
    appointmentStatusId === APPOINTMENT_STATUS_IDS.proposed;

  const PARTICIPANT_STATUS_IDS = {
    accepted: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
    ),
    declined: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
    ),
    needsAction: getMlovIdFromCode(
      participantStatusList,
      APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION
    ),
  };

  const participantStatusOptions: IParticipantStatusOption[] = [
    {
      value: APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED,
      key: PARTICIPANT_STATUS_IDS.accepted,
      label: getMlovValueFromCode(
        participantStatusList,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.ACCEPTED
      ),
    },
    {
      value: APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED,
      key: PARTICIPANT_STATUS_IDS.declined,
      label: getMlovValueFromCode(
        participantStatusList,
        APPOINTMENT_PARTICIPANT_STATUS_CODES.DECLINED
      ),
    },
    {
      value: APPOINTMENT_PARTICIPANT_STATUS_CODES.NEEDS_ACTION,
      key: PARTICIPANT_STATUS_IDS.needsAction,
      label: 'Propose new slot',
    },
  ];

  const onVisitReasonTextLayout = useCallback(e =>{
    if(numOfLines === 0){
      if(e.nativeEvent.lines.length > 2){
        setNumOfLines(e.nativeEvent.lines.length);
        setShowMore(true)
      }
    }
  }, [numOfLines])
  const totalInvites = appointment?.participants?.filter(item => item.contactId);
  const totalAccepted = totalInvites?.filter(item=> item.statusId == ACCEPTED_PARTICIPANT_CODE);
  const participants = getPrimaryAndSecondaryContacts(appointment,APPOINTMENT_PARTICIPANT_TYPE_IDS);
  const totalMembers = (contactList: IParticipantData[]) => {
    return (
      <>
        <Text
          color={Colors.Custom.Gray500}
          fontSize={16}
          fontWeight={'600'}
        >
          {`${intl.formatMessage({id: 'totalMembers'})}: `}
        </Text>
        <Text
          size="smMedium"
          fontSize={16}
          fontWeight={'700'}
          style={{marginRight: 8}}
        >
          {contactList?.length}
        </Text>
      </>
    );
  };
  const isRsvpEnabled = props?.appointment?.isRsvpEnabled || false;
  const groupCountDetails = () => {
    const contactList:IParticipantData[] = participants?.contactList;
    return (
      <HStack
        w="100%"
        justifyContent={isRsvpEnabled ? 'space-evenly' : 'flex-start'}
        borderColor={Colors.Custom.Gray200}
        borderRadius={'8'}
        borderWidth={'1'}
        px={isRsvpEnabled ? '0' : '4'}
        py={'2'}
        >
          {isRsvpEnabled ?
            <>
              <View>
                <HStack alignItems={'center'}>
                  <Feather
                    name="check-circle"
                    size={18}
                    color={Colors.success[500]}
                    style={{ marginRight: 8, paddingTop: 4 }}
                  />
                  <Text
                    size="smMedium"
                    fontSize={16}
                    fontWeight={'700'}
                    style={{ marginRight: 8 }}
                    {...testID('AcceptedCount')}
                  >
                    {
                      contactList?.filter(
                        (participant) =>
                          participant.statusId ==
                          APPOINTMENT_PARTICIPANT_STATUS_LIST.accepted
                      ).length
                    }
                  </Text>
                </HStack>
              </View>
              <View>
                <HStack alignItems={'center'}>
                  <Feather
                    name="x-circle"
                    size={18}
                    color={Colors.error[500]}
                    style={{ marginRight: 8, paddingTop: 4 }}
                  />
                  <Text
                    size="smMedium"
                    fontSize={16}
                    fontWeight={'700'}
                    style={{ marginRight: 8 }}
                    {...testID('DeclinedCount')}
                  >
                    {
                      contactList?.filter(
                        (participant) =>
                          participant.statusId ==
                          APPOINTMENT_PARTICIPANT_STATUS_LIST.declined
                      ).length
                    }
                  </Text>
                </HStack>
              </View>
              <View>
                <HStack alignItems={'center'}>
                  <Feather
                    name="clock"
                    size={18}
                    color={Colors.warning[400]}
                    style={{ marginRight: 8, paddingTop: 4 }}
                  />
                  <Text
                    size="smMedium"
                    fontSize={16}
                    fontWeight={'700'}
                    style={{ marginRight: 8 }}
                    {...testID('NoResponseCount')}
                  >
                    {
                      contactList?.filter(
                        (participant) =>
                          !participant.statusId
                      ).length
                    }
                  </Text>
                </HStack>
              </View>
            </>
          :
          totalMembers(contactList)
          }
      </HStack>
    );
  }

  const getLocationName = () => {
    const locationTypeId = detailState.detail?.locationTypeId;
    let locationDetail = '';
    const accountPracticeLocationName =
      detailState?.accountPracticeLocation?.practiceLocation?.name;
    if (locationTypeId) {
      const locationType: IMlov =
        scheduleLocationTypeList.find((locationType) => {
          return locationType.id === locationTypeId;
        }) || ({} as IMlov);

      if (locationType?.code === LOCATION_TYPE_CODES.VIRTUAL) {
        return `${locationType.value}`;
      }
      if (detailState.detail?.accountLocationId) {
        locationDetail = getCompletePracticeLocation(
          detailState?.accountPracticeLocation?.practiceLocation
        );
        if (locationDetail.trim().length || accountPracticeLocationName) {
          return `${locationDetail.trim()} ${accountPracticeLocationName
            ? `(${accountPracticeLocationName})`
            : ''
            }`;
        }
      }
      if (locationType.value) {
        return `${locationType.value}`;
      }
    } else {
      if (detailState.detail?.accountLocationId) {
        locationDetail = getCompletePracticeLocation(
          detailState?.accountPracticeLocation?.practiceLocation
        );
        if (locationDetail.trim().length || accountPracticeLocationName) {
          return `${locationDetail.trim()} ${accountPracticeLocationName
            ? `(${accountPracticeLocationName})`
            : ''
            }`;
        }
      }
      return `${getMlovValueFromCode(
        scheduleLocationTypeList,
        LOCATION_TYPE_CODES.VIRTUAL
      )}`;
    }
    return '';
    // if (locationCode === LOCATION_TYPE_CODES.VIRTUAL) {
    //   return getMlovValueFromCode(
    //     scheduleLocationTypeList,
    //     LOCATION_TYPE_CODES.VIRTUAL
    //   );
    // } else if (detailState.location?.accountLocation?.practiceLocation?.name) {
    //   const userPracticeLocation =
    //     detailState.location?.accountLocation?.practiceLocation;
    //   let location = getCompletePracticeLocation(userPracticeLocation);
    //   location = location
    //     ? `${location} (${userPracticeLocation.name})`
    //     : userPracticeLocation.name;
    //   return location;
    // }
    // const locationTypeId = detailState.detail?.locationTypeId;
    // if (locationTypeId) {
    //   const locationType =
    //     scheduleLocationTypeList.find((locationType) => {
    //       return locationType.id === locationTypeId;
    //     }) || ({} as IMlov);
    //   return locationType ? locationType.value : '';
    // }
    // return detailState.detail?.appointmentType?.locationType?.value || '';
  };

  return (
    <ScrollView contentContainerStyle={{flexGrow: 1}}>
      <View margin={4}>
        {!!props?.appointment && (
          <VStack alignItems={'center'} justifyContent={'center'} maxW={'full'}>
            <VStack space={2} maxW={'100%'} overflowWrap={'break-word'}>
              <HStack justifyContent={'space-between'}>
                <Text
                  fontSize={14}
                  fontWeight={600}
                  color={Colors.primary[700]}
                  {...testID('AppointmentTypeHeading')}
                >
                  {getAppointmentTypeHeading()}
                </Text>
                {!isBlockedInterval && (
                  <Text
                    fontSize={12}
                    fontWeight={600}
                    color={getAppointmentStatusColor()}
                    {...testID(TestIdentifiers.appointmentCardStatus)}
                  >
                    {getMlovValueFromId(
                      appointmentStatusList,
                      props.appointment?.statusId
                    )}
                  </Text>
                )}
              </HStack>
              <SeparatorLine />
              {!isBlockedInterval && (
                <VStack space={3}>
                  <HStack marginTop={1}>
                    {isGroupSessionAppointment ? (
                      <View
                        alignItems={'center'}
                        justifyContent={'center'}
                        rounded={'full'}
                        borderWidth={1}
                        h={12}
                        w={12}
                        backgroundColor={Colors.Custom.Primary50}
                        borderColor={Colors.Custom.mainPrimaryPurple}
                      >
                        <Feather
                          name="users"
                          size={18}
                          color={Colors.Custom.mainPrimaryPurple}
                        />
                      </View>
                    ) : (
                      <DisplayCardAvatar
                        avatarStyle={{
                          avatarSize: '12',
                        }}
                        isLetterAvatarShow={true}
                        userData={{
                          userId: -1,
                          userType: GROUP_MEMBER_TYPE.CONTACT,
                          genderCode: '' as any,
                          userName: isGroupSessionAppointment
                            ? appointment.name
                            : primaryContactName,
                        }}
                      />
                    )}

                    <VStack mx={2}>
                      <Text
                        fontSize="18"
                        fontWeight="500"
                        color={Colors.Custom.Gray900}
                        {...testID(isGroupSessionAppointment ? TestIdentifiers.appointmentName: 'ContactName')}
                        maxW={'90%'}
                      >
                        {isGroupSessionAppointment
                          ? appointment.name
                          : primaryContactName}
                      </Text>
                      {isGroupSessionAppointment ? (
                        <>
                          {!!totalInvites.length && (
                            <Text
                              color={Colors.Custom.Gray500}
                              {...testID('TotalInvited')}
                            >{`Total Invited: ${totalInvites.length}`}</Text>
                          )}
                        </>
                      ) : (
                        <>
                          {!!detailState.primary?.contactTypeValue && (
                            <HStack>
                              <Text
                                color={Colors.Custom.Gray500}
                                size={'xsMedium'}
                                {...testID('AppointmentSubTitle')}
                              >
                                {`${detailState.primary.contactTypeValue}${
                                  detailState.primary.age ||
                                  detailState.primary.gender
                                    ? ' | '
                                    : ''
                                }${
                                  detailState.primary.age
                                    ? detailState.primary.age + ' Y' + ' | '
                                    : ''
                                }${
                                  detailState.primary.gender
                                    ? detailState.primary.gender
                                    : ''
                                }`}
                              </Text>
                            </HStack>
                          )}
                        </>
                      )}
                    </VStack>
                  </HStack>
                  <SeparatorLine />
                </VStack>
              )}

              <VStack space={1}>
                {isGroupSessionAppointment && groupCountDetails()}
                {isGroupSessionAppointment && (
                  <>
                    <Text fontWeight={600}>Description</Text>
                    <HStack alignItems={'center'} mb={2}>
                      <Feather
                        name="align-left"
                        size={16}
                        color={Colors.Custom.Gray500}
                      />
                      <Text marginLeft={2} flex={1} fontSize={12} {...testID('AppointmentDescription')}>
                        {appointment.description}
                      </Text>
                    </HStack>
                  </>
                )}
                {!isGroupSessionAppointment && (
                  <HStack
                    flexWrap={'wrap'}
                    paddingX={1}
                    my={2}
                    justifyContent={'space-between'}
                  >
                    <Text color={Colors.Custom.Gray900} size={'smMedium'} {...testID(TestIdentifiers.userName)}>
                      {getUserNameFromAppointment(
                        props.appointment,
                        APPOINTMENT_PARTICIPANT_TYPE_IDS.primaryUser
                      )}
                    </Text>
                  </HStack>)}
                <HStack alignItems={'center'}>
                  <Feather
                    name="tag"
                    size={16}
                    color={Colors.Custom.Gray500}
                  />
                  <Text fontSize={12} ml={2} {...testID('AppointmentType')}>
                    {isGroupSessionAppointment
                      ? detailState.detail?.appointmentType.eventName
                      : detailState.detail?.name}
                  </Text>
                </HStack>
                <SeparatorLine />
                <DateAndTime
                  startDateTime={props.appointment.startDateTime}
                  endDateTime={props.appointment.endDateTime}
                  isRecurrentAppointment={isRecurrentAppointment}
                  showVerticalLayout={true}
                />
                <SeparatorLine />
                {isGroupSessionAppointment && (
                  <>
                    <HStack alignItems={'center'}>
                      <Feather
                        name="user"
                        size={16}
                        color={Colors.Custom.Gray500}
                      />
                      <Text fontSize={12} ml={2} {...testID('PrimaryUsers')}>
                        {participants?.primaryUsers?.[0]?.user?.name}
                      </Text>
                    </HStack>
                    {!!participants?.secondaryUsers.length && (
                      <Divider my={2} backgroundColor={Colors.Custom.Gray50} />
                    )}
                    {!!participants?.secondaryUsers.length && (
                      <HStack alignItems={'center'} mb={1}>
                        <Feather
                          name="users"
                          size={16}
                          color={Colors.Custom.Gray500}
                        />
                        <Text fontSize={12} ml={2} {...testID('SecondaryUsers')}>
                          {participants?.secondaryUsers?.map(
                            (user: IParticipantData, index: number) =>
                              `${user?.user?.name}${
                                index < participants?.secondaryUsers.length - 1
                                  ? ' • '
                                  : ''
                              }`
                          )}
                        </Text>
                      </HStack>
                    )}
                  </>
                )}
                {detailState?.detail?.isBlockedInterval && (
                  <Text color={Colors.Custom.Gray500} {...testID('LocationName')}>
                    {detailState?.detail?.isVirtualLocation
                      ? 'Virtual'
                      : props.accountLocations?.find((location) => {
                          return (
                            location.uuid ===
                            props?.appointment?.accountLocationId
                          );
                        })?.practiceLocation?.name}
                  </Text>
                )}
                <SeparatorLine />
                <HStack alignItems={'center'} my={1}>
                  <Feather
                    name="map-pin"
                    size={16}
                    color={Colors.Custom.Gray500}
                  />
                  {locationAPIQuery.loading ? (
                    <VStack w='90%'>
                     <Skeleton w={'80%'} h={2}  rounded="md" />
                     <Skeleton w={'50%'} h={2} my={1} rounded="md" />
                     <Skeleton w={'30%'} h={2} rounded="md" />
                    </VStack>
                    ) : getLocationName() ? (
                    <Text fontSize={12} ml={2} w={'90%'} {...testID(`Appointment${TestIdentifiers.location}`)}>
                      {getLocationName()}
                    </Text>
                  ) : null}
                </HStack>
              </VStack>
              {!isBlockedInterval && !isGroupSessionAppointment && (
                <VStack>
                  <Text color={Colors.Custom.Gray500} size={'xsNormal'}>
                    Visit Reason
                  </Text>
                  <Text
                    onTextLayout={onVisitReasonTextLayout}
                    numberOfLines={showMore ? 2 : undefined}
                    color="#000000"
                    size={'smMedium'}
                    marginBottom={2}
                    {...testID(TestIdentifiers.reasonForVisit)}
                  >
                    {detailState.detail?.reasonForVisit?.displayName ??
                      'No RFV'}
                  </Text>
                  <HStack justifyContent={'flex-end'} marginBottom={2}>
                    {numOfLines > 2 && (
                      <Pressable
                        onPress={() => {
                          setShowMore(!showMore);
                        }}
                        {...testID(showMore ? 'ViewMore' : 'ViewLess')}
                      >
                        <Text size={'xsNormal'} color={Colors.primary[700]}>
                          {showMore ? 'View More' : 'View Less'}
                        </Text>
                      </Pressable>
                    )}
                  </HStack>
                  <SeparatorLine />
                </VStack>
              )}

              <VStack>
                {(isAppointmentStatusPending ||
                  isAppointmentStatusDecline ||
                  isAppointmentStatusProposed) &&
                  // detailState.loggedInParticipantId &&
                  appointment?.participants?.length &&
                  appointment?.participants?.map(
                    (participant: any, index: any) => {
                      return (
                        <AppointmentRsvpNative
                          navigation={navigation}
                          participant={participant}
                          key={index}
                          appointment={appointment}
                          appointmentStatusList={appointmentStatusList}
                          careStudioMlovData={careStudioMlovData}
                          appointmentRSVPState={appointmentRSVPState}
                          appointmentStatusId={detailState?.appointmentStatusId}
                          detailState={detailState}
                          setDetailState={setDetailState}
                          setAppointmentRSVPState={setAppointmentRSVPState}
                          participantStatusOptions={participantStatusOptions}
                          // handleOnSendRSVPResponse={handleOnSendRSVPResponse}
                          showErrorPopup={showErrorPopup}
                          updateParticipantStatus={updateParticipantStatus}
                          onSendResponseSuccess={onSendResponseSuccess}
                          onBookAppointment={props.onBookAppointment}
                        />
                      );
                    }

                    // <React.Fragment>
                    //   {canShowRSVPComponent && (
                    //     <VStack>
                    //       {canParticipantTakeAction && (
                    //         <VStack space={2}>
                    //           {canShowRSVPSelect && (
                    //             <HStack justifyContent={'space-between'}>
                    //               <View width={'48%'}>
                    //                 <Button style={styles.acceptBtn}>
                    //                   {intl.formatMessage({id: 'accept'})}
                    //                 </Button>
                    //               </View>
                    //               <View width={'48%'}>
                    //                 <Button
                    //                   variant="outline"
                    //                   style={styles.declineBtn}
                    //                 >
                    //                   {intl.formatMessage({id: 'decline'})}
                    //                 </Button>
                    //               </View>
                    //             </HStack>
                    //           )}
                    //           {canShowProposedDateTimeSelect && (
                    //             <Button
                    //               variant="outline"
                    //               style={styles.proposeNewTimeSlotBtn}
                    //             >
                    //               Propose New Time Slot
                    //             </Button>
                    //           )}
                    //         </VStack>
                    //       )}
                    //       {canShowDeclinedReasonText && (
                    //         <Text>
                    //           {'Decline Reason: ' + (declinedReason || '-')}
                    //         </Text>
                    //       )}
                    //       {canShowAwaitingApprovalFrom && (
                    //         <Text>{`Awaiting Response From ${participantName}`}</Text>
                    //       )}
                    //       {canShowDeclinedReasonInput && ''}
                    //       {canShowProposedDateTimeSelect && ''}
                    //     </VStack>
                    //   )}
                    // </React.Fragment>
                  )}
              </VStack>
              <VStack marginTop={2} space={2}>
                {canUnblock() && (
                  <Button
                    onPress={onUnblockAppointment}
                    variant="outline"
                    colorScheme="primary"
                    style={{
                      borderRadius: 16,
                      alignItems: 'center',
                      //borderColor: '#6941c6',
                    }}
                    isLoading={bookAppointmentLoading}
                    isDisabled={bookAppointmentLoading}
                    {...testID(TestIdentifiers.unblockAppointmentBtn)}
                  >
                    {intl.formatMessage({id: 'unblock'})}
                  </Button>
                )}
                {canShowCheckIn() && (
                  <Button
                    onPress={onCheckInClicked}
                    variant="outline"
                    isDisabled={isActionsDisabled}
                    colorScheme="primary"
                    style={{
                      borderRadius: 16,
                      alignItems: 'center',
                      borderColor: isActionsDisabled ? Colors.Custom.Gray400 : undefined,
                    }}
                    disabled={isActionsDisabled}
                    {...testID(TestIdentifiers.checkInAppointmentBtn)}
                  >
                    <Text color={isActionsDisabled ? Colors.Custom.Gray400 : undefined}>{intl.formatMessage({id: 'checkInPatient'})}</Text>
                  </Button>
                )}
                {canShowCheckOut() && (
                  <Button
                    onPress={onCheckoutClicked}
                    variant="outline"
                    colorScheme="primary"
                    style={{
                      borderRadius: 16,
                      alignItems: 'center',
                    }}
                    {...testID(TestIdentifiers.checkoutAppointmentBtn)}
                  >
                    <Text >{intl.formatMessage({id: 'checkOutMember'})}</Text>
                  </Button>
                )}
                {isGroupSessionAppointment &&
                  appointment?.statusId !==
                    APPOINTMENT_STATUS_IDS.checkedOut && (
                    <Button
                      onPress={onCheckedOutClicked}
                      variant="outline"
                      colorScheme="primary"
                      style={{
                        borderRadius: 16,
                        alignItems: 'center',
                      }}
                      {...testID(TestIdentifiers.checkoutAppointmentBtn)}
                    >
                      {intl.formatMessage({id: 'checkedOut'})}
                    </Button>
                  )}
                {canShowReschdule() && (
                  <Button
                    onPress={() => {
                      props.onBookAppointment(
                        BookAppointmentScreenAction.reschedule
                      );
                    }}
                    variant="outline"
                    disabled={isActionsDisabled}
                    colorScheme="primary"
                    style={{
                      borderRadius: 16,
                      alignItems: 'center',
                      borderColor: isActionsDisabled ? Colors.Custom.Gray300 : undefined,
                    }}
                    {...testID(TestIdentifiers.rescheduleAppointmentBtn)}
                  >
                    <Text color={isActionsDisabled ? Colors.Custom.Gray300 : undefined}>
                      {intl.formatMessage({id: 'rescheduleAppointment'})}
                    </Text>
                  </Button>
                )}
                {canShowCancel() && (
                  <Button
                    onPress={onCancelAppointmentClicked}
                    variant="outline"
                    colorScheme="primary"
                    style={{
                      borderRadius: 16,
                      alignItems: 'center',
                    }}
                    {...testID(TestIdentifiers.cancelAppointmentBtn)}
                  >
                    <Text >{intl.formatMessage({id: 'cancelAppointment'})}</Text>
                  </Button>
                )}
                {/* <Select
                  placeholder="Select Response"
                  variant="outline"
                  borderColor={'#6941c6'}
                  p={2.5}
                  fontSize={14}
                  fontWeight={500}
                  borderRadius={16}
                >
                  <Select.Item label="Accept" value="accept" />
                  <Select.Item label="Decline" value="decline" />
                  <Select.Item label="Proposed New Time" value="other" />
                </Select> */}
                {canShowJoinMeeting() && (
                  <Button
                    onPress={() => {
                      props.onJoinMeeting();
                    }}
                    style={{
                      borderRadius: 16,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#6941c6',
                    }}
                    {...testID(TestIdentifiers.joinMeetingBtn)}
                  >
                    {intl.formatMessage({id: 'joinMeeting'})}
                  </Button>
                )}
              </VStack>
            </VStack>
          </VStack>
        )}
        <FHAlertDialog
          isOpen={cancelAppointmentConfirmation.visible}
          header={cancelAppointmentConfirmation.header}
          message={cancelAppointmentConfirmation.message}
          buttonActions={[
            {
              textLocalId: 'No',
              buttonProps: {
                colorScheme: 'muted',
                variant: 'outline',
              },
              onPress: () => {
                setCancelAppointmentConfirmation({
                  visible: false,
                });
              },
            },
            {
              textLocalId: 'Yes',
              textColor: 'white',
              buttonProps: {
                backgroundColor: Colors.primary[700],
              },
              onPress: () => {
                onCancelAppointment();
              },
            },
          ]}
        />
        {showLoader && <LoadingSpinner />}
      </View>
    </ScrollView>
  );
};

const SeparatorLine = () => {
  return (
    <View
      style={{height: 1, borderRadius: 10}}
      backgroundColor={Colors.Custom.Gray50}
    />
  );
};

const styles = StyleSheet.create({
  acceptBtn: {
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#6941c6',
  },
  declineBtn: {
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropdown: {
    borderStyle: 'solid',
    backgroundColor: '#6941c6',
    borderWidth: 1,
  },
  proposeNewTimeSlotBtn: {
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default AppointmentDetailCardOption;
