import React, { useContext, useEffect, useState } from "react";
import { defaultColors } from "./colors";

import { Text, Tooltip, View } from "native-base";
import { IAvatarStyle } from "./interface";
import {GROUP_MEMBER_TYPE} from "../../../constants";
import { FontWeight } from "@foldhealth/easy-email-extensions";
import { TestIdentifiers, testID } from "../../../testUtils";
import { Colors } from "../../../styles";
import StopSvgIcon from "../Svg/StopSvgIcon";
import { CommonDataContext } from "../../../context/CommonDataContext";
import {MESSAGE_TYPE} from "../../RightSideContainer/TeamInbox/Conversations/interfaces";
import BotsSvg from "../Svg/BotsSvg";
import MailOpenedSvg from "../Svg/MailOpenedSvg";
export const AlphabetAvatar =(props: IAvatarStyle) => {
    const {
      name,
      size,
      style,
      textStyle,
      backgroundColor,
      backgroundColors,
      userType,
      isUserDeleted,
      disableTop,
      hideFullNameTooltip,
      messageType
    } = props;

    const commonData = useContext(CommonDataContext);
    const isSideCarContext = commonData.sidecarContext?.isSidecar;
    const [alPhabetLetter, setAlPhabetLetter] = useState('');
    const [bgColor, setBgColor] = useState('');
    const isCampaignMessage = messageType === MESSAGE_TYPE.campaign_message;
    const isAutomationMessage = messageType === MESSAGE_TYPE.automation_message;
    const isAutomatedMessage = isCampaignMessage || isAutomationMessage;
    const bgColorByUserType = isAutomatedMessage ? Colors.FoldPixel.GRAY50 : isSideCarContext
      ? userType === GROUP_MEMBER_TYPE.USER
        ? '#FEEEE7'
        : Colors.Custom.PrimaryColor100
      : userType === GROUP_MEMBER_TYPE.USER
      ? Colors.Custom.iconBgColorOrange
      : Colors.Custom.PrimaryColor100;
    const borderColorByUserType = isAutomatedMessage ? Colors.FoldPixel.GRAY250 : isSideCarContext
      ? userType === GROUP_MEMBER_TYPE.USER
        ? '#FBCEB7'
        : Colors.Custom.PrimaryColor200
      : userType === GROUP_MEMBER_TYPE.USER
      ? Colors.Custom.brandAccentColor
      : Colors.Custom.PrimaryColor200;
    const fontColorByUserType = isSideCarContext
      ? userType === GROUP_MEMBER_TYPE.USER
        ? Colors.Custom.badgeColorOrange
        : Colors.Custom.Primary300
      : userType === GROUP_MEMBER_TYPE.USER
      ? Colors.Custom.brandAccentColor
      : Colors.Custom.mainPrimaryPurple;


    const sumChars = (str: any) => {
      let sum = 0;
      for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
      }
      return sum;
    };
  const getAutomatedMessageIcon = () => {
    if (isAutomationMessage) {
      return (
        <BotsSvg 
          size={16} 
        />
      );
    }
    if (isCampaignMessage) {
      return (
        <MailOpenedSvg 
          size={16}
        />
      );
    }
    return null;
  };
  useEffect(()=>{
     let initials = '';
     let defaultBackground = '';
    // GET AND SET INITIALS
    const filteredNameText =  name?.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
    const names = filteredNameText?.split(" ") || [];
    if (names.length === 1) {
      initials = names[0].substring(0, 1).toUpperCase();
    } else if (names.length > 1) {
      //if need all initial letter
      // names.forEach((n: any, i: any) => {
      //   initials += names[i].substring(0, 1).toUpperCase();
      // });

      //if need only first initial letter
      //initials += names[0].substring(0, 1).toUpperCase();

       //first & last initial letter
      initials += names[0].substring(0, 1).toUpperCase();
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    const showDefaultColor = true;
    // SET BACKGROUND COLOR
    if (/[A-Z]/.test(initials) && !showDefaultColor) {
      const randomIndex = Math.floor(Math.random() * defaultColors.length);
      //const index = initials.charCodeAt(0) - 65;

      if (backgroundColor) {
        defaultBackground = backgroundColor;
      } else if (backgroundColors && backgroundColors.length) {
        const i = sumChars(name) % backgroundColors.length;
        defaultBackground = backgroundColors[i];
      } else {
        defaultBackground = defaultColors[randomIndex];
      }
    } else if (/[\d]/.test(initials) && !showDefaultColor) {
      defaultBackground = defaultColors[parseInt(initials)];
    } else {
      defaultBackground = bgColorByUserType;
    }
    setAlPhabetLetter(initials);
    setBgColor(defaultBackground);
  }, [name])

  const renderUserAvatar = () => {
    return (
      isUserDeleted ? (
        <View {...testID('image')}>
          <StopSvgIcon
            customColor={Colors.Custom.Gray300}
            size={48}
            bgColor={Colors.Custom.Gray50}
            customStrokeWidth="1"
          />
        </View>
      ) : (
        <View
          width={size}
          height={size}
          backgroundColor={bgColor}
          style={[
            style,
            {
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              backgroundColor: bgColor,
              borderColor: borderColorByUserType,
            },
          ]}
        >
          {isAutomatedMessage ? (
            getAutomatedMessageIcon()
          ) : (
            <Text
              style={{
                padding: textStyle?.padding || 5,
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              fontSize: textStyle?.fontSize || 20,
              fontWeight: textStyle?.fontWeight || 'normal',
              color: fontColorByUserType,
              top: disableTop ? undefined : 4,
            }}
            {...testID(TestIdentifiers.avatarInitials)}
          >
            {alPhabetLetter}
          </Text>
          )}
        </View>
      )
    )
  };
  
  return !hideFullNameTooltip ? (
    <Tooltip label={isUserDeleted ? `${name} is deleted member` : name}>
      {renderUserAvatar()}
    </Tooltip>
  ) : (
    renderUserAvatar()
  );
}

  export default React.memo(AlphabetAvatar);

